import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function বাস্তুবিদ্যাপরিবেশওপপুলেশন() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          বাস্তুবিদ্যা, পরিবেশ ও পপুলেশন | West Bengal Class 12 Guide | West
          Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="বাস্তুবিদ্যা, পরিবেশ ও পপুলেশন | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                বাস্তুবিদ্যা, পরিবেশ ও পপুলেশন
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নিম্নলিখিত কোন্‌টি উল্লেখ্য লবণাম্বু উদ্ভিদ?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Ceriops
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Cassia
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Eichhornia
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Utricularia
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অতি নিম্ন তাপমাত্রায় বসবাসকারী উদ্ভিদদের বলে —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মাইক্রোথারমস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মেসোথারমস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মেগাথারমস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হেকিটোথারমস
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  জৈব ও অজৈব উভয়ের সঙ্গে জীবের সার্বিক সম্পর্কের অধ্যয়ন হল —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ইকোলজি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অট-ইকোলজি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সিন-ইকোলজি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>একটি (+) ও (0) আন্তঃক্রিয়া হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যামেনাস্যালিজম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পরজীবিতা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রতিযোগিতা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  কমেনসালিজম
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>একটি (+) ও (—) আন্তঃক্রিয়া হল - </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পরজীবিতা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রিডেশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>a ও b উভয়ই
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>উষ্ণতার সর্বাধিক তারতম্য দেখা যায় - </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সমুদ্র অঞ্চলে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হ্রদ অঞ্চলে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মরুভূমিতে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তুন্দ্রা অঞ্চলে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতীয়দের গড় আয়ুষ্কাল হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  50-60 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  60-70 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  50 বছরের কম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  70-80 বছর
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>জাগল উদ্ভিদে বাষ্পমোচন হয় প্রধানত _____-এর মাধ্যমে।</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মূল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পরিবর্তিত কাণ্ড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শঙ্কপত্র
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পত্ররস্ত্র
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বাধ্যতামূলক মূল-পরজীবী কোন্‌টি?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Striga
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Rafflesia
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Viscum
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Loranthus
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>উজ্জ্বল আলোতে বসবাসকারী উদ্ভিদ হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জেরোফাইট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হেলিওফাইট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হ্যালোফাইট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সিওফাইট
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>ত্রয়োদশ অধ্যায় - বাস্তুবিদ্যা, পরিবেশ ও পপুলেশন</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. মাইক্রোক্লাইমেট কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                স্বল্প পরিসর অঞ্চলে জলবায়ুর স্থানীয় পরিবর্তন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. আর্দ্র চিরহরিৎ অরণ্যে কী কী গাছ দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Hopea odorata Artocarpus hirsuta
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. দুটি আরোহী উদ্ভিদের নাম করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Dioscoria Hoya <br />
                <br />
                <font color="#f626d7">
                  <b>4. পরিবেশগত শর্তাবলি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                পরিবেশের যে উপাদানগুলি প্রত্যক্ষ বা পরোক্ষভাবে জীবের গঠন।
                কার্যাবলিকে বিশেষভাবে প্রভাবিত করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. উৎপাদক কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে সকল জীব নিজেদের খাদ্য সংশ্লেষ করে বাস্তুতন্ত্রের অন্য জীবনের
                ঘন বা শক্তি সরবরাহ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. সর্বভুক কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে সকল প্রাণীরা উদ্ভিদ ও বিভিন্ন স্তরের খাদকদের ভক্ষণ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. একটি জলজ উদ্ভিদের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Wolffia (উলফিয়া)
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. কোন্ প্রাণীর পদ প্যাডেলে রূপান্তরিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Sirenia
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. কোন্ প্রাণীর চক্ষু হেড সিল্ড দ্বারা আবৃত থাকে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                টিফলোপ (Typhlop)
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. সম্পূর্ণ পরজীবীর নাম কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                Ascaris, Cuscuta ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>11. প্যাথোজেনিক পরজীবীর নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Salmonella typhael
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. রক্ষণাত্মক অনুকরণ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                উত্তর আত্মরক্ষার্থে যখন কোনো জীব অন্য কোনো জীব বা জড় পদার্থের
                অনুকরণ করে তাকে বলে রক্ষণাত্মক অনুকরণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. অসমোকনফরমার কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                উত্তর পরিবেশের ঘনত্ব পরিবর্তনের সঙ্গে সঙ্গে যাদের দেহের অভিস্রবণ
                ঘনত্ব পরিবর্তিত হয় তাদের অসমোকনফরমার বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    14. কোন্ ধরনের বৃদ্ধির লেখচিত্রে সূচকীয় বৃদ্ধিদশার পর
                    পপুলেশনের বৃদ্ধির হার না বেড়ে একই হারে চলতে থাকে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                S-আকৃতির বৃদ্ধির লেখচিত্র।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. শিলাস্তরে প্রথম কোন্ ধরনের উদ্ভিদ বসতি স্থাপন করে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                মস ও লাইকেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    16. কোনো নির্দিষ্ট জীব ও তার পরিবেশ সংক্রান্ত আলোচনাকে কী
                    বলে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                একে অইকোলজি বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. কমেনসালিজমের উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                উত্তর- চোষক মাছ ও হাঙর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. অ্যামেনস্যালিজম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে আন্তঃক্রিয়ায় একটি জীব অপর জীবের বৃদ্ধিকে ব্যাহত করে কিন্তু
                নিজে উপকৃত বা অপকৃত কিছুই হয় না তাকে বলে অ্যামেনস্যালিজম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. মানুষের পপুলেশনের বৃদ্ধির লেখচিত্র কীরূপ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                S-আকৃতির।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. পপুলেশন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                একটি নির্দিষ্ট সময়ে নির্দিষ্ট স্থানে নির্দিষ্ট প্রজাতির সদস্য
                সংখ্যাকে ওই প্রজাতির ওই স্থানের পপুলেশন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>21. স্টেনোথার্মাল কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে সকল জীবেরা উষ্ণতার সামান্য পরিবর্তন সহ্য করতে পারে তাদের
                স্টেনোথার্মাল বলে। যেমন—শামুক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. ইকোটোন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে স্থানে দুটি মেজর কমিউনিটি মিলিত ও মিশ্রিত হয় তাকে বলে
                ইকোটোন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. বায়োম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                নির্দিষ্ট জীবকুল (উদ্ভিদ ও প্রাণী) দ্বারা চিহ্নিত একটি
                সুনির্দিষ্ট জলবায়ুযুক্ত বৃহৎ অঞ্চলকে বলে বায়োম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. হোমিওথারমস কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যাদের দেহে তাপমাত্রা স্থির থাকে পরিবেশের পরিবর্তনের সঙ্গে সঙ্গে
                তাপমাত্রা পরিবর্তিত হয় না তাকে হোমিওথারমস বলে। যেমন — মানুষ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. অসমোরেগুলেটর কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                পরিবেশের অভিস্রবণ ঘনত্বের পরিবর্তনের সঙ্গে সঙ্গে যাদের দেহের
                অভিস্রবণ ঘনত্ব পরিবর্তিত হয় না তাদের অসমোরেগুলেটর বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. মেগাথারমস কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                অধিক উষ্ণতাযুক্ত অঞ্চলে যারা (উদ্ভিদ) জন্মায় তাদের মেগাথারমস
                বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. বাধ্যতামূলক পরজীবী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে সকল পরজীবী পোষক ছাড়া জীবন অতিবাহিত করতে পারে না অর্থাৎ
                পরজীবীর জীবনচক্রের সব দশাই পোষকে সম্পন্ন হয় তাকে বলে
                বাধ্যতামূলক পরজীবী। যেমন—ফিতাকৃমি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. পয়কিলোথারমস কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে সকল প্রাণীদের দেহে উন্নতা নিয়ন্ত্রণের ব্যবস্থা না থাকায়
                পরিবেশের উষ্ণতা পরিবর্তনের সঙ্গে সঙ্গে তাদের দেহতাপমাত্রাও
                পরিবর্তিত হয় তাদের পয়কিলোথারমস বলে। যেমন — সাপ, ব্যাঙ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. ডেমস কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                কোনো নির্দিষ্ট ভৌগোলিক অঞ্চলে উপস্থিত সমগ্রজাতির জীবের বিভিন্ন
                পপুলেশন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. ল্যান্ডস্কেপ কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                নির্দিষ্ট প্রাকৃতিক সীমানাভুক্ত স্থলভূমি যার মধ্যে একাধিক পৃথক
                প্রকৃতির ইকোসিস্টেম বর্তমান।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. ইকোলজিক্যাল নির্দেশক কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ইকোলজিক্যাল নির্দেশক : </b>{" "}
              </font>
              <font color="#3c92e5">
                {" "}
                পরিবেশের বিভিন্ন শর্তের উপর নির্ভর করে, একটি নির্দিষ্ট
                হ্যাবিট্যাটে কি ধরনের জীব বসবাস করবে বা কোন ধরনের জীবের
                প্রাধান্য লক্ষ্য করা যাবে, অর্থাৎ কোন একটি নির্দিষ্ট জীবের
                অবস্থান থেকে হ্যাবিট্যাটের প্রকৃতি বোঝা যায় বা নির্দিষ্ট জীবের
                উপস্থিতি নির্দিষ্ট পরিবেশের শর্তকে নির্দেশ করে, এই জীবকে বলা হয়
                ইকোলজিক্যাল নির্দেশক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>উদাহরণ : </b>
                </font>
                শৈবালের অধিক উপস্থিতি ফসফেট দূষণকে নির্দেশ করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. J-আকৃতির বৃদ্ধির লেখচিত্র বর্ণনা কর।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> J-আকৃতির বৃদ্ধির লেখচিত্র : </b>{" "}
              </font>
              <font color="#3c92e5">
                কোনো কোনো পপুলেশনে প্রথমে বৃদ্ধির হার অল্প সময়ের জন্য ধীর গতিতে
                হয়। এরপরই প্রচণ্ড দ্রুতগতিতে পপুলেশনের বৃদ্ধি ঘটে এবং সর্বোচ্চ
                সংখ্যায় পৌঁছায়। সর্বোচ্চ সংখ্যায় পৌঁছানোর পর খাদ্য, বাসস্থান
                ও বৃদ্ধির বিভিন্ন শর্তের ঘাটতি শুরু হয়। ফলস্বরূপ ওই পপুলেশনের
                বৃদ্ধি দ্রুত হ্রাস পায়। এই ধরনের পপুলেশনের বৃদ্ধিকে সময়ের
                সাপেক্ষে বিচার করলে যে লেখচিত্র পাওয়া যায় তা ‘J’-আকৃতির হয়।{" "}
                <br />
                যেমন — শৈবাল, পতঙ্গ, গোলাপ ফুলের পরজীবী।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. অনুকরণ কী? অনুকৃতি/মিমিক্রি কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> অনুকরণ : </b>{" "}
              </font>
              <font color="#3c92e5">
                {" "}
                যে পদ্ধতিতে কোনো জীব শত্রুদের হাত থেকে রক্ষা পাওয়ার জন্য জড়
                বস্তু, অখাদ্য প্রাণী বা ভয়ানক জীবের আকৃতি ধারণ করে তাকে অনুকরণ
                বা মিমিক্রি বলে। যে অনুকরণ করে তাকে অনুকারক বা মিমিক (Mimic) বলে
                এবং যার অনুকরণ করা হয় তাকে মডেল (Model) বলা হয়।। এরিশিমা
                (Arisaema) নামক কচু জাতীয় গাছের চমশা বা স্পেদ (Spath) সাপের
                ফণার আকৃতি ধারণ করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>4. ডেমইকোলজি কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>{" "}
              </font>
              <font color="#3c92e5">
                {" "}
                বাস্তুবিদ্যার যে শাখায় একটি ক্ষুদ্র অঞ্চলের জীবগোষ্ঠী ও তাদের
                পারিপার্শ্বিক পরিবেশের আন্তঃসম্পর্ক বিষয়ে আলোচনা করা হয় তাকে
                ডেমইকোলজি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. সিনইকোলজি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b> উত্তর : </b>{" "}
                </font>{" "}
                ইকোলজির যে শাখায় একাধিক উদ্ভিদ বা প্রাণী প্রজাতির সঙ্গে
                পরিবেশের সম্পর্ক বিষয় আলোচনা করা হয় তাকে সিনইকোলজি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. রিয়েলাইজড নিচ্ বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b> উত্তর : </b>{" "}
                </font>
                প্রাকৃতিক পরিবেশের চাপ এবং অন্যান্য প্রজাতির প্রতিযোগিতামূলক
                আচরণের জন্য যে নির্দিষ্ট ও সীমাবদ্ধ স্থানে জীব বসবাস করে সেই
                স্থানকে রিয়েলাইজড নিচ্ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. প্রাণীর গৌণ খেচর অভিযোজন বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b> উত্তর : </b>{" "}
                </font>
                কিছু প্রাণী যারা প্রধানত জলচর বা স্থলচর কিন্তু নিষ্ক্রিয়
                উড্ডয়ন দ্বারা কিছু সময়ের জন্য বাতাসে ভেসে থাকতে পারে বা উড়তে
                পারে তাকে বলে গৌণ খেচর অভিযোজন। যেমন — উড়ুক্কু ব্যাঙ, উড়ুক্কু
                মাছ।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>8. হাইপারপ্যারাসাইট বা অধিপরজীবী কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>{" "}
              </font>
              <font color="#3c92e5">
                একটি পরজীবীর উপর যদি অন্য একটি জীব পরজীবীরূপে বাস করে তখন তাকে
                অধিপরজীবী বা হাইপারপ্যারাসাইট বলে। Bucephalus cuculus নামক
                চ্যাপ্টা কৃমি ঝিনুকের দেহে পরজীবীরূপে বাস করে। আবার ওই চ্যাপ্টা
                কৃমির দেহে Nosema dollfusi নামক আদ্যপ্রাণীটি
                হাইপারপ্যারাসাইটরূপে বাস করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>9. অটইকোলজি ও সিনইকোলজি কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>{" "}
              </font>
              <font color="#3c92e5">
                বিজ্ঞানী Schroter অটইকোলজি শাখার প্রবর্তক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>অটইকোলজি : </b>
                </font>{" "}
                ইকোলজির যে শাখায় একটি নির্দিষ্ট উদ্ভিদ বা প্রাণী প্রজাতির সঙ্গে
                পরিবেশের সম্পর্ক বিষয় আলোচনা করা হয় তাকে অটইকোলজি বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>10. তুন্দ্রা বায়োমের জীবকুল উল্লেখ কর।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>{" "}
              </font>
              <font color="#3c92e5">
                বরফে আবৃত তুন্দ্রা বায়োমের জীবকুল হল —<br />
                <br />
                <font color="#f626d7">
                  <b>উদ্ভিদ : </b>{" "}
                </font>{" "}
                লাইকেন, মস, উইলো, বার্চ, আলডার।
                <br />
                <font color="#f626d7">
                  <b>প্রাণী : </b>{" "}
                </font>{" "}
                বলগা হরিণ, লেমিং, মেরু ভালুক, কস্তুরী মৃগ, সীলমাছ, তিমি, সিন্ধু
                ঘটক।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. S-আকৃতির বৃদ্ধির লেখচিত্র বলতে কী বোঝ?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>{" "}
              </font>
              <font color="#3c92e5">
                {" "}
                কোনো পপুলেশনের শুরুতে বৃদ্ধির হার তুলনামূলকভাবে কম থাকে। যা লগ
                দশা নামে পরিচিত। পরিবেশের সঙ্গে মানিয়ে নিলেই তারপর পপুলেশনের
                বৃদ্ধি দ্রুতগতিতে হয়। যাকে লগ দশা বা এক্সপোনেনশিয়াল বৃদ্ধি দশা
                বলে।
                <br />
                <br />
                পরবর্তী পর্যায়ে পপুলেশনের সদস্যদের মধ্যে খাদ্য ও বাসস্থানের
                জন্য সংগ্রাম শুরু হলে বৃদ্ধি ধীর গতিতে চলতে থাকে এবং পপুলেশনে
                জন্ম ও মৃত্যু হার সমান হয় ফলে বৃদ্ধি স্থির হয়ে যায় যা
                স্থিতিশীল দশা নামে পরিচিত।
                <br />
                <br />
                এই ধরনের বৃদ্ধিকে সময়ের সাপেক্ষে বিশ্লেষণ করলে S-আকৃতির
                লেখচিত্র পাওয়া যায়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

বাস্তুবিদ্যাপরিবেশওপপুলেশন.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(বাস্তুবিদ্যাপরিবেশওপপুলেশন);
