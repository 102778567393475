import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function জাতীয়শিক্ষানীতি() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          জাতীয় শিক্ষানীতি | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="জাতীয় শিক্ষানীতি | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                জাতীয় শিক্ষানীতি
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>CABE একটি -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আন্তর্জাতিক প্রতিষ্ঠান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শিক্ষা প্রতিষ্ঠান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জাতীয় প্রতিষ্ঠান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আন্তঃরাষ্ট্রীয় প্রতিষ্ঠান
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  জাতীয় শিক্ষানীতি 1986 খ্রিস্টাব্দের _______ তারিখে প্রকাশিত
                  হয়।
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  21 জুন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  11 এপ্রিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  21 এপ্রিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  21 মে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  1986 খ্রিস্টাব্দের জাতীয় শিক্ষানীতি সংক্রান্ত বিলটি অনুমোদন
                  লাভ করে -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1998 এর 5 মে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1986 এর 4 মে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1986 এর 11 মে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1986 এর 13 মে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  স্বশাসিত মহাবিদ্যালয় (Autonomus College) স্বীকৃতির কথা কোন্
                  কমিশনে বলা হয়েছে -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোঠারি কমিশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জাতীয় শিক্ষানীতি, 1986
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিশ্ববিদ্যালয় কমিশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জাতীয় শিক্ষানীতি, 1968
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  জাতীয় শিক্ষানীতি 1986-তে _______ স্কুল ব্যবস্থা গড়ে তোলার
                  প্রস্তাব দেওয়া হয়।
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Special
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Urban
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  pace-setting
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Rural
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>জনার্দন রেডি কমিটি কত সালে গঠিত হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1990 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1992 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1989 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1991 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>জাতীয় শিক্ষানীতিতে মোট কতগুলি অধ্যায় ছিল?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  13 টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  10 টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  11 টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  12 টি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নবোদয় বিদ্যালয়ের অপর নাম -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কিন্ডার গার্টেন স্কুল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নার্সারি স্কুল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পেস সেটিং স্কুল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কমন স্কুল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  Educational Equality-র কথা জাতীয় শিক্ষানীতির কোন
                  অধ্যায়ভুক্ত?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রথম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  চতুর্থ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তৃতীয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সপ্তম
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নবোদয় বিদ্যালয় গঠনের সুপারিশ করেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিশ্ববিদ্যালয় শিক্ষা কমিটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জাতীয় শিক্ষানীতি, 1986
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মুদালিয়র কমিশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রামমূর্তি কমিটি
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="3">
                <b>অষ্টম অধ্যায় - জাতীয় শিক্ষানীতি</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. NLM-এর পুরো নাম লেখো।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                NLM-এর পুরো নাম হল – National Literacy Mission.
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. DIET শিক্ষার কোন স্তরের সঙ্গে যুক্ত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                DIET শিক্ষার প্রাথমিক স্তরের সঙ্গে যুক্ত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    3. স্বশাসিত কলেজের সুপারিশ কোন্ কমিশনে উল্লেখ করা হয়েছে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                স্বশাসিত কলেজের সুপারিশ 'জাতীয় শিক্ষানীতি 1986' তে উল্লেখ করা
                হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. 1986 খ্রিস্টাব্দে গঠিত শিক্ষানীতির নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                1986 খ্রিস্টাব্দে গঠিত শিক্ষানীতির নাম 'জাতীয় শিক্ষানীতি'।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. UGC-এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                UGC-এর পুরো নাম— University Grants Commission.
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. NPE-এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                NPE-এর পুরো নাম-“National Policy on Education.
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. DIET-এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                DIET-এর পুরো নাম হল District Institute of Education and
                Training.
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. 'রামমূর্তি কমিটি' কত সালে গঠিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                1990 সালে 'রামমূর্তি কমিটি' গঠিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. প্রাথমিক শিক্ষার ক্ষেত্রে অনুন্নয়ন বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রাথমিক শিক্ষার স্তরে শিক্ষার্থীরা যখন বারবার অনুত্তীর্ণ
                হওয়ায় একই শ্রেণিতে থেকে যায়, একেই বলে অনুন্নয়ন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. শিক্ষাক্ষেত্রে 'ড্রপ আউট' বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                শিক্ষাক্ষেত্রে যে-সমস্ত শিক্ষার্থীরা শিক্ষাবর্ষ সমাপ্ত না করে
                বিদ্যালয় ছেড়ে চলে যায় তাকে ড্রপ আউট বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. NCERT এর পুরো নাম কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                NCERT-এর পুরো নাম National Council of Educational Research and
                Training.
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. NCTE এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                Med+NCTE-এর পুরো নাম National Council of Teacher Education.
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. NLM-এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                NLM-এর পুরো নাম - National Literacy Mission.
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. NIEPA-এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                NIEPA-এর পুরো নাম– National Institute of Educational Planing and
                Administration.
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. রামমূর্তি কমিটি কত খ্রিস্টাব্দে গঠিত হয়েছিল?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                রামমূর্তি কমিটি 1990 খ্রিস্টাব্দে জাতীয় শিক্ষানীতি 1986-কে
                মূল্যায়নের জন্য গঠিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. NCERT এর পুরো নামটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                NCERT এর পুরো নাম National Council of Education Research and
                Training.
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. NCTE -এর পুরো নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                NCTE-এর পুরো নাম National Council of Teacher Education.
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. IGNOU এর পুরো নাম কী? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                IGNOU-এর পুরো নাম হল Indira Gandhi National Open University.
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. শিক্ষাক্ষেত্রে অপচয় বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রাথমিক শিক্ষাস্তর শেষ হওয়ার পূর্বেই সাধারণত বিভিন্ন শ্রেণিতে
                শিশুরা বিদ্যালয় ছেড়ে চলে যায়, ফলে অর্থ এবং শ্রমের অপচয় ঘটে,
                একেই বলে শিক্ষাক্ষেত্রে অপচয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. অপ্রথাগত শিক্ষা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                নিয়মতান্ত্রিক শিক্ষাব্যবস্থার বাইরে সংগঠিত যে, নমনীয়,
                শিক্ষার্থীকেন্দ্রিক এবং একটি অংশগ্রহণমূলক পদ্ধতি সংযুক্ত হয়
                তাকে অ-প্রথাগত শিক্ষা বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  21. Challenge of Education- A Policy Perspective কবে প্রকাশিত
                  হয়?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                1985 খ্রিস্টাব্দের জানুয়ারি মাসে Challenge of Education A
                Policy Perspective প্রকাশিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    22. জাতীয় শিক্ষানীতি 1986 খ্রিস্টাব্দে কত তারিখে প্রকাশিত
                    হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জাতীয় শিক্ষানীতি 1986 খ্রিস্টাব্দের এপ্রিল মাসের 21 তারিখে
                প্রকাশিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    23. 1985 খ্রিস্টাব্দে প্রকাশিত শিক্ষা বিষয়ক আলোচনা পত্রের
                    নাম কী?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                1985 খ্রিস্টাব্দে প্রকাশিত শিক্ষা বিষয়ক আলোচনা পত্রের নাম হল –
                Challenge of Education-A Policy Perspective".
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    24. ভারত সরকার জাতীয় শিক্ষানীতি 1986-র সংশোধনী প্রস্তাব কত
                    খ্রিস্টাব্দে উপস্থাপন করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                1992 খ্রিস্টাব্দে ভারত সরকার জাতীয় শিক্ষানীতি 1986-র সশোধনী
                প্রস্তাব উপস্থাপন করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    25. জাতীয় শিক্ষানীতি 1986 অনুযায়ী জাতীয় শিক্ষার যে-কোনো
                    একটি বৈশিষ্ট্য উল্লেখ করো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জাতীয় শিক্ষানীতি 1986-র গুরুত্বপূর্ণ বৈশিষ্ট্য হল— সারাদেশে
                প্রতিভাবান শিক্ষার্থীদের যথার্থ বিকাশের জন্য নবোদয় বিদ্যালয়
                স্থাপন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. SABE-এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                SABE-এর পুরো নাম হল — State Advisory Board of Education.
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. DIET এর পুরো নাম কী? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                DIET-এর পুরো নাম হল- District Institute of Education and
                Training.
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. POA কী? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                1992 খ্রিস্টাব্দে জনার্দন কমিটির রিপোর্ট পার্লামেন্টে অনুমোদিত
                হয়। এই কমিটির চূড়ান্ত রিপোর্ট রূপায়ণের জন্য যে কর্মসূচি গ্রহণ
                করা হয় তাকে বলা হয় Programme of Action বা POA।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. ECCE বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জাতীয় শিক্ষানীতিতে শিশুর পুষ্টি, স্বাস্থ্য, শারীরিক, মানসিক,
                সামাজিক, নৈতিক ইত্যাদি মৌলিক বিষয়গুলি স্বীকৃতি জানিয়ে যে
                শিশুকল্যাণ কর্মসূচি গ্রহণ করা হয় তাকে Early Childhood Care and
                Education বা ECCE বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. পশ্চিমবঙ্গের দুটি মুক্ত বিশ্ববিদ্যালয়ের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                পশ্চিমবঙ্গের দুটি মুক্ত বিশ্ববিদ্যালয়ের নাম হল - (i) নেতাজি
                সুভাষ মুক্ত বিশ্ববিদ্যালয়। (ii) রবীন্দ্রভারতী মুক্ত বিদ্যালয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>31. ECCE কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                জাতীয় শিক্ষানীতি 86-র পঞ্চম অংশে প্রত্যেক শিশুর সামগ্রিক
                সহায়তা করার দিকে লক্ষ্য রেখে Early Childhood Care and Education
                কর্মসূচি গ্রহণ করা হয়। এই কর্মসূচির অন্তর্ভুক্ত ছিল শিশুর
                পুষ্টি, স্বাস্থ্য, শারীরিক, মানসিক, সামাজিক, নৈতিক ইত্যাদি মৌলিক
                বিষয়গুলি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. অন্তর্ভুক্তি শিক্ষা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                শারীরিক ও মানসিকভাবে কিছুটা অক্ষম শিক্ষার্থীরা সাধারণধর্মী
                বিদ্যালয় স্বাভাবিক শিক্ষার্থীদের সঙ্গে পড়াশোনা করার সুযোগ
                পায়, একে অন্তর্ভুক্তি শিক্ষা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. ভারতের দুটি জাতীয় শিক্ষা সংস্থার নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জাতীয় শিক্ষা সংস্থা হল – (i) NCERT (ii) UGC.
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. DIET-এর দুটি কাজ লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                (i) প্রাথমিক স্তরের শিক্ষিকাদের প্রশিক্ষণের ব্যবস্থা করা। (ii)
                প্রাথমিক শিক্ষা প্রতিষ্ঠানগুলির শিক্ষাগত মান উন্নয়ন করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    36. বৃত্তিশিক্ষার ক্ষেত্রে রেড্ডি কমিটির ২টি সুপারিশ লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                বৃত্তিশিক্ষার ক্ষেত্রে রেড্ডি কমিটির ২টি সুপারিশ হল— (i)
                বৃত্তিশিক্ষার গ্রহণের পর শিক্ষার্থীরা যাতে কর্মসংস্থান হয়
                সেদিকে লক্ষ্য রাখা। (ii) পরিকল্পিতভাবে বৃত্তিমুখী শিক্ষা
                প্রতিষ্ঠানগুলি চালু করতে হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. জনার্দন রেড্ডি কমিটি কত সালে গঠিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                1992 সালে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. জাতীয় শিক্ষানীতি(1986) এর প্রস্তাব কে দিয়েছিলেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                রাজীব গান্ধী। <br />
                <br />
                <font color="#f626d7">
                  <b>39. জাতীয় শিক্ষানীতি কবে প্রকাশিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                21 এপ্রিল 1986 সালে। <br />
                <br />
                <font color="#f626d7">
                  <b>
                    40. 1992 জাতীয় শিক্ষানীতি কার নেতৃত্বে জনার্দন কমিটি গঠিত
                    হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                মানবসম্পদ উন্নয়ন মন্ত্রী অর্জুন সিং এর।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>41. অপারেশন ব্ল্যাকবোর্ড কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                একটি প্রস্তাব। <br />
                <br />
                <font color="#f626d7">
                  <b>42. নবোদয় বিদ্যালয় গঠনের কথা বলা হয় কোন কমিশনে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জাতীয় শিক্ষানীতি(1986)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>43. নবোদয় বিদ্যালয়ের অপর নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                পেস সেটিং স্কুল। <br />
                <br />
                <font color="#f626d7">
                  <b>44. নবোদয় বিদ্যালয়ের শিক্ষার মাধ্যম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ইংরেজি ও হিন্দি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>45. 'সাম্যের জন্য শিক্ষা'- কোন কমিশনের সুপারিশ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জাতীয় শিক্ষানীতি (1986)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    46. নবোদয় বিদ্যালয়ে মেয়েদের জন্য কত শতাংশ আসন সংরক্ষিত
                    থাকে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                এক-তৃতীয়াংশ। <br />
                <br />
                <font color="#f626d7">
                  <b>
                    47. 'চাকরি থেকে ডিগ্রীকে আলাদা করা'র কথা বলা হয়েছে কোন
                    কমিশনে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জাতীয় শিক্ষানীতি (1986)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>48. অপারেশন ব্ল্যাকবোর্ড কথাটি যুক্ত কোন শিক্ষার সঙ্গে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রাথমিক শিক্ষার সঙ্গে। <br />
                <br />
                <font color="#f626d7">
                  <b>
                    49. নবোদয় বিদ্যালযয়ে তপশিলি জাতিদের জন্য কত শতাংশ আসন
                    সংরক্ষিত থাকে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                15% <br />
                <br />
                <font color="#f626d7">
                  <b>50. স্বশাসিত কলেজ গঠনের কথা বলা হয়েছে কোন কমিশনে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জাতীয় শিক্ষানীতি (1986)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    51. কোন দুটি কমিটির প্রস্তাবে 1992 সালের শিক্ষানীতি রূপায়িত
                    হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                রামমূর্তি ও জনার্দন রেড্ডি কমিটি। <br />
                <br />
                <font color="#f626d7">
                  <b>
                    52. ইন্দিরা গান্ধী মুক্ত বিশ্ববিদ্যালয় কবে প্রতিষ্ঠিত হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                1985 সালে। <br />
                <br />
                <font color="#f626d7">
                  <b>53. নবোদয় বিদ্যালয়ের উদ্দেশ্য কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                মেধাবী শিক্ষার্থীদের শিক্ষা দান। <br />
                <br />
                <font color="#f626d7">
                  <b>54. কমন স্কুল এর উদ্দেশ্য কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                সকলের জন্য শিক্ষার ব্যবস্থা করা।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. 1986 সালের জাতীয় শিক্ষানীতির সংশোধিত রূপটির বিবরণ দাও।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                উত্তর 1986 খ্রিস্টাব্দের জাতীয় শিক্ষানীতি এবং রামমূর্তি কমিটির
                সুপারিশ কতগুলি বাস্তবায়িত হয়েছে ভবিষ্যতে সেগুলিকে কীভাবে
                বাস্তবায়িত করা যায় তা বিচার-বিবেচনা করবার জন্য কেন্দ্রীয়
                শিক্ষা উপদেষ্টা বোর্ড অন্ধ্রপ্রদেশের তদানীন্তন মুখ্যমন্ত্রী
                জনার্দন রেড্ডির নেতৃত্বে 17 জন সদস্যবিশিষ্ট একটি কমিটি গঠিত হয়।
                এই কমিটি জনার্দন রেড্ডি কমিটি নামে পরিচিত।
                <br />
                <br />
                রামমূর্তি কমিটির রিপোর্ট ও জনার্দন কমিটির রিপোর্টের ভিত্তিতে
                1986 খ্রিস্টাব্দের জাতীয় শিক্ষানীতির কিছু পরিবর্তন সাধন করে
                তৎকালীন শিক্ষামন্ত্রী তা সংসদে পেশ করেন। এই সংশোধিত শিক্ষানীতির
                শিরোনাম হল 'National Policy of Education Revised Policy
                Formulation, 1992 1992-এর Programme of Action এ যেসব ক্ষেত্রে
                গুরুত্ব দেওয়া হয়েছে তা হল -<br />
                <br />
                <font color="#f626d7">
                  <b>1. শিক্ষা কাঠামো : </b>{" "}
                </font>{" "}
                সারাদেশে একই শিক্ষা কাঠামো থাকবে। এর উপরেই ভিত্তি করে 10+2+3
                প্ৰথা গৃহীত হয়েছে। উচ্চমাধ্যমিক +2 স্তরের শিক্ষা হবে স্কুল
                শিক্ষা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. কমন স্কুল : </b>{" "}
                </font>{" "}
                যত শীঘ্র সম্ভব কমন স্কুল ব্যবস্থা চালু করতে হবে। বিভিন্ন
                বিদ্যালয়ের মধ্যে যে সমস্ত বৈষম্য রয়েছে সেগুলি দূর করাতে হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3 নবোদয় বিদ্যালয় : </b>{" "}
                </font>{" "}
                নবোদয় বিদ্যালয় ব্যবস্থা চালু থাকবে। দেশের প্রতিটি জেলায় একটি
                করে নবোদয় বিদ্যালয় স্থাপনের সিদ্ধান্তে বহাল থাকবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. Standing Committee গঠন : </b>{" "}
                </font>{" "}
                তপশিলি জাতি, উপজাতি ও অন্যান্য পিছিয়ে পড়া শ্রেণির মানুষদের
                শিক্ষার জন্য দেশের বিশিষ্ট শিক্ষাবিদদের নিয়ে CABE একটি Standing
                Committee গঠন করবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. প্রাথমিক শিক্ষা : </b>{" "}
                </font>{" "}
                14 বছর বয়স পর্যন্ত প্রতিটি শিশুর সর্বজনীন অবৈতনিক প্রাথমিক
                শিক্ষার লক্ষ্য অর্জনের জন্য কেন্দ্রীয় ও রাজ্য সরকারের বয়স্ক
                শিক্ষা ও সর্বজনীন প্রাথমিক শিক্ষাকে অগ্রাধিকার দেবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. অপারেশন ব্ল্যাকবোর্ড : </b>{" "}
                </font>{" "}
                প্রাথমিক শিক্ষার মানোন্নয়নের জন্য অপারেশন ব্ল্যাকবোর্ড কর্মসূচি
                অগ্রাধিকার পাবে। তা ছাড়া প্রাথমিক স্তরে MLL অর্থাৎ Minimum
                Levels of Learning কতটা হওয়া উচিৎ তা নির্দিষ্ট করতে হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. NCERT এর দায়িত্ব : </b>{" "}
                </font>{" "}
                প্রাথমিক শিক্ষার জন্য জাতীয় স্তরে একই পাঠক্রমের ব্যবস্থা করতে
                হবে। এই পাঠক্রম প্রণয়নের দায়িত্বে থাকবে NCERT.
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    8. দুটি নতুন অনুচ্ছেদ সংশোধিত শিক্ষানীতিতে অন্তর্ভুক্ত :
                  </b>{" "}
                </font>{" "}
                <br />
                (i) ক্রম উন্নয়নশীল ভারতে দক্ষ কর্মীর চাহিদার সঙ্গে সামস্য বিধান
                করা বৃত্তিমুখী শিক্ষাকে পরিচালনা করতে হবে।
                <br />
                (ii) শিক্ষাক্ষেত্রে শিক্ষকের অভাব-অভিযোগ বিচার-বিবেচনার জন্য
                বিশেষ ট্রাইবুনাল গঠন করতে হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. মাধ্যমিক শিক্ষা : </b>{" "}
                </font>{" "}
                সমস্ত রাজ্যের মাধ্যমিক শিক্ষার প্রাথমিক কাঠামোর মধ্যে সমতা বজায়
                রাখতে হবে। মাধ্যমিক শিক্ষা বোর্ডগুলিকে পুনর্গঠন করে তাদের
                স্বশাসন দেওয়ার ব্যবস্থা করতে হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. উচ্চশিক্ষা : </b>{" "}
                </font>{" "}
                উচ্চশিক্ষার উদ্দেশ্য হবে মানুষের মধ্যে সামাজিক, অর্থনৈতিক,
                সাংস্কৃতিক, নৈতিক ও আধ্যাত্মিক ইত্যাদি ক্ষেত্রে মানবিকতা বোধ
                জাগ্রত করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>11. বৃত্তিগত শিক্ষা : </b>{" "}
                </font>{" "}
                উচ্চমাধ্যমিক স্তরে 10% ছাত্রছাত্রীদের 1995 খ্রিস্টাব্দের মধ্যে ও
                25% ছাত্রছাত্রীদের 2000 খ্রিস্টাব্দের মধ্যে বৃত্তিশিক্ষার আওতায়
                আনতে হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. প্রথামুক্ত শিক্ষা : </b>{" "}
                </font>{" "}
                স্কুলছুটদের জন্য প্রথামুক্ত শিক্ষার ব্যবস্থা করতে হবে। যে সব
                শিশু কর্মরত এবং যে সব মেয়ের পক্ষে সারাদিনব্যাপী স্কুলে যাওয়া
                সম্ভব নয় তাদের জন্য প্রথামুক্ত শিক্ষা ব্যবস্থাকরার কথা বলা
                হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. জাতীয় নিয়ন্ত্রক সংস্থা : </b>{" "}
                </font>{" "}
                জাতীয় স্তরে একটি নিয়ন্ত্রক সংস্থা গড়ে তুলতে হবে। যার কাজ হবে
                কলেজ ও বিশ্ববিদ্যালয় শিক্ষার মান নির্ধারণ করা।
                <br />
                <br />
                ⦿ 1992-এর POA-এর অন্য সুপারিশগুলি হল -<br />
                <br />
                (i) সারাদেশে বিভিন্ন স্তরেরশিক্ষার সুযোগ বৃদ্ধি।
                <br />
                (ii) শিক্ষা সংক্রান্ত বিভিন্ন বোর্ডের ভূমিকার পরিবর্তন প্রয়োজন।
                <br />
                (iii) সমস্ত স্তরের শিক্ষার আধুনিকীকরণ প্রয়োজন।
                <br />
                (iv) সমস্ত স্তরের শিক্ষার্থীদের মধ্যে সামাজিক চেতনা, ধর্ম
                নিরপেক্ষতা ও গণতান্ত্রিকতার বিকাশ ঘটানো দরকার।
                <br />
                (v) শিক্ষাক্ষেত্রে বৈষম্য দূর করে সমতা আনা দরকার।
                <br />
                <br />⦿ সবশেষে বলা যেতে পারে জাতীয় শিক্ষানীতি ও পরবর্তী
                Programme of Action (POA) 1992-এর সুপারিশসমূহ শিক্ষার নানা
                ক্ষেত্রে বাঞ্ছিত পরিবর্তনের সূচনা করেছে। অনেক সুপারিশ কার্যকর
                করা হয়েছে। তবে অর্থের অভাব এবং উপযুক্ত সমন্বয় এর ঘাটতি শিক্ষার
                অগ্রগতিকে বহু পরিমাণে ব্যাহত করেছে। লক্ষ্যপূরণে এখনো অনেক পথ
                চলতে হবে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

জাতীয়শিক্ষানীতি.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(জাতীয়শিক্ষানীতি);
