import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function জনসংখ্যাওজনবসতি() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          জনসংখ্যা ও জনবসতি | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="জনসংখ্যা ও জনবসতি | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                জনসংখ্যা ও জনবসতি
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b>নবম অধ্যায়</b>
              </font>
              <br />{" "}
              <font color="#2b9a09" size="4">
                <b>জনসংখ্যা ও জনবসতি</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ৭</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                ১. ভারতে অত্যধিক জনঘনত্বযুক্ত অঞ্চলগুলির বণ্টন আলোচনা করো।
                পশ্চিমবঙ্গের জনঘনত্ব বেশি হওয়ার কারণ কী?
                <br />
                ২. জনঘনত্বের ভিত্তিতে পৃথিবীর আঞ্চলিক শ্রেণিবিভাগ করাে এবং
                অঞ্চলগুলির বর্ণনা দাও।
                <br />
                ৩. পৃথিবীতে অসম জনবণ্টনের কারণ ব্যাখ্যা করাে। <br />
                ৪. পৃথিবীতে জনসংখ্যা বৃদ্ধির গতিপ্রকৃতি বিশ্লেষণ করাে।
                <br />
                ৫. বিশ্বব্যাপী জনসংখ্যা বণ্টনের ওপর পরিব্রাজনের প্রভাব আলােচনা
                করাে।
                <br />
                ৬. ভারতে পৌরায়ণের বৈশিষ্ট্য ও সমস্যা আলােচনা করাে।
                <br />
                ৭. কাম্য জনসংখ্যা, জনস্বল্পতা ও অতি জনাকীর্ণতার ধারণাগুলি
                ব্যাখ্যা করো।
                <br />
                ৮. জনবিস্ফোরণ কাকে বলে? ভারতে দ্রুতহারে জনসংখ্যা বৃদ্ধির
                কারণগুলি আলোচনা করো। <br />
                ৯. পরিব্রাজন কাকে বলে? পরিব্রাজনের ফলাফল আলোচনা করো। <br />
                ১০. হ্যামলেট বা ক্ষুদ্র গ্রাম কী? গ্রামীণ বসতির বিন্যাস আলোচনা
                করো।
                <br />
                ১১. সেন্সাস শহর কাকে বলে? ভারতীয় জনগণনা অনুসারে পৌর বসতির
                শ্রেণিবিভাগ করো। <br />
                ১২. উমল্যান্ড কী? কর্মধারার ভিত্তিতে ম্যাকেঞ্জির শহরের
                শ্রেণিবিভাগটি উল্লেখ করো।
                <br />
                ১৩. এক্যুমনােপলিস্ কাকে বলে? কর্মধারা অনুযায়ী হ্যারিসের শহরের
                শ্রেণিবিভাগটি উল্লেখ করাে।
                <br />
                ১৪. গ্রামীণ বসতির বৈশিষ্ট্য লেখাে। গ্রামীণ ও পৌর বসতির মধ্যে
                পার্থক্য নির্ণয় করাে।
                <br />
                ১৫. পরিকল্পিত গ্রাম কী? গোষ্ঠীবদ্ধ এবং বিক্ষিপ্ত জনবসতির মধ্যে
                পার্থক্য লেখাে।
                <br />
                ১৬. গােষ্ঠীবদ্ধ জনবসতি গড়ে ওঠার কারণগুলি ব্যাখ্যা করাে।
                <br />
                ১৭. বিক্ষিপ্ত জনবসতি গড়ে ওঠার কারণগুলি ব্যাখ্যা করাে। বয়সলিঙ্গ
                পিরামিড বা জনসংখ্যা পিরামিডের তাৎপর্য লেখো।
                <br />
                ১৮. উন্নত দেশের বয়সলিঙ্গ পিরামিড (জনসংখ্যা পিরামিড) এবং অনুন্নত
                দেশের বয়সলিঙ্গ পিরামিড(জনসংখ্যা পিরামিড) পার্থক্য লেখো। <br />
                ১৯. জনসংখ্যা বিবর্তন মডেল বা জনবিবর্তন মডেল কাকে বলে? ভারতে
                জনসংখ্যা বৃদ্ধির কারণগুলি আলোচনা করো।
                <br />
                ২০. রৈখিক বসতি বা দণ্ডাকৃতি বসতি গড়ে ওঠার কারণ আলোচনা করো।
                ভারতের নগরায়ণের সমস্যাগুলি আলোচনা করো।
                <br />
                ২১. পৌরবসতির/শহরের/নগরের বিভিন্ন কার্যকলাপ আলোচনা করো।
                <br />
                ২২. আদমশুমারি অনুযায়ী পৌরবসতি বা শহরের সংজ্ঞা ও শ্রেণিবিভাগ
                লেখো।
                <br />
                ২৩. গ্রামীণ বসতির কার্যকলাপ আলোচনা করো। বিক্ষিপ্ত ও রৈখিক বসতির
                পার্থক্য লেখো।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                ১. ২০১১ খ্রিস্টাব্দের জনগণনা অনুযায়ী ভারতের জনঘনত্ব কত?
              </font>
              <br />
              <font color="#29900a">উত্তর : </font>{" "}
              <font color="#3c92e5">
                ৩৮২ জন / বর্গকিমি।
                <br />
                <br />
                <font color="#f626d7">২. বর্তমানে পৃথিবীর জনসংখ্যা কত?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ৭,৫১৫,২৮৪,১৫৩ জন।
                <br />
                <br />
                <font color="#f626d7">
                  ৩. ২০১১ খ্রিস্টাব্দের জনগণনা অনুযায়ী ভারতের জনসংখ্যা
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ১২১ কোটি।
                <br />
                <br />
                <font color="#f626d7">৪. জনঘনত্ব কাকে বলে?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কোনাে দেশের মােট জনসংখ্যা এবং ওই দেশের মােট জমির পরিমাণের
                সংখ্যাসূচক অনুপাতই হল জনঘনত্ব। সাধারণত প্রতি বর্গকিলােমিটার
                এলাকায় বসবাসকারী মােট জনসংখ্যাকে ওই এলাকার জনঘনত্ব বলে।
                <br />
                <br />
                <font color="#f626d7">
                  ৫. ভারতে ২০১১ খ্রিস্টাব্দের জনগণনা অনুযায়ী কোন্ রাজ্যের
                  জনঘনত্ব সর্বনিম্ন?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                অরুণাচলপ্রদেশ।
                <br />
                <br />
                <font color="#f626d7">
                  ৬. যে ভূগােলে জনসংখ্যা নিয়ে আলােচনা করা হয়, তাকে কী বলে?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                জনসংখ্যা ভূগােল।
                <br />
                <br />
                <font color="#f626d7">৭. শারীরবৃত্তীয় ঘনত্ব কী?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                প্রতি বর্গএকক কৃষিজমিতে জনসংখ্যার পরিমাণকে শারীরবৃত্তীয় জনঘনত্ব
                বলা হয়।
                <br />
                <br />
                <font color="#f626d7">৮. কৃষি ঘনত্ব কী?*</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                প্রতি বর্গ একক কৃষিজমিতে কৃষির উপর শুধুমাত্র নির্ভরশীল জনসংখ্যার
                পরিমাণ কৃষি ঘনত্ব নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  ৯. ভারতের দুটি অতি নিবিড় জনবসতির নাম লেখাে।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                পশ্চিমবঙ্গ এবং বিহার।
                <br />
                <br />
                <font color="#f626d7">
                  ১০. পৃথিবীর দুটি জনবিরল দেশের নাম লেখাে।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                গ্রিনল্যান্ড, সুইডেন।
                <br />
                <br />
                <font color="#f626d7">
                  ১১. ভারতের দুটি স্বল্প জনবসতিযুক্ত অঞ্ছলের নাম লেখাে৷
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                মেঘালয় এবং রাজস্থান।
                <br />
                <br />
                <font color="#f626d7">
                  ১৫. ভারতের সর্বাধিক জনবহুল কেন্দ্রশাসিত অঞ্চল কোনটি?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                দিল্লি।
                <br />
                <br />
                <font color="#f626d7">
                  ১২. ভারতের সবচেয়ে জনবিরল কেন্দ্রশাসিত অঞ্চল কোনটি?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                লাক্ষা ও মিনিকয় দ্বীপপুঞ্জ।
                <br />
                <br />
                <font color="#f626d7">
                  ১৩. ভারতের কেন্দ্রশাসিত রাজ্যগুলির মধ্যে জনঘনত্ব সবচেয়ে কম
                  কোথায়?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                আন্দামান ও নিকোবর দ্বীপপুঞ্জে।
                <br />
                <br />
                <font color="#f626d7">
                  ১৪. জনসংখ্যার নিরিখে ভারতের স্থান পৃথিবীতে কত?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                দ্বিতীয়।
                <br />
                <br />
                <font color="#f626d7">
                  ১৫. বিশ্বের সর্বাধিক জনবহুল মহাদেশ কোনটি?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                এশিয়া।
                <br />
                <br />
                <font color="#f626d7">
                  ১৬. জনসংখ্যার ভিত্তিতে ভারতের বৃহত্তম শহরটির নাম লেখাে।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                মুম্বাই।
                <br />
                <br />
                <font color="#f626d7">
                  ১৭. মানুষ জমি অনুপাত|মানুষ-জমি অনুপাতের সংজ্ঞা দাও
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কোনাে দেশ বা অঞ্চলে বসবাসকারী মােট কর্মদক্ষতাসম্পন্ন জনসংখ্যা ও
                দেশের বা অঞ্চলের মােট কার্যকর জমির অনুপাতকেই বলা হয় মানুষ জমি
                অনুপাত”।
                <br />
                <br />
                <font color="#f626d7">
                  ১৮. আদর্শ মানুষ-জমি অনুপাতকে কী বলে?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কাম্য জনসংখ্যা।
                <br />
                <br />
                <font color="#f626d7">
                  ১৯. জনসংখ্যার প্রকৃত বৃদ্ধি কীভাবে নির্ণয় করা যায়?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                মানুষ-জমি অনুপাত দেখে।
                <br />
                <br />
                <font color="#f626d7">
                  ২০. মানুষ-জমি অনুপাত ও পরিব্রাজনের সম্পর্ক লেখাে।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                মানুষ-জমি অনুপাত ও পরিব্রাজনের একে অপরের সঙ্গে সমানুপাতিক
                সম্পর্ক বর্তমান।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">২১. ‘অতি জনাকীর্ণতা’ বলতে কী বােঝাে?*</font>
              <br />
              <font color="#29900a">উত্তর : </font>{" "}
              <font color="#3c92e5">
                কোনাে একটি দেশে উপস্থিত প্রাকৃতিক সম্পদের তুলনায় ওই দেশের
                জনসংখ্যা অত্যধিক বেশি হলে, সেই অবস্থাকে ওই দেশের অতি জনাকীর্ণতা
                বলে।
                <br />
                <br />
                <font color="#f626d7">
                  ২২. জনাকীর্ণ বা জনাধিক্যতা কাকে বলে?{" "}
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কোনাে স্থান বা দেশের জনসংখ্যা যখন সেখানে প্রাপ্ত সম্পদের তুলনায়
                বেশি হয় অর্থাৎ কাম্য জনসংখ্যার চেয়ে অধিক জনসংখ্যা হলে তখন তাকে
                জনাকীর্ণতা বা জনধিক্যতা বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  ২৩. কাম্য জনসংখ্যা থেকে অধিক জনসংখ্যাকে কী বলে?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font> জনাকীর্ণতা।
                <br />
                <br />
                <font color="#f626d7">২৪. জমির বহনক্ষমতার প্রধান সূচক কী?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                জনসংখ্যা।
                <br />
                <br />
                <font color="#f626d7">
                  ২৫. কাম্য জনসংখ্যার গতি সর্বদা কীরূপ হয়?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font> স্থিতিশীল।
                <br />
                <br />
                <font color="#f626d7">২৬. জনবিস্ফোরণ কাকে বলে?*</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কোনাে দেশের জন্মহার-এর তুলনায় মৃত্যুহার হঠাৎ করে দ্রুত হারে কমে
                গেলে ওই দেশের জনসংখ্যা দ্রুত বৃদ্ধি পেতে থাকে। জনসংখ্যার এইরূপ
                দ্রুত বৃদ্ধিকেই বলা হয় জনবিস্ফোরণ। উদাহরণ—ভারত, বাংলাদেশ।
                <br />
                <br />
                <font color="#f626d7">
                  ২৭. জনসংখ্যাবৃদ্ধির কোন অবস্থাকে জনবিস্ফোরণ বলা হয়?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কাম্য জনসংখ্যা অতিক্রম করলে।
                <br />
                <br />
                <font color="#f626d7">২৮. জনসংখ্যা অভিক্ষেপ কাকে বলে?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                বর্তমান জনসংখ্যার কাঠামাে, জন্মহার, মৃত্যুহার, অভিবাসন প্রভৃতির
                উপর নির্ভর করে। ভবিষ্যৎ জনসংখ্যা সম্পর্কে যে পূর্বানুমান করা,
                হয় তাকেই অভিক্ষেপ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  ২৯. প্রাকৃতিক সম্পদের তুলনায় জনসংখ্যা অধিক হলে তাকে কী বলে?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                জনাকীর্ণতা।
                <br />
                <br />
                <font color="#f626d7">৩০. কাম্য জনসংখ্যা কাকে বলে?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কোনাে দেশের মােট সম্পদের উৎপাদন ও জোগানের সঙ্গে দেশের জনসংখ্যা
                যদি সামঞ্জস্যপূর্ণ হয়, তবে তাকে কাম্য জনসংখ্যা বলে। অর্থাৎ
                মানুষ-জমি অনুপাতের আদর্শ অবস্থাই হরে কাম্য জনসংখ্যা।
                <br />
                <br />
                <font color="#f626d7">
                  ৩১. ভারতের আগামী আদমশুমারি কবে সম্পন্ন হবে?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ২০২১ খ্রিস্টাব্দে।
                <br />
                <br />
                <font color="#f626d7">
                  ৩২. জনসংখ্যার পরিবর্তনের প্রধান উপাদানগুলি কী কী?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                প্রজনন, মরণশীলতা, পরিব্রাজন।
                <br />
                <br />
                <font color="#f626d7">৩৩. পরিব্রাজন কাকে বলে?**</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                প্রাকৃতিক, সামাজিক, রাজনৈতিক, অর্থনৈতিক ইত্যাদি কারণে মানুষ যখন
                স্থান পরিবর্তন করে একস্থান থেকে অন্যস্থানে সাময়িকভাবে বা দীর্ঘ
                সময়ের জন্য গমন করে, তখন তাকে পরিব্রাজন বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  ৩৪. The Dictionary of Human Geography' speito রচনা করেন?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                জনস্টন।
                <br />
                <br />
                <font color="#f626d7">
                  ৩৫. জনসংখ্যা বণ্টনের একটি প্রাকৃতিক কারণ উল্লেখ করাে।*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                খনিজ সম্পদের প্রাচুর্য, জনসংখ্যা বণ্টনের ক্ষেত্রে গুরুত্বপূর্ণ
                ভূমিকা পালন করে।
                <br />
                <br />
                <font color="#f626d7">৩৬. জন্মহার কাকে বলে?*</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কোনাে দেশের প্রতি বছর প্রতি এক হাজার জন মানুষ পিছু যত জন জীবন্ত
                শিশুর জন্ম হয়, তার অনুপাতই হল জন্মহার।
                <br />
                <br />
                <font color="#f626d7">
                  ৩৭. জনসংখ্যা বিবর্তন তত্ত্বের মূল কথা কী?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কোনাে দেশের জনসংখ্যার ধারাবাহিক পরিবর্তন সর্বদাই ওই দেশের
                জন্মহার এবং মৃত্যুহার দ্বারা ব্যাপক হারে নির্ধারিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  ৩৮. ভারতের দ্রুত জনসংখ্যাবৃদ্ধির কারণ লেখাে, যে-কোনাে দুটি।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                (১) শিক্ষা ও সচেতনতার অভাব এবং (২) অল্প বয়সে বিবাহ।
                <br />
                <br />
                <font color="#f626d7">
                  ৩৯. সম্পদের তুলনায় জনসংখ্যা বেশি হলে জীবনযাত্রার মান কেমন
                  হয়?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                অবনত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  ৪০. পার্বত্য অঞলে ঋতুভিত্তিক পরিব্রাজনকে কী বলে?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ট্রান্স-হিউম্যান্স।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                ৪১. জনসংখ্যা বিবর্তন তত্ত্বের মূল কথা কী?
              </font>
              <br />
              <font color="#29900a">উত্তর : </font>{" "}
              <font color="#3c92e5">
                কোনাে দেশের জনসংখ্যার ধারাবাহিক পরিবর্তন সর্বদাই ওই দেশের
                জন্মহার এবং মৃত্যুহার দ্বারা ব্যাপক হারে নির্ধারিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  ৪২. ভারতের দ্রুত জনসংখ্যাবৃদ্ধির কারণ লেখাে, যে-কোনাে দুটি।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                (১) শিক্ষা ও সচেতনতার অভাব এবং (২) অল্প বয়সে বিবাহ।
                <br />
                <br />
                <font color="#f626d7">
                  ৪৩. সম্পদের তুলনায় জনসংখ্যা বেশি হলে জীবনযাত্রার মান কেমন
                  হয়?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                অবনত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  ৪৪. শূন্য জনসংখ্যাবৃদ্ধি’ কাকে বলে?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                জনসংখ্যার বৃদ্ধির স্থিতিশীল অবস্থাকে শূন্য জনসংখ্যাবৃদ্ধি বলে।
                এই সময় মােট পরিব্রাজন-সহ মােট জন্মহার ও মােট মৃত্যুহার-এর
                অন্তরফল শূন্য হয়। জনবিবর্তনের প্রথম ও চতুর্থ পর্যায়ে শূন্য
                জনসংখ্যাবৃদ্ধি’ দেখা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  ৪৫. জন্মহার মৃত্যুহার অপেক্ষা কম হলে বয়ঃলিঙ্গ পিরামিডের আকৃতি
                  কেমন হয়?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ব্যারেল আকৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  ৪৬. জনসংখ্যা বিবর্তন তত্ত্ব অনুসারে ভারত বর্তমানে কোন
                  পর্যায়ের?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font> দ্বিতীয় পর্যায়ের।
                <br />
                <br />
                <font color="#f626d7">
                  ৪৭. লিঙ্গানুপাত (Sex ratio) বলতে কী বােঝাে?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কোনাে স্থানের মােট জনসংখ্যার কত জন নারী ও কতজন পুরুষ সেই সংখ্যার
                অনুপাতকেই বলা হয় লিঙ্গানুপাত (Sex ratio)।
                <br />
                <br />
                <font color="#f626d7">
                  ৪৮. কোন্ দেশে মানুষের গড় আয়ু সবচেয়ে কম?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font> আফগানিস্তানে (৪৪.৬ বছর)।
                <br />
                <br />
                <font color="#f626d7">
                  ৪৯. ভারতের সর্বাধিক লিঙ্গ অনুপাত দেখা যায় কোন্ রাজ্যে?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কেরলে (১০৮৪:১০০০)।
                <br />
                <br />
                <font color="#f626d7">৫০. জনসংখ্যার বৃদ্ধি কাকে বলে?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কোনাে দেশের জমির বহনক্ষমতা অপেক্ষা জনসংখ্যা বেশি হলে ও বছর বছর
                তা বাড়তে থাকলে তাকে বলে জনসংখ্যার বৃদ্ধি।
                <br />
                <br />
                <font color="#f626d7">
                  ৫১. ভারতের মেগাসিটির ন্যূনতম জনসংখ্যা কত?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ১ কোটির বেশি।
                <br />
                <br />
                <font color="#f626d7">
                  ৫২. জনসংখ্যার ভিত্তিতে পৃথিবীর বৃহত্তম শহরটি কী?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                টোকিও।
                <br />
                <br />
                <font color="#f626d7">
                  ৫৩. মেগালােপলিস শব্দটির ব্যবহার পেট্রিক গেডেস এবং জাঁ গটম্যান
                  কবে করেন?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                পেট্রিক গেডেস ১৯১৮ খ্রিস্টাব্দে। জাঁ গটম্যান ১৯৬১
                খ্রিস্টাব্দে(Cities in Evolution গ্রন্থে)।
                <br />
                <br />
                <font color="#f626d7">
                  ৫৪. দিল্লি ও মুম্বাইতে কী জাতীয় পৌরবসতি দেখতে পাওয়া যায়?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                মেট্রোপলিস।
                <br />
                <br />
                <font color="#f626d7">৫৫. পলিস কাকে বলে?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ইয়ােপলিসের থেকে আকার ও জনসংখ্যায় বৃহৎ পৌর বসতিকে বলা হয় পলিস।
                <br />
                <br />
                <font color="#f626d7">৫৬. ওমেগাসিটি কাকে বলে?</font>
                <br />
                <font color="#29900a">উত্তর : </font> যেসকল মহানগরের জনসংখ্যা ৫০
                লক্ষ বা ১ কোটির বেশি ও জনঘনত্ব অত্যধিক ও অর্থনৈতিক দিক থেকে
                উন্নত তাদের বলা হয় মেগাসিটি।
                <br />
                <br />
                <font color="#f626d7">
                  ৫৭. ভারতের কোন্ কেন্দ্রশাসিত অঞ্চলে নগরায়ণের হ্যর সর্বাধিক?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font> দিল্লি।
                <br />
                <br />
                <font color="#f626d7">
                  ৫৮. ভারতের একটি আধুনিক শহরের উদাহরণ দাও।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                চণ্ডীগড়।
                <br />
                <br />
                <font color="#f626d7">
                  ৫৯. ভারতের একটি দ্বৈত নগরের উদাহরণ দাও।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কলকাতা-হাওড়া।
                <br />
                <br />
                <font color="#f626d7">
                  ৬০. ভারতের একটি প্রশাসনিক শহরের উদাহরণ দাও।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                দিল্লি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">৬১. শিল্প শহর কাকে বলে?</font>
              <br />
              <font color="#29900a">উত্তর : </font>{" "}
              <font color="#3c92e5">
                কলকারখানা বা শিল্পকে কেন্দ্র করে যে পৌরবসতি গড়ে ওঠে,তাকে বলা
                হয় শিল্প শহর। যেমন— ভারতের জামশেদপুর।
                <br />
                <br />
                <font color="#f626d7">৬২. দুটি ধর্ম শহরের উদাহরণ দাও।*</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                মথুরা, বৃন্দাবন।
                <br />
                <br />
                <font color="#f626d7">
                  ৬৩. ভারতের বৃহত্তম মেট্রোপলিটন শহরটির নাম লেখ।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                NCR, দিল্লি।
                <br />
                <br />
                <font color="#f626d7">৬৪. একটি নেক্রোপলিসের উদাহরণ দাও।</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ব্যাবিলন। <br />
                <br />
                <font color="#f626d7">৬৫. যমজ শহর কাকে বলে?*</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                পৌর এলাকার একত্রীভবনের ফলে সৃষ্ট দুটি শহর পাশাপাশি অবস্থান করলে
                তাদের একত্রে যমজ শহর বলে। উদাহরণ- হায়দরাবাদ ও সেকেন্দ্রাবাদ।
                <br />
                <br />
                <font color="#f626d7">৬৬. জনবসতি কাকে বলে?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কোনাে নির্দিষ্ট এলাকাতে এক বা একাধিক ভৌগােলিক পরিবেশের আনুকূল্যে
                একাধিক মানুষের যে আবাসস্থল গড়ে ওঠে, তাকে বলা হয় জনবসতি।
                <br />
                <br />
                <font color="#f626d7">
                  ৬৭. গ্রামীণ বসতির প্রধান উপজীবিকা কী?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কৃষিকাজ।
                <br />
                <br />
                <font color="#f626d7">
                  ৬৮. গ্রামীণ ও পৌর বসতির মধ্যে কোটি প্রাচীন?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                গ্রামীণ বসতি।
                <br />
                <br />
                <font color="#f626d7">৬৯. হ্যামলেট কাকে বলে?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                প্রধান গ্রামের অংশ থেকে বিচ্ছিন্ন, প্রায় ২০-২৫টি বাড়ি
                নিয়ে,নিম্নবর্গ বা উচ্চ নিম্নবিত্ত সম্প্রদায়কে নিয়ে গড়ে ওঠা
                ছােটো পাড়াই হল হ্যামলেট।
                <br />
                <br />
                <font color="#f626d7">৭০. সবুজ গ্রাম কী?*</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                বনভূমির মধ্যে কোনাে ফাঁকা স্থানে গড়ে ওঠা ক্ষুদ্রাকার গ্রাম
                বসতিকেই বলা হয় সবুজ গ্রাম।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">৭১. কতকগুলি জেলার সম্মিলিত একক কী?</font>
              <br />
              <font color="#29900a">উত্তর : </font>{" "}
              <font color="#3c92e5">
                রাজ্য।
                <br />
                <br />
                <font color="#f626d7">
                  ৭২. কতকগুলি ব্লক বা থানা নিয়ে কী গঠিত হয়?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                মহকুমা।
                <br />
                <br />
                <font color="#f626d7">৭৩. ক্যারাভান সরাই কাকে বল?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                বণিক এবং পথিকদের রাত্রিবাসের জন্য মরুভূমির মধ্য দিয়ে প্রসারিত
                পথের ধারে নির্মিত গ্রাম্য বসতিকেই বলা হয় ক্যারাভান সরাই।
                <br />
                <br />
                <font color="#f626d7">৭৪. জলবিন্দু বসতি’ কাকে বলে?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                শুষ্ক অঞ্চলে জলের উৎসকে (প্লায়া) কেন্দ্র করে গড়ে ওঠা বসতিকে
                বলা হয় আর্দ্রবিন্দু বসতি বা জলবিন্দু বসতি। যেমন— মরুভূমিতে
                মরুদ্যানকে কেন্দ্র করে বসতি।
                <br />
                <br />
                <font color="#f626d7">৭৫. শুষ্ক বিন্দু বসতি কাকে বলে?*</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                নদী অববাহিকায় বন্যাকবলিত অঞ্চলে মানুষজন বন্যার জল পৌছােয় না
                এমন উঁচু ও শুষ্ক জায়গায় তাদের বসতি গড়ে তােলে। এই বসতিকেই বলে
                শুষ্ক বিন্দু বসতি।
                <br />
                <br />
                <font color="#f626d7">
                  ৭৬. দুটি রাস্তার সংযােগস্থলে কী ধরনের বসতি গড়ে ওঠে?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                L বা T আকৃতির বসতি।
                <br />
                <br />
                <font color="#f626d7">
                  ৭৭. রাস্তার ধার বরাবর কোন্ ধরনের জনবসতি সাধারণত গড়ে ওঠে?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                দণ্ডকার বসতি।
                <br />
                <br />
                <font color="#f626d7">
                  ৭৮. পার্বত্য অঞ্চলে সাধারণত কোন্ ধরনের জনবসতি গড়ে ওঠে?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                বিক্ষিপ্ত জনবসতি
                <br />
                <br />
                <font color="#f626d7">
                  ৭৯. রাস্তার ধার বরাবর কোন্ ধরনের জনবসতি সাধারণত গড়ে ওঠে?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                দণ্ডকার বসতি।
                <br />
                <br />
                <font color="#f626d7">
                  ৮০. পার্বত্য অঞ্চলে সাধারণত কোন্ ধরনের জনবসতি গড়ে ওঠে?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                বিক্ষিপ্ত জনবসতি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                ৮১. সারিবদ্ধভাবে অবস্থিত অনেকগুলি বাসগৃহকে একত্রে কী বলে?
              </font>
              <br />
              <font color="#29900a">উত্তর : </font>{" "}
              <font color="#3c92e5">
                রৈখিক জনবসতি।
                <br />
                <br />
                <font color="#f626d7">
                  ৮২. নদীর বদ্বীপ অঞলে কোন্ ধরনের গ্রামীণ বসতি দেখা যায়?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                পিণ্ডাকৃতির বসতি।
                <br />
                <br />
                <font color="#f626d7">
                  ৮৩. নিম্ন গাঙ্গেয় সমভূমি অঞ্চলে কোন্ প্রকার গ্রামীণ বসতি বেশি
                  দেখা যায়?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                রৈখিক জনবসতি।
                <br />
                <br />
                <font color="#f626d7">
                  ৮৪. সামাজিক নৈকট্য কোন্ ধরনের জনবসতি গড়ে তােলে?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                গােষ্ঠীবদ্ধ জনবসতি।
                <br />
                <br />
                <font color="#f626d7">৮৫. রৈখিক জনবসতি কাকে বলে?*</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                সড়কপথ, রেলপথ, নদী, খাল প্রভৃতির সমান্তরালে সারিবদ্ধভাবে গড়ে
                ওঠা জনবসতিকে বলা হয় রৈখিক জনবসতি।
                <br />
                <br />
                <font color="#f626d7">৮৬. Rurban কাকে বলে?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                শহরের সীমানার বাইরে গ্রামীণ ও পৌর বসতির সংমিশ্রণে বসতি যার
                জনবসতির ঘনত্ব, জনসংখ্যা ও জীবিকাসমূহ শহরের মতাে এবং প্রশাসনিক
                দিকটি গ্রামের মতাে, তাকে বলে। Rurban বা গ্রাম-শহর।
                <br />
                <br />
                <font color="#f626d7">৮৭. ‘পৌরপুঞ্জ’-এর সংজ্ঞা দাও।</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                একসঙ্গে লাগােয়া একগুচ্ছ শহরকে একত্রিতভাবে পৌরপুঞ্জ বলা হয়। এটি
                প্রয়ােগ করেছিলেন প্রথম প্যাট্রিক গেডেস।
                <br />
                <font color="#29900a">উদাহরণ : </font>
                কলকাতা পৌরপুঞ্জের অন্তর্গত হুগলি নদীর দু-পাশের শহরগুলাে।
                <br />
                <br />
                <font color="#f626d7">
                  ৮৮. ভারতে ১০ লক্ষ বা তার বেশি জনসংখ্যাবিশিষ্ট নগরকে কী বলে?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                মহানগর।
                <br />
                <br />
                <font color="#f626d7">
                  ৮৯. ভারতের আদমশুমারি অনুযায়ী পৌর বসতিকে ক-টি ভাগে ভাগ করা
                  যায়?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ৬টি।
                <br />
                <br />
                <font color="#f626d7">
                  ৯০. লুই মামফোর্ড-এর মতে, অতিক্ষুদ্র নগরবসতিকে কী বলা হয়?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ইয়ােপলিস।
                <br />
                <br />
                <font color="#f626d7">
                  ৯১. Rurban শব্দটি প্রথম কে ব্যবহার করেন?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                সি জে গ্যালপিন, ১৯১৮ খ্রিস্টাব্দে।
                <br />
                <br />
                <font color="#f626d7">
                  ৯২. ভারতের প্রথম শ্রেণির শহরের লােকসংখ্যা কত?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কমপক্ষে ১ লক্ষ বা তার বেশি।
                <br />
                <br />
                <font color="#f626d7">৯৩. উপনগর কী?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                প্রধান নগর বা মহানগরের অতিরিক্ত জনসংখ্যার চাপ কমানাের জন্য
                প্রধান শহরের নিকটে পরিকল্পিতভাবে গঠিত সর্বাধুনিক সুবিধাযুক্ত
                ক্ষুদ্র শহরকেই উপনগর বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ১. ভারতে অত্যধিক জনঘনত্বযুক্ত অঞ্চলগুলির বণ্টন আলোচনা করো।
                  পশ্চিমবঙ্গের জনঘনত্ব বেশি হওয়ার কারণ কী?{" "}
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>ভারতে অত্যধিকঘনত্বযুক্ত অঞ্চলগুলির বণ্টন : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                <br />
                <br />
                <font color="#f626d7">
                  <b>(ক) আঞ্চলিক বিস্তৃতি : </b>{" "}
                </font>{" "}
                পশ্চিমবঙ্গ, বিহার, কেরল উত্তরপ্রদেশ প্রভৃতি রাজ্য এবং
                কেন্দ্রশাসিত অঞ্চলগুলির মধ্যে দিল্লি, চণ্ডীগড়, পুদুচেরি,
                দমন-দিউ ওলাক্ষা দ্বীপপুঞ্জ এই জনঘনত্ব অঞ্চলের অন্তর্গত। এই
                অঞ্চলের জনঘনত্ব প্রতি বর্গকিমিতে ৪০০ জনের বেশি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(খ) অত্যধিক জনঘনত্বের কারণ : </b>{" "}
                </font>{" "}
                <br />
                <br />
                (i) গাঙ্গেয় সমভূমি অঞ্চলে অবস্থিত হওয়ায় পশ্চিমবঙ্গের মাটি খুব
                উর্বর। গঙ্গা নদীর উভয় তীরে ভারতের প্রাচীনতম ও গুরুত্বপূর্ণহুগলি
                শিল্পাঞ্চল অবস্থিত হওয়ায় শিল্পের প্রয়োজনে পার্শ্ববর্তী অঞ্চল
                থেকে প্রচুর লোকের আগমন ঘটেছে। এ ছাড়া, বাংলাদেশ থেকে দলে দলে
                শরণার্থী এদেশে আসায় পশ্চিমবঙ্গের সীমান্তবর্তী জেলাগুলিতে
                জনঘনত্ব বহুগুণ বেড়েছে। <br />
                <br />
                (ii) কেরলের উপকূলবর্তী পলিমাটি অঞ্চল উর্বর হওয়ায় কৃষিকাজে বেশ
                উন্নত। এরই পার্বত্য অংশে প্রচুর বাগিচা ফসল উৎপন্ন হয় এবং সমুদ্র
                থেকে প্রচুর মাছ ধরার সুবিধা থাকায় এই অঞ্চলের জনঘনত্ব বেশি।{" "}
                <br />
                <br />
                (iii) উত্তরপ্রদেশ ও বিহার উর্বর পলিসমৃদ্ধ মধ্যগাঙ্গেয় সমভূমিতে
                অবস্থিত হওয়ায় কৃষিজ ফসল উৎপাদনে যথেষ্ট উন্নত এবং এই রাজ্যে
                জন্মহার খুব বেশি। তাই, এই রাজ্যের জনঘনত্বও বেশি। <br />
                <br />
                (iv) দিল্লি, চণ্ডীগড়, পুদুচেরি কেন্দ্রশাসিত অঞ্চল হওয়ায়
                শাসনতন্ত্র পরিচালনা, শিল্প ও বাণিজ্যের কারণে শহরে পরিণত হয়েছে।
                তাই, স্বাভাবিকভাবেই এই সমস্ত অঞ্চলের জনঘনত্ব বেশি।
                <br />
                <br />
                <font color="#29900a">
                  <b>পশ্চিমবঙ্গে জনবসতির ঘনত্ব অত্যন্ত বেশি হওয়ার কারণ : </b>{" "}
                </font>{" "}
                পশ্চিমবঙ্গ ভারতের দ্বিতীয় সর্বাধিক ঘনবসতিপূর্ণ রাজ্য। 2011
                সালের আদমশুমারি অনুযায়ী এই রাজ্যে প্রতি বর্গকিলােমিটারে 1029 জন
                লােক বসবাস করে, এর কারণগুলি হল — <br />
                <br />
                <font color="#f626d7">
                  <b>1. সমতল ভূপ্রকৃতি : </b>{" "}
                </font>{" "}
                পশ্চিমবঙ্গের বিস্তীর্ণ অঞ্চল নিম্ন গঙ্গা সমভূমির অন্তর্গত বলে
                কৃষিকাজ, পরিবহণ ব্যবস্থা এবং শিল্পে যথেষ্ট উন্নত। <br />
                <br />
                <font color="#f626d7">
                  <b>2. অনুকূল জলবায়ু : </b>{" "}
                </font>{" "}
                রাজ্যটি ক্রান্তীয় মৌসুমি জলবায়ু দ্বারা প্রভাবিত বলে উষ্ণতা ও
                বৃষ্টিপাত উভয়ই মধ্যম প্রকৃতির। এইরূপ জলবায়ু কৃষিকাজ ও
                অধিবাসীদের জীবনধারণের পক্ষে অনুকূল। <br />
                <br />
                <font color="#f626d7">
                  <b>3. উর্বর মৃত্তিকা : </b>{" "}
                </font>{" "}
                পশ্চিমবঙ্গের অধিকাংশ এলাকা গঙ্গা নদীর পলি দ্বারা গঠিত বলে
                এখানকার মৃত্তিকা খুব উর্বর প্রকৃতির। এই মৃত্তিকায় কৃষিকাজ খুব
                ভালাে হয়। <br />
                <br />
                <font color="#f626d7">
                  <b>4. উন্নত পরিবহণ ব্যবস্থা : </b>{" "}
                </font>{" "}
                এই রাজ্যের অধিকাংশ এলাকার ভূমি সমতল বলে এখানে সড়কপথ ও রেলপথে
                পরিবহণ ব্যবস্থার প্রভূত উন্নতি ঘটেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. বন্দরের অবস্থান : </b>{" "}
                </font>{" "}
                কলকাতা ও হলদিয়া বন্দরের কল্যাণে বাণিজ্যিক তথা অর্থনৈতিক
                ক্ষেত্রে বিশেষ সমৃদ্ধি ঘটেছে। <br />
                <br />
                <font color="#f626d7">
                  <b>6. খনিজ সম্পদে সমৃদ্ধ : </b>{" "}
                </font>{" "}
                পশ্চিমবঙ্গ খনিজ সম্পদে (রানিগঞ্জের কয়লা, বাঁকুড়ার চিনামাটি,
                ঝালদার চুনাপাথর, তামা, সিসা, ম্যাঙ্গানিজ প্রভৃতি) মােটামুটিভাবে
                একটি সমৃদ্ধ রাজ্য।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ২. জনঘনত্বের ভিত্তিতে পৃথিবীর আঞ্চলিক শ্রেণিবিভাগ করাে এবং
                  অঞ্চলগুলির বর্ণনা দাও।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>জনঘনত্ব অনুযায়ী ভারতের আঞ্চলিক বিভাগ : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                গড় জনঘনত্বের তারতম্য অনুসারে ভারতকে পাঁচটি অঞ্চলে ভাগ করা যায়
                — <br />
                [1] অত্যধিক জনঘনত্ব অঞ্চল, <br />
                [2] অধিক জনঘনত্ব অঞ্চল, <br />
                [3] মধ্যম জনঘনত্ব অঞ্চল, <br />
                [4] স্বল্প জনঘনত্ব অঞ্চল এবং [5] অতি-স্বল্প জনঘনত্ব অঞ্চল।
                <br />
                <br />
                <font color="#29900a">
                  <b>[1] অত্যধিক জনঘনত্ব অঞ্চল : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>আঞ্চলিক বিস্তৃতি : </b>{" "}
                </font>{" "}
                পশ্চিমবঙ্গ, বিহার, কেরল উত্তরপ্রদেশ প্রভৃতি রাজ্য এবং
                কেন্দ্রশাসিত অঞ্চলগুলির মধ্যে দিল্লি, চণ্ডীগড়, পুদুচেরি,
                দমন-দিউ ও লাক্ষা দ্বীপপুঞ্জ এই জনঘনত্ব অঞ্চলের অন্তর্গত। এই
                অঞ্চলের জনঘনত্ব প্রতি বর্গকিমিতে 800 জনের বেশি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>অত্যধিক জনঘনত্বের কারণ : </b>{" "}
                </font>
                (i) গাঙ্গেয় সমভূমি অঞ্চলে অবস্থিত হওয়ায় পশ্চিমবঙ্গের মাটি খুব
                উর্বর। গঙ্গা নদীর উভয় তীরে ভারতের প্রাচীনতম ও গুরুত্বপূর্ণ
                শিল্পাঞ্চল অবস্থিত হওয়ায় শিল্পের প্রয়ােজনে পার্শ্ববর্তী অঞ্চল
                থেকে প্রচুর লােকের আগমন ঘটেছে। এ ছাড়া, বাংলাদেশ থেকে দলে দলে
                শরণার্থী এদেশে আসায় পশ্চিমবঙ্গের সীমান্তবর্তী জেলাগুলিতে
                জনঘনত্ব বহুগুণ বেড়েছে। <br />
                <br />
                (ii) কেরলের উপকূলবর্তী পলিমাটি অঞ্চল উর্বর হওয়ায় কৃষিকাজে বেশ
                উন্নত। এরই পার্বত্য অংশে প্রচুর বাগিচা ফসল উৎপন্ন হয় এবং সমুদ্র
                থেকে প্রচুর মাছ। ধরার সুবিধা থাকায় এই অঞ্চলের জনঘনত্ব বেশি।{" "}
                <br />
                <br />
                (iii) উত্তরপ্রদেশ ও বিহার উর্বর পলিসমৃদ্ধ মধ্যগাঙ্গেয় সমভূমিতে
                অবস্থিত হওয়ায় কৃষিজ ফসল উৎপাদনে যথেষ্ট উন্নত এবং এই রাজ্যে
                জন্মহার খুব বেশি। তাই, এই রাজ্যের জনঘনত্বও বেশি। <br />
                <br />
                (iv) দিল্লি, চণ্ডীগড়, পুদুচেরি কেন্দ্রশাসিত অঞ্চল হওয়ায়
                শাসনতন্ত্র পরিচালনা, শিল্প ও বাণিজ্যের কারণে শহরে পরিণত হয়েছে।
                তাই, স্বাভাবিকভাবেই এই সমস্ত অঞ্চলের জনঘনত্ব বেশি।
                <br />
                <br />
                <font color="#29900a">
                  <b>[2] অধিক জনঘনত্ব অঞ্চল : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>আঞ্চলিক বিস্তৃতি : </b>{" "}
                </font>{" "}
                ঝাড়খণ্ড, পাঞ্জাব, হরিয়ানা ও তামিলনাড়ু রাজ্য এবং কেন্দ্রশাসিত
                দাদরা ও নগর হাভেলি অধিক জনঘনত্বযুক্ত অঞ্চলের অন্তর্গত। এই
                অঞ্চলের জনঘনত্ব প্রতি বর্গকিমিতে 401 থেকে 800 জন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>অধিক জনঘনত্বের কারণ : </b>{" "}
                </font>{" "}
                <br />
                <br />
                (i) উত্তরপ্রদেশ, পাঞ্জাব ও হরিয়ানা উর্বর পলিসমৃদ্ধ গাঙ্গেয়
                সমভূমিতে অবস্থিত। জলসেচ ব্যবস্থাও বেশ উন্নত। তাই, এই অঞ্চল
                কৃষিতে বেশ উন্নত। কৃষিভিত্তিক শিল্পেও এই অঞ্চল বেশ উন্নত। এ
                ছাড়াও কৃষি জমিতে কজের জন্য ভারতরে অন্যান্য রাজ্য থেকে প্রচুর
                শ্রমিক এখানে আসে। <br />
                <br />
                (ii) তামিলনাড়ুর কাবেরী অববাহিকা ও উপকূলীয় সমভূমি কৃষিতে বেশ
                উন্নত। মালভূমি অঞ্চল খনিজ সম্পদে সমৃদ্ধ হওয়ায় এই রাজ্যের
                জনঘনত্ব বেশ বেশি।
                <br />
                <br />
                <font color="#29900a">
                  <b>[3] মধ্যম জনঘনত্ব অঞ্চল : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>আঞ্চলিক বিস্তৃতি : </b>{" "}
                </font>{" "}
                অসম, ত্রিপুরা, ওডিশা, অবিভক্ত অপ্রদেশ, কর্ণাটক, গােয়া,
                মহারাষ্ট্র, মধ্যপ্রদেশ, গুজরাত ও রাজস্থান রাজ্যের জনঘনত্ব মধ্যম
                প্রকৃতির। এই অঞ্চলের জনঘনত্ব প্রতি বর্গকিমিতে 201 থেকে 400 জন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>মধ্যম জনঘনত্বের কারণ : </b>{" "}
                </font>{" "}
                <br />
                <br />
                (i) ওডিশা, অবিভক্ত অন্ধ্রপ্রদেশ ও কর্ণাটক রাজ্য সমভূমি ও
                মালভূমির সমন্বয়ে গঠিত সমভূমি কৃষিতে উন্নত এবং মালভূমি অঞ্চল
                খনিজ সম্পদে সমৃদ্ধ। <br />
                (ii) ঝাড়খণ্ড ভারতের খনিজ ভাণ্ডার হওয়ায় শিল্পে বেশ উন্নত।{" "}
                <br />
                (iii) অসম ও ত্রিপুরা রাজ্য বাগিচা কৃষিতে উন্নত। <br />
                (iv) মহারাষ্ট্র ও গুজরাত দুটি প্রথম সারির শিল্পোন্নত রাজ্য হলেও
                মহারাষ্ট্রের বিস্তীর্ণ অঞ্চল মালভূমির অন্তর্গত এবং গুজরাতের
                উত্তর ও পশ্চিম অংশ শুষ্ক মরুপ্রায় হওয়ায় জনঘনত্ব মধ্যম প্রকার।{" "}
                <br />
                (v) গােয়া পর্যটন শিল্পে বেশ উন্নত এবং সমুদ্র থেকে মাছ ধরার
                সুবিধা থাকায় এই অঞ্চলের জনঘনত্ব মাঝারি ধরনের।
                <br />
                <br />
                <font color="#29900a">
                  <b>[4] স্বল্প জনঘনত্ব অঞ্চল : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>আঞ্চলিক বিস্তৃতি : </b>{" "}
                </font>{" "}
                মেঘালয়, মণিপুর, নাগাল্যান্, হিমাচল প্রদেশ, উত্তরাখণ্ড,
                ছত্তিশগড় ও জম্মু ও কাশ্মীর—এই আটটি রাজ্য স্বল্প জনঘনত্বযুক্ত
                অঞ্চলের অন্তর্গত। এই অঞ্চলে প্রতি বর্গকিমিতে 101 থেকে 200 জন
                লােক বাস করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>স্বল্প জনঘনত্বের কারণ : </b>{" "}
                </font>{" "}
                <br />
                <br />
                i) মেঘালয়, মণিপুর, নাগাল্যান্ড, হিমাচল প্রদেশ ও উত্তরাখণ্ড
                দুর্গম পার্বত্য অঞ্চলে অবস্থিত। গভীর বনভূমি, প্রতিকূল জলবায়ু,
                অনুন্নত কৃষি ও শিল্পের কারণে এই অঞ্চলের জনঘনত্ব খুবই কম <br />
                (ii) ছত্তিশগড় মালভূমিতে অবস্থিত হওয়ায় এই অঞ্চলের ভূপ্রকৃতি
                বন্ধুর, মৃত্তিকা অনুর্বর ল্যাটেরাইট জাতীয় বলে কৃষিতে অনুন্নত।{" "}
                <br />
                (iii) রাজস্থান মরুভূমি অঞ্চলে অবস্থিত হওয়ায় স্বাভাবিকভাবেই
                জনঘনত্ব কম।
                <br />
                <br />
                <font color="#29900a">
                  <b>[5] অতি স্বল্প জনঘনত্ন অঞ্চল : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>আঞ্চলিক বিস্তৃতি : </b>{" "}
                </font>{" "}
                অরুণাচল প্রদেশ, মিজোরাম, সিকিম এবং কেন্দ্রশাসিত আন্দামান ও
                নিকোবর দ্বীপপুঞ্জ অতি-স্বল্প জনঘনত্বযুক্ত অঞ্চলের অন্তর্গত। এই
                অঞ্চলে প্রতি বর্গকিমিতে 100 জনের কম লােক বাস করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>অতি-স্বল্প জনঘনত্বের কারণ : </b>{" "}
                </font>{" "}
                <br />
                <br />
                (i) অরুণাচল প্রদেশ, মিজোরাম, সিকিম দুর্গম পার্বত্য অঞ্চলে
                অবস্থিত। এই অঞ্চলের পার্বত্য ভূভাগ, প্রতিকূল জলবায়ু, গভীর
                অরণ্য, অনুন্নত কৃষিপদ্ধতি প্রভৃতি কারণে জনঘনত্ব খুবই কম। অনুন্নত
                যােগাযােগ ব্যবস্থা এবং খনিজ সম্পদের অভাবে কুটির শিল্প ছাড়া এই
                অঞ্চলে তেমন কোনাে শিল্প গড়ে ওঠেনি। <br />
                <br />
                (ii) আন্দামান ও নিকোবর দ্বীপপুঞ্জ মূল ভূভাগ থেকে সম্পূর্ণভাবে
                বিচ্ছিন্ন। গভীর বনভূমি ও অস্বাস্থ্যকর উয় আর্দ্র জলবায়ুর জন্য
                এই অঞ্চলের জনবসতির ঘনত্ব খুবই কম (প্রতি বর্গকিমিতে 50 জনেরও কম)।
                গ্যাংটক, ইটানগর প্রভৃতি স্থান পর্যটন শিল্পে উন্নত হওয়ায়
                জনঘনত্ব কিছুটা বেশি।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

জনসংখ্যাওজনবসতি.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(জনসংখ্যাওজনবসতি);
