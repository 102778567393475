import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function বংশগতিরআণবিকভিত্তি() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          বংশগতির আণবিক ভিত্তি | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="বংশগতির আণবিক ভিত্তি | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                বংশগতির আণবিক ভিত্তি
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নীচের কোন্ জোড়টি সঠিক?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  রাইবোজাইম-নিউক্লিক অ্যাসিড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  TH Morgan – ট্রান্সডাকশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>G
                  Mendel –ট্রান্সফরমেশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  F2 × প্রচ্ছন্ন বৈশিষ্ট্যসম্পন্ন জনিতৃ –ডাইহাইব্রিড ক্রস
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  DNA-ই জিনগত বস্তু, যাঁদের পরীক্ষার মাধ্যমে সর্বোত্তম প্রমাণ
                  পাওয়া যায় —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Hershey ও Chase
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Avery, Mcleod ও Mccarty
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Griffith
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Hargobind Khorana
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নিম্নলিখিত কোন্‌গুলি DNA ও RNA-উভয় প্রাপ্ত পিউরিন বেস -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাইটোসিস এবং থাইমিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অ্যাডেনিন এবং গুয়ানিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গুয়ানিন এবং সাইটোসিস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাডেনিন এবং থাইমিন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নীচের কোন্‌টি Lac ওপেরনের ইনডিউসার হিসেবে প্রয়োজন?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ল্যাকটোজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ল্যাকটোজ ও গ্যালাকটোজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গ্লুকোজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গ্যালাকটোজ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নিম্নলিখিতগুলির কোন্‌টি চারগফের সূত্রানুযায়ী সঠিক?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>G = T
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>A = C
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>A + G = T + C
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  A+ T / G + C = 1
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>DNA খণ্ডকগুলি কী আধানযুক্ত?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তড়িৎ নিরপেক্ষ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আকারের ওপর ভিত্তি করে ধনাত্মক, নয় ঋণাত্মক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ঋণাত্মক তড়িতাধানযুক্ত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ধনাত্মক তড়িতাধানযুক্ত
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  কোন্ ধরনের অ্যামিনো অ্যাসিডকে একাধিক সংকেত (Code) দ্বারা
                  প্রকাশ করা যায়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দ্ব্যর্থহীন (unambiguous)
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ডিজেনারেট (degenerate)
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সূচনাকারী (initiation)
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সর্বজনীন (universal)
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  কোন্ ধরনের RNA প্রাণীকোশে সবচেয়ে বেশি সংখ্যায় থাকা উচিত?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  mRNA
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  tRNA
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  miRNA
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  rRNA
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  নিম্নলিখিতগুলির কোন্‌টি ট্রান্সক্রিপ্ট-এর বেস সজ্জা হবে, যদি
                  দ্বিতন্ত্রী DNA-এর কোডিং (coding) শৃঙ্খলে বেস সজ্জা 5 -
                  GTTCGAGTC-3 ́ হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  5' – CUGAGCUUG—3
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  5'-GUUCGAGUC-3'
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  5'– CCAGCUCAG– 3 ́
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  5' – GACUCGAAC-3 ́
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  DNA-এর অর্ধরক্ষণশীল প্রতিলিপিকরণ পদ্ধতির পরীক্ষালব্ধ প্রমাণ
                  পাওয়া যায় নিম্নলিখিত কোন্‌টিতে?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ছত্রাক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভাইরাস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ব্যাকটেরিয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উদ্ভিদ
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>ষষ্ঠ অধ্যায় - বংশগতির আণবিক ভিত্তি</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 1. ব্যাকটেরিয়ার Transduction বলতে কী বোঝ? </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                ব্যাকটেরিয়ার Transduction বলতে বোঝা যায় যে, এটি এমন একটি
                প্রক্রিয়া যার মাধ্যমে একটি ব্যাকটেরিয়ার জিনোমের একটি অংশ
                ব্যাকটেরিওফাজ বাহকের সাহায্যে অন্য একটি ব্যাকটেরিও কোশে
                স্থানান্তরিত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 2. স্বার্থপর DNA (Selfish DNA) কী? ট্রান্সপোজন কী? </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>স্বার্থপর DNA : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                স্বার্থপর DNA হল এমন DNA যা কোনো নির্দিষ্ট জিন ধারণ করে না,
                অর্থাৎ কোনো প্রোটিনের সংশ্লেষের জন্য কোড গঠন করে না, তবে এটি
                প্রতিলিপিকরণে অংশগ্রহণ করে। <br />
                <br />
                <font color="#1f7405">
                  <b>ট্রান্সপোজন </b>
                </font>{" "}
                যে DNA সিকোয়েন্স একই জিনোমের একটি অংশ থেকে অন্য অংশে
                স্থানান্তরিত হতে পারে, তাকে ট্রান্সপোজন বা মোবাইল DNA এলিমেন্ট
                (Mobile DNA element) বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 3. DNA-এর C-value বলতে কী বোঝ? </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>DNA-এর C-value : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                কোনো প্রজাতির হ্যাপ্লয়েড সেট ক্রোমোজোমের বা হ্যাপ্লয়েড জিনোমের
                যে পরিমাণ DNA বর্তমান তাকে C-value বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 4. Central Dogma কাকে বলে? </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>Central Dogma : </b>
              </font>
              <font color="#3c92e5">
                DNA, RNA এবং প্রোটিন—এই তিনটি জৈব অণুর মধ্যে পারস্পরিক কার্যকরী
                সম্পর্ক রয়েছে, যার মাধ্যমে জেনেটিক বার্তার প্রভাব রেপ্লিকেশন,
                ট্রান্সক্রিপশন এবং ট্রান্সলেশনের মাধ্যমে প্রদান হয়। এই
                সম্পর্কটি সাধারণভাবে "Central Dogma" নামে পরিচিত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 5. DNA প্যাকেজিং কাকে বলে? </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>DNA প্যাকেজিং : </b>
              </font>
              <font color="#3c92e5">
                DNA প্যাকেজিং বলতে এমন প্রক্রিয়া বোঝায় যা ধনাত্মক আধানযুক্ত
                ক্ষারীয় হিস্টোন প্রোটিনের সাথে ঋণাত্মক আধানযুক্ত DNA-এর
                সংযুক্তির মাধ্যমে দীর্ঘ DNA অণুকে কুণ্ডলীকৃত এবং ঘনীভূত করে,
                অর্থাৎ কোষের নিউক্লিয়াসের মধ্যে সম্প্রেষণ করে স্থানান্তরিত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 6. চ্যাপেরোন কী? </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>চ্যাপেরোন : </b>
              </font>
              <font color="#3c92e5">
                রাইবোজোম থেকে প্রোটিন সংশ্লেষের শেষে সদ্য মুক্তিপ্রাপ্ত
                পলিপেপটাইড শৃঙ্খল এককভাবে বা একাধিক পলিপেপটাইড শৃঙ্খলের সঙ্গে
                যুক্ত হয়, যেখানে বিশেষ প্রোটিন সহায়ক হয় নিজের প্রাথমিক গঠন
                থেকে সেকেন্ডারি, টার্শিয়ারী, অথবা কোয়াটারনারী প্রোটিন গঠনযুক্ত
                প্রোটিন সৃষ্টি করে, এই পদ্ধতিকে চ্যাপেরোন বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 7. জিনোম কাকে বলে? </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>জিনোম : </b>
              </font>
              <font color="#3c92e5">
                কোনো একটি নির্দিষ্ট প্রজাতির জীবের একটি হ্যাপ্লয়েড ক্রোমোজোম
                সেটে অবস্থিত সকল জিনগুলিকে একত্রে জিনোম বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 8. Human Genome Project বলতে কী বোঝ? </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>Human Genome Project : </b>
              </font>
              <font color="#3c92e5">
                মানুষের জিনোম গঠনকারী ক্রোমোসোমগুলিতে অবস্থিত DNA-এর
                নিউক্লিওটাইড সাজানো, জিনের সংখ্যা, জিনের বিন্যাস অর্থাৎ জিন
                ম্যাপিং-এর উদ্দেশ্যে যে আন্তর্জাতিক প্রকল্প বা Project গঠন করা
                হয়েছে, তাকে "হিউম্যান জিনোম প্রকল্প" (Human Genome Project,
                HGP) বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  {" "}
                  9. ব্যাকটেরিয়ার Transformation বা রূপান্তরভবন বলতে কী বোঝ?{" "}
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>Human Genome Project : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                Bacteria-এর Transformation প্রক্রিয়াতে একটি মৃত বা ধ্বংসপ্রাপ্ত
                ব্যাকটেরিয়ার জিনোমের একটি অংশ কোনো বাহকের সাহায্য ছাড়াই অপর
                কোনো সজীব ব্যাকটেরিয়া কোশে প্রবেশ করে এবং সেই সজীব
                ব্যাকটেরিয়ার জিনোমে অন্তর্ভুক্ত হয়, যার ফলস্বরূপ সেই সজীব
                ব্যাকটেরিয়ার নতুন বৈশিষ্ট্য প্রকাশিত হয়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

বংশগতিরআণবিকভিত্তি.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(বংশগতিরআণবিকভিত্তি);
