import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function ভূমিরূপগঠনকারীপ্রক্রিয়াসমূহবহিজাতপ্রক্রিয়াসমূহওসংশ্লিষ্টভূমিরূপ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          ভূমিরূপ গঠনকারী প্রক্রিয়াসমূহ : বহিজাত প্রক্রিয়াসমূহ ও সংশ্লিষ্ট
          ভূমিরূপ | West Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="ভূমিরূপ গঠনকারী প্রক্রিয়াসমূহ : বহিজাত প্রক্রিয়াসমূহ ও সংশ্লিষ্ট ভূমিরূপ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                ভূমিরূপ গঠনকারী প্রক্রিয়াসমূহ : বহিজাত প্রক্রিয়াসমূহ ও
                সংশ্লিষ্ট ভূমিরূপ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b>প্রথম অধ্যায়</b>
              </font>
              <br />
              <font color="#2b9a09" size="4">
                <b>ভূমিরূপ প্রক্রিয়া</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ৭ </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                1. ভূমিরূপ গঠনকারী প্রক্রিয়া বলতে কী বােঝ? পার্থিব প্রক্রিয়ার
                শ্রেণিবিভাগ করাে।
                <br />
                2. পর্যায়ন কী? বহির্জাত প্রক্রিয়ার পদ্ধতিগুলি আলােচনা করাে।
                <br />
                3. আবহবিকার কাকে বলে? যান্ত্রিক আবহবিকারের প্রক্রিয়াসমূহ
                আলােচনা করাে।
                <br />
                4. পর্যায়ন প্রক্রিয়ার মাধ্যমগুলি কী কী? যান্ত্রিক ও রাসায়নিক
                আবহবিকারের মধ্যে তুলনা করাে।
                <br />
                5. পর্যায়ন কাকে বলে? আরােহণ ও অবরােহণ পদ্ধতির পার্থক্য লেখাে।{" "}
                <br />
                6. ভৌমজল কাকে বলে? ভৌমজলের গুরুত্ব আলােচনা করাে।
                <br />
                7. শুষ্ক উপত্যকা ও অন্ধ উপত্যকার বৈশিষ্ট্যগুলি আলােচনা করাে।
                অ্যাকুইফার বলতে কী বােঝ এবং এটি কীভাবে সৃষ্টি হয়?
                <br />
                8. আর্টেজীয় কূপ কীভাবে সৃষ্টি হয় তা চিত্রসহযােগে বর্ণনা করাে।
                আর্তেজীয় কূপ থেকে পাম্পের সাহায্য ছাড়াই জল উঠে আসে’- কারণ
                নির্দেশ করাে। <br />
                9. গিজার কী? এর উৎপত্তির কারণ ব্যাখ্যা করাে।
                <br />
                10. জলচক্র কাকে বলে? মানবজীবনে কাস্ট ভূমিরূপের প্রভাব আলােচনা
                করাে।
                <br />
                11. নদীর দ্বারা সৃষ্ট কাস্ট ভূমিরূপ সম্পর্কে আলােচনা করাে।
                স্ট্যালাকটাইট ও স্ট্যালাগমাইট-এর পার্থক্য লেখাে।
                <br />
                12. কাস্ট ভূমিরূপ সম্পর্কে সংক্ষেপে আলােচনা করাে।
                <br />
                অথবা, চুনাপাথর অঞ্চলে ভৌমজলের কার্যের ফলে সৃষ্ট ভূমিরূপের বর্ণনা
                দাও।
                <br />
                অথবা, কাস্ট অঞ্চলে ভৌমজলের কার্যের ফলে সৃষ্ট ভূমিরূপের বর্ণনা
                দাও।
                <br />
                13. প্রস্রবণ কাকে বলে? কাস্ট অঞ্চল প্রায়শই অনুর্বর হয় — কারণ
                নির্দেশ করাে। <br />
                14. মুক্ত ও আবদ্ধ অ্যাকুইফারের মধ্যে তুলনা করে। অ্যাকুইড ও
                অ্যাকুইটার্ড কী?
                <br />
                15. অন্তর্জাত ও বহির্জাত প্রক্রিয়ার মধ্যে পার্থক্য লেখাে।
                চুনাপাথরযুক্ত অঞ্চলে গুহা কীভাবে সৃষ্টি হয়?
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. ভূমিরূপ গঠনকারী প্রক্রিয়া বলতে কী বােঝ? পার্থিব
                  প্রক্রিয়ার শ্রেণিবিভাগ করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>ভূমিরূপ গঠনকারী প্রক্রিয়া : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যেসকল পদ্ধতিতে ভৌত ও রাসায়নিক পরিবর্তনের মাধ্যমে ভূমিরূপের
                পরিবর্তন ও বিবর্তন ঘটে থাকে, তাদের ভূমিরূপ গঠনকারী প্রক্রিয়া
                (Geomorphic Process) বলে। বিভিন্ন ভূতাত্বিক যুগে এই প্রক্রিয়ার
                মাধ্যমেই বিভিন্ন ভূমিরূপের সৃষ্টি হয়েছে। এই ভূমিরূপ গঠনকারী
                প্রক্রিয়াসমূহকে মূলত 3টি ভাগে ভাগ করা যায়। যথা 一<br />
                <br />
                [1] পার্থিব প্রক্রিয়া (Terrestrial Process), <br />
                [2] মহাজাগতিক প্রক্রিয়া (Extra-terrestrial Process), <br />
                [3] মনুষ্যজনিত প্রক্রিয়া (Man made Process)
                <br />
                <br />
                পার্থিব প্রক্রিয়ার শ্রেণিবিভাগ : পৃথিবীতে যেসব ভূগঠনকারী
                প্রক্রিয়া ঘটে থাকে তাদের পার্থিব প্রক্রিয়া বলে। পার্থিব
                প্রক্রিয়া ভূমিরূপ সৃষ্টিতে প্রধান ভূমিকা পালন করে। পার্থিব
                প্রক্রিয়াকে পুনরায় দুটি ভাগে ভাগ করা যায়। যথা- [1] অন্তর্জাত
                প্রক্রিয়া ও [2] বহির্জাত প্রক্রিয়া।
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. অন্তর্জাত প্রক্রিয়া : </b>{" "}
                </font>{" "}
                পৃথিবীর অভ্যন্তরে বিভিন্ন ধরনের অর্ধতরল পদার্থ রয়েছে। মূলত চাপ
                ও তাপের কারণে এসব পদার্থের ভৌত ও রাসায়নিক বৈশিষ্ট্যের পরিবর্তন
                ঘটে। এর ফলে ভূঅভ্যন্তর থেকে এক অন্তর্জাত শক্তির সৃষ্টি হয়
                (Endogenic Force)। তার প্রভাব ভূত্বকের ওপর পড়ে এবং বিভিন্ন
                পদ্ধতিতে আকস্মিক বা ধীর ভাবে ভূত্বকের পরিবর্তন ঘটায়। এসব
                পদ্ধতিকে একত্রে অন্তর্জাত প্রক্রিয়া বা Endogenic Process বলে।
                <br />
                <br />
                <font color="#29900a">
                  <b>বৈশিষ্ট্য : </b>{" "}
                </font>{" "}
                <br />
                (১) অন্তর্জাত প্রক্রিয়া ভূত্বকে আকস্মিক বা ধীর দুভাবে কাজ করে
                থাকে।
                <br /> (২) অন্তর্জাত প্রক্রিয়ার ফল হল ভূসংক্ষোভ বা ভূবিপর্যয়।
                <br /> (৩) অন্তর্জাত প্রক্রিয়া মূলত পৃথিবী গঠনকারী বিভিন্ন
                উপাদান যেমন - ইউরেনিয়াম, থােরিয়াম প্রভৃতি তেজস্ক্রিয় মৌলিক
                পদার্থের দ্বারা ঘটে।
                <br /> (৪) গুরুমণ্ডলের বিভিন্ন স্তরে পরিচলন স্রোত এবং
                ভূ-অভ্যন্তরস্থ শিলার সংকোচন ও প্রসারণের মাধ্যমে এই প্রক্রিয়া
                হয়ে থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. বহির্জাত প্রক্রিয়া : </b>{" "}
                </font>{" "}
                আবহবিকার, নদী, হিমবাহ, ভৌমজল প্রভৃতি প্রাকৃতিক শক্তিসমূহ
                ভূপৃষ্ঠে অথবা ভূপৃষ্ঠের কাছাকাছি উপপৃষ্ঠীয় অংশকে সর্বদা ক্ষয়,
                পরিবহণ ও সঞ্চয় কাজের দ্বারা নগ্ন করে দিচ্ছে, ফলে ভূপৃষ্ঠে নানা
                ধরনের ভূমিরূপ গড়ে উঠেছে। প্রাকৃতিক শক্তির দ্বারা ভূপৃষ্ঠ কিংবা
                উপপৃষ্ঠীয় অংশের নগ্নীভবন পদ্ধতিকে বহির্জাত প্রক্রিয়া বা
                Exogenic Process বলা হয়।
                <br />
                <br />
                <font color="#29900a">
                  <b>বৈশিষ্ট্য : </b>{" "}
                </font>{" "}
                <br />
                (১) এটি একটি অত্যন্ত ধীর প্রক্রিয়া। <br />
                (২) এই প্রক্রিয়াগুলি অন্তর্জাত প্রক্রিয়াজনিত ভূমিরূপের ওপর কাজ
                করে ছােটো ছােটো ভূমিরূপ গড়ে তােলে।
                <br /> (৩) এই প্রক্রিয়াগুলির প্রয়ােজনীয় শক্তি মূলত বায়ুমণ্ডল
                ও সূর্য থেকে আসে।
                <br /> (৪) ভূগাঠনিক ক্রিয়ায় সৃষ্ট ভূমির ঢাল (Slope of
                gradient) থেকেও এই প্রক্রিয়া শক্তি পেয়ে থাকে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  2. পর্যায়ন কী? বহির্জাত প্রক্রিয়ার পদ্ধতিগুলি আলােচনা করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>পর্যায়ন : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ক্ষয় ও সঞ্চয়কাজের মাধ্যমে ভূমির ঢালের ক্রমান্বয়ে ভারসাম্য
                অবস্থা লাভের সাপেক্ষে পর্যায়ন শব্দটি এসেছে। চেম্বারলিন
                (Chamberlin) ও স্যালিসবারি (Salisbury)-এর মতে পর্যায়ন বলতে
                বহির্জাত প্রক্রিয়া সমূহের সম্মিলিত ক্ষয় ও সঞ্চয় কাজের মাধ্যমে
                উঁচুনীচু ভূভাগের (Surface Irregularities) একটি সাধারণ তল বা
                পৃষ্ঠে (Common level) নিয়ে আসাকে বোঝায়। এখানে সাধারণ তল বা
                পৃষ্ঠ বলতে ক্ষয়ের শেষ সীমাকে (Base level of Erosion) বোঝায়।
                সুতরাং ক্ষয়ের শেষ সীমার সাপেক্ষে ভূপৃষ্ঠের সমতলীকরণ হল
                পর্যায়ন।
                <br />
                <br />
                <font color="#29900a">
                  <b>বহিজাত প্রক্রিয়ার পদ্ধতি : </b>{" "}
                </font>{" "}
                মূলত তিনটি পদ্ধতির মাধ্যমে বহির্জাত প্রক্রিয়া কার্যকরী হয়, যথা
                — <br />
                <br />
                <font color="#f626d7">
                  <b>1. অবরােহণ প্রক্রিয়া : </b>{" "}
                </font>{" "}
                এই প্রক্রিয়ায় আবহবিকার, পুঞ্জিত ক্ষয়, নদী, হিমবাহ,
                বায়ুপ্রবাহ প্রভৃতি বহির্জাত শক্তিসমূহের প্রভাবে ভূমিভাগের
                উচ্চতা ক্রমশ কমতে থাকে। যেমন—সুউচ্চ পর্বত ক্ষয় হতে হতে ক্ষয়জাত
                পর্বত, তারপর ক্ষয়জাত মালভূমি এবং শেষে ক্ষয়জাত সমভূমিতে পরিণত
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. আরােহণ প্রক্রিয়া : </b>{" "}
                </font>{" "}
                আরােহণ কথার অর্থ ওপরে ওঠা। এই ধরনের প্রক্রিয়ায় ভূমিভাগের
                উচ্চতা ক্রমশ বাড়তে থাকে। নদী, হিমবাহ, বায়ু দ্বারা বাহিত পদার্থ
                সঞ্চিত হয়ে ভূপৃষ্ঠের নিম্নস্থান উঁচু বা ভরাট হয়। একে আরােহণ
                প্রক্রিয়া বলে। যেমন—বদ্বীপ, প্লাবন সমভূমি, লােয়েস সমভূমি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. জৈবিক প্রক্রিয়া : </b>{" "}
                </font>{" "}
                উদ্ভিদ এবং প্রাণী যখন ভূমিরূপের পরিবর্তন ঘটায়, তখন তাকে জৈবিক
                প্রক্রিয়া বলে। জলাভূমিতে শ্যাওলা, আগাছা, পাতা, ফুল ইত্যাদি জমে
                জলাভূমি ভরাট হয়ে যায়। মানুষ পাহাড় কেটে রাস্তা বানায়, সমুদ্রে
                বাঁধ দিয়ে সমুদ্রগর্ভ ভরাট করে, নতুন ভূমি তৈরি করে। এভাবে জৈবিক
                প্রক্রিয়া ক্রিয়াশীল থাকে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  3. আবহবিকার কাকে বলে? যান্ত্রিক আবহবিকারের প্রক্রিয়াসমূহ
                  আলােচনা করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>আবহবিকার : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                আবহবিকার (Weathering) শব্দটি এসেছে আবহাওয়া (Weather) থেকে।
                আবহাওয়ার বিভিন্ন উপাদানের (যেমন — উষ্ণতা, আদ্রতা, বৃষ্টিপাত,
                এবং বায়ুমণ্ডলের বিভিন্ন গ্যাস) সাহায্যে ভূপৃষ্ঠের শিলাস্তর
                যান্ত্রিক পদ্ধতিতে চূর্ণবিচূর্ণ এবং রাসায়নিক পদ্ধতিতে বিয়ােজিত
                হলে, তাকে আবহবিকার (weathering) বলে।
                <br />
                <br />
                আবহবিকার সংঘটিত হয় — যান্ত্রিক, রাসায়নিক ও জৈবিক পদ্ধতিতে। তাই
                আবহবিকারকে [1] যান্ত্রিক আবহবিকার, [2] রাসায়নিক আবহবিকার ও [3]
                জৈবিক আবহবিকার—এই তিনটি ভাগে ভাগ করা হয়।
                <br />
                <br />
                যান্ত্রিক আবহবিকারের প্রক্রিয়াসমূহ উষ্ণতার পরিবর্তন, শিলাস্তরে
                চাপের হ্রাসবৃদ্ধি, আর্দ্রতার পরিবর্তন, জৈবিক কার্যাবলি প্রভৃতি
                কারণে ভূপৃষ্ঠের শিলাস্তর যান্ত্রিকভাবে চূর্ণবিচূর্ণ অথাৎ ভেঙে
                টুকরাে টুকরাে হলে, তাকে যান্ত্রিক আবহবিকার (Physical Weathering)
                বলে। যান্ত্রিক আবহবিকারে শিলা চূর্ণবিচূর্ণ হয়, কিন্তু শিলা
                খনিজের কোনাে মৌল পরিবর্তন ঘটে না। যান্ত্রিক আবহবিকার বিভিন্নভাবে
                ঘটে থাকে যথা -<br />
                <br />
                <font color="#f626d7">
                  <b>1. উষ্ণতার গরিবর্তনে যান্ত্রিক আবহবিকার : </b>{" "}
                </font>{" "}
                উষ্ণতার পরিবর্তনে বিভিন্নভাবে শিলার যান্ত্রিক পরিবর্তন ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>i. প্রস্তরচাই খণ্ডীকরণ : </b>{" "}
                </font>{" "}
                শিলা তাপের কুপরিবাহী। দিনের বেলা প্রবল উয়তায় শিলার উপরিস্তর
                উত্তপ্ত হয়ে প্রসারিত হয় এবং রাত্রি বেলা তাপ বিকিরণ করে সংকুচিত
                হয়। কিন্তু শিলার নিম্ন স্তরে সংকোচন বা প্রসারণ ঘটে না। এইভাবে
                ক্রমাগত অসম সংকোচন ও প্রসারণের কারণে শিলাস্তরে অসংখ্য উল্লম্ব ও
                সমান্তরাল ফাটলের সৃষ্টি হয় এবং এক সময় এই ফাটল বরাবর টুকরা
                টুকরাে বিভিন্ন আকৃতির শিলাখণ্ড খুলে বেরিয়ে আসে। একেই প্রস্তরাই
                খণ্ডীকরণ (Block Disintegration) বলে। এভাবে আদি শিলা চূর্ণবিচূর্ণ
                হয় ও মাটির উৎপত্তি ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>ii. ক্ষুদ্রকণা বিশরণ : </b>{" "}
                </font>{" "}
                শিলা একাধিক খনিজের সমন্বয়ে গঠিত এবং খনিজগুলির বৈশিষ্ট্য,
                প্রকৃতি ও রং ভিন্ন। উয়তা বাড়া-কমার জন্য খনিজগুলি বিভিন্ন হারে
                প্রসারিত ও সংকুচিত হয়। এই কারণে শিলায় প্রবল পীড়নের (Stress)
                সৃষ্টি হয় এবং একসময় শিলা ক্ষুদ্র ক্ষুদ্র খণ্ডে পরিণত হয়। একেই
                ক্ষুদ্রকণা বিশরণ (Granular Disintegration) বলে। এই প্রক্রিয়ায়
                মূল শিলা থেকে প্রথমে বালুকা ও পরে জৈব পদার্থের সংযােজনে মাটি
                সৃষ্টি হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>iii. শল্কমােচন : </b>{" "}
                </font>{" "}
                শিলা তাপের কুপরিবাহী হওয়ায় শিলাস্তরের বাইরের অংশ দিনের বেলায়
                তীব্র উয়তায় প্রসারিত এবং রাতের বেলায় সংকুচিত হয়। কিন্তু
                ভিতরের অংশ প্রসারিত ও সংকুচিত হতে পারে না। শিলাস্তরের বাইরের অংশ
                ক্রমাগত সম্প্রসারণের কারণে স্তরগুলি পেঁয়াজের খােসার মতাে খুলে
                যায়। পরবর্তীকালে বায়ুপ্রবাহে খুলে যাওয়া অংশগুলি অপসারিত হয়ে
                শিলাস্তরটি গােলাকৃতি রূপ ধারণ করে। একেই শল্কমােচন বা গােলাকার
                আবহবিকার (Exfoliation) বলে। মরুভূমি ও শুষ্ক অঞ্চলের কেলাসিত
                শিলায় এই আবহবিকার ঘটে এবং মাটি গঠিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. কেলাস গঠন : </b>{" "}
                </font>{" "}
                উচ্চ পার্বত্য অঞ্চলে উচ্চ অক্ষাংশের শীতল জলবায়ু অঞ্চলে দিনের
                বেলায় বরফ গলা জল শিলার ফাটলে প্রবেশ করে। রাতে তাপমাত্রা
                হিমাঙ্কের নীচে নামলে ঐ জল বরফে পরিণত হয় এবং আয়তনে বেড়ে যায়।
                এর ফলে ফাটলের দু-পাশের দেওয়ালে প্রচণ্ড চাপ বাড়ে, আবার দিনের
                বেলা সেই বরফ গলে গিয়ে শিলাগাত্রে চাপ হ্রাস পায়। এভাবে দিন ও
                রাতে জলের ভৌত অবস্থার পরিবর্তনের সাথে সাথে ক্রমাগত শিলায় চাপ
                বাড়ে ও ফাটল বৃদ্ধি পায়। অবশেষে শিলা চূর্ণবিচূর্ণ হয়। এই
                তীক্ষ্ণ শিলা খণ্ডগুলিকে স্ত্রী বা ট্যালাস বলে। এগুলি পর্বতের
                পাদদেশে জমা হয়ে বালুকাপূর্ণ মাটি গঠন করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. শিলাস্তরে চাপ হ্রাস : </b>{" "}
                </font>{" "}
                ভূগর্ভে গ্র্যানাইটজাতীয় শিলা ওপরের অন্য শিলাস্তুপ বা বিপুল
                পরিমাণ বরফের চাপে সংকুচিত থাকে। কিন্তু নানা কারণে ওপরের
                শিলাস্তূপ বা বরফ সরে গেলে নীচের ওই শিলা চাপ ও ভারমুক্ত হয়ে
                প্রসারিত হয়, ফলে শিলায় পীড়ন ও টানের সৃষ্টি হয়। এভাবে শিলায়
                ফাটল ধরে এবং আবহবিকার ঘটে ও পরে মাটির সৃষ্টি হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. জলের মাধ্যমে : </b>{" "}
                </font>{" "}
                জলের মাধ্যমে যান্ত্রিক আবহবিকার বিভিন্ন ভাবে ঘটে- জলস্রোতের ফলে
                সৃষ্ট বুদ্বুদের বায়ু শিলাফাটলে প্রবল চাপের সৃষ্টি করে এবং
                শিলায় আবহবিকার ঘটায়। শিলাছিদ্রে সঞ্চিত জল গ্রীষ্মকালে শুকিয়ে
                যায় এবং বর্ষার সময় পূর্ণ হয়। আর্দ্রতা ও শুষ্কতার
                পর্যায়ক্রমিক পরিবর্তনের ফলে শিলা ভেঙে টুকরাে টুকরাে (Flake)
                হয়ে যায়। মরু অঞ্চলে অতি উত্তপ্ত শিলার ওপর হঠাৎ বৃষ্টির জল
                পড়লে শিলা ঠাণ্ডা জলের সংস্পর্শে এসে সংকুচিত হয় এবং ফেটে যায়।
                এভাবে আদি শিলা চূর্ণবিচূর্ণ হয়ে মাটিতে পরিণত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. অন্যান্য প্রক্রিয়া : </b>{" "}
                </font>{" "}
                উপকূল বরাবর পর্যায়ক্রমে জোয়ারভাটার প্রভাবে শিলা আর্দ্র ও শুষ্ক
                হয় এবং শিলা ফেটে যায়। একে কলিকরণ বা Slaking বলে। শিলাস্তরের
                ওপর সঞ্চিত আর্দ্র মৃত্তিকা-কণা (কলয়েড) শুকিয়ে যাওয়ার সময় মূল
                শিলা থেকে ক্ষুদ্রকণা উৎপাটন করে। একে কলয়েড প্লাকিং বলে।
                দাবানলের প্রভাবে শিলা প্রবলভাবে উত্তপ্ত হলে শিলা ভেঙে যায়। এই
                ধরনের আবহবিকার মাটি গঠনে সাহায্য করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  4. পর্যায়ন প্রক্রিয়ার মাধ্যমগুলি কী কী? যান্ত্রিক ও
                  রাসায়নিক আবহবিকারের মধ্যে তুলনা করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>আবহবিকার : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ক্ষয়কার্যের মাধ্যমে উচ্চভূমি ও সঞ্জয়কার্যের মাধ্যমে নিম্নভূমির
                সমতলীকরণকে পর্যায়ণ বলে।
                <br />
                <br />
                <font color="#29900a">
                  <b>পর্যায়ণের বিভিন্ন মাধ্যম : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>i. নদী : </b>{" "}
                </font>{" "}
                পর্যায়ণের সর্বাপেক্ষা গুরুত্বপূর্ণ মাধ্যম হল নদী।
                <br />
                <font color="#29900a">
                  <b>প্রভাবিত অঞ্চল : </b>{" "}
                </font>{" "}
                শীতল মেরু ও পার্বত্য অঞ্চল বাদে স্থলভাগের প্রায় সর্বত্র এইভাবে
                পর্যায়ণ ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>ii. হিমবাহ : </b>{" "}
                </font>{" "}
                হিমযুগে প্রবাহিত বরফের স্তূপ পর্যায়ণের গুরুত্বপূর্ণ মাধ্যম ছিল।
                <br />
                <font color="#29900a">
                  <b>প্রভাবিত অঞ্চল : </b>{" "}
                </font>{" "}
                বর্তমানে ৯% স্থলভাগে, দুই মেরু ও পার্বত্য অঞ্চল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>iii. বায়ুপ্রবাহ : </b>{" "}
                </font>{" "}
                সারা বছর ধরে প্রবল বেগে প্রবাহিত বায়ু পর্যায়ণের মাধ্যম।
                <br />
                <font color="#29900a">
                  <b>প্রভাবিত অঞ্চল : </b>{" "}
                </font>{" "}
                ১৫% স্থলভাগে শুষ্ক ক্রান্তীয় ও নাতিশীতোয় মরু অঞ্চল এবং উপকূল
                অঞ্চল।
                <br />
                <br />
                <font color="#29900a">
                  <b>যান্ত্রিক ও রাসায়নিক আবহবিকারের মধ্যে তুলনা </b>{" "}
                </font>{" "}
                <br />
                <table>
                  <colgroup>
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>বিষয়</b>
                          </font>
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>যান্ত্রিক আবহবিকা</b>
                          </font>
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>রাসায়নিক আবহবিকা</b>
                          </font>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">সংজ্ঞা</font>
                        </span>
                      </td>
                      <td>
                        <span>
                          {" "}
                          উষ্ণতা, বৃষ্টিপাত, তুষার প্রভৃতি আবহাওয়ার বিভিন্ন
                          উপাদানের মাধ্যমে যান্ত্রিক প্রক্রিয়ায় ভূপৃষ্ঠের শিলা
                          সমূহ চূর্ণ বিচূর্ণ হলে তাকে যান্ত্রিক আবহবিকার বলে।{" "}
                        </span>
                      </td>
                      <td>
                        <span>
                          যখন জলীয় বাষ্প বা জলের মাধ্যমে শিলা সমূহের খনিজ
                          পদার্থ দ্রবীভূত, বিচ্ছিন্ন ও পরিবর্তিত হয়ে যায়, তখন
                          তাকে রাসায়নিক আবহবিকার বলে।{" "}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">প্রকৃতি</font>
                        </span>
                      </td>
                      <td>
                        <span>
                          যান্ত্রিক আবহ বিকারের ফলে শিলার শুধু মাত্র আকৃতি গত বা
                          ভৌত পরিবর্তন হয়।
                        </span>
                      </td>
                      <td>
                        <span>
                          রাসায়নিক আবহ বিকারের ফলে শিলার মৌলিক খনিজ পদার্থ গুলি
                          রূপান্তরিত হয়ে গৌণ বা যৌগিক খনিজ পদার্থে পরিণত হয়।{" "}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">আবহবিকারের নিয়ন্ত্রক </font>
                        </span>
                      </td>
                      <td>
                        <span>
                          বায়ুর উষ্ণতার পরিবর্তন, চাপ, বায়ুর আর্দ্রতা ও জৈবিক
                          প্রক্রিয়া যান্ত্রিক আবহবিকারের মূল নিয়ন্ত্রক হিসাবে
                          কাজ করে।{" "}
                        </span>
                      </td>
                      <td>
                        <span>
                          রাসায়নিক আবহবিকারের নিয়ন্ত্রক গুলি হল জল, অক্সিজেন,
                          কার্বন ডাই অক্সাইড প্রভৃতি।
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">সময় </font>
                        </span>
                      </td>
                      <td>
                        <span>
                          যান্ত্রিক আবহবিকার প্রক্রিয়ায় শিলার চূর্ণ করন অতি
                          দ্রুত গতিতে সম্পন্ন হয়ে থাকে।{" "}
                        </span>
                      </td>
                      <td>
                        <span>
                          রাসায়নিক আবহবিকার দ্বারা শিলার বিয়োজন বা ভাঙনে অধিক
                          সময় ব্যয় হয়। অর্থাৎ এটি ধীর গামী প্রক্রিয়া।
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  5. ভৌমজলের নিয়ন্ত্রকগুলি আলোচনা করো। আরােহণ ও অবরােহণ পদ্ধতির
                  পার্থক্য লেখাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>আবহবিকার : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভৌমজলের নিয়ন্ত্রকগুলি নীচে আলোচনা করা হল —<br />
                <br />
                <font color="#f626d7">
                  <b>i. বৃষ্টিপাতের পরিমাণ ও স্থায়িত্ব : </b>{" "}
                </font>{" "}
                যে অঞ্চলে বৃষ্টিপাতের পরিমাণ ও স্থায়িত্ব বেশি সেখানে ভৌমজলের
                পরিমাণও বেশি হয়। <br />
                <font color="#29900a">
                  <b>উদাহরণ :</b>{" "}
                </font>{" "}
                বর্ষাকালে ভৌমজলের পরিমাণ বৃদ্ধি পায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>ii. মৃত্তিকার প্রবেশ্যতা :</b>{" "}
                </font>{" "}
                কোনো অঞ্চলে মাটির সছিদ্রতা অধিক হলে জলের প্রবেশ্যতা বৃদ্ধি পায়।
                ফলে ভৌমজলের পরিমাণ বৃদ্ধি পায়। <br />
                <font color="#29900a">
                  <b>উদাহরণ : </b>{" "}
                </font>{" "}
                বেলে মৃত্তিকা অঞ্চলে ভৌমজলের পরিমাণ বেশি হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>iii. ভূমির ঢাল : </b>{" "}
                </font>{" "}
                যে অঞ্চলে ভূমির ঢাল বেশি যেখানে জল ভূগর্ভে প্রবেশ করতে পারে না।
                অর্থাৎ অন্যত্র অপসারিত হয়। ফলে সেই অঞ্চলে ভৌমজলের পরিমাণ কম
                হয়।
                <br />
                <font color="#29900a">
                  <b>উদাহরণ : </b>{" "}
                </font>{" "}
                পার্বত্য অঞ্চলে ভৌমজলের পরিমাণ কম হয়।
                <br />
                <br />
                <table>
                  <colgroup>
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>বিষয়</b>
                          </font>
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>আরোহণ</b>
                          </font>
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>অবরোহণ</b>
                          </font>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">সংজ্ঞা</font>
                        </span>
                      </td>
                      <td>
                        <span>
                          কোনো নিম্নভূমি সঞ্জয় কার্যের মাধ্যমে উচ্চভূমিতে পরিণত
                          হলে তাকে আরোহণ প্রক্রিয়া বলে।
                        </span>
                      </td>
                      <td>
                        <span>
                          যে প্রক্রিয়ায় কোনো উচ্চভূমি ক্ষয়কার্যের মাধ্যমে
                          নিম্নসমভূমিতে পরিণত হয় তাকে অবরোহণ প্রক্রিয়া বলে।
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">পর্যায়</font>
                        </span>
                      </td>
                      <td>
                        <span>আরোহণ, অবরোহণ পরবর্তী পর্যায়ে সৃষ্টি</span>
                      </td>
                      <td>
                        <span>
                          অবরোহণ, আরোহণের পূর্ববর্তী পর্যায়ে সংঘটিত হয়।
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">উচ্চতা</font>
                        </span>
                      </td>
                      <td>
                        <span>
                          এই প্রক্রিয়ায় ভূমিভাগের উচ্চতা বৃদ্ধি পায়।
                        </span>
                      </td>
                      <td>
                        <span>
                          এই প্রক্রিয়ায় ভূমিভাগের উচ্চতা হ্রাস পায়।
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">নিয়ন্ত্রক</font>
                        </span>
                      </td>
                      <td>
                        <span>এর নিয়ন্ত্রক নদী, বায়ু, হিমবাহ প্রভৃতি।</span>
                      </td>
                      <td>
                        <span>
                          এর নিয়ন্ত্রক—আবহবিকার, ক্ষয়ীভবন পুঞ্জিত ক্ষয়
                          প্রভৃতি।
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. ভৌমজল কাকে বলে? ভৌমজলের গুরুত্ব আলােচনা করাে।</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>আবহবিকার : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভূ-অভ্যন্তরে কিংবা মৃত্তিকা, রেগোলিথ এবং শিলারন্ধ্রে যে জল
                অবস্থান করে, তাকে ভৌমজল বলে। ভৌমজলের প্রধান উৎস হল বৃষ্টি ও
                তুষারগলা জল। বৃষ্টিপাত ও তুষারগলা জলের সামান্য অংশ পৃথিবীর
                অভিকর্ষজ টানে মাটির মধ্য দিয়ে ভূ-অভ্যন্তরে অপ্রবেশ্য স্তর
                পর্যন্ত পৌঁছোয়- এবং অপ্রবেশ্য শিলাস্তরের ওপরে মৃত্তিকা ও
                শিলারন্ধ্রকে সম্পূর্ণ সম্পৃক্ত করে রাখে।
                <br />
                <br />
                <font color="#29900a">
                  <b>ভৌমজলের গুরুত্ব : </b>{" "}
                </font>{" "}
                মানবজীবনে ভৌমজলের গুরুত্ব অপরিসীম নীচে ভৌমজলের গুরুত্ব গুলি
                আলোচনা করা হল-
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. পানীয় জলের উৎস : </b>{" "}
                </font>{" "}
                ভৌম়জল মানুষের পানীয় জলের প্রধান উৎস। গভীর ও অগভীর নলকূপ, কুয়ো
                থেকে আমরা পানীয় জল সংগ্রহ করি। প্রস্রবণের মধ্য দিয়ে নির্গত
                ভৌমজলও পানীয় হিসেবে অনেক সময় ব্যবহৃত হয়।
                <br />
                <font color="#f626d7">
                  <b>2. কৃষি ও শিল্পকর্মের উথ‍্যান : </b>{" "}
                </font>{" "}
                বহু অঞ্চলে ভৌমজলের সঞ্চয় নির্ভর কৃষিকাজ ও শিল্পকাজ গড়ে ওঠে।
                পশ্চিমবঙ্গের বহুস্থানে গভীর ও অগভীর নলকূপের মাধ্যমে ভৌমজল
                উত্তোলন করে বহুফসলি শস্যের উৎপাদন সম্ভব হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. ভূতাপ বিদ্যুৎ উৎপন্ন : </b>{" "}
                </font>{" "}
                উষ্ণ প্রস্রবণ ও গিজারের উত্তপ্ত ভৌমজল়কে কাজে লাগিয়ে ভূতাপ
                বিদ্যুৎ উৎপাদন সম্ভব হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. জনবসতি স্থাপন : </b>{" "}
                </font>{" "}
                প্রস্রবণ থেকে নির্গত ভৌমজ়ল জনবসতি গড়ে উঠতে গুরুত্বপূর্ণ ভূমিকা
                পালন করে। পশ্চিমবঙ্গে শুশুনিয়া পাহাড়ের পাদদেশ থেকে প্রাপ্ত
                প্রস্রবণের জল ওই স্থানে জনবসতি গড়ে উঠতে সাহায্য করেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. পশুদের জলের জোগান : </b>{" "}
                </font>{" "}
                চুনাপাথর অঞ্চলের ভূপৃষ্ঠ শুষ্ক প্রকৃতির। এই অঞ্চলের তৃণই প্রধান
                উদ্ভিদ। পশুপালনের জন্য প্রয়োজনীয় পানীয় জল এই অঞ্চলে গঠিত
                ভৌমজলের ভাণ্ডার থেকে সংগ্রহ করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. রোগ নিরাময় : </b>{" "}
                </font>{" "}
                চুনাপাথরযুক্ত অঞ্চলে উষ্ম প্রস্রবণের জলে কিছু কিছু রোগ
                নিরাময়কারী উপাদান থাকে। তাই, ওইসব স্থানে মানুষের সমাগম বাড়ে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. পর্যটন শিল্পের সম্প্রসারণ : </b>{" "}
                </font>{" "}
                চুনাপাথর যুক্ত অঞ্চলে কিছু বিশেষ ধরনের ভূমিরূপ গঠিত হয় যা
                পর্যটকদের আকর্ষণ করে। ফলে ওই অঞ্চলে বা স্থানে পর্যটন শিল্প গড়ে
                ওঠে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. মরূদ্যানের সৃষ্টি : </b>{" "}
                </font>{" "}
                মরু অঞ্চলে একটি নির্দিষ্ট স্থানে বায়ুর দ্বারা বালুকারাশি
                অপসারিত হলে ভৌমজল়পীঠ উন্মুক্ত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  7. শুষ্ক উপত্যকা ও অন্ধ উপত্যকার বৈশিষ্ট্যগুলি আলােচনা করাে।
                  অ্যাকুইফার বলতে কী বােঝ এবং এটি কীভাবে সৃষ্টি হয়?
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>শুষ্ক ও অন্ধ উপত্যকা : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                নদী-উপত্যকার মধ্যে সিদ্ধহােল তৈরি হলে শুষ্ক উপত্যকা ও অন্ধ
                উপত্যকা সৃষ্টি হয়। নদীর প্রবাহপথের যেখানে সিঙ্কহােল থাকে সেখানে
                নদী ওই সিঙ্কহােলে প্রবেশ করে ভূ-অভ্যন্তর দিয়ে প্রবাহিত হয়।
                সিঙ্কহােলের পরের নদী-উপত্যকার অংশটি তখন জলশূন্য হয়ে পড়ে।
                নদী-উপত্যকার এই জলশূন্য অংশটি হল শুঙ্ক উপত্যকা। আর নদীপ্রবাহের
                যে অংশ সিঙ্কহােলে প্রবেশ করে সেই অংশটি কানাগলির মতাে ওখানে হঠাৎ
                শেষ হয়ে যায়। নদীর এই অংশটি হল অন্ধ উপত্যকা।
                <br />
                <br />
                <font color="#29900a">
                  <b>শুষ্ক উপত্যকা ও অন্ধ উপত্যকার বৈশিষ্ট্য : </b>{" "}
                </font>{" "}
                <br />
                <br />
                ১. প্রচুর বৃষ্টিপাতের সময় শুষ্ক উপত্যকার মধ্য দিয়ে সামান্য
                পরিমাণ জল প্রবাহিত হলেও বর্ষার পর উপত্যকাটি পুনরায় শুকনাে হয়ে
                যায়।
                <br />
                ২. অন্ধ উপত্যকায় নদীর গতি অব্যাহত থাকে বলে নদীর ক্ষয়কার্যের
                ফলে অন্ধ উপত্যকাটি পরিত্যক্ত উপত্যকা অপেক্ষা গভীর হয়। পরিত্যক্ত
                উপত্যকাটি অন্ধ উপত্যকা অপেক্ষা অনেক উঁচুতে অবস্থান করে।
                <br />
                ৩. বর্ষাকালে নদীবাহিত জলের সমস্তটাই সিঙ্কহােলের মধ্যে দিয়ে
                প্রবেশ করতে পারে না বলে অন্ধ উপত্যকা সাময়িকভাবে হ্রদে পরিণত
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>অ্যাকুইফার : </b>{" "}
                </font>{" "}
                অ্যাকুইফার শব্দের উৎপত্তি দুটি ল্যাটিন শব্দ Aqua এবং Ferre থেকে।
                Aqua শব্দের অর্থ জল এবং Ferre শব্দের অর্থ বহনকারী। অতএব,
                অ্যাকুইফার শব্দের অর্থ জলবহনকারী বা জলবাহী স্তর। যে ভূতাত্ত্বিক
                স্তরের মধ্য দিয়ে জল প্রবাহিত হয়, পরিপূরিত হয়, ক্ষরিত হয় এবং
                জলের ভাণ্ডাররূপে অবস্থান করে তাকে অ্যাকুইফার বলে। অ্যাকুইফার
                ছাড়া ভৌমজল সৃষ্টি হতে পারে না। বৃষ্টির জল ও বরফগলা জল মৃত্তিকার
                মধ্য দিয়ে প্রবেশ্য শিলাস্তরে সঞ্ছিত হলে ভৌমজলের ভাণ্ডার গড়ে
                ওঠে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>অ্যাকুইফারের সৃষ্টি : </b>{" "}
                </font>{" "}
                অপ্রবেশ্য শিলাস্তরের ওপর প্রবেশ্য শিলাস্তর অবস্থান করলে, ওই
                প্রবেশ্য শিলাস্তরে জল সঞ্চিত হয়ে যে অ্যাকুইফার গঠিত হয় তাকে
                মুক্ত অ্যাকুইফার বলে। দুটি অপ্রবেশ্য শিলাস্তরের মধ্যে থাকা
                প্রবেশ্য শিলাস্তরে জল সঞ্চিত হয়ে যে অ্যাকুইফার গঠিত হয়, তাকে
                আবদ্ধ অ্যাকুইফার বলে। মুক্ত অ্যাকুইফারের ওপরে চামচের মতাে অথবা
                লেন্সের মতাে অল্প পরিসর স্থানে অপ্রবেশ্য শিলাস্তর অবস্থান করলে
                ওই অপ্রবেশ্য শিলাস্তরের ওপরে জলবাহী স্তরে সামান্য কিছু জল সঞ্চিত
                হয়। একে স্থানীয় বা পার্চড অ্যাকুইফার বলে। প্রবেশ্য শিলাস্তরে
                এইভাবে জলের সঞ্চয় ঘটিয়ে বিভিন্ন প্রকার অ্যাকুইফার সৃষ্টি হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  8. আর্টেজীয় কূপ কীভাবে সৃষ্টি হয় তা চিত্রসহযােগে বর্ণনা
                  করাে। <br />
                  অথবা,
                  <br />
                  আর্তেজীয় কূপ থেকে পাম্পের সাহায্য ছাড়াই জল উঠে আসে’- কারণ
                  নির্দেশ করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভূ-অভ্যন্তরস্থ ভৌমজল যখন নিজস্ব চাপে স্বাভাবিকভাবে ভূপৃষ্ঠ
                নির্গত হয়, তখন তাকে আর্টেজীয় কূপ বলে। ফ্রান্সের আর্ট্রয়েস
                অঞ্চলে সর্বপ্রথম এই ধরনের কূপ খনন করা হয়েছিল বলে পৃথিবীর
                সর্বত্র এই ধরনের কূপ আর্টেজীয় কূপ নামে পরিচিত।
                <br />
                <br />
                অর্ধচন্দ্রাকারে সজ্জিত ভঙ্গিল শিলাস্তর দ্বারা গঠিত অঞ্চলে দুটি
                অপ্রবেশ্য শিলাস্তরের মাঝে একটি প্রবেশ্য শিলাস্তর অবস্থান করলে
                আর্টেজীয় কূপের উৎপত্তির পরিবেশ গড়ে ওঠে। প্রবেশ্য শিলাস্তরের
                উভয় প্রান্ত যদি মাঝখানের অধোঃভঙ্গের থেকে উপরে অবস্থান করে এবং
                ওই শিলাস্তরের উভয় প্রান্ত যদি ভূপৃষ্ঠে উন্মুক্ত থাকে, তাহলে
                শুধুমাত্র দুই প্রান্তে বৃষ্টিপাত হলেই বৃষ্টির জল দুই প্রান্তে
                বৃষ্টিপাত হলেই বৃষ্টির জল প্রবেশ্য শিলাস্তরের ভেতর দিয়ে চুইয়ে
                অধোঃভঙ্গের মধ্যে জমা হতে থাকে।
                <br />
                <br />
                প্রবেশ্য শিলাস্তরটির ওপরে ও নিচে অপ্রবেশ্য শিলাস্তরের থাকায় ওই
                জল কোনো ভাবেই বেরিয়ে আসতে পারে না। ফলে জলের চাপ খুব বেশি হয়।
                এরূপ অবস্থায় ওপরের অপ্রবেশ্য শিলাস্তরের ভেতর দিয়ে প্রবেশ্য
                শিলাস্তর পর্যন্ত কোন কূপ খনন করলে জলের স্বাভাবিক চাপে প্রবেশ্য
                শিলাস্তরের জল পাম্পের সাহায্য ছাড়াই বাইরে বেরিয়ে আসে। যেস্থানে
                কূপ খনন করা হয়, সেখানে বৃষ্টিপাত হোক বা না হোক, প্রবেশ্য
                শিলাস্তরের দুই প্রান্তে বৃষ্টিপাত হলেই কূপ থেকে জল পাওয়া যায়।
                তবে শুষ্ক ঋতুতে ভৌম জলস্তর নেমে গেলে ওই কূপ থেকে জল তোলবার জন্য
                পাম্প ব্যবহার করতে হয়।
                <br />
                <br />
                অস্ট্রেলিয়ার শুষ্ক অঞ্চলে আর্টেজীয় কূপ এর সাহায্যে জল তুলে মেষ
                পালন করা হয়। ভারতের গুজরাটের আমেদাবাদের বীরগাঁও নামক স্থানে
                প্রায় ২৩২ মিটার গভীর একটি আর্টেজীয় কূপ খনন করা হয়েছে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

ভূমিরূপগঠনকারীপ্রক্রিয়াসমূহবহিজাতপ্রক্রিয়াসমূহওসংশ্লিষ্টভূমিরূপ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
  memo
)(ভূমিরূপগঠনকারীপ্রক্রিয়াসমূহবহিজাতপ্রক্রিয়াসমূহওসংশ্লিষ্টভূমিরূপ);
