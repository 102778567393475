import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function অমাধ্যমঅনুমান() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          অমাধ্যম অনুমান | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="অমাধ্যম অনুমান | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                অমাধ্যম অনুমান
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>চতুর্থ অধ্যায় - অমাধ্যম অনুমান</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. বিবর্তনের আশ্রয়বাক্যকে কী বলে? </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                বিবর্তনীয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. বিবর্তনের সিদ্ধান্তকে কী বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বিবর্তিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. A বচনের বিবর্তিত বচন কী হবে? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                E বচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. E বচনের বিবর্তিত বচন কী হবে? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                A বচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. কোন বচনের আবর্তন অ-সরল বা সীমিত আবর্তন? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                A বচনের।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. কোন বচনের আবর্তন সম্ভব নয় বা কোন বচনের আবর্তন অবৈধ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                O বচনের।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. I বচনের বিবর্তিত ৰচন কী হবে? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                O বচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. O বচনের বিবৰ্তিত ৰচন কী হবে? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                I বচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. কোন বচনের সমবিবর্তন সম্ভব নয়? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                I বচনের।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. O বচনের আবর্তন সম্ভব নয় কেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                O বচনের আবর্তন করলে যে পদ আশ্রয়বাক্যে ব্যাপ্য নয় সেই পদ
                সিদ্ধান্তে ব্যাপ্য হয়ে যায়, ফলে আবর্তনের নিয়ম লঙ্ঘিত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. অমাধ্যম অনুমানে বা যুক্তিতে ক-টি আশ্রয়বাক্য থাকে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                একটি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. A বচনের আবর্তিত বচন কী হবে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                I বচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. E বচনের আবর্তিত বচন কী হবে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                E বচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. আবর্তনের আশ্রয়বাক্যকে কী বলে? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আবর্তনীয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. আবর্তনের সিদ্ধান্তকে কী বলে? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আবর্তিত।
                <font color="#f626d7">
                  <b>16. আবর্তন কয়প্রকার ও কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আবর্তন দুই প্রকার—সরল আবর্তন এবং অসরল বা সীমিত আবর্তন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. আবর্তনের ব্যাপ্যতা সংক্রান্ত নিয়মটি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যে পদ আশ্রয়বাক্যে ব্যাপ্য নয়, সেই পদ সিদ্ধান্তে ব্যাপ্য হতে
                পারবে না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>1৪. “সকল জড়বস্তু হয় বিস্তৃতিশীল”—বচনটির আবর্তন করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আবর্তনীয় : (A) সকল জড়বস্তু হয় বিস্তৃতিশীল।
                <br />
                আবর্তিত : (A) সকল বিস্তৃতিশীল বস্তু হয় জড়বস্তু।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. A বচনের সরল আবর্তনের একটি উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আবর্তনীয় : সকল মানুষ হয় বুদ্ধিবৃত্তিসম্পন্ন প্রাণী। (A)
                <br />
                আবর্তিত : সকল বুদ্ধিবৃত্তিসম্পন্ন প্রাণী হয় মানুষ। (A)
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. সরল আবর্তন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যে আবর্তনে আশ্রয়বাক্য ও সিদ্ধান্তের পরিমাণ এক হয়, তাকে সরল
                আবর্তন বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. অ-সরল বা সীমিত আবর্তন কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যে আবর্তনে আশ্রয়বাক্য ও সিদ্ধান্তের পরিমাণ ভিন্ন হয়, তাকে অসরল
                আবর্তন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. কোন কোন বচনের আবর্তনকে সরল আবর্তন বলা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                E এবং I বচনের আবর্তনকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. বস্তুগত বিবর্তনের মূল ভিত্তি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বস্তুগত বিবর্তনের মূল ভিত্তি হল বাস্তব ‘অভিজ্ঞতামূলক জ্ঞান।’
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. বিরুদ্ধ পদ কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বিরুদ্ধ পদ হল কোনো পদের সকল বিপরীত পদের সমষ্টি। যেমন—‘সাদা’ এই
                পদটির বিরুদ্ধ পদ হল অ-সাদা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    25. আবর্তনীয় বচনের উদ্দেশ্যপদ আবর্তিত বচনের কোন পদ হবে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বিধেয় পদ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. I বচনের আবর্তিত বচন কী হবে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                I বচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    27. কোন যুক্তিবিজ্ঞানী বস্তুগত বিবর্তনের কথা উল্লেখ করেছেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                উত্তরঃ। যুক্তিবিজ্ঞানী বেইন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. বস্তুগত বিবর্তনের মূল ভিত্তি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অভিজ্ঞতা বা বাস্তবজ্ঞান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    29. কোন যুক্তিবিজ্ঞানী অমাধ্যম অনুমানকে প্রকৃত অনুমান বলে
                    স্বীকার করেননি?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যুক্তিবিজ্ঞানী মিল।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  {" "}
                  1. বস্তুগত বিবর্তন কাকে বলে? একটি উদাহরণ দাও। একে কি প্রকৃত
                  বিবর্তন বলা যায়? 2 + 1 + 5 = 8
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে প্রক্রিয়ায় বচনের আকারে কোনো পরিবর্তন সাপেক্ষে না, তবে তার
                অর্থের উপর বিশেষ ভাবে নির্ভর করা হয় এবং বাস্তব অবস্থানের
                পরিপ্রেক্ষ্য দেখে প্রদত্ত বচনটি পরিবর্তিত করা হয় - এই
                প্রক্রিয়াকে "বস্তুগত বিবর্তন" বলা হয়।
                <br />
                <br />
                যুক্তিবিদ বেইন এই প্রকার বিবর্তন সম্পর্কিত উল্লেখ করেন। এই
                প্রকার বিবর্তনের ক্ষেত্রে, আশ্রয়বাক্যের উদ্দেশ্যপদের বিপরীত
                পদকে সিদ্ধান্তের উদ্দেশ্যে এবং আশ্রয়বাক্যের বিধেয়কে বিপরীত বা
                বিরুদ্ধ পদের মাধ্যমে সিদ্ধান্তের বিধেয়রূপে গ্রহণ করা হয়।
                <br />
                <br />
                <font color="#1f7405">
                  <b> উদাহরণ : </b>
                </font>{" "}
                শীত হয় কষ্টদায়ক।
                <br />
                গরম হয় আরামদায়ক।
                <br />
                <br />
                বস্তুগত বিবর্তনে বাস্তবজ্ঞান বা অভিজ্ঞতার উপর ভিত্তি করে প্রদত্ত
                বচনের বিবর্তন করা হয়। ফলে বস্তুগত বিবর্তনে প্রকৃত বিবর্তন বা
                বিবর্তনের নিয়মগুলিকে যথাযথভাবে অনুসরণ করা হয় না। এরজন্য
                বস্তুগত বিবর্তনকে প্রকৃত বিবর্তন বলা যায় না। যে কারণগুলির জন্য
                বস্তুগত বিবর্তনকে প্রকৃত বিবর্তন বলা যায় না, সেগুলি নীচে আলোচনা
                করা হল -<br />
                <br />
                প্রথমত, বিবর্তনের নিয়ম অনুসারে, আশ্রয়বাক্য এবং সিদ্ধান্তের
                উদ্দেশ্যপদ ভিন্ন হবে। কিন্তু বস্তুগত বিবর্তনে আশ্রয়বাক্যের
                উদ্দেশ্যপদের বিপরীত পদ সিদ্ধান্তের উদ্দেশ্য হয়েছে। এতে
                বিবর্তনের নিয়ম লঙ্ঘিত হয়েছে।
                <br />
                <br />
                দ্বিতীয়ত, বিবর্তনের নিয়ম অনুসারে আশ্রয়বাক্যের বিধেয় পদের
                বিরুদ্ধ পদ সর্বদা সিদ্ধান্তের বিধেয় হয়। কিন্তু বস্তুগত
                বিবর্তনে আশ্রয়বাক্যের বিধেয় পদের বিপরীত পদ বা বিরুদ্ধ পদ
                সিদ্ধান্তের বিধেয় হয়।
                <br />
                <br />
                তৃতীয়ত,বিবর্তনের নিয়মানুসারে আশ্রয়বাক্য ও সিদ্ধান্তের গুণ
                ভিন্ন হবে। কিন্তু বস্তুগত বিবর্তনে আশ্রয়বাক্য ও সিদ্ধান্তের গুণ
                একই থাকে।
                <br />
                <br />
                চতুর্থত, অবরোহ অনুমানের প্রধান লক্ষ্য হল আকারগত সত্যতা অর্জন
                করা, বস্তুগত সত্যতা নয়। এরজন্য বিবর্তনে বচনের আকারের ওপর ভিত্তি
                করে বিবর্তন করা হয়, যা অর্থ জানার জন্য কোনো প্রয়োজন নেই। তবে,
                বাস্তব অভিজ্ঞতা বা বাস্তব জ্ঞান না থাকলে বস্তুগত বিবর্তন সম্ভব
                নয়।
                <br />
                <br />
                পঞ্চমত, প্রকৃত বিবর্তন অবরোহমূলক। এর সিদ্ধান্ত প্রচ্ছন্নভাবে
                আশ্রয়বাক্যে নিহিত থাকে। কিন্তু বস্তুগত বিবর্তনের ক্ষেত্রে,
                সিদ্ধান্ত আশ্রয়বাক্যে নিহিত থাকে না। সিদ্ধান্তটি সম্পূর্ণ নতুন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  {" "}
                  2. নিম্নলিখিত বাক্যগুলির আবর্তিতের বিবর্তন করো/আবর্তন করে
                  বিবর্তন করো/আবর্তের বিবর্ত করো : 2 x 4 = 8
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>
              </font>
              <font color="#3c92e5">
                (i) মানুষ সর্বাঙ্গসুন্দর হতে পারে না।
                <br />
                <font color="#f626d7"> যুক্তিবিজ্ঞানসম্মত আকার : </font> (E)
                কোনো মানুষ নয় সর্বাঙ্গসুন্দর। (প্রদত্ত বচন)
                <br />
                <font color="#f626d7"> প্রদত্ত বচনের আবর্তিত : </font> (E) কোনো
                সর্বাঙ্গসুন্দর প্রাণী নয় মানুষ।
                <br />
                <font color="#f626d7"> আবর্তিতের বিবর্তিত : </font> (A) সকল
                সর্বাঙ্গসুন্দর প্রাণী হয় অ-মানুষ।
                <br />
                <br />
                (ii) একজন ব্যবসায়ী সৎ।
                <br />
                <font color="#f626d7"> যুক্তিবিজ্ঞানসম্মত আকার : </font> (I)
                কোনো কোনো ব্যবসায়ী হয় সৎ। (প্রদত্ত বচন)
                <br />
                <font color="#f626d7"> প্রদত্ত বচনের আবর্তিত : </font> (I) কোনো
                কোনো সৎ ব্যক্তি হয় ব্যবসায়ী।
                <br />
                <font color="#f626d7"> আবর্তিতের বিবর্তিত : </font> (O) কোনো
                কোনো সৎব্যক্তি হয় অব্যবসায়ী। <br />
                <br />
                (iii) শিশুরা কদাচিৎ মিথ্যা বলে।
                <br />
                <font color="#f626d7"> যুক্তিবিজ্ঞানসম্মত আকার : </font> (O)
                কোনো কোনো শিশু নয় মিথ্যাবাদী। (প্রদত্ত বচন)
                <br />
                <font color="#f626d7"> প্রদত্ত বচনের আবর্তিত : </font> 'O' বচনের
                আবর্তন সম্ভব নয়।
                <br />
                <font color="#f626d7"> আবর্তিতের বিবর্তিত : </font> যেহেতু,
                এক্ষেত্রে আবর্তিত সম্ভব হয়নি, সেহেতু আবর্তিতের বিবর্তিতও সম্ভব
                নয়।
                <br />
                <br />
                (iv) এমন কোনো বিজ্ঞানী নেই যে যুক্তিবাদী না।
                <br />
                <font color="#f626d7"> যুক্তিবিজ্ঞানসম্মত আকার : </font> (A) সকল
                বিজ্ঞানী হয় যুক্তিবাদী। (প্রদত্ত বচন)
                <br />
                <font color="#f626d7"> প্রদত্ত বচনের আবর্তিত : </font> (1) কোনো
                কোনো যুক্তিবাদী ব্যক্তি হয় বিজ্ঞানী।
                <br />
                <font color="#f626d7"> আবর্তিতের বিবর্তিত : </font> (O) কোনো
                কোনো যুক্তিবাদী বিজ্ঞানী নয় অ-বিজ্ঞানী f|
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  3. বিবর্তন কাকে বলে? দৃষ্টান্তসহ বিবর্তনের নিয়মগুলি ব্যাখ্যা
                  করো। বিবর্তনকে কেন অমাধ্যম অনুমান বলা হয়? 2 + 4 + 2 = 8
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে অমাধ্যম অনুমানে হেতুবাক্যের বিধেয় পদের বিরুদ্ধ পদটিকে
                সিদ্ধান্তে বিধেয় হিসেবে গ্রহণ করা হয় এবং হেতুবাক্যের
                গুণাদেশটির পরিবর্তন করে একটি সিদ্ধান্ত অনিবার্যভাবে নিঃস্বার্থ্য
                করা হয়, এই প্রক্রিয়াকে "বিবর্তন" বলা হয়। <br />
                যেমন — সকল মানুষ হয় মরণশীল। (A)
                <br />
                কোনো মানুষ নয় অ-মরণশীল। (E)
                <br />
                <br />
                <font color="#f626d7">
                  <b> বিবর্তনের নিয়ম : </b>
                </font>
                <br />
                (i) বিবর্তনে আশ্রয়বাক্য ও সিদ্ধান্তের উদ্দেশ্যপদ একই হবে।
                <br />
                যেমন — (O) কোনো কোনো মানুষ নয় দরিদ্র। (আশ্রয়বাক্য)
                <br />
                (I) কোনো কোনো মানুষ হয় অ-দরিদ্র। (সিদ্ধান্ত)
                <br />
                <br />
                (ii) আশ্রয়বাক্যের বিধেয়পদের বিরুদ্ধপদ সিদ্ধান্তের বিধেয় হবে।
                <br />
                যেমন — (E) কোনো মানুষ নয় পশু। (আশ্রয়বাক্য)
                <br />
                (A) সকল মানুষ হয় অ-পশু। (সিদ্ধান্ত)
                <br />
                <br />
                (iii) আশ্রয়বাক্য ও সিদ্ধান্তের গুণ ভিন্ন হবে। অর্থাৎ,
                আশ্রয়বাক্য সদর্থক হলে সিদ্ধান্ত নঞর্থক হবে। আর আশ্রয়বাক্য
                নঞর্থক হলে সিদ্ধান্ত সদর্থক হবে।
                <br />
                যেমন — (A) সকল কবি হয় জ্ঞানী। (আশ্রয়বাক্য)
                <br />
                (E) কোনো কবি নয় অ-জ্ঞানী। (সিদ্ধান্ত)
                <br />
                <br />
                (iv) আশ্রয়বাক্য ও সিদ্ধান্তের পরিমাণ এক হবে। অর্থাৎ,
                আশ্রয়বাক্য যদি সামান্য হয়, তাহলে সিদ্ধান্তও সামান্য হবে। আর
                আশ্রয়বাক্য যদি বিশেষ হয়, তাহলে সিদ্ধান্তও বিশেষ হবে।
                <br />
                যেমন —(I) কোনো কোনো ধাতু হয় কঠিন। (আশ্রয়বাক্য)
                <br />
                (O) কোনো কোনো ধাতু নয় অ-কঠিন। (সিদ্ধান্ত)
                <br />
                <br />
                বিবর্তনকে অমাধ্যম অনুমান বলার কারণ : অমাধ্যম অনুমানে মোট দুটি
                বচন থাকে – একটি আশ্রয়বাক্য ও অপরটি সিদ্ধান্ত। অমাধ্যম অনুমানের
                সিদ্ধান্তটি একটিমাত্র আশ্রয়বাক্য থেকে অনিবার্যভাবে নিঃসৃত হয়।
                বিবর্তনের ক্ষেত্রেও একটিমাত্র আশ্রয়বাক্য থেকে সিদ্ধান্ত
                অনিবার্যভাবে নিঃসৃত হয়। অর্থাৎ, এক্ষেত্রেও দুটি বচন থাকে — একটি
                আশ্রয়বাক্য ও অন্যটি সিদ্ধান্ত। তাই বিবর্তনকে অমাধ্যম অনুমান বলা
                হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  4. অমাধ্যম অনুমান কাকে বলে? দৃষ্টান্ত সহকারে মাধ্যম এবং
                  অমাধ্যম অনুমানের মধ্যে পার্থক্য ব্যাখ্যা করো। 2 + 6 = 8
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে অবরোহ অনুমানে একটিমাত্র আশ্রয়বাক্য থেকে সিদ্ধান্তটি
                অনিবার্যভাবে নিঃসৃত হয়, তাকে অমাধ্যম অনুমান বলে।
                <br />
                যেমন — কোনো কোনো শিক্ষিত ব্যক্তি হয় বেকার। (আশ্রয়বাক্য)
                <br />
                কোনো কোনো বেকার হয় শিক্ষিত ব্যক্তি। (সিদ্ধান্ত)
                <br />
                <br />
                <font color="#f626d7">
                  <b> মাধ্যম এবং অমাধ্যম অনুমানের পার্থক্য : </b>
                </font>{" "}
                <br />
                মাধ্যম এবং অমাধ্যম উভয় অনুমানই অবরোহ অনুমানের অন্তর্ভুক্ত। ফলে
                উভয় অনুমানের ক্ষেত্রেই সিদ্ধান্তটি আশ্রয়বাক্য থেকে
                অনিবার্যভাবে নিঃসৃত হয় এবং উভয় অনুমানের ক্ষেত্রেই আকারগত
                সত্যতা বিচার করা হয়, কোনোটির ক্ষেত্রেই বস্তুগত সত্যতার দিকে
                লক্ষ্য রাখা হয় না। কিন্তু তবুও মাধ্যম ও অমাধ্যম অনুমানের মধ্যে
                কিছু সুস্পষ্ট পার্থক্য বর্তমান। পার্থক্যগুলি নিম্নরূপ :<br />
                <br />
                প্রথমত, মাধ্যম অনুমানের ক্ষেত্রে সিদ্ধান্তটি সর্বদা দুই বা তার
                বেশি আশ্রয়বাক্য থেকে অনিবার্যভাবে নিঃসৃত হয়। যেমন –<br />
                (A) সকল জ্ঞানীব্যক্তি হয় সৎ। (আশ্রয়বাক্য)
                <br />
                (E) কোনো চোর নয় জ্ঞানীব্যক্তি। (আশ্রয়বাক্য) <br />
                (E) কোনো চোর নয় সৎ। (সিদ্ধান্ত)
                <br />
                <br />
                অপরদিকে, অমাধ্যম অনুমানের ক্ষেত্রে সিদ্ধান্তটি সর্বদা একটি
                আশ্রয়বাক্য থেকে অনিবার্যভাবে নিঃসৃত হয়।
                <br />
                যেমন —(I) কোনো কোনো সাহিত্যিক হয় কবি। (আশ্রয়বাক্য)
                <br />
                (I) কোনো কোনো কবি হয় সাহিত্যিক। (সিদ্ধান্ত)
                <br />
                <br />
                দ্বিতীয়ত, মাধ্যম অনুমানে সাধারণত তিনটি পদ থাকে। যথা—পক্ষপদ,
                সাধ্যপদ এবং হেতুপদ। এই তিনটি পদই দুবার করে ব্যবহৃত হয়।
                <br />
                যেমন —(A) সকল সৈনিক হয় সাহসী। (প্রধান আশ্রয়বাক্য)
                <br />
                (A) সকল যোদ্ধা হয় সৈনিক। (অপ্রধান আশ্রয়বাক্য)
                <br />
                (A) সকল যোদ্ধা হয় সাহসী। (সিদ্ধান্ত)
                <br />
                <br />
                এই মাধ্যম অনুমানটিতে তিনটি পদ রয়েছে। যথা — যোদ্ধা, সাহসী এবং
                সৈনিক এবং প্রতিটি পদ দুবার করে ব্যবহৃত হয়েছে।
                <br />
                অপরদিকে, অমাধ্যম অনুমানে সাধারণত দুটি পদ থাকে — উদ্দেশ্যপদ এবং
                বিধেয়পদ।
                <br />
                এখানে দুটি পদই দুবার করে ব্যবহৃত হয়। অবশ্য কিছু ক্ষেত্রে
                বিরুদ্ধ পদের ব্যবহার করা হয়।
                <br />
                যেমন —(E) কোনো মানুষ নয় দেবতা। (আশ্রয়বাক্য)
                <br />
                ..(E) কোনো দেবতা নয় মানুষ। (সিদ্ধান্ত)
                <br />
                <br />
                এই অমাধ্যম অনুমানটিতে দুটি পদ রয়েছে। যথা — মানুষ দেবতা এবং পদ
                দুটি দুবার করে ব্যবহৃত হয়েছে।
                <br />
                <br />
                তৃতীয়ত, মাধ্যম অনুমান সাধারণত তিনটি বচন দ্বারা গঠিত হয়। এই
                তিনটি বচনের মধ্যে দুটি বচন আশ্রয়বাক্য এবং অপর বচনটি সিদ্ধান্ত
                হয়।
                <br />
                অপরদিকে, অমাধ্যম অনুমান সর্বদা দুটি বচন দ্বারা গঠিত হয়। এই দুটি
                বচনের মধ্যে একটিকে বলা হয় আশ্রয়বাক্য এবং অপর বচনটিকে বলা হয়
                সিদ্ধান্ত।
                <br />
                <br />
                চতুর্থত, মাধ্যম অনুমানের ক্ষেত্রে কোনো একটি আশ্রয়বাক্য থেকে
                সিদ্ধান্তটি কখনোই সরাসরি নিঃসৃত হয় না।এক্ষেত্রে সিদ্ধান্ত
                প্রতিষ্ঠার জন্য মধ্যস্থতাকারী বচনের প্রয়োজনীয়তা থাকবেই। এরজন্য
                মাধ্যম অনুমানকে পরোক্ষ অনুমান বলা হয়।
                <br />
                <br />
                অপরদিকে, অমাধ্যম অনুমানের ক্ষেত্রে আশ্রয়বাক্য থেকে সিদ্ধান্তটি
                সরাসরি প্রতিষ্ঠা করা যায়, এক্ষেত্রে মধ্যস্থতাকারী কোনো বচনের
                প্রয়োজন হয় না। এরজন্য অমাধ্যম অনুমানকে অপরোক্ষ অনুমান বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  5. আবর্তনের সংজ্ঞা দাও। আবর্তনের নিয়মগুলি লেখো। ‘O’ বচনের
                  আবর্তন সম্ভব নয় কেন? 2 + 2 + 4 = 8{" "}
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে অমাধ্যম অনুমানে আশ্রয়বাক্য ও সিদ্ধান্তের গুণ অপরিবর্তিত রাখা
                হয় এবং আশ্রয়বাক্যের উদ্দেশ্য ও বিধেয়পদকে ন্যায়সংগতভাবে
                যথাক্রমে সিদ্ধান্তের বিধেয় ও উদ্দেশ্য পদে পরিণত করা হয়, তাকে
                আবর্তন বলে।
                <br />
                যেমন — কোনো মানুষ নয় পশু। (E)
                <br />
                কোনো পশু নয় মানুষ। (E)
                <br />
                <br />
                <font color="#f626d7">
                  <b> আবর্তনের নিয়ম : </b>
                </font>
                <br />
                (i) আশ্রয়বাক্যের উদ্দেশ্যপদ সিদ্ধান্তের বিধেয়পদ হবে এবং
                আশ্রয়বাক্যের বিধেয়পদ সিদ্ধান্তের উদ্দেশ্যপদ হবে।
                <br />
                (ii) আশ্রয়বাক্য ও সিদ্ধান্তের গুণ অপরিবর্তিত থাকবে। অর্থাৎ,
                আশ্রয়বাক্য সদর্থক হলে সিদ্ধান্ত সদর্থক হবে। আর আশ্রয়বাক্য
                নঞর্থক হলে সিদ্ধান্ত নঞর্থক হবে।
                <br />
                <br />
                (iii) যে পদ আশ্রয়বাক্যে ব্যাপ্য হবে না, সেই পদ সিদ্ধান্তে
                ব্যাপ্য হতে পারবে না। ‘O’ বচনের আবর্তন সম্ভব নয়। কারণ ‘O’ বচনের
                আবর্তন করলে আবর্তনের নিয়ম লঙ্ঘিত হয়। ‘O’ বচনকে আবর্তিত করে যদি
                ‘O’ বচন করা হয় তাহলে দেখা যাবে, যে পদ আশ্রয়বাক্যে ব্যাপ্য
                হয়নি সেই পদ সিদ্ধান্তে ব্যাপ্য হয়েছে।
                <br />
                যেমন — কোনো কোনো শিক্ষক নয় কবি। (O)
                <br />
                .:. কোনো কোনো কবি নয় শিক্ষক। (O)
                <br />
                <br />
                এক্ষেত্রে আশ্রয়বাক্যের উদ্দেশ্যপদ ‘শিক্ষক’ ব্যাপ্য হয়নি।
                কিন্তু এই পদটি সিদ্ধান্তে ‘O’ বচনের বিধেয়ে থাকায় ব্যাপ্য
                হয়েছে। ফলে আবর্তনের ব্যাপ্যতার নিয়ম লঙ্ঘিত হয়েছে। ‘O’ বচনকে
                আবর্তিত করে যদি ‘E’ বচন করা হয় তাহলেও আবর্তনের ব্যাপ্যতার নিয়ম
                লঙ্ঘিত হবে।
                <br />
                <br />
                যেমন — কোনো কোনো বিজ্ঞানী নয় দার্শনিক। (O)
                <br />
                :. কোনো দার্শনিক নয় বিজ্ঞানী। (E)
                <br />
                <br />
                এক্ষেত্রে আশ্রয়বাক্যের উদ্দেশ্যপদ ‘বিজ্ঞানী’ ব্যাপ্য হয়নি।
                কিন্তু এই পদটি সিদ্ধান্তে ব্যাপ্য হয়েছে। ফলে আবর্তনের
                ব্যাপ্যতার নিয়ম এক্ষেত্রেও লঙ্ঘিত হয়েছে। ‘O’ বচনকে A’ বা ‘I’
                বচনে আবর্তিত করা যায় না। কেননা ‘O’ বচন নঞর্থক বচন, কিন্তু ‘A’
                এবং ‘I’ বচন সদর্থক বচন। ‘O’ বচনকে যদি ‘A’ বা ‘I’ বচনে আবর্তিত
                করা হয় তবে আশ্রয়বাক্য ও সিদ্ধান্তের গুণ ভিন্ন হয়ে যাবে। ফলে
                আবর্তনের গুণের নিয়ম লঙ্ঘিত হবে। এই সকল কারণে ‘O’ বচনের আবর্তন
                সম্ভব নয়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

অমাধ্যমঅনুমান.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(অমাধ্যমঅনুমান);
