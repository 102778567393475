import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function ঔপনিবেশিকআধিপত্যেরপ্রকৃতিনিয়মিতওঅনিয়মিতসাম্রাজ্য() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          ঔপনিবেশিক আধিপত্যের প্রকৃতি : নিয়মিত ও অনিয়মিত সাম্রাজ্য | West
          Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="ঔপনিবেশিক আধিপত্যের প্রকৃতি : নিয়মিত ও অনিয়মিত সাম্রাজ্য | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                ঔপনিবেশিক আধিপত্যের প্রকৃতি : নিয়মিত ও অনিয়মিত সাম্রাজ্য
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ২৯ আগস্ট, ১৮৪২ খ্রিস্টাব্দে চিনে কোন্ চুক্তি স্বাক্ষরিত হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  নানকিং সন্ধি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ওয়ামপোয়া সন্ধি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পিকিং সন্ধি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তিয়েনসিন সন্ধি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>চিনে আফিম/অহিফেন যুদ্ধ সংঘটিত হয়েছিল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  একটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  দুটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তিনটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চারটি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  কোন্ গভর্নর জেনারেলের আমলে পাঞ্জাব ব্রিটিশ সাম্রাজ্যভুক্ত
                  (১৮৪৯ খ্রিস্টাব্দ) হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উইলিয়াম বেন্টিঙ্ক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ড কর্নওয়ালিশ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  লর্ড ডালহৌসি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ড ওয়েলেসলি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>শ্রীরঙ্গপত্তনমের সন্ধি স্বাক্ষরিত হয় - </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৭৮৪ খ্রিস্টাব্দে (দ্বিতীয় ইঙ্গ-মহীশূর যুদ্ধের পর)
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৭৯৯ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ১৭৯২ খ্রিস্টাব্দে (তৃতীয় ইঙ্গ-মহীশুর যুদ্ধের পর)
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৭৬৯ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>স্বত্ববিলোপ নীতি (১৮৪৮ খ্রিস্টাব্দ) প্রবর্তন করেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উইলিয়াম বেন্টিঙ্ক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ড কর্নওয়ালিশ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  লর্ড ডালহৌসি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ড ওয়েলেসলি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ভারতের যে রাজ্যে প্রথম রেলপথ (১৮৫৩ খ্রিস্টাব্দ) স্থাপিত হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মহারাষ্ট্রে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কেরলে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তামিলনাড়ুতে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পশ্চিমবঙ্গে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ব্রিটিশ ইস্ট ইন্ডিয়া কোম্পানি মোগল সম্রাট ফারুকশিয়ারের কাছ
                  থেকে বাংলায় দস্তক লাভ করে -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৭০৭ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ১৭১৭ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৮১৬ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৮২৬ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ১৭৬৫ খ্রিস্টাব্দে ইংরেজ ইস্ট ইন্ডিয়া কোম্পানিকে বাংলা, বিহার,
                  ওড়িশার দেওয়ানি প্রদান করেন মোগল সম্রাট —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  দ্বিতীয় শাহ আলম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিরজুমলা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দ্বিতীয় বাহাদুর শাহ্
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সুজাউদ্দিন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>'ভারত ইতিহাসের জলবিভাজিকা' বলা হয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৮৫৮ খ্রিস্টাব্দকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৮৪২ খ্রিস্টাব্দেকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ১৮১৮ খ্রিস্টাব্দেকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৮৪৫ খ্রিস্টাব্দেকে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>‘গ্যারান্টি প্রথা' যুক্ত ছিল কোন্ ব্যবস্থার সঙ্গে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  রেল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিমান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জাহাজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডাক
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="3">
                <b>
                  তৃতীয় অধ্যায় ➺ ঔপনিবেশিক আধিপত্যের প্রকৃতি : নিয়মিত ও
                  অনিয়মিত সাম্রাজ্য
                </b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রশ্নের মান - ৮</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                1. ক্যান্টন বাণিজ্যের প্রধান বৈশিষ্ট্যগুলি কী ছিল? এই বাণিজ্যের
                অবসান কেন হয়?
                <br />
                2. ভারতে রেলপথ প্রবর্তনের উদ্দেশ্য ও প্রভাব আলোচনা করো।
                <br />
                3. ব্রিটিশ শাসনকালে ভারতে আমলাতন্ত্রের বিকাশ সম্পর্কে আলোচনা
                করো।
                <br />
                4. ব্রিটিশ ইস্ট ইন্ডিয়া কোম্পানির আমলে ভারতের ভূমি রাজস্ব
                ব্যবস্থার সংক্ষিপ্ত পরিচয় দাও৷ <br />
                5. সিরাজ-উদদৌলার সঙ্গে ইংরেজ ইস্ট ইন্ডিয়া কোম্পানির বিরোধের
                কারণগুলি আলোচনা করো।
                <br />
                6. চিনের ওপর আরোপিত বিভিন্ন অসম চুক্তিগুলির সংক্ষিপ্ত আলোচনা
                করো।
                <br />
                7. ভারতে অবশিল্পায়নের কারণগুলি কী ছিল? ভারতীয় অর্থনীতির ওপর এর
                প্রভাব আলোচনা করো।
                <br />
                8. বাংলার সমাজ ও অর্থনীতিতে চিরস্থায়ী বন্দোবস্তের প্রভাবসমূহ
                পর্যালোচনা করো। দেশীয় বস্ত্রশিল্পের ওপর অবশিল্পায়নের কী প্রভাব
                পড়েছিল?
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রশ্নের মান - ১</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. 'কুয়োমিনতাং’ দলের প্রতিষ্ঠাতা কে?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ‘কুয়োমিনতাং’ দলের প্রতিষ্ঠাতা হলেন সান-ইয়াৎ-সেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. মেকলে তাঁর বিখ্যাত 'মেকলে মিনিট' কবে প্রকাশ করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮৩৫ খ্রিস্টাব্দে মেকলে তাঁর বিখ্যাত ‘মেকলে মিনিট’ প্রকাশ করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    3. কোন যুদ্ধের মাধ্যমে চিনে ক্যান্টন বাণিজ্যের অবসান ঘটে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আফিম যুদ্ধের মাধ্যমে চিনে ক্যান্টন বাণিজ্যের অবসান ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    4. কবে, কারা কাদের বিরুদ্ধে দ্বিতীয় অহিফেন যুদ্ধ ঘোষণা করে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮৫৬ খ্রিস্টাব্দে ব্রিটেন চিনের বিরুদ্ধে দ্বিতীয় অহিফেন যুদ্ধ
                ঘোষণা করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. কে প্রথম পাটকল স্থাপন করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জর্জ অকল্যান্ড ১৮৫৫ খ্রিস্টাব্দে পাটকল স্থাপন করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. ‘আধুনিক পাঞ্জাবের জনক' কাকে বলা হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                লর্ড ডালহৌসিকে ‘আধুনিক পাঞ্জাবের জনক' বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. জেমস মিল রচিত দুটি গ্রন্থের নাম লেখো।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জেমস মিল রচিত দুটি গ্রন্থ হল – 'An Essay on Government' ও Laws
                of Nations'
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    8. প্রথম আফিমের যুদ্ধের ফলে কবে, কোন্ সন্ধি স্বাক্ষরিত
                    হয়েছিল?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রথম আফিমের যুদ্ধে চিন পরাজিত হয়ে ১৮৪২ খ্রিস্টাব্দে ২৯ আগস্ট
                নানকিং সন্ধি স্বাক্ষর করতে বাধ্য হয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    9. দ্বিতীয় আফিমের যুদ্ধ কবে, কাদের মধ্যে সংঘটিত হয়েছিল?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দ্বিতীয় আফিমের যুদ্ধ ১৮৫৬ খ্রিস্টাব্দ থেকে ১৮৫৮ খ্রিস্টাব্দের
                মধ্যে চিনের সঙ্গে ইংল্যান্ড ও ফ্রান্সের যৌথবাহিনীর মধ্যে সংঘটিত
                হয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. বক্সারের যুদ্ধ কবে, কাদের মধ্যে হয়েছিল?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৭৬৪ খ্রিস্টাব্দে মিরকাশিম ও ইংরেজদের মধ্যে বক্সারের যুদ্ধ
                হয়েছিল।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. প্রথম ইঙ্গ-মহীশূর যুদ্ধ কবে শুরু হয়েছিল?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ১৭৬৭ খ্রিস্টাব্দে প্রথম ইঙ্গ-মহীশূর যুদ্ধ শুরু হয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. কোন্ যুদ্ধে টিপু সুলতান পরাজিত ও নিহত হন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৭৯৯ খ্রিস্টাব্দে চতুর্থ ইঙ্গ-মহীশূর যুদ্ধে টিপু সুলতান পরাজিত ও
                নিহত হন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. কে, কবে পাঞ্জাবকে ব্রিটিশ সাম্রাজ্যভুক্ত করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বড়োলাট লর্ড ডালহৌসি ১৮৪৯ খ্রিস্টাব্দে দ্বিতীয় ইঙ্গ-শিখ যুদ্ধে
                জয়লাভের মাধ্যমে পাঞ্জাব রাজ্যটি ব্রিটিশ সাম্রাজ্যভুক্ত করেন।
                পাঞ্জাবে প্রথম ব্রিটিশ প্রশাসক নিযুক্ত হন হেনরি লরেন্স।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. সর্বশেষ চার্টার অ্যাক্ট কবে প্রবর্তিত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮৫৩ খ্রিস্টাব্দে সর্বশেষ চার্টার অ্যাক্ট প্রবর্তিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    15. কোন্ আইন দ্বারা কলকাতায় সুপ্রিমকোর্ট (১৭৭৪
                    খ্রিস্টাব্দে) স্থাপিত হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৭৭৩ খ্রিস্টাব্দে রেগুলেটিং অ্যাক্টের দ্বারা ১৭৭৪ খ্রিস্টাব্দে
                কলকাতায় সুপ্রিম কোর্ট স্থাপিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    16. কোন্ দুটি স্থানের মধ্যে ভারতে প্রথম রেলপথ স্থাপিত হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮৫৩ সালে বোম্বাই থেকে থানে পর্যন্ত ২১ মাইল দুরত্বে ভারতের প্রথম
                রেলপথ স্থাপিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. স্বত্ববিলোপ নীতি কে প্রবর্তন করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বড়লাট লর্ড ডালহৌসি ১৮৪৮ খ্রিস্টাব্দে রাজ্যগ্রাসী স্তত্ববিলোপ
                নীতি প্রবর্তন করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. চিনে মুক্তদ্বার নীতি কে প্রবর্তন করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মার্কিন পররাষ্ট্র সচিব জন হে ১৮৯৯ খ্রিস্টাব্দে চিনে মুক্তদ্বার
                নীতি (open door policy) ঘোষণা করেন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  19. আলিনগরের সন্ধি কত খ্রিস্টাব্দে কাদের মধ্যে স্বাক্ষরিত হয়?
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ১৭৫৭ খ্রিস্টাব্দের ৯ ফেব্রুয়ারি সিরাজ-উদ্-দৌলা ও ইংরেজ ইস্ট
                ইন্ডিয়া কোম্পানির মধ্যে আলিনগরের সন্ধি স্বাক্ষরিত হয়। এই
                সন্ধির দ্বারা ইংরেজ কোম্পানি বাংলায় বিনাশুল্কে বাণিজ্য, দুর্গ
                নির্মাণ ও নিজ নামাঙ্কিত মুদ্রা প্রচলনের অধিকার পায়। এর ফলে
                কোম্পানির প্রভাব-প্রতিপত্তি ও তাদের রাজনৈতিক উচ্চাকাঙ্ক্ষা
                বৃদ্ধি পায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. কোন্ ভূমিরাজস্ব ব্যবস্থা জমিদারদের জমির উপর মালিকানা
                    স্বত্ব দিয়েছিল?
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৭৯৩ খ্রিস্টাব্দে বাংলা সুবায় লর্ড কর্নওয়ালিস কর্তৃক প্রবর্তিত
                চিরস্থায়ী বন্দোবস্ত জমিদারদের জমির উপর মালিকানা স্বত্ত্ব প্রদান
                করেছিল। এই অধিকার বলে জমিদার রাজস্ব প্রদানে অক্ষম কৃষককে তার জমি
                থেকে উৎখাত করে তার জমি দখল করে নিতে পারত এইভাবে স্বাধীন কৃষক
                ক্রমে ভূমিহীন কৃষি-শ্রমিকে পরিণত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. পলাশির লুণ্ঠন বলতে কী বোঝো?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                পলাশির যুদ্ধের পর মসনদে বসে মিরজাফর লর্ড ক্লাইভ ও অন্যান্য ইংরেজ
                কর্মচারীদের প্রায় ১.৭০ কোটি টাকা ও অন্যান্য উপঢৌকন দান করেন।
                বাৎসরিক প্রায় দেড় লক্ষ পাউন্ড আয়ের জমিদারি কোম্পানিকে ছেড়ে
                দিতে হয়। ফলে রাজকোশ শূন্য হয়ে যায় এর ওপর কোম্পানি ও তার
                কর্মচারীরা ব্যক্তিগত বাণিজ্য ও অন্যান্য উপায়ে বাংলার আর্থিক
                সম্পদ শোষণ করতে থাকে। এই অবস্থাকে ‘পলাশির লুণ্ঠন’ বলে বর্ণনা করা
                হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    22. ভারতের সুপ্রিম কোর্টের প্রথম প্রধান বিচারপতি কে ছিলেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের সুপ্রিম কোর্টের প্রথম প্রধান বিচারপতি ছিলেন স্যার এলিজা
                ইম্পে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. সূর্যাস্ত আইন বলতে কী বোঝো?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                লর্ড কর্নওয়ালিস প্রবর্তিত চিরস্থায়ী বন্দোবস্তে বলা হয় যে,
                প্রতি বাংলা বছরের শেষ দিন সূর্য অস্ত যাওয়ার আগে জমিদার বা
                তালুকদারকে তাদের প্রদেয় রাজস্ব পরিশোধ করতে হবে। অন্যথায় তার
                সমগ্র জমিদারি বা জমিদারির অংশ বিশেষ সরকার বাজেয়াপ্ত করে নেবে।
                এই বিধি ‘সূর্যাস্ত আইন’ নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. ক্যান্টন বাণিজ্য কাকে বলে?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ‘স্বর্গীয় সাম্রাজ্যের অধীশ্বর’ চিনের মাঞ্জু রাজবংশ বিদেশিদের
                সঙ্গে যাবতীয় সংযোগ ত্যাগ করে চলত, অন্যদিকে ইউরোপীয় জাতিগুলি
                চিনের সঙ্গে বাণিজ্যিক সম্পর্ক স্থাপনে বদ্ধ পরিকর ছিল।
                এমতাবস্থায় চিনের আদালত ১৭৫৯ খ্রিস্টাব্দে এক নির্দেশনামার দ্বারা
                একমাত্র ক্যান্টন বন্দরকেই বিদেশি বাণিজ্যের জন্য উন্মুক্ত বলে
                ঘোষণা করে। এইভাবে ক্যান্টন বন্দরকে কেন্দ্র করে চিনে বিদেশিদের যে
                এক-বন্দরকেন্দ্রিক বাণিজ্যপ্রথার সূচনা হয়, তা-ই ‘ক্যান্টন
                বাণিজ্যপ্রথা' নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. রায়তওয়ারি বন্দোবস্ত বলতে কী বোঝো?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮২০ খ্রিস্টাব্দে স্যার টমাস মনরো ও ক্যাপ্টেন আলেকজান্ডার রিড
                দক্ষিণ ভারতের মাদ্রাজ প্রেসিডেন্সিতে রায়তওয়ারি বন্দোবস্ত
                প্রবর্তন করেন। এই ব্যবস্থায় সরকার সরাসরি রায়ত বা কৃষকের সঙ্গে
                রাজস্ব বন্দোবস্ত করত, কোনো মধ্যস্বত্বভোগীর সঙ্গে নয়। এই
                ব্যবস্থায় উৎপাদনের মোট খরচ বাদ দিয়ে যা বাকি থাকবে তার ৫০ শতাংশ
                রায়তদের ভূমিরাজস্ব হিসেবে নির্ধারিত হত। রায়ত সরাসরি সরকারের
                কাছে রাজস্ব প্রদান করত বলে এই ভূমিব্যবস্থা ‘রায়তওয়ারি
                বন্দোবস্ত' নামে পরিচিত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>26. মহলওয়ারি বন্দোবস্ত বলতে কী বোঝো?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                হোল্ট ম্যাকেঞ্জি ও এলফিনস্টোন ১৮২২ খ্রিস্টাব্দে উত্তরপ্রদেশ,
                মধ্যপ্রদেশ ও দিল্লি অঞ্চলে মহলওয়ারি বন্দোবস্ত প্রবর্তন করেন। এই
                ব্যবস্থায় কয়েকটি গ্রাম নিয়ে এক—একটি ‘মহল’ বা তালুক সৃষ্টি করা
                হত এবং কোনো প্রভাবশালী ব্যক্তি বা ব্যক্তিবর্গকে যৌথভাবে রাজস্ব
                দেবার শর্তে ওই মহলের ইজারা দেওয়া হত। ‘মহল’ ধরে জমি বন্দোবস্ত
                দেওয়া হত বলে এই ব্যবস্থা মহলওয়ারি বন্দোবস্ত নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. ভারতে কবে রেলপথ প্রথম স্থাপিত হয়?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বড়োলাট লর্ড ডালহৌসির উদ্যোগে ১৮৫৩ খ্রিস্টাব্দে বোম্বাই থেকে
                থানে পর্যন্ত ২১ মাইল রেল যোগাযোগের মাধ্যমে ভারতে প্রথম রেলপথ
                স্থাপিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. 'দস্তক’ কী?/ফারুকশিয়ারের ফরমান বলতে কী বোঝো?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ‘দস্তক’ হল এক ধরনের বাণিজ্যিক ছাড়পত্র। ১৭১৭ খ্রিস্টাব্দে মোগল
                সম্রাট ফারুকশিয়ারের ফরমানে প্রাপ্ত এই অধিকার বলে কোম্পানি
                ‘দস্তক’ বা ছাড়পত্র দেখিয়ে বাংলায় বিনাশুল্কে অভ্যন্তরীণ
                বাণিজ্য চালাতে পারত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. ১৮১৩ খ্রিস্টাব্দের সনদ আইনের গুরুত্ব কী ছিল?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                1. ভারতে ইংরেজ ইস্ট ইন্ডিয়া কোম্পানির একচেটিয়া বাণিজ্যের অবসান
                হয় এবং অন্য যেকোনো ব্রিটিশ কোম্পানির জন্য ভারতে বাণিজ্যের দরজা
                খুলে যায়। 2. এই আইনের ২৯ নং ধারায় বলা হয় যে, এখন থেকে
                ভারতীয়দের শিক্ষার জন্য কোম্পানি বছরে অন্তত এক লক্ষ টাকা ব্যয়
                করবে। ভারতে ইংরেজ শাসনে এটাই প্রথম শিক্ষাখাতে ব্যয় বরাদ্দ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    30. কোন্ চার্টার আইনে ভারতে ইস্ট-ইন্ডিয়া কোম্পানির
                    একচেটিয়া বাণিজ্যের অধিকার বন্ধ হয়ে যায়?
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮১৩ খ্রিস্টাব্দের সনদ আইনে ভারতে ইংরেজ ইস্ট ইন্ডিয়া কোম্পানির
                একচেটিয়া বাণিজ্যের অবসান হয় এবং অন্য যে-কোনো ব্রিটিশ কোম্পানির
                নিকট ভারতে বাণিজ্যের দরজা খুলে যায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>31. কাও-তাও প্রথা কী?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                চিনা সম্রাটের প্রতি বিদেশি রাষ্ট্রের বশ্যতা প্রকাশস্বরূপ নজরানা
                প্রদানকারী রাষ্ট্রগুলির দূতেরা চিনা প্রথা অনুযায়ী তিনবার নতজানু
                হয়ে নয়বার মাথা ঠুকে সম্রাটকে অভিবাদন জানাতেন। এই প্রথা
                ‘কাও-তাও’ প্রথা নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. কবে এবং কাদের মধ্যে বেসিনের সন্ধি স্বাক্ষরিত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮০২ খ্রিস্টাব্দে পেশোয়া দ্বিতীয় বাজিরাও ও ইংরেজ ইস্ট ইন্ডিয়া
                কোম্পানির মধ্যে বেসিনের সন্ধি স্বাক্ষরিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. পলাশির বিতর্ক বলতে কী বোঝো?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পলাশির যুদ্ধের প্রেক্ষাপট বিশ্লেষণ করতে গিয়ে সাম্প্রতিককালের
                ঐতিহাসিক পি. জে. মার্শাল, রজতকান্ত রায়, ব্রিজেন গুপ্ত প্রমুখ
                মুর্শিদাবাদ দরবারে অভিজাতবর্গের সিরাজ-বিরোধী ষড়যন্ত্র,
                কোম্পানির বলপূর্বক দুর্গ নির্মাণ, দস্তকের অপব্যবহার প্রভৃতির ওপর
                গুরুত্ব আরোপ করেছেন। পক্ষান্তরে, এস. সি. হিল, সি. এ. বেইলি
                প্রমুখ নবাব সিরাজের অহমিকাবোধ, অর্থলোভ, কলকাতা আক্রমণ প্রভৃতিকে
                দায়ী করেছেন। এই বিতর্কই ‘পলাশির বিতর্ক’ নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. দ্বৈত শাসনব্যবস্থা বলতে কী বোঝো?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৭৬৫ খ্রিস্টাব্দে কোম্পানির দেওয়ানি লাভের ফলে কোম্পানি বাংলায়
                রাজস্ব আদায় ও দেওয়ানি বিচারের ভার পায়, আর নবাবের হাতে থাকে
                ফৌজদারি বিচার ও প্রশাসনিক দায়-দায়িত্ব। এইভাবে দেওয়ানি ও
                নিজামতের দায়িত্ব দুই আধিকারিকের হাতে থাকায় যে অভিনব শাসন
                ব্যবস্থার সূচনা হয়, তা ‘দ্বৈত শাসন’ নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. মহম্মদ রেজা খাঁ ও সিতাব রায় কারা?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৭৬৫ খ্রিস্টাব্দে ইংরেজ ইস্ট ইন্ডিয়া কোম্পানির দেওয়ানি লাভের
                পর কোম্পানির মনোনীত ব্যক্তি হিসেবে মহম্মদ রেজা খাঁ বাংলার এবং
                সিতাব রায় বিহারের ‘নায়েব নাজিম’ পদে নিযুক্ত হন। তারা কোম্পানির
                স্বার্থরক্ষার যন্ত্রে পরিণত হন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>36. কর্নওয়ালিস কোড বলতে কী বোঝো?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতে কোম্পানির কর্মচারীদের মধ্যে দায়িত্ববোধ ও শৃঙ্খলা
                প্রতিষ্ঠা তথা ভারতীয় বিচার ব্যবস্থাকে সুসংহত করার জন্য বড়োলাট
                লর্ড কর্নওয়ালিস ১৭৯৩ খ্রিস্টাব্দে কিছু নিয়মাবলি প্রবর্তন ও
                বিধিবদ্ধ করেন। এটি ‘কর্নওয়ালিস কোড’ বা ‘কর্নওয়ালিস সংহিতা’
                নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. আমিনি কমিশন কে কী উদ্দেশ্যে গঠন করেছিলেন?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ওয়ানের হেস্টিংস ১৭৭৬ খ্রিস্টাব্দে আমিনি কমিশন গঠন করেন।
                <br />
                <font color="#29900a">
                  <b>উদ্দেশ্য : </b>{" "}
                </font>{" "}
                (i) জমির সঠিক পরিমাপ নির্ধারণ, <br />
                (ii) পাঁচসালা বন্দোবস্তের ত্রুটি-বিচ্যুতি দূরীকরণ এবং <br />
                (ii) নতুন ভূমিরাজস্ব ব্যবস্থা প্রবর্তন সংক্রান্ত বিস্তৃত
                তথ্যানুসন্ধান ও প্রয়োজনীয় সুপারিশ প্রদান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. শোর-গ্রান্ট বিতর্ক বলতে কী বোঝো?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                লর্ড কর্নওয়ালিসের আমলে ভূমিরাজস্বের প্রকৃতি নিয়ে মতবিরোধের
                সৃষ্টি হয়। কোম্পানির রাজস্ব বিভাগের প্রধান স্যার জন শোর বলেন
                যে, মোগল আমলে জমিদাররাই ছিলেন জমির মালিক, তাই তাদের সঙ্গেই জমি
                বন্দোবস্ত করা উচিত। অন্যদিকে, কোম্পানির দলিল বিভাগের প্রধান
                গ্রান্ট বলেন যে, মোগল আমলে সরকার ছিলেন জমির মালিক, তাই সরকারী
                তত্ত্বাবধানেই জমির বন্দোবস্ত করা উচিত। এই বিতর্কই শোর-গ্রান্ট
                বিতর্ক নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. ‘অ্যাকওয়ার্থ কমিটি'র দুটি সুপারিশ উল্লেখ করো।</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতীয় রেলব্যবস্থার উন্নতিকল্পে ১৯১৯ খ্রিস্টাব্দে স্যার
                উইলিয়াম অ্যাকওয়ার্থের সভাপতিত্বে গঠিত ‘অ্যাকওয়ার্থ কমিটি’ যে
                সুপারিশ প্রদান করে, তাতে রেলব্যবস্থার উন্নতিকল্পে প্রতি পাঁচ
                বছরে ১৫০ কোটি টাকা বিনিয়োগ, দেশের সাধারণ বাজেট থেকে রেল বাজেটের
                পৃথকীকরণ, রেলপথের ওপর বেসরকারি নিয়ন্ত্রণের অবসান, সরকারি
                নিয়ন্ত্রণে রেলপথ নির্মাণ প্রভৃতির কথা বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>40. অবশিল্পায়ন বলতে কী বোঝো?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                অবশিল্পায়ন হল শিল্পায়নের বিপরীত অবস্থা। পলাশির যুদ্ধের পরবর্তী
                ১০০ বছরের মধ্যে কোম্পানির শোষণ ও নিপীড়ন, ব্রিটিশ পণ্যের অবাধ
                আমদানী, বৈষম্যমূলক শুল্ক নীতি প্রভৃতির ফলে চিরাচরিত ভারতীয়
                কুটির শিল্প ধ্বংস হয়ে যায়। এর ফলে ভারতীয় অর্থনীতিতে শিল্প
                নির্ভরতা হ্রাস পায় এবং কৃষি নির্ভরতা বৃদ্ধি পায়। অর্থনৈতিক
                ইতিহাসের বিশেষজ্ঞরা এই অবস্থাকে অবাশপায়ন' আখ্যা দিয়েছেন।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

ঔপনিবেশিকআধিপত্যেরপ্রকৃতিনিয়মিতওঅনিয়মিতসাম্রাজ্য.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
  memo
)(ঔপনিবেশিকআধিপত্যেরপ্রকৃতিনিয়মিতওঅনিয়মিতসাম্রাজ্য);
