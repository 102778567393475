import { call, takeEvery, put, takeLatest } from "redux-saga/effects";
import Axios from "axios";
//import { incrementByAmount } from "../features/counter/counterSlice";
import { FETCH_POPULAR_CHANNELS } from "./constants";
import { setPopularChannels } from "./reducer";

// function uses axios to fetch data from our api
let callAPI = async ({ url, method, data }) => {
  return await Axios({
    url,
    method,
    data,
  });
};

export function* fetchPopularChannels() {
  try {
  } catch (e) {
    //yield put({ type: "NUMBER_SAGA_FAILED" });
  }
}
export default function* rootSaga() {
  yield takeLatest(FETCH_POPULAR_CHANNELS, fetchPopularChannels);
}
