/*
 * FooterComponent Messages
 *
 * This contains all the text for the FooterComponent component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.FooterComponent';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the FooterComponent component!',
  },
});
