import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function বচন() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          বচন | West Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="বচন | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">বচন</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সাধারণত বচন হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইচ্ছা প্রকাশক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ঘোষক বাক্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রশ্নসূচক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আদেশসূচক
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>প্রাকল্পিক বচনের প্রথম অংশকে কী বলে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পূর্বগ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মানক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনুগ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিকল্প
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সংযােজক কার অংশ?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বাক্যের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বচনের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যুক্তির
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনুমানের
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>পরিমাণ অনুসারে নিরপেক্ষ বচন কয় প্রকার?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>1
                  প্রকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>2 প্রকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>3
                  প্রকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>4
                  প্রকার
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বৈকল্পিক বচনের অঙ্গ বচনগুলিকে বলে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পূর্বগ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মানক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনুগ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বিকল্প
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বিশেষ সদর্থক বা I বচনে ব্যাপ্য হয় কোন পদ?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিধেয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উদ্দেশ্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উভয় পদ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  এর কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কোন বচনে কোনো পদই ব্যাপ্য হয় না?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>A বচনে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>E বচনে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>I বচনে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>O বচনে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>গুণ অনুসারে নিরপেক্ষ বচন কয় প্রকার?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>3
                  প্রকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>2 প্রকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>1
                  প্রকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>4
                  প্রকার
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  যে বচনের বিধেয়টি উদ্দেশ্যপদের সমগ্র পরিমাণকে স্বীকার বা
                  অস্বীকার করে, তাকে বলে -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সামান্য বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নঞর্থক বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সদর্থক বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিশেষ বচন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বিধেয় পদ অব্যাপ্য হয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সামান্য বচনে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিশেষ বচনে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সদর্থক বচনে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নঞর্থক বচনে
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>দ্বিতীয় অধ্যায় - বচন</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  {" "}
                  1. বচন কাকে বলে? বচনের সংযোজকের কাজ কী? উদাহরণসহ নিরপেক্ষ এবং
                  সাপেক্ষ বচনের মধ্যে পার্থক্য লেখো। 2 + 2 + 4 = 8
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>বচন : </b>
              </font>
              <font color="#3c92e5">
                অবধারণের ভাষায় প্রকাশিত রূপকে বচন বলে। অবধারণ হল দুটি ধারনার
                মধ্যে মিল বা অমিল প্রত্যক্ষ করা। বচন মাত্রই জগতের কোনা বিষয় বা
                বস্তু সম্পর্কে কিছু স্বীকার বা অস্বীকার করে। তাই বলা যায় —দুটি
                পদের মধ্যে কোনো একটি সম্বন্ধের স্বীকৃতি বা অস্বীকৃতিকে বচন বলে।
                <br />
                <br />
                যে শব্দের দ্বারা বচনের উদ্দেশ্যপদ ও বিধেয়পদের মধ্যে একটি
                সম্বন্ধ স্থাপন করা হয়, তাকে সংযোজক বলে। প্রতিটি বচনের বিধেয়টি
                উদ্দেশ্য সম্পর্কে কিছু স্বীকার বা অস্বীকার করে। সংযোজকের কাজ হল
                এই স্বীকৃতি বা অস্বীকৃতির বিষয়টিকে প্রকাশ করা।
                <br />
                <br />
                যেমন - 'বিনয় হয় ছাত্র’-এই বচনে ‘হয়’ সংযোজকের সাহায্যে ‘বিনয়’
                সম্পর্কে ‘কবি’ কথাটি স্বীকার করা হয়েছে।
                <br />
                <br />
                উদ্দেশ্যপদ ও বিধেয়পদের সম্বন্ধ অনুসারে বচনকে দু-ভাগে ভাগ করা
                হয়—নিরপেক্ষ বা অনপেক্ষ বচন এবং সাপেক্ষ বা অ-নিরপেক্ষ বচন। যে
                বচনে উদ্দেশ্য ও বিধেয় পদের সম্বন্ধ কোনো শর্তের উপর নির্ভর করে
                না, তাকে নিরপেক্ষ বচন বলে। এই প্রকার বচনের বিধেয় পদটি উদ্দেশ্য
                সম্পর্কে বিনা শর্তে কোনো কিছু স্বীকার বা অস্বীকার করে। যেমন—‘সকল
                লিচু হয় টক’–এই বচনটিতে লিচু সম্পর্কে টক গুণটি বিনা শর্তে
                স্বীকৃত হয়েছে। অবার ‘কোনো লোভীব্যক্তি নয় সুখী’–এই বচনটিতে
                উদ্দেশ্য সম্পর্কে বিধেয় পদের যে অস্বীকৃতি তা অন্য কোনো শর্তের
                উপর নির্ভরশীল নয়। অপরদিকে, যে বচনে উদ্দেশ্য ও বিধেয় পদের
                সম্বন্ধ অন্য কোনো শর্তের উফর নির্ভর করে, তাকে সাপেক্ষ বচন বলে।
                এই প্রকার বচনের বিধেয় পদটি অন্য কোনো শর্তের উপর নির্ভরশীল হয়ে
                উদ্দেশ্য সম্পর্কে কোনো কিছু স্বীকার বা অস্বীকার করে। যেমন—যদি
                পরিশ্রম করো তবে সফল হবে?—এই বচনটিতে সফল হওয়ার বিষয়টি পরিশ্রম
                করা নামক শর্তের উপর নির্ভর করে। এরজন্য এই প্রকার বচনকে সাপেক্ষ
                বচন বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  2. নিরপেক্ষ বচন বলতে কী বোঝো? গুণ ও পরিমাণ অনুযায়ী নিরপেক্ষ
                  বচ শ্রেণিবিভাগ করো। একটি উদাহরণ দিয়ে নিরপেক্ষ বচনের অংশগুলি
                  দেখাও।{" "}
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>নিরপেক্ষ বচন : </b>
              </font>
              <font color="#3c92e5">
                যে বচনে উদ্দেশ্য ও বিধেয়ের সম্বন্ধ কোনো শর্তের উপ নির্ভর করে
                না, তাকে নিরপেক্ষ বচন বলে। <br />
                যেমন - 1. সকল ছাত্র এই কাজ পারে। <br />
                2. কোনো জবা ফুল নয় কালো। <br />
                <br />
                এখানে উদ্দেশ্য সম্পর্কে বিধেয় কোনো কিছু স্বীকার করছে অথবা
                অস্বীকার করছে কিন্তু এই স্বীকার অথবা অস্বীকারের সম্বন্ধটি কোনো
                শর্তের উপর নির্ভরশীল নয়। <br />
                <br />
                <font color="#1f7405">
                  <b>(A) নিরপেক্ষ বচনের শ্রেণিবিভাগ : </b>
                </font>{" "}
                গুণ অনুসারে নিরপেক্ষ বচনের শ্রেণিবিভাগ - <br />
                <br />
                নিরপেক্ষ বচনকে গুণ অনুসার দুই ভাগে ভাগ করা যায়। <br />
                যেমন — 1. সদর্থক ও 2. নঞর্থক। <br />
                <br />
                <font color="#f626d7">
                  <b>1. সদর্থক বচন : </b>
                </font>{" "}
                যে বচনে উদ্দেশ্য সম্পর্কে বিধেয়কে স্বীকার করা হয় তারে সদর্থক
                বলে।
                <br /> যথা — সকল রাজা হয় ধনী ব্যক্তি। এখানে উদ্দেশ্য রাজা
                সম্পর্কে বিয়ে পদ দ্বারা ধনী হওয়ার বিষয়টি স্বীকার করা হচ্ছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. নঞর্থক বচন : </b>
                </font>{" "}
                যে বচনে উদ্দেশ্য সম্পর্কে বিধেয়কে অস্বীকার করা হয় তাবে নঞর্থক
                বচন বলে। <br />
                যথা - কোনো মানুষ নয় ত্রুটিহীন। এই বচনটিতে সকল মানু সম্পর্কে
                ত্রুটিহীনতার কথা অস্বীকার করা হয়েছে। এটি নঞর্থক বচন।
                <br />
                <br />
                <font color="#1f7405">
                  <b> (B) পরিমাণ অনুসারে নিরপেক্ষ বচনের শ্রেণিবিভাগ : </b>
                </font>
                পরিমাণ অনুসারে নিরপে বচনকে দুই ভাগে ভাগ করা যায়।
                <br /> যেমন — 1. সামান্য বচন ও 2. বিশেষ বচন। <br />
                <br />
                <font color="#f626d7">
                  <b>1. সামান্য বচন : </b>
                </font>{" "}
                যে বচনে সমগ্র উদ্দেশ্য সম্পর্কে বিধেয়টি স্বীকার বা অস্বীকা করে
                তাকে সামান্য বচন বলে। <br />
                যেমন - ‘ সকল মানুষ হয় মরণশীল ’ <br />
                <br />
                এখানে সকল মানষ সম্পর্কে মরণশীলতাকে স্বীকার করা হয়েছে। আবার এ
                বচনটিতে সকল মানুষ সম্পর্কে ত্রুটিহীনতা নামক গুণটি অস্বীকার করা
                হয়েছে। <br />
                <br />
                <font color="#f626d7">
                  <b>2. বিশেষ বচন : </b>
                </font>{" "}
                বিধেয়টির উদ্দেশ্যে কিছু অনির্দিষ্ট অংশকে যে বচনে স্বীকার ব ব্যা
                অস্বীকার করা হয় তাকে বিশেষ বচন বলে। <br />
                <br />
                যথা - কোনো কোনো মানুষ হয় কবি ’ এখানে বিধেয় পদ কবি কোনো কোনো
                মানুষ সম্পর্কে স্বীকার করা হয়েছে। আর্য না কোনো মানুষ নয় সৈনিক
                ‘ এখানে বিধেয় পদ সৈনিক কোনো কোনো মানুষ সম্পর্কে অস্বীকার করা
                হয়েছে।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  3. নিম্নলিখিত বাক্যগুলিকে আদর্শ যুক্তিবিজ্ঞান সম্মত বচনে
                  রূপান্তর করো এবং কোন পদ ব্যাপ্য এবং কোন্ পদ অব্যাপ্য তা লেখো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                <br />
                (i) তিমি স্তন্যপায়ী।
                <br />
                <font color="#2b9a09"> ➺ </font>{" "}
                <font color="#f626d7">
                  <b> যুক্তিবিজ্ঞানসম্মত আকার : </b>
                </font>{" "}
                সকল তিমি হয় স্তন্যপায়ী (A) এখানে উদ্দেশ্যপদ ‘তিমি’ ব্যাপ্য
                হয়েছে এবং বিধেয়পদ ‘স্তন্যপায়ী’ অব্যাপ্য হয়েছে।
                <br />
                <br />
                (ii) সাধু ব্যবসায়ী আছে। <br />
                <font color="#2b9a09"> ➺ </font>{" "}
                <font color="#f626d7">
                  <b> যুক্তিবিজ্ঞানসম্মত আকার : </b>
                </font>{" "}
                কোনো কোনো ব্যবসায়ী হয় সাধু (I) এখানে উদ্দেশ্যপদ ‘ব্যবসায়ী’
                এবং বিধেয়পদ ‘সাধু’ উভয় পদই অব্যাপ্য হয়েছে।
                <br />
                <br />
                (iii) একমাত্র শান্তিকামী মানুষরাই সাম্যবাদী। <br />
                <font color="#2b9a09"> ➺ </font>{" "}
                <font color="#f626d7">
                  <b> যুক্তিবিজ্ঞানসম্মত আকার : </b>
                </font>{" "}
                সকল সাম্যবাদী হয় শান্তিকামী(A) এখানে উদ্দেশ্যপদ ‘সাম্যবাদী’
                ব্যাপ্য হয়েছে কিন্তু বিধেয়পদ ‘শান্তিকামী’ অব্যাপ্য হয়েছে।
                <br />
                <br />
                (iv) যারা প্রশংসা করে তারা সকলেই বন্ধু নয়। <br />
                <font color="#2b9a09"> ➺ </font>{" "}
                <font color="#f626d7">
                  <b> যুক্তিবিজ্ঞানসম্মত আকার : </b>
                </font>{" "}
                কোনো কোনো প্রশংসাকারী ব্যক্তি নয় বন্ধু(O)। এখানে উদ্দেশ্যপদ
                ‘প্রশংসাকারী ব্যক্তি’ অব্যাপ্য হয়েছে কিন্তু বিধেয়পদ ‘বন্ধু’
                ব্যাপ্য হয়েছে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  4. বচন বলতে কী বোঝো? বাক্য ও বচনের পার্থক্য লেখো। বচন ও
                  বচনাকারের পার্থক্য লেখো। 2 + 4 + 2 = 8
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                <br />
                অবধারণের ভাষায় প্রকাশিত রূপকে বচন বলে। অবধারণ হল দুটি ধারণার
                মধ্যে মিল বা অমিল প্রত্যক্ষ করা। বচন মাত্রই জগতের কোনো বিষয় বা
                বস্তু সম্পর্কে কিছু স্বীকার অস্বীকার করে। তাই বলা যায — দুটি
                পদের মধ্যে কোনো একটি সম্বন্ধের স্বীকৃতি বা অস্বীকৃতিকে বচন বলে।{" "}
                <br />
                <br />
                <font color="#2b9a09"> ⦿ </font>{" "}
                <font color="#f626d7">
                  <b> বাক্য ও বচনের পার্থক্য ➺ </b>
                </font>
                <br />
                (i) বাক্যে সাধারণত দুটি অংশ থাকে — উদ্দেশ্য ও বিধেয়। অপরদিকে,
                আদর্শ নিরপেক্ষ বচনে চারটি অংশ থাকে—পরিমাণক, উদ্দেশ্যপদ, সংযোজক
                এবং বিধেয় পদ।
                <br />
                (ii) বাক্যের ক্ষেত্রে গুণ ও পরিমাণ সকল সময় স্পষ্টভাবে উল্লেখ
                থাকে না। কিন্তু, বচনের ক্ষেত্রে গুণ ও পরিমাণ সর্বদা স্পষ্টভাবে
                উল্লেখ করতে হয়।
                <br />
                (ii) সব বাক্যকে সত্য বা মিথ্যারূপে গণ্য করা যায় না। কিন্তু,
                বচনমাত্রই সত্য অথবা মিথ্যা হবে।
                <br />
                (iv) একটি বাক্য অতীত, বর্তমান, ভবিষ্যৎ যে-কোনো কালের হতে পারে।
                কিন্তু বচন কেবলমাত্র বর্তমান কালের হয়।
                <br />
                (v) বাক্য সকলপ্রকার সাহিত্যে ব্যবহৃত হয়। অপরদিকে, বচন কেবলমাত্র
                যুক্তির অবয়ব হিসেবে ব্যবহৃত হয়।
                <br />
                <font color="#2b9a09"> ⦿ </font>{" "}
                <font color="#f626d7">
                  <b> বচন ও বচনাকারের পার্থক্য ➺ </b>
                </font>
                (i) একটি বচন হল আকার এবং উপাদান বা বিষয়বস্তুর মিলিত রূপ।
                অপরদিকে, বচনাকারে শুধু আকার থাকে, উপাদান বা বিষয়বস্তু থাকে না।{" "}
                <br />
                <br />
                (ii) বচন গঠিত হয় পদ ও যোজকের দ্বারা। অপরদিকে, বচনাকারে থাকে
                গ্রাহক ও যোজক, পদ থাকে না।
                <br />
                <br />
                (iii) বচন সত্য হতে পারে, আবার মিথ্যাও হতে পারে। অপরদিকে,
                বচনাকারে যেহেতু বিষয়বস্তু থাকে না, তাই বচনাকার সত্য বা মিথ্যা
                হতে পারে না।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

বচন.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(বচন);
