import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function MSAccess2007() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          MS Access 2007 | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="MS Access 2007 | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                MS Access 2007
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>MS Access কোন্ ধরনের Software?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Database
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Electronics Spread sheet
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Word porocessing
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Programming
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Report তৈরি করতে গেলে কোন্ রিবনের প্রয়োজন হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Home
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Create
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Data Sheet
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Data base tool
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>MS Access একটি কিসের প্রোগ্রাম?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ওয়ার্ড প্রোসেসিং সিস্টেম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গেমিং প্লাটফর্ম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অপারেটিং সিস্টেম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ডাটাবেস ম্যানেজমেন্ট সিস্টেম
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Date and Time ডেটা টাইপের আয়তন কত?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>4 byte
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>6 byte
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>8 byte
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  10 byte
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  Primary Key হিসাবে নিম্নলিখিত কোন্ ডেটাটাইপকে সব থেকে বেশি
                  ব্যবহার করা উপযোগী?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Auto number
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Date & time
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Ole object
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Memo
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>MS Access একটি ডাটাবেস কী নামে সেভ করা হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ফাইল নাম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  টেবিল নাম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডাটাবেস নাম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রোজেক্ট নাম
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>MS Access কোন ধরনের ডাটাবেস সিস্টেম?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  রিলেশনাল ডাটাবেস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফ্ল্যাট ডাটাবেস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অপারেশনাল ডাটাবেস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হায়ব্রিড ডাটাবেস
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  বৃহৎ আকারের Text কে সংরক্ষণের জন্য কোন্ ডেটা টাইপ ব্যবহার করা
                  হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Text
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Memo
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ole object
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  currency
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  Table সংক্রান্ত কোনো তথ্যকে দেখার জন্য নীচে কোন্‌টি আমরা
                  ব্যবহার করি?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Text
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Memo
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Report
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  currency
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  একাধিক Comment কে একসঙ্গে ব্যবহার করার জন্য নীচের কোন্‌টি
                  ব্যবহার করা হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Table
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Query
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Macro
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Form
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b>Chapter ➺ 5 </b>
              </font>
              <br />{" "}
              <font color="#2b9a09" size="4">
                <b> MS ACCESS ➺ 2007</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. MS Access 2007 এ Navigation Pane বলতে কী বোঝ?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>Answer : </b>
              </font>
              <font color="#3c92e5">
                MS Access 2007 এ খুবই প্রয়োজনীয় Option হল Navigation Pane
                group আকারে Database object গুলিকে রাখার জন্য Navigation Pane
                ব্যবহার করা হয়। Navigation Pane এ একটি অতি প্রয়োজনীয় Option
                হল Search যার মাধ্যমে আমরা সহজেই কোনো database object কে খুঁজতে
                পারি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. Ms Access 2007 এ form বলতে কী বোঝ?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>Answer : </b>
              </font>
              <font color="#3c92e5">
                Ms Access 2007 এর একটি object হল form, Data base table এ Data
                insert, Data update, Data delete প্রভৃতির জন্য Form ব্যবহার করা
                হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. MS Access 2007 এ Report বলতে কী বোঝ? </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>Answer : </b>
              </font>
              <font color="#3c92e5">
                MS Access 2007 এর একটি Object হল report form, Query মাধ্যমের
                database এর বিভিন্ন কাজকর্ম করার পরে database এর সমগ্র data কে
                সুন্দরভাবে report এর মাধ্যমে দেখা যায় এবং প্রয়োজনে Print করা
                যায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>4. Ms Access 2007 এ Table বলতে কী বোঝ?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>Answer : </b>
              </font>
              <font color="#3c92e5">
                MS Access 2007 অত্যন্ত একটি গুরুত্বপূর্ণ Object হল Table যা Row
                এবং Column এর সমন্বয়ে গঠিত হয়। কোনো Data base সুগঠিত ভাবে Data
                সংরক্ষণের জন্য Table প্রয়োজনীয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. Data Type বলতে কী বোঝ?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>Answer : </b>
              </font>
              <font color="#3c92e5">
                Ans: Database Table এ প্রতিটি field এ কী ধরনের তথ্য সংরক্ষিত
                থাকবে তা data type এর মাধ্যমে নির্ধারণ করা হয়। Table তৈরির সময়
                Table এ প্রতিটি field এর নামের সঙ্গে অবশ্যই data type উল্লেখ
                করতে হবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. MS Access 2007 এ Query বলতে কী বোঝ?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>Answer : </b>
              </font>
              <font color="#3c92e5">
                Ms Access 2007 এর একটি Object হল Query data base এর বিভিন্ন
                কাজকর্ম যেমন — Data insert, Data update, Data delete প্রভৃতির
                জন্য Query ব্যবহার করা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. Form এর বিভিন্ন অংশগুলি সংক্ষেপে বর্ণনা করো।</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                <font color="#f626d7">
                  <b>1. Form header : </b>
                </font>{" "}
                সামান্য বিবরণ যা প্রতিটি রেকর্ডের শীর্ষে থাকে, সেই টেবিলের
                সামান্য মূল্যবোধ প্রদান করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. Page Header : </b>
                </font>{" "}
                Page এর সার্বিক বর্ণনা অর্থাৎ Page এর Heading কে প্রকাশ করার
                জন্য ব্যবহার করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. Details : </b>
                </font>{" "}
                Form এর বিভিন্ন অংশগুলি যেগুলি record এর অপরিহার্য সেগুলিকে এর
                মাধ্যমে প্রকাশ করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. Page footer : </b>
                </font>{" "}
                Page-র শেষে থাকে, Page number এবং অতিরিক্ত বিস্তারিত তথ্য থাকে,
                যা পেজের পরিচিতি প্রদান করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. Form footer : </b>
                </font>{" "}
                প্রতিটি Record এর জন্য অপরিবর্তিত তথ্যগুলিকে দেখা যায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  8. MS access এ পূর্বে তৈরি করা দুটি Table এর মধ্যে কীভাবে
                  relationship করবে।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>Answer : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                <br />
                (i) ডেটাবেস থেকে পূর্বে তৈরি করা Table দুটিকে Open করতে হবে।
                <br />
                (ii) ডেটাবেস Tool রিবনের show and hide group এর relationship
                অপশনে ক্লিক করতে হবে।
                <br />
                (iii) Show Table dialogue box (Table relationship করতে হবে। সেই
                Table দুটিকে নির্বাচন করতে হবে। Add button এবং Close button এ
                Click করতে হবে।
                <br />
                (iv) দুটি Table এর মধ্যে যে field টি common সেই field টিকে প্রথম
                Table থেকে Select করতে হবে এবং দ্বিতীয় Table এর একই field এর
                উপর drop করতে হবে।
                <br />
                (v) Table দুটির মধ্যে relationship হয়ে যাবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>9. Data Sheet View বলতে কী বোঝ?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>Answer : </b>
              </font>
              <font color="#3c92e5">
                Electronics Spread Sheet মতোই, Electronics Data Sheet View
                ব্যবহার করে Data প্রদর্শন করা হয়, যেখানে তথ্য টেবিলের আকারে
                প্রদর্শিত হয়। এই টেবিলে record insert, delete, update Data Sheet
                View করা যায়, এবং এটি সহজভাবে ডেটা শীট ভিউ ব্যবহার করে সম্পাদনা
                করার সুবিধা উপস্থাপন করে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

MSAccess2007.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(MSAccess2007);
