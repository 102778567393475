import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function আন্তর্জাতিকসম্পর্ক() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>আন্তর্জাতিক সম্পর্ক | West Bengal Class 12 Guide</title>
        <meta
          name="description"
          content="আন্তর্জাতিক সম্পর্ক | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                আন্তর্জাতিক সম্পর্ক
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রথম অধ্যায় - আন্তর্জাতিক সম্পর্ক</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. আন্তর্জাতিক সম্পর্কের সংজ্ঞা দাও। আন্তর্জাতিক সম্পর্ক ও
                  আন্তর্জাতিক রাজনীতির মধ্যে পার্থক্য আলোচনা করো। 4 + 4
                </b>
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#1f7405">
                <b> আন্তর্জাতিক সম্পর্ক ও আন্তর্জাতিক রাজনীতি ➺ </b>{" "}
              </font>{" "}
              বিষয় হিসেবে আন্তর্জাতিক সম্পর্ককে অনেক সময় আন্তর্জাতিক রাজনীতির
              সমার্থক বলে মনে করা হয়, এর ফলে বিভ্রান্তির সৃষ্টি হয়।
              আন্তর্জাতিক সম্পর্কের বিখ্যাত লেখক হ্যান্স জে মর্গেনথাউ তাঁর
              Politics Among Nations গ্রন্থে আন্তর্জাতিক সম্পর্কের বদলে
              আন্তর্জাতিক রাজনীতি শব্দটি ব্যবহার করেছেন। অধ্যাপক পামার ও
              পারকিনস, হলসটি এবং শ্লেইচার প্রমুখ লেখকরা তা করেননি। তাঁরা মনে
              করেন আন্তর্জাতিক সম্পর্ক ও আন্তর্জাতিক রাজনীতি দুটি আলাদা বিষয়,
              তাই এদের মধ্যে পার্থক্যও বিদ্যমান। তা ছাড়া, এঁরা বিশ্বাস করেন
              আন্তর্জাতিক রাজনীতির চেয়ে আন্তর্জাতিক সম্পর্কের পরিধি অনেক
              ব্যাপক, তাই আন্তর্জাতিক সম্পর্ককে কখনোই আন্তর্জাতিক রাজনীতির
              অন্তর্ভুক্ত করে দেখা ঠিক নয়। বস্তুত আন্তর্জাতিক সম্পর্ক ও
              আন্তর্জাতিক রাজনীতির মধ্যে প্রকৃতিগত পার্থক্য রয়েছে।
              <br />
              <br />
              <font color="#f626d7">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#1f7405">
                <b>
                  {" "}
                  আন্তর্জাতিক সম্পর্ক ও আন্তর্জাতিক রাজনীতির মধ্যে পার্থক্য ➺{" "}
                </b>{" "}
              </font>{" "}
              আন্তর্জাতিক সম্পর্ক ও আন্তর্জাতিক রাজনীতির মধ্যে যেসব পার্থক্য
              রয়েছে তাদের মধ্যে উল্লেখযোগ্য হল –<br />
              <br />
              <font color="#f626d7">
                <b>1. সীমানাগত পার্থক্য ➺ </b>{" "}
              </font>{" "}
              আন্তর্জাতিক সম্পর্কের বিষয়বস্তুর গন্ডি ব্যাপক ও বৃহত্তর।
              আন্তর্জাতিক সম্পর্কের আলোচনা কেবল রাজনৈতিক সীমানার মধ্যেই সীমাবদ্ধ
              নয়, অরাজনৈতিক ক্ষেত্রেও প্রবেশ করেছে। বিশ্বের সকল মানুষ ও গোষ্ঠীর
              নানা দিক্ নিয়ে আলোচনা করে আন্তর্জাতিক সম্পর্ক। কেবল রাজনৈতিক নয়
              অর্থনৈতিক, সামাজিক, মানবিক ইত্যাদি সকল দিক নিয়ে আলোচনা করে
              আন্তর্জাতিক সম্পর্ক। অন্যদিকে আন্তর্জাতিক রাজনীতি বলতে বোঝায়
              আন্তর্জাতিক সমাজের রাজনীতি। আন্তর্জাতিক ক্ষেত্রে বিভিন্ন রাষ্ট্রের
              কূটনৈতিক আলোচনা ও রাজনৈতিক সাংগঠনিক আলোচনা-এর অন্তর্ভুক্ত।
              মরগেনথাউ-এর মতে আন্তর্জাতিক রাজনীতি হল মূলত ক্ষমতার লড়াই। এক
              কথায় আন্তর্জাতিক রাজনীতির আলোচনা সুনির্দিষ্ট এবং তা আন্তর্জাতিক
              সম্পর্কেরই অংশ বিশেষ।
              <br />
              <br />
              <font color="#f626d7">
                <b>2. বিষয়বস্তুগত পার্থক্য ➺ </b>{" "}
              </font>{" "}
              আন্তর্জাতিক সম্পর্কের আলোচনায় বাস্তববাদী তত্ত্বের অন্যতম প্রবক্তা
              মরগেনথাউ-এর মতে আন্তর্জাতিক সম্পর্কে সার্বভৌম ক্ষমতাসম্পন্ন
              রাষ্ট্রগুলির কেবল পারস্পরিক ক্ষমতা বিরোধ সংক্রান্ত বিষয়ই নয়
              আন্তর্জাতিক স্তরে পারস্পরিক সহযোগিতা, বন্ধুত্ব ও শান্তিপূর্ণ
              উপায়ে সমগ্র বিশ্বব্যবস্থাকে স্থায়ী করার উপায় নিয়েও আলোচনা হয়।
              কিন্তু, অপরদিকে আন্তর্জাতিক রাজনীতির মূল আলোচ্য বিষয় হল
              ক্ষমতাকেন্দ্রিক বিরোধ। অর্থাৎ, এক কথায় বিভিন্ন রাষ্ট্রের মধ্যে
              ক্ষমতাকে কেন্দ্র করে বিরোধ, দ্বন্দ্ব ও সংঘাতই হল আন্তর্জাতিক
              রাজনীতির প্রধান বিষয়।
              <br />
              <br />
              <font color="#f626d7">
                <b>3. প্রকৃতিগত পার্থক্য ➺ </b>{" "}
              </font>{" "}
              আন্তর্জাতিক সম্পর্ক বর্তমানে একটি নবীনতম শাস্ত্র হিসেবে আত্মপ্রকাশ
              করেছে সমাজবিজ্ঞানের ক্ষেত্রে। পৃথিবীর বিভিন্ন বিশ্ববিদ্যালয়ে
              আন্তর্জাতিক সম্পর্কের আলোচনা নতুন অধ্যায়নের বিষয়। প্রকৃতিগত দিক
              থেকে আন্তর্জাতিক সম্পর্ক রাজনৈতিক-অরাজনৈতিক, সরকারি বেসরকারি
              বিভিন্ন ক্ষেত্রগুলিকে নিয়েও অধ্যায়ন করে। অন্যদিকে আন্তর্জাতিক
              রাজনীতি প্রকৃতিগত দিক থেকে বিভিন্ন রাষ্ট্রের ক্ষমতা, দ্বন্দ্ব,
              প্রতিযোগিতা প্রভৃতি ক্ষেত্রে পরিবর্তন, বিবর্তন নিয়ে পর্যালোচনা
              করে থাকে।
              <br />
              <br />
              <font color="#f626d7">
                <b>4. দৃষ্টিভঙ্গিগত পার্থক্য ➺ </b>{" "}
              </font>{" "}
              আন্তর্জাতিক রাজনীতির সঙ্গে আন্তর্জাতিক সম্পর্কের দৃষ্টিভঙ্গি জনিত
              পার্থক্য বিদ্যমান। কে জে হলসটি-র মতে, আন্তর্জাতিক রাজনীতির
              আলোচনাকে অনেক সময় পররাষ্ট্র সম্পর্কিত আলোচনার মধ্যেই সীমাবদ্ধ
              রাখাহয়। এখানে মূলত বৃহৎ রাষ্ট্রগুলির ক্ষমতার উপাদান, পারস্পরিক
              সম্পর্ক ও ক্রিয়াকলাপ প্রভৃতি ওপরে বেশি জোর দেওয়া হয়। কিন্তু
              আন্তর্জাতিক সম্পর্ক বিষয়টি তা নয়, আন্তর্জাতিক সম্পর্কের সঙ্গে
              রাজনীতি, অর্থনীতি, আইন, যোগাযোগ ব্যবস্থা, আন্তর্জাতিক সংগঠন,
              বিশ্বযুদ্ধ প্রভৃতি বিষয়গুলি ঘনিষ্ঠভাবে সম্পর্কযুক্ত।
              <br />
              <br />
              <font color="#f626d7">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#1f7405">
                <b> উপসংহার ➺ </b>{" "}
              </font>{" "}
              পরিশেষে বলা যায়, আন্তর্জাতিক রাজনীতির মধ্যে আন্তর্জাতিক সমাজের যে
              পরিচয় মেলে তা আংশিক, অন্যদিকে আন্তর্জাতিক সম্পর্ক সমকালীন
              আন্তর্জাতিক ব্যবস্থার একটি সামগ্রিক পরিচয়কে তুলে ধরতে চায়।
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  2. বিশ্বায়নের সংজ্ঞা দাও। বিশ্বায়নের বিভিন্ন রূপ বা প্রকৃতি
                  আলোচনা করো। 2 + 6<br />
                  অথবা,
                  <br /> বিশ্বায়নের সংজ্ঞা লেখো। বিশ্বায়নের বিভিন্ন রূপ
                  পর্যালোচনা করো।
                  <br />
                  অথবা,
                  <br /> বিশ্বায়ন বলতে কী বোঝ? বিশ্বায়নেরবিভিন্ন প্রকার গুলি
                  উল্লেখ করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                বিশ্বায়ন বা গ্লোবালাইজেশন-এর বাংলা প্রতিশব্দ হল বা ভুবনায়ন। এই
                ধারণাটির সঙ্গে আরও দুটো গুরুত্বপূর্ণ ধারণা উদারিকীকরণ ও
                বেসরকারিকরণ যুক্ত। বর্তমান বিশ্বে একটি বহুল আলোচিত বিষয় হল
                বিশ্বায়ন। অধ্যাপক রবার্টসন ‘বিশ্বায়ন’ ধারণার প্রথম প্রবর্তক।
                1980-এর দশকে ‘বিশ্বায়ন’ শব্দটি ব্যাপক প্রচারলাভ করলেও 1991
                খ্রিস্টাব্দের পর থেকে এই ধারণার ব্যাপক প্রয়োগ শুরু হয়।
                বিশ্বায়নের কোনো সর্বজনগ্রাহ্য সংজ্ঞা নেই। রবার্টসন বিশ্বের
                সংকুচিতকরণ ও একত্রীকরণকেই বিশ্বায়ন বলে চিহ্নিত করেছেন। জোসেফ
                স্টিগলিৎসের মতে বিশ্বায়ন হল প্রকৃতপক্ষে বিশ্বের বিভিন্ন দেশ ও
                জনগোষ্ঠীর মধ্যে একধরনের নিবিড় একাত্মতা বা ঘনিষ্ঠতার সংহতি।
                এককথায় বিশ্বায়ন হল সমগ্র বিশ্বকে একহাতের মুঠোয় আনা।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b>বিশ্বায়নের বিভিন্ন রূপ বা প্রকৃতি ➺ </b>{" "}
                </font>{" "}
                বিশ্বায়ন একটি বিশ্বব্যাপী প্রক্রিয়া। যা সমগ্র বিশ্বকে একই
                গণ্ডির মধ্যে নিয়ে আসতে চায়। এটি একটি সামগ্রিক প্রক্রিয়া হলেও
                বিশ্বায়নের কয়েকটি রূপ বা প্রকৃতি রয়েছে। এগুলি হল — (a)
                অর্থনৈতিক বিশ্বায়ন, (b) রাজনৈতিক বিশ্বায়ন ও (c) সাংস্কৃতিক
                বিশ্বায়ন।
                <br />
                <br />
                <font color="#f626d7">
                  <b> 1. অর্থনৈতিক বিশ্বায়ন ➺ </b>{" "}
                </font>{" "}
                সমগ্র বিশ্বজুড়ে একই ধরনের অর্থনৈতিক কাঠামো গড়ে তোলার মধ্য
                দিয়ে অর্থনৈতিক বিশ্বায়নের আবির্ভাব ঘটেছে। অর্থনৈতিক
                বিশ্বায়নের ধারণা অনুযায়ী সমস্ত দেশের অর্থনীতিই পরস্পরের সঙ্গে
                সম্পর্কযুক্ত। মূলধন ও প্রযুক্তির বিশাল প্রবাহ, পরিসেবার
                বাণিজ্যকে উৎসাহিত করে জাতীয় অর্থনীতি সমূহকে নতুনভাবে পরস্পরের
                সঙ্গে সংযুক্ত করে সমগ্র বিশ্বে এক অখন্ড বাজার গড়ে তুলেছে। একুশ
                শতকের বিশ্ব-অর্থনীতির মুখ্য পরিচালক ও নিয়ন্তা হিসেবে বহুজাতিক
                সংস্থা, শক্তিশালী আন্তর্জাতিক আর্থিক প্রতিষ্ঠান এবং বৃহদাকার
                আঞ্চলিক বাণিজ্য ব্যবস্থা প্রতিষ্ঠিত হয়েছে। আন্তর্জাতিক আর্থিক
                প্রতিষ্ঠানগুলির মধ্যে বিশ্বব্যাংক, আন্তর্জাতিক অর্থভান্ডার এবং
                বিশ্ব বাণিজ্য সংস্থা হল সবচেয়ে ক্ষমতাবান। উন্নয়নশীল ও দরিদ্র
                দেশগুলির অর্থনীতি ও উন্নয়ন এদের নীতি ও নির্দেশের ওপর নির্ভরশীল।
                এইভাবে অর্থনৈতিক বিশ্বায়নের পথ সুদৃঢ় হচ্ছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b> 2. রাজনৈতিক বিশ্বায়ন ➺ </b>{" "}
                </font>{" "}
                জাতীয় রাষ্ট্র ক্ষমতার সংকোচন, ন্যূনতম রাষ্ট্রের ধারণার
                জনপ্রিয়তা বৃদ্ধি, আমলাতান্ত্রিক কাঠামোর সংকোচন শ্রমিক সংগঠনের
                ব্যাপারে জনগণের অনীহা রাজনৈতিক বিশ্বায়নের বৈশিষ্ট্য হিসেবে
                এগুলি চিহ্নিত হতে পারে। সাম্রাজ্যবাদ ও ঔপনিবেশিকতাবাদের পথ ধরে
                সুদূর অতীতে যেভাবে অনুন্নত রাষ্ট্র গুলির ওপর সাম্রাজ্যবাদী ও
                ঔপনিবেশিকতাবাদী রাষ্ট্রগুলি আধিপত্য প্রতিষ্ঠা করেছিল, একইভাবে
                বর্তমানে বিশ্বায়নের পথ ধরে উন্নত পুঁজিবাদী দেশগুলি উন্নয়নশীল
                দেশগুলির ওপর আধিপত্য কায়েম করে রাজনৈতিক বিশ্বায়নকে প্রতিষ্ঠিত
                করেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b> 3. সাংস্কৃতিক বিশ্বায়ন ➺ </b>{" "}
                </font>{" "}
                বিশ্বায়নের মূল লক্ষ্য সমগ্র বিশ্ব জুড়ে এক ও অভিন্ন সংস্কৃতি
                প্রতিষ্ঠা করা। বহুসংস্কৃতিবাদে বিশ্বায়ন বিশ্বাসী নয়। সমগ্র
                বিশ্বে মার্কিন ম্যাকডোনাল্ড সংস্কৃতিকে বলপূর্বক চাপিয়ে দিতে
                প্রস্তুত বিশ্বায়নের এই সাংস্কৃতিক রূপ। তৃতীয় বিশ্বের বিভিন্ন
                সাবেকি ধ্রুপদি সংস্কৃতিকে ধ্বংস করে পুঁজিবাদী মতাদর্শকেন্দ্রিক
                ভোগবাদী পণ্যের বিপুল সমাহার উপস্থাপন করাই সাংস্কৃতিক বিশ্বায়নের
                রূপ। তা ছাড়া প্রযুক্তিবিদ্যার অভাবনীয় উন্নতিতে মানুষ অতি অল্প
                ব্যয়ে পশ্চিমি উন্নত সংস্কৃতির সঙ্গে খুব সহজেই পরিচিত হচ্ছে।
                যেমন, বলা যায় বর্তমান যুগে মোবাইল ও ইন্টারনেটের সহজলভ্যতা ও তার
                বিবর্তন দেশীয় সংস্কৃতিকে ধ্বংস করে পশ্চিমি সংস্কৃতিকে তার
                জায়গায় স্থান দিচ্ছে। বর্তমান যুগে বিজ্ঞাপন নতুন মোড়কে আনা
                পণ্যের চাহিদাকে আকাশছোঁয়া করে তুলেছে। আর এইভাবেই আঞ্চলিক
                সংস্কৃতির সঙ্গে উন্নত পশ্চিমি সংস্কৃতির মেলবন্ধন ঘটে নতুন এক
                বিশ্বায়িত সংস্কৃতির জন্ম নিয়েছে।
                <br />
                <br />
                <font color="#1f7405">
                  <b> উপসংহার ➺ </b>{" "}
                </font>{" "}
                এ কথা ঠিক যে, উন্নত বা উন্নয়নশীল সকল দেশেই বিশ্বায়ন আজ
                পরীক্ষার পর্যায়ে রয়েছে। কিন্তু উন্নয়নশীল দেশগুলিতে এর কুফল
                দেখা দিতে শুরু করেছে। বিশ্বায়ন আজ ধনী ও দরিদ্র দেশগুলির মধ্যে
                অর্থনৈতিকবৈষম্যই শুধুবৃদ্ধি করেনি, উন্নয়নশীল দেশগুলির
                সাংস্কৃতিক ঐতিহ্য ও জীবনধারাকে বিনষ্ট করেছে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. বিশ্বায়নের প্রভাব বা ফলাফলগুলো আলোচনা করো।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                বর্তমানে আন্তর্জাতিক রাজনীতির ধারণার অন্যতম আলোচ্য বিষয়বস্তু হল
                বিশ্বায়নকেন্দ্রিক। মূলত পশ্চিমী অর্থনীতির হাত ধরেই এই বিশ্বায়ন
                সমগ্র বিশ্বে ব্যাপ্ত হয়েছে। বিশ্বের বিভিন্ন ক্ষেত্রগুলোতে
                বিশ্বায়নের অভিন্ন প্রভাব ও প্রতিক্রিয়া পরিলক্ষিত হচ্ছে। ফলত,
                আজ সমগ্র দুনিয়ার সর্বাধিক বিতর্কিত বিষয় হল বিশ্বায়ন।
                অর্থনৈতিক, সামাজিক, রাজনৈতিক ও সাংস্কৃতিক সমগ্র ক্ষেত্রেই এর
                প্রভাব বহুমুখী। সমগ্র বিশ্বে বিশ্বায়নের প্রভাব ও প্রতিক্রিয়ার
                ক্ষেত্রে বিশ্বায়নের কয়েকটি প্রেক্ষিত লক্ষ্য করা যায়—রক্ষণশীল
                প্রতিক্রিয়া, উদারনৈতিক ও নয়া উদারনৈতিক প্রতিক্রিয়া ও
                সমালোচনামূলক প্রতিক্রিয়া।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b> বিশ্বায়নের প্রভাব ও ফলাফল ➺ </b>{" "}
                </font>{" "}
                একবিংশ শতাব্দীতে এক অপরিহার্য আন্তর্জাতিক প্রতিক্রিয়া হল
                বিশ্বায়ন। আন্তর্জাতিক রাজনীতির আঙ্গিনায় বহুল আলোচিত বিষয়
                হিসেবে বিশ্বায়নের নানাবিধ প্রভাব ও ফলাফলগুলো আলোচিত হল — <br />
                <br />
                <font color="#f626d7">
                  <b>1. কর্পোরেট বিশ্ব বা MNC গুলোর আধিপত্য ➺ </b>{" "}
                </font>{" "}
                1990-এর দশকে যে বিশ্বায়নের সূচনা হয়, তার প্রভাবে বিশ্ব
                বাণিজ্যে কর্পোরেট ব্যবস্থার আধিপত্য অতি অল্প সময়ে দাপুটে চেহারা
                ধারণ করেছে। মূলত পশ্চিমী দেশগুলোর বৃহৎ পুঁজি সমৃদ্ধ বিভিন্ন MNC
                বা বহুজাতিক সংস্থাগুলো সমগ্র বিশ্ববাণিজ্যে তাদের আধিপত্য জোরদার
                করেছে। ফলত, বিশ্বের দরিদ্র ও প্রান্তিক দেশগুলোর স্বদেশীয় বাজার
                অর্থনীতি, ক্ষুদ্র দেশীয় শিল্প ও সংস্থাগুলোর অস্তিত্ব চরম
                সংকটাপন্ন হয়েছে অথবা সংস্থাগুলোর অধিগ্রহণ সুসম্পন্ন হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. উন্নত ও অনুন্নত বিশ্বের চরম বৈষম্য ➺ </b>{" "}
                </font>{" "}
                বিশ্বায়ন সমগ্র বিশ্বে আধুনিকতার ধারণা বহন করে। তবে এই
                আধুনিকীকরণের অন্তরালে উন্নত ও অনুন্নত বিশ্বের মধ্যে চরম বৈষম্য
                আনে। বর্তমান প্রতিযোগিতামূলক বাজার অর্থনীতিতে অনুন্নত ও
                উন্নয়নশীল দেশগুলোর উপর বিশ্বায়নের ইতিবাচক প্রভাবের তুলনায়
                নেতিবাচক প্রভাবই সর্বাধিক। বিশ্বায়নের নেতিবাচক প্রভাব দরিদ্র
                দেশগুলোকে আরও দরিদ্র করেছে এবং ধনী দেশগুলোকে আরোও ধনী করেছে।
                সবথেকে বেশি প্রভাব পড়েছে তৃতীয় বিশ্বের দেশগুলোর উপর। ফলত উন্নত
                ও অনুন্নত বিশ্বের মানুষের আয়ের মধ্যেও পার্থক্য সূচিত হয়েছে।
                সাম্প্রতিক উদাহরণ হিসেবে করোনা পরিস্থিতিতে শ্রীলঙ্কার
                আধুনিকীকরণের অলীক কল্পনার বাস্তবায়ন শ্রীলঙ্কার অর্থনীতিকে
                সম্পূর্ণ ধ্বংস করেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. নয়া উপনিবেশিকতাবাদ ও উত্তর-দক্ষিণ সংঘাত ➺ </b>{" "}
                </font>{" "}
                বাস্তবে উন্নত দেশগুলোর দ্বারা প্রভাবিত ও নিয়ন্ত্রণাধীন বিভিন্ন
                আন্তর্জাতিক সংস্থাগুলোর নেতিবাচক প্রভাব উন্নয়নশীল ও অনুন্নত
                দেশগুলোর উপর শোষণকে জারি রেখেছে। প্রত্যক্ষভাবে না হোক,
                পরোক্ষভাবে বিকল্প উপায়ে উন্নত দেশগুলো বিশ্বায়নকে হাতিয়ার করে
                অনুন্নত দেশগুলোর উপর কর্তৃত্ব বজায় রেখে চলেছে। ফলত আন্তর্জাতিক
                ক্ষেত্রে নয়া উপনিবেশকতাবাদের সূচনা হয়েছে। বিভিন্ন আন্তর্জাতিক
                অর্থনৈতিক সংগঠন যেমন—IMF, WTO, বিশ্বব্যাংক প্রভৃতি সংস্থাগুলোর
                তত্ত্বাবধানে (উন্নত) এবং দক্ষিণ (অনুন্নত) সংঘাত আরোও তীব্র আকার
                ধারণ করেছে। অনুন্নত দেশগুলো ক্রমশ উন্নত দেশগুলোর নিয়ন্ত্রণাধীন
                হয়ে পড়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. সাংস্কৃতিক ক্ষেত্রে প্রভাব ➺ </b>{" "}
                </font>{" "}
                বিশ্বায়নের প্রভাব সুদূর প্রসারী। সাংস্কৃতিক ক্ষেত্রেও এর প্রভাব
                বিদ্যমান। বিশ্বায়ন সমগ্র বিশ্বে একই সাংস্কৃতিক প্রচলন দাবি করে।
                বিভিন্ন দেশের প্রান্তিক অঞ্চলগুলোর ভিন্ন ভিন্ন সংস্কৃতি মুছে
                ফেলে আঞ্চলিক ও জাতীয় সংস্কৃতির স্বাতন্ত্র্যতা ক্ষুণ্ন করেছে
                বিশ্বায়ন। বলপূর্বক উন্নত দেশগুলো নিজেদের সংস্কৃতি অনুন্নত
                দেশগুলোর উপর চাপিয়ে দিয়ে বাণিজ্যিকভাবে নিজেদের মুনাফা লাভে
                সচেষ্ট হয়ে ওঠে এবং তা বাস্তবায়িত করতে অত্যাধুনিক গণমাধ্যমের
                সহায়তায় এক পণ্যমুখী ভোগবাদী সংস্কৃতির অনন্ত প্রচারকার্য
                চালায়। উদাহরণস্বরূপ মার্কিন ম্যাকডোনাল্ড সংস্কৃতির উল্লেখ করা
                যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. জাতি-রাষ্ট্রের সার্বভৌমের অস্তিত্বের সংকট ➺ </b>{" "}
                </font>{" "}
                সার্বভৌমত্বের ধারণা অভ্যন্তরীণ ও বাহ্যিক দিক্ থেকে চরম, মৌলিক ও
                নিরঙ্কুশ। তবে বিশ্বায়নের প্রভাব জাতি-রাষ্ট্রের সার্বভৌমত্বের
                অস্তিত্বের উপর সংকট উপস্থিত করেছে। বিশ্বায়ন বর্তমানে
                রাষ্ট্রগুলোকে বাজারকেন্দ্রিক সংগঠনে পরিণত করেছে। তৃতীয় বিশ্বের
                দেশগুলোর দুর্বল অর্থনৈতিক কাঠামোর উপর পশ্চিমী বহুজাতিক
                সংস্থাগুলোর আধিপত্য জাতি-রাষ্ট্রের সার্বভৌমিকতার উপর চরম আঘাত
                আনছে।
                <br />
                <br />
                উপরোক্ত আলোচনার পরিপ্রেক্ষিতে বলা যায়, আধুনিক বিশ্ব ব্যবস্থায়
                বিশ্বায়ন অবিসম্ভাবী ধারণা। বর্তমানে বিশ্ব ব্যবস্থায় কোনো
                রাষ্ট্রই স্বয়ংসম্পূর্ণ নয়। তাই বিশ্বায়নের প্রভাব থেকে কোনো
                রাষ্ট্রই মুক্ত হতে পারবে না। বিশ্বায়নের ধারণার সঙ্গে নিবিড়ভাবে
                যুক্ত রয়েছে বেসরকারীকরণ, উদারিকরণ প্রভৃতি ধারণা। অনেকের মতে
                বিভিন্ন আন্তর্জাতিক সংগঠন যেমন — IMF, বিশ্ব ব্যাংক, WTO প্রভৃতি
                সংগঠনগুলো এই বিশ্বায়নের ধারণাকে সম্প্রসারিত করছে। তবে অদূর
                ভবিষ্যতে কর্পোরেট বিশ্ব ব্যবস্থার ধারণা বিশ্বায়নের ধারণাকে নতুন
                রূপে পেশ করবে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

আন্তর্জাতিকসম্পর্ক.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(আন্তর্জাতিকসম্পর্ক);
