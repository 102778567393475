import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function আঞ্চলিকঅর্থনৈতিকউন্নয়ন() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          আঞ্চলিক অর্থনৈতিক উন্নয়ন | West Bengal Class 12 Guide | West Bengal
          HS Suggestion
        </title>
        <meta
          name="description"
          content="আঞ্চলিক অর্থনৈতিক উন্নয়ন | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                আঞ্চলিক অর্থনৈতিক উন্নয়ন
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b>দশম অধ্যায়</b>
              </font>
              <br />{" "}
              <font color="#2b9a09" size="4">
                <b>আঞ্চলিক অর্থনৈতিক উন্নয়ন</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ৭</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                ১. পরিকল্পনা অঞ্চলের বৈশিষ্ট্য ও উদ্দেশ্য লেখো। বৃহৎ অঞ্চল
                (ম্যাক্রো অঞ্চল) ও ক্ষুদ্র অঞ্চল (মাইক্রো অঞ্চল)-এর পার্থক্য
                লেখো।
                <br />
                ২. স্থিতিশীল বা নিরবিচ্ছিন্ন উন্নয়ন কী? বাহ্যিক ও ক্রিয়ামূলক
                অঞ্চলের পার্থক্য।
                <br />
                ৩. বেঙ্গালুরুতে ইলেকট্রনিকস শিল্প গড়ে ওঠার কারণগুলি কী?
                বেঙ্গালুরুকে ভারতের ইলেকট্রনিকস রাজধানী বলা হয় কেন?
                <br />
                ৪. হলদিয়া বন্দরের গুরুত্ব লেখো। হলদিয়া বন্দরের প্রধান
                সমস্যাগুলি কী কী?
                <br />
                ৫. ছত্তিশগড়ের কৃষি, খনিজসম্পদ এবং শিল্প সম্পর্কে আলোচনা করো।
                <br />
                ৬. বেঙ্গালুরুর ইলেকট্রনিক্স শিল্পের সংক্ষিপ্ত বর্ণনা দাও।
                বেঙ্গালুরুকে নব প্রযুক্তিরশহর বলা হয় কেন?
                <br />
                ৭. হলদিয়া বন্দর গড়ে ওঠার কারণগুলি আলোচনা করো।
                <br />
                ৮. ছত্তিশগড়ের খনিজ সম্পদের সংক্ষিপ্ত বিবরণ দাও। ভারতে পরিকল্পনা
                অঞ্চলের স্তরক্রম ব্যাখ্যা করো।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ১. হলদিয়া বন্দরের উন্নতির ক্ষেত্রে কোন শিল্পের অবদান
                  সর্বাধিক?
                </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                হলদিয়া বন্দরের উন্নতির ক্ষেত্রে পেট্রো-রসায়ন শিল্পের অবদান
                সর্বাধিক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২. হলদিয়া বন্দরের প্রধান সমস্যা কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                হুগলি নদীর ক্রমহ্রাসমান নাব্যতা হলদিয়া বন্দরের প্রধান সমস্যা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৩. হলদিয়া পেট্রোপ্ল্যান্ট কেন তৈরি করা হয়েছে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                হলদিয়া বন্দর এলাকায় সামগ্রিক উন্নতির জন্য হলদিয়া
                পেট্রোপ্ল্যান্ট তৈরি করা হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৪. হলদিয়া বন্দরটির আয়তন কত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                হলদিয়া বন্দরটির আয়তন প্রায় ৪033 বর্গমিটার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫. হলদিয়া বন্দরের পােতাশ্রয়টি কীরূপ?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                হলদিয়া বন্দরের পােতাশ্রয়টি কৃত্রিম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৬. PPP কথাটির সম্পূর্ণ অর্থ কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                Purchasing Power Parity (ক্রয়ক্ষমতার সামঞ্জস্য)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৭. ভারতে প্রথম পঞ্চবার্ষিকী পরিকল্পনার সময়কাল কী ছিল?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতে 1951-56 সাল পর্যন্ত প্রথম পঞ্চবার্ষিকী পরিকল্পনার সময়কাল
                ছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৮. দুর্গাপুরে নির্মিত ব্যারেজ কোন্ পরিকল্পনা অঞ্চলের
                    অন্তর্ভুক্ত?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দুর্গাপুরে নির্মিত ব্যারেজটি দামােদর উপত্যকা পরিকল্পনা অঞ্চলের
                অন্তর্গত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৯. মানব উন্নয়ন বলতে কী বােঝ?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মানব উন্নয়ন বলতে খাদ্য, বাসস্থান, স্বাস্থ্য, সাক্ষরতা, নারীর
                ক্ষমতায়ন প্রভৃতি ক্ষেত্রে মানুষের অগ্রগতিকে বােঝায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১০. উন্নয়নের ধারণাটি সময় ও অঞ্চলভেদে কী প্রকৃতির?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                উন্নয়নের ধারণাটি সময় এবং অঞ্চলভেদে পৃথক প্রকৃতির।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>১১. অর্থনৈতিক বিকাশ কাকে বলে?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                মাথাপিছু আয় এবং জাতীয় আয়ের দীর্ঘকালীন বৃদ্ধিকে অর্থনৈতিক
                বিকাশ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১২. অর্থনৈতিক উন্নয়ন কখন ঘটে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জাতীয় আয় এবং মাথাপিছু আয় বৃদ্ধির সাথে অর্থনৈতিক পরিকাঠামাের
                উন্নতি হলে অর্থনৈতিক উন্নয়ন ঘটে। এর ফলে কোনাে দেশের আর্থিক
                সমৃদ্ধি ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১৩. অধ্যাপক টেলর-এর মতে, পরিকল্পনা অঞ্চল কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                অধ্যাপক টেলর-এর মতে, পরিকল্পনা অঞ্চল বলতে সেই অঞ্চলকে বােঝানাে
                হয় যা বৈশিষ্ট্যগত দিক থেকে অন্য অঞ্চল থেকে পৃথক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১৪. বৃহৎ পরিকল্পনা অঞ্চল কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বৃহৎ অঞ্চল জুড়ে যখন পরিকল্পনা গ্রহণ করা হয় তখন সেই অঞ্চলকে
                বৃহৎ পরিকল্পনা অঞ্চল বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ১৫. বৃহৎ অঞ্চলের পরিকল্পনা গ্রহণ ও রূপায়ণের জন্য স্থাপিত
                    সংস্থাটির নাম কী?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বৃহৎ অঞ্চলের পরিকল্পনা গ্রহণ ও রূপায়ণের জন্য স্থাপিত সংস্থাটি
                হল জাতীয় পরিকল্পনা কমিশন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১৬. ক্ষুদ্র পরিকল্পনা অঞ্চল কোন্ স্তরে গড়ে তােলা হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জেলা, ব্লক এবং গ্রামস্তরে ক্ষুদ্র পরিকল্পনা অঞ্চল গড়ে তােলা
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১৭. অঞ্চল কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                অঞ্চল বলতে কোনাে সুচিহ্নিত সীমানার মধ্যবর্তী সমধর্মী এলাকাকে
                বােঝায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ১৮. কোনাে এলাকার প্রাকৃতিক ও আঞ্চলিক সমস্যা সমাধানের জন্য
                    কোন্ ধরনের অঞ্চল সৃষ্টি করা হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কোনাে এলাকার প্রাকৃতিক ও আঞ্চলিক সমস্যা সমাধানের জন্য পরিকল্পনা
                অঞ্চল সৃষ্টি করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ১৯. কোন্ ধরনের উন্নয়নে প্রাকৃতিক পরিবেশের ভারসাম্য রক্ষার
                    ওপর গুরুত্ব আরােপ করা হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                স্থিতিশীল বা সুস্থায়ী উন্নয়নে প্রাকৃতিক পরিবেশের ভারসাম্য
                রক্ষার ওপর গুরুত্ব আরােপ করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২০. কীসের ওপর ভিত্তি করে দেশের উন্নয়ন পরিমাপ করা হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রধানত দেশের মােট অভ্যন্তরীণ উৎপাদন বা GDP-এর ওপর ভিত্তি করে
                দেশের উন্নয়ন পরিমাপ করা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>২১. উন্নয়ন বলতে কী বােঝ?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সাধারণভাবে উন্নয়ন হল অর্থনৈতিক, সামাজিক ও রাজনৈতিক অবস্থার
                সার্বিক অগ্রগতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২২. বৃহৎ পরিকল্পনাকে কেন্দ্রীয় পরিকল্পনা বলা হয় কেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বৃহৎ পরিকল্পনা জাতীয় স্তরে গড়ে তােলা হয় বলে একে কেন্দ্রীয়
                পরিকল্পনা বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২৩. নিরবচ্ছিন্ন বা স্থিতিশীল উন্নয়ন বলতে কী বােঝায়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                নিরবচ্ছিন্ন বা স্থিতিশীল উন্নয়ন হল ভবিষ্যত প্রজন্মের প্রয়ােজন
                মেটানাের সামর্থ্যের সাথে কোনাে আপস না করে বর্তমান প্রজন্মের
                চাহিদা মেটানাে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ২৪. পরিকল্পনা অঞ্চল গড়ে তােলার যে-কোনাে একটি উদ্দেশ্য
                    লেখাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কোনাে অঞ্চলের দারিদ্রতাকে দূর করে ওই অঞ্চলের সামগ্রিক উন্নতি
                ঘটানাে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২৫. ক্ষুদ্র পরিকল্পনা অঞ্চল কাকে বলা হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ক্ষুদ্র স্কেলে স্থানীয় অঞ্চল জুড়ে যে পরিকল্পনা প্রণয়ন করা হয়
                তাকে ক্ষুদ্র পরিকল্পনা অঞ্চল বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ২৬. আর্থসামাজিক ক্রিয়া প্রতিক্রিয়ার সংহতি এবং পারস্পরিক
                    নির্ভরতার ভিত্তিতে গড়ে ওঠা পৃথক অঞ্চল কী নামে পরিচিত?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আর্থসামাজিক ক্রিয়া-প্রতিক্রিয়ার সংহতি এবং পারস্পরিক নির্ভরতার
                ভিত্তিতে গড়ে ওঠা পৃথক অঞ্চলকে ক্রিয়ামূলক অঞ্চল বা Functional
                region বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ২৭. জেলান্তরে পরিকল্পনা গ্রহণ ও পর্যবেক্ষণের জন্য গঠিত
                    সংস্থাটির নাম কী?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জেলাস্তরে পরিকল্পনা গ্রহণ ও পর্যবেক্ষণের জন্য গঠিত সংস্থাটি
                হল—জেলা পরিকল্পনা ও উন্নয়ন পর্ষদ (District Planning and
                Development Council).
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ২৮. ব্লকস্তরে পরিকল্পনা গ্রহণ করার জন্য কে নিযুক্ত থাকেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ব্লকের মুখ্য আধিকারিক বিডিও ব্লকস্তরে পরিকল্পনা গ্রহণ করার জন্য
                নিযুক্ত থাকেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২৯. ছত্তিশগড়ের ভূপ্রকৃতি কী প্রকৃতির?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ছত্তিশগড়ের ভূপ্রকৃতি প্রধানত তরঙ্গায়িত প্রকৃতির, মধ্যভাগ সমতল
                এবং প্রান্তদেশ উচ্চভূমিযুক্ত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৩০. ছত্তিশগড়ের জলবায়ু কী প্রকৃতির?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ছত্তিশগড়ের জলবায়ু প্রধানত শুষ্ক শীতকালীন ক্রান্তীয় বৃষ্টিবহুল
                প্রকৃতির।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ৩১. ভারতের কততম রাজ্য হিসেবে 2000 সালে ছত্তিশগড় রাজ্যটি গঠিত
                  হয়?
                </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতের 26 তম রাজ্য হিসেবে 2000 সালে ছত্তিশগড় রাজ্যটি গঠিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৩২. কোন্ জলপ্রপাতকে ভারতের নায়াগ্রা বলা হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                চিত্রকূট জলপ্রপাতকে 'ভারতের নায়াগ্রা বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৩৩. চিত্রকূট জলপ্রপাতটি কোন্ রাজ্যে কোন্ নদীর ওপর অবস্থিত?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                চিত্রকূট জলপ্রপাতটি ছত্তিশগড় রাজ্যে ইন্দ্রাবতী নদীর ওপর
                অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৩৪. ছত্তিশগড় রাজ্যের দুটি গুরুত্বপূর্ণ খনিজের নাম লেখাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ছত্তিশগড় রাজ্যের দুটি গুরুত্বপূর্ণ খনিজ হল-কয়লা ও ডলােমাইট।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৩৫. কোন্ রাজ্যের কিছু অংশ নিয়ে ছত্তিশগড় রাজ্যটি গঠিত হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মধ্যপ্রদেশের কিছু অংশ নিয়ে ছত্তিশগড় রাজ্যটি গঠিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৩৬. মাইথন-পাঞেতে নির্মিত জলাধারটি কোন পরিকল্পনা অঞ্চলের
                    অন্তর্গত?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মাইথন-পাঞেতে নির্মিত জলাধারটি দামােদর উপত্যকা পরিকল্পনা অঞ্চলের
                অন্তর্গত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৩৭. মানব উন্নয়ন সূচকের ভিত্তিতে পৃথিবীতে ভারতের স্থান কততম?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মানব উন্নয়ন সূচকের ভিত্তিতে পৃথিবীতে ভারতের স্থান 135 তম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৩৮. বাহ্যিক অঞ্চল নির্ধারণের দুটি নিয়ন্ত্রকের নাম লেখাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বাহ্যিক অঞ্চল নির্ধারণের দুটি নিয়ন্ত্রকের নাম হল—জলবায়ু ও
                ভাষা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৩৯. ক্ষুদ্র পরিকল্পনা অঞ্চল কে বা কারা পরিচালনা করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জেলা প্রশাসন, ব্লক প্রশাসন এবং গ্রাম পঞ্চায়েত দ্বারা ক্ষুদ্র
                পরিকল্পনা অঞ্চল পরিচালিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৪০. ছত্তিশগড়ের কোন্ কোন্ জেলায় প্রধানত কয়লা পাওয়া যায়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ছত্তিশগড়ের বিলাসপুর, সরগুজা, কোরিয়া, কোরবা প্রভৃতি জেলায়
                প্রধানত কয়লা পাওয়া যায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ৪১. ছত্তিশগড়ের কোন্ কোন্ অঞ্চলে প্রধানত আকরিক লােহা পাওয়া
                  যায়?
                </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ছত্তিশগড়ের বস্তার জেলার বাইলাডিলা ও দুর্গ জেলার দাল্লিরাজহারা
                অঞ্চলে প্রধানত আকরিক লােহা পাওয়া যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৪২. ছত্তিশগড়ে বসবাসকারী প্রধান উপজাতিরা কী নামে পরিচিত?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ছত্তিশগড়ে বসবাসকারী প্রধান উপজাতিরা গােন্ড নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৪৩. বিলাসপুর জেলার মৈকাল পাহাড়ে কোন্ খনিজ পদার্থ পাওয়া
                    যায়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বিলাসপুর জেলার মৈকাল পাহাড়ে বক্সাইট পাওয়া যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৪৪. ছত্তিশগড়কে পরিকল্পনা অঞ্চলের অন্তর্ভুক্ত করার যে-কোনাে
                    একটি কারণ লেখাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ছত্তিশগড়কে পরিকল্পনা অঞ্চলের অন্তর্ভুক্ত করার একটি কারণ
                হল—আদিবাসী এলাকাগুলির উন্নয়ন ঘটানাে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৪৫. বিশ্বের তথ্যপ্রযুক্তির রাজধানী কোথায় অবস্থিত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বিশ্বের তথ্যপ্রযুক্তির রাজধানী আমেরিকা যুক্তরাষ্ট্রের উত্তর
                ক্যালিফোর্নিয়া প্রদেশের সিলিকন ভ্যালিতে অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৪৬. ছত্তিশগড়ের কত শতাংশ জমিতে কৃষিকাজ করা হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ছত্তিশগড়ের ৩৫ শতাংশ জমিতে কৃষিকাজ করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৪৭. ছত্তিশগড়ের শতকরা কত ভাগ লােক কৃষিকাজের মাধ্যমে
                    জীবিকানির্বাহ করেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ছত্তিশগড়ের শতকরা ৪০ ভাগ লােক কৃষিকাজের মাধ্যমে জীবিকানির্বাহ
                করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৪৮. ভারতের মােট আদিবাসী সম্প্রদায়ের কত শতাংশ লােক
                    ছত্তিশগড়ে বসবাস করেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের মােট আদিবাসী সম্প্রদায়ের ২৩ শতাংশ লােক ছত্তিশগড়ে বসবাস
                করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৪৯. বেঙ্গালুরুকে নব প্রযুক্তির শহর বলা হয় কেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৯০-এর দশকের পর থেকে বেঙ্গালুরুতে প্রযুক্তির ব্যাপক উন্নতি
                ঘটায় তাকে নব প্রযুক্তির শহর বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৫০. বেঙ্গালুরুকে ভারতের ইলেকট্রনিকস রাজধানী বলা হয় কেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের শ্রেষ্ট ইলেকট্রনিকস দ্রব্য উৎপাদন কেন্দ্র হওয়ার জন্য
                বেঙ্গালুরুকে ভারতের ইলেকট্রনিকস রাজধানী বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ৫১. কোন্ কোন্ দেশের যৌথ উদ্যোগে বেঙ্গালুরুতে ইন্টারন্যাশনাল
                  টেক পার্ক গড়ে উঠেছে?
                </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারত ও সিঙ্গাপুরের যৌথ উদ্যোগে বেঙ্গালুরুতে ইন্টারন্যাশনাল টেক
                পার্ক গড়ে উঠেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫২. ব্রেন-ড্রেন কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দক্ষ ও শিক্ষিত কর্মীদের অতিরিক্ত আয়ের আশায় বিদেশে পাড়ি
                দেওয়ার ঘটনাকে ব্রেন-ড্রেন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫৩. বেঙ্গালুরুকে ভারতের সিলিকন ভ্যালি বলা হয় কেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বেঙ্গালুরুতে ইলেকট্রনিক শিল্পের ব্যাপক উন্নতির জন্য একে আমেরিকা
                যুক্তরাষ্ট্রের সিলিকন ভ্যালির সাথে তুলনা করে ভারতের সিলিকন
                ভ্যালি বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫৪. ভারতের জনসংখ্যার বিচারে বেঙ্গালুরুর স্থান কত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জনসংখ্যার বিচারে বেঙ্গালুরু ভারতের তৃতীয় বৃহত্তম মহানগর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫৫. ভারতের 'সিলিকন উপত্যকা কোন্ রাজ্যে অবস্থিত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের 'সিলিকন উপত্যকা' কর্ণাটক রাজ্যে অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৫৬. বেঙ্গালুরুর ইলেকট্রনিকস শিল্পের যে-কোনাে একটি সমস্যার
                    উল্লেখ করাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আন্তর্জাতিক বাজারে রপ্তানির ক্ষেত্রে উন্নত দেশগুলির (যেমন—চিন,
                জাপান, দক্ষিণ কোরিয়া এবং আমেরিকা যুক্তরাষ্ট্র) সাথে
                প্রতিযােগিতা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫৭. ২০১১ সালের জনগণনা অনুযায়ী বেঙ্গালুরুর জনসংখ্যা কত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ২০১১ সালের জনগণনা অনুযায়ী বেঙ্গালুরুর জনসংখ্যা হল ৮৪২৫৯৭০ জন।{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৫৮. কবে ব্যাঙ্গালাের থেকে বেঙ্গালুরু নামটির প্রচলন হয়েছে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ২০০৬ সালের ১ নভেম্বর ব্যাঙ্গালাের থেকে বেঙ্গালুরু নামটির প্রচলন
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৫৯. ভারতের বৈদ্যুতিন শিল্প বা ইলেকট্রনিক শিল্পের সর্ববৃহৎ
                    কেন্দ্র কোনটি?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বেঙ্গালুরু ভারতের বৈদ্যুতিন শিল্প বা ইলেকট্রনিক শিল্পের সর্ববৃহৎ
                কেন্দ্র।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৬০. কোন সময় থেকে বেঙ্গালুরু ভারতের তথা এশিয়ার একটি অন্যতম
                    আধুনিক শহরে পরিণত হয়েছে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বিংশ শতাব্দীর সাতের দশক থেকে বেঙ্গালুরু ভারতের তথা এশিয়ার একটি
                অন্যতম আধুনিক শহরে পরিণত হয়েছে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>৬১. বেঙ্গালুরুকে গার্ডেন সিটি বলা হয় কেন?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                বেঙ্গালুরুকে তার নৈসর্গিক সৌন্দর্যের জন্য গার্ডেন সিটি বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৬২. কোন্ দুটি নদীর মিলনস্থলে হলদিয়া বন্দর অবস্থিত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                হুগলি ও হলদি নদীর মিলনস্থলে হলদিয়া বন্দর অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৬৩. হলদিয়া শহরটি কোন্ ধরনের শিল্পের জন্য গড়ে উঠেছে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                হলদিয়া শহরটি পেট্রোরসায়ন শিল্প এবং তার অনুসারী শিল্পগুলির জন্য
                গড়ে উঠেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৬৪. হলদিয়া বন্দরটি কী ধরনের বন্দর?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                হলদিয়া বন্দরটি একটি নদীকেন্দ্রিক বন্দর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৬৫. হলদিয়া শিল্পাঞ্চল গড়ে ওঠার প্রধান কারণ কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                হলদিয়া শিল্পাঞ্চল গড়ে ওঠার প্রধান কারণ হল হলদিয়া বন্দরের
                অবস্থান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৬৬. হলদিয়া বন্দর গড়ে তােলার অন্যতম প্রধান কারণ কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কলকাতা বন্দর গুরুত্ব হারিয়ে ফেলায় তার পরিপূরক বন্দর হিসেবে
                হলদিয়া বন্দরকে গড়ে তােলা হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৬৭. হলদিয়া বন্দরকে কোথায় গড়ে তােলা হয়েছে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কলকাতা থেকে প্রায় 96 কিমি দক্ষিণে হুগলি এবং হলদি নদীর
                সংযােগস্থলে হলদিয়া বন্দর গড়ে তােলা হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৬৮. হলদিয়া বন্দর মারফত প্রধান আমদানি ও রপ্তানিকৃত
                    দ্রব্যগুলি কী?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                অপরিশােধিত তেল, পাটজাত দ্রব্য এবং চা হল হলদিয়া বন্দরের প্রধান
                আমদানি ও রপ্তানিকৃত দ্রব্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৬৯. হলদিয়া বন্দরের যে-কোনাে একটি গুরুত্ব লেখাে।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পশ্চিমবঙ্গের শিল্পের অগ্রগতিতে হলদিয়া বন্দর গুরুত্বপূর্ণ ভূমিকা
                পালন করেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৭০. হলদিয়া বন্দর কোন্ সংস্থা দ্বারা পরিচালিত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কলকাতা পাের্ট ট্রাস্ট দ্বারা হলদিয়া বন্দর পরিচালিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৭১. হলদিয়া পাের্ট ট্রাস্ট কেন তৈরি করা হয়েছে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                হলদিয়া বন্দর এলাকার সামগ্রিক উন্নতির জন্য হলদিয়া পাের্ট
                ট্রাস্ট তৈরি করা হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৭২. পশ্চিমবঙ্গের কোন্ জেলায় হলদিয়া বন্দরটি অবস্থিত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পশ্চিমবঙ্গের পূর্ব মেদিনীপুর জেলায় হলদিয়া বন্দরটি অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৭৩. স্বাধীনতার পরবর্তী ভারতের প্রথম বন্দরভিত্তিক শিল্পাঞ্চল
                    কোনটি?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                স্বাধীনতার পরবর্তী ভারতের প্রথম বন্দরভিত্তিক শিল্পাঞ্চলটি হল -
                হলদিয়া শিল্পাঞ্চল।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ৭</b>
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

আঞ্চলিকঅর্থনৈতিকউন্নয়ন.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(আঞ্চলিকঅর্থনৈতিকউন্নয়ন);
