import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function PreviousyearQuestionpaper2018() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          Previous year Question paper 2018 | West Bengal Class 12 Guide | West
          Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="Previous year Question paper 2018 | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Previous year Question paper 2018
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ভূপৃষ্ঠ থেকে ফ্রিয়েটিক স্তর পর্যন্ত বিস্তৃত অসম্পৃক্ত স্তরকে
                  বলে —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাকুইফার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাকুইক্লুড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ভ্যাদোস স্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাকুই টার্ড
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নব্য স্তরের অর্থনৈতিক কার্যাবলির একটি উদাহরণ হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  গবেষণা ও উন্নয়ন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পর্যটন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পরামর্শদান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্যাঙ্কিং পরিষেবা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>আমেরিকা যুক্তরাষ্ট্রের ডেট্রয়েট বিখ্যাত —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কার্পাস বয়ন শিল্পের জন্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মোটর গাড়ি নির্মাণ শিল্পের জন্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পেট্রোরসায়ন শিল্পের জন্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কাগজ শিল্পের জন্য
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>হলদিয়া দুটি নদীর সংযোগস্থলে অবস্থিত, সেগুলি হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হলদি ও রূপনারায়ন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হলদি ও কংসাবতী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হলদি ও হুগলি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভাগীরথী ও রূপনারায়ন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  প্রদত্ত ঘটনা গুলির মধ্যে যেটি একটি প্রাকৃতিক বিপর্যয়ের উদাহরণ
                  নয়, সেটি হল —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  2009 খ্রিস্টাব্দের আয়লা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1984 খ্রিস্টাব্দের ভূপাল গ্যাস দুর্ঘটনা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  2004 খ্রিস্টাব্দের সুনামি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  2015 খ্রিস্টাব্দের নেপালের ভূমিকম্প
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ক্যালিফোর্নিয়া যে জলবায়ু অঞ্চলটিতে অবস্থিত সেটি হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মৌসুমি জলবায়ু অঞ্চল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ভূমধ্যসাগরীয় জলবায়ু অঞ্চল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তুন্দ্রা জলবায়ু অঞ্চল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মরু জলবায়ু অঞ্চল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  {" "}
                  মৃত্তিকার যে স্তরে উদ্ভিদ ও প্রাণীর দেহাবশেষ আংশিক বা সম্পূর্ণ
                  বিয়োজিত হয়ে হিউমাসে পরিণত হয়, সেই স্তরটি হল —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  A' স্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  B' স্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  O' স্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  R' স্তর
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  {" "}
                  উপকূলের সাথে সমান্তরালে অবস্থিত শৈলশিরার সমুদ্রে আংশিক নিমজ্জন
                  এর ফলে গঠিত উপকূল কে বলে —{" "}
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যৌগিক উপকূল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রিয়া উপকূল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফিয়র্ড উপকূল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ডালমেশিয়ান উপকূল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>একটি পুষ্করিনী কে কেন্দ্র করে যে বসতি গড়ে ওঠে, তা হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শুষ্ক বিন্দু বসতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আর্দ্র বিন্দু বসতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রৈখিক বসতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বর্গাকার বসতি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সেবা ক্ষেত্রের কাজ কর্মে নিযুক্ত কর্মীদের বলা হয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাদা পোশাকের কর্মী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লাল পোশাকে র কর্মী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নীল পোশাকের কর্মী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  গোলাপি পোশাকের কর্মী
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#1f7405" size="5">
                <b>H.S QUESTION PAPER 2018</b>
              </font>
              <br />
              <font color="#3c92e5">
                <font color="#ed9c23" size="5">
                  <b>Geography</b>
                </font>
                <br />
                <font color="#ed9c23">(New Syllabus)</font>
                <br />
                <font color="#f11eda">
                  Total Time : 3 Hours 15 minutes
                  <br />
                  Total Marks : 70
                </font>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#1f7405" size="5">
                <b> PART - B (Marks : 35)</b>
              </font>
              <br />
              <br />

              <font color="#ed9c23">
                <b>
                  1. প্রতিটি প্রশ্নের বিকল্প উত্তরগুলির মধ্যে থেকে সঠিক উত্তরটি
                  বেছে নিয়ে ডানদিকে নীচে প্রদত্ত বাক্সে লেখাে :
                  <font color="#1f7405">
                    <b> 1 x 21 = 21</b>
                  </font>
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                <font color="#f11eda">
                  <b>
                    i) ভূপৃষ্ঠ থেকে ফ্রিয়েটিক স্তর পর্যন্ত বিস্তৃত অসম্পৃক্ত
                    স্তরকে বলে —
                  </b>
                </font>
                <br />
                (a) অ্যাকুইফার
                <br />
                (b) অ্যাকুইক্লুড
                <br />
                <font color="#1f7405">
                  <b>(c) ভ্যাদোস স্তর</b>
                </font>
                <br />
                (d) অ্যাকুই টার্ড
                <br />
                <br />
                <font color="#f11eda">
                  <b>ii) নব্য স্তরের অর্থনৈতিক কার্যাবলির একটি উদাহরণ হল — </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) গবেষণা ও উন্নয়ন</b>
                </font>
                <br />
                (b) পর্যটন
                <br />
                (c) পরামর্শদান
                <br />
                (d) ব্যাঙ্কিং পরিষেবা
                <br />
                <br />
                <font color="#f11eda">
                  <b>iii) আমেরিকা যুক্তরাষ্ট্রের ডেট্রয়েট বিখ্যাত —</b>
                </font>
                <br />
                (a) কার্পাস বয়ন শিল্পের জন্য
                <br />
                <font color="#1f7405">
                  <b>(b) মোটর গাড়ি নির্মাণ শিল্পের জন্য </b>
                </font>
                <br />
                (c) পেট্রোরসায়ন শিল্পের জন্য
                <br />
                (d) কাগজ শিল্পের জন্য
                <br />
                <br />
                <font color="#f11eda">
                  <b>iv) হলদিয়া দুটি নদীর সংযোগস্থলে অবস্থিত, সেগুলি হল —</b>
                </font>
                <br />
                (a) হলদি ও রূপনারায়ন
                <br />
                (b) হলদি ও কংসাবতী
                <br />
                <font color="#1f7405">
                  <b>(c) হলদি ও হুগলি</b>
                </font>
                <br />
                (d) ভাগীরথী ও রূপনারায়ন
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    v) প্রদত্ত ঘটনা গুলির মধ্যে যেটি একটি প্রাকৃতিক বিপর্যয়ের
                    উদাহরণ নয়, সেটি হল —
                  </b>
                </font>
                <br />
                (a) 2009 খ্রিস্টাব্দের আয়লা
                <br />
                <font color="#1f7405">
                  <b>(b) 1984 খ্রিস্টাব্দের ভূপাল গ্যাস দুর্ঘটনা</b>
                </font>
                <br />
                (c) 2004 খ্রিস্টাব্দের সুনামি
                <br />
                (d) 2015 খ্রিস্টাব্দের নেপালের ভূমিকম্প
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    vi) ক্যালিফোর্নিয়া যে জলবায়ু অঞ্চলটিতে অবস্থিত সেটি হল —
                  </b>
                </font>
                <br />
                (a) মৌসুমি জলবায়ু অঞ্চল <br />
                <font color="#1f7405">
                  <b>(b) ভূমধ্যসাগরীয় জলবায়ু অঞ্চল</b>
                </font>
                <br />
                (c) তুন্দ্রা জলবায়ু অঞ্চল
                <br />
                (d) মরু জলবায়ু অঞ্চল
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    vii) মৃত্তিকার যে স্তরে উদ্ভিদ ও প্রাণীর দেহাবশেষ আংশিক বা
                    সম্পূর্ণ বিয়োজিত হয়ে হিউমাসে পরিণত হয়, সেই স্তর টি হল —
                  </b>
                </font>
                <br />
                (a) 'A' স্তর
                <br />
                (b) 'B' স্তর
                <br />
                <font color="#1f7405">
                  <b>(c) 'O' স্তর</b>
                </font>
                <br />
                (d) 'R' স্তর
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    viii) উপকূলের সাথে সমান্তরালে অবস্থিত শৈলশিরার সমুদ্রে আংশিক
                    নিমজ্জন এর ফলে গঠিত উপকূলকে বলে —{" "}
                  </b>
                </font>
                <br />
                (a) যৌগিক উপকূল
                <br />
                (b) রিয়া উপকূল
                <br />
                (c) ফিয়র্ড উপকূল
                <br />
                <font color="#1f7405">
                  <b>(d) ডালমেশিয়ান উপকূল</b>
                </font>
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    ix) একটি পুষ্করিনী কে কেন্দ্র করে যে বসতি গড়ে ওঠে, তা হল —
                  </b>
                </font>
                <br />
                (a) শুষ্ক বিন্দু বসতি <br />
                <font color="#1f7405">
                  <b>(b) আর্দ্র বিন্দু বসতি</b>
                </font>
                <br />
                (c) রৈখিক বসতি
                <br />
                (d) বর্গাকার বসতি
                <br />
                <br />
                <font color="#f11eda">
                  <b>x) সেবা ক্ষেত্রের কাজ কর্মে নিযুক্ত কর্মীদের বলা হয় —</b>
                </font>
                <br />
                (a) সাদা পোশাকের কর্মী (b) লাল পোশাকে র কর্মী (c) নীল পোশাকের
                কর্মী
                <font color="#1f7405">
                  <b>(d) গোলাপি পোশাকের কর্মী</b>
                </font>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f11eda">
                <b>
                  xi) মেক্সিকো উপসাগর ও ক্যারিবিয়ান সাগরে ক্রান্তীয় ঘূর্ণবাত
                  যে নামে পরিচিত, তা হল —
                </b>
              </font>
              <br />
              <font color="#3c92e5">
                (a) টাইফুন
                <br />
                (b) টর্নেডো <br />
                <font color="#1f7405">
                  <b>(c) হ্যারিকেন </b>
                </font>
                <br />
                (d) উইলি- উইলি
                <br />
                <br />
                <font color="#f11eda">
                  <b>xii) জীব বৈচিত্র শব্দটি প্রথম ব্যবহার করেন —</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) ওয়াল্টার রোজেন </b>
                </font>
                <br />
                (b) নরম্যান মায়ারস
                <br />
                (c) চার্লস ডারউইন
                <br />
                (d) রবার্ট হুক
                <br />
                <br />
                <font color="#f11eda">
                  <b>xiii) ভারতের নীল বিপ্লব যে উৎপাদনের সাথে জড়িত তা হলো —</b>
                </font>
                <br />
                (a) দুধ
                <br />
                (b) মাংস
                <br />
                (c) ডিম
                <br />
                <font color="#1f7405">
                  <b>(d) মাছ</b>
                </font>
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    xiv) একনত গঠনযুক্ত ভূমি ভাগে যে ধরনের নদী নকশা গড়ে ওঠে,
                    সেটি হল —
                  </b>
                </font>
                <br />
                (a) অঙ্গুরীয় নদী নকশা
                <br />
                (b) কেন্দ্রবিমুখ নদী নকশা
                <br />
                <font color="#1f7405">
                  <b>(c) জাফরী রুপি নদী নকশা</b>
                </font>
                <br />
                (d) কেন্দ্রমুখী নদী নকশা
                <br />
                <br />
                <font color="#f11eda">
                  <b>xv) ভারতের একটি উল্লেখযোগ্য কাস্ট অঞ্চল হল — </b>
                </font>
                <br />
                (a) কাশ্মীর উপত্যকা
                <br />
                <font color="#1f7405">
                  <b>(b) বোরা গুহা</b>
                </font>
                <br />
                (c) ব্লু পার্বত্য অঞ্চল
                <br />
                (d) অজন্তা গুহা
                <br />
                <br />
                <font color="#f11eda">
                  <b>xvi) ভারতের বয়-লিঙ্গ পিরামিড নির্ণয়ের সূত্রটি হল - </b>
                </font>
                <br />
                (a) (মোট নারী সংখ্যা/মোট পুরুষের সংখ্যা) × 100
                <br />
                <font color="#1f7405">
                  <b>(b) (মোট নারী সংখ্যা/মোট পুরুষের সংখ্যা) × 1000</b>
                </font>
                <br />
                (c) (মোট পুরুষের সংখ্যা/মোট নারীর সংখ্যা) × 100
                <br />
                (d) (মোট পুরুষের সংখ্যা/মোট নারীর সংখ্যা) × 1000
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    xvii) কষ্টিক সোডা ও সোডা অ্যাস কাঁচামাল হিসেবে ব্যবহৃত হয় —{" "}
                  </b>
                </font>
                <br />
                (a) পাট শিল্পে
                <br />
                (b) কার্পাস বয়ন শিল্পে
                <br />
                <font color="#1f7405">
                  <b>(c) কাগজ শিল্পে</b>
                </font>
                <br />
                (d) লৌহ ইস্পাত শিল্পে
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    xviii) চীনের যে প্রদেশ টি ' চীনের ধানের ভান্ডার' নামে
                    পরিচিত, তা হল —
                  </b>
                </font>
                <br />
                (a) ইউনান
                <br />
                (b) জেচুয়ান
                <br />
                <font color="#1f7405">
                  <b>(c) হুনান প্রদেশ</b>
                </font>
                <br />
                (d) হুবেই
                <br />
                <br />
                <font color="#f11eda">
                  <b>xix) এল নিনো দেখা যায় —</b>
                </font>
                <br />
                (a) আটলান্টিক মহাসাগরে
                <br />
                <font color="#1f7405">
                  <b>(b) প্রশান্ত মহাসাগরে</b>
                </font>
                <br />
                (c) ভারত মহাসাগরে
                <br />
                (d) ভূমধ্যসাগরে
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    xx) উদ্ভিদের জন্য প্রয়োজনীয় প্রধান প্রাথমিক পরিপোষকের একটি
                    উদাহরণ হল —{" "}
                  </b>
                </font>
                <br />
                (a) লৌহ
                <br />
                (b) ম্যাঙ্গানিজ
                <br />
                (c) তামা
                <br />
                <font color="#1f7405">
                  <b>(d) নাইট্রোজেন</b>
                </font>
                <br />
                <br />
                <font color="#f11eda">
                  <b>xxi) পাদসমতলিকরণ মতবাদটি প্রথম অবতারণা করেন — </b>
                </font>
                <br />
                (a) ডব্লিউ. এম. ডেভিস
                <br />
                (b) ডব্লিউ. পেঙ্ক
                <br />
                (c) জে. টি. জ্যাক
                <br />
                <font color="#1f7405">
                  <b>(d) এল. সি. কিং</b>
                </font>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#1f7405">
                <b>
                  2. নিম্মলিখিত প্রশ্নগুলির উত্তরদাও : ( বিকল্প প্রশ্নগুলি
                  লক্ষণীয় ) :{" "}
                  <font color="#1f7405">
                    <b> 1 × 14 = 14</b>
                  </font>
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                <font color="#f11eda">
                  <b>(i) নিক্ বিন্দু কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পুনযৌবন ঘটলে নদীর দৈর্ঘ্য বরাবর একটি বিন্দুতে উর্ধ্ব উপত্যকার
                পুরোনো মৃদুঢ়ালের সঙ্গে নিম্ম উপত্যকার নতুন খাড়া ঢালের সংযোগ
                হয়, একে নিক বিন্দু বা নিক পয়েন্ট বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(ii) আয়ন বায়ু ককে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                আয়ন শব্দের অর্থ পথ। কর্কটীয় ও মকরীয় উচ্চচাপ থেকে নিরক্ষীয়
                নিম্মচাপ বলয়ের দিকে প্রবহিত নিয়ত বায়ুকে আয়নবায়ু বলে।
                উত্তরগোলার্ধে উত্তরপূর্ব আয়ন বায়ু এবং দক্ষিণ গোলার্ধে দাক্ষিণ
                পূর্ব আয়ন বায়ু প্রবাহিত হয়।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(iii) মেঘ ভাঙা বৃষ্টি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                কিউমুলোনিম্বাস মেঘে দ্রুত ঘনীভবনের ফলে হঠাৎ অল্প সময়ের মধ্যে
                প্রবলবর্ষণকে মেঘভাঙা বৃষ্টি বলে। ২০১৩ সালে মেঘভাঙা বৃষ্টির কারণে
                প্রচুর সম্পদ নষ্ট ও জীবনহানি হয়।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(iv) শুন্য জনসংখ্যা বৃদ্ধি বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                কোনো দেশের একবছরে জন্ম ও অভিবাসনের মোট সংখ্যা, মৃত্যু ও
                প্রবাসনের মোট সংখ্যার সমান হলে সেই ্দেশের জনসংখ্যার কোনো
                পরিবর্তন ঘটেনা। জনসংখ্যা বৃদ্ধির এরুপ অবস্থাকে শুন্য জনসংখ্যা
                বৃদ্ধি বলে। সুতরাং শুন্য জনসংখ্যা বৃদ্ধি (জন্ম + অভিবাসন ) -
                (মৃত্যু + প্রবাসন) = 0<br />
                <br />
                <font color="#f11eda">
                  <b>(v) ফোর ও ক্লোক রেন কোন জলবায়ু অঞ্চলে দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                নিরক্ষীয় জলবায়ু অঞ্চলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>অথবা, ঘূর্ণবাতের চক্ষু কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                শক্তিশালী ক্রান্তীয় ঘূর্ণবাতের কেন্দ্রে ১০ থেকে ২০ কিমি
                ব্যাস'যুক্ত এক গতিহীন... শান্ত প্রায় মেঘশূন্য অবস্থা বিরাজ করে,
                একে ঘূর্ণবাতের চক্ষু বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(vi) মলিসলের উদাহরণ দাও?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                চারনোজেম মৃত্তিকা, চেস্টনাট মৃত্তিকা।
                <br />
                <br />
                <font color="#f11eda">
                  <b> অথবা, মৃত্তিকা সংরক্ষণ বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে বিজ্ঞানসম্মত পদ্ধতির মাধ্যমে কৃষি উৎপাদন সহ বিভিন্ন কাজে
                ব্যবহৃত মৃত্তিকাকে ক্ষয় ও অবনমনের হাত থেকে সম্পূর্ন বা আংশিক
                রক্ষা করা যায়, তাকেই বলে মৃত্তিকা সংরক্ষণ।
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (vii) ভারতের জীববৈচিত্রের উষ্মা অঞ্চলের দুটি উদাহরণ দাও।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পশ্চিমঘাট পর্বত ও হিমালয় অঞ্চল
                <br />
                <br />
                <font color="#f11eda">
                  <b>(viii) বাজার বাগান কৃষির সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                বড়ো বড়ো শহরের দৈনন্দিন শাকসবজি, ফুল, ফল প্রভৃতির চাহিদা মেটাতে
                যখন ওই সব শহরেরই উপকণ্ঠে বা শহরতলিতে অত্যন্ত আধুনিক পদ্ধতিতে ওই
                ফসলগুলির চাষ করা হয়, তখন তাকে বলে বাজার বাগান কৃষি।
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    {" "}
                    অথবা, ভারতের দুটি প্রধান চীনাবাদাম উৎপাদনকারী রাজ্যের নাম
                    করো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                গুজরাট ও রাজস্থান।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(ix) পৃথিবীর দীর্ঘতম রেলপথটির নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ট্রান্স সাইবেরিয়ান রেলপথ।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(x) পরিকল্পনা অঞ্চলের সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পরিকল্পনা অঞ্চল:- কোন ভূ-খন্ডে সুষম অর্থনৈতিক উন্নয়নের জন্য
                পরিকল্পনার উদ্দেশ্য ও কর্মসূচীকে একইভাবে কার্যকর করা হয় এবং
                সমগ্র অঞ্চলটির ভূ-প্রাকৃতিক ও আর্থ সামাজিক অবস্থা অভিন্ন হয়,
                তাকেই তাকে পরিকল্পনা অঞ্চল বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b> অথবা, ছত্রিশগড় অঞ্চলের প্রধান নদী কোনটি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                মহানদী।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xi) সমুদ্রতটের কোন অংশ পশ্চাৎ তটভূমি নামে পরিচিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                সমুদ্র জলের উর্ধ্ব জলসীমা থেকে ভূগুতটের পাদদেশের মধ্যে বিস্তৃর্ণ
                অঞ্চল পশ্চাৎ তটভূমি নামে পরিচিত
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xii) অনুগামী নদীর সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ভূমির প্রধান বা প্রাথমিক ঢালকে অনুসরণ করে যে নদী প্রবাহিত হয়
                তাকে অনুগামী নদী বলে। যেমন - গঙ্গা নদী।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xiii) আইসোটিম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                আলফ্রেড ওয়েবারের শিল্পস্থানিকতা তত্ত্বে কাঁচামাল ও উৎপন্ন
                পণ্যের সমপরিবহণ ব্যয় বিশিষ্ট স্থানগুলিকে যে বৃত্তকার রেখা
                দ্বারা যুক্ত করা হয়, তাকে সমপরিবহণ ব্যয় রেখা বা আইসোটিম বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    {" "}
                    অথবা, আমেরিকা যুক্তরাষ্ট্রের দক্ষিণাঞ্চলে অবস্থিত দুটি
                    কার্পাস বয়ন শিল্পকেন্দ্রের নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                কলাম্বিয়া, আটলান্টা, অগাস্টা, ম্যাকন, রেলিগ, বার্মিংহোম।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xiv) পর্যায়নের সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে প্রক্রিয়ায় ক্ষয় ও সঞ্চয়কার্যের মাধ্যমে ভূত্বকের উপরিভাগের
                উচ্চতার পরিবর্তন ঘটে এবং বিভিন্ন উচ্চতাযুক্ত ভূমিরূপের ক্রমশ
                একটি সমতা এনে একটি সাধারণ তল বা পৃষ্ঠ গঠিত হয় তাকে পর্যায়ন বলে
                । অর্থাৎ ক্ষয়ের শেষ সীমার সাপেক্ষে ভূপৃষ্ঠের সমতলিকরণ হল
                পর্যায়ন।
                <br />
                <br />
                <font color="#f11eda">
                  <b> অথবা, গিজার কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ভূগর্ভের উষ্ণ জল বাষ্পসহ নিয়মিত এবং মোটামুটি নির্দিষ্ট সময়
                অন্তর প্রবল বেগে ফোয়ারার মত স্তম্ভাকারে উৎক্ষিপ্ত হলে তাকে
                গিজার বলে।
                <br />
                <br />
                যেমন : আমেরিকা যুক্তরাষ্ট্রের শুল্ড ফেথফুল গিজার।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#1f7405" size="4">
                <b>PART - A (Marks 35)</b>
              </font>
              <br />
              <br />

              <font color="#f11eda">
                <b>
                  1. নিম্মলিখিত প্রশ্নগুলির উত্তর দাও। ( বিকল্প প্রশ্নগুলি
                  লক্ষণীয় ) :{" "}
                  <font color="#1f7405">
                    <b> ৭ × ৫ = ৩৫</b>
                  </font>
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                (a) উইলিয়াম মরিস ডেভিস বর্ণিত ক্ষয়বজ্রের বিভিন্ন পর্যায়গুলি
                উপযুক্ত চিত্রসহ বর্ণনা করো..... ইলুভিয়েশন বলতে কী বোঝায়? ৬+১ =
                ৭<br />
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                তরঙ্গ কর্তিত মঞ্চ , স্বাভাবিক খিলান, ও রো- হোল কীভাবে সৃষ্টি
                হয়, তা উপযুক্ত চিত্রসহ ব্যাখ্যা করো অধ্যারোপ নদী কাকে বলে ? ৬+১
                = ৭<br />
                <br />
                (b) পরিবেশের উপর ওজোন স্তরের অবক্ষয়ের প্রভাব আলোচনা করো
                ।লবণাম্বু উদ্ভিদের অভিযোজনগতৎবৈশিষ্টগুলি উল্লেখ করো। ৪ + ৩ = ৭
                <br />
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                জীববৈচিত্রের জিনগত বৈচিত্র ও প্রজাতিগত বৈচিত্র সম্বন্ধে
                সংক্ষিপ্ত টিকা লেখো। জেট বায়ুপ্রবাহের তিনটি বৈশিষ্ট্য উল্লেখ
                করো। ৪ + ৩ = ৭<br />
                <br />
                (c) ভারতীয় কৃষিতে সবুজ বিপ্লবের প্রভাব আলোচনা করো। নিবিড়
                জীবিকা সত্তাভিত্তিক কৃষির ক্ষেত্রে মাথাপিছু উৎপাদন কম হয় কেন?
                শস্য সমন্বয়ের সংজ্ঞা দাও। ৩ + ২ + ২ = ৭<br />
                <br />
                (d) ব্রাজিলে রবার শিল্প গড়ে ওঠার সমস্যা গুলি আলোচনা করো। ভারতে
                তৈরি পোশাক শিল্প উন্নতি লাভ করেছে কেন? দ্রব্য সূচক কী? ২ + ৩ + ২
                = ৭<br />
                <br />
                (e) বিশ্বব্যাপী জনসংখ্যা বন্টনের ওপর পরিব্রাজনের প্রভাব আলোচনা
                করো ।জনবিবর্তনের বিভিন্ন পর্যায়ের বৈশিষ্ট্যসমূহ উল্লেখ করো। ৩ +
                ৪ = ৭<br />
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                ছত্রিশগড়ের খনিজ সম্পদের একটি সংক্ষিপ্ত বিবরণ দাও। কার্যাবলীর
                ভিত্তিতে পৌরবসতির শ্রেণিবিভাগ করো ও উদাহরণ দাও। ৩ + ৪ = ৭
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

PreviousyearQuestionpaper2018.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(PreviousyearQuestionpaper2018);
