import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function মৃত্তিকা() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          মৃত্তিকা | West Bengal Class 12 Guide | West Bengal HS Suggestion |
          Higher Secondary Guide | WBCHSE | WB
        </title>
        <meta
          name="description"
          content="মৃত্তিকা | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                মৃত্তিকা
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="5">
                <b>চতুর্থ অধ্যায়</b>
              </font>
              <br />{" "}
              <font color="#2b9a09" size="5">
                <b>মৃত্তিকা</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ৮</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                ১. মৃত্তিকার পরিলেখ কাকে বলে? মাটি সৃষ্টির মৌলিক প্রক্রিয়া গুলি
                আলোচনা করো।
                <br />
                ২. আঞ্চলিক মৃত্তিকা ও অনাঞ্চলিক মৃত্তিকার পার্থক্য লেখো।
                <br />
                ৩. মৃত্তিকার পরিলেখ বা Soil Profile-এর বিভিন্ন স্তরগুলি আলোচনা
                করো।
                <br />
                ৪. মৃত্তিকা সংরক্ষণের উপায় বা পদ্ধতি আলোচনা করো।
                <br />
                ৫. এলুভিয়েশন ও ইলুভিয়েশনের মধ্যে পার্থক্য নির্দেশ করো। আদিশিলা
                কীভাবে সময়ের সাথে বিভিন্ন পর্যায়ে বিবর্তনের মাধ্যমে মাটিতে
                পরিণত হয়?
                <br />
                ৬. পরিণত ও অপরিণত মাটির মধ্যে তুলনা করো। পেডোক্যাল ও পেডালফার
                মাটির তুলনা করো। 4+3
                <br />
                ৭. মৃত্তিকার ভৌত ধর্ম ও রাসায়নিক ধর্ম সম্বন্ধে আলোচনা করো।
                <br />
                ৮. মাটি গঠনে বৃষ্টিপাতের ভূমিকা উল্লেখ করো। মাটির রং নানারকম হয়
                কেন?
                <br />
                ৪৯. মৃত্তিকার উর্বরতা বলতে কী বোঝ? মৃত্তিকার উর্বরতার
                নিয়ন্ত্রকসমূহ আলোচনা করো।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ১</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>১. কাকে মৃত্তিকা বিজ্ঞানের জনক বলা হয়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ডকুচেভকে।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ২. ভূপৃষ্ঠের থেকে কত গভীরতা পর্যন্ত মৃত্তিকা-মণ্ডলের অবস্থান?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">৯ - ১০ মিটার।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩. USDA-এর পুরাে নাম কী ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                USDA-এর পুরাে নাম– United States Department of Agriculture
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪. মৃত্তিকার গ্রথন কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                মৃত্তিকার মধ্যস্থিত মৃত্তিকা গঠনকারী বিভিন্ন আকৃতির খনিজকণার
                আপেক্ষিক অনুপাত হল মৃত্তিকার গ্রথন।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫. আনুস্রাবণ কম হলে কোন্ মৃত্তিকার সৃষ্টি হয়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> পেডােক্যাল মৃত্তিকা।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬. বেশি মাত্রায় চুন ও লবণ দ্বারা গঠিত মৃত্তিকার নাম কী ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> পেডােক্যাল।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৭. কোন্ অঞলে অধিক পরিমাণে পেডালফার মৃত্তিকা দেখতে পাওয়া যায়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> আর্দ্র অঞ্চলে।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৮. মাটির খনিজ উপাদান ভাসমান অবস্থায় যান্ত্রিক পদ্ধতি দ্বারা
                  অপসারিত হয়, তাকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> লেসিভেজ।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৯. লিচিং কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                রাসায়নিকভাবে এভিয়েশন প্রক্রিয়াকে বলা হয় লিচিং।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১০. মৃত্তিকার pH কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                মৃত্তিকার মধ্যে হাইড্রোজেন আয়নের ঘনত্বের পরিমাণের বা ওজনের
                লগারিদমকে বলে মৃত্তিকার pH।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>১১.মৃত্তিকার অনুক্রমের A ও B স্তরকে একত্রে কী বলা হয়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">সােলাম।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১২. হিউমাস সর্বাধিক কোথায় দেখা যায় ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">চারনােজেম মৃত্তিকায়।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৩. পেডােক্যাল মৃত্তিকার একটি বৈশিষ্ট্য লেখাে। </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                পেডােক্যাল মৃত্তিকায় pH-এর মান ৭-এর বেশি হয়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ১৪. মৃত্তিকার রং অনুসারে গুণমান বিচারের জন্য কোন চার্ট ব্যবহার
                  হয়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                মুনসেন কালার চার্ট (Munsell Colour Chart)।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ১৫. মৃত্তিকায় লৌহের পরিমাণ বেশি হলে মৃত্তিকার বর্ণ কী হয়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">লাল।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ১৬. মাটির কণা একত্রিত হয়ে যে পাতলা প্লেট আকারের গঠন সৃষ্টি
                  করে তাকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">ল্যামিনার।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৭. কঙ্কালসার মাটি কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                পার্বত্য অঞ্চলে সৃষ্ট মৃত্তিকায় পলি ও বালির সঙ্গে প্রচুর বড়াে
                বড়াে পাথর ও নুড়ি মেশানাে যে নবীন অপরিণত মাটি পরিলক্ষিত হয়,
                তাকে কঙ্কালসার মাটি বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৮. কোন্ মৃত্তিকা অনুস্রাবণ বেশি হলে সৃষ্টি হয়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> পেডালফার মাটি।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৯. কোন্ পদ্ধতিতে সােলানটেজ মৃত্তিকা গড়ে ওঠে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">অ্যালকালাইজেশন।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২০.স্যালিনাইজেশন কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                মৃত্তিকার পরিলেখের মধ্যে ক্যালশিয়াম, ম্যাগনেশিয়াম ও সােডিয়াম
                লবণ ক্লোরাইড বা সালফেট রূপে সঞ্চিত হওয়ার প্রক্রিয়াকেই বলা হয়
                স্যালিনাইজেশন। এর ফলে সােলানচাক মাটি সৃষ্টি হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>২১. মৃত্তিকা সৃষ্টির দুটি বিশেষ প্রক্রিয়ার নাম লেখাে।</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> অ্যালকালাইজেশন এবং পড়জোলাইজেশন।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ২২. মৃত্তিকার রাসায়নিক বিক্রিয়ার ভিত্তিতে মৃত্তিকার
                  শ্রেণিবিভাগ করাে।
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                দুই ভাগে বিভক্ত। ১. পেডলফার, ২. পেডােক্যাল।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ২৩. আবহবিকারের ফলে মাটির উৎপত্তির প্রাথমিক পর্যায়ে কী সৃষ্টি
                  হয়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> রেগােলিথ।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৪. সােলাম কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                মৃত্তিকা পরিলেখের A ও B স্তরকে একত্রে বলা হয় সােলাম।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৫. মৃত্তিকা সৃষ্টির প্রাথমিক ধাপটি কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> আবহবিকার।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৬. মৃত্তিকার ক্যাটেনা বলতে কী বােঝায় ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে মৃত্তিকা একই ধরনের আদি শিলা ও জলবায়ুযুক্ত অঞ্চলে
                ভূ-প্রাকৃতিক তারতম্যের জন্য গঠিত হয়, তাকে বলা হয় ক্যাটেনা।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৭. মৃত্তিকার কাটেনার ধারণাটি কোন্ বিজ্ঞানী দিয়েছেন?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">মিলনে।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৮. অপরিণত মৃত্তিকা বলতে কী বােঝাে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                প্রতিকূল পরিবেশে মাটি গঠনকারী প্রক্রিয়াগুলির অসম্পূর্ণ কাজের
                ফলে সৃষ্ট আদিশিলার ছাপযুক্ত, স্তরহীন, আলগা ও ভগুর মাটিকে অপরিণত
                মাটি বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ২৯. কোন্ প্রক্রিয়ায় সােলানচাক ও সােলােনেজ মৃত্তিকা সৃষ্টি
                  হয়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> কৈশিক প্রক্রিয়ায়।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩০. মৃত্তিকা স্তরে জল প্রবেশ করার ক্ষমতাকে কী বলা হয়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> মাটির প্রবেশ্যতা।</font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ৩১. কী কারণে শীতল মরু অঞলের তুলনায় উম্ন ক্রান্তীয় অঞলে
                  মৃত্তিকা সৃষ্টির হার দ্রুত ?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> অধিক তাপমাত্রার কারণে।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৩২. কোন্ রাসায়নিক আবহবিকার দ্বারা ফেরাস-অক্সাইড ফেরিক
                  অক্সাইডে পরিণত হয় ?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> অক্সিডেশন।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৩৩. কোন্ বিজ্ঞানী সর্বপ্রথম মৃত্তিকার সৃষ্টির কারণ ও
                  নিয়ন্ত্রক সম্পর্কে ধারণা দেন?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> জেনি।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৪. তৈগা অরণ্যের মাটি গঠিত হয় কোন প্রক্রিয়ায়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> পডসলাইজেশন।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৩৫. মৃত্তিকা সৃষ্টির প্রথম পর্যায়ে কোন্ প্রক্রিয়া
                  গুরুত্বপূর্ণ?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> আবহবিকার।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৬. গিলগাই মৃত্তিকা কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                কৃষ্ণ মৃত্তিকা জলে সিক্ত হলে ফুলে ওঠে, তাই একে গিলগাই মৃত্তিকা
                বলা হয়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৩৭. কোন্ মৃত্তিকা বিজ্ঞানী সর্বপ্রথম দক্ষিণ ভারতের ইটভাঙা
                  সুরকির মতাে লাল রঙের মৃত্তিকার নাম দেন ল্যাটেরাইট?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> বুকানন।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৮. মৃত্তিকার pH পরিমাপক স্কেলের আবিষ্কর্তা কে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> সােরেনসেন।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৩৯. কোন্ প্রক্রিয়া প্লেইজেশন প্রক্রিয়ায় বেশি কার্যকরী হয় ?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> বিজারণ।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪০.ক্র্যাটেভিনা কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                চরনােজেম মৃত্তিকার A ভূরে মৃদভেদী প্রাণী কর্তৃক সৃষ্ট গর্তকেই
                বলা হয় ক্রাটেভিনা।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>৪১. মৃত্তিকা বলয় কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                জলবায়ু, স্বাভাবিক উদ্ভিদ, জনকশিলা, জৈব পদার্থের উপস্থিতি, এ
                ছাড়া বিভিন্ন আঞ্চলিক, অনালিক বৈশিষ্ট্যের উপর ভিত্তি করে
                মৃত্তিকার দীর্ঘ অলব্যাপী বিস্তারকে বলে মৃত্তিকা বলয়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪২. জলবায়ুর প্রত্যক্ষ প্রভাবে গঠিত মাটিসমূহকে কী বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> আঞ্চলিক মাটি।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৩. অ্যান্টিসল’ কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                অতিসাম্প্রতিক সময়ে সৃষ্ট স্তরহীন নবীন মৃত্তিকাকে বলে
                অ্যান্টিসল। যেমন — রেগােসল মাটি।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৪. শারীরবৃত্তীয় শুষ্ক মাটি কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                আর্দ্র যে মাটিতে লবণতা বেশি হওয়ার কারণে উদ্ভিদ রস শােষণ করতে
                পারে না, সে মাটিকে শারীরবৃত্তীয় শুষ্ক মাটি বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৫. পলিমৃত্তিকা কী প্রকার মৃত্তিকা?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> অনালিক মৃত্তিকা।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৬. সােলানচাক কী ধরনের মৃত্তিকা?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ক্ষারীয় মৃত্তিকা।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৭. ল্যাটেরাইট মৃত্তিকা কোন্ জলবায়ুতে সৃষ্টি হয়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ক্রান্তীয় উয়-আর্দ্র ও উপক্ৰান্তীয় জলবায়ুতে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৮. ম্পর্কোন্ ধরনের শিলা থেকে রেগুর মাটি সৃষ্টি হয়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ব্যাসল্ট শিলা থেকে।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৯. কোন্ বিশেষ প্রক্রিয়ায় ‘পেডােক্যাল’ মাটি সৃষ্টি হয়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ক্যালসিফিকেশন প্রক্রিয়ায়।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৫০. নাতিশীতােয় তৃণভূমি অঞ্চলে কী জাতীয় মৃত্তিকা দেখা যায়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> চারনােজেম (মলিসল)। </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>৫১. তৈগা অরণ্যে কী জাতীয় মৃত্তিকা দেখা যায় ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> পডসল মৃত্তিকা।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৫২. মৃত্তিকার কোন্ স্তরকে উদ্ভিদের পুষ্টি ভাণ্ডার বলা হয়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> B স্তরকে।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৩. মলিস কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                শুষ্ক অবস্থাতেও যে মাটি নরম অবস্থায় থাকে, তাকে বলে মলিস।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৪. ল্যাটেরাইট মৃত্তিকার একটি বৈশিষ্ট্য লেখাে।</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ল্যাটেরাইট মৃত্তিকায় লােহার পরিমাণ খুব বেশি থাকে বলে মাটির রং
                লাল হয়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৫. ব্যাসল্ট শিলা থেকে কোন্ জাতীয় মৃত্তিকার সৃষ্টি হয়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> কৃষ্ণ মৃত্তিকা।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৫৬. ব্যাসল্ট শিলা থেকে কৃ মৃত্তিকা ছাড়া অন্য কোন্ মাটি গঠিত
                  হয়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> লাল মাটি, ল্যাটেরাইট মাটি।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৭. অর্সটেন কী ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                মৃত্তিকার B স্তরে হিউমাস ক্যালশিয়াম, ম্যাগনেশিয়াম কিংবা লােহা
                মিশ্রিত হয়ে কাকর রূপে সঞ্চিত হলে, তাকে বলা হয় অর্সটেন।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৮. কৃষ্ণ মৃত্তিকা সৃষ্টি হয় কোন্ শিলা থেকে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ব্যাসল্ট শিলা। </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৫৯. ভারতের কৃষ্ণ মৃত্তিকা USDA-এর শ্রেণিবিভাগে কী ধরনের মাটি?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ভার্টিসল। </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬০. মালসলের একটি উদাহরণ দাও।</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">চেস্টনাট মাটি/চারনােজোম মাটি।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬১. ‘রেনজিনা’ কী ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                চুনাপাথর বা মার্ল স্টোন থেকে উৎপন্ন মাটিকে বলে রেনজিনা।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬২. মাটির USDA শ্রেণিবিভাগের প্রবক্তা কে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> বলদুইন। </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬৩. পাইন বনভূমি অঞ্চলে কী জাতীয় মৃত্তিকা দেখা যায়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> পডসল মৃত্তিকা।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬৪. অক্সিসল মৃত্তিকার উদাহরণ দাও।</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> চারনােজেম।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬৫. হিস্টোসল বর্গের একটি মৃত্তিকার উদাহরণ দাও।</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> বগ মৃত্তিকা।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬৬. কৃষ্ণ মৃত্তিকা কোন্ বর্গের মৃত্তিকা?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ভার্টিসল।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬৭. অ্যান্টিসল বর্গের অন্তর্গত দুটি মৃত্তিকার উদাহরণ দাও।</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> অ্যালুভিয়াল ও রেগােসল।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৬৮. ভারতের পশ্চিমাংশের কৃষ্ণ মৃত্তিকায় কীসের পরিমাণ বেশি
                  থাকে?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">CaCO৩, MgCO৩, Fe, O৩, AI,Og!</font>

              <font color="#f626d7">
                <b>৬৯. ভারতের কোথায় লাল মাটি দেখতে পাওয়া যায়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> অন্ধ্রপ্রদেশে।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৭০. কীসের অভাবে উদ্ভিদের ক্লোরােসিস রােগ হয়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ম্যাগনেশিয়াম।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৭১. উদ্ভিদ নাইট্রোজেনকে কী হিসেবে মৃত্তিকা থেকে সংগ্রহ করে?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                উদ্ভিদ নাইট্রোজেনকে অ্যামােনিয়ামে পরিণত করে মাটি থেকে সংগ্রহ
                করে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৭২. রেগোলিথ কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভূত্বকের উপরিভাগে আবহবিকারগ্রস্ত শিথিল ও অসংবদ্ধ স্তরকে রেগোলিথ
                বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>১. মাটি কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                শিলাচূর্ণ বা ব্লেগোলিখ ও জৈব পদার্থ মিশে দীর্ঘ সময়ে বিভিন্ন
                রাসায়নিক বিক্রিয়ার মাধ্যমে ভূপৃষ্ঠের উপর এমন এক শিথিল পদার্থ
                গঠিত হয় যেখানে উদ্ভিদ প্রয়োজনীয় পুষ্টি সংগ্রহ করে বেঁচে থাকে,
                তাকেই বলে মৃত্তিকা।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>২. পেডোলজি কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                মৃত্তিকা বিজ্ঞানের যে অংশে মৃত্তিকার উৎপত্তি, উপাদান, স্তরায়ন,
                ধর্ম ও শ্রেণীবিভাগ প্রকৃতির আলোচনা করা হয় তাকে সেডোলজি বলে।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৩. ইডাফোলজি কাকে বলে?</b>
              </font>
              <br /> <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                মৃত্তিকা বিজ্ঞানের যে অংশে মৃত্তিকাকে উদ্ভিদের জন্ম, বুদ্ধি ও
                সংরক্ষণের মাধ্যম হিসেবে গণ্য করা হয় তাকে ইডাফোলজি বলে।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৪. রেগোলিথ কাকে বলে?</b>
              </font>
              <br /> <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                আবহবিকারের ফলে সৃষ্ট শিলাচূর্ণ বিরাজিত শিলার একটি শিথিল স্তরকে
                রেগোলিথ বলা হয়।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৫. মৃত্তিকার জৈব পদার্থ বলতে কী বোঝো?</b>
              </font>
              <br /> <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                উদ্ভিদ ও প্রাণীর মৃতদেহ ও বর্জ্য পদার্থের বিশ্লিষ্ট কঠিন ও জটিল
                পদার্থকে মাটির জৈব পদার্থ বলা হয়, যার পরিমাণ হল ৫ শতাংশ।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

মৃত্তিকা.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(মৃত্তিকা);
