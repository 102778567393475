import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function বচনেরবিরোধিতা() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          বচনের বিরোধিতা | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="বচনের বিরোধিতা | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                বচনের বিরোধিতা
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>A বচনের অতিবিষম বচন কোনটি?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>O বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>I বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>E বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এর কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>A বচন মিথ্যা হলে I বচনের সত্যমূল্য হবে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সত্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিথ্যা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অনিশ্চিত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এর কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  বিপরীত বিরোধিতার ক্ষেত্রে একটি বচন মিথ্যা হলে তার অনুরূপ বচনটি
                  হবে —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সত্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিথ্যা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অনিশ্চিত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এর কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বিরোধানুমান হল একপ্রকার -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অমাধ্যম অনুমান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মাধ্যম অনুমান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আরোহ অনুমান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এর কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>I বচন সত্য হলে E বচনের সত্যমূল্য হবে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সত্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মিথ্যা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনিশ্চিত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এর কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>দুটি অধীন-বিপরীত বিরোধী বচন কী একসঙ্গে সত্য হতে পারে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পারে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পারে না
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্ববিরোধী হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এর কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  বিপরীত বিরোধিতার ক্ষেত্রে একটি বচন মিথ্যা হলে তার অনুরূপ বচনটি
                  হবে —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সত্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিথ্যা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অনিশ্চিত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এর কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>যদি A বচন সত্য হয় তাহলে O বচনের সত্যমূল্য হবে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সত্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মিথ্যা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনিশ্চিত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এর কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বচনের বিরোধানুমান হলো একপ্রকার –</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মাধ্যম অনুমান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ন্যায় অনুমান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অমাধ্যম অনুমান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যৌগিক যুক্তি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>E বচন সত্য A বচন হবে – </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সত্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মিথ্যা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনিশ্চিত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এর কোনোটিই নয়
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>তৃতীয় অধ্যায় - বচনের বিরোধিতা</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 1. বচনের বিরোধিতা কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                একই উদ্দেশ্য ও একই বিধেয় পদযুক্ত দুটি বচনের মধ্যে যখন গুণ বা
                পরিমাণ বা গুণ ও পরিমাণ উভয় দিক থেকে পার্থক্য লক্ষ্য করা যায়,
                তখন সেইরূপ বচনের পারস্পরিক সম্পর্ককে বলা হয় বচনের বিরোধিতা।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 2. বচনের বিরোধিতা কয় প্রকার ও কী কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                বচনের বিরোধিতা চারপ্রকার - (i) বিপরীত বিরোধিতা, (ii) অধীন-বিপরীত
                বিরোধিতা, (iii) অসম বিরোধিতা, (iv) বিরুদ্ধ বিরোধিতা।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 3. বিপরীত বিরোধিতা কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>বিপরীত বিরোধিতা ➺ </b>
              </font>
              <font color="#3c92e5">
                একই উদ্দেশ্য এবং একই বিধেয়পদ বিশিষ্ট দুটি সামান্য বচনের মধ্যে
                যদি শুধুমাত্র গুণগত পার্থক্য থাকে তাহলে বচন দুটির পারস্পরিক
                সম্পর্ককে বিপরীত বিরোধিতা বলে।
                <br />
                <br />
                <font color="#1f7405">
                  <b> উদাহরণ ➺ </b>
                </font>
                <br /> সকল দার্শনিক হয় জ্ঞানী (A)
                <br />
                কোনো দার্শনিক নয় 'জ্ঞানী (E)
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 4. বিরুদ্ধ বিরোধিতা কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>বিরুদ্ধ বিরোধিতা ➺ </b>
              </font>
              <font color="#3c92e5">
                একই উদ্দেশ্য ও একই বিধেয়পদ বিশিষ্ট দুটি নিরপেক্ষ বচন যখন গুণ ও
                পরিমাণ উভয় দিক থেকে পৃথক হয়, তখন তাদের পারস্পরিক সম্পর্ককে
                বিরুদ্ধ বিরোধিতা বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 5. বিরোধানুমান কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>বিরোধানুমান ➺ </b>
              </font>
              <font color="#3c92e5">
                "যে অমাধ্যম দ্বারা বিরোধিতার সম্পর্কের ভিত্তিতে কোনো একটি বচনের
                সত্যতা বা মিথ্যাত্ব থেকে তার বিরোধী অপর বচনের সত্যতা বা
                মিথ্যাত্ব সম্পর্কে অনুমান করা হয়, তাকে 'বিরোধানুমান' বলা হয়।"
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. বচনের বিরোধানুমান কয় প্রকার ও কী কী?</b>
                </font>
                <br />
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                বচনের বিরোধানুমান চারপ্রকার। যথা -<br /> (i) বিপরীত বিরোধানুমান,{" "}
                <br />
                (ii) অধীন-বিপরীত বিরোধানুমান, <br />
                (iii) অসম বিরোধানুমান, <br />
                (iv) বিরুদ্ধ বিরোধানুমান।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. বিরোধী বচন কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> বিরোধী বচন ➺ </b>
              </font>
              <font color="#3c92e5">
                বিরোধিতার সম্বন্ধে আবদ্ধ দুটি বচনের একটিকে অন্যটির বিরোধী বচন
                বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>8. অসম বিরোধিতা কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> অসম বিরোধিতা ➺ </b>
              </font>
              <font color="#3c92e5">
                একই উদ্দেশ্য এবং একই বিধেয়পদ বিশিষ্ট দুটি বচনের মধ্যে যদি
                কেবলমাত্র পরিমাণগত প্রভেদ থাকেতাহলে বচন দুটির পারস্পরিক
                সম্পর্ককে অসম বিরোধিতা বলে।
                <br />
                <br />
                <font color="#1f7405">
                  <b> উদাহরণ ➺ </b>
                </font>
                <br /> সকল লেখক হয় ভাবুক। (A)
                <br />
                কোনো কোনো লেখক নয় ভাবুক। (I)
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  9. দুটি বিপরীত বিরোধী বচনের সত্যমূল্য সম্পর্কে কী অনুমান করা
                  যায়?
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর ➺ </b>
              </font>
              <font color="#3c92e5">
                A এবং E বচনের মধ্যে বিপরীত বিরোধিতার সম্পর্ক বর্তমান। এদের একটি
                বচনের সত্যমূল্য সত্য হলে অপর বচনের সত্যমূল্য মিথ্যা হয়। কিন্তু
                যদি একটি বচনের সত্যমূল্য মিথ্যা হয় তখন অপর বচনের সত্যমূল্য
                অনিশ্চিত হবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>10. বিপরীত এবং অধীন বিপরীত বিরোধিতার মধ্যে পার্থক্য কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর ➺ </b>
              </font>
              <font color="#3c92e5">
                একই উদ্দেশ্য ও একই বিধেয়পদ বিশিষ্ট দুটি সামান্য বচনের মধ্যে
                বিপরীত বিরোধিতা হয়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

বচনেরবিরোধিতা.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(বচনেরবিরোধিতা);
