import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function শিক্ষারবিশ্বব্যাপীদৃষ্টিভঙ্গি() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          শিক্ষার বিশ্বব্যাপী দৃষ্টিভঙ্গি | West Bengal Class 12 Guide | West
          Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="শিক্ষার বিশ্বব্যাপী দৃষ্টিভঙ্গি | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                শিক্ষার বিশ্বব্যাপী দৃষ্টিভঙ্গি
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ডেলর কমিশন প্রস্তাবিত বিশ্বশিক্ষার স্তম্ভ সংখ্যা -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দুই
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  চার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ছয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পাঁচ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  2000 খ্রিস্টাব্দে ডাকারে অনুষ্ঠিত আন্তর্জাতিক সম্মেলনের বিষয়
                  ছিল -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রথাগত শিক্ষা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সকলকে জন্য শিক্ষা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অন্তর্ভুক্তি শিক্ষা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পরিবেশ শিক্ষা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>প্রাচীনকালে শিক্ষার মূল উদ্দেশ্য ছিল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আত্মোপলব্ধি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বৃত্তিশিক্ষা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জ্ঞানলাভ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আত্মীকরণ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>জ্যাক ডেলর কোন দেশের অধিবাসী ছিলেন?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ফ্রান্সের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মেক্সিকোর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আমেরিকার
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ডেলর্স কমিশনের সদস্য ছিলেন -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  14 জন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  18 জন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  20 জন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  22 জন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  কোন ভারতীয় শিক্ষাবিদের বক্তব্যে শিক্ষার চারটি স্তম্ভের প্রভাব
                  দেখা যায়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রবীন্দ্রনাথ ঠাকুর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  স্বামী বিবেকানন্দ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিদ্যাসাগর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মহাত্মা গান্ধি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কর্মের জন্য শিক্ষা বলতে বোঝায় – </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যৌথবদ্ধভাবে কাজ করা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নির্দিষ্ট কাজে দক্ষতা অর্জন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিভিন্ন পরিস্থিতিতে উৎকর্ষের সঙ্গে কাজ করা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  উপরের সবক’টি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  2000 সালে ডাকারে কোন বিষয়ে আন্তর্জাতিক সম্মেলন অনুষ্ঠিত হয়?{" "}
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রারম্ভিক শিক্ষা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অন্তর্ভুক্তি শিক্ষা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পরিবেশ শিক্ষা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সকলের জন্য শিক্ষা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  1971 খ্রিস্টাব্দে UNESCO-র দ্বারা গঠিত প্রথম আন্তর্জাতিক
                  শিক্ষা কমিশনের সভাপতি ছিলেন -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডিউই
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  এডগার ফার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডেলর্স
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রুশো
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নীচের কোনটি জ্যাক ডেলর্স-এর দেওয়া শিখনের স্তম্ভ নয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সকলকে সাহায্য করার শিক্ষা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কর্মের জন্য শিক্ষা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জ্ঞান অর্জনের জন্য শিক্ষা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  একসঙ্গে বসবাস করার শিক্ষা
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="3">
                <b>একাদশ অধ্যায় - শিক্ষার বিশ্বব্যাপী দৃষ্টিভঙ্গি</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. কোন দেশে ভাষা পরীক্ষা প্রথম শুরু হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                আমেরিকায় ভাষা পরীক্ষা প্রথম শুরু হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. TAL-এর পুরো কথাটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                TAL-এর পুরো কথাটি হল- Technology Aided Learning.
                <br />
                <br />
                <font color="#f626d7">
                  <b>3.PBL এর পুরো কথাটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                PBL-এর পুরো নাম হল- Project Based Learning.
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. 'ট্রেজার উইদিন' কথাটির অর্থ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                টেজার উইদিন' কথাটির অর্থ হল—'অন্তর্নিহিত সম্পদ'।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. শিক্ষার চারটি মূল স্তম্ভের প্রবক্তার নাম কী? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                শিক্ষার চারটি মূল স্তম্ভের প্রবক্তার নাম হল - জ্যাক ডেলস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. ডেলর্স কমিশন কতসালে রিপোর্ট পেশ করে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ডেলর্স কমিশন 1996 সালে রিপোর্ট পেশ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. UNESCO-র জন্ম কবে হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                1945 খ্রিস্টাব্দের 16 নভেম্বর UNESCO-র জন্ম হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. ডাকার সম্মেলনের বিষয় কী ছিল?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ডাকার সম্মেলনের বিষয় ছিল - Education for all.
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. UESCO-এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                UNESCO-এর পুরো নাম-United Nations Educational Scientific and
                Cultural Organisation.
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. জ্ঞানার্জনের জন্য শিক্ষার একটি উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                জ্ঞানার্জনের জন্য শিক্ষার একটি উদাহরণ হল—কোনো ব্যক্তিকে
                স্বাধীনভাবে চিন্তা করতে শেখানো।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  11. 1971 খ্রিস্টাব্দের প্রথম আন্তর্জাতিক শিক্ষা কমিশনের সভাপতি
                  কে ছিলেন?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                1971 খ্রিস্টাব্দের প্রথম আন্তর্জাতিক শিক্ষা কমিশনের সভাপতি ছিলেন
                ফ্রান্সের প্রধানমন্ত্রী ও শিক্ষামন্ত্রী Edgar Faure.
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    12. প্রথম আন্তর্জাতিক শিক্ষা কমিশনের রিপোর্টটির শিরোনাম কী
                    ছিল?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                প্রথম আন্তর্জাতিক শিক্ষা কমিশনের রিপোর্টটির শিরোনাম ছিল
                'Learning to be'.
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    13. ডেলর্স কমিশনের কর্তৃক প্রকাশিত প্রতিবেদনটির শিরোনাম কী
                    ছিল?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ডেলর্স কমিশন কর্তৃক প্রকাশিত প্রতিবেদনটির শিরোনাম ছিল - Learning
                : The Treasure within".
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    14. "Learning : The Treasure within" রির্পোটের মূল বক্তব্য
                    কী?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ডেলর্স রিপোর্টের মূল বক্তব্য হল-শিখন হল অন্তর্নিহিত সম্পদ। এখানে
                শিক্ষার চারটি স্তম্ভই হল শিক্ষার মূল উদ্দেশ্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. UNESCO-এর দুটি কর্মসূচি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                UESCO-এর দুটি কর্মসূচি হল – <br />
                (i) আদর্শমান নির্ধারণ করা, <br />
                (ii) শিক্ষাক্ষেত্রে আন্তর্জাতিক সহযোগিতার ব্যবস্থা করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. UNICEF-এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                UNICEF-এর পুরো নাম – United Nations International Children's
                Emergency Fund.
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. শিক্ষার চারটি স্তম্ভ কী ছিল?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                এর শিক্ষার চারটি স্তম্ভ হল- (i) Learning to know <br />
                (ii) Learning to do
                <br /> (iii) Learning to live together <br />
                (iv) Learing to be.
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. একত্রে বসবাসের শিক্ষা কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                শিক্ষার্থীরা বোঝাপড়ার মধ্য দিয়ে, সহমতের ভিত্তিতে এবং সহাবস্থান
                করতে শেখানোর মধ্য দিয়ে শিক্ষা পায় তাকে একত্রে বসবাসের শিক্ষা
                বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    19. স্মৃতিশক্তির বিকাশসাধন শিক্ষার কোনস্তরের সঙ্গে
                    সম্পর্কযুক্ত?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                স্মৃতিশক্তির বিকাশসাধন ডেলর্স কমিশনের প্রথম স্তম্ভ—'Learing to
                know বা “জ্ঞানার্জনের জন্য শিক্ষার' সঙ্গে সম্পর্কযুক্ত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. ডাকার সম্মেলনের মূল লক্ষ্য কী ছিল?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ডাকার সম্মেলনের মূল লক্ষ্য ছিল—সকলের জন্য শিক্ষাব্যবস্থা
                সুনিশ্চিত করা এবং শিক্ষার মান উন্নয়ন করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>21. মানুষ হয়ে ওঠার শিক্ষা বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ব্যক্তির মধ্যে মানবিক মূল্যবোধ অর্জনের উপযোগী শিক্ষাকে মানুষ
                হয়ে ওঠার শিক্ষা বলে। এই শিক্ষার মধ্য দিয়ে ব্যক্তিকে একজন
                পরিপূর্ণমানুষ হিসেবে গড়ে তোলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. জ্ঞানার্জনের শিক্ষার দুটি ত্রুটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                জ্ঞান অর্জন শিক্ষার ত্রুটি -<br />
                (i) এই জ্ঞান অর্জন প্রক্রিয়া অতিরিক্ত শিক্ষক নির্ভর,
                <br />
                (ii) অনেকক্ষেত্রে শিক্ষার্থীর বিকাশ সঠিকভাবে ঘটে না।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. 'কর্মের জন্য শিক্ষা'র উদ্দেশাগুলি পুরণে বিদ্যালয়ের ভূমিকা
                  সংক্ষেপে আলোচনা করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ডেলর কমিশন বিংশ শতকের নতুন প্রজন্মকে কেন্দ্র করে শিক্ষার যে
                চারটি স্তম্ভের কথা বলেন তার দ্বিতীয় স্তম্ভ হল Learning to do বা
                কর্মের জন্য শিক্ষা। দক্ষতার সঙ্গে বুদ্ধিকে কাজে লাগিয়ে কোনো
                কার্য সম্পাদন করাই এর লক্ষ্য। এই লক্ষ্য পূরণের জন্য বিদ্যালয়ের
                ভূমিকা নিম্নরূপ -<br />
                <br />
                1. শিক্ষার্থীদের মধ্যে কর্মের প্রতি উৎসাহ, শ্রমের প্রতি
                মর্যাদাবোধ তৈরিতে বিদ্যালয় একটি গুরুত্বপূর্ণ ভূমিকা পালন করে
                থাকে।
                <br />
                <br />
                2. শিক্ষায় গণতান্ত্রিকতাকে প্রাধান্য দিয়ে এমন পরিবেশ বিদ্যালয়
                তৈরি করবে সেখানে সকলেই শিক্ষার অধিকার লাভ করবে।
                <br />
                <br />
                3. বিদ্যালয় সৃজনাত্মক কর্মের জন্য সবরকম ব্যবস্থা করবে যাতে সকল
                শিক্ষার্থী অংশ গ্রহণ করতে পারে।
                <br />
                <br />
                4. কর্মসম্পাদনের মধ্য দিয়ে শিক্ষার্থীদের মধ্যে প্রাক্ষোভিক
                বিকাশ ঘটে। বিদ্যালয় বিভিন্ন কর্ম সম্পাদনের মধ্যে দিয়ে
                শিক্ষার্থীরা পারস্পরিক বন্ধনে জড়িয়ে পড়ে।
                <br />
                <br />
                5. হাতের কাজ, মাটির কাজ, কাঠের কাজ, সুতোর কাজ, বাড়ির বাতিল
                অংশের সাহায্য নতুন নতুন জিনিস তৈরির কাজ ইত্যাদি তৈরিতে বিদ্যালয়
                সর্বদা শিক্ষার্থীকে উৎসাহ প্রদান করবে।
                <br />
                <br />
                <br />
                6. উৎপাদনশীলতাই শিক্ষার্থীকে কর্মদক্ষ, কর্মনিপুণ করে তোলে। ফলে
                শিক্ষার্থী মানসিক তৃপ্তি, সামাজিক পরিচয় ও আনন্দ লাভ করতে পারে।
                তাই বিদ্যালয়কে উৎপাদনশীলতার ওপর গুরুত্ব আরোপ করতে হবে।
                <br />
                <br />
                সুতরাং শিক্ষার অন্যতম উদ্দেশ্য হবে কর্মের মাধ্যমে নতুন জ্ঞান ও
                অভিজ্ঞতা অর্জন করা।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  2. জ্ঞান অর্জনের শিক্ষা বলতে কী বোঝ? এই শিক্ষাকে বাস্তবায়িত
                  করতে বিদ্যালয় কী কী ভূমিকা গ্রহণ করতে পারে। <br />
                  অথবা,
                  <br /> জ্ঞানঅর্জনের শিক্ষা বাস্তবায়িত করতে বিদ্যালয়ের ভূমিকা
                  সংক্ষেপে আলোচনা করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b>জানার জন্য শিক্ষার তাৎপর্য : </b>{" "}
                </font>{" "}
                'জ্ঞান অর্জন' শিক্ষার অন্যতম প্রধান বৈশিষ্ট্য। শিক্ষার উদ্দেশ্য
                হিসেবে জ্ঞানার্জনের তাৎপর্য নীচে আলোচনা করা হল -<br />
                <br />
                <font color="#f626d7">
                  <b>(i) জ্ঞানার্জন ও শিক্ষাবিদদেরবক্তব্য : </b>{" "}
                </font>{" "}
                সব দেশের মহান শিক্ষাবিদরা সর্বকালের শিক্ষাদর্শন আলোচনা করতে
                গিয়ে জ্ঞানার্জনের কথা বলেছেন।
                <br />
                <br />
                প্রাচীনকালের শিক্ষার লক্ষ্য ছিল আত্মোপলব্ধি আর এই আত্মোপলব্ধির
                জন্য প্রয়োজন হল জ্ঞান| স্বামী বিবেকানন্দ তাঁর শিক্ষা চিন্তায়
                ব্যক্তির সম্ভাবনার ওপর অগাধ আস্থার কথা ব্যক্ত করেছেন। তিনি
                বলেছেন, ব্যক্তির মধ্যেই জ্ঞান বিদ্যমান। শিক্ষার কাজ হল সেই
                জ্ঞানের প্রকাশ ঘটানো
                <br />
                <br />
                <font color="#f626d7">
                  <b>(ii) জ্ঞানার্জন ও শিক্ষার অন্যান্য উদ্দেশ্য : </b>{" "}
                </font>{" "}
                প্রকৃতপক্ষে শিক্ষার অন্যান্য উদ্দেশ্যপূরণে (যেমন – কর্মের জন্য
                শিক্ষা, একত্রে বেঁচে থাকার শিক্ষা, মানুষ হওয়ার শিক্ষা) আবশ্যিক
                উপাদান হল জ্ঞান অর্জন বা জ্ঞানলাভ। এর মধ্যে দিয়েই ব্যক্তি
                কর্মের বিভিন্ন দিক সম্পর্কে সচেতন হয়, কর্মে প্রবৃত্ত হয় এবং
                কর্মের উন্নতি সাধনে সক্ষম হয়। জ্ঞান অর্জনের মাধ্যমেই ব্যক্তি
                একত্রে বেঁচে থাকার সুবিধা, সার্থকতা ও প্রয়োজনীয়তা সম্পর্কে
                সচেতন হয় ও তার জন্য সক্রিয় হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(iii) জ্ঞানার্জন ও অভিযোজন : </b>{" "}
                </font>{" "}
                শিক্ষা হল অভিযোজন৷ জৈবিক, প্রাকৃতিক, সামাজিক, বৌদ্ধিক প্রভৃতি
                বিভিন্ন পরিবেশের সঙ্গে অভিযোজনের জন্য প্রয়োজন পরিবেশ সম্পর্কিত
                সঠিক জ্ঞান অর্জন। পরিবেশের উপাদানগুলি সম্পর্কে সঠিক তথ্য না
                জানলে সার্থক অভিযোজন সম্ভব নয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(iv)জ্ঞানার্জন ও পরিবর্তনশীল বিশ্ব : </b>{" "}
                </font>{" "}
                প্রযুক্তির দ্রুত পরিবর্তন এবং নতুন। ধরনের অর্থনৈতিক ও সামাজিক
                গঠনের সঙ্গে তাল মিলিয়ে চলার জন্য, মানবজাতির কাছে জ্ঞানার্জনের
                শিক্ষাগত তাৎপর্য অনেকটাই বৃদ্ধি পেয়েছে। জ্ঞানার্জনের মাধ্যমে
                আমরা যদি নতুন বিশ্বের সঙ্গে পরিচিত না হই তাহলে আমরা পিছিয়ে
                পড়ব৷
                <br />
                <br />
                <font color="#f626d7">
                  <b>(v) জ্ঞানার্জন ও মানসিক বৈশিষ্ট্য : </b>{" "}
                </font>{" "}
                বিভিন্ন সামাজিক জ্ঞানার্জনের ফলে ব্যক্তির মানসিক প্রক্রিয়াগুলি,
                যেমন— স্মৃতি, যুক্তি নির্ণয়, বিচারবোধ, সংবেদন, প্রত্যক্ষণ,
                বুদ্ধি ইত্যাদির বিকাশ ও অনুশীলন হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(vi) জ্ঞান আহরণ ও দৃষ্টিভঙ্গি : </b>{" "}
                </font>{" "}
                ব্যক্তির দৃষ্টিভঙ্গির পরিবর্তন জ্ঞানার্জনের ফলেই সম্ভব হয়।
                জ্ঞানের অভাবে মানুষ ধর্মান্ধ, কুসংস্কারাচ্ছন্ন ও অন্ধবিশ্বাসে
                আক্রান্ত হয়। জ্ঞানই মানুষের সঠিক ও স্বচ্ছ দৃষ্টিভঙ্গি গড়ে
                তোলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(vii) জ্ঞান ও আত্মপ্রত্যয় : </b>{" "}
                </font>{" "}
                জ্ঞান ব্যক্তির আত্মপ্রত্যয় বৃদ্ধি করে আত্মবিশ্বাসী করে তোলে।
                আত্মবিশ্বাসী ব্যক্তি যে-কোনো পরিস্থিতির সম্মুখীন হতে দ্বিধাবোধ
                করে না। যে-কোনো অবস্থায় মতামত ব্যক্ত করতে এবং সিদ্ধান্ত নিতে
                পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(viii) জ্ঞানের প্রসার : </b>{" "}
                </font>{" "}
                জ্ঞান ব্যক্তিকে আরও জ্ঞান অর্জনের প্রেরণা দেয়, জ্ঞানের গভীরে
                প্রবেশ করতে উৎসাহ জোগায়।
                <br />
                <br />
                ওপরের আলোচনা থেকে এই সিদ্ধান্তে আসা যায় যে, জ্ঞান শিক্ষার
                অন্যান্য উদ্দেশ্য পূরণ করতে সাহায্য করে। বিভিন্ন পরিবেশে সার্থক
                অভিযোজনের ক্ষেত্রে জ্ঞান অত্যন্ত গুরুত্বপূর্ণ। সামাজিক
                শক্তিগুলির অনুশীলনের মাধ্যমে জ্ঞান তাদের বিকাশ ঘটায় এবং
                পরিবর্তনশীল বিশ্ব সম্পর্কে আমাদের অবহিত হতে সাহায্য করে। জ্ঞান
                এই পরিবর্তনের ইতিবাচক দিকগুলিকে যেমন ব্যবহার করে, তেমনই এর
                ক্ষতিকর দিকগুলি সম্পর্কে ব্যক্তিকে সচেতন করে তার মোকাবিলায়
                প্রস্তুত করে। <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b> জ্ঞানার্জনের শিক্ষা এবং বিদ্যালয়ের কর্মসূচি : </b>{" "}
                </font>{" "}
                জ্ঞানার্জনের শিক্ষার জন্য প্রয়োজনীয় বিদ্যালয়ের কর্মসূচি গুলি
                সম্পর্কে নীচে আলোচনা করা হল –<br />
                <br />
                <font color="#f626d7">
                  <b>(i) সাধারণ শিক্ষা ও সীমিত বিষয়ে জ্ঞানের সমন্বয়সাধন : </b>{" "}
                </font>{" "}
                সাধারণ শিক্ষা ব্যক্তিকে জীবনব্যাপী অভিযোজনে সাহায্য করবে,
                দৈনন্দিন সাধারণ সমস্যা সমাধানে সক্ষম করে তুলবে এবং জ্ঞানের
                যেকোনো শাখায় বিচরণ করতে সাহায্য করবে। অন্যদিকে, সীমিত বিষয়ে
                বিশেষ জ্ঞান শিক্ষার্থীকে বিষয় সম্পর্কিত গভীর অনুসন্ধানে
                উদ্বুদ্ধ করবে এবং জ্ঞানের প্রসার ঘটাবে। এ ছাড়া বিভিন্ন বিষয়ের
                মধ্যে যে পারস্পরিক সম্পর্ক আছে শিক্ষার্থীকে তা অনুসন্ধানে
                সাহায্য করবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(ii) জ্ঞানের মৌলিক উপাদানগুলির অনুশীলন : </b>{" "}
                </font>{" "}
                জ্ঞানের মৌলিক উপাদান গুলি, যেমন – স্মৃতি, মনঃসংযোগ এবং ক্ষমতার
                অনুশীলনের ব্যবস্থা করতে হবে। এতে জীবনব্যাপী জ্ঞানার্জনের শিক্ষার
                জন্য প্রয়োজনীয় এই মানসিক প্রক্রিয়াগুলি সাবলীলতা ও উৎকর্ষের
                সঙ্গে কাজ করবে। স্মৃতির কৌশলগুলি সম্পর্কে শিক্ষার্থীদের অবহিত
                করতে হবে। মনঃসংযোগের ক্ষমতা বৃদ্ধির জন্য ধ্যান, যোগ ইত্যাদিকে
                বিদ্যালয়ের কর্মসূচির মধ্যে অন্তর্ভুক্ত করতে হবে। চিন্তন ক্ষমতা
                অনুশীলনের জন্য সমস্যামূলক পদ্ধতি, আবিষ্কার মূলক পদ্ধতি এবং আরোহ
                ও অবরোহ পদ্ধতি ব্যবহারের ওপর গুরুত্ব দিতে হবে। শিক্ষার্থীরা যাতে
                সমস্যা সমাধানে সচেষ্ট হয়, তাদের সেদিকে উৎসাহিত করতে হবে। এই
                সমস্যা সমাধানে তারা যাতে আরোহী ও অবরোহী চিন্তনের আশ্রয় নেয়,
                সেদিকে লক্ষ রাখতে হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(iii) কৌতূহলবৃদ্ধি : </b>{" "}
                </font>{" "}
                শিক্ষার্থীদের কৌতূহলকে জ্ঞানার্জনের সঙ্গে যুক্ত করতে হবে, যাতে
                জ্ঞানার্জনের ইচ্ছা ক্রমশ বৃদ্ধি পায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  3. একসঙ্গে বাঁচার শিক্ষা' — শিক্ষার এই উদ্দেশ্য বাস্তবায়িত
                  করতে বিদ্যালয়ের ভূমিকা লেখো।{" "}
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                1993 খ্রিঃ ডেলস কমিশন যে প্রতিবেদন প্রকাশ করেছেন তার তৃতীয়
                স্তম্ভ অর্থাৎ জীবনব্যাপী শিক্ষার উদ্দেশ্যর তৃতীয়টি ছিল ‘একসঙ্গে
                বাঁচার শিক্ষা বা Learning to live Together. এই উদ্দেশ্য পূরণের
                জন্য বিদ্যালয়ের ভূমিকা নিম্নরূপ। <br />
                <br />
                <font color="#f626d7">
                  <b>1. পাঠ্যক্রম : </b>{" "}
                </font>{" "}
                পাঠক্রমে এমন সব বিষয় এর আধিক্য থাকবে যাতে তার মধ্যে দিয়েই
                শিক্ষার্থীর মধ্যে পারস্পরিক সহযোগিতা, সহানুভূতি, নির্ভরশীলতার
                বোধ তৈরি হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. ধর্ম নিরপেক্ষতা : </b>{" "}
                </font>{" "}
                একই মানসিকতা সম্পন্ন নাগরিক তৈরি করার জন্য ধর্ম নিরপেক্ষ শিক্ষার
                ব্যবস্থা করতে হবে। ভারতবর্ষের সংবিধানে ধর্মনিরপেক্ষতাকে স্বীকার
                করা হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. সহপাঠ্যক্রম : </b>{" "}
                </font>{" "}
                শিক্ষামূলক ভ্রমণ, দেশাত্মবোধক আলোচনা, সংগীত, বিভিন্ন জাতীয় দিবস
                পালন, মনীষীদের বাণীপাঠ, সাংস্কৃতিক প্রতিযোগিতা ইত্যাদির মাধ্যমে
                শিক্ষার্থীদের ভাবের আদান প্রদান হবে। ফলে তাদের মধ্যে এক সঙ্গে
                বসবাসের গুরুত্ব উপলব্ধি হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. জীবনাদর্শভিত্তিক মূল্যবোধ সৃষ্টি করা : </b>{" "}
                </font>{" "}
                একদেশের কৃষ্টি, সংস্কৃতি ও ঐতিহ্যের সঙ্গে অন্য দেশের বিস্তর
                প্রভেদ, শিক্ষার উদ্দেশ্য হবে চিন্তার ও ভাবের আদান-প্রদানের
                দ্বারা এই প্রভেদ দূর করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. জাতীয় ঐক্যবোধ গড়ে তোলা : </b>{" "}
                </font>{" "}
                গোটা দেশে একই ধরনের শিক্ষার ব্যবস্থা করতে হবে যাতে দেশের মধ্যে
                ঐক্যবোধ গড়ে ওঠে। আগে দেশের মধ্যে ঐক্যস্থাপন করতে হবে তার থেকেই
                আসবে বিশ্ব ঐক্যবোধ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. বিশ্ব শান্তি ও বিশ্ব ভ্রাতৃত্ববোধ জাগ্রত করা : </b>{" "}
                </font>{" "}
                শিক্ষার্থীরা যাতে শিক্ষার মাধ্যমে কৃষ্টি, ঐতিহ্য ও সংস্কৃতির
                মাধ্যমে বিশ্ব শান্তি ও ভ্রাতৃত্ববোধ গড়ে তুলতে পারে সেই রকম
                শিক্ষা তাদের দিতে হবে।
                <br />
                <br />
                এই সকল বিষয়গুলি যদি প্রতিটি দেশের শিক্ষার্থীদের মধ্যে শৈশবেই
                গড়ে তোলা যায় তবেই শিশুর মধ্যে সংকীর্ণতা, হিংসা, বিদ্বেষ তৈরি
                হবে না এবং এক সঙ্গে বাস করার গুরুত্ব উপলব্ধি হবে শিক্ষার্থীদের
                মধ্যে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

শিক্ষারবিশ্বব্যাপীদৃষ্টিভঙ্গি.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(শিক্ষারবিশ্বব্যাপীদৃষ্টিভঙ্গি);
