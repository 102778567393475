import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function জীববৈচিত্র্যওসংরক্ষণ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          জীববৈচিত্র্য ও সংরক্ষণ | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="জীববৈচিত্র্য ও সংরক্ষণ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                জীববৈচিত্র্য ও সংরক্ষণ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  কোন্ প্রাণী সংরক্ষণের জন্য WWD-N-এর লোগো চিহ্নটি ব্যবহৃত হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বড়ো পান্ডা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  লাল পান্ডা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মেরু-ভালুক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বাঘ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কোন্‌টি এক্স সিটু সংরক্ষণ তা নির্বাচন কর -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সংরক্ষিত বনাঞ্চল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  উদ্ভিদ উদ্যান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জাতীয় উদ্যান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অভয়ারণ্য
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>করবেট পার্ক বিখ্যাত কোন্ পশুর জন্য?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গন্ডার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সিংহ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বাঘ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হরিণ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নিম্নলিখিত কোন্‌টিকে এক্স সিটু সংরক্ষণ করা হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অভয়ারণ্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জাতীয় উদ্যান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জুওলজিক্যাল গার্ডেন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বায়োস্ফিয়ার রিজার্ভ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>জীববৈচিত্র্য শব্দের প্রবক্তা কে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হুক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্যান্ডোল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  র‍্যাডফার্ড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  রোজেন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কোন্ অঞ্চলটিতে মনুষ্য ক্রিয়াকলাপের অনুমতি আছে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Core zone
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Restroration zone
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Manipulation zone
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  buffer zone
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>জাতীয় বৃক্ষ কোন্‌টি?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Ficus benghalensis
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Mangifera indica
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Azadirachta indica
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  প্রাণী ও উদ্ভিদ সমূহ সর্বাপেক্ষা সুরক্ষিত থাকে নিম্নলিখিত কোন
                  স্থানে?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্যাংচুয়ারি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ন্যাশনাল পার্ক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বোটানিক্যাল গার্ডেন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চিড়িয়াখানা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  1986 খ্রিস্টাব্দে কোথায় ভারতের সর্বপ্রথম বায়োস্ফিয়ার
                  রিজার্ভ স্থাপিত হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নন্দাদেবীতে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সুন্দরবনে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  নীলগিরিতে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিনামার উপকূলে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নিম্নের কোন্‌টি ইন-সিটু সংরক্ষণের সঙ্গে সংশ্লিষ্ট নয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জাতীয় উদ্যান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  উদ্ভিদ উদ্যান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বায়োস্ফিয়ার রিজার্ভ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্যাংচুয়ারি
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>পঞ্চদশ অধ্যায় - জীববৈচিত্র্য ও সংরক্ষণ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. জীববৈচিত্র্য কী?</b>
              </font>
              <br />
              <font color="#2b9a09">
                <b>উত্তর ➺ </b>
              </font>
              <font color="#3c92e5">
                {" "}
                বিভিন্ন প্রকার বাস্তুতন্ত্রের জীবের মধ্যে যেসকল বিভিন্নতা দেখা
                যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. পৃথিবীতে স্থলজ হটম্পটের সংখ্যা কয়টি?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                25টি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. গণ্ডার ও সিংহের জন্য প্রসিদ্ধ জাতীয় উদ্যান কোন্‌টি?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                কাজিরাঙা জাতীয় উদ্যান ও গির জাতীয় উদ্যান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. বাস্তুতান্ত্রিক বৈচিত্র্য কী?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                একটি বিস্তীর্ণ অঞ্চলে বিভিন্ন বাস্তুতন্ত্রের প্রকারভেদকে
                বাস্তুতান্ত্রিক বৈচিত্র্য বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. এক্স-সিটু সংরক্ষণ কাকে বলে?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                উদ্ভিদ ও প্রাণীর নিজস্ব প্রাকৃতিক পরিবেশের বাইরে তাদের সংরক্ষণ
                করার পদ্ধতিকে এক্স-সিটু সংরক্ষণ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. ইন সিটু সংরক্ষণ কী?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                প্রাকৃতিক পরিবেশে জীববৈচিত্র্যের সংরক্ষণকে ইন-সিটু সংরক্ষণ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. দুটি অভয়ারণ্যের উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                পশ্চিমবঙ্গের বেথুয়াডহরি ও কর্ণাটকের বন্দিপুর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. দুটি জাতীয় উদ্যানের নাম বলো।</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                মধ্যপ্রদেশের কান্হা ও উত্তরপ্রদেশের করবেট।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. দুটি বায়োস্ফিয়ার রিজার্ভের নাম কী?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                রাজস্থানের পর মরুভূমি ও অরুণাচল প্রদেশের নামধাপা
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. দুটি বিপন্ন উদ্ভিদ প্রজাতির নাম লেখো।</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                Drosera indica (সূর্যশিশির) ও Aloe vera (ঘৃতকুমারী)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>11. ভারতের বিপন্ন প্রজাতির একটি উভচরের নাম লেখো।</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                হিমালয়ান স্যালামান্ডার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. জীববৈচিত্র্যের পর্যায়গুলি কী কী?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                জেনেটিক, প্রজাতিভুক্ত এবং সম্প্রদায়ভুক্ত জীববৈচিত্র্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. সর্বপ্রথম কে জীববৈচিত্র্য শব্দটির ব্যাখ্যা করেন?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                বিজ্ঞানী ওয়াল্টার রোসেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. বাস্তৃতান্ত্রিক বৈচিত্র্যের প্রকারগুলি কী কী?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                আলফা বৈচিত্র্য, বিটা বৈচিত্র্য, গামা বৈচিত্র্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    15. জীববৈচিত্র্যের ক্ষুদ্রতম একক কোন্ প্রকার বৈচিত্র্যকে
                    বলে?
                  </b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                আলফা বৈচিত্র্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. দুটি বিপন্ন প্রাণী প্রজাতির নাম লেখো।</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                Panthera tigris (ভারতীয় বাঘ) ও Chelonia (সামুদ্রিক কচ্ছপ)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. জাতীয় উদ্যান IUCN-এর কোন্ বিভাগের অন্তর্গত?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                জাতীয় উদ্যান IUCN-এর বিভাগ বা Category II-এর অন্তর্গত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    18. ক্রায়োপ্রিজারভেশনের জন্য কোন্ গ্যাস ব্যবহার করা হয়?
                  </b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                তরল নাইট্রোজেন ক্রায়োপ্রিজারভেশনে ব্যবহার করা হয় যার মাধ্যমে
                তাপমাত্রা –196°C পর্যন্ত নামানো যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. WWF-এর সম্পূর্ণ নাম কী?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>
                WWF-এর নাম প্রথমে ছিল 'World Wildlife Fund' যা বর্তমানে
                পরিবর্তিত হয়ে 'World Wide Fund for Nature' হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. WWF-এর সদর দফতর কোথায়?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                সুইজারল্যান্ডের গ্ল্যান্ড (Gland) শহরে WWF-এর সদর দফতর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>21. IUCN-এর সম্পূর্ণ নাম কী?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>
                International Union for Conservation of Nature and Natural
                Resources.
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    22. পৃথিবীর বৃহত্তম বোটানিকাল গার্ডেনের নাম ও অবস্থান লেখ।{" "}
                  </b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>
                Royal Botanic Gardens, Kew — এটি লন্ডনে অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    23. Royal Botanic Garden বা Kew Gardens-এ আনুমানিক কটি
                    প্রজাতির উদ্ভিদ সংরক্ষিত আছে?
                  </b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                আনুমানিক 27,000টি প্রজাতির উদ্ভিদ সংরক্ষিত আছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. BRP-এর সম্পূর্ণ নাম কী?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                Biosphere Research Programme.
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    25. পশ্চিমবঙ্গের একটি উল্লেখযোগ্য অ্যাকোয়ারিয়ামের নাম
                    লেখো।
                  </b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                Marine Aquarium and Research Centre- এটি দীঘায় অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. আরবোরেটাম কী?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                যে বিশেষ স্থানে পর্যবেক্ষণ ও বৈজ্ঞানিক গবেষণার জন্য বৃক্ষজাতীয়
                উদ্ভিদ সংরক্ষণ করা হয় তাকে আরবোরেটাম বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    27. ভারতের দুটি লুপ্তপ্রায় স্তন্যপায়ী প্রাণীর নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                এক শৃঙ্গ গন্ডার ও এশিয়ার সিংহ।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. 'গ্রিন ডাটা বুক' কী?</b>
              </font>
              <br />
              <font color="#2b9a09">
                <b>উত্তর ➺ </b>
              </font>
              <font color="#3c92e5">
                যে পুস্তকে বিরল শ্রেণিভুক্ত উদ্ভিদের নাম, গোত্র ও সংরক্ষণ পদ্ধতি
                সম্পর্কে আলোচনা করা হয় তাকে ‘গ্রিন ডাটা' বুক বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. রেড ডাটা বুক কী ?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                1963 খ্রিস্টাব্দে IUCN কর্তৃক প্রকাশিত যে তালিকাতে বিপন্ন
                জীবকুলের তথ্য বর্তমান তাকে বলে Red Data Book|
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. বায়ো-ব্যাঙ্ক কাকে বলে?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                যে স্থানে এক্স-সিটু পদ্ধতিতে পরাগরেণু বীজ, শুক্রাণু ডিম্বাণু এবং
                জিন যথাযথভাবে সংরক্ষণ করা হয় তাকে বায়ো ব্যাঙ্ক বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. কোর অঞ্চল কী?</b>
                </font>
                <br />
                <font color="#2b9a09">
                  <b>উত্তর ➺ </b>
                </font>{" "}
                বায়োস্ফিয়ার রিজার্ভের কেন্দ্রীয় অঞ্চল যেখানে মানুষ প্রবেশ
                নিষিদ্ধ ও জীবকুল নির্ভয়ে বসবাস করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. অভয়ারণ্য কী?</b>
              </font>
              <br />
              <br />
              <font color="#2b9a09">
                <b>অভয়ারণ্য ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                রাজ্য বন্যপ্রাণী বোর্ডের পরামর্শক্রমে রাজ্য সরকারের তত্ত্বাবধানে
                যে বনাঞ্চলে যে বিনা অনুমতিতে প্রবেশ, গবাদি পশুচারণ, বৃক্ষচ্ছেদন
                ইত্যাদি নিষিদ্ধ এবং যে অঞ্চলে জীবকুল নির্ভয়ে বসবাস করে ও
                বংশবিস্তার করে তাকে বলে অভয়ারণ্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>উদাহরণ : </b>
                </font>
                বেথুয়াডহরি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. বায়োস্ফিয়ার রিজার্ভ কী?</b>
              </font>
              <br />
              <br />
              <font color="#2b9a09">
                <b>বায়োস্ফিয়ার রিজার্ভ ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                {" "}
                UNESCO-র MAB কর্মসূচির অন্তর্গত যে স্থলজ ও জলজ অঞ্চলের সমগ্র
                বাস্তুতন্ত্র এবং তার সম্পূর্ণ জীববৈচিত্র্য সংরক্ষিত হয়, তাকে
                বায়োস্ফিয়ার রিজার্ভ বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. বাস্তৃতান্ত্রিক বৈচিত্র্যের সংজ্ঞা দাও।</b>
              </font>
              <br />
              <br />
              <font color="#2b9a09">
                <b>বাস্তৃতান্ত্রিক বৈচিত্র্য ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                একটি বিস্তীর্ণ অঞ্চলে বিভিন্ন বাস্তুতন্ত্রের প্রকারভেদকে
                বাস্তুতান্ত্রিক বৈচিত্র্য বলে। বিভিন্ন প্রকার জীবসত্ত্বা ও
                নানারকম অজৈব উপকরণ নিয়ে কোনো বাস্তুতন্ত্রের চরিত্র গঠিত হয়।
                জীব ব্যতিরেকে কোনো বাস্তুতন্ত্র হয় না বলে বাস্তুতন্ত্রের
                বিভিন্নতাও বিশেষ গুরুত্বপূর্ণ বিষয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>8. ক্রায়োপ্রিজারভেশন কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#2b9a09">
                <b>ক্রায়োপ্রিজারভেশন ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                গ্যামেট, ভ্রুণ, বীজ, কলা, বা বিভিন্ন অঙ্গ অতি নিম্ন তাপমাত্রায়
                (196°C) সংরক্ষিত করার প্রক্রিয়াকে ক্রায়োপ্রিজারভেশন বলা হয়।
                এক্ষেত্রে জীবের বিভিন্ন অঙ্গগুলি তরল নাইট্রোজেনে সংরক্ষিত করা
                হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>9. মেগাডাইভারসিটি দেশ বলতে কী বোঝ?</b>
              </font>
              <br />
              <br />
              <font color="#2b9a09">
                <b>মেগাডাইভারসিটি দেশ ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                নিরক্ষরেখার উভয় পার্শ্বেকর্কটক্রান্তি ও মকরক্রান্তি রেখার
                মধ্যবর্তী অঞ্চলটি উষ্ণ, আদ্র, বৃষ্টিপাতযুক্ত হওয়ায় এই অঞ্চলে
                উদ্ভিদ, প্রাণী, অণুজীবের প্রাচুর্যতা লক্ষ্য করা যায়। এই অঞ্চলে
                পৃথিবীর অর্ধেকেরও বেশি জীবকুল বসবাস করে। এই অঞ্চলের দেশগুলিকে
                মেগাডাইভারসিটি দেশ বলে। যেমন — ভারত, ব্রাজিল, জাইরে ইত্যাদি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>10. জীববৈচিত্র্য বা বায়োডাইভারসিটি কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#2b9a09">
                <b>জীববৈচিত্র্য বা বায়োডাইভারসিটি ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                {" "}
                যে-কোনো জলাভূমি বা স্থলভূমির বাস্তুতন্ত্রে বিভিন্ন জীবের মধ্যে
                প্রজাতিগত, আন্তঃপ্রজাতিগত বা বাস্তুতান্ত্রিক দিক থেকে যেসকল
                বিভিন্নতা প্রকাশ পায়, তাকে জীববৈচিত্র্য বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. জাতীয় উদ্যান কী? </b>
              </font>
              <br />
              <br />
              <font color="#2b9a09">
                <b>জাতীয় উদ্যান ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                {" "}
                কেন্দ্রীয় বন্যপ্রাণী বোর্ডের পরামর্শক্রমে কেন্দ্রীয় সরকারের
                তত্ত্বাবধানে যে বনাঞ্চলে বিনা অনুমতিতে প্রবেশ, গবাদি পশুচারণ,
                বৃক্ষচ্ছেদন ইত্যাদি নিষিদ্ধ এবং যে অঞ্চলে জীবকুল নির্ভয়ে বসবাস
                করে ও বংশবিস্তার করে তাকে বলে জাতীয় উদ্যান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>উদাহরণ :</b>
                </font>{" "}
                কানহা, গোরুমারা জাতীয় উদ্যান।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

জীববৈচিত্র্যওসংরক্ষণ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(জীববৈচিত্র্যওসংরক্ষণ);
