/*
 *
 * HomePage reducer
 *
 */

import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "HomePage",
  initialState: {
    popularChannels: [],
    // MCQS: [],
    // DESCRIPTIVE: [],
    // Images: [],
    // Topics: [],
    // VIDEOS: [],
    currentTopic: null,
    currentType: null,
  },
  reducers: {
    setPopularChannels: (state, { payload: { channels } }) => {
      if (typeof channels !== "undefined") {
        state.popularChannels = channels;
      }
    },
    setTopic: (state, { payload: { topic } }) => {
      if (typeof topic !== "undefined") {
        state.currentTopic = topic;
      }
    },
    setType: (state, { payload: { type } }) => {
      if (typeof type !== "undefined") {
        state.currentType = type;
      }
    },
  },
});

export const { setPopularChannels, setTopic, setType } = slice.actions;
export default slice.reducer;
