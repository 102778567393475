import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function বিদেশনীতিবাপররাষ্ট্রনীতি() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          বিদেশনীতি বা পররাষ্ট্রনীতি | West Bengal Class 12 Guide | West Bengal
          HS Suggestion
        </title>
        <meta
          name="description"
          content="বিদেশনীতি বা পররাষ্ট্রনীতি | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                বিদেশনীতি বা পররাষ্ট্রনীতি
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কত বছর ধরে ভারত, ব্রিটেনের উপনিবেশ ছিল?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রায় 150 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রায় 300 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রায় 250 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  প্রায় 200 বছর
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সার্কের প্রথম সভাপতি ছিলেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নেহরু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  এরশাদ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাজীব গান্ধি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জিয়ায়ুর রহমান
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ভারতে 1991 খ্রিস্টাব্দে যে প্রধানমন্ত্রীর সময় বাজার অর্থনীতির
                  সূচনা ঘটে —{" "}
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাজীব গান্ধি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  নরসিমহা রাও
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দেবগৌড়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মনমোহন সিং
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতের জোটনিরপেক্ষতা নীতির প্রধান রূপকার হলেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মতিলাল নেহরু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নরসিমহা রাও
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জওহরলাল নেহরু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাজীব গান্ধি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>দিল্লি-লাহোর সমঝোতা এক্সপ্রেস চালু হয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইন্দিরা গান্ধির আমলে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জওহরলাল নেহরুর আমলে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বাজপেয়ীর আমলে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নরসিমহা রাও-এর আমলে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বর্তমানে সার্ক-এর সদস্য সংখ্যা হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>7
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>8
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  10
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  12
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  আঞ্চলিক সহযোগিতা বৃদ্ধির জন্য দক্ষিণ-পূর্ব এশিয়ার সংগঠনটি হল
                  —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>G -78
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  SAARC
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  WARSAW
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ASEAN
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সাপটা চুক্তি কত খ্রিস্টাব্দে সম্পাদিত হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1995 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1993 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1991 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1997 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সার্কের নবীনতম সদস্য দেশটি হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আফগানিস্তান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শ্রীলঙ্কা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভূটান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পাকিস্তান
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সুয়েজ সংকট দেখা দেয় যে বছর -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1945 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1956 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1953 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1962 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#1f7405">
                <b>তৃতীয় অধ্যায় - বিদেশনীতি বা পররাষ্ট্রনীতি</b>{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. SAARC-এর পুরো নামটি লেখো।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                SAARC-এর পুরো নামটি হল – South Asian Asociation for Regional
                Co-operation
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. ASEAN-এর পুরো নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                উত্তর:- ASEAN-এর পুরো নামটি হল— Association of South East Asian
                Nations
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. SAFTA-এর পুরো নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                SAFTA-এর পুরো নামটি হল – South Asian Free Trade Area অথবা দক্ষিণ
                এশীয় মুক্ত বাণিজ্য অঞ্চল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    4. বিদেশনীতি বা পররাষ্ট্রনীতি বলতে কী বোঝো? অথবা, বিদেশনীতির
                    সংজ্ঞা দাও।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                জাতীয় স্বার্থের সংরক্ষণ ও জাতীয় লক্ষ্যপুরণের উদ্দেশ্যে
                আন্তর্জাতিক ক্ষেত্রে একটি রাষ্ট্রের সঙ্গে অন্যান্য রাষ্ট্রের
                সম্পর্ক স্থাপনের উদ্দেশ্যে প্রণীত ও রূপায়িত নীতিকেই বলা হয়
                বিদেশনীতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. বিদেশনীতি বা পররাষ্ট্রনীতির প্রধান উদ্দেশ্য কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বিদেশনীতি বা পররাষ্ট্রনীতির প্রধান উদ্দেশ্য হল জাতীয় স্বার্থের
                সংরক্ষণ ও জাতীয় লক্ষ্যপুরণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    6. পররাষ্ট্র বা বিদেশনীতি রূপায়ণে ভারত সরকারের কোন্ দপ্তর
                    মুখ্য ভূমিকা পালন করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পররাষ্ট্র বা বিদেশনীতি রূপায়ণে ভারত সরকারের বৈদেশিক বা কূটনীতি
                দপ্তর মুখ্য ভূমিকা পালন করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. পররাষ্ট্র নীতির দূরপাল্লার লক্ষ্য কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                চূড়ান্ত ও চরম রাজনৈতিক মতাদর্শের মাধ্যমে আন্তর্জাতিক ব্যবস্থার
                পুনর্গঠন ঘটানোই হল পররাষ্ট্রনীতির দুরপাল্লার লক্ষ্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. জোসেফ ফ্র্যাঙ্কেলের মতে পররাষ্ট্রনীতি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                জোসেফ ফ্র্যাঙ্কেলের মতে পররাষ্ট্রনীতি হল সেই সকল কার্যকলাপ বা
                সিদ্ধান্তের সমষ্টি, যা একাধিক রাষ্ট্রের পারস্পরিক সম্পর্কের
                সঙ্গে জড়িত থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. ‘কমনওয়েল্থ ‘ কী? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                কমনওয়েল্থ হলো স্বাধীন রাষ্ট্রগুলির এক স্বেচ্ছামূলক প্রতিষ্ঠান।{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. সার্কের একটি দুর্বলতা চিহ্নিত করো। </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সদস্য রাষ্ট্রগুলির অভ্যন্তরীণ বিবাদ।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  11. ভিয়েতনাম যুদ্ধে মার্কিন আগ্রাসনে ভারতের ভূমিকা কী ছিল?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভিয়েতনাম যুদ্ধে মার্কিন যুক্তরাষ্ট্রের সামরিক আগ্রাসনকে ভারত
                মেনে নেয়নি। <br />
                <br />
                <font color="#f626d7">
                  <b>
                    12. আন্তর্জাতিক ক্ষেত্রে একটি নিরপেক্ষ রাষ্ট্রের নাম উল্লেখ
                    করো।{" "}
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সুইৎজারল্যান্ড। <br />
                <br />
                <font color="#f626d7">
                  <b>13. বিদেশনীতির বাহ্যিক উপাদানগুলি কী? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বিশ্ব রাজনৈতিক পরিস্থিতি , বিশ্ব জনমত , প্রতিবেশী রাষ্ট্রের
                ক্রিয়া – প্রতিক্রিয়া। <br />
                <br />
                <font color="#f626d7">
                  <b>14. সার্কের সনদে মোট ধারার সংখ্যা কত? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মোট ধারার সংখ্যা ১০। <br />
                <br />
                <font color="#f626d7">
                  <b>15. কূটনীতি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                কূটনীতি হল সেই সকল নীতি যার মাধ্যমে আন্তর্জাতিক রাজনীতিতে জাতীয়
                রাষ্ট্রগুলো তাদের ভূমিকা নির্ধারণ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. পররাষ্ট্রনীতি ও কূটনীতির মধ্যে প্রধান পার্থক্য কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পররাষ্ট্রনীতি হল নিজ দেশের জাতীয় স্বার্থ সংরক্ষণে অপরাপর
                রাষ্ট্রগুলোর ক্ষেত্রে প্রয়োজনীয় নীতি সমূহ এবং কূটনীতি হল এই
                সকল নীতি কার্যকর করার পন্থা বা কৌশলসমূহ। অর্থাৎ এককথায়
                পররাষ্ট্র নীতি হল লক্ষ্য এবং কূটনীতি হল লক্ষ্যে পৌঁছানোর মাধ্যম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. মার্কিন পররাষ্ট্রনীতি প্রণয়ন করার ক্ষমতা কার?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মার্কিন পররাষ্ট্রনীতি প্রণয়ন করার অধিকারী হলেন মার্কিন
                রাষ্ট্রপতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    18. The End of History And The Last Man' গ্রন্থটি কার লেখা?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ফ্রান্সিস ফুকুয়ামা-র লেখা অন্যতম গ্রন্থটি হল “The End of
                History And The Last Man'|
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    19. ইতিহাসের পরিসমাপ্তি’ বলতে ফ্রান্সিস ফুকুয়ামা কি
                    বুঝিয়েছেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ফ্রান্সিস ফুকুয়ামা বলেছেন ইতিহাসের পরিসমাপ্তি হল পাশ্চাত্য
                উদারনৈতিক গণতন্ত্রের বিবর্তীত রূপ ও বিশ্বজনীনতা লাভ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. ভারতের মুম্বাইয়ে সন্ত্রাসবাদী হামলা কবে ঘটে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের মুম্বাইয়ে সন্ত্রাসবাদী হামলা সংঘটিত হয় 2008 সালে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. SAPTA-এর পুরো নামটি লেখো।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                SAPTA-এর পুরো নামটি হল-- South Asian Preferential Trade
                Agreement
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. এশিয়ার দুটি সাহায্যকারী গোষ্ঠীর নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                এশিয়ার অন্যতম দুটি সাহায্যকারী গোষ্ঠীর নাম হল—SAARC ও ASEAN|
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. SAARC কবে প্রতিষ্ঠিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                SAARC প্রতিষ্ঠিত হয় 1986 সালের ডিসেম্বর মাসে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. SAARC (সার্ক) গঠনের সময় তাঁর সদস্য সংখ্যা কত ছিল?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                SAARC গঠনের সময় তাঁর সদস্য সংখ্যা ছিল 7<br />
                <br />
                <font color="#f626d7">
                  <b>25. বর্তমানে SAARC-এর সদস্য সংখ্যা কত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বর্তমানে SAARC-এর সদস্য সংখ্যা হল ৪।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. SAARC গঠনের প্রস্তাব প্রথম কে দেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                SAARC গঠনের প্রস্তাব প্রথম দেন তৎকালীন বাংলাদেশের রাষ্ট্রপতি
                জিয়াউর রহমান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. SAARC-এর একটি উদ্দেশ্য লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                SAARC-এর অন্যতম উদ্দেশ্য হল দক্ষিণ-এশিয়ার অঞ্চলের সামগ্রিক
                উন্নয়ন ও নাগরিকজীবনের উৎকর্ষবিধান করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. 'BRICS' অন্তর্ভুক্ত দেশগুলোর নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                'BRICS' অন্তর্ভুক্ত দেশগুলো হল- ব্রাজিল, রাশিয়া, ভারত, চিন
                আফ্রিকা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. CIA কী ও এর পুরো নামটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মার্কিন গোয়েন্দা সংস্থার নাম CIA। CIA-এর পুরো নামটি হল Central
                Intelligence Agency
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. WTO কবে গঠিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                1995 খ্রিস্টাব্দে WTO গঠিত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>31. WTO-র পুরো নামটি লেখো?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                WTO-এর পুরো নামটি হল— World Trade Organisation|
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. SAARC-এর সর্ববৃহৎ সদস্য রাষ্ট্র কোনটি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                SAARC-এর সর্ববৃহৎ রাষ্ট্র হল ভারতবর্ষ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. SAARC-এর ক্ষুদ্রতম সদস্য রাষ্ট্র কোনটি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                SAARC-এর ক্ষুদ্রতম রাষ্ট্র হল মালদ্বীপ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. SAARC-এর সনদে ক-টি উদ্দেশ্যের কথা বলা আছে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                SAARC-এর সনদে ৪টি উদ্দেশ্যের কথা বলা আছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. SAARC-এর সনদে কটি ধারা আছে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                SAARC-এর সনদে 10টি ধারা আছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>36. সার্কের একটি নীতি উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সার্কের অন্যতম একটি নীতি হল—ভৌগোলিক তাখণ্ডতার প্রতি
                শ্রদ্ধাজ্ঞাপন এবং অন্য রাষ্ট্রের অভ্যন্তরীণ বিষয়ে হস্তক্ষেপ না
                করার প্রতিশ্রুতিবদ্ধ হওয়া।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. SAARC-এর প্রথম শীর্ষ সম্মেলন কোথায় অনুষ্ঠিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                SAARC-এর প্রথম শীর্ষ সম্মেলন অনুষ্ঠিত হয় বাংলাদেশের ঢাকায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. রতের বিদেশনীতির একটি মূল বৈশিষ্ট্য লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের বিদেশনীতির একটি মূল বৈশিষ্ট্য হল জোট নিরপেক্ষতা নীতি
                অনুসরণ ও আন্তর্জাতিক শান্তি প্রতিষ্ঠা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. ভারতের বিদেশনীতির মূল উপাদান কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের বিদেশনীতির মূল উপাদান হল—উপনিবেশবাদের বিরোধীতা ও জাতীয়
                মুক্তি আন্দোলনের সমর্থন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>40. ভারতের বিদেশনীতির মূল লক্ষ্য ও উদ্দেশ্যটি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের বিদেশনীতির মূল লক্ষ্যটি বা উদ্দেশ্যটি হল আন্তর্জাতিক
                শান্তি প্রতিষ্ঠা।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>41. ভারতের বিদেশনীতির প্রধান স্তম্ভ কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতের বিদেশনীতির প্রধান স্তম্ভটি হল—জোটনিরপেক্ষতা অর্থাৎ
                নিরপেক্ষ, স্বাধীন ও শান্তিপূর্ণ অবস্থান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>42. SAARC এর সদর দপ্তর কোথায় অবস্থিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                SAARC এর সদর দপ্তর নেপালের রাজধানী কাঠমান্ডুতে অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>43. সার্কের নবীন সদস্যের নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সার্কের নবীন সদস্যের নাম হল আফগানিস্তান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    44. কত খ্রিস্টাব্দে আফগানিস্তান SAARC-এর সদস্যপদ লাভ করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                2007 খ্রিস্টাব্দে আফগানিস্তান SAARC-এর সদস্যপদ লাভ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    45. SAARC-এর প্রথম অধিবেশনে ভারতের প্রধানমন্ত্রী হিসেবে কে
                    উপস্থিত ছিলেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                SAARC-এর প্রথম অধিবেশনে ভারতের প্রধানমন্ত্রী হিসেবে রাজীব গান্ধি
                উপস্থিত ছিলেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>46. কার সভাপতিত্বে SAARC-এর প্রথম অধিবেশন অনুষ্ঠিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বাংলাদেশের রাষ্ট্রপতি হুসেন মহম্মদ এরশাদ-এর সভাপতিত্বে SAARC-এর
                প্রথম অধিবেশন অনুষ্ঠিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>47. SAARC-এর একটি সীমাবদ্ধতা বা দুর্বলতা লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                SAARC-এর অন্তর্ভুক্ত দেশগুলোর মধ্যেকার বিবাদ সহযোগিতা স্থাপনের
                ক্ষেত্রে অন্যতম অন্তরায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>48. ভারতের পরমাণু নীতির মূল কথা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের পরমাণু নীতির মূল কথা হল— ‘No First Strike’ বা ‘প্রথম আঘাত
                নয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>49. FISA' কে প্রণয়ন করেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                প্রাক্তন মার্কিন রাষ্ট্রপতি জর্জ বুশ FISA প্রণয়ন করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>50. ‘FISA’-র পুরো নামটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ‘FISA’-র পুরো নামটি হল— Foreign Intelligence Service Act
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  51. ভারতে কোন্ প্রধানমন্ত্রীর আমল থেকে উদারিকীকরণের প্রক্রিয়া
                  শুরু হয়?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতে প্রধানমন্ত্রী নরসিমহা রাও-এর সময় থেকে উদারিকীকরণের
                প্রক্রিয়া শুরু হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>52. ISA-র পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ISA-র পুরো নাম হল— Inter Service Intelligence
                <br />
                <br />
                <font color="#f626d7">
                  <b>53. ভারত-চিন সংঘর্ষ কোন্ ব্যর্থতাকে প্রকাশ করে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারত চিন সংঘর্ষ পঞ্চশীল নীতির ব্যর্থতাকে প্রকাশ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>54. কমনওয়েলথ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                স্বাধীনতাপ্রাপ্ত রাষ্ট্রগুলোর একটি স্বেচ্ছামূলক সংগঠন বা
                প্রতিষ্ঠান হল কমনওয়েলথ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    55. কত খ্রিস্টাব্দে মার্কিন যুক্তরাষ্ট্র সিরিয়া আক্রমণের
                    প্রস্তুতি নেয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                2013 খ্রিস্টাব্দে মার্কিন যুক্তরাষ্ট্র সিরিয়া আক্রমণের
                প্রস্তুতি নেয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>56. মানবসভ্যতার চরম শত্রু কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মানবসভ্যতার চরম শত্রু হল সাম্রাজ্যবাদ ও উপনিবেশবাদ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>57. কোন্ দুটি দেশের মধ্যে সিমলা চুক্তি সম্পাদিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারত ও পাকিস্তানের মধ্যে সিমলা চুক্তি সম্পাদিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>58. SAFTA গঠনের উদ্দেশ্য কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মূলত বাণিজ্যিক আদান-প্রদানের উদ্দেশ্যে 2015 খ্রিস্টাব্দে SAARC
                অন্তর্ভুক্ত দেশগুলোর মধ্যে দ্বাদশ সম্মেলনে SAFTA গঠনের সিদ্ধান্ত
                নেওয়া হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>59. SAFTA-এর দীর্ঘমেয়াদি পরিকল্পনা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                দক্ষিণ এশিয় অঞ্চলকে মুক্ত বাণিজ্যিক অঞ্চলে পরিণত করাই SAFTA-এর
                দীর্ঘমেয়াদি পরিকল্পনা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>60. SAPTA গঠনের প্রস্তাব কবে গৃহীত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                SAPTA গঠনের প্রস্তাব গৃহীত হয় 1991 খ্রিস্টাব্দের 21 ডিসেম্বর।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>61. NDA-র পুরো নামটি লেখো।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                NDA-র পুরো নামটি হল— National Democratic Alliance|
                <br />
                <br />
                <font color="#f626d7">
                  <b>62. GATT-এর পুরো নামটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>{" "}
                GATT-এর পুরো নামটি হল—General Agreement of Trade and Tarriff|
                <br />
                <br />
                <font color="#f626d7">
                  <b>63. ‘123’-চুক্তি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                2007 খ্রিস্টাব্দে সংঘটিত ভারত-মার্কিন অসামরিক পরমাণু চুক্তিটি হল
                '123' চুক্তি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>64. CTBT-এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                CTBT-এর পুরো নাম হল Comprehensive Test Ban Treaty
                <br />
                <br />
                <font color="#f626d7">
                  <b>65. CTBT চুক্তি কবে স্বাক্ষরিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                CTBT চুক্তি স্বাক্ষরিত হয় 1996 খ্রিস্টাব্দে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>66. ভারত কেন CTBT চুক্তিতে স্বাক্ষর করেনি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পরমাণু অস্ত্র পরীক্ষা বন্ধ হলে তা সবার ক্ষেত্রেই প্রযুক্ত হওয়ার
                দরকার। ভারত এই বৈষম্যমূলক CTBT চুক্তিতে স্বাক্ষর করেনি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    67. ভারতে ‘No First Strike' নীতি কোন্ প্রধানমন্ত্রীর আমলে
                    গ্রহণ করা হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অটল বিহারী বাজপেয়ী-র আমলে গ্রহণ করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    68. ভারতে ‘Look East Policy’ বা ‘পূর্বে তাকাও নীতি’ কোন্
                    প্রধানমন্ত্রীর আমলে গ্রহণ করা হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অটল বিহারী বাজপেয়ী-র আমলে গ্রহণ করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    69. ‘পূর্বে তাকাও নীতি’ বা ‘Look East Policy’-র মূল লক্ষ্য
                    কী?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                প্রশান্ত মহাসাগরের কাছে অবস্থিত পূর্ব এশিয়ার অঞ্চলগুলোর
                অর্থনৈতিক অগ্রগতির সঙ্গে ভারতকে সংযুক্ত করাই ‘Look east Policy-র
                মূল লক্ষ্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>70. UPA-র পুরো নামটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                UPA-র পুরো নামটি হল— United Progressive Alliance|
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

বিদেশনীতিবাপররাষ্ট্রনীতি.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(বিদেশনীতিবাপররাষ্ট্রনীতি);
