import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function সাম্রাজ্যবাদেরবিরুদ্ধেপ্রতিক্রিয়া() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          সাম্রাজ্যবাদের বিরুদ্ধে প্রতিক্রিয়া | West Bengal Class 12 Guide |
          West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="সাম্রাজ্যবাদের বিরুদ্ধে প্রতিক্রিয়া | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                সাম্রাজ্যবাদের বিরুদ্ধে প্রতিক্রিয়া
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  সাউকার বিরোধী দাঙা বা দাক্ষিণাত্য কৃষক হাঙ্গামা’ হয়েছিল —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৮১৭ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৮৫৪ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ১৮৭৫ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৮৭০ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>রামমোহন রায়কে ‘রাজা’ উপাধি দিয়েছিলেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রবীন্দ্রনাথ ঠাকুর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ড মিন্টো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সম্রাট বাহাদুর শাহ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মোগল সম্রাট ‘দ্বিতীয় আকবর’
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সিং-চুং-হুং-এর প্রবর্তক ছিলেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চিয়াং-কাই-শেক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মাও-সে-তুং
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চৌ-এন-লাই
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সান-ইয়াৎ-সেন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ভারতে ‘হোমরুল' আন্দোলনের (১৯১৬ খ্রিস্টাব্দ) নেত্রী ছিলেন —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অ্যানি বেসান্ত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভোগেশ্বরী দেবী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সরলাদেবী চৌধুরানি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সরোজিনী নাইডু
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  {" "}
                  সতীদাহ প্রথা নিবারণের সঙ্গে যে ভারতীয়র নাম যুক্ত তিনি হলেন —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোলব্রুক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মেকলে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  রাজা রামমোহন রায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আলেকজান্ডার ডাফ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  {" "}
                  দক্ষিণ ভারতে/মাদ্রাজে বিধবা বিবাহের সপক্ষে জোরালো আন্দোলন গড়ে
                  তোলেন -{" "}
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্বামী দয়ানন্দ সরস্বতী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জ্যোতিবা ফুলে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বীরসালিঙ্গম পানতুলু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এম. জি. রানাডে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>হিন্দু পাইওনিয়ার’ পত্রিকাটি প্রকাশ করে - </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রার্থনা সমাজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্রাহ্মসমাজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  নব্যবঙ্গ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আর্যসমাজ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>চার্লস উড কে ছিলেন?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভারতের গভর্নর জেনারেল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভারতসচিব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বোর্ড অফ কন্ট্রোলের সভাপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বাংলার গভর্নর জেনারেল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বিধবা বিবাহ আইন (১৮৫৬ খ্রিস্টাব্দ) পাস করেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ড উইলিয়াম বেন্টিঙ্ক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  লর্ড ক্যানিং
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ড হার্ডিঞ্জ (১৮৪৪ খ্রিস্টাব্দে)
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ড ওয়ারেন হেস্টিংস
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> উডের নির্দেশনামা (/‘উডস্ ডেসপ্যাচ’) কৰে ঘোষিত হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৮১৭ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ১৮৫৪ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৮১৩ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৮২৯ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="3">
                <b>চতুর্থ অধ্যায় - সাম্রাজ্যবাদের বিরুদ্ধে প্রতিক্রিয়া</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রশ্নের মান - ৮</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                1. আলিগড় আন্দোলনের একটি সংক্ষিপ্ত বিবরণ দাও।
                <br />
                2. পাশ্চাত্য শিক্ষার প্রভাবে চিনে কীভাবে বুদ্ধিজীবী শ্রেণির
                উদ্ভব হয়? চীনের তাইপিং বিদ্রোহ সম্পর্কে একটা টীকা লেখো।
                <br />
                3. ঔপনিবেশিক শাসনকালে আদিবাসী ও দলিত সম্প্রদায়ের আন্দোলনের
                পরিচয় দাও। <br />
                4. ডিরোজিওর নেতৃত্বে ইয়ং বেঙ্গল আন্দোলনের সংক্ষিপ্ত পরিচয় দাও।
                তাদের আন্দোলনের সীমাবদ্ধতা/ব্যর্থতার কারণগুলি আলোচনা করো। <br />
                5. বাংলায় নবজাগরণের প্রকৃতি আলোচনা করো। এর সীমাবদ্ধতা কী ছিল?
                <br />
                6. চিনের চৌঠা মে আন্দোলনের কারণগুলি বিশ্লেষণ করো। এই আন্দোলনের
                প্রভাব আলোচনা করো।
                <br />
                7. সমাজ ও শিক্ষাসংস্কারে রাজা রামমোহন রায়ের অবদান মূল্যায়ন
                করো।
                <br />
                8. বাংলার সমাজসংস্কার আন্দোলনে ঈশ্বরচন্দ্র বিদ্যাসাগরের ভূমিকা
                আলোচনা করো।
                <br />
                9. ডিরোজিওর নেতৃত্বে বাংলায় সংস্কার আন্দোলন সম্পর্কে আলোচনা
                করো। ডিরোজিওর মৃত্যুর পর এই আন্দোলনের কী পরিণতি হয়েছিল?
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রশ্নের মান - ১</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. ‘গোৱক্ষিনী সভা’ কে কবে প্রতিষ্ঠা করেন?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                স্বামী দয়ানন্দ সরস্বতী, ১৮৮২ খ্রিস্টাব্দে ‘গোরক্ষিনী সভা’
                প্রতিষ্ঠা করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    2. মাঞ্চু রাজবংশের পতন ঘটে কবে? চিনে রাজতান্ত্রিক শাসনের
                    অবসান ঘটে কবে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯১২ খ্রিস্টাব্দে মাঞ্চু রাজবংশের পতন ঘটে। চিনে রাজতান্ত্রিক
                শাসনের অবসান ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    3. চিনে প্রথম প্রজাতান্ত্রিক সরকার কবে প্রতিষ্ঠিত হয়? এই
                    প্রজাতন্ত্রের প্রথম রাষ্ট্রপতি কে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯১২ খ্রিস্টাব্দে, ড. সান-ইয়াৎ-সেন। <br />
                <br />
                <font color="#f626d7">
                  <b>4. কে, কবে চিনে কুয়ো-মিন-তাং দলকে নিষিদ্ধ ঘোষণা করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ইউয়ান-সি-কাই, ১৯১৩ খ্রিস্টাব্দে কুয়ো-মিন-তাং দলকে নিষিদ্ধ
                ঘোষণা করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. ‘সত্যশোধক সভা'র প্রতিষ্ঠাতা কে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জ্যোতিবা ফুলে ১৮৭৩ খ্রিস্টাব্দে ‘সত্যশোধক সমাজ’ প্রতিষ্ঠা করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. বিধবা বিবাহ আন্দোলনের প্রধান উদ্যোক্তা কে ছিলেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বিধবা বিবাহ আন্দোলনের প্রধান উদ্যোক্তা ছিলেন পণ্ডিত ঈশ্বরচন্দ্র
                বিদ্যাসাগর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7.কে, কবে রামকৃষ্ণ মিশন প্রতিষ্ঠা করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                স্বামী বিবেকানন্দ ১৮৯৭ খ্রিস্টাব্দে রামকৃষ্ণ মিশন প্রতিষ্ঠা
                করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. কলকাতা বিশ্ববিদ্যালয়ের প্রথম উপাচার্য কে ছিলেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কলকাতা বিশ্ববিদ্যালয়ের প্রথম উপাচার্য ছিলেন জেম্স উইলিয়াম
                কোলভিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. স্বামী বিবেকানন্দের লেখা দুটি গ্রন্থের নাম লেখো। </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                স্বামী বিবেকানন্দের লেখা দুটি গ্রন্থের নাম হল—‘বর্তমান ভারত’ ও
                ‘প্রাচ্য পাশ্চাত্য'।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. ‘বিবেকবর্ধিনী’ নামক পত্রিকা কে প্রকাশ করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বীরসালিঙ্গম পাত্তুলু/‘বিবেকবর্ধিনী’ নামক পত্রিকা প্রকাশ করেন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  11.পুণা চুক্তি কত খ্রিস্টাব্দে কাদের মধ্যে স্বাক্ষরিত হয়েছিল?
                </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ১৯৩২ খ্রিস্টাব্দের ২৪ সেপ্টেম্বর গান্ধিজি ও ড. বি. আর.
                আম্বেদকরের মধ্যে পুণা চুক্তি স্বাক্ষরিত হয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    12. বক্সার প্রোটোকল’ কবে সম্পাদিত হয়?
                    <br />
                    অথবা,
                    <br /> ‘বক্সার প্রোটোকল’ কাদের মধ্যে স্বাক্ষরিত হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯০১ খ্রিস্টাব্দের ৭ সেপ্টেম্বর চিনের কিং রাজ বংশ ও বক্সার
                বিদ্রোহ দমনে সাহায্যকারী আটটি ইউরোপীয় শক্তির মধ্যে ‘বক্সার
                প্রটোকল’ স্বাক্ষরিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. ‘পত্তিদার’ কাদের বলা হত? </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>{" "}
                ‘পত্তিদার’ শব্দের অর্থ হল জমির মালিক। ঔপনিবেশিক শাসনকালে গুজরাটে
                কুনবি সম্প্রদায়ভুক্ত গ্রামের ভূমিরাজস্ব আদায়কারী স্থানীয়
                মোড়ল বা আধিপত্যকারী সম্প্রদায়কে পত্তিদার বলা হত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    14. কেরালায় কে অস্পৃশ্যতার বিরুদ্ধে শক্তিশালী আন্দোলন গড়ে
                    তোলেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                শ্রীনারায়নগুরুর নেতৃত্বে কেরালায় অস্পৃশ্যতার বিরুদ্ধে
                শক্তিশালী আন্দোলন গড়ে ওঠে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. চিনে কোন্ বছর মে ফোর্থ আন্দোলন শুরু হয়েছিল?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯১৯ খ্রিস্টাব্দে চিনে মে ফোর্থ আন্দোলন শুরু হয়েছিল।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>16. দলিত কাদের বলা হত?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                মারাঠা ‘দলন’ শব্দ থেকে দলিত শব্দের উৎপত্তি, যার আক্ষরিক অর্থ হল
                পদদলিত বা অবহেলিত। ভারতীয়বর্ণ-বিভক্ত সমাজ ব্যবস্থায় প্রান্তিক
                স্তরে অবস্থিত শোষিত, বঞ্চিত, অস্পৃশ্য জনগোষ্ঠী ‘দলিত’ নামে
                পরিচিত ছিল। গান্ধিজি ‘দলিত’ শব্দের পরিবর্তে ‘হরিজন' (ঈশ্বরের
                সন্তান) শব্দটি ব্যবহারের পক্ষপাতী ছিলেন। সাধারণভাবে মাহার,
                নাদার, চামার, এঝাবা, পুলায়া, নমঃশূদ্র প্রভৃতি জনগোষ্ঠী দলিত
                নামে পরিচিত ছিল। স্বাধীন ভারতের সংবিধানে এদেরকে তপশিলি জাতি’,
                ‘তপশিলি উপজাতি’ নামে সমাজভুক্ত করা হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. সাহুকার/সাউকার কাদের বলা হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ‘সাউকার’ শব্দের অর্থ টাকাপয়সা লেনদেনের সঙ্গে যুক্ত মারওয়ারি
                ব্যবসায়ী শ্রেণি। চতুঃবৰ্ণ ভিত্তিক হিন্দু সমাজে ‘সাহু’ বা
                ‘সাউকার’রা বৈশ্য-বণিক সম্প্রদায়ভুক্ত মানুষ হিসেবে অভিহিত।
                ইংরেজ-সৃষ্ট নব্য ভূমিরাজস্ব ব্যবস্থায় পশ্চিম ও দক্ষিণ ভারতে এই
                মহাজন শ্রেণি ঋণদাতা ও সুদখোর মহাজন হিসেবে শোষক শ্রেণির প্রতীক
                হয়ে ওঠে। ১৮৭৫ খ্রিস্টাব্দের ‘দাক্ষিণাত্য কৃষক হাঙ্গামা'য় এই
                সাউকার শ্রেণি আক্রমণের অন্যতম লক্ষ্য হয়ে উঠেছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. একশো দিনের সংস্কার/শত দিবসের সংস্কার’ কী ছিল?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জাপানের কাছে পরাজয়ের পর চিনে জাতীয়তাবাদী সংস্কার শুরু হয়।
                কাং-ইউ-ওয়ে নামে পন্ডিতের সুপারিশের ভিত্তিতে সম্রাট কোয়াং সু
                ১৮৯৮ খ্রিস্টাব্দের ১১ জুন এক সংস্কার কর্মসূচি গ্রহণ করেন, যা ২২
                সেপ্টেম্বর পর্যন্ত মাত্র ১০০ দিন চালু ছিল। সেই কারণে এই সংস্কার
                কর্মসূচি ‘শত দিবসের সংস্কার’ নামে খ্যাত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. তাইপিং বিদ্রোহ কবে এবং কোথায় হয়েছিল?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮৫০-৬৪ খ্রিস্টাব্দের মধ্যে দক্ষিণচিনের কোয়াংসি প্রদেশে
                হ্যাং-শিউ-চুয়ান-এর নেতৃত্বে তাইপিং বিদ্রোহ সংঘটিত হয়েছিল।
                ক্রমে এই বিদ্রোহ উত্তরে পিকিং, পূর্বে সাংহাই ও পশ্চিমে তিব্বতীয়
                পার্বত্য অঞ্চলে সম্প্রসারিত হয়েছিল। ১৮৫৩ খ্রিস্টাব্দে
                বিদ্রোহীরা নানকিং দখল করে সেখানে তাইপিং রাজ্যের রাজধানী
                প্রতিষ্ঠা করেছিল। <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20.হিন্দু বিধবা পুনর্বিবাহ আইন কোন্ খ্রিস্টাব্দে প্রবর্তিত
                    হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বড়োলাট লর্ড ক্যানিং-এর আমলে ঈশ্বরচন্দ্র বিদ্যাসাগরের উদ্যোগে
                ১৮৫৬ খ্রিস্টাব্দে হিন্দু বিধবা পুনর্বিবাহ আইন প্রবর্তিত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21.নব্যবঙ্গীয় কারা? </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                হিন্দু কলেজের তরুণ ও মননশীল অধ্যাপক হেনরি লুই ভিভিয়ান ডিরোজিও-র
                নেতৃত্বে ওই কলেজের একদল ছাত্র উনিশ শতকের প্রথমার্ধে হিন্দু ধর্ম
                ও সমাজের কুসংস্কারের বিরুদ্ধে জেহাদ ঘোষণা করেন এবং পাশ্চাত্যের
                অন্ধ অনুকরণে মেতে ওঠেন। তাঁরা ‘নব্যবঙ্গ’ নামে পরিচিত এবং তাঁদের
                পরিচালিত আন্দোলনই হল ‘নব্যবঙ্গ আন্দোলন'।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. চুক্তিবদ্ধ শ্রমিক কাদের বলা হত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                চুক্তিবদ্ধ শ্রমিক বলতে কোনো নিয়োগ কর্তার অধীনে চুক্তিতে আবদ্ধ
                শ্রমিক শ্রেণিকে বোঝায় যারা নির্দিষ্ট বেতনের বিনিময়ে নির্দিষ্ট
                সময়ে বিদেশে কাজ করার চুক্তিতে আবদ্ধ হয়। উনিশ শতকে ভারত ও চিনের
                অনেক দরিদ্র মানুষ চুক্তিবদ্ধ শ্রমিক হিসেবে বিদেশে কাজ করতে যেত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    23. আৰ্য সমাজ কবে প্রতিষ্ঠিত হয়?
                    <br />
                    অথবা,
                    <br /> আর্য সমাজের প্রতিষ্ঠাতা কে ছিলেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                স্বামী দয়ানন্দ সরস্বতীর নেতৃত্বে 1875 খ্রিস্টাব্দে বোম্বাইতে
                আর্যসমাজ প্রতিষ্ঠিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. চিনে কে, কবে দ্বিতীয় বিপ্লবের ডাক দেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                সান-ইয়াৎ-সেন, ১৯১৩ খ্রিস্টাব্দে, ইউয়ান-সি-কাই-এর বিরুদ্ধে চিনে
                ‘দ্বিতীয় বিপ্লবে’র ডাক দেন। ১৯১২ খ্রিস্টাব্দে চিনে প্রথম
                প্রজাতান্ত্রিক সরকার প্রতিষ্ঠিত হয়। এই প্রজাতান্ত্রিক সরকারে
                রাষ্ট্রপতির দায়িত্ব পালন করেন ড. সান ইয়াৎ সেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. চার্লস উড কে ছিলেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                চার্লস উড ছিলেন কোম্পানির বোর্ড অফ কন্ট্রোলের সভাপতি। ভারতে
                পাশ্চাত্য শিক্ষার উন্নতিকল্পে ১৮৫৪ খ্রিস্টাব্দে তিনি তাঁর
                বিখ্যাত ‘ডেসপ্যাচ’ বা নির্দেশনামা জারি করেন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>26. প্রার্থনা সমাজের প্রতিষ্ঠাতা কে? </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                আত্মারাম পান্ডুরঙ্গ ১৮৬৭ খ্রিস্টাব্দে মহারাষ্ট্রে প্রার্থনা সমাজ
                প্রতিষ্ঠা করেন। একাজে কেশবচন্দ্র সেনের দ্বারা তিনি গভীরভাবে
                প্রভাবিত হয়েছিলেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    27. ১৯২৪-২৫ খ্রিস্টাব্দে ভাইকম সত্যাগ্রহের নেতৃত্ব কে দেন?{" "}
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দক্ষিণ ভারতের ভাইকমে শ্রীনারায়ন গুরুর নেতৃত্বে ১৯২৪-২৫
                খ্রিস্টাব্দে নিম্নবর্গের মানুষের মন্দিরে প্রবেশের আন্দোলন বা
                ভাইকম সত্যাগ্রহ গড়ে উঠেছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. তাইপিং বিদ্রোহ কবে এবং কেন শুরু হয়েছিল? </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মাঞ্জু সরকারের অপদার্থতা, দুর্নীতিগ্রস্ত প্রশাসন, চিনে বিদেশিদের
                আধিপত্য এবং তজ্জনিত দেশবাসীর দুরবস্থা প্রভৃতির সম্রাট বিরুদ্ধে
                হ্যাং-শিউ-চুয়ান ‘তাইপিং’ নামে একটি ধর্মীয় সম্প্রদায় প্রতিষ্ঠা
                করে চিনে ধর্মরাজ্য প্রতিষ্ঠার আন্দোলন গড়ে তোলেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. চুঁইয়ে পড়া নীতি কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                লর্ড বেন্টিঙ্কের আইন সচিব ও খ্যাতনামা পন্ডিত টমাস ব্যাবিংটন
                মেকলে ১৮৩৫ খ্রিস্টাব্দে তার বিখ্যাত ‘মিনিটস্’ বা প্রতিবেদনে বলেন
                যে, প্রথমে উচ্চ ও মধ্যবিত্তের মধ্যে ইংরেজি শিক্ষা বিস্তৃত হবে
                এবং তারপর ধীরে ধীরে তা সমাজের সর্বস্তরের মানুষের কাছে পৌঁছে
                যাবে। ফিলটার বা জল পরিসুতকরণ যন্ত্রের জল যেমন নীচে চুঁইয়ে পড়ে,
                তেমনি পাশচাত্য শিক্ষাও ক্রমশ উচ্চ ও মধ্যবিত্ত থেকে নিম্নবিত্তের
                মধ্যে ছড়িয়ে পড়বে। এটিই 'filtration theory’ বা ‘চুঁইয়ে পড়া
                নীতি’ নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. উডের ডেসপ্যাচ কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কোম্পানির বোর্ড অফ কন্ট্রোলের সভাপতি চার্লস উড ১৮৫৪ খ্রিস্টাব্দে
                একটি শিক্ষা সংক্রান্ত নির্দেশনামা জারি করেন। এতে সরকারি উদ্যোগে
                প্রাথমিক, মাধ্যমিক স্কুল ও কলেজ প্রতিষ্ঠা, একটি স্বতন্ত্র
                শিক্ষাবিভাগ স্থাপন, তিনটি প্রেসিডেন্সি সম্রাট শহরে
                বিশ্ববিদ্যালয় স্থাপন প্রভৃতির কথা বলা হয়। এগুলি উডের
                নির্দেশনামা নামে খ্যাত। এর উপর ভিত্তি করেই আধুনিক ভারতের
                শিক্ষাব্যবস্থা গড়ে উঠেছে। তাই ভারতে পাশ্চাত্যশিক্ষা বিস্তারের
                ইতিহাসে একে ‘ম্যাগনাকার্টা’ বা মহাসনদ বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  31. ড. সান-ইয়াৎ-সেন প্রস্তাবিত তিনটি নীতি কী কী ছিল
                  ?/সান-মিন-চুই' বলতে কী বোঝো?
                </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ড. সান ইয়াৎ-সেন ১৮৯৮ খ্রিস্টাব্দে চিনা জনগণের জন্য
                ‘সান-মিন-চুই' বা তিনটি নীতি ঘোষণা করেন। এই তিনটি নীতি হল—জন
                জাতীয়তাবাদ, জন গণতন্ত্রবাদ এবং জন জীবিকাবাদ বা জন সমাজতন্ত্র।
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. ভাইকম সত্যাগ্রহ বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দক্ষিণ ভারতের ভাইকমে একটি হিন্দুমন্দিরের নিকটবর্তী রাস্তা
                নিম্নবর্গের মানুষের চলাচলের জন্য নিষিদ্ধ ছিল। এই রাস্তায় তাদের
                চলাচল এবং মন্দিরে প্রবেশাধিকারের দাবিতে শ্রীনারায়নগুরু ১৯২৪
                খ্রিস্টাব্দে যে আন্দোলন গড়ে তোলেন; তা ‘ভাইকম সত্যাগ্রহ' নামে
                খ্যাত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    33. দেশীয় ভাষায় সংবাদপত্র আইন কত খ্রিস্টাব্দে কোন্ বড়লাট
                    পাস করেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮৭৮ খ্রিস্টাব্দে বড়োলাট লর্ড লিটন দেশীয় ভাষায় সংবাদপত্র আইন
                (ভার্নাকুলার প্রেস অ্যাক্ট) পাস করে সংবাদপত্রের কণ্ঠরোধ করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. কুয়ো-মিন-তাং দলের প্রতিষ্ঠাতা কে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ড. সান-ইয়াৎ-সেন ১৯১২ খ্রিস্টাব্দে কুয়ো-মিন-তাং দল প্রতিষ্ঠা
                করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. 8 মে আন্দোলন বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভার্সাই সন্ধিতে চিনের প্রতি অবমাননার প্রতিবাদে চিনা বুদ্ধিজীবী
                সমাজ, নারী, ছাত্র, কৃষক ও শ্রমিক শ্রেণি একত্রিতভাবে বিদেশি
                আধিপত্যের বিরুদ্ধে ১৯১৯ খ্রিস্টাব্দের ৪ মে চিনে যে আন্দোলন গড়ে
                তোলেন, তা ৪ মে আন্দোলন নামে পরিচিত। চেন-তু-শিউ, লি-তা-চাও প্রমুখ
                ছিলেন এই আন্দোলনের উল্লেখযোগ্য নেতা। এই আন্দোলনের মূল শ্লোগান
                ছিল ‘জিও গুয়ো’ বা দেশকে রক্ষা করো।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>36. চার্লস উড কে ছিলেন?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                চার্লস উড ছিলেন কোম্পানির বোর্ড অফ কন্ট্রোলের সভাপতি। ১৮৫৪
                খ্রিস্টাব্দে তিনি ভারতীয় শিক্ষা সংক্রান্ত যে বিখ্যাত ‘ডেসপ্যাচ’
                ঘোষণা করেন, তার জন্য তিনি ইতিহাসে স্মরণীয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. দ্বিজাতি তত্ত্ব কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতে দ্বিজাতি তত্ত্বের প্রবক্তা স্যার সৈয়দ আহমেদ খান। আলিগড়
                কলেজকে কেন্দ্র করে তিনি প্রচার করেন যে, ইতিহাসের গোড়া থেকেই
                ভারতে হিন্দু ও মুসলিম দুটি সম্পূর্ণ পরস্পরবিরোধি ও যুদ্ধরত জাতি।
                এদেশে তাদের সহাবস্থান সম্ভব নয়। তাই মুসলিমদের জন্য পৃথক
                রাষ্ট্রের প্রয়োজন। এই রাজনৈতিক মতাদর্শই দ্বিজাতি তত্ত্ব নামে
                পরিচিত। পরবর্তীতে জিন্নাহ্ প্রমুখ এই মতাদর্শকে আরও পল্লবিত করে
                দেশভাগকে অবশ্যম্ভাবী করে তোলেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    38. কাকে, কেন ‘দক্ষিণের বিদ্যাসাগর' বলা হয়?/বীরসালিঙ্গম
                    পাণ্ডুলু স্মরণীয় কেন?{" "}
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পন্ডিত ঈশ্বরচন্দ্র বিদ্যাসাগরের ভাবাদর্শে প্রভাবিত হয়ে
                বীরসালিঙ্গম পাণ্ডুলু দক্ষিণ ভারতে বিধবা বিবাহের সমর্থনে জনমত গঠন
                করেন এবং নিজ উদ্যোগে অনেকগুলি বিধবা বিবাহ সুসম্পন্ন করেন। ১৮৭১
                খ্রিস্টাব্দে দাক্ষিণাত্যে তাঁর উদ্যোগে গড়ে ওঠে ‘বিধবাবিবাহ
                সমিতি’। তাই মহাদেব গোবিন্দ রানাডে তাঁকে ‘দক্ষিণের বিদ্যাসাগর’
                অভিধায় ভূষিত করেছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. ডেভিড হেয়ার স্মরণীয় কেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                স্কটল্যান্ডের ঘড়ি ব্যবসায়ী ডেভিড হেয়ার কলকাতায় হিন্দু কলেজ
                প্রতিষ্ঠায় সক্রিয় সহায়তা করেন এবং নিজ উদ্যোগে পটলডাঙা
                অ্যাকাডেমি (হেয়ার স্কুল) স্থাপন করেন। ইংরেজি ভাষায় পাঠ্যপুস্তক
                রচনা ও সুলভে বিতরণের জন্য তার উদ্যোগে ১৮১৭ খ্রিস্টাব্দে স্কুল
                বুক সোসাইটি প্রতিষ্ঠিত হয়। পাশ্চাত্য শিক্ষাপ্রসারে তার এই
                অবদানের জন্য ডেভিড হেয়ারকে ‘মহাত্মা’ বলে অভিহিত করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>40. ‘ভারতের প্রথম আধুনিক মানুষ’ কাকে কেন বলা হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                রাজা রামমোহন রায় হলেন প্রথম ভারতীয়, যিনি ধর্মীয় ও সামাজিক
                কুসংস্কার ও কুপ্রথার বিরুদ্ধে জনমত গড়ে তোলেন, নারী জাতির
                উন্নতিতে সচেষ্ট হন, প্রাচ্য ও পাশ্চাত্যের মধ্যে সমন্বয় সাধন
                করেন, সংবাদপত্রের স্বাধীনতার জন্য নিয়মতান্ত্রিক পথে আন্দোলনের
                সূচনা করেন এবং সর্বোপরি পথ প্রদর্শকরূপে সামাজিক অগ্রগতির পথে
                যাবতীয় বাধাগুলিকে দূর করতে সচেষ্ট হন। তাই তাঁকে ‘ভারতের প্রথম
                আধুনিক মানুষ' বলা হয়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

সাম্রাজ্যবাদেরবিরুদ্ধেপ্রতিক্রিয়া.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(সাম্রাজ্যবাদেরবিরুদ্ধেপ্রতিক্রিয়া);
