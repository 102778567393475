import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function PreviousyearQuestionpaper2019() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          Previous year Question paper 2019 | West Bengal Class 12 Guide | West
          Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="Previous year Question paper 2019 | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Previous year Question paper 2019
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  অগ্ন্যুৎপাতের সময় ম্যাগমার সঙ্গে যে উত্তপ্ত জল বেরিয়ে আসে,
                  তাকে বলে —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সহজাত জল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  উৎস্যন্দ জল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভাদোস জল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আবহিক জল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ইনসেলবার্জ যে অঞ্চলে দেখা যায়, সেটি হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  কালাহারি মরুভূমি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গাঙ্গেয় সমভূমি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডেকান ট্র্যাপ অঞ্চল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আমাজন নদীর অববাহিকা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নিরক্ষীয় জলবায়ু অঞ্চলের একটি বৈশিষ্ট্য হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শুষ্ক গ্রীষ্মকাল এবং আর্দ্র শীতকাল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বার্ষিক উষ্ণতার প্রসর বেশি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শীতকালে তুষারপাত হয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অপরাহ্নে পরিচলন বৃষ্টিপাত হয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> হিউমাসসমৃদ্ধ মাটির রং হয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লালচে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  কালো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ধূসর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাদাটে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মৌসুমি বায়ু হল এক প্রকারের —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্থানীয় বায়ু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সাময়িক বায়ু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নিয়ত বায়ু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনিয়মিত বায়ু
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> অভিকর্ষজ বলের সাথে যুক্ত একটি দুর্যোগ হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভূমিকম্প
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বন্যা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হিমানী সম্প্রপাত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দাবানল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মিলেট যে কৃষিপ্রণালীর প্রধান ফসল, তা হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আৰ্দ্ৰ কৃষি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  শুষ্ক কৃষি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্থানান্তর কৃষি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বাণিজ্যিক কৃষি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ‘শিল্প স্থানিকতার ন্যূনতম ব্যয় তত্ত্ব’-টির প্রবর্তক হলেন —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অগাস্ট লশ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আলফ্রেড ওয়েবার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জর্জ রেনার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>ই
                  ডব্লিউ জিমারম্যান
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ভারতের নিম্নলিখিত কোন্ শহরগুলি ‘সোনালি চতুর্ভুজ’ দ্বারা যুক্ত?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দিল্লি-মুম্বাই-কলকাতা-বেঙ্গালুরু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দিল্লি-মুম্বাই-হায়দ্রাবাদ-কলকাতা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  দিল্লি-মুম্বাই-চেন্নাই-কলকাতা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পোরবন্দর-দিল্লি-কলকাতা-চেন্নাই
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  প্রায় যোগাযোগহীন বিচ্ছিন্ন ও ক্ষুদ্র গ্রামীণ বসতিকে বলে —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হ্যামলেট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গ্রাম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মৌজা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শহর
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#1f7405" size="5">
                <b>H.S QUESTION PAPER 2019</b>
              </font>
              <br />
              <font color="#3c92e5">
                <font color="#ed9c23" size="5">
                  <b>Geography</b>
                </font>
                <br />
                <font color="#ed9c23">(New Syllabus)</font>
                <br />
                <font color="#f11eda">
                  Total Time : 3 Hours 15 minutes
                  <br />
                  Total Marks : 70
                </font>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#1f7405" size="5">
                <b>বিভাগ - খ (Marks : 35)</b>
              </font>
              <br />
              <br />
              <font color="#f11eda">
                <b>
                  1. প্রতিটি প্রশ্নের বিকল্প উত্তরগুলির মধ্যে থেকে সঠিক উত্তরটি
                  বেছে নিয়ে ডানদিকে নীচে প্রদত্ত বাক্সে লেখো :{" "}
                  <font color="#1f7405">
                    <b>1 × 21 = 21</b>
                  </font>
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                <font color="#f11eda">
                  <b>
                    (i) অগ্ন্যুৎপাতের সময় ম্যাগমার সঙ্গে যে উত্তপ্ত জল বেরিয়ে
                    আসে, তাকে বলে —
                  </b>
                </font>
                <br />
                (a) সহজাত জল
                <br />
                <font color="#1f7405">
                  <b>(b) উৎস্যন্দ জল</b>
                </font>
                <br />
                (c) ভাদোস জল
                <br />
                (d) আবহিক জল
                <br />
                <br />
                <font color="#f11eda">
                  <b>(ii) ইনসেলবার্জ যে অঞ্চলে দেখা যায়, সেটি হল —</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) কালাহারি মরুভূমি</b>
                </font>
                <br />
                (b) গাঙ্গেয় সমভূমি
                <br />
                (c) ডেকান ট্র্যাপ অঞ্চল
                <br />
                (d) আমাজন নদীর অববাহিকা
                <br />
                <br />
                <font color="#f11eda">
                  <b>(iii) নিরক্ষীয় জলবায়ু অঞ্চলের একটি বৈশিষ্ট্য হল —</b>
                </font>
                <br />
                (a) শুষ্ক গ্রীষ্মকাল এবং আর্দ্র শীতকাল
                <br />
                (b) বার্ষিক উন্নতার প্রসর বেশি
                <br />
                (c) শীতকালে তুষারপাত হয়
                <br />
                <font color="#1f7405">
                  <b>(d) অপরাহ্নে পরিচলন বৃষ্টিপাত হয়</b>
                </font>
                <br />
                <br />
                <font color="#f11eda">
                  <b>(iv) হিউমাসসমৃদ্ধ মাটির রং হয় —</b>
                </font>
                <br />
                (a) লালচে
                <br />
                <font color="#1f7405">
                  <b>(b) কালো</b>
                </font>
                <br />
                (c) ধূসর
                <br />
                (d) সাদাটে
                <br />
                <br />
                <font color="#f11eda">
                  <b>(v) মৌসুমি বায়ু হল এক প্রকারের —</b>
                </font>
                <br />
                (a) স্থানীয় বায়ু
                <br />
                <font color="#1f7405">
                  <b>(b) সাময়িক বায়ু</b>
                </font>
                <br />
                (c) নিয়ত বায়ু
                <br />
                (d) অনিয়মিত বায়ু
                <br />
                <br />
                <font color="#f11eda">
                  <b>(vi) অভিকর্ষজ বলের সাথে যুক্ত একটি দুর্যোগ হল —</b>
                </font>
                <br />
                (a) ভূমিকম্প
                <br />
                (b) বন্যা
                <br />
                <font color="#1f7405">
                  <b>(c) হিমানী সম্প্রপাত</b>
                </font>
                <br />
                (d) দাবানল
                <br />
                <br />
                <font color="#f11eda">
                  <b>(vii) মিলেট যে কৃষিপ্রণালীর প্রধান ফসল, তা হল —</b>
                </font>
                <br />
                (a) আৰ্দ্ৰ কৃষি
                <br />
                <font color="#1f7405">
                  <b>(b) শুষ্ক কৃষি</b>
                </font>
                <br />
                (c) স্থানান্তর কৃষি
                <br />
                (d) বাণিজ্যিক কৃষি
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (viii) ‘শিল্প স্থানিকতার ন্যূনতম ব্যয় তত্ত্ব’-টির প্রবর্তক
                    হলেন —
                  </b>
                </font>
                <br />
                (a) অগাস্ট লশ
                <br />
                <font color="#1f7405">
                  <b>(b) আলফ্রেড ওয়েবার</b>
                </font>
                <br />
                (c) জর্জ রেনার
                <br />
                (d) ই ডব্লিউ জিমারম্যান
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (ix) ভারতের নিম্নলিখিত কোন্ শহরগুলি ‘সোনালি চতুর্ভুজ’ দ্বারা
                    যুক্ত?
                  </b>
                </font>
                <br />
                (a) দিল্লি-মুম্বাই-কলকাতা-বেঙ্গালুরু
                <br />
                (b) দিল্লি-মুম্বাই-হায়দ্রাবাদ-কলকাতা
                <br />
                <font color="#1f7405">
                  <b>(c) দিল্লি-মুম্বাই-চেন্নাই-কলকাতা</b>
                </font>
                <br />
                (d) পোরবন্দর-দিল্লি-কলকাতা-চেন্নাই
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (x) প্রায় যোগাযোগহীন বিচ্ছিন্ন ও ক্ষুদ্র গ্রামীণ বসতিকে বলে
                    —
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) হ্যামলেট</b>
                </font>
                <br />
                (b) গ্রাম
                <br />
                (c) মৌজা
                <br />
                (d) শহর
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f11eda">
                <b>(xi) ছত্তিশগড়ের প্রধান লৌহআকরিক উৎপাদনকারী অঞ্চল হল —</b>
              </font>
              <br />
              <font color="#3c92e5">
                (a) গোরুমহিষানি
                <br />
                <font color="#1f7405">
                  <b>(b) দাল্লিরাজহারা</b>
                </font>
                <br />
                (c) বাবাবুদান পাহাড়
                <br />
                (d) কোরবা
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xii) ‘গ্রেড’ শব্দটি প্রথম ব্যবহার করেন —</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) জি কে গিলবার্ট</b>
                </font>
                <br />
                (b) ডব্লিউ ডি থর্নবেরি
                <br />
                (c) আর্থার হোমস্
                <br />
                (d) ডব্লিউ এম ডেভিস
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xiii) ‘গ্রেট ব্যারিয়ার রিফ’ দেখা যায় —</b>
                </font>
                <br />
                (a) মার্কিন যুক্তরাষ্ট্রের উপকূলের কাছে
                <br />
                (b) এশিয়ার উপকূলের কাছে
                <br />
                <font color="#1f7405">
                  <b>(c) অস্ট্রেলিয়ার উপকূলের কাছে</b>
                </font>
                <br />
                (d) দক্ষিণ আমেরিকার উপকূলের কাছে
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xiv) অগ্ন্যুৎপাতজাত পদার্থ দ্বারা গঠিত মৃত্তিকা হল —</b>
                </font>
                <br />
                (a) অক্সিসল
                <br />
                (b) আল্টিসল
                <br />
                (c) অ্যালফিসল
                <br />
                <font color="#1f7405">
                  <b>(d) অ্যান্ডিসল</b>
                </font>
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (xv) গম্বুজাকৃতি পাহাড়ে যে ধরনের জলনির্গম প্রণালী গড়ে ওঠে,
                    তা হল —
                  </b>
                </font>
                <br />
                (a) সমান্তরাল জলনির্গম প্ৰণালী
                <br />
                (b) হেরিংবোন জলনির্গম প্ৰণালী
                <br />
                <font color="#1f7405">
                  <b>(c) কেন্দ্রবিমুখ জলনির্গম প্ৰণালী</b>
                </font>
                <br />
                (d) পিনেট জলনিৰ্গম প্রণালী
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (xvi) যে জলবায়ু অঞ্চলে জীববৈচিত্র্য সর্বাধিক, তার নাম —
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) ক্রান্তীয় বৃষ্টি অরণ্য জলবায়ু অঞ্চল</b>
                </font>
                <br />
                (b) মৌসুমি জলবায়ু অঞ্চল
                <br />
                (c) ভূমধ্যসাগরীয় জলবায়ু অঞ্চল
                <br />
                (d) তুন্দ্রা জলবায়ু অঞ্চল
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xvii) একটি মুক্ত ভাসমান জলজ উদ্ভিদের নাম হল —</b>
                </font>
                <br />
                (a) হোগলা
                <br />
                (b) পদ্ম
                <br />
                (c) শালুক
                <br />
                <font color="#1f7405">
                  <b>(d) কচুরিপানা</b>
                </font>
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xviii) ভারতের ‘শ্বেত বিপ্লব’-এর জনক হলেন —</b>
                </font>
                <br />
                (a) নরম্যান বোরলগ
                <br />
                (b) রোনাল্ড রস
                <br />
                <font color="#1f7405">
                  <b>(d) ড. ভার্গিস কুরিয়েন</b>
                </font>
                <br />
                (c) এম এস স্বামীনাথন
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xix) ভারতের প্রথম পাটকল স্থাপিত হয় —</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) রিষড়ায়</b>
                </font>
                <br />
                (b) ব্যান্ডেলে
                <br />
                (c) শ্রীরামপুরে
                <br />
                (d) টিটাগড়ে
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (xx) ‘পেশাদার পরামর্শদান’ যে অর্থনৈতিক ক্রিয়াকলাপের উদাহরণ,
                    তা হল —
                  </b>
                </font>
                <br />
                (a) টার্সিয়ারি ক্রিয়াকলাপ
                <br />
                (b) কোয়াটারনারি ক্রিয়াকলাপ
                <br />
                <font color="#1f7405">
                  <b>(c) কুইনারি ক্রিয়াকলাপ</b>
                </font>
                <br />
                (d) এগুলির কোনোটিই নয়
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (xxi) 2011 সালের আদমশুমারি অনুসারে ভারতের নারী ও পুরুষের
                    অনুপাত হল —
                  </b>
                </font>
                <br />
                (a) 925: 1000
                <br />
                (b) 930 : 1000
                <br />
                (c) 935: 1000
                <br />
                <font color="#1f7405">
                  <b>(d) 940: 1000</b>
                </font>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f11eda">
                <b>
                  2. নিম্নলিখিত প্রশ্নগুলির উত্তর দাও (বিকল্প প্রশ্নগুলি
                  লক্ষণীয়):{" "}
                  <font color="#1f7405">
                    <b>1 × 14 = 14</b>
                  </font>
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                (i) জনবিস্ফোরণ কাকে বলে?
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                শুষ্কবিন্দু বসতি কাকে বলে?
                <br />
                <br />
                (ii) শিল্প কাকে বলে?
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                দুর্গাপুরকে ‘ভারতের রুর’ বলা হয় কেন?
                <br />
                <br />
                (iii) কেরলের সাম্প্রতিক বন্যার দুটি মুখ্য কারণ নির্দেশ করো।
                <br />
                <br />
                (iv) মৃত্তিকার গ্রথন বলতে কী বোঝায়?
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                শারীরবৃত্তীয় শুষ্ক মৃত্তিকা বলতে কী বোঝায়?
                <br />
                <br />
                (v) কী ধরনের প্রাকৃতিক পরিবেশ হ্যালোফাইট জাতীয় উদ্ভিদ জন্মানোর
                পক্ষে উপযোগী?
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                ‘বিশ্ব-উম্নায়ন’-এর দুটি প্রভাব উল্লেখ করো।
                <br />
                <br />
                (vi) গতিময় পুনর্যৌবন লাভ বলতে কী বোঝায়?
                <br />
                <br />
                (vii) মরু অঞ্চলে ভৌমজলের পরিমাণ কম হওয়ার কারণ কী?
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                অ্যাকুইফার কাকে বলে?
                <br />
                <br />
                (viii) ‘উন্নয়ন’-এর সংজ্ঞা দাও।
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                বেঙ্গালুরুকে ‘ভারতের বৈদ্যুতিন শহর’ বলা হয় কেন?
                <br />
                <br />
                (ix) আর্দ্র কৃষি কাকে বলে?
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                ‘শস্যপ্রগাঢ়তা’র সংজ্ঞা দাও।
                <br />
                <br />
                (x) তৃতীয় স্তরের অর্থনৈতিক ক্রিয়াকলাপের সংজ্ঞা দাও।
                <br />
                (xi) বহিক্ষেত্রীয় সংরক্ষণ কাকে বলে?
                <br />
                (xii) জেট বায়ুপ্রবাহ কী?
                <br />
                (xiii) পূর্ববর্তী নদীর সংজ্ঞা দাও।
                <br />
                (xiv) টম্বোলো কাকে বলে?
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#1f7405" size="5">
                <b>বিভাগ - ক (Marks: 35 )</b>
              </font>
              <br />
              <br />

              <font color="#f11eda">
                <b>
                  1. নিম্নলিখিত প্রশ্নগুলির উত্তর দাও (বিকল্প প্রশ্নগুলি
                  লক্ষণীয়):{" "}
                  <font color="#1f7405">
                    <b>7 × 5 = 35</b>
                  </font>
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                (a) কার্স্ট অঞ্চলে ‘ডোলাইন’ ও ‘পাতন প্রস্তর’ (dripstone) কীভাবে
                সৃষ্টি হয় তা চিত্র-সহ ব্যাখ্যা করো| ‘রিয়া উপকূল’ কাকে বলে? 5+2
                <br />
                <br />
                <font color="#1f7405" size="4">
                  <b>অথবা,</b>
                </font>
                <br />
                <br />
                মৃত্তিকা সৃষ্টিতে আদি শিলা ও জলবায়ুর ভূমিকা সংক্ষেপে আলোচনা
                করো। নদীর পুনর্যৌবন লাভের ফলে কীভাবে নদী-মঞ্জু গঠিত হয় চিত্র-সহ
                ব্যাখ্যা করো। 5+2
                <br />
                <br />
                (b) উপযুক্ত চিত্র-সহ নাতিশীতোম্ন ঘূর্ণবাতের জীবনচক্র ব্যাখ্যা
                করো| ‘জীববৈচিত্র্যের উম্মাকেন্দ্র’ কাকে বলে? 6+1
                <br />
                <br />
                <font color="#1f7405" size="4">
                  <b>অথবা,</b>
                </font>
                <br />
                <br />
                জাঙ্গল উদ্ভিদের শ্রেণিবিভাগ করো এবং পরিবেশের সাথে এদের অভিযোজন
                সম্পর্কে আলোচনা করো। দুর্যোগ ও বিপর্যয়ের মধ্যে পার্থক্য নিরূপণ
                করো। 5+2
                <br />
                <br />
                (c) ভূমধ্যসাগরীয় অঞ্চল ফল উৎপাদনে বিখ্যাত কেন? দক্ষিণ ভারতে কফি
                চাষের উন্নতির কারণগুলি কী কী? শস্যাবর্তনের সংজ্ঞা দাও। 3+3+1
                <br />
                <br />
                (d) কাঁচামালের অভাব সত্ত্বেও জাপানে লৌহ-ইস্পাত শিল্প গড়ে ওঠার
                কারণগুলি ব্যাখ্যা করো। কার্পাসবয়ন শিল্পকে ‘শিকড়-আলগা শিল্প’
                বলে কেন? পেট্রোরসায়ন শিল্প বলতে কী বোঝায়? 3+2+2
                <br />
                <br />
                (e) পৃথিবীর বিভিন্ন অঞ্চলে অসম জনবণ্টনের প্রাকৃতিক কারণগুলি
                আলোচনা করো । হলদিয়া বন্দর গড়ে ওঠার কারণগুলি বিশ্লেষণ করো। 4+3
                <br />
                <br />
                <font color="#1f7405" size="4">
                  <b>অথবা,</b>
                </font>
                <br />
                <br />
                জনঘনত্ব ও মানুষ-জমির অনুপাতের মধ্যে পার্থক্য নিরূপণ করো।
                পরিকল্পনা অঞ্চলের স্তরক্রম সম্পর্কে সংক্ষিপ্ত আলোচনা করো।
                বয়ঃলিঙ্গ অনুপাতের গুরুত্ব উল্লেখ করো। 2+3+2
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

PreviousyearQuestionpaper2019.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(PreviousyearQuestionpaper2019);
