import { createSelector } from "reselect";

/**
 * Direct selector to the homePage state domain
 */

const selectHomePageDomain = (state) =>
  (state.homePage && state.homePage.popularChannels) || [];
const selectCurrentType = (state) =>
  (state.homePage && state.homePage.currentType) || null;
const selectCuttentTopic = (state) =>
  (state.homePage && state.homePage.currentTopic) || null;

/**
 * Other specific selectors
 */

/**
 * Default selector used by HomePage
 */

const getTypesAndTopics = () =>
  createSelector(selectHomePageDomain, (allData) => {
    console.log(allData);
    let popularTypes = [];
    let popularTopics = [];
    let popularChannels = [];
    allData.forEach((popularChannel) => {
      //Type
      const typeIndex = popularTypes.findIndex(
        (m) =>
          m.typeId === popularChannel.typeId &&
          m.typeName === popularChannel.typeName
      );
      if (typeIndex === -1) {
        popularTypes.push({
          typeId: popularChannel.typeId,
          typeName: popularChannel.typeName,
        });
      }
      //Topic
      const topicIndex = popularTopics.findIndex(
        (m) =>
          m.typeId === popularChannel.typeId &&
          m.topicId === popularChannel.topicId &&
          m.topicName === popularChannel.topicName
      );
      if (topicIndex === -1) {
        popularTopics.push({
          typeId: popularChannel.typeId,
          topicId: popularChannel.topicId,
          topicName: popularChannel.topicName,
        });
      }
      popularChannels.push(popularChannel);
    });
    return {
      popularTypes: popularTypes,
      popularTopics: popularTopics,
      popularChannels: popularChannels,
    };
  });

const makeSelectHomePage = () =>
  createSelector(selectHomePageDomain, (substate) => substate);

const getCurrentChannels = () =>
  createSelector(
    selectHomePageDomain,
    selectCurrentType,
    selectCuttentTopic,
    (allData, currentType, currentTopic) => {
      const currentPopulareChannels = allData.filter(
        (m) => m.typeId === currentType && m.topicId === currentTopic
      );
      return currentPopulareChannels;
    }
  );

export default makeSelectHomePage;
export { getTypesAndTopics, getCurrentChannels };
