import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function দ্বিতীয়স্তরেরক্রিয়াকলাপশিল্প() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          দ্বিতীয় স্তরের ক্রিয়াকলাপ : শিল্প | West Bengal Class 12 Guide |
          West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="দ্বিতীয় স্তরের ক্রিয়াকলাপ : শিল্প | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                দ্বিতীয় স্তরের ক্রিয়াকলাপ : শিল্প
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b> অষ্টম অধ্যায় (৮.২) </b>
              </font>
              <br />{" "}
              <font color="#2b9a09" size="4">
                <b> দ্বিতীয় স্তরের অর্থনৈতিক ক্রিয়াকলাপ : শিল্প </b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b> প্রশ্নের মান - ৭</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                1. শিল্প গড়ে ওঠার অনুকূল পরিবেশ বা কারণ সম্বন্ধে আলােচনা করাে।
                <br />
                2. ভারতে মােটরগাড়ি নির্মাণ শিল্প গড়ে ওঠার কারণগুলি সংক্ষেপে
                ব্যাখ্যা করাে।
                <br />
                3. ইঞ্জিনিয়ারিং শিল্প সম্বন্ধে সংক্ষেপে আলােচনা করাে।
                <br />
                4. ভারতে পেট্রো-রসায়ন শিল্পের সমস্যা ও সম্ভাবনা কী?
                <br />
                5. ব্রাজিলের রাবার শিল্পের সমস্যা/অনুন্নতির কারণ লেখো।
                <br />
                6. উদীয়মান বা সূর্যোদয় শিল্প বলতে কী বােঝায়? অথবা, শিল্পদানব
                কাকে বলে? পেট্রোরসায়ন শিল্পের উন্নতির অনুকূল ভৌগােলিক কারণগুলি
                আলােচনা করাে।
                <br />
                7. পেট্রো-রাসায়নিক শিল্পের গুরুত্ব আলােচনা করাে।
                পেট্রো-রাসায়নিক শিল্পকেন্দ্রগুলি বন্দর বা তেলশােধনাগারের কাছে
                গড়ে ওঠার কারণ কী?
                <br />
                8. ভারতে লৌহ-ইস্পাত শিল্পের সমস্যাগুলি আলােচনা করাে। দুর্গাপুরকে
                'ভারতের রুর’ বলা হয় কেন?
                <br />
                9. দক্ষিণ ভারতের প্রধান ইস্পাত কারখানাটির গড়ে ওঠার কারণ
                সংক্ষেপে আলোচনা করো। পূর্ব ও মধ্য ভারতে লৌহ ওইস্পাত শিল্পের
                একদেশীভবন বা কেন্দ্রীভবনের কারণ উল্লেখ করো। <br />
                10. মালয়েশিয়ায় রবার শিল্পের কেন্দ্রীভবনের কারণগুলি উল্লেখ
                করাে।
                <br />
                11. ভারতে কাগজ শিল্প গড়ে ওঠার কারণগুলি আলােচনা করাে। ভারতের
                কোথায় কোথায় সংবাদপত্র ছাপার কাগজ বা নিউজপ্রিন্ট কারখানা গড়ে
                উঠেছে?
                <br />
                12. কানাডার কাগজশিল্পের পরিচয় দাও। কাগজের আমদানিকারক ও
                রপ্তানিকারক দেশগুলি কী কী?
                <br />
                13. উদাহরণসহ শিল্পের বিভিন্ন শ্রেণিবিভাগ উল্লেখ করাে। কাগজ শিল্প
                গড়ে ওঠার কারণগুলি সংক্ষেপে উদাহরণসহ আলােচনা করাে।
                <br />
                14. বাংলাদেশের পাট শিল্পের বিভিন্ন সমস্যা ও সম্ভাবনা আলােচনা
                করাে।
                <br />
                15. ভারতে রেডিমেড পােশাক শিল্প গড়ে ওঠার কারণগুলি সংক্ষেপে
                আলােচনা করাে। রেডিমেড পােশাকের প্রধান প্রধান রপ্তানিকারক ও
                আমদানিকারক দেশের নাম লেখাে।
                <br />
                16. কার্পাস-বয়ন শিল্প গড়ে ওঠার কারণগুলি উদাহরণসহ আলােচনা করাে।
                এই শিল্পকে 'শিকড়-আলগা শিল্প বা 'Foot-loose Industry' বলে কেন?
                <br />
                17. ভারতে খাদ্যপ্রক্রিয়াকরণ শিল্পের সমস্যাগুলি কী কী? এই
                সমস্যার সমাধানে গৃহীত পদক্ষেপগুলি আলােচনা করাে।
                <br />
                18. ভারতে খাদ্যপ্রক্রিয়াকরণ শিল্পের উন্নতির কারণগুলি কী কী?
                ভারতে এই শিল্পের গুরুত্ব আলােচনা করাে।
                <br />
                19. ওয়েবারের তত্ত্ব অনুযায়ী দ্রব্যসূচক ও কাঁচামালের সম্পর্ক
                দেখাও। শিল্পের স্থান নির্বাচনে শিল্প সমাবেশের প্রভাব ব্যক্ত করো।
                <br />
                20. শিল্প গড়ে ওঠার অনুকূল প্রাকৃতিক পরিবেশ বা কারণ সম্বন্ধে
                আলোচনা করো।
                <br />
                21. আমেরিকা যুক্তরাষ্ট্রে পেট্রো-রসায়ন শিল্পের উন্নতির কারণ
                লেখো। 18. কানাডা কাগজ শিল্পে উন্নত কেন?
              </font>
            </div>
            <br />
            <br />
            <div>,</div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>১. ভারতের দুটি দুগ্ধ প্রক্রিয়াকরণ সমবায়ের নাম লেখাে।</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতের দুটি দুগ্ধপ্রক্রিয়াকরণ সমবায়ের নাম হল- (i) আমুল ও (ii)
                সরস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২. ভারতে রেডিমেড পােশাক শিল্প গড়ে ওঠার প্রধান কারণ?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের আর্দ্র জলবায়ুতে সুতাে ছিড়ে যাওয়ার সম্ভাবনা কম থাকার
                ফলে রেডিমেড পােশাক শিল্প উন্নতি লাভ করেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৩. কার্পাস-বয়ন শিল্পের জন্য কোন ধরনের জলবায়ু আদর্শ? </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আর্দ্র প্রকৃতির জলবায়ু আদর্শ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৪. পৃথিবীতে কোন্ গাছের পাতা লেখার কাজে ব্যবহৃত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্যাপিরাস গাছের পাতা <br />
                <br />
                <font color="#f626d7">
                  <b>৫. ল্যাটেক্স কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                রবার গাছের তরল জৈবসকে ল্যাটেক্স বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৬. ভারতের কোথায় টাকা ছাপানাের কাগজ উৎপাদিত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের ভূপালের হােসেঙ্গাবাদে <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৭. কোন্ সময়ে ভারতে কার্পাস-বয়ন শিল্পের প্রকৃত সূচনা
                    হয়েছিল?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮৫১ সালে
                <br />
                <br />
                <font color="#f626d7">
                  <b>৮. তথ্যপ্রযুক্তি শিল্পে ভারতের প্রধান কেন্দ্র কোনটি?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বেঙ্গালুরু।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৯. অগাস্ট লােশ-এর সর্বাধিক মুনাফা তত্বের মূল কথা কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                অগাস্ট লােশ-এর সর্বাধিক মুনাফা তত্বের মূল কথা হল শিল্পটি সেখানেই
                গড়ে উঠবে যেখানে মুনাফা সর্বাধিক হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১০. “আইসােটিম” কথার অর্থ কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আইসােটিম কথার অর্থ হল সমপরিবহণ ব্যয় রেখা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ১১. ওজন হ্রাসশীল কাঁচামাল ব্যবহারকারী শিল্পসমূহ কোথায় গড়ে
                    ওঠে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ওজন হ্রাসশীল কাঁচামাল ব্যবহারকারী শিল্পসমূহ কাঁচামালের
                প্রাপ্তিস্থানের নিকট গড়ে ওঠে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১২. আইসােডাপেন কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কাঁচামালের 'আইসােটিম এবং উৎপাদিত পণ্যের আইসােটিম যেখানে পরস্পর
                ছেদ করে, সেই ছেদ বিন্দুগুলিকে যুক্ত করে যে ডিম্বাকৃতি রেখা
                কল্পনা করা হয় তাকে আইসােডাপেন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ১৩. ওয়েবারের মতানুসারে কোন্ জায়গা শিল্প গড়ে তােলার পক্ষে
                    আদর্শ?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ওয়েবারের মতানুসারে যে জায়গায় আইসােডাপেনের মান সবচেয়ে কম সেই
                জায়গাটি শিল্প গড়ে তােলার পক্ষে আদর্শ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১৪. কে আইসােডাপেনের ধারণা দিয়েছেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আলফ্রেড ওয়েবার <br />
                <br />
                <font color="#f626d7">
                  <b>১৫. কানাডার কাগজ শিল্প গড়ে ওঠার প্রধান কারণ কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কানাডার উত্তরে অবস্থিত পৃথিবীর সবচেয়ে বড়াে তৈগা বা সরলবর্গীয়
                বনভূমি কাগজ শিল্পের বিকাশে সাহায্য করেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ১৬. ভারতের প্রথম পেট্রোরাসায়নিক কারখানাটি কোথায় গড়ে
                    উঠেছে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মহারাষ্ট্রের ট্রম্বেতে ভারতের প্রথম পেট্রো রাসায়নিক কারখানাটি
                অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১৭. Geotextile কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যে পাটজাত দ্রব্য ভূমিক্ষয় বা মৃত্তিকাক্ষয় রােধের কাজে ব্যবহৃত
                হয় তাকে Geotextile বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ১৮. ‘জিওটেক’ ধরনের পাট প্রধানত কোন্ কাজে ব্যবহার করা হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জিওটেক' ধরনের পাট ভূমিক্ষয় বা মৃত্তিকাক্ষয় নিয়ন্ত্রণে ব্যবহার
                করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১৯. চিনের ম্যাঞ্চেস্টার কাকে বলা হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                সাংহাইকে চিনের ম্যাঞ্চেস্টার বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২০. রাশিয়ার ম্যাঞ্চেস্টার কাকে বলা হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ইভানােভাকে রাশিয়ার ম্যাঞ্চেস্টার বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>২১. জাপানের ম্যাস্টোর কাকে বলে?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ওসাকা শহরকে জাপানের ম্যাঞ্চেস্টার বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ২২. মার্কিন যুক্তরাষ্ট্রের নিউ ইংল্যান্ড কী জন্য বিখ্যাত?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কার্পাস-বয়ন শিল্পের অন্যতম গুরুত্বপূর্ণ কেন্দ্র হিসেবে মার্কিন
                যুক্তরাষ্ট্রের নিউ ইংল্যান্ড বিখ্যাত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ২৩. পূর্ব ভারতের একটি বন্দরকেন্দ্রিক পেট্রোরাসায়নিক
                    শিল্পকেন্দ্রের নাম লেখাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পূর্ব ভারতের একটি বন্দরকেন্দ্রিক পেট্রো রাসায়নিক শিল্পকেন্দ্র
                হল হলদিয়া।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ২৪. ভারতের প্রথম বৃহদায়তন ইস্পাত কারখানাটি কোথায় অবস্থিত?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের প্রথম বৃহদায়তন ইস্পাত কারখানাটি তামিলনাড়ুর আর্কট জেলার
                পাের্টোলােভাতে অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২৫. পেট্রোরসায়ন শিল্পের প্রধান কাঁচামাল কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পেট্রোলিয়াম হল পেট্রোরসায়ন শিল্পের প্রধান কাঁচামাল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ২৬. উত্তর ভারতের ম্যাঞ্চেস্টার বলে কোন শহরকে অভিহিত করা হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কানপুরকে উত্তর ভারতের ম্যাস্টোর বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২৭. কার্পাস বস্ত্র উৎপাদনে পৃথিবীতে কোন্ দেশ প্রথম?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কার্পাস বস্ত্র উৎপাদনে ভারত পৃথিবীতে প্রথম স্থানাধিকারী।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ২৮. আমেরিকা যুক্তরাষ্ট্রের একটি প্রধান পেট্রো-রসায়ন
                    কেন্দ্রের নাম লেখাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আমেরিকা যুক্তরাষ্ট্রের উইলমিংটন হল একটি প্রধান পেট্রোরসায়ন
                কেন্দ্র।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২৯. পৃথিবীর বৃহত্তম নিউজপ্রিন্ট কারখানার নাম কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আবিটিবি বােওয়াটার ইনক হল পৃথিবীর বৃহত্তম নিউজপ্রিন্ট কারখানা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৩০. ভারতের বৃহত্তম মােটরগাড়ি নির্মাণ কোম্পানির নাম কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের বৃহত্তম মােটরগাড়ি নির্মাণ কোম্পানি হল মারুতি উদ্যোগ
                লিমিটেড।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৩১. ভারতের যে-কোনাে একটি স্কুটার নির্মাণ শিল্পকেন্দ্রের নাম
                    উল্লেখ করাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                টিভিএস মােটর কোম্পানি (তামিলনাড়ুর হােসুর) ভারতের একটি স্কুটার
                নির্মাণ শিল্পকেন্দ্র।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৩২. আমেরিকা যুক্তরাষ্ট্রের বৃহত্তম মােটরগাড়ি নির্মাণ
                    সংস্থাটির নাম কী?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আমেরিকা যুক্তরাষ্ট্রের বৃহত্তম মােটরগাড়ি নির্মাণ সংস্থাটির নাম
                হল জেনারেল মােটরস কর্পোরশেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৩৩. দৈতারি ইস্পাত কারখানাটি কোথায় অবস্থিত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের ওডিশায় দৈতারি ইস্পাত কারখানা অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৩৪. ক্রিটিক্যাল আইসােডাপেন কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                শ্রমিকের নূন্যতম মজুরি-ব্যয় ও মােট পরিবহণ ব্যয় নির্দেশক
                কাল্পনিক রেখা হল ক্রিটিক্যাল আইসােডাপেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৩৫. ভারতের পাট শিল্পের সমস্যা দূরীকরণে গৃহীত একটি পদক্ষেপ
                    উল্লেখ করাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের পাট শিল্পের সমস্যার সমাধান পাটজাত পণ্যের প্রদর্শনী, মেলা
                ও বিক্রির আয়ােজন করার ব্যবস্থা গৃহীত হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৩৬. শিল্পাঞ্চল বলতে কী বােঝায়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                শিল্পাঞ্চল বলতে কোনাে ভৌগােলিক এলাকায় একদেশিতার কারণে গড়ে ওঠা
                সহযােগী ও প্রতিদ্বন্দ্বী শিল্পগুলির একত্রিত সমাবেশকে বােঝায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৩৭. খাদ্য উৎপাদনে পৃথিবীতে ভারতের স্থান কততম?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                খাদ্য উৎপাদনে পৃথিবীতে ভারতের স্থান দ্বিতীয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৩৮. ভারতের ভিলাই লৌহ-ইস্পাত কারখানাটি কোন্ দেশের কারিগরি
                    সহায়তায় গড়ে উঠেছে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের ভিলাই লৌহ-ইস্পাত কারখানাটি পূর্বতন সােভিয়েত ইউনিয়নের
                কারিগরি সহায়তায় গড়ে উঠেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৩৯. কোন নদীর তীরে রাউরকেলা ইস্পাত শিল্পকেন্দ্রটি গড়ে উঠেছে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ব্রাহ্মণী নদীর তীরে রাউরকেলা ইস্পাত শিল্পকেন্দ্রটি গড়ে উঠেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৪০. বার্নপুর-কুলটি লৌহ-ইস্পাত শিল্পকেন্দ্রটি কোন নদীর তীরে
                    অবস্থিত?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বার্নপুর-কুলটি লৌহ-ইস্পাত শিল্পকেন্দ্রটি দামােদর নদীর তীরে
                অবস্থিত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>৪১. ভারতের ইস্পাত নগরী কাকে বলা হয়?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                জামসেদপুরকে ভারতের ইস্পাত নগরী বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৪২. ছত্তিশগড়ে অবস্থিত ভারতের অন্যতম লৌহখনির নাম কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ছত্তিশগড়ে অবস্থিত ভারতের অন্যতম লৌহখনির নাম বায়লাডিলা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৪৩. ভারতের প্রথম স্পঞ্জ লােহার কারখানাটি কোথায় স্থাপিত
                    হয়েছিল?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                অবিভক্ত অন্প্রপ্রদেশের কোট্টাগুডেমে ভারতের প্রথম স্পঞ্জ লােহার
                কারখানাটি স্থাপন করা হয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৪৪. ছত্তিশগড়ের কোথায় লৌহ-ইস্পাত কেন্দ্রটি স্থাপিত হয়েছে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ছত্তিশগড়ের ভিলাইতে লৌহ-ইস্পাত কেন্দ্রটি স্থাপিত হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৪৫. লৌহ-ইস্পাত শিল্পের কাঁচামালগুলি কী কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                লৌহ ইস্পাত শিল্পের কাঁচামালগুলি হল আকরিক লােহা, স্পঞ্জ লােহা,
                কোক কয়লা, ডলােমাইট ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৪৬. আমেরিকা যুক্তরাষ্ট্রের কোথায় পরিবহণের দোলক নীতির
                    প্রভাবে পরবর্তী পর্যায়ে ইস্পাত শিল্প কেন্দ্র গড়ে উঠেছে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ডুলুথ শহরে
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৪৭. বিশ্বের কোথায় ছাঁট লােহা-নির্ভর বাজারের অবস্থান লক্ষ
                    করা যায়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জাপানের সাকাই ও মার্কিন যুক্তরাষ্ট্রের শিকাগাে শহরে ছাঁট
                লােহা-নির্ভর বাজারের অবস্থান লক্ষ করা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৪৮. এশিয়ার বৃহত্তম ইস্পাত কেন্দ্রের নাম লেখাে।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                এশিয়ার বৃহত্তম ইস্পাত কেন্দ্রের নাম জাপানের ইয়া-ওয়াটা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৪৯. 'Tata Steel' কোন নদীর সংগমস্থলে গড়ে উঠেছে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                Tata Steel সুবর্ণরেখা ও খরকাই নদীর সংগমস্থলে গড়ে উঠেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৫০. কোন্ দুটি বৈদেশিক কোম্পানির সহায়তায় রাউরকেলা ইস্পাত
                    কারখানাটি স্থাপিত হয়েছিল?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জার্মানির কুপস ও ডেমাগ কোম্পানির সহায়তায় <br />
                <br />
                <font color="#f626d7">
                  <b>৫১. জাপানের দুটি মােটরগাড়ি কারখানার নাম লেখ।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জাপানের দুটি মােটরগাড়ি কারখানা হল টয়ােটা ও সুজুকি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫১. ভারতের দুটি মােটরগাড়ি নির্মাণ সংস্থার নাম লেখাে।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের দুটি মােটরগাড়ি নির্মাণ সংস্থা হল (i) টাটা মােটরস্
                লিমিটেড ও (ii) হিন্দুস্থান মােটরস্ লিমিটেড।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫২. অস্থানু (Foot Loose) শিল্প কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যেসকল শিল্পের বস্তুসূচক বা পণ্যসূচক 1 হয়, তাদের অস্থানু শিল্প
                বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫৩. বর্তমানে ভারতে কোন্ শিল্পকে উদীয়মান শিল্প বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বর্তমানে ভারতে পেট্রো রসায়ন শিল্পকে 'উদীয়মান শিল্প বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৫৪. দক্ষিণ-পূর্ব এশিয়ার রেডিমেড পােশাক উৎপাদক দেশগুলির নাম
                    লেখাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দক্ষিণ-পূর্ব এশিয়ার রেডিমেড পােশাক উৎপাদক দেশ হল ইন্দোনেশিয়া,
                থাইল্যান্ড ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫৫. বস্তুসূচক (Material Index) কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কাঁচামালের ওজন ও উৎপাদিত পণ্যের ওজনের অনুপাতকে বস্তুসূচক
                (Material Index) বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫৬. গুচ্ছ শিল্প কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পেট্রো-রসায়ন শিল্পাঞ্চলে বহু শিল্পকেন্দ্র গড়ে ওঠে বলে এই
                শিল্পকে গুচ্ছ শিল্প বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৫৭. পেট্রো-রসায়ন শিল্পে উৎপাদিত রাসায়নিক দ্রব্যগুলির নাম
                    লেখাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পেট্রোরসায়ন শিল্পে উৎপাদিত রাসায়নিক দ্রব্যগুলি হল বেঞ্জিন,
                ইথিলিন, প্রােপিলিন, কার্বন ব্ল্যাক ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫৮. ভারী ইঞ্জিনিয়ারিং শিল্প কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যে ইঞ্জিনিয়ারিং শিল্পে বৃহদায়তন ও ভারী অথচ জটিল যন্ত্রপাতি
                তৈরি হয় তাকে ভারী ইঞ্জিনিয়ারিং শিল্প বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫৮. হালকা ইঞ্জিনিয়ারিং শিল্প কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যে প্রযুক্তিগত কৌশলের সাহায্যে ছােটো ছােটো অথচ জটিল, সূক্ষ্ম
                যন্ত্রপাতি ও যন্ত্রাংশ তৈরি হয় তাকে হালকা ইঞ্জিনিয়ারিং শিল্প
                বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫৯. বুনা (Buna) রবার কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                রবার গাছের রস ছাড়া কেবল মাত্র রাসায়নিক পদ্ধতিতে যে রবার
                প্রস্তুত করা হয় তাকে বুনা (Buna) রবার বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৬০. আবাদি রবারের চাষ প্রথম কোথায় শুরু হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মালয়েশিয়ায় প্রথম আবাদি রবারের চাষ শুরু হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ৬১. ভারতের একমাত্র উপকূলীয় ইস্পাত শিল্পকেন্দ্রের নাম লেখাে।
                </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতের একমাত্র উপকূলীয় ইস্পাত শিল্পকেন্দ্রের নাম বিশাখাপত্তনম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৬২. ভারতের গ্যাসভিত্তিক স্পঞ্জ লােহার কারখানাটি কোথায়
                    অবস্থিত?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের গ্যাসভিত্তিক স্পঞ্জ লােহার কারখানাটি গুজরাতের হাজিরা-তে
                অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৬৩. অনুসারী শিল্প বা ডাউনস্ট্রিম ইন্ডাস্ট্রি কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বৃহদায়তন শিল্পে উৎপাদিত দ্রব্যেকে যেসব ক্ষুদ্রায়তন শিল্প তাদের
                শিল্পের কাঁচামালরূপে ব্যবহার করে তাদের অনুসারী শিল্প বা
                ডাউনস্ট্রিম ইন্ডাস্ট্রি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৬৪. কাগজ শিল্পে ব্যবহৃত যে-কোনাে দুটি রাসায়নিক কাঁচামালের
                    নাম উল্লেখ করাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কাগজ শিল্পে ব্যবহৃত দুটি রাসায়নিক কাঁচামাল হল কস্টিক সােডা,
                অ্যালুমিনিয়াম সালফেট।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৬৫. বস্তুগত সম্পদ নির্ভর শিল্পের উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বয়ন শিল্প, ইঞ্জিনিয়ারিং শিল্প, খাদ্যপ্রক্রিয়াকরণ শিল্প
                ইত্যাদি হল বস্তুগত সম্পদ নির্ভর শিল্পের উদাহরণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৬৬. আমাজনের অরণ্য থেকে রবার সংগ্রহকারীরা কী নামে পরিচিত?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আমাজনের অরণ্য থেকে রবার সংগ্রহকারীরা সেরিংগুয়েরাে নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৬৭. কৃত্রিম রবারে প্রধান কাঁচামালগুলি কী কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কৃত্রিম রবারে প্রধান কাঁচামালগুলি কয়লা, খনিজ তেল এবং অ্যালকোহল
                বা সুরাসার ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৬৮. বিলাসপুর জেলার মৈকাল পাহাড়ে কোন্ খনিজ পদার্থ পাওয়া
                    যায়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বিলাসপুর জেলার মৈকাল পাহাড়ে বক্সাইট পাওয়া যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৬৯. ভারতের কোথায় বস্ত্রবয়ন শিল্পের গবেষণাগার স্থাপিত
                    হয়েছে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের মুম্বাইতে (টেক্সটাইল রিসার্চ অ্যাসােসিয়েশন) বস্ত্রবয়ন
                শিল্পের গবেষণাগার স্থাপিত হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৭০. কানাডা কোন্ শিল্পের জন্য বিখ্যাত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কানাডা কাগজ শিল্পের জন্য বিখ্যাত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৭১. কানাডার কোথায় কাগজ শিল্পকেন্দ্র স্থাপিত হয়েছে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কানাডার অন্টারিও কুইবেক অঞ্চলে কাগজ শিল্পকেন্দ্র স্থাপিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৭২. ভারতে কাগজ উৎপাদনে কোন্ রাজ্য প্রথম?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতে কাগজ উৎপাদনে মহারাষ্ট্র প্রথম স্থান অধিকার করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৭৩. প্রাথমিক উৎপাদন কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রত্যক্ষ বা পরােক্ষভাবে প্রকৃতি থেকে যেসব দ্রব্য সংগ্রহ করা হয়
                সেইসব দ্রব্যগুলিকে প্রাথমিক উৎপাদন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৭৪. শিল্প কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রাকৃতিক উৎপাদন থেকে প্রাপ্ত দ্রব্যগুলিকে যে প্রক্রিয়ার
                মাধ্যমে রূপান্তরীকরণ করে ব্যবহারের উপযােগী করা হয় বা উপযােগিতা
                বাড়ানাে হয়, সেই প্রক্রিয়াকে শিল্প বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৭৫. শিল্পকে দ্বিতীয় পর্যায়ের কার্যাবলি বলার কারণ কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রাথমিক কার্যাবলি থেকে প্রাপ্ত কাঁচামালকে রূপান্তরের মাধ্যমে
                শিল্প গড়ে ওঠে বলে একে দ্বিতীয় পর্যায়ের কার্যাবলি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৭৬. ফ্রেট কস্ট বলতে কী বােঝায়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পণ্যসামগ্রির যে পরিবহণ ব্যয় তাকে ফ্রেট কস্ট বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৭৭. অবিশুদ্ধ বা অশুদ্ধ বা ওজন হ্রাসমান কাঁচামাল কাকে বলে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যেসব কাঁচামালকে শিল্পজাত দ্রব্যে পরিণত করলে ওজন কমে যায় তাদের
                অবিশুদ্ধ বা অশুদ্ধ বা ওজন হ্রাসমান কাঁচামাল বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৭৮. বিশুদ্ধ কাঁচামাল কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যেসব কাঁচামাল শিল্পজাত পণ্যে রূপান্তরিত হলেও তাদের ওজন কমে না,
                সেগুলিকে বিশুদ্ধ কাঁচামাল বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৭৯. একটি বিশুদ্ধ কাঁচামালভিত্তিক শিল্পের উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                একটি বিশুদ্ধ কঁচামালভিত্তিক শিল্পের উদাহরণ হল কার্পাস-বয়ন
                শিল্প।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৮০. দুটি বনজভিত্তিক শিল্পের নাম লেখাে।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দুটি বনজভিত্তিক শিল্প হল- (i) কাগজ ও (ii) প্লাইউড শিল্প।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ৮১. ভারতের দুটি গুরুত্বপূর্ণ পেট্রো-রসায়নিক শিল্পকেন্দ্রের
                  নাম লেখাে।
                </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতের দুটি গুরুত্বপূর্ণ পেট্রো-রসায়নিক শিল্পকেন্দ্রের নাম হল-
                (i) ভাদোদরা ও (ii) জামনগর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৮২. ভারতে সর্বপ্রথম কত সালে কোথায় প্রতিরক্ষা যান নির্মিত
                    হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতে সর্বপ্রথম 1969 সালে মধ্যপ্রদেশের জবলপুরে প্রতিরক্ষা যান
                নির্মিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৮৩. বিশ্বের মােটরগাড়ি নির্মাণের শহর’ বলে পরিচিত কোন্ শহর?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আমেরিকা যুক্তরাষ্ট্রের ডেট্রয়েট 'বিশ্বের মোটরগাড়ি নির্মাণের
                শহর’ বলে পরিচিতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৮৪. বন্দর নির্ভর অধাতব শিল্প কোনটি?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বন্দর নির্ভর অধাতব শিল্প হল পেট্রো রসায়ন শিল্প।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৮৫. SAIL কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                SAIL (Steel Authority of India Limited) হল একটি রাষ্ট্রায়ত্ত
                সংস্থা যা ভারতে সরকারি উদ্যোগে গড়ে তােলা লৌহ ইস্পাত
                কারখানাগুলির পরিচালনা করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৮৬. SAIL-এর সদর দপ্তর কোথায় অবস্থিত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                SAIL-এর সদর দপ্তর কলকাতায় অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৮৭. শীতপ্রধান অঞ্চলে সাধারণত কোন্ শিল্পের সর্বাধিক উন্নতি
                    লাভের সম্ভাবনা থাকে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                শীতপ্রধান অঞ্চলে সাধারণত পশম শিল্পের সর্বাধিক উন্নতি লাভের
                সম্ভাবনা থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৮৮. ব্রেক অফ বাল্ক পয়েন্ট কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভূমিরূপের বন্ধুরতা ও পরিবহণের বিভিন্নতার কারণে যাত্রাপথের কোথাও
                কোথাও বাধ্যতামূলকভাবে বিরতি নিতে হয়। ওই স্থানগুলিকে ব্রেক অফ
                বাল্ক পয়েন্ট বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৮৯. সর্বত্রলভ্য কাঁচামাল বলতে কী বােঝ?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যেসব কাঁচামাল পৃথিবীর সব জায়গায় পাওয়া যায়। সেগুলিকে
                সর্বত্রলভ্য কাঁচামাল বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৯০. কেন্দ্রীভূত বা সীমাবদ্ধ কাঁচামাল কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যেসব কাঁচামাল বিশেষ বিশেষ জায়গায় কেন্দ্রীভূত অবস্থায় পাওয়া
                যায় তাদের কেন্দ্রীভূত বা সীমাবদ্ধ কাঁচামাল বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৯১. হেরিটেজ শিল্প কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পর্যটনশিল্পকে উৎসাহিত করার জন্য প্রাচীন নির্মাণগুলিকে
                (যেমন—স্থাপত্য, সৌধ ইত্যাদি) সংরক্ষণের মধ্যে দিয়ে যে শিল্প গড়ে
                ওঠে তাকে হেরিটেজ শিল্প বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৯২. ওয়েবার তাঁর তত্ত্বে কোন্ স্থানটিকে ন্যূনতম ব্যয়সূচক
                    স্থান হিসেবে চিহ্নিত করেছেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যে স্থানে কাঁচামাল, শ্রমিক ও পরিবহণজনিত ব্যয় সর্বাপেক্ষা কম,
                সেই স্থানটিকে ওয়েবার ন্যূনতম ব্যয়সূচক স্থান হিসেবে চিহ্নিত
                করেছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৯৩. শিল্প স্থাপনের ক্ষেত্রে চাহিদা শঙ্কু (Demand Cone) র
                    ধারণা কে প্রদান করেছেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                শিল্প স্থাপনের ক্ষেত্রে চাহিদা শঙ্কুর ধারণা দিয়েছেন অগাস্ট
                লােশ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৯৪. আলফ্রেড ওয়েবার কোন্ দেশের অর্থনীতিবিদ ছিলেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আলফ্রেড ওয়েবার জার্মান অর্থনীতিবিদ ছিলেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৯৫. ওয়েবার তার ন্যূনতম ব্যয় তত্ত্বের অনুমানে পরিবহণ ব্যয়
                    ও পণ্যের কোন ধরনের সম্পর্কের কথা উল্লেখ করেছেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ওয়েবার তার ন্যূনতম ব্যয় তত্ত্বের অনুমানে বলেছেন পরিবহণ ব্যয়
                পণ্যের ওজন ও পণ্য স্থানান্তরের দূরত্বের ওপর নির্ভরশীল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৯৬. শিল্পের একত্র সমাবেশের ফলে উৎপাদন ব্যয়-এর কী পরিবর্তন
                    দেখা যায়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                শিল্পের একত্র সমাবেশের ফলে উৎপাদন ব্যয় অনেকটা হ্রাস পায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ৯৭. ওয়েবার তাঁর তত্ত্বের অনুমানসমূহে কোন্ ধরনের বাজারের কথা
                    উল্লেখ করেছেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ওয়েবার তাঁর তত্ত্বে পূর্ণাঙ্গ প্রতিযােগিতার বাজারের কথা উল্লেখ
                করেছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৯৮. রুর শিল্পাঞ্চলটি কোথায় অবস্থিত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জার্মানির রাইন ও রুর নদীর সংযােগস্থলে রুর শিল্পাঞ্চলটি অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৯৯. পূর্ব ভারতের অন্যতম বৃহত্তম শিল্পাঞ্চল কোনটি?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পূর্ব ভারতের অন্যতম বৃহত্তম শিল্পাঞ্চল হল হুগলি শিল্পাঞ্চল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ১০০. টোকিও ইয়াকোহামা বা কেইহিন শিল্পাঞ্চলের প্রধান প্রধান
                    শিল্পগুলির নাম লেখাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                টোকিও ইয়াকোহামা বা কেইহিন শিল্পাঞ্চলের প্রধান প্রধান শিল্পগুলির
                নাম কার্পাস-বয়ন, লৌহ ইস্পাত, জাহাজ নির্মাণ, রাসায়নিক, ক্ষুদ্র
                ও ভারী ইঞ্জিনিয়ারিং প্রভৃতি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ১০১. শিল্পের আয়তন অনুসারে কোন কোন্ শিল্পকে মাঝারি শিল্পের
                  অন্তর্ভুক্ত করা হয়?
                </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                শিল্পের আয়তন অনুসারে প্লাইউড শিল্প, খাদ্যপ্রক্রিয়াকরণ শিল্প
                মাঝারি শিল্পের অন্তর্ভুক্ত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১০২. Mineral Water কী ধরনের শিল্পের অন্তর্গত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                Mineral Water খাদ্যপ্রক্রিয়াকরণ শিল্পের অন্তর্গত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ১০৩. আমেরিকা যুক্তরাষ্ট্রের কোথায় কোথায় খাদ্যপ্রক্রিয়াকরণ
                    শিল্প বিকাশ লাভ করেছে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আমেরিকা যুক্তরাষ্ট্রের বােস্টন, শিকাগাে, কানসাস সিটি, বাফেলাে
                এবং হ্যালিফ্যাক্স-এ খাদ্যপ্রক্রিয়াকরণ শিল্প বিকাশ লাভ করেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    ১০৪. আমেরিকা যুক্তরাষ্ট্রের প্রথম কার্পাস-বয়ন কলটি কোথায়
                    স্থাপিত হয়েছিল?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আমেরিকা যুক্তরাষ্ট্রের প্রথম কার্পাস বয়ন কলটি 1790 সালে নিউ
                ইংল্যান্ড অঞলে রেড আইল্যান্ড রাজ্যে স্থাপিত হয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১০৫. ভারতের একক বৃহত্তম শিল্প কোনটি?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের একক বৃহত্তম শিল্প হল কার্পাস-বয়ন শিল্প।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১০৬. একদেশীভবন কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কোনাে একটি নির্দিষ্ট এলাকায় একই ধরনের শিল্প ঘনসন্নিবিষ্টভাবে
                গড়ে উঠলে, তাকে ওই শিল্পের একদেশীভবন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১০৭. ভালকানাইজেশন পদ্ধতির আবিষ্কর্তা কে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                চার্লস গুডইয়ার হলেন ভালকানাইজেশন পদ্ধতির আবিষ্কর্তা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১০৮. ভারতের পাট শিল্পের প্রধান সমস্যা কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কাঁচামালের স্বল্পতা হল ভারতের পাট শিল্পের উন্নতির পথে অন্যতম
                প্রধান অন্তরায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b> প্রশ্নের মান - ৭</b>
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

দ্বিতীয়স্তরেরক্রিয়াকলাপশিল্প.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(দ্বিতীয়স্তরেরক্রিয়াকলাপশিল্প);
