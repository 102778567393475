import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function প্রাথমিকক্রিয়াকলাপকৃষিকাজ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          প্রাথমিক ক্রিয়াকলাপ : কৃষিকাজ | West Bengal Class 12 Guide | West
          Bengal HS Suggestion | Higher Secondary Guide | WBCHSE | WB
        </title>
        <meta
          name="description"
          content="প্রাথমিক ক্রিয়াকলাপ : কৃষিকাজ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                প্রাথমিক ক্রিয়াকলাপ : কৃষিকাজ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b>অষ্টম অধ্যায় </b>
              </font>{" "}
              <br />
              <font color="#2b9a09" size="4">
                <b>প্রাথমিক ক্রিয়াকলাপ : কৃষিকাজ </b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ৭ </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                ১. কৃষিকাজ কাকে বলে? কৃষিকাজের ওপর পরিবেশের প্রভাব আলােচনা করাে।
                <br />
                ২. আর্দ্র কৃষি কাকে বলে? এই কৃষির অবস্থান ও বৈশিষ্ট্য আলােচনা
                করাে।
                <br />
                ৩. শুষ্ক কৃষি কাকে বলে? এই কৃষির অবস্থান ও বৈশিষ্ট্য আলােচনা
                করাে।
                <br />
                ৪. শস্যাবর্তন বা চক্ৰকৃষি কী? এর বৈশিষ্ট্য এবং গুরুত্ব বা সুবিধা
                উল্লেখ করাে।
                <br />
                ৫. জে. সি. উইভারের শস্য সমন্বয় মডেলটি উল্লেখ করাে। ভারতের
                বিভিন্ন শস্য সমন্বয় অঞ্চলগুলি চিহ্নিত করাে।
                <br />
                ৬. শস্য নিবিড়তা বা শস্য প্রগাঢ়তা বলতে কী বােঝায়? শস্য
                নিবিড়তার গুরুত্ব উল্লেখ করাে। ভারতে শস্য নিবিড়তার প্রকৃতি
                বিশ্লেষণ করাে।
                <br />
                ৭. জীবনধারণ ভিত্তিক বা জীবিকাসত্তাভিত্তিক কৃষি এবং বাণিজ্যিক
                কৃষির মধ্যে পার্থক্য উল্লেখ করাে।
                <br />
                ৮. উদাহরণসহ জীবনধারণভিত্তিক কৃষির বন্টন ও বৈশিষ্ট্য আলােচনা
                করাে।
                <br />
                ৯. সবুজ বিপ্লব কী? ভারতে সবুজ বিপ্লবের জন্য গৃহীত পদক্ষেপ এবং এর
                ফলাফল আলােচনা করাে।
                <br />
                ১০. শ্বেত বিপ্লব বলতে কী বােঝায়? শ্বেত বিপ্লব বা 'অপারেশন
                ফ্লাড'-এর উদ্দেশ্যগুলি কী কী? শ্বেত বিপ্লবের বিভিন্ন পর্যায়গুলি
                উল্লেখ করাে।
                <br />
                ১১. নীল বিপ্লব কী? নীল বিপ্লবের প্রধান উদ্দেশ্যগুলি কী ছিল? নীল
                বিপ্লবের ফলে মৎস্য উৎপাদনের যে গতিপ্রকৃতি তা উল্লেখ করাে।
                <br />
                ১২. ধান চাষের অনুকূল পরিবেশ সম্বন্ধে আলােচনা করাে। ধান কী কী
                কাজে ব্যবহৃত হয়?
                <br />
                ১৩. ভারতে গম উৎপাদনের উপযােগী অনুকূল পরিবেশের বিবরণ দাও। গমের
                ব্যবহার উল্লেখ করাে।
                <br />
                ১৪. মিশরে কার্পাস চাষের অনুকূল পরিবেশ সম্পর্কে আলোচনা করো।
                <br />
                ১৫. দক্ষিণ ভারত কফি চাষে উন্নত কেন?
                <br />
                ১৬. শ্রীলঙ্কায় চা-এর চাষ সম্বন্ধে আলােচনা করাে।
                <br />
                ১৭. তুলাে চাষের অনুকূল অবস্থা বর্ণনা করাে।
                <br />
                ১৮. ব্রাজিলের আখ চাষের অনুকূল পরিবেশ লেখো। <br />
                অথবা, <br />
                ব্রাজিলের আখ চাষে উন্নতির কারণ লেখো।
                <br />
                ১৯. বাংলাদেশে পাট চাষের অনুকূল পরিবেশ সম্পর্কে আলোচনা করো।
                <br />
                ২০. তৈলবীজ বলতে কী বােঝ? ভারতে কী ধরনের পরিবেশে তৈলবীজ উৎপন্ন
                হয়? ভারতের চিনাবাদাম ও সয়াবিন উৎপাদনকারী রাজ্যের নাম উল্লেখ
                করাে।
                <br />
                ২১. বাজারভিত্তিক উদ্যান কৃষি কী? এর বণ্টন আলােচনা করাে। এই কৃষির
                প্রধান বৈশিষ্ট্যগুলি উল্লেখ করাে।
                <br />
                ২২. ভূমধ্যসাগরীয় অঞ্চলের কৃষি সম্বন্ধে আলােচনা করাে।
                ভূমধ্যসাগরীয় অঞ্চলকে 'পৃথিবীর ফলের ঝুড়ি' বলা হয় কেন?
                <br />
                ২৩. বাজারভিত্তিক উদ্যান কৃষির গুরুত্ব লেখাে। এই কৃষির গতিপ্রকৃতি
                উল্লেখ করাে। বাজারভিত্তিক উদ্যান কৃষিতে বিভিন্ন দেশে কী কী
                শাকসবিজ উৎপাদিত হয়?
                <br />
                ২৪. চিনা বাদাম (তৈলবীজ) উৎপাদনে দুটি অনুকূল পরিবেশ লেখো।
                শ্রীলঙ্কা নারকেল উৎপাদনে অগ্রণী কেন?
                <br />
                ২৫. কৃষিকাজে আধুনিক যন্ত্রপাতি ব্যবহারের প্রভাব অথবা সুবিধা ও
                অসুবিধা লেখো।
              </font>
            </div>
            <br />
            <br />
            <div>,</div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. আন্তর্জাতিক গম গবেষণা কেন্দ্র কোথায় অবস্থিত?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                আন্তর্জাতিক গম গবেষণা কেন্দ্র মেক্সিকোর রাজধানী মেক্সিকো সিটিতে
                অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. ভারতে প্রধান সয়াবিন উৎপাদনকারী রাজ্যগুলির নাম করো।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতে প্রধান সয়াবিন উৎপাদনকারী রাজ্যগুলি হল – মধ্যপ্রদেশ,
                মহারাষ্ট্র, রাজস্থান, অন্ধ্রপ্রদেশ প্রভৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. পাটের বিকল্প দুটি কৃত্রিম তন্তুর নাম লেখো।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পাটের বিকল্প দুটি কৃত্রিম তন্তু হল – রেয়ন ও নাইলন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. আঙুরের চাষ কী নামে পরিচিত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আঙুরের চাষ ভিটিকালচার নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. বহু ফসলি কৃষি বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যে কৃষিব্যবস্থায় একটি জমিতে দুটির বেশি ফসল উৎপন্ন করা হয় সেই
                কৃষিব্যবস্থাকে বহু-ফসলি কৃষি বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. শস্যাবর্তন কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                নির্দিষ্ট কোনো কৃষিক্ষেত্রে বা জমিতে ধারাবাহিকভাবে বিভিন্ন
                শস্যের পর্যায়ক্রমিক চাষকে শস্যাবর্তন বা শস্য পর্যায় বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    7. বীজ ব্যাংকের কার্যকলাপ রূপায়িত হয় কোন্ সংস্থার দ্বারা?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জাতীয় বীজ নিগম দ্বারা বীজ ব্যাংকের কার্যকলাপ রূপায়িত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. নিবিড় জীবিকাসত্তাভিত্তিক কৃষি কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যে জীবিকাসত্তাভিত্তিক কৃষিব্যবস্থায় ছোটো জমিতে অধিক শ্রমের
                প্রয়োগ করে শস্য উৎপাদন করা হয় তাকে নিবিড় জীবিকাসত্তাভিত্তিক
                কৃষি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. তামরাই কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                থাইল্যান্ডে স্থানান্তর কৃষিকে তামরাই বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. মিলপা কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মধ্য আমেরিকায় স্থানান্তর কৃষি মিলপা নামে পরিচিত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. সবুজবিপ্লব বলতে কী বোঝায়?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                উনিশ শতকের ছয়ের দশকের মাঝামাঝি সময়ে কৃষিক্ষেত্রে নতুন
                প্রযুক্তি ব্যবহারের ফলে শস্য উৎপাদনে যে অভাবনীয় অগ্রগতি দেখা
                যায়, তাকেই সবুজবিপ্লব বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. শ্বেতবিপ্লব বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                1970 সালে 'ন্যাশনাল ডেয়ারি ডেভলপমেন্ট বোর্ড'-এর সাহায্যে ভারতে
                দুধ উৎপাদনে যে অভাবনীয় অগ্রগতি ঘটেছিল তাকে শ্বেতবিপ্লব হিসেবে
                চিহ্নিত করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. AMUL-এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                AMUL-এর পুরো নাম হল – Anand Milk Union Limited|
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. ফল উৎপাদক কৃষি বা Orchard Farming কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যখন কোনো কৃষিজমিতে বাণিজ্যিক উদ্দেশ্যে নানা ধরনের ফল ও বীজ
                উৎপাদন করা হয়, তাকে ফল উৎপাদক কৃষি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. পোমামকালচার কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                সারাবছর পাওয়া যায় এমন ফলের চাষ বা ঋতুভিত্তিক ফলের চাষকে
                পোমামকালচার বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. আর্দ্র কৃষির সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পৃথিবীর যেসব অঞ্চলে নিয়মিত এবং পর্যাপ্ত বৃষ্টিপাত হয়, সেইসব
                অঞ্চলে জলসেচ ছাড়াই কেবল বৃষ্টির জলের সাহায্যে কৃষিকাজ করার
                পদ্ধতিকে আর্দ্র কৃষি বলে। ভারত, বাংলাদেশ, শ্রীলঙ্কা,
                মালয়েশিয়া, ইন্দোনেশিয়া, ভিয়েতনাম প্রভৃতি দেশে আর্দ্র কৃষিকাজ
                হয়ে থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    17. কোন কৃষিব্যবস্থায় একই জমিতে একসঙ্গে দু-তিন রকমের ফসল
                    সারিবদ্ধভাবে চাষ করা হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ইনটারক্রপিং বা আন্তঃকৃষি ব্যবস্থায় একই জমিতে একসঙ্গে দু-তিন
                রকমের ফসল সারিবদ্ধভাবে চাষ করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. ফ্লোরিকালচার কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                সারাবছর ফোটে এমন ফুল বা মরশুমি ফুলের চাষকে ফ্লোরিকালচার বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    19. ভারতে 'নীলবিপ্লব' শব্দটি কোন্ উৎপাদন ব্যবস্থা সম্পর্কিত?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতে 'নীলবিপ্লব' শব্দটি মৎস্য উৎপাদন ব্যবস্থা সম্পর্কিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. ধান চাষের জন্য কোন্ জলবায়ু অঞ্চল সর্বাপেক্ষা উপযোগী?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ধান চাষের জন্য ক্রান্তীয় মৌসুমি জলবায়ু অঞ্চল সর্বাপেক্ষা
                উপযোগী।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. কোন্ ধরনের মাটি ধান চাষের পক্ষে উপযোগী?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                উর্বর দোআঁশ মাটি এবং নদী উপত্যকার পলিমাটি ধান চাষের পক্ষে বিশেষ
                উপযোগী।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. Exclusive Economic Zone (EEZ) কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতীয় উপকূল বলয়টি 200 নটিক্যাল মাইল পর্যন্ত বিস্তৃত, এই
                অঞ্চলটি EEZ নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. বাজার বাগান কৃষির সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বড়ো বড়ো শহরের নিত্যপ্রয়োজনীয় শাকসবজি, ফুল ও ফলের চাহিদা
                মেটাতে শহরের উপকণ্ঠে অত্যন্ত আধুনিক পদ্ধতিতে যে চাষ করা হয়,
                তাকে বাজার বাগান কৃষি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. ট্রাক ফার্মিং কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কৃষিক্ষেত্রে উৎপাদিত শাকসবজি ট্রাকে করে নিকটবর্তী বাজারে পাঠানো
                হয় বলে মার্কিন যুক্তরাষ্ট্রে বাজারভিত্তিক উদ্যান কৃষি ট্রাক
                ফার্মিং নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    25. দক্ষিণ ভারতে কোন শ্রেণির কফি প্রচুর পরিমাণে জন্মায়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দক্ষিণ ভারতে রোবাস্টা ও আরবীয় শ্রেণির কফি প্রচুর পরিমাণে
                জন্মায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. শাকসবজির চাষ কোন্ বিশেষ নামে পরিচিত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                শাকসবজির চাষ ওলেরিকালচার নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    27. ভারতে কোন্ শস্যের উৎপাদনের ওপর সবুজবিপ্লবের প্রভাব
                    সর্বাধিক?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতে গম উৎপাদনের ওপর সবুজবিপ্লবের প্রভাব সর্বাধিক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. IRRI এর সম্পূর্ণ নাম কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                IRRI-এর সম্পূর্ণ নাম হল – International Rice Research Institute
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    29. শ্রীলঙ্কার সর্বোচ্চ চা বাগানগুলি কোন অঞ্চলে অবস্থিত?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                শ্রীলঙ্কার সর্বোচ্চ চা বাগানগুলি নুয়ারা এলিয়া অঞ্চলে অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. মিশরের সর্বাধিক তুলো কোথায় উৎপন্ন হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মিশরের সর্বাধিক তুলো নীলনদের বদ্বীপ অঞ্চলে উৎপন্ন হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>31. আখ প্রধানত কোন্ জলবায়ু অঞ্চলের ফসল?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                আখ প্রধানত ক্রান্তীয় ও উপক্রান্তীয় জলবায়ু অঞ্চলের ফসল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. আখ চাষের জন্য কীরূপ মৃত্তিকা প্রয়োজন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আখ চাষের জন্য চুন ও লবণ সমৃদ্ধ দোআঁশ মৃত্তিকা প্রয়োজন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. কোন্‌ গাছ ‘জীবন বৃক্ষ' নামে পরিচিত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                নারকেল গাছ 'জীবন বৃক্ষ' নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. পাহাড়ের ঢালে ধাপ কেটে চা চাষ করা হয় কেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                গাছের গোড়ায় জল জমে যাতে গাছ নষ্ট হয়ে না যায় সেই কারণে
                পাহাড়ের ঢালু জমিতে ধাপ কেটে চাষ করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. ভাদই কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আউশ ধান ভাদ্র মাসে কাটা হয় বলে একে ভাদই বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>36. কোন্ ধরনের মাটি গম চাষের উপযুক্ত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                উর্বর ভারী দোআঁশ মাটি বা ভারী কাদামাটি গম চাষের উপযুক্ত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. বাসন্তিক গম কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                শীতপ্রধান জলবায়ু অঞ্চলে বসন্তকালে বরফ গলে গেলে সেখানে যে গমের
                চাষ করা হয় তাকে বাসন্তিক গম বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. মিলেট কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জোয়ার, বাজরা, রাগি প্রভৃতি ক্ষুদ্র দানাশস্যকে একত্রে মিলেট বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. কফি কোন্ জলবায়ু অঞ্চলের ফসল?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কফি গ্রীষ্মপ্রধান ক্রান্তীয় জলবায়ু অঞ্চলের ফসল।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ১. কৃষিকাজ কাকে বলে? কৃষিকাজের ওপর পরিবেশের প্রভাব আলােচনা
                  করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                কৃষি শব্দটি সংস্কৃত থেকে এসেছে যার অর্থ "কর্ষণ করা" কৃষি এর
                ইংরেজি প্রতিশব্দ Agriculture শব্দটি একটি ল্যাটিন শব্দ থেকে
                এসেছে, যার 'Agar' অর্থ 'ভূমি' বা 'ক্ষেত্র' এবং Culture অর্থ
                'কর্ষণ করা'। <br />
                <br />
                অধ্যাপক জিমারম্যান (Zimmerman,1951) কৃষির সংজ্ঞা বলেছেন - "
                জমিতে স্থায়ীভাবে বসবাস করে মানুষ যখন উদ্ভিদ ও প্রাণীর স্বাভাবিক
                জন্ম ও বৃদ্ধির প্রসার ঘটিয়ে নিজের চাহিদা মেটানোর জন্য উদ্ভিজ্জ
                ও প্রাণিজ দ্রব্য উৎপাদন করে, তখন তাকে কৃষিকার্য বলে।"
                <br />
                যেমন - পশুপালন, ফসল উৎপাদন, মৎস্য প্রতিপালন প্রভৃতি।
                <br />
                <br />
                <font color="#29900a">
                  <b>কৃষিকাজের ওপর পরিবেশের প্রভাব : </b>{" "}
                </font>{" "}
                কৃষিকাজের মূল উপকরণ উর্বর জমি হলেও কতকগুলি ভৌগােলিক উপাদান বা
                পরিবেশ বিভিন্ন কৃষি প্রণালী বা জমির কৃষিযােগ্যতাকে প্রবাবিত করে।
                যেমন 一<br />
                <br />
                <font color="#29900a">
                  <b>A. কৃষিকাজে প্রাকৃতিক পরিবেশের প্রভাব : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. ভূপ্রকৃতি : </b>{" "}
                </font>{" "}
                ভূপ্রকৃতির তারতম্যের জন্য কৃষিকাজের ধরন পরিবর্তিত হয়। পার্বত্য
                অঞ্চলের ঢালু জমিতে কৃষিকাজ সহজে করা যায় না। তবে চা, কফির জন্য
                পাহাড়ের ঢালু জমিই উপযুক্ত। অন্যদিকে, সমভূমির উর্বর পলিমাটি
                কৃষিকাজের পক্ষে বিশেষ উপযােগী।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. মৃত্তিকা : </b>{" "}
                </font>{" "}
                মাটির উর্বরা শক্তির ওপর ফসল উৎপাদন নির্ভর করে। মাটির নানারকম
                বৈশিষ্ট্যের ওপর ফসলের প্রকৃতি নির্ধারিত হয়। যেমন, কৃয়
                মৃত্তিকায় তুলাে, লােহা-মিশ্রিত মাটিতে চা ও কফির ফলন ভালাে হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. তাপমাত্রা : </b>{" "}
                </font>{" "}
                গাছপালার স্বাভাবিক বৃদ্ধির জন্য কমপক্ষে 6°সে. উয়তা প্রয়ােজন। এ
                ছাড়া সব শস্য একই উয়তায় জন্মায় না। তাই বিভিন্ন ফসলের জন্য
                বিভিন্ন রকম তাপমাত্রার প্রয়ােজন হয়। যেমন — গম চাষের জন্য
                বার্ষিক 14°সে. থেকে 16°সে. তাপমাত্রা আদর্শ অথবা চা চাষের
                ক্ষেত্রে 27°সে. উত্তাপ আদর্শ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. বায়ুপ্রবাহ : </b>{" "}
                </font>{" "}
                স্বাভাবিক বায়ুপ্রবাহ ফসল উৎপাদনের সহায়ক হয়। সমুদ্রের নােনা
                হাওয়ায় নারকেল, কফি, কাজুবাদাম প্রভৃতি বাগিচা ফসলের চাষ ভালাে
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. জীবজন্তু : </b>{" "}
                </font>{" "}
                কেঁচো, ইঁদুর প্রভৃতি প্রাণী নীচের মাটিকে ওপরে তুলে দেয়। ফলে জমি
                উর্বর হয়, যা ফসল উৎপাদনে সাহায্য করে। বর্তমানে রাসায়নিক বা
                অজৈব সার প্রয়ােগ না করে এধরনের উপকারী প্রাণীর সাহায্যে মাটির
                উর্বরতা বাড়ানাে হচ্ছে।
                <br />
                <br />
                <font color="#29900a">
                  <b>B. কৃষিকাজে অর্থনৈতিক পরিবেশের প্রভাব : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. শ্রমিক ও কৃষি যন্ত্রপাতি : </b>{" "}
                </font>{" "}
                শ্রমিকের পর্যাপ্ত সরবরাহের ওপর কৃষি পদ্ধতি গড়ে ওঠে। যেমন,
                ক্রান্তীয় মৌসুমি অঞ্চল ঘন জনবসতিপূর্ণ হওয়ায় শ্রমিকের পর্যাপ্ত
                জোগান থাকায় বাগিচা কৃষি, নিবিড় কৃষি প্রচলিত। অন্যদিকে,
                নাতিশীতােয় অঞ্চলে শ্রমিকের অভাব থাকায় কৃষিকাজ সম্পূর্ণ
                যন্ত্রনির্ভর। এই অঞ্চলের দেশগুলিতে ব্যাপক ও বাণিজ্যিক কৃষি
                পদ্ধতি প্রচলিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. জলসেচ ও সার : </b>{" "}
                </font>{" "}
                বর্তমানে ব্যাপক জলসেচ ও রাসায়নিক সার প্রয়ােগে নিবিড় ও
                বাণিজ্যিক পদ্ধতিতে কৃষিকাজ হয়ে থাকে। প্রধানত খাদ্যশস্যের চাহিদা
                মেটাতে উচ্চফলনশীল বীজ ব্যবহার করে নিবিড় প্রথায় ধান, গম ও
                বিভিন্ন খাদ্যশস্য উৎপাদন করা হয়। বাণিজ্যিক উদ্দেশ্যে শহরতলি
                অংশে বাগান বাগিচায় শাকসবজি, ফলমূল ইত্যাদি উৎপাদন করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. চাহিদা : </b>{" "}
                </font>{" "}
                মানুষের চাহিদার ওপর কৃষিজ ফসল উৎপাদনের ধারা নির্ভর করে। এ ছাড়া
                বাজারে কৃষিপণ্যের দাম ওঠা-নামার ওপর ফসল উৎপাদন নির্ভর করে। এই
                দুয়ের প্রভাব উদ্যান কৃষি ও দোহ কৃষিতে লক্ষ করা যায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ২. আর্দ্র কৃষি কাকে বলে? এই কৃষির অবস্থান ও বৈশিষ্ট্য আলােচনা
                  করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                কোনো কোনো অঞ্চলে জলসেচের সুযোগ নেই বা থাকলেও তা সীমিত। সেখানে
                জলসেচের সাহায্য ছাড়াই কেবল বৃষ্টির জলের ওপর নির্ভর করে যে
                কৃষিকাজ করা হয় , তাকে বলে আর্দ্র কৃষি।
                <br />
                <br />
                <font color="#29900a">
                  <b>আদ্র কৃষির বৈশিষ্ট্য : </b>{" "}
                </font>
                1. যে সকল অঞ্চলে নিয়মিত ও পর্যাপ্ত পরিমাণে বৃষ্টিপাত হয়,
                সেখানে মাটি আর্দ্র থাকে। তাই কৃষিকাজের জন্য আর জলসেচের দরকার হয়
                না। অনেক ক্ষেত্রে ভৌমজলপ্তরও ভূপৃষ্ঠের নিকটে অবস্থান করে। ফলে
                মাটির আর্দ্রতা বজায় থাকে।
                <br />
                <br />
                2 এক্ষেত্রে কৃষক বৃষ্টির আগেই জমি তৈরি করে রাখে এবং বৃষ্টি শুরু
                হলেই বীজ বুনতে শুরু করে।
                <br />
                3. এসকল অঞ্চলে শুষ্ক ঋতুতে কৃষিকাজ হয় না।
                <br />
                4. এই কৃষিতে কৃষকের কর্মদক্ষতা পরিবেশ দ্বারা নিয়ন্ত্রিত। সে
                প্রধানত নিজের চাহিদা মেটাতেই এই কাজে অংশ নেয়।
                <br />
                5. জলসেচের সুযোগ না থাকায় এখানে মূলধনের বিনিয়োগ সীমিত।
                <br />
                6. ফলনের হার ও মোট উৎপাদন কম।
                <br />
                7. উৎপাদনে উদ্বৃত্ত হয় না বললেই চলে। ফলে কৃষিপণ্য রপ্তানি করার
                কোনো সুযোগ নেই।
                <br />
                <br />
                8. উন্নয়নশীল দেশগুলিতে আর্দ্র কৃষির অন্তর্গত জমিগুলির আয়তন
                ছোটো। সমতল ভূমিতে নদী – খাল – জলাশয় থেকে দূরে বা যেখানে জলসেচের
                সুযোগ নেই সেখানে, মালভূমির সমতল শীর্ষদেশে (যেখানে বেশি বৃষ্টি
                হয়), পর্বত বা মালভূমির প্রতিবাত ঢালে এ ধরনের কৃষি দেখা যায়।
                ধান, পাট ইত্যাদি এই কৃষির প্রধান ফসল।
                <br />
                <br />
                <font color="#29900a">
                  <b>আদ্র কৃষির অবস্থান : </b>{" "}
                </font>{" "}
                দক্ষিণ ও পূর্ব এশিয়ার ভারত, বাংলাদেশ, শ্রীলঙ্কা, মায়ানমার,
                ইন্দোনেশিয়া, মালয়েশিয়া প্রভৃতি দেশের মৌসুমি বৃষ্টিবহুল
                অঞ্চলে, ইথিওপিয়া, জাম্বিয়া, ব্রাজিলের দক্ষিণাংশ ইত্যাদি অঞ্চলে
                আর্দ্র কৃষির প্রচলন আছে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ৩. শুষ্ক কৃষি কাকে বলে? এই কৃষির অবস্থান ও বৈশিষ্ট্য আলােচনা
                  করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যেসব অঞ্চলে বার্ষিক বৃষ্টিপাতের পরিমাণ খুবই কম (সাধারণত 50 সেমির
                কম), জলসেচেরও সুবন্দোবস্ত নেই, সেসব অঞ্চলে যে কৃষিব্যবস্থা
                প্রচলিত আছে, তাকে বলে শুষ্ক কৃষি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>শুষ্ক কৃষির বৈশিষ্ট্য : </b>{" "}
                </font>{" "}
                <br />
                <br />
                1. এ ধরনের কৃষি খরাপ্রবণ অঞ্চলে প্রচলিত। এই কৃষির অন্তর্গত
                অঞ্চলে বৃষ্টিপাত অনিশ্চিত, বৃষ্টিপাতের পরিমাণও অল্প।
                <br />
                <br />
                2. বেলে, দোআঁশ মাটি এই কৃষির উপযুক্ত। কারণ, এধরনের মাটির জলধারণ
                ক্ষমতা কম।
                <br />
                3. জলের অপচয় নিবারণের জন্য বড়ো জমিকে ছোটো ছোটো অংশে ভাগ করা
                হয়।
                <br />
                4. জলের অপচয় বন্ধের জন্য জমিকে আগাছা মুক্ত রাখার চেষ্টা করা
                হয়।
                <br />
                5. তবে শুষ্ক কৃষি ব্যবস্থায় উৎপাদন ব্যয় অধিক হলেও ফলন সে
                তুলনায় কম।
                <br />
                6. মাটিকে প্রথমে গভীরভাবে কর্ষণ করা হয়, যাতে বৃষ্টির জল মাটির
                গভীরে প্রবেশ করতে পারে।
                <br />
                7. যে সকল শস্য লাভজনক, যাদের চাহিদা রয়েছে, সে সকল ফসলের চাষই
                করা হয়। যেমন মিলেট, জোয়ার, বাজরা, রাগি ইত্যাদি এই কৃষি
                ব্যবস্থার প্রধান ফসল।
                <br />
                8. এই পদ্ধতিতে জলসেচের ব্যবহার নেই।
                <br />
                9. বছরে একবার চাষের পর জমি ফেলে রাখা হয়।
                <br />
                10. জমিকে কর্ষণ করার পর খড় দিয়ে ঢেকে রাখা হয় যাতে মাটির
                আর্দ্রতা কমে না যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>শুষ্ক কৃষির অবস্থান : </b>{" "}
                </font>{" "}
                পশ্চিম অস্ট্রেলিয়া, মার্কিন যুক্তরাষ্ট্রের পশ্চিমাংশ, কানাডা,
                দক্ষিণআফ্রিকা, মধ্য আমেরিকা, দক্ষিণ–পশ্চিম ব্রাজিল, পশ্চিম –
                এশিয়া, ভারতের পশ্চিমাংশ (পাঞ্জাব, রাজস্থান, গুজরাট), দক্ষিণ
                ভারতের অভ্যন্তরভাগ ইত্যাদি অঞ্চলে শুষ্ক কৃষিব্যবস্থার প্রচলন
                দেখা যায়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

প্রাথমিকক্রিয়াকলাপকৃষিকাজ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(প্রাথমিকক্রিয়াকলাপকৃষিকাজ);
