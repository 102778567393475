import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

const রাষ্ট্রবিজ্ঞানউচ্চমাধ্যমিকপ্রস্তুতি = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>
          West Bengal Class 12 Guide | West Bengal HS Suggestion | Higher
          Secondary Guide | WBCHSE | WB | রাষ্ট্রবিজ্ঞান - উচ্চ মাধ্যমিক
          প্রস্তুতি
        </title>
        <meta
          name="description"
          content="West Bengal Class 12 Guide | West Bengal HS Suggestion | রাষ্ট্রবিজ্ঞান - উচ্চ মাধ্যমিক প্রস্তুতি"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                রাষ্ট্রবিজ্ঞান - উচ্চ মাধ্যমিক প্রস্তুতি
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative h-100"></div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3"></h6>
              <h1 className="mb-4">
                Welcome to রাষ্ট্রবিজ্ঞান - উচ্চ মাধ্যমিক প্রস্তুতি
              </h1>
              <p className="mb-4">
                প্রতিটি অধ্যায়ের সাথে প্রস্তুতির সহায়ক সামগ্রী ও লিঙ্ক সরবরাহ
                করা হয়েছে যাতে ছাত্ররা তাদের অধ্যয়নে সহায়তা পেতে পারেন। এই
                পৃষ্ঠাটি ছাত্রদের পড়াশোনা প্রক্রিয়ার জন্য একটি গুরুত্বপূর্ণ
                সহায়ক সরঞ্জাম হিসাবে কাজ করতে পারে।
              </p>

              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-আন্তর্জাতিক-সম্পর্ক-Guide"
                        )
                      }
                    >
                      আন্তর্জাতিক সম্পর্ক
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-দ্বিতীয়-বিশ্বযুদ্ধের-পরবর্তী-পর্বের-আন্তর্জাতিক-সম্পর্ক-Guide"
                        )
                      }
                    >
                      দ্বিতীয় বিশ্বযুদ্ধের পরবর্তী পর্বের আন্তর্জাতিক সম্পর্ক
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-বিদেশনীতি-বা-পররাষ্ট্রনীতি-Guide"
                        )
                      }
                    >
                      বিদেশনীতি বা পররাষ্ট্রনীতি
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-সম্মিলিত-জাতিপুঞ্জ-Guide"
                        )
                      }
                    >
                      সম্মিলিত জাতিপুঞ্জ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-কয়েকটি-প্রধান-রাজনৈতিক-মতবাদ-Guide"
                        )
                      }
                    >
                      কয়েকটি প্রধান রাজনৈতিক মতবাদ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-সরকারের-বিভিন্ন-বিভাগ-Guide"
                        )
                      }
                    >
                      সরকারের বিভিন্ন বিভাগ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভারতের-শাসন-বিভাগ-Guide"
                        )
                      }
                    >
                      ভারতের শাসন বিভাগ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভারতের-আইন-বিভাগ-Guide"
                        )
                      }
                    >
                      ভারতের আইন বিভাগ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভারতের-বিচার-বিভাগ-Guide"
                        )
                      }
                    >
                      ভারতের বিচার বিভাগ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/রাষ্ট্রবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-স্থানীয়-স্বায়ত্বশাসন-ব্যবস্থা-Guide"
                        )
                      }
                    >
                      স্থানীয় স্বায়ত্বশাসন ব্যবস্থা
                    </a>
                  </p>
                </div>
              </div>
              <a className="btn btn-primary py-3 px-5 mt-2" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

রাষ্ট্রবিজ্ঞানউচ্চমাধ্যমিকপ্রস্তুতি.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(রাষ্ট্রবিজ্ঞানউচ্চমাধ্যমিকপ্রস্তুতি);
