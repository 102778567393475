import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function অভিব্যক্তিবাবিবর্তন() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          অভিব্যক্তি বা বিবর্তন | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="অভিব্যক্তি বা বিবর্তন | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                অভিব্যক্তি বা বিবর্তন
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  নিম্নলিখিতগুলির কোন্‌টির দ্বারা কোনো পপুলেশনের জেনেটিক
                  ইকুউলিব্রিয়ামে থাকা বাধাপ্রাপ্ত হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যথেচ্ছ হারে প্রজননের অভাব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যথেচ্ছ হারে প্রজনন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মাইগ্রেশনের অভাব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পরিব্যক্তির অভাব
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>যোগ্যতমের উদ্বর্তন তত্ত্বটি কার মতবাদ?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডি-প্রিস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ডারউইন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ল্যামার্ক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ওয়ালেস
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Haeckel-এর বায়োজেনেটিক সূত্র কোন্‌টি?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জননগত পৃথক্করণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অন্টোজনি রিপিটস্ ফাইলোজেনি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফাইলোজেনি রিপিটস্ অন্টোজেনি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রতিটি অপত্য তাঁর পিতামাতার সৃষ্ট উপাদান
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>যে জাহাজে করে ডারউইন ভ্রমণ করেছিলেন সেটি হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বিগল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আটলান্টিক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সিগলি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গঙ্গোত্রী
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  প্রাণের উৎপত্তির জন্য প্রথম যে যৌগ সৃষ্টি হয়েছিল সেটি হল -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  প্রোটিন ও নিউক্লিক অ্যাসিড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইউরিয়া ও অ্যামাইনো অ্যাসিড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রোটিন ও অ্যামাইনো অ্যাসিড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইউরিয়া ও নিউক্লিক অ্যাসিড
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  কোন্ পদ্ধতির জননের ফলে প্রজাতির মধ্যে বংশগত ও বিবর্তন পরিবর্তন
                  বেশি দেখা যায়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বাডিং
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অযৌন জনন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফিশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  যৌন জনন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>'অরিজিন অফ লাইফ' এর আর এক নাম হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বায়োপোয়েসিস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বায়োজেনেসিস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রোটোবায়োজেনেসিস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সবকটি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ডারউইনের মতবাদ নীচের কোন্‌টির ব্যাখ্যা দিতে পারেনি?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অত্যধিক বংশবৃদ্ধি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যোগ্যতমের উদ্বর্তন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রকৃতির ক্রিয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  প্রকরণের উৎস
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নীচের কোন্‌টি আদিম পৃথিবীর বায়ুমণ্ডলে অবস্থান করত?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অক্সিজেন ও হাইড্রোজেন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হাইড্রোজেন ও মিথেন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যামোনিয়া ও হিলিয়াম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জলীয় বাষ্প ও আর্গন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>'যোগ্যতমের উদ্বর্তন' কথাটি কে ব্যবহার করেন?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডি-ভিস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ডারউইন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ল্যামার্ক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্পেনসার
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>সপ্তম অধ্যায় - অভিব্যক্তি</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 1. মিসিং লিংক কাকে বলে?</b>
              </font>
              <br />
              <br />{" "}
              <font color="#3c92e5">
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b>মিসিং লিংক : </b>
                </font>{" "}
                কিছু কিছু অবলুপ্ত জীবের সন্ধান পাওয়া যায় যাদের কেবলমাত্র
                ভীবাশ্মরূপে দেহাবশেষ পাওয়া যায় এবং এদের মধ্যে দুই গোষ্ঠীর
                জীবের গুণাগুণের চিহ্ন মেলে। এমন লুপ্ত জীবেদের মিসিং লিংক বলে।
                <br />
                <br />
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b>উদাহরণ : </b>
                </font>{" "}
                আর্কিওপটেরিক্স। সরীসৃপ ও পক্ষী শ্রেণির মিসিং লিংক Archaeopteryx
                .
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. নিউ-ডারউইনিজম্ বা আধুনিক সংশ্লেষবাদ কাকে বলে? </b>
              </font>
              <br />
              <br />{" "}
              <font color="#3c92e5">
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> মিসিং লিংকনিউ-ডারউইনিজম্ বা আধুনিক সংশ্লেষবাদ : </b>
                </font>
                আধুনিক যুগে বিভিন্ন বিজ্ঞানীদের মতে, জীবের বিবর্তনের ক্ষেত্রে
                ডারউইনবাদ স্বয়ংসম্পূর্ণ নয়। 1942 খ্রিস্টাব্দে J. S. Huxley
                ডারউইনের প্রাকৃতিক নির্বাচন বাদকেই বিশেষ কিছু পরিবর্তনের মাধ্যমে
                উপস্থাপিত করেন বা বিজ্ঞানী ফিশার, হ্যালডেন, রাইট, মূলার,
                ডবজানস্কি প্রমুখ বিজ্ঞানীগণ প্রদত্ত তত্ত্ব দ্বারা আরো দৃঢ়
                ভিত্তিভূমিতে প্রতিষ্ঠিত হয়। এই মতবাদ অনুসারে পরিব্যক্তি বা
                মিউটেশন, জেনেটিক রিকম্বিনেশন, জেনেটিক ড্রিফট ও জিন প্রবাহের
                মাধ্যমে ঘটা পরিবর্তনসমূহ, জীবের মধ্যে কিছু পরিবর্তন সৃষ্টি করে।
                এরপর এই পরিবর্তনসহ অভিযোজিত জীব প্রাকৃতিকভাবে নির্বাচিত হলেই
                জীবটির বিবর্তন ঘটে। একেই নব্য ডারউইনবাদ বা নিও-ডারউইনিজম বলে।
                Darwin পরবর্তী বিভিন্ন বিজ্ঞানীদের মতবাদকে একত্রিত করে সৃষ্ট এই
                তত্ত্বকে আধুনিক সংশ্লেষণবাদ বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. জীবন্ত জীবাশ্ম বলতে কী বোঝ? </b>
              </font>
              <br />
              <br />{" "}
              <font color="#3c92e5">
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> জীবন্ত জীবাশ্ম : </b>
                </font>
                যে সকল জীব সুদূর অতীতে উৎপত্তিলাভ করলেও কোনোরূপ পরিবর্তন ছাড়াই
                আজও পৃথিবীতে টিকে আছে কিন্তু তাদের সমসাময়িক অধিকাংশ জীবেরা
                বর্তমানে অবলুপ্ত, সেই সকল জীবেদের জীবন্ত জীবাশ্ম বলে।
                <br />
                <br />
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> উদাহরণ : </b>
                </font>{" "}
                Limulus বা রাজকাকড়া নামক সন্ধিপদ প্রাণী বা Ginkgo biloba নামক
                ব্যক্তজীবী উদ্ভিদ।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>4. হার্ডি-উইনবার্গের মূল নীতি বলতে কী বোঝ?</b>
              </font>
              <br />
              <br />{" "}
              <font color="#3c92e5">
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> হার্ডি-উইনবার্গের মূল নীতি : </b>
                </font>{" "}
                যদি কোন একটি বৃহৎ এবং Random মেটিং মেন্ডেলীয় পপুলেশনে কোনও
                প্রকারের মিউটেশন, জিন প্রবাহ, জেনেটিক ড্রিফট, বা প্রাকৃতিক
                নির্বাচন ঘটতে না পারে, তাহলে সেই পপুলেশনের আলেল ফ্রিকোয়েন্সি
                (অথবা, জিন ফ্রিকোয়েন্সি) এবং জিনোটাইপ ফ্রিকোয়েন্সি অপরিবর্তিত
                থাকবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. প্রোগ্রেসিভ ও রেট্রোগ্রেসিভ বিবর্তন কাকে বলে?</b>
              </font>
              <br />
              <br />{" "}
              <font color="#3c92e5">
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> প্রোগ্রেসিভ বিবর্তন : </b>
                </font>{" "}
                সরল গঠনযুক্ত জীব থেকে উন্নত জটিল গঠনযুক্ত জীবের সৃষ্টি হলে তা
                প্রোগ্রেসিভ বা অগ্রগামী বিবর্তনের ফলে ঘটে। উদাহরণস্বরূপ,
                অস্টিকথিসের প্রোগ্রেসিভ বিবর্তনের পরে উভচরের উদ্ভব ঘটে।
                <br />
                <br />
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>
                <font color="#f626d7">
                  <b> রেট্রোগ্রেসিভ বিবর্তন : </b>
                </font>{" "}
                যে বিবর্তনের ফলে জটিল গঠনযুক্ত অঙ্গ সরল গঠনযুক্ত অঙ্গে পরিণত হয়
                অথবা লুপ্ত হয়, তাকে রেট্রোগ্রেসিভ বা পশ্চাদ্‌গামী বিবর্তন বলে।
                উদাহরণস্বরূপ, মানুষের অ্যাপেনডিক্স হল একটি লুপ্তপ্রায় অঙ্গ, যা
                রেট্রোগ্রেসিভ বিবর্তনকে নির্দেশ করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  6. হার্ডি-উইনবার্গ সাম্যাবস্থা (Hardy-Weinberg Equilibrium)
                  বলতে কী বোঝ?
                </b>
              </font>
              <br />
              <br />{" "}
              <font color="#3c92e5">
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> হার্ডি-উইনবার্গ সাম্যাবস্থা : </b>
                </font>{" "}
                বিবর্তন সৃষ্টিকারী বিভিন্ন শর্তের (পরিব্যক্তি, জিন প্রবাহ,
                জেনেটিক ড্রিফট, প্রাকৃতিক নির্বাচন প্রভৃতি) অনুপস্থিতিতে একটি
                সম্রাট মেন্ডেলীয় র‍্যানডম মেটিং পপুলেশন-এ অ্যালিল ফ্রিকোয়েন্সি
                বা জিন ফ্রিকোয়েন্সি ও জিনোটাইপ ফ্রিকোয়েন্সির মধ্যে বংশ
                পরম্পরায় যে সাম্যাবস্থা গড়ে ওঠে তাকে Hardy-Weinberg
                equilibrium বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. অ্যালোপ্যাট্রিক প্রজাতি সৃষ্টি কাকে বলে?</b>
              </font>
              <br />
              <br />{" "}
              <font color="#3c92e5">
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> অ্যালোপ্যাট্রিক প্রজাতি সৃষ্টি : </b>
                </font>
                যখন একটি মিলনে সক্ষম পপুলেশন একাধিক গোষ্ঠীতে বিভক্ত হয়ে ভিন্ন
                ভিন্ন ভৌগোলিক অঞ্চলে আবদ্ধ হয় তখন আলাদা আলাদা প্রজাতির সৃষ্টির
                পথ তৈরি হয়। এইভাবে প্রজাতি সৃষ্টিকে অ্যালোপ্যাট্রিক প্রজাতি
                সৃষ্টি বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>8. সিবলিং স্পিসিস কাকে বলে?</b>
              </font>
              <br />
              <br />{" "}
              <font color="#3c92e5">
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> সিবলিং স্পিসিস : </b>
                </font>
                বহিরাকৃতি সাদৃশ্যযুক্ত যখন দুটি জীব পপুলেশন জননগতভাবে বিভিন্ন
                হয় তখন তাদের সিবলিং স্পিসিস বা সিবলিং প্রজাতি বলে। Drosepints
                persimilis, D. pseudobscura দুটি সিবলিং স্পিসিস।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>9. সায়ানোজেন মতবাদ কাকে বলে?</b>
              </font>
              <br />
              <br />{" "}
              <font color="#3c92e5">
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> সায়ানোজেন মতবাদ : </b>
                </font>
                বিজ্ঞানী ফুজারের ধারণা অনুসারে, পৃথিবী শীতল হওয়ার সময় কার্বন ও
                নাইট্রোজেন মিলে সায়ানোজেন নামে এক প্রকার প্রোটিন যৌগ গঠিত
                হয়েছিল, যার থেকে প্রোটোপ্লাজমের উদ্ভব হয়। এই মতবাদকে
                সায়ানোজেন মতবাদ বলে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

অভিব্যক্তিবাবিবর্তন.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(অভিব্যক্তিবাবিবর্তন);
