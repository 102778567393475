import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function ভৌমজলেরকার্যএবংসংশ্লিষ্টভূমিরূপ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          ভৌমজলের কার্য এবং সংশ্লিষ্ট ভূমিরূপ | West Bengal Class 12 Guide |
          West Bengal HS Suggestion | Higher Secondary Guide | WBCHSE | WB
        </title>
        <meta
          name="description"
          content="ভৌমজলের কার্য এবং  সংশ্লিষ্ট ভূমিরূপ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                ভৌমজলের কার্য এবং সংশ্লিষ্ট ভূমিরূপ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সমুদ্রতরঙ্গের ক্ষয় প্রক্রিয়া সম্পন্ন হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অবঘর্ষ দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জলপ্রবাহের অভিঘাত দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ঘর্ষণ দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  উক্ত সবকটি কারণের জন্য
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  সামুদ্রিক অবক্ষেপণের ফলে একটি দ্বীপ ও তার নিকটবর্তী মূল ভূখণ্ড
                  পরস্পরের সঙ্গে মিলিত হলে গঠিত হয় -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পুরোদেশীয় বাঁধ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হুক স্পিট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  টম্বেলো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্পিট
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বিশাখাপত্তনমের ডলফিন নোজ হল একটি —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্ট্যাক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  খিলান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সমুদ্র ভৃগু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্ট্যাম্প
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> সমুদ্রে জলরাশির উল্লম্ব সঞ্চালনকে কী বলে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফেচ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সমুদ্রতরঙ্গ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সমুদ্রস্রোত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জলপ্রবাহ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>খাঁড়িগুলি বড়ো হলে তাকে বলে —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লেগুন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  উপসাগর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অন্তরীপ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভৃগু
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সমুদ্র তীরবর্তী উঁচু খাঁজকাটা পাড়কে বলে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লেগুন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্ট্যাক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জিও
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ভৃগু
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  তটভূমির উপরিভাগে ছোটো ছোটো নুড়ি, বালি প্রভৃতির অবক্ষেপণে
                  সৃষ্ট হয় —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্পিট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সৈকতভূমি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভৃগু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফিয়র্ড
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>পৃথিবীর বৃহত্তম প্রতিবন্ধক প্রবাল প্রাচীর হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফুনাফুটি প্রবাল বলয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  গ্রেট ব্যারিয়ার রিফ (অস্ট্রেলিয়া)
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফ্যারোস রিফ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতে স্ট্যাক ও স্ট্যাম্প দেখা যায় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অন্ধ্ৰ উপকূলে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কেরালা উপকূলে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গোয়া উপকূলে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তামিলনাড়ু উপকূলে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সমুদ্র বৃষ্টি অরণ্য' নামে পরিচিত -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্পিট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পুরোদেশীয় বাঁধ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  টম্বেলো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  প্রবাল প্রাচীর
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#ec3dde">
                <b>1. ভৌমজলের ভাণ্ডার অব্যাহত থাকে কীসের মাধ্যমে ?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> জলচক্রের মাধ্যমে।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>2. বৃষ্টিপাত ও তুষারগলা জল ভৌমজলে পরিণত হলে তাকে কী বলে?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> মিটিওরিক জল বা আবহিক জল।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  3. ভূ-অভ্যন্তরের যে শিলাস্তর জলশােষণ ও সরবরাহে অক্ষম তাকে কী
                  স্তর বলে ?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> অপ্রবেশ্য স্তর।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>4. অ্যাকুইফার এবং অ্যাকুইড-এর একটি করে পার্থক্য লেখাে।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                অ্যাকুইফার অবস্থান করে দুটি অপ্রবেশ্য শিলাস্তরের মধ্যে। কিন্তু
                প্রবেশ্য শিলাস্তরকে ঘিরে অবস্থান করে অ্যাকুইড।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>5. অ্যাকুইফিউজ কী?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভূ-অভ্যন্তরে যে শিলাস্তর জল সওয় ও ক্ষরণে অক্ষম এবং জল শােষণ ও
                সরবরাহ করতে পারে না, সেই শিলাস্তরকে বলে জ্যাকুইফিউজ। যেমন-
                গ্রানাইট শিলাস্তর।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>6. স্বাভাবিক উদকচাপ ভৌমজলের ভূপৃষ্ঠে নির্গমনকে কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> প্রস্রবণ।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>8. প্রস্রবণ রেখা কাকে বলে?* </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে রেখা বা ফাটল বরাবর একাধিক প্রস্রবণ থাকে, তাকে প্রস্রবণরেখা
                বলা হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  9. যে প্রস্রবণ থেকে শুষ্ক ঋতুতে ভৌমজল নির্গত হয় না তাকে কী
                  বলে?*
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> সবিরাম প্রস্রবণ।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>10. সহস্রধারা প্রবণটি কী জাতীয় প্রস্রবণ ?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> শীতল প্রস্রবণ </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  11. আগ্নেয়গিরি অধ্যুষিত অঞ্চলে কী জাতীয় প্রস্রবণ দেখা যায় ?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> উষ্ণ প্রস্রবণ।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  12. থায়ী সম্পৃক্ত স্তরের কোনাে অংশ হঠাৎ উন্মুক্ত হয়ে পড়লে
                  সেখানে কী সৃষ্টি হয় ?*
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">অবিরাম প্রস্রবণ।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>13. প্রতরেখা কাকে বলে?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সুদীর্ঘ চ্যুতিরেখা বরাবর সৃষ্ট অসংখ্য প্রস্রবণ থেকে যখন ছছাটো
                জলপ্রপাত সৃষ্টি হয় এক রেখা বরাবর সেই রেখাকে প্রপাতরেখা বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>14. ভারতের কোথায় প্রস্রবণ রেখা দেখা যায় ?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                মেঘালয় ও ছােটোনাগপুর মালভূমি অঞ্চলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>15. ভ্যসীয়ান প্রস্রবণ কী ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                চুনাপাথর ও চক নির্মিত প্রবেশ্য শিলাস্তরের নীচে উপস্থিত অপ্রবেশ্য
                কাদাপাথর স্তরের নতিডাল বরাবর সৃষ্ট প্রস্রবণকে বলে ভকুসীয়ান
                প্রস্রবণ।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  16. Valley of Geyser এটি পৃথিবীর দ্বিতীয় বৃহত্তম গিজার অঞ্চল।
                  এটি কোথায় অবস্থিত?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> রাশিয়াতে।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>17. ফন্টেনেলি কী?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                এটি একপ্রকার ক্ষুদ্র প্রস্রবণ। পলল ব্যজনীর পাদদেশে এরূপ প্রস্রবণ
                মাঝে মাঝে দেখা যায়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>18. নতিঢাল কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                অনুভূমিক তলের সঙ্গে শিলাস্তরের কৌণিক অবস্থানকেই নতিটাল বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>19. ভৌমজলস্তরের উপরিভাগকে কী বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">ভৌম জলপীঠ।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>20. কোন্ ঋতুতে ভৌম জলপীঠের উচ্চতা বৃদ্ধি পায় ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">বর্ষা ঋতুতে।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  21. মৃত্তিকা বা শিলাস্তরের রগুলি জলে পরিপূর্ণ হয়, তখন তাকে কী
                  বলে?*
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> সম্পৃক্ত স্তর।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  22. সম্পৃক্ত জলস্তরে জলের পরিমাণ বৃদ্ধি পেলে যে স্তরের নলের
                  ন্যায় ছিদ্রপথে জল উপরে উঠে আসেতাকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> কৈশিক স্তর।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>23. উৎস্যন্দ জলের উৎস কী?* </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> ম্যাগমা।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>26. কাস্ট শব্দটি কোন দেশীয় শব্দ থেকে এসেছে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> ক্রোয়েশিয়ান।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>27. ভারতের একটি শীতল ও উয় প্রস্রবণের উদাহরণ দাও।*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                শীতল প্রস্রবণ- দেরাদুনের সহস্রধারা, উয় প্রস্রবণ– হিমাচল
                প্রদেশের মণিকরণ।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>28. একটি খনিজ প্রস্রবণের উদাহরণ দাও।*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">পশ্চিমবঙ্গের বক্রেশ্বর।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>29. ভাদোস স্তর কাকে বলে?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভূ-অভ্যন্তরে সম্পৃক্ত স্তরের উপরিতল থেকে ভূপৃষ্ঠ পর্যন্ত বিস্তৃত
                যে স্তর বায়ুদ্বারা পূর্ণ এবং অসম্পৃক্ত বা আংশিক জলপূর্ণ, ভৌম
                জলপীঠের অনেকটা উপরে অবস্থিত, তাকে বলে ভাদোস স্তর।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>30. ভাদোস জল কী?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভৌম জলস্তরের উপরের বাতপূর্ণ স্তরের মধ্যে সঞ্চিত জলকে ভাদোস জল
                বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  31. জলস্তরের উপরে অবথিত শিলাস্তরের মধ্যে যে জল প্রবাহিত হয়
                  তাকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> ভাদোস জল।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>32. ফ্রিয়েটিক স্তর কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভাদোস স্তরের নীচ থেকে অপ্রবেশ্য শিলাস্তর পর্যন্ত মধ্যবর্তী স্তরে
                যে জল সঞ্চিত হয় তাকেই ফ্রিয়েটিক স্তর বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>33. আদর্শ অ্যাকুইফার কাকে বলে?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে অ্যাকুইফারের দৈর্ঘ্য, প্রস্থ, গভীরতা এবং মধ্যবর্তী জলের
                প্রকৃতি, প্রবাহ ও সঞ্চয় সমধর্মী এবং যথাযােগ্য হয়, তাকে আদর্শ
                অ্যাকুইফার বলে |
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>34. অ্যাকুইটার্ড কাকে বলে?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                স্বল্প পরিমাণে জল সঞ্চয় ও ক্ষরণে সক্ষম অথচ প্রাকৃতিকভাবে
                অপ্রবেশ্য শিলাস্তরকে অ্যাকুইটার্ড বলে। যেমন- কাদা ও বালিমিশ্রিত
                শিলাস্তর।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>35. অ্যাকুইড কী?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                অধিক সচ্ছিদ্রতা যুক্ত যে স্তর জলক্ষরণে অক্ষম অথচ জল ধারণে সক্ষম,
                সেই স্তরকে বলা হয় অ্যাকুইকুড।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  36. যখন ভৌমজল ভূপৃষ্ঠে সম্ভেদ রূপে অবস্থান করে এবং তখন
                  প্রবেশ্য শিলা ও সম্ভেদের সন্ধিস্থলে যে প্রস্রবণ সৃষ্টি হয়,
                  তাকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">ডাইক প্রস্রবণ।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>37. আর্টেজীয় কূপ প্রথম কোথায় খনন করা হয় ?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> উর ফ্রান্সের আর্টোয়েস প্রদেশে।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>38. আর্টেজীয় কুপ কী ধরনের শিলাস্তরে দেখা যায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভাঁজযুক্ত প্রবেশ্য শিলাস্তরে অবােভঙ্গ অঞ্চল, একনত গঠন বিশিষ্ট
                অঞ্চল ও আগ্নেয় উদভেদ বিশিষ্ট অঞ্চলে দেখা যায়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>39. গিজার কী ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                এটি এক ধরনের উয় প্রস্রবণ যে ক্ষেত্রে নির্দিষ্ট সময়ান্তর জল ও
                বাম্প নির্গত হয় প্রবল বেগে শব্দ করে। উদা—যুক্তরাষ্ট্রের
                ইয়ােলােস্টোন পার্কের ওল্ড ফেথফুল গিজার।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>40. কোন্ জাতীয় প্রস্রবণ গিজারে পরিণত হয় ?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সংকীর্ণ উয় প্রস্রবণের নিম্নাংশ ম্যাগমা স্তর পর্যন্ত বিস্তৃত
                থাকলে সেই প্রস্রবণ গিজারে পরিণত হয়। এখানে ভূ-অভ্যন্তর থেকে কিছু
                সময় অন্তর বাম্পসহ গরম জলের উৎক্ষেপণ ঘটে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>41. দুটি প্রবেশ ও দুটি অপ্রবেশ্য শিলার উদাহরণ দাও।*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                দুটি প্রবেশ্য শিলা চুনাপাথর ও বেলেপাথর, দুটি অপ্রবেশ্য শিলা
                গ্যাব্রো ও শেল।এ
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>42. ভ্যকুসীয়ান নামটির উৎপত্তি হয় কীসের থেকে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                ফ্রান্সের ফনটেন-দ্য-ভ্যস নামক প্রস্রবণ থেকে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>43. কোথায় চুনাপাথর গঠিত বিস্তীর্ণ মালভূমি আছে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                ইউরােপের দক্ষিণ ভাগে সার্বিয়া-মন্টেনিগ্রো এবং
                বসনিয়া-হার্জেগােবিনা নামক দুটি দেশের আড্রিয়াটিক সাগর-সংলগ্ন
                অঞ্চলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  44. ভারতের কোথায় কোথায় কাস্ট ভূমিরূপ দেখতে পাওয়া যায়?*
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                ভারতের উরাখণ্ডের দেরাদুন, অন্ত্রপ্রদেশের বােরাগুহা, মধ্যপ্রদেশের
                পাঁচমারি ও মেঘালয়ের চেরা মালভূমি প্রভৃতি স্থানে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>45. চুনাপাথর যুক্ত অঞ্চলে ভৌমজল কোন প্রক্রিয়ায় ক্ষয়কাজ</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">উঃ দ্রবণ প্রক্রিয়ায়।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>46. দ্রবণ খাতবিশিষ্ট কাস্টভূমিকে ইংল্যান্ডে কী বলে?</b>
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>47. কারেন কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                দ্রবণ প্রক্রিয়ায় সৃষ্ট কাস্ট অঞ্চলের গর্তগুলিকে বলা হয় কারেন।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  48. চুনাপাথর অণ্ডলে দুটি গ্রাইকসের মধ্যবর্তী উচ্চভূমিরূপটির
                  নাম কী ?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> ক্লিট।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>49. কাস্ট অঞ্চলের আবদ্ধ গর্তকে কী বলে?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> ডােলাইন।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>50. ভারতের কোথায় কাস্ট গিরিখাত দেখা যায় ?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">উত্তর ভারতের তপােকেশ্বর নদীতে।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>51. F, প, ফ্রান্সের টার্ন কীসের উদাহরণ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                কাস্ট অঞ্চলে ক্ষয়ের ফলে সৃষ্ট গিরিখাতের।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  52. কাস্ট ভূমিরূপ অঞ্চলে সাধারণত নদী দৃশ্যমান হয়, কোথায় পতিত
                  হওয়ার আগে পর্যন্ত ?*
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">সিঙ্কহােলে।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>53. টেরারােসা কী ?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                চুনাপাথর গঠিত অঞলে নিম্নগামী ভৌমজলের দ্রবণকার্যের ফলে অদ্রবণীয়
                পদার্থ গঠিত লাল মৃত্তিকাকে টেরারােসা বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>54. অত্যন্ত ক্ষুদ্র ও ঘনসন্নিবদ্ধ কারেনকে কী বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> রিলেনকারেন।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>55. গােলাকৃতি শিলাখণ্ড দিয়ে গঠিত কারেন কী নামে পরিচিত?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> রুন্ডকারেন।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>56. গ্রাইক গঠনের ফলে বিচ্ছিন্ন শিলাস্তুপকে কী বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> ক্লিন্টস্।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>57. ক্লিন্ট ভারতের কোথায় দেখা যায় ?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                দেরাদুনের তপােকেশ্বর গুহার পার্শ্ববর্তী স্থানে প্রচুর ক্লিন্টস্
                দেখা যায়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>58. টেরারােসা কোন্ অঞ্চলে দেখা যায় ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> কাস্ট অঞ্চলে।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  59. চুনাপাথর গঠিত অঞ্চলে অবনমিত গােলাকার দ্রবণকার্যেরফলে সৃষ্ট
                  স্থানকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> সিঙ্কহােল।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>60. ভারতের কোথায় সিদ্ধহােল দেখা যায় ?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> ভারতের উত্তরাখণ্ড রাজ্যের দেরাদুনে।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>61.‘অন্ধ উপত্যকা কাকে বলে?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                শুষ্ক উপত্যকায় সিঙ্কহােল পর্যন্ত এসে নদী হঠাৎ ভূগর্ভে অন্তর্হিত
                হয়, কিন্তু নদী উপত্যকাটি সিঙ্কহােল পর্যন্ত জলপূর্ণ থাকে। তাই
                সিঙ্কহােল পর্যন্ত প্রসারিত এই নদী উপত্যকাকে ‘অন্ধ উপত্যকা বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  62. সিঙ্কহােলের ছাদ ধসে গিয়ে যে ভােলাইন সৃষ্টি হয়, তাকে কী
                  বলে?*
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> ধস ডােলাইন।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>63. দ্রবণ ভােলাইন সৃষ্টি হয় কেন?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                দ্রবণকার্যের ফলে দারণ বরাবর প্রবহমান জলধারা ও দ্রবীভূত পদার্থের
                দ্বারা যে গর্ত সৃষ্টি হয়, তাকে বলে দ্রবণ ডােলাইন।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>64. বহুতলবিশিষ্ট গুহাকে কী বলে?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> গ্যালারিগুহা।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  65. চুনাপাথর গঠিত অঞলে দ্রবণকার্যের ফলে সৃষ্ট গুহায়অবস্থিত
                  বিভিন্ন সঞ্চয়জাত ভূমিরূপগুলিকে একত্রে কী বলা হয়?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> কেভ ট্রাভারটাইন বা স্পেলিওথেম।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  66. চুনাপাথরের গুহার ছাদ থেকে ঝুলতে থাকা ভূমিরূপকে কী বলে?*
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> স্ট্যালকটাইট।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>67. ড্রিপস্টোন বা পাতনপ্রস্তর কী ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভূতত্ত্ববিদ W M Davis (1930খ্রিস্টাব্দ)-এর মতে,চুনাপাথরযুক্ত
                গুহায় সঞ্জয়কার্যের ফলে সৃষ্ট বিভিন্ন ধরনের ভূমিরূপের মধ্যে
                গুহার ছাদ থেকে চুইয়ে পড়া দ্রবীভূত ক্যালশিয়াম বাইকার্বনেট
                মেঝেতে, ছাদে, দেয়ালে সঞ্চিত হয়ে সৃষ্ট ভূমিরূপকে বলে ড্রিপস্টোন
                বা পাতপ্রস্তর। যেমন—স্ট্যালাকটাইট, স্তম্ভ ইত্যাদি।
              </font>
              <br />
              <br />
              <font color="#ec3dde">
                <b>68. ককপিট ভােলাইন কোথায় গড়ে ওঠে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                হাস দেখা যায় যেখানে বেশি পরিমাণে সেখানেই ককপিট ডােলাইন দেখা
                যায়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>69. অন্ধ্রপ্রদেশের কোথায় ভােলাইন দেখা যায় ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">বােরাগুহা অঞ্চলে।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  70. কাস্ট অঞলে সৃষ্ট গর্ত যা ডােলাইনের তুলনায় অগভীর কিন্তু
                  আয়তনে বিশাল তাকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">সলিউশন প্যান।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>71. উভালার তুলনায় বড়াে আবদ্ধ গর্তকে কী বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> পােলজি।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>72. একটি পৃথিবীবিখ্যাত পপালজির উদাহরণ দাও।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> যুগােস্লাভিয়ার লিভােনাে।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  73. ডােলাইনের তলদেশের ছিদ্র বুজে গিয়ে জল সঞ্চিত হয়ে কী
                  সৃষ্টি হয় ?*
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> সিঙ্কহােল পুষ্করিণী।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>74. খুব ছােটো আয়তনের উভালাকে কী বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> জামা (Jama)।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  75. চুনাপাথরের গুহার ছাদে সৃষ্ট গম্বুজাকৃতি গর্তকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> ব্লো হােল।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>76. রিমস্টোন বা কিনারাপ্রস্তর কী ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                জলপ্রবাহ গুহাবক্ষে উপচে পড়ে গুহাবক্ষের দুই কিনারায় যে ভূমিরূপ
                সৃষ্টি করে, তাকে বলে রিমস্টোন।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>77. রিমস্টোন ও ফ্লোস্টোন কোথায় দেখা যায় ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                পূর্বতন যুগােশ্লাভিয়ার কাস্ট অঞ্চলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  78. চুনাপাথর অঞলে পােলজির মধ্যভাগে অবশিষ্ট পাহাড়কে কী বলে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> হাম৷</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>79. হান্স কোথায় কী নামে পরিচিত?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                পুয়ের্তোরিকোতে হে স্ট্যাক পাহাড় বা পেপিনাে হিলস এবং কিউবাতে
                মােগােতেস।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>80. ভারতে হামস কী নামে পরিচিত?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                ছত্তিশগড় রাজ্যের রায়পুর জেলায় গঠিত হাসগুলিকে স্থানীয় ভাষায়
                রাবণ-ভাটা বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  81. যে নলাকৃতি পথে জল সােয়ালাে হােল দ্বারা ভূগর্ভে প্রবেশ করে
                  তাকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> পােনর।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>82. অন্ধ্রপ্রদেশের কোথায় কাস্ট সেতু আছে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">কোটিগুড়ায়।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>৪3. সিঙ্কহোল পুষ্করিণী আকারে খুব বড়াে হলে তাদের কী বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> কাস্ট হ্রদ।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>84. ভারতের কোথায় কাস্ট জানালা দেখা যায় ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> মধ্যপ্রদেশের পাঁচমারিতে।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  {" "}
                  86. চুনাপাথরের গুহায় ছাদ থেকে ঝুলতে থাকা ভূমিরূপটির নাম কী ?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> স্ট্যালাকটাইট।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>87. গুহার তলদেশ থেকে উথিত চুনাপাথর গঠিত ভূমিরূপকে কী বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> স্ট্যালাকমাইট।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  88. চুনাপাথরের গুহায় ছুরির ফলার মতাে অবিক্ষিপ্ত প্রস্তরখণ্ডকে
                  কী বলে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> রুফ পেনডেন্ট।</font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>89. কেভট্যাভারটিন কাকে বলে?*</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সঞ্চয়জাত ভূমিরূপগুলি কাস্ট অঞ্চলে একত্রিতভাবে কেভট্যাভারটিন
                নামে পরিচিত।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>90. উভালা ও পােলিয়ে কোন্ অঞ্চলে দেখা যায় ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5"> চুনাপাথর গঠিত কাস্ট অঞ্চলে।</font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

ভৌমজলেরকার্যএবংসংশ্লিষ্টভূমিরূপ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(ভৌমজলেরকার্যএবংসংশ্লিষ্টভূমিরূপ);
