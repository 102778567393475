import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function বংশগতিওবিভেদ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          বংশগতি ও বিভেদ | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="বংশগতি ও বিভেদ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                বংশগতি ও বিভেদ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  যদি একজন বর্ণান্ধ ব্যক্তি স্বাভাবিক দৃষ্টিসম্পন্ন (হোমোজাইগাস)
                  মহিলাকে বিবাহ করেন, তাহলে জিনগতভাবে —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পুত্র ও কন্যা সন্তানরা বর্ণান্ধ হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কন্যা সন্তানরা স্বাভাবিক হবে, কন্যা সন্তানরা বাহক হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পুত্র-সন্তানরা বর্ণান্ধ হবে, কন্যা সন্তানরা স্বাভাবিক হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পুত্র ও কন্যা সন্তানরা স্বাভাবিক হবে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মা বর্ণান্ধতা রোগের বাহক ও বাবা স্বাভাবিক হলে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অর্ধেক ছেলে স্বাভাবিক ও অর্ধেক ছেলে বর্ণান্ধ হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সকল মেয়ে স্বাভাবিক হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সকল মেয়ে বাহক হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  (a) এবং (b) উভয়ই
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ক্লাইনফেল্টার সিন্ড্রোম হওয়ার কারণ কোনটি?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  একটিমাত্র X
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  একটিমাত্র Y
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  দুটি X ও একটি Y
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  একটি X ও দুটি Y
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>একটি লিঙ্গ সংযোজিত রোগের উদাহরণ -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যালবিনিজম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হিমোফিলিয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সিক্ল সেল অ্যানিমিয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফিনাইল কিটোনিউরিয়া
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  জিন পদার্থের সবচেয়ে ছোটো একক যা মিউটেশন হলে ফিনোটাইপিক
                  পরিবর্তন ঘটায় তা হল -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রেকন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মিউটন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নিউক্লিকঅ্যাসিড
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মেন্ডেলের পরীক্ষায় মটর গাছ ব্যবহার করা হয়েছিল কারণ -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সেগুলি সস্তা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সেগুলি সহজলভ্য ছিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সেগুলি পরস্পর বিপরীত চারিত্রিক গুণসম্পন্ন ছিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উপরের সবগুলি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নিম্নলিখিত বিবৃতিগুলির মধ্যে ভুলটিকে চিহ্নিত করো - </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  টাক-পড়া হল একটি লিঙ্গ-সীমিত লক্ষণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গ্যালাকটোসেমিয়া হল একটি জন্মগত বিপাকীয় ত্রুটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বংশগতিতে লিংকেজ হল স্বাধীন সঞ্ঝারণ সূত্রের একটি ব্যতিক্রম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ছোটো জনসংখ্যা আকারের কারণে জনসংখ্যায় জেনেটিক ড্রিফ্ট ঘটে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সংকরায়ণ পদ্ধতিতে ফুল থেকে পরাগধানীর অপসারণকে কী বলা হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্রসিং
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আইসোলেশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্টেরিলাইজেশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ইমাসকুলেশন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  নিম্নলিখিত কোন্ অবস্থায় থাকলে ভূণাণু কোশটি একটি স্বাভাবিক
                  মানব কন্যা সন্তানের জন্ম হবে?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কেবলমাত্র একটি Y ক্রোমোজোমের উপস্থিতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  দুটি X ক্রোমোজোমের উপস্থিতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  একটি X এবং একটি Y ক্রোমোজোমের উপস্থিতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কেবলমাত্র একটি X ক্রোমোজোমের উপস্থিতি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অ্যালিলিক জিনদ্বয় কোথায় অবস্থান করে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  দুটি সমসংস্থ ক্রোমোজোমের মধ্যে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দুটি অ-সমসংস্থ ক্রোমোজোমের মধ্যে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যে-কোনো দুটি ক্রোমোজোমের মধ্যে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  একই ক্রোমোজোমের মধ্যে
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>পঞ্চম অধ্যায় - বংশগতি এবং প্রকরণ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. ব্যাক ব্রুস কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                অপত্য জনুর প্রাণী বা উদ্ভিদের সঙ্গে পিতৃজনুর যে-কোনো জীবের
                মিলনকে ব্যাক ব্রুস বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. লোকাস কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ক্রোমোজোমের উপর যে স্থানে কোনো জিন অবস্থান করে তাকে লোকাস বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. জিনোটাইপ কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                যে অন্তর্নিহিত জিন বা জিন সমাহারের জন্য জীবের কোনো চরিত্র প্রকাশ
                পায় তাকে জিনোটাইপ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. ইউক্রোমাটিন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                যে ক্রোমাটিন ইন্টারফেজ দশায় হালকাভাবে কিন্তু কোশ বিভাজনের সময়
                গাঢ়ভাবে রশ্মিত হয় তাকে ইউক্রোমাটিন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. হেটারোক্রোমাটিন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                যে ক্রোমাটিন ইন্টারফেজ দশায় গাঢ়ভাবে কিন্তু বিভাজন কালে হালকা
                বর্ণ ধারণ করে তাকে হেটারোক্রোমাটিন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. জিন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জিন হল বংশগতির গঠনগত ও কার্যগত একক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. রেকন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                DNA-র যে ক্ষুদ্রতম অংশে ক্রসিংওভার ঘটতে পারে তাকে রেকন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. মিউটন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                DNA অণুর পরিব্যক্তিক্ষম ক্ষুদ্রতম অংশ হল মিউটন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. ফিনোটাইপ কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জীবের কোনো চরিত্রের বাহ্যিক প্রকাশকেই তার ফিনোটাইপ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. প্লিওটুপি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                কোনো একটি জিনের এমন একাধিকচরিত্র নির্ধারণের ক্ষমতাকে বলে
                প্লিওট্রপি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>11. ক্রোমোজোম কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                নিউক্লিয়াস মধ্যস্থ নিউক্লিওপ্রোটিন দ্বারা গঠিত যে সূত্রাকার বা
                দণ্ডাকার বস্তু বংশানুক্রমে জৈর বৈশিষ্ট্য সঞ্চারিত করে, তাকে
                ক্রোমোজোম বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. পলিটিন ক্রোমোজোম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                অসংখ্য সূত্রাকার অংশ দিয়ে গঠিত ক্রোমোজোমকে পলিটিন ক্রোমোজোম
                বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. পাফ্ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                পলিটিন ক্রোমোজোমের কিছু অংশ স্ফীত হয় যাকে পাফ্ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. বারবডি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                স্ত্রীলোকের দেহকোশে নিউক্লিয়াসের গায়ে এবং নিউট্রোফিল
                শ্বেতকণিকার নিউক্লিয়াসের ভিতরে ছোটো একটি হেটেরোক্রোমাটিন অংশ
                থাকে, তাকে বারবড়ি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. স্ট্রিন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জিনের ক্রিয়াগত একককে সিন্টুন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. জিনতত্ত্বের জনক কে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                গ্রেগর জোহান মেন্ডেল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    17. মেন্ডেলের একসংকর জননের পরীক্ষায় জিনোটাইপ অনুপাত কী?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                1:2:1
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. YyRr-এর ফিনোটাইপ কী হবে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                9:3:3:1
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. বংশগতি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                যে পদ্ধতিতে জীবের চারিত্রিক বৈশিষ্ট্য সমূহ জনিতৃ জনু থেকে অপত্য
                জনুতে সঞ্চারিত হয় তাকে বংশগতি বা হেরেডিটি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. অ্যালিল কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                একই জিনের বিভিন্ন রূপকেই অ্যালিল বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>21. মেন্ডেলবাদ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                একসংকর ও বিসংকর জনন থেকে প্রাপ্ত মেন্ডেলের সূত্রগুলিকে
                সাধারণভাবে মেন্ডেলবাদ বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. বিভেদ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                একই প্রজাতিভুক্ত জীবেদের আকার, আকৃতি ও ব্যবহারগত পার্থক্যকে
                বিভেদ বা ভেরিয়েশন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. মিউটেশন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জীবের মধ্যে স্যারণযোগ্য হঠাৎ জেগে ওঠা স্থায়ী কোনো পরিবর্তনকে
                মিউটেশন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. মেন্ডেলের পরীক্ষায় তিনি কোন্ গাছ ব্যবহার করেছিলেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                মটর গাছ (Pisum sativum)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. জিনোম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ডিপ্লয়েড জীবের হ্যাপ্লয়েড ক্রোমোজোম সেটে যেসব জিন থাকে তাদের
                একত্রে জিনোম বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. মাল্টিপল অ্যালিল কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                কোনো জিনে অ্যালিল সংখ্যা যখন দুই-এর বেশি হয় তখন তাদের মাল্টিপল
                অ্যালিল বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. মেন্ডেল ক-জোড়া মটর গাছ ব্যবহার করেছিলেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                সাতজোড়া।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. একসংকরায়ণ কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                একটি চরিত্রের জন্য বিপরীতধর্মী দুটি জীবের মিলনকে একসংকরায়ণ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. দ্বিসংকরায়ণ কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                দুটি চরিত্রের বিপরীতধর্মী দুটি জীবের মিলনকে দ্বিসংকরায়ণ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. সংকর কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                দুটি ভিন্ন বৈশিষ্ট্যসম্পন্ন জীবের মিলনের ফলে মিশ্র
                বৈশিষ্ট্যযুক্ত যে জীবের সৃষ্টি হয় তাকে সংকর বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. সম্পূর্ণ লিংকেজ কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                কোনো ক্রোমোজোমে জিনগুলির খুবই কাছাকাছি অবস্থানের ফলে, কসিং ওভার
                না ঘটায় অপত্যদের মধ্যে জনিতৃ সংযুক্তি দেখা যায় না, নতুন
                সংযুক্তি দেখা যায়, সেই ঘটনাকে সম্পূর্ণ লিংকেজ বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. অসম্পূর্ণ লিংকেজকাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                একই ক্রোমোজোমে অবস্থানকারী জিনগুলির যখন জনিতৃ জনু থেকে অপত্য
                জনুতে সঞ্চারিত হয় তখন ওই জিনগুলির মধ্যে ক্রসিংওভার ও
                রিকম্বিনেশন ঘটার ফলে উক্ত জিনগুলি অপত্যে বিচ্ছিন্নভাবে সঞ্চালিত
                হয়। জিনগুলির এরূপ বিচ্ছিন্নভাবে সঞ্চারিত হওয়ার প্রবণতাকে
                অসম্পূর্ণ লিংকেজ বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. অসম্পূর্ণ লিংকেজকাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সাংকেতিক চিহ্নযুক্ত যে তালিকার মাধ্যমে একটি পরিবারের বিভিন্ন
                প্রজন্মে কোনো অস্বাভাবিক জিন ঘটিত বৈশিষ্ট্যের বাহ্যিক প্রকাশ ও
                তার বংশানুক্রমিক সঞ্চারণ নির্ণয় করা যায়। সেই সাংকেতিক
                চিহ্নযুক্ত তালিকাকে পেডিগ্রি বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>4. বারবডি কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                স্ত্রীদেহে দেহকোশে বিশ্রামরত অবস্থায় নিউক্লিয়াসের অভ্যন্তরে
                নিউক্লিও পর্দা সংলগ্ন ড্রামস্টিক আকৃতির যে ঘন হেটেরোক্রোমাটিন
                বড়ি দেখা যায়, তাকে আবিষ্কারক বিজ্ঞানীর নাম অনুসারে বারবডি বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. লিংকড্ জিন (Linked gene) কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                একই ক্রোমোজোমে অবস্থিত দুই বা ততোধিক জিনের মধ্যে কম বেশি লিংকে
                দেখা যায়। ফলে তারা সহজে পরস্পর থেকে পৃথক না হয়ে একই সঙ্গে এক
                প্রজন্ম থেকে পরের প্রজন্মে সঞ্চারিত হওয়ার চেষ্টা করে, এই
                জিনগুলিকে লিংকড্ জিন বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. প্লিওট্রপি কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যখন নির্দিষ্ট প্রজাতির জীবে কোনো একটি জিন একইসঙ্গে পরস্পরের
                সঙ্গে সম্পর্কহীন একাধিক বৈশিষ্ট্যের বহিঃপ্রকাশকে নিয়ন্ত্রণ করে,
                তাকে প্লিওট্রপি বলে। এই সংশ্লিষ্ট জিনকে প্লিওট্রপি জিন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b> উদাহরণ : </b>
                </font>{" "}
                ড্রসোফিলার ক্ষেত্রে যে জিন ক্ষয়প্রাপ্ত ডানা বৈশিষ্ট্য
                নিয়ন্ত্রণ করে, সেই একই জিনের প্রভাবে ওই মাছির পৃষ্ঠদেশে
                খাড়াভাবে রোঁয়া দেখা যায় এবং ডিম্বাশয়ের ডিমের সারিতে ডিমের
                সংখ্যা কমে যায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. ক্রসিং ওভার কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                {" "}
                মিয়োসিস কোশ বিভাজনের প্রথম প্রোফেজের প্যাকাইটিন উপদশায়
                হোমোলোগাস ক্রোমোজোমদ্বয়ের নন সিস্টার ক্রোমোটিডগুলির মধ্যে
                দেহাংশের বিনিময় এবং তার মাধ্যমে জিনের পুনঃসংযুক্তি ঘটে, তাকে
                ক্রসিং ওভার বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>8. থ্যালাসেমিয়া রোগের লক্ষণগুলি লেখ।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>থ্যালাসেমিয়া রোগের লক্ষণ : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                থ্যালাসেমিয়া রোগের লক্ষণগুলি হল -<br />
                (i) রক্তে হিমোগ্লোবিনের পরিমাণ হ্রাস পায় ফলে রক্তে অক্সিজেন
                পরিবহণ ক্ষমতা হ্রাস পায়। <br />
                (ii) আক্রান্ত ব্যক্তির দেহের বৃদ্ধি হার হ্রাস পায়।
                <br />
                (iii) থ্যালাসেমিয়ায় আক্রান্ত ব্যক্তির প্লিহার আকৃতি বৃদ্ধি
                পায়, যাকে স্পিনোমেগালি বলে।
                <br />
                (iv) থ্যালাসেমিয়া রোগের ক্ষেত্রে ঘন ঘন রক্ত সঞ্চারণের জন্য দেহে
                লোহা সঞ্চয় বৃদ্ধি পায়, অতিরিক্ত লোহা সঞ্চয়ের ফলে হৃৎপিণ্ড,
                যকৃত, অন্তঃক্ষরা তন্ত্র প্রভৃতি অঙ্গ ক্ষতিগ্রস্ত হয়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

বংশগতিওবিভেদ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(বংশগতিওবিভেদ);
