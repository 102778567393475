import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function দ্বিতীয়বিশ্বযুদ্ধওউপনিবেশসমূহ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          দ্বিতীয় বিশ্বযুদ্ধ ও উপনিবেশ সমূহ | West Bengal Class 12 Guide | West
          Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="দ্বিতীয় বিশ্বযুদ্ধ ও উপনিবেশ সমূহ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                দ্বিতীয় বিশ্বযুদ্ধ ও উপনিবেশ সমূহ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="3">
                <b>ষষ্ঠ অধ্যায় : দ্বিতীয় বিশ্বযুদ্ধ ও উপনিবেশ সমূহ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রশ্নের মান - ৮</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                1. ক্রিপস মিশনের প্রস্তাবগুলি আলোচনা করো। ক্রিপস মিশনের
                ব্যর্থতার কারণগুলি ব্যাখ্যা করো।
                <br />
                2. ভারতছাড়ো আন্দোলনের ঐতিহাসিক তাৎপর্য ব্যাখ্যা করো এবং এই
                আন্দোলনে মহিলাদের অংশগ্রহণ সম্পর্কে সংক্ষেপে আলোচনা করো।
                <br />
                3. হো-চি-মিনের নেতৃত্বে ভিয়েতনাম মুক্তি যুদ্ধের বিবরণ দাও।
                <br />
                4. মাউন্ট ব্যাটেন পরিকল্পনার মূল বিষয় কী ছিল? এই পরিকল্পনার ফল
                কী ছিল?
                <br />
                অথবা, মাউন্ট ব্যাটেন পরিকল্পনা কী? এই পরিকল্পনার বিষয়ে ভারতীয়
                নেতৃবৃন্দের কী মনোভাব ছিল?
                <br />
                5. মন্ত্রী মিশন বা ক্যাবিনেট মিশনের পরিকল্পনাগুলি কী ছিল?
                মন্ত্রী মিশনের পরিকল্পনা অনুসারে সংবিধান রচনার উদ্দেশ্যে গণপরিষদ
                রচনার প্রেক্ষাপট আলোচনা করো।
                <br />
                6. ১৯৪৬-এর নৌবিদ্রোহের কারণ ও তাৎপর্য লেখো।
                <br />
                7. ১৯৪২ খ্রিস্টাব্দের ভারতছাড়ো আন্দোলন সম্পর্কে একটি নিবন্ধ
                লেখো।
                <br />
                8. কোন্ পরিস্থিতিতে লিনলিথগো প্রস্তাব ঘোষিত হয়? ওই প্রস্তাবে কী
                বলা হয়? ভারতীয় রাজনৈতিক দলগুলি ওই প্রস্তাব সম্পর্কে কী
                প্রতিক্রিয়া জানিয়েছিল?
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রশ্নের মান - ১</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. দ্বিতীয় বিশ্বযুদ্ধের সময় জাপানের প্রধানমন্ত্রী কে ছিলেন?
                </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                দ্বিতীয় বিশ্বযুদ্ধের সময় জাপানের প্রধানমন্ত্রী ছিলেন হিদেকি
                তোজো।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    2. লিনলিথগো প্রস্তাব অথবা আগস্ট প্রস্তাব কবে ঘোষিত হয়?{" "}
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৪০ খ্রিস্টাব্দের ৮ আগস্ট লিনলিথগো প্রস্তাব অথবা আগস্ট প্রস্তাব
                ঘোষিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    3. গান্ধিজি কোন্ পত্রিকায় ‘ভারত ছাড়ো' আন্দোলনের ডাক দেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                গান্ধিজি ‘হরিজন' পত্রিকায় ‘ভারত ছাড়ো’ আন্দোলনের ডাক দেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    4. সুভাষচন্দ্র বসু কাকে ‘পূর্ব এশিয়ার ভারতের স্বাধীনতা
                    আন্দোলনের জনক' বলে অভিহিত করেন?{" "}
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                সুভাষচন্দ্র বসু রাসবিহারী বসুকে ‘পূর্ব এশিয়ার ভারতের স্বাধীনতা
                আন্দোলনের জনক’ বলে অভিহিত করেছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. তাম্রলিপ্ত জাতীয় সরকারের সর্বাধিনায়ক কে ছিলেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                তাম্রলিপ্ত জাতীয় সরকারের সর্বাধিনায়ক ছিলেন সতীশচন্দ্ৰ সামন্ত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. কোন্ জাহাজে নৌবিদ্রোহ শুরু হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বোম্বাই বন্দরে নোঙররত নৌপ্রশিক্ষণ জাহাজ ‘তলোয়ার’-এ ১৯৪৬
                খ্রিস্টাব্দের ১৮ ফেব্রুয়ারি নৌবিদ্রোহ শুরু হয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. OAS কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                OAS হচ্ছে Organisation of American State। এটি লাতিন আমেরিকার
                রাষ্ট্রগুলির একটি সংগঠন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. দক্ষিণ কোরিয়ার রাজধানী কী ছিল?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দক্ষিণ কোরিয়ার রাজধানী ছিল সিওল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. উত্তর কোরিয়ার রাজধানীর নাম কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                উত্তর কোরিয়ার রাজধানীর নাম পানমুনজম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. হো-চি-মিন কে ছিলেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভিয়েতনামের জাতীয়তাবাদী নেতা ছিলেন হো-চি-মিন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. ‘তাম্রলিপ্ত জাতীয় সরকার’-এর মুখপত্রের নাম কী ছিল?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                'তাম্রলিপ্ত জাতীয় সরকার’-এর মুখপত্রের নাম ছিল ‘বিপ্লবী’।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. ‘র‍্যাডিক্যাল ডেমোক্রেটিক পার্টি’ কে কবে গঠন করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                এম. এন. রায় ১৯৪০ খ্রিস্টাব্দে ‘র‍্যাডিক্যাল ডেমোক্রেটিক পার্টি
                গঠন করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. ‘বিদ্যুৎ বাহিনী’ কে গঠন করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                সতীশচন্দ্র সামন্ত ‘বিদ্যুৎ বাহিনী’ গঠন করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    14. 'তাম্রলিপ্ত জাতীয় সরকার’-এর নারীবাহিনীর নাম কী ছিল?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                “তাম্রলিপ্ত জাতীয় সরকার’-এর নারীবাহিনীর নাম ছিল ভগিনী সেনা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. 'আজাদ হিন্দ ফৌজ’-এর কয়েকটি ব্রিগেডের নাম করো।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ‘গান্ধি ব্রিগেড’, ‘সুভাষ ব্রিগেড’, ‘আজাদ ব্রিগেড’, ‘নেহেরু
                ব্রিগেড’, ‘ঝাঁসির রানি ব্রিগেড’ প্রভৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. আজাদ হিন্দ বাহিনীর কয়েকজন সেনাধ্যক্ষের নাম করো।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                শাহওয়াজ খান, জি. এস. ধিলো, পি. কে. সায়গল প্রমুখ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. 'আজাদ হিন্দ সরকার’ কবে, কোথায় প্রতিষ্ঠিত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৪৩ খ্রিস্টাব্দের ২১ সেপ্টেম্বর, সিঙ্গাপুরে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. ‘শহীদ দ্বীপ’ ও ‘স্বরাজ দ্বীপ’ কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আজাদ হিন্দ বাহিনীর আন্দামান ও নিকোবর দ্বীপপুঞ্জ বিজয়ের পর
                সুভাষচন্দ্র এদের নামকরণ করেন যথাক্রমে ‘শহীদ দ্বীপ’ ও ‘স্বরাজ
                দ্বীপ’।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. ভারত কবে প্রজাতান্ত্রিক রাষ্ট্রে পরিণত হয়? </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৫০ খ্রিস্টাব্দের ২৬ জানুয়ারি ভারত প্রজাতান্ত্রিক রাষ্ট্রে
                পরিণত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. স্বাধীন ভারতের সংবিধান কবে গৃহীত হয় ও কবে কার্যকরী হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ‘খসড়া কমিটি’র তৈরি করা সংবিধান ১৯৪৯ খ্রিস্টাব্দের ২৬ নভেম্বর
                গণপরিষদে গৃহীত হয় এবং ১৯৫০ খ্রিস্টাব্দের ২৬ জানুয়ারি তা
                কার্যকরী হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. ইন্দোনেশিয়া কোন্ কোন্ দ্বীপের সমন্বয়ে গড়ে উঠেছে?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                প্রশান্ত মহাসাগরের বুকে জেগে ওঠা জাভা, সুমাত্রা, বালি, বোর্নিও
                সহ সহস্রাধিক ছোটো ছোটো দ্বীপের সমন্বয়ে গড়ে উঠেছে বিশ্বের
                সর্ববৃহৎ দ্বীপরাষ্ট্র ইন্দোনেশিয়া।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    22. স্বাধীন ভারতের সংবিধান কবে গৃহীত হয় ও কবে কার্যকরী হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ‘খসড়া কমিটি'র তৈরি করা সংবিধান ১৯৪৯ খ্রিস্টাব্দের ২৬ নভেম্বর
                গণপরিষদে গৃহীত হয় এবং ১৯৫০ খ্রিস্টাব্দের ২৬ জানুয়ারি থেকে তা
                কার্যকরী হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. নেভারে প্ল্যান কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভিয়েতমিনদের সম্পূর্ণ ধ্বংস করার উদ্দেশ্যে ফরাসি সেনাপতি নেভারে
                এক নতুন পরিকল্পনা গ্রহণ করেন, যা ‘নেভারে প্ল্যান' নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. বুলগানিন কে ছিলেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বুলগানিন ছিলেন সোভিয়েত রাশিয়ার প্রতিরক্ষা মন্ত্রী।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. কে, কাদের নিয়ে GRD প্রতিষ্ঠা করে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৪৯ খ্রিস্টাব্দের অক্টোবর মাসে পশ্চিমি শক্তিবর্গের প্রতি
                ক্ষুব্ধ হয়ে রাশিয়া পূর্ব জার্মানির GRD (জার্মান ডেমোক্র্যাটিক
                রিপাবলিক) প্রতিষ্ঠা করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. কবে এবং কারা বার্লিন প্রাচীর প্রতিষ্ঠা করে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পূর্ব জার্মান সেনাবাহিনী ১৯৬১ খ্রিস্টাব্দে পূর্ব ও পশ্চিম
                জার্মানির মধ্যে কংক্রিটের বার্লিন প্রাচীর প্রতিষ্ঠা করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. কবে বার্লিন প্রাচীর ধ্বংস হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জার্মানবাসীর জনরোষের আঘাতে ১৯৮৯ খ্রিস্টাব্দের ৭ নভেম্বর বার্লিন
                প্রাচীর ধ্বংস হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. ক্যাবিনেট মিশন কেন ভারতে আসে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের স্বাধীনতা ও ক্ষমতা হস্তান্তর সম্পর্কে বিভিন্ন রাজনৈতিক দল
                ও ব্যক্তির সঙ্গে আলোচনার জন্য ১৯৪৬ খ্রিস্টাব্দে তিন সদস্যের
                ক্যাবিনেট মিশন বা মন্ত্রী মিশন ভারতে আসে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. ক্যাবিনেট মিশনের তিনজন সদস্যের নাম লেখো।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ক্ষমতা হস্তান্তরের উদ্দেশ্যে ১৯৪৬ খ্রিস্টাব্দে ভারতে আগত তিন
                সদস্যের ক্যাবিনেট মিশন বা মন্ত্রী মিশনের সদস্যরা ছিলেন—ভারত সচিব
                স্যার পেথিক লরেন্স, সভাপতি স্যার স্ট্যাফোর্ড ক্রিপস এবং
                নৌবাহিনীর প্রধান এ. ভি. আলেকজান্ডার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. কে, কবে আজাদ হিন্দ ফৌজ গঠন করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রবাসী ভারতীয় বিপ্লবী রাসবিহারী বসু, ১৯৪২ খ্রিস্টাব্দের ১
                সেপ্টেম্বর সিঙ্গাপুরে জাপানের হাতে বন্দি ভারতীয় সেনাদের নিয়ে
                আজাদ হিন্দ ফৌজ গঠন করেন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>31. ‘বৃহত্তর পূর্ব এশিয়া যুগ্ম বিকাশ তত্ত্ব'টি কার?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                পূর্ব এশিয়ার রাজনীতিতে নিজের আধিপত্য স্থাপনের উদ্দেশ্যে জাপান
                ১৯৩০-৪০ এর দশকে এই নীতি গ্রহণ করেছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. ‘তানাকা মেমোরিয়াল' কী?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জাপানের উগ্র সাম্রাজ্যবাদী প্রধানমন্ত্রী তানাকা জাপানের সম্রাট
                হিরোহিতোর কাছে ১৯২৭ খ্রিস্টাব্দে প্রেরিত এক প্রতিবেদনে জানান যে,
                পূর্ব এশিয়ার সমস্যা সমাধানের জন্য জাপানের উচিত যুদ্ধনীতি গ্রহণ
                করা। এই প্রতিবেদন ‘তানাকা মেমোরিয়াল' নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. ‘মেইজি পুনরুত্থান' কাকে বলে?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮৬৮ খ্রিস্টাব্দে শোগুন যোশিনোবু (কেইকি)-র পদত্যাগের মধ্য দিয়ে
                জাপানে দীর্ঘ ২৬৮ বছর (১৬০০-১৮৬৮) স্থায়ী শোগুনতন্ত্রের অবসান ঘটে
                এবং ১৪ বছর বয়স্ক মেইজি সম্রাট মুৎসোহিতো ক্ষমতায় পুনঃপ্রতিষ্ঠিত
                হন। এইভাবে শোগুনতন্ত্রের পতনের মধ্য দিয়ে মেইজি শাসকদের পুনরায়
                ক্ষমতায় অধিষ্ঠানকে ‘মেইজি পুনরুত্থান' বা 'Restoration বলে
                অভিহিত করা হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    34. ১৯৩৯ খ্রিস্টাব্দে কংগ্রেসি মন্ত্রীসভাগুলি পদত্যাগ করেছিল
                    কেন?
                  </b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতীয় জাতীয় নেতাদের সঙ্গে কোনোরূপ আলাপ-আলোচনা না করে ব্রিটিশ
                সরকার ভারতকে দ্বিতীয় বিশ্বযুদ্ধে জড়িয়ে দেওয়ায় কংগ্রেস
                ক্ষুব্ধ হয় এবং প্রতিবাদে আটটি প্রদেশের কংগ্রেস মন্ত্রীসভা
                পদত্যাগ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. ক্রিপস্ মিশন কবে ও কেন ভারতে এসেছিল?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দ্বিতীয় বিশ্বযুদ্ধচলাকালীন ১৯৪২ খ্রিস্টাব্দের ২৩
                মার্চক্রিপস্মিশন ভারতে আসে। দ্বিতীয় বিশ্বযুদ্ধে ভারতীয়দের
                পূর্ণ সাহায্য ও সহযোগিতা লাভের উদ্দেশ্যে কিছু সাংবিধানিক
                সংস্কারের প্রস্তাব নিয়ে ভারতীয় নেতৃবৃন্দের সঙ্গে আলোচনার জন্য
                ক্রিপস্ মিশন ভারতে আসে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  36. ভারতের রাজনৈতিক দলগুলি ‘ক্রিপস প্রস্তাব’ প্রত্যাখ্যান
                  করেছিল কেন?
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ক্রিপস্ প্রস্তাবে পূর্ণ স্বাধীনতার প্রস্তাব ছিল না; ভারতীয়
                প্রতিরক্ষা ব্যবস্থার ওপর ভারতীয়দের কোনো অধিকার ছিল না এবং
                প্রদেশগুলিকে বিচ্ছিন্ন থাকার অধিকার দেওয়া হয় বলে জাতীয়
                কংগ্রেস এই প্রস্তাবের বিরোধিতা করে। অন্যদিকে এই প্রস্তাবে পৃথক
                পাকিস্তান রাষ্ট্র গঠনের কোনো প্রতিশ্রুতি ছিল না বলে মুসলিম লিগ
                এই প্রস্তাবের বিরোধিতা করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    37. সুভাষচন্দ্র বসু কোন্ অধিবেশনে প্রথমবার জাতীয় কংগ্রেসের
                    সভাপতি মনোনীত হন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৩৮ খ্রিস্টাব্দে, জাতীয় কংগ্রেসের হরিপুরা অধিবেশনে সুভাষচন্দ্র
                বসু প্রথমবার জাতীয় কংগ্রেসের সভাপতি মনোনীত হন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    38. সুভাষচন্দ্র বসু কোন্ অধিবেশনে দ্বিতীয়বার জাতীয়
                    কংগ্রেসের সভাপতি নির্বাচিত হন? তিনি কাকে পরাজিত করেন?
                  </b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৩৯ খ্রিস্টাব্দে, জাতীয় কংগ্রেসের ত্রিপুরি অধিবেশনে
                সুভাষচন্দ্র বসু দ্বিতীয়বার জাতীয় কংগ্রেসের সভাপতি নির্বাচিত
                হন। তিনি গান্ধিজি-সমর্থিত প্রার্থী ড. পট্টভি সীতারামাইয়াকে
                পরাজিত করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    39. সুভাষচন্দ্র বসু কবে ও কেন জাতীয় কংগ্রেসের সভাপতির পদ
                    থেকে ইস্তফা দেন?
                  </b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জাতীয় আন্দোলনের অভিমুখ নিয়ে গান্ধিজি ও সুভাষচন্দ্রের মধ্যে
                দৃষ্টিভঙ্গিগত পার্থক্য ছিল। এমতাবস্থায়, ১৯৩৯ খ্রিস্টাব্দে
                জাতীয় কংগ্রেসের ত্রিপুরি অধিবেশনে সুভাষচন্দ্র গান্ধিজির আপত্তি
                অগ্রাহ্য করে দ্বিতীয়বার কংগ্রেসের সভাপতি পদপ্রার্থী হন এবং
                গান্ধি-সমর্থিত প্রার্থীকে পরাজিত করে সভাপতি নির্বাচিত হন।
                কিন্তু, এর পরেই সুভাষচন্দ্র গান্ধি-গোষ্ঠীর ক্রমাগত অসহযোগিতার
                সম্মুখীন হন এবং শেষ পর্যন্ত বাধ্য হয়ে ১৯৩৯ খ্রিস্টাব্দে
                কংগ্রেসের সভাপতির পদ থেকে ইস্তফা দেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>40. ঝাঁসির রানির বাহিনী বিখ্যাত কেন?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                স্বাধীনতার মরণপণ সংগ্রামে নারী সমাজের বীরত্বপূর্ণ অংশগ্রহণের
                উজ্জ্বলতম দৃষ্টান্তটি হল নেতাজি সুভাষচন্দ্রের আজাদি সেনার ঝাঁসির
                রানি ব্রিগেড। দক্ষিণ-পূর্ব এশিয়ার প্রায় ১৫০০ নারীকে নিয়ে এই
                বাহিনী গড়ে ওঠে। শ্রীমতী লক্ষ্মী স্বামীনাথন এই বাহিনীর নেতৃত্ব
                গ্রহণ করেন। এই বাহিনীর সদস্যরা ‘রানি’ নামে অভিহিত হতেন। ১৯৪৪
                খ্রিস্টাে বার্মার যুদ্ধে ঝাঁসির রানি বাহিনী খুবই সক্রিয় ভূমিকা
                গ্রহণ করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>41. ‘প্রত্যক্ষ সংগ্রাম'-এর ডাক কেন দেওয়া হয়েছিল?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ক্যাবিনেট মিশনের প্রস্তাব অনুযায়ী ১৯৪৬ খ্রিস্টাব্দের আগস্টে
                জওহরলাল নেহরুর নেতৃত্বে একটি অন্তবর্তী সরকার গঠনের সিদ্ধান্ত হলে
                মুসলিম লিগ তথা জিন্না অত্যন্ত ক্রুদ্ধ হন। সম্ভাব্য হিন্দু
                প্রাধান্য থেকে মুক্তি ও ‘পাকিস্তান’ দাবি আদায়ের জন্য সরকারের
                উপর চাপ বৃদ্ধির উদ্দেশ্যে জিন্না ১৯৪৬ খ্রিস্টাব্দের ১৬ আগস্ট
                প্রত্যক্ষ সংগ্রামের ডাক দেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>42. ভারতের স্বাধীনতা আইনে কী বলা হয়েছিল?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের স্বাধীনতা আইনে বলা হয় - <br />
                (ক) ১৯৪৭ খ্রিস্টাব্দের ১৫ আগস্ট ভারত ও পাকিস্তান নামে দুটি
                স্বাধীন রাষ্ট্র গঠিত হবে; <br />
                (খ) দুটি রাষ্ট্রের পৃথক আইনসভা থাকবে;
                <br />
                (গ) নতুন সংবিধান রচিত না হওয়া পর্যন্ত উভয় রাষ্ট্রেই ১৯৩৫
                খ্রিস্টাব্দের ভারত শাসন আইন বলবৎ থাকবে;
                <br />
                (ঘ) দেশীয় রাজ্যগুলি নিজেদের স্বাধীন অস্তিত্ব বজায় রাখতে পারবে
                অথবা ভারত ও পাকিস্তান—যে-কোনো একটি রাষ্ট্রে যোগদান করতে পারবে
                প্রভৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    43. ক্রিপস্ মিশনের প্রস্তাবে গান্ধিজি কী প্রতিক্রিয়া ব্যক্ত
                    করেন?{" "}
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ক্রিপস্ প্রস্তাবে পূর্ণ স্বাধীনতার প্রসঙ্গ না থাকায় হতাশ
                গান্ধিজি একে ‘ফেল পড়া ব্যাঙ্কের উপর আগামি দিনের চেক’ ('a
                post-dated cheque on a crashing bank') বলে অভিহিত করেছেন। আর
                ‘হরিজন’ পত্রিকায় তিনি এই প্রস্তাবকে ‘দুর্ভাগ্যজনক প্রস্তাব’
                (‘the ill-fated proposal') বলে অভিহিত করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>44. সি. আর. ফর্মুলা কী?/‘রাজাজী সূত্র’ বলতে কী বোঝ? </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জাতীয় অখণ্ডতা বজায় রেখে লিগের পৃথক পাকিস্তান রাষ্ট্রের দাবি
                প্রতিহত করার জন্য কংগ্রেসে নেতা চক্রবর্তী রাজা গোপালাচারী ১৯৪৪
                খ্রিস্টাব্দে যে সম্রাট সমাধানসূত্র পেশ করেন, তা তাঁর নামানুসারে
                সি. আর. ফর্মুলা বা রাজাজী সূত্র নামে পরিচিত। এই পরিকল্পনায়
                প্রথমে ক্ষমতা হস্তান্তর এবং পরে গণভোটের মাধ্যমে দেশভাগের
                বিষয়টির নিষ্পত্তির কথা বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>45. রসিদ আলি দিবস কবে এবং কেন পালিত হয়েছিল?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দিল্লির সামরিক আদালত আজাদ হিন্দ ফৌজের ক্যাপ্টেন রশিদ আলিকে ৭
                বছরের সশ্রম কারাদণ্ডে দণ্ডিত করলে তার মুক্তির দাবিতে মুসলিম
                লিগের ছাত্র সংগঠন ১৯৪৬-খ্রিস্টাব্দের ১১ ফেব্রুয়ারি কলকাতায়
                ছাত্র ধর্মঘটের ডাক দেয়। সেদিন দুপুরে ছাত্ররা মিছিল করে ডালহৌসি
                স্কোয়ারের দিকে যেতে চাইলে পুলিশ ছাত্র মিছিলের উপর গুলি চালায়।
                এর পরদিন অর্থাৎ ১২ ফেব্রুয়ারি রশিদ আলি দিবস পালনের ডাক দেওয়া
                হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>46. কার নেতৃত্বে ঝাঁসি রেজিমেন্ট গঠিত হয়?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সুভাষচন্দ্রের আহ্বানে সিঙ্গাপুর নিবাসী প্রবাসী ভারতীয় চিকিৎসক
                শ্রীমতী লক্ষ্মী স্বামীনাথন তাঁর উজ্জ্বল কর্মজীবন ছেড়ে ‘ঝাঁসির
                রানী ব্রিগেড’-এর নেতৃত্ব দিতে এগিয়ে আসেন। তিনি ‘ক্যাপ্টেন
                লক্ষ্মী’ নামে পরিচিত ছিলেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    47. ‘এশিয়াবাসীদের জন্য এশিয়া' এই স্লোগানের উদ্দেশ্য কী
                    ছিল?{" "}
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ‘এশিয়াবাসীদের জন্য এশিয়া'—জাপানের এই স্লোগানের উদ্দেশ্য ছিল
                মুখ্যত দুটি —<br />
                (i) এশিয়ায় মার্কিন আগ্রাসন রোধ করা; এবং
                <br />
                (ii) এশিয়ায় জাপানের সাম্রাজ্য লিপ্সা চরিতার্থ করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    48. ভারতের ইতিহাসের প্রেক্ষিতে ১৯৪০ খ্রিস্টাব্দের আগস্ট
                    ঘোষণা বলতে কী বোঝো?{" "}
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দ্বিতীয় বিশ্বযুদ্ধে ভারতবাসীর সমর্থন তথা সাহায্য লাভের জন্য
                বড়োলাট লর্ড লিনলিথগো ১৯৪০ খ্রিস্টাব্দের আগস্ট মাসে (৮ আগস্ট)
                কয়েকটি সম্রাট সংস্কারের কথা ঘোষণা করেন - <br />
                (ক) যুদ্ধান্তে সব রাজনৈতিক দলের প্রতিনিধিদের নিয়ে সংবিধান রচনার
                ব্যবস্থা করা, <br />
                (খ) বড়োলাটের কার্যনির্বাহী পরিষদে অধিক সংখ্যক ভারতীয় গ্রহণ,{" "}
                <br />
                (গ) ভারতের রাজনৈতিক দলগুলিকে নিয়ে যুদ্ধ পরামর্শদাতা সংস্থা গঠন
                প্রভৃতি। এগুলি ‘আগস্ট ঘোষণা’ নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>49. সিমলা বৈঠক কবে ও কেন আহ্বান করা হয়?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৪৫ খ্রিস্টাব্দের ২৫ জুন সিমলা বৈঠকের আয়োজন করা হয়েছিল।
                রাজনৈতিক অচলাবস্থা দূরীকরণ ও ক্ষমতা হস্তান্তরের প্রেক্ষাপট
                হিসেবে বড়োলাট লর্ড ওয়াভেল ১৯৪৫ খ্রিস্টাব্দের ১৪ জুন যে
                পরিকল্পনা পেশ করেছিলেন, সেই পরিকল্পনার বিষয়গুলি নিয়ে জাতীয়
                নেতৃবৃন্দের সঙ্গে আলোচনার জন্য তিনি এই বৈঠকের আহ্বান করেছিলেন।
                কংগ্রেসের তরফে মৌলানা আবুল কালাম আজাদ ও মুসলিম লিগের পক্ষে
                জিন্না এই বৈঠকে প্রতিনিধিত্ব করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>50. ‘র‍্যাডক্লিফ রোয়েদাদ’ কী?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পাঞ্জাব ও বাংলার কোন্ কোন্ অঞ্চল ভারত ও পাকিস্তানের অন্তর্ভুক্ত
                হবে তা নির্ধারণের জন্য স্যার সিরিল র‍্যাডক্লিফের নেতৃত্বে একটি
                সীমান্ত কমিশন গঠন করা হয়। ১৯৪৭ খ্রিস্টাব্দের ১৭ আগস্ট
                র‍্যাডক্লিফ এ ব্যাপারে চূড়ান্ত রায় ঘোষণা করেন। এটি
                ‘র‍্যাডক্লিফ রোয়েদাদ’ নামে খ্যাত।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

দ্বিতীয়বিশ্বযুদ্ধওউপনিবেশসমূহ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(দ্বিতীয়বিশ্বযুদ্ধওউপনিবেশসমূহ);
