/*
 * AboutComponent Messages
 *
 * This contains all the text for the AboutComponent component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.AboutComponent';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the AboutComponent component!',
  },
});
