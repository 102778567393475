import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function ঠান্ডালড়াইয়েরযুগ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          ঠান্ডা লড়াইয়ের যুগ | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="ঠান্ডা লড়াইয়ের যুগ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                ঠান্ডা লড়াইয়ের যুগ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ফুলটন বক্তৃতা (১৯৪৬ খ্রিস্টাব্দ) দিয়েছিলেন — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রুজভেল্ট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইটালি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্ট্যালিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  চার্চিল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতের প্রথম অ-কংগ্রেসি প্রধানমন্ত্রী কে ছিলেন? </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মোরারজি দেশাই
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চন্দ্রশেখর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিশ্বনাথ প্রতাপ সিং
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চরণ সিং
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>জিয়োনিস্টদের সংগঠনের সভাপতি ছিলেন — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আরাফাত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নাসের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ওয়াইজম্যান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বেন গুরিয়ান
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সুয়েজ সংকট সৃষ্টি হয়েছিল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৯৪৫ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৯৪৮ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ১৯৫৬ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৯৬৮ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ১৯৪৯ সালে সোভিয়েত-বিরোধী যে সামরিক চুক্তি হয়েছিল, তা হল —{" "}
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্রাসেলস্
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ওয়ারশ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ন্যাটো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সিয়েটো
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সুয়েজ খাল জাতীয়করণ করেন -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চার্চিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নেহরু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  নাসের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  টিটো
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মিশরের কোন্ শাসকের আমলে সুয়েজ খাল খনন করা হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জেনারেল নগুইব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নাসের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সৈয়দ পাশা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফ্যারাও খুফু
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>‘ঠান্ডা লড়াই’ শব্দটিকে জনপ্রিয় করেন — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বার্নাড বারুস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফ্রান্জ ফ্যানন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডেভিড টমসন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ওয়াল্টার লিপম্যান
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>২৭ দফা দাবি পেশ করা হয়েছিল কোন্ সম্মেলনে? </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নতুন দিল্লি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বান্দুং
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বেলগ্রেড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তেহেরান
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>‘দাঁতাত’ কথার অর্থ — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উত্তেজনা বৃদ্ধি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যুদ্ধ ঘোষণা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ঠান্ডা লড়াই
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  উত্তেজনা প্রশমন
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b> সপ্তম অধ্যায় : ঠান্ডা লড়াইয়ের যুগ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b> প্রশ্নের মান - ৮</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                1. ঠান্ডা লড়াই বলতে কী বোঝায়? ঠান্ডা লড়াইয়ের তাত্ত্বিক
                ভিত্তি ব্যাখ্যা করো।
                <br />
                2. “ট্রুম্যান নীতি ও মার্শাল পরিকল্পনা কী? ইউরোপে কীভাবে পরস্পর
                বিরোধী সামরিক জোট গড়ে ওঠে?
                <br />
                3. ১৯৫০-এর দশকে আন্তর্জাতিক রাজনীতিতে কমিউনিস্ট চিনের প্রভাব
                নিরূপণ করো।
                <br />
                4. তেল কূটনীতি বলতে কী বোঝায়? এই কূটনীতি কীভাবে উপসাগরীয় সংকট
                ঘটিয়েছিল?
                <br />
                5. কিউবার ক্ষেপণাস্ত্র সংকটের সংক্ষিপ্ত পরিচয় দাও। <br />
                6. পূর্ব ইউরোপের সোভিয়েতিকরণের উদ্দেশ্য কী ছিল? বিভিন্ন দেশে এর
                কী প্রভাব পড়েছিল?
                <br />
                7. জোটনিরপেক্ষ নীতি কী? জোট নিরপেক্ষ আন্দোলনের উদ্দেশ্য আলোচনা
                করো।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b> প্রশ্নের মান - ২</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. উত্তর ভিয়েতনামের কমিউনিস্ট বাহিনী কী নামে পরিচিত ছিল?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                উত্তর ভিয়েতনামের কমিউনিস্ট বাহিনী ‘ভিয়েতমিন' নামে পরিচিত ছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. দক্ষিণ কোরিয়ার রাজধানী কী ছিল?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দক্ষিণ কোরিয়ার রাজধানী ছিল সিওল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. বুলগানিন কে ছিলেন? </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বুলগানিন ছিলেন সোভিয়েত রাশিয়ার প্রতিরক্ষামন্ত্রী।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. স্বাধীন ইন্দোনেশিয়ার প্রথম প্রধানমন্ত্রী কে ছিলেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                স্বাধীন ইন্দোনেশিয়ার প্রথম প্রধানমন্ত্রী ছিলেন মহম্মদ হাত্তা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. ন্যাটো (NATO)-র পুরো কথা কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ন্যাটো (NATO)-এর পুরো নাম হল – North Atlantic Treaty
                Organisation (উত্তর আটলান্টিক চুক্তি সংস্থা)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. উত্তর কোরিয়ার রাজধানীর নাম কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                উত্তর কোরিয়ার রাজধানীর নাম পানমুনজম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. হো-চি-মিন কে ছিলেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভিয়েতনামের জাতীয়তাবাদী নেতা ছিলেন হো-চি-মিন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. ড. সুকর্ণ কে ছিলেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ইন্দোনেশিয়ার জাতীয়তাবাদী আন্দোলনের প্রধান নেতা ও প্রথম
                প্রেসিডেন্ট।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    9. ইন্দোনেশিয়ার জাতীয়তাবাদী দল কবে, কার নেতৃত্বে গঠিত হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯২৭ খ্রিস্টাব্দে ড. সুকর্ণ-র নেতৃত্বে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. জোট নিরপেক্ষ দেশগুলির প্রথম শীর্ষ সম্মেলন কোন্‌টি?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৬১ খ্রিস্টাব্দে অনুষ্ঠিত বেলগ্রেড সম্মেলন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. চার্লস্-দ্য-গল কে ছিলেন?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                পঞ্চম ফরাসি প্রজাতন্ত্রের প্রেসিডেন্ট।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    12. জোট নিরপেক্ষ আন্দোলনের প্রধান তিনজন প্রবক্তার নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                উত্তর ভারতের প্রথম প্রধানমন্ত্রী জওহরলাল নেহ্রু, মিশরের
                রাষ্ট্রপ্রধান গামাল নাসের ও যুগোশ্লাভিয়ার প্রেসিডেন্ট মার্শাল
                টিটো।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. পুরো নাম লেখো : U.S.A ও U.S.S.R </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                U.S.A. — United States of America.
                <br />
                U.S.S.R.―Union of Soviet Socialist Republics.
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. পঞ্চশীল নীতি কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৫৪ খ্রিস্টাব্দের এপ্রিল মাসে ভারত ও চিনের মধ্যে সম্পর্ক
                নির্ধারণের ক্ষেত্রে ভারতের প্রধানমন্ত্রী জওহরলাল নেহরু ও চিনের
                প্রধানমন্ত্রী চৌ এন-লাই-এর মধ্যে যে-পাঁচটি চুক্তি স্বাক্ষরিত
                হয়, তা পঞ্চশীল নীতি নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. বুলগানিন কে ছিলেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বুলগানিন ছিলেন সোভিয়েত রাশিয়ার প্রতিরক্ষা মন্ত্রী।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. কে, কাদের নিয়ে GRD প্রতিষ্ঠা করে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৪৯ খ্রিস্টাব্দের অক্টোবর মাসে পশ্চিমি শক্তিবর্গের প্রতি
                ক্ষুব্ধ হয়ে রাশিয়া পূর্ব জার্মানির GRD (জার্মান ডেমোক্র্যাটিক
                রিপাবলিক) প্রতিষ্ঠা করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. হিটলার কবে রাশিয়া আক্রমণ করে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জার্মান সম্রাট হিটলার ১৯৪১ খ্রিস্টাব্দে রাশিয়া আক্রমণ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. কবে মার্শাল পরিকল্পনার অবসান ঘটে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৫২ খ্রিস্টাব্দে মার্শাল পরিকল্পনার অবসান ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    19. মার্শাল পরিকল্পনার বিপরীতে রাশিয়া কী পরিকল্পনা গ্রহণ
                    করে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                রাশিয়া ১৯৪৯ খ্রিস্টাব্দে Communist Economic Union (COMECON)
                নামে একটি পরিকল্পনা গ্রহণ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. ঠান্ডা লড়াইয়ের উদ্ভবকালে আমেরিকার নেতৃত্বাধীন কয়েকটি
                    সামরিক জোটের নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                NATO, SEATO, MEDO, CENTO, ANZUS প্রভৃতি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. ফিদেল কাস্ত্রো কে ছিলেন?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ফিদেল কাস্ত্রো ছিলেন কিউবার সমাজতান্ত্রিক নেতা। পরবর্তী সময়
                তিনি সেদেশের প্রধানমন্ত্রী এবং রাষ্ট্রপতির পদও অলঙ্কৃত করেছিলেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. দিয়েন-বিয়েন-ফু তে কী ঘটেছিল?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দিয়েন-বিয়েন-ফু ছিল টংকিং-এ অবস্থিত ফরাসি বাহিনীর একটি
                দুর্ভেদ্য ঘাঁটি। ভিয়েতমিন সেনাপতি জেনারেল গিয়াপের পরিকল্পিত
                আক্রমণে ১৯৫৪ খ্রিস্টাব্দের মে মাসে দিয়েন-বিয়েন-ফুর পতন ঘটে। এই
                ঘটনার পর ফ্রান্স উত্তর ভিয়েতনাম থেকে হাত গুটিয়ে নেয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. মার্শাল পরিকল্পনা কী?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ট্রুম্যান নীতির সুত্র ধরে মার্কিন পররাষ্ট্র সচিব জর্জ সি.
                মার্শাল ১৯৪৭ খ্রিস্টাব্দে ৫ জুন যুদ্ধ-বিধ্বস্ত ইউরোপে সাম্যবাদের
                প্রসার রোধ করার জন্য আর্থিক পুনরুজ্জীবনের যে পরিকল্পনা পেশ করেন,
                তা ‘মার্শাল প্ল্যান’ নামে পরিচিত। <br />
                <br />
                <font color="#f626d7">
                  <b>24. দাঁতাত কী?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ‘দাঁতাত’ একটি ফরাসি শব্দ, যার অর্থ উত্তেজনা প্রশমন ও বন্ধুত্বের
                পুনঃপ্রতিষ্ঠা। ১৯৬০-এর দশকে মার্কিন যুক্তরাষ্ট্র ও সোভিয়েত
                ইউনিয়নের মধ্যে এক পারস্পরিক সহাবস্থানের নীতি গৃহীত হয়। ফলে
                ঠান্ডা যুদ্ধের প্রভাব বহুলাংশে হ্রাস পায়। এই নীতি ‘দাঁতাত’ নামে
                পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. হো-চি-মিন কে ছিলেন?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                হো-চি-মিন ছিলেন ভিয়েতনামের মুক্তিযুদ্ধের পথপ্রদর্শক ও
                প্রাণপুরুষ। ১৯৪৫ খ্রিস্টাব্দে তিনি ভিয়েতনাম গণতান্ত্রিক
                প্রজাতন্ত্রের প্রথম রাষ্ট্রপতি নিযুক্ত হন। তাঁর প্রকৃত নাম
                নগুয়েন আই কুয়োক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    26. কোন্ বছর ভিয়েতনাম গণতান্ত্রিক প্রজাতন্ত্র রাষ্ট্র
                    হিসেবে ঘোষিত হয়? <br />
                    অথবা,
                    <br /> কবে ভিয়েতনাম গণতান্ত্রিক প্রজাতন্ত্র স্থাপিত হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৪৫ খ্রিস্টাব্দে ভিয়েতনাম গণতান্ত্রিক রাষ্ট্র হিসেবে ঘোষিত
                হয়। হো-চি-মিন এই প্রজাতন্ত্রের প্রথম রাষ্ট্রপতি নিযুক্ত হন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. বার্লিন অবরোধ বলতে কী বোঝায়?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বার্লিন শহরের সঙ্গে পশ্চিম জার্মানির যোগাযোগ ছিন্ন করার
                উদ্দেশ্যে সোভিয়েত ইউনিয়ন ১৯৪৮ খ্রিস্টাব্দের ২৪ জুলাই থেকে ১৯৪৯
                খ্রিস্টাব্দের ১২ মে পর্যন্ত দীর্ঘ এগারো মাস ধরে বার্লিনে
                প্রবেশের সড়কপথ অবরোধ করে রাখে। এই ঘটনা ‘বার্লিন অবরোধ নামে
                পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    28. জেনেভা সম্মেলন কেন ডাকা হয়? ১৯৫৪ খ্রিস্টাব্দের জেনিভা
                    সম্মেলনের গুরুত্ব কী?
                  </b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ইন্দোচিন সমস্যার সমাধানের জন্য ১৯৫৪ খ্রিস্টাব্দে জেনিভা সম্মেলন
                আহূত হয় এবং জেনিভা চুক্তি স্বাক্ষরিত হয়। এর ফলে—(ক) উত্তর
                ভিয়েতনামে স্বাধীন ভিয়েতমিন সরকার স্থাপিত হয় এবং এর নাম হয়
                Democratic Republic of Vietnam,
                <br />
                (খ) ভিয়েতনামে ফরাসি সাম্রাজ্যবাদের অবসান ঘটে এবং (গ) লাওস ও
                কম্বোডিয়া স্বাধীনতা অর্জন করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. প্রথম নির্জোট সম্মেলন কোথায় অনুষ্ঠিত হয়?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৫৫খ্রিস্টাব্দে ইন্দোনেশিয়ার বান্দুং সম্মেলনের মধ্য দিয়ে
                নির্জোট আন্দোলনের আনুষ্ঠানিক সূচনা হয়। আর ১৯৬১ খ্রিস্টাব্দে
                যুগোশ্লাভিয়ার বেলগ্রেড সম্মেলনকে নির্জোট দেশগুলির প্রথম শীর্ষ
                সম্মেলন রূপে গণ্য করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. কেন্নান-এর বেষ্টনী নীতি কী?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বেষ্টনী নীতির প্রবক্তা হলেন মার্কিন কূটনীতিবিদ জে. এফ. কেন্নান।
                Mr. X ছদ্মনামে American Foreign Affairs নামক পত্রিকায় লেখা এক
                প্রবন্ধে তিনি সাম্যবাদের অগ্রগতি প্রতিহত করার জন্য সোভিয়েত
                রাশিয়াকে তার দখলীকৃত অঞ্চলের মধ্যে সীমাবদ্ধ রাখার কথা বলেন। এই
                নীতি ‘বেষ্টনী নীতি’ নামে পরিচিত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>31. ইয়াসের আরাফাত কে ছিলেন?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ইয়াসের আরাফাত ছিলেন প্যালেস্টাইনের একজন বিখ্যাত আরব
                জাতীয়তাবাদী নেতা। তিনি দীর্ঘদিন প্যালেস্টাইন লিবারেশন
                অর্গানাইজেশন (PLO)-এর সভাপতি পদে অধিষ্ঠিত ছিলেন। তিনি ফাতাহ্
                নামক রাজনৈতিক দলের প্রতিষ্ঠাতা এবং প্যালেস্টাইনের প্রথম
                রাষ্ট্রপতি। মধ্যপ্রাচ্যে রাজনৈতিক শান্তি ও স্থিতাবস্থা রক্ষায়
                তাঁর অসামান্য অবদানের স্বীকৃতি স্বরূপ ১৯৯৪ সালে তিনি নোবেল
                শান্তি পুরষ্কারে ভূষিত হয়েছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    32. সুয়েজ সংকট সমাধানের জন্য কোন্ ভারতীয় বিদেশমন্ত্রী
                    লন্ডন সম্মেলনে (১৯৫৬ খ্রিস্টাব্দ) যোগ দেন?
                  </b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতীয় বিদেশমন্ত্রী ভি. কে. কৃষ্ণমেনন সুয়েজ সংকট সমাধানের জন্য
                ১৯৫৬ খ্রিস্টাব্দে লন্ডন সম্মেলনে যোগদান করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. সুয়েজ সংকটের আশু কারণ কী ছিল? </b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                সুয়েজ সংকটের আশু কারণ ছিল ১৯৫৬ খ্রিস্টাব্দের ২৬ জুলাই মিশরের
                শাসক গামাল আবদেল নাসের কর্তৃত্ব সুয়েজ খাল ও সুয়েজ ক্যানাল
                কোম্পানির জাতীয়করণের সিদ্ধান্ত ঘোষণা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. ভিয়েত কং কারা?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দক্ষিণ ভিয়েতনামে দিয়েম সরকারের অপশাসনের অবসান ঘটানোর জন্য
                কমিউনিস্ট, অ-কমিউনিস্ট ও বৌদ্ধ সন্ন্যাসীদের নিয়ে ১৯৬০
                খ্রিস্টাব্দে ‘জাতীয় মুক্তি ফ্রন্ট’ গঠিত হয়। এরা উত্তর
                ভিয়েতনামের কমিউনিস্ট সরকারের কাছ থেকে অর্থ ও অস্ত্র সাহায্য
                পেতে থাকে। তাই দিয়েম সরকার তাদের ভিয়েতনামী কমিউনিস্ট বা
                ‘ভিয়েত কং’ নামে অভিহিত করেছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. ঠান্ডা যুদ্ধ কী আদর্শগত সংঘাত ছিল?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                অনেক ঐতিহাসিক ও রাষ্ট্রনীতিবিদ ঠান্ডা যুদ্ধকে আদর্শগত সংঘাত বলে
                অভিহিত করেছেন। তাদের মতে, মার্কিন পুঁজিবাদী ধ্যান-ধারণা,
                ব্যক্তিস্বাধীনতা ও গণতন্ত্রের আদর্শের সঙ্গে সোভিয়েত রাশিয়ার
                সাম্যবাদী শাসন ও অর্থনীতির তত্ত্বগত বৈপরীত্য ও সংঘাত ঠান্ডা
                যুদ্ধের উৎপত্তির প্রধান কারণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>36. নেভারে প্ল্যান কী?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভিয়েতনাম যুদ্ধে ফ্রান্সের প্রধান সেনাপতি ছিলেন জেনারেল নেভারে।
                তিনি ভিয়েতনাম সেনাদের ধ্বংস করার জন্য দিয়েন-বিয়েন-ফু-তে একটি
                দুর্ভেদ্য ঘাঁটি নির্মাণের পরিকল্পনা করেছিলেন। এটি ‘নেভারে
                প্ল্যান' নামে খ্যাত। যদিও শেষ পর্যন্ত তাঁর এই পরিকল্পনা
                বাস্তবায়িত হয়নি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. জেনারেল গিয়াপ কে ছিলেন?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ইন্দোচিনের মুক্তি সংগ্রামের ইতিহাসে ভিয়েতমিন সেনাপতি জেনারেল
                গিয়াপের নাম বিশেষ উল্লেখযোগ্য। তাঁর পরিকল্পিত আক্রমণে ১৯৫৪
                খ্রিস্টাব্দের মে মাসে দিয়েন-বিয়েন-ফু-তে অবস্থিত দুর্ভেদ্য
                ফরাসি ঘাঁটির পতন ঘটে। এই ঘটনার পর ফ্রান্স উত্তর ভিয়েতনাম থেকে
                হাত গুটিয়ে নেয়। দিয়েন-বিয়েন-ফু-র যুদ্ধে বিজয়ী সেনানায়ক
                হিসেবে তিনি ইতিহাসে স্মরণীয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    38. কোন্ কোন্ প্রদেশ নিয়ে ইন্দোচিন গঠিত? ইন্দোচিনের বর্তমান
                    নাম কী?
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কোচিন-চিন, আন্নাম, লাওস, টংকিং এবং কম্বোডিয়া - এই পাঁচটি প্রদেশ
                নিয়ে ইন্দোচিন গঠিত। ইন্দোচিনের বর্তমান নাম ভিয়েতনাম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. 'মাইলাই’ ঘটনা'টি কী?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৬৮ সালের ১৬ মার্চ ভিয়েতনামের মাইলাই নামক স্থানে আমেরিকান
                সৈন্যরা একদিনে শিশু, বৃদ্ধ ও নারী নির্বিশেষে প্রায় ৪৫০ জন
                মানুষকে হত্যা করে। এই ঘটনাই ‘মাইলাই ঘটনা’ নামে খ্যাত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>40. জেনেভা সম্মেলনে কোন্ কোন্ দেশ অংশগ্রহণ করে?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৫৪ খ্রিস্টাব্দের জেনেভা সম্মেলনে মার্কিন যুক্তরাষ্ট্র, গ্রেট
                ব্রিটেন, সোভিয়েত রাশিয়া, ফ্রান্স, ভিয়েতনাম, কম্বোডিয়া, লাওস
                প্রভৃতি রাষ্ট্রের বিদেশমন্ত্রীরা অংশগ্রহণ করেছিল।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  41. নেহরু যুগে ভারতীয় পররাষ্ট্রনীতির প্রধান বৈশিষ্ট্যগুলি কী
                  ছিল?
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                জোট নিরপেক্ষতা, শান্তিপূর্ণ উপায়ে আন্তর্জাতিক সমস্যার সমাধান,
                প্রতিবেশী দেশগুলির সঙ্গে মৈত্রী সম্পর্ক স্থাপন, বর্ণবৈষম্য,
                সাম্রাজ্যবাদ ও উপনিবেশবাদের বিরোধিতা এবং দেশে দেশে জাতীয় মুক্তি
                সংগ্রামকে সমর্থন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    42. ইয়াল্টা সম্মেলন কেন ডাকা হয়?/ ইয়াল্টা সম্মেলন কেন
                    গুরুত্বপূর্ণ?
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৪৫ খ্রিস্টাব্দে ইয়াল্টা সম্মেলন ডাকার মুখ্য উদ্দেশ্য ছিল —
                <br /> (ক) বিশ্বশান্তি ও নিরাপত্তার জন্য একটি আন্তর্জাতিক
                প্রতিষ্ঠান গড়ে তোলা,
                <br /> (খ) বিজিত জার্মানির ভাগ্য নির্ধারণ এবং <br />
                (গ) পোল্যান্ড সমস্যার সমাধান করা প্রভৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>48. কিউবার ক্ষেপণাস্ত্র সংকট বলতে কী বোঝো? </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মার্কিন আক্রমণের আশঙ্কায় কিউবা সোভিয়েত রাশিয়ার সাহায্য
                প্রার্থনা করলে বেশ কিছু রুশ ক্ষেপণাস্ত্র কিউবায় প্রতিস্থাপিত
                হয়। এই নিয়ে ১৯৬২ খ্রিস্টাব্দে মার্কিন যুক্তরাষ্ট্র ও সোভিয়েত
                রাশিয়ার মধ্যে পরমাণু যুদ্ধের সম্ভাবনা দেখা দেয়। এটি ‘কিউবা
                সংকট’ নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>49. ‘আইজেনহাওয়ার তত্ত্ব’ কী?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৫৭ খ্রিস্টাব্দের ৫ জানুয়ারি মার্কিন রাষ্ট্রপতি আইজেনহাওয়ার
                মার্কিন কংগ্রেসে মধ্যপ্রাচ্য সম্পর্কে প্রদত্ত এক ভাষণে বলেন যে,
                আন্তর্জাতিক সাম্যবাদ দ্বারা নিয়ন্ত্রিত কোনো রাষ্ট্রের, বিশেষত
                সোভিয়েত রাশিয়ার সশস্ত্র আক্রমণের বিরুদ্ধে কোনো দেশ সাহায্য
                প্রার্থনা করলে মার্কিন সরকার সেই দেশকে সামরিক সাহায্য প্রদান
                করবে। এটি ‘আইজেনহাওয়ার তত্ত্ব’ নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>50. ১৭ ডিগ্রি সমাক্ষরেখা কী?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৪৫ খ্রিস্টাব্দের জেনেভা সম্মেলনে যে অক্ষরেখা বরাবর ভিয়েতনামকে
                উত্তর ও দক্ষিণ—এই দুই ভিয়েতনামে বিভক্ত করা হয়, তাকে ১৭ ডিগ্রি
                সমাক্ষরেখা বলা হয়। এই সমাক্ষরেখার উত্তরাংশে ভিয়েতমিন এবং
                দক্ষিণাংশে ন-দিন-দিয়েম-এর শাসন প্রতিষ্ঠিত হয়েছিল।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  51. কবে এবং কাদের মধ্যে কৌশলগত অস্ত্রশস্ত্রের সীমিতকরণ চুক্তি
                  (SALT-I) স্বাক্ষরিত হয়েছিল?
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ১৯৭২ খ্রিস্টাব্দে আমেরিকা যুক্তরাষ্ট্রের রাষ্ট্রপতি রিচার্ড
                নিক্সন ও সোভিয়েত রাশিয়ার রাষ্ট্রপতি ব্রেজনেভ-এর মধ্যে SALT-I
                চুক্তি স্বাক্ষরিত হয়েছিল। এর পুরো নাম — Strategic Arms
                Limitation Talks-I.
                <br />
                <br />
                <font color="#f626d7">
                  <b>52. কবে এবং কাদের মধ্যে (SALT-II) স্বাক্ষরিত হয়েছিল?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৭৯ খ্রিস্টাব্দে আমেরিকা যুক্তরাষ্ট্রের রাষ্ট্রপতি জিমি কার্টার
                ও সোভিয়েত রাশিয়ার রাষ্ট্রপতি ব্রেজনেভ-এর মধ্যে SALT-II চুক্তি
                স্বাক্ষরিত হয়েছিল। এর পুরো নাম - Strategic Arms Limitation
                Talks-II. <br />
                <br />
                <font color="#f626d7">
                  <b>
                    53. কবে এবং কাদের মধ্যে পারমাণবিক অস্ত্রের পরীক্ষা আংশিক
                    নিরোধচুক্তি বা PTBT স্বাক্ষরিত হয়েছিল?
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৬৩ খ্রিস্টাব্দে আমেরিকা যুক্তরাষ্ট্র ও সোভিয়েত রাশিয়ার মধ্যে
                PTBT চুক্তি স্বাক্ষরিত হয়েছিল। এর পুরো নাম – Partial Nuclear
                Test Ban Treaty.
                <br />
                <br />
                <font color="#f626d7">
                  <b>54. ওপেক (OPEC) কী?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মধ্যপ্রাচ্যের তেল উৎপাদনকারী বিভিন্ন দেশকে নিয়ে গড়ে ওঠা একটি
                সংস্থা হল ওপেক বা Organisation of Petroleum Exporting Countries.
                ১৯৬০ খ্রিস্টাব্দে ইরাকের রাজধানী বাগদাদে এটি প্রতিষ্ঠিত হয়।
                আন্তর্জাতিক বাজারে অপরিশোধিত তেলের দাম নির্ধারণ, পেট্রোলিয়াম ও
                পেট্রোলিয়ামজাত পণ্যের মূল্যের স্থিতিশীলতা বজায় রাখা, তেলের
                উৎপাদন ও জোগান অব্যাহত রাখা, সদস্য রাষ্ট্রগুলির স্বার্থ সুরক্ষিত
                রাখা প্রভৃতি এই সংগঠনের মূল কাজ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>55. 'গ্লাক্তন' কী?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ‘গ্লাস্তনস্ত’ শব্দটির অর্থ মুক্ত ব্যবস্থা। ১৯২০-এর দশকে সোভিয়েত
                রাষ্ট্রপ্রধান মিখাইল গর্বাচেভ মানবাধিকার ও সাংস্কৃতিক
                কার্যকলাপের ক্ষেত্রে যে মুক্ত ব্যবস্থা গ্রহণ করেছিলেন, তা
                ‘গ্লাস্তনস্ত’ নামে পরিচিত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>56. মার্শাল টিটো কে ছিলেন?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                মার্শাল টিটো ছিলেন যুগোশ্লাভিয়ার রাষ্ট্রপ্রধান। তিনি প্রথমে
                কমিনফর্মের সদস্য থাকলেও মার্শাল পরিকল্পনা গ্রহণ করার ‘অপরাধে’
                স্ট্যালিন কর্তৃক কমিনফর্ম থেকে বহিষ্কৃত হন। পরবর্তীকালে তিনি জোট
                নিরপেক্ষ আন্দোলনের অন্যতম প্রধান নেতৃত্বে পরিণত হন। তিনি ৯৮টি
                আন্তর্জাতিক উপাধিতে ভূষিত হয়েছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>57. ট্রুম্যান নীতি কী ছিল? </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মার্কিন প্রেসিডেন্ট হ্যারি এস. ট্রুম্যান ১৯৪৭ খ্রিস্টাব্দের ১২
                মার্চ গ্রিস ও তুরস্ককে সামরিক সহায়তা দানের প্রস্তাব উত্থাপন করে
                যে নীতির জন্ম দেন, তাই ট্রুম্যান নীতি নামে পরিচিত। এই নীতির মূল
                কথা হল—কোনো দেশ (সোভিয়েত রাশিয়া) যদি অন্য কোনো দেশের
                অভ্যন্তরীণ বিশৃঙ্খলার সুযোগ নিয়ে সেখানে শাসনতান্ত্রিক অচলাবস্থা
                সৃষ্টির চেষ্টা করে, তবে আমেরিকা আক্রান্ত দেশকে সামরিক সহযোগিতা
                প্রদান করবে। উল্লেখ্য, এই নীতি ‘ঠান্ডা লড়াই’-এর পরিবেশ সৃষ্টিতে
                গুরুত্বপূর্ণ ভূমিকা পালন করেছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>58. জিওনবাদী বা জিওনিস্ট আন্দোলন বলতে কী বোঝো?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ঊনবিংশ শতকের শেষের দিকে প্যালেস্টাইনে একটি স্বাধীন ও সার্বভৌম
                ইহুদি রাষ্ট্র প্রতিষ্ঠার উদ্দেশ্যে বিশ্বের বিভিন্ন প্রান্তে যে
                আন্দোলন শুরু হয়, তা জিওনবাদী বা জিওনিস্ট আন্দোলন নামে পরিচিত।
                উল্লেখ্য, ‘জিওন’ কথাটি জেরুজালেম থেকে এসেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>59. মধ্যপ্রাচ্য বলতে কী বোঝো?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মধ্যপ্রাচ্যের কোনো সুনির্দিষ্ট ভৌগোলিক সংজ্ঞা না থাকলেও
                সাধারণভাবে উত্তরে ভূমধ্যসাগর থেকে দক্ষিণে লোহিত সাগর এবং পূর্বে
                নীলনদ থেকে পশ্চিমে পাকিস্তানের পশ্চিম সীমান্ত পর্যন্ত বিস্তৃত
                ভূভাগ মধ্যপ্রাচ্য নামে পরিচিত। লেবানন, সিরিয়া, জর্ডন, ইরাক
                প্রভৃতি মধ্যপ্রাচ্যের উল্লেখযোগ্য দেশ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>60. ব‍্যালফুর ঘোষণা' কী?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রথম বিশ্বযুদ্ধ চলাকালে ইহুদিদের সমর্থন আদায়ের উদ্দেশ্যে
                ব্রিটিশ পররাষ্ট্র সচিব আর্থার ব্যালফুর ১৯১৭ খ্রিস্টাব্দের ২
                নভেম্বর একটি বিখ্যাত ঘোষণার মাধ্যমে প্যালেস্টাইনে ইহুদিদের জন্য
                একটি জাতিরাষ্ট্র গড়ে তোলার প্রতিশ্রুতি দেন। এটি ব্যালফুর ঘোষণা
                নামে পরিচিত।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

ঠান্ডালড়াইয়েরযুগ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(ঠান্ডালড়াইয়েরযুগ);
