/**
 *
 * PrivacyPolicy
 *
 */

import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

import Colors from "../../utils/Colors";

export function PrivacyPolicy() {
  return (
    <div>
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="Privacy Policy QList - WBBSE, WB, পশ্চিমবঙ্গ"
        />
      </Helmet>

      <p>
        {"\n"}Privacy Policy{"\n"}
      </p>

      <div style={{ fontSize: 15, color: Colors.black }}>
        Last updated: [08-05-2023]{"\n"}
      </div>
      <br />
      <div style={{ fontSize: 15, color: Colors.black }}>
        Team Samanta built the QList - WBBSE, WB, পশ্চিমবঙ্গ app as a Free app.
        This SERVICE is provided by Team Samanta at no cost and is intended for
        use as is. This page informs visitors regarding my policies regarding
        the collection, use, and disclosure of Personal Information if anyone
        decides to use my Service. If you choose to use my Service, then you
        agree to the collection and use of information about this policy. The
        Personal Information I collect is used to provide and improve the
        Service. I will not use or share your information with anyone except as
        described in this Privacy Policy. The terms used in this Privacy Policy
        have the same meanings as in our Terms and Conditions, which are
        accessible at QList - WBBSE, WB, পশ্চিমবঙ্গ unless otherwise defined in
        this Privacy Policy.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Information Collection and Use
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        We collect certain information from you when you use our mobile app,
        including personal and non-personal information. Personal information
        includes your name, email address, phone number, and other information
        that you choose to provide to us. We use this information to provide you
        with our app's features and services, to communicate with you about your
        account or our app's features and services, and to personalize your
        experience with our app. Non-personal information includes technical
        information such as the type of device you are using, the operating
        system version, and other technical information. We use this information
        to improve our app's functionality and to provide better service to our
        users. We may also use cookies and other tracking technologies to
        collect information about your use of our app. Cookies are small text
        files that are stored on your device by websites and apps that you use.
        We use cookies to improve your experience with our app and to understand
        how our app is being used. We do not share any personal information
        about our users with third parties, except as necessary to provide our
        app's features and services or as required by law. We may use
        third-party service providers to help us operate our app and provide our
        services, and these service providers may have access to your personal
        information only to perform services on our behalf. We take reasonable
        measures to protect your personal information from unauthorized access,
        disclosure, alteration, or destruction. However, we cannot guarantee the
        security of your personal information. You may access, update, or delete
        your personal information by contacting us at [Your Contact
        Information]. We will respond to your request within a reasonable
        timeframe. The app does use third-party services that may collect
        information used to identify you. Link to the privacy policy of
        third-party service providers used by the app{"\n"}
        {"\n"}
        {"\n"}
        <a href="https://policies.google.com/privacy">Google Play</a>
        &nbsp;&nbsp;&nbsp;
        <a href="https://support.google.com/admob/answer/6128543?hl=en">
          Services AdMob
        </a>
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        User Data
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        We collect information from you when you use our App, including personal
        information such as your name, email address, phone number, and other
        information that you choose to provide. We use this information to
        provide you with our App's features and services, to communicate with
        you about your account or our App's features and services, and to
        personalize your experience with our App. We may also collect
        non-personal information about your use of our App, such as the type of
        device you are using, the operating system version, and other technical
        information. We do not share any personal information about our users
        with third parties, except as necessary to provide our App's features
        and services or as required by law. We may use third-party service
        providers to help us operate our App and provide our services, and these
        service providers may have access to your personal information only to
        perform services on our behalf. We take reasonable measures to protect
        your personal information from unauthorized access, disclosure,
        alteration, or destruction. However, we cannot guarantee the security of
        your personal information.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Cookies
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        We do not use cookies to collect information about you or your usage of
        our App.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Security
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        I value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and I cannot guarantee
        its absolute security.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Links to Other Sites
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by me. Therefore, I strongly advise you
        to review the Privacy Policy of these websites. I have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </div>

      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Children's Privacy
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        Our mobile app is intended for users who are 13 years of age and older.
        We do not knowingly collect personal information from children under the
        age of 13. {"\n"}
        If we become aware that we have collected personal information from a
        child under the age of 13, we will take steps to remove that information
        from our servers. {"\n"}We may collect personal information from users
        who are 13 years of age and older, including names, email addresses, and
        other information that you choose to provide to us. We use this
        information to provide you with our app's features and services, to
        communicate with you about your account or our app's features and
        services, and to personalize your experience with our app.
        {"\n"}We do not share any personal information about our users with
        third parties, except as necessary to provide our app's features and
        services or as required by law. We may use third-party service providers
        to help us operate our app and provide our services, and these service
        providers may have access to your personal information only to perform
        services on our behalf.{"\n"} We take reasonable measures to protect
        your personal information from unauthorized access, disclosure,
        alteration, or destruction. However, we cannot guarantee the security of
        your personal information.{"\n"} You may access, update, or delete your
        personal information by contacting us at nareshchandrasmt@gmail.com. We
        will respond to your request within a reasonable timeframe.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Advertising
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        Ads in our app/service are age-appropriate and do not contain any
        deceptive or inappropriate content. We clearly disclose any ads or
        sponsored content.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        APIs and SDKs
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        Our app uses APIs and SDKs to provide enhanced functionality. We may use
        APIs to allow our app to communicate with other apps or services, such
        as social media platforms. We may also use SDKs to enable features such
        as analytics or advertising.{"\n"}We understand that the use of APIs and
        SDKs in an app designed for children requires special attention to
        privacy. Therefore, we only use APIs and SDKs from reputable providers
        that comply with privacy laws and regulations, including the Children's
        Online Privacy Protection Act (COPPA) in the United States.{"\n"}
        However, our app does not collect any personal information or usage data
        through APIs or SDKs. Any data that is collected through APIs or SDKs is
        used solely for the purpose of improving the app and providing a better
        experience for children.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Changes to This Privacy Policy
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Contact Us
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us at nareshchandrasmt@gmail.com.
      </div>
    </div>
  );
}

PrivacyPolicy.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(PrivacyPolicy);
