import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function মানবকল্যাণেঅণুজীব() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          মানবকল্যাণে অণুজীব | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="মানবকল্যাণে অণুজীব | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                মানবকল্যাণে অণুজীব
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>একটি বায়োইনসেন্টিসাইডের উদাহরণ -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শুঁয়োপোকা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  টাইগার বিটল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সিল্ক মথ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মাজরা পোকা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নীচের কোন্‌টি মুক্তজীবী N2 আবদ্ধকারী ব্যাকটেরিয়া?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Rhizobium
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Beijernickia
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Azotobacter
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Rhodospirillum
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কোন্‌টি প্রস্তুত করতে ইস্ট ব্যবহৃত হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাসিটিক অ্যাসিড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পনির
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ইথাইল অ্যালকোহল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দই
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  নিম্নলিখিত কোন্ বাক্যটি সন্ধান প্রক্রিয়া সম্পর্কে সঠিক নয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পেশিকলায় সন্ধানের ফলে ইথানল তৈরি হয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Propoinibacterium পনির সন্ধানে ব্যবহৃত হয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ময়দা মাখার পরে CO2 গ্যাস উৎপন্ন হয় বলে ফুলে ওঠে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তালের রসের সন্ধানের ফলে ইথানল তৈরি হয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নীচের কোন্‌টি এন্ডোমাইকোরাইজার উদাহরণ?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Nostoc
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Agaricus
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Rhizopus
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Glomus
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নীচের কোন্ বস্তুটির উৎপাদনে ইস্ট ব্যবহৃত হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাইট্রিক অ্যাসিড ও ল্যাকটিক অ্যাসিড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লাইকেজ্ ও পেকটিসেজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চিজ ও মাখন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পাউরুটি ও বিয়ার
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  বায়োপেস্টিসাইড হিসেবে সর্বাধিক ব্যবহৃত হয় নীচের কোন্‌টি?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Bacillus thuringiensis
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Bacillus subtilis
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Lactobacillus anidophilus
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Streptococcus laits
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  Alums-এর মূলে উপস্থিত N2 সংবন্ধনকারী ব্যাকটেরিয়া নিম্নলিখিত
                  কোন্‌টি?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Azorhizobium
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Frankia
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Bradyhizobium
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Clostridium
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  Azolla ফার্নের সঙ্গে মিথোজীবী সহাবস্থান গড়ে তোলে কোন্ মৃতজীবী
                  N2 সংবন্ধনকারী সায়ানা-ব্যাকটেরিয়াম?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Tohypothrix
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Anabaena
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Nostoc
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Chlorella
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  সিউয়েজের গৌণ প্রক্রিয়াকরণ প্রধানত কোন্ ধরনের প্রক্রিয়া?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জৈবিক প্রক্রিয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যান্ত্রিক প্রক্রিয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভৌত প্রক্রিয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাসায়নিক প্রক্রিয়া
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>একাদশ অধ্যায় - মানব উন্নয়নে অণুজীব</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. মিথোজেনস কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#f626d7">
                <b> মিথোজেনস : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ব্যাকটেরিয়া যখন সেলুলোজিক বস্তুর উপর বৃদ্ধি লাভ করে এবং প্রচুর
                মাত্রায় কার্বন ডাইঅক্সাইড ও হাইড্রোজেনসহ মিথেন উৎপন্ন করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. বায়োগ্যাস কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#f626d7">
                <b> বায়োগ্যাস : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে প্রকার গ্যাস প্রাণীদের বর্জ্যবস্তু, কৃষিজ অবশিষ্টাংশ বা
                মিউনিসিপ্যালিটির জৈববর্জ্য থেকে উৎপন্ন করা হয়, তাকে বায়োগ্যাস
                বলা হয়। এটি থেকে শক্তি উৎপাদন করা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. মাইকোরাইজার গুরুত্ব লেখ।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#f626d7">
                <b> মাইকোরাইজার গুরুত্ব : </b>
              </font>
              <font color="#3c92e5">
                মাইকোরাইজার গুরুত্ব হল —<br />
                (i) মাইকোরাইজা ভূমিক্ষয় রোধ করে। <br />
                (ii) মূল দ্বারা শোষণে অনুপযোগী ফসফেটকে বিশ্লেষিত করে তাকে
                দ্রবণীয় ও শোষণযোগ্য করে তোলে। <br />
                (iii) মাইকোরাইজা উদ্ভিদ দেহে জিব্বেরেলিক অ্যাসিড ও হরমোন
                সংশ্লেষকে উদ্দীপিত করে ফলে উদ্ভিদের দেহ ত্বরান্বিত হয়।
                <br />
                (iv) মাইকোরাইজা, বিশেষ করে এক্টো মাইকোরাইজারা মূলের শোষণ তলের
                ক্ষেত্রফল বৃদ্ধি করে জল ও খনিজ লবণ শোষণে সহায়তা করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>4. মাইকোহাৰ্বিসাইড কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#f626d7">
                <b> মাইকোহাৰ্বিসাইড : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ছত্রাক বা ছত্রাকজাত উৎপাদন যা আগাছা দমনে ব্যবহৃত হয় তাকে মাইকে
                হার্বিসাইড বলে। <br />
                যেমন – Alternaria eichhorniae একটি মাইকোহাৰ্বিসাইড যা জলজ আগাছা
                কচুরীপানাকে নির্মূল করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. বায়োগ্যাস কাকে বলে? বায়োগ্যাস টেকনোলজি কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#f626d7">
                <b> বায়োগ্যাস : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে গ্যাস গোবর, মল প্রভৃতি প্রাণীজ বর্জ্য, কৃষিজ অবশিষ্টাংশ,
                মিউনিসিপ্যালিটির জৈব বর্জ্য প্রভৃতি থেকে উৎপন্ন করা হয়, যা
                দাহ্য ও শক্তি উৎপাদক তাকে বায়োগ্যাস বলে। বায়োগ্যাসে প্রধানত
                মিথেন, কার্বন-ডাই-অক্সাইড, H2S, N2 প্রভৃতি গ্যাস থাকে। <br />
                <br />
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> বায়োগ্যাস টেকনোলজি : </b>
                </font>{" "}
                যে বিশেষ জৈব রাসায়নিক পদ্ধতির সাহায্যে গোবর, মল প্রভৃতি প্রাণীজ
                বর্জ্য পদার্থ, কৃষিজ অবশিষ্টাংশ, মিউনিসিপ্যালিটির বর্জ্য
                প্রভৃতির থেকে বায়োগ্যাস উৎপন্ন করা হয়, তাকে বায়োগ্যাস
                টেকনোলজি বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. সিউয়েজ ও স্লাজ বলতে কী বোঝ?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#f626d7">
                <b> সিউয়েজ : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                "পয়প্রণালী, জলবাহিত মানুষ সহ বিভিন্ন প্রাণীর মলমূত্র, শিল্পজাত
                বর্জ্য, গৃহস্থালীর বর্জ্য, প্রভৃতি যেগুলি নর্দমার জলে দ্রবীভূত
                এবং প্রলম্বিতভাবে অবস্থান করে, সেগুলি সাধারণভাবে 'সিউয়েজ' বলা
                হয়।"
                <br />
                <br />
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> স্লাজ : </b>
                </font>{" "}
                পয়প্রণালীর জল পরিশোধন প্রক্রিয়ায় বা শিল্পজাত বর্জ্য
                প্রক্রিয়াকরণের সময় উৎপন্ন কঠিন অবশিষ্টাংশকে স্লাজ বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  7. Bioinsectiside এবং Bioherbicide বলতে কী বোঝ? উদাহরণ দাও।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#f626d7">
                <b> বায়োইনসেক্টিসাইড (Bioinsectiside) : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                "পতঙ্গ পেস্টের নিয়ন্ত্রণের জন্য যে সকল জৈব নিয়ন্ত্রণকারী
                প্রতিনিধি বা তাদের দেহ নিঃসৃত পদার্থ ব্যবহৃত হয়, তাকে
                'বায়োইনসেক্টিসাইড' বলা হয়।"
                <br />
                <br />
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> উদাহরণ : </b>
                </font>{" "}
                লেডি বার্ড বিটল নামক পতঙ্গ Bacillus thuringiensis নামক
                ব্যাকটেরিয়া।
                <br />
                <br />
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> Bioherbicide : </b>
                </font>{" "}
                অবাঞ্ছিত উদ্ভিদ বা আগাছা নিয়ন্ত্রণের জন্য যে সকল জৈব
                নিয়ন্ত্রণকারী প্রতিনিধি বা তাদের দেহ নিঃসৃত পদার্থ ব্যবহার করা
                হয় তাদের Bioherbicide বলে।
                <br />
                <br />
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> উদাহরণ : </b>
                </font>{" "}
                পার্থেনিয়াম নামক আগাছা দমনের জন্য মেক্সিকান বিটল (Zygogramma
                bicolorita) ব্যবহার করা হয়। Lantana camara নামক আগাছা
                নিয়ন্ত্রণ করার জন্য Plusia verticillala নামক পতঙ্গে শুঁয়োপোকা
                ব্যবহার করা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>8. ফ্লোকিউল ও সক্রিয় স্লাজ বলতে কী বোঝ?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#f626d7">
                <b> ফ্লোকিউল : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                সিউয়েজের সক্রিয় স্নাজ পদ্ধতিতে গৌণ পরিশুদ্ধকরণের সময়
                ট্যাঙ্কের নীচে পুঞ্জীভূত বায়োবীয় অণুজীব বা ফ্লক্সের
                ক্রিয়াশীলতায় সুক্ষ্ম প্রলম্বিত কণা ও কোলয়েডগুলি একত্রিত হয়ে
                যে দানাদার গঠন সৃষ্টি করে তাকে ফ্লোকিউল বলে।
                <br />
                <br />
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> সক্রিয় ফ্লাজ : </b>
                </font>{" "}
                সিউয়েজ ট্রিটমেন্টের গৌণ পরিশুদ্ধকরণের সময় যান্ত্রিক
                আলোড়নযুক্ত ট্যাঙ্কের নীচে পুঞ্জীভূত বায়বীয় অণুজীব (ফ্লক্স)
                এবং অধঃক্ষিপ্ত জৈব বস্তুগুলির (স্পোকিউল) একত্রে সক্রিয় স্লাজ
                বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  9. Biocontrol Agent বা জৈব নিয়ন্ত্রণকারী প্রতিনিধি বলতে কী
                  বোঝ? উদাহরণ দাও।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#f626d7">
                <b> জৈব নিয়ন্ত্রণকারী প্রতিনিধি : </b>
              </font>
              <font color="#3c92e5">
                কোনো নির্দিষ্ট ভাইরাস, ব্যাকটেরিয়া, ছত্রাক বা প্রাণীর সাহায্যে
                পরিবেশের কোনোরূপ ক্ষতিসাধন না করে পেস্ট বা অপর কোনো ক্ষতিকারক
                জীবকে নিয়ন্ত্রণের পদ্ধতিকেই জৈব নিয়ন্ত্রণ (Biocontrol) বলে এবং
                যে সকল জীব বা তাদের দেহনিঃসৃত পদার্থ ক্ষতিকারক পেস্ট বা ক্ষতিকর
                জীবকে মেরে ফেলে বা ধ্বংস করে তাদের Biocontrol Agent বা জৈব
                নিয়ন্ত্রণকারী প্রতিনিধি বলে।
                <br />
                <br />
                <font color="#1f7405">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#f626d7">
                  <b> উদাহরণ : </b>
                </font>{" "}
                Bacillus thuringiensis নামক ব্যাকটেরিয়া, লেডি বার্ড বিটস্
                প্রভৃতি।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

মানবকল্যাণেঅণুজীব.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(মানবকল্যাণেঅণুজীব);
