import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function সম্মিলিতজাতিপুঞ্জ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          সম্মিলিত জাতিপুঞ্জ | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="সম্মিলিত জাতিপুঞ্জ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                সম্মিলিত জাতিপুঞ্জ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বিশ্ব ব্যাংক কাজ শুরু করে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1948 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1945 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1956 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1950 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>জাতিপুঞ্জের চক্ষু ও কর্ণের মতো কাজ করে —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাধারণ সভা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নিরাপত্তা পরিষদ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মহাসচিব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অছি পরিষদ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  নিরাপত্তা পরিষদের স্থায়ী সদস্যের দ্বারা সিদ্ধান্ত বাতিলের
                  ক্ষমতাকে বলা হয় —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ভেটো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইমপিচমেন্ট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্বৈরাচারী ক্ষমতা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বলপূর্বক ক্ষমতা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>UNO-র সদস্য নয় এমন একটি রাষ্ট্রের নাম হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মার্কিন যুক্তরাষ্ট্র
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জাপান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ভ্যাটিকান সিটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভারত
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>রাষ্ট্র সংঘের বাজেট অনুমোদন করে —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নিরাপত্তা পরিষদ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সচিবালয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সাধারণ সভা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মহাসচিব
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>খাদ্য ও কৃষি সংস্থার সদর দপ্তর অবস্থিত -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  রোমে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লন্ডনে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মস্কোতে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্যারিসে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সম্মিলিত জাতিপুঞ্জ প্রতিষ্ঠিত হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1960 খ্রিস্টাব্দে ৪ মে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1950 খ্রিস্টাব্দে 6 মে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1945 খ্রিস্টাব্দে 22 জুন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1945 খ্রিস্টাব্দে 28 অক্টোবর
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সাধারণ সভার অধিবেশন পরিচালনা করেন -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সভাপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সহ-সভাপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অধ্যক্ষ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সদস্য
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বলকান সমস্যা দেখা দেয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1958-62
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1940-42
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1954-60
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1946-47
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সম্মিলিত জাতিপুঞ্জের সমস্ত সদস্য রাষ্ট্র সাধারণ সভার -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সভাপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সহ-সভাপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অধ্যক্ষ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সদস্য
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>চতুর্থ অধ্যায় - সম্মিলিত জাতিপুঞ্জ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. আটল্যান্টিক সনদে ক-টি নীতি ঘোষিত হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                আটল্যান্টিক সনদে ৪টি নীতি ঘোষিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. কোন্ ঘোষণাটি 'সম্মিলিত জাতিপুঞ্জ' ঘোষণা নামে পরিচিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ওয়াশিংটন ঘোষণাটি সম্মিলিত জাতিপুঞ্জ ঘোষণা নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. ওয়াশিংটন ঘোষণা কবে সংঘটিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ওয়াশিংটন ঘোষণা 1942 খ্রিস্টাব্দে সংঘটিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. ‘তেহেরান ঘোষণা’ কবে হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ‘তেহেরান ঘোষণা’ হয় 1943 খ্রিস্টাব্দে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. ‘জাতিসংঘ’ বা ‘লিগ অব নেশনস’ কবে প্রতিষ্ঠিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ‘জাতিসংঘ’ বা ‘লিগ অব নেশনস’ প্রতিষ্ঠিত হয় 1919 খ্রিস্টাব্দে
                প্রথম বিশ্বযুদ্ধের সমাপ্তির পর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. ‘জাতিসংঘ’ বা ‘লিগ অব নেশনস' কবে ধ্বংস হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ‘জাতিসংঘ’ বা ‘লিগ অব নেশনস’ ধ্বংস হয় 1939 খ্রিস্টাব্দে দ্বিতীয়
                বিশ্বযুদ্ধ শুরুর সঙ্গে সঙ্গে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. সম্মিলিত জাতিপুঞ্জের প্রতিষ্ঠা দিবস কোনটি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সম্মিলিত জাতিপুঞ্জের প্রতিষ্ঠা দিবস 24 অক্টোবর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. সম্মিলিত জাতিপুঞ্জের সংবিধানকে কী বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সম্মিলিত জাতিপুঞ্জের সংবিধানকে সনদ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. ‘ডাম্বারটন ওক্স’ সম্মেলন কবে, কোথায় হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                1944 খ্রিস্টাব্দে মার্কিন যুক্তরাষ্ট্রের ওয়াশিংটন শহরের কাছে
                ডাম্বারটন ওক্স নামক স্থানে এই সম্মেলন অনুষ্ঠিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    10. কোন্ সম্মেলনে সম্মিলিত জাতিপুঞ্জের সনদ রচনার কাজ শুরু
                    হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                1945 খ্রিস্টাব্দে সংঘটিত সানফ্রান্সিসকো সম্মেলনে সম্মিলিত
                জাতিপুঞ্জের সনদ রচনার কাজ শুরু হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. ‘UNITED NATION' শব্দটি প্রথম কে ব্যবহার করেন?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ‘UNITED NATION' শব্দটি প্রথম মার্কিন প্রেসিডেন্ট রুজভেল্ট
                ব্যবহার করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. UNESCO-র পুরো নামটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                UNESCO-র পুরো নামটি হল – United Nations Educational Scientific
                and Cultural Organization.
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. UNESCO-র সদর দপ্তরটি কোথায় অবস্থিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                UNESCO-র সদর দপ্তরটি অবস্থিত ফ্রান্সের প্যারিসে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. UNESCO-র মূল উদ্দেশ্যটি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পারস্পারিক বিশ্ব সহযোগিতার মাধ্যমে শিক্ষা, বিজ্ঞান ও সাংস্কৃতিক
                ক্ষেত্রে বিশ্বশান্তি প্রতিষ্ঠা করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    15. বিশ্ব স্বাস্থ্য সংস্থা বা WHO-এর সদর দপ্তরটি কোথায়
                    অবস্থিত?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বিশ্ব স্বাস্থ্য সংস্থ WHO-এর সদর দপ্তরটি অবস্থিত
                সুইটজারল্যান্ডের জেনেভায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. আন্তর্জাতিক মানবাধিকার দিবস কবে পালিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                10 ডিসেম্বর আন্তর্জাতিক মানবাধিকার দিবস পালিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. বিশ্ব এইড্স দিবস কবে পালিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বিশ্ব এইড্স দিবস 1 ডিসেম্বর পালিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    18. সম্মিলিত জাতিপুঞ্জ বা UNO-র বর্তমান সদস্য সংখ্যা কত?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                UNO-র বা সম্মিলিত জাতিপুঞ্জের বর্তমান সদস্য সংখ্যা 193।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. সম্মিলিত জাতিপুঞ্জের সনদে ক-টি ধারা আছে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সম্মিলিত জাতিপুঞ্জের সনদে 111 টি ধারা আছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. সম্মিলিত জাতিপুঞ্জের সনদের 1 নং ধারায় কি বর্ণিত আছে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সম্মিলিত জাতিপুঞ্জের সনদের 1 নং ধারায় জাতিপুঞ্জের চারটি
                উদ্দেশ্য বর্ণিত আছে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. সম্মিলিত জাতিপুঞ্জের ক-টি মূল অঙ্গ বা সংস্থা আছে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সম্মিলিত জাতিপুঞ্জের 6টি মূল অঙ্গ বা সংস্থা আছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. WHO-এর পুরো নামটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                WHO-এর পুরো নামটি হল—World Health Organization|
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. WTO-এর পুরো নামটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                WTO-এর পুরো নামটি হল—World Trade Organization|
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    24. বর্তমানে জাতিপুঞ্জের কোন্ অঙ্গটির অস্তিত্ব নিয়ে প্রশ্ন
                    ওঠে।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বর্তমানে জাতিপুঞ্জের অছি পরিষদ নামক অঙ্গটির অস্তিত্ব নিয়ে
                প্রশ্ন ওঠে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. বিশ্ব পার্লামেন্ট কাকে বলা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সাধারণ সভাকে বিশ্ব পার্লামেন্ট বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. সাধারণ সভার প্রতিটি সদস্য রাষ্ট্রের ভোট ক-টি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সাধারণ সভায় প্রতিটি সদস্য রাষ্ট্রের ভোট 1টি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    27. প্রতিটি সদস্য রাষ্ট্র সাধারণ সভায় কত জন প্রতিনিধি
                    প্রেরণ করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                প্রতিটি সদস্য রাষ্ট্র সাধারণ সভায় 5 জন প্রতিনিধি প্রেরণ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    28. শান্তির জন্য সম্মিলিত হওয়ার প্রস্তাব কোথায় কবে গৃহীত
                    হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                শান্তির জন্য সম্মিলিত হওয়ার প্রস্তাব সম্মিলিত জাতিপুঞ্জের
                সাধারণ সভায় 1950 খ্রিস্টাব্দের 3 নভেম্বর গৃহীত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    29. সম্মিলিত জাতিপুঞ্জের প্রধান গুরুত্বপূর্ণ সংস্থাটি কোনটি?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সম্মিলিত জাতিপুঞ্জের প্রধান গুরুত্বপূর্ণ সংস্থাটি হল নিরাপত্তা
                পরিষদ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. জাতিপুঞ্জের সনদ সংশোধন করার ক্ষমতা কাদের রয়েছে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                জাতিপুঞ্জের অন্যতম দুটি গুরুত্বপূর্ণ অঙ্গ সাধারণ সভা ও নিরাপত্তা
                পরিষদ সনদ সংশোধন করার ক্ষমতা ভোগ করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>31. নিরাপত্তা পরিষদের গঠনটি লেখো।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                নিরাপত্তা পরিষদ 15টি সদস্য রাষ্ট্র নিয়ে গঠিত। এর মধ্যে দুটি
                স্থায়ী সদস্য রাষ্ট্র ও 10টি অস্থায়ী সদস্য রাষ্ট্র।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    32. নিরাপত্তা পরিষদের পাঁচটি স্থায়ী সদস্য রাষ্ট্রের নাম
                    লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                নিরাপত্তা পরিষদের পাঁচটি স্থায়ী সদস্য রাষ্ট্রের নাম হল—মার্কিন
                যুক্তরাষ্ট্র, গ্রেট ব্রিটেন, রাশিয়া, ফ্রান্স ও চিন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. নিরাপত্তা পরিষদে ভেটো ক্ষমতা কারা ভোগ করে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                নিরাপত্তা পরিষদে ভেটো ক্ষমতা ভোগ করে পরিষদের স্থায়ী সদস্য
                রাষ্ট্রগুলো।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. WTO এর সদর দপ্তর কোথায় অবস্থিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                WTO-এর সদর দপ্তর অবস্থিত সুইটজারল্যান্ডের জেনেভা শহরে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. UNICEF এর সদর দপ্তর কোথায় অবস্থিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                UNICEF-এর সদর দপ্তর মার্কিন যুক্তরাষ্ট্রের নিউ ইয়র্কে অবস্থিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>36. IBRD-এর সদর দপ্তর কোথায় অবস্থিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                IBRD-এর সদর দপ্তর অবস্থিত মার্কিন যুক্তরাষ্ট্রের ওয়াশিংটন ডি
                সি-তে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. UNICEF-এর পুরো নামটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                UNICEF-এর পুরো নামটি হল United Nations Children's Emergency
                Fund|
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. FAO-এর পুরো নামটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                FAO-এর পুরো নামটি হল— Food and Agricultural Organization|
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. বিশ্বব্যাংকের প্রধান উদ্দেশ্য কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বিশ্বব্যাংকের অন্যতম প্রধান উদ্দেশ্য হল বিশ্বব্যাপী অর্থনৈতিক
                উন্নয়ন ও অর্থনৈতিক পুনর্গঠন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>40. জাতিপুঞ্জের মহাসচিব কীভাবে নির্বাচিত হন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                নিরাপত্তা পরিষদের সুপারিশক্রমে সাধারণ সভা কর্তৃক মহাসচিব
                নির্বাচিত হন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>41. মহাসচিব কে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                জাতিপুঞ্জের প্রধান প্রশাসনিক অধিকর্তা হলেন মহাসচিব।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    42. বর্তমানে অর্থনৈতিক ও সামাজিক পরিষদের সদস্য সংখ্যা কত?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বর্তমানে অর্থনৈতিক ও সামাজিক পরিষদের সদস্য সংখ্যা হল 58।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    43. পূর্বে অর্থনৈতিক ও সামাজিক পরিষদের সদস্য সংখ্যা কত ছিল?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পূর্বে অর্থনৈতিক ও সামাজিক পরিষদের সদস্য সংখ্যা ছিল 18।
                <br />
                <br />
                <font color="#f626d7">
                  <b>44. অর্থনৈতিক ও সামাজিক পরিষদের একটি কাজ উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অর্থনৈতিক ও সামাজিক পরিষদের একটি অন্যতম কাজ হল জাতিপুঞ্জের বিশেষ
                সংস্থাগুলো কাজকর্মের প্রতিনিয়ত রিপোর্ট সংগ্রহ করা এবং সাধারণ
                সভায় তা উত্থাপন করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    45. অর্থনৈতিক ও সামাজিক পরিষদের সদস্যদের কার্যকালের মেয়াদ
                    কত?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অর্থনৈতিক ও সামাজিক পরিষদের সদস্যদের কার্যকালের মেয়াদ 3 বছর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    46. অর্থনৈতিক ও সামাজিক পরিষদের অধীনে কয়েকটি বিশেষজ্ঞ
                    সংস্থার নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অর্থনৈতিক ও সামাজিক পরিষদের অধীনে কয়েকটি বিশেষজ্ঞ সংস্থার নাম
                হল WTO, WHO, UNICEF, FÃO ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    47. আন্তর্জাতিক শ্রম সংস্থা বা ILO-র সদর দপ্তরটি কোথায়
                    অবস্থিত?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আন্তর্জাতিক শ্রম সংস্থা বা ILO-র সদর দপ্তরটি অবস্থিত
                সুইটজারল্যান্ডের জেনেভায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>48. সম্মিলিত জাতিপুঞ্জের যৌথ নিরাপত্তার নীতিটি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বিশ্বব্যাপী জাতিপুঞ্জের নিয়ন্ত্রণে বৃহৎ শক্তিধর রাষ্ট্রগুলোর
                যৌথ নেতৃত্বে নিরাপত্তার নিশ্চিতকরণই হল জাতিপুঞ্জের যৌথ
                নিরাপত্তার নীতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>49. আন্তর্জাতিক নারী দিবস কবে পালিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আন্তর্জাতিক নারী দিবস পালিত হয় ৪ মার্চ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>50. আন্তর্জাতিক সাক্ষরতা দিবস কবে পালিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আন্তর্জাতিক সাক্ষরতা দিবস পালিত হয় ৪ সেপ্টেম্বর।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>51. IMF-এর সদর দপ্তর কোথায় অবস্থিত?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                IMF-এর সদর দপ্তর অবস্থিত আমেরিকা যুক্তরাষ্ট্রের ওয়াশিংটন ডি
                সিতে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>52. FAO এর সদর দপ্তর কোথায় অবস্থিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                FAO এর সদর দপ্তর অবস্থিত ইটালির রোম শহরে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>53. সাধারণ সভায় কতজন সভাপতি ও সহ-সভাপতি উপস্থিত থাকেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সম্মিলিত জাতিপুঞ্জের সাধারণ সভায় 1 জন সভাপতি ও 21 জন সহ-সভাপতি
                থাকেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>54 IMF এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ইন্টারন্যাশনাল মনিটারি ফান্ড।
                <br />
                <br />
                <font color="#f626d7">
                  <b>55. UNO-এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ইউনাইডেট নেশনস্ অরগানাইজেশন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>56. আন্তর্জাতিক বিচারালয়ের একটি কাজ লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আন্তর্জাতিক বিবাদ ও বিরোধের মীমাংসা করে আন্তর্জাতিক শান্তি বজায়
                রাখা আন্তর্জাতিক বিচারালয়ের একটি অন্যতম গুরুত্বপূর্ণ কাজ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>57. মহাসচিবের একটি কাজ লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মহাসচিবের অন্যতম গুরুত্বপূর্ণ কাজটি হল জাতিপুঞ্জের বার্ষিক
                কাজকর্মের রিপোর্ট প্রস্তুত করে সাধারণ সভা ও নিরাপত্তা পরিষদে তা
                পেশ করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>58. সম্মিলিত জাতিপুঞ্জের বর্তমান মহাসচিবের নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সম্মিলিত জাতিপুঞ্জের বর্তমান সহাসচিবে নাম হল অ্যান্টোনিও গুটারেস
                (পোর্তুগাল)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>59. কে, কেন সাধারণ সভাকে বিশ্ব নাগরিক সভা বলেছেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অধ্যাপক গেটেল সাধারণ সভাকে বিশ্ব নাগরিক সভা বলেছেন। কারণ, সাধারণ
                সভায় সম্মিলিত জাতিপুঞ্জের সকল সদস্য রাষ্ট্রের প্রতিনিধিগণ
                উপস্থিত থাকেন। এই কারণে সাধারণ সভায় বিশ্বের বিভিন্ন প্রান্তের
                মানবজাতির সমস্যা ও কণ্ঠস্বর প্রতিফলিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>60. 'শান্তির জন্য ঐক্য’ প্রস্তাব কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                1950 খ্রিস্টাব্দের 3 নভেম্বর ‘শান্তির জন্য ঐক্য’ প্রস্তাবটি
                সাধারণ সভায় গৃহীত হয়। এই প্রস্তাবটির অর্থ হল বিশ্ব শান্তি ও
                নিরাপত্তা রক্ষার্থে ভেটো প্রয়োগের ফলে যদি নিরাপত্তা পরিষদ তাঁর
                দায়িত্ব পালনে ব্যর্থ হয় তাহলে সাধারণ সভা সেই দায়িত্ব পালন
                করবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  61. সম্মিলিত জাতিপুঞ্জের কয়েকটি মূল অঙ্গ বা সংস্থার নাম লেখো।
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সম্মিলিত জাতিপুঞ্জের কয়েকটি মূল অঙ্গ বা সংস্থার নাম হল— (i)
                সাধারণ সভা, (ii) নিরাপত্তা পরিষদ, (iii) সচিবালয় এবং (iv)
                অর্থনৈতিক সামাজিক পরিষদ ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    62. সম্মিলিত জাতিপুঞ্জের 1 নং ধারায় বর্ণিত দুটি উদ্দেশ্য
                    লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সম্মিলিত জাতিপুঞ্জের 1 নং ধারায় বর্ণিত দুটি উদ্দেশ্য হল— (i)
                আন্তর্জাতিক শান্তি ও নিরাপত্তা রক্ষা ও (ii) মানুষের মানবিক
                অধিকার ও মৌল স্বাধীনতার সংরক্ষণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    63. সম্মিলিত জাতিপুঞ্জের 2 নং ধারায় বর্ণিত দুটি নীতি উল্লেখ
                    করো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সম্মিলিত জাতিপুঞ্জের 2 নং ধারায় বর্ণিত দুটি নীতি হল – (i) সদস্য
                রাষ্ট্রগুলোর সম-সার্বভৌমত্বের নীতি এবং (ii) কোনো রাষ্ট্রের
                অভ্যন্তরীণ বিষয়ে হস্তক্ষেপের বিরোধীতার নীতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>64. সম্মিলিত জাতিপুঞ্জের দুটি সাফল্যতার দৃষ্টান্ত দাও?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সম্মিলিত জাতিপুঞ্জের অন্যতম দুটি সাফল্য হল— (i) আন্তর্জাতিক
                পারমাণবিক অস্ত্র প্রতিরোধে সাফল্যতা অর্জন। (ii) সুয়েজ সংকট,
                ইরাক-ইরান যুদ্ধ, উপসাগরীয় যুদ্ধ প্রভৃতি ক্ষেত্রে আন্তর্জাতিক
                সমস্যা সমাধানের মাধ্যমে বিশ্বশান্তি ও নিরাপত্তা রক্ষার সাফলতা
                অর্জন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>65. জাতিপুঞ্জের দুটি ব্যর্থতার উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                জাতিপুঞ্জের আফগানিস্তানের উপর মার্কিন অন্যতম দুটি ব্যর্থতা হল –
                (i) 2001-2002 খ্রিস্টাব্দে আগ্রাসন। (ii) সাম্প্রতিক 2022 সালে
                রাশিয়া-ইউক্রেন যুদ্ধ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>66. বাফার স্টেট কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আন্তর্জাতিক রাজনীতিতে শক্তিসাম্যের ক্ষেত্রে দুটি বৃহৎ শক্তিধর
                রাষ্ট্রের মধ্যবর্তী স্থানে নিরপেক্ষভাবে অবস্থান করে যে ক্ষুদ্র
                রাষ্ট্র আন্তর্জাতিক শক্তি সাম্য বজায় রাখে, তাকে বাফার স্টেট
                বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    67. নিরাপত্তা পরিষদের স্থায়ী সদস্য রাষ্ট্রগুলোর ভেটো ক্ষমতা
                    বলতে কি বোঝো?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                নিরাপত্তা পরিষদের স্থায়ী সদস্য রাষ্ট্রগুলো পদ্ধতিগত বিষয় ছাড়া
                অন্যান্য গুরুত্বপূর্ণ বিষয়ে যে নেতিবাচক বা অসম্মতিসূচক ভোট
                প্রদান করে থাকে তাকে ভেটো ক্ষমতা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>68. দ্বৈত ভেটো কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                নিরাপত্তা পরিষদের স্থায়ী সদস্যগণ পদ্ধতিগত বিষয় ছাড়া অন্যান্য
                গুরুত্বপূর্ণ একই বিষয়ে দু-বার ভেটো ক্ষমতা প্রয়োগ করলে তাকে
                দ্বৈত ভেটো বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    69. নিরাপত্তা পরিষদের কোন্ সদস্য রাষ্ট্র সর্বাধিক ভেটো
                    ক্ষমতা প্রয়োগ করেছে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাশিয়া সর্বাধিক ভেটো ক্ষমতা প্রয়োগ করেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    70. নিরাপত্তা পরিষদের অস্থায়ী সদস্যদের কারা নির্বাচন করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                নিরাপত্তা পরিষদের অস্থায়ী সদস্যরা জাতিপুঞ্জের সাধারণসভার
                সদস্যের সংখ্যাগরিষ্ঠ ভোটে 2 বছরের জন্য নির্বাচিত হয়ে থাকে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  71. নিরাপত্তা পরিষদের দুটি শাস্তিমূলক ব্যবস্থা উল্লেখ করো।
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                নিরাপত্তা পরিষদের দুটি শাস্তিমূলক ব্যবস্থা হল – (i) শান্তি
                বিঘ্নিতকারী দেশের সঙ্গে কূটনৈতিক সম্পর্ক ছিন্ন করা। (ii)
                শান্তিবিঘ্নিতকারী দেশের সঙ্গে প্রয়োজনে সকল প্রকার অর্থনৈতিক
                সম্পর্ক ছিন্ন করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>72. নিরাপত্তা পরিষদের সনদ সংশোধন ক্ষমতাটি কীরূপ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সনদ সংশোধনের কোনো প্রস্তাব সাধারণ সভার অংশ সদস্য দ্বারা ভোটে
                গৃহীত হলে এবং সম্মিলিত জাতিপুঞ্জের = অংশ সদস্য ও নিরাপত্তা
                পরিষদের স্থায়ী সদস্য দ্বার তা সমর্থিত হলে সাংবিধানিক পদ্ধতি
                অনুসারে সনদ সংশোধনের বিষয়টি কার্যকরী হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>73. নিরাপত্তা পরিষদের একটি দূর্বলতা লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                নিরাপত্তা পরিষদের সর্ববৃহৎ দূর্বলতাটি হল পরিষদের স্থায়ী সদস্য
                রাষ্ট্রগুলো ভেটো প্রয়োগের ক্ষমতা। জাতিপুঞ্জের কোনো একটি
                গুরুত্বপূর্ণ সিদ্ধান্ত স্থায়ী সদস্য রাষ্ট্রের কোনো একটি
                রাষ্ট্রের ভেটো তা কার্যকর হতে বাঁধা দেয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>74. এচিসন পরিকল্পনা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভেটো ক্ষমতার অহেতুক প্রয়োগের মধ্য দিয়ে আন্তর্জাতিক শক্তি ও
                নিরাপত্তা রক্ষার ক্ষেত্রে নিরাপত্তা পরিষদ তাঁর দায়িত্ব পালনে
                ব্যর্থ হলে সাধারণ সভা উক্ত দায়িত্ব পালন করবে, এটিই এচিসন
                পরিকল্পনার মূল উদ্দেশ্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>75. ‘সম্মিলিত জাতিপুঞ্জ’ বা UNO কবে প্রতিষ্ঠিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সম্মিলিত জাতিপুঞ্জ’ বা UNO প্রতিষ্ঠিত হয় দ্বিতীয় বিশ্বযুদ্ধ
                সমাপ্তির পর 1945 খ্রিস্টাব্দের 24 অক্টোবর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    76. UNO বা ‘সম্মিলিত জাতিপুঞ্জের পূর্বসূরি প্রতিষ্ঠানটির নাম
                    কী?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                UNO বা 'সম্মিলিত জাতিপুঞ্জের পূর্বসূরি প্রতিষ্ঠানটির নাম 'জাতি
                সংঘ’ বা ‘লিগ অব নেশনস'।
                <br />
                <br />
                <font color="#f626d7">
                  <b>77. আটল্যান্টিক সনদ কবে, কাদের মধ্যে স্বাক্ষরিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আটল্যান্টিক সনদ 1941 খ্রিস্টাব্দের 14 আগস্ট তৎকালীন ব্রিটিশ
                প্রধানমন্ত্রী চার্চিল ও মার্কিন রাষ্ট্রপতি রুজভেন্টের মধ্যে
                স্বাক্ষরিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>78. 'প্রিন্স অফ ওয়েলস' কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                চার্চিল ও রুজভেন্ট যে যুদ্ধ জাহাজে মিলিত হয়েছিল তার নাম
                ‘প্রিন্স অফ ওয়েলস'।
                <br />
                <br />
                <font color="#f626d7">
                  <b>79. UNO প্রতিষ্ঠিত হবার সময় এর সদস্য সংখ্যা কত ছিল?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                UNO প্রতিষ্ঠিত হবার সময় এর সদস্য সংখ্যা ছিল 51।
                <br />
                <br />
                <font color="#f626d7">
                  <b>80. ‘হায়ার অ্যান্ড ফায়ার’ নীতি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                উত্তর হায়ার অ্যান্ড ফায়ার নীতিটি হল কাজ থাকলে শ্রমিকদের ডেকে
                নেওয়া এবং কাজ না থাকলে তাদের তাড়িয়ে দেওয়া।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>81. অস্টিনের মতে সার্বভৌমিকতা কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                অস্টিনের মতে সার্বভৌমিকতা হল অধস্তনের প্রতি ঊর্ধ্বতন কর্তৃপক্ষের
                আদেশ বা নির্দেশ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>82. রাষ্ট্রীয় সার্বভৌমিকতার ক-টি দিক্ ও কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাষ্ট্রীয় সার্বভৌমিকতার দুটি দিক্ দিগুলো হল—অভ্যন্তরীণ ও
                বাহ্যিক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>83. কূটনীতি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                জাতীয় রাষ্ট্রগুলি যে নীতির মাধ্যমে আন্তর্জাতিক রাজনীতিতে তাদের
                ভূমিকা নির্ধারণ করে তাকে কূটনীতি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>84. ‘ডিক্রি অন্ পিস্’ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সোভিয়েত সরকার গোপন কূটনীতি বিলোপ করে জনগণের সামনে প্রকাশ্যে
                যাবতীয় আপস মীমাংসা করার ব্যাপারে যে দৃঢ় সংকল্প ঘোষণা করেন তাই
                হল ‘ডিক্রি অন পিস'।
                <br />
                <br />
                <font color="#f626d7">
                  <b>85. অছি ব্যবস্থা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                নিজের দেশের অর্থনৈতিক ক্ষমতা স্বাধীনতার মাধ্যমে ফিরে পাওয়ার
                উপযুক্ত হলে জাতিপুঞ্জ ওইসব অঞ্চলের স্বাধীনতা ফিরে পাওয়ার যে
                ব্যবস্থা গ্রহণ করে তাকে অছি ব্যবস্থা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    86. আন্তর্জাতিক বিচারালয়ের বিচারপতিরা কীভাবে নিযুক্ত হন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>{" "}
                সাধারণ সভায় ও নিরাপত্তা পরিষদে আলাদা আলাদাভাবে অনুষ্ঠিত ভোটে যে
                প্রার্থীরা সবথেকে বেশি ভোট পান তাঁরাই আন্তর্জাতিক বিচারালয়ের
                বিচারপতি হিসেবে নিযুক্ত হন।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

সম্মিলিতজাতিপুঞ্জ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(সম্মিলিতজাতিপুঞ্জ);
