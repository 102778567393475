import { FETCH_POPULAR_CHANNELS, FETCH_CHANNEL_INFO } from "./constants";

export const sagaActions = {
  FETCH_POPULAR_CHANNELS: "FETCH_POPULAR_CHANNELS",
  FETCH_CHANNEL_INFO: "FETCH_CHANNEL_INFO",
};

export function fetchChnnelInfo() {
  return {
    type: FETCH_CHANNEL_INFO,
  };
}
export function fetchPopulareChannels() {
  return {
    type: FETCH_POPULAR_CHANNELS,
  };
}
