/**
 *
 * HomePage
 *
 */

import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";

import DrawerComponent from "../../components/DrawerComponent";
import CarouselComponent from "../../components/CarouselComponent/Loadable";
import ServiceComponent from "../../components/ServiceComponent/Loadable";
import AboutComponent from "../../components/AboutComponent/Loadable";
import FooterComponent from "../../components/FooterComponent/Loadable";
import OwlCarouselComponent from "../../components/OwlCarouselComponent/Loadable";
// import { useInjectSaga } from 'utils/injectSaga';
// import { useInjectReducer } from 'utils/injectReducer';
import makeSelectHomePage, {
  getTypesAndTopics,
  getCurrentChannels,
} from "./selectors";
import { setType, setTopic } from "./reducer";
import { fetchChnnelInfo, fetchPopulareChannels } from "./sagaActions";

export function HomePage({
  fetchPopulareChannelsInfo,
  allData,
  setPopularType,
  setPopularTopic,
  currentChannels,
}) {
  useEffect(() => {
    // When initial state username is not null, submit the form to load repos
    fetchPopulareChannelsInfo();
  }, []);
  return (
    <>
      <Helmet>
        <title>QList | WB | CBSE</title>
        <meta name="description" content="QList | WB | CBSE" />
        <meta
          name="keywords"
          content="West Bengal Madhyamik Suggestion, Exam Preparation, Madhyamik Guide, WBBSE, WB, CBSE"
        />
      </Helmet>
      {/* <DrawerComponent
        allData={allData}
        setPopularType={setPopularType}
        setPopularTopic={setPopularTopic}
        currentChannels={currentChannels}
      /> */}
      <CarouselComponent />
      <ServiceComponent />
      <AboutComponent />
      {/* <FooterComponent /> */}
      {/* <OwlCarouselComponent /> */}
    </>
  );
}

// HomePage.propTypes = {
//   dispatch: PropTypes.func.isRequired,
// };

const mapStateToProps = createStructuredSelector({
  allData: getTypesAndTopics(),
  currentChannels: getCurrentChannels(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchPopulareChannelsInfo: (evt) => dispatch(fetchPopulareChannels()),
    setPopularType: (type) => dispatch(setType(type)),
    setPopularTopic: (topic) => dispatch(setTopic(topic)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(HomePage);
