import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function SpecificChapterSample() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>CHAPTERTITLE</title>
        <meta name="description" content="Description of CHAPTERDESCRIPTION" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                CHAPTERH1
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                      // style="border-radius: 30px 0 0 30px;"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                      // style="border-radius: 30px 0 0 30px;"
                    >
                      PRACTICE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          MCQQUESTIONS
          <p className="mb-4 mcqQuestion">CHAPTERQUESTION</p>
          <div className="row gy-2 gx-4 mb-4">
            <div className="col-sm-12">
              <p className="mb-0 px-3">
                <i className="fa fa-arrow-right text-primary me-2"></i>
                CHAPTERQUESTIONOPTION1
              </p>
            </div>
            <div className="col-sm-12">
              <p className="mb-0 px-3 text-primary">
                <i className="fa fa-arrow-right text-primary me-2"></i>
                CHAPTERQUESTIONOPTION2
              </p>
            </div>
            <div className="col-sm-12">
              <p className="mb-0 px-3">
                <i className="fa fa-arrow-right text-primary me-2"></i>
                CHAPTERQUESTIONOPTION3
              </p>
            </div>
            <div className="col-sm-12">
              <p className="mb-0 px-3">
                <i className="fa fa-arrow-right text-primary me-2"></i>
                CHAPTERQUESTIONOPTION4
              </p>
            </div>
          </div>
        </div>
      )}
      {isNotes && (
        <div>
          CHAPETERNOTES
          <div>
            <font color="#f626d7" size="4">
              <b>পৃষ্ঠা - ১৪</b>
            </font>
          </div>
          <br />{" "}
          <font color="#3c92e5">
            <font color="#f626d7">
              <b> ❑ </b>{" "}
            </font>{" "}
            <font color="#f626d7">
              <b>
                Let's listen and say... (লেট’স লিসেন অ্যান্ড সে)–এসো শুনি এবং
                বলি......
              </b>
            </font>
            <br />
            <br />
            <font color="#f626d7">
              <b> ❑ </b>{" "}
            </font>{" "}
            <font color="#f626d7">Twinkle, twinkle little star</font>
            <br /> <font color="#2a9a08">উচ্চারণ : </font>
            <font color="#ed9c23"> [ট্যুইংকল, ট্যুইংকল লিট্ল স্টার]</font>
            <br />
            <font color="#2a9a08"> বঙ্গানুবাদ : </font>
            <font color="#3c92e5">ক্ষুদ্র তারকা ঝিক্‌মিক্‌ করছ, </font>
            <br />
            <br />
            <font color="#f626d7">
              <b> ❑ </b>{" "}
            </font>{" "}
            <font color="#f626d7">How I wonder what you are! </font>
            <br /> <font color="#2a9a08">উচ্চারণ : </font>
            <font color="#ed9c23">[হাউ আই ওয়ান্ডার হোয়াট ইউ আর্।]</font>
            <br />
            <font color="#2a9a08"> বঙ্গানুবাদ : </font>
            <font color="#3c92e5">তুমি যে কী তা ভেবে আমি অবাক হই!</font>
            <br />
            <br />
            <font color="#f626d7">
              <b> ❑ </b>{" "}
            </font>{" "}
            <font color="#f626d7">Up above the world so high </font>
            <br /> <font color="#2a9a08">উচ্চারণ : </font>
            <font color="#ed9c23">[আপ্ অ্যাবাভ্ দ্য ওআরল্স্ সো হাই]</font>
            <br />
            <font color="#2a9a08"> বঙ্গানুবাদ : </font>
            <font color="#3c92e5">আকাশের এত উঁচুতে</font>
            <br />
            <br />
            <font color="#f626d7">
              <b> ❑ </b>{" "}
            </font>{" "}
            <font color="#f626d7">Like a diamond in the sky.</font>
            <br /> <font color="#2a9a08">উচ্চারণ : </font>
            <font color="#ed9c23">
              {" "}
              [লাইক্ অ্যা ডাইঅ্যাম্যান্ড্র ইন্‌ দ্য স্কাই।]
            </font>
            <br />
            <font color="#2a9a08"> বঙ্গানুবাদ : </font>
            <font color="#3c92e5"> একটি হিরের টুকরোর মতো ঝিক্‌মিক্‌ করছ।</font>
            <br />
            <br />
            <font color="#f626d7">
              <b> ❑ </b>{" "}
            </font>{" "}
            শব্দার্থ : twinkle (টুইংকল) — ঝিক্‌মিক্‌ করা। <br />
            little (লিট্ল) — ছোটো। star (স্টার) — তারা, নক্ষত্র।
            <br />
            how (হাউ) — কেমন করিয়া। I(আই) — আমি। <br />
            wonder (ওয়ান্ডার) —বিস্ময়। what (হোয়াট্) — কী।
            <br />
            you (ইউ) — তুমি। are (আর) — বহুবচনে হয়। <br />
            up (আপ) — উপরের দিকে। above (অ্যাবাভ্) — উপরে।
            <br />
            world (ওয়ার) — পৃথিবী। so (সো) — ততখানি, সেটুকু। <br />
            high (হাই) — উঁচু। like (লাইক্) — সদৃশ/পছন্দ,
            <br />a (অ্যা) — একটি। diamond (ডাইঅ্যামানড্) — হিরে।
            <br /> in (ইন) — ভেতরে। sky (স্কাই) — আকাশ।
          </font>
        </div>
      )}
    </div>
  );
}

SpecificChapterSample.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(SpecificChapterSample);
