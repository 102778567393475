import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function বাস্তুতন্ত্র() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          বাস্তুতন্ত্র | West Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="বাস্তুতন্ত্র | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                বাস্তুতন্ত্র
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>উদ্ভিদের পক্ষে সর্বাপেক্ষা উপযুক্ত বসতি কোন্‌টি?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইকোটাইপ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইকোক্লাইন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মাইক্লোহ্যাবিট্যাট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইকোলজিক্যাল হ্যাবিট্যাট
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  পুকুর/ঘাসজমির বাস্তুতন্ত্রে সর্বাপেক্ষা বেশি সংখ্যক ________
                  লক্ষ করা যায়।{" "}
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  উৎপাদক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিরোজক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  খাদক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সর্বোচ্চ খাদক
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>প্রতিটি বাস্তুতন্ত্রে সবুজ উদ্ভিদগুলি হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  উৎপাদক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিরোজক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  খাদক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সর্বোচ্চ খাদক
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  উদ্ভিদদের বাস্তুতান্ত্রিক পর্যায়ক্রমের (Plant Succession)
                  প্রতিটি অন্তর্বর্তী পর্যায়কে বলে —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সেরি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পাইওনিয়ার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্লাইম্যাক্স পর্যায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সোরাস
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> বাস্তুতন্ত্র সংরক্ষণ করা যায় কোন্‌টি নির্মাণ করে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ন্যাশনাল পার্ক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বায়োস্ফিয়ার রিজার্ভ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ওয়ার্ল্ড লাইফ স্যাংচুয়ারি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জার্মপ্লাজন ব্যাংক
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মনুষ্যসৃষ্ট স্থলজ বাস্তুতন্ত্র হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তৃণভূমি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অরণ্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাকোরিয়াম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বোটানিক্যাল গার্ডেন ও পার্ক
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>একটি নির্দিষ্ট অঞ্চলের জীবগোষ্ঠীকে বলা হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লিথোস্ফিয়ার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জীবসম্প্রদায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বায়োস্ফিয়ার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বায়োমাস
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কোন্‌টি নাইট্রিফাইং ব্যাকটেরিয়া?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  নাইট্রোব্যাকটর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্যাসিলাস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সিউডোমোনাস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্লসটিডিয়াম
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অ্যামোনিফাইং ব্যাকটেরিয়াম হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Clostridium
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Nitrosomonas
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Bacillus
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Azotobactor
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সিংহ হল - </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উৎপাদক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  শিকারি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রাথমিক খাদক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গৌণ খাদক
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>চতুর্দশ অধ্যায় - বাস্তুতন্ত্র</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 1. দুটি মাইক্রোকনজিউমারের উদাহরণ দাও।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                {" "}
                ব্যাকটেরিয়া ও ছত্রাক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. পুষ্টিস্তর কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                পুষ্টির প্রকৃতি অনুসারে বাস্তুতন্ত্রে জীবগুলিকে যেসব গোষ্ঠীভুক্ত
                করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. কোন প্রকার খাদ্যশৃঙ্খল মৃতজীবী থেকে শুরু হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                কর্কর বা ডেট্রিটাস খাদ্যশৃঙ্খল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. কোন্ জীবদের ওল্টানো পিরামিড দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                জলজ জীব ও পরজীবী জীবদের।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. হাইড্রোসিরি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                যেক্ষেত্রে প্রারম্ভিক পর্যায়ের উদ্ভিদগুলি জলাশয়ে বসতি স্থাপন
                করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. জেরোসিরি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                শিলাময় জমিতে প্রারম্ভিকভাবে উদ্ভিদের বসতি শুরু হলে, উদ্ভিদের
                পর্যায়ক্রমকে জেরোসিরি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. জলচ কী কী প্রক্রিয়ায় ঘটে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                বাষ্পীভবন, বাষ্পমোচন এবং অধঃক্ষেপণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. জলে অক্সিজেন কত পরিমাণ থাকে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                0.7 শতাংশ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. ইকোসিস্টেম শব্দটির প্রবর্তন কে করেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                A G Tansley
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. মাটিতে নাইট্রোজেনের শতকরা পরিমাণ কত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                0.1-0.5 ভাগ
                <br />
                <br />
                <font color="#f626d7">
                  <b>11. ইকোক্লাইন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                একটি বাস্তুতন্ত্রকে থেকে অপর বাস্তুতন্ত্রে প্রবেশ করার সময় যে
                ধারাবাহিক পরিবর্তন দেখা যায় তাকে ইকোক্লাইন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    12. বাস্তুতন্ত্রের পিরামিডে কোন ধরনের জীবদের গুরুত্ব দেওয়া
                    হয়নি?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                বাস্তুতন্ত্রের পিরামিডে বিয়োজকদের গুরুত্ব দেওয়া হয়নি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. জলে ভাসমান দুটি উদ্ভিদের নাম উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                উলফিয়া (Wolfia) এবং লেমনা (Lemna) |<br />
                <br />
                <font color="#f626d7">
                  <b>14. ক্যানোপি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                ক্রান্তীয় বৃষ্টিস্নাত অরণ্যে বড়ো গাছগুলির ঘন সন্নিবিষ্ট পাতা
                যে চাঁদোয়ার মতন আচ্ছাদন তৈরি করে তাকে ক্যানোপি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. ক্ষুদ্রতম কৃত্রিম জলজ বাস্তুতন্ত্র কোন্‌টি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                অ্যাকুয়ারিয়াম হল ক্ষুদ্রতম কৃত্রিম জলজ বাস্তুতন্ত্র।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. নেকটন কাদের বলা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                জলজ বাস্তুতন্ত্রে যে সব প্রাণী স্বাধীনভাবে সাঁতার কাটতে পারে
                তাদের নেকটন বলে। উদাহরণ : মাছ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. বেনথস কাদের বলা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                যে সব প্রাথমিক খাদক জলাশয়ের তলদেশ বা বেনথিক অঞ্চলে বসবাস করে
                তাদের বেনথস বলে। যেমন—ঝিনুক, শামুক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. সর্বভুক খাদক কাদের বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                খাদ্য হিসাবে যে প্রাণী উদ্ভিদ ও প্রাণী উভয়কেই ভক্ষণ করে তাদের
                সর্বভুক খাদক বলে। উদাহরণ : কাক, মানুষ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. দুটি কর্করভক্ষক জীব বা ডেট্রিভোরের নাম লেখো। </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                কেঁচো ও উইপোকা দুটি কর্করভক্ষক জীব।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. GP3NP কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>
                </font>
                GP হল Gross Production এবং NP হল Net Production
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 1. বাস্তুতন্ত্র কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                কোনো নির্দিষ্ট স্থানের জীবজাত উপাদানগুলি যখন পরিবেশের জড়
                উপাদানগুলির সঙ্গে পরস্পর আন্তঃক্রিয়া করে যার ফলে জীবগোষ্ঠীর
                মধ্যে শক্তিপ্রবাহ ঘটে এবং পদার্থগুলি চক্রাকারে আবর্তিত হয়ে জীব
                ও জড় উপাদানগুলির মধ্যে যে বিশেষ তন্ত্র গঠন করে, তাকে
                বাস্তুতন্ত্র বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 2. খাদ্য পিরামিড কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                কোনো বাস্তুতন্ত্রের পুষ্টির স্তরগুলিকে ক্রমপর্যায়ে পরপর সাজানো
                হয়ে যে শিখর বা পিরামিডের মতো যে বিশেষ গঠন পাওয়া যায়, তাকে
                খাদ্য পিরামিড বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 3. কার্বন চক্র কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                যে প্রক্রিয়ায় প্রকৃতির কার্বন ডাইঅক্সাইড গ্যাস রূপে পরিবেশ
                থেকে জীবদেহে এবং জীবদেহ থেকে পরিবেশে আবর্তিত হয়ে পরিবেশে
                কার্বনের সমতা বজায় রাখে, তাকে কার্বন চক্র বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  {" "}
                  4. বাস্তুতান্ত্রিক পর্যায়ক্রম (Ecological succession) কাকে
                  বলে?
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                {" "}
                যে প্রাকৃতিক পদ্ধতিতে একটি নির্দিষ্ট সময়কালে' কোনো একটি
                নির্দিষ্ট বাস্তুতন্ত্রের জীবসম্প্রদায়ের পর্যায়ক্রমিক
                পরিবর্তনের মাধ্যমে প্রারম্ভিক কমিউনিটি থেকে স্থায়ী এবং
                ক্লাইমেক্স কমিউনিটির আগমন হয় ফলে সেই সকল জীবসম্প্রদায় উক্ত
                পরিবেশে অভিযোজিত হয়ে স্থায়ীভাবে বসবাস করে, তাকে
                বাস্তুতান্ত্রিক পর্যায়ক্রম (Ecological succession) বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 5. ফসফরাস চক্র কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                {" "}
                যে পদ্ধতিতে ফসফরাস পরিবেশ থেকে জীবদেহে এবং জীবদেহ থেকে পরিবেশে
                আবর্তিত হয়ে পরিবেশে ফসফরাসের সমতা বজায় রাখে, তাকে ফসফরাস চক্র
                বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 6. NPP বলতে কী বোঝো?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                NPP কথাটির অর্থ Net Primary Production অর্থাৎ উদ্ভিদ
                সালোকসংশ্লেষ প্রক্রিয়ায় যতটা খাদ্য উৎপাদন করে, (GPP) তার থেকে
                শ্বসনজনিত কারণে যে শক্তি নির্গত হয় তা বিয়োগ করলে উদ্ভিদদেহের
                নিট প্রাথমিক উৎপাদন পাওয়া যায়।
                <br />
                <br />
                NPP = GPPR (GPP = গ্রস বা প্রাথমিক মোট উৎপাদন এবং
                <br />R = উদ্ভিদ দ্বারা শ্বসনজনিত খাদ্য বা শক্তির ব্যয়)।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. নুডেশন বা নগ্নীভবন কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                যে নগ্ন বা বন্ধ্যাভূমি অর্থাৎ যেখানে জীবের অস্তিত্ব নেই এমন জমি
                সৃষ্টির পদ্ধতিকে নুডেশন বা নগ্নীভবন বলে। কোনো জমি দাবানল বা ধস
                নামার ফলে উদ্ভিদবিহীন হয়ে নগ্নীভবন ঘটতে পারে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>8. আবর্তনশীল ভাণ্ডার কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>
              </font>{" "}
              <font color="#3c92e5">
                যেসব জৈব-ভূরাসায়নিক পদার্থ পরিবেশ থেকে জীবদেহে এবং জীবদেহ থেকে
                পুনরায় পরিবেশে আবর্তিত হয় তাদের বাস্তুতন্ত্রের আবর্তনশীল
                ভাণ্ডার বলে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

বাস্তুতন্ত্র.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(বাস্তুতন্ত্র);
