import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

const ভূগোলউচ্চমাধ্যমিকপ্রস্তুতি = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>
          West Bengal Class 12 Guide | West Bengal HS Suggestion | Higher
          Secondary Guide | WBCHSE | WB | ভূগোল - উচ্চ মাধ্যমিক প্রস্তুতি
        </title>
        <meta
          name="description"
          content="West Bengal Class 12 Guide | West Bengal HS Suggestion |  ভূগোল - উচ্চ মাধ্যমিক প্রস্তুতি"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                ভূগোল - উচ্চ মাধ্যমিক প্রস্তুতি
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative h-100"></div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3"></h6>
              <h1 className="mb-4">
                Welcome to ভূগোল - উচ্চ মাধ্যমিক প্রস্তুতি
              </h1>
              <p className="mb-4">
                প্রতিটি অধ্যায়ের সাথে প্রস্তুতির সহায়ক সামগ্রী ও লিঙ্ক সরবরাহ
                করা হয়েছে যাতে ছাত্ররা তাদের অধ্যয়নে সহায়তা পেতে পারেন। এই
                পৃষ্ঠাটি ছাত্রদের পড়াশোনা প্রক্রিয়ার জন্য একটি গুরুত্বপূর্ণ
                সহায়ক সরঞ্জাম হিসাবে কাজ করতে পারে।
              </p>

              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভূমিরূপ-গঠনকারী-প্রক্রিয়াসমূহ-বহিজাত-প্রক্রিয়াসমূহ-ও-সংশ্লিষ্ট-ভূমিরূপ-Guide"
                        )
                      }
                    >
                      ভূমিরূপ গঠনকারী প্রক্রিয়াসমূহ : বহিজাত প্রক্রিয়াসমূহ ও
                      সংশ্লিষ্ট ভূমিরূপ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-ভৌমজলের-কার্য-এবং-সংশ্লিষ্ট-ভূমিরূপ-Guide"
                        )
                      }
                    >
                      ভৌমজলের কার্য এবং সংশ্লিষ্ট ভূমিরূপ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-সামুদ্রিক-প্রক্রিয়াসমূহ-ও-সংশ্লিষ্ট-ভূমিরূপ-Guide"
                        )
                      }
                    >
                      সামুদ্রিক প্রক্রিয়াসমূহ ও সংশ্লিষ্ট ভূমিরূপ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-ক্ষয়চক্র-গঠন-ও-প্রক্রিয়া-Guide"
                        )
                      }
                    >
                      ক্ষয়চক্র : গঠন ও প্রক্রিয়া
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-জলনির্গম-প্রণালী-Guide"
                        )
                      }
                    >
                      জলনির্গম প্রণালী
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-মৃত্তিকা-Guide"
                        )
                      }
                    >
                      মৃত্তিকা
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-বায়ুমণ্ডল-Guide"
                        )
                      }
                    >
                      বায়ুমণ্ডল
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-জলবায়ু-পরিবর্তন-Guide"
                        )
                      }
                    >
                      জলবায়ু পরিবর্তন
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-মানুষ-পরিবেশ-আন্তঃসম্পর্ক-প্রাকৃতিক-দুর্যোগ-ও-বিপর্যয়-Guide"
                        )
                      }
                    >
                      মানুষ পরিবেশ আন্তঃসম্পর্ক প্রাকৃতিক দুর্যোগ ও বিপর্যয়
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-অর্থনৈতিক-ক্রিয়াকলাপ-Guide"
                        )
                      }
                    >
                      অর্থনৈতিক ক্রিয়াকলাপ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-প্রাথমিক-ক্রিয়াকলাপ-কৃষিকাজ-Guide"
                        )
                      }
                    >
                      প্রাথমিক ক্রিয়াকলাপ : কৃষিকাজ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-দ্বিতীয়-স্তরের-ক্রিয়াকলাপ-শিল্প-Guide"
                        )
                      }
                    >
                      দ্বিতীয় স্তরের ক্রিয়াকলাপ : শিল্প
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-তৃতীয়-স্তরের-ক্রিয়াকলাপ-Guide"
                        )
                      }
                    >
                      তৃতীয় স্তরের ক্রিয়াকলাপ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-চতুর্থ-স্তরের-ক্রিয়াকলাপ-Guide"
                        )
                      }
                    >
                      চতুর্থ স্তরের ক্রিয়াকলাপ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-পঞ্চম-স্তরের-ক্রিয়াকলাপ-Guide"
                        )
                      }
                    >
                      পঞ্চম স্তরের ক্রিয়াকলাপ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-জনসংখ্যা-ও-জনবসতি-Guide"
                        )
                      }
                    >
                      জনসংখ্যা ও জনবসতি
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-আঞ্চলিক-অর্থনৈতিক-উন্নয়ন-Guide"
                        )
                      }
                    >
                      আঞ্চলিক অর্থনৈতিক উন্নয়ন
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-জীববৈচিত্র্য-Guide"
                        )
                      }
                    >
                      জীববৈচিত্র্য
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-Previous-year-Question-paper-2017-Guide"
                        )
                      }
                    >
                      Previous year Question paper 2017
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-Previous-year-Question-paper-2018-Guide"
                        )
                      }
                    >
                      Previous year Question paper 2018
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-Previous-year-Question-paper-2019-Guide"
                        )
                      }
                    >
                      Previous year Question paper 2019
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/ভূগোল-উচ্চ-মাধ্যমিক-প্রস্তুতি-Previous-year-Question-paper-2022-Guide"
                        )
                      }
                    >
                      Previous year Question paper 2022
                    </a>
                  </p>
                </div>
              </div>
              <a className="btn btn-primary py-3 px-5 mt-2" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ভূগোলউচ্চমাধ্যমিকপ্রস্তুতি.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(ভূগোলউচ্চমাধ্যমিকপ্রস্তুতি);
