import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function ঔপনিবেশিকভারতেরশাসন() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          ঔপনিবেশিক ভারতের শাসন | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="ঔপনিবেশিক ভারতের শাসন | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                ঔপনিবেশিক ভারতের শাসন
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>১৯২৩ খ্রিস্টাব্দে স্বরাজ্য দলের প্রতিষ্ঠা করেন — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  চিত্তরঞ্জন দাশ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পুলিনবিহারী দাস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গান্ধিজি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সুভাষচন্দ্র বসু
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>১৯০৯ খ্রিস্টাব্দে ভারতের ভাইসরয় ছিলেন — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চেমস্‌ফোর্ড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  লর্ড মিন্টো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মন্টেগু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ড কার্জন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>১৯০৯ খ্রিস্টাব্দে ভারত সচিব ছিলেন -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ডকার্জন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মর্লে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চেমস্‌ফোর্ড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ড মিন্টো
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>তাসখন্ডে ভারতীয় কমিউনিস্ট পার্টির প্রতিষ্ঠাতা — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  এম. এন. রায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বি. পি. ওয়াদিয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সিঙ্গারাভেলু চেট্টিয়ার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আচার্য নরেন্দ্রদেব
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>দ্বিতীয় গোলটেবিল অনুষ্ঠিত হয়েছিল — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৯১৬ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৯৩০ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ১৯৩১ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৯৩২ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মুসলিম লিগের লাহোর অধিবেশনের সভাপতি ছিলেন — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মহম্মদ আলি জিন্না
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চৌধুরী রহমত আলি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আঁগা খান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মহম্মদ ইকবাল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>১৯৩৩ খ্রিস্টাব্দে Now or Never শীর্ষক পুস্তিকাটি লেখেন — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  চৌধুরি রহমত আলি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আগা খান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বাল গঙ্গাধর তিলক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মহঃ আলি জিন্নাহ্
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ১৯৪৩-খ্রিস্টাব্দের দুর্ভিক্ষের সময় ভারতের ভাইসরয় কে ছিলেন?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আরউইন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্লাইভ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ওয়াভেল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রিপন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>'ভারতের লৌহমানব' নামে পরিচিত — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাজেন্দ্র প্রসাদ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মহাত্মা গান্ধি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সর্দার বল্লভভাই প্যাটেল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মহম্মদ আলি জিন্নাহ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  নিখিল ভারত ট্রেড ইউনিয়ন কংগ্রেস (AITUC)- এর প্রথম সভাপতি
                  ছিলেন —{" "}
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বি. পি. ওয়াদিয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দেওয়ান চমনলাল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  লালা লাজপত রায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অজিত সিং
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="3">
                <b>পঞ্চম অধ্যায় ➺ ঔপনিবেশিক ভারতের শাসন</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রশ্নের মান - ৮</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                1. ১৯৩৫ খ্রিস্টাব্দের ভারত শাসন আইনের প্রেক্ষাপট ও শর্তাবলি
                আলোচনা করো। এই আইনের গুরুত্ব কী ছিল?
                <br />
                2. লক্ষ্ণৌ চুক্তির শর্তাবলি উল্লেখ করো। এই চুক্তির গুরুত্ব
                সম্পর্কে সংক্ষিপ্ত আলোচনা করো।
                <br />
                3. মিরাট ষড়যন্ত্র মামলার (১৯২৯ খ্রিস্টাব্দ) প্রেক্ষাপট আলোচনা
                করো। এই মামলাটির পরিণতি কী হয়েছিল?
                <br />
                4. মন্টেগু-চেমসফোর্ড সংস্কার আইনের (১৯১৯) সমালোচনামূলক আলোচনা
                করো।
                <br />
                5. রাওলাট আইনের উদ্দেশ্য কী ছিল? গান্ধিজি কেন এই আইনের বিরোধিতা
                করেছিলেন?
                <br />
                6. রাওলাট আইন প্রবর্তনের প্রেক্ষাপট ও শর্তগুলি আলোচনা করো।
                <br />
                7. ১৯০৯ খ্রিস্টাব্দের মলে-মিন্টো সংস্কার আইনের শর্ত এবং গুরুত্ব
                গুলি আলোচনা করো।
                <br />
                8. জালিয়ানওয়ালাবাগ হত্যাকাণ্ড কী? এই হত্যাকাণ্ডের বিরুদ্ধে
                ভারতীয়দের প্রতিক্রিয়া কী ছিল?
                <br />
                9. প্রথম বিশ্বযুদ্ধের পর (১৯১৪-৪৫ খ্রিস্টাব্দ) থেকে শিল্পের
                অগ্রগতির কারণ কী ছিল? এই সময় শিল্পের বিকাশে সরকার কী কী উদ্যোগ
                নিয়েছিল?
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রশ্নের মান - ১</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. IISCO-এর পুরো নাম কী?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                IISCO-এর পুরো নাম হল – 'ইন্ডিয়ান আয়রন অ্যান্ড স্টিল কোম্পানি'।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. রাওলাট সত্যাগ্রহের সূচনা কে করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                গান্ধিজি রাওলাট সত্যাগ্রহের সূচনা করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    3. কবে ও কোথায় ভারতীয় কমিউনিস্ট পার্টি প্রতিষ্ঠিত হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯২৫ খ্রিস্টাব্দের ২৬ ডিসেম্বর কানপুরে কমিউনিস্ট পার্টি
                প্রতিষ্ঠিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. ভারতে মে দিবস কবে, কোথায় প্রথম পালিত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯২৩ খ্রিস্টাব্দের ১ মে, মাদ্রাজের সমুদ্রোপকূলে প্রথম মে দিবস
                পালিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. মর্লে-মিন্টো আইনে কী বিষয় সংরক্ষণ করা হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মর্লে-মিন্টো সংস্কার আইনে মুসলিম সম্প্রদায়ের জন্য পৃথক
                নির্বাচনের উদ্দেশ্যে আসন সংরক্ষণ করা হয়। বলাবাহুল্য, এই সরকারি
                সিদ্ধান্ত ভারতে সাম্প্রদায়িকতাকে উসকে দিয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. কবে, কোথায় ভারতের প্রথম কয়লাখনি আবিষ্কৃত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮২০ খ্রিস্টাব্দে পশ্চিমবঙ্গের রানিগঞ্জে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. রাওলাট আইনের পিছনে কী উদ্দেশ্য ছিল?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতে ব্রিটিশ বিরোধী গণ আন্দোলন ও বৈপ্লবিক কার্যকলাপ বন্ধ করে
                দেওয়াই ছিল রাওলাট আইনের উদ্দেশ্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. কোন্ আইন কাউন্সিল আইন নামে পরিচিত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯০৯ খ্রিস্টাব্দে প্রবর্তিত মর্লে-মিন্টো সংস্কার আইনটি ‘কাউন্সিল
                আইন’ নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. মর্লে-মিন্টো সংস্কার আইন কবে পাস হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মর্লে-মিন্টো সংস্কার আইন ১৯০৯ খ্রিস্টাব্দে পাস হয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. সিমলা সাক্ষাৎকারে প্রতিনিধিদলের নেতৃত্ব কে দেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মুসলিম নেতা আগা খাঁ।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. চার্লস উড কে ছিলেন?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ইস্ট ইন্ডিয়া কোম্পানির 'বোর্ড অফ কন্ট্রোল'-এর সভাপতি ছিলেন
                চার্লস উড।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. জুনাগড় কখন ভারতের সঙ্গে যুক্ত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জুনাগড় ১৯৪৮ খ্রিস্টাব্দে ভারতের সঙ্গে যুক্ত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    13. কাশ্মীর কখন ভারতের অন্তর্ভুক্ত হয়? তখন কাশ্মীরের রাজা
                    কে ছিলেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৪৭ খ্রিস্টাব্দের ২৬ অক্টোবর কাশ্মীর আনুষ্ঠানিকভাবে ভারতের
                অন্তর্ভুক্ত হয়। তখন কাশ্মীরের রাজা ছিলেন হরি সিং।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. হায়দরাবাদ কবে ভারতের সঙ্গে যোগ দেয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৫০ খ্রিস্টাব্দের ২৬ জানুয়ারি হায়দরাবাদ আনুষ্ঠানিকভাবে ভারতের
                সঙ্গে যোগ দেয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. সর্বশেষ চার্টার আইন কবে প্রবর্তিত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                সর্বশেষ চার্টার আইন প্রবর্তিত হয় ১৮৫৩ খ্রিস্টাব্দে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    16. ভারতবর্ষে কোন্ আইনের মাধ্যমে ইস্ট ইন্ডিয়া কোম্পানির
                    শাসনের অবসান হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮৫৮ খ্রিস্টাব্দের ভারতশাসন আইনের মাধ্যমে ইস্ট ইন্ডিয়া
                কোম্পানির শাসনের অবসান হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. ভারতের প্রথম ভাইসরয় কে ছিলেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের প্রথম ভাইসরয় ছিলেন লর্ড ক্যানিং।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. নিখিল ভারত অস্পৃশ্যতা বিরোধী লিগ কে, কবে গঠন করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৩২ খ্রিস্টাব্দে গান্ধিজি, নিখিল ভারত অস্পৃশ্যতা বিরোধী লিগ গঠন
                করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. কেরালায় কবে, ‘মন্দির প্রবেশ দিবস’ পালিত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৩১ খ্রিস্টাব্দের ১ নভেম্বর কেরালায় ‘মন্দির প্রবেশ দিবস’ পালিত
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. ‘ কাইজার-ই-হিন্দ’ ও ‘কায়েদ-ই-আজম' নামে কারা পরিচিত
                    ছিলেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মহাত্মা গান্ধি, ‘কাইজার-ই-হিন্দ’ ও মহম্মদ আলি জিন্নাহ্
                ‘কায়েদ-ই-আজম’ নামে পরিচিত ছিলেন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. ‘বেঙ্গল প্যাক্ট' কবে, কাদের মধ্যে স্বাক্ষরিত হয়?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ১৯২৩ খ্রিস্টাব্দে স্বরাজ্য দল ও জাতীয়তাবাদী মুসলিমদের মধ্যে
                ‘বেঙ্গল প্যাক্ট’ স্বাক্ষরিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    22. মিরাট ষড়যন্ত্র মামলায় কতজন শ্রমিক নেতা অভিযুক্ত হন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মিরাট ষড়যন্ত্র মামলায় দেশি-বিদেশি মিলিয়ে মোট ৩৩ জন শ্রমিক
                নেতা অভিযুক্ত হন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. কে, কবে সাম্প্রদায়িক বাঁটোয়ারা নীতি ঘোষণা করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ব্রিটিশ প্রধানমন্ত্রী স্যার র‍্যামসে ম্যাকডোনাল্ড ১৯৩২
                খ্রিস্টাব্দের ১৬ আগস্ট তাঁর কুখ্যাত সাম্প্রদায়িক বাঁটোয়ারা
                নীতি’ ঘোষণা করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    24. কবে, কোন্ আইনে ব্রহ্লদেশকে ভারতের থেকে বিচ্ছিন করা হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৩৫ খ্রিস্টাব্দের ভারত শাসন আইনানুসারে ১৯৩৭ খ্রিস্টাব্দে
                ব্রাহ্মদেশকে ভারতের থেকে বিচ্ছিন্ন করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. সিমলা সাক্ষাৎকারের প্রতিনিধি দলের নেতৃত্ব কে দেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বড়োলাট লর্ড মিন্টোর সঙ্গে সিমলা সাক্ষাৎকারে (১৯০৬ খ্রিঃ) মুসলিম
                প্রতিনিধি দলের নেতৃত্ব দেন আগা খাঁ। <br />
                <br />
                <font color="#f626d7">
                  <b>26. পুণা চুক্তি কত খ্রিস্টাব্দে স্বাক্ষরিত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৩২ খ্রিস্টাব্দের ২৪ সেপ্টেম্বর গান্ধিজি ও তপশিলি হিন্দু সমাজের
                নেতা বি. আর. আম্বেদকরের মধ্যে ‘পুণা চুক্তি’ স্বাক্ষরিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. বিশ শতকে বাংলায় কবে মন্বন্তর হয়েছিল?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দ্বিতীয় বিশ্বযুদ্ধের প্রেক্ষাপটে ১৯৪৩ খ্রিস্টাব্দে (১৩৫০
                বঙ্গাব্দে) বাংলায় মন্বন্তর হয়েছিল। এই মন্বন্তর ‘পঞ্চাশের
                মন্বন্তর' নামেই ইতিহাসে পরিচিত।
                <font color="#f626d7">
                  <b>28. রাওলাট সত্যাগ্রহের সূচনা কে করেন এবং কবে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দমনমূলক রাওলাট আইনের প্রতিবাদে মোহন দাস করমচাঁদ গান্ধি, ১৯১৯
                খ্রিস্টাব্দে ‘রাওলাট সত্যাগ্রহের’ সূচনা করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. মিরাট ষড়যন্ত্র মামলা বলতে কী বোঝো? কেন শুরু হয়?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতে কমিউনিস্টদের নেতৃত্বে শ্রমিক আন্দোলনের বিপুল অগ্রগতি
                ব্রিটিশ সরকারকে শঙ্কিত করে তোলে। শ্রমিকদের থেকে কমিউনিস্টদের
                আলাদা করা এবং তাদের দমন করার জন্য সরকার ‘জন নিরাপত্তা বিল' ও
                ‘শিল্প বিরোধ বিল’ নামে দুটি দমনমূলক আইন পাস করে ভারতের বিভিন্ন
                প্রান্ত থেকে ৩৩ জন কমিউনিস্ট নেতাকে গ্রেপ্তার করে তাদের বিরুদ্ধে
                মিরাট ষড়যন্ত্র মামলা (১৯২৯ খ্রিস্টাব্দ) রুজু করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. ভারতীয়রা সাইমন কমিশন বর্জন করেছিল কেন?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতীয়রা সাইমন কমিশন বয়কট করেছিল প্রধানত দুটি কারণে। প্রথমত
                সাত সদস্য বিশিষ্ট এই কমিশনের সব সদস্যই ছিলেন শ্বেতাঙ্গ, কোনো
                ভারতীয়কে এতে নেওয়া হয়নি; দ্বিতীয়ত, ভারতীয়রা দায়িত্বশীল
                সরকার গঠনের উপযুক্ত হয়েছে কি না—তা বিচারের দায়িত্ব ভারতের
                সঙ্গে সম্পর্কহীন ব্যক্তিদের দেওয়ায় ভারতবাসী এটিকে জাতীয় অপমান
                বলে গণ্য করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  31. মিরাট ষড়যন্ত্র মামলায় দুজন বিদেশি অভিযুক্তের নাম লেখো।{" "}
                </b>
              </font>
              <br /> <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                মিরাট ষড়যন্ত্র মামলায় অভিযুক্ত মোট ৩৩ জন ব্যক্তির মধ্যে দুজন
                বিদেশি হলেন ব্রিটিশ সমাজতান্ত্রিক নেতা ফিলিপ স্প্ল্যাট ও
                বেঞ্জামিন ব্রাডলি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. মাহাদ মার্চ কী?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মহারাষ্ট্রের কোলাবায় চৌদার জলাশয়ের জল যাতে অস্পৃশ্য
                সম্প্রদায়ের মানুষেরা ব্যবহার করতে পারে, সেই দাবিতে আম্বেদকরের
                নেতৃত্বে ১৯২৭ খ্রিস্টাব্দের ২০ মার্চ সহস্রাধিক মানুষের যে
                সত্যাগ্রহ অনুষ্ঠিত হয়, তা ‘মাহার মার্চ’ নামে পরিচিত। উল্লেখ্য,
                এখানেই জনতা আম্বেদকরকে ‘বাবাসাহেব’ অভিধায় ভূষিত করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    33. সিমলা ডেপুটেশান কী? অথবা, ‘সিমলা দৌত্য’ বলতে কী বোঝো?
                  </b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আগা খাঁ সহ ৩৫ জন বিশিষ্ট মুসলিম নেতা আলিগড় কলেজের অধ্যক্ষ
                আর্চিবল্ডের প্ররোচনায় ১৯০৬ খ্রিস্টাব্দের অক্টোবরে সিমলায় গিয়ে
                বড়োলাট লর্ড মিন্টোর সঙ্গে সাক্ষাৎ করেন এবং মুসলিম সমাজের জন্য
                নানা সুযোগ-সুবিধা দাবি করেন। বড়োলাট মুসলিমদের অধিকার ও
                স্বার্থরক্ষার প্রতিশ্রুতি দেন। এই ঘটনা ‘সিমলা দৌত্য’ নামে খ্যাত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. ঔপনিবেশিক ভারতে দেশীয় রাজ্য বলতে কী বোঝানো হত?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৪৭ খ্রিস্টাব্দে স্বাধীনতা লাভের পূর্বে ভারতীয় ভূখণ্ডে
                ব্রিটিশের প্রত্যক্ষ নিয়ন্ত্রণের বাইরে প্রায় ৬০০টি স্বশাসিত
                রাজ্যের অস্তিত্ব ছিল। আয়তনে এরা ছিল দেশের মোট আয়তনের শতকরা ৪৮
                ভাগ এবং মোট জনসমষ্টি ছিল প্রায় ৯ কোটি। এগুলিকে দেশীয় রাজ্য বলে
                অভিহিত করা হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. কবে এবং কাদের মধ্যে লক্ষ্ণৌ চুক্তি স্বাক্ষরিত হয়?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯১৬ খ্রিস্টাব্দে জাতীয় কংগ্রেস ও মুসলিম লিগের মধ্যে লক্ষ্ণৌ
                চুক্তি স্বাক্ষরিত হয়। এই চুক্তির দ্বারা উভয় দলের মধ্যে ঐক্য
                স্থাপিত হয় এবং স্থির হয় যে, <br /> (ক) কংগ্রেস ও মুসলিম লিগ
                যুগ্মভাবে সরকারের কাছে শাসনতান্ত্রিক সংস্কারের দাবি পেশ করবে;{" "}
                <br /> (খ) মুসলিম লিগ কংগ্রেসের ‘স্বরাজ’-এর আদর্শ গ্রহণ করবে এবং{" "}
                <br /> (গ) কংগ্রেস মুসলিম লিগের পৃথক নির্বাচনের দাবি মেনে নেবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  36. ১৯৩২ খ্রিস্টাব্দের পুনা চুক্তি কেন স্বাক্ষরিত হয়েছিল?{" "}
                </b>
              </font>
              <br /> <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ব্রিটিশ সরকার ‘সাম্প্রদায়িক বাঁটোয়ারা নীতি’র মাধ্যমে ভারতীয়
                হিন্দু সমাজের কৃত্রিম বিভাজন সৃষ্টি করে জাতীয় আন্দোলনকে দুর্বল
                করে দিতে উদ্যোগী হলে মহাত্মা গান্ধি সরকারের এই দুরভিসন্ধির
                বিরুদ্ধে পুণার যারবেদা জেলে আমরণ অনশন শুরু করেন। দীর্ঘ অনশনে
                তাঁর প্রাণ সংশয় দেখা দেয়। এমতাবস্থায় তাঁর প্রাণ রক্ষা এবং
                হিন্দু সমাজের অভ্যন্তরীণ ঐক্য বজায় রাখার উদ্দেশ্যে ১৯৩২
                খ্রিস্টাব্দের ২৪ সেপ্টেম্বর গান্ধিজি ও তপশিলী হিন্দু সমাজের নেতা
                বি. আর. আম্বেদকরের মধ্যে ‘পুণা চুক্তি’ স্বাক্ষরিত হয়। এর দ্বারা
                হিন্দু সমাজের যৌথ নির্বাচনের নীতি স্বীকৃত হয়।
                <br /> <br />
                <font color="#f626d7">
                  <b>
                    37. ১৯১৬ খ্রিস্টাব্দের লক্ষ্ণৌ কংগ্রেসের গুরুত্ব কী ছিল?
                  </b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                (ক) ১৯১৬ খ্রিস্টাব্দে জাতীয় কংগ্রেসের লক্ষ্ণৌ অধিবেশনে
                কংগ্রেসের নরমপন্থী ও চরমপন্থী গোষ্ঠীর মধ্যে পুনরায় ঐক্য স্থাপিত
                হয়। প্রায় এক দশক পর জাতীয় কংগ্রেসের এই দুই গোষ্ঠীর মিলন
                পুনরায় কংগ্রেসের শক্তি বৃদ্ধি করে। (খ) লক্ষ্ণৌ অধিবেশনে কংগ্রেস
                ও মুসলিম লিগের মধ্যে ঐক্য প্রতিষ্ঠিত হয়।
                <br /> <br />
                <font color="#f626d7">
                  <b>
                    38. স্বত্ববিলোপ নীতি প্রয়োগে ডালহৌসি কোন্ কোন্ রাজ্য দখল
                    করেন?
                  </b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                স্বত্ববিলোপ নীতির মাধ্যমে লর্ড ডালহৌসি একে একে সাতারা (১৮৪৮
                খ্রিস্টাব্দ), সম্বলপুর ও জৈৎপুর (১৮৪৯), বাগৎ (১৮৫০), উদয়পুর
                (১৮৫২), ঝাঁসি (১৮৫৩), নাগপুর (১৮৫৪), কর্ণাটক (১৮৫৫) প্রভৃতি
                রাজ্য ব্রিটিশ সাম্রাজ্যভুক্ত করেন।
                <br /> <br />
                <font color="#f626d7">
                  <b>39. মুসলিম লিগের লাহোর অধিবেশনের গুরুত্ব কী?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৪০ খ্রিস্টাব্দে মুসলিম লিগের লাহোর অধিবেশনে তৎকালীন বাংলার
                প্রধানমন্ত্রী ফজলুল হক বিখ্যাত ‘লাহোর প্রস্তাব’ উত্থাপন করেন। এই
                প্রস্তাবে হিন্দু ও মুসলিমদের দুটি পৃথক জাতি হিসেবে চিহ্নিত করা
                হয় এবং জাতি হিসেবে মুসলিমদের জন্য পৃথক সার্বভৌম রাষ্ট্রের দাবি
                জানানো হয়। এইজন্য ‘লাহোর প্রস্তাব’ ‘পাকিস্তান প্রস্তাব’ নামেও
                পরিচিত। এরপর থেকেই পাকিস্তান গঠনের আন্দোলন জোরদার হয়ে ওঠে।
                <br /> <br />
                <font color="#f626d7">
                  <b>40. মর্লে-মিন্টো আইন প্রণয়নের দুটি কারণ উল্লেখ করো।</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রথমত, জাতীয় কংগ্রেসের নরমপন্থীদের সরকারের সপক্ষে রাখা এবং
                দ্বিতীয়ত, সাম্প্রদায়িক প্রতিনিধিত্বের মাধ্যমে মুসলিম
                সম্প্রদায়কে জাতীয় কংগে এস থেকে বিচ্ছিন্ন করে জাতীয় আন্দোলনকে
                দুর্বল করা।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  41. জালিয়ানওয়ালা বাগ হত্যাকান্ডের সময় ব্রিটিশ প্রধানমন্ত্রী
                  কে ছিলেন?
                </b>
              </font>
              <br /> <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ডেভিড লয়েজ জর্জ ছিলেন জালিয়ানওয়ালা বাগ হত্যাকান্ডের ব্রিটিশ
                প্রধানমন্ত্রী।
                <br />
                <br />
                <font color="#f626d7">
                  <b>42. মুসলিম লিগ প্রতিষ্ঠার লক্ষ্য কী ছিল?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                (ক) মুসলিম সমাজের স্বার্থ রক্ষা করা, <br />
                (খ) কংগ্রেসের প্রভাব-প্রতিপত্তি খর্ব করে মুসলিমদের জন্য নিজস্ব
                প্রতিষ্ঠান গড়ে তোলা এবং <br />
                (গ) মুসলিমদের জন্য আধুনিক শিক্ষার সুযোগ-সুবিধার ব্যবস্থা করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>43. রাওলাট সত্যাগ্রহ কী?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কুখ্যাত রাওলাট আইনের প্রতিবাদে গান্ধিজির সভাপতিত্বে ১৯১৯
                খ্রিস্টাব্দে ‘সত্যাগ্রহ সভা’ প্রতিষ্ঠিত হয় এবং বিভিন্ন স্থানে
                স্বতঃস্ফূর্ত গণআন্দোলন গড়ে ওঠে। ৬ সর্বভারতীয় ধর্মঘট পালিত হয়।
                রাওলাট আইন-বিরোধী এই আন্দোলন ‘রাওলাট সত্যাগ্রহ' নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>44. ১৯১৯ খ্রিস্টাব্দে জালিয়ানওয়ালা বাগে কী ঘটেছিল?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                রাওলাট আইনের প্রতিবাদে পাঞ্জাবের অমৃতসর শহরের জালিয়ানওয়ালা বাগ
                নামক প্রাচীর ঘেরা উদ্যানে ১৯১৯ খ্রিস্টাব্দে ১৩ এপ্রিল বহু মানুষ
                সমবেত হন। অমৃতসরের সামরিক শাসক জেনারেল মাইকেল ও'ডায়ারে নির্দেশে
                ব্রিগেডিয়ার জেনারেল রেজিনাল্ড ডায়ার বিনা প্ররোচনায় প্রায় দশ
                হাজার নিরস্ত্র মানুষের ওপর নির্বিচারে গুলিবর্ষণ করেন এবং এতে
                সহস্রাধিক মানুষ হতাহত হন। এই ঘটনা ‘জালিয়ানওয়ালা বাগ
                হত্যাকাণ্ড’ নামে কুখ্যাত। এই ঘটনার প্রতিবাদে রবীন্দ্রনাথ ঠাকুর
                ব্রিটিশের দেওয়া ‘নাইট’ খেতাব বর্জন করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    45. মন্টেগু-চেমস্‌ফোর্ড’ সংস্কার আইন প্রণয়নের দুটি কারণ
                    উল্লেখ করো।
                  </b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রথম বিশ্বযুদ্ধোত্তর ভারতে বৈপ্লবিক আন্দোলনের তীব্রতা বৃদ্ধি
                পেলে তা প্রশমিত করা; কিছু সীমিত প্রশাসনিক সুযোগ-সুবিধা প্রদানের
                মাধ্যমে কংগ্রেসের নরমপন্থীদের সহযোগিতা লাভ, পূর্ববর্তী
                মর্লে-মিন্টো সংস্কার আইনের ত্রুটি-বিচ্যুতি দূরীকরণ প্রভৃতি কারণে
                ব্রিটিশ সরকার ১৯১৯ খ্রিস্টাব্দে মন্টেগু-চেমসফোর্ড সংস্কার আইন
                প্রণয়ন করেন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>46. মন্টেগু-চেমসফোর্ড সংস্কার আইনের দুটি শর্ত উল্লেখ করো।</b>
              </font>
              <br /> <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                (ক) কেন্দ্রীয় ও প্রাদেশিক সরকারের মধ্যে সুনির্দিষ্টভাবে ক্ষমতা
                ও আয় বণ্টন করা হয়। <br />
                (খ) প্রতিরক্ষা, ডাকব্যবস্থা, রেল যোগাযোগ, মুদ্রা ব্যবস্থা
                প্রভৃতি গুরুত্বপূর্ণ বিষয়গুলি কেন্দ্রীয় সরকারের হাতে দেওয়া
                হয়, আর আইনশৃঙ্খলা, পুলিশি ব্যবস্থা, স্বাস্থ্য, শিক্ষা, কৃষি
                প্রভৃতি বিষয়গুলির দায়িত্ব প্রাদেশিক সরকারগুলির ওপর ন্যস্ত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    47. মন্টেগু চেমসফোর্ড সংস্কার আইনের দুটি ত্রুটি উল্লেখ করো।
                  </b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                (ক) এই আইনের দ্বারা ভারতে প্রতিনিধিত্বমূলক শাসনব্যবস্থা
                প্রতিষ্ঠার চেষ্টা করা হয়নি, <br />
                (খ) এই আইনের দ্বারা কোনো দায়িত্বশীল শাসনব্যবস্থা প্রতিষ্ঠিত
                হয়নি। কেন্দ্রে ভাইসরয় এবং প্রদেশে গভর্নর চূড়ান্ত ক্ষমতার
                অধিকারী হন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    48. কবে ও কোথায় ভারতীয় কমিউনিস্ট পার্টি প্রতিষ্ঠিত হয়?
                    অথবা, ভারতের কমিউনিস্ট পার্টিকে ‘দ্বিজ’ বলা হয় কেন?
                  </b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের কমিউনিস্ট পার্টি আক্ষরিক অর্থেই দ্বিজ। অর্থাৎ
                এরজন্মদু-বার—প্রথমবার ১৯২০খ্রিস্টাব্দে রাশিয়ার তাসখন্দে,
                প্রতিষ্ঠাতা এম. এন. রায়। দ্বিতীয়বারের প্রতিষ্ঠা ১৯২৫
                খ্রিস্টাব্দে কানপুরে, প্রতিষ্ঠাতাদের মধ্যে উল্লেখযোগ্য হলেন
                সিঙ্গারাভেলু চেট্টিয়ার, এস. বি. ঘাটে প্রমুখ। তাই ভারতের
                কমিউনিস্ট পার্টিকে ‘দ্বিজ’ বলা হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>49. মন্টেগু-চেমস্‌ফোর্ড সংস্কার আইনের গুরুত্ব কী ছিল?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                (ক) মন্টেগু-চেমসফোর্ড সংস্কার আইনের দ্বারা ভারতে প্রত্যক্ষ
                নির্বাচনের সূচনা হয় এবং ভোটাধিকারের সম্প্রসারণ ঘটে। <br />
                (খ) প্রাদেশিক মন্ত্রীসভায় ভারতীয়দের প্রবেশাধিকারের মধ্য দিয়ে
                শাসনব্যবস্থার ভারতীয়করণের পথ প্রশস্ত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    50. ঔপনিবেশিক শাসনকালে ভারতে স্বদেশীয় উদ্যোগে গড়ে ওঠা দুটি
                    শিল্প সংস্থার নাম লেখো।
                  </b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                (ক) ১৯০৭ খ্রিস্টাব্দে জামসেদজি টাটা কর্তৃক জামশেদপুরে প্রতিষ্ঠিত
                'টাটা আয়রন অ্যান্ড স্টিল কোম্পানি’ (TISCO)।
                <br />
                (খ) ১৯২৩ খ্রিস্টাব্দে স্যার এম. বিশ্বেশরাইয়া কর্তৃক ভদ্রাবতীতে
                প্রতিষ্ঠিত হয় ‘মহীশূর আয়রন অ্যান্ড স্টিল ওয়ার্কস'।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

ঔপনিবেশিকভারতেরশাসন.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(ঔপনিবেশিকভারতেরশাসন);
