import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function অর্থনৈতিকক্রিয়াকলাপ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          অর্থনৈতিক ক্রিয়াকলাপ | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="অর্থনৈতিক ক্রিয়াকলাপ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                অর্থনৈতিক ক্রিয়াকলাপ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b> অষ্টম অধ্যায় </b>
              </font>
              <br />{" "}
              <font color="#2b9a09" size="4">
                <b>অর্থনৈতিক ক্রিয়াকলাপ </b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ৭</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                1. অর্থনৈতিক ক্রিয়াকলাপ কাকে বলে? বিভিন্ন ক্ষেত্র সহকারে
                অর্থনৈতিক ক্রিয়াকলাপের শ্রেণিবিভাগ করাে।
                <br />
                2. প্রথম ও দ্বিতীয় স্তরের অর্থনৈতিক ক্রিয়াকলাপের সংজ্ঞা ও
                বৈশিষ্ট্য ব্যাখ্যা করাে।
                <br />
                3. তৃতীয় ও চতুর্থ ক্ষেত্রের অর্থনৈতিক ক্রিয়াকলাপের সংজ্ঞা ও
                বৈশিষ্ট্য লেখো।
                <br />
                4. প্রাথমিক ক্ষেত্রের অর্থনৈতিক কার্যাবলি ও দ্বিতীয় ক্ষেত্রের
                অর্থনৈতিক কার্যাবলির মধ্যে পার্থক্য নির্দেশ করাে।
                <br />
                5. পঞ্চম ক্ষেত্রের অর্থনৈতিক কার্যাবলি বলতে কী বােঝ? তৃতীয় বা
                সেবাক্ষেত্রের বিভিন্ন কাজের উপাদান বা স্তরগুলি আলােচনা করাে।
                <br />
                6. কোয়াটারনারি (চতুর্থ ক্ষেত্রের কার্যাবলি) ও কুইনারি (পঞ্চম
                ক্ষেত্রের কার্যাবলি) অর্থনৈতিক ক্রিয়াকলাপের মধ্যে পার্থক্য
                লেখাে। আউটসাের্সিং কী?
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. মানুষ তার অভাবপূরণ বা জীবিকানির্বাহের জন্য যেসব কাজ করে
                  সেগুলিকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                মানুষ তার অভাবপূরণ বা জীবিকানির্বাহের জন্য যেসব কাজ করে সেগুলিকে
                অর্থনৈতিক ক্রিয়াকলাপ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    2. অর্থনীতিবিদরা অর্থনৈতিক কাজকে অপর কী নামে অভিহিত করেছেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                অর্থনীতিবিদরা অর্থনৈতিক কাজকে সামাজিক কাজ নামে অভিহিত করেছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. অর্থনৈতিক ক্রিয়াকলাপের মুখ্য উদ্দেশ্য কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                অর্থনৈতিক ক্রিয়াকলাপের মুখ্য উদ্দেশ্য হল মানুষের বিভিন্ন
                অভাবগুলিকে পূরণ করা এবং জীবিকানির্বাহ করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    4. গ্রে কলার কথাটি কী ধরনের অর্থনৈতিক কার্যাবলিকে নির্দেশ
                    করে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                অবসরের বয়স অতিক্রম করার পরেও মানুষ যেসকল কার্যাবলির সাথে
                নিযুক্ত থাকে সেসকল অর্থনৈতিক কার্যাবলির ক্ষেত্রে গ্রে কলার কথাটি
                ব্যবহার করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. অফসােরিং বলতে কী বােঝ?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যখন আউটসাের্সিং-এর কাজকে বাইরের দেশে পাঠিয়ে করিয়ে নেওয়া হয়,
                তখন তাকে অফসােরিং বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    6. বর্তমানে আউটসাের্সিং-এর মাধ্যমে কী কী কাজ করানাে হয়ে
                    থাকে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বর্তমানে আউটসাের্সিং-এর মাধ্যমে তথ্যপ্রযুক্তি, গবেষণা,
                উন্নয়ন-সংক্রান্ত কাজ এবং ব্যাংকসহ বিভিন্ন আর্থিক প্রতিষ্ঠানের
                কাজ করানাে হয়ে থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. অতি নব্যস্তরের অর্থনৈতিক কাজ কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যে ক্ষেত্র থেকে অতি উচ্চ পর্যায়ের বুদ্ধিজীবী পরিসেবা পাওয়া
                যায় তাকে অতি নব্যস্তরের অর্থনৈতিক কাজ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    8. কোনাে দেশ বা রাজ্যের কৃষি নীতি, শিল্প নীতি প্রভৃতি
                    নির্ধারণ করেন কোন্ কর্মীরা?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                সােনালি কলার কর্মীরা কোনাে দেশ বা রাজ্যের কৃষি নীতি, শিল্পনীতি
                প্রভৃতি নির্ধারণ করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    9. পঞ্চম স্তরের ক্রিয়াকলাপে নিযুক্ত কর্মীদের সােনালি কলার
                    কর্মী বলা হয় কেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পঞ্চম স্তরের কাজে নিযুক্ত কর্মীদের প্রচুর অর্থ উপার্জনের সুযােগ
                থাকে বলে এদেরকে সােনালি কলার কর্মী বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. প্রাথমিক অর্থনৈতিক ক্রিয়াকলাপ কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রাকৃতিক সম্পদ সংগ্রহ বা উৎপাদনের জন্য মানুষ যেসব কাজ করে
                সেগুলিকে প্রাথমিক অর্থনৈতিক ক্রিয়াকলাপ বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  11. কোন্ স্তরের অর্থনৈতিক ক্রিয়াকলাপে অধিক দৈহিক শ্রম বা
                  কায়িক শ্রমের প্রয়ােজন হয়?
                </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                প্রথম স্তরের অর্থনৈতিক ক্রিয়াকলাপে অধিক দৈহিক শ্রম বা কায়িক
                শ্রমের প্রয়ােজন হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    12. প্রাথমিক অর্থনৈতিক ক্রিয়াকলাপকে জীবনধারণভিত্তিক বা
                    স্বয়ংভােগী অর্থনৈতিক ক্রিয়াকলাপ বলার কারণ কী?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রাথমিক অর্থনৈতিক ক্রিয়াকলাপে উৎপাদিত পণ্যদ্রব্য প্রধানত
                উৎপাদকের জীবিকা এবং জীবনধারণের জন্যই ব্যবহৃত বলে একে
                জীবনধারণভিত্তিক স্বয়ংভােগী অর্থনৈতিক ক্রিয়াকলাপ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. প্রথম স্তরের অর্থনৈতিক কাজ জমি নির্ভর কেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জমিকে ভিত্তি করেই চাষাবাদ, পশুপালন প্রভৃতি প্রথম স্তরের
                অর্থনৈতিক কাজ করা হয় বলে এই স্তরের কাজ জমি নির্ভর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    14. শিল্পবিপ্লবকে ভিত্তি করে কোন্ ধরনের কাজের উদ্ভব হয়েছে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                শিল্পবিপ্লবকে ভিত্তি করে দ্বিতীয় স্তরের কাজের উদ্ভব হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    15. প্রাথমিক স্তরের কাজে নিযুক্ত কর্মীদের Red-collar Workers
                    বলা হয় কেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রাথমিক স্তরের কাজে দৈহিক শ্রম বেশি প্রয়ােজন হয় এবং এইসব
                কার্যাবলি অত্যন্ত ঝুঁকিপূর্ণ হওয়ায় এই কাজের সাথে যুক্ত
                কর্মীদের Red-collar Workers বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. মানুষের সবচেয়ে প্রাচীন অর্থনৈতিক কাজ কোনটি?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রাথমিক স্তরের অর্থনৈতিক কাজ হল মানুষের সবচেয়ে প্রাচীন
                অর্থনৈতিক কাজ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. উন্নত দেশগুলিতে কোন্ ক্ষেত্রের গুরুত্ব সবথেকে বেশি?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                উন্নত দেশগুলিতে পরিসেবা ক্ষেত্রের গুরুত্ব সবথেকে বেশি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    18. কোন্ স্তরের ক্রিয়াকলাপকে পরিসেবামূলক অর্থনৈতিক
                    ক্রিয়াকলাপ বলে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                তৃতীয় স্তরের ব্রিয়াকলাপকে পরিসেবামূলক অর্থনৈতিক ক্রিয়াকলাপ
                বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. পর্যটন কোন্ স্তরের অর্থনৈতিক কাজের অন্তর্ভুক্ত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পর্যটন তৃতীয় স্তরের অর্থনৈতিক কাজের অন্তর্ভুক্ত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. দেশের অর্থনৈতিক উন্নতি দ্রুত হয় কোন্ স্তরের অর্থনৈতিক
                    কার্যাবলির উন্নতি ঘটলে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                তৃতীয় স্তরের অর্থনৈতিক কার্যাবলির দ্রুত উন্নতি ঘটলে দেশের
                অর্থনৈতিক উন্নতি দ্রুত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  21. প্রাথমিক স্তরের ক্রিয়াকলাপকে প্রকৃতি নির্ধারিত
                  ক্রিয়াকলাপ বলার কারণ কী?
                </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                প্রাথমিক স্তরের ক্রিয়াকলাপ মূলত প্রাকৃতিক পরিবেশের ওপর নির্ভর
                করে গড়ে ওঠে বলে একে প্রকৃতি নির্ধারিত ক্রিয়াকলাপ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    22. মানুষের প্রাথমিক চাহিদা মেটানাের চেষ্টা করা হয় কোন্
                    স্তরের অর্থনৈতিক ক্রিয়াকলাপ দ্বারা?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মানুষের প্রাথমিক চাহিদা মেটানাের চেষ্টা করা হয় প্রথম স্তরের
                অর্থনৈতিক ক্রিয়াকলাপ দ্বারা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    23. কোয়াটারনারি অ্যাকটিভিটি বলতে কোন স্তরের অর্থনৈতিক
                    ক্রিয়াকলাপকে বােঝায়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কোয়াটারনারি অ্যাকটিভিটি বলতে চতুর্থ স্তরের অর্থনৈতিক
                ক্রিয়াকলাপকে বােঝায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    24. বিশেষজ্ঞের কাজ কোন্ স্তরের অর্থনৈতিক কাজের অন্তর্ভুক্ত?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বিশেষজ্ঞের কাজ পঞ্চম স্তরের অর্থনৈতিক কার্যাবলির অন্তর্ভুক্ত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    25. পরামর্শদান কোন স্তরের অর্থনৈতিক কার্যাবলির অন্তর্গত?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পরামর্শদান পঞ্চম স্তরের অর্থনৈতিক কার্যাবলির অন্তর্গত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    26. থিংক ট্যাংক (think tank) কোন্ স্তরের কর্মীদের বলা হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পঞ্চম স্তর বা সােনালি কলার কর্মীদের থিংক ট্যাংক বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    27. কুইনারি অ্যাকটিভিটি বলতে কোন্ স্তরের কার্যাবলিকে
                    বােঝায়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কুইনারি অ্যাকটিভিটি বলতে পঞ্চম স্তরের কার্যাবলিকে বােঝায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. নীল পােশাকের শ্রমজীবীর একটি উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                নীল পােশাকের শ্রমজীবীর একটি উদাহরণ হল-নির্মাণ কাজে নিযুক্ত
                ব্যক্তি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ৭</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. অর্থনৈতিক ক্রিয়াকলাপ কাকে বলে? বিভিন্ন ক্ষেত্র সহকারে
                  অর্থনৈতিক ক্রিয়াকলাপের শ্রেণিবিভাগ করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>অর্থনৈতিক ক্রিয়াকলাপ : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                বিভিন্ন জীবিকা বা পেশায় নিযুক্ত থেকে প্রত্যক্ষ বা পরােক্ষভাবে
                সম্পদ উৎপাদন, বণ্টন, বিনিময়, ভােগ এবং পরিসেবাকে কেন্দ্র করে
                পৃথিবী জুড়ে মানুষের যে-সমস্ত ক্রিয়াকলাপ গড়ে উঠেছে, তাকে
                অর্থনৈতিক ক্রিয়াকলাপ (Economic Activities) বলে। অর্থাৎ
                জীবনধারণের উদ্দেশ্যে যে যে কাজ করলে মানুষের চাহিদা মেটে, সম্পদের
                উপযােগিতা বাড়ে, মানুষ স্বনির্ভর হয় এবং দেশের আর্থসামাজিক
                উন্নতি হয়, তাই হল অর্থনৈতিক কাজ। এই কারণে অর্থনৈতিক কাজকে অনেক
                অর্থনীতিবিদরা সামাজিক কাজ-ও (Social Activity) বলে থাকেন।
                <br />
                <br />
                <font color="#29900a">
                  <b>অর্থনৈতিক ক্রিয়াকলাপের শ্রেণিবিভাগ : </b>{" "}
                </font>{" "}
                কাজের ধরন এবং বৈশিষ্ট্য অনুযায়ী কোনাে কোনাে ক্ষেত্রে দৈহিক শ্রম
                বেশি লাগে। আবার কোননা ক্ষেত্রে মানসিক শক্তি বা বুদ্ধি বেশি লাগে।
                এইসব বৈশিষ্ট্যের ওপর ভিত্তি করে অর্থনৈতিক ক্রিয়াকলাপকে পাঁচ
                ভাগে ভাগ করা যায় 一<br />
                <br />
                <font color="#f626d7">
                  <b>(১) প্রাথমিক ক্ষেত্র : </b>{" "}
                </font>{" "}
                বনজ সম্পদ সংগ্রহ ও পশুশিকার, খনিজ সম্পদ আহরণ, মৎস্যশিকার,
                জীবিকাসত্তাভিত্তিক কৃষি ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(২) দ্বিতীয় বা গৌণ ক্ষেত্র : </b>{" "}
                </font>{" "}
                শ্রমশিল্প, প্রক্রিয়াকরণ ও দ্রব্যের রূপান্তরকরণ, বাণিজ্যিক কৃষি
                ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(৩) তৃতীয় বা সেবা ক্ষেত্র : </b>{" "}
                </font>{" "}
                ব্যাবসাবাণিজ্য, যােগাযােগ ও পরিবহণ, পরিসেবা ইত্যাদি
                <br />
                <br />
                <font color="#f626d7">
                  <b>(৪) চতুর্থ ক্ষেত্র বা নব্য কার্যাবলি : </b>{" "}
                </font>{" "}
                ব্যাংকিং, বিমা, মিউনিসিপ্যাল পরিসেবা ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(৫) পঞ্চম ক্ষেত্র বা সর্বোচ্চ পর্যায়ের কার্যাবলি : </b>{" "}
                </font>{" "}
                আইনি পরামর্শ, আর্থিক উপদেষ্টা, পেশাদান উপদেষ্টা ইত্যাদি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  2. প্রথম ও দ্বিতীয় স্তরের অর্থনৈতিক ক্রিয়াকলাপের সংজ্ঞা ও
                  বৈশিষ্ট্য ব্যাখ্যা করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>প্রথম স্তরের অর্থনৈতিক ক্রিয়াকলাপ : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                <br />
                <br />
                <font color="#f626d7">
                  <b>সংজ্ঞা : </b>{" "}
                </font>{" "}
                প্রকৃতির সহায়তায় মানুষ নিজের ও সমাজের প্রাথমিক চাহিদা (খাদ্য,
                বস্ত্র, বাসস্থান) পূরণে প্রাকৃতিক সম্পদ সংগ্রহ, প্রতিপালন ও
                উৎপাদনভিত্তিক যেসব কাজ করে, তাকে প্রাথমিক বা প্রথম স্তরের
                অর্থনৈতিক ক্রিয়াকলাপ বলে। কৃষিকাজ, মৎস্যচাষ, বনজসম্পদ সংগ্রহ,
                পশুপালন ও শিকার বৃত্তি – এই পাঁচটি অর্থনৈতিক উদ্যোগকে এক কথায়
                প্রাথমিক অর্থনৈতিক কাজ বলা হয়।
                <br /> <br />
                <font color="#f626d7">
                  <b>বৈশিষ্ট্য : </b>{" "}
                </font>{" "}
                প্রথম স্তরের অর্থনৈতিক ক্রিয়াকলাপের বৈশিষ্ট্যগুলি হল –<br />
                (i) প্রথম স্তরের অর্থনৈতিক কাজের মাধ্যমে মানুষ তার প্রাথমিক
                চাহিদা মেটানোর চেষ্টা করে।
                <br />
                (ii) প্রকৃতি থেকে সরাসরি সম্পদ সংগ্রহ এই কাজের সঙ্গে যুক্ত।
                <br />
                (iii) তাই এটি প্রাচীনতম অর্থনৈতিক উদ্যোগ।
                <br />
                (iv) প্রথম স্তরের অর্থনৈতিক কাজের ওপর প্রাকৃতিক পরিবেশের
                নিয়ন্ত্রণ সবচেয়ে বেশি।
                <br />
                (v) পৃথিবীর সবচেয়ে বেশি মানুষ প্রথম স্তরের অর্থনৈতিক কাজকে পেশা
                হিসেবে গ্রহণ করেছে। উন্নয়নশীল দেশগুলিতে এই সংখ্যা সবচেয়ে বেশি।
                <br />
                (vi) প্রথম স্তরের অর্থনৈতিক কাজ গ্রামভিত্তিক।
                <br />
                (vii) এই ধরনের কাজকর্মে দৈহিক অর্থাৎ কায়িক শ্রম বেশি লাগে এবং
                সব কাজ ঘরের বাইরেই করতে হয়। এই কাজে নিযুক্ত কর্মীদের Red collar
                Workers (লাল কলার শ্রমিক) বলা হয়।
                <br /> <br />
                <font color="#29900a">
                  <b>
                    দ্বিতীয় স্তরের বা গৌণক্ষেত্রের অর্থনৈতিক ক্রিয়াকলাপ :{" "}
                  </b>{" "}
                </font>{" "}
                <br /> <br />
                <font color="#f626d7">
                  <b>সংজ্ঞা : </b>{" "}
                </font>{" "}
                প্রাথমিক ক্রিয়াকলাপের দ্বারা উৎপাদিত দ্রব্যসমূহকে যেসব
                ক্রিয়াকলাপের মাধ্যমে রূপান্তরিত করে ভোগ্যপণ্যে পরিণত করা হয়
                তাকে দ্বিতীয় শ্রেণির ক্রিয়াকলাপ বলে। যেমন – শিল্পজাত পণ্য
                উৎপাদন এই স্তরের কর্মের অন্তর্ভুক্ত।
                <br /> <br />
                <font color="#f626d7">
                  <b>বৈশিষ্ট্য : </b>{" "}
                </font>{" "}
                দ্বিতীয় স্তরের অর্থনৈতিক ক্রিয়াকলাপের বৈশিষ্ট্যগুলি হল –<br />{" "}
                <br />
                (i) দ্বিতীয় স্তরের অর্থনৈতিক কাজের মাধ্যমে মানুষ স্থায়ী সম্পদ
                সৃষ্টি করে ও ভোগ্যপণ্যের চাহিদা মেটায়।
                <br />
                (ii) দ্বিতীয় স্তরের অর্থনৈতিক কাজ সম্পদের মূল্য, মান, উপযোগিতা
                ও কার্যকারিতা বৃদ্ধি করে।
                <br />
                (iii) দ্বিতীয় স্তরের অর্থনৈতিক কাজ উন্নত প্রযুক্তির ওপর
                নির্ভরশীল। তাই শিক্ষায়, গবেষণায়, কারিগরি ক্ষেত্রে যে দেশ যত
                উন্নত, সেই দেশ আলোচ্য অর্থনৈতিক কাজও তত বেশি উন্নত।
                <br />
                (iv) দ্বিতীয় স্তরের অর্থনৈতিক কাজ সাধারণত নগরভিত্তিক।
                <br />
                (v) এটি এক বিশাল পরিসরের অর্থনৈতিক ক্রিয়াকলাপ। এই ধরনের কাজে
                দেশের অগ্রগতি দ্রুত বিকাশ লাভ করে। এই উৎপাদন মূলক কাজে নিযুক্ত
                কর্মীদের Blue-collar Workers (নীল কলার শ্রমিক) বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  3. তৃতীয় ও চতুর্থ ক্ষেত্রের অর্থনৈতিক ক্রিয়াকলাপের সংজ্ঞা ও
                  বৈশিষ্ট্য লেখো।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b> তৃতীয় বা সেবাক্ষেত্রের অর্থনৈতিক ক্রিয়াকলাপ : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                <br />
                <br />
                <font color="#f626d7">
                  <b>সংজ্ঞা : </b>{" "}
                </font>{" "}
                প্রাথমিক ও গৌণ কার্যাবলি সুষ্ঠুভাবে সম্পাদন বা সমন্বয়সাধনের
                জন্য মানুষ কোনো ব্যক্তি বা গোষ্ঠীকে যে সেবা বা সাহায্য প্রদান
                করে এবং উৎপাদন ব্যবস্থাকে সচল রাখে, তাকে তৃতীয় স্তর বা
                সেবাক্ষেত্রের অর্থনৈতিক ক্রিয়াকলাপ বলে। আর্থিক ও স্বাস্থ্য
                পরিসেবা, ব্যবসা বাণিজ্য, পরিবহণ, যোগাযোগ, পর্যটন, ব্যাংকিং,
                পরিসেবার কাজ, বিনোদনমূলক (গান-বাজনা, খেলাধুলা, নাটক, থিয়েটার,
                সিনেমা প্রভৃতি) কাজ – এই ক্ষেত্রের অন্তর্ভুক্ত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>বৈশিষ্ট্য : </b>{" "}
                </font>{" "}
                তৃতীয় স্তরের অর্থনৈতিক ক্রিয়াকলাপের বৈশিষ্ট্যগুলি হল –<br />
                <br />
                (i) তৃতীয় স্তরের অর্থনৈতিক কাজ প্রথম ও দ্বিতীয় স্তরের
                অর্থনৈতিক কাজের মধ্যে সংযোগ ঘটায়।
                <br />
                (ii) তৃতীয় স্তরের অর্থনৈতিক কাজ উৎপাদন বৃদ্ধি করতে সাহায্য করে।
                বাণিজ্যের প্রসার ঘটায় এবং অর্থনৈতিক উন্নয়নে গতি আনে।
                <br />
                (iv) এই স্তরে সাধারণ ও ব্যক্তিগত পর্যায়ে পরিসেবা প্রদান করা
                হয়ে থাকে।
                <br />
                (v) ব্যবসা বাণিজ্যের মাধ্যমে ভোগ্যপণ্য পরিসেবা দেওয়াই এর প্রধান
                উদ্দেশ্য। খুচরো বিক্রেতা, মুচি, বিউটিশিয়ান প্রভৃতি কর্মী এই
                শ্রেণির পরিসেবামূলক কাজের অন্তর্গত। এই কাজে নিযুক্ত কর্মীদের
                Pink-collar Workers (গোলাপি কলার শ্রমিক) বলা হয়।
                <br />
                <br />
                <font color="#29900a">
                  <b>চতুর্থ ক্ষেত্রের অর্থনৈতিক ক্রিয়াকলাপ : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>সংজ্ঞা : </b>{" "}
                </font>{" "}
                তৃতীয় স্তরের অর্থনৈতিক কাজ যখন আরও উন্নত হয়, তখন চতুর্থ স্তরের
                অর্থনৈতিক কাজের (Quaternary Activities) উদ্ভব হয়। এই শ্রেণির
                কাজ অতি উন্নত ও আধুনিক। অতি দক্ষ শ্রমিক ও উন্নত মেধা ছাড়া এই
                ধরনের অর্থনৈতিক উদ্যোগ গড়ে তোলা সম্ভব নয়। তথ্য ও সংবাদ
                পরিবেশন, তথ্য-বিশ্লেষণ প্রক্রিয়া, গবেষণা, শিক্ষাদান, সরকারি
                আমলাতান্ত্রিক সার্ভিস, তথ্য প্রযুক্তি প্রভৃতি কাজ এই পর্যায়ের
                অন্তর্গত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>বৈশিষ্ট্য : </b>{" "}
                </font>{" "}
                চতুর্থস্তরের অর্থনৈতিক ক্রিয়াকলাপের বৈশিষ্ট্যগুলি হল –<br />
                <br />
                (i) এটি এক বিশেষ ধরনের পরিসেবামূলক স্তরকে নির্দেশ করে। এক্ষেত্রে
                প্রধান পেশাদারি ও প্রশাসনিক কাজের ওপর গুরুত্ব দেওয়া হয়।
                <br />
                (ii) এই অর্থনৈতিক ক্রিয়াকলাপ মূলত উচ্চ প্রযুক্তিনির্ভর এবং এর
                মাধ্যমে বহু লোকের কর্মসংস্থান হয়।
                <br />
                (iii) এই অর্থনৈতিক ক্রিয়াকলাপের ফলে উন্নত যোগাযোগ প্রতিষ্ঠিত
                হয়। যেমন – টেলিকমিউনিকেশন ব্যবস্থার উন্নতি যোগাযোগ ব্যবস্থায়
                যুগান্তকারী পরিবর্তন এনেছে।
                <br />
                (iv) এই ক্ষেত্রের সমস্ত কাজ অফিস-বিল্ডিং, স্কুল, কলেজ, থিয়েটার,
                হোটেল ও হাসপাতাল – এ জাতীয় পরিবেশে করা হয়। তাই একে
                প্রাতিষ্ঠানিক পরিসেবা আখ্যা দেওয়া হয়। এই ক্ষেত্রের কর্মীরা এক
                বিশেষ ধরনের পরিসেবা প্রদান করে বলে এদের White-collar Workers
                (সাদা কলার শ্রমিক) বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  4. প্রাথমিক ক্ষেত্রের অর্থনৈতিক কার্যাবলি ও দ্বিতীয় ক্ষেত্রের
                  অর্থনৈতিক কার্যাবলির মধ্যে পার্থক্য নির্দেশ করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b> উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                <br />

                <table>
                  <colgroup>
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>প্রাথমিক ক্ষেত্রের অর্থনৈতিক কার্যাবলি</b>
                          </font>
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>দ্বিতীয় ক্ষেত্রের অর্থনৈতিক কার্যাবলি</b>
                          </font>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">
                            প্রধানত নিজেদের প্রাথমিক চাহিদা মেটাতে প্রাকৃতিক
                            সম্পদ সংগ্রহ বা উৎপাদনের জন্য মানুষ যেসব কাজ করে
                            সেগুলিকেই প্রাথমিক ক্ষেত্রের অর্থনৈতিক কার্যাবলি
                            বলে।
                          </font>
                        </span>
                      </td>
                      <td>
                        <span>
                          সংস্কৃতির সাহায্য নিয়ে প্রাথমিক ক্ষেত্রে উৎপাদিত
                          পণ্যদ্রব্যের প্রক্রিয়াকরণকে দ্বিতীয় ক্ষেত্রের
                          অর্থনৈতিক কার্যাবলি বলে।
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">
                            প্রধানত উদ্ভিদ ও প্রাণীর গার্হস্থ্যকরণ প্রক্রিয়ার
                            ওপর ভিত্তি করেই এই ক্রিয়াকলাপের বিকাশ ঘটেছে।
                          </font>
                        </span>
                      </td>
                      <td>
                        <span>
                          এই ক্রিয়াকলাপের উন্নতির জন্য প্রয়ােজন আধুনিক
                          প্রযুক্তি ও মূলধন।
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">
                            প্রাকৃতিক পরিবেশের প্রভাব বেশ গুরুত্বপূর্ণ।
                          </font>
                        </span>
                      </td>
                      <td>
                        <span>
                          প্রাকৃতিক পরিবেশের প্রভাব বা নিয়ন্ত্রণ খুবই কম।
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">
                            এই ধরনের অর্থনৈতিক ক্রিয়াকলাপে মানুষের চিরাচরিত
                            দক্ষতা ও জৈব শক্তির ব্যবহার তথা গুরুত্ব খুব বেশি।
                          </font>
                        </span>
                      </td>
                      <td>
                        <span>
                          এক্ষেত্রে বিপুল পরিমাণে জড়শক্তি, বিশেষত কয়লা,
                          পেট্রোলিয়াম প্রভৃতি জীবাশ্ম জ্বালানি ব্যবহৃত হয়।
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">
                            প্রাথমিক ক্রিয়াকলাপের সঙ্গে যুক্ত কর্মীদের
                            'Red-collar Workers' বলা হয়।
                          </font>
                        </span>
                      </td>
                      <td>
                        <span>
                          যেসব শ্রমজীবী মানুষ এই ধরনের কাজে নিয়ােজিত আছে, তাদের
                          ‘Blue-collar Workers' বলা হয়।
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

অর্থনৈতিকক্রিয়াকলাপ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(অর্থনৈতিকক্রিয়াকলাপ);
