import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function কয়েকটিপ্রধানরাজনৈতিকমতবাদ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          কয়েকটি প্রধান রাজনৈতিক মতবাদ | West Bengal Class 12 Guide | West
          Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="কয়েকটি প্রধান রাজনৈতিক মতবাদ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                কয়েকটি প্রধান রাজনৈতিক মতবাদ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Liberalism গ্রন্থের রচয়িতা হলেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বেন্থাম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হবহাউস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  টকভিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জেমস মিল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>উদারনীতিবাদের উদ্ভব ঘটে —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিংশ শতকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সপ্তদশ শতকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ঊনবিংশ শতকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অষ্টাদশ শতকে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Das Capital-এর রচয়িতা হলেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  কার্ল মার্কস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  টট্রস্কি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বুখারিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হেগেল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কোন্ ঘটনা উদারনীতিবাদের ভিত্তিকে সুদৃঢ় করেছিল?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গৃহ যুদ্ধ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  গৌরবময় বিপ্লব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আমেরিকার স্বাধীনতা সংগ্রাম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফরাসি বিপ্লব
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কার্ল মার্কসের জন্ম হয়েছিল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1845 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1830 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1870 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1818 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>আইন অমান্য আন্দোলন হয়েছিল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1951–55 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1920–22 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1930–34 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1927–32 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  যে পত্রিকায় গান্ধিজি তাঁর সত্যাগ্রহ সম্পর্কে বক্তব্য রাখেন —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অমৃতবাজার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দ্য হিন্দু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সমাচার দর্পণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ইয়ং ইন্ডিয়া
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  বিশ্বের প্রথম সমাজতান্ত্রিক দেশ হিসেবে সোভিয়েত ইউনিয়নের
                  আত্মপ্রকাশ ঘটে —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1910 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1917 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1920 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1915 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  কতদিন ধরে উদারনীতিবাদ পশ্চিমী দুনিয়ার একটি বিশিষ্ট মতবাদ
                  হিসেবে বিবেচিত হয়ে আসছে?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  300 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  200 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  150 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  450 বছর
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ‘পুরোনো সমাজের গর্ভে যখন নতুন সমাজের উদ্ভব ঘটে, শক্তি তখন
                  ধাত্রী হিসেবে কাজ করে’ — উক্তিটির বক্তা হলেন —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মুসোলিনি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্তালিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মার্কস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হিটলার
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>পঞ্চম অধ্যায় - কয়েকটি প্রধান রাজনৈতিক মতাদর্শ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. সত্যাগ্রহের অর্থ কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সত্যাগ্রহের অর্থ হল — সত্যের প্রতি চরম আগ্রহ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. মার্কস কার ভাববাদী দর্শন দ্বারা প্রভাবিত হয়েছিল?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মার্কস জার্মান আদর্শবাদী দার্শনিক হেগেল দ্বারা প্রভাবিত হয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    3. মার্কস ও এঙ্গেলসের উদ্বৃত্ত মূল্যতত্ত্ব কোন্ তত্ত্বের
                    দ্বারা প্রভাবিত হয়েছিল?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অ্যাডাম স্মিথ ও ডেভিড রিকার্ডোর ‘মূল্যের শ্রম তত্ত্ব’ দ্বারা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. মার্কসের মতে উৎপাদন পদ্ধতির ক-টি দিক ও কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মার্কসের মতে উৎপাদন পদ্ধতির দুটি দিক। যথা —<br />
                (1) উৎপাদন শক্তি ও (2) উৎপাদন সম্পর্ক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. দ্বন্দ্বমূলক বস্তুবাদের মূল বক্তব্য কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মার্কসের মতে জগৎ বস্তুময়। এই জগতের প্রতিটি বস্তু পরস্পরের উপর
                নির্ভরশীল এবং পরস্পরের দ্বারা নিয়ন্ত্রিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. লেনিনের মতানুযায়ী শ্রেণির সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                নির্দিষ্ট আর্থসামাজিক ব্যবস্থায় শ্রেণি হল এমন এক জনগোষ্ঠী যাদের
                পৃথক অর্থনৈতিক অবস্থান রয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. মার্কসবাদে লেনিনের একটি অবদান লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                লেনিন মার্কসবাদকে তত্ত্ব থেকে বাস্তবে প্রয়োগ ঘটায়। তা ছাড়া
                তাঁর আর একটি অবদান হল বিপ্লবী পার্টি সংগঠন সম্পর্কিত তত্ত্ব।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. উদারনীতিবাদ সম্পর্কে মার্কসীয় ধারণা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মার্কসীয় ধারণা অনুযায়ী উদারনীতিবাদ বুর্জোয়া শ্রেণির
                স্বার্থরক্ষা করে। এটি পুঁজিবাদেরই অন্য একটি রূপ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. সত্যাগ্রহ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                গান্ধিজির মতে সত্যাগ্রহ হল সত্যের প্রতি চরম আগ্রহ। এটি এমন এক
                আদর্শ যেখানে প্রেম ও ভালোবাসার সাহায্যে প্রতিপক্ষের হৃদয়
                পরিবর্তন করা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. রাষ্ট্র সম্পর্কে গান্ধিজির ধারণা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                গান্ধিজি আধুনিক রাষ্ট্রকে বলপ্রয়োগের আধার হিসেবে দেখেছেন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. গান্ধিজির চিন্তার উৎসগুলি কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                গান্ধিজির চিন্তার উৎসগুলি হল — বেদ্, বাইবেল, কোরান, ভগবৎ গীতা,
                বৈয়ব ধর্মমত ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    12. গান্ধিজির রাজনৈতিক চিন্তায় কোন্ কোন্ মনীষীর প্রভাব
                    রয়েছে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                লিও টলস্টয়, জন রাস্কিন এবং থোরো প্রমুখ মনীষীর প্রভাব রয়েছে
                গান্ধিজির রাজনৈতিক চিন্তাধারায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. গান্ধিজির সত্যাগ্রহের কয়েকটি বৈশিষ্ট্য লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                গান্ধিজির সত্যাগ্রহের কয়েকটি বৈশিষ্ট্য হল — অহিংসা, ভয়শূন্যতা
                এবং সৃজনশীলতা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. উদারনীতিবাদের একটি বৈশিষ্ট্য লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                উদারনীতিবাদ ব্যক্তি স্বাধীনতার উপর অধিক গুরুত্ব আরোপ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. পাশ্চাত্যের একটি মতবাদের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পাশ্চাত্যের একটি মতবাদের নাম হল উদারনীতিবাদ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. মার্কসবাদের মূল উৎস কয়টি ও কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মার্কসবাদের মূল উৎস তিনটি। এগুলি হল — জার্মান দর্শন, ব্রিটিশ
                অর্থনীতি ও ফরাসি সমাজতন্ত্র।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    17. ‘ স্বাভাবিক অধিকার’ বলতে লক্ কোন্ কোন্ অধিকারের কথা
                    বলেছেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                লক্রে মতে, ‘স্বাভাবিক অধিকার’ হল জীবন, স্বাধীনতা ও সম্পত্তির
                অধিকার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    18. কে রাষ্ট্রকে ‘প্রয়োজনীয় অথচ ক্ষতিকারক’ বলে চিহ্নিত
                    করেছেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                জন স্টুয়ার্ট মিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. হিতবাদীদের মতে রাষ্ট্রের লক্ষ্য কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ‘সর্বাধিক ব্যক্তির সর্বাধিক সুখ’ হল হিতবাদীদের মতে রাষ্ট্রের মূল
                লক্ষ্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. জনকল্যাণকর রাষ্ট্রতত্ত্বের দুইজন প্রচারকের নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                কেইনস্, বেভারিজ প্রমুখ।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  21. মার্কস ও এঙ্গেলস ‘কমিউনিস্ট ম্যানিফেস্টোতে’ কী বলেছেন?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                তাঁরা বলেছেন — আজ পর্যন্ত যত সমাজ দেখা গেছে, তাদের সকলের ইতিহাস
                হল শ্রেণিসংগ্রামের ইতিহাস। <br />
                <br />
                <font color="#f626d7">
                  <b>22. সাম্যবাদী সমাজের মূল কথা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                এইরূপ সমাজে প্রত্যেকে তার সামর্থ্য অনুযায়ী কাজ করবে এবং
                প্রত্যেকে তার প্রয়োজন অনুযায়ী ভোগ করবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. মার্কসের মতে বিপ্লব কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মার্কসের মতে বিপ্লব হল ইতিহাসের মূল চালিকা শক্তি। বিপ্লব কোনো
                ধ্বংস নয়, এ হল পুরাতনের গর্ভে নতুনের সৃষ্টি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. নয়া উদারনীতিবাদের দুজন প্রবক্তার নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                নয়া উদারনীতিবাদের অন্যতম দুইজন প্রবক্তা হলেন—রবার্ট নোজিক ও
                কেইনস্।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. উৎপাদন শক্তি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                কোনো একটি উৎপাদন ব্যবস্থায় উৎপাদন কার্যে নিযুক্ত শ্রমিক, তার
                শ্রম ক্ষমতা এবং উৎপাদন কার্যে ব্যবহৃত আনুষঙ্গিক সকল যন্ত্রপাতি
                হল উৎপাদন শক্তি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. উৎপাদন সম্পর্ক কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                কোনো একটি উৎপাদন প্রক্রিয়ায় উৎপাদন কার্যকে কেন্দ্র করে মানুষে
                মানুষে তথা শ্রেণিতে শ্রেণিতে উৎপাদনভিত্তিক পারস্পরিক সম্পর্ককে
                উৎপাদন সম্পর্ক বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. রামরাজ্য কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                গান্ধিজির মতে সত্য, অহিংসা ও সাম্যের উপর প্রতিষ্ঠিত শোষণহীন ও
                বিকেন্দ্রীকরণ সম্পন্ন গণতান্ত্রিক এক আদর্শ সমাজই হল রামরাজ্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. সর্বোদয়ের তিনটি মূল নীতি কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সর্বোদয়ের তিনটি মূল নীতি হল — (1) সকলের কল্যাণের মধ্য দিয়েই
                নিজের কল্যাণ সাধিত হয়। (2) সকল প্রকার শ্রমের সমমূল্য রয়েছে।
                (3) একটি শ্রমনির্ভর জীবনই আদর্শ জীবন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. গান্ধিজির স্বরাজ সংক্রান্ত ধারণা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                স্বরাজ বা স্বশাসন হল এমন এক আদর্শ সমাজ ব্যবস্থা এবং এক উন্নততর
                সামাজিক অবস্থা যার মূল ভিত্তি হল সাম্য, স্বাধীনতা ও ন্যায়বিচার
                এবং বিকেন্দ্রীকরণ নীতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. মার্কসবাদ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মার্কসবাদ হল একটি বিশ্ববিক্ষা। বৈজ্ঞানিক বস্তুবাদী দৃষ্টিভঙ্গির
                সাহায্যে মার্কস মানব সমাজের বিবর্তন ও বিকাশের যে ধারা ব্যাখ্যা
                বিশ্লেষণ করেছেন তাই হল মার্কসবাদ।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. মার্কস – এর রাষ্ট্র ও বিপ্লব – সংক্রান্ত তত্ত্ব দু’টি
                  আলোচনা করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                পূর্বসূরি চরম অনুকরণ না করে, কার্ল মার্কস তাদের চিন্তা-ভাবনা
                নিষ্পত্তিকরভাবে সমালোচনা করেন এবং একটি তাত্ত্বিক প্রণালী উন্নীত
                করেন। মানব সভ্যতার ইতিহাসে, এই তাত্ত্বিক ধারণা কে "মার্কসবাদ"
                নামে পরিচিত। মার্কসবাদ শুধুমাত্র একটি তাত্ত্বিক ধারণা নয়; এটি
                একটি অবিনির্মিত এবং গতিশীল বৈজ্ঞানিক মতবাদ। এই বৈজ্ঞানিক
                মতবাদটিকে শক্তিশালী তত্ত্বে উন্নত এবং বৌদ্ধিক বিশ্বে মর্যাদা
                অধিষ্ঠিত করতে মার্কস অবদান রাখেন। এ জন্যই তাকে "বৈজ্ঞানিক
                সমাজতন্ত্রের জনক" বলা হয়।
                <br />
                <br />
                <font color="#1f7405">
                  <b>➺ </b>{" "}
                </font>{" "}
                <font color="#f626d7">
                  <b> রাষ্ট্র-সম্পর্কিত তত্ত্ব : </b>{" "}
                </font>{" "}
                মার্কসবাদী তত্ত্বে রাষ্ট্রের উৎপত্তি এবং প্রকৃতি সম্পর্কে
                গুরুত্ব দেওয়া হয়। এই তত্ত্ব অনুসারে, রাষ্ট্রের উৎপত্তি হঠাৎ
                ঘটে না। আবার, সমাজের বুকে সর্বদা রাষ্ট্রের অস্তিত্ব নেই।
                সমাজবিবর্তনের এক বিশেষ স্তরে, ব্যক্তিগত সম্পত্তির উদ্ভবের
                পাশাপাশি ধনী শ্রেণির স্বার্থ সংরক্ষণ করার উদ্দেশ্যেই রাষ্ট্র গঠন
                করা হয়। বাস্তবিকভাবে, শ্রেণিবিভক্ত সমাজে রাষ্ট্রের উদ্ভব
                বলপ্রয়োগকারী প্রতিষ্ঠান হল। এবং বিত্তশালীরা রাষ্ট্রসংক্রান্ত
                রক্ষণাবেক্ষণ করে এবং সংখ্যাগরিষ্ঠ মানুষের উপর শোষণ এবং নিষ্পেষণ
                চালায় রাষ্ট্রযন্ত্রের মাধ্যমে। রাষ্ট্র সমাজের সাধারণ জনসাধারণের
                ভালোবাসা ও মঙ্গলকামনা প্রকাশ করার জন্য নির্দিষ্ট প্রতিষ্ঠান নেই,
                বরং সমাজের প্রভাবশালী শ্রেণির হাতে স্থাপন করা হয়। এই সংগঠনের
                লক্ষ্য হল অন্য শ্রেণির উপর শোষণ ও নিষ্পেষণ ব্যাতীত রাখা, এবং
                কার্ল মার্কস এই সত্যটি প্রমাণিত করতে চেয়েছিলেন, যা রাষ্ট্র
                সম্পর্কিত সনাতন তত্ত্বের মৌলিক নীতিতে ভর্তি হয়।
                <br />
                <br />
                <font color="#1f7405">
                  <b>➺ </b>{" "}
                </font>{" "}
                <font color="#f626d7">
                  <b> বিপ্লব-সংক্রান্ত তত্ত্ব : </b>{" "}
                </font>{" "}
                মার্কসবাদে বিপ্লব একটি ঐতিহাসিক প্রক্রিয়া। এর মাধ্যমে সমাজে
                পরিবর্তনের পথ খুবই গুরুত্বপূর্ণ হয়। বিপ্লবের ধারাবাহিকতা
                অনুসারে, এক শাসক শ্রেণি অপসারিত হয় এবং অন্য শাসক শ্রেণি ক্ষমতার
                সামনে আসে। নতুন শাসকেরা উৎপাদন ব্যবস্থা এবং সামাজিক দৃষ্টিকোণ
                থেকে পুরাতন শাসক শ্রেণির স্থানে অধিকতর উন্নত অবস্থায় আসে।
                মার্কসবাদ অনুযায়ী, বিপ্লবের মাধ্যমেই পুরাতন সামাজিক ব্যবস্থা
                ধ্বংস হয় এবং নতুন সমাজ আত্মপ্রকাশ করে। তবে, বিপ্লব সম্পর্কে এই
                অবস্থাটি সহজভাবে মন্তব্য করা হয় না। এটির জন্য দুটি অবস্থা
                প্রয়োজন। <br />
                <br />
                <font color="#1f7405">
                  <b>➺ </b>{" "}
                </font>{" "}
                <font color="#f626d7">
                  <b> বিপ্লবের দুইটি অবস্থা হল : </b>{" "}
                </font>{" "}
                বিষয়গত অবস্থা এবং বিষয়ীগত অবস্থা। এছাড়াও, মার্কসবাদ অনুযায়ী,
                বিপ্লবে হিংসা সম্পন্ন হয় না, বরং শাসক শ্রেণির মধ্যে বিপ্লবে
                হিংসার প্রবেশ ঘটে। আবারও, মার্কসবাদ-বিরোধী তত্ত্ববিদরা বিপ্লবকে
                গণতন্ত্র-বিরোধী মনে করে এবং মার্কসবাদের দৃষ্টিকোণে বিপ্লবের
                মধ্যেই গণতন্ত্রের নিষ্যোজন দেওয়া হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  {" "}
                  2. গান্ধিজির চিন্তাধারার বা রাজনৈতিক দর্শনের মূল সূত্রগুলো
                  আলোচনা করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতীয় রাষ্ট্রীয় দর্শনে গান্ধিজির চিন্তাধারা ও রাজনৈতিক দর্শন
                একইসাথে মৌলিক এবং একক। তার সমগ্র চিন্তাধারা সত্য এবং অহিংসা
                নীতির উপর নির্ভর করে। প্রাচীন ভারতবর্ষকে সাম্রাজ্যবাদী ইংরেজ
                শাসন থেকে মুক্তি প্রদানের জন্য গান্ধিবাদের ভূমিকা অত্যধিক
                গুরুত্বপূর্ণ ছিল। গান্ধিজির সমগ্র জীবনাদর্শন গান্ধিবাদ নামে
                পরিচিত। 'হরিজন পত্রিকা', 'The Story of My Experiments with
                Truth', 'স্বরাজ' প্রভৃতি গ্রন্থে তার এই জীবনাদর্শনের অবিচ্ছেদ্য
                ভূমিকা প্রতিষ্ঠিত হয়। ভারতীয় বেদ, পুরাণ, ভাগবত গীতা গান্ধিজিকে
                প্রবলভাবে প্রভাবিত করেছিলেন। এছাড়াও, পাশ্চাত্য তাত্ত্বিকদের
                মধ্যে টলস্টয়, থোরো এমন প্রমুখদের চিন্তাদর্শনও গান্ধিজিকে
                প্রভাবিত করেছিল।
                <br />
                গান্ধিজির চিন্তাধারার মূল সূত্রগুলো নিম্নরূপ -<br />
                <br />
                <font color="#f626d7">
                  <b> 1. সত্য ও অহিংসা ➺ </b>{" "}
                </font>{" "}
                ভারতীয় সংস্কৃতি এবং বৈয়ব পারিবারিক সূত্রে সত্য ও অহিংসের নীতি
                তাঁর রাজনৈতিক দর্শনে বিশেষ স্থান পেয়েছে। গান্ধিজির মতে সত্য হল
                ঈশ্বরের সমান। ইংরেজ সাম্রাজ্যবাদী শক্তির হিংসাত্মক বল প্রয়োগ
                তিনি ঘৃণার চোখে দেখেছেন। গান্ধিজির মতে অহিংসা হল একটি
                সমর্থনপূর্ণ ভালোবাসা মাধ্যমে। অহিংসার পথ একে অপরের সাথে সত্যের
                প্রতি প্রবৃত্তি উত্তেজিত করে। গান্ধিজি তাঁর 'হিন্দ স্বরাজ'
                গ্রন্থে সম্রাট, সত্য ও অহিংসা জীবনের ধর্ম হিসেবে উল্লিখিত
                করেছেন। এই সত্য ও অহিংসার বোধ জীবনে নৈতিক শক্তি প্রদান করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b> 2. সত্যাগ্রহ ➺ </b>{" "}
                </font>{" "}
                সত্যাগ্রহ গান্ধিজির একটি মৌলিক ধারণা, যা সত্যের প্রতি অত্যন্ত
                আগ্রহ নির্মিত করে। সত্যাগ্রহ একটি পদ্ধতি, যেখানে প্রেম এবং
                ভালোবাসার সাহায্যে শত্রু পক্ষের হৃদয় জয় করা হয়। সত্য, অহিংসা
                এবং ন্যায়ের মাধ্যমে সকল শিষ্টাচার এবং ত্যাগের মাধ্যমে অপরের
                অন্যায়কে প্রতিরোধ করাই সত্যাগ্রহ। এটি একটি কঠিন সাধনা, যা
                অন্যকে বদলে দেওয়া হয়। সত্যাগ্রহের বিভিন্ন পথ এরকম বিচিত্রঃ
                আমরণ অনশন, অসহায়তা আন্দোলন, আইনের বিধিমান নিরাকরণ, বয়কট,
                পিকেটিং ইত্যাদি। এককথায়, সত্যাগ্রহীরা নির্ভীক যোদ্ধা, যাদের
                মধ্যে ঘৃণা, ক্রোধ এবং হিংসার কোন স্থান নেই।
                <br />
                <br />
                <font color="#f626d7">
                  <b> 3. সর্বোদয় ➺ </b>{" "}
                </font>{" "}
                সর্বোদয় শব্দের সন্ধিগত অর্থ হল সর্ব + উদয়। অর্থাৎ, সকলের
                কল্যাণ বা সকলের উদয়। গান্ধিজির মতে ব্যক্তিগত স্বার্থ অপেক্ষা
                সামাজিক স্বার্থ অধিক গুরুত্বপূর্ণ। ব্যক্তিকে সমাজের সকলের
                কল্যাণের মধ্য দিয়ে নিজের কল্যাণ সাধন করতে হবে, তবেই সমাজ ও সেই
                সঙ্গে ব্যক্তিগত উন্নতি ঘটবে। সর্বোদয়ের তিনটি মূল নীতি রয়েছে —
                <br />
                <br />
                (i) সকলের কল্যাণের মধ্যে নিজের কল্যাণ নিহিত আছে। <br />
                (ii) সকল বৌদ্ধিক ও কায়িক শ্রমের মূল্য সমান। <br />
                (iii) একজন শ্রমজীবি মানুষের জীবনই আদর্শ জীবন। আসলে গান্ধিজি এমন
                এক সর্বোদয় সমাজের কল্পনা করেছেন যেখানে ধনিক শ্রেণির মানুষেরা
                ইচ্ছাকৃতভাবে তাদের অতিরিক্ত সম্পত্তি সমাজের সকলের মধ্যে বণ্টিত
                করবে। তিনি আধুনিক যন্ত্রনির্ভর সভ্যতার পরিবর্তে কৃষি ও কুটির
                শিল্পভিত্তিক সমাজকেই আদর্শ সমাজরূপে গণ্য করেছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b> 4. রামরাজ্য ➺ </b>{" "}
                </font>{" "}
                সত্য, অহিংসা ও সর্বোদয় ধারণার উপর ভিত্তি করে গান্ধিজি এমন এক
                আদর্শ সমাজের ধারণা দিয়েছেন যেখানে বলপ্রয়োগকারী রাষ্ট্রের কোনো
                প্রয়োজন নেই। এই রূপ সমাজ নিয়ন্ত্রিত হবে স্বশাসিত ও ক্ষমতার
                বিকেন্দ্রীকরণ নীতির প্রয়োগের মধ্য দিয়ে। এই রূপ আদর্শ সমাজই হল
                রামরাজ্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b> 5. রাষ্ট্রসম্পর্কিত ধারণা ➺ </b>{" "}
                </font>{" "}
                আধ্যাত্মিক এবং নৈতিক ধারণার আধারে গান্ধিজি তাঁর রাষ্ট্র
                সম্পর্কিত তত্ত্ব গঠন করেন। তাঁর মতে, রাষ্ট্র হিংসা এবং
                বলপ্রয়োগের প্রতীক। সেই রাষ্ট্রই শ্রেষ্ঠ যা সবচেয়ে কম শাসন করে।
                ব্যক্তির জন্যই রাষ্ট্র সৃষ্টি হয়। রাষ্ট্র নিজের লক্ষ্য হতে পারে
                না, এটি ব্যক্তির নৈতিক মূল্যবোধের জন্য অত্যন্ত মৌলিক। গান্ধিজি
                সম্প্রতি রাষ্ট্রকে ভয়ের চোখে দেখেছেন, কারণ রাষ্ট্রের অধিক
                সক্রিয়তা ব্যক্তির নৈতিক মূল্যবোধকে ক্ষতি পৌঁছাতে পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b> 6. স্বরাজ ➺ </b>{" "}
                </font>{" "}
                গান্ধিজি যে রাষ্ট্রহীন গণতন্ত্রের ধারণা দিয়েছেন তা গড়ে উঠবে
                ক্ষমতার বিকেন্দ্রীকরণ নীতি ভিত্তিতে। গ্রামীণ মানুষ নিজেদের
                শাসনকার্য নিজেরাই পরিচালনা করবে। ক্ষমতা কোনো কেন্দ্রীয় স্তরে
                কুক্ষিগত হবে না। ক্ষমতা তৃমমূল স্তরে সমানভাবে বণ্টিত হবে। সাম্য,
                স্বাধীনতা ও সৌভ্রাতৃত্বের মেলবন্ধনেই এই স্বরাজভিত্তিক আদর্শ সমাজ
                গঠিত হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b> 7. অছি সম্পর্কিত ধারণা ➺ </b>{" "}
                </font>{" "}
                অছি সম্পর্কিত ধারণাটি সম্পূর্ণরূপে নৈতিকতার আদর্শে গড়ে উঠেছে।
                জীবনধারণের প্রয়োজনে সমাজের সম্পদ যতটা দরকার ততটাই ভোগ করা উচিত
                এবং বাকিটা সমাজের পশ্চাদপদ শ্রেণির মানুষের জন্য বণ্টিত করা উচিত।
                গান্ধিজি মনে করেন সমাজের সমস্ত সম্পদ অছি ব্যবস্থার অন্তর্গত। এই
                সম্পদ কোনোভাবেই ব্যক্তিগত ও মালিকানায় আসবে না। প্রত্যেক সভ্য
                মানুষ নৈতিক দৃষ্টিকোণ থেকে অছি ব্যবস্থার সংরক্ষণ করবে।
                <br />
                <br />
                <font color="#1f7405">
                  <b> সমালোচনা ➺ </b>{" "}
                </font>{" "}
                গান্ধিজির উপরোক্ত রাজনৈতিক চিন্তাধারা সম্রাট ও সমালোচনার উর্ধ্বে
                নয়। অনেকের মতে, তাঁর রাজনৈতিক চিন্তাধারা — (i) পরস্পর
                স্ববিরোধী, (ii) বর্তমান আধুনিক যন্ত্রনির্ভর সমাজের অনুপযোগী,
                (iii) তাঁর রাষ্ট্রসম্পর্কিত ধারণা অত্যধিক নৈতিকতা ও ধর্মের
                নিয়ন্ত্রণাধীন এবং (iv) রামরাজ্য সম্পর্কিত ধারণা এক অলীক কল্পনা
                মাত্র।
                <br />
                <br />
                উপরোক্ত আলোচনা ও সমালোচনা ব্যক্ত হলেও সর্বশেষ বলা যায় দেশ ও
                আন্তর্জাতিক ক্ষেত্রে গান্ধিবাদ একক ও মৌলিক আদর্শ। তৎকালীন
                ব্রিটিশ পরাধীন ভারতবর্ষে গান্ধিজির জীবনাদর্শ নতুন আশার আলো
                এনেছিল। এমনকি বর্তমান যুগেও গান্ধিজির জীবনাদর্শ আমাদের গভীরভাবে
                আলোড়িত করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. মার্কসের রাষ্ট্র সম্পর্কিত তত্ত্বটি আলোচনা।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                মার্কসবাদ হল এক বিশ্ববিক্ষা। রাষ্ট্রের উৎপত্তি ও প্রকৃতি
                সম্পর্কে এক সাড়া জাগানো অভিজ্ঞতাবাদী দৃষ্টিভঙ্গি হল মার্কসবাদ।
                মার্কস তাঁর কোনো একক গ্রন্থে রাষ্ট্রের উৎপত্তি ও প্রকৃতি
                সম্পর্কে দৃষ্টিভঙ্গি ব্যক্ত করেননি। যেসব গ্রন্থগুলিতে তাঁর
                রাষ্ট্র সম্পর্কিত দৃষ্টিভঙ্গি প্রকাশ পেয়েছে তাদের মধ্যে অন্যতম
                কয়েকটি গ্রন্থ হল—‘দ্য কমিউনিস্ট ম্যানিফেস্টো', লেনিনের ‘দ্য
                স্টেট অ্যান্ড রেভোলিউশন, মার্কসের ‘দাস ক্যাপিটাল', এঙ্গেলসের
                ‘দ্য অরিজিন অফ দ্য ফ্যামিলি, প্রাইভেট প্রোপারটি অ্যান্ড দ্য
                স্টেট’ প্রভৃতি। তবে মার্কস পরবর্তী সময়ে অন্যতম মার্কসবাদী
                তাত্ত্বিকগণ গ্রামসি, আলথুসার, মাও-জে-দং প্রমুখের চিন্তাধারায়
                মার্কসের রাষ্ট্র সম্পর্কিত তত্ত্বটি আরও বিকাশ লাভ করে। মার্কসের
                রাষ্ট্রচিন্তার মূল উপাদানগুলি হল—
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. সমাজরিকাশের এক বিশেষ স্তরে রাষ্ট্রের উদ্ভব : </b>{" "}
                </font>{" "}
                মার্কসের দৃষ্টিকোণে, রাষ্ট্র কোনও চিরস্থায়ী বা শাশ্বত
                প্রতিষ্ঠান নয়। মানুষের আবির্ভাব থেকেই রাষ্ট্রের অস্তিত্ব ছিল
                না। ঐতিহাসিক দৃষ্টিকোণ থেকে আদিম সাম্যবাদী সমাজব্যবস্থা তে
                রাষ্ট্রের কোনও অস্তিত্ব ছিল না। তবে, সমাজে ব্যক্তিগত সম্পত্তির
                প্রকাশের ফলে সমাজে এক নতুন শষ স্তরে রাষ্ট্রের উৎপত্তি ঘটে, যা
                সামাজিক উন্নতির পথে প্রবৃদ্ধি করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. রাষ্ট্র শ্রেণিশোষণের হাতিয়ার : </b>{" "}
                </font>{" "}
                রাষ্ট্র শ্রেণিশাসনের একটি সংস্থা ও শ্রেণি শোষণের যন্ত্র। মার্কস
                ও এঙ্গেলস বলেছেন আজ পর্যন্ত যত সমাজ দেখা গেছে প্রত্যেকেরই ইতিহাস
                শ্রেণিশোষণের ইতিহাস। সমাজে সংখ্যালঘু বিত্তবান প্রভুত্বকারী
                শ্রেণি দরিদ্র সংখ্যাগরিষ্ঠ শ্রেণিকে শোষণ করে রাষ্ট্রযন্ত্রকে
                ব্যবহারের মধ্য দিয়ে। তাই রাষ্ট্র হল বলপ্রয়োগের হাতিয়ার।
                রাষ্ট্র মূলত আইন, পুলিশি সম্রাট ব্যবস্থা, সৈন্যবাহিনী, আদালত
                প্রভৃতিকে কাজে লাগিয়ে এই শ্রেণিশোষণকে বজায় রাখে। বলাবাহুল্য
                ব্যক্তিগত সম্পত্তির উদ্ভবের মধ্য দিয়েই সমাজে শ্রেণিবিভাজন
                ঘটেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. সমাজবিকাশের ধারায় রাষ্ট্রের বিভিন্ন অবস্থান : </b>{" "}
                </font>{" "}
                মার্কসবাদীদের দৃষ্টিকোণে মানবসমাজের ইতিহাস হল শ্রেণি সংগ্রামের
                ইতিহাস। সমাজের বিভিন্ন পর্যায়ে রাষ্ট্রের রূপান্তর ঘটেছে। মার্কস
                ও এঙ্গেলস সমাজ বিকাশের ৫টি পর্যায় সম্পর্কে বলেছেন, যথা—(i) আদিম
                সাম্যবাদী সমাজ, (ii) দাস সমাজ, (iii) সামন্ততান্ত্রিক সমাজ, (iv)
                ধনতান্ত্রিক বা পুঁজিবাদী সমাজ, (v) সমাজতান্ত্রিক সমাজ। <br />
                <br />
                <font color="#1f7405">
                  <b>(i) আদিম সাম্যবাদী সমাজ : </b>{" "}
                </font>{" "}
                অনৈতিহাসিক পর্যায়ে মানবসমাজের বিকাশের প্রথম স্তরটি হল আদিম
                সাম্যবাদী সমাজ। এই সমাজে ব্যক্তিগত সম্পত্তির অস্তিত্ব নেই, তাই
                কোনো শ্রেণি অথবা শ্রেণি শোষণ বোধ করা হয়নি, এবং এই প্রকার সমাজের
                জন্য রাষ্ট্রযন্ত্রের আবশ্যকতা ছিলনা। সমাজের মূল আধারভুত বিন্যাস
                ছিল সাম্যে।
                <br />
                <br />
                <font color="#1f7405">
                  <b>(ii) দাস সমাজ : </b>{" "}
                </font>{" "}
                শ্রেণিশাসিত ও শ্রেণিবিভক্ত সমাজের প্রথম স্তরটি হল দাস সমাজ।
                ব্যক্তিগত সম্পত্তির আবির্ভাবের ফলে প্রথম শ্রেণিবিভক্ত সমাজ রূপে
                দাস সমাজের উদ্ভব ঘটে। এই সমাজে দুটি পরস্পরবিরোধী শ্রেণি হল—দাস
                মালিক ও ক্রীতদাস। ক্রীতদাসরা, দাস মালিকের ব্যক্তিগত সম্পত্তি।
                তাদের মন ও দেহের সম্পূর্ণ অধিকার দাস মালিকের। এখানে সংখ্যালঘু
                দাস মালিকরা, সংখ্যাগরিষ্ঠ দাসদের উপর শোষণ ও শাসন চালায় রাষ্ট্র
                যন্ত্রকে কাজে লাগিয়ে।
                <br />
                <br />
                <font color="#1f7405">
                  <b>(iii) সামন্ততান্ত্রিক সমাজ : </b>{" "}
                </font>{" "}
                এই সমাজ দুটি পরস্পরবিরোধী শ্রেণিতে বিভক্ত—সামন্ত প্রভু ও
                ভূমিদাস। তবে ভূমিদাসদের অবস্থাক্রীতদাসদের তুলনায় উন্নত ছিল।
                ভূমিদাসদের কেবল শ্রমের অধিকারী ছিল সামন্ত প্রভুরা। উৎপাদনের
                উপকরণগুলির অধিকার সামন্তপ্রভুরা ভোগ করত এবং তাদের স্বার্থেই
                রাষ্ট্রযন্ত্র পরিচালিত হত।
                <br />
                <br />
                <font color="#1f7405">
                  <b>(iv) পুঁজিবাদী সমাজ : </b>{" "}
                </font>{" "}
                আধুনিকীকরণ এবং শিল্প বিপ্লবের মধ্য দিয়ে মধ্যযুগীয়
                সামন্ততান্ত্রিক সমাজব্যবস্থার অবসান ঘটে। নতুন পুঁজিবাদী সমাজের
                জন্ম ঘটে। এখানে দুটি পরস্পর বিরোধী শ্রেণি থাকে - বুর্জোয়া এবং
                শ্রমিক। সমাজে উৎপাদনের উপাদানগুলি সমস্ত মালিকানা বুর্জোয়া
                শ্রেণির। শ্রমিকের একমাত্র সম্পত্তি তার শ্রমশক্তি। শ্রমিকের শ্রম
                থেকে তৈরি উদ্বৃত্ত মূল্য আত্মসাৎ করে বুর্জোয়ারা এবং তারা তাদের
                মুনাফার পাহাড় তৈরি করে। অসন্তোষ ঘনীভূত হয়ে বুর্জোয়া বিপ্লবের
                মধ্য দিয়ে সর্বহারা শ্রমিক শ্রেণির পতন ঘটায়।
                <br />
                <br />
                <font color="#1f7405">
                  <b>(v) সমাজতান্ত্রিক সমাজ : </b>{" "}
                </font>{" "}
                এই সমাজে সংখ্যাগরিষ্ঠ সর্বহারা শ্রমিক শ্রেণি রাষ্ট্রযন্ত্র
                কায়েম করে বুর্জোয়া বিপ্লবের মধ্য দিয়ে। ফলে সর্বহারা শ্রেণির
                একনায়কত্ব প্রতিষ্ঠিত হয়। এখানে ব্যক্তিগত মালিকানার অবসান ঘটে
                সমস্ত উৎপাদনের উপকরণগুলির উপর সামাজিক মালিকানা প্রতিষ্ঠিত হয়
                এবং শ্রেণি শোষণের বিলুপ্তি ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b> 4. রাষ্ট্রের অবলুপ্তি ও সাম্যবাদী সমাজ : </b>{" "}
                </font>{" "}
                রাষ্ট্রের আবির্ভাব ঘটেছে শ্রেণি শোষণকে কেন্দ্র করে।
                সমাজতান্ত্রিক সমাজে শ্রেণি শোষণের অবসান ঘটে। ফলে পরবর্তী পর্বে
                শ্রেণিহীন শোষণহীন সাম্যবাদী সমাজের আবির্ভাব ঘটবে। ফলে শ্রেণি
                শোষণকারী রাষ্ট্রযন্ত্রেরও অবলুপ্তি ঘটবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>4. মার্কসবাদের মূল নীতি বা সূত্রগুলো আলোচনা করো।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                কার্ল মার্কসের জীবনাদর্শ ও চিন্তাধারাই মার্কসবাদ নামে পরিচিত।
                পৃথিবীর ইতিহাসে বৈজ্ঞানিক সমাজতন্ত্রবাদের জন্মদাতা হলেন অন্যতম
                দার্শনিক মার্কস। মার্কসের জীবিত থাকাকালীন মৃত্যুর পরে এঙ্গেলস,
                লেনিন, মাও-জে-দং, গ্রামসি, আলথুসার প্রমুখ মার্কসবাদী
                তাত্ত্বিকদের দ্বারা মার্কসবাদ আরও সমৃদ্ধ হয়েছে, মার্কসের
                চিন্তাধারায় জার্মান দর্শন, ব্রিটিশ অর্থশাস্ত্র ও ফরাসি
                সমাজতন্ত্রের প্রভাব বিদ্যমান। লেনিনের মতে মার্কসের দৃষ্টিভঙ্গি ও
                শিক্ষামালাই হল মার্কসবাদ। মার্কসের চিন্তাধারার মূল নীতি বা
                সূত্রগুলো নীচে আলোচিত হল —<br />
                <br />
                <font color="#f626d7">
                  <b>1. দ্বন্দ্বমূলক বস্তুবাদ : </b>{" "}
                </font>{" "}
                কার্ল মার্কস তাঁর দ্বন্দ্বতত্ত্ব উত্থাপনে ফয়েরবাখের দ্বারা
                প্রভাবিত হয়েছিলেন। মার্কসের দ্বন্দ্বমূলক বস্তুবাদ হেগেলের
                ভাববাদী তত্ত্বের পূর্ণ বিপরীত। মার্কসের দৃষ্টিতে পৃথিবী
                বস্তুময়। প্রত্যেকটি বস্তু অন্য একটির উপর নির্ভর করে। এই
                বস্তুময় পৃথিবী চিরস্থায়ী নয়, বরং সর্বদা পরিবর্তনশীল। বস্তুর
                মধ্যে বিভিন্ন ধর্মের বিরোধ বুদ্ধিজীবী, বৈদ্যুতিনি এবং সম্মানী
                প্রতিবাদমূলক বাতাস। মার্কস বলেন, বস্তুর মধ্যে বিপর্যয়ের
                দ্বন্দ্ব পাওয়া যায়, এবং বস্তুর পরিমাণগত পরিবর্তন ছাড়াও গুণগত
                পরিবর্তনটি গুরুত্বপূর্ণ। তত্ত্বটি হেগেলের উপরে ভাবকে দেওয়া
                প্রধান উদ্দেশ্য অনুসরণ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. ঐতিহাসিক বস্তুবাদ : </b>{" "}
                </font>{" "}
                ঐতিহাসিক বস্তুবাদ হল দ্বন্দ্বমূলক বস্তুবাদের নীতির বাস্তব
                প্রয়োগ। কার্ল মার্কসের অভিমত ছিল যে, সমাজে চলমান ইতিহাস
                শ্রেণিসংগ্রামের ইতিহাস। আদিম সাম্যবাদী সমাজ থেকে শুরু করে
                পুঁজিবাদী সমাজ সমস্ত ক্ষেত্রে সংখ্যালঘু মালিক শ্রেণির দ্বারা
                সংখ্যাগরিষ্ঠ শ্রমিকশ্রেণির শোষণের ইতিহাস বর্ণিত হয়েছে। মার্কসের
                দৃষ্টিতে অর্থনীতি সমাজের মূল চালিক শক্তি, এবং সমাজের উপরিকাঠামোর
                উপর নির্ভর করে। উপরিকাঠামো শব্দে মার্কস বোঝাতেন আম্মু ইন,
                রাজনীতি, ধর্ম, সাহিত্য, সংস্কৃতি সহ সব বিষয়। যখন উপরিকাঠামো
                পরিবর্তন হয়, তখন সমাজের অর্থনৈতিক ব্যবস্থা ও বিভিন্ন দিকে
                পরিবর্তন হয়ে থাকে।
                <br />
                <br />
                মানব সমাজের পরিবর্তনের কারণ হিসেবে উৎপাদন ও উৎপাদন সম্পর্কের
                অন্তর্দ্বন্দ্বই প্রধান। আদিম সাম্যবাদী সমাজে ব্যক্তিগত মালিকানা
                না থাকায় কোনো শ্রেণি ছিল না ফলে শোষণও ছিল না এবং শ্রেণি
                শোষণকারী যন্ত্র হিসেবে রাষ্ট্রেরও আবির্ভাব ঘটেনি। তবে পরবর্তী
                সময়ে ব্যক্তিগত মালিকাক্ষ্ম নার উদ্ভবের ফলে প্রথম শ্রেণিবিভক্ত
                সমাজ হিসেবে দাস সমাজের আবির্ভাব ঘটে। আবার সামন্ততান্ত্রিক সমাজে
                দুই প্রকার শ্রেণি-সামন্ত প্রভু ও সামন্ত কৃষক উভয়ের উদ্ভব ঘটে।
                সামন্ততন্ত্রের অবসানের পর পুঁজিবাদী সমাজের অস্তিত্ব লক্ষ্য করা
                যায়। তবে পুঁজিপতিদের দ্বারা শ্রমিক শ্রেণির শোষণের রূপটি ভিন্ন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. উদ্বৃত্ত মূল্যের তত্ত্ব : </b>{" "}
                </font>{" "}
                মার্কস তাঁর উদ্বৃত্ত মূল্যের তত্ত্ব নির্মাণে অ্যাডাম স্মিথ ও
                ডেভিড রিকার্ডোর মূল্যের তত্ত্ব দ্বারা প্রভাবিত হন। শ্রমিকের
                উদ্বৃত্ত শ্রমকে কাজে লাগিয়ে পুঁজিপতিরা পুঁজিবাদী সমাজে তাদের
                মুনাফার পাহাড় গড়ে তোলে। শ্রমিকদের একমাত্র সম্পদ হল তার শ্রম।
                শ্রম বিক্রি করে যে যা মজুরি পায় তা দিয়ে তার জীবনধারণের নিত্য
                প্রয়োজন মেটায় সে। এককথায় উৎপাদিত দ্রব্যের মূল্য থেকে যে বেশি
                পরিমাণ মূল্য শ্রমিককে দেওয়া হয় না, তাই হল উদ্বৃত্ত মূল্য। এই
                উদ্বৃত্ত মূল্য আত্মসাৎ করেই পুঁজিবাদী সমাজে শ্রমিক শোষণ বজায়
                রাখে পুঁজিপতি শ্রেণি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. শ্রেণি সংগ্রাম তত্ত্ব : </b>{" "}
                </font>{" "}
                মার্কসরাষ্ট্রকে ব্যাখ্যা করেছেন শ্রেণি শোষণকারী যন্ত্র হিসেবে।
                আদিম সাম্যবাদী সমাজে ব্যক্তিগত সম্পত্তির অস্তিত্ব না থাকায় কোনো
                শ্রেণির সৃষ্টি হয়নি, ফলত সেখানে শোষণও ছিল না এবং শ্রেণি
                শোষণকারী যন্ত্র হিসেবে রাষ্ট্রেরও অস্থিরতা ছিল না। সমাজ জীবনের
                শুরু থেকে রাষ্ট্র ছিল না। সমাজ বিবর্তনের একটি বিশেষ পর্যায়ে
                রাষ্ট্রের আবির্ভাব ঘটে।
                <br />
                <br />
                কমিউনিস্ট ম্যানিফেস্টোতে লেখা রয়েছে — আজ পর্যন্ত যত সমাজ দেখা
                গেছে প্রত্যেকেরই ইতিহাস শ্রেণি সংগ্রামের ইতিহাস। আদিম সাম্যবাদী
                সমাজের পরবর্তী সময়ে ব্যক্তিগত সম্পত্তির উৎপত্তির মধ্য দিয়ে
                সমাজে দুটি পরস্পর বিপরীত বিরোধী শ্রেণি দাস মালিক ও ক্রীতদাসের
                সৃষ্টি হয়। দাস সমাজের পরবর্তী সমাজব্যবস্থাটি হল সামন্ততান্ত্রিক
                সমাজব্যবস্থা। এখানে সংখ্যাগরিষ্ঠ শ্রেণিটি হল সামন্ত প্রভু এবং
                সংখ্যালঘু শ্রেণিটি হল সামন্ত কৃষক। সমাজ বিবর্তনের পরবর্তী স্তরটি
                হল পুঁজিবাদী সমাজ। এই রূপ সমাজ ব্যবস্থায় সংখ্যাগরিষ্ঠ পুঁজিপতি
                শ্রেণির স্বার্থে সংখ্যালঘু শ্রমিক শ্রেণিকে শোষণের মধ্য দিয়ে
                কার্যকর হয়। এখানে প্রতিটি ক্ষেত্রেই রাষ্ট্র সংখ্যালঘু শ্রেণি
                দ্বারা সংখ্যাগরিষ্ঠ শ্রেণির শোষণের হাতিয়ার হিসাবে কাজ করে।
                পুঁজিবাদে সংখ্যালঘু শ্রমিক শ্রেণি বুর্জোয়া বিপ্লবের মধ্য দিয়ে
                পুঁজিবাদী ব্যবস্থাকে ধ্বংস করে সংখ্যাগরিষ্ঠ সমাজতন্ত্র প্রতিষ্ঠা
                করবে এবং এখানে রাষ্ট্র ব্যবস্থা সংখ্যাগরিষ্ঠ সর্বহারা শ্রেণির
                দ্বারা পরিচালিত হবে। এর পরবর্তী পর্যায়ে প্রতিষ্ঠা হবে সাম্যবাদী
                সমাজ। যেখানে কোনো শ্রেণি থাকবে না। সমাজে সকল সম্পত্তির
                সামাজিকীকরণ ঘটবে। একটি শ্রেণিহীন, শোষণহীন সমাজ প্রতিষ্ঠিত হবে।
                এখানে প্রত্যেকে তার সামর্থ্য অনুযায়ী কাজ করবে এবং প্রয়োজন
                অনুযায়ী ভোগ করবে। <br />
                <br />
                <font color="#f626d7">
                  <b>5. রাষ্ট্র সম্পর্কিত তত্ত্ব : </b>{" "}
                </font>{" "}
                রাষ্ট্র কোনো চিরন্তন বা শাশ্বত প্রতিষ্ঠান নয়, আদিম সাম্যবাদী
                সমাজে রাষ্ট্রের কোনো অস্তিত্ব ছিল না। ব্যক্তিগত সম্পত্তির উদ্ভব
                ও জনসংখ্যার বৃদ্ধি সমাজে শ্রেণির সৃষ্টি করে। এই শ্রেণির স্বার্থ
                পরস্পর বিরোধী। মূলত ব্যক্তিগত সম্পত্তির রক্ষার্থে রাষ্ট্রের
                প্রয়োজনীয়তা দেখা দেয়। এককথায় পশুশক্তির উপর ভিত্তি করে
                রাষ্ট্র নামক সংগঠনের সৃষ্টি হয়েছে। পুঁজিবাদী সমাজব্যবস্থায়
                সংখ্যাগরিষ্ঠ শ্রমিক শ্রেণির শোষণের মধ্য দিয়ে রাষ্ট্র ব্যবস্থা
                বজায় থাকে। বুর্জোয়া শ্রেণি শোষণকে বজায় রাখতে রাষ্ট্রকে
                হাতিয়ার হিসেবে ব্যবহার করে। তবে সাম্যবাদী সমাজব্যবস্থায় শ্রেণি
                থাকবে না, শোষণও থাকবে না ফলে শ্রেণি শোষণকারী যন্ত্র হিসেবে
                রাষ্ট্রও থাকবে না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. বিপ্লব সংক্রান্ত তত্ত্ব : </b>{" "}
                </font>{" "}
                মার্কস স্পষ্ট ভাবে বিপ্লব সম্পর্কে ব্যক্ত করেননি, তবে বিপ্লবের
                তত্ত্ব লেনিনের দ্বারা আবদ্ধ হয়েছে। লেনিনের দৃষ্টিতে বিপ্লব হল
                উপদীপ্ত ও উদ্ধত মানুষের মহান সংঘর্ষ, অধিকার প্রতিষ্ঠান এবং
                উৎপীড়িত ও শোষিতদের মহোৎসব। মার্কসবাদী মতবাদ অনুযায়ী, বিপ্লব হল
                সামাজিক পরিবর্তনের মূল চালিক শক্তি। বিপ্লবের মাধ্যমে পুরাতন
                সমাজের পরিস্থিতি অস্বীকার করে নতুন সমাজের সৃষ্টি হয়। মার্কসের
                মতে সংঘাতমূলক বিপ্লবের মাধ্যমেই সর্বহারা শ্রমিক শ্রেণি বুর্জোয়া
                ব্যবস্থার ধ্বংস সাধন করবে।
                <br />
                <br />
                উপরোক্ত মার্কসীয় সূত্রগুলো আলোচনার মধ্যে এ বিষয়টি স্পষ্ট যে
                শ্রমিক শ্রেণির দুঃখময় জীবনের নিরসনে মার্কসবাদের বিকল্প আর কিছু
                হতে পারে না। মার্কসবাদ হল একটি বিশ্ববিক্ষা এবং সমাজে শোষিত ও
                বঞ্চিত শ্রেণির মানুষের মুক্তির দিশারী।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  5. রাষ্ট্রের প্রকৃতি সম্পর্কে গান্ধিজির ধারণা সমালোচনাসহ
                  আলোচনা করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                গান্ধিজির রাষ্ট্র সম্পর্কিত চিন্তাধারার প্রথম প্রকাশ ঘটে 1908
                খ্রিস্টাব্দে প্রকাশিত তাঁর ‘হিন্দস্বরাজ’ গ্রন্থে। এ ছাড়াও
                হরিজন, ইয়ং ইন্ডিয়া প্রভৃতি গ্রন্থে তাঁর রাজনৈতিক ধ্যান ধারণা
                প্রকাশ পায়। রাষ্ট্র সম্পর্কিত তাঁর চিন্তাধারা মূলত টলস্টয়,
                রাস্কিন, থোরো, ম্যাৎসিনি প্রমুখ চিন্তাবিদদের মতাদর্শ দ্বারা
                সমৃদ্ধ। গান্ধিজির রাষ্ট্রচিন্তার বৈশিষ্ট্যগুলি নীচে আলোচিত হল —
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. রাষ্ট্রহীন ও দলহীন গণতন্ত্র : </b>{" "}
                </font>{" "}
                গান্ধিজির দৃষ্টিতে, প্রত্যেক মানুষ নিজেই নিজের মালিক। তার
                দৃষ্টিতে শাসনব্যবস্থা হতে হবে স্বশাসিত। রাষ্ট্র হল হিংসার প্রতীক
                এবং রাজনৈতিক দলগুলি এই হিংসাকে আরও বৃদ্ধি দেয়। তাই তিনি একটি
                রাষ্ট্রহীন, দলহীন স্বায়ত্ত শাসিত আদর্শ সমাজ ব্যবস্থা ধারণা
                করেন, যাকে তিনি "রামরাজ্য" বলে সূচনা করেন। এই শাসনব্যবস্থা
                ক্ষমতা বিকেন্দ্রীকরণ, শোষণ মুক্ত এবং সমানতা ভিত্তিতে গঠিত হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. সার্বভৌমিকতার ধারণা : </b>{" "}
                </font>{" "}
                গান্ধিজির দৃষ্টিতে, রাষ্ট্র একটি চরম সার্বভৌম ক্ষমতার ধারক নয়।
                রাষ্ট্রের মতো অন্যান্য সংস্থাগুলি সামাজিক উন্নতির উদ্দেশ্যে কাজ
                করে এবং সমাজের বিভিন্ন দিকে বিকাশ সাধন করে। সুতরাং, রাষ্ট্র এবং
                অন্যান্য সংস্থাগুলি সমান অধিকারে সাম আনুগত্যের অধিকারী।
                গান্ধিজির দৃষ্টিতে, জনগণ সমাজের মৌলিক অংশ এবং রাষ্ট্র তাদের
                উন্নতির সাধনে সাহায্য করতে বাধ্য। মানুষ যদি নিজের স্বত্বমূল্য
                সম্পর্কে বুদ্ধিমত হয়, তবে তার স্বাধীনতা এবং উন্নতির দিকে যাওয়া
                হবে, এবং রাষ্ট্রের সহায়কতা প্রয়োজন হবে না। গান্ধিজি এই
                দৃষ্টিকোণে রাষ্ট্রের চেয়ে জনগণকে আরও বেশি প্রাধান্য দেওয়ার মত
                করেছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. রাষ্ট্রের কার্যকলাপ : </b>{" "}
                </font>{" "}
                মানুষের জন্যই সমাজ বিবর্তনের একটি বিশেষ পর্যায়ে রাষ্ট্রের
                আবির্ভাব ঘটেছে। রাষ্ট্র কখনোই নিজে নিজের লক্ষ্য হতে পারে না।
                ব্যক্তির ব্যক্তিত্বের বিকাশ ঘটানোই রাষ্ট্রের মূল কাজ। গান্ধিজির
                মতে, মানুষের দুর্বলতার জন্যই রাষ্ট্রের আবির্ভাব ঘটেছে। আধুনিক
                বলপ্রয়োগকারী রাষ্ট্রের কাজে কোনো পবিত্রতা নেই। তাই রাষ্ট্র যদি
                জনবিরোধী কাজ করে, জনগণের উচিত রাষ্ট্রের বিরুদ্ধে সত্যাগ্রহ ঘোষণা
                করা। তবে তা হবে সম্পূর্ণ অহিংস উপায়ে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. নৈতিকতা : </b>{" "}
                </font>{" "}
                গান্ধিজি ইশ্বরের আইনকে রাষ্ট্রীয় আইনের ঊর্ধ্বে স্থান দিয়েছেন।
                তিনি রাষ্ট্রীয় আইনের নৈতিকতা নিয়ে প্রশ্ন তুলেছেন। যে
                রাষ্ট্রীয় আইন মানুষের নৈতিক মূল্যবোধকে আঘাত করে তাকে অমান্য করা
                নাগরিকদের একটি অধিকার ও পবিত্র কর্তব্যও বটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. পাশ্চাত্য গণতন্ত্র : </b>{" "}
                </font>{" "}
                পাশ্চাত্যের গণতন্ত্রের ধারণাকে গান্ধিজি প্রকৃত গণতন্ত্র বলে মেনে
                নিতে রাজি নন। এরূপ পশ্চিমিকেন্দ্রিক গণতন্ত্রে মূলত সংখ্যা
                গরিষ্ঠের গণতন্ত্র। এজন্য তিনি এরূপ গণতন্ত্রের প্রাতিষ্ঠানিক
                কাঠামোর কঠোর সমালোচনা করে বলেছেন, আমরা যেন সংখ্যাগরিষ্ঠের দাস না
                হয়ে পড়ি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. আধুনিক যন্ত্র সভ্যতার বিরোধিতা : </b>{" "}
                </font>{" "}
                গান্ধিজি নগরায়ণ বা আধুনিক যন্ত্র নির্ভর সভ্যতাকে ভালো চোখে
                দেখেননি। তাঁর আদর্শ সমাজব্যবস্থা হল গ্রামভিত্তিক। তিনি সম্পত্তির
                সামাজিকীকরণে বিশ্বাসী। কৃষি ও কুটির শিল্পভিত্তিক উৎপাদন
                ব্যবস্থাকেই তিনি শ্রেয় বলে মনে করেন। আধুনিক যন্ত্র নির্ভর
                সভ্যতা ও নগরায়ণ মানুষের ভোগের ভ্রান্ত চাহিদাকে নির্দেশ করে। ফলে
                মুনাফা লাভের আশায় এই সভ্যতা উপনিবেশবাদ ও সাম্রাজ্যবাদের জন্ম
                দেয়। যা মিথ্যা ও অহিংসাকে প্রশ্রয় দেয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b> সমালোচনা : </b>{" "}
                </font>{" "}
                রাষ্ট্র সম্পর্কিত গান্ধিজির চিন্তাধারা সমালোচনার উর্ধ্বে নয়।
                নীচে সমালোচনাগুলি উল্লেখ করা হল —<br />
                <br />
                <font color="#1f7405">
                  <b>(i) অবাস্ততত্ত্ব : </b>{" "}
                </font>{" "}
                গান্ধিজি যে আদর্শ সমাজব্যবস্থা রামরাজ্যের কল্পনা করেছেন তা নিছকই
                কল্পনা মাত্র। এই রামরাজ্য গঠনে তিনি নৈতিকতা ও আধ্যাত্মিকতার উপর
                অধিক গুরুত্ব দিয়েছেন, তা বর্তমান সামাজিক পরিস্থিতিতে কোনো ভাবেই
                মেলে না।”
                <br />
                <br />
                <font color="#1f7405">
                  <b>(ii) ব্যক্তিকেন্দ্রিক : </b>{" "}
                </font>{" "}
                গান্ধিজি তাঁর রাষ্ট্র সম্পর্কিত চিন্তাধারায় রাষ্ট্রকে উপেক্ষা
                করে না; তিনি ব্যক্তিকে গুরুত্ব দেন। তবে, তার দৃষ্টিতে ব্যক্তির
                জীবনে রাষ্ট্রের গুরুত্ব বহুভিধ অবশ্যই অন্তর্ভুক্ত থাকে না। তিনি
                বাস্তবতার সঙ্গে যুক্ত হতে এবং ব্যক্তিকে মনে করেন বিশেষ
                গুরুত্বপূর্ণ। ব্যক্তির জীবনে রাষ্ট্রের প্রতি এমন বিবিধ সংশ্লেষণ
                থাকে যা সমাজে নৈতিক গুণাবলি সম্পন্ন করতে সহায়ক হতে পারে এমন
                চিন্তা গান্ধিজি নিয়েছিলেন। <br />
                <br />
                <font color="#1f7405">
                  <b>(iii) স্ববিরোধী চিন্তাভাবনা : </b>{" "}
                </font>{" "}
                রাষ্ট্র সম্পর্কিত গান্ধিজির চিন্তাভাবনা স্ববিরোধী দোষে দুষ্ট।
                তিনি একদিকে রাষ্ট্রহীন ও দলহীন গণতন্ত্রের কথা বলেছেন, আবার
                অপরদিকে বলেছেন রাষ্ট্রের হাতে যেন কম শাসনভার দেওয়া হয়। এদিক
                থেকেই তাঁর স্ববিরোধী দৃষ্টিভঙ্গির প্রকাশ পায়। তিনি এই বিষয়টিও
                উপেক্ষা সম্রাট করেছেন, গণতন্ত্রে রাজনৈতিক দলের অংশগ্রহণ একান্ত
                কাম্য।
                <br />
                <br />
                পরিশেষে একথা বলা যায় রাষ্ট্র সম্পর্কে গান্ধিজির চিন্তাধারার
                গুরুত্ব অপরিসীম। আধুনিক ভারতে পঞ্চায়েতিরাজ ব্যবস্থা তাঁরই
                চিন্তাধারার ফসল। এই ব্যবস্থার মধ্য দিয়েই ভারতের গ্রামীণ
                ক্ষেত্রে গণতন্ত্রকে বাস্তবায়িত করা হয়েছে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

কয়েকটিপ্রধানরাজনৈতিকমতবাদ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(কয়েকটিপ্রধানরাজনৈতিকমতবাদ);
