import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function যুক্তি() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          যুক্তি | West Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="যুক্তি | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                যুক্তি
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  যুক্তিবিজ্ঞানের প্রধান প্রবক্তা কে? অথবা, কাকে তর্কবিদ্যার জনক
                  বলা হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অ্যারিস্টট্লকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জর্জবুলকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কাণ্টকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্লেটোকে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>যুক্তিতে যে বচনের সত্যতা দাবি করা হয়, তাকে বলে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সিদ্ধান্ত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ঘোষকবাক্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অঙ্গবাক্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যুক্তিবাক্য
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বৈধতা বলতে বোঝায় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হেতুবাক্য থেকে সিদ্ধান্তের অনিবার্যভাবে নিঃসৃত হওয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিথ্যাত্ব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সত্যতা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হেতুবাক্য থেকে সিদ্ধান্তের ব্যাপকতা বেশি হওয়া
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> মুক্তির ক্ষেত্রে বৈধতা হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উপাদানগত ধর্
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বাস্তব ধর্ম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আকারগত ধর্ম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বস্তুগত ধর্ম
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  অবরোহ যুক্তিতে হেতুবাক্য ও সিদ্ধান্তের মধ্যে কীরূপ সম্বন্ধ
                  থাকে?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আপতিক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  প্রসক্তি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আকস্মিক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনো সম্বন্ধ থাকে না
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  যে বাক্যের সাহায্যে সিদ্ধান্ত প্রতিষ্ঠা করা হয়, তাকে বলে -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সিদ্ধান্তবাক্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হেতুবাক্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ঘোষকবাক্য
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>যুক্তির বৈধতার নিয়মটি হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বৈধ যুক্তির হেতুবাক্য সত্য হলে সিদ্ধান্ত মিথ্যা হতে পারে না
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বৈধ যুক্তির হেতুবাক্য ও সিদ্ধান্ত উভয়ই সত্য হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বৈধ যুক্তির হেতুবাক্য ও সিদ্ধান্ত উভয়ই মিথ্যা হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বৈধ যুক্তির হেতুবাক্য মিথ্যা হলে সিদ্ধান্ত সত্য হতে পারে না
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  কোন যুক্তির সিদ্ধান্ত দুটি হেতুবাক্য থেকে অনিবার্যভাবে নিঃসৃত
                  হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আরোহ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অমাধ্যম অবরোহ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মাধ্যম অবরোহ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাপেক্ষ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অবৈধ যুক্তির সিদ্ধান্তটি -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  শুধুমাত্র মিথ্যা হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শুধুমাত্র সত্য হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সত্য ও মিথ্যা উভয়ই হতে পারে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সত্য ও মিথ্যা কোনোটিই হবে না
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  যে যুক্তিতে আশ্রয়বাক্য থেকে সিদ্ধান্ত অনিবার্যভাবে নিঃসৃত হয়
                  তাকে বলে —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আরোহ যুক্তি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উপমা যুক্তি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অবরোহ যুক্তি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এর কোনোটিই নয়
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রথম অধ্যায় - যুক্তি</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 1. যুক্তি কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                এককথায় অনুমানের ভাষায় প্রকাশিত রূপকেই যুক্তি বল। অন্যভাবে এক
                বা একাধিক বচনের সমষ্টিকেও যুক্তি বলে। <br />
                যেমন - সকল ধুম যুক্ত স্থান হয় বহ্ণি যুক্ত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. সিদ্ধান্ত কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                আশ্রয় বাক্যের উপর ভিত্তি করে যে বচনটি পাওয়া যায় তাকে
                সিদ্ধান্ত বলে। <br />
                যেমন- উক্ত যুক্তিতে "পর্বত হয় বহ্নিমান"- হলো সিদ্ধান্ত বচন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. অবরোহ যুক্তি কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                অবরোহ কথার অর্থ হল অবরোহন করা অর্থাৎ উপর থেকে নিচে নামা। কিন্তু
                যুক্তি বিজ্ঞানের ভাষায়- "সামান্য থেকে বিশেষের দিকে এগিয়ে
                যাওয়া "। তাই যুক্তি বিজ্ঞানীদের মতে যে যুক্তিতে এক বা একাধিক
                পরস্পর সম্বন্ধযুক্ত আশ্রয় বাক্যের উপর ভিত্তি করে অনিবার্যভাবে
                একটি সিদ্ধান্ত নিঃসৃত হয় এবং যে সিদ্ধান্তটি আশ্রয় বাক্যের কম
                ব্যাপক বা সমব্যাপক হয় তাকেই অবরোহ যুক্তি বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>4. আরোহ যুক্তি কাকে বলে? উদাহরণ দাও।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>আরোহ যুক্তি : </b>
              </font>
              <font color="#3c92e5">
                যে যুক্তিতে সর্বদা দুই বা ততোধিক আশ্রয়ে বাক্যের উপর ভিত্তি করে
                একটি সিদ্ধান্ত নিঃসৃত হয়, তবে অনিবার্যভাবে নয় এবং যে
                সিদ্ধান্তটি আশ্রয় বাক্যের তুলনায় ব্যাপক হয়। তাকে আরোহ যুক্তি
                বলে। আরোহ কথার অর্থ হল আরোহন করা অর্থাৎ নিচে থেকে উপরে ওঠা
                কিন্তু যুক্তি বিজ্ঞানের ভাষায় বিশেষ থেকে সামান্যের দিকে এগিয়ে
                যাওয়া।
                <br /> যেমন - রাম হয় মরণশীল।
                <br />
                <br />
                <font color="#1f7405">
                  <b>আরোহ যুক্তির উদাহরণ :</b>
                </font>
                <br />
                মেসি ফুটবলার এবং জনপ্রিয়
                <br />
                রোনাল্ডো ফুটবলার এবং জনপ্রিয়
                <br />
                সুনীল ফুটবলার এবং জনপ্রিয়
                <br />
                নেইমার ফুটবলার এবং জনপ্রিয়
                <br />
                সকল ফুটবলার হয় জনপ্রিয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. যুক্তির আকার বলতে কী বোঝো?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>যুক্তির আকার : </b>
              </font>
              <font color="#3c92e5">
                যুক্তির আকার বলতে বোঝায় যুক্তির কাঠামো বা গঠন অর্থাৎ যেখানে এক
                বা একাধিক আশ্রয় বাক্য ও একটি সিদ্ধান্ত বচন থাকে। যেমন- সকল রাজা
                হয় সুখী (আশ্রয় বাক্য) রাম হয় রাজা।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. নিরপেক্ষ যুক্তি কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>নিরপেক্ষ যুক্তি : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে অবরোহ যুক্তির হেতুবাক্য ও সিদ্ধান্ত সব অবয়বগুলিই নিরপেক্ষ
                বচন, তাকে নিরপেক্ষ যুক্তি বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. যুক্তির উপাদান বলতে কী বোঝায়?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>যুক্তির উপাদান : </b>
              </font>
              <font color="#3c92e5">
                যুক্তির উপাদান বলতে বোঝায় যুক্তির অন্তর্গত বচন গুলির অর্থ বা
                বিষয়।
                <br />
                উক্ত যুক্তিতে রাজার সঙ্গে সুখী থাকার সম্পর্কের কথা ভেবে রাম কেও
                রাজা ভেবে তার সম্পর্কে সুখী হওয়ার যে অর্থ প্রকাশ করা হলো ।এটাই
                হলো যুক্তির উপাদান।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>8. যুক্তি বিজ্ঞান কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>যুক্তি বিজ্ঞান : </b>
              </font>
              <font color="#3c92e5">
                দর্শন এর যে গুরুত্বপূর্ণ শাখা বৈধ চিন্তার নিয়মাবলী নিয়ে আলোচনা
                করে, অবৈধ যুক্তি থেকে বৈধ যুক্তি কে পৃথক করার নিয়ম পদ্ধতি
                আলোচনা করে, এমনকি অযথার্থ চিন্তা থেকে যথার্থ চিন্তাকে পৃথকীকরণের
                আলোচনা করে তাকে যুক্তিবিজ্ঞান বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>9. ব্যাপক ও সংকীর্ণ অর্থে চিন্তা বলতে কী বোঝায়?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                ব্যাপক অর্থে চিন্তা বলতে বোঝায় চিন্তার কত বেশি মাধ্যম আছে। সেই
                ভাবে সংবেদন , প্রত্যক্ষণ, স্মৃতি, কল্পনা, অবধারণ, অনুমান
                ইত্যাদি। সংকীর্ণ অর্থে চিন্তা বলতে বোঝায় শুধুমাত্র অনুমান।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

যুক্তি.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(যুক্তি);
