import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function মানুষেরজনন() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          মানুষের জনন | West Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="মানুষের জনন | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                মানুষের জনন
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ডিম্বাশয়ের যে অংশ থেকে ইসট্রোজেন ক্ষরিত হয় সেটি হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  গ্রাফিয়ান ফলিকলের মেমব্রেনাস গ্র্যানুলোসা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  করপাস লিউটিয়াম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডিম্বাশয়ের প্রজনন কলাস্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পিটুইটারি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  নিষেক প্রক্রিয়ার সময় ফার্টিলাইজিন ও অ্যান্টিফার্টিলাইজিন-এর
                  বিক্রিয়াকে বলে -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্যাভিটেশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অ্যাগ্লুটিনেশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সক্রিয়করণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্যাপাসিটেশন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সারভিক্স বা জরায়ু কণ্ঠের অবস্থান -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফ্যালোপিয়ান নলে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বুদ্ধে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শুক্রসঞ্জয়ী সংবর্তনালিতে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জরায়ু এবং যোনির মধ্যবর্তী স্থানে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ডিম্বাশয় থেকে ডিম্ব নির্গত হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আদি ডিম্বাণু অবস্থায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  গৌণ পরডিম্বাণু অবস্থায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রাথমিক পরডিম্বাণু অবস্থায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পরিণত ডিম্বাণু অবস্থায়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  একজন প্রজননক্ষম স্বাভাবিক মহিলার রজচক্রের মোটামুটি কতদিনে
                  ডিম্বাণু নিঃসরণ প্রক্রিয়া ঘটে?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রথম দিনে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>8 দিনে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  14 দিনে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  18 দিনে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ডিম্বাণুর ঠিক ওপরে আবৃতকারী মোটা স্তরটি হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মেমব্রেনা গ্র্যানুলোসা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  করোনা রেডিয়েটা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জোনা পেলুসিডা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  থিকা ইনটারনা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>প্রাথমিক পরশুক্রাণু থেকে উৎপন্ন হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  একটি শুক্রকোশ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দুটি শুক্রকোশ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তিনটি শুক্রকোশ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  চারটি শুক্রকোশ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ডিম্বাশয় থেকে নিঃসৃত মূল হরমোন দুটির প্রকৃতি হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পরিবর্তিত অ্যামাইনো অ্যাসিড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রোটিন জাতীয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  স্টেরয়েড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যামাইন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>প্রস্টেট গ্রন্থির কাজ হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বীর্য উৎপন্ন করা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  শুক্রাণুর motality-তে অংশ নেওয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বীর্য সঞ্চিত রাখা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হরমোন ক্ষরণ করা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নীচের কোন্‌টি হওয়ার সময় পোলার বডির উৎপাদন ঘটে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডিম্বাণু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শুক্রাণু উৎপাদন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ডিম্বাণু উৎপাদন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শুক্রাণু
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#1f7405" size="4">
                <b>মানুষের জনন</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. করপাস স্পণ্ডিওসাম পেশি কোথায় দেখা যায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                শিল্পে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    2. ডিম্বাণুর কোশপুঞ্জের মধ্যে যে ফাঁকা স্থান থাকে তাকে কী
                    বলে।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                অ্যানট্রাম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. ABP এর পুরো নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                অ্যানড্রোজেন বাইন্ডিং প্রোটিন (Androgen Binding Protein) |<br />
                <br />
                <font color="#f626d7">
                  <b>
                    4. স্পার্মাটোজেনেসিসের কোন্ দশায় সদৃশ বিভাজন দেখা যায়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                স্পার্মাটিড গঠনের পূর্ণতা প্রাপ্তি দশায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. ঋতুস্রাবের কতদিনের মাথায় ওভিউলেশন ঘটে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                14
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    6. ভাইটেলাইন পর্দা ও জোনা পেলুসিডার মাঝের স্থানকে কী বলে।
                    <br />
                    <br />
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পেরিভাইটেলাইন স্পেস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. ডিম্বাণুর সাইটোপ্লাজমকে কী বলা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                উপাজন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. রজঃচক্রের কোন্ দশায় ইসট্রোজেন ক্ষরণ বৃদ্ধি পায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ডিম্বনিঃস্রাবী দশায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    9. ডিম্বাণুর প্রাচীরে শুক্রাণু কী নিক্ষেপ করে প্রাচীরকে
                    গলিয়ে ফেলে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                হায়ালুরোনিডেজ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. কত বছরের মধ্যে মেনোপজ-এর সূচনা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                40-45 বছর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    11. বয়ঃসন্ধিকালের সূচনা হয় কত বছর থেকে কত বছর পর্যন্ত?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                9-15 বছর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. গ্রাফিয়ান ফলিকল কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ডিম্বাশয়ের স্ট্রোমার বা ধাত্রের মধ্যে যে ফলিকলগুলি ছড়িয়ে
                অবস্থান করে তাদের গ্রাফিয়ান ফলিকল বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. ওভিউলেশন বা ডিম্ব নিঃসরণ কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে প্রক্রিয়ায় পরিণত প্রাবিয়ান ফলিকল বিদীর্ণ হয়ে ডিম্বাণু
                নির্গত হয়, সেই প্রক্রিয়াকে ওভিউলেশন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    14. ভ্রূণ যে তিনটি ভ্রুণ পর্দা পরিবেষ্টিত থাকে তাদের নাম
                    লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                অ্যামনিয়ন, কোরিওন ও অ্যালানটয়েস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    15. পরিণত ভ্রূণ কীসের সাহায্যে জরায়ুর প্রাচীরে সংলগ্ন থাকে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                নাভিরজ্জুর সাহায্যে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. HCG কোথা থেকে ক্ষরিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                HCG প্লাসেন্টা থেকে ক্ষরিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. মেনোপজ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                সধারণত মহিলাদের 40-50 বছর বয়সে রজঃস্রাব নিবৃত অর্থাৎ বন্ধ হয়ে
                যাওয়াকে মেনোপজ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. প্রস্টেট গ্রন্থির যে-কোনো একটি কাজ উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                প্রস্টেট গ্রন্থির ক্ষরণ মুত্রনালিকে পিচ্ছিল করে, ফলে শুরুস্খলন
                সহজ হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. রেকারেন্ট বা পিরিয়ডিক গ্রন্থি কী? উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যেসব গ্রন্থি সাময়িকভাবে সৃষ্টি হয় এবং কিছুদিন পর বিলুপ্ত হয়ে
                যায় তাদের রেকারেন্ট গ্রন্থি বলে। উদা: ডিম্বাশয়ের করপাস
                লিউটিয়াম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. অ্যামনিওটিক গহ্বর কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ভ্রুণ ও কোরিয়নের মাঝের স্থানকে অ্যামনিওটিক গহ্বর বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>21. ফিটাস কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                9 সপ্তাহ থেকে জন্ম হওয়া পর্যন্ত ভ্রূণ যখন পরিণত হয়ে পূর্ণাঙ্গ
                শিশুতে রূপান্তরিত হয় তখন তাকে ফিটাস বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    22. কোন্ হরমোনের প্রভাবে স্তনগ্রন্থিগুলি থেকে দুগ্ধ উৎপাদন
                    শুরু হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                প্রোল্যাকটিন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    23. কোন্ হরমোনের প্রভাবে স্তনগ্রন্থি থেকে দুগ্ধ ক্ষরণ শুরু
                    হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                অক্সিটোসিন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. রজঃস্রাব বা কতদিন হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                28 দিন
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. মেনাকি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                স্ত্রীদের যৌনজীবনচক্রে প্রথম রজঃস্রাব শুরু হওয়াকে মেনার্কি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. লিকার ফলিকল কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                গ্রাফিয়ান ফলিকলের (বর্ধনশীল) গহ্বরস্থিত তরলকে লিকার ফলিকল বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. করোনা রেডিয়েটা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                গৌণ পরডিম্বাণুর চারপাশে যে গ্রানুলোসা কোশসমূহ অবস্থান করে তাকে
                করোনা রেডিয়েটা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. ট্রোফোব্লাস্ট বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ডিম্বনালিতে অবস্থানকালে নিষিক্ত ডিম্বাণুর চারদিকে যে কৌশস্তরের
                সৃষ্টি হয় তাকে ট্রোফোব্লাস্ট বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. নিষেক পর্দা কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                নিষেকের পর ডিম্বাণুর চারপাশে যে পর্দার আবির্ভাব হয় তাকে নিষেক
                পর্দা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. কোন হরমোনের প্রভাবে রজঃস্রাব শুরু হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                প্রোজেস্টেরন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>31. প্রসব নিয়ন্ত্রণকারী দুটি হরমোনের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                অক্সিটোসিন এবং রিলাক্সিন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. উটিড কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                প্রাথমিক উসাইট মিয়োসিস প্রক্রিয়ায় বিভাজিত হয়ে উটিড গঠন করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. কাউপার গ্রন্থির কাজ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                এই গ্রন্থির ক্ষরণ সংগমকালে মুত্রনালিকে পিচ্ছিল করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    34. কোথায় প্রক্সিমাল সেন্ট্রিওল এবং ডিস্টাল সেন্ট্রিওল দেখা
                    যায়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                শুক্রাণুর গ্রীবাতে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. ক্যাপাসিটেশন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                স্তন্যপায়ী প্রাণীদের শুক্রাণুর সক্রিয় হওয়ার পদ্ধতিকে
                শুক্রাণুর ক্যাপাসিটেশন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    36. শুক্রাণুর অ্যাক্রোজোমের পর্দায় অবস্থিত একটি
                    প্রোটিওলাইটিক উৎসেচকের নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                অ্যাক্রোসিন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. শুক্র উৎপাদক নালিকার অপর নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                সেনিনিফেরাস নালিকা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. প্রাক্রজঃস্রাবীয় দশা কতদিন স্থায়ী হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                13-14 দিন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. কটিকাল গ্র্যানিউলসের মধ্যে কী থাকে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                প্রোটিন ও মিউকোপলিস্যাকারাইড।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    40. যৌনমিলনকালে শিশ্ন থেকে যে অস্বচ্ছ সাদা গাঢ় রস নিঃসৃত
                    হয় তাকে কী বলে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                বীর্য বা সিমেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    41. পরিণত ডিম্বাণুর কোন্ অংশে বা স্তরে নির্দিষ্ট শুক্রাণু
                    থাকে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                জোনা পেলুসিডা স্তরে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>42. গ্যাস্টুলার তিনটি স্তরের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                এক্টোডার্ম, মেসোডার্ন এবং এন্ডোডার্ম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>43. লুণ বা এমব্রায়ো কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                গর্ভের সূচনাকাল থেকে দ্বিতীয় মাস পর্যন্ত জরায়ুর মধ্যে অবস্থিত
                ভীরেভ্রূণ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>44. কত সপ্তাহে ভ্ৰূণ সম্পূর্ণভাবে গঠিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                40 সপ্তাহে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>45. অ্যামনিয়ন ও কোরিওনের মাঝের স্থানকে কী বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                এক্সট্রা এমব্রায়োনিক সিলোম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>46. নিষেকের কতদিন পর ব্লাস্টোসিস্ট জরায়ুগাত্রে রোপিত</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                12 দিন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>47. জরায়ুর মধ্যে ভ্রূণ কতদিন ধরে লালিতপালিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                9 মাস 10 দিন (280 দিন)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    48. 40 সপ্তাহের শেষে একজন সুস্থ স্বাভাবিক শিশুর ওজন কত হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                প্রায় 3 কেজি। <br />
                <br />
                <font color="#f626d7">
                  <b>49. ল্যাক্টোজেনিক হরমোনটির নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ল্যাক্টোজেনিক হরমোন হল প্রোল্যাকটিন/লিউটোট্রপিন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>50. মানুষের দুটি মুখ্য যৌনাঙ্গের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                শুক্রাশয় এবং ডিম্বাশয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    51. যদি কোনো কারণে শুক্রাশয় উদরগহ্বর থেকে ছোটানে না নেমে
                    উদরগহ্বরেই থেকে যায়, তবে ওই অবস্থাকে কী বলে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ক্রিপটরকিডিজম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>52. পুরুষদের কয়েকটি আনুষঙ্গিক জননাঙ্গের নাম করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                এপিডিডাইমিস, শিশ্ন, প্রস্টেট, সেমিনাল ভেসিকল, ভাস ডিফারেন্স,
                স্লোটাম ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    53. কোন্ অংশের মাধ্যমে শুক্রাণু এপিডিডাইনিস থেকে নিক্ষেপণ
                    নালিতে আসে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ভাষা ডিফারেনসিয়ার মাধ্যমে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>54. পুরুষদের ইউরেথ্রার বিভিন্ন অংশগুলি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                (i) প্রস্টেটিক ইউরেথ্রা, (ii) মেমব্রেনাস ইউরেখা, (iii) পিনাইল
                ইউরো।
                <br />
                <br />
                <font color="#f626d7">
                  <b>55. ফিনরি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ডিম্বনালির ফানেলাকৃতির মুক্তস্রাত্তে যে ক্ষুদ্র অঙ্গুলাকার
                অংশগুলি থাকে তাকে ফিনরি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    56. লেবিয়া নেজোরা ও লেবিয়া মাইনোরার সংযোগস্থলে যে ক্ষুদ্র
                    ও দণ্ডাকার পেশিময় অংশ থাকে তাকে কী বলে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ক্লাইটোরিস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>57. অ্যারিওলা কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                স্তনগ্রন্থির কেন্দ্রে অবস্থিত স্তনবৃত্তকে বেষ্টন করে যে হালকা
                কালচে রঙের রঞ্জক স্তর থাকে তাকে ম্যারিওলা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>58. রেটি টেস্টিস কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                সেমিনিফেরাস নালিকাগুলি পরস্পর যুক্ত হয়ে ঋজুনালিকা গঠন করে। এই
                ঋজুনালিকাগুলি আবার পুনরায় যুক্ত হয়ে রেটি টেস্টিস বা জালক
                শুক্রাশয় গঠন করে।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#1f7405" size="3">
                <b>প্রশ্নের মান - ২</b>
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>1. প্রস্টেট কী? এর কাজ কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                মুত্রাশয়ের গ্রীবাদেশের গোড়ায় মুত্রনালিকে বেষ্টন করে যে
                আনুষঙ্গিক গ্রন্থি থাকে তাকে প্রস্টেট গ্রন্থি বলে। এই গ্রন্থির
                ক্ষরণ মূত্রনালিকে পিচ্ছিল করে, ফলে শুক্রাণু স্খলন সহজ হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. কাউপার গ্রন্থি কোথায় থাকে? এর কাজ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                প্রস্টেটের নীচে একজোড়া মটরদানার মতো গ্রন্থি থাকে যা
                সূক্ষ্মনালির সাহায্যে মুত্রনালিতে উন্মুক্ত হয়। একে কাউপার
                গ্রন্থি বলে। এই গ্রন্থির ক্ষরণ সংগমকালে মূত্রনালিকে পিচ্ছিল করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. সারটোলি কোশ কোথায় থাকে? এই কোশের কাজ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                আদি শুক্রকোশস্তরে একরকমের বৃহদাকার কোশ ভিত্তিপর্দা থেকে
                কেন্দ্রের দিকে বিস্তৃত থাকে। এদের সারটোলি কোশ বলে। কাজ : সারটোলি
                কোশগুলি শুক্রাণুকে পুষ্টি জোগায়। তাই এদের পোষক কোশ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. জনন শারীরবিদ্যা কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                মুখ্য জননাঙ্গ এবং আনুষঙ্গিক জননাঙ্গের অবস্থান, গঠন ও
                শারীরবৃত্তীয় কার্যসমূহের পঠনপাঠনকে জনন শারীরবিদ্যা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. জনন অঙ্গগুলি কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                এপিডিডাইমিস, শুরুনালি, শুরুথলি বা সেমিনাল ভেসিকল নিক্ষেপণ নালি,
                প্রস্টেট গ্রন্থি, কাউপার গ্রন্থি, নূত্র-জনন নালি ও লিঙ্গ।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. স্ত্রীলোকদের গৌণ জনন অঙ্গগুলি কী কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ফ্যালোপিয়ান নালি, জরায়ু, বার্থোলিন গ্রন্থি, যোনিপথ, যোনিদ্বার,
                হাইমেন ও স্তনগ্রন্থি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    7. রজঃচক্রের নিরাময় দশা বা রিপেয়ারিং দশা কোন্ কোন্ হরমোন
                    দ্বারা নিয়ন্ত্রিত হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ইসট্রোজেন ও FSH দ্বারা নিয়ন্ত্রিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. কোন্ হরমোনের প্রভাবে রজঃস্রাব শুরু হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                প্রোজেস্টেরন হরমোনের প্রভাবে রজঃস্রাব শুরু হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. ঋতুচক্র কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                মানুষ ও মনুষ্যতের প্রাণী ছাড়া অন্যান্য স্ত্রীপ্রাণীর যৌনাঙ্গে
                এক বা একাধিক নিয়মিত চক্রাকার পরিবর্তনগুলিকে ঋতুচক্র বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    10. একঋতুচক্রী ও বহুঋতুচক্রী প্রাণী কাকে বলে? উদাহরণ দাও।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                একঋতুচক্রী প্রাণী : যেসব প্রাণীর বছরে একবার ঋতুচক্র সম্পন্ন হয়
                তাদের একঋতুচক্রী প্রাণী বলে। যেমন — কুকুর।
                <br />
                বহুঋতুচক্রী প্রাণী : যেসব প্রাণীদের বছরে একাধিকবার ঋতুচক্র ঘটে
                তাদের বহুঋতুচক্রী প্রাণী বলে। যেমন – গিনিপিগ, ইঁদুর, বিড়াল,
                ঘোড়া ইত্যাদি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. নিষেক কাকে বলে? নিষেক কোথায় ঘটে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                পুংগ্যামেট বা শুক্রাণু এবং স্ত্রীগ্যামেট বা ডিম্বাণুর মিলনকে
                নিষেক বা গর্ভাধান বলে। নিষেক প্রধানত ফ্যালোপিয়ান নালির
                ঊর্ধ্বাংশে অ্যাম্পুলার মধ্যে সম্পন্ন হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. অ্যাগ্লুটিনেশন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                শুক্রাণু ডিম্বাণুর নিকটস্থ হলে শুক্রাণু-নিঃসৃত
                অ্যান্টিফার্টিলাইজিন এবং ডিম্বাণু-নিঃসৃত ফার্টিলাইজিন-এর
                রাসায়নিক বিক্রিয়ায় শুক্রাণু ডিম্বাণুর প্রাচীর সংলগ্ন হয়। এই
                প্রক্রিয়াকে অ্যাগ্লুটিনেশন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. দেহের বৃদ্ধিতে টেস্টোস্টেরনের প্রভাব কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                টেস্টোস্টেরনের প্রভাবে মাংসপেশির বৃদ্ধি ঘটে, ফলে পুরুষোচিত
                আকৃতির বিকাশ ঘটে। তা ছাড়া টেস্টোস্টেরনের ক্রিয়ায়
                বয়ঃসন্ধিকালে দেহের ক্যালশিয়াম ধারণ ক্ষমতা বৃদ্ধি পাওয়ায়
                অস্থির বুদ্ধি ঘটে ও অস্থি মজবুত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. বার্থোলিন গ্রন্থির অবস্থান ও কাজ লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যোনিছিদ্রের নিকটবর্তী অঞ্চলে ভেস্টিবিউলের মধ্যে বার্থোলিন
                গ্রন্থি অবস্থান করে।
                <br />
                কাজ : এই গ্রন্থি মিউকাস ক্ষরণ করে যোনিপথকে পিচ্ছিল ও সিক্ত রাখে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. করপাস লিউটিয়াম বা পীতগ্রন্থি কী? এর কাজ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পরিণত ডিম্বথলি থেকে ডিম্বাণু নিঃসরণের পর বিদীর্ণ ডিম্বথলি করপাস
                লিউটিয়াম বা পীতগ্রন্থিতে পরিণত হয়।
                <br />
                কাজ : এটি একটি অস্থায়ী অন্তঃক্ষরা গ্রন্থিরূপে কাজ করে এবং
                প্রোজেস্টেরন হরমোন ক্ষরণ করে। এই হরমোন গর্ভাবস্থাকে বজায় রাখতে
                সাহায্য করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  16. স্তনগ্রন্থি কী? স্তনগ্রন্থির বৃদ্ধি, স্তনগ্রন্থিতে দুগ্ধ
                  উৎপাদন এবং স্তনগ্রন্থি থেকে দুগ্ধ নিঃসরণ কোন্ কোন্ হরমোন
                  দ্বারা প্রভাবিত হয়?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                স্তনগ্রন্থি একপ্রকার গৌণ যৌনগ্রন্থি। এই গ্রন্থি পুরুষদের দেহে
                ক্ষুদ্র এবং অপরিণত থাকে। স্ত্রীলোকের দেহে বয়ঃসন্ধিকালে
                ইসট্রোজেন ও প্রোজেস্টেরন হরমোনের প্রভাবে স্তনগ্রন্থি
                বৃদ্ধিপ্রাপ্ত হয়ে প্রমাণ আকার ধারণ করে। সন্তান প্রসবের পর
                প্রোল্যাকটিন হরমোনের প্রভাবে স্তনগ্রন্থিতে দুগ্ধ উৎপাদন শুরু
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. ডিম্বাশয় থেকে প্রধানত কী কী হরমোন ক্ষরিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ডিম্বাশয় থেকে প্রধানত তিন ধরনের হরমোন ক্ষরিত হয়। যথা - (i)
                ইসট্রোজেন, (ii) প্রোজেস্টেরন এবং (iii) রিলাক্সিন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. ভ্যাসেকটোমি ও ভ্যাসোলাইগেশন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                শুক্রনালি অর্থাৎ ভাস ডিফারেন্স ব্যবচ্ছেদ করে কিছু অংশ অপসারিত
                করে স্থায়ী বন্ধ্যাত্বকরণ পদ্ধতিকে ভ্যাসেকটোমি বলে। অপরপক্ষে,
                শুক্রনালিকে বেঁধে দিয়ে অস্থায়ী বন্ধ্যাত্বকরণ পদ্ধতিকে
                ভ্যাসোলাইগেশন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. পুরুষ ও মহিলাদের বহিঃদ্ধ জনন অঙ্গগুলি কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পুরুষদের শিশু ও স্ক্রোটাম এবং মহিলাদের ভালভা, লেবিয়া,
                ক্লাইটোরিস, যোনিছিদ্র ও দুটি স্তনগ্রন্থি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. গৌণ যৌনলক্ষণ কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                বয়ঃসন্ধিকালে (puberty) বিশেষ কয়েকটি হরমোনের প্রভাবে পুরুষ ও
                নারীদেহে যেসব লক্ষণ প্রকাশিত হয় তাদের গৌণ যৌনলক্ষণ বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. পুরুষদের গৌণ যৌনলক্ষণগুলি কী কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                গোঁফ-দাড়ির আবির্ভাব, তলপেটে লিঙ্গের গোড়ায় কেশোদ্‌গম, বগলে ও
                বক্ষদেশে লোমের আবির্ভাব, কণ্ঠস্বরের পরিবর্তন, পেশিবহুল দেহ,
                পুরুষোচিত যৌন মানসিকতার বিকাশ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. নারীদের গৌণ যৌনলক্ষণগুলি কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                স্তনগ্রন্থি ও নিতম্বের বৃদ্ধি, তলপেটে ভালভার চারপাশে, যৌনকেশের
                বৃদ্ধি, রজঃচক্রের সূত্রপাত, কোমল মসৃণ ত্বক, কোমল কণ্ঠস্বর,
                নারীসুলভ মানসিকতার বিকাশ ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. শুক্রাণু কোথায় উৎপন্ন হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                শুক্লাশয়ের সেমিনিফেরাস নালিকায় শুক্রাণু উৎপন্ন হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    24. নিষেক কী? মানুষের নিষেক কোথায় ও কখন হয়? নিষেক হলে কী
                    হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                পুংগ্যামেট ও স্ত্রীগ্যামেটের মিলনকে নিষেক বলে। মানুষের নিষেক
                স্ত্রীদেহে ফ্যালোপিয়ান নালির ঊর্ধ্বাংশে অর্থাৎ অ্যাম্পুলাতে
                সম্পন্ন হয়। নিষেক হলে ডিম্বাণুর সক্রিয়তা বৃদ্ধি পায় এবং
                নিষিক্ত ডিম্বাণুটি ডিপ্লয়েড জাইগোটে রূপান্তরিত হয় এবং নতুন
                জীবনের সূচনা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. লেডিগ-এর আন্তরকোশ কাকে বলে? এর কাজ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                শুক্রাশয়ের সেমিনিফেরাস নালিকাগুলির অন্তর্বর্তী স্থানের যোগকলায়
                যে বহুতলাকার অন্তঃক্ষরা কোশসমষ্টি থাকে তাদের লেডিগ-এর আন্তরকোশ
                বলে। এই কোশগুলি টেস্টোস্টেরন হরমোন নিঃসরণ করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  26. স্পার্মাটোজেনেসিস, স্পার্মিওজেনেসিস ও স্পার্মিয়েশন কাকে
                  বলে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে প্রক্রিয়ায় শুক্রাশয়ে শুক্রাণু মাতৃকোশ থেকে শুক্রাণু উৎপন্ন
                হয় তাকে স্পার্মাটোজেনেসিস বলে। যে প্রক্রিয়ায় গৌণ পরশুক্রাণু
                পরিণত শুক্রাণুতে রূপান্তরিত হয় তাকে স্পার্মিওজেনেসিস বলে।
                অপরপক্ষে, সারটোলির কোশ থেকে শুক্রাণুর অপসারণকে স্পামিয়েশনবলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. অ্যাগ্লুটিনেশন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                শুক্রাণু ডিম্বাণুর কাছে এলে ডিম্বাণু নিঃসৃত ফার্টিলাইজিন এবং
                শুক্রাণু নিঃসৃত অ্যান্টিফার্টিলাইজিন নামক রাসায়নিক পদার্থের
                বিক্রিয়ায় শুক্রাণু ডিম্বাণুর প্রাচীর সংলগ্ন হয়। এই
                প্রক্রিয়াকে অ্যাগ্লুটিনেশন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. নিষেক পর্দা কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                একটি শুক্রাণুর মস্তক ডিম্বাণুর মধ্যে প্রবেশ করার সঙ্গে সঙ্গে
                ডিম্বাণুর চারদিকে একটি সুদৃঢ় ঝিল্লি গঠিত হয়। নিষেকের পর
                ডিম্বাণুর চারপাশে যে পর্দার আবির্ভাব হয় তাকে নিষেক পর্দা বা
                ফার্টিলাইজেশন মেমব্রেন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. স্ত্রীদেহে ভ্ৰূণ কোথায় অবস্থান করে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                আদি ভ্রুণ জরায়ুর এন্ডোমেট্রিয়াম স্তরে অবস্থান করে। পরিণত
                ভ্রুণটি ভ্রুণপর্দায় (অ্যামনিয়ন, কোরিওন ও অ্যালানটয়েস) বেষ্টিত
                অবস্থায় জরায়ুর মধ্যে অবস্থান করে এবং জরায়ুর প্রাচীর থেকে
                নাভিরজ্জু দিয়ে প্রলম্বিত থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. লাইগেশন বা টিউবেকটোমি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                স্ত্রীলোকের উভয় পাশের ফ্যালোপিয়ান নালির কিছু অংশ কেটে বাদ
                দিয়ে বেঁধে দেওয়ার পদ্ধতিকে লাইগেশন বা টিউবেকটোমি বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  31. বীর্য বা সিমেন কী? বীর্যে শুক্রাণুর স্বাভাবিক সংখ্যা কত?{" "}
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                যৌন মিলনকালে শিশ্ন থেকে যে অস্বচ্ছ, সাদা, গাঢ় তরল নিঃসৃত হয়,
                যা শুক্রাণু, প্রস্টেট রস ও সেমিনাল রস নিয়ে গঠিত, তাকে বীর্য বা
                সিমেন বলে। প্রতিবার বীর্যস্খলনে 25-35 কোটি শুক্রাণু নির্গত হয়,
                প্রতি মিলিলিটার বীর্যে শুক্রাণুর সংখ্যা প্রায় 10 কোটি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    32. গ্রাফিয়ান ফলিকল বা ডিম্বথলি কাকে বলে? এটি কত প্রকারের?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ডিম্বাশয়ে প্রজনন কলা থেকে উৎপন্ন হয়ে স্ট্রোমার মধ্যে
                অবস্থানকারী যে ধলির মতো অংশে ডিম্বাণু সৃষ্টি হয় এবং ডিম্বাণু
                অবস্থান করে তাদের গ্রাফিয়ান ফলিকল বা ডিম্বথলি বলে। ডিম্বথলি
                দু-প্রকারের, যথা – (i) আদি ডিম্বথলি এবং (ii) পরিণত ডিম্বথলি বা
                বিদীর্ণ ডিম্বথলি। উল্লেখ্য, আদি ডিম্বথলি ডিম্বাণুবিহীন এবং পরিণত
                ডিম্বথলি ডিম্বাণুযুক্ত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. করপাস লিউটিয়াম কী? এর কাজ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পরিণত ডিম্বথলি থেকে ডিম্বাণু নির্গত হয়ে গেলে ডিম্বথলিটি পীতাভ
                কোশ দিয়ে পূর্ণ হয়ে যে গ্রন্থি সৃষ্টি করে তাকে করপাস লিউটিয়াম
                বলে। করপাস লিউটিয়াম প্রোজেস্টেরন ও রিলাক্সিন হরমোন নিঃসরণ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    34. উজেনেসিস কাকে বলে? কোন্ হরমোন এই প্রক্রিয়াকে নিয়ন্ত্রণ
                    করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে প্রক্রিয়ায় ডিম্বাশয়ের ডিম্বাণু-মাতৃকোশ থেকে ডিম্বাণু
                সৃষ্টি হয় তাকে উজেনেসিস বলে। GTH, FSH এবং LH হরমোন এই
                প্রক্রিয়াকে নিয়ন্ত্রণ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    35. ওভিউলেশন কাকে বলে? কোন্ হরমোনের প্রভাবে এই প্রক্রিয়াটি
                    ঘটে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                পরিণত গ্রাফিয়ান ফলিকল বিদীর্ণ হয়ে ডিম্বাশয় থেকে ডিম্বাণুর বের
                হওয়াকে ডিম্বক নিঃসরণ বা ওভিউলেশন বলে। LH হরমোন এই
                প্রক্রিয়াটিকে নিয়ন্ত্রণ করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>36. গাইনিকোমাসটিয়া কাকে বলে? </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                পুরুষদের বুকের স্তনগ্রন্থির বৃদ্ধিকে গাইনিকোমাসটিয়া বলে।
                ইসট্রোজেন ক্ষরণকারী টিউমারের উপস্থিতির জন্য এই অবস্থার সৃষ্টি
                করে। <br />
                <br />
                <font color="#f626d7">
                  <b>37. প্রোনিউক্লিয়াস কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                নিষেকের পূর্বে ডিম্বাণুর উপ্লাজমে প্রবেশের পর শুক্রাণুর স্ফীত
                মস্তক এবং দ্বিতীয় মেরুডিম্ব নিক্ষেপের পর পরিণত ডিম্বাণু যে দুটি
                নিউক্লিয়দেহে পরিণত হয় তাদের প্রত্যেকটিকে প্রোনিউক্লিয়াস বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. যমজ সন্তান কত রকমের হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যমজ সন্তান (Twins) দু-রকমের হয়, যথা- অভিন্ন যমজ অর্থাৎ একই
                ডিম্বাণুজাত যমজ এবং সহোদর যমজ অর্থাৎ দ্বিডিম্বাণুজাত যমজ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    39. রজঃচক্র বা মাসিক যৌনচক্র কাকে বলে? কী কী অবস্থায়
                    রজঃচক্র বন্ধ থাকে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                নারীদের যৌনজীবনে একটি রজঃস্রাবীয় দশার শুরু থেকে পরবর্তী রজঃ
                স্রাবীয় দশার শুরু পর্যন্ত যৌনাঙ্গসমূহে যে চক্রাকার ও
                পর্যায়ক্রমিক পরিবর্তন সংঘটিত হয় তাকে রজঃচক্র বলে। গর্ভাবস্থায়
                সম্পূর্ণভাবে এবং স্তন্যদানকালে সাময়িকভাবে রজঃচক্র বন্ধ থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>40. ডিম্বনিঃসরণ বা ওভিউলেশন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                শেষ রজঃস্রাবের 13-17 দিনের মধ্যে পরিণত গ্রাফিয়ান ফলিকল বিদীর্ণ
                হয়ে ডিম্বাণু বের হয়। এই প্রক্রিয়াকে ডিম্বনিঃসরণ বা ওভিউলেশন
                বলে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

মানুষেরজনন.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(মানুষেরজনন);
