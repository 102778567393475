/**
 *
 * AboutComponent
 *
 */

import React, { memo } from "react";
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { FormattedMessage } from "react-intl";
import messages from "./messages";

function AboutComponent() {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5">
          <div
            className="col-lg-6 wow fadeInUp"
            data-wow-delay="0.1s"
            // style="min-height: 400px;"
          >
            <div className="position-relative h-100">
              <img
                className="img-fluid position-absolute w-100 h-100"
                src={require("../../img/about.jpg")}
                alt=""
                //style="object-fit: cover;"
              />
            </div>
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
            <h6 className="section-title bg-white text-start text-primary pe-3">
              About Us
            </h6>
            <h1 className="mb-4">Welcome to QList</h1>
            <p className="mb-4">
              We are thrilled to welcome you to our online learning platform,
              where knowledge knows no boundaries and learning is made
              accessible right at your fingertips. Whether you are a seasoned
              learner or embarking on a new educational journey, we are
              delighted to have you join our vibrant community.
            </p>
            {/* <p className="mb-4">
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu
              diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet
              lorem sit clita duo justo magna dolore erat amet
            </p> */}
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2"></i>Skilled
                  Instructors
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2"></i>Online
                  Classes
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  International Certificate
                </p>
              </div>
            </div>
            <a className="btn btn-primary py-3 px-5 mt-2">Read More</a>
          </div>
        </div>
      </div>
    </div>
  );
}

AboutComponent.propTypes = {};

export default memo(AboutComponent);
