import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function MSExcel2007() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          MS Excel-2007 | West Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="MS Excel-2007 | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                MS Excel-2007
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>MS excel কোন্ ধরনের Software?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Database
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Presentation
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Word processing
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Electronics Spreadsheet
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  এক্সেল 2007 এ কোন রিবন ট্যাবে আপনি ফন্ট, ফন্ট সাইজ এবং ফন্ট
                  স্টাইল পরিবর্তন করতে পারেন?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Page Layout
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Home
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Insert
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Formulas
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  কোন ফাংশন ব্যবহার করে আপনি সেলের ডেটা পুনরায় সাজাতে পারেন?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  RESET
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  UNDO
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  FORMAT
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  REORGANIZE
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কোনো Workbook open করার Short Cut Key কোন্‌টি?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ctrl + S
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ctrl + V
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ctrl + O
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ctrl + N
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  এক্সেলে কপি করা বিষয়টি পেস্ট করতে কোন কীবোর্ড শর্টকাট ব্যবহার
                  করা হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Ctrl + C
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Ctrl + X
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Ctrl + P
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Ctrl + V
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  এক্সেলে ফরমুলা লেখার সময়, যদি আপনি একটি ফিল্ডের মধ্যে স্পেস
                  চান, তবে আপনি কোন চিহ্ন ব্যবহার করবেন?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>_
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>+
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>&
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>*
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  Cell এর মধ্যে থাকা ডেটাগুলিকে ছবির আকারে প্রকাশ করার পদ্ধতিকে
                  excel এ কী বলা হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  picture
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  graph
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  chart
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  কোন ফাংশন ব্যবহার করে আপনি একটি সেলের ডেটা বাদ দিতে পারেন?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Delete
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Erase
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Clear
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Remove
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>MS excel এ Total column এর সংখ্যা কয়টি?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  16320
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  16380
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  16382
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  16384
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Ms excel এ Total row এর সংখ্যা কয়টি?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1043674
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1048575
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1048576
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1045764
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b>Chapter ➺ 4</b>
              </font>{" "}
              <br />
              <font color="#2b9a09" size="4">
                <b>MS Excel- 2007</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. MS excel কে Electronic Spreadsheet বলা হয় কেন?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                Spreadsheet হল একটি বিশেষ ধরনের কাগজ যা row এবং column এর
                সমন্বয়ে গঠিত হয়। MS excel একইরকম একটি row এবং column বিশিষ্ট
                document যা electronically উপস্থাপন করা হয় তাই Ms excel কে
                electronic spreadsheet বলা হয়।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. MS excel 2007 এর বৈশিষ্ট্য কী?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>Autofill : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                MS excel এর Autofill এর মাধ্যমে আমরা খুব সহজেই Type না করে কোনো
                series কে insert করতে পারি।
                <br />
                <br />
                <font color="#29900a">
                  <b>Filter : </b>{" "}
                </font>{" "}
                আমরা খুব সহজেই নির্দিষ্ট কোনো Data কে filter করে বিভিন্ন ভাবে
                ব্যবহার করতে পারি।
                <br />
                <br />
                <font color="#29900a">
                  <b>Short : </b>{" "}
                </font>{" "}
                অগোছালো Data কে খুব সহজেই Assending বা Desending Order এ Short
                করা যায়।
                <br />
                <br />
                <font color="#29900a">
                  <b>Chart : </b>{" "}
                </font>{" "}
                MS excel এর খুবই প্রয়োজনীয় একটি বৈশিষ্ট্য হল ‘Chart'। যেটিকে
                কোনো তথ্যকে graphically represent করার জন্য আমরা ব্যবহার করতে
                পারি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. Worksheet কাকে বলে?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                MS excel 2007 এ row column এবং cell এর সমন্বয়ে যে পৃষ্ঠা তৈরি
                হয় তাকে sheet বলে। MS excel 2007 open করার পরে প্রথমে আমরা
                তিনটি Worksheet পাই, প্রয়োজনে আমরা Worksheet সংযোজন ও বিয়োজন
                করতে পারি। MS excel 2007 সর্বোচ্চ 255টি worksheet সংযোজন করা
                যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. Column কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                MS excel worksheet এ vertical সারিগুলি যা A, B, C, D প্রভৃতির
                মাধ্যমে নামকরণ করা হয় তাকে column বলা হয়। MS excel এর মোট
                কলামের সংখ্যা 16384টি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. Row কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                MS excel worksheet এ Horizontal সারিগুলি যা 1, 2, 3 প্রভৃতির
                মাধ্যমে নামকরণ করা হয় তাকে Row বলা হয়। MS excel এ মোট row এর
                সংখ্যা 1048576 টি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. Cell কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                MS excel worksheet এ row এবং column এর সংযোগস্থলে যে আয়তকার
                বক্সের সৃষ্টি হয় তাকে cell বলে। যেহেতু row এবং column দুটির
                সমন্বয়ে cell গঠিত হয়। তাই cell এর নাম row এবং column এর
                সমন্বয়ে গঠিত হয়। MS excel এ মোট cell এর সংখ্যা 1048576 x 16384
                = 17179869184|
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. Cell Address কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                MS excel এ প্রতিটি cell এর নামকরণ করা পদ্ধতিকে Cell Address বলে।
                ‘Row' এবং Column এর নামের সমন্বয়ে Cell Address হয় যদি কোনো
                Cell. A column এবং One row এর সমন্বয় গঠিত হয় তাহলে সেই cell এর
                Address হবে A1।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. Cell range কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                MS excel worksheet এ একাধিক row এবং column কে পরপর Select করার
                পদ্ধতিকে cell range বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. Workbook কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                একাধিক Worksheet এর সমন্বয়ে যে MS excel document তৈরি হয় তাকে
                Workbook বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>10. Worksheet এ cell range select করার পদ্ধতি লেখো।</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                (i) Formula বারে বাঁ দিকে অবস্থিত Main box click করতে হবে।
                <br />
                (ii) Cell range এ প্রথম Cell Address লিখতে হবে Keyboard থেকে ':'
                Press করতে হবে এবং cell range এর শেষ Address লিখতে হবে।
                <br />
                (iii) Enter Key press করতে হবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. MS excel একটি নূতন work book তৈরি করার পদ্ধতি লেখো।</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                (i) Office button এর অন্তর্গত New option click করলে New work
                book-টি dialogue নামে প্রদর্শিত হবে।
                <br />
                (ii) New work book dialouge box থেকে templete option অন্তর্গত
                blank recent option- click create button 4 click করলে একটি blank
                workbook তৈরি হয়ে যাবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>12. MS excel একটি workbook open করার পদ্ধতি লেখো।</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                (i) Office button এর অন্তর্গত New option click করলে New work
                book-টি dialogue নামে প্রদর্শিত হবে।
                <br />
                (ii) Open dialouge box থেকে নির্দিষ্ট excel file কে সংরক্ষণ করার
                location নির্বাচন করতে হবে।
                <br />
                (iii) নির্দিষ্ট work book file এর নাম file name box এ লিখতে হবে
                এবং save button এ click করলে excel file টি Save হয়ে যাবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  13. MS excel worksheet এ নূতন row সংযোজন করার পদ্ধতি লেখো।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                (i) MS excel worksheet এ যে অংশে নূতন row সংযোজন করতে হবে সেই
                row টিকে select করতে হবে।
                <br />
                (ii) Home রিবনের cell গ্রুপের অন্তর্গত insert button এর '∇'
                click করতে হবে।
                <br />
                (iii) প্রদর্শিত list থেকে insert sheet row option এ click করলে
                নতুন row inrert হয়ে যাবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. MS excel এ একটি workbook save করার পদ্ধতি লেখো।</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                (i) Office button এর অন্তর্গত New option click করলে New work
                book-টি dialogue নামে প্রদর্শিত হবে।
                <br />
                (ii) Save as dialouge box থেকে নির্দিষ্ট file টিকে Save করার
                জন্য নির্দিষ্ট জায়গাকে select করতে হবে।
                <br />
                (iii) Save button click করলে file টি Save হয়ে যাবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  15. MS excel worksheet এ কোনো row কে delete করার পদ্ধতি লেখো।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                (i) MS excel worksheet এসে row টিকে delete করতে হবে তাকে select
                করতে হবে।
                <br />
                `(ii) Home রিবনের cell গ্রুপের অন্তর্গত delete button এর click
                করতে হবে।
                <br />
                (iii) প্রদর্শিত list থেকে delete sheet row option টিতে click
                করলে select করা row টি delete হয়ে যাবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    16. MS excel worksheet-এ নতুন column সংযোজন করার পদ্ধতি
                    লেখো।
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                (i) MS excel worksheet এ যে অংশে নূতন column সংযোজন করতে হবে সেই
                column টিকে select করতে হবে।
                <br />
                (ii) Home রিবনের cell গ্রুপের অন্তর্গত insert button এর '∇'
                click করতে হবে।
                <br />
                (iii) প্রদর্শিত list থেকে insert sheet column option এ click
                করলে নতুন column insert হয়ে যাবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  17. MS excel worksheet এ কোনো column কে hide করার পদ্ধতি লেখো।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                (i) MS excel worksheet এসে column টিকে hide করতে হবে তাকে select
                করতে হবে।
                <br />
                (ii) Home রিবনের cell গ্রুপের অন্তর্গত Format button এর '∇'
                click করতে হবে।
                <br />
                (iii) প্রদর্শিত list থেকে Hide and unhide option এর অন্তর্গত
                hide columns option click করলে Select করা column টি Hide হয়ে
                যাবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    18. MS excel worksheet এ কোনো column কে delete করার পদ্ধতি
                    লেখো।
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                (i) MS excel worksheet এ যে column কে delete করার পদ্ধতি লেখো।
                <br />
                (ii) Home রিবনের cell গ্রুপের অন্তর্গত delete button এর '∇' করতে
                হবে।
                <br />
                (iii) প্রদর্শিত list থেকে delete sheet columns option click করলে
                select করা column টি delete হয়ে যাবে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

MSExcel2007.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(MSExcel2007);
