import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function পরিবেশসংক্রান্তবিষয়সমূহ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          পরিবেশ সংক্রান্ত বিষয়সমূহ | West Bengal Class 12 Guide | West Bengal
          HS Suggestion
        </title>
        <meta
          name="description"
          content="পরিবেশ সংক্রান্ত বিষয়সমূহ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                পরিবেশ সংক্রান্ত বিষয়সমূহ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>London Smog-এর জন্য দায়ী গ্যাস কোন্‌টি?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  NH3
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  CO2
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  SO2
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  CO
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সিসা দূষণের ফলে যে রোগ হয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ফানকোনি সিনড্রোম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিনামাটা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্ল্যাক সুই
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হাইপারটেশন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সাইক্লোন গ্রাহক ব্যবহার করা হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শব্দদূষণে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বায়ুদূষণে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জলদূষণে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তেজস্ক্রিয় দূষণে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  DDT-এর সর্বাধিক বায়োম্যাগনিফিকেশন দেখা যায় কোন্ প্রাণীতে?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উচ্চ শ্রেণির উদ্ভিদ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মানুষ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শৈবাল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মাছ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ইউট্রোফিকেশন প্রায়ই দেখা যায় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পাহাড়ে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সমুদ্রে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  স্বাদু জলের হ্রদে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সিসার দূষণের ফলে মানবদেহে যে রোগ হয় তা হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিনামাটা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্ল্যাক লাং
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্ল্যাক ফুট ডিজিজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কিয়োটো প্রোটোকল চালু হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  CoP-5
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  CoP4
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  CoP-3
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  CoP-6
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ইউট্রিফিকেশনের কারণ —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ফসফরাস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কার্বন ও অক্সিজেন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Ca ও Mg
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পারদ ও DDT
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  কার নেতৃত্বে দাসোলী গ্রাম স্বরাজ্য মণ্ডল আন্দোলনের সূচনা হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সুদেশা দেবী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বাচনী দেবী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গোবিন্দ সিং
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  চণ্ডী প্রসাদ ভট্ট
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ইটাই ইটাই রোগের কারণ হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোবাল্ট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ক্যাডমিয়াম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সিসা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আর্সেনিক
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>ষোড়শ অধ্যায় - পরিবেশ সংক্রান্ত বিষয়সমূহ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. ওজোন কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                হালকা নীলাভ, আঁশটে গন্ধযুক্ত এক বিশেষ প্রকারের গ্যাস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. ডিফরেস্টেশন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অরণ্য বা বনাঞ্চল ধ্বংস করাকে ডিফরেস্টেশন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. চিপকো আন্দোলনের সূত্রপাত কবে হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                1970 খ্রিস্টাব্দে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. চিপকো আন্দোলনের নেতা কে ছিলেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সুন্দরলাল বহুগুণা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    5. চিপকো আন্দোলনের কোন্ আন্দোলনকারীকে 'Ramon Magsaysay
                    Award'-এ সম্মানিত করা হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                চণ্ডী প্রসাদ ভট্ট।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. অটোমোবাইল থেকে প্রধান কোন্ দূষক নির্গত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                প্রধান দূষকরূপে কার্বন মনোক্সাইড নির্গত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. বিশ্বের দুটি জলসমৃদ্ধ দেশের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পানামা ও ব্রাজিল দুটি জলসমৃদ্ধ দেশ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. পৃথিবীর দুটি জলনিঃস্ব দেশের নাম উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                কুয়েত ও সৌদি আরব দুটি জলনিঃস্ব দেশ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. ইউট্রোফিকেশনের জন্য প্রধানত কোন্ ধরনের যৌগ দায়ী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                প্রধানত নাইট্রেট ও ফসফেট জাতীয় যৌগ দায়ী, যেগুলি সারে মেশানো
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    10. কবে, কার নেতৃত্বে দাসোলী গ্রামস্বরাজ্য মণ্ডল আন্দোলনের
                    সূত্রপাতহয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                1964 খ্রিস্টাব্দে চণ্ডী প্রসাদ ভট্টের নেতৃত্বে এই আন্দোলনের
                সূত্রপাত হয়। <br />
                <br />
                <font color="#f626d7">
                  <b>11. কোন প্রকার দূষণের গৌণ প্রভাবজনিত ফল হল অম্লবৃষ্টি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বায়ুদূষণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. কবে ওজোন দিবস পালন করা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                16 সেপ্টেম্বর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    13. DDT-র সর্বাধিক বায়োম্যাগনিফিকেশন কোন্ প্রাণীতে দেখা
                    যায়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মানুষে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. কোন প্রকার দূষণের ফলে 'Heat Islands'-সৃষ্টি হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বায়ুদূষণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. ক্যাডমিয়াম দূষণের ফলে কোন্ রোগ হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ইটাই-ইটাই।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. ধানখেতে উৎপন্ন দুযকটি কোন্‌টি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মিথেন (CH4)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. Electrostatic Precipitators কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বাতাসের সূক্ষ্মকণা দূরীভূত করে বাতাস বিশুদ্ধ করার যন্ত্রের নাম
                হল Electrostatic Precipitators
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. SPM কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বায়ুমণ্ডলের সূক্ষ্ম সূক্ষ্ম কঠিন বা তরল কণা (ধূলিকণা, সিলিকা,
                কয়লা, পরাগরেণু ইত্যাদি) বায়ু দূষণের অন্যতম কারণ হিসেবে কাজ
                করলে তাকে SPM বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. গৌণ দূষক কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মুখ্য দূষকের সঙ্গে অন্য পদার্থের বিক্রিয়ায় যে দূষক উৎপন্ন হয়
                তাকে বলে গৌণ দূষক। যেমন— PAN, H2CO3
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. মুখ্য দূষক কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যে সকল দূষক উৎস থেকে সরাসরি পরিবেশে মেশে বা প্রথম সৃষ্ট দূষক হল
                প্রাথমিক দূষক। যেমন – CO2, SO2
                <br />
                <br />
                <font color="#f626d7">
                  <b>21. সাইলেন্ট ভ্যালি আন্দোলন কোথায় গড়ে উঠেছিল?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সাইলেন্ট ভ্যালি আন্দোলন গড়ে উঠেছিল কেরলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. গ্রিন হাউস গ্যাসের উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                CO2, CH4, CFC, N2O
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. GAP কী? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                গঙ্গা নদীর দূষণ নিয়ন্ত্রণ করার জন্য এবং গঙ্গা নদীর স্বাভাবিকতা
                বজায় রাখার যে পরিকল্পনা গ্রহণ করা হয়েছে তাকে GAP বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. BOD কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                জলে বসবাসরত অণুজীবদের O2 এর চাহিদাকে (বিভিন্ন বিক্রিয়া ও জলের
                দূষিত উপাদানের বিয়োজনের জন্য) বলা হয় BOD।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. দূষক পদার্থ কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যেসকল অজৈব ও জৈব পদার্থ পরিবেশকে দূষিত করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. অক্ষয়িষ্ণু দূষক পদার্থ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                প্রাকৃতিক পরিবেশে যেসব বস্তুর ক্রমক্ষয় ঘটে না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. SPM-এর সম্পূর্ণ নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                Suspended Particulate Matter বা বাতাসে ভাসমান কণা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. দুটি জৈব দূষকের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অ্যালডিহাইড ও কিটোন হল দুটি জৈব দূষক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. দুটি জৈব অভঙ্গুর দূষকের নাম উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                DDT ও পলিথিন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. ফসজিন কোন্ শিল্পজাত দূষক?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ফসজিন রাসায়নিক ও রজুক শিল্পজাত দূষক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>31. CFC-এর সম্পূর্ণ নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                CFC-এর সম্পূর্ণ নাম ক্লোরোফ্লুরোকার্বন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. ইউট্রোফিকেশনে আক্রান্ত দুটি লেকের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                তামিলনাড়ুর ওটি লেক এবং চণ্ডীগড়ের শুকনা লেক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. BOD-এর সম্পূর্ণ নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                BOD-এর সম্পূর্ণ নাম Biological Oxygen Demand
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. পরিশোধিত জলের BOD কত হওয়া উচিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পরিশোধিত জলের BOD 1-এর কাছাকাছি হওয়া উচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. চিপকো আন্দোলনের জন্য কে পদ্মবিভূষণ পান?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সুন্দরলাল বহুগুণা চিপকো আন্দোলনের জন্য পদ্মবিভূষণ পান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>36. কোন্ গ্যাস ফুসফুসে ক্যানসার সৃষ্টি করে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সালফার ডাইঅক্সাইড ফুসফুসে ক্যানসার সৃষ্টি করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. গ্রিনহাউস গ্যাসের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মিথেন ও ক্লোরোফ্লুরোকার্বন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. ফ্লাই অ্যাশ প্রধানত কোথা থেকে উৎপন্ন হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ফ্লাই অ্যাশ প্রধানত তাপবিদ্যুৎ কেন্দ্র থেকে উৎপন্ন হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. সাইলেন্ট ভ্যালি কোথায় অবস্থিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                কেরলের পাল্লাকাড জেলায় সাইলেন্ট ভ্যালি অবস্থিত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. বায়ুদূষণ কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                {" "}
                বিভিন্ন প্রাকৃতিক কারণে বা মানযের সৃষ্ট কারণে উদ্ভূত
                অপ্রয়োজনীয় কণা ও গ্যাসীয় পদার্থের ঘনত্ব বায়ুতে স্বাভাবিক
                মাত্রায় থেকে হ্রাস বা বৃদ্ধি পেলে ঐ প্রকার বায়ুকে দূষিত বায়ু
                বলে এবং এই পদ্ধতিকে বায়ুদূষণ বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. জলদূষণ কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                জলের সঙ্গে কোনো অবাঞ্ছিত দ্রব্য মেশার ফলে জলের ভৌত, রাসায়নিক ও
                জৈব বৈশিষ্ট্যসমূহ পরিবর্তিত হয় এবং তার ফলে জলজ প্রাণী, উদ্ভিদ ও
                মানুষের ক্ষতির আশঙ্কা থাকে, তাকে জলদূষণ বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. বায়োম্যাগনিফিকেশন বা জৈব বিবর্ধন কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                {" "}
                অভঙ্গুর নূষকগুলি ক্ষয়প্রাপ্ত হয় না বলে জীবদেহে ধীরে ধীরে এদের
                ঘনত্ব বা পরিমাণ বাড়তে থাকে। বিভিন্ন খাদ্য উপাদানের মধ্য দিয়ে
                প্রাণীদেহে এদের পরিমাণ ক্রমশ বৃদ্ধি পেতে থাকে—এই ঘটনাকেই জৈব
                বিবর্ধন বলে। জলে DDT মিশ্রিত হওয়ার ফলে মাছের দেহে এই দূষক
                সঞ্চিত হয় এবং সেই বিষাক্ত মাছ খেতে থাকলে মানবদেহের মেদকলায়
                প্রচুর পরিমাণে DDT সঞ্চিত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>4. ইউট্রোফিকেশন কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                কৃষিকার্যে ব্যবহৃত রাসায়নিক সার বৃষ্টির মাধ্যমে ধৌত হয়ে
                জলাশয়ে অতিরিক্ত পরিপোষক (Nutrient) সংযোজিত হয়। এইজন্য শৈবাল,
                ব্যাকটেরিয়া ও জলজ উদ্ভিদের সংখ্যা অস্বাভাবিক হারে বৃদ্ধি পায়।
                এই জলজ উদ্ভিদগুলি জলাশয়ের উপরে স্তরীভূত হওয়ায় জলে দ্রাব্য
                অক্সিজেনের পরিমাণ কমে যায় ও এর ফলে জলজ প্রাণীদের মৃত্যু ঘটে।
                পরিশেষে অতিরিক্ত পরিমাণ জলজ উদ্ভিদের মৃত্যুর ফলে জলাশয় বুজে
                যেতে পারে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. অ্যাগ্রোকেমিক্যালস কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                কৃষিক্ষেত্রে উদ্ভিদের বৃদ্ধি, ফসলের বৃদ্ধি, পেস্ট নাস করা
                ইত্যাদি কাজের জন্য যে সকল রাসায়নিক উপাদান ব্যবহৃত হয় তাদের বলে
                অ্যাগ্রোকেমিক্যালস। যেমন – DDT, BHC, NPK ইত্যাদি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. অ্যালগাল ব্লুম কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                জলে অত্যধিক পরিমাণ শৈবালের (বিশেষত নীলাভ শৈবাল) বুঝিতে অ্যালগাল
                ব্লুম বলে। এর ফলে জল নীলচে বা সবুজ বর্ণ ধারণ করে ও দুর্গন্ধযুক্ত
                হয়। নীলাভ সবুজ শৈবাল মাইক্রোসিস্টিন নামক বিষাক্ত পদার্থ উৎপন্ন
                করায় অ্যালগাল রুমের ফলে জলজ প্রাণীরা মারা যায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. শিল্পবিপ্লব কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                কায়িক শ্রমের পরিবর্তে যন্ত্রের মাধ্যমে পণ্য উৎপাদনের পদ্ধতি
                অবলম্বন করার জন্য পরিবেশ, অর্থনীতি প্রভৃতি ক্ষেত্রে যে আমূল
                পরিবর্তন হয়েছে তাকে শিল্পবিপ্লব বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>8. সূক্ষ্মকণা দূষকের প্রভাব লেখ।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                (i) মানুষের শ্বাসতন্ত্র ক্ষতিগ্রস্ত হয়।
                <br /> (ii) পাতার পত্ররন্ধ্র বন্ধ হয়ে গেলে বাষ্পমোচন ও
                সালোকসংশ্লেষ হ্রাস পায়। <br />
                (iii) সূক্ষ্মকণা দুষকের ফলে কয়লা খনির শ্রমিকের ব্ল্যাক ল্যাং,
                বস্ত্রশিল্পের শ্রমিকের হোয়াইট ল্যাং রোগ সৃষ্টি হয়।
                <br /> (iv) বিল্ডিং, স্মৃতিসৌধের ব্যাপক ক্ষতিসাধন হয়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

পরিবেশসংক্রান্তবিষয়সমূহ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(পরিবেশসংক্রান্তবিষয়সমূহ);
