import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function ভারতেরআইনবিভাগ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          ভারতের আইন বিভাগ | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="ভারতের আইন বিভাগ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                ভারতের আইন বিভাগ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>রাজ্যসভা অর্থবিলকে আটকে রাখতে পারে —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  10 দিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  14 দিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  20 দিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  15 দিন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>‘জিরো আওয়ার' বলতে বোঝানো হয়, যে সময়কে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দুপুর ১২টা - দুপুর ২টো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  দুপুর ১২টা - দুপুর ১টা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দুপুর ২টা- দুপুর ৩টে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সকাল ১০টা- সকাল ১১টা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  “অধ্যক্ষ সমগ্র কক্ষের মর্যাদা ও স্বাধীনতার প্রতিভূ” বলেছেন -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাভারকর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বল্লভভাই প্যাটেল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইন্দিরা গান্ধি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জওহরলাল নেহরু
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>রাষ্ট্রপতির নির্বাচন ও পদচ্যুতির ব্যাপারে —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লোকসভার ক্ষমতা বেশি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাজ্যসভার ক্ষমতা বেশি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  লোকসভা ও রাজ্যসভা সম ক্ষমতাসম্পন্ন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এর কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>শুধুমাত্র লোকসভা যে বিল উত্থাপন করতে পারে, সেটি হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আইন-সংক্রান্ত বিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভূমি বিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাধারণ বিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অর্থ বিল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>রাষ্ট্রপতির ইমপিচমেন্ট প্রক্রিয়া সম্পাদিত হয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কেবল লোকসভায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কেবল বিধানসভায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পার্লামেন্টের উভয় কক্ষে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কেবল রাজ্যসভায়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতে লোকসভার অধ্যক্ষকে নির্বাচন করেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  লোকসভার সদস্যগণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাজ্যসভার সদস্যগণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উপ-রাষ্ট্রপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাষ্ট্রপতি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>পশ্চিমবঙ্গের বিধানসভার বর্তমান সদস্য সংখ্যা —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  290 জন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  295 জন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  300 জন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  305 জন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>রাজ্য মন্ত্রীসভার বৈঠকে সভাপতিত্ব করেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রধানমন্ত্রী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মুখ্যমন্ত্রী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্পিকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উপরাষ্ট্রপতি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অধ্যক্ষের অনুপস্থিতিতে লোকসভায় অধিবেশন পরিচালনা করেন -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রধানমন্ত্রী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রধান বিচারপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ডেপুটি স্পিকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাষ্ট্রপতি
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="3">
                <b>অষ্টম অধ্যায় - ভারতের আইন বিভাগ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. লোকসভার স্পিকারের ক্ষমতা ও কার্যাবলি এবং পদমর্যাদা আলোচনা
                  করো।
                  <br />
                  অথবা,
                  <br />
                  ভারতের সংসদীয় ব্যবস্থায় লোকসভার অধ্যক্ষের ভূমিকা বিশ্লেষণ
                  করো।
                  <br />
                  অথবা,
                  <br /> লোকসভার অধ্যক্ষের ক্ষমতা ও কার্যাবলী আলোচনা করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ভূমিকা : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                {" "}
                ভারতের সংসদীয় শাসন ব্যবস্থায় স্পিকারের একটি গুরুত্বপূর্ণ
                ভূমিকা রয়েছে। জওহরলাল নেহরুর মতে, লোকসভার স্বাধীনতা ও সম্ভ্রমের
                মূর্ত প্রতীক হলেন স্পিকার লোকসভা যেহেতু সমগ্র জাতির প্রতিনিধিত্ব
                করে, তাই লোকসভার সভাপতি হিসেবে স্পিকার সমগ্র জাতির স্বাধীনতার
                প্রতীক হিসেবে কাজ করেন। সংবিধান অনুসারে স্পিকারকে অবশ্যই লোকসভার
                সদস্য হতে হয়। লোকসভা নির্বাচনের পর নবগঠিত সভার সদস্যরা নিজেদের
                মধ্যে থেকে একজনকে স্পিকার নির্বাচন করেন (৯৩ নং ধারা)। সাধারণত
                লোকসভায় সংখ্যাগরিষ্ঠ দলের মনোনীত প্রার্থী স্পিকার হিসেবে
                নির্বাচিত হয়ে থাকেন৷
                <br />
                <br />
                স্পিকারের কার্যকাল সম্পর্কে সংবিধানে কিছু বলা না হলেও লোকসভার
                মেয়াদ অর্থাৎ পাঁচ বছর পর্যন্ত তিনি স্বপদে অধিষ্ঠিত থাকেন। অবশ্য
                লোকসভার সদস্যদের স্পিকারকে অপসারণ করার ক্ষমতা দেওয়া হয়েছে।
                ভারতের সংবিধানে স্পিকার এক বিশেষ মর্যাদা ও ক্ষমতার অধিকারী। তাঁর
                পদমর্যাদা সুপ্রিম কোর্টের প্রধান বিচারপতির সমতুল্য বলে অনেকে মনে
                করেন। স্পিকারের ক্ষমতার উৎস হল ভারতের সংবিধান এবং লোকসভার কার্য
                পরিচালনা-সংক্রান্ত বিধি কিছু অলিখিত ক্ষমতাও তাঁর রয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b> স্পিকারের ক্ষমতা ও কার্যাবলি : </b>{" "}
                </font>{" "}
                সংবিধান অনুসারে লোকসভার স্পিকারের ক্ষমতা ও কার্যাবলি নিম্নরূপ —
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. সভা পরিচালনা ও নিয়ন্ত্রণ সংক্রান্ত ক্ষমতা : </b>{" "}
                </font>{" "}
                লোকসভায় কোন প্রস্তাবনা উত্থাপিত হবে, কোন নোটিশ আলোচনার জন্য
                গ্রহণ করা হবে, কোন প্রশ্ন জিজ্ঞাসা হবে সেক্ষেত্রে অধ্যক্ষের
                সিদ্ধান্ত নিবে চূড়ান্ত। এই সিদ্ধান্তগুলি আদালতের বিচারযোগ্য
                বিষয় নয়। সভার কেবল পরিচালনা নয়, সভার শান্তি শৃঙ্খলা বজায়
                রাখা ও তাঁর দায়িত্ব। কোন সদস্য কখন এবং কতক্ষণ সময় বক্তব্য
                রাখবেন, তা তিনি নির্ধারণ করেন। শৃঙ্খলাভঙ্গের অভিযোগে তিনি কোনো
                সদস্যকে সংযত হওয়ার নির্দেশ দিতে পারেন, আবার কোনো সদস্যকে
                সাময়িকভাবে সভা থেকে বহিষ্কার করতে পারেন। সভার শান্তি
                সাময়িকভাবে নিয়ন্ত্রণের বাইরে গেলে, তিনি সময়িকভাবে সভা মুলতুবি
                ঘোষণা করতে পারেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. অর্থ বিল বিষয়ক ক্ষমতা : </b>{" "}
                </font>{" "}
                অর্থবিল নিয়ে কোনো বিরোধ দেখা দিলে স্পিকার তার চূড়ান্ত
                নিষ্পত্তি করে থাকেন৷ লোকসভায় অনুমোদনের পর অর্থবিলটিকে যখন
                রাজ্যসভায় পাঠানো হয়, তখন বিলটি যে একটি অর্থ বিল সে সম্পর্কে
                স্পিকার একটি প্রমাণপত্র দিয়ে থাকেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. লোকসভার সদস্যদের অধিকার রক্ষা : </b>{" "}
                </font>{" "}
                অধিকার ভঙ্গ বা লোকসভা অবমাননার দায়ে তিনি যে-কোনো ব্যক্তি বা
                সাংসদের বিরুদ্ধে উপযুক্ত ব্যবস্থা গ্রহণ করতে পারেন। লোকসভা
                অবমাননার জন্য সংশ্লিষ্ট ব্যক্তি বা সাংসদকে গ্রেফতারের নির্দেশ
                দেওয়ার ক্ষমতাও স্পিকারের রয়েছে। অবশ্য লোকসভা অধিবেশনের
                সমাপ্তির পর সংশ্লিষ্ট আটক ব্যক্তিকে মুক্তি দেওয়ার সংস্থান আছে।
                তা ছাড়া স্পিকারের অনুমতি ছাড়া লোকসভার কোনো সদস্যকে
                পার্লামেন্টের ভেতরে গ্রেফতার করা যায় না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. সংসদীয় কমিটির প্রধান হিসেবে ভূমিকা : </b>{" "}
                </font>{" "}
                সংসদীয় কমিটিগুলির প্রধান হলেন স্পিকার। তিনি বিভিন্ন সংসদীয়
                কমিটির সভাপতিদের নিয়োগ করেন। এই কমিটিগুলি স্পিকারের
                নিয়ন্ত্রণাধীনে দায়িত্ব পালন করে। গুরুত্বপূর্ণ কমিটিগুলির সভায়
                স্পিকার সভাপতিত্ব করেন। এর মধ্যে রয়েছে বিধি- সম্পর্কিত কমিটি
                (Rules Committee), সভার কর্মপদ্ধতি পরিচালনা- সংক্রান্ত উপদেষ্টা
                কমিটি (Business Advisory Committee) প্রভৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. কাস্টিং বা নিৰ্ণায়ক ভোট দান : </b>{" "}
                </font>{" "}
                স্পিকার লোকসভার তর্ক-বিতর্ক বা আলাপ আলোচনায় পক্ষে বা বিপক্ষে
                কোনো ক্ষেত্রেই অংশ নেন না। তবে কোনো বিষয়ে পক্ষে ও বিপক্ষে
                সমসংখ্যক ভোট পড়লে সেই অচলাবস্থা কাটানোর জন্য তিনি নির্ণায়ক বা
                কাস্টিং ভোট দিয়ে থাকেন। <br />
                <br />
                <font color="#f626d7">
                  <b>6. পদত্যাগ পত্রের যথার্থতা বিচার : </b>{" "}
                </font>{" "}
                কোনো লোকসভার সদস্য পদত্যাগ করতে চাইলে পদত্যাগ পত্রটি স্পিকারের
                নিকট জমা দিতে হয়। তবে এক্ষেত্রে স্পিকার যদি মনে করেন সদস্যটির
                পদত্যাগ ইচ্ছাকৃত নয় বরং বলপূর্বক, তখন তিনি পদত্যাগপত্রটি জমা
                নিতে অস্বীকার করতে পারেন। <br />
                <br />
                এ ছাড়াও স্পিকার পার্লামেন্টের যৌথ অধিবেশনে সভাপতিত্ব করেন,
                দলত্যাগ বিরোধী আইন অমান্য করলে অভিযুক্ত সদস্যের সদস্যপদ বাতিল
                করতে পারেন, সংসদীয় কার্যবিবরণী সংরক্ষণের দায়িত্বে থাকেন,
                সাধারণ দর্শক ও সাংবাদিকদের জন্য বসার ব্যবস্থাও করে থাকেন ইত্যাদি
                প্রভূত ক্ষমতা ও কার্যাবলি ভোগ করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b> স্পিকারের পদমর্যাদা : </b>{" "}
                </font>{" "}
                ভারতের আইনসভার নিম্নকক্ষ লোকসভা যেহেতু জনপ্রতিনিধি কক্ষ, তাই সেই
                কক্ষের সভাপতি হিসেবে লোকসভার স্পিকার সমগ্র জাতির স্বাধীনতার
                প্রতীক হিসেবেই কাজ করেন। ভারতের সংবিধান রচয়িতাগণ স্পিকারের
                পদটিকে স্বাধীন ও নিরপেক্ষ গড়ে তোলার জন্যই সচেষ্ট ছিলেন। জাতীয়
                স্বার্থে ক্ষমতাসীন দলের পাশাপাশি বিরোধী দলেরও ভূমিকা যথেষ্ট
                গুরুত্বপূর্ণ সংসদীয় শাসনব্যবস্থায়। তাই লোকসভার স্পিকারের
                স্বাধীন ও নিরপেক্ষ দৃষ্টিভঙ্গি বিরোধী দলের কাছে তাঁকে গ্রহণযোগ্য
                করে তোলে। স্পিকারই লোকসভার সম্মান ও স্বাধীনতা রক্ষা করে। তবে
                বলাবাহুল্য স্পিকার যেহেতু লোকসভার সংখ্যাগরিষ্ঠ দল বা জোটের
                সমর্থনেই নির্বাচিত হন, তবে স্পিকারের নিরপেক্ষ দৃষ্টিভঙ্গির
                ঐতিহ্য ভারতীয় সংসদীয় শাসন ব্যবস্থায় তেমনভাবে গড়ে ওঠেনি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  2. ভারতের পার্লামেন্টে আইন পাসের পদ্ধতি আলোচনা করো।
                  <br />
                  অথবা,
                  <br /> ভারতের সংসদে সাধারণ বিল পাসের পদ্ধতি আলোচনা করো।
                  <br />
                  অথবা,
                  <br /> ভারতীয় পার্লামেন্টে আইন প্রণয়ন পদ্ধতি আলোচনা করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> সূচনা : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                পৃথিবীর প্রায় প্রতিটি রাষ্ট্রের আইন বিভাগের প্রধান কাজ হলো আইন
                প্রণয়ন করা। ভারতবর্ষের কেন্দ্রীয় আইনসভাও এই নিয়মের ব্যতিক্রম
                নয়। ভারতের আইনসভার প্রধান কাজ হলো সময়ের সাথে সামঞ্জস্যপূর্ণ
                নতুন আইন প্রণয়ন করা এবং পুরনো আইন বাতিল বা সংশোধন করা। ভারতীয়
                সংবিধানের অনুসারে কেন্দ্রীয় তালিকাভুক্ত বিষয়ে আইন প্রণয়নের
                দায়িত্ব রাষ্ট্রপতি সহ পার্লামেন্টের বা কেন্দ্রীয় আইনসভার।
                আইনের প্রস্তাবটি পার্লামেন্টের উভয় কক্ষ কর্তৃক গৃহীত হওয়ার পর
                তা রাষ্ট্রপতির সম্মতির জন্য প্রেরিত হয়। রাষ্ট্রপতির সম্মতি
                পেলেই ঐ বিলটি আইনে পর্যবসিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>{" "}
                </font>
                <font color="#1f7405">
                  <b> আইন পাশের পদ্ধতি : </b>{" "}
                </font>{" "}
                ভারতীয় সংসদে দু’ধরনের বিল পাশ হয় — 1. সরকারি বিল এবং 2.
                বেসরকারি বিল। সরকারি বিল আবার দু’ভাগে বিভক্ত — (ক) সাধারণ বিল ও
                (খ) অর্থবিল। <br />
                <br />
                ভারতীয় সংবিধান অনুসারে তা কেন্দ্রীয় আইনসভায় যেকোনো সাধারণ
                বিলকে আইনে পরিণত করতে গেলে 7 টি পর্যায় অতিক্রম করতে হয়। <br />
                <br />
                <font color="#f626d7">
                  <b> 1. প্রথম পর্যায় : </b>{" "}
                </font>{" "}
                বিলের প্রথম পর্যায়ে রয়েছে বিল উত্থাপন ও বিলের প্রথম পাঠ| বিলটি
                যদি লোকসভায় উত্থাপিত হয় তাহলে স্পিকারের কাছে এবং রাজ্যসভায়
                উত্থাপিত হলে চেয়ারম্যানের কাছে অনুমতি নিয়ে উত্থাপককে সভায় বিল
                পেশ করতে হয়। এই পর্যায়ে শুধুমাত্র বিলের 'Title' বা শিরোনাম পাঠ
                করা হয়। কোনো আলোচনা বা বিতর্ক এই পর্যায়ে হয় না। তবে উত্থাপক
                প্রয়োজনবোধে বিলটির উদ্দেশ্য ও প্রকৃতি ব্যাখ্যা করতে পারেন।
                নিয়মানুসারে উত্থাপিত হওয়ার পর বিলটি সরকারি গেজেটে প্রকাশিত
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. দ্বিতীয় পর্যায় : </b>{" "}
                </font>{" "}
                এই পর্যায়ে মূল উত্থাপক বিলটির ভবিষ্যৎ সম্পর্কে যেকোনো একটি
                প্রস্তাব আইনসভার সংশ্লিষ্ট কক্ষের সামনে উপস্থাপন করতে পারেন –
                <br />
                <br />
                1. বিলটি এখনই বিবেচনার জন্য গ্রহণ করা হোক,
                <br />
                2. বিলটিকে সিলেক্ট কমিটিতে পাঠানো হোক,
                <br />
                3. বিলটিকে সংসদের একটি যুক্ত কমিটিতে পাঠানো হোক,
                <br />
                4. বিলটিকে জনসাধারণের মতামতের জন্য গণভোটে প্রদান করা হোক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. তৃতীয় পর্যায় : </b>{" "}
                </font>{" "}
                বিলের তৃতীয় পর্যায়ে বিলটি নিয়ে সামগ্রিক বিচার বিবেচনা করা
                হয়। বিলের এই পর্যায়কে কমিটি পর্যায় বলে। কোনো বিল সিলেক্ট
                কমিটির কাছে পাঠানো হলেসংশ্লিষ্ট বিল নিয়ে কমিটি ব্যাপক আলোচনা
                করতে পারে, কিন্তু বিলের নীতি বা উদ্দেশ্য পরিবর্তনের ক্ষমতা
                কমিটির নেই। অবশ্য বিলের কোনো অংশের সংশোধনের জন্য কমিটি তার
                প্রস্তাব রাখতে পারে। কমিটি প্রয়োজনবোধে বিলের সঙ্গে জড়িত
                ব্যক্তিবর্গ, প্রতিষ্ঠান ইত্যাদির বক্তব্য শুনতে পারে অথবা কোনো
                সাক্ষ্য গ্রহণ করতে পারে বা বিশেষ কারণে সরকারকে সংশ্লিষ্ট দলিল ও
                তথ্য পেশ করতেও বলতে পারে। কমিটি সবশেষে বিলটির সম্পর্কে তার
                সুচিন্তিত মতামত সংসদে উপস্থাপন করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. চতুর্থ পর্যায় : </b>{" "}
                </font>{" "}
                কমিটি দ্বারা তৈরি করা রিপোর্ট আলোচনার জন্য সিদ্ধান্ত গ্রহণের
                মাধ্যমে বিলটির চতুর্থ পর্যায়ের সূচনা হয়। কারণ বিলের বিভিন্ন
                দিক নিয়ে বিচারবিশ্লেষণ করার পর কমিটি তার রিপোর্ট তৈরি করে এবং
                কমিটির চেয়ারম্যান বিলটি প্রথম যে কক্ষে উত্থাপিত হয়েছিল, সেই
                কক্ষে প্রেরণ করে। প্রসঙ্গত, খুব কমসংখ্যক বিলকে সিলেক্ট কমিটি,
                যুগ্ম কমিটি বা জনগণের মতামতের জন্য প্রেরণ করা হয়। তবে,
                সংশ্লিষ্ট কক্ষে বিলটি উত্থাপনের পর বিলটি যদি সংখ্যাগরিষ্ঠের
                সমর্থন না পায় তা হলে বিলটি আইনে পরিণত হতে পারে না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. পঞ্চম পর্যায় : </b>{" "}
                </font>{" "}
                এই পর্যায়ে প্রস্তাবক সংশ্লিষ্ট কক্ষে বিলটি গ্রহণের উদ্দেশ্যে
                প্রস্তাব উত্থাপন করেন। এই পর্যায়ে বিলের কোনো ধারা বা উপধারা বা
                কোনো সংশোধনী প্রস্তাব উত্থাপন করা যায় না। তবে শব্দগত সংশোধনের
                প্রস্তাব উত্থাপন করা যায়। সাধারণত এই পর্যায়ে বিলের কোনো আলোচনা
                বা সংশোধন করা যায় না। তবে বিলটি গৃহীত হবে না বর্জন করা হয়ে সে
                সম্পর্কে এই পর্যায়ে সিদ্ধান্ত গ্রহণ করা হয়। কেবলমাত্র
                সংখ্যাগরিষ্ঠের সম্মতির মাধ্যমে এই পর্যায়ে বিলটি গৃহীত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. ষষ্ঠ পর্যায় : </b>{" "}
                </font>{" "}
                যে কক্ষে বিলটি উত্থাপিত হয়েছিল, সেই কক্ষে বিলটি গৃহীত হওয়ার
                পরবর্তী পর্যায়ে সেই বিলটিকে অন্য কক্ষের অনুমোদনের জন্য প্রেরণ
                করা হয়। এই কক্ষেও বিলটিকে পূর্বেই মতোই বিভিন্ন পর্যায় অতিক্রম
                করতে হয়। কোনোরকম সংশোধনী ব্যতীত এই কক্ষে বিলটি গ্রহণ করা হতে
                পারে। তবে বিলটি গ্রহণের ক্ষেত্রে উভয় কক্ষের মধ্যে মতপার্থক্য
                দেখা দিলে উভয় কক্ষের যুক্ত অধিবেশনে বিলটির ভাগ্য নির্ধারিত হয়।
                এক্ষেত্রে লোকসভার সদস্যসংখ্যা রাজ্যসভার তুলনায় অনেক বেশি
                হওয়ায় লোকসভার জয় হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. সপ্তম পর্যায় : </b>{" "}
                </font>{" "}
                সপ্তম ও শেষ পর্যায় হল রাষ্ট্রপতির সম্মতি জ্ঞাপনের পর্যায়।
                পার্লামেন্টের উভয় কক্ষে বিলটি গৃহীত হওয়ার পর তা রাষ্ট্রপতির
                কাছে পাঠানো হয়। রাষ্ট্রপতি সংশ্লিষ্ট বিলটিতে সম্মতি দিলে বিলটি
                তৎক্ষণাৎ আইনে পরিণত হয়। অবশ্য রাষ্ট্রপতির অসম্মতি জানানোর
                ক্ষমতাও রয়েছে। এ ছাড়া রাষ্ট্রপতি কোনো বিলকে পুনর্বিবেচনার জন্য
                পার্লামেন্টের কাছে ফেরত পাঠাতেও পারেন। তবে পার্লামেন্ট সংশ্লিষ্ট
                বিলটিকে পুনরায় অনুমোদন করে পাঠালে রাষ্ট্রপতি তাতে সম্মতি দিতে
                বাধ্য থাকেন। এভাবে একটি বিল লোকসভা, রাজ্যসভা ও রাষ্ট্রপতির
                অনুমোদনক্রমে আইনে পরিণত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  3. অর্থ বিল কী? ভারতের সংসদে অর্থবিল পাসের পদ্ধতিটি আলোচনা
                  করো।
                  <br />
                </b>
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#1f7405">
                <b> অর্থ বিশের সাজা : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                {" "}
                সংবিধানের ১১০ নং ধারায় অর্থবিলের সংজ্ঞা দেওয়া হয়েছে। এই
                ধারায় উল্লিখিত সাতটি বিষয় বা তার মধ্যে যেকোনো একটিকে নিয়ে
                রচিত বিলকে অর্থবিল বলা হয়। এগুলি হল - <br />
                <br />
                (1) কোনো করধার্য, বিলোপ, প্রত্যাহার, পরিবর্তন ও নিয়ন্ত্রণ,
                <br />
                (2) সরকারি ঋণ গ্রহণ-সম্পর্কিত আইনগত ব্যবস্থা বা গৃহীত ঋণের দায়
                দায়িত্ব-সম্পর্কিত কোনো আইনের সংশোধন,
                <br />
                (3) ভারতের সজ্জ্বিত তহবিল বা আকস্মিক ব্যয় তহবিল থেকে অর্থের
                বিনিয়োগ,
                <br />
                (4) ভারতের সঞ্চিত তহবিল বা আকস্মিক ব্যয় তহবিল থেকে অর্থ প্রদান
                বা প্রত্যাহার,
                <br />
                (5) কোনো ব্যয়কে ভারতের সঞ্চিত তহবিলের ব্যয় বলে ঘোষণা বা সঞ্চিত
                তহবিলের ব্যয়ের পরিমাণ বৃদ্ধি,
                <br />
                (6) রাষ্ট্রের সঞ্চিত তহবিল খাতে অর্থপ্রাপ্তি বা ওই অর্থের
                তত্ত্বাবধান বা অর্থপ্রদান অথবা কেন্দ্রীয় সরকার ও রাজ্য
                সরকারগুলির হিসাব পরীক্ষা,
                <br />
                (7) উপরোক্ত বিষয়গুলির সঙ্গে সম্পর্কিত যেকোনো বিষয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b> অর্থবিলের বৈশিষ্ট্য : </b>{" "}
                </font>{" "}
                অর্থ বিল অন্যান্য সাধারণ বিলের থেকে সুনির্দিষ্টভাবে চিহ্নিত
                হওয়া প্রয়োজন —<br />
                <br />
                (1) যদি পার্লামেন্টের উভয় কক্ষ অর্থবিল গৃহীত করে, তবে স্পিকারের
                স্বাক্ষরসহ প্রমাণপত্র যুক্ত করে এবং এটি রাষ্ট্রপতির কাছে
                চূড়ান্ত মতামতের জন্য প্রেরিত হয়। রাষ্ট্রপতির স্বাক্ষর প্রাপ্তি
                হলে, তা আইনে পরিণত হয়।
                <br />
                (2) অর্থ বিল কেবল লোকসভাতে উত্থাপিত হয় এবং কোনও অবস্থায় তা
                রাজ্যসভায় উত্থাপিত হয় না।
                <br />
                (3) পার্লামেন্টে রাষ্ট্রপতির সুপারিশ ছাড়াই অর্থ বিল পেশ করা
                যায় না। রাষ্ট্রপতি অর্থ বিলে সুপারিশ দিতে বাধ্য থাকেন এবং তিনি
                সাধারণ বিলের মতো অর্থ বিলে ভেটো ক্ষমতা প্রয়োগ করতে পারেন না।
                <br />
                (4) অর্থমন্ত্রী হলে রাষ্ট্রপতির সম্মতিস্বরূপ অর্থ বিল উত্থাপন
                করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b>অর্থ বিল সম্পর্কে স্পিকারের সিদ্ধান্ত : </b>{" "}
                </font>{" "}
                ১১০(২) নং ধারায় বলা হয়েছে যে, জরিমানা বা অন্য কোনো অর্থদণ্ড
                ধার্য, লাইসেন্স বা সেবার জন্য ফি দাবি বা প্রদান অথবা স্থানীয়
                কর্তৃপক্ষ বা সংস্থা কর্তৃক স্থানীয় উদ্দেশ্যে কোনো কর ধার্য,
                বিলোপ, প্রত্যাহার, কর হার পরিবর্তন বা নিয়ন্ত্রণকে অর্থ বিল বলা
                যাবে না। কোনো বিল অর্থবিল কি না তা নিয়ে মতবিরোধ দেখা দিলে
                লোকসভার স্পিকারের সিদ্ধান্তই চূড়ান্ত। এক্ষেত্রে একটি প্রমাণপত্র
                স্পিকার দিয়ে থাকেন৷
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>
                <font color="#1f7405">
                  <b>অর্থ বিল পাসের পদ্ধতি : </b>{" "}
                </font>{" "}
                সংবিধানের ১০৯ নং ধারায় অর্থ বিলের উত্থাপনা এবং আইন প্রণয়ন
                পদ্ধতি নিয়ে উল্লেখ করা হয়। সাধারণভাবে, অর্থমন্ত্রী বা তার
                দায়িত্বপ্রাপ্ত কোনো মন্ত্রী রাষ্ট্রপতির সুপারিশের ভিত্তিতে অর্থ
                বিলটি লোকসভায় উত্থাপন করে। অর্থ বিলটি আইনে পরিণত হওয়ার সময়,
                তিনটি পর্যায় অতিক্রম করতে হয় —<br />
                <br />
                (1) অর্থবিল শুধুমাত্র লোকসভাতেই উত্থাপন করা যায়। রাজ্যসভায়
                কোনো অর্থ বিল উত্থাপন করা যায় না। রাষ্ট্রপতির সুপারিশে
                শুধুমাত্র মন্ত্রীরা এই অর্থ বিল উত্থাপন করেন। সাধারণ বিল পাসের
                জন্য প্রতিটি বিলকে যেভাবে প্রথম ও দ্বিতীয় পাঠ, কমিটি পর্যায়,
                তৃতীয় পাঠ প্রভৃতি পর্যায় অতিক্রম করতে হয় অর্থবিলের ক্ষেত্রেও
                তেমনটাই ঘটে।
                <br />
                <br />
                (2) অর্থ বিল লোকসভায় গৃহীত হলে তা রাজ্যসভার কাছে সুপারিশের জন্য
                পাঠানো হয়। অর্থবিলকে প্রত্যাখ্যান বা সংশোধন করার কোনো ক্ষমতা
                রাজ্যসভার নেই। রাজ্যসভাকে ১৪ দিনের মধ্যে সুপারিশসহ অর্থ বিল
                লোকসভায় পাঠিয়ে দিতে হয়। ওই সময়ের মধ্যে যদি বিলটি ফেরত না আসে
                তাহলে ধরে নেওয়া হয় যে পার্লামেন্টের উভয়কক্ষে বিলটি গৃহীত
                হয়েছে। অর্থাৎ, ১৪ দিনের বেশি রাজ্যসভা কোনো অর্থবিলকে আটকে রাখতে
                পারে না।
                <br />
                <br />
                (3) পার্লামেন্টে অর্থবিল গৃহীত হওয়ার পর তা রাষ্ট্রপতির সম্মতির
                জন্য পাঠানো হয়। রাষ্ট্রপতি কোনো অর্থ বিলকে পুনর্বিবেচনার জন্য
                পার্লামেন্টে ফেরত পাঠাতে পারেন না। অর্থ বিলে রাষ্ট্রপতির সম্মতি
                গ্রহণের বিষয়টি একটি নিছক আনুষ্ঠানিক ব্যাপার মাত্র।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  4. ভারতের সংসদের দুটি কক্ষের মধ্যে সাংবিধানিক সম্পর্ক আলোচনা
                  করো। <br />
                  অথবা,
                  <br /> রাজ্যসভা ও লোকসভার মধ্যে পারস্পরিক সম্পর্ক ব্যাখ্যা
                  করো।
                  <br />
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতীয় সংবিধানে কেন্দ্রীয় আইনসভা বা পার্লামেন্টে দুটি কক্ষ
                রয়েছে। সংবিধানের 79 নং ধারায় বলা হয়েছে ভারতীয় পার্লামেন্ট
                দুটি কক্ষ এবং রাষ্ট্রপতিকে নিয়ে গঠিত। তবে, রাষ্ট্রপতি কোনও
                কক্ষের সদস্য নন, তিনি পার্লামেন্টের অবিচ্ছেদ্য অঙ্গ। ভারতের
                দ্বিকক্ষবিশিষ্ট আইনসভার দুটি কক্ষ হল – উচ্চ কক্ষ অর্থাৎ রাজ্যসভা
                এবং নিম্নকক্ষ অর্থাৎ লোকসভা। লোকসভার কার্যকালের মেয়াদ ৫ বছর।
                বর্তমানে আইন অনুসারে লোকসভায় সর্বাধিক সদস্য হতে পারে ৫৫২।
                বর্তমানে লোকসভায় সদস্য আছে ৫৪৫। অন্যদিকে রাজ্যসভা একটি স্থায়ী
                কক্ষ। রাজ্যসভার সদস্যদের কার্যকালের মেয়াদ ৬ বছর। প্রতি ২ বছর
                অন্তর অংশ সদস্যের কার্যকাল শেষ হয় এবং নতুন অংশ সদস্য যুক্ত হন।
                রাজ্যসভার সদস্য হতে পারে সর্বাধিক ২৫০ জন। তবে প্রশ্ন উঠতেই পারে
                — লোকসভা না রাজ্যসভা অধিক ক্ষমতাসম্পন্ন। তাই উভয়ের পারস্পরিক
                সম্পর্কের বিষয়টিকে তিনটি দিক থেকে আলোচনা করা যেতে পারে – <br />
                <br />
                (i) লোকসভা ও রাজ্যসভা উভয় সমক্ষমতাসম্পন্ন এমন ক্ষেত্র, <br />
                (ii) রাজ্যসভা লোকসভার চেয়ে অধিক ক্ষমতাসম্পন্ন এমন ক্ষেত্র এবং
                <br />
                (iii) লোকসভা রাজ্যসভার চেয়ে অধিক ক্ষমতাসম্পন্ন এমন ক্ষেত্র।
                <br />
                <font color="#f626d7">
                  <b>A. লোকসভা ও রাজ্যসভার সমক্ষমতা : </b>{" "}
                </font>{" "}
                সাংবিধানিক দিক থেকে লোকসভা ও রাজ্যসভা যে সমস্ত বিষয়গুলিতে
                সমক্ষমতা ভোগ করে সেগুলি হল —<br />
                <br />
                (i) অর্থ বিল ছাড়া অন্যান্য সাধারণ বিলে আইন প্রণয়ন, সংবিধান
                সংশোধন, জরুরি অবস্থার অনুমোদনের ক্ষেত্রে উভয় কক্ষ সমক্ষমতা ভোগ
                করে। <br />
                (ii) রাষ্ট্রপতি ও উপ-রাষ্ট্রপতি নির্বাচন, রাষ্ট্রপতির পদচ্যুতির
                বিষয়ে উভয় কক্ষই সমক্ষমতার অধিকারী।
                <br />
                (iii) হাইকোর্ট ও সুপ্রিমকোর্টের বিচারকদের অপসারণ, কেন্দ্রীয়
                রাষ্ট্রকৃত্যকের সভাপতি ও সদস্যদের অপসারণের বিষয়ে উভয়কক্ষই
                সমক্ষমতার অধিকারী। <br />
                (iv) আইনসভার অবমাননাকারী কোনো ব্যক্তি অথবা প্রতিষ্ঠানের বিরুদ্ধে
                উভয় কক্ষই শাস্তি দেয়ার অধিকারী। এই ব্যক্তি আইনসভার সদস্য হতে
                পারে আবার নাও হতে পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>B. রাজ্যসভা লোকসভার থেকে অধিক ক্ষমতাশালী : </b>{" "}
                </font>{" "}
                যেসব বিষয়ে রাজ্যসভা লোকসভার উপর প্রাধান্য বিস্তার করে, তা হল -
                <br />
                <br />
                (i) সংবিধানের-249 নং ধারা অনুসারে যদি রাজ্যসভার উপস্থিত ও
                ভোটদানকারী অংশ সদস্যের সমর্থনে প্রস্তাব গৃহীত হয় যে জাতীয়
                স্বার্থে রাজ্য তালিকার অন্তর্ভুক্ত কোনো বিষয়ে পার্লামেন্টের আইন
                প্রণয়ন করা উচিত, তবে পার্লামেন্ট সেই বিষয়ে আইন প্রণয়ন করতে
                পারে।
                <br />
                <br />
                (ii) নতুন সর্বভারতীয় চাকরি সৃষ্টির ক্ষেত্রে রাজ্যসভা বিশেষ
                ক্ষমতা ভোগ করে। 312 ধারায় বলা হয়েছে যদি রাজ্যসভার উপস্থিত ও
                ভোটদানকারী সদস্যের অংশের সমর্থনে প্রস্তাব গৃহীত হয় যে জাতীয়
                স্বার্থে পার্লামেন্টের এক বা একাধিক সর্বভারতীয় চাকরি সৃষ্টি করা
                দরকার, তাহলে পার্লামেন্ট সে বিষয়ে আইন প্রণয়ন করবে।
                <br />
                <br />
                (iii) পদাধিকারবলে ভারতের উপরাষ্ট্রপতি রাজ্যসভায় সভাপতিত্ব করে
                থাকেন। সাংবিধানের ক্ষমতাবলে উপরাষ্ট্রপতির পদচ্যুতি সংক্রান্ত
                প্রস্তাব কেবল রাজ্যসভায় উত্থাপন করা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>C. লোকসভা, রাজ্যসভার থেকে অধিক ক্ষমতাশালী : </b>{" "}
                </font>{" "}
                যেসব দিক থেকে লোকসভা রাজ্যসভার উপর প্রাধান্য বিস্তার করে তা হল —{" "}
                <br />
                <br />
                (i) ব্রিটেনের অনুকরণে ভারতে সংসদীয় বা মন্ত্রীসভাচালিত
                শাসনব্যবস্থা প্রবর্তিত হয়েছে। সংসদীয় শাসনব্যবস্থার রীতি
                অনুসারে ক্যাবিনেট বা মন্ত্রীসভা আইনসভার নিম্নকক্ষের নিকট
                দায়বদ্ধ থাকে। ফলে ভারতীয় সংসদীয় শাসন ব্যবস্থায়
                প্রধানমন্ত্রীর নেতৃত্বে পরিচালিত ক্যাবিনেট বা মন্ত্রীসভা লোকসভার
                নিকট দায়বদ্ধ থাকে। কোনো মন্ত্রী অথবা সমগ্র মন্ত্রীসভার বিরুদ্ধে
                অনাস্থা প্রস্তাব পাস হলে মন্ত্রীসভাকে পদত্যাগ করতে হয়। তাই
                মন্ত্রীসভা লোকসভার নিকটই কেবল দায়বদ্ধ, রাজ্যসভার নিকট নয়।
                <br />
                <br />
                (ii) অর্থ বিলের বিষয়ে লোকসভাই প্রভূত ক্ষমতার অধিকারী। অর্থ বিল
                কেবল লোকসভাতেই উত্থাপন করা যায়, রাজ্যসভায় নয়। তা ছাড়া অর্থ
                বিল বা অর্থসংক্রান্ত বিল সংশোধনের প্রস্তাব রাজ্যসভায় উত্থাপন
                করা যায় না। কোনো বিল অর্থ বিল কিনা, চূড়ান্ত বিচারের ক্ষমতা
                লোকসভার স্পিকারের হাতেই ন্যস্ত। এমনকি অর্থ বিল লোকসভায় পাস
                হওয়ার পর তা রাজ্যসভায় পাঠানো হয়, রাজ্যসভার সম্মতির জন্য। তবে
                রাজ্যসভা 14 দিনের মধ্যে অর্থ বিলে সম্মতি দিক বা না দিক, ধরে
                নেওয়া হবে বিলটি উভয় কক্ষে পাস হয়েছে এবং তা রাষ্ট্রপতির
                স্বাক্ষর পেলে আইনে পরিণত হবে।
                <br />
                <br />
                (iii) সাধারণ বিলগুলো আইনে পরিণত করার বিষয়ে লোকসভা ও রাজ্যসভা সম
                ক্ষমতার অধিকারী হলেও বাস্তবে লোকসভার ক্ষমতাই বেশি। কোনো সাধারণ
                বিল নিয়ে উভয় কক্ষে মতপার্থক্য সৃষ্টি হলে রাষ্ট্রপতি উভয়কক্ষের
                যৌথ অধিবেশন আহ্বান করেন। লোকসভার স্পিকার এই যৌথ অধিবেশনে
                সভাপতিত্ব করেন। তবে লোকসভার সদস্য সংখ্যা রাজ্যসভার সদস্য সংখ্যার
                তুলনায় বেশি বলে লোকসভার মতামতই প্রাধান্য পায়।
                <br />
                <br />
                (iv) জরুরি অবস্থা বিষয়ে লোকসভা ও রাজ্যসভা সমক্ষমতার অধিকারী
                হলেও জরুরি অবস্থা প্রত্যাহারের বিষয়ে লোকসভা প্রস্তাব গ্রহণ করলে
                রাষ্ট্রপতি তা প্রত্যাহার করতে বাধ্য থাকেন।
                <br />
                <br />
                ভারতীয় সংসদীয় শাসনব্যবস্থায় আপাতদৃষ্টিতে লোকসভার ক্ষমতাই
                সর্বাধিক প্রাধান্য পেয়ে থাকে। তবে বলা যায় রাজ্যসভা মার্কিন
                কংগ্রেসের উচ্চকক্ষ সিনেটের মতো অতটা ক্ষমতাশালী নয়। আবার
                ব্রিটেনের আইনসভার উচ্চকক্ষ লর্ড সভার মতো এত কম মর্যাদাসম্পন্ন
                নয়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

ভারতেরআইনবিভাগ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(ভারতেরআইনবিভাগ);
