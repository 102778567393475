import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function জীবেরজনন() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          জীবের জনন | West Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="জীবের জনন | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                জীবের জনন
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বাঁশগাছে ফুল দেখা যায় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  12 বছরে একবার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রত্যেক বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জীবনচক্রে শুধুমাত্র একবার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  50-100 বছরে দু-বার
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  মিয়োসিস ব্যতিরেকে রেণুধর উদ্ভিদ থেকে সরাসরি লিঙ্গধর উদ্ভিদ
                  সৃষ্টিকে বলে -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পার্থেনোকার্পি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পার্থেনোজেনেসিস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাপোস্পোরি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অ্যাপোগ্যামি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মাছির মিয়োসাইটের ক্রোমোজোম সংখ্যা হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  21
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  23
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  12
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>8
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  রেণু মাতৃকোশ থেকে পুংগ্যামেটোফাইট উৎপন্নের জন্য নিম্নলিখিত কোন
                  পদ্ধতিগুলি অনুসরণ করা হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দুটি মাইটোসিস কোশবিভাজন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  একটি মিয়োসিস এবং দুটি মাইটোসিস কোশ বিভাজন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দুটি মিয়োসিস এবং একটি মাইটোসিস কোশবিভাজন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  একটি মিয়োসিস কোশবিভাজন এবং একটি মাইটোসিস কোশবিভাজন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  একটি আলু থেকে নতুন গাছ সৃষ্টি হওয়া সম্ভব যদি তার মধ্যে
                  নিম্নলিখিত অঙ্গটি উপস্থিত থাকে -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মূল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অর্বুদ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সঞ্চিত খাদ্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  চোখ বা কাক্ষিক মুকুল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>পাখির দ্বারা বীজের বিস্তার ঘটানোর উপায় হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ঠোটে করে বীজ নিয়ে যাওয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পালকের সাহায্যে বহন করা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফল খাওয়া এবং পরিপাকের পর বীজ পাখির অস্ত্রে থাকা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ফল খেয়ে বীজগুলি অক্ষত অবস্থায় বিষ্ঠার সঙ্গে নির্গত করা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>পুদিনা নামক উদ্ভিদে অঙ্গজ জননের মাধ্যম হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রানার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অফসেট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাইজোম
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>পরাগরেণু সম্পর্কীয় গবেষণাকে বলা হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কো-ট্যাক্সোনমি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রত্নেদ্ভিদবিদ্যা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এথমোলজি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  রেণুবিদ্যা বা প্যালাইনোলজি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ডিপ্লয়েড জীবের জনন মাতৃকোশ থেকে যে গ্যামেট উৎপন্ন হয় তাদের
                  বলা হয় -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডিম্বক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মিয়োসাইট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মাইটোসাইট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনোটাই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>পুষ্পাঙ্ক হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পুংরেণুর রূপাত্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডিম্বাশয়ের নিম্নভাগ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পাপড়ির রূপান্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ফুলের যে অংশে পুষ্পস্তবকগুলি বিরাজ করে
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#1f7405" size="4">
                <b>জীবের জনন</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. জাইগোট কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                দুটি হ্যাপ্লয়েড গ্যামেটের (n) মিলনে যে ডিপ্লয়েড (2n) কোশ গঠিত
                হয়, তাকে জাইগোট বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. নিষেক কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পুংগ্যামেট ও স্ত্রী গ্যামেটের মিলনকে নিষেক বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    3. সংশ্লেষ দেখা যায় এমন একটি উদ্ভিদ ও প্রাণীর নাম কী কী?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                উদ্ভিদ : স্পাইরোগাইরা (শৈবাল)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. কুমিরের জীবদ্দশার সময়সীমা কত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                60 বছর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. ব্রায়োফাইলামের অযৌন জননকারী অংশটি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                অস্থানিক পত্রমুকুল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. জীবন কাল কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                জীবের জন্মের পর থেকে স্বাভাবিক মৃত্যুর পূর্ব পর্যন্ত সময়কে
                জীবনকাল বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. দ্বি-বিভাজনে কোন্‌টি জনন একক রূপে কাজ করে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                সম্পূর্ণ জীবদেহটি জনন একক রূপে কাজ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. বহুবিভাজন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে প্রক্রিয়ায় মাতৃজীব বিভাজিত হয়ে বহু অপত্য জীব সৃষ্টি হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. প্লাসমোটোমি কাদের ক্ষেত্রে দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ওপালিনা (Opalina), পেলোমিক্সা (Pelomyxa)-র ক্ষেত্রে দেখা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. বাডিং কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                জনিতৃ দেহ থেকে উৎপন্ন কোরক থেকে বংশবিস্তার কে বাড়িং বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    11. খণ্ডীভবন দেখা যায় এমন একটি উদ্ভিদ ও প্রাণীর নাম কী কী?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                উদ্ভিদ : রিকসিয়া (Riccia); প্রাণী : স্পা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. জুস্পোর বা চলরেণু কাদের ক্ষেত্রে গঠিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ফাইটোপথোরা (Phytophthora), ইউলোথ্রিক্স (Ulothrix)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. ক্ল্যামাইডোস্পোর কাদের ক্ষেত্রে লক্ষ করা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                রাইজোপাস (Rhizopus), অ্যাগারিকাস (Agaricus)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. অ্যাপোমিক্সিস কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                মিয়োসিস ও নিষেক ব্যাতীত, ভ্রুণ বা বীজ উৎপাদন হলে তার
                অ্যাপোমিক্সিস বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. স্পোরাগ্লিওরেণু কার ক্ষেত্রে দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                মিউকর (Mucor)
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. কন্দ কাদের ক্ষেত্রে দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পেঁয়াজ, রসুন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    16. কোন্ কোন্ গাছ ধাবকের সাহায্যে অঙ্গজ জনন সম্পন্ন করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                আমরুল, শুশনি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    17. কোন্ কোন্ উদ্ভিদ বুলবিলের সাহায্যে অঙ্গজ জনন সম্পন্ন
                    করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                চুপড়ি আলু (Dioscoria), এগেভ (Agave) |<br />
                <br />
                <font color="#f626d7">
                  <b>
                    18. কোন্ প্রাণী কোরকোগম এবং পুনরুৎপাদন পদ্ধতিতে অযৌন সম্পন্ন
                    করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                হাইড্রা (Hydra)
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    19. কোন্ দুটি প্রাণী দ্বি-বিভাজন পদ্ধতিতে জনন সম্পন্ন করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                অ্যামিবা (Amoeba), প্লানেরিয়া (Planaria)
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. উভলিঙ্গ প্রাণী কাকে বলে? উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে সকল প্রাণীর ক্ষেত্রে পুং এবং স্ত্রী উভয় জননাঙ্গই উপস্থিত।
                উদা: টিনিয়া (Tacnia)
                <br />
                <br />
                <font color="#f626d7">
                  <b>21. জনন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                জনিতৃ জীব থেকে অপত্য জীব সৃষ্টি হওয়ার পদ্ধতিকে জনন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    22. কোন্ জলজ উদ্ভিদ টিউরিয়নের সাহায্যে অঙ্গজ জনন সম্পন্ন
                    করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ইউট্রিকুলারিয়া (Utricularia)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. যৌন জননের একক কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                জনন কোশ বা গ্যামেট।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. একটি সহবাসী উদ্ভিদের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                নারকেল গাছ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. মুক্ত নিউক্লিয় বিভাজন কোথায় দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                সস্যে
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. কোন্ উদ্ভিদের অঙ্গজ জননে দাবাকলম ব্যবহৃত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                জুঁই
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. প্রাকৃতিক পার্থেনোজেনেসিস কার ক্ষেত্রে দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                অ্যাফিড।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. সমজনন কোশ কার দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Cladophora
                <font color="#f626d7">
                  <b>29. একটি অণ্ড জরায়ুজ প্রাণীর নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                হাঙর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    30. Strobilanthus kunthiana সপুষ্পক উদ্ভিদের বিশেষত্ব কী?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                এই উদ্ভিদের 12 বছরে একবার ফুল ফোটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>31. পুং ও স্ত্রীজনন কোশের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                শুক্রাণু ও ডিম্বাণু যথাক্রমে পুং ও স্ত্রী জনন কোশ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. গ্যামেটোজেনেসিস কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                গ্যামেট উৎপাদনের প্রক্রিয়াকে গ্যামেটোজেনেসিস বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. অ্যাম্ফিমিক্সিস কথাটির অর্থ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যৌনজননের ফলে পুং ও স্ত্রী গ্যামেটের ক্রোমোজোমের মিশ্রণকে
                অ্যাম্ফিমিক্সিস বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. চলরেণু ও গ্যামেটের মধ্যে কোনটি আকৃতিতে বড় হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                চলরেণু আকৃতিতে বড়ো হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    35. খণ্ডীভবন লক্ষ করা যায় এমন একটি উদ্ভিদ ও প্রাণীর নাম
                    লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                স্পাইরোগাইরা নামক শৈবাল এবং সাইকন নামক ছিদ্রালদেহী প্রাণীতে
                খণ্ডীভবন দেখা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>36. স্টক ও সিয়নের মধ্যে কোন্‌টি উন্নত হওয়া প্রয়োজন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                কলমের এই দুটি অংশের মধ্যে সিয়ন উন্নত হওয়া প্রয়োজন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. পত্ৰজ মুকুল দেখা যায় এমন একটি উদ্ভিদের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পাথরকুচি (Bryophyllum) উদ্ভিদে পত্রজ মুকুল দেখা যায় যা অঙ্গজ
                জনন সম্পন্ন করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. কনিডিয়া কোন্ ধরনের উদ্ভিদের অযৌন জননের একক?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                কনিডিয়া ছত্রাক জাতীয় উদ্ভিদ, যেমন — পেনিসিলিয়াম-এর অযৌন জননের
                একক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. পুং মৌমাছির ক্ষেত্রে কোন প্রকার জনন দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পার্থেনোজেনোসিস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>40. ক্ল্যামাইডোস্পোর কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                প্রতিকূল পরিবেশে উৎপন্ন স্থূল প্রাচীরযুক্ত অযৌন রেণুকে
                ক্ল্যামাইডোস্পোর বলে। উদাহরণ : মিউকর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    41. কোন্ আদ্যপ্রাণী সাইজোগনি নামক বহুবিভাজন পদ্ধতিতে
                    বংশবিস্তার করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ম্যালেরিয়ার জীবাণু গ্লাসমোডিয়াম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>42. মূলজ মুকুল সৃষ্টিকারী দুটি উদ্ভিদের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পটল ও মিষ্টি
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    43. দুটি গাছের নাম করো যারা বীজের মাধ্যমে বংশবিস্তার করে না।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                আলু ও জবা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>44. কলম তৈরিতে সাহায্য করে এমন দুটি হরমোনের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ইনডোল অ্যাসিটিক অ্যাসিড (IAA) এবং সাইটোকাইনিন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    45. কোন্ ধরনের জোড়কলম তৈরির সময় একটি স্টকের সাথে অনেকগুলি
                    নিয়ন যুক্ত করা হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ক্লাউন গ্রাফটিং।
                <br />
                <br />
                <font color="#f626d7">
                  <b>47. গ্যামেট ও রেণুর মধ্যে কী সাদৃশ্য লক্ষ করা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                উভয়ই এককোশী, হ্যাপ্লয়েড (n) জনন কোশ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>48. ফ্ল্যাজেলাযুক্ত রেণুকে কী বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ফ্ল্যাজেলাযুক্ত রেণু চলনক্ষম বলে একে প্ল্যানোস্পোর বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>49. ফ্ল্যাজেলাবিহীন রেণুকে কী বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ফ্ল্যাজেলা না থাকায় রেণু গমনে অক্ষম হয় বলে একে
                অ্যাপ্ল্যানোস্পোর বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>50. টুরিয়ন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                টুরিয়ন জলজ উদ্ভিদের এক ধরনের মৃগত মুকুল যা সাধারণত শৈত্য
                প্রতিরোধক জননাঙ্গ। উদা: ইউট্রিকুলারিয়া।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#1f7405" size="4">
                <b>প্রশ্নের মান - ২</b>
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>1. জনন বা রিপ্রোডাকশন কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে জৈবিক পদ্ধতিতে জীব নিজ সত্ত্বা ও আকৃতিবিশিষ্ট অপত্য জীব
                সৃষ্টি করে এবং সংখ্যা বৃদ্ধির দ্বারা নিজস্ব বংশধারাকে অক্ষুণ্ণ
                রাখে, তাকে জনন বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. জননের দুটি গুরুত্ব লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>গুরুত্ব : </b>
                </font>{" "}
                (i) জননের ফলে জীবের মৃত্যুজনিত সংখ্যার হ্রাস পুরণ হয়, যার ফলে
                পৃথিবীতে জীবজগতের ভারসাম্য বজায় থাকে। <br />
                (ii) জনন জীবের মধ্যে ভেদ বা প্রকরণের সূচনা করে। প্রয়োজনীয়
                প্রকরণ জীবের অভিযোজন ও অভিব্যক্তির জন্য আবশ্যক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. যৌন জনন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে জনন পদ্ধতিতে দুটি অসম আকৃতির ভিন্নধর্মী জনন কোশ অর্থ
                পুংগ্যামেট ও স্ত্রী গ্যামেটের মিলনের ফলে অপত্য জীবের সৃষ্টি হয়,
                তার যৌন জনন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. যৌন জননের বৈশিষ্ট্যগুলি সংক্ষেপে লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>বৈশিষ্ট্য : </b>
                </font>{" "}
                (i) যৌন জননে দুই প্রকার জননকোশ অর্থাৎ পুং স্ত্রী গ্যামেটের
                প্রয়োজন হয়। <br />
                (ii) যৌন জননে পুং ও স্ত্রী গ্যামেটের মিলন ঘটে যাকে নিষেক বলে।
                <br /> (iii) অপত্য জীবগুলিতে নতুন বৈশিষ্ট্যের সৃষ্টি হয়। <br />
                (iv) এই জনন তুলনামূলকভাবে সময়সাপেক্ষ। <br />
                (v) হ্যাপ্লয়েড ও ডিপ্লয়েড দশা পর্যায়ক্রমে আবর্তিত হয় বলে
                জনুক্রম লম্ব করা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. অযৌন জনন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে জনন পদ্ধতিতে গ্যামেট উৎপাদন ছাড়াই জনিত্ব জীবের দেহকোশ
                বিভাজিত হয়ে অথবা রেণু তৈরির মাধ্যমে অপত্য জীবের সৃষ্টি হয়,
                তাকে অযৌন জনন বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. অযৌন জননের তিনটি বৈশিষ্ট্য উল্লেখ করো।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                বৈশিষ্ট্য : <br />
                (i) একটি মাত্র জনিতৃ জীব জননে অংশগ্রহণ করে; <br />
                (ii) গ্যামেট সৃষ্টি হয় না; নিষেক সম্পন্ন হয় না; <br />
                (iii) কেবলমাত্র মাইটোসিস কোশ বিভাজন ঘটে সেক্ষেত্রে অপত্য জীব
                মাতৃভীবের সদৃশ হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. অঙ্গজ জনন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে জনন প্রক্রিয়ায় উদ্ভিদদেহের কোনো অঙ্গ বা অভ্যাংশ জনতৃ থেকে
                বিচ্ছিন্ন হয়ে অপত্য উদ্ভিদ সৃষ্টি করে, তাকেই অঙ্গজ জনন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. জীবের জীবনকালের পর্যায়গুলি কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                জীবের জীবনকালের চারটি পর্যায় লক্ষ করা যায় – (i) কৈশোর কাল,
                (ii) পরিণতি পর্যায়, (iii) বয়ঃপ্রাপ্তি ও বার্ধক্য ও (iv)
                মৃত্যু।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. জননের মূল বৈশিষ্ট্য সংক্ষেপে লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                বৈশিষ্ট্য : <br />
                (i) জীবদেহ থেকে একটি অংশ বিচ্ছিন্ন হয়ে অথবা রেণু বা গ্যামেট
                উৎপাদনের মাধ্যমে জনন সম্পন্ন হয়। <br />
                (ii) অযৌন জননের ক্ষেত্রে রেণু ও যৌন জননের ক্ষেত্রে গ্যামেটকে
                জননের একক বলা হয়।
                <br />
                (iii) জননের কোশতত্ত্বগত ভিত্তি হল কোশ বিভাজন। <br />
                (iv) জননের আণবিক ভিত্তি হল DNA অণুর প্রতিলিপিকরণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. সোমাটোজেনিক জনন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে অযৌন জনন প্রক্রিয়ায় দেহকোশ অ্যামাইটোসিস বা মাইটোসিস
                প্রক্রিয়ায় বিভাজিত হয়ে অপত্য কোশের সৃষ্টি হয়, তাকেই
                সোমাটোজেনিক বা ব্লাস্টোজেনিক জনন বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. দ্বি-বিভাজন কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে অযৌন জনন প্রক্রিয়ায় এককোশী জীবের নিউক্লিয়াস প্রত্যক্ষভাবে
                বিভাজিত হয় এবং পরবর্তী ধাপে সাইটোপ্লাজমে বিভাজিত হয়ে দুটি
                অনুরূপ অপত্য কোশ সৃষ্টি করে তাকে দ্বি-বিভাজন বলে। <br />
                উদাহরণ : ইস্ট, অ্যামিবা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. অনুদৈর্ঘ্য বিভাজন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে প্রকার বিভাজন অনুদৈর্ঘ্য অক্ষ বরাবর হয়। প্রথমে ফ্লাজেলামের
                বিভাজন ঘটে এবং পরে দেহের বিভাজন ঘটে। প্রথমে মাতৃ নিউক্লিয়াসটি
                দুটি অপত্য নিউক্লিয়াস গঠন করে, পরে সাইটোপ্লাজন অনুদৈর্ঘ্যভাবে
                বিভাজিত হয়ে দুটি অপত্য প্রাণী গঠন করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. সিউডোপোডিওরেণু কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                অ্যামিবার বহুবিভাজনে সিস্টের মধ্যে অ্যামিবা বার বার বিভাজিত হয়ে
                অসংখ্য ক্ষুদ্র ক্ষুদ্র অ্যামিবা সৃষ্টি করে, যাদের সিউডোপোডিওরেণু
                বলে। অনুকুল পরিবেশে সিস্টের প্রাচীর বিদীর্ণ হয়ে
                সিউডোপোডিওরেগুগুলি মুক্ত হয়ে অপত্য অ্যামিবা সৃষ্টি করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. প্ল্যাসনোটোমি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে প্রক্রিয়ায় বহু নিউক্লিয়াস যুক্ত জনিতৃ প্রাণী বিভাজিত হয়ে
                বহু নিউক্লিয়াস যুক্ত অপত্য প্রাণী সৃষ্টি করে, যাকে
                প্ল্যাসমোটোমি বলে। <br />
                উদাহরণ : ওপালিনা (Opalina)
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. এক্সোজেনাস বাড়িং কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                হাইড্রা (Hydra) কোরক দেহের বাইরের দিকে সৃষ্টি হয়, এই প্রকার
                বাড়িংকে এক্সোজেনাস বাড়িং বলে। হাইড্রার দেহের পরিধির দিকে কোরক
                গঠিত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>16. ওইডিয়া কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                অ্যাগারিকাসের (Agaricus) অণুসূত্রে ছোটো ছোটো খণ্ডক গঠন হয়।
                যাদের ওইডিয়া বলে। এই গুলি পাতলা প্রাচীর বিশিষ্ট এক ধরনের রেণু,
                যাদের মধ্যে সজ্জিত খাদ্যবস্তু থাকে, সাধারণত জলে অতিরিক্ত লবণের
                উপস্থিতিতে এই ধরণের রেণুর সৃষ্টি করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. সংশ্লেষ বা কনজুগেশন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে যৌন জনন পদ্ধতিতে একই প্রজাতির কিন্তু শরীরবৃত্তীয়ভাবে পৃথক
                দুটি জীব সংশ্লেষ নালিকার মাধ্যমে অস্থায়ীভাবে মিলিত হয়ে
                নিউক্লিয়াস বা প্রোটোপ্লাজমের সংযোগ ঘটায়। সেই যৌন জনন
                প্রক্রিয়াকে সংশ্লেষ বা কনজুগেশন বলে।
                <br />
                উদাহরণ : প্যারামেসিয়াম, স্পাইরোগাইরা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. অ্যাম্ফিমিক্সিস কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে যৌন জনন প্রক্রিয়ায় পুংগ্যামেট ও স্ত্রীগ্যামেট মিলিত হয়ে
                জাইগো সৃষ্টি করে তাকে অ্যাম্ফিমিক্সিস বলে।
                <br />
                উদাহরণ : আম, মানুষ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. ক্লোন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                অঙ্গজ জনন প্রক্রিয়ায় প্রাকৃতিক ভাবে বা কলাকর্ষণের মাধ্যমে
                কৃত্রিমভাবে যে জীবগুলি উৎপন্ন করা হয় তাদের জিনগত বৈশিষ্ট্য
                একইরকম থাকে। হুবহু একইরকম বৈশিষ্ট্যযুক্ত জীবগোষ্ঠীকে ক্লোন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. নিম্নলিখিত উদ্ভিদ ও প্রাণীর গ্যামেটে ও মিয়োসাইটে
                    ক্রোমোজোম সংখ্যা কত?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                কুকুর; পেঁয়াজ; মাছি।
                <br />
                গ্যামেটে ক্রোমোজোম সংখ্যা : কুকুর –78; পেঁয়াজ – 16; মাছি–12।
                <br />
                মিয়োসাইটে ক্রোমোজোম সংখ্যা : কুকুর - 39; পেঁয়াজ —৪; মাছি-6।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. জোড়কলম কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে পদ্ধতিতে একই প্রজাতিভুক্ত দুটি ভিন্ন উদ্ভিদের শাখা জোড়া
                লাগাে হয়, তাকে জোড়কলম বলে। এই ধরনের কলম উদ্যান-পালন বিদ্য
                ঘটানো হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. স্টক এবং সিয়ন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                জোড় কলম পদ্ধতিতে, মূলসহ যে গাছটিতে গুটি বাঁধা হয় তাকে স্টক এ
                যে গাছটির শাখা স্টকের সঙ্গে জোড়া লাগানো হয় তাকে সিয়ন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. যৌনতার অবক্ষয় বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ছত্রাকের ক্ষেত্রে অভিব্যক্তির সাথে সাথে উন্নত ছত্রাকে যৌন জনন
                প্রক্রিয়া ধীরে ধীরে সরলীকৃত এমনকি অবলুপ্ত হয়ে যায়, যাকে
                যৌনতার অবক্ষয় বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. বহিঃনিষেক প্রক্রিয়াকে কেন অনুন্নত বলা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                বহিঃনিষেক প্রক্রিয়ায় নিষিক্ত ডিম্বাণু - (i) মাতৃদেহ থেকে
                পুষ্টিলাভ করতে পারে না, <br />
                (ii) বাহ্যিক পরিবেশে অধিকাংশ জাইগোট বিনষ্ট হয়, তাই একে অনুন্নত
                প্রক্রিয়া বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. ছত্রাকের যৌন রেণু বহনকারী দেহকে কী বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ছত্রাকের যৌন রেণু বহনকারী অঙ্গকে ফলদেহ বলে। এই ফলদেহ (Fruit
                body) অ্যাসকোকার্প বা ব্যাসিডিওকার্প হতে পারে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>26. সিনগ্যামি কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে যৌন জননে দুটি ভিন্নধর্মী জননকোশ অর্থাৎ পুং ও স্ত্রী গ্যামেটের
                স্থায়ী মিলন ঘটে এবং নতুন অপত্য জীবের সৃষ্টি হয়, তাকে সিনগ্যামি
                বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. স্ব-নিষেক কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে সিনগ্যামি পদ্ধতিতে পুংগ্যামেট ও স্ত্রী গ্যামেট একই জনিতৃদের
                উৎপন্ন হয় এবং তাদের মিলন ঘটে, এক্ষেত্রে নিষেক একই দেহে ঘটায়,
                তাকে স্ব-নিষেক বলে। ফিতাকৃমির (Taenia) ক্ষেত্রে স্বনিষেকদের
                যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. ইতর-নিষেক কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                এই প্রকার নিষেক পদ্ধতিতে পুংগ্যামেট ও স্ত্রী গ্যামেট পৃথক পিতা,
                মাতার দেহে উৎপন্ন হয়, যার ফলে ইতর নিষেক সম্পন্ন। গিনিপিগ,
                মানুষ্যে ক্ষেত্রে ইতর নিষেক ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. অটোগ্যামি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                এক্ষেত্রে জীবদেহে একটি নিউক্লিয়াস পুনঃকরণ পদ্ধতিতে দুটি
                নিউক্লিয়াস গঠন করে। এই দুটি নিউক্লিয়াসের মিলনকে অটোগ্যামি বলে।
                <br />
                উদাহরণ : প্যারামেসিয়ামের (Paramoecium) ক্ষেত্রে দেখা যায়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

জীবেরজনন.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(জীবেরজনন);
