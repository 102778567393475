import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function PreviousyearQuestionpaper2022() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          Previous year Question paper 2022 | West Bengal Class 12 Guide | West
          Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="Previous year Question paper 2022 | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Previous year Question paper 2022
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b> ভারতের একটি উল্লেখযোগ্য কার্স্ট অঞ্চল হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কাশ্মীর উপত্যকা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বোরা গুহা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্লু পার্বত্য অঞ্চল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অজন্তা গুহা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ভৌত জলস্তরের নিচে অবস্থিত যে জলবাহী স্তর থেকে সব ঋতুতেই জল
                  পাওয়া যায়, তাকে বলে —{" "}
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভাদোস স্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাময়িক সম্পৃক্ত স্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কৈশিক স্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  স্থায়ী সম্পৃক্ত স্তর
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  অগ্ন্যুৎপাতের সময় ম্যাগমার সঙ্গে যে উত্তপ্ত জল বেরিয়ে আসে,
                  তাকে বলে —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সহজাত জল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  উৎস্যন্দ জল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভাদোস জল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আবহিক জল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অগ্ন্যুৎপাতজাত পদার্থ দ্বারা গঠিত মৃত্তিকা হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অক্সিসল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আল্টিসল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যালফিসল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অ্যান্ডিসল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>আন্তঃআঞ্চলিক মাটির একটি উদাহরণ হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  রেনজিনা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লোয়েস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কঙ্গালসার মাটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পলিমাটি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> প্রশমিত মাটির pH-এর মান হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>6
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  6.5
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>7
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  7.5
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মৌসুমী বায়ু হল এক প্রকারের —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্থানীয় বায়ু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সাময়িক বায়ু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নিয়ত বায়ু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনিয়মিত বায়ু
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>একটি মুক্ত ভাসমান জলজ উদ্ভিদের নাম হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হোগলা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পদ্ম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শালুক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  কচুরীপানা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নিরক্ষীয় জলবায়ু অঞ্চলের একটি বৈশিষ্ট্য হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শুষ্ক গ্রীষ্মকাল এবং আর্দ্র শীতকাল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বার্ষিক উষ্ণতার প্রসর বেশী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শীতকালে তুষারপাত হয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অপরাহ্নে পরিচলন বৃষ্টিপাত হয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বায়ুমন্ডলীয় গোলোযোগ যে স্তরে দেখা যায়, তার নাম —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ট্রপোস্ফিয়ার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্ট্র্যাটোস্ফিয়ার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মেসোস্ফিয়ার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আয়নোস্ফিয়ার
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#1f7405" size="5">
                <b>H.S QUESTION PAPER 2022</b>
              </font>
              <br />
              <font color="#3c92e5">
                <font color="#ed9c23" size="5">
                  <b>Geography</b>
                </font>
                <br />
                <font color="#ed9c23">(New Syllabus)</font>
                <br />
                <font color="#f11eda">
                  Total Time : 3 Hours 15 minutes
                  <br />
                  Total Marks : 70
                </font>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#1f7405" size="5">
                <b> PART - B (Marks - 35)</b>
              </font>
              <br />
              <br />

              <font color="#f11eda">
                <b>
                  1. প্রতিটি প্রশ্নের বিকল্প উত্তরগুলির মধ্যে থেকে সঠিক উত্তরটি
                  বেছে নিয়ে ডানদিকে নীচে প্রদত্ত বাক্সে লেখো :{" "}
                  <font color="#1f7405">
                    <b>1 × 21 = 21</b>
                  </font>
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                <font color="#f11eda">
                  <b>(i) ভারতের একটি উল্লেখযোগ্য কার্স্ট অঞ্চল হল —</b>
                </font>
                <br />
                (a) কাশ্মীর উপত্যকা
                <br />
                <font color="#1f7405">
                  <b>(b) বোরা গুহা</b>
                </font>
                <br />
                (c) ব্লু পার্বত্য অঞ্চল
                <br />
                (c) অজন্তা গুহা
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (ii) ভৌত জলস্তরের নিচে অবস্থিত যে জলবাহী স্তর থেকে সব ঋতুতেই
                    জল পাওয়া যায়, তাকে বলে —{" "}
                  </b>
                </font>
                <br />
                (a) ভাদোস স্তর
                <br />
                (b) সাময়িক সম্পৃক্ত স্তর
                <br />
                (c) কৈশিক স্তর
                <br />
                <font color="#1f7405">
                  <b>(d) স্থায়ী সম্পৃক্ত স্তর</b>
                </font>
                <br />
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (iii) অগ্ন্যুৎপাতের সময় ম্যাগমার সঙ্গে যে উত্তপ্ত জল বেরিয়ে
                    আসে, তাকে বলে —
                  </b>
                </font>
                <br />
                (a) সহজাত জল
                <br />
                <font color="#1f7405">
                  <b>(b) উৎস্যন্দ জল</b>
                </font>
                <br />
                (c) ভাদোস জল
                <br />
                (d) আবহিক জল
                <br />
                <br />
                <font color="#f11eda">
                  <b>(iv) অগ্ন্যুৎপাতজাত পদার্থ দ্বারা গঠিত মৃত্তিকা হল —</b>
                </font>
                <br />
                (a) অক্সিসল
                <br />
                (b) আল্টিসল
                <br />
                (c) অ্যালফিসল
                <br />
                <font color="#1f7405">
                  <b>(d) অ্যান্ডিসল</b>
                </font>
                <br />
                <br />
                <font color="#f11eda">
                  <b>(v) আন্তঃআঞ্চলিক মাটির একটি উদাহরণ হল —</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) রেনজিনা</b>
                </font>
                <br />
                (b) লোয়েস
                <br />
                (c) কঙ্গালসার মাটি
                <br />
                (d) পলিমাটি
                <br />
                <br />
                <font color="#f11eda">
                  <b>(vi) প্রশমিত মাটির pH-এর মান হল —</b>
                </font>
                <br />
                (a) 6.0
                <br />
                (b) 6.5
                <br />
                <font color="#1f7405">
                  <b>(c) 7.0</b>
                </font>
                <br />
                (d) 7.5
                <br />
                <br />
                <font color="#f11eda">
                  <b>(vii) মৌসুমী বায়ু হল এক প্রকারের —</b>
                </font>
                <br />
                (a) স্থানীয় বায়ু
                <br />
                <font color="#1f7405">
                  <b>(b) সাময়িক বায়ু</b>
                </font>
                <br />
                (c) নিয়ত বায়ু
                <br />
                (d) অনিয়মিত বায়ু
                <br />
                <br />
                <font color="#f11eda">
                  <b>(viii) একটি মুক্ত ভাসমান জলজ উদ্ভিদের নাম হল —</b>
                </font>
                <br />
                (a) হোগলা
                <br />
                (b) পদ্ম
                <br />
                (c) শালুক
                <br />
                <font color="#1f7405">
                  <b>(d) কচুরীপানা</b>
                </font>
                <br />
                <br />
                <font color="#f11eda">
                  <b>(ix) নিরক্ষীয় জলবায়ু অঞ্চলের একটি বৈশিষ্ট্য হল —</b>
                </font>
                <br />
                (a) শুষ্ক গ্রীষ্মকাল এবং আর্দ্র শীতকাল
                <br />
                (b) বার্ষিক উষ্ণতার প্রসর বেশী
                <br />
                (c) শীতকালে তুষারপাত হয়
                <br />
                <font color="#1f7405">
                  <b>(d) অপরাহ্নে পরিচলন বৃষ্টিপাত হয়</b>
                </font>
                <br />
                <br />
                <font color="#f11eda">
                  <b>(x) বায়ুমন্ডলীয় গোলোযোগ যে স্তরে দেখা যায়, তার নাম —</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) ট্রপোস্ফিয়ার</b>
                </font>
                <br />
                (b) স্ট্র্যাটোস্ফিয়ার
                <br />
                (c) মেসোস্ফিয়ার
                <br />
                (d) আয়নোস্ফিয়ার
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f11eda">
                <b>(xi) সর্বাধিক জীববৈচিত্র্যযুক্ত অঞ্চল হল —</b>
              </font>
              <br />
              <font color="#3c92e5">
                (a) নাতিশীতোষ্ণ অঞ্চল
                <br />
                <font color="#1f7405">
                  <b>(b) নিরক্ষীয় অঞ্চল</b>
                </font>
                <br />
                (c) সাভানা অঞ্চল
                <br />
                (d) মরু অঞ্চল
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xii) 'জীবজৈচিত্র্য' শব্দটি সর্বপ্রথম ব্যবহার করেন —</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) ওয়ালটার রোজেন</b>
                </font>
                <br />
                (b) নরম্যান মায়ারস
                <br />
                (c) চার্লস ডারউইন
                <br />
                (d) রবার্ট হুক
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xiii) পোমামকালচারের সাথে যুক্ত নয় এমন শস্য হল —</b>
                </font>
                <br />
                (a) কলা ও পেয়ারা
                <br />
                (b) আপেল ও আঙুর
                <br />
                <font color="#1f7405">
                  <b>(c) লাউ ও কুমড়ো</b>
                </font>
                <br />
                (d) পেঁপে ও লাজুবাদাম
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xiv) ভারতের 'শ্বেত বিপ্লব'-এর জনক হলেন —</b>
                </font>
                <br />
                (a) নরম্যান বোরলগ
                <br />
                (b) রোনালশ রস
                <br />
                (c) এম আস স্বামীনাথন
                <br />
                <font color="#1f7405">
                  <b>(d) ডঃ ভার্গিস কুরিয়েন</b>
                </font>
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (xv) চীনের যে প্রদেশটি 'চীনের ধানের আধার' নামে পরিচিত, তা হল
                    —
                  </b>
                </font>
                <br />
                (a) জেচুয়ান
                <br />
                (b) ইউনান
                <br />
                <font color="#1f7405">
                  <b>(c) হুনান</b>
                </font>
                <br />
                (d) হুবেই
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (xvi) কষ্টিক সোডা ও সোডা অ্যাশ কাঁচামাল হিসেবে ব্যবহৃত হয় —
                  </b>
                </font>
                <br />
                (a) পাট শিল্পে
                <br />
                (b) কার্পাস-বয়ন শিল্পে
                <br />
                <font color="#1f7405">
                  <b>(c) কাগজ শিল্পে</b>
                </font>
                <br />
                (d) লৌহ-ইস্পাত শিল্পে
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xvii) ভারতের প্রথম পাটকল স্থাপিত হয় -</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) রিষড়ায়</b>
                </font>
                <br />
                (b) ব্যান্ডেলে
                <br />
                (c) শ্রীরামপুরে <br />
                (d) টিটাগড়ে
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (xviii) 'শিল্প স্থানিকতার ন্যুনতম ব্যয় তত্ত্ব-টির প্রবর্তক
                    হলেন —
                  </b>
                </font>
                <br />
                (a) অগাস্ট লশ
                <br />
                <font color="#1f7405">
                  <b>(b) আলফ্রেড ওয়েবার</b>
                </font>
                <br />
                (c) জর্জ রেনার
                <br />
                (d) ই ডব্লিউ জিমারম্যান
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (xix) প্রায় যোগাযোগহীন বিচ্ছিন্ন ও ক্ষুদ্র গ্রামীণ বসতিকে
                    বলে —
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) হ্যামলেট</b>
                </font>
                <br />
                (b) গ্রাম
                <br />
                (c) মৌজা
                <br />
                (d) শহর
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (xx) একটি পুষ্করিনীকে কেন্দ্র করে যে বসতি গড়ে ওঠে, তা হল —
                  </b>
                </font>
                <br />
                (a) শুষ্ক-বিন্দু বসতি
                <br />
                <font color="#1f7405">
                  <b>(b) আর্দ্র-বিন্দু বসতি</b>
                </font>
                <br />
                (c) রৈখিক বসতি
                <br />
                (d) বর্গাকার বসতি
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (xxi) ভারতের নিম্নলিখিত কোন্‌ শহরগুলি 'সোনালী চতুর্ভুজ'
                    দ্বারা যুক্ত?
                  </b>
                </font>
                <br />
                (a) দিল্লী-মুম্বাই-কলকাতা-বেঙ্গালুরু
                <br />
                (b) দিল্লী-মুম্বাই-হায়দ্রাবাদ-কলকাতা
                <br />
                <font color="#1f7405">
                  <b>(c) দিল্লী-মুম্বাই-চেন্নাই-কলকাতা</b>
                </font>
                <br />
                (d) পোরবন্দর-দিল্লী-কলকাতা-চেন্নাই
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f11eda">
                <b>
                  2. নিম্নলিখিত প্রশ্নগুলির উত্তর দাও (বিকল্প প্রশ্নগুলি
                  লক্ষণীয়) :{" "}
                  <font color="#1f7405">
                    <b>1 × 14 = 14</b>
                  </font>
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                <font color="#f11eda">
                  <b>(i) 'আরোহণ প্রক্রিয়া' বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে বহির্জাত প্রক্রিয়ার মাধ্যমে ক্ষয়জাত পদার্থ ভূপৃষ্ঠে নিচে
                অংশে সঞ্চিত হয় এবং উচ্চতা বৃদ্ধি করে, তাকে আরোহন বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b> অথবা, হামস কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                চুনাপাথর গঠিত অঞ্চলে ক্ষয় কাজ প্রতিরোধ করে দাঁড়িয়ে থাকা ছোটো
                ছোটো টিলা গুলিকে হামস বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(ii) গীজার কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                Geyser শব্দ থেকে এসেছে গিজার। এর বাংলা প্রতিশব্দ হচ্ছে
                উষ্ণপ্রস্রবণ, যে উষ্ণপ্রসবণ থেকে মাঝে মাঝে জল বা বাষ্প
                স্তম্ভেউঠে আসে তাই গিজার।
                <br />
                <br />
                <font color="#f11eda">
                  <b> অথবা, পর্যায়ন-এর সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                নদী, হিমবাহ, বায়ু, সমুদ্রতরঙ্গ প্রভৃতি প্রাকৃতিক শক্তি ভূপৃষ্ঠে
                ভূমিরূপ তৈরি করার জন্য ক্ষয়, পরিবহন ও সঞ্চয় কাজের মধ্যে যে
                সামঞ্জস্য রক্ষা করে তাকে পর্যায়ন বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(iii) সোলাম বলতে কী বোঝায় ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                'Solum' একটি ল্যাটিন শব্দ যার অর্থ মাটি বা জমি। A এবং B স্তরে
                মৃত্তিকার গঠন এর সমস্ত প্রক্রিয়া সক্রিয় থাকে। যার মধ্যে উদ্ভিদ
                এবং প্রাণীর অস্তিত্ব লক্ষ্য করা যায়। এই নরম, শিথিল, হিউমাস
                সমৃদ্ধ স্তরকে সোলাম(Solum) বলে। এই স্তর সবচেয়ে উর্বর।
                <br />
                <br />
                <font color="#f11eda">
                  <b> অথবা, ল্যাটারাইজেশান বলতে কী বোঝায় ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে প্রক্রিয়ায় মৃত্তিকার উপরিস্তর থেকে ক্যালশিয়াম,
                ম্যাগনেশিয়াম প্রভৃতি অপসারিত হয় এবং মৃত্তিকা মধ্যস্থিত লোহা ও
                অ্যালুমিনিয়াম অক্সাইড থেকে যায়, এইভাবে ল্যাটেরাইট মৃত্তিকা
                সৃষ্টির পদ্ধতিকে লেটারাইজেশন বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(iv) রেগোলিথ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                আবহবিকারের ফলে শিলা ক্রমাগত চূর্ণবিচূর্ণ হয়ে শেষে
                ক্ষুদ্রাতিক্ষুদ্র কণায় পরিণত হয় এবং ভূমির ওপর এক শিথিল, কোমল
                বস্তুকণার আবরণ সৃষ্টি করে, যাকে বলে রেগোলিথ বা ভূ আস্তরণ।
                <br />
                <br />
                <font color="#f11eda">
                  <b> অথবা, শল্কমোচন বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                শিলা তাপের কুপরিবাহী হওয়ায় শিলাস্তরের বাইরের অংশ দিনের বেলা
                প্রবল উষ্ণতায় প্রসারিত এবং রাতের বেলায় সংকুচিত হয়। বাইরের অংশ
                ক্রমাগত সম্প্রসারণের কারণে শিলার স্তরগুলি পেঁয়াজের খোসার মত
                খুলে যায়। পরবর্তী কালে বায়ুপ্রবাহে খুলে যাওয়া অংশগুলি অপসারিত
                হয়ে গোলাকৃতি আকার ধারন করে। একেই শল্কমোচন বলা হয়।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(v) ঘূর্ণবাতের চক্ষু কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                শক্তিশালী ঘূর্ণবাতের কেন্দ্রে এক গতিহীন, শান্ত প্রায় মেঘশূন্য
                অবস্থা বিরাজ করে। একে ঘূর্ণবাতের চক্ষু বা ঝড়ের চক্ষু বলে। এর
                ব্যাস প্রায় 10 কিমি থেকে 20 কিমি বিস্তৃত।
                <br />
                <br />
                <font color="#f11eda">
                  <b>অথবা, মৌসুমী বিস্ফোরণ বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                গ্রীষ্মের অসহ্য গরমের মধ্যে আর্দ্র দক্ষিণ-পশ্চিম মৌসুমি বায়ু
                আকস্মিকভাবেই ভারতে প্রবেশ করে। আকাশ মেঘে ঢাকা পড়ে , শুরু হয়ে
                যায় বজ্রবিদ্যুৎ সহ প্রবল বর্ষণ। এইভাবে , হঠাৎই বজ্রবিদ্যুৎসহ
                প্রবল বৃষ্টিপাতের মাধ্যমে ভারতে যে বর্ষাকালের সূচনা ঘটে , তাকে
                মৌসুমি বিস্ফোরণ ( burst of monsoon ) নামে আখ্যায়িত করা হয়।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(vi) ITCZ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ITCZ - এর পূর্ণরূপ হলো: Intertropical Convergence Zone. এই ITCZ
                বলতে বোঝায় নিরক্ষীয় সেই অঞ্চলকেই, যেখানে উত্তর -পূর্ব এবং দক্ষিণ
                - পূর্ব আয়ন বায়ু মিলিত হয়।ITCZ - এর উত্তর - পূর্ব ও দক্ষিণ -
                পূর্ব আয়ন বায়ুর মিলিত নিরক্ষীয় অঞ্চলের সেই স্থান, যেখানে আবহাওয়া
                নিশ্চল থাকে।
                <br />
                <br />
                নিরক্ষীয় এই শান্ত বলয় অঞ্চলে আয়ন বায়ুদ্বয় ( উত্তর-পূর্ব এবং
                দক্ষিণ-পূর্ব) এককেন্দ্রমুখী হয়ে সরু সূতার মতো স্থানে নিশ্চল, অনড়
                বায়ুপ্রবাহহীন সৃষ্টি করে থাকে। একে নাবিকরা খর্বাকৃতি বা শান্ত
                নিশ্চল আবহাওয়া বলে থাকে।
                <br />
                <br />
                <font color="#f11eda">
                  <b> অথবা, জিওস্ট্রাফিক বায়ু কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ঊর্দ্ধাকাশে সমচাপরেখার সাথে সমান্তরালভাবে বায়ু প্রবাহিত হয়।
                এইপ্রকার বায়ুপ্রবাহ জিওস্ট্রফিক বায়ু (Geostrophic Wind) নামে
                পরিচিত।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(vii) বিশ্ব উষ্ণায়ণের দুটি প্রভাব উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                (১) মেরু অঞ্চলের বরফের গলন ও পার্বত্য অঞ্চলে হিমবাহের গলনের ফলে
                মেরু অঞ্চলের বরফের স্তর ও পার্বত্য অঞ্চলের হিমবাহের গলন ঘটবে ও
                সমুদ্র জলতলের উচ্চতা বৃদ্ধি পেয়ে সমুদ্র উপকূলভাগের নীচু অংশ
                জলমগ্ন হবে, সেই অঞ্চলের মাটি লবণাক্ত ও অনুর্বর হয়ে কৃষি উৎপাদন
                ব্যাহত হবে এবং সমগ্র সামুদ্রিক বাস্তুতন্ত্র বিঘ্নিত হবে।
                <br />
                <br />
                (২) শস্য উৎপাদনের বৈষম্য : বিশ্ব উষ্ণায়নের ফলে বৃষ্টিপাতের
                প্রকৃতির পরিবর্তন ঘটবে, বৃষ্টিপাতের বন্টনেও চরম অসাম্য দেখা
                দেবে। ফলে কৃষিজমির পরিমাণ হ্রাস পাবে । অতিবৃষ্টি বা অনাবৃষ্টি
                দীর্ঘায়িত হয়ে কৃষিজ ফসল উৎপাদনে ব্যাঘাত সৃষ্টি করবে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    {" "}
                    অথবা, আলোকপ্রেমী ও আলোকবিদ্বেষী উদ্ভিদের একটি করে উদাহরণ
                    দাও।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে-সব উদ্ভিদ আলোকের উপস্থিতিতে সবচেয়ে ভালো জন্মায়, তাদের আলোক
                প্রেমী উদ্ভিদ বলে। যেমন - জবা, বেল, সূর্যমুখী, নয়নতারা ইত্যাদি
                উদ্ভিদ।
                <br />
                <br />
                যে-সব উদ্ভিদ আলোকের অনুপুস্থিতিতে অর্থাৎ ছায়ায় ভালো জন্মায়
                তাদের আলোক বিদ্বেষী উদ্ভিদ বলে। যেমন - পান, অর্কিড, মানিপ্লান্ট
                ইত্যাদি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f11eda">
                <b>
                  (viii) ভারতের জীববৈচিত্র্যের উষ্মা অঞ্চলের দুটি উদাহরণ দাও।
                </b>
              </font>
              <br />
              <font color="#3c92e5">
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পূর্ব হিমালয় অঞ্চল ও পশ্চিমঘাট পার্বত্য অঞ্চল। <br />
                <br />
                <font color="#f11eda">
                  <b> অথবা, অন্তঃক্ষেত্রীয় সংরক্ষণ কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                বিপন্ন প্রজাতিগুলিকে নিজ আবাসস্থলে রক্ষা করার পদ্ধতিকে
                জীববৈচিত্র্যের স্বস্থানিক বা অন্তঃক্ষেত্রীয় সংরক্ষণ বলা হয়।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(ix) জীববৈচিত্র্য বিনাশের দুটি কারণ উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                (১) জীববৈচিত্র্য বিলুপ্তির প্রাকৃতিক কারণসমূহ : জলবায়ুর
                পরিবর্তন, অগ্ন্যুৎপাত।
                <br />
                <br />
                (২) জীববৈচিত্র্য বিলুপ্তির মনুষ্যসৃষ্ট কারণসমূহ : আবাসস্থলের
                সংকোচন, পরিকল্পনাবিহীন উন্নয়ন।
                <br />
                <br />
                <font color="#f11eda">
                  <b> অথবা, জীববৈচিত্র্য-এর সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                জীববৈচিত্র্য হল পৃথিবীতে জীবনের জৈবিক বৈচিত্র্য এবং
                পরিবর্তনশীলতা। অধ্যাপক হ্যামিল্টনের মতে, পৃথিবীর মাটি, জল ও
                বায়ুতে বসবাসকারী সব উদ্ভিদ, প্রাণী ও অনুজীবদের মধ্যে যে জিনগত,
                প্রজাতিগত ও পরিবেশগত (বাস্তুতান্ত্রিক) বৈচিত্র্য দেখা যায় তাকেই
                জীববৈচিত্র্য বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(x) ট্রাক ফার্মিং বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                বাজার বাগান কৃষিকে ট্রাক ফার্মিং বলা হয়। এটি একধরনের প্রগাঢ়
                কৃষি ব্যবস্থা। যেখানে নিকটবর্তী শহরকে কেন্দ্র করে শহরবাসীর
                দৈনন্দিন প্রয়োজনীয় শাকসবজি, ফল, ফুল ইত্যাদির চাষ হয়। শহরের
                অভ্যন্তরে এই সব পন্য কে সহজেই সড়ক পথে ট্রাক পরিবহন মারফৎ দ্রুত
                পাঠানো হয়।
                <br />
                <br />
                <font color="#f11eda">
                  <b> অথবা, ছেদন ও দহন কৃষি বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ছেদন ও দহন কৃষি হল এক ধরনের কৃষি যেখানে ফসলের পথ তৈরি করতে বন
                পুড়িয়ে ফেলা হয় এবং পরিষ্কার করা হয়। ছেদন ও দহন কৃষি আদিম
                জীবিকা কৃষি।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xi) শস্য সমন্বয়-এর সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                জে. সি. উইভার-এর মতে (1954) শস্য সমন্বয় বলতে বােঝায়কোনাে একটি
                অঞ্চলের কোনা একটি শস্য কতটুকু স্থান দখল করে আছে তার শতাংশ
                নির্ণয় করার মাধ্যমে ভূমির শ্রেণীবিভাগের পদ্ধতিকেই বলা হয় শস্য
                সমন্বয় বা শস্য সংযুক্তিকরণ।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xii) অনুসারী শিল্প কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                বৃহদায়তন মূল শিল্পের প্রয়োজনে মূল শিল্পের পাশে যে অসংখ্য ছোটো
                ছোট শিল্প গড়ে ওঠে, সেই প্রথমটিকে কেন্দ্রীয় শিল্প ও তার ওপর
                ভিত্তি করে গড়ে ওঠা অন্যান্য শিল্পগুলোকে অনুসারী শিল্প বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b> অথবা, ব্রেক অফ বাল্ক পয়েন্ট কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পণ্যসামগ্রী এক স্থান থেকে অন্য স্থানে নিয়ে যেতে ভূ-প্রাকৃতিক
                বৈচিত্র‍্য এবং পরিবহন মাধ্যমগুলির ভিন্নতার কারণে বা পরিবহনের
                সুবিধার কারণে পণ্যদ্রব্যকে ছোট ছোট ভাগে ভাগ করে বন্টন করার জন্য
                যে স্থানে পণ্যসামগ্রীর পরিবহনের মাধ্যম পরিবর্তন করা হয়, তাকে
                ব্রেক অফ বাল্ক বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xiii) শুন্য জনসংখ্যা বৃদ্ধি বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে সব দেশের জন্ম ও মৃত্যু হার প্রায় সমান, সেই সব দেশে জনসংখ্যার
                বৃদ্ধি প্রায় হয় না বললেই চলে, একেই শূন্য জনসংখ্যা বৃদ্ধি বলে।
                যেমন – জার্মানি, জাপান, সুইডেন, বেলজিয়াম প্রভৃতি দেশ।
                <br />
                <br />
                <font color="#f11eda">
                  <b> অথবা, অভ্যন্তরীণ পরিব্রাজনের দুটি কারণ উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                অর্থনৈতিক কারণ : অর্থনৈতিক স্বচ্ছলতার অভাব, বেকারত্ব এবং স্বচ্ছল
                জীবনযাপনের হাতছানির মানুষকে সর্বদা পরিব্রাজনে বাধ্য ও উৎসাহিত
                করেছে। উদাহরণ - বাংলা, বিহার ও ওড়িশার গরীব কৃষি-মজুরদের শহরে
                পরিব্রাজন।
                <br />
                <br />
                সামাজিক ও ধর্মীয় কারণ : মানুষ সবসময় তার নিজ জাতি, সমাজ ও
                ধর্মীয় গোষ্ঠীর মধ্যে থাকতে, কাজ করতে ও জীবনকে উপভোগ করতে পছন্দ
                করে। যা পরিব্রাজনের একটি অন্যতম কারণ।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xiv) মেগাসিটি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে সকল সহরে জনসংখ্যা ১০ মিলিয়ন বা ১ কোটির বেশী , সেগুলোকে মেগা
                সিটি হিসাবে আখ্যায়িত করা হয়।যেমন — জাপানের টোকিয়ো।
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    {" "}
                    অথবা, উদাহরনসহ ভারতের একটি দ্বৈত-নগর বা যমজ-নগর -এর সংজ্ঞা
                    দাও।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পাশাপাশি অবস্থিত দুটি মূল নগর একত্রে মিলিত হলে তাকে দ্বৈত বা যমজ
                শহর বলে। উদাহরণ — হায়দ্রাবাদ-সেকেন্দ্রাবাদ, কলকাতা-হাওড়া
                প্রভৃতি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f11eda">
                <b>
                  1. নিম্নলিখিত প্রশ্নগুলির উত্তর দাও (বিকল্প প্রশ্নগুলি
                  লক্ষণীয়) :{" "}
                  <font color="#1f7405">
                    <b>7 × 5 = 35</b>
                  </font>
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                (a) কার্স্ট ভূমিরূপের পরিপূর্ণ বিকাশের জন্য প্রয়োজনীয় শর্তাবলী
                সংক্ষেপে আলোচনা করো। 'নগ্নীভবন' বলতে কী বোঝায়? 'অন্ধ উপত্যকা' ও
                'শুষ্ক উপত্যকা'র সংজ্ঞা দাও।
                <br />
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                মৃত্তিকা সংরক্ষণের বিভিন্ন পদ্ধতিগুলি সংক্ষেপে আলোচনা করো।
                'এলুভিয়েশন' বলতে কী বোঝায়?
                <br />
                <br />
                (b) জীববৈচিত্র্যের 'জিনগত বৈচিত্র্য' ও প্রজাতিগত বৈচিত্র্য'
                সম্বন্ধে সংক্ষিপ্ত টীকা লেখো। সংক্ষেপে জীববৈচিত্র্যের গুরুত্ব
                উল্লেখ করো।
                <br />
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                উপযুক্ত চিত্রসহ নাতিশীতোষ্ণ ঘূর্ণবাত-এর জীবনচক্র ব্যাখ্যা করো।
                'শারীরবৃত্তীয় শুষ্ক মৃত্তিকা' বলতে কী বোঝায়?
                <br />
                <br />
                (c) ভূমধ্যসাগরীয় জলবায়ু অঞ্চল উৎপাদনে বিখ্যাত কেন ? নিবিড়
                জীবিকাসত্তাভিত্তিক কৃষির ক্ষেত্রে মাথাপিছু উৎপাদন কম কেন?
                'শস্যাবর্তন'-এর দুটি গুরুত্বপূর্ণ বৈশিষ্ট্য উল্লেখ করো।
                <br />
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                ভারতীয় কৃষিতে সবুজ বিপ্লবের তিনটি কুফল আলোচনা করো। ইক্ষু
                উৎপাদনের অনুকূল ভৌগোলিক পরিবেশ সম্পর্কে সংক্ষেপে লেখো।
                <br />
                <br />
                (d) ভারতের পূর্ব-মধ্যাঞ্চলে লৌহ-ইস্পাত শিল্পের কেন্দ্রীভবনের
                কারণগুলি ব্যাখ্যা করো। কার্পাস-বয়ন শিল্পকে 'শিকড়-আলগা শিল্প' বলে
                কেন?
                <br />
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                ব্রাজিলে রবার শিল্প গড়ে ওঠার সমস্যাগুলি আলোচনা করো। ভারতে
                'তৈরী-পোশাক শিল্প' উন্নতিলাভ করেছে কেন? 'দ্রব্য সূচক' কী?
                <br />
                <br />
                (e) 'জনাকীর্ণতা' ও 'জনস্বল্পতা'র সংজ্ঞা দাও। ভারতের নগরায়ণের
                সমস্যাগুলি সংক্ষেপে আলোচনা করো।
                <br />
                <br />
                <font color="#1f7405" size="3">
                  <b>অথবা,</b>
                </font>
                'জনঘনত্ব' ও 'মানুষ-জমি অনুপাত'-এর মধ্যে পার্থক্য নিরূপণ করো।
                'বয়ঃলিঙ্গ অনুপাত'-এর গুরুত্ব উল্লেখ করো। কর্মধারার ভিত্তিতে
                ভারতের উদাহরণসহ যে কোনো তিন ধরনের শহরের নাম উল্লেখ করো।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

PreviousyearQuestionpaper2022.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(PreviousyearQuestionpaper2022);
