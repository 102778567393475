import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function অভিব্যক্তিরকৌশল() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          অভিব্যক্তির কৌশল | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="অভিব্যক্তির কৌশল | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                অভিব্যক্তির কৌশল
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>জাভা এপ মানুষ কে আবিষ্কার করেন?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্লাক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লিকি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ডুবয়েস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কুভিয়ের
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>জিন প্রবাহ জেনেটিক ড্রিফটের -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  একই দিকে কাজ করে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বিপরীত কাজ করে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>a ও b
                  উভয়ই
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ইন্ডাস্ট্রিয়াল মেলানিজম হল একপ্রকারের —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অভিমুখী নির্বাচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্থিতিকারী নির্বাচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভাঙনমূলক নির্বাচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সবগুলি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মানুষের ফোরামেন ম্যাগনাম হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অঙ্কীয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পৃষ্ঠীয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অগ্রবর্তী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পার্শ্বীয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Darwin's finches যেটির আদর্শ উদাহরণ, সেটি হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সংযোগকারী যোগসূত্র
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অ্যাডাপটিভ রেডিয়েশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অভিসারী বিবর্তন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইন্ডাস্ট্রিয়াল মেলানিজম্
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Homo habilis-এর 'habilis' কথাটির অর্থ হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রাচীন মানুষ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  যন্ত্র প্রস্তুতকারক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আধুনিক মানুষ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যাযাবর মানুষ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  অ্যান্টিবায়োটিক প্রতিরোধী ব্যাকটেরিয়ার আবির্ভাব নির্দেশ করে
                  -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ট্রান্সডাকশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অভিযোজিত বিকিরণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অপসারী বিবর্তন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পপুলেশনের মধ্যে পূর্বের প্রকরণের উপস্থিতি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কোন্ যুগে Homo erectus-এর আবির্ভাব হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিওসিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অলিগোসিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্লিওসিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  প্লিস্টোসিন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মানুষের দেহকোশে মোট ক্রোমোজোম সংখ্যা -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  43
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  46
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  47
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  23
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অস্ট্রালোপিথেকাস-এর জীবাশ্ম দেখা যায় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আফ্রিকা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আমেরিকা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দঃ পূঃ এশিয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অস্ট্রেলিয়া
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#1f7405">
                <b>অষ্টম অধ্যায় - অভিব্যক্তির কৌশল</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. পিকিং মানুষ কোন্ প্রজাতির অন্তর্গত জীবাশ্ম?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                Homo erectus
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. Ramapithecus জীবাশ্মটি প্রথম কোথায় পাওয়া গেছিল?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                শিবালিক পাহাড়ে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. আধুনিক মানুষের উদ্ভব হয়েছিল কত বছর আগে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                আনুমানিক 10,000 বছর আগে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. ট্রিপ্লয়েড প্রাণীর একটি উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                স্যালামান্ডার
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. মানুষের কোশে ক্রোমোজোম সংখ্যা কত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                46
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. মানুষের 21নং ক্রোমোজোমে ট্রাইজোমির জন্য কী হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ডাউন সিন্ড্রোম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. বিভেদ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                একই প্রজাতিভুক্ত জীবেদের আকার, গঠন ও ব্যবহারগত পার্থক্য হল
                বিভেদ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. অভিযোজন বিচ্ছুরণের সূত্র কে উপস্থাপন করেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                অভিযোজন বিচ্ছুরণের সূত্র উপস্থাপন করেন বিজ্ঞানী Henry Fairfiele
                Osborn।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. মিউট্যান্ট কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                মিউটেশনের ফলে উদ্ভূত জীবকে বলে মিউট্যান্ট।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. মানুষের বিবর্তনে সবচেয়ে আদিম জীবাশ্ম কোন্‌টি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                অস্ট্রালোপিথেকাস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    11. মানুষের বিবর্তনে কোন্ Homo গণটি প্রথম উদ্ভূত হয়েছিল?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                Homo habilis <br />
                <br />
                <font color="#f626d7">
                  <b>12. মানুষের বিবর্তনে উদ্ভূত প্রথম মানুষ কোন্‌টি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                Homo erectus <br />
                <br />
                <font color="#f626d7">
                  <b>13. জাভা মানুষ কোন্ প্রজাতির অন্তর্গত জীবাশ্ম?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                Homo erectus
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. মানুষ কোন্ গোত্রের প্রাণী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                হোমিনিডি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. রানাপিথেকাসের জন্ম হয়েছিল কবে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                সম্ভবত 15 মিলিয়ন বছর আগে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. The Descent of Man' কার লেখা?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                চার্লস ডারউইন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. পিকিং মানুষের জীবাশ্ম উদ্ধার হয় কোথা থেকে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                চিন থেকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. এপ কীভাবে হাঁটে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                চারটি পা দিয়ে হাঁটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. এপ-এর ছেদক দাঁত কীরকম?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                লম্বাটে এবং বেরিয়ে থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. এপ-এর মস্তিষ্ক গহ্বরের আয়তন কত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                460-600 cc
                <br />
                <br />
                <font color="#f626d7">
                  <b>21. মানুষের 13নং ক্রোমোজোমে ট্রাইজোমির জন্য কী হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পাতাউ সিন্ড্রোম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. মানুষের 18নং ক্রোমোজোমে ট্রাইজোমির জন্য কী হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                এডওয়ার্ড সিন্ড্রোম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. নালিজোমি অবস্থাকে কীভাবে চিহ্নিত করা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                2n - 2<br />
                <br />
                <font color="#f626d7">
                  <b>24. ট্রাইজোমি অবস্থাকে কীভাবে চিহ্নিত করা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                2n + 1<br />
                <br />
                <font color="#f626d7">
                  <b>25. মনোজোমি অবস্থাকে কীভাবে চিহ্নিত করে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                2n - 2<br />
                <br />
                <font color="#f626d7">
                  <b>26. দ্বৈত মনোজোমি অবস্থাকে কীভাবে চিহ্নিত করা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                2n - 1 - 1<br />
                <br />
                <font color="#f626d7">
                  <b>27. টার্নার সিন্ড্রোম স্ত্রীলোকের ক্রোমোজোম সংখ্যা কত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                45
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. টার্নার সিন্ড্রোমকে কীভাবে চিহ্নিত করা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                45, XO
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. এডওয়ার্ড সিন্ড্রোমে শিশুর কী অবস্থা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                শিশু বিকলাঙ্গ হয়ে পড়ে ও মারা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. রেসিপ্রোকাল ট্রান্সলোকেশন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                এক্ষেত্রে দুটি অসমসংস্ক ক্রোমোজোমের মধ্যে অংশ বিনিময় ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>31. জেনেটিক ড্রিফ্ট মতবাদ কে বর্ণনা করেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                সিওয়াল রাইট (Sewall Wright) ।<br />
                <br />
                <font color="#f626d7">
                  <b>32. জিন পুল কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                কোনো এক প্রজাতির ভিতর যত জিন থাকে তাকে বলে ওই প্রজাতির জিন পুল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    33. স্তন্যপায়ীদের মধ্যে ওড়ার জন্য অভিযোজন ঘটেছে কোন
                    প্রাণীর?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                বাদুড়ের।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. জলবাসী স্তন্যপায়ী প্রাণীর একটি উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                সিল মাছ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. জলবাসী স্তন্যপায়ী প্রাণীর একটি উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                সিল মাছ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>36. মানুষের উপপর্ব কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ভার্টিব্রাটা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. মানুষের বিজ্ঞানসম্মত নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Homo sapiens sapiens
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. কনটিনিউয়াস ভেরিয়েশন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে সকল চরিত্রের প্রকাশকে গুণগত মান দ্বারা নির্দেশ করা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. মিউটেশন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                জীবের মধ্যে সঞ্চারণযোগ্য হঠাৎ জেগে ওঠা স্থায়ী কোনো পরিবর্তন হল
                মিউটেশন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>40. একটি বেস অ্যানালগের নাম করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                5 BU
                <br />
                <br />
                <font color="#f626d7">
                  <b>41. অস্ট্রালোপিথেকাসের একটি প্রজাতির নাম করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Australopithecus africanus
                <br />
                <br />
                <font color="#f626d7">
                  <b>42. পিকিং মানুষের মস্তিষ্কের আয়তন কত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                প্রায় 850-1200 cc
                <br />
                <br />
                <font color="#f626d7">
                  <b>43. প্রজননগত বিচ্ছিন্নতা বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে পদ্ধতিতে পপুলেশনে জীবগোষ্ঠী ক্ষুদ্র ক্ষুদ্র গোষ্ঠীতে ভাগ হয়ে
                অবাধ মিলনে বাধাপ্রাপ্ত হয় তাকে প্রজননগত বিচ্ছিন্নতা বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. সাবস্ট্যানটিভ ভেরিয়েশন কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                গুণগত মানের ভিত্তিতে যে সকল চরিত্র বিচার্য হয় তাদের বলে
                সাবস্ট্যানটিভ চরিত্র। যখন এমন বৈশিষ্ট্যের মধ্যে বিভেদ সৃষ্টি
                হয়, তখন তাকে সাবস্ট্যানটিভ ভেরিয়েশন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. জেনেটিক ড্রিফ্ট কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                একটি বৃহৎ পপুলেশন থেকে ব্যক্তি বিশেষের ছোটো একটি গ্রুপ হয় পৃথক
                হয় তখন জিন ফ্রিকোয়েন্সির পরিবর্তন ঘটে, একে জেনেটিক বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. অ্যাডাপটিভ রেডিয়েশন বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                একই গোত্রের প্রাণীরা বিভিন্ন পরিবেশে থাকার দরুন ও বিভি অভিযোজনের
                তাগিদে আকৃতিগতভাবে বিচিত্র হয়ে পড়ে। একেই হয় অভিযোজিত বিকিরণ
                বা অ্যাডাপটিভ রেডিয়েশন বলি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. ইউপ্লয়ডি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ক্রোমোজোম সংখ্যা জিনোম সেটের গুণিতক আকারে বৃদ্ধি পেলে তাকে
                ইউপ্লয়ডি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. অ্যানিউপ্লয়ডি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                কোশস্থ সমসংস্থ ক্রোমোজোম সংখ্যার হ্রাস বা ক্রোমোজোমের সংখ্যা
                বৃদ্ধির ঘটনাকে অ্যানিউপ্লয়ডি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. পলিপ্লয়ডি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ক্রোমোজোম সেট সংখ্যার পরিবর্তন ঘটলে যদি জীবের চরিত্রগত পরিবর্তন
                আসে তখন তাকে পলিপ্রয়ডি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. ডিলিশন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যদি ক্রোমোজোমের কোনো অংশ বিচ্যুত হয় এবং আকারে পরিবর্তন ঘটে তাকে
                ডিলিশন বলে। উদা—মানুষের ক্রাই-ডু-চ্যাট সিন্ড্রোম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. ট্রান্সলোকেশন বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                কোনো ক্রোমোজোমের কোনো অংশ সমসংস্থ ক্রোমোজোম ছাড়া অন্য
                ক্রোমোজোমে স্থানান্তরিত হয় তাকে ট্রান্সলোকেশন বলে। এতে
                ক্রোমোজোমের বৃদ্ধি বা হ্রাস ঘটে। উদা — মানুষের ডাউন সিন্ড্রোমে
                15 ও 21 নং ক্রোমোজোমের মধ্যে ট্রান্সলোকেশন ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. ট্রানজিশন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পিরিমিডিন বেস (T, C) অপর পিরিমিডিন দিয়ে, আবার পিউরিন বেস (A, G)
                অপর পিউরিন বেস দিয়ে প্রতিস্থাপনকে বলে ট্রানজিশন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. ট্রান্সভারশন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                একটি পিউরিন যদি একটি পিরিমিডিন দ্বারা প্রতিস্থাপিত হয় বা একটি
                পিরিমিডিন একটি পিউরিন দ্বারা প্রতিস্থাপিত হয় তখন তাকে
                ট্রান্সভারশন বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. ডুপ্লিকেশন কী? </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ক্রোমোজোমে কোনো অংশ বা তার থেকে বেশি উপস্থিত থাকলে ক্রোমোজোমে যে
                পরিবর্তন সংঘটিত হয় তাকে ডুপ্লিকেশন বলে। এই ধরণের মিউটেশনের ফলে
                ক্রোমোজোম দৈর্ঘ্য বৃদ্ধি পায় এবং জিনেরও পরিমাণ বৃদ্ধি পায়।
                উদা—ড্রসোফিলার বার চোখ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. ইনভারশন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                কোনো ক্রোমোজোমের বাহক অংশ একই স্থানে 1800 কোণে ঘুরে যখন বিপরীত
                সজ্জাক্রমে অবস্থান করে তখন তাকে ইনভারশন বলে। উদা—Drosophila
                মাছির ক্ষেত্রে CLB মিউটেশন যুক্ত বৈশিষ্ট্যের সৃষ্টি হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. জিন ফ্লো কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                গ্যামেটের/জাইগোটের স্থানান্তরের ফলে দুটি পপুলেশনের মধ্যে জেলার
                ফ্যাক্টরের বিনিময়কে জিন ফ্লো বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. হার্ডি-উইনবার্গ ভারসাম্য তত্ত্বের গুরুত্ব লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                (i) হার্ডি-উইনবার্গ ভারসাম্য তত্ত্বের দ্বারা জেনেটিস্টরা
                নির্ধারণ ক বিবর্তন ঘটবে কিনা। (ii) এই তত্ত্বের সাহায্যে তারা
                নির্ণয় করেন হেয় অ্যালিলের বর্তমান ও ভবিষ্যৎ ফ্রিকোয়েন্সি।
                (iii) পর্যবেক্ষিত ফ্রিকোয়েন্সি এবং হার্ডি-উইনবার্গ ভারসাম্য
                তত্ত্বের দ্বারা নির্ধারিত ফ্রিকোয়েন্সি পার্থক্য দ্বারা
                বিবর্তনের মাত্রা নির্ণয় করা সম্ভব হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. প্রাকৃতিক নির্বাচন কত রকমের হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                প্রাকৃতিক নির্বাচন তিন রকমের, যথা- (i) স্টেবিলাইজিং সিলেকশন,
                (ii) ডাইরেকশনাল সিলেকশন এবং (iii) ডিসরাপটিভ সিলেকশন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. বনমানুষ ও মানুষের মধ্যে কী পার্থক্য দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                বনমানুষের পা-হাতের তুলনায় ছোটো, তাই তারা চার পায়ে দৌড়ায়।
                মানুষের পা-হাতের তুলনায় বড়ো, তাই তারা দু-পায়ে দৌড়োতে পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. ক্রো-ম্যাগনন মানুষ কাদের বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                প্রায় 30,000 বছর আগে নিয়ানডারথাল মানুষের পরে, ক্রোম্যাগনন
                মানুষের আবির্ভাব ঘটেছিল। আধুনিক মানুষ ক্রোম্যাগনন মানুষেরই
                সমতুল্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. আধুনিক মানুষের পূর্বপুরুষদের নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                আধুনিক মানুষের পূর্বপুরুষদের যে পরিচয় পাওয়া যায়, প্রথম থেকে
                শেষ পর্যন্ত তাদের নাম হল : ড্রায়োপিথেকাস → রামাপিথেকাস →
                অস্ট্রালোপিথেকাস → পিথেক্যানথ্রোপাস নিয়ানডারথাল → ক্রোম্যাগনন →
                মানুষ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. মেরিস্টিক ভেরিয়েশন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                সংখ্যার ভিত্তিতে যখন দেহের কোনো অংশ বা অঙ্গ-চারিত্রিক বৈশিষ্ট্য
                হিসেবে বিবেচিত হয় তখন তাকে মেরিস্টিক চরিত্র বলা হয়। এমন
                বৈশিষ্ট্যের মধ্যে বিভেদ দেখা দিলে তাকে মেরিস্টিক ভেরিয়েশন বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>20. আবিষ্ট মিউটেশন কাকে বলে? </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>আবিষ্ট মিউটেশন : </b>
              </font>
              <font color="#3c92e5">
                কৃত্রিম পদ্ধতিতে ভৌত বা রাসায়নিক পদার্থের প্রভাবে যে মিউটেশন
                ঘটে তাকে আবিষ্ট মিউটেশন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>21. সিলেকশন-কো-এফিসিয়েন্ট বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>সিলেকশন-কো-এফিসিয়েন্ট : </b>
                </font>
                একটি পপুলেশনের পরবর্তী প্রজন্মে কোনও নির্দিষ্ট জিনোটাইপের
                আপেক্ষিক প্রভাবক কমাতে প্রাকৃতিক নির্বাচন যে পরিমাণ কার্যক্ষমতা
                প্রদর্শন করে, তাকে 'S' অক্ষরের মাধ্যমে সিলেকশন কো-ফিশিয়েন্ট বলা
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. মিউটেশন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>মিউটেশন : </b>
                </font>{" "}
                জীবদেহের ক্রোমোজোম বা জিন সংঘটিত যে আকস্মিক, স্থায়ী
                বংশানুক্রমিকভাবে সঞ্চারণশীল পরিবর্তনের ফলে জীবের ফিনোটাইপের
                পরিবর্তন ঘটে তাকে পরিব্যক্তি বা মিউটেশন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. ভিজিবল মিউটেশন বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>ভিজিবল মিউটেশন : </b>
                </font>{" "}
                যে মিউটেশনের ফলে জীবের ফিনোটাইপের দৃশ্যমান পরিবর্তন ঘটে, তাকে
                ভিজিল মিউটেনশ বলে। যেমন— Drosophila মাছির লাল চোখের মিউটেশনের
                ফলে সাদা চোখ সৃষ্টি করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. লিথাল মিউটেশন বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>লিথাল মিউটেশন : </b>
                </font>{" "}
                যে মিউটেশনের বহিঃপ্রকাশে জীবের মৃত্যু ঘটে তাকে লিথাল মিউটেশন
                বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. জিন ফ্লো (Gene Flow)-এর সংজ্ঞা লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>জিন ফ্লো : </b>
                </font>{" "}
                একটি ক্ষুদ্র পপুলেশনে আকস্মিকভাবে কিছু সংখ্যক সদস্যের মৃত্যু বা
                পরিযানের কারণে ওই পপুলেশনে জিন ফ্রিকোয়েন্সির পরিবর্তন ঘটাকে জিন
                ফ্লো বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>26. অভিমুখী নির্বাচনের সংজ্ঞা দাও। </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#f626d7">
                <b>অভিমুখী নির্বাচন : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে প্রকার প্রাকৃতিক নির্বাচনের প্রভাবে পরিবেশের পরিবর্তিত
                পরিস্থিতির সঙ্গে মানিয়ে নেওয়ার জন্য কোনো পপুলেশনের সদস্যরা
                ক্রমশ ফিনোটাইপের একটি চরম মানের অভিমুখে পরিবর্তিত হতে থাকে, তাকে
                অভিমুখী নির্বাচন বা Directional Selection বলে। যেমন —
                ইন্ডাস্ট্রিয়াল মেলানিজমের ফলে ধূসর পেপারড্ মথ থেকে কালো পেপার
                মথের আবির্ভাব।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>27. জেনেটিক ড্রিফটের প্রভাবে কী হয়? </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#f626d7">
                <b> জেনেটিক ড্রিফটের প্রভাব : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                জেনেটিক ড্রিফটের প্রভাবে একটি পপুলেশনে কোনো অ্যালিলের
                ফ্রিকোয়েন্সি বেড়ে যেতে পারে বা কমে যেতে পারে। এর ফলে কোনো
                অ্যালিল সম্পূর্ণভাবে পপুলেশন থেকে বিলুপ্ত হওয়ার সম্ভাবনা
                রয়েছে। জেনেটিক ড্রিফট কোনো ছোট পপুলেশনের জীব বৈচিত্র্যের
                হ্রাসের একটি কারণ হতে পারে, এটি অনেক সময় পপুলেশনের
                জীববৈচিত্র্যও হ্রাস পায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>28. বটলনেক এফেক্ট কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#f626d7">
                <b> বটলনেক এফেক্ট : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                জেনেটিক ড্রিফটের প্রভাবে কোনো পপুলেশনের সদস্য সংখ্যা
                মারাত্মকভাবে হ্রাস পেলে অথবা এমিগ্রেশনের ফলে পপুলেশনের ঘনত্ব কমে
                যাওয়ার ফলে জিনের ভারসাম্য পরিবর্তিত হয় এবং শূন্য বা অত্যন্ত
                স্বল্প সংখ্যক জিনোটাইপ অবশিষ্ট থাকে। সাময়িকভাবে এই শূন্য সংখ্যক
                জিনোটাইপযুক্ত জীবগুলি সংখ্যায় বৃদ্ধি পেয়ে পপুলেশনটিকে পুনরায়
                স্থাপন করতে পারে। একে বটলনেক এফেক্ট বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  29. Hardy-Weinberg ভারসাম্য ব্যাহতকারী শর্তগুলি উল্লেখ করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#f626d7">
                <b> বটলনেক এফেক্ট : </b>
              </font>
              <font color="#3c92e5">
                Hardy-Weinberg -ভারসাম্য ব্যাহতকারী শর্তগুলি হল – (i) অসম
                উদ্বর্তন, (ii) প্রাকৃতিক নির্বাচন, (iii) পরিব্যক্তি, (iv) জিন
                ফ্লো, (v) জেনেটিক ড্রিফট, (vi) নিয়ন্ত্রিত জনন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  30. মিউটেশন তত্ত্বের প্রধান প্রতিপাদ্য বিষয়গুলি বর্ণনা করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> ⦿ </b>
              </font>{" "}
              <font color="#f626d7">
                <b> মিউটেশন তত্ত্বের প্রধান প্রতিপাদ্য বিষয় : </b>
              </font>
              <font color="#3c92e5">
                Hugo de Vris-এর মিউটেশন তত্ত্বের প্রধান প্রতিপাদ্যগুলি — <br />
                (i) মিউটেশন বংশ পরম্পরায় সঞ্চারিত হয় এবং যে-কোনো অভিমুখে ঘটতে
                পারে।
                <br />
                (ii) মিউটেশন তত্ত্ব অনুসারে বিবর্তন একটি অনিয়মিত ও বিচ্ছিন্ন
                পদ্ধতি।
                <br />
                (iii) জীবদেহে আকস্মিকভাবে ঘটা স্থায়ী বৃহৎ পরিবর্তন হল মিউটেশন
                যা সৃষ্টির সঙ্গে সঙ্গে কার্যকরী হয়।
                <br />
                (iv) মিউটেশনের ফলে আকস্মিকভাবে নতুন প্রজাতির আবির্ভাব দ্রুত এবং
                একটি ধাপেই ঘটে। <br />
                (v) মিউটেশনের ফলে উদ্ভূত জীব বা মিউট্যান্ট জীবরা তাদের জনিতৃ জীব
                থেকে অনেক পৃথক বৈশিষ্ট্যযুক্ত হয় এবং কোনো মধ্যবর্তী দশা উপস্থিত
                থাকে না।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

অভিব্যক্তিরকৌশল.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(অভিব্যক্তিরকৌশল);
