import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

const জীববিদ্যাউচ্চমাধ্যমিকপ্রস্তুতি = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>
          West Bengal Class 12 Guide | West Bengal HS Suggestion | জীববিদ্যা -
          উচ্চ মাধ্যমিক প্রস্তুতি
        </title>
        <meta
          name="description"
          content="West Bengal Class 12 Guide | West Bengal HS Suggestion | জীববিদ্যা - উচ্চ মাধ্যমিক প্রস্তুতি"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                জীববিদ্যা - উচ্চ মাধ্যমিক প্রস্তুতি
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative h-100"></div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3"></h6>
              <h1 className="mb-4">
                Welcome to জীববিদ্যা - উচ্চ মাধ্যমিক প্রস্তুতি
              </h1>
              <p className="mb-4">
                প্রতিটি অধ্যায়ের সাথে প্রস্তুতির সহায়ক সামগ্রী ও লিঙ্ক সরবরাহ
                করা হয়েছে যাতে ছাত্ররা তাদের অধ্যয়নে সহায়তা পেতে পারেন। এই
                পৃষ্ঠাটি ছাত্রদের পড়াশোনা প্রক্রিয়ার জন্য একটি গুরুত্বপূর্ণ
                সহায়ক সরঞ্জাম হিসাবে কাজ করতে পারে।
              </p>

              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-জীবের-জনন-Guide"
                        )
                      }
                    >
                      জীবের জনন
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-সপুষ্পক-উদ্ভিদের-যৌন-জনন-Guide"
                        )
                      }
                    >
                      সপুষ্পক উদ্ভিদের যৌন জনন
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-মানুষের-জনন-Guide"
                        )
                      }
                    >
                      মানুষের জনন
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-জননগত-স্বাস্থ্য-Guide"
                        )
                      }
                    >
                      জননগত স্বাস্থ্য
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বংশগতি-ও-বিভেদ-Guide"
                        )
                      }
                    >
                      বংশগতি ও বিভেদ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বংশগতির-আণবিক-ভিত্তি-Guide"
                        )
                      }
                    >
                      বংশগতির আণবিক ভিত্তি
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-অভিব্যক্তি-বা-বিবর্তন-Guide"
                        )
                      }
                    >
                      অভিব্যক্তি বা বিবর্তন
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-অভিব্যক্তির-কৌশল-Guide"
                        )
                      }
                    >
                      অভিব্যক্তির কৌশল
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-স্বাস্থ্য-ও-রোগ-Guide"
                        )
                      }
                    >
                      স্বাস্থ্য ও রোগ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-খাদ্য-উৎপাদনের-উন্নতি-সাধন-Guide"
                        )
                      }
                    >
                      খাদ্য উৎপাদনের উন্নতি সাধন
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-মানবকল্যাণে-অণুজীব-Guide"
                        )
                      }
                    >
                      মানবকল্যাণে অণুজীব
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-জীব-প্রযুক্তি-এবং-এর-প্রয়োগ-Guide"
                        )
                      }
                    >
                      জীব প্রযুক্তি এবং এর প্রয়োগ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বাস্তুবিদ্যা-পরিবেশ-ও-পপুলেশন-Guide"
                        )
                      }
                    >
                      বাস্তুবিদ্যা, পরিবেশ ও পপুলেশন
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-বাস্তুতন্ত্র-Guide"
                        )
                      }
                    >
                      বাস্তুতন্ত্র
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-জীববৈচিত্র্য-ও-সংরক্ষণ-Guide"
                        )
                      }
                    >
                      জীববৈচিত্র্য ও সংরক্ষণ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/জীববিদ্যা-উচ্চ-মাধ্যমিক-প্রস্তুতি-পরিবেশ-সংক্রান্ত-বিষয়সমূহ-Guide"
                        )
                      }
                    >
                      পরিবেশ সংক্রান্ত বিষয়সমূহ
                    </a>
                  </p>
                </div>
              </div>
              <a className="btn btn-primary py-3 px-5 mt-2" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

জীববিদ্যাউচ্চমাধ্যমিকপ্রস্তুতি.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(জীববিদ্যাউচ্চমাধ্যমিকপ্রস্তুতি);
