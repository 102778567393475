import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function সপুষ্পকউদ্ভিদেরযৌনজনন() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          সপুষ্পক উদ্ভিদের যৌন জনন | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="সপুষ্পক উদ্ভিদের যৌন জনন | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                সপুষ্পক উদ্ভিদের যৌন জনন
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>পরাগরেণু উৎপন্ন হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গর্ভপত্রে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্ত্রীস্তবকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পরাগধানীতে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পরাগনালিতে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নীচের কোন্ ফলটি প্রকৃত ফল নয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আপেল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কুল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জাম
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>জেনিয়া এবং মেটাজেনিয়া হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শুধুমাত্র শস্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পরাগ ও শস্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জাইলেম ও ফ্লোয়েম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ডিম্বকের বৃত্তকে বলা হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ডিম্বকনাভি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পুষ্পবৃত্তিকা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বৃন্ত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অমরা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  নিম্নলিখিত কোন্ উদ্ভিদের বীজ বৃদ্ধিপ্রাপ্ত ঘৃণ দ্বারা সস্য
                  ক্রমে ক্রমে ক্ষয়প্রাপ্ত হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভুট্টা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্যাস্টর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নারকেল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মটর
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ট্যাপেটান লক্ষ করা যায় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পরাগধানীর প্রাচীরে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডিম্বাশয়ের প্রাচীরে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পুংগ্যামেটোফাইটে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্ত্রীগ্যামেটোফাইটে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নিম্নলিখিত কোন্‌টি ডিপ্লয়েড -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডিম্ব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পরাগরেণু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মেগাস্পোর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মেগাস্পোর মাতৃকোশ (MMC)
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  হেটারোজাইগোসিটি নিম্নলিখিত কোন প্রকার পরাগযোগের জন্য দায়ী?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অটোগ্যামি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গেইটোনোগ্যামি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্লিস্টোগ্যামি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জেনোগ্যামি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>পরাগরেণুর সাইটোপ্লাজমে থাকে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  DNA
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রোটিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্টার্চ ও ফ্ল্যাভিনয়েড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ফ্যাট এবং স্টার্চ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ইত্তর পরাগযোগ হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চ্যাসমোগ্যামি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অটোগ্যামি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্লিস্টোগ্যামি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অ্যালোগ্যামি
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#1f7405" size="4">
                <b>সপুষ্পক একলিঙ্গ উদ্ভিদের যৌন জনন</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. অ্যাপোমিক্সিস কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                এটি উদ্ভিদের একটি বিশেষ ধরনের জনন যেখানে পুংগ্যামেট ও
                স্ত্রীগ্যামেটের মিলন না হয়েও অপত্য সৃষ্টি হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. পার্থেনোকাপি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                বীজহীন ফল উৎপাদনকে পার্থেনোকার্পি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. টেট্রাম্পোরাঞ্ঝিয়েট পরাগধানী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ডাইথিকাস পরাগধানীতে চারটি পরাগথলি থাকে, তাই একে
                টেট্রাস্পোরাগ্লিয়েট পরাগধানী বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. বাইস্পোরাগ্লিয়েট কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                মনোথিকাস পরাগধানীতে দুটি পরাগথলি থাকে, তাই একে বাইস্পোরাগ্লিয়েট
                বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. পদ্মের পরাগমিলন কার মাধ্যমে ঘটে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                পতজ্যের মাধ্যমে ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. ইমাসকুলেশন কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে পদ্ধতিতে পরাগধানীর অপসারণ করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. কোন ফুলে ট্রান্সলেটর দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Calotropus-এর ফুলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. কোথায় স্ত্রী গ্যামেটোফাইট লক্ষ করা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ডিম্বকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. অ্যাপোকারপাস গর্ভকেশর কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                গর্ভপত্রগুলি যখন মুক্তভাবে অবস্থান করে। যেমন—চাপা (Michelia)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. সিনকারপাস গর্ভকেশর কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                গর্ভপত্রগুলি যখন পরস্পর সংলগ্ন থাকে। যেমন—জবা (Hibiscus) |<br />
                <br />
                <font color="#f626d7">
                  <b>11. গর্ভদণ্ড কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                গর্ভকেশরের গর্ভমুণ্ড ও ডিম্বাশয়ের মাঝখানের সরু বৃত্তসদৃশ অংশ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. ডিম্বক কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ডিম্বকত্বক দ্বারা আবৃত অপরিপক্ব স্ত্রীরেণুম্বলীকে ডিম্বক বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. ডিম্বক বৃত্ত কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে বুদ্ধের সাহায্যে ডিম্বক অমরার সঙ্গে সংলগ্ন থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. ডিম্বক মূল কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ডিম্বকের যে অংশ থেকে ডিম্বক ত্বক উৎপন্ন হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. কারা মিলিত হয়ে সস্য গঠন করে? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                দুটি গৌণ নিউক্লিয়াস এবং একটি পুংগ্যামেট।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. পুংদণ্ড কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                পুংকেশরের যে সরু অংশে পরাগধানী সংলগ্ন থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. পরাগধানী কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                পুংকেশরের পুংদণ্ডের ওপর যে থলির মতো অংশটি যুক্ত থাকে যা পরাগরেণু
                বহন করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. ডিম্বক ত্বক কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ডিম্বকের দুটি আবরণীকে একত্রে ডিম্বক ত্বক বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. বহুমুখ ডিম্বক কার ক্ষেত্রে দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                সরিষা (Brassica napus)
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. ঊর্ধ্বমুখ বা অর্থোট্রোপাস ডিম্বক কার ক্ষেত্রে দেখা যায়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                গোলমরিচ (Piper nigram) |<br />
                <br />
                <font color="#f626d7">
                  <b>
                    21. মেগাস্পোর পরাগরেণুর মাতৃকোশ কটি আবরক বা স্ত্রীরেণু থাকে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                পরাগরেণুর দুটি আবরক থাকে। বাইরের আবরককে এক্সাইন বা রেণু
                বহিস্ত্বক এবং ভিতরের আবরণীকে ইন্টাইন বা রেণু অন্তঃস্তক বলে।{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. বীজের দুটি স্তরের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                বীজের বাইরের ত্বকটিকে টেস্টা (Testa) বা বহিঃবীজত্বক এবং ভি
                ত্বকটিকে টেগমেন (Tegmen) বা অন্তঃবীজত্বক বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. সাইফোনোগ্যামি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                গুপ্তবীজী উদ্ভিদের নিষেক প্রক্রিয়ার সময় পরাগনালির মাধ্যমে
                পুংগ্যাস্টে বহন করার প্রক্রিয়াকে সাইফোনোগ্যামি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. গৰ্ভযন্ত্র বা এগ অ্যাপারেটাস কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ভ্রূণস্থলীতে ডিম্বক রন্দ্রের দিকে হ্যাপ্লয়েড ডিম্বাণু ও দুটি
                হ্যাপ্লয়েড সহকার কোশ নিয়ে গঠিত অংশকে গর্ভযন্ত্র বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. বায়ুপরাগী ফুল কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যেসব ফুলের পরাগযোগ বায়ুর মাধ্যমে ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. জলপরাগী ফুল কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যেসকল ফুলে জলের মাধ্যমে পরাগযোগ ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. জুফিলি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যেসব ফুলে প্রাণীর সাহায্যে পরাগযোগ সংঘটিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. পক্ষীপরাগী ফুল কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যেসকল ফুলে পক্ষীর মাধ্যমে পরাগযোগ ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. গর্ভাধান কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                পুংজনন কোশ ও স্ত্রীজনন কোশের মিলনকে গর্ভাধান বা নিষেক বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. কোন্ কোন্ উদ্ভিদে দ্বিনিষেক লক্ষ করা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                রেড়ি, ভুট্টা, গম, ধান প্রভৃতি উদ্ভিদে দ্বিনিষেক লক্ষ করা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>31. বহুভ্ৰূণতা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                একটি বাঁজে যখন একাধিক ভ্রুণ উৎপন্ন হয়, তখন তাকে বহুভ্ৰূণতা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. মেকি বহুভ্ৰূণতা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যখন একই ডিম্বকের একাধিক ভ্রূণস্থলীতে একাধিক ভ্রুণ উৎপন্ন হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. পার্থেনোজেনেসিস কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে জনন প্রক্রিয়ায় অনিষিক্ত ডিম্বাণু থেকে সরাসরি অপত্য জীব
                সৃষ্টি হয়, তাকে পার্থেনোজেনেসিস বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. নিওটেনি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                লার্ভা দশায় যৌনতার পূর্ণতাপ্রাপ্তিকে নিওটেনি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. নিউসেলার পলিএমব্রায়োনি কার ক্ষেত্রে দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Citrust
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    36. কার ক্ষেত্রে নিষেকের পর স্ত্রীফুলের বৃত্ত কুণ্ডলীকৃত
                    অবস্থায় থাকে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Valltsneria-র ক্ষেত্রে দেখা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. সস্যল বীজে কোথায় খাদ্য সজ্জিত থাকে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                সস্যে খাদা সঞ্চিত থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    38. কোন্ উদ্ভিদে ভ্রূণস্থলীর মধ্যে বেশি সংখ্যায় প্রতিপাদ
                    কোশ লক্ষ করা হয়।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Drusa-র ক্ষেত্রে লক্ষ করা যায়। <br />
                <br />
                <font color="#f626d7">
                  <b>39. ইমাসকুলেশনের প্রধান উদ্দেশ্য কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                স্বনিষেক প্রতিহত করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>40. কোন্ কোশ থেকে ভ্রুণস্থলী সৃষ্টি হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                মেগাস্পোর মাতৃকোশ বা স্ত্রীরেণু মাতৃকোশ থেকে ভ্রুণস্থলা সৃষ্টি
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>41. মনোসাইফোনাস পরাগ কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে পরাগরেণু থেকে একটিমাত্র পরাগনালিকা উৎপন্ন হয় তাকে
                মনোসাইফোনাস পরাগ বলে। উদাহরণ - বেগুন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>42. পলিসাইফোনাস পরাগ বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে পরাগরেণু অঙ্কুরিত হয়ে একাধিক পরাগনালিকা উৎপন্ন করে তাকে
                পলিসাইফোনাস পরাগ বলে। উদাহরণ — কুমড়ো, জবা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>43. মুক্তবৃতি ও যুক্তবৃতি ফুলের উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ফুলের বৃতিগুলি পৃথক পৃথক ভাবে থাকলে সেই ফুলকে মুক্তবৃতি বলে।
                উদাহরণ — চাঁপা। <br />
                বৃতিগুলি সংযুক্ত বা লেগে থাকলে যুক্তবৃতি ফুল হয়। উদাহরণ -
                বেগুন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. মাইক্রোম্পোরোজেনেসিস কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে প্রক্রিয়ায় রেণুধর কলায় অবস্থিত রেণুমাতৃকোশ থেকে পরাগরেণু
                সৃষ্টি হয়, তাকে মাইক্রোস্পোরোজেনেসিস বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. প্রকৃত বহুভূণতা কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যখন ডিম্বকের একটি ভ্রূণস্থলীতে একাধিক ভ্রুণ উৎপন্ন হয়, তখন তাকে
                প্রকৃত বহুভ্রূণতা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. নির্ণীত নিউক্লিয়াস কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ভ্রুণস্থলীর মধ্যবর্তী স্থানে দুটি নিউক্লিয়াস থাকে যারা পরস্পর
                ডিপ্লয়েড (2n) নিউক্লিয়াস গঠন করে থাকে, এদের নির্ণীত বা
                ডেফিনিটি নিউক্লিয়াস বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. পার্থেনোকারপিক ফল কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                এটি একপ্রকারের ফল যেখানে নিষেক ছাড়াই ফুলের ডিম্বাশয় বীজহীন ফলে
                পরিণত হয়। বিভিন্ন হরমোন প্রয়োগ করে কৃত্রিম উপায়ে বীজহীন ফল
                তৈরি করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. অসাযুজ্যতা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ফুলে গর্ভমুণ্ড ও পরাগরেণুর আন্তঃক্রিয়ার ফলে যখন অবাঞ্ছিত
                পরাগরেণু অঙ্কুরিত হতে পারে না, তখন তাকে অসাযুজ্যতা বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. রেণুরুস্প্র কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                পরাগরেণুর বহিঃপ্রাচীরে এক বা একাধিক রন্দ্র থাকে, যার পরাগনালির
                নির্গমন ঘটে, তাকে রেণুরন্দ্র বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. ভ্রূণস্থলী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ভ্রুণপোষক কলার মধ্যে যে বৃহৎ পলির মতো অংশ থাকে, যার
                নিউক্লিয়াসগুলি অবস্থান করে, তাকে ভ্রূণস্থলী বলে। এর মধ্যে একটি
                নিউক্লিয়াস থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. ক্যামপাইলেট্রপাস ডিম্বক কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                এই ধরনের ডিম্বকটি এমনভাবে বাঁকা থাকে যাতে ডিম্বকরন্দ্রটি
                ডিম্বকনাভির পাশে অবস্থান করে। ডিম্বকবৃত্তের এক পাশে থাকে ডিম্বক
                এবং অপর পাশে থাকে ডিম্বকমূল। <br />
                উদা: সরিযা (Brassica)|
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. দ্বিনিষেক বা ডাবল ফার্টিলাইজেশন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                একই স্ত্রী লিঙ্গধরের মধ্যে অবস্থিত দুটি ভিন্ন ধরনের (ডিম্বাণু ও
                নির্ণীত নিউক্লিয়াস) স্ত্রীজনন কোশের সঙ্গে দুটি পুংগ্যামেটের
                মিলনকে অর্থাৎ নিষেককে দ্বিনিষেক বা ডাবল ফার্টিলাইজেশন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. সেলুলার সস্য কী? উদাহরণ সহ লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                এক্ষেত্রে প্রাথমিক সস্যের প্রতিটি বিভাজনেই নিউক্লিয়াস বিভাজনের
                সঙ্গে সঙ্গে সাইটোপ্লাজম বিভাজিত হয় এবং শুরু থেকেই সস্য
                কোশপ্রাচীরযুক্ত বা সেলুলার গঠনবিশিষ্ট হয়। বালসাম (Balsam),
                ধুতুরা (Datura), পেটুনিয়া (Petunia) প্রভৃতি উদ্ভিদে দেখা যায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. প্রকৃত ও অপ্রকৃত ফল কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                <font color="#f626d7">
                  <b>প্রকৃত ফল :</b>
                </font>{" "}
                যখন কেবল ডিম্বাশয় ফলে পরিণত হয়, ফুলের অন্যান্য অংশ ফল গঠনে
                অংশগ্রহণ করে না, তখন সেই প্রকার ফলকে প্রকৃত ফল (true fruit) বলে।
                যেমন — আম, জাম। <br />
                <font color="#f626d7">
                  <b>অপ্রকৃত ফল :</b>
                </font>{" "}
                কখনো-কখনো পুষ্পাক্ষ এবং ফুলের অন্যান্য অংশ ফল গঠনে অংশগ্রহণ করে,
                তখন সেই প্রকার ফলকে অপ্রকৃত ফল (false fruit) বলে। যেমন — আপেল,
                স্ট্রবেরি ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. অ্যাম্ফিট্রপাস ডিম্বক কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                এই ধরনের ডিম্বকটি ডিম্বক বৃত্তের সঙ্গে লম্বভাবে অবস্থান করে,
                ডিম্বকরস্ত্র ও ডিম্বকমূল ডিম্বকবৃত্তের দুই বিপরীত পাশে একই
                সরলরেখায় অবস্থান করে। <br />
                উদা : আফিং (Papaver somniferum)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. স্ত্রীরেণুর উৎপত্তি কীভাবে ঘটে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ডিপ্লয়েড স্ত্রীরেণুমাতৃকোশটি মায়োসিস প্রক্রিয়ায় চারটি
                হ্যাপ্লয়েড স্ত্রীরেণু, (megaspore) অর্থাৎ রেণচতুষ্টয়
                (megaspore tetrad) উৎপন্ন করে। চারটি স্ত্রীরেণু এক সরলরেখায়
                অবস্থান করে। এদের মধ্যে ডিম্বক মূলের দিকে অবস্থিত স্ত্রীরেণুটি
                সক্রিয় ভূমিকা নেয়, বাকি তিনটি বিলুপ্ত হয়ে যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. পরাগযোগ বা পরাগায়ন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ফুলের পরাগধানী থেকে পরাগরেণু একই ফুলের বা একই গাছের অন্য ফুলের
                বা একই প্রজাতির অন্য গাছের ফুলের গর্ভমুণ্ডে স্থানাত্তরণকে
                পরাগযোগ বা পরাগায়ন বলে। ব্যক্তবীজী উদ্ভিদের পরাগরেণু সরাসরি
                অনাবৃত ডিম্বকের ওপর পতিত হয়ে পরাগযোগ ঘটায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. স্বপরাগযোগ কাকে বলে? উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যখন কোনো ফুলের পরাগরেণু সেই ফুলের বা সেই গাছের অন্য ফুলের
                গর্ভমুণ্ডের ওপর পতিত হয়, তখন তাকে স্বপরাগযোগ বলে। উদাহরণ :
                উভলিঙ্গ ফুলে সাধারণত স্বপরাগযোগ দেখা যায়। শিম (Dolichos
                lablab), টম্যাটো (Lycopersicum esculentum), সন্ধ্যামালতী
                (Mirabilis jalapa) ইত্যাদি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>16. স্বপরাগী উদ্ভিদের যে-কোনো চারটি বৈশিষ্ট্য উল্লেখ করো।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                <font color="#f626d7">
                  <b>বৈশিষ্ট্য :</b>
                </font>{" "}
                (i) ফুলের পরাগধানী ও গর্ভমুণ্ড একই সময়ে পরিণত হয়।
                <br />
                (ii) একই ফুলে অথবা একই উদ্ভিদের দুটি ফুলে পরাগযোগ ঘটে।
                <br />
                iii) ফুলগুলি আংশিকভাবে বা সম্পূর্ণভাবে অনুন্মীলিত হয়। <br />
                (iv) এইধরনের ফুল উভলিঙ্গ প্রকৃতির হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. হোমোগ্যামি ও ক্লিস্টোগ্যামি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                <font color="#f626d7">
                  <b>হোমোগ্যামি :</b>
                </font>{" "}
                এরকম স্বপরাগযোগ ক্যামোগ্যামাস (chamogamous) অর্থাৎ মুক্ত ফুলে
                ঘটে। গর্ভদণ্ডটি বৃদ্ধি পেয়ে গর্ভমুণ্ডটি যখন পরিপক্ক পরাগধানীর
                সংলগ্ন হয় তখন পরাগযোগ ঘটে। যেমন— Catharanthus ফুল Mirabilis
                (4'O clock) ফুলে পরাগদণ্ড থেকে পরাগধানী যখন গর্ভমুণ্ডের নিকট আসে
                তখন পরাগযোগ ঘটে। এই ধরনের পরাগযোগকে হোমোগ্যামি বলে। <br />
                <br />
                <font color="#f626d7">
                  <b>ক্লিস্টোগ্যামি : </b>
                </font>
                ফুলগুলি বন্ধ প্রকৃতির হওয়ায় পরাগধানী বিদীর্ণ হলে পরাগরেণু
                স্বাভাবিকভাবেই গর্ভমুণ্ডে পড়ে, ফলে পরাগযোগ ঘটে। বালসাম, Oxalis,
                Viola, Commelina প্রভৃতি ফুলে এই ধরনের পরাগযোগ সম্পন্ন হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. অটোগ্যামি ও গেইটোনোগ্যামি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                <font color="#f626d7">
                  <b>অটোগ্যামি : </b>
                </font>
                পরাগমিলন যখন একই ফুলের মধ্যে ঘটে, অর্থাৎ একটি ফুলের পরাগধানী
                থেকে উৎপন্ন পরাগরেণু সেই ফুলেরই গর্ভমুণ্ডে পতিত হয়। <br />
                <font color="#f626d7">
                  <b>গেইটোনোগ্যামি : </b>
                </font>
                এইরকম পরাগযোগ একই গাছের দুটি ফুলের মধ্যে ঘটে থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. স্বপরাগযোগের যে-কোনো চারটি সুবিধা উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                (i) এই প্রক্রিয়ায় প্রজাতি বিশুদ্ধতা রক্ষা পায় এবং জনিতৃ
                বৈশিষ্ট্য নিয়ন্ত্রিত হয়।
                <br />
                (ii) সংকরায়ণ পরীক্ষায় পিওর লাইন (pure line) বজায় রাখার জন্য
                স্বপরাগযোগ ঘটানো হয়। <br />
                (iii) এইরকম পরাগযোগের জন্য অন্য প্রজাতির উদ্ভিদের বা ফুলের দরকার
                হয় না। <br />
                (iv) এই প্রকার পরাগযোগে অধিক সংখ্যক পরাগরেণুর প্রয়োজন হয় না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. ইতর পরাগযোগ কাকে বলে? উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যখন কোনো ফুলের পরাগরেণু একই প্রজাতির অন্য কোনো গাছের ফুলের
                গর্ভমুণ্ডে পড়ে, তখন তাকে ইতর পরাগযোগ বা ক্রস পলিনেশন বলে।{" "}
                <br />
                উদাহরণ : তাল ( Borassus flabellifer), পেঁপে (Carica papaya)।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. স্টিমুলেটিভ পার্থেনোকাপি কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                বিভিন্ন রকম উদ্দীপক ব্যবহার করে ফুলের পরাগযোগ ও নিষেকে বাধা
                সৃষ্টি করে যে বীজবিহীন ফল সৃষ্টি করা হয় তাকে স্টিমুলেটিভ
                পার্থেনোকাপি বলে। বিশেষ করে কৃত্রিম অক্সিন প্রয়োগ করে অনেক
                উদ্ভিদেরই বীজবিহীন ফল সৃষ্টি করা হয়। যেমন — আঙুর, কলা,
                আম্মনারস, কমলা ইত্যাদি। নিম্ন তাপমাত্রা, কুয়াশা ইত্যাদির ফলেও
                বহু উদ্ভিদের বীজবিহীন ফল সৃষ্টি হয়। যেমন—জলপাই, পেয়ারা, লংকা,
                টম্যাটো ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. বহুভ্রূণতা কী? এর গুরুত্বগুলি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                একটি বীজে যখন একাধিক মাত্রায় ভ্রুণ উৎপন্ন হয় তখন তাকে
                বহুভ্রুণতা বলা হয়। <br />
                <br />
                <font color="#f626d7">
                  <b>গুরুত্ব : </b>
                </font>
                (i) এই ধরনের ভ্রুণ ক্লোন তৈরি করতে সুবিধাজনক।
                <br />
                (ii) এই ধরনের ভ্রূণ সহজে সংক্রামিত হয় না। <br />
                (iii) সংকর প্রজাতি সৃষ্টির জন্য এই ধরনের ভ্রুণ খুবই
                তাৎপর্যপূর্ণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. পার্থেনোম্পোর কাকে বলে? অপুংজনির গুরুত্ব লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                স্পাইরোগাইরা, মিউকর প্রভৃতি কতিপয় নিম্নশ্রেণির অপুষ্পক উদ্ভিদ
                এবং মৌমাছি, বোলতা, পিঁপড়ে প্রভৃতি প্রাণীদের অনিষিক্ত ডিম্বাণুটি
                নিষেক ছাড়াই সরাসরি নতুন উদ্ভিদ ও প্রাণী সৃষ্টি করে। অনিষিক্ত
                ডিম্বাণুটিকে পার্থেনোস্পোর বা অ্যাজাইগোস্পোর বলে। <br />
                <br />
                <font color="#f626d7">
                  <b>অপুংজনির গুরুত্ব : </b>
                </font>
                (i) অনিষিক্ত ডিম্বাণু থেকে সরাসরি পূর্ণাঙ্গ প্রাণীতে পরিণত
                হওয়ার পদ্ধতি যৌন জনন পদ্ধতির মতো জটিল নয়। <br />
                (ii) নিষেকের প্রয়োজন হয় না। <br />
                (iii) কৃত্রিম উপায়ে বাহ্যিক শর্ত আরোপ করে সহজে অপুংজনির মাধ্যমে
                অপত্য প্রাণী তৈরি করা যায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>24. পার্থেনোকাৰ্পি কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                পরাগযোগ ও নিষেক ছাড়াই গর্ভাশয় বিবর্ধিত হয়ে যে বীজহীন ফল
                উৎপন্ন করে সেই প্রক্রিয়াকে পার্থেনোকার্পি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. সমাঙ্গ পুষ্পকে কেন বহুপ্রতিসম পুষ্প বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে পুষ্পের প্রতিটি স্তবকের প্রতিটি অংশ সমান হয় তাকে সমাঙ্গ
                পুষ্প বলে। এই ধরনের পুষ্পকে উল্লম্বভাবে কেন্দ্রের মধ্য দিয়ে
                বিভক্ত করলে বহুবার দুটি সমান অংশে ভাগ করা যায় বলে এই ধরনের
                পুষ্পকে বহুপ্রতিসম পুষ্প বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. নগ্ন পুষ্প কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যেই পুষ্পের সহকারী দুটি স্তবক অর্থাৎ বৃতি ও দলমণ্ডল উভয়ই
                অনুপস্থিত তাকে নগ্ন পুষ্প বলে। উদাহরণ - রাংচিতা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    27. গর্ভপাদ বা হাইপোগাইনাস পুষ্পের ডিম্বাশয় অধিগর্ভ হয়
                    কেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                গর্ভপাদ পুষ্পে পুষ্পাধারটি উত্তর হয় এবং স্ত্রীস্তবকটি সবচেয়ে
                উপরে অবস্থান করে যার তলায় অন্যান্য স্তবকগুলি অবস্থান করে। ফুলের
                ডিম্বাশয়ের অবস্থান সবচেয়ে উপরে বলে একে অধিগর্ভ ডিম্বাশয় বলে।
                উদাহরণ - বেগুন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. স্কুটেলাম কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                একবীজপত্রী ভুট্টা বীজের বীজপত্রটিকে স্কুটেলাম বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. পোমোলজি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                উদ্ভিদবিজ্ঞানের যে শাখায় ফলের প্রকৃতি ও ফলচাষ সম্পর্কে আলোচনা
                করা হয় তাকে পোমোলজি বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>30. গর্ভকটি ফুল কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে ফুলে পুষ্পাধারটি সমতল হওয়ায় স্ত্রীস্তবক এবং অন্যান্য
                স্তবকগুলি প্রায় একই তলে অবস্থান করে তাকে গর্ভকটি ফুল
                (Perigynous flower) বলে। উদাহরণ - অতসী।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    31. একগুচ্ছ দ্বিগুচ্ছ ও বহুগুচ্ছ পুংকেশর যুক্ত ফুলের উদাহরণ
                    দাও।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                একগুচ্ছ পুংকেশর— জবা, দ্বিগুচ্ছ পুংকেশ—মটর এবং বহুগুচ্ছ
                পুংকেশর–শিমুল ফুল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. নিষেকের অসুবিধাগুলি কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                (i) দুটি বিপরীত লিঙ্গের জননাঙ্গের উপস্থিতির অভাব ঘটলে
                নিষেকসম্পন্ন হবে না। <br />
                (ii) অনেক সময় পুংগ্যামেট ও স্ত্রীগ্যামেটের মিলনে বাধা সৃষ্টি
                হয় ফলে নিষেক সম্পন্ন হয় না।
                <br /> (iii) প্রচুর পরাগরেণু উৎপন্ন করতে হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. হোমোগ্যামি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে উভলিঙ্গ ফুলে পুংকেশর ও গর্ভকেশর একই সাথে পরিণত হয়ে পরাগযোগ
                করে তাকে হোমোগ্যামি বলে। উদাহরণ - নয়নতারা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. ব্যক্তবীজী উদ্ভিদ কাদের বলা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যেসব উদ্ভিদের বীজ ফল দ্বারা আবৃত না হয়ে নগ্ন অবস্থায় থাকে
                তাদের ব্যস্তবীজী উদ্ভিদ বলে। উদাহরণ - সাইকাস, পাইনাস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. অপ্রকৃত ফল কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ডিম্বাশয় ছাড়া ফুলের অন্য কোনো অংশ বা পুষ্পমঞ্জরি যদি ফলে পরিণত
                হয় তখন সেই ফলকে অপ্রকৃত ফল বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>36. চালতা, আপেল ও কাঁঠালের কোন্ অংশ ফলে পরিণত হয়? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                চালতা — বৃতি, আপেল – পুষ্পাক্ষ, কাঁঠাল — সমগ্র পুষ্পমঞ্জুরি ফলে
                পরিণত হয়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

সপুষ্পকউদ্ভিদেরযৌনজনন.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(সপুষ্পকউদ্ভিদেরযৌনজনন);
