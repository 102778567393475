import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

const Computerউচ্চমাধ্যমিকপ্রস্তুতি = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>Computer - Higher Secondary Guide</title>
        <meta name="description" content="Computer - Higher Secondary Guide" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Computer - উচ্চ মাধ্যমিক প্রস্তুতি
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative h-100"></div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3"></h6>
              <h1 className="mb-4">
                Welcome to Computer - উচ্চ মাধ্যমিক প্রস্তুতি
              </h1>
              <p className="mb-4">
                প্রতিটি অধ্যায়ের সাথে প্রস্তুতির সহায়ক সামগ্রী ও লিঙ্ক সরবরাহ
                করা হয়েছে যাতে ছাত্ররা তাদের অধ্যয়নে সহায়তা পেতে পারেন। এই
                পৃষ্ঠাটি ছাত্রদের পড়াশোনা প্রক্রিয়ার জন্য একটি গুরুত্বপূর্ণ
                সহায়ক সরঞ্জাম হিসাবে কাজ করতে পারে।
              </p>

              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a href="/Computer-Higher-Secondary-Exam-Preparation-Logic-Gate-and-Combinational-Circuit-Guide">
                      Logic Gate and Combinational Circuit
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a href="/Computer-Higher-Secondary-Exam-Preparation-Networking-Guide">
                      Networking
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a href="/Computer-Higher-Secondary-Exam-Preparation-Database-Management-System-Guide">
                      Database Management System
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a href="/Computer-Higher-Secondary-Exam-Preparation-MS-Excel-2007-Guide">
                      MS Excel-2007
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a href="/Computer-Higher-Secondary-Exam-Preparation-MS-Access-2007-Guide">
                      MS Access 2007
                    </a>
                  </p>
                </div>
              </div>
              <a className="btn btn-primary py-3 px-5 mt-2" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Computerউচ্চমাধ্যমিকপ্রস্তুতি.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(Computerউচ্চমাধ্যমিকপ্রস্তুতি);
