import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function জীববৈচিত্র্য() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          জীববৈচিত্র্য | West Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="জীববৈচিত্র্য | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                জীববৈচিত্র্য
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b>ষষ্ঠ অধ্যায়</b>
              </font>
              <br />{" "}
              <font color="#2b9a09" size="4">
                <b>জীববৈচিত্র্য</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ৭</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                ১. জীববৈচিত্র্য বলতে কী বােঝ? এর প্রধান বৈশিষ্ট্য ও গুরুত্ব
                আলােচনা করাে। অথবা, জীববৈচিত্র্যের গুরুত্ব আলােচনা করাে।
                <br />
                ২. জীববৈচিত্র্যের ঢাল বলতে কী বােঝ? সংরক্ষণের আন্তর্জাতিক সংস্থা
                (IUCN) অনুযায়ী সংকটময়তার নিরিখে প্রজাতিগুলি শ্রেণিবিভাগ করাে।
                <br />
                ৩. ইন-সিটু সংরক্ষণ ও এক্স-সিটু সংরক্ষণের মধ্যে পার্থক্য লেখো।
                <br />
                ৪. বিশ্বের গুরুত্বপূর্ণ জীববৈচিত্র্যসমৃদ্ধ দেশের নাম উল্লেখ
                করাে। জীববৈচিত্র্যের উপাদানগুলির ওপর ভিত্তি করে জীববৈচিত্র্যের
                প্রকারভেদ আলােচনা করাে।
                <br />
                ৫. জীববৈচিত্র্য বিলুপ্তির প্রধান কারণগুলি লেখাে।
                <br />
                ৬. Biodiversity Hotspot বা জীববৈচিত্র্য হটস্পট বলতে কী বােঝ?
                জীববৈচিত্র্য সংরক্ষণের জন্য গৃহীত পদক্ষেপসমূহ আলােচনা করাে।
                <br />
                ৭. ভারতে জীববৈচিত্র্য সংরক্ষণের উদ্দেশ্যে কী কী ব্যবস্থা গ্রহণ
                করা হয়েছে?
                <br />
                ৮. জীববৈচিত্র্য সংরক্ষণের গুরুত্ব কী? ক্রান্তীয় অঞ্চলে সর্বাধিক
                জীববৈচিত্র্য দেখা যায় কেন?
                <br />
                ৯. জীববৈচিত্র্য বিনাশে মানুষের ভূমিকা আলোচনা করো। ভারতীয়
                উপমহাদেশের জীববৈচিত্র্য হটস্পট অঞ্চল সম্পর্কে আলোচনা করো।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ১</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>১. আলফা বৈচিত্র্যের দ্বারা কি জানা যায়?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                একটি নির্দিষ্ট অঞ্চলে উপস্থিত বিভিন্ন প্রকার জীবের বৈচিত্র্যকে
                আলফা বৈচিত্র্য বলে। আলফা বৈচিত্র্যের মাধ্যমে কোনো অঞ্চলে
                প্রজাতির প্রাচুর্য (Species richness) সম্পর্কে জানা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২. বিটা বৈচিত্র্য কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পাশাপাশি অবস্থিত দুটি অঞ্চলের মধ্যে যে প্রজাতিগত বৈচিত্র্য
                লক্ষ্য করা যায় তার মাত্রাকে নির্দেশ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৩. গামা বৈচিত্র্য কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                একটি বৃহদায়তন ভৌগোলিক অঞ্চলের মধ্যে থাকা বিভিন্ন বাসস্থানের
                প্রজাতির প্রাচুর্যকে গামা বৈচিত্র্য বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৪. প্রজাতিগত বৈচিত্র্য বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                একটি নির্দিষ্ট অঞ্চল বা বাস্তুতন্ত্রের মধ্যে যে বিভিন্ন প্রকার
                জীবের সমাবেশ দেখা যায়, তাকেই প্রজাতি গত বৈচিত্র্য বলে।পৃথিবীর
                প্রাকৃতিক অঞ্চল গুলির মধ্যে নিরক্ষীয় অঞ্চলেই সবচেয়ে বেশি
                সমৃদ্ধশালী প্রজাতিগত বৈচিত্র্য পরিলক্ষিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৫. জীববৈচিত্র্য শব্দটি কে কত সালে ব্যবহার করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ‘Biodiversity’ শব্দটি প্রথম ব্যবহার করেন ওয়াল্টার জি. রোসেন ১৯৮৫
                সালে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৬. ভারতে কত সালে জীববৈচিত্র্য আইন প্রণীত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ২০০২ সালে
                <br />
                <br />
                <font color="#f626d7">
                  <b>৭. সাইলেন্ট ভ্যালি কোথায় অবস্থিত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কেরালায়
                <br />
                <br />
                <font color="#f626d7">
                  <b>৮. কত সালে MAB কর্মসূচি গ্রহন করা হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৭১ সালে
                <br />
                <br />
                <font color="#f626d7">
                  <b>৯. পেরিয়ার জাতীয় উদ্যান কোন রাজ্যে অবস্থিত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কেরালায়
                <br />
                <br />
                <font color="#f626d7">
                  <b>১০. বায়োস্ফিয়ার রিজার্ভ কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বিশাল অঞ্চলের জীব বৈচিত্র্য সংরক্ষণের উদ্দেশ্যে MAB পরিকল্পনার
                অঙ্গ হিসাবে UNESCO ১৯৭১ সালে প্রথম বায়োস্ফিয়ার রিজার্ভ
                (Biosphere Reserve) ধারনার সৃষ্টি করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>১১. ভারতের প্রথম ও নবীনতম বায়োস্ফিয়ার রিজার্ভ কোনটি?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতের প্রথম জীবমণ্ডল সংরক্ষণ অঞ্চল হল নীলগিরি (১৯৮৬) এবং ভারতের
                নবীন জীবমণ্ডল সংরক্ষণ অঞ্চল হল কাঞ্চনজঙ্ঘা (২০১৮)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১২. ভারতের জীববৈচিত্র্যের উষ্ম অঞ্চলের উদাহরণ দাও?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পশ্চিমঘাট পার্বত্য অঞ্চল ও পূর্ব হিমালয় পার্বত্য অঞ্চল
                <br />
                <br />
                <font color="#f626d7">
                  <b>১৩. IUCN এর প্রতীকে কোন প্রানীর ছবি রয়েছে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জায়েন্ট পাণ্ডা
                <br />
                <br />
                <font color="#f626d7">
                  <b>১৪. IPCC এর পুরো নাম কি?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ইন্টার গভার্নমেন্টাল প্যানেল অফ ক্লাইমেন্ট চেঞ্জ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১৫. ইনসিটু সংরক্ষণ কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জীব প্রজাতিকে যখন তার নিজ প্রাকৃতিক পরিবেশে সংরক্ষণ করা হয়, তখন
                তাকে ইন-সিটু সংরক্ষণ বলে। যেমন – সুন্দরবনের বাঘ ও সুন্দরী গাছকে
                সুন্দরবনের প্রাকৃতিক বাস্তুতন্ত্রে সংরক্ষণ করাকে ইন-সিটু সংরক্ষণ
                বলা হয়। স্বস্থানে সংরক্ষণের উদ্দেশ্যে জাতীয় উদ্যান, অভয়ারণ্য ,
                জীবমন্ডল সংরক্ষণ অঞ্চল গড়ে তোলা হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১৬. ক্রায়ো সংরক্ষণ বলতে কি বোঝ?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                উদ্ভিদের পরাগ, বীজ, কোশ ও প্রানীর জিন কে খুব শীতল তাপমাত্রায়
                (-১৯০ ডিগ্রি সেলসিয়াস) সংরক্ষণ করাকে ক্রায়ো সংরক্ষণ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১৭. ইকোটোন কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ইকোটোন হল দুটি বাস্তুতন্ত্রের সীমানার মধ্যবর্তী পরিবর্তনশীল
                অঞ্চল, যেখানে দুটি বাস্তুতন্ত্রের জীব প্রজাতির সমাবেশ দেখা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>১৮. জীববৈচিত্র্যের হটস্পট শব্দটি প্রথম কে ব্যবহার করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                নরম্যান মায়ার্স
                <br />
                <br />
                <font color="#f626d7">
                  <b>১৯. পৃথিবীর ফুসফুস কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আমাজনের চিরসবুজ অরণ্যকে পৃথিবীর ফুসফুস বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২০. বিশ্বের সর্বপ্রথম জাতীয় উদ্যান কোনটি?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                আমেরিকা যুক্তরাষ্ট্রের ইয়োলোস্টোন ন্যাশনাল পার্ক
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>২১. ভারতের সর্বপ্রথম জাতীয় উদ্যান কোনটি?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                জিম করবেট ন্যাশনাল পার্ক
                <br />
                <br />
                <font color="#f626d7">
                  <b>২২. রেড ডেটা বুক কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যে বই এ পৃথিবীর বিপদ গ্রস্থ প্রজাতি গুলিকে অন্ত:ভুক্ত করা হয়,
                তাকে রেড ডেটা বুক বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২৩. লুপ্ত প্রজাতি কাদের বলা হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যে সব উদ্ভিদ ও প্রানী প্রজাতি পৃথিবী থেকে হারিয়ে গেছে, যাদের
                বর্তমানে কোনো অস্তিত্ব নেই, তাদের লুপ্ত প্রজাতি বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২৪. আঞ্চলিক সীমাবদ্ধতা বা এনডেমিক প্রজাতি কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যে সব জীব প্রজাতি কেবল একটি অঞ্চলেই পাওয়া যায়, অন্য কোথাও তাদের
                অস্তিত্ব নেই, তাকেই আঞ্চলিক সীমাবদ্ধতা বলে। এই আঞ্চলিক
                সীমাবদ্ধতার থেকে জীববৈচিত্র্যের হটস্পট ধারনার উৎপত্তি হয়। <br />
                <br />
                <font color="#f626d7">
                  <b>২৫. অন্ধকার বৈচিত্র্য কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কোন একটি অঞ্চলে জীববৈচিত্র্য সম্পর্কে অধ্যায়নের সময় কোন একটি
                প্রাণী প্রজাতি সেখানে অধ্যায়ন চলাকালিন সময়ে উপস্থিত না থাকলেও
                অন্য সময় সেখানে উপস্থিত থাকে। অর্থাৎ জীববৈচিত্র্য পরিমাপের সময়
                তা গননার মধ্যে আসে না বলে, উক্ত প্রজাতিকে অন্ধকার বৈচিত্র্য বলা
                হয়। গভীর সমুদ্রে এই ধরণের জীব বৈচিত্র্য দেখা যায়।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ৭</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ১. জীববৈচিত্র্য বলতে কী বােঝ? এর প্রধান বৈশিষ্ট্য ও গুরুত্ব
                  আলােচনা করাে। <br />
                  অথবা,
                  <br /> জীববৈচিত্র্যের গুরুত্ব আলােচনা করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>জীববৈচিত্র্য : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                প্রাকৃতিক পরিবেশে উদ্ভিদ, প্রাণী ও আণুবীক্ষণিক জীব সমূহের
                পারস্পরিক সম্পর্কের ভিত্তিতে যে বাস্তুতন্ত্র গড়ে ওঠে, সেই
                বাস্তুতন্ত্রে অগনিত নানা ধরনের জীব প্রজাতির সমাহার বা সমাবেশকে
                জীববৈচিত্র্য বলে। 1986 খ্রিস্টাব্দে W. G. Rosen স্কিসােমিয়ান
                ইনস্টিটিউটের ন্যাশনাল ফোরামে সর্বপ্রথম জীববৈচিত্র্য তথা
                'Biodiversity শব্দটি ব্যবহার করেন। পরবর্তীকালে IUCN ও UNEP
                “Biodeversity” শব্দটি গ্রহণ করে জীববৈচিত্র্যের যে সংজ্ঞা প্রদান
                করে তা হল—কোনাে একটি নির্দিষ্ট অঞ্চলের সমস্ত জিন, প্রজাতি ও
                বাস্তুতন্ত্রের বৈচিত্র্য ও সমগ্রতাকে জীববৈচিত্র্য বা
                Biodiversity বলে। পৃথিবীর জীবমণ্ডলে প্রায় 2.2 কোটি প্রকার জীবের
                বাস। এর মধ্যে এখনও পর্যন্ত প্রায় 16 লক্ষ 5 হাজার জীব প্রজাতিকে
                শনাক্ত করা হয়েছে। উদ্ভিদ, প্রাণী ও জীবাণুর প্রত্যেক প্রজাতির
                মধ্যে যেমন স্বাতন্ত্র্যতা দেখা যায়, তেমনি একই প্রজাতির বিভিন্ন
                সদস্যদের মধ্যেও গঠনগত বিভিন্নতা দেখা যায়। জীবমণ্ডলের সমগ্র
                জীবদের মধ্যে এই বিভিন্নতাই হল জীববৈচিত্র্য।
                <br />
                <br />
                <font color="#29900a">
                  <b>জীববৈচিত্র্যের বৈশিষ্ট্য সমূহ : </b>{" "}
                </font>{" "}
                <br />
                (i) সময়ের সাথে সাথে জীববৈচিত্র্য সবসময়ই পরিবর্তনশীল।
                <br />
                (ii) উষ্ণ-আর্দ্র অঞ্চলে জীববৈচিত্র্য সর্বাধিক।
                <br />
                (iii) উচ্চ অক্ষাংশ থেকে নিম্ন অক্ষাংশে জীববৈচিত্র্য বেশি হয়ে
                থাকে।
                <br />
                (iv) ঋতুপার্থক্যহীন এলাকায় জীববৈচিত্র্য বেশি দেখা যায়।
                <br />
                (v) জীব বৈচিত্র্য সৃষ্টিতে সব প্রজাতিই গুরুত্বপূর্ণভূমিকা পালন
                করে।
                <br />
                <br />
                <font color="#29900a">
                  <b>জীববৈচিত্র্যের গুরুত্ব : </b>{" "}
                </font>{" "}
                একটি সমাজ তৈরি করতে গেলে বিভিন্ন পেশার লােকের দরকার হয়, যারা
                নিজ নিজ কাজের দ্বারা সমাজকে টিকিয়ে রাখে। তেমনি একটি
                বাস্তুতন্ত্রের সুস্থায়ীভাবে টিকে থাকার জন্য জীববৈচিত্র্যের
                প্রয়ােজন হয়, যেখানে প্রত্যেক প্রজাতি তাদের নিজস্ব ভূমিকা পালন
                করে। যে কারণগুলির জন্য জীববৈচিত্র্যের প্রয়ােজন হয় সেগুলি হল —
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. অর্থনৈতিক মূল্য : </b>{" "}
                </font>{" "}
                মানুষ তার খাদ্য, বস্ত্র, বাসস্থান, ওষুধ প্রভৃতির জন্য সরাসরি
                প্রকৃতির ওপর নির্ভরশীল। জীববৈচিত্র্যের জন্যেই মানুষ তার চাহিদা
                প্রকৃতি থেকে মেটাতে সক্ষম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. নান্দনিক মূল্য : </b>{" "}
                </font>{" "}
                বিভিন্ন প্রজাতির জীব প্রকৃতিকে বৈচিত্র্যময় ও সুন্দর করে তােলে।
                প্রকৃতির অপরূপ সৌন্দর্য উপভােগ করতে প্রতিবছর অসংখ্য মানুষ
                পাহাড়, সমুদ্র সৈকত, বনাঞ্চল, জাতীয় উদ্যান প্রভৃতিতে ভ্রমণ করতে
                যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. নৈতিক মূল্য : </b>{" "}
                </font>{" "}
                প্রত্যেক জীবের এই পৃথিবীতে বেঁচে থাকার অধিকার আছে। 1982
                খ্রিস্টাব্দে রাষ্ট্রপুঞ্জের বিশ্বপ্রকৃতি সম্পর্কিত ঘােষণাপত্রে
                এই চিন্তাধারা স্বীকৃতি পেয়েছে। তাই মানুষের কর্তব্য প্রতিটি
                প্রজাতির জীবকে এই পৃথিবীর বুকে বাঁচিয়ে রাখা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. পরিবেশ রক্ষা : </b>{" "}
                </font>{" "}
                প্রত্যেক জীব বাস্তুতান্ত্রিকভাবে একে অপরের ওপর নির্ভরশীল। একটি
                জীবের বিনাশ অন্য কোনাে জীবের বিপন্নতার কারণ হতে পারে। তাই
                মানুষের এই পৃথিবীতে বেঁচে থাকার প্রধান শর্ত হল এই জীববৈচিত্র্যকে
                বাঁচিয়ে রাখা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. আধ্যাত্মিক ও সাংস্কৃতিক মূল্য : </b>{" "}
                </font>{" "}
                বিভিন্ন ধর্মগ্রন্থে উদ্ভিদ ও প্রাণীর আধ্যাত্মিক ও সাংস্কৃতিক
                গুরুত্বের উল্লেখ আছে। হিন্দুধর্মে বিভিন্ন উদ্ভিদ যেমন-তুলসী, বট,
                অশ্বত্থ ইত্যাদি পূজোর বিধান আছে। হিন্দু দেবদেবীদের অনেকেরই বাহন
                হল বন্যপ্রাণী। সেইসব প্রাণীদের হত্যা করা ধর্মে নিষিদ্ধ। পৃথিবীর
                অন্যান্য ধর্মেও জীবের প্রতি অহিংসা প্রদর্শনের কথা বলা হয়েছে।
                তাই মানুষের আধ্যাত্মিক ও সাংস্কৃতিক চেতনা রক্ষায় জীববৈচিত্র্যের
                বিশেষ ভূমিকা বর্তমান।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ২. জীববৈচিত্র্যের ঢাল বলতে কী বােঝ? সংরক্ষণের আন্তর্জাতিক
                  সংস্থা (IUCN) অনুযায়ী সংকটময়তার নিরিখে প্রজাতিগুলি
                  শ্রেণিবিভাগ করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>জীববৈচিত্র্যের ঢাল : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                অক্ষাংশ এবং উচ্চতার পার্থক্যের জন্য জীববৈচিত্র্যের পরিবর্তন ঘটে।
                জীববৈচিত্র্যের পরিমাণ মেরু অঞ্চল (উচ্চ অক্ষাংশ) থেকে নিরক্ষীয়
                অঞলের (নিম্ন অক্ষাংশ) দিকে বেড়ে যায়। পরিবেশগত তারতম্যের কারণেও
                জীববৈচিত্র্যের পরিমাণের পরিবর্তন ঘটে। যেমন — নাতিশীতােয় অঞ্চলে
                ঋতুভিত্তিক জলবায়ু পরিবর্তনের জন্য উদ্ভিদগুলি স্বল্প সময়ের জন্য
                জন্মায়, নিরক্ষীয় অঞ্চলে জলবায়ুর পরিবর্তন খুব বেশি দেখা যায়
                না বলে উদ্ভিদগুলির জীবনকাল দীর্ঘ। সমতলভূমি থেকে যত উচ্চতা বাড়ে
                তত প্রতি 1,000 মিটার উচ্চতায় 6.5 ° সে. হারে তাপমাত্রা কমে। যার
                ফলে উচ্চতা বৃদ্ধির সাথে সাথে জীববৈচিত্র্যের পরিমাণ হ্রাস পায়।
                <br />
                <br />
                <font color="#29900a">
                  <b>
                    প্রকৃতি সংরক্ষণের আন্তর্জাতিক সংস্থা (IUCN) অনুযায়ী
                    সংকটময়তার নিরিখে প্রজাতির শ্রেণিবিভাগ :{" "}
                  </b>{" "}
                </font>{" "}
                প্রকৃতি সংরক্ষণের আন্তর্জাতিক সংস্থা International Union for
                Conservation of Nature and Natural Resources (IUCN) সংকটময়তার
                নিরিখে একটি লাল তালিকা (Red List) প্রকাশ করেছে। সংকটময়তার
                মাত্রা অনুযায়ী সংস্থাটি বিভিন্ন প্রাজতিগুলিকে 9টি ভাগে ভাগ
                করেছে। নীচে ভাগগুলি তালিকাকারে দেখানাে হল 一<br />
                <br />
                <font color="#f626d7">
                  <b>(১) বিলুপ্ত : </b>{" "}
                </font>{" "}
                বর্তমানে যেসব প্রজাতির অস্তিত্বের আর কোনাে প্রমাণ নেই। যেমন —
                ভারতে বিলুপ্ত প্রজাতি হল গােলাপি মাথাযুক্ত হাঁস, পাহাড়ি কোয়েল
                পাখি। IUCN-এর তথ্য অনুযায়ী 2011 খ্রিস্টাব্দ পর্যন্ত প্রায়
                727টি প্রজাতি বিলুপ্তি ঘটেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(২) বন্য অবস্থায় বিলুপ্ত : </b>{" "}
                </font>{" "}
                যেসব প্রজাতির বন্য বা সাধারণ পরিবেশে বিলুপ্তি ঘটেছে, কেবলমাত্র
                সংরক্ষণের মাধ্যমে তাদের অস্তিত্ব বজায় রয়েছে। IUCN-এর তথ্য
                অনুযায়ী 2011 খ্রিস্টাব্দ পর্যন্ত বন্য অবস্থায় বিলুপ্তি ঘটেছে
                64টি প্রজাতির।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(৩) চরম বিপন্ন : </b>{" "}
                </font>{" "}
                যেসব প্রজাতির প্রায় 80% প্রজাতি বিলুপ্ত হয়ে গেছে। IUCN-এর তথ্য
                অনুযায়ী 2011 খ্রিস্টাব্দ পর্যন্ত চরম বিপন্ন প্রজাতির সংখ্যা
                প্রায় 3,801।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(৪) বিপন্ন : </b>{" "}
                </font>{" "}
                অদূর ভবিষ্যতে বিলুপ্ত হওয়ার সম্ভাবনা রয়েছে এমন প্রজাতি। এদের
                70% প্রজাতির বিলুপ্তি ঘটে গেছে। IUCN-এর তথ্য অনুযায়ী 2011
                খ্রিস্টাব্দ পর্যন্ত বিপন্ন প্রজাতির সংখ্যা প্রায় 5,566।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(৫) বিপদপ্রবণ : </b>{" "}
                </font>{" "}
                যেসব প্রজাতির সংখ্যা দ্রুতহারে হ্রাস পাওয়ায় খুব তাড়াতাড়ি
                বিপন্ন প্রজাতিতে পরিণত হওয়ার সম্ভাবনা রয়েছে। এদের প্রায় 50%
                প্রজাতি বিলুপ্ত হয়ে গেছে। যেমনভারতে বিপদপ্রবণ প্রজাতি হল বাদামি
                শিংযুক্ত হরিণ, হিসপিড খরগােস। IUCN-এর তথ্য অনুযায়ী 2011
                খ্রিস্টাব্দ পর্যন্ত বিপদপ্রবণ প্রজাতির সংখ্যা 9,898।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(৬) প্রায় বিপদপ্রবণ : </b>{" "}
                </font>{" "}
                যেসব প্রজাতির ভবিষ্যতে বিপন্ন প্রজাতিতে পরিণত হওয়ার সম্ভাবনা
                রয়েছে। IUCN-এর তথ্য অনুযায়ী 2011 খ্রিস্টাব্দ পর্যন্ত প্রায়
                বিপদপ্রবণ প্রজাতির সংখ্যা প্রায় 4,273।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ৩. ইন-সিটু সংরক্ষণ ও এক্স-সিটু সংরক্ষণের মধ্যে পার্থক্য লেখো।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                <br />

                <table>
                  <colgroup>
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>বিষয়</b>
                          </font>
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>ইন-সিটু সংরক্ষণ</b>
                          </font>
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>এক্স-সিটু সংরক্ষণ</b>
                          </font>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">(i) সংজ্ঞা</font>
                        </span>
                      </td>
                      <td>
                        <span>
                          বিপন্ন এবং বিপদগ্রস্ত প্রজাতিকে তার নিজেদের আবাসস্থলে
                          প্রজাতিটির সংখ্যাবৃদ্ধি করে রক্ষা করার পদ্ধতিকে ইন-
                          সিটু সংরক্ষণ' বলা হয়।
                        </span>
                      </td>
                      <td>
                        <span>
                          অতি মাত্রায় বিপন্ন প্রজাতিকে নিজেদের আবাসস্থলের বাইরে
                          কৃত্রিমভাবে বংশ বৃদ্ধি করে সংরক্ষণের পদ্ধতিকে
                          ‘এক্স-সিটু সংরক্ষণ’ বলা হয়।
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">(ii) জীববৈচিত্র্য</font>
                        </span>
                      </td>
                      <td>
                        <span>
                          এক্ষেত্রে প্রজাতিগত, জিনগত ও বাস্তুতন্ত্রগত জীবের এক
                          সঙ্গে সংরক্ষণ করা হয়।
                        </span>
                      </td>
                      <td>
                        <span>
                          এক্ষেত্রে প্রজাতিগত, জিনগত ও বাস্তুতন্ত্রগত জীব
                          পৃথকভাবে সংরক্ষণ করা হয়।
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">(iii) বিস্তার</font>
                        </span>
                      </td>
                      <td>
                        <span>এর বিস্তার বেশি।</span>
                      </td>
                      <td>
                        <span>এর বিস্তার কম।</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">(iv) উদ্দেশ্য</font>
                        </span>
                      </td>
                      <td>
                        <span>জীববৈচিত্র্য সংরক্ষণের প্রধান উদ্দেশ্য।</span>
                      </td>
                      <td>
                        <span>
                          শিক্ষা, গবেষণা ও জীব সংরক্ষণের প্রধান উদ্দেশ্য।
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

জীববৈচিত্র্য.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(জীববৈচিত্র্য);
