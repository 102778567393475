import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function মিলেরপরীক্ষামূলকপদ্ধতি() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          মিলের পরীক্ষামূলক পদ্ধতি | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="মিলের পরীক্ষামূলক পদ্ধতি | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                মিলের পরীক্ষামূলক পদ্ধতি
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  উপস্থিতি ও অনুপস্থিতির সাদৃশ্য যে পদ্ধতিতে দেখা যায়, তা হল —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অন্বয়-ব্যতিরেকী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সহ-পরিবর্তন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্যতিরেকী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অন্বয়ী
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  মিলের কোন পদ্ধতি কারণের পরিমাণগত লক্ষণের উপর ভিত্তি করে
                  প্রতিষ্ঠিত?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অন্বয়-ব্যতিরেকী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সহ-পরিবর্তন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্যতিরেকী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অন্বয়ী
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  মিল কার্যকারণ সম্পর্কে নির্ণয়ের জন্য কয়টি পরীক্ষামূলক পদ্ধতি
                  উদ্ভাবন করেছেন?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দুটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পাঁচটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চারটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তিনটি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মিল কোন পদ্ধতিকে অপরোক্ষ ব্যতিরেকী পদ্ধতি বলেছেন?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সহ-পরিবর্তন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অন্বয়ী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্যতিরেকী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অন্বয়-ব্যতিরেকী
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  সদর্থক ও নঞর্থক দুই প্রকার দৃষ্টান্ত গুচ্ছের প্রয়োজন হয় কোন
                  পদ্ধতিতে?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অন্বয়-ব্যতিরেকী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সহ-পরিবর্তন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্যতিরেকী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অন্বয়ী
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কোন পদ্ধতিকে অন্বয়ী পদ্ধতির উন্নতরূপ বলা হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সহ-পরিবর্তন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অন্বয়ী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্যতিরেকী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অন্বয়-ব্যতিরেকী
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অন্বয়ী পদ্ধতির সিদ্ধান্ত হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিশ্লেষণাত্মক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সুনিশ্চিত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনিশ্চিত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সম্ভাব্য
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  অন্বয়ী পদ্ধতি অপসারণের কোন সূত্রের উপর ভিত্তি করে প্রতিষ্ঠিত?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দ্বিতীয় সূত্র
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তৃতীয় সূত্র
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চতুর্থ সূত্র
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  প্রথম সূত্র
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মিলের পদ্ধতিগুলি কোন সূত্রের উপর প্রতিষ্ঠিত?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অপনয় বা অপসারণ সূত্র
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উপনয় সূত্র
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কার্যকারণ নিয়ম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রকৃতির একরূপতা নীতি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সহ পরিবর্তন কয় প্রকারের হতে পারে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  দুটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তিনটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চারটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পাঁচটি
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b> একাদশ অধ্যায় - মিলের পরীক্ষামূলক পদ্ধতি</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  {" "}
                  ১. দৃষ্টান্ত সহ মিলের ব্যতিরেকী পদ্ধতিটি ব্যাখ্যা ও বিচার করো।
                  [সংজ্ঞা , আকার , দৃষ্টান্ত , সুবিধা (২ টি) , অসুবিধা (২ টি)]{" "}
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যুক্তিবিজ্ঞানী জন স্টুয়ার্ট মিল কোনো নির্দিষ্ট ঘটনার কোনো
                নির্দিষ্ট কারণ আবিষ্কার ও প্রমাণের জন্য পাঁচটি পরীক্ষামূলক
                পদ্ধতি প্রণয়ন করেন। পদ্ধতি পাঁচটি হল - অন্বয়ী পদ্ধতি,
                ব্যতিরেকী পদ্ধতি, অন্বয়-ব্যতিরেকী পদ্ধতি, সহ-পরিবর্তন পদ্ধতি
                এবং পরিশেষ পদ্ধতি। নীচে ব্যতিরেকী পদ্ধতি সম্বন্ধে আলোচনা করা হল।
                <br />
                <br />
                <font color="#2b9a09"> ⦿ </font>{" "}
                <font color="#f626d7">
                  <b> পদ্ধতিটির ব্যাখ্যা : </b>{" "}
                </font>{" "}
                ব্যতিরেকী পদ্ধতির সূত্রটিকে বিশ্লেষণ করলে দেখা যায় যে , এই
                পদ্ধতিকে প্রয়োগ করতে হলে কেবলমাত্র দু’টি দৃষ্টান্তের প্রয়োজন
                হয় - একটি হলো সদর্থক দৃষ্টান্ত এবং অপরটি হলো নঞর্থক দৃষ্টান্ত।
                পরীক্ষণের সাহায্যে এই দুই রকম দৃষ্টান্ত সংগৃহীত হয়। এই দু’টি
                দৃষ্টান্তের মধ্যে একটি মাত্র ঘটনার পার্থক্য থাকে এবং অন্যান্য
                আনুষঙ্গিক ঘটনাগুলির মধ্যে মিল থাকে। প্রথম দৃষ্টাত্তে একটিমাত্র
                বিশেষ ঘটনা উপস্থিত থাকলে আলোচ্য ঘটনাটিও উপস্থিত থাকে। আর
                দ্বিতীয় দৃষ্টান্তে একটিমাত্র ঘটনা অনুপস্থিত থাকলে আলোচ্য
                ঘটনাটিও অনুপস্থিত থাকে। এর থেকে সিদ্ধান্ত করা যায় যে ,
                দৃষ্টান্ত দু’টির মধ্যে যে ঘটনাটিকে কেন্দ্র করে পার্থক্য , সেই
                ঘটনাটিই হলো আলোচ্য ঘটনার কার্য বা কারণ বা কারণের অপরিহার্য অংশ।
                সুতরাং A হলো a-এর কারণ। <br />
                <br />
                এখানে ১ নং উদাহরণে পূর্ববর্তী ঘটনা থেকে A- কে বাদ দেওয়ার জন্যে
                অনুবর্তী ঘটনা থেকে a বাদ পড়লে এবং ২ য় উদাহরণে পূর্ববর্তী ঘটনার
                সঙ্গে A- কে যোগ করার ফলে অনুবর্তী ঘটনাতে a যোগ হলো। কিন্তু
                অন্যান্য সব অবস্থা অপরিবর্তিত। সুতরাং A হলো a- এর কারণ।
                <br />
                <br />
                <font color="#2b9a09"> ⦿ </font>{" "}
                <font color="#f626d7">
                  <b> বাস্তব উদাহরণ : </b>{" "}
                </font>{" "}
                একটি বায়ুপূর্ণ পাত্রে ঘণ্টা বাজিয়ে দেখা গেল যে , ঘণ্টার শব্দ
                হচ্ছে। কিন্তু পাত্রটিকে বায়ুশূন্য করে ঘণ্টা বাজিয়ে দেখা গেল যে
                , ঘণ্টার শব্দ হচ্ছে না। এর থেকে অনুমান করা যায় যে , ‘ বায়ুর
                উপস্থিতি হলো শব্দের কারণ। ‘ <br />
                <br />
                <font color="#2b9a09"> ⦿ </font>{" "}
                <font color="#f626d7">
                  <b> দৃষ্টান্ত : </b>{" "}
                </font>{" "}
                শরীরের অন্যান্য অঙ্গপ্রত্যঙ্গের সঙ্গে মস্তিষ্কে থাইরয়েড গ্রন্থি
                বর্তমান থাকলে বুদ্ধি বর্তমান থাকে। কিন্তু অস্ত্রপ্রচারের
                সাহায্যে মস্তিষ্ক থেকে থাইরয়েড গ্রন্থি বাদ দিলে বুদ্ধি লুপ্ত
                হয়ে যায়। এর থেকে সিদ্ধান্ত করা যায় যে, থাইরয়েড গ্রন্থি হল
                বুদ্ধির কারণ।
                <br />
                <br />
                <font color="#2b9a09"> ⦿ </font>{" "}
                <font color="#f626d7">
                  <b> ব্যতিরেকী পদ্ধতির সুবিধা : </b>{" "}
                </font>{" "}
                <br />
                1. ব্যতিরেকী পদ্ধতি হলো মূলত পরীক্ষামূলক পদ্ধতি। এই জন্য এই
                পদ্ধতি নিশ্চিতভাবে কার্য – কারণ সম্পর্ক প্রমাণ করতে পারে। <br />
                2. অন্যান্য পদ্ধতির সাহায্যে পাওয়া সিদ্ধান্তের পরীক্ষণ ও প্রমাণ
                এই পদ্ধতির সাহায্যে করা যেতে পারে। <br />
                3. প্রয়োগের দিক থেকে এই পদ্ধতি খুবই সহজ ও সরল। কারণ এই পদ্ধতির
                প্রয়োগের জন্য একটি সদর্থক ও একটি নঞর্থক দৃষ্টান্ত প্রয়োজন হয়।{" "}
                <br />
                4. ব্যতিরেকী পদ্ধতি যথাযথভাবে প্রয়োগ করতে পারলে নিশ্চিত
                সিদ্ধান্তে পৌঁছানো সম্ভব। <br />
                <br />
                <font color="#2b9a09"> ⦿ </font>{" "}
                <font color="#f626d7">
                  <b> ব্যতিরেকী পদ্ধতির অসুবিধা : </b>{" "}
                </font>{" "}
                <br />
                1. ব্যতিরেকী পদ্ধতির প্রয়োগক্ষেত্র খুবই সীমিত। যে সকল ক্ষেত্রে
                পরীক্ষণ সম্ভব নয় সেই সব ক্ষেত্রে এই পদ্ধতি প্রয়োগ করা যায় না।{" "}
                <br />
                2. এই পদ্ধতির সাহায্যে কারণ থেকে কার্যে যাওয়া যায় , কিন্তু
                কার্য থেকে কারণে সোজাসুজি আসা যায় না। <br />
                3. ব্যতিরেকী পদ্ধতিকে অসতর্কভাবে প্রয়োগ করলে কাকতালীয় দোষ দেখা
                দেয়। ধূমকেতু দেখা গেল এবং তার পরেই যুদ্ধ আরম্ভ হলো— এটি একটি
                ব্যতিরেকী পদ্ধতির ভুল প্রয়োগ। <br />
                4. ব্যতিরেকী পদ্ধতি কারণ ও শর্তের মধ্যে পার্থক্য করতে পারে না।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  {" "}
                  ২. মিলের সহপরিবর্তন পদ্ধতিটি আলোচনা করো। [ সংজ্ঞা, আকার,
                  সুবিধা (২ টি) , অসুবিধা (২ টি)]{" "}
                </b>
              </font>
              <br />
              <br />
              <font color="#2b9a09"> ⦿ </font>{" "}
              <font color="#3c92e5">
                <font color="#f626d7">
                  <b> সহপরিবর্তন পদ্ধতির সূত্র : </b>{" "}
                </font>{" "}
                যখন কোনো একটি ঘটনা একভাবে পরিবর্তিত হয় , অন্য একটি ঘটনা তখন যদি
                একইভাবে পরিবর্তিত হয় , তবে পূর্ববর্তী ঘটনাটি পরবর্তী ঘটনার কারণ
                বা কারণাংশ বা ঘটনা দু’টি কার্যকারণ সম্বন্ধে যুক্ত।
                <br />
                <br />
                <font color="#f626d7">
                  <b> সহপরিবর্তন সূত্রের ভিত্তি : </b>{" "}
                </font>{" "}
                অপসারণ পদ্ধতির সূত্রানুযায়ী যদি একটি ঘটনার হ্রাস – বৃদ্ধি হয় ,
                অন্য একটি ঘটনার হ্রাস – বৃদ্ধি না হয় , তবে বুঝতে হবে ঘটনা
                দু’টির মধ্যে কোনো কার্যকারণ সম্বন্ধ নেই। অপসারণ পদ্ধতির এই
                সুত্রের উপর ভিত্তি করে সহপরিবর্তন পদ্ধতি প্রতিষ্ঠিত। <br />
                <br />
                কারণের পরিমাণগত লক্ষণের উপর ভিত্তি করেও এই পদ্ধতিটি প্রতিষ্ঠিত।
                পরিমাণগত লক্ষণ বলতে বোঝায় কারণ ও কার্য পরিমাণের দিক থেকে সমান।
                অর্থাৎ কারণ ও কার্যের মধ্যে সমান পরিমাণ শক্তি থাকে। এই কারণে
                কারণের পরিমাণের হ্রাস – বৃদ্ধি ঘটলে কার্যের পরিমাণেরও হ্রাস –
                বৃদ্ধি ঘটে। তাই বলা যায় , পূর্ববর্তী ঘটনার হ্রাস – বৃদ্ধির ফলে
                যদি পরবর্তী ঘটনার হ্রাস – বৃদ্ধি ঘটে , তবে পূর্ববর্তী ঘটনা
                অবশ্যই পরবর্তী ঘটনার কারণ। <br />
                <br />
                <font color="#2b9a09"> ⦿ </font>{" "}
                <font color="#f626d7">
                  <b> সহপরিবর্তনের দু’টি রূপ : </b>{" "}
                </font>{" "}
                <br />
                <font color="#1f7405">
                  <b>1. একমুখী হ্রাসবৃদ্ধি : </b>{" "}
                </font>{" "}
                একমুখী হ্রাস – বৃদ্ধি বলতে বোঝায় , পূর্ববর্তী ঘটনার বৃদ্ধির
                সাথে সাথে পরবর্তী ঘটনা বৃদ্ধি পায় ; আবার পূর্ববর্তী ঘটনা হ্রাস
                পেলে পরবর্তী ঘটনাও হ্রাস পায়। <br />
                <br />
                <font color="#f626d7">
                  <b> উদাহরণ : </b>{" "}
                </font>{" "}
                আবহাওয়া যতই উত্তপ্ত হয় , ব্যারোমিটারের পারদস্তম্ভের উচ্চতা ততই
                বৃদ্ধি পায়। আবার উত্তাপের পরিমাণ যতই কমতে থাকে, তাপমান যন্ত্রের
                পারদস্তম্ভের উচ্চতা ততই কমতে থাকে। সুতরাং উত্তাপ বৃদ্ধি
                পারদস্তত্তের বৃদ্ধির কারণ। <br />
                <br />
                <font color="#1f7405">
                  <b>2. বিপরীতমুখী হ্রাসবৃদ্ধি : </b>{" "}
                </font>{" "}
                এক্ষেত্রে পূর্ববর্তী ঘটনার বৃদ্ধি ঘটলে পরবর্তী ঘটনা হ্রাস পায়।
                আবার পূর্ববর্তী ঘটনা হ্রাস পেলে পরবর্তী ঘটনা বৃদ্ধি পায়। <br />
                <br />
                <font color="#2b9a09"> ⦿ </font>{" "}
                <font color="#f626d7">
                  <b> উদাহরণ : </b>{" "}
                </font>{" "}
                ভোগ্যপণ্যের দাম যতই বৃদ্ধি পায় , তার চাহিদা ততই হ্রাস পায় ;
                আবার দাম যতই হ্রাস পায় চাহিদা ততই বৃদ্ধি পায়। সুতরাং
                ভোগ্যপণ্যের মূল্যের সঙ্গে চাহিদার কার্যকারণ সম্বন্ধ আছে। <br />
                <br />
                <font color="#2b9a09"> ⦿ </font>{" "}
                <font color="#f626d7">
                  <b> সহপরিবর্তন পদ্ধতির সুবিধা : </b>{" "}
                </font>
                <br /> <br />
                1. ব্যতিরেকী পদ্ধতিতে না – বাচক দৃষ্টান্তে কারণকে পুরোপুরি বাদ
                দিতে হয়। কিন্তু উত্তাপ , মাধ্যাকর্ষণ প্রভৃতি স্থায়ী কারণকে
                পুরোপুরি বাদ দেওয়া যায় না। এই ক্ষেত্রে সহপরিবর্তন পদ্ধতি
                প্রয়োগ করে দু’টি ঘটনার মধ্যে কার্যকারণ সম্বন্ধ নির্ণয় করা
                সম্ভব হয়। অর্থাৎ সহপরিবর্তন পদ্ধতি ব্যতিরেকী পদ্ধতির পরিপূরক
                হিসাবে কাজ করতে পারে। <br />
                <br />
                2. সহপরিবর্তন পদ্ধতির দু’টি উদ্দেশ্য- কার্যকারণ সম্বন্ধ খুঁজে
                বের করা , কার্যকারণের পরিমাণগত অনুপাত নির্ণয় করা। <br />
                <br />
                3. বৈজ্ঞানিক আবিষ্কার ও সিদ্ধান্ত প্রতিষ্ঠার ক্ষেত্রে অন্যান্য
                পদ্ধতির উপরে এই পদ্ধতির বিশেষ মূল্য আছে।
                <br />
                <br />
                <font color="#2b9a09"> ⦿ </font>{" "}
                <font color="#f626d7">
                  <b> সহপরিবর্তন পদ্ধতির অসুবিধা : </b>{" "}
                </font>{" "}
                <br />
                <br />
                1. যে সব ঘটনার হ্রাস – বৃদ্ধি অর্থাৎ পরিমাণগত তারতম্য লক্ষ করা
                যায়, শুধুমাত্র সেসব ঘটনার ক্ষেত্রেই এই পদ্ধতি প্রয়োগ করা চলে।
                স্থিতিশীল ঘটনার ক্ষেত্রে এই পদ্ধতি অচল। <br />
                <br />
                2. ঘটনার পরিমাণগত পরিবর্তনের ক্ষেত্রে এই পদ্ধতি কার্যকরী। গুণগত
                বা জাতিগত পরিবর্তনের ক্ষেত্রে এই পদ্ধতি প্রয়োগ করা যায় না।{" "}
                <br />
                <br />
                3. এই পদ্ধতি পরিমাণগত হ্রাস – বৃদ্ধির একটি নির্দিষ্ট সীমা
                পর্যন্ত কাজ করে। এই সীমার বাইরে এই পদ্ধতি কাজ করে না।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  {" "}
                  3. মিলের অন্বয়ী পদ্ধতি আলোচনা কর। [সংজ্ঞা, আকার, দৃষ্টান্ত,
                  সুবিধা (২টি) অসুবিধা (২টী)] (2 + 1 + 1 + 2 + 2)
                  <br />
                  <br />
                  অথবা, <br />
                  একটি বনভোজনে যারা যারা মাংস খেয়েছিল তাদের প্রত্যেকেই বদহজম
                  হয়েছিল। সুতরাং মাংস খাওয়াই বদহজমের কারণ। - এই যুক্তিতে মিলের
                  কোন পদ্ধতি প্রয়োগ করা হয়েছে? পদ্ধতিটির ব্যাখ্যা কর।
                  [চিহ্নিতকরণ, সংজ্ঞা, আকার, সুবিধা (দুটি), অসুবিধা (দুটি)] (1 +
                  2 + 1 + 2 + 2)
                </b>
              </font>
              <br />
              <br />
              <font color="#2b9a09">
                <b> ⦿ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                <font color="#f626d7">
                  <b>চিহ্নিতকরণ : </b>{" "}
                </font>{" "}
                উদ্ধৃত যুক্তিটিতে মিলের অন্বয়ী পদ্ধতি প্রয়োগ করা হয়েছে।
                <br />
                <br />
                <font color="#2b9a09">
                  <b> ⦿ </b>{" "}
                </font>{" "}
                <font color="#f626d7">
                  <b> সূত্র বা সংজ্ঞা : </b>{" "}
                </font>{" "}
                মিল প্রদত্ত অন্বয়ী পদ্ধতির সূত্র বা সংজ্ঞা হলো--- " আলোচ্য
                ঘটনার দুই বা ততোধিক দৃষ্টান্তে যদি একটি মাত্র ঘটনা সাধারণভাবে
                উপস্থিত থাকে এবং এই সাধারণ ঘটনার সম্বন্ধে যদি দৃষ্টান্ত গুলির
                মধ্যে মিল থাকে তাহলে সেই সাধারণ ঘটনাটি হবে আলোচ্য ঘটনার কারণ বা
                কার্য বা কারণের অপরিহার্য অংশ।"
                <br />
                <br />
                <font color="#2b9a09">
                  <b> ⦿ </b>{" "}
                </font>{" "}
                <font color="#f626d7">
                  <b> সূত্রটির বিশ্লেষণ ও ব্যাখ্যা : </b>{" "}
                </font>{" "}
                অন্বয়ী পদ্ধতি প্রয়োগের জন্য দুই বা ততোধিক দৃষ্টান্তের প্রয়োজন
                হয় এবং দৃষ্টান্ত গুলি পর্যবেক্ষণের সাহায্যে সংগ্রহীত হয়।
                প্রতিটি দৃষ্টান্তেই অগ্রবর্তী এবং অনুবর্তী ঘটনায় একটি মাত্র
                বিষয়ের উপস্থিতির মিল থাকবে। আর ঐ একটি মাত্র বিষয়ের উপস্থিতি
                ছাড়া অন্য সব বিষয়ে দুটি ঘটনার মধ্যে অমিল থাকবে। এই উপস্থিতির
                মিল লক্ষ্য করে সিদ্ধান্ত করা হবে যে বিষয়ে দুটির মধ্যে কার্যকারণ
                সম্পর্ক আছে।
                <br />
                <font color="#2b9a09">
                  <b> ⦿ </b>{" "}
                </font>{" "}
                <font color="#f626d7">
                  <b> সাংকেতিক উদাহরণ/ আকার : </b>{" "}
                </font>{" "}
                <br />
                <table>
                  <colgroup>
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>অগ্রবর্তী ঘটনা </b>
                          </font>
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>অনুবর্তী ঘটনা</b>
                          </font>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">ABC</font>
                        </span>
                      </td>
                      <td>
                        <span> abc</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">ADE </font>
                        </span>
                      </td>
                      <td>
                        <span>ade</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">AFG </font>
                        </span>
                      </td>
                      <td>
                        <span>afg </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                সুতরাং A হল a এর কারণ বা a হল A এর কার্য। কেননা, 'A' সকল
                দৃষ্টান্তই সমভাবে উপস্থিত এবং BCDEF প্রভৃতি ঘটনাগুলি কোন কোন
                দৃষ্টান্তে উপস্থিত আছে, আবার কোনো কোনো দৃষ্টান্ত উপস্থিত নেই।
                <br />
                <br />
                <font color="#2b9a09">
                  <b> ⦿ </b>{" "}
                </font>{" "}
                <font color="#f626d7">
                  <b> দৃষ্টান্ত /বাস্তব উদাহরণ : </b>{" "}
                </font>{" "}
                অন্বয়ী পদ্ধতির বাস্তব দৃষ্টান্ত হলো -<br />
                <br />
                <table>
                  <colgroup>
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>দৃষ্টান্ত</b>
                          </font>
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>অগ্রবর্তী ঘটনা</b>
                          </font>
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>অনুবর্তী ঘটনা</b>
                          </font>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">১নং </font>
                        </span>
                      </td>
                      <td>
                        <span>মাংস+ ভাত +ডাল </span>
                      </td>
                      <td>
                        <span>বদহজম হওয়া</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">২নং</font>
                        </span>
                      </td>
                      <td>
                        <span>মাংস+রুটি +সবজি</span>
                      </td>
                      <td>
                        <span>বদহজম হওয়া</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7"> ৩নং</font>
                        </span>
                      </td>
                      <td>
                        <span>মাংস+ নান +চাটনি</span>
                      </td>
                      <td>
                        <span>বদহজম হওয়া</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                সুতরাং মাংস খাওয়া (A) হলো বদহজম হওয়ার (a) কারণ। কেননা, বদ হজম
                হওয়া কার্যটির অগ্রবর্তী ঘটনায় কেবল মাংস খাওয়া ঘটনাটি সকল
                দৃষ্টান্তে উপস্থিত আছে কিন্তু অন্যান্য আনুষঙ্গিক ঘটনাগুলি সব
                দৃষ্টান্ত উপস্থিত নেই।
                <br />
                <br />
                <font color="#2b9a09">
                  <b> ⦿ </b>{" "}
                </font>{" "}
                <font color="#f626d7">
                  <b> অন্বয়ী পদ্ধতির সুবিধা : </b>{" "}
                </font>{" "}
                অন্বয়ী পদ্ধতির দুটি সুবিধা হল - <br />
                <br />
                (১) যে সকল ক্ষেত্রে পরীক্ষণ সম্ভব নয় সেই সকল ক্ষেত্রে কার্যকারণ
                সম্বন্ধ আবিষ্কারের একমাত্র পদ্ধতি হলো অন্বয়ী পদ্ধতি। যেমন, ঝড়,
                ভূমিকম্প, মহামারী প্রভৃতি ঘটনার কারণ আবিষ্কারের একমাত্র পদ্ধতি
                হলো অন্বয়ী পদ্ধতি।
                <br />
                (২ অন্বয়ী পদ্ধতির সাহায্যে কারণ থেকে কার্য এবং কার্য থেকে কারণ
                আবিষ্কার করা যায়।
                <br />
                <br />
                <font color="#2b9a09">
                  <b> ⦿ </b>{" "}
                </font>{" "}
                <font color="#f626d7">
                  <b> অসুবিধা / ত্রুটি : </b>{" "}
                </font>{" "}
                অন্বয়ী পদ্ধতির দুটি অসুবিধা হলো -<br />
                <br />
                (১) অন্বয়ী পদ্ধতি প্রয়োগের দ্বারা বহুকারণের সম্ভাবনাকে দূর করা
                যায় না; অর্থাৎ, অন্বয়ী পদ্ধতি বহু কারণবাদের দোষে দুষ্ট।
                <br />
                (২) অন্বয়ী পদ্ধতি পর্যবেক্ষণের উপর নির্ভরশীল। তাই কারণ
                অনুসন্ধানের ক্ষেত্রে প্রকৃত কারণ সর্বদা অপর্যবেক্ষণের সম্ভাবনা
                থাকে। অর্থাৎ অন্বয়ী পদ্ধতি সর্বদা অপর্যবেক্ষণ দোষে দুষ্ট হতে
                পারে।
                <br />
                <br />
                <font color="#2b9a09">
                  <b> ⦿ </b>{" "}
                </font>{" "}
                <font color="#f626d7">
                  <b>মূল্যায়ন : </b>{" "}
                </font>{" "}
                মিল যদিও দাবি করেছেন অন্বয়ী পদ্ধতি কার্যকারণ সম্বন্ধ আবিষ্কারের
                ও প্রমাণের পদ্ধতি, তবুও উপরের আলোচনা থেকে বোঝা যায় এই পদ্ধতি
                কার্যকারণ সম্বন্ধে ইঙ্গিত দেয় মাত্র, সুনিশ্চিতভাবে কার্যকারণ
                সম্বন্ধ প্রমাণ করতে পারে না। তাই মিলের অন্বয়ী পদ্ধতি নিঃসন্দেহে
                আবিষ্কারের পদ্ধতি কিন্তু কখনোই প্রমাণের পদ্ধতি নয়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

মিলেরপরীক্ষামূলকপদ্ধতি.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(মিলেরপরীক্ষামূলকপদ্ধতি);
