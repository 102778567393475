import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function ভারতেরবিচারবিভাগ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          ভারতের বিচার বিভাগ | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="ভারতের বিচার বিভাগ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                ভারতের বিচার বিভাগ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতে রাজন্য ভাতা বিলোপ হয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1987 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1990 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1971 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1980 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ভারতীয় সংবিধান অনুযায়ী মৌলিক অধিকার রক্ষার জন্য নাগরিকগণ
                  সুপ্রিমকোর্ট এর কাছে আবেদন জানাতে পারে -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  32 নং ধারায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  258 নং ধারায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  226 নং ধারায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  260 নং ধারায়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সুপ্রিমকোর্ট গোলকনাথ বনাম পাঞ্জাব রাজ্য মামলার রায় দেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1987 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1967 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1971 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1980 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সুপ্রিমকোর্টের গঠনের বিষয়ে বলা আছে সংবিধানের —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  130 নং ধারায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  132 নং ধারায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  120 নং ধারায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  124 নং ধারায়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>পশ্চিমবঙ্গ ছাড়া কলকাতা হাইকোর্টের এলাকায় রয়েছে —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ত্রিপুরা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মেঘালয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আসাম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আন্দামান ও নিকোবর দ্বীপপুঞ্জ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  মিনার্ভা মিলস্ ও অন্যান্য বনাম ভারত সরকার মামলায় সুপ্রিমকোর্ট
                  রায় দেন —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1987 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1990 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1971 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1980 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কলকাতায় সুপ্রিমকোর্ট স্থাপিত হয়েছিল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1916 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1774 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1700 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1800 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতের সুপ্রিমকোর্টের বিচারপতিগণ নিযুক্ত হন -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জনগণ দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিচার বিভাগ দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আইন বিভাগ দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  শাসন বিভাগ দ্বারা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>রাষ্ট্রপতি সুপ্রিমকোর্টের কাছে পরামর্শ চান সংবিধানের —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  145 নং ধারা অনুসারে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  140 নং ধারা অনুসারে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  132 নং ধারা অনুসারে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  143 নং ধারা অনুসারে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতের সর্বোচ্চ আপিল আদালত হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সুপ্রিমকোর্ট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লোক আদালত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্রেতা সুরক্ষা আদালত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হাইকোর্ট
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>নবম অধ্যায় - ভারতের বিচার বিভাগ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. অধস্তন আদালত বলতে কী বোঝো?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                ভারতের অখণ্ড বিচারব্যবস্থায়, নীচতর পর্যায়ের আদালতগুলিকে
                "অধস্তন আদালত" বলা হয়। এগুলির মধ্যে অন্তর্ভুক্ত আছে —<br />
                (a) জেলার অধস্তন আদালত,
                <br />
                (b) মহানগরীয় অঞ্চলের অধস্তন আদালত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. ভারতের বিচার ব্যবস্থার দুটি বৈশিষ্ট্য লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ভারতের বিচার ব্যবস্থার দুটি প্রধান বৈশিষ্ট্য হল —<br />
                (a) ভারতের বিচার ব্যবস্থা অখণ্ড প্রকৃতির মতো, যেটি মিশরের
                পিরামিডের সামর্থ্য অনুকরণ করে। <br />
                (b) সমগ্র ভারতে একই আইন ও ফৌজদারি ব্যবস্থা প্রয়োগ করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. ট্রাইব্যুনাল কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ট্রাইব্যুনাল হল আইন নির্দিষ্ট পদ্ধতিতে গঠিত অস্থায়ী আদালত, যার
                উদ্দেশ্য হল নির্দিষ্ট বিষয়ে বিচার করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. বিচার বিভাগীয় স্বাতন্ত্র্য বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                বিচার বিভাগীয় স্বাতন্ত্র্য বলতে বোঝায় আইন বিভাগ ও শাসন বিভাগ
                থেকে বিচার বিভাগকে প্রভাব মুক্ত রাখা। বিচার বিভাগীয়
                স্বাতন্ত্র্য বিচার বিভাগের নিরপেক্ষতা বজায় রাখার জন্য একান্ত
                জরুরি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. সুপ্রিমকোর্টের মূল এলাকাভুক্ত দুটি বিষয় উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                সুপ্রিম কোর্টের মূল এলাকাভুক্ত দুটি বিষয় হল -<br />
                (a) কেন্দ্রীয় সরকারের সঙ্গে এক বা একাধিক রাজ্যের মধ্যে উত্থিত
                বিরোধের মীমাংসা,
                <br />
                (b) দুই বা ততোধিক রাজ্যের মধ্যে পারস্পরিক বিরোধের মীমাংসা।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. সুপ্রিমকোর্টের প্রধান বিচারপতি কীভাবে নিযুক্ত হন?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                রাষ্ট্রপতি সুপ্রিমকোর্টের প্রধান বিচারপতিকে নিযুক্ত করেন। তবে
                এক্ষেত্রে তিনি প্রযোজনমতো সুপ্রিমকোর্টের অন্যান্য বিচারপতি ও
                হাইকোর্টের প্রধান বিচারপতিদের সঙ্গে পরামর্শ করতে পারেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. লোক আদালত কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ভারতের বিচার ব্যবস্থাকে সমাজের সাধারণ মানুষের কাছে স্বল্প ব্যয়ে
                ও স্বল্প সময়ে পৌঁছে দেওয়ার এক প্রগতিশীল পদক্ষেপ হল লোক আদালত।
                একে জনগণের আদালতও বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    8. আইনের যথাবিহিত পদ্ধতি বা Due Process of Law বলতে কী
                    বোঝায়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                আইনের যথাবিহিত পদ্ধতি অনুসারে, সুপ্রিম কোর্ট দেখে যে আইনটি যথাযথ
                সাংবিধানিক পদ্ধতি অনুসরণ করে তা কি না, তা ছাড়াও সম্প্রতি আইনটি
                স্বাভাবিক ন্যায়-নীতি লঙ্ঘন করেছে কি না, এই বিষয়টিও সুপ্রিম
                কোর্ট বিচার করে। যদিও মার্কিন সুপ্রিম কোর্টের ন্যায়-নীতি
                লঙ্ঘনের বিষয়টির বিচারে তার ক্ষমতা থাকতে পারে, ভারতের সুপ্রিম
                কোর্টের এই নির্দিষ্ট ক্ষমতা নেই।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    9. সুপ্রিমকোর্ট বা হাইকোর্টের বিচারকদের কোন্ পদ্ধতিতে
                    পদচ্যুত করা যায়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যদি সংসদের উভয় কক্ষের মোট সদস্যের সংখ্যা গড়িষ্ঠতায় এবং উপস্থিত
                এবং ভোটদানকারী সদস্যের বড়দিকের সমর্থনে একটি বিচারপতির বিরুদ্ধে
                অনুসৃত প্রস্তাব গৃহীত হলে, তবে তাকে রাষ্ট্রপতি পদচ্যুত করার জন্য
                প্রয়োজনীয় বোধ করা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>10. আপিল এলাকা বলতে কী বোঝায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                সুপ্রিমকোর্টের যে এলাকায় নিম্ন আদালতের রায়ের বিরুদ্ধে আপিল করা
                যায়, সেই এলাকাকেই আপিল এলাকা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>11. গোলকনাথ মামলায় সুপ্রিমকোর্ট কী রায় দেয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                1967 খ্রিস্টাব্দে গোলকনাথ মামলায় সুপ্রিমকোর্ট রায় দেয় যে
                পার্লামেন্ট মৌলিক অধিকার বিরোধী কোনো আইন প্রণয়ন করতে পারবে না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    12. হাইকোর্টের বিচারকদের বেতন, ভাতা কোন তহবিল থেকে প্রদান
                    করা হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                রাজ্যের সঞ্চিত তহবিল থেকে হাইকোর্টের বিচারকদের বেতন ও ভাতা
                প্রদান করা হয়। <br />
                <br />
                <font color="#f626d7">
                  <b>13. ক্রেতা আদালতে কি আইনজীবীর প্রয়োজন হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ক্রেতা আদালতে মামলা দায়ের করার জন্য কোনো আইনজীবীর প্রয়োজন হয়
                না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. ন্যায় পঞ্চায়েত কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ন্যায় পঞ্চায়েত হল গ্রাম পঞ্চায়েতের আদালত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. অভিলেখ আদালত কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে সকল আদালত (হাইকোর্ট, সুপ্রিমকোর্ট) নিজেদের অবমাননার জন্য
                শাস্তি দিতে পারে, তাকে অভিলেখ আদালত বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>16. ক্রেতা আদালতের ক-টি স্তর রয়েছে ও কী কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ক্রেতা আদালতের তিনটি স্তর রয়েছে। এগুলি হল –<br />
                (a) জেলা স্তরে জেলা ক্রেতা আদালত, <br />
                (b) রাজ্য স্তরে রাজ্য ক্রেতা আদালত এবং <br />
                (c) জাতীয় স্তরে জাতীয় ক্রেতা আদালত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. মিনার্ভা মিলস্ মামলায় সুপ্রিমকোর্ট কী রায় দেয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                1980 খ্রিস্টাব্দে মিনার্ভা মিলস্ মামলায় সুপ্রিমকোর্ট রায় দেয়
                যে, পার্লামেন্ট সংবিধান সংশোধনের মাধ্যমে সংবিধানের মৌল কাঠামোর
                কোনো পরিবর্তন করতে পারবে না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. ক্রেতা আদালত কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                কোনো ক্রেতা কোনো বস্তু বা পরিসেবা কিনতে গিয়ে যখন প্রতারিত বা
                বঞ্চিত হয়, তখন যে আইনগত প্রতিষ্ঠানের মাধ্যমে তিনি প্রতিকার পেতে
                পারেন, তাকে বলা হয় ক্রেতা আদালত বা ক্রেতা সুরক্ষা আদালত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. সুপ্রিমকোর্টের এক্তিয়ার বা এলাকাগুলি কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                সুপ্রিমকোর্টের এক্তিয়ার বা এলাকাগুলিকে চার ভাগে ভাগ করা যায়।
                যথা —<br />
                (a) মূল এলাকা, (b) আপিল এলাকা, (c) পরামর্শদান এলাকা, (d) লেখ
                জারির এলাকা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. মার্কিন বিচারবিভাগীয় সমীক্ষার সঙ্গে ভারতীয়
                    বিচারবিভাগীয় সমীক্ষার পার্থক্য দেখাও।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                বিচারবিভাগীয় সমীক্ষার মধ্য দিয়ে মার্কিন ও ভারতীয় উভয় বিচার
                বিভাগ দেখে আইনটি উপযুক্ত কর্তৃপক্ষ কর্তৃক এবং যথাযথ পদ্ধতিতে
                প্রণীত হয়েছে কি না। তবে আইনটি ন্যায়সংগত বা যুক্তিসংগত কি না তা
                বিচার করে দেখতে পারে মার্কিন সুপ্রিমকোর্ট, কিন্তু ভারতীয়
                সুপ্রিমকোর্টের এই ক্ষমতা নেই।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. হাইকোর্টের বিচারপতি পদে নিযুক্ত হওয়ার যোগ্যতা কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                হাইকোর্টের বিচারপতি পদে নিযুক্ত হওয়ার যোগ্যতা হল – <br />
                (a) প্রার্থীকে অবশ্যই ভারতীয় নাগরিক হতে হবে। <br />
                (b) ভারতের যে-কোনো বিচারালয়ে কমপক্ষে 10 বছর যে-কোনো
                বিচারবিভাগীয় পদে কাজের অভিজ্ঞতা থাকতে হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    22. সুপ্রিমকোর্টের বিচারপতি পদপ্রার্থীর দুটি যোগ্যতা লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                সুপ্রিমকোর্টের বিচারপতি পদপ্রার্থীর দুটি যোগ্যতা হল — <br />
                (a) প্রার্থীকে অবশ্যই ভারতীয় নাগরিক হতে হবে। <br />
                (b) তাঁকে অন্তত একাধিক্রমে পাঁচ বছর কোনো হাইকোর্টের বিচারপতি
                হিসেবে কাজ করতে হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. হাইকোর্টের মূল এলাকা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                রাজস্ব সংক্রান্ত যাবতীয় বিচার হাইকোর্টের মূল এলাকায় স্থান
                পেয়েছে। কেবলমাত্র কলকাতা, চেন্নাই ও মুম্বাই হাইকোর্টের এই
                ক্ষমতা রয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. কেশবানন্দ ভারতী মামলায় সুপ্রিমকোর্ট কী রায় দেয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                1973 খ্রিস্টাব্দে কেশবানন্দ ভারতী মামলায় সুপ্রিমকোর্ট রায় দেয়
                যে পার্লামেন্ট সংবিধানের মৌল কাঠামো ছাড়া অন্য সব অংশের পরিবর্তন
                করতে পারবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. বিশেষ অনুমতির মাধ্যমে আপিল বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের যে-কোনো আদালতের রায়ের বিরুদ্ধে সুপ্রিমকোর্ট আপিল করার
                বিশেষ অনুমতি দিলে, সেই আপিলকে বিশেষ অনুমতির মাধ্যমে আপিল বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  26. ভারতের বিচার বিভাগের নিরপেক্ষতা রক্ষায় দুটি সাংবিধানিক
                  ব্যবস্থার উল্লেখ করো।
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতের বিচার বিভাগের নিরপেক্ষতা রক্ষার দুটি গৃহীত পদক্ষেপ হল –{" "}
                <br />
                (a) কেবল প্রমাণিত ‘অসদাচরণ’ ও ‘অসমর্থ’ এই কারণেই কোনো বিচারপতিকে
                তাঁর পদ থেকে অপসারণ করা যায়। <br />
                (b) বিচারপতিদের বেতন, ভাতা প্রভৃতি ভারতের সঞ্চিত তহবিল থেকে
                প্রদান করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. রাষ্ট্রপতি কখন সুপ্রিমকোর্টের পরামর্শ গ্রহণ করেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                রাষ্ট্রপতি যদি মনে করেন আইন বা তথ্য-সংক্রান্ত বিষয়ে কোনো
                গুরুত্বপূর্ণ প্রশ্ন দেখা দিয়েছে বা দিতে পারে, তাহলে তিনি
                সুপ্রিমকোর্টের পরামর্শ চাইতে পারেন। (১৪৩ নং ধারা)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    28. মৌলিক অধিকার রক্ষার জন্য আদালত ক-টি ও কী কী লেখ জারি
                    করতে পারে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                মৌলিক অধিকার রক্ষার জন্য আদালত 5টি লেখ জারি করতে পারে। এগুলি হল
                —<br />
                (a) বন্দি প্রত্যক্ষীকরণ, (b) পরমাদেশ, (c) প্রতিষেধ, (d)
                অধিকারপ্‌চ্ছা, (e) উৎপ্রেষণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. ভারতের অখণ্ডতা বিচারব্যবস্থা বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের বিচার ব্যবস্থার প্রকৃতি মিশরের পিরামিডের মতো। বিচার
                ব্যবস্থার শীর্ষে রয়েছে সুপ্রিমকোর্ট। পরবর্তী পর্যায়ে রয়েছে
                হাইকোর্ট ও অন্যান্য অধস্তন আদালত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    30. এমন দুটি ক্ষেত্র উল্লেখ করো যেখানে হাইকোর্ট
                    সুপ্রিমকোর্টের চেয়ে বেশি ক্ষমতা ভোগ করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                হাইকোর্ট সুপ্রিমকোর্টের চেয়ে অধিক ক্ষমতা ভোগ করে এমন দুটি
                ক্ষেত্র হল – <br />
                (a) নাগরিকদের মৌলিক অধিকার সংরক্ষণ ছাড়াও অন্য কোনো উদ্দেশ্যে
                হাইকোর্ট লেখ জারি করতে পারে।
                <br />
                (b) জরুরি অবস্থাতে হাইকোর্টের জারি করা বন্দি প্রত্যক্ষীকরণ লেখ
                সাময়িকভাবে খর্ব করা যায় না।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>31. জনস্বার্থ মামলার সঙ্গে সাধারণ মামলার তফাৎ কোথায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                জনস্বার্থ মামলায় ক্ষতিগ্রস্ত ব্যক্তি ছাড়াও অন্যান্য ব্যক্তি এই
                মামলা করতে পারে, অপরদিকে সাধারণ মামলায় কেবল ক্ষতিগ্রস্ত
                ব্যক্তিই আদালতের দারস্থ হতে পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    32. সুপ্রিমকোর্টের অন্যান্য বিচারপতিরা কীভাবে নিযুক্ত হন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                রাষ্ট্রপতি সুপ্রিমকোর্টের অন্যান্য বিচারপতিদের নিযুক্ত করেন। তবে
                এক্ষেত্রে তিনি সুপ্রিমকোর্টের প্রধান বিচারপতির সঙ্গে পরামর্শ করে
                থাকেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. বিচার বিভাগীয় সক্রিয়তা বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                সরকারের আইন বিভাগ ও শাসন বিভাগের উপর বিচার বিভাগের প্রাধান্য
                বিস্তারকেই বিচার বিভাগীয় সক্রিয়তা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. ক্রেতা আদালতের প্রধান উদ্দেশ্য কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ক্রেতা আদালতের প্রধান উদ্দেশ্য হল ক্রেতাদের সুরক্ষা প্রদান ও
                স্বার্থ দেখা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. জনস্বার্থ মামলার সঙ্গে সাধারণ মামলার তফাৎ কোথায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জনস্বার্থ মামলায় ক্ষতিগ্রস্ত ব্যক্তি ছাড়াও অন্যান্য ব্যক্তি এই
                মামলা করতে পারে, অপরদিকে সাধারণ মামলায় কেবল ক্ষতিগ্রস্ত
                ব্যক্তিই আদালতের দারস্থ হতে পারে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  36. জনস্বার্থমূলক মামলা বা Public Interest Litigation বলতে কী
                  বোঝায়?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                কোনো ব্যক্তি বা প্রতিষ্ঠানের স্বার্থের জন্য মামলা নয়, শুধুমাত্র
                জনগণের স্বার্থ সম্পর্কিত মামলাকেই বলা হয় জনস্বার্থমূলক মামলা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. PIL-এর পুরো অর্থ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                PIL-এর পুরো অর্থ হল- Public Interest Litigation বা জনস্বার্থমূলক
                মামলা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. বন্দি প্রত্যক্ষীকরণ-এর অর্থ কী ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                বন্দিকে সশরীরে আদালতে হাজির করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. পরমাদেশ-এর অর্থ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                আমরা আদেশ দিচ্ছি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>40. বিশেষ অনুমতির মাধ্যমে আপিল বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের যে-কোনো আদালতের রায়ের বিরুদ্ধে সুপ্রিমকোর্ট আপিল করার
                বিশেষ অনুমতি দিলে, সেই আপিলকে বিশেষ অনুমতির মাধ্যমে আপিল বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. হাইকোর্টের গঠন ও কার্যাবলি ব্যাখ্যা করো।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যখন ভারতে যুক্তরাষ্ট্রীয় গঠন নেওয়া হয়, সেই সময় দ্বৈত বিচার
                ব্যবস্থা অনুসরণ করা হয়নি। ভারতের অখণ্ড বিচার ব্যবস্থার উচ্চতম
                পর্যায়ে সুপ্রিম কোর্ট অবস্থিত রয়েছে। সংবিধানের 214 নং ধারা
                অনুসারে, প্রতিটি রাজ্যে একটি করে হাইকোর্ট বা মহাধর্মাধিকরণ গঠন
                সম্পন্ন করার প্রস্তাবনা আছে। তবে, সংবিধানের 231 ধারা অনুসারে,
                দুই বা ততোধিক রাজ্য এবং কেন্দ্রশাসিত অঞ্চলের জন্য একটি হাইকোর্ট
                গঠন সম্পন্ন করার অনুমতি পাওয়া যায়। উদাহরণস্বরূপ, আন্দামান ও
                নিকোবর দ্বীপপুঞ্জের বিচারকার্য কলকাতা হাইকোর্টে সম্পাদন করা হয়।
                বর্তমানে, ভারতে 25টি হাইকোর্ট অবস্থিত আছে।
                <br />
                <br />
                ভারতীয় সংবিধানে হাইকোর্টের গঠন সম্পর্কে কোনো নির্দিষ্ট সংখ্যা
                উল্লিখিত হয়নি। তবে, সংবিধানের 216 নং ধারা অনুসারে, রাষ্ট্রপতির
                পরামর্শের প্রক্রিয়ায় 1 জন প্রধান বিচারপতি এবং অন্যান্য কয়েকজন
                বিচারপতি নিয়োগ পেতে পারেন হাইকোর্ট গঠনে। সংবিধানের 224 নং ধারা
                অনুসারে, রাষ্ট্রপতি অতিরিক্ত অথবা 2 বছরের জন্য অস্থায়ী বিচারপতি
                নিয়োগ করতে পারেন। হাইকোর্টের প্রধান বিচারপতির সহ অন্যান্য
                বিচারপতিদের নিয়োগের ক্ষেত্রে রাষ্ট্রপতি সংশ্লিষ্ট রাজ্যের
                রাজ্যপালের সঙ্গে পরামর্শ করতে পারেন। এছাড়াও, হাইকোর্টের
                অন্যান্য বিচারপতিদের নিয়োগের সময়ে রাষ্ট্রপতি তার প্রধান
                বিচারপতি ও সেই হাইকোর্টের প্রধান বিচারপতির সঙ্গে পরামর্শ করেন।
                <br />
                <br />
                হাইকোর্টের বিচারপতি পদে নিযুক্ত হওয়ার ক্ষেত্রে ব্যক্তিকে —
                <br />
                (i) অবশ্যই ভারতীয় নাগরিক হতে হবে, <br />
                (ii) কোনো রাজ্যের হাইকোর্টে তাঁর 10 বছর অ্যাডভোকেটরূপে কাজ করার
                অভিজ্ঞতা থাকতে হবে, <br />
                (iii) ভারতীয় ভূখণ্ডের যে-কোনো আদালতে অন্তত 10 বছর বিচারকরূপে
                কাজ করার অভিজ্ঞতা থাকতে হবে। হাইকোর্টের বিচারপতিগণ 62 বছর বয়স
                পর্যন্ত নিজপদে বহাল থাকেন। কার্যকাল শেষ হওয়ার পূর্বে তিনি নিজে
                পদত্যাগ করতে পারেন অথবা অন্য হাইকোর্টে বদলি হতে পারেন। অসদাচরণ
                অথবা অক্ষমতার অভিযোগে পার্লামেন্টের সমর্থনের দ্বারা রাষ্ট্রপতি
                হাইকোর্টের বিচারপতিদের পদচ্যুত করে থাকেন। হাইকোর্টের বিচারপতিদের
                বেতন ও ভাতা রাজ্যের সঞ্চিত তহবিল থেকে প্রদান করা হয়। <br />
                <br />
                <font color="#1f7405">
                  <b>হাইকোর্টের ক্ষমতা ও কার্যাবলি : </b>{" "}
                </font>{" "}
                ভারতে অখণ্ড বিচারব্যবস্থায় হাইকোর্টের ক্ষমতা ও কার্যাবলি নীচে
                আলোচনা করা হল -<br />
                <br />
                <font color="#f626d7">
                  <b>(a) মূল এলাকা : </b>{" "}
                </font>{" "}
                মৌলিক অধিকার বলবদ্ধ এবং রাজস্ব সংক্রান্ত বিরোধের বিষয়
                হাইকোর্টের মূল এলাকার অন্তর্গত পরিযোজন। তবে, দেওয়ানি মামলার
                ক্ষেত্রে শুধুমাত্র কলকাতা, মুম্বাই এবং চেন্নাই হাইকোর্টের মূল
                এলাকার মধ্যে পড়ে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(b) আপিল এলাকা : </b>{" "}
                </font>{" "}
                রাজ্যের সর্বোচ্চ আপিল আদালত হল হাইকোর্ট। দেওয়ানি বা ফৌজদারি
                মামলার বিষয়ে হাইকোর্টে আপিল করা যায়। দেওয়ানি মামলার ক্ষেত্রে
                জেলা জজ আথবা সহকারী জেলা জজের রায়ের বিরুদ্ধে হাইকোর্টে আপিল করা
                যায়। ফৌজদারি মামলার ক্ষেত্রে দায়রা জজ বা অতিরিক্ত দায়রা জজ
                বিচারে অভিযুক্তকে 7 বছরের অধিক সময়ের জন্য কারাদণ্ডে দণ্ডিত করলে
                অথবা কোনো ম্যাজিস্ট্রেট তাঁর রায়ে 4 বছরের বেশি সময়ের জন্য
                কারাদণ্ডে দণ্ডিত করলে হাইকার্টে আপিল করা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(c) লেখ জারির ক্ষমতা : </b>{" "}
                </font>{" "}
                সংবিধানের 226 নং ধারা অনুযায়ী হাইকোর্ট নাগরিকদের মৌলিক অধিকার
                রক্ষার্থে 5 প্রকারের লেখ, নির্দেশ বা আদেশ জারি করে থাকে। এই
                প্রকারের লেখগুলি হল— বন্দি-প্রত্যক্ষীকরণ, পরমাদেশ, প্রতিষেধ,
                অধিকারপৃচ্ছা এবং উৎপ্রেষণ। যেহেতু হাইকোর্ট নাগরিকদের সাধারণ
                আইনগত অধিকার হলেও, প্রায়শই হাইকোর্ট সম্প্রতি জারির ক্ষমতা
                ব্যতীত এই প্রকারের লেখ জারি করতে পারে। তবে জরুরি পরিস্থিতির
                সময়ে হাইকোর্টের লেখ জারির ক্ষমতা কিছুটা সংকোচিত হতে পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(d) আইনের বৈধতা বিচারের ক্ষমতা : </b>{" "}
                </font>{" "}
                রাজ্য আইনসভা ও কেন্দ্রীয় আইনসভা কর্তৃক কোনো আইন সাংবিধানিক
                উপায়ে প্রণীত হয়েছে কি না সেই বৈধতা হাইকোর্ট বিচার করে দেখতে
                পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(e) মামলা অধিগ্রহণের ক্ষমতা : </b>{" "}
                </font>{" "}
                সংবিধানের 228 নং ধারা অনুসারে নিম্ন আদালতে চলা কোনো মামলার সঙ্গে
                সংবিধানের ব্যাখ্যা সংক্রান্ত গুরুত্বপূর্ণ প্রশ্ন জড়িত আছে
                যেগুলি নিম্নলিখিত আদালতে নিজের হাতে নেওয়ার ক্ষমতা হাইকোর্টের
                কাছে রয়েছে। এছাড়াও, হাইকোর্ট অভিলেখ বা নথি আদালত হিসেবে কাজ
                করে। এমনকি হাইকোর্ট অবমাননার জন্য অবমাননাকারীকে শাস্তি প্রদানের
                ক্ষমতাও হাইকোর্টের কাছে রয়েছে।
                <br />
                <br />
                পরিশেষে বলা যায়, রাজ্যের বিচারকার্য সম্পাদনে হাইকোর্টের ভূমিকা
                বেশ তাৎপর্যপূর্ণ। হাইকোর্টগুলির ক্ষেত্রেও ভারতের বিচারব্যবস্থার
                দীর্ঘসূত্রিতা ও ব্যয়বহুলতার প্রভাব লক্ষ্য করা যায়। হাইকোর্টের
                বিচারক পদের শূন্যতা ভারতে এক দৃষ্টান্ত হাজির করেছে। আবার
                বিচারপতিদের নিয়োগ ও বদলির বিষয়ে রাজনৈতিক প্রভাব স্পষ্ট হয়ে
                উঠেছে। তবে অখণ্ড বিচারব্যবস্থায় হাইকোর্টগুলো সুপ্রিমকোর্টের
                অধীন।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

ভারতেরবিচারবিভাগ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(ভারতেরবিচারবিভাগ);
