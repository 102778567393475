import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

const দর্শনউচ্চমাধ্যমিকপ্রস্তুতি = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>
          West Bengal Class 12 Guide | West Bengal HS Suggestion | দর্শন - উচ্চ
          মাধ্যমিক প্রস্তুতি
        </title>
        <meta
          name="description"
          content="West Bengal Class 12 Guide | West Bengal HS Suggestion | দর্শন - উচ্চ মাধ্যমিক প্রস্তুতি"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                দর্শন - উচ্চ মাধ্যমিক প্রস্তুতি
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative h-100"></div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3"></h6>
              <h1 className="mb-4">
                Welcome to দর্শন - উচ্চ মাধ্যমিক প্রস্তুতি
              </h1>
              <p className="mb-4">
                প্রতিটি অধ্যায়ের সাথে প্রস্তুতির সহায়ক সামগ্রী ও লিঙ্ক সরবরাহ
                করা হয়েছে যাতে ছাত্ররা তাদের অধ্যয়নে সহায়তা পেতে পারেন। এই
                পৃষ্ঠাটি ছাত্রদের পড়াশোনা প্রক্রিয়ার জন্য একটি গুরুত্বপূর্ণ
                সহায়ক সরঞ্জাম হিসাবে কাজ করতে পারে।
              </p>

              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate("/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-যুক্তি-Guide")
                      }
                    >
                      যুক্তি
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate("/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-বচন-Guide")
                      }
                    >
                      বচন
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-বচনের-বিরোধিতা-Guide"
                        )
                      }
                    >
                      বচনের বিরোধিতা
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-অমাধ্যম-অনুমান-Guide"
                        )
                      }
                    >
                      অমাধ্যম অনুমান
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-নিরপেক্ষ-ন্যায়-Guide"
                        )
                      }
                    >
                      নিরপেক্ষ ন্যায়
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-প্রাকল্পিকও-বৈকল্পিক-ন্যায়-Guide"
                        )
                      }
                    >
                      প্রাকল্পিকও বৈকল্পিক ন্যায়
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-বুলীয়-ভাষ্য-ও-ভেনচিত্র-Guide"
                        )
                      }
                    >
                      বুলীয় ভাষ্য ও ভেনচিত্র
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-সত্যাপেক্ষক-Guide"
                        )
                      }
                    >
                      সত্যাপেক্ষক
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-আরোহ-অনুমানের-স্বরূপ-Guide"
                        )
                      }
                    >
                      আরোহ অনুমানের স্বরূপ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate("/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-কারণ-Guide")
                      }
                    >
                      কারণ
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-মিলের-পরীক্ষামূলক-পদ্ধতি-Guide"
                        )
                      }
                    >
                      মিলের পরীক্ষামূলক পদ্ধতি
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/দর্শন-উচ্চ-মাধ্যমিক-প্রস্তুতি-আরোহমূলক-দোষ-Guide"
                        )
                      }
                    >
                      আরোহমূলক দোষ
                    </a>
                  </p>
                </div>
              </div>
              <a className="btn btn-primary py-3 px-5 mt-2" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

দর্শনউচ্চমাধ্যমিকপ্রস্তুতি.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(দর্শনউচ্চমাধ্যমিকপ্রস্তুতি);
