import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function অবউপনিবেশীকরণ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          অব-উপনিবেশীকরণ | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="অব-উপনিবেশীকরণ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                অব-উপনিবেশীকরণ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>স্বাধীন আলজিরিয়ার প্রথম রাষ্ট্রপতি ছিলেন — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নকুমা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বেন বেল্লা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্যাট্রিক লুলুম্বা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ড. ওয়াইজ ম্যান
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>আওয়ামি লিগ পার্টির প্রতিষ্ঠাতা ছিলেন — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জুলফিকার আলি ভুট্টো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফজলুল হক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সুরাবর্দি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শেখ মুজিবর রহমান
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ‘নিয়ন্ত্রিত গণতন্ত্রে’র ধারণাটি কোন্ ব্যক্তির সঙ্গে
                  সম্পর্কিত?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সুকর্ণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সুহার্তো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নেহরু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হো-চি-মিন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>স্বাধীন ভারতে প্রথম সাধারণ নির্বাচন অনুষ্ঠিত হয় — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ১৯৫১-৫২ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৯৪৭-৪৮ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৯৪৯-৫০ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৯৫৩-৫৪ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Planneed Economy for India গ্রন্থটির লেখক — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সুভাষচন্দ্র বসু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জওহরলাল নেহরু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  এম. বিশ্বেশ্বরাইয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আবুল কালাম আজাদ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ভারতে অর্থনৈতিক উদারীকরণ কর্মসূচির সঙ্গে যাঁর নাম সর্বাগ্রে
                  যুক্ত, তিনি হলেন —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইন্দ্রকুমার গুজরাল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মনমোহন সিং
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নরেন্দ্র মোদি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নির্মলা সীতারামন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>আন্তর্জাতিক মাতৃভাষা দিবস হিসেবে পালিত হয় — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ২১ ফেব্রুয়ারি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>৫ জুন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১২ জানুয়ারি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ২১ ডিসেম্বর
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতের পারমাণবিক শক্তি কমিশন গঠিত হয় — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ১৯৪৮ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৯৬৮ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৯৮৫ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ১৯৯১ খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সংবিধান অনুসারে ভারতের সর্বোচ্চ শাসক — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  রাষ্ট্রপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সেনাপ্রধান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রধানমন্ত্রী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাজ্যপাল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সার্ক (SAARC)-এর স্থায়ী সচিবালয় অবস্থিত -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ঢাকায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  কাঠমাণ্ডুতে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নিউ ইয়র্কে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইসলামাবাদে
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="3">
                <b>অষ্টম অধ্যায় ➺ অব-উপনিবেশীকরণ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রশ্নের মান - ৮ </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                1. স্বাধীন ভারতের প্রথম তিনটি পঞ্চবার্ষিক পরিকল্পনার লক্ষ্য ও
                গুরুত্ব আলোচনা করো।
                <br />
                2. ইন্দোনেশিয়ার স্বাধীনতা আন্দোলনের সংক্ষিপ্ত বিবরণ দাও।
                <br />
                3. SAARC কীভাবে গঠিত হয়েছিল? এর উদ্দেশ্য লেখো।
                <br />
                4. পাকিস্তানের আইন ও শাসন কাঠামো পর্যালোচনা করো। <br />
                5. অব-উপনিবেশিকরণ বলতে কী বোঝায়? এর সামাজিক, রাজনৈতিক ও
                অর্থনৈতিক তাৎপর্য ব্যাখ্যা করো।
                <br />
                6. বিংশ শতকে পৃথিবীর বিভিন্ন প্রান্তে উপনিবেশ বিরোধী আন্দোলনের
                কারণগুলি আলোচনা করো।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রশ্নের মান - ১ </b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. কবে, কোথায় ভারত প্রথম পরমাণু বোমার বিস্ফোরণ ঘটায়?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারত ১৯৭৪ খ্রিস্টাব্দের ১৮ মে পোখরানে প্রথম পারমাণবিক বোমার
                বিস্ফোরণ ঘটায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. ভারতবর্ষের প্রকৃত শাসক কে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতবর্ষের প্রকৃত শাসক প্রধানমন্ত্রী।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. রাজ্যের নিয়মতান্ত্রিক প্রধান কে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                রাজ্যের নিয়মতান্ত্রিক প্রধান হলেন রাজ্যপাল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. ভারতবর্ষের প্রথম রাষ্ট্রপতি কে ছিলেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতবর্ষের প্রথম রাষ্ট্রপতি ছিলেন ড. রাজেন্দ্রপ্রসাদ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. ইংল্যান্ডের কয়েকটি উপনিবেশের নাম লেখো।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ইংল্যান্ডের উপনিবেশ গুলি হল ভারত, বার্মা, সিংহল, মালয়, হংকং
                প্রভৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. বর্ণবৈষম্য নীতি কোন্ দেশে বলবৎ হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বর্ণবৈষম্য নীতি দক্ষিণ আফ্রিকায় বলবৎ হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. কে পাকিস্তানের জাতির পিতা ছিলেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মহম্মদ আলি জিন্নাহ পাকিস্তানের জাতির পিতা ছিলেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. কোন্‌ দিন বাংলাদেশে বিজয় দিবস পালিত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৭১ খ্রিস্টাব্দের ১৬ ডিসেম্বর বাংলাদেশে বিজয় দিবস পালিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. ভারতে কবে বেতার প্রচার শুরু হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতে বেতার প্রচার শুরু হয় ১৯২৭ খ্রিস্টাব্দে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. ফ্রান্সের কয়েকটি উপনিবেশের নাম লেখো।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ফ্রান্সের উপনিবেশ গুলি হল – ইন্দোচিন, সিরিয়া, টিউনিসিয়া,
                নাইজার, কঙ্গো প্রভৃতি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. SAFTA-এর পুরো নাম কী?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                SAFTA-এর পুরো নাম South Asian Free Trade Area (এটি কার্যকারী
                হয়েছিল ২০০৬-এর ১ জানুয়ারি থেকে)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    12. দক্ষিণ আফ্রিকার প্রথম অ-শ্বেতাঙ্গ রাষ্ট্রপ্রধানের নাম
                    কী?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দক্ষিণ আফ্রিকার প্রথম অ-শ্বেতাঙ্গ রাষ্ট্রপ্রধানের নাম নেলসন
                ম্যান্ডেলা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    13. ভারত সরকারের বরাদ্দ অর্থকে যথাযথভাবে ব্যয় করার জন্য
                    কীভাবে ভাগ করা হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারত সরকারের বরাদ্দ অর্থকে যথাযথভাবে ব্যয় করার জন্য দু-ভাগে ভাগ
                করা হয়। যথা –<br />
                (ক) পরিকল্পিত খাত এবং (খ) অপরিকল্পিত খাত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    14. দক্ষিণ আফ্রিকার প্রথম অ-শ্বেতাঙ্গ রাষ্ট্রপ্রধানের নাম
                    কী?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দক্ষিণ আফ্রিকার প্রথম অ-শ্বেতাঙ্গ রাষ্ট্রপ্রধানের নাম নেলসন
                ম্যান্ডেলা। ১৯৯৪-৯৯ খ্রিস্টাব্দ পর্যন্ত তিনি এই পদ অলংকৃত করেন।
                তাঁকে ‘গান্ধিজির মানসপুত্র’ হিসেবে অভিহিত করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    15. ভারতীয় সংবিধানে মৌলিক অধিকারের সংখ্যা কয়টি ও কী কী?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ৬টি। যথা—সাম্যের অধিকার, স্বাধীনতার অধিকার, শোষণের বিরুদ্ধে
                অধিকার, ধর্মের অধিকার, শিক্ষা ও সংস্কৃতির অধিকার এবং
                শাসনতান্ত্রিক প্রতিবিধানের অধিকার।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>16. স্বাধীন ভারতের প্রথম (সাধারণ) নির্বাচন কবে হয়েছিল?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ১৯৫১ খ্রিস্টাব্দের ২৫ অক্টোবর থেকে ১৯৫২ খ্রিস্টাব্দের ২১
                ফেব্রুয়ারি পর্যন্ত প্রায় চার মাস ধরে বিশ্বের সর্বাধিক সংখ্যক
                নির্বাচক মণ্ডলীকে নিয়ে অনুষ্ঠিত হয়েছিল স্বাধীন ভারতের প্রথম
                (সাধারণ) নির্বাচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. পাকিস্তান পিপলস্ পার্টি কে গঠন করেন? </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৬৭ খ্রিস্টাব্দে জুলফিকার আলি ভুট্টো পাকিস্তান পিপিলস্ পার্টি
                গঠন করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. হোমি জাহাঙ্গীর ভাবা কে ছিলেন? </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জওহরলাল নেহরুর প্রধানমন্ত্রীত্বকালে ভারতের পরমাণু গবেষণার বিষয়ে
                প্রধান উপদেষ্টা ছিলেন বিখ্যাতবিজ্ঞানী হোমি জাহাঙ্গীর ভাবা। ১৯৪৮
                খ্রিস্টাব্দে তাঁর সভাপতিত্বে ভারতের পরমাণু শক্তি কমিশন
                প্রতিষ্ঠিত হয়। তিনি ‘ভারতের পরমাণু কর্মসূচির জনক’ নামে অভিহিত
                হন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. সার্ক (SAARC) কী? </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮৮৫ খ্রিস্টাব্দে দক্ষিণ এশিয়ার ৭টি দেশ পারস্পরিক সহযোগিতার
                মাধ্যমে নিজেদের উন্নতির জন্য বাংলাদেশের রাজধানী ঢাকায় যে
                আঞ্চলিক সহযোগিতামূলক সংস্থা গড়ে তোলে, তা সার্ক (SAARC) নামে
                পরিচিত। ইংরেজিতে এই শব্দগুচ্ছের সম্পূর্ণ নাম – South Asian
                Association for Regional Co-operation.
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. স্বাধীন ভারতের প্রেক্ষিতে মিশ্র অর্থনীতি বলতে কী বোঝো?
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মিশ্র অর্থনীতি বলতে এমন এক অর্থব্যবস্থা বোঝায়, যেখানে
                রাষ্ট্রীয় উদ্যোগের পাশাপাশি বেসরকারি উদ্যোগকেও দেশের অর্থনৈতিক
                উন্নয়নে শামিল করা হয়। স্বাধীন ভারতে মিশ্র অর্থনৈতিক ব্যবস্থা
                প্রচলিত হয়েছে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. তৃতীয় পঞ্চবার্ষিকী পরিকল্পনার ফলাফল কী হয়েছিল?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সীমান্ত সংঘর্ষ এবং প্রাকৃতিক দুর্যোগের কারণে তৃতীয় পঞ্চবার্ষিক
                পরিকল্পনার অর্জিত সাফল্য ছিল যথেষ্ট নৈরাশ্যজনক। জাতীয় আয়
                বৃদ্ধি, কৃষি, সেচ, বিদ্যুৎ, সংগঠিত শিল্প, কর্মসংস্থান,
                গৃহনির্মাণ প্রভৃতি কোনোক্ষেত্রেই লক্ষ্যমাত্রা পূরণ হয়নি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. জ্যাকুইস ম্যাসু কে ছিলেন?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জ্যাকুইস ম্যাসু ছিলেন আলজিরিয়ায় নিযুক্ত ফরাসি জেনারেল। তিনি
                চণ্ডনীতি গ্রহণ করে আলজিরিয়ার গণ আন্দোলন দমন করতে চেষ্টা করলেও
                শেষ পর্যন্ত পরাজিত ও ব্যর্থ হন। আলজিরিয়ায় অনুসৃত তার পাশবিক
                নীতি ইতিহাসের এক ঘৃণিত অধ্যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. আগরতলা ষড়যন্ত্র মামলা বলতে কী বোঝো?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পাক সরকার ১৯৬৮ খ্রিস্টাব্দে শেখ মুজিবর রহমানসহ ৩৫ জন আওয়ামি
                লিগের নেতাকে গ্রেফতার করে তাদের বিরুদ্ধে একটি রাষ্ট্রদ্রোহিতার
                মামলা রুজু করে। বলা হয় ভারতের সঙ্গে মিলে এই নেতারা আগরতলায়
                পাকিস্তানের অখন্ডতার বিরুদ্ধে ষড়যন্ত্র করছেন। এই মামলা আগরতলা
                ষড়যন্ত্র মামলা' নামে খ্যাত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. অপারেশন সার্চলাইট কী?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৭১ খ্রিস্টাব্দে পশ্চিম পাকিস্তানের জেনারেল ইয়াহিয়া খান,
                পূর্ব পাকিস্তানের সামরিক গভর্নর টিক্কা খানকে বাঙালি নিধনের
                নির্দেশ দেন। পূর্ব পাকিস্তানের বাঙালিদের উপর পশ্চিম পাকিস্তানের
                সরকার এই রাষ্ট্রীয় সন্ত্রাস ‘অপারেশন সার্চলাইট’ নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. ISRO কী?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                স্বাধীন ভারতে প্রধানমন্ত্রী জওহরলাল নেহরু ও ড. বিক্রম Exclusive
                সারাভাই-এর যৌথ উদ্যোগে ১৯৬২ খ্রিস্টাব্দে পারমাণবিক শক্তি কমিশনের
                অধীনে মহাকাশ গবেষণার উদ্দেশ্যে প্রতিষ্ঠিত হয় The Indian
                National Committee for Space Research. ১৯৬৯ খ্রিস্টাব্দ থেকে এটি
                ISRO নামে পরিচিত পায়। এর পুরো নাম - Indian Space Research
                Organisation.
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  26. স্বাধীন ভারতে পঞ্চবার্ষিকী পরিকল্পনা কেন গৃহীত হয়েছিল?
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                প্রধানমন্ত্রী জওহরলাল নেহরুর উদ্যোগে সোভিয়েত মডেলে ১৯৫১
                খ্রিস্টাব্দ থেকে স্বাধীন ভারতে পঞ্চবার্ষিকী পরিকল্পনা গৃহীত হয়।
                এই পরিকল্পনার প্রধান লক্ষ্য ছিল —<br />
                (ক) দেশভাগজনিত অর্থনৈতিক সংকট থেকে মুক্তি, <br />
                (খ) কৃষি, সেচ, পরিবহণ ও পুনর্বাসনের ওপর গুরুত্ব দেওয়া এবং
                <br /> (গ) জাতীয় আয় ও জনগণের জীবনযাত্রার মানোন্নয়ন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. প্রশান্তচন্দ্র মহালানবিশ কে ছিলেন?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ইন্ডিয়ান স্ট্যাটিসটিক্যাল ইনস্টিটিউটের প্রখ্যাত পরিসংখ্যানবিদ ও
                রাশিবিজ্ঞানী ছিলেন ড. প্রশান্তচন্দ্র মহালানবিশ। ১৯৫৫
                খ্রিস্টাব্দে ভারতের অর্থনৈতিক সমৃদ্ধির জন্য তিনি দ্বিতীয়
                পঞ্চবার্ষিকী পরিকল্পনার একটি মডেল বা রূপরেখা তৈরি করেন। তাঁকে
                ‘দ্বিতীয় পঞ্চবার্ষিকী পরিকল্পনার জনক’ হিসেবে অভিহিত করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    28. ভারত সরকারের বরাদ্দ অর্থকে যথাযথভাবে ব্যায় করার জন্য
                    কীভাবে ভাগ করা হয়?
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারত সরকারের বরাদ্দ অর্থকে যথাযথভাবে ব্যয় করার জন্য তিনটি ভাগে
                ভাগ করা হয়। যথা — সমন্বয় তহবিল (Consolidated Fund), আপাতকালীন
                তহবিল (Contingency Fund) এবং সরকারি তহবিল (Public Fund)। <br />
                <br />
                <font color="#f626d7">
                  <b>29. ASEAN কী?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৬৭ খ্রিস্টাব্দে দক্ষিণ-পূর্ব এশিয়ার কয়েকটি দেশ, যথা —
                ইন্দোনেশিয়া, মালয়েশিয়া, ফিলিপিন্স, সিঙ্গাপুর এবং থাইল্যান্ড
                পারস্পরিক সহযোগিতার মাধ্যমে নিজেদের উন্নতির জন্য যে আঞ্চলিক
                সহযোগিতামূলক সংস্থা গড়ে তোলে, তা ASEAN নামে পরিচিত। ইংরেজিতে এই
                শব্দগুচ্ছের সম্পূর্ণ নাম — The Association of Southeast Asian
                Nations.
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    30. ভারতবর্ষের জাতীয় জীবনে ১৯৪৭ খ্রিস্টাব্দের ১৫ আগস্ট এবং
                    ১৯৫০ খ্রিস্টাব্দের ২৬ জানুয়ারি দিন দুটি গুরুত্বপূর্ণ কেন?
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৪৭ খ্রিস্টাব্দের ১৫ আগস্ট দীর্ঘ ব্রিটিশ শাসনের অবসানের পর ভারত
                বহু আকাঙ্ক্ষিত স্বাধীনতা লাভ করে। ১৯৫০ সালের ২৬ জানুয়ারি তারিখে
                স্বাধীন ভারতের সংবিধান প্রবর্তিত হয় এবং এরপর থেকে প্রতি বছর ২৬
                জানুয়ারি দিনটি প্রজাতন্ত্র দিবসরূপে পালিত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>31. ভারতীয় সংবিধানের প্রস্তাবনা বলতে কী বোঝো?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                মার্কিন যুক্তরাষ্ট্রের সংবিধানের অনুসরণে ভারতীয় সংবিধানে একটি
                প্রস্তাবনা সংযোজিত হয়েছে। প্রস্তাবনায় সংবিধানের মূল নীতিসমূহ
                দার্শনিক ভিত্তি, তত্ত্ব, আদর্শ, উদ্দেশ্য ইত্যাদি সকল দিকই যথাযথ
                ফুটে উঠেছে। ড. ঠাকুরদাস ভার্গবব-এর পরিভাষায় “ভারতীয় সংবিধানের
                আত্মাই হল এর প্রস্তাবনা।”
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. নেহরু-মহলানবিশ মডেল বলতে কী বোঝো?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রখ্যাত পরিসংখ্যানবিদ ও রাশিবিজ্ঞানী প্রশান্তচন্দ্র মহালানবিশ
                ১৯৫৫ খ্রিস্টাব্দে ভারতের অর্থনৈতিক সমৃদ্ধির জন্য দ্বিতীয়
                পঞ্চবার্ষিকী পরিকল্পনার একটি মডেল বা রূপরেখা তৈরি করেন।
                প্রধানমন্ত্রী জওহরলাল নেহরু সংশোধনের পর এই মডেলটি গ্রহণ করেন ও
                প্রয়োগ করেন। এটি ‘নেত্রু-মহলানবিশ মডেল' নামে খ্যাত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. তৃতীয় বিশ্ব কী?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                উত্তর তৃতীয় বিশ্বের সংজ্ঞা নিয়ে মতপার্থক্য থাকলেও সাধারণ ভাবে,
                দ্বিতীয় বিশ্বযুদ্ধের পর মার্কিন পুঁজিবাদী জোট এবং সোভিয়েত
                সমাজতান্ত্রিক জোটের বাইরে অবস্থিত এশিয়া ও ইউরোপের সদ্য
                স্বাধীনতাপ্রাপ্ত বা স্বাধীনতা সংগ্রামে রত দেশগুলিকে ‘তৃতীয়
                বিশ্ব’ বলে আখ্যায়িত । হয়েছে। প্রথম বিশ্ব হল পুঁজিবাদী মার্কিন
                যুক্তরাষ্ট্র ও তার অনুগত রাষ্ট্রজোট। আর দ্বিতীয় বিশ্ব হল
                সোভিয়েত অনুগত সমাজতান্ত্রিক রাষ্ট্রজোট।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. বর্ণ বৈষম্য নীতি কোন্ দেশে বলবৎ হয়?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মানব সভ্যতার এক অভিশাপ হল ‘বর্ণবৈষম্যবাদ’। প্রধানত গাত্রবর্ণ এবং
                অংশত ধর্ম ও জাতির নিরিখে মানুষকে মনুষ্যেতরবর্গে বিবেচনা করা বলা
                হয় ‘বর্ণবৈষম্যবাদ’। শ্বেতাঙ্গ ইউরোপীয় জাতিগুলি শ্রেষ্ঠত্বের
                দম্ভে আফ্রিকা ও এশিয়ার কৃষ্ণাঙ্গ মানুষদের উপর আধিপত্য কায়েম
                করেছিল। দক্ষিণ আফ্রিকায় শ্বেতাঙ্গ প্রিটোরিয়া শাসনের
                বর্ণবৈষম্যবাদের বিরুদ্ধে নেলসন ম্যান্ডেলা কৃষ্ণাঙ্গ মানুষদের উপর
                আধিপত্য কায়েম করেছিল। দক্ষিণ আফ্রিকায় শ্বেতাঙ্গ প্লিটোরিয়া
                শাসনের বর্ণবৈষম্যবাদের বিরুদ্ধে নেলসন ম্যান্ডেলা জোরদার আন্দোলন
                গড়ে তোলেন। ভারতেও বর্ণবৈষম্যবাদের বিরুদ্ধে আন্দোলন গড়ে ওঠে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. অর্থনৈতিক উদারীকরণ বলতে কী বোঝো?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯৯০-এর দশকে উদ্ভূত অর্থনৈতিক সংকট দূরীভূত করে ভারতের অর্থনীতিকে
                চাঙ্গা করার জন্য প্রধানমন্ত্রী পি. ভি. নরসিমা রাও এবং
                অর্থমন্ত্রী (পরবর্তীতে প্রধানমন্ত্রী) ড. মনমোহন সিং অর্থনৈতিক
                ক্ষেত্রে যে আমূল সংস্কার কর্মসূচি গ্রহণ করেন, তা অর্থনৈতিক
                উদারীকরণ' নামে পরিচিত। এই ব্যবস্থায় বিদেশি বা বেসরকারি পুঁজি
                বিনিয়োগকে উৎসাহদান, কর ব্যবস্থায় সংস্কার প্রভৃতি কর্মসূচি
                গৃহীত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  36. সংবিধানের প্রস্তাবনায় ভারতকে কী বলে অভিহিত করা হয়েছে?
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                মূল সংবিধানের প্রস্তাবনায় ভারতকে একটি ‘সার্বভৌম’,
                ‘গণতান্ত্রিক’, ‘সাধারণতন্ত্র’ বলে অভিহিত করা হয়েছিল। কিন্তু
                ১৯৭৬ খ্রিস্টাব্দের ৪২ তম সংবিধান সংশোধনীর মাধ্যমে প্রস্তাবনায়
                ‘সমাজতান্ত্রিক’ ও ‘ধর্মনিরপেক্ষ’ শব্দ দুটি অন্তর্ভুক্ত করা
                হয়েছে। তাই বর্তমান সংবিধানের প্রস্তাবনায় ভারতকে ‘সার্বভৌম’,
                ‘সমাজতান্ত্রিক’, ‘ধর্মনিরপেক্ষ’, ‘গণতান্ত্রিক’, ‘সাধারণতন্ত্র’
                বলে অভিহিত করা হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. সার্কের দুটি উদ্দেশ্য লেখো।</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                (ক) সদস্য রাষ্ট্রগুলির আর্থসামাজিক সমৃদ্ধি ও সামাজিক অগ্রগতি
                ঘটানো ;<br />
                (খ) শান্তিপূর্ণ সহাবস্থান এবং সদস্য রাষ্ট্রগুলির ভৌগোলিক অখণ্ডতা
                রক্ষা ও সন্ত্রাসবাদ প্রতিরোধ ;<br />
                (গ) পারস্পরিক সহযোগিতা বৃদ্ধি ও বাণিজ্যিক ও কারিগরি ক্ষেত্রে
                আদান-প্রদানের ক্ষেত্র প্রস্তুত করা প্রভৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    38. দ্বিতীয় বিশ্বযুদ্ধের পরবর্তী পর্যায়ে অব-উপনিবেশীকরণ
                    বলতে কী বোঝো?
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দ্বিতীয় বিশ্বযুদ্ধের পরবর্তীকালে এশিয়া, আফ্রিকাসহ বিশ্বের নানা
                প্রান্তে নতুন করে উপনিবেশ গড়ে ওঠার পরিবর্তে প্রতিষ্ঠিত
                উপনিবেশগুলিতে স্বাধীনতার দাবিতে আন্দোলন জোরদার করা হয় এবং এর
                ফলে উপনবেশগুলি একে একে স্বাধীনতা লাভ করতে থাকে। এই প্রক্রিয়াকে
                ঐতিহাসিকরা অব-উপনিবেশীকরণ বা বি-উপনিবেশীকরণ বলে আখ্যায়িত
                করেছেন।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

অবউপনিবেশীকরণ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(অবউপনিবেশীকরণ);
