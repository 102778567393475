import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function আরোহঅনুমানেরস্বরূপ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          আরোহ অনুমানের স্বরূপ | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="আরোহ অনুমানের স্বরূপ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                আরোহ অনুমানের স্বরূপ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>আরোহ অনুমানের বস্তুগত ভিত্তি কী?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কার্যকারণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পর্যবেক্ষণ ও পরীক্ষণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রকৃতির একরূপতা নীতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সামান্যীকরণ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  “বিশেষ বিশেষ ঘটনার মাধ্যমে সামান্য নিয়মে উপনীত হওয়াই হল আরোহ
                  অনুমান।”—এটি বলেছেন -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোপি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জয়েস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বেইন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>উপমাযুক্তি কয় প্রকার?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  দুইপ্রকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তিনপ্রকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চারপ্রকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পাঁচপ্রকার
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>'A System of Logic' বইটির লেখক কে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যারিস্টট্ল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বেইন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোপি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>আরোহমূলক লাফকে অন্ধকারে ঝাঁপ দেওয়া বলেছেন -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বেকন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বেইন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোপি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অবৈজ্ঞানিক আরোহ অনুমানের অপর নাম কী?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অমাধ্যম অনুমান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উপমা যুক্তি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তথাকথিত আরোহ অনুমান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  লৌকিক বা অপূর্ণ গণনামূলক আরোহ অনুমান
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  “বিশেষ বিশেষ ঘটনার মাধ্যমে সামান্য নিয়মে উপনীত হওয়াই হল আরোহ
                  অনুমান।” — এটি বলেছেন -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বেইন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ফাউলার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোপি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বৈজ্ঞানিক আরোহ অনুমানের লক্ষ্য কী?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অপ্রয়োজনীয় ঘটনা বর্জন করা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সত্যতা নির্ণয় করা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বৈধতা নির্ণয় করা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  কার্যকারণ সম্বন্ধ প্রতিষ্ঠা করা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>লৌকিক বা অবৈজ্ঞানিক আরোহ অনুমানের ভিত্তি কী?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অবাধ অভিজ্ঞতা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাদৃশ্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কার্যকারণ নীতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আরোহমূলক লাফ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সাদৃশ্যমূলক আরোহ অনুমানের অপর নাম কী?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  উপমা যুক্তি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অবৈজ্ঞানিক আরোহ অনুমান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিশ্র যুক্তি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লৌকিক আরোহ অনুমান
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>নবম অধ্যায় - আরোহ অনুমানের স্বরূপ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 1. আরোহ অনুমানের সংজ্ঞা দাও।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                কয়েকটি বিশেষ দৃষ্টান্ত পর্যবেক্ষন করে প্রকৃতির একরূপতা ও
                কার্যকারণ নীতির উপর ভিত্তি করে একটি সার্বিক সংশ্লেষক বচন
                প্রতিষ্ঠা করাকেই আরোহ অনুমান বলে।
                <br />
                <br />
                যেমন — রানা ছাত্র এবং ফুটবলার।
                <br />
                সকল ছাত্র হয় ফুটবলার।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 2. পরীক্ষণ কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                কৃত্রিম পরিবেশে কোনো ঘটনাকে কৃত্রিমভাবে ঘটিয়ে পর্যবেক্ষণ করাকে
                বলা হয় পরীক্ষণ।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 3. বৈজ্ঞানিক আরোহ অনুমানের সংজ্ঞা দাও।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে আরোহ অনুমানে প্রকৃতির একরূপতা নীতি এবং কার্যকারণ নিয়মের
                ভিত্তিতে কয়েকটি বিশেষ বস্তু বা ঘটনাকে পর্যবেক্ষণ ও পরীক্ষণ করে
                একটি সামান্য সংশ্লেষক বচন প্রতিষ্ঠা করা হয়, তাকে বৈজ্ঞানিক আরোহ
                অনুমান বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 4. অবৈজ্ঞানিক বা লৌকিক আরোহের সংজ্ঞা দাও।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে আরোহ অনুমানে কার্যকারণ সম্পর্কের উপর নির্ভর না করে কেবলমাত্র
                অবাধ অভিজ্ঞতার ভিত্তিতে কয়েকটি বিশেষ দৃষ্টান্ত গণনার মাধ্যমে
                একটি সামান্য সংশ্লেষক বচন সিদ্ধান্ত হিসেবে প্রতিষ্ঠা করা হয়,
                তাকে অবৈজ্ঞানিক আরোহ অনুমান বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 5. ভালো বা উত্তম উপমাযুক্তি কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে উপমাযুক্তিতে দুটি বিষয়ের মধ্যে প্রাসঙ্গিক ও গুরুত্বপূর্ণ
                সাদৃশ্যের ভিত্তিতে সিদ্ধান্ত গ্রহণ করা হয়, তাকে ভালো উপমাযুক্তি
                বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. অবৈজ্ঞানিক আরোহ অনুমানকে কি মূল্যহীন বলা যায়?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                অবৈজ্ঞানিক আরোহ অনুমানকে মূল্যহীন বলা যায় না। কেন-না এই প্রকার
                অনুমানের উপর নির্ভর করেই সাধারণ মানুষ বিশেষ দৃষ্টান্ত থেকে একটি
                সামান্য সংশ্লেষক বচনকে সিদ্ধান্ত হিসেবে প্রতিষ্ঠা করে। এজন্য
                অবৈজ্ঞানিক আরোহ অনুমানকে লৌকিক আরোহ অনুমান বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. কার্যকারণ নিয়ম বলতে কী বোঝো?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                কার্যকারণ নিয়ম অনুসারে প্রতিটি কার্য বা ঘটনারই কারণ আছে। এই
                পৃথিবীতে কোনো কার্য বা ঘটনা বিনা কারণে ঘটে না।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>8. অবৈজ্ঞানিক আরোহ অনুমানকে লৌকিক আরোহ বলা হয় কেন?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                সাধারণ মানুষ অবৈজ্ঞানিক আরোহ অনুমানের মতো কার্যকারণ সম্বন্ধ
                নির্ণয় না করেই ব্যতিক্রমহীন অভিজ্ঞতার ভিত্তিতে অনুমান গঠন করে
                থাকে। তাই অবৈজ্ঞানিক আরোহ অনুমানকে লৌকিক আরোহ অনুমান বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  9. বৈজ্ঞানিক ও অবৈজ্ঞানিক আরোহ অনুমানের একটি পার্থক্য লেখো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                বৈজ্ঞানিক আরোহ অনুমানে কার্যকারণ নীতির উপর নির্ভর করে সিদ্ধান্ত
                প্রতিষ্ঠা করা হয়। অপরদিকে, অবৈজ্ঞানিক আরোহ অনুমানে কেবল অবাধ
                অভিজ্ঞতার ভিত্তিতে সিদ্ধান্ত প্রতিষ্ঠা করা হয়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

আরোহঅনুমানেরস্বরূপ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(আরোহঅনুমানেরস্বরূপ);
