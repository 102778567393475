import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function বুলীয়ভাষ্যওভেনচিত্র() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          বুলীয় ভাষ্য ও ভেনচিত্র | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="বুলীয় ভাষ্য ও ভেনচিত্র | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                বুলীয় ভাষ্য ও ভেনচিত্র
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বুলীয় ভাষ্য অনুযায়ী কোন বিরোধিতা বৈধ?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অসম বিরোধিতা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বিরুদ্ধ বিরোধিতা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিপরীত বিরোধিতা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অধীন-বিপরীত বিরোধিতা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বুলীয় মতে কোন বচনের অস্তিত্বমূলক তাৎপর্য আছে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নঞর্থক বচনের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সামান্য বচনের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বিশেষ বচনের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সদর্থক বচনের
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>শ্রেণি বলতে আধুনিক যুক্তিবিজ্ঞানে বোঝানো হয়েছে —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  শব্দকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বাক্যকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পদকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বচনকে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>'=' এই চিহ্নটি কীসের চিহ্ন?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সমীকরণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শূন্যগর্ভ শ্রেণি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অশূন্যগর্ভ শ্রেণি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অসমীকরণ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>E বচনের বুলীয় ভাষ্য হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  S≠0
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>S = 0
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  SP = 0
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  SP≠0
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বুলীয় মতে কোন কোন বচন সাত্ত্বিক বচন?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>1 এবং O
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>A এবং E
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>A এবং I
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>E এবং O
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  বুলীয় মতে অস্তিত্বমূলক তাৎপর্য বা সাত্ত্বিক ব্যঞ্জনা নেই কোন
                  বচনের?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নঞর্থক বচনের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সামান্য বচনের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিশেষ বচনের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সদর্থক বচনের
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কোন মতে, বচনের অস্তিত্বমূলক তাৎপর্যকে স্বীকার করা হয়েছে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  নব্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রাচীন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাবেকি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>I বচনের বুলীয় ভাষ্য হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  S≠0
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>S = 0
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  SP = 0
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  SP≠0
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বুলীয় মতে বা নব্য মতে বৈধ মূর্তি ক-টি?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  15 টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  19 টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>4 টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  256 টি
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>সপ্তম অধ্যায় - বুলীয় ভাষ্য ও ভেনচিত্র</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. আধুনিক বুলীয় ভাষ্যের জনক কে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                জর্জ বুল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. বুলীয় ভাষ্য কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                বুলের নামানুসারে নিরপেক্ষ বচনের ভাষ্যকে বুলিও ভাষ্য বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. আধুনিক যুক্তি বিজ্ঞানের ভিত্তি কি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                বুলীয় ভাষ্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    4. কোন কোন বচন এর অস্তিত্ব মূলক স্বীকৃতি নেই বা সাত্বিক
                    ব্যঞ্জনা নেই?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                A ও E বচনের(শূন্য শ্রেণি)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    5. কোন কোন বচন এর অস্তিত্ব মূলক স্বীকৃতি বা সাত্বিক ব্যঞ্জনা
                    আছে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                I ও O বচনের (অশূন্য বচন)
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. শূন্যগর্ভ শ্রেণি বোঝাতে কি প্রতীক ব্যবহৃত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                0 (zero)
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. শূন্যগর্ভ শ্রেণির প্রথম প্রবক্তা কে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                জর্জ বুল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. পরস্পর ছেদী বৃত্ত কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                দুটি বৃত্ত যখন পরস্পর পরস্পরকে ছেদ করে তখন তাদের পরস্পর ছেদী
                বৃত্ত বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. আধুনিক ব্যাখ্যা অনুযায়ী কোন বচন শূন্য বচন হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                A ও E বচন। এককথায় সামান্য বচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. বুলীয় ভাষ্যের ধারণাটির উৎস কি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                বচনের অস্তিত্ব মূলক তাৎপর্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>11. S-নামক শূন্য শ্রেণির ভেনচিত্র কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                S = 0<br />
                <br />
                <font color="#f626d7">
                  <b>12. S-নামক অশূন্য শ্রেণির ভেনচিত্র কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                S = 0<br />
                <br />
                <font color="#f626d7">
                  <b>13. ভেনচিত্রের ক্ষেত্রে কখন ছায়াবৃত্ত অঙ্কন করা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ভেনচিত্রের ক্ষেত্রে শূন্যগর্ভ শ্রেণি বোঝাতে ছায়াবৃত্ত অঙ্কন করা
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    14. দুটি পরস্পরচ্ছেদী বৃত্ত ক-টি শ্রেণিকে নির্দেশ করে তা
                    চিত্রে দেখাও।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                দুটি পরস্পরচ্ছেদী বৃত্ত চারটি শ্রেণিকে নির্দেশ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    15. আধুনিক ব্যাখ্যা বা বুলীয় ভাষ্য অনুযায়ী কোন্ বচনের
                    সম্রাট অ-সরল আবর্তন অবৈধ?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                A বচনের।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    16. বুলীয় ব্যাখ্যা অনুযায়ী কোন্ বচনের সমবিবর্তন বৈধ নয়।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                E বচনের।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. E বচনের বুলীয় রূপ বা বুলীয় ভাষ্য কী? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                SP = 0<br />
                <br />
                <font color="#f626d7">
                  <b>18. I বচনের বুলীয় ব্যাখ্যা কী? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                SP ≠ 0<br />
                <br />
                <font color="#f626d7">
                  <b>19. বুলীয় মতে বা নব্য মতে কোন বিরোধিতা বৈধ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                একমাত্র বিরুদ্ধ বিরোধিতা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. বুলীয় ভাষ্য অনুযায়ী কোন বচনের অস্তিত্বমূলক তাৎপর্য বা
                    সাত্ত্বিক ব্যঞ্জনা আছে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                বিশেষ বচনের অর্থাৎ I ও O বচনের।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    21. বুলীয় ভাষ্য অনুযায়ী কোন বচনের অস্তিত্বমূলক তাৎপর্য
                    নেই?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                সামান্য বচনের অর্থাৎ A এবং E বচনের।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>22. পরিপূরক শ্রেণি কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                মূল শ্রেণির বিরুদ্ধ শ্রেণিকে মূল শ্রেণির পরিপূরক শ্রেণি বলা হয়।{" "}
                <br />
                যেমন — ‘দার্শনিক’ শ্রেণির পরিপূরক শ্রেণি হল ‘অ-দার্শনিক’
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>23. বুলীয় ভাষ্য বা লিপি কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                নব্য যুক্তিবিজ্ঞানী জর্জ বুল নিরপেক্ষ বচনের যে ব্যাখ্যা
                দিয়েছেন, তার নাম বুলীয় ভাষ্য বা লিপি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>24. অস্তিত্বমূলক তাৎপর্য বা সাত্ত্বিক ব্যঞ্জনা কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                কোনো বচন উচ্চারিত হওয়া মাত্র বচনটির উদ্দেশ্য পদটির বাস্তবে
                অস্তিত্ব আছে যদি এমন বোঝায় — তাহলে বচনের এই বাস্তব অস্তিত্বকে
                বোঝানোর ক্ষমতাকেই বলা হয় বচনের অস্তিত্বমূলক তাৎপর্য।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>25. অ-শূন্য বা সাত্ত্বিক কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে শ্রেণির অন্তর্গত কমপক্ষে একজন সদস্যের বাস্তবে অস্তিত্ব আছে।
                তাকে অশূন্য বা সাত্ত্বিক শ্রেণি বলে। যেমন — মানুষ, পাখি, ফুল
                প্রভৃতি হল অশূন্য শ্রেণি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>26. ভেনচিত্র কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যুক্তিবিজ্ঞানী জন ভেন নিরপেক্ষ বচনকে যে চিত্রের মধ্য দিয়ে
                প্রকাশ করার পদ্ধতি উদ্ভাবন করেছেন, তাকে ভেনচিত্র বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>27. শ্রেণি কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                সমজাতীয় সদস্যদের নিয়ে গঠিত জাতি বা দলকে শ্রেণি বলে।
                <br />
                যেমন — পশু শ্রেণি, মানুষ শ্রেণি প্রভৃতি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>28. অস্তিত্বমূলক দোষ কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যদি কোনো যুক্তিতে এমন হয় যে অস্তিত্বমূলক তাৎপর্য নেই (A, E) এমন
                কোনো আশ্রয়বাক্য থেকে অস্তিত্বমূলক তাৎপর্য আছে (I, O) এমন কোনো
                সিদ্ধান্তকে নিঃসৃত করা হয়, তাহলে যুক্তিতে যে দোষ ঘটে, তাকে
                অস্তিত্বমূলক দোষ বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  29. DARAPTI, FELAPTON, BRAMANTIP, FESAPO—এই চারটি মূর্তি
                  নব্যমতে বা বুলীয় মতে বৈধ নয় কেনো?
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                এই চারটি মূর্তির ক্ষেত্রেই আশ্রয়বাক্য দুটি সামান্য বচন অর্থাৎ
                আশ্রায়বাক্যের অস্তিত্বমূলক তাৎপর্য নেই, কিন্তু সিদ্ধান্ত
                বিশেষবচন অর্থাৎ সিদ্ধান্তের অস্তিত্বমূলক তাৎপর্য আছে। ফলে
                এক্ষেত্রে সবক-টি মূর্তি অস্তিত্বমূলক দোষে দুষ্ট।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

বুলীয়ভাষ্যওভেনচিত্র.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(বুলীয়ভাষ্যওভেনচিত্র);
