import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function জীবপ্রযুক্তিএবংএরপ্রয়োগ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          জীব প্রযুক্তি এবং এর প্রয়োগ | West Bengal Class 12 Guide | West
          Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="জীব প্রযুক্তি এবং এর প্রয়োগ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                জীব প্রযুক্তি এবং এর প্রয়োগ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ব্লু-বায়োটেকনোলজি হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শিল্প সম্বন্ধীয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কৃষিকাজ সম্বন্ধীয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জলজ জীব ও পরিবেশ সম্বন্ধীয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চিকিৎসা সম্বন্ধীয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  জৈব রাসায়নিক যৌগের পৃথকীকরণে ব্যবহৃত স্পেকট্রোস্কোপি পদ্ধতিতে
                  প্রয়োজনীয় উপাদান —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  y-রশ্মি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  X-রশ্মি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  UV-রশ্মি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সবকটি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>গ্রিন বায়োটেকনোলজি বলতে বোঝায় — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  কৃষিকাজে ব্যবহৃত জৈব প্রযুক্তি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চিকিৎসাশাস্ত্রে ব্যবহৃত জৈব প্রযুক্তি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শিল্পে ব্যবহৃত জৈব প্রযুক্তি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  জৈব নিরাপত্তা-এর নির্দেশনা ও নিয়মসমূহ-এর নিয়ন্ত্রক সংস্থা হল
                  -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  GEAC
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  IBSC
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  RCGM
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সবকটি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  কোন্ পদ্ধতিটি অ্যান্টিজেন-অ্যান্টিবডির পারস্পরিক ক্রিয়ার উপর
                  নির্ভরশীল —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জিন থেরাপি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  PCR
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ELISA
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইলেকট্রোফোরেসিস
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>‘Flavr savr' হল যে ট্রান্সজেনিক ভ্যারাইটি —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চাল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  টম্যাটো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তুলো
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বায়োপাইরেসি ঘটেছে এমন উদ্ভিদ হল — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নিম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বাসমতি চাল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কালোজাম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সবকটি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>প্রথম ট্রান্সজেনিক প্রাণী হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ইঁদুর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গোরু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভেড়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রোসি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>জেনেটিক ইঞ্জিনিয়ারিং শব্দের প্রবক্তা হলেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোহন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পল বার্গ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  উইলিয়ামসন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বয়ার
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>তেজস্ক্রিয় অণুর সঙ্গে যুক্ত DNA বা RNA খণ্ডককে বলা হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  প্রোব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্লোন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্লাজমিড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভেক্টর
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>দ্বাদশ অধ্যায় - জীব প্রযুক্তি এবং এর প্রয়োগ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. ইউক্যারিওট পোষক হিসেবে একটি বহুল ব্যবহৃত জীবের নাম করে।
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ইস্ট
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. PCR কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                যান্ত্রিক উপায়ে DNA সংশ্লেষণের একটি পদ্ধতি হল PCR
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. PCR পদ্ধতি কে আবিষ্কার করেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ক্যারি মুলিস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. সাদার্ন ব্লটিং পদ্ধতিটি কে প্রবর্তন করেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                এডওয়ার্ড সার্দান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. ওয়েস্টার্ন ব্লটিং কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                যে ব্লটিং পদ্ধতিতে জেল থেকে প্রোটিনকে তোলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. VNTR কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                DNA-এর বিভিন্ন অংশের পুনরাবৃত্তি সজ্জাগুলিকে বলে VNTR
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. দুটি কৃত্রিম ক্রোমোজোম বাহকের নাম উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                BAC, YAC
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. কসমিডের একটি উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                PLR5
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    9. জিন অন্তর্ভুক্তির কাজে সহায়ক দুটি পদ্ধতির নাম উল্লেখ
                    করো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ট্রান্সফরমেশন, কনজুগেশন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. DNA লাইব্রেরি কয় প্রকার ও কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                দু-প্রকারের হয়। যথা—জিনোম DNA লাইব্রেরি ও CDNA লাইব্রেরি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>11. PCR-এর একটি প্রয়োগ উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                DNA সজ্জার পরিমাণ বৃদ্ধি করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. ELISA পদ্ধতিতে ব্যবহৃত দুটি উৎসেচকের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                অ্যামাইলেজ ও পারক্সিডেজ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. জৈবপ্রযুক্তি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জীব বা জীব উপজাত পদার্থের ব্যবহারিক ও শিল্পভিত্তিক প্রয়োগ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. জিন ক্লোনিং কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                যে পদ্ধতিতে নির্দিষ্ট জিন থেকে অনেকগুলি অভিন্ন প্রতিলিপি তৈরি
                করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. রেস্ট্রিকশন এন্ডোনিউক্লিয়েজ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                যেসব উৎসেচক DNA খণ্ডিত করলে ব্যবহৃত হয়, যা প্রধানত
                ব্যাকটেরিয়াতে পাওয়া যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. প্লাসমিড কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ব্যাকটেরিয়া কোশে নিজস্ব DNA ছাড়া অপর যে ক্ষুদ্র, চক্রাকার,
                স্ববিভাজনক্ষম, দ্বিতন্ত্রী DNA থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. জেনেটিক ইঞ্জিনিয়ারিং কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জিনের কার্যকরী সংগঠনের পরিবর্তন ঘটানোর কৌশল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. ক্লোনিং কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                যে প্রক্রিয়ায় একটি কোশ থেকে একই রকম জিনগত গঠনবিশিষ্ট অনেক কোশ
                তৈরি হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. টোটিপোটেন্সি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                যে ক্ষমতাবলে কোনো প্রাণীকোশ একটি পূর্ণাঙ্গ প্রাণী সৃষ্টিতে সক্ষম
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. ট্রান্সজেনিক কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ট্রান্সজেনিক জীব সৃষ্টির জন্য যে অভীষ্ট জিনটি ব্যবহৃত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>21. দুটি ট্রান্সজেনিক প্রাণীর উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ভেড়া, ইঁদুর।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. রিভার্স ট্রান্সক্রিপটেজ কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                এটি একপ্রকার RNA নির্ভর DNA পলিমারেজ। অর্থাৎ উৎসেচকরি RNA-এর ওপর
                ভিত্তি করে DNA উৎপাদন করাতে পারে। রেট্রো ভাইরাসদের মধ্যে এই
                উৎসেচক পাওয়া যায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. বায়োসেফটি বলতে কী বোঝো?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                জেনেটিক ইঞ্জিনিয়ারিং-এ উৎপাদিত বস্তুর বৈশিষ্ট্য সম্পর্কে
                ভবিষ্যৎবাণী করা যায় না, ফলে জিন টেকনোলজিতে যথেষ্ট পরিমাণ ঝুঁকি
                থেকেই যায়। ট্রান্সজেনিক জীব থেকে উদ্ভিদ, প্রাণী, অণুজীবদের কোনো
                ঝুঁকি প্রতিরোধ করার জন্য যে ব্যবস্থা গ্রহণ করা হয় তাকে
                বায়োসেফটি বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. বায়োপেটেন্ট বলতে কী বোঝ? </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                জৈব নিরাপত্তা সংক্রান্ত বিষয় উত্তর কোনো জীবজ সত্বা, উপাদান,
                পণ্য ইত্যাদি বা তার ব্যবহারে কোনো ব্যক্তি বা সংস্থার প্রতি সরকার
                প্রদত্ত বিশেষ অধিকার যার ফলে অন্য ব্যক্তি বা সংস্থা অনুমতি
                ব্যতিরেকে ব্যবসায়িক ভিত্তিতে ব্যবহার করতে না পারে। এই অধিকারই
                হল বায়োপেটেন্ট।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>4. বায়োপাইরেসি কী? </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                কিছু সংগঠন বা সংস্থা এবং বহুজাতিক কোম্পানিগুলি অন্য জাতিগুলির
                অর্থাৎ দেশগুলির জৈব সম্পদ বা পেটেন্ট জৈব সম্পদ যথাযথ অনুমতি
                ব্যতিরেকেই লুণ্ঠন করছে তাকে বায়োপাইরেসি বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. GMO কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                চিরাচরিত ব্রিডিং পদ্ধতির পরিবর্তে উদ্ভিদ, প্রাণী, ছত্রাক এবং
                অণুজীবের মধ্যে জিন প্রবেশ করিয়ে যে পরিবর্তিত জীব সৃষ্টি করা হয়
                তাদের Genetically Modified Organism বা GMO বলে।
                <br />
                যেমন – Bt শস্য, Flavr savr টম্যাটো।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. জেনেটিক ফিউশন কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                একটি ক্রোমোজোমের এক জায়গার কতকগুলি জিন ওই ক্রোমোজোমের অপর কোনো
                জায়গায় যুক্ত হলে তাকে বলে জেনেটিক ফিউশন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. জেল ইলেকট্রোফোরেসিস কী? </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যে পদ্ধতিতে জেল মাধ্যমযুক্ত তড়িৎক্ষেত্রে আধানযুক্ত বিভিন্ন অণু
                (DNA, RNA, প্রোটিন ইত্যাদি) স্থাপিত করে তাদের আধান, আকৃতি, আয়তন
                ও আণবিক ওজন অনুসারে পৃথক করা হয় তাকে বলে জেল ইলেকট্রোফোরেসিস।
                <br />
                <br />
                এই পদ্ধতিতে ব্যবহৃত জেল-এর উপর নির্ভর করে ইলেকট্রোফোরেসিস
                বিভিন্ন প্রকার হয়। যেমন—অ্যাগারোজ্ জেল ইলেকট্রোফোরেসিস, পলি
                অ্যাক্রাইল্যামাইড জেল ইলেকট্রোফোরেসিস।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>8. কসমিড কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ফাজ এর DNA-এর কোহেসিভ বা Cos সাইটের সঙ্গে প্লাজমিডের
                সংযুক্তিকরণে ব্যবহৃত ভেক্টর হল কসমিড।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

জীবপ্রযুক্তিএবংএরপ্রয়োগ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(জীবপ্রযুক্তিএবংএরপ্রয়োগ);
