/**
 *
 * ChipComponent
 *
 */

import React, { memo } from "react";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

function ChipComponent(props) {
  return (
    <>
      <Stack direction="row" spacing={1}>
        <div>
          {props.currentChannels &&
            props.currentChannels.map((currentChannel, index) => (
              <Chip
                key={currentChannel.channelId}
                clickable={true}
                avatar={<Avatar>M</Avatar>}
                label={currentChannel.channelName}
                sx={{ margin: 1 }}
                onClick={() => console.log("hi")}
                variant={"filled"}
              />
            ))}
        </div>
      </Stack>
      <Divider />
    </>
  );
}

ChipComponent.propTypes = {};

export default memo(ChipComponent);
