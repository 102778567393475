import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function আরোহমূলকদোষ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          আরোহমূলক দোষ | West Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="আরোহমূলক দোষ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                আরোহমূলক দোষ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>দ্বাদশ অধ্যায় - আরোহমূলক দোষ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  {" "}
                  1. নীচের আরোহ যুক্তিগুলি বিচার করো এবং কোনো দোষ থাকলে তা
                  উল্লেখ করো : 4+4 (i) পর্যাপ্ত বৃষ্টিপাত ভালো ফসল হওয়ার কারণ।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>
              </font>
              <font color="#3c92e5">
                প্রদত্ত আরোহ যুক্তিতে একটি অত্যন্ত গুরুত্বপূর্ণ শর্তকে সম্পূর্ণ
                কারণ হিসেবে বিবেচনা করার অপরাধ বা একটি শর্তকে কারণ হিসেবে প্রমাণ
                করার অপরাধ ঘটেছে। আমরা জানি যে, কারণ হল একটি সংখ্যক আবশ্যিক
                শর্তের সম্মিলিত পরিমাণ। অর্থাৎ, একটি আবশ্যিক শর্ত হল সম্মিলিত
                কারণের একটি নির্দিষ্ট অংশ। যখন কোনো ক্রিয়ার কারণ নির্ধারণ করতে
                যাই, যদি সম্মিলিত শর্তের যেকোনো একটি শর্তকে, অর্থাৎ একটি আবশ্যিক
                শর্তকে, সম্মিলিত কারণ হিসেবে গ্রহণ করা হয়, তখন যুক্তিতে যে
                অপরাধ সৃষ্টি হয় তাকে বলা হয় 'একটি আবশ্যিক শর্তকে সম্পূর্ণ কারণ
                হিসেবে বিবেচনা করার অপরাধ।
                <br />
                <br />
                প্রদত্ত দৃষ্টান্তটিতে শুধুমাত্র পর্যাপ্ত বৃষ্টিপাতকে ভালো ফসল
                হওয়ার কারণ হিসেবে উল্লেখ করা হয়েছে। কিন্তু পর্যাপ্ত বৃষ্টিপাত
                ভালো ফসল হওয়ার একটি শর্তমাত্র, সমগ্র কারণ নয়। পর্যাপ্ত
                বৃষ্টিপাতের পাশাপাশি উর্বর মৃত্তিকা, উন্নত মানের বীজ, কীটনাশক,
                রাসায়নিক সার প্রভৃতির প্রয়োজন হয়। এগুলি একত্রিতভাবে ভালো ফসল
                উৎপন্ন করে। কাজেই এই শর্তগুলির মধ্যে কেবলমাত্র একটিকে (পর্যাপ্ত
                বৃষ্টিপাত) ভালো ফসল হওয়ার কারণ হিসেবে গণ্য করার জন্য যুক্তিটিকে
                একটি আবশ্যিক শর্তকে সমগ্র কারণ হিসেবে গণ্য করার দোষ ঘটেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    (ii) ছেলেটি উচ্চমাধ্যমিক পরীক্ষায় খারাপ ফল করেছে, কারণ ফল
                    প্রকাশের দিন সে একটি কালো বিড়াল দেখেছিল।
                  </b>
                </font>
                <br />
                <br />
                <font color="#1f7405">
                  <b> উত্তর : </b>
                </font>{" "}
                প্রদত্ত আরোহ যুক্তিতে অবান্তর বা অপ্রাসঙ্গিক বিষয়কে কারণ বলে
                গণ্য করার দোষ ঘটেছে। কোনো ঘটনা কারণ হওয়ার ক্ষেত্রে, সেই
                ঘটনাটিকে কার্য উৎপত্তির সাথে প্রাসঙ্গিক নয়। এমন ঘটনাটির যেটি
                বর্জন করা যায় যদি কার্যের উৎপত্তির ক্ষেত্রে কোনো বাধা বা বিঘ্ন
                সৃষ্টি না হয়, সেই ঘটনাটিকে কার্য উৎপত্তির সাথে অবান্তর বা
                অপ্রাসঙ্গিক ঘটনা হতে হবে। একটি ঘটনা প্রসঙ্গে যা অবান্তর ও
                অপ্রাসঙ্গিক তা কখনও সেই ঘটনার কারণ হিসেবে গণ্য হতে পারে না। কোনো
                কার্যের যেটি প্রকৃত কারণ তা কারণ হিসেবে গণ্য না করে যদি কারণের
                সাথে যুক্ত নয়, এমন কোনো অবান্তর বা অপ্রাসঙ্গিক বিষয়কে কারণ বলে
                গণ্য করা হয়, তাহলে যুক্তিতে যে দোষ ঘটে তাকে বলে 'অবান্তর ঘটনাকে
                কারণ বলে গণ্য করার দোষ।
                <br />
                <br />
                প্রদত্ত যুক্তিটিতে ছেলেটির উচ্চমাধ্যমিকে খারাপ ফলের কারণরূপে ফল
                প্রকাশের দিন কালো বিড়াল দেখাকে গণ্য করা হয়েছে। কিন্তু ‘কালো
                বিড়াল দেখা’ এবং ‘উচ্চমাধ্যমিকে খারাপ ফল করা' এই দুটি বিষয়ের
                মধ্যে কোনো কার্যকারণ সম্পর্ক নেই। এক্ষেত্রে খারাপ ফলের প্রকৃত
                কারণকে কারণরূপে গণ্য না করে একটি অবান্তর বিষয়কে (কালো বিড়াল
                দেখা) কারণরূপে গণ্য করা হয়েছে। তাই যুক্তিটিতে অবান্তর ঘটনাকে
                কারণরূপে গণ্য করার দোষ ঘটেছে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  {" "}
                  2. টীকা লেখো : (i) অবৈধ সামান্যীকরণ দোষ (ii) কোনো অবান্তর বা
                  অপ্রাসঙ্গিক ঘটনাকে কারণ বলে গণ্য করার দোষ। 4 + 4{" "}
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>
              </font>
              <font color="#3c92e5">
                <font color="#f626d7">
                  <b>(i) অবৈধ সামান্যীকরণ দোষ : </b>
                </font>{" "}
                কার্যকরণের সংক্রিয়া নির্ণয় না করে শুধুমাত্র দুটি ঘটনার সাথে
                সম্পর্ক পর্যবেক্ষণ করে এবং যদি সামান্যীকরণ হয়, অর্থাৎ সামান্য
                বক্তব্যের প্রতিষ্ঠা হয়, তখন যুক্তিতে যে দোষ ঘটে তা
                অপ্রযুক্তিক-সামান্যীকরণ দোষ বলা হয়। লৌকিক আরোহ অনুমানের
                ক্ষেত্রে সাধারণভাবে অপ্রযুক্তিক-সামান্যীকরণ দোষ প্রকাশ পায়। এটি
                এমন কারণে যে, লৌকিক আরোহ অনুমানে কার্যকারণ সম্পর্কের উপর নির্ভর
                না করে, শুধুমাত্র সামান্য অভিজ্ঞতার আধারে সামান্যীকরণের মাধ্যমে
                একটি সামান্য বক্তব্য সিদ্ধান্ত হয়। আবারও অনেক সময় দেখা যায়
                যে, কোনো ঘটনার নিষ্প্রয়োজন দৃষ্টান্ত উল্লিখিত না করে, শুধুমাত্র
                যথাযথ দৃষ্টান্তের উপর ভিত্তি করে একটি সামান্য বক্তব্য সিদ্ধান্ত
                হয়, ফলে যুক্তিতে অপ্রযুক্তিক-সামান্যীকরণ দোষ ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>উদাহরণ : </b>
                </font>{" "}
                আজ পর্যন্ত যত কাক দেখেছি সব কালো, ছাড়া অন্য রঙের কাক দেখিনি।
                সুতরাং, কাক মাত্রই কালো।
                <br />
                <br />
                উক্ত আরোহ যুক্তিতে অবৈধ সামান্যীকরণ দোষ ঘটেছে। এক্ষেত্রে কয়েকটি
                কাককে কালো দেখে সিদ্ধান্ত গ্রহণ করা হয়েছে যে, কাক মাত্রই কালো।
                কিন্তু এরূপ সিদ্ধান্ত করার আগে কোনো নঞর্থক দৃষ্টান্ত পর্যবেক্ষণ
                করা হয়নি। তা ছাড়া এখানে ‘কাক’ এবং 'কালো’—এই দুটি বিষয়ের মধ্যে
                কোনো কার্যকারণ সম্বন্ধ বিচার না করে কেবলমাত্র অবাধ অভিজ্ঞতার
                ভিত্তিতে সিদ্ধান্ত প্রতিষ্ঠা করা হয়েছে। এরজন্য যুক্তিটিতে অবৈধ
                সামান্যীকরণ দোষ ঘটেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    (ii) কোনো অবান্তর বা অপ্রাসঙ্গিক ঘটনাকে কারণ বলে মনে করার
                    দোষ :{" "}
                  </b>
                </font>{" "}
                কোনো ঘটনাকে কারণ হতে গেলে সেই ঘটনাটিকে কার্য উৎপত্তির ক্ষেত্রে
                প্রাসঙ্গিক ঘটনা হতে হবে। যে ঘটনাকে বর্জন করলে কার্যের উৎপত্তির
                ক্ষেত্রে কোনো বাধা বা বিপত্তি ঘটে, সেই ঘটনাকে কার্যের উৎপত্তি
                ক্ষেত্রে প্রাসঙ্গিক ঘটনা বলা হয়। আর যে ঘটনাকে বর্জন করলে কার্য
                উৎপত্তির ক্ষেত্রে বিঘ্ন ঘটে না, সেই ঘটনাকে কার্য উৎপত্তির
                ক্ষেত্রে অপ্রাসঙ্গিক বা অবান্তর ঘটনা বলা হয়। কোনো ঘটনা প্রসঙ্গে
                যা অবান্তর বা অপ্রাসঙ্গিক তা কখনও সেই ঘটনার কারণরূপে গণ্য হতে
                পারে না। কোনো কার্যের যেটি প্রকৃত কারণ তাকে কারণ হিসেবে গণ্য না
                করে যদি কারণের সঙ্গে যুক্ত নয় এমন কোনো অবান্তর বা অপ্রাসঙ্গিক
                বিষয়কে কারণ বলে গণ্য করা হয়, তাহলে যুক্তিতে যে দোষ ঘটে, তাকে
                অবান্তর বা অপ্রাসঙ্গিক বিষয়কে কারণ বলে মনে করার দোষ বলা হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>উদাহরণ : </b>
                </font>{" "}
                নতুন জামা পরার পরই রাতুলের জ্বর হল। সুতরাং, নতুন জামা পরাই
                রাতুলের জ্বরের কারণ।
                <br />
                <br />
                উক্ত আরোহ যুক্তিতে কোনো অবান্তর বা অপ্রাসঙ্গিক ঘটনাকে কারণ বলে
                মনে করার দোষ ঘটেছে। নতুন জামা পরার পর রাতুলের জ্বর হওয়ায়
                এক্ষেত্রে সিদ্ধান্ত করা হয়েছে যে, নতুন জামা পরা জ্বর হওয়ার
                কারণ। কিন্তু এখানে ‘নতুন জামা পরা’ এবং ‘জ্বর হওয়া’—এই দুটি
                বিষয়ের মধ্যে কোনো কার্যকারণ সম্পর্ক নেই। এক্ষেত্রে জ্বর হওয়ার
                প্রকৃত কারণকে চিহ্নিত না করে একটি অবান্তর বা অপ্রাসঙ্গিক বিষয়কে
                (নতুন জামা পরা) কারণ বলে গণ্য করার জন্য যুক্তিটিতে অবান্তর বা
                অপ্রাসঙ্গিক বিষয়কে কারণ বলে গণ্য করার দোষ ঘটেছে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  3. নীচের আরোহ যুক্তিগুলি বিচার করো এবং কোনো দোষ থাকলে তা
                  উল্লেখ করো : 4 + 4
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>
              </font>
              <font color="#3c92e5">
                (i) আকাশে ধূমকেতু আবির্ভাবের ঠিক পরেই রাজার মৃত্যু হল। সুতরাং,
                আকাশে ধূমকেতুর আবির্ভাবই রাজার মৃত্যুর কারণ।
                <br />
                <br />
                প্রদত্ত আরোহ যুক্তিটিতে কাকতালীয় দোষ উদ্ভূত হয়েছে। এই দোষের
                কারণ হল কার্যের অব্যবহিত শর্তান্তরহীন অপরিবর্তনীয় পূর্ববর্তী
                ঘটনা। একটি ঘটনা যদি শুধুমাত্র পূর্ববর্তী হয়, তাহলে তা অপর একটি
                ঘটনার কারণ হিসেবে গণ্য করা যায় না। পূর্ববর্তী ঘটনাকে যেকোনো
                পরবর্তী ঘটনার কারণ বলে গণ্য করলে, যুক্তিতে উত্পন্ন হওয়া দোষটির
                নাম হয় 'কাকতালীয় দোষ'। ব্যতিরেকী পদ্ধতি অপপ্রয়োগ করা সামান্য
                হলেই, কাকতালীয় দোষ উদ্ভূত হয়। পর্যবেক্ষণের উপর ভিত্তি করে
                ব্যতিরেকী পদ্ধতি ব্যবহার করলে, যুক্তিতে কাকতালীয় দোষ সৃষ্টি
                হওয়ার সম্ভাবনা থাকে। লৌকিক সংস্কারের ফলে এই দোষ সন্দর্ভে
                পর্যাপ্ত প্রমাণ দেখা দেয়।
                <br />
                <br />
                প্রদত্ত দৃষ্টান্তটিতে আকাশে ধূমকেতুর আবির্ভাবের পরেই রাজার
                মৃত্যু হওয়ায় ধূমকেতুর আবির্ভাবকে রাজার মৃত্যুর কারণরূপে
                চিহ্নিত করা হয়েছে। কিন্তু ধূমকেতুর আবির্ভাব রাজার মৃত্যুর
                পূর্ববর্তী ঘটনা হলেও অপরিবর্তনীয় বা নিয়ত পূর্ববর্তী ঘটনা নয়।
                ধূমকেতুর আবির্ভাব রাজার মৃত্যুর ক্ষেত্রে একটি অনিয়ত পূর্ববর্তী
                ঘটনা। এই অনিয়ত পূর্ববর্তী ঘটনাকে কারণ হিসেবে গণ্য করায়
                এক্ষেত্রে কাকতালীয় দোষ ঘটেছে।
                <br />
                <br />
                (ii) টেলিগ্রাম অশুভ। কারণ টেলিগ্রাম দুঃসংবাদ নিয়ে আসে।
                <br />
                <br />
                প্রদত্ত আরোহ যুক্তিটিতে অকার্যকর সামানীকরণ দোষ সৃষ্টি হয়েছে। এই
                দোষের মূল কারণ হল, কার্যকারণ সম্পর্ক নির্ধারণের প্রচেষ্টা
                অকার্যকর এবং কেবল অবাধ অভিজ্ঞতার উপর নির্ভর করে সামানীকরণ করা
                হয়। অর্থাৎ, সামান্য বচন প্রতিষ্ঠা করা হয়। এই প্রস্তাবনার ফলে,
                যুক্তিতে যে দোষ উত্পন্ন হয় তা অকার্যকর সামানীকরণ দোষ নামে
                পরিচিত। আদ্যতঃ লৌকিক বা অবৈজ্ঞানিক আরোহ অনুমানের সেই সময়,
                অকার্যকর সামানীকরণ দোষ উদ্ভূত হয়। একই সময়ে, লৌকিক আরোহের
                ক্ষেত্রে, নিষ্কর্ষণ অনুমানের পর পূর্ববর্তী দৃষ্টান্তের অবশ্যই
                পর্যাপ্ত পর্যবেক্ষণ করা হয় না, কেবল অবাধ অভিজ্ঞতার উপর নির্ভর
                করে সামানীকরণ করা হয়। এমনকি, তাই লৌকিক আরোহের ক্ষেত্রে অকার্যকর
                সামানীকরণ দোষ উদ্ভূত হয়। এই ব্যাপারে তর্কবিদ মিলের মতামত, একটি
                প্রাধান্যতার বিধান মেলে, কার্যকারণ সম্পর্ক নির্ধারণ না করে কেবল
                দুটি ঘটনার একই অবস্থানের কয়েকটি দৃষ্টান্ত নির্দেশ করে সামানীকরণ
                করলে, অকার্যকর সামানীকরণ দোষ সৃষ্টি হয়। এই উপায়ে প্রদত্ত
                দৃষ্টান্তে একটি টেলিগ্রাম যেমন অশুভ বার্তা বহন করতে পারে, সেটির
                পরিবর্তে অনেক আবারও টেলিগ্রাম যেমন শুভ বার্তা বহন করতে পারে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  4. টীকা লেখো : (i) কাকতালীয় দোষ। (ii) আবশ্যিক শর্তকে সমগ্র
                  কারণ বলে মনে করার দোষ বা একটি শর্তকে সমগ্র কারণ বলে করার দোষ।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর : </b>
              </font>
              <font color="#3c92e5">
                <font color="#f626d7">
                  <b>(i) কাকতালীয় দোষ : </b>
                </font>{" "}
                কারণ হল কার্যের নিয়ত শর্তান্তরহীন অব্যবহিত পূর্বগামী ঘটনা।
                যে-কোনো পূর্ববর্তী ঘটনাই কারণ নয়। কোনো পূর্ববর্তী ঘটনা যদি
                নিয়ত শর্তান্তরহীন হয়, তবে তা কারণরূপে গণ্য হবে। যে-কোনো অনিয়ত
                পূর্ববর্তী ঘটনাকে কারণ হিসেবে গণ্য করলে যে দোষের উদ্ভব হয়, তাকে
                বলে কাকতালীয় দোষ। ব্যতিরেকী পদ্ধতির অপপ্রয়োগের ফলে কাকতালীয়
                দোষ ঘটে। পর্যবেক্ষণের উপর ভিত্তি করে ব্যতিরেকী পদ্ধতি প্রয়োগ
                করলে যুক্তিতে কাকতালীয় দোষ ঘটার সম্ভাবনা থাকে। আমাদের
                অন্ধবিশ্বাস ও কুসংস্কার এই দোষের উৎস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>উদাহরণ : </b>
                </font>{" "}
                নতুন পোশাকটি পরার পরই তার জ্বর হল। সুতরাং, নতুন পোশাকটি পরাই তার
                জ্বরের কারণ।
                <br />
                <br />
                এই যুক্তিটি কাকতালীয় দোষে দুষ্ট। এক্ষেত্রে ‘নতুন পোশাক পরা’ ও
                ‘জ্বর হওয়া’ এই দুটি বিষয়ের মধ্যে কোনো কার্যকারণ সম্পর্ক নেই।
                নতুন পোশাক পরা এক্ষেত্রে জ্বর হওয়ার পূর্ববর্তী ঘটনা হলেও নিয়ত
                পূর্ববর্তী নয়। কেন-না এমন অনেক মানুষ আছে, যাদের নতুন পোশাক পরার
                পর জ্বর হয়নি। আমরা জানি কোনো ঘটনাকে কারণ হতে গেলে তাকে
                শর্তান্তরহীন নিয়ত পূর্ববর্তী হতে হয়। কিন্তু ‘নতুন পোশাক পরা’
                জ্বর হওয়ার ক্ষেত্রে নিয়ত পূর্ববর্তী ঘটনা নয়। এক্ষেত্রে একটি
                অনিয়ত পূর্ববর্তী ঘটনাকে কারণ হিসেবে গণ্য করা হয়েছে। তাই উক্ত
                যুক্তিটিতে কাকতালীয় দোষ ঘটেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(ii) আবশ্যিক শর্তকে সমগ্র কারণ বলে মনে করার দোষ : </b>
                </font>{" "}
                কারণ হল কতকগুলি আবশ্যিক শর্তের সমষ্টি। যে শর্ত উপস্থিত না থাকলে
                কার্যটি ঘটে না, সেই শর্তকে আবশ্যিক শর্ত বলে। যেমন—অক্সিজেনের
                উপস্থিতি দহনের আবশ্যিক শর্ত। কেন-না, অক্সিজেন না থাকলে দহন হবে
                না। তবু অক্সিজেনকে দহনের কারণ বলা যায় না। কারণ শুধুমাত্র
                অক্সিজেন উপস্থিত থাকলেই দহন হয় না। দহনের জন্য অগ্নিসংযোগের
                প্রয়োজন। এরজন্য কারণকে একাধিক আবশ্যিক শর্তের সমষ্টি বলা হয়।
                কোনো কার্যের কারণ নির্ণয় করতে গিয়ে যদি শর্ত সমষ্টির কোনো একটি
                শর্তকে সমগ্র কারণ হিসেবে গ্রহণ করা হয়, তাহলে যুক্তিতে যে দোষ
                ঘটে তাকে বলে একটি আবশ্যিক শর্তকে সমগ্র কারণ হিসেবে গণ্য করার
                দোষ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>উদাহরণ : </b>
                </font>{" "}
                নদীতে নৌকাডুবির ফলে লোকটি মারা গেল। সুতরাং, নৌকাডুবিই হল লোকটির
                মৃত্যুর কারণ।
                <br />
                <br />
                এই যুক্তিটিতে একটি আবশ্যিক শর্তকে সমগ্র কারণ বলে মনে করার দোষ
                ঘটেছে। এক্ষেত্রে নৌকাডুবিকে লোকটির মৃত্যুর কারণ হিসেবে গণ্য করা
                হয়েছে। কিন্তু নৌকাডুবি লোকটির মৃত্যুর ক্ষেত্রে একটি আবশ্যিক
                শর্তমাত্র। নৌকাডুবি, লোকটির সাঁতার না জানা, নদীর জলের গভীরতা,
                আশেপাশে বাঁচানোর কেউ না থাকা প্রভৃতি শর্তগুলি একত্রিতভাবে লোকটির
                মৃত্যু নামক ঘটনাটি ঘটিয়েছে। এই শর্তগুলির মধ্যে কেবলমাত্র একটি
                (নৌকাডুবি) এক্ষেত্রে কারণ হিসেবে উল্লেখ করার জন্য যুক্তিটিতে
                একটি আবশ্যিক শর্তকে সমগ্র কারণ বলে মনে করার দোষ ঘটেছে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

আরোহমূলকদোষ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(আরোহমূলকদোষ);
