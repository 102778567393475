import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function DatabaseManagementSystem() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          Database Management System | West Bengal Class 12 Guide | West Bengal
          HS Suggestion
        </title>
        <meta
          name="description"
          content="Database Management System | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Database Management System
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ER model এ ER এর পূর্ণ রূপ কী?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Enter Relationship
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Enter Relation
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Entity Relationship
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Entity Relation
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>DCL এর পূর্ণরূপ কী?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Data Control Logic
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Digital Control Logic
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Digit Control Language
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Data Control Language
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ডেটাবেসের সার্বিক গঠন কয়টি স্তরে বিভক্ত?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>1
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>2
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>3
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>4
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Projection এর জন্য কোন চিহ্ন ব্যবহার করা হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>Σ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>@
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>п
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>+
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নীচের কোনটি সঠিক ডেটাবেস মডেল নয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Relational model
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Chart model
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Network model
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Hierarchi model
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সাতটি Column ও পাঁচটি row যুক্ত টেবিলের degree কত?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>5
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>6
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>7
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>8
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Database এর Structure কে কী বলা হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Schema
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  field
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Entity
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Relation
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>প্রতিটি Column ডেটাবেসে কী নামে পরিচিত?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Data
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Field
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Date
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Control
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>DBA এর পূর্ণরূপ কী?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Data Base Administrator
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Data Base Adminision
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Data Bas Address
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Data Base Address
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Data about data- কে কী বলা হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  DCL
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Meta data
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  FR model
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Data dictionary
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b>Chapter ➺ 3</b>
              </font>{" "}
              <br />
              <font color="#2b9a09" size="4">
                <b>Database Management System</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. Database কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                ডেটা শব্দের অর্থ হচ্ছে উপাত্ত এবং বেজ শব্দের অর্থ হচ্ছে ঘাঁটি বা
                সমাবেশ। পরস্পর সম্পর্কযুক্ত এক বা একাধিক ডেটা ফাইলের সমষ্টি
                হচ্ছে ডেটাবেজ (Database)। Database এর মাধ্যমে সহজে এবং দ্রুত
                ভাবে তথ্য সংরক্ষণ করা যায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. ডেটাবেসের সুবিধাগুলি ব্যাখ্যা করো।</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                ডেটাবেজ ব্যবহারের সুবিধা গুলো নিচে উল্লেখ করা হল - <br />
                ১. অতি দ্রুত ডেটা উপস্থাপন করা যায়।
                <br />
                ২. অত্যন্ত দক্ষতার সাথে ডেটা পরিচালনা করা যায়।
                <br />
                ৩. সংরক্ষিত ডেটাকে পরবর্তীতে আপডেট করা সহজ হয়।
                <br />
                ৪. ডেটাবেসের তথ্য সমূহকে প্রয়োজনে অ্যাসেন্ডিং ও ডিসেন্ডিং
                অর্ডারে সাজানো যায়।
                <br />
                ৫. অল্প সময়ে ডেটার বিন্যাস ঘটানো যায়।
                <br />
                ৬. একই ডেটার বারবার উপস্থাপন প্রয়োজন হয় না।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. ডেটাবেসের অসুবিধাগুলি ব্যাখ্যা করো।</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                ডেটাবেজ ব্যবহারের নিম্নলিখিত অসুবিধা রয়েছে -<br />
                ১. ভুল ডেটা আইটেম সম্পূর্ণ ডেটাবেসে প্রবাহিত হতে পারে।
                <br />
                ২. ডেটাবেস বাস্তবায়নের জন্য প্রশিক্ষণপ্রাপ্ত অভিজ্ঞ জনসম্পদ
                দরকার।
                <br />
                ৩. অনেক ডেটাবেস প্রক্রিয়াকরণ পদ্ধতি ধীর গতি সম্পন্ন।
                <br />
                ৪. যখন দূরবর্তী ব্যবহারকারীর ডেটাবেসের ডেটা ব্যবহার করে তখন
                নিরাপত্তা ঝুঁকি থাকে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>4. DBMS কী? </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                Database Management System বা DBMS হলো পরস্পর সম্পর্কযুক্ত তথ্য
                পর্যালোচনা করার জন্য অনেকগুলো প্রোগ্রামের সমন্বয়ে তৈরি এমন একটি
                সফটওয়্যার যার মাধ্যমে ডেটাবেসের মধ্যে থাকা ডেটাকে update,
                insert, delete করা হয় এবং Database এ সুরক্ষা প্রদান করা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. Database Schema কাকে বলে? </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                ডেটাবেসের সামগ্রিক পরিকল্পনা এবং ডিজাইনকে স্কিমা বা ডেটাবেস
                স্কিমা বলে। স্কিমা ডেটাবেসের এনটিটি (সাধারণত টেবিল কে এনটিটি
                বলে) এবং অ্যট্রিবিউট (সাধারণত টেবিলে ফিল্ডকে বলে) নিয়ে গঠিত এবং
                এটি এনটিটিগুলির মধ্যে সম্পর্ক কে প্রকাশ করে।
                <br />
                <br />
                ডেটাবেসের গঠন জটিল প্রকৃতির হওয়ায় সাধারণ ব্যবহারকারীদের থেকে
                মুল গঠনকে আড়াল করার জন্য ডেটাবেস স্কিমাকে তিন স্তরে বিভক্ত কারা
                হয়েছে –<br />
                <br />
                1. অভ্যন্তরীণ স্কিমা (Internal Schema) / Physical Schema
                <br />
                2. ধারণাগত স্কিমা (Logical Schema)
                <br />
                3. বাহ্যিক স্কিমা (External Schema)
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. Metadata কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                ডেটাবেসের বিভিন্ন Field Record, Table সংক্রান্ত ডেটা অর্থাৎ Data
                about data-কে Meta data বলা হয়।
                <br />
                <br />
                এখানে সংরক্ষিত থাকে –<br />
                অবজেক্টেটি কে বানিয়েছে এবং তার গঠন।
                <br />
                অবজেক্টের ডেটাটাইপ ও তার আকার।
                <br />
                অবজেক্টটি কখন তৈরি করা হয়েছে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. Data Dictionary কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                ডেটা ডিকশনারি হল একটি কেন্দ্রীভূত তথ্যভান্ডার, যেখানে কোনো ডেটার
                অর্থ এবং অন্য ডেটার সাথে কী সম্পর্ক সেই ধরনের তথ্য সংরক্ষিত
                থাকে। এটিকে ছোটো ডেটাবেস ম্যানেজমেন্ট সিস্টেম ও বলা চলে কারন
                এখানে মেটাডেটার ওপর কাজ হয়।
                <br />
                <br />
                এখানে সংরক্ষিত থাকে –<br />
                1. ডেটাবেস ডিজাইন এবং স্টোরেজ স্ট্রাকচার সম্পর্কিত বিবরণ।
                <br />
                2. ডেটাবেস স্কিমার বিবরণ।
                <br />
                3. ব্যবহারকারীর অনুমতি সম্পর্কিত বিবরণ।
                <br />
                4. বিভিন্ন ডেটা আইটেমের মধ্যে সম্পর্কজানিত বিবরণ।
                <br />
                5. ডেটাবেস ফাইল, রেকর্ড সাইজ, ফিল্ডের ডেটা টাইপ ইত্যাদির বিবরণ।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>8. Relationship কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                বিভিন্ন এনটিটিগুলির মধ্যে সম্পর্ক রক্ষা করার পদ্ধতিকেই রিলেশনশিপ
                বলা হয়। প্রত্যেক এনটিটির একটি স্বতন্ত্র অ্যাট্রিবিউট থাকে যার
                সহায্যে রিলেশনশিপ প্রতিস্থাপন করা হয়।
                <br />
                <br />
                যেমন – স্কুল এবং ছাত্রের মধ্যে সম্পর্ক, কর্মচারী এবং
                ডিপার্টমেন্টের মধ্যে সম্পর্ক ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. Database Model কাকে বলে?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>
                </font>
                ডেটাবেসের প্রতিটি Table এবং ডেটাগুলি নিজেদের মধ্যে কীভাবে
                Relationship করবে এবং কোন্ পদ্ধতি মেনে ডেটাবেসের Structure গঠিত
                হবে তা নির্ধারিত করার পদ্ধতিকে Database Model বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. Entity কাকে বলে?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>
                </font>
                বাস্তব জগতের সাথে সম্পর্কিত ডেটাবেস অবজেক্ট
                (Table,Form,Report,Query,Macro,Module,Pages) কে এনটিটি বলে।
                <br />
                যেমন : Student, Employee, School ইত্যাদি প্রত্যেকে একএকটি
                এনটিটি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. Degree কাকে বলে?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                একটি Table যে সংখ্যা Attribute থাকে তাকে Table এর degree বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. Attribute কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ডেটাবেসের প্রতিটি এনটিটি সেটের যে নিজস্ব বৈশিষ্ট্য থাকে তাদের ওই
                এনটিটি সেটের অ্যাট্রিবিউট বলে।
                <br />
                যেমন - Student(Roll, Name, Class, Mobile Marks) – এখানে Student
                এনটিটির অ্যাট্রিবিউট হল Roll, Name, Marks।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. DBA কী?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ডেটাবেসের এমন একটি ব্যবহারকারী যে ডেটাবেসের গঠন, Data backup,
                ডেটাবেসের অন্যান্য ব্যবহারকারীদের ডেটাবেস ব্যবহারের অনুমতি
                database design প্রভৃতি গুরুত্বপূর্ণ কাজগুলি করে তাকে DBA বলে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

DatabaseManagementSystem.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(DatabaseManagementSystem);
