import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function মানুষপরিবেশআন্তঃসম্পর্কপ্রাকৃতিকদুর্যোগওবিপর্যয়() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          মানুষ পরিবেশ আন্তঃসম্পর্ক প্রাকৃতিক দুর্যোগ ও বিপর্যয় | West Bengal
          Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="মানুষ পরিবেশ আন্তঃসম্পর্ক প্রাকৃতিক দুর্যোগ ও বিপর্যয় | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                মানুষ পরিবেশ আন্তঃসম্পর্ক প্রাকৃতিক দুর্যোগ ও বিপর্যয়
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b>সপ্তম অধ্যায়</b>
              </font>{" "}
              <br />{" "}
              <font color="#2b9a09" size="4">
                <b>মানুষ ও পরিবেশের পারস্পারিক সম্পর্ক</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ৭</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                ১. দুর্যোগ ও বিপর্যয়ের সংজ্ঞা বৈশিষ্ট্যসহ আলােচনা করাে।
                <br />
                ২. দুর্যোগ ও বিপর্যয়ের মধ্যে পার্থক্য লেখাে।
                <br />
                ৩. প্রাকৃতিক বিপর্যয়ের উৎপত্তি ও শ্রেণিবিভাগ আলােচনা করাে।
                <br />
                ৪. বিপর্যয়-পূর্ব ব্যবস্থাপনা সম্বন্ধে আলােচনা করাে।
                <br />
                ৫. বিপর্যয়ের পরবর্তী ব্যবস্থাপনা সম্বন্ধে লেখাে।
                <br />
                ৬. বিপর্যয়ের প্রশমন বলতে কী বােঝ এবং বিপর্যয় প্রশমনের বিভিন্ন
                কৌশলগুলি আলােচনা করাে।
                <br />
                ৭. প্রাকৃতিক দুর্যোগ ও প্রাকৃতিক বিপর্যয়ের মধ্যে পার্থক্য লেখো।
                কী কী ব্যবস্থা নিলে বিপর্যয়ের পূর্বে ক্ষয়ক্ষতির সম্ভাবনা কমানো
                যায়?
                <br />
                ৮. বিপর্যয় ব্যবস্থাপনার বিভিন্ন পর্যায় ও পদ্ধতি আলােচনা করাে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ১</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. zard শব্দের অর্থ কী?*</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                অপ্রত্যাশিত বা দৈব ঘটনা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. একটি আধা-প্রাকৃতিক দুর্যোগের উদাহরণ দাও।*</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বাঁধ ছাড়া জল দ্বারা বন্যা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. Hazard শব্দের অর্থ কী?*</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                অপ্রত্যাশিত বা দৈব ঘটনা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. একটি আধা-প্রাকৃতিক দুর্যোগের উদাহরণ দাও।*</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বাঁধ ছাড়া জল দ্বারা বন্যা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. দুটি ভূমিরূপগত দুর্যোগের নাম লেখাে।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                নদীর পাড় ভাঙন ও ধস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. দুটি ভূ-তাত্ত্বিক বিপর্যয়ের নাম লেখাে।*</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                অগ্ন্যুৎপাত ও ভূমিকম্প।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. একটি জীব সংশ্লিষ্ট বিপর্যয়ের নাম লেখাে।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মহামারি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. হড়পা বান’ বলতে কী বােঝায়? ** </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ‘মেঘভাঙা বৃষ্টি থেকে স্থানীয়ভাবে হঠাৎ করে অল্প সময়ের মধ্যে
                মাত্রাতিরিক্ত বৃষ্টিপাত থেকে যে প্রবল জলপ্রবাহ ঘটে, তাকে হড়পা
                বান’ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    9. 2013 খ্রিস্টাব্দের আগস্ট মাসে হড়পা বানের কারণে কোন্
                    রাজ্য সর্বাধিক প্রাকৃতিক বিপর্যয়ের সম্মুখীন হয়েছিল?*
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                উত্তরাখণ্ড রাজ্য। <br />
                <br />
                <font color="#f626d7">
                  <b>10. Disaster শব্দের উৎপত্তি কোন্ শব্দ থেকে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                Disaster শব্দের উৎপত্তি ফরাসি শব্দ থেকে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. দুর্যোগের ফলাফল কাকে বলে?*</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                বিপর্যয়কে
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. দুর্যোগ ও বিপর্যয়ের একটি পার্থক্য লেখাে।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দুর্যোগ হলেই যে তা বিপর্যয়ের রূপ নেবে তা নয়। অথচ বিপর্যয়ই
                দুর্যোগের পরবর্তী ভয়ংকর পর্যায় বা রূপ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    13. কোন্ বিজ্ঞানী পরিবেশগত বিপদের প্রাবল্য, সম্ভাবনা ও
                    ঝুঁকির মধ্যে সম্পর্কজনিত লেখচিত্র নির্মাণ করেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বিজ্ঞানী কিথ স্মিথ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    14. দুর্যোগকে বিপর্যয়ের মান দেওয়ার যে-কোনাে দুটি মানদণ্ড
                    লেখাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                (a) এক মিলিয়ন মার্কিন ডলারের ক্ষতি। (b) একশাে জনের বেশি মানুষ
                আহত হলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. আধা-প্রাকৃতিক দুর্যোগ কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যেসকল দুর্যোগগুলি প্রাকৃতিকভাবে সৃষ্টি হলেও কখনাে কখনাে
                মনুষ্যসৃষ্ট কারণেও ঘটতে পারে তাদের আধা-প্রাকৃতিক দুর্যোগ বলে।
                উদাহরণ—ধস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. নেপালের সাম্প্রতিক বিপর্যয়ের কারণ কী?*</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভূমিকম্প।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. কাকে ভূমিকম্পের দেশ বলে?* **</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জাপান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. ভারতে কত শতাংশ এলাকা ভূমিকম্পপ্রবণ?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                60%
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    19. দক্ষিণ-পূর্ব এশিয়ার ভূমিকম্পপ্রবণ দুটি এলাকার নাম
                    লেখাে।
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জাপান ও ইন্দোনেশিয়া।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. পূর্ব হিমালয়ে বর্ষাকালে কোন্ ধরনের প্রাকৃতিক বিপর্যয়ের
                    ঘটনা সবচেয়ে বেশি ঘটে?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভূমিধস।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                21. ভারতে অতি সাম্প্রতিক মনুষ্যসৃষ্ট বিপর্যয় কী?*
              </font>
              <br />
              <font color="#29900a">উত্তর : </font>{" "}
              <font color="#3c92e5">
                2016 খ্রিস্টাব্দের 31 মার্চ গিরিশপার্ক কলকাতার উড়ালপুল ভেঙে
                পড়ে।
                <br />
                <br />
                <font color="#f626d7">22. খরা কী? </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                কোনাে অঞ্চলে বার্ষিক বৃষ্টিপাত 75%-এর কম হলে তাকে বলা হয় খরা।
                <br />
                <br />
                <font color="#f626d7">
                  23. কোন্ বিপর্যয়ের পূর্বাভাস দেওয়া এখনও সম্ভব হয়নি?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ভূমিধস।
                <br />
                <br />
                <font color="#f626d7">
                  24. ভারতের একটি ধসপ্রবণ অঞলের নাম কী?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                দার্জিলিং।
                <br />
                <br />
                <font color="#f626d7">
                  25. একটি খরা সহিষ্ণু উদ্ভিদের নাম লেখাে।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ফণিমনসা।
                <br />
                <br />
                <font color="#f626d7">26. খরার একটি প্রধান প্রভাব কী?*</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                জলসংকট।
                <br />
                <br />
                <font color="#f626d7">
                  27. ধীরগতিতে সংঘটিত একটি বিপর্যয়ের নাম লেখাে।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                খুরা।
                <br />
                <br />
                <font color="#f626d7">28. ভূমিধস কী?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                আধা-প্রাকৃতিক কারণে পাহাড়ের ঢাল বরাবর মাধ্যাকর্ষণ শক্তির
                প্রভাবে পাথরের খণ্ড, মৃত্তিকা অকস্মাভাবে নেমে আসার ঘটনা হল
                ভূমিধস।
                <br />
                <br />
                <font color="#f626d7">29. হিমানি সম্প্রপাত কাকে বলে? </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                উচ্চ পার্বত্য অঞ্চল থেকে বিশালাকৃতির বরফের স্থূপ নীচে নেমে আসাকে
                বলা হয় হিমানি সম্প্রপাত।
                <br />
                <br />
                <font color="#f626d7">
                  30. ভারতের একটি ধসপ্রবণ এলাকার উদাহরণ দাও।*{" "}
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                উত্তর-পূর্ব ও উত্তর-পশ্চিম হিমালয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                31. বিভিন্ন প্রকার প্রাকৃতিক বিপর্যয়ের শ্রেণিবিভাগ করাে।
              </font>
              <br />
              <font color="#29900a">উত্তর : </font>{" "}
              <font color="#3c92e5">
                অগ্ন্যুৎপাত, ভূমিকম্প, বন্যা, সুনামি, খরা এবং সাইক্লোন।
                <br />
                <br />
                <font color="#f626d7">32. আবহাওয়াগত খরা কখন ঘটে?*</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                স্বাভাবিকের তুলনায় কম বৃষ্টিপাত ঘটলে আবহাওয়া সংক্রান্ত খরা
                সৃষ্টি হয়। <br />
                <br />
                <font color="#f626d7">33. মেঘভাঙা বৃষ্টি কী?* </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                স্বল্পস্থান জুড়ে অতি অল্প সময়ে বজ্রপাত-সহ একসঙ্গে অনেকটা
                পরিমাণে বৃষ্টিপাত ঘটলে সেই ঘটনা মেঘভাঙা বৃষ্টি (Cloud burst)
                নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  34. সমুদ্র উপকূলে সামুদ্রিক জলােচ্ছাসের ফলে সৃষ্ট বন্যা কী
                  নামে পরিচিত?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                জলােচ্ছাসজনিত বন্যা।
                <br />
                <br />
                <font color="#f626d7">
                  35. ভারতের একটি বন্যাপ্রবণ অঞলের নাম লেখাে।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                পশ্চিমবঙ্গ।
                <br />
                <br />
                <font color="#f626d7">
                  36. কত খ্রিস্টাব্দে কোথায় আয়লা সংঘটিত হয় ?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                2009 খ্রিস্টাব্দে বঙ্গোপসাগরে।
                <br />
                <br />
                <font color="#f626d7">37. টর্নেডাে কী জাতীয় দুর্যোগ?*</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                জলবায়ুগত দুর্যোগ।
                <br />
                <br />
                <font color="#f626d7">
                  38. ভারতে ঘূর্ণিঝড়প্রবণ অঞ্চলের বিস্তার কত শতাংশ?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ৪%।
                <br />
                <br />
                <font color="#f626d7">39. বজ্রপাত কোন্ ধরনের দুর্যোগ?*</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                প্রাকৃতিক দুর্যোগ।
                <br />
                <br />
                <font color="#f626d7">
                  40. একটি বায়ুমণ্ডলীয় বিপর্যয়ের উদাহরণ দাও।*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ঘূর্ণিঝড়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">41. ফাইলিন কী?</font>
              <br />
              <font color="#29900a">উত্তর : </font>{" "}
              <font color="#3c92e5">
                2013 খ্রিস্টাব্দের অক্টোবর মাসে ওড়িশার উপকূলে সৃষ্ট ঘূর্ণিঝড়
                ফাইলিন নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  42. অস্ট্রেলিয়ায় সংঘটিত ঘূর্ণিঝড় কী নামে পরিচিত?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                উইলি-উইলি। <br />
                <br />
                <font color="#f626d7">
                  43. ক্যারিবিয়ান সাগরে সৃষ্ট ঘূর্ণবাত কী নামে পরিচিত?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                হারিকেন।
                <br />
                <br />
                <font color="#f626d7">
                  44. দক্ষিণ চিন সাগরে সৃষ্ট ক্রান্তীয় ঘূর্ণবাত কী নামে
                  পরিচিত?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                টাইফুন।
                <br />
                <br />
                <font color="#f626d7">
                  45. ওড়িশা উপকূলে সুপার সাইক্লোন সংঘটিত হয় কত খ্রিস্টাব্দে?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                1999 খ্রিস্টাব্দে।
                <br />
                <br />
                <font color="#f626d7">46. কখন মৃত্তিকা বিপর্যয় ঘটে?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                মৃত্তিকার উর্বরতা নষ্ট হলে, লবণতা বৃদ্ধি পেলে মৃত্তিকা বিপর্যয়
                ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  47. বিশ্বের বৃহত্তম শিল্প বিপর্যয় কোনটি?* * *
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ভােপাল গ্যাস দুর্ঘটনা (1984 খ্রি.)।
                <br />
                <br />
                <font color="#f626d7">
                  48. অগ্ন্যুৎপাত সৃষ্টির একটি কারণ লেখাে৷{" "}
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ভূগর্ভে তাপ ও চাপের পরিবর্তনের ফলে অগ্ন্যুৎপাত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  49. অলকানন্দা অববাহিকায় কবে মেঘভাঙা বৃষ্টি হয়?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                2013 খ্রিস্টাব্দের জুন মাসে অলকানন্দা অববাহিকায় মেঘভাঙা বৃষ্টি
                হয়। <br />
                <br />
                <font color="#f626d7">
                  50. 1950 খ্রিস্টাব্দে লন্ডন ফগ’-এর কবলে পড়ে বহু মানুষের
                  মৃত্যুর ঘটনা যে প্রকার বিপর্যয়ের ফলে ঘটে, সেটি কী?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                মনুষ্যসৃষ্ট বিপর্যয়, যা কলকারখানার ধোঁয়ার ফলে সৃষ্টি হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                51. Central Water Commission-43 160 AGO বন্যাপ্রবণ জমির পরিমাণ
                কত?
              </font>
              <br />
              <font color="#29900a">উত্তর : </font>{" "}
              <font color="#3c92e5">
                11%
                <br />
                <br />
                <font color="#f626d7">
                  52. ঘরের মধ্যে থাকাকালীন কোন্ বিপর্যয়ের সময় বিদ্যুৎ পরিবাহী
                  তার এড়িয়ে চলা উচিত?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                বর্জ্যবিদ্যুৎ-এর সময়। <br />
                <br />
                <font color="#f626d7">53. MIC-এর পুরাে কথা লেখাে। </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                মিথাইল আইসাে সায়ানাইড।
                <br />
                <br />
                <font color="#f626d7">
                  54. খরা পরিস্থিতি মােকাবিলার জন্য একটি সরকারি কর্মসূচির নাম
                  লেখাে।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                DPAP
                <br />
                <br />
                <font color="#f626d7">
                  55. প্রাক বিপর্যয় ব্যবস্থাপনা বলতে কী বােঝাে?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                বিপর্যয় ঘটার পূর্বে সংশ্লিষ্ট অঞ্চলের সম্ভাবনাময় বিপর্যয়
                সম্পর্কে তথ্যসংগ্রহ ও সেই অনুসারে পরিকল্পনা গ্রহণকে বলে প্রাক্‌
                বিপর্যয় ব্যবস্থাপনা।
                <br />
                <br />
                <font color="#f626d7">
                  56. বিপর্যয় মােকাবিলার একটি গুরুত্বপূর্ণ উপায় কী?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                আধুনিক মানের ও বিজ্ঞানসম্মত সতর্কীকরণ ব্যবস্থা গড়ে তােলার
                মাধ্যমে বিপর্যয় মােকাবিলা সম্ভব।
                <br />
                <br />
                <font color="#f626d7">
                  57. বিপর্যয় রােধে একটি আন্তর্জাতিক সংস্থার নাম লেখাে।
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ইউনাইটেড নেশন ডিজাস্টার রিলিফ অর্গানাইজেশন ও বিশ্বস্বাস্থ্য
                সংস্থা।
                <br />
                <br />
                <font color="#f626d7">
                  58. দ্রুত সতর্কীকরণ উপকরণ বলতে কী বােঝাে?*
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                রেডার, টেলিভিশন, বেতার, খবরের কাগজ, মােবাইল।
                <br />
                <br />
                <font color="#f626d7">
                  59. ওয়ার্ল্ড ব্যাংকের তথ্য অনুযায়ী প্রত্যেক বছর বাহ্যিক
                  ক্ষয়ক্ষতির পরিমাণ কত?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                40 হাজার মিলিয়ন ডলার।
                <br />
                <br />
                <font color="#f626d7">60. ইউট্রোফিকেশন কী ধরনের দুর্যোগ?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                জৈব বিপর্যয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                61. 'National Committee for Disaster Management স্থাপন করা হয়
                কেন?
              </font>
              <br />
              <font color="#29900a">উত্তর : </font>{" "}
              <font color="#3c92e5">
                দুর্যোগ নিবারণের জন্য।
                <br />
                <br />
                <font color="#f626d7">
                  62. বিপর্যয় সংঘটিত হওয়ার পূর্ববর্তী পর্যায়টি কী নামে
                  পরিচিত?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                পূর্বজ্ঞানমূলক পর্যায়।
                <br />
                <br />
                <font color="#f626d7">
                  63. বিপর্যয় মােকাবিলার জন্য বিপর্যয়ের পরবর্তীকালে কী পদক্ষেপ
                  গ্রহণ করা যেতে পারে?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                ত্রাণ সরবরাহ ও পুনর্বাসন।
                <br />
                <br />
                <font color="#f626d7">64. DART-এর অর্থ কী?* </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                DART-43 072 - Deep Ocean Assessment and Reporting of Tsunami
                <br />
                <br />
                <font color="#f626d7">
                  65. 2007 খ্রিস্টাব্দ থেকে কোন্ পদ্ধতির দ্বারা ভারতে সুনামি
                  সতর্কতা জারি করা হয়?
                </font>
                <br />
                <font color="#29900a">উত্তর : </font>
                S: DARTI
                <br />
                <br />
                <font color="#f626d7">66. EWS কী?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                EWS = Early Warning System
                <br />
                <br />
                <font color="#f626d7">67. GBM-এর পুরাে নাম লেখাে।</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                GBM-এর পুরাে নাম হল- Government Based Mitigation
                <br />
                <br />
                <font color="#f626d7">68.UNO প্রদত্ত দুর্যোগের সংজ্ঞা কী?</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                প্রাকৃতিক ও মানবিক কার্যাবলির দ্বারা সৃষ্ট যে ঘটনাবলির মাধ্যমে
                জীবন ও সম্পদহানি, অর্থনৈতিক ও সামাজিক ক্ষয়ক্ষতি ঘটে, তাকেই বলা
                হয় দুর্যোগ।
                <br />
                <br />
                <font color="#f626d7">69. বিপর্যয় কী?*</font>
                <br />
                <font color="#29900a">উত্তর : </font>
                প্রাকৃতিক বা মনুষ্যসৃষ্ট কারণে চরম দুর্যোগের ফলে জীবন ও
                সম্পদহানি চরমমাত্রা অতিক্রম করলে তাকে বিপর্যয় বলে। ফরাসি শব্দ
                ডিসাসট্রি থেকে প্রাপ্ত ডিজাস্টার যার আক্ষরিক অর্থ শব্দতারা (Des
                = খারাপ, astre = তারা)।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

মানুষপরিবেশআন্তঃসম্পর্কপ্রাকৃতিকদুর্যোগওবিপর্যয়.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
  memo
)(মানুষপরিবেশআন্তঃসম্পর্কপ্রাকৃতিকদুর্যোগওবিপর্যয়);
