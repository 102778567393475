import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function অতীতকেস্মরণ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          অতীতকে স্মরণ | West Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="অতীতকে স্মরণ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                অতীতকে স্মরণ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বিশ্বের প্রাচীনতম জাদুঘর গড়ে উঠেছিল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফ্রান্সে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গ্রিসে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ইরাকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইংল্যান্ডে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বিশ্বের সর্ববৃহৎ মিউজিয়াম —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মাদাম তুসোর মিউজিয়াম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইন্ডিয়ান মিউজিয়াম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্রিটিশ মিউজিয়াম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ল্যুভর মিউজিয়াম
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  “পুরাণে বর্ণিত রাজবংশগুলির অস্তিত্বের বেশিরভাগই স্বীকৃত
                  সত্য”—উক্তিটি কার?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অমলেশ ত্রিপাঠী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  রণবীর চক্রবর্তী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ই. এইচ. কার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রামকৃষ্ণ ভান্ডারকর
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>‘প্যারালাল মিথস' (১৮১২ খ্রিস্টাব্দ) গ্রন্থের রচয়িতা - </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জে. এফ. বিয়ারলেইন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  থুকিডিডিস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  টমাস থাম্ব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গ্রিম ভ্রাতৃদ্বয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  “ইতিহাস একটি বিজ্ঞান—এর বেশিও নয়, কমও নয়।”—এটি কার উক্তি?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ই. এইচ. কার-এর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বিউরী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  র‍্যাঙ্কে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জেমস্ মিল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  {" "}
                  ১৮০০ খ্রিস্টাব্দে কলকাতায় ফোর্ট উইলিয়াম কলেজ প্রতিষ্ঠা করেন
                  -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উইলিয়াম জোনস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  লর্ড ওয়েলেসলি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উইলিয়াম কেরি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ওয়ারেন হেস্টিংস
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> ‘সব ইতিহাসই সমকালীন ইতিহাস'—এটি কার উক্তি?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  র‍্যাঙ্কের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ই. এইচ. কার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ক্রোচের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  র‍্যালের
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ১৮০০ খ্রিস্টাব্দে কলকাতায় ফোর্ট উইলিয়াম কলেজ প্রতিষ্ঠা করেন
                  —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উইলিয়াম কেরি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উইলিয়াম জোনস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ওয়ারেন হেস্টিংস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  লর্ড ওয়েলেসলি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>‘সব ইতিহাসই সমকালীন ইতিহাস'—এটি কার উক্তি?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  র‍্যাঙ্কের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ই. এইচ. কার-এর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  র‍্যালের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ক্রোচের
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>যে প্রাচীন সভ্যতায় প্রথম জাদুঘর গড়ে উঠেছিল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সুমেরীয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গ্রিক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সিন্ধু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিশরীয়
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রথম অধ্যায় - অতীতকে স্মরণ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ৮</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                ১. জাদুঘর কাকে বলে? অতীত পুনর্গঠনে জাদুঘরের ভূমিকা আলোচনা করো।
                <br />
                ২. মিউজিয়ামের (জাদুঘরের) প্রকারভেদ আলোচনা করো।
                <br />
                অথবা, ঐতিহাসিক মিউজিয়াম, সামরিক মিউজিয়াম, বিজ্ঞান মিউজিয়াম
                এবং ভ্রাম্যমান মিউজিয়াম-এর কার্যগত ও বৈশিষ্ট্যগত পার্থক্য
                উদাহরণসহ ব্যাখ্যা করো।
                <br />
                ৩. স্মৃতিকথা বলতে কী বোঝায়? স্মৃতিকথার বৈশিষ্ট্য লেখো। কয়েকটি
                স্মৃতিকথার উদাহরণ দাও। ভারতের ইতিহাস রচনায় স্মৃতিকথার গুরুত্ব
                কী? ২+৩+১+২
                <br />
                ৪. কিংবদন্তি বা বীরগাথার সংজ্ঞা ও বৈশিষ্ট্য লেখো। ইতিহাস রচনায়
                কিংবদন্তির গুরুত্ব কী? ৫+৩
                <br />
                ৫. আধুনিক ইতিহাসের লিখন পদ্ধতি সম্পর্কে আলোচনা করো।
                <br />
                ৬. অতীতকে স্মরণ করার ক্ষেত্রে মিথ (myths) এবং স্মৃতিকথার
                (memories) ভূমিকা আলোচনা করো।
                <br />
                ৭. পৌরাণিক কাহিনি এবং কিংবদন্তির মধ্যে পার্থক্য গুলি লেখো।
                উদাহরণ দিয়ে কয়েকটি দেশের কিংবদন্তির পরিচয় দাও।
                <br />
                ৮. মিথ বা উপকথা বা পৌরাণিক কাহিনি বা অতিকথা বলতে কী বোঝায়?
                মিথ-এর বৈশিষ্ট্যগুলি কী কী? ইতিহাসে পৌরাণিক কাহিনির গুরুত্ব
                লেখো।
                <br />
                ৯. জনশ্রুতি কী? পেশাদারি শাখা হিসেবে ইতিহাসের গুরুত্ব লেখো।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. জাদুঘর কাকে বলে? অতীত পুনর্গঠনে জাদুঘরের ভূমিকা আলোচনা করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b> ভূমিকা : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                বাংলা ‘জাদুঘর’ শব্দটির ইংরেজি প্রতিশব্দ হল Museum (মিউজিয়াম)।
                আর এই মিউজিয়াম’ শব্দটি গ্রিক শব্দ ‘Mouseion’ (মউসিয়ন) থেকে
                এসেছে। এর অর্থ হল-“Seat of the Muses” অর্থাৎ শিল্পকলার
                পৃষ্ঠপােষক দের বসার স্থান। ভিন্ন কথায় শিক্ষাদান গৃহ। <br />
                <br />
                সাধারণ অর্থে জাদুঘর’ হল বিভিন্ন ঐতিহাসিক উপাদানের সংগ্রহশালা,
                যেখানে ঐতিহাসিক, সাংস্কৃতিক, বৈজ্ঞানিক ও লােক-সংস্কৃতিমূলক
                নিদর্শন বা নমুনা সংগ্রহ কবে সেগুলিকে যেসব প্রতিষ্ঠান বা ভবনে
                সংবক্ষণ করে রাখা হয় এবং জনসাধারণের উদ্দেশ্যে মাঝে মাঝে
                প্রদর্শনী করে সরাসরি শিক্ষার প্রসার ঘটানাে হয়। সেই সব
                প্রতিষ্ঠানকে জাদুঘর বলে।
                <br />
                <br />
                জাদুঘরের উদ্দেশ্য, কার্যাবলী ও গুরুত্ব : বিশেষ বিশেষ উদ্দেশ্য
                নিয়ে পৃথিবীর বিভিন্ন স্থানে জাদুঘর প্রতিষ্ঠিত হয়েছে। অতীত
                পুনর্গঠনে (ইতিহাস রচনায়) এই জাদুঘরগুলি গুরুত্বপূর্ণ ভূমিকা পালন
                করে। জাদুঘরের প্রধান উদ্দেশ্য ও কার্যাবলী ব্যাখ্যা করলে তা
                স্পষ্ট হয়ে ওঠে -<br />
                <br />
                <font color="#f626d7">
                  <b>1. ঐতিহাসিক নিদর্শন সংগ্রহ : </b>{" "}
                </font>{" "}
                জাদুঘরের প্রাথমিক উদ্দেশ্য ও কাজ হল দেশবিদেশের বিভিন্ন স্থানে
                ছড়িয়ে ছিটিয়ে থাকা দুর্লভ ঐতিহাসিক নিদর্শনগুলি খুঁজে বের করা
                এবং সংগ্রহ করা। হারিয়ে যাওয়া এই সব নিদর্শন ইতিহাস রচনায়
                গুরুত্বপূর্ণ ভূমিকা পালন করে। <br />
                <br />
                <font color="#f626d7">
                  <b>2. ঐতিহাসিক নিদর্শনের সংরক্ষন : </b>{" "}
                </font>{" "}
                জাদুঘর ইতিহাসের গুরুত্বপূর্ণ নিদর্শন, যেমন- প্রাচীন মুদ্রা,
                লিপি, প্রাচীন স্থাপত্য, ভাস্কর্য, চিত্রকলা, প্রাচীনকালের ব্যবহৃত
                বিভিন্ন উপকরণ ও বিজ্ঞান বিষয়ক আশ্চর্যজনক বস্তুগুলি দীর্ঘস্থায়ী
                করার জন্য বৈজ্ঞানিক পদ্ধতিতে সংরক্ষণ করে। সংরক্ষিত এই উপকরণগুলি
                অতীত পুণঠনে গুরুত্বপূর্ণ ভূমিকা নেয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. প্রতিকৃতি নির্মাণ : </b>{" "}
                </font>{" "}
                অতীতের বিভিন্ন নিদর্শনের প্রতিকৃতি বা মডেল তৈরি কবে তা সাধারণ
                মানুষের সামনে তুলে ধরার বিষয়ে জাদুঘরগুলি উদ্যোগ নেয়। জাদুঘরে
                এমন কতগুলি মূল্যবান বস্তু আছে যা নিরাপত্তার কারণে সাধারণ
                দর্শকদের দেখানাে সম্ভব নয় বা এমন কিছু গুরুত্বপূর্ণ বস্তু যা
                জাদুঘবের সংগ্রহে নেই সেগুলি দর্শকদের দেখানাের জন্য জাদুঘর
                প্রতিকৃতি বা মডেল তৈরি কবে। যেমন - তাজমহলের মডেল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. ঐতিহাসিক নিদর্শনের প্রদর্শন : </b>{" "}
                </font>{" "}
                জাদুঘর অতীতের যে সকল মূল্যবান ও আশ্চর্যজনক বস্তু সংগ্রহ ও
                সংরক্ষণ করে তা সাধারন দর্শক বা গবেষক সকল শ্রেণীর মানুষের
                প্রদর্শনের জন্য উন্মুক্ত করে দেয়। <br />
                <br />
                <font color="#f626d7">
                  <b>5. ইতিহাস চেতনা : </b>{" "}
                </font>{" "}
                জাদুঘর ইতিহাস বিষয়ক বিভিন্ন উদ্যোগের দ্বারা জনগণের মধ্যে ইতিহাস
                চেতনা বৃদ্ধি করে। উদাহরণ হিসেবে বলা যায়- স্বাধীন বাংলাদেশের
                বিভিন্ন জাদুঘর স্বাধীনতা উৎসব, বিজয় উৎসব, গণ অভ্যুত্থান দিবস,
                চিত্রপ্রদর্শনী প্রভৃতি অনুষ্ঠানের আয়ােজন করে দেশবাসীর মধ্যে
                মুক্তিযুদ্ধের ইতিহাস চেতনা বিস্তার করতে সাহায্য করে। <br />
                <br />
                <font color="#f626d7">
                  <b>6. গবেষণা : </b>{" "}
                </font>{" "}
                জাদুঘরে সংরক্ষিত বিভিন্ন নিদর্শনগুলি নিয়ে গবেষণার ফলে অতীতের
                বহু অজানা দিকে উন্মেষ ঘটতে পারে। এজন্য জাদুঘর গবেষকদের বৃত্তি
                অর্থাৎ স্কলারশিপ দিয়ে তার গবেষণার কাজে উৎসাহ দান করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. প্রকাশনা : </b>{" "}
                </font>{" "}
                জাদুঘরে সংরক্ষিত নানান ঐতিহাসিক নিদর্শন সম্পর্কে গবেষণালব্ধ নতুন
                তথ্য এবং সমসাময়িককালে আবিষ্কৃত বহুল আলােচিত বিষয় বা সাধারণ
                ঐতিহাসিক পত্রিকা প্রকাশনা করা জাদুঘরের অন্যতম গুরুত্বপূর্ণ কাজ।
                নতুন কোন নিদর্শন জাদুঘরের সংগ্রহে যুক্ত হলে তাও সাধারণ দর্শকদের
                জন্য লিপিবদ্ধ করা উচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. আনন্দদান : </b>{" "}
                </font>{" "}
                জাদুঘরের একটি গুরুত্বপূর্ণ কাজ হল সাধারণ দর্শকদের আনন্দ দান করা।
                গবেষকদের পরিবর্তে সাধারণ মানুষই জাদুঘরে বেশি সংখ্যায় আসে, তাই
                তাদের আনন্দ দেওয়ার জন্য জাদুঘর নানা প্রদর্শনীর ব্যবস্থা করে।
                <br />
                <br />
                <font color="#29900a">
                  <b>উপসংহার : </b>{" "}
                </font>{" "}
                পরিশেষে উল্লেখ্য, মিউজিয়াম বা জাদুঘর হল অতীত স্মৃতিচিহ্ন ও
                বর্তমান যুগের যােগসূত্র। তাই জাদুঘরে সংরক্ষিত তথ্য ব্যবহার ও
                বিশ্লেষণ করে মানুষ অনেক নতুন তথ্য আবিষ্কার করে, যা অতীত
                পুনর্গঠনে গুরুত্বপূর্ণ ভূমিকা নেয়। শুধু তাই নয়, ইতিহাস
                শিক্ষার্থীদের কল্পনা ও মননশক্তি তৈরি করতেও সাহায্য করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  2. মিউজিয়ামের (জাদুঘরের) প্রকারভেদ আলোচনা করো। <br />
                  অথবা,
                  <br /> ঐতিহাসিক মিউজিয়াম, সামরিক মিউজিয়াম, বিজ্ঞান মিউজিয়াম
                  এবং ভ্রাম্যমান মিউজিয়াম-এর কার্যগত ও বৈশিষ্ট্যগত পার্থক্য
                  উদাহরণসহ ব্যাখ্যা করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b> ভূমিকা : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                পৃথিবীর বিভিন্ন প্রান্তে বর্তমানে অসংখ্য জাদুঘর দেখা যায়।
                সাধারণ জাদুঘর যেমন দেখা যায়, তেমনি বিভিন্ন ধরনের জাদুঘরও
                পরিলক্ষিত হয়। যেমন — প্রত্নতাত্ত্বিক জাদুঘর, ঐতিহাসিক জাদুঘর,
                শিল্প জাদুঘর প্রভৃতি। বিভিন্ন প্রকারের জাদুঘর সম্পর্কে নিচে
                আলোচনা করা হল —<br />
                <br />
                <font color="#f626d7">
                  <b>1. প্রত্নতাত্ত্বিক জাদুঘর : </b>{" "}
                </font>{" "}
                প্রত্নতাত্ত্বিক জাদুঘরে কেবলমাত্র প্রত্নতাত্ত্বিক নিদর্শনসমূহ
                সংগ্রহ, সংরক্ষণ এবং প্রদর্শন করা হয়। এই জাদুঘরগুলি অট্টালিকার
                অভ্যন্তরে যেমন প্রদর্শন করা হয়, তেমনি খোলা জায়গাতেও প্রদর্শিত
                হয়। যেমন— এথেন্সের অ্যাগোরা, রোমান ফোরাম প্রভৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. ঐতিহাসিক জাদুঘর : </b>{" "}
                </font>{" "}
                ঐতিহাসিক জাদুঘরে সাধারণত বিভিন্ন ঐতিহাসিক যুগের নানা নিদর্শন
                সংরক্ষিত হয়। এখানে বিশ্বের বিভিন্ন ঐতিহাসিক ব্যক্তিদের
                ব্যবহারিক দ্রব্য সামগ্রী স্মৃতি হিসেবে সংরক্ষণ করা হয়। এছাড়াও
                এখানে সংরক্ষিত থাকে বিখ্যাত ব্যক্তিদের চিঠিপত্র, দিনলিপি, ছবি,
                সমসাময়িক নথিপত্র প্রভৃতি। <br />
                <br />
                <font color="#f626d7">
                  <b>3. কলা বা শিল্প জাদুঘর : </b>{" "}
                </font>{" "}
                কলা বা শিল্প জাদুঘরের মধ্যে বিভিন্ন ধরনের শিল্পকলা সংরক্ষণ ও
                প্রদর্শনের ব্যবস্থা করা হয়। এসব শিল্পকলা নানা ধরনের হতে পারে।
                যেমন— মৃৎশিল্প, আসবাবপত্র, ধাতুর ফলকে খোদিত শিল্প, ভাস্কর্য,
                চিত্র, নকশা প্রভৃতি। উদাহরণ— আশুতোষ মিউজিয়াম অফ ইন্ডিয়ান আর্ট
                হল শিল্প জাদুঘরের উদাহরণ। <br />
                <br />
                <font color="#f626d7">
                  <b>4. সামরিক জাদুঘর : </b>{" "}
                </font>{" "}
                সামরিক জাদুঘরে কোন দেশের সামরিক বাহিনী ও যুদ্ধ সংক্রান্ত নানা
                নিদর্শন সংরক্ষণ ও প্রদর্শন করা হয়। সাধারণত কোনো দেশের
                জাতীয়তাবাদী দৃষ্টিকোণ থেকে এ ধরনের জাদুঘর প্রতিষ্ঠা করা হয়। এই
                জাদুঘরে সামরিক অস্ত্রশস্ত্র, যন্ত্রপাতি, সামরিক পোশাক,
                যুদ্ধকালীন পরিস্থিতিতে নাগরিক জীবন ইত্যাদি বিষয়ে তুলে ধরা হয়।
                যেমন— কানাডিয়ান ওয়ার মিউজিয়াম। <br />
                <br />
                <font color="#f626d7">
                  <b>5. বিজ্ঞান জাদুঘর : </b>{" "}
                </font>{" "}
                বিজ্ঞান জাদুঘর বিভিন্ন বৈজ্ঞানিক যন্ত্রপাতি, প্রযুক্তিগত
                বিবর্তন, বিজ্ঞানের অগ্রগতি, বিস্ময় প্রভৃতি বিষয়ের নিদর্শন
                সংরক্ষণ ও প্রদর্শন করে থাকে। কিছু কিছু বিজ্ঞান জাদুঘরে বিশেষ
                কতগুলো বিষয়— রেলওয়ে, পদার্থবিদ্যা, মহাকাশ বিদ্যা, কম্পিউটার
                প্রভৃতি বিভিন্ন ক্ষেত্রের ক্রমবিবর্তনকে তুলে ধরা হয়। এই ধরনের
                জাদুঘরে থ্রিডি ফিল্ম ও ছবির মাধ্যমে প্রদর্শনের ব্যবস্থা করা হয়।{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. শিশু জাদুঘর : </b>{" "}
                </font>{" "}
                শিশুদের শিক্ষা ও অভিজ্ঞতা প্রদানের উদ্দেশ্যে তৈরি হয় শিশু
                জাদুঘর। সাধারণত 12 বছর বয়স পর্যন্ত শিশুদের জন্য এই ধরনের জাদুঘর
                গড়ে তোলা হয়। অধিকাংশ শিশু জাদুঘরগুলি অলাভজনক প্রতিষ্ঠান।
                মাউন্ট রায়ান্ড চিলড্রেন্স মিউজিয়াম হল শিশু জাদুঘরের উদাহরণ।{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. চলমান জাদুঘর : </b>{" "}
                </font>{" "}
                যখন কোনো চলমান যানের মাধ্যমে বিভিন্ন স্থান ঘুরে ঘুরে ঐতিহাসিক বা
                দুর্লভ নিদর্শন সমূহ দর্শকদের সামনে প্রদর্শন করা হয় তখন তাকে
                চলমান জাদুঘর বলে। ২০১১ সালে বিশ্বকবি রবীন্দ্রনাথ ঠাকুরের জন্ম
                সার্ধশতবর্ষ উপলক্ষে তাঁর জীবন কাহিনীকে কেন্দ্র করে ভারতীয় রেল
                একটি ট্রেনে জাদুঘর তৈরি করে দেশের বিভিন্ন স্থান ঘুরে ঘুরে
                রবীন্দ্রনাথের জীবনের বিভিন্ন দিক দর্শকদের সামনে প্রদর্শন করে।{" "}
                <br />
                <br />
                <font color="#29900a">
                  <b>উপসংহার : </b>{" "}
                </font>{" "}
                জাদুঘর সংস্কৃতি সময়ের সঙ্গে সঙ্গে এতটাই বিস্তার লাভ করেছে যে
                পূর্বের তুলনায় জাদুঘরের বিভিন্নতা বহু গুণে বৃদ্ধি পেয়েছে। শুধু
                প্রত্নতাত্ত্বিক বা ইতিহাস ভিত্তিকই নয়, অন্য নানা প্রকার
                জাদুঘরের ক্রমাগত প্রতিষ্ঠা জাদুঘর সংস্কৃতির প্রতি মানুষের
                ক্রমবর্ধমান আগ্রহেরই পরিচয় বহন করে।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  3. স্মৃতিকথা বলতে কী বোঝায়? স্মৃতিকথার বৈশিষ্ট্য লেখো।
                  কয়েকটি স্মৃতিকথার উদাহরণ দাও। ভারতের ইতিহাস রচনায় স্মৃতিকথার
                  গুরুত্ব কী?
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b> স্মৃতিকথা : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                স্মৃতি বলতে মনে রাখা বিষয়কে বোঝায়, যার মধ্য দিয়ে নিকট অতীতকে
                স্মরণ করা যায়। কোনো ব্যক্তি তার জীবনের অনেকটা সময় অতিক্রম করার
                পরে তার জীবনে ফেলে আসা কোনো ঘটনার স্মৃতিচারণ ও প্রকাশকেই বলা হয়
                স্মৃতিকথা। এই স্মৃতিকথা হল এক ধরনের ব্যক্তিগত অভিজ্ঞতার বিবরণ,
                যেখানে লেখক তাঁর নিজের দৃষ্টিকোণ থেকে অতীত কোনো ঘটনার বিবরণ দেন।
                <br />
                <br />
                অন্যভাবে বলা যায়, স্মৃতিকথা হল এক ধরনের সাহিত্য, যেখানে লেখক
                তাঁর জীবনে ঘটে যাওয়া বা প্রত্যক্ষ করা বিভিন্ন ঘটনার বিবরণ
                স্মৃতি থেকে এনে তুলে ধরেন।
                <br />
                <br />
                <font color="#29900a">
                  <b>স্মৃতিকথার বৈশিষ্ট্য : </b>{" "}
                </font>{" "}
                স্মৃতিকথার যে বিভিন্ন উল্লেখযোগ্য বৈশিষ্ট্য গুলি লক্ষ করা যায়,
                তা হল –<br />
                <br />
                <font color="#f626d7">
                  <b>1. ঘটনার প্রত্যক্ষ বর্ণন : </b>{" "}
                </font>{" "}
                স্মৃতিকথা হল প্রকৃতপক্ষে অতীত ঘটনার প্রত্যক্ষ বর্ণন। অনেক বছর
                পরেও তা লেখক অবিকল একই ভাবে বর্ণনা করে থাকেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. নিজস্ব অনুভূতির প্রকাশ : </b>{" "}
                </font>{" "}
                স্মৃতিকথা হল লেখকের নিজস্ব অনুভূতির প্রকাশ। বক্তা বা লেখক তাঁর
                স্মৃতিকথায় যে-কাহিনি বা ঘটনার বিবরণ দেন, তা ঘটনার সমসাময়িক
                কালেতাঁর মনে কীরূপ প্রভাব ফেলেছিল, তিনি কীরূপ অভিজ্ঞতার সম্মুখীন
                হয়েছিলেন বা সেই ঘটনায় সমসাময়িক পরিস্থিতি কীভাবে পাল্টে যেতে
                দেখেছেন তা তাঁর স্মৃতিকথার আলোচনায় উঠে আসে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. ঘটনার বাস্তবতা : </b>{" "}
                </font>{" "}
                স্মৃতিকথা কোনো কাল্পনিক উপন্যাস নয়, এটি বাস্তব চিত্র। লেখক
                সাধারণত প্রকৃত ঘটনাই তাঁর স্মৃতিকথায় তুলে ধরেন বলে মনে করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. বিশেষ ঘটনার উপস্থাপন : </b>{" "}
                </font>{" "}
                স্মৃতিকথায় লেখকের সমগ্র জীবনের আলোচনা করা হয় না, বরং লেখকের
                জীবনকালে সংগঠিত সুনির্দিষ্ট কিছু ঘটনার ওপর অধিক গুরুত্ব দিয়ে
                স্মৃতিকথা লেখা হয়।
                <br />
                <br />
                <font color="#29900a">
                  <b>স্মৃতিকথার উদাহরণ : </b>{" "}
                </font>{" "}
                স্মৃতিকথার বিভিন্ন উল্লেখযোগ্য উদাহরণগুলি হল –<br />
                <br />
                1. বুদ্ধদেব বসু তাঁর ‘আমার জীবন' নামক স্মৃতিকথায় পূর্ববঙ্গ বা
                বাংলাদেশের সেই গ্রাম জীবনের ঘটনাবলির ব্যাখ্যা করেছেন।
                <br />
                <br />
                2. সাহিত্যিক সমর সেন তাঁর 'বাবুবৃত্তান্ত' গ্রন্থে তাঁর ফেলে আসা
                জীবনের অনেক কথাই বর্ণনা করেছেন।
                <br />
                <br />
                3. বিপ্লবী বীণা দাশ তাঁর ‘শৃঙ্খল ঝংকার' গ্রন্থে ভারতের স্বাধীনতা
                সংগ্রামের ঘটনাবলি ও তাঁর জীবনের কথা বর্ণনা করেছেন।
                <br />
                <br />
                4. নারায়ণ সান্যাল তাঁর 'আমি নেতাজিকে দেখেছি' এই স্মৃতিচারণায়
                প্রত্যক্ষদর্শী হিসেবে সুভাষচন্দ্রের রাজনৈতিক জীবনের ঘটনা বর্ণনা
                করেছেন।
                <br />
                <br />
                এ ছাড়াও উল্লেখযোগ্য স্মৃতিকথামূলক গ্রন্থগুলি হল, মণিকুন্তলা
                সেন-এর 'সেদিনের কথা', আশালতা সরকার-এর ‘আমি সূর্য সেনের শিষ্যা',
                সুফিয়া কামাল-এর 'একাত্তরের ডায়েরী', মান্নাদের ‘জীবনের
                জলসাঘরে', শ্রীহিরণ্ময় বন্দ্যোপাধ্যায়-এর
                ‘উদ্বাস্তু”।রবীন্দ্রনাথ ঠাকুরের ‘জীবনস্মৃতি', নেলসন ম্যান্ডেলার
                'দ্য স্ট্রাগল ইন মাই লাইফ' প্রভৃতি।
                <br />
                <br />
                <font color="#29900a">
                  <b>স্মৃতিকথার গুরুত্ব : </b>{" "}
                </font>{" "}
                স্মৃতিকথামূলক বিবরণগুলির ইতিহাস রচনায় যথেষ্ট গুরুত্ব রয়েছে,
                যেমন –<br />
                <br />
                <font color="#f626d7">
                  <b>1. গুণি ব্যক্তিদের বিবরণ : </b>{" "}
                </font>{" "}
                অধিকাংশ ক্ষেত্রেই স্মৃতিকথাগুলি গুণী ব্যক্তিরা রচনা করেন যা থেকে
                অতীতের বাস্তব ঘটনার তথ্য ও বিবরণ পাওয়া যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. প্রত্যক্ষদর্শীর বিবরণ : </b>{" "}
                </font>{" "}
                বিভিন্ন ব্যক্তি বিভিন্ন ঐতিহাসিক ঘটনার প্রত্যক্ষদর্শী হিসেবে
                ঘটনার বিবরণ তাঁদের স্মৃতিকথাগুলিতে আলোচনা করেন। ফলে উক্ত বিবরণে
                ঐতিহাসিক তথ্যের সত্যতা অনেক বেশি থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. ইতিহাস রচনার ক্ষেত্রে গুরুত্ব : </b>{" "}
                </font>{" "}
                বিভিন্ন স্মৃতিকথা বিভিন্ন ঐতিহাসিক ঘটনার মূল্যবান তথ্য হিসেবে
                কাজ করতে পারে।
                <br />
                <br />
                সুতরাং বলা যায়, যেখানে লিখিত ইতিহাস পৌঁছোতে পারে না সেখানেই
                স্মৃতিকথার প্রয়োজন হয়। স্মৃতিকথা গুরুত্বপূর্ণ, তবে তা সতর্কতার
                সঙ্গে গ্রহণ করতে হবে; কারণ স্মৃতিকথার তথ্য সব সময় নিরপেক্ষ হয়
                না।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  4. কিংবদন্তি বা বীরগাথার সংজ্ঞা ও বৈশিষ্ট্য লেখো। ইতিহাস
                  রচনায় কিংবদন্তির গুরুত্ব কী?
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b> লিজেন্ড (কিংবদন্তি) : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সাধারণত বিশেষ কোনো অঞ্চলে সংঘটিত কোনো ঘটনা বা চরিত্রকেন্দ্রিক
                কাহিনি যা সেই অঞ্চলের মানুষ প্রজন্ম পরম্পরায় মনে রাখে এবং
                বিশ্বাস করে ও পরবর্তী প্রজন্মের কাছে প্রচার করে , তাকে কিংবদন্তি
                বলে। মৌখিক ইতিহাসে এক প্রধান উপাদান হল কিংবদন্তির কাহিনি বা
                বীরগাথা (Legends)। সত্য, মিথ্যা, সম্ভাবনা — এই তিনের মিলিত
                সমষ্টি কিংবদন্তি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. উদ্ভবগত : </b>{" "}
                </font>{" "}
                অনেকের মতে মানুষের সামাজিক বিবর্তনের পথ ধরেই কিংবদন্তির উদ্ভব
                হয়েছে। পৌরাণিক বা ঐতিহাসিক কল্পকাহিনির চরিত্রগুলি থেকেই
                কিংবদন্তির জন্ম বলে মনে করা হয়। যে সময়ে মানুষ আদিম জীবন থেকে
                অনেকদূর এগিয়ে এসেছে সেই সময় থেকেই কিংবদন্তির সূচনা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. নায়কের প্রকৃতি : </b>{" "}
                </font>{" "}
                কিংবদন্তির নায়ক সর্বদাই একজন পুরুষ; যিনি ধার্মিক, বলবান, দয়ালু
                ও সাহসী ব্যক্তি। তিনি সর্বদাই জাতীয়, সাংস্কৃতিক অথবা ধর্মীয়
                তাৎপর্য তুলে ধরতে অন্য ভূমিকা পালন করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. কিংবদন্তির চরিত্র : </b>{" "}
                </font>{" "}
                কিংবদন্তির চরিত্রগুলি সাধারণ মানুষের চেয়ে বেশি ক্ষমতাবান,
                দয়ালু ও সাহসী সে-কথা নিঃসন্দেহে বলা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. সময়কাল : </b>{" "}
                </font>{" "}
                মিথের চেয়ে কিংবদন্তির ঘটনা বর্তমানের নিকটবর্তী হয় এবং তা
                কমপক্ষে অন্তত ২০ বছরের পুরোনো ঘটনা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. জীবিত চরিত্র : </b>{" "}
                </font>{" "}
                যাকে নিয়ে ঘটনা তিনি একদা জীবিত ছিলেন, তথাপি তার ঘটনাবলি যে সত্য
                হবেই সেটি নিশ্চিত নয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. বিস্ময় ও কল্পনা : </b>{" "}
                </font>{" "}
                কিংবদন্তির চরিত্র বা কাহিনিগুলি আমাদেরকে বিস্মিত করে এবং
                আমাদেরকে এক কল্পনার জগৎ – এ নিয়ে যায়। কিংবদন্তি হল বস্তুত
                সাধারণ মানুষের ভাবনা ও আগ্রহের বিকশিত রূপ যাতে কালে কালে নতুন
                কল্পনার রং মিশে তাকে কাল্পনিক করে তোলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. গুজবের সংস্রব : </b>{" "}
                </font>{" "}
                কিংবদন্তির সঙ্গে আজগুবি বিষয় বা গুজবের সংস্রব লক্ষ করা যায়।
                আর্নেস্ট বার্নহেইম-এর মতে – কিংবদন্তি খুব সরলভাবে বলতে গেলে
                গুজবের পুনর্গঠন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. স্থায়িত্ব : </b>{" "}
                </font>{" "}
                কিংবদন্তি সাধারণত দীর্ঘকাল স্থায়ী হয়। লোকমুখে প্রচারিত হতে হতে
                বংশানুক্রমে তা চলতে থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. জোট উপাখ্যান নির্ভর : </b>{" "}
                </font>{" "}
                কিংবদন্তি বিশেষত একটি জোট উপাখ্যান নির্ভর। ঐতিহ্যের ওপর আধারিত
                গল্পগুলির প্রতীকী উপস্থাপনা, লোকবিশ্বাস ও লৌকিক সামগ্রিক
                অভিজ্ঞতার ওপর নির্ভরশীল।
                <br />
                <br />
                <font color="#29900a">
                  <b>কিংবদন্তির গুরুত্ব : </b>{" "}
                </font>{" "}
                কিংবদন্তির ঐতিহাসিক সত্যতা নিয়ে সন্দেহ থাকলেও ইতিহাস রচনার
                ক্ষেত্রে কিংবদন্তির গুরত্ব খুব কম নয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. ঐতিহাসিক তথ্যের উৎস : </b>{" "}
                </font>{" "}
                কিছু কিংবদন্তি থেকে আমরা ঐতিহাসিক তথ্যের উৎস পাই। লোকমুখে
                পুরুষানুক্রমে কাহিনি প্রচারিত হলেও সেগুলির মধ্যে ইতিহাসের
                তথ্যসূত্র লুকিয়ে থাকে। কিংবদন্তির প্রেক্ষাপটেই পূর্ববঙ্গের
                সীতারকোট – এর উঁচু ঢিবিতে পরীক্ষামূলকভাবে খননকার্য চালিয়ে
                প্রাচীন বৌদ্ধবিহারের অস্তিত্ব খুঁজে পাওয়া গেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. আনন্দদান : </b>{" "}
                </font>{" "}
                কিংবদন্তির চরিত্র বা ঘটনাগুলির অলৌকিক কাহিনি পাঠ করে পাঠককুল
                আনন্দ পায়। অনেক সময় কাল্পনিক হলেও পাঠকের কাছে তা বিশ্বাসযোগ্য
                হয়ে ওঠে বলে কিংবদন্তি পাঠ আনন্দ দান করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. প্রবহমান ঐতিহ্য : </b>{" "}
                </font>{" "}
                নৃতাত্ত্বিক বিচারে একটি দেশ বা জাতি অঞ্চলে একটি বিশেষ পরিমণ্ডলের
                মধ্যে আবদ্ধ না থেকে কিংবদন্তি দেশদেশান্তরে ছড়িয়ে পড়ে। এক
                বিশেষ অঞ্চলের ঐতিহ্যের মধ্যে কিংবদন্তি গড়ে উঠলেও তাই
                পরবর্তীকালে প্রবহমান ঐতিহ্য রূপান্তরিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. শিক্ষাদানে : </b>{" "}
                </font>{" "}
                কিংবদন্তির চরিত্রগুলির কর্মকাণ্ড আমাদেরকে নৈতিকতার শিক্ষা দান
                করে বলা চলে। সত্যের প্রতি নিষ্ঠা, অনমনীয় দৃঢ়তা, সাহসিকতা,
                ত্যাগ, তিতিক্ষা, উদারতা, মানবিকতা এসবের প্রতি গুরুত্বদান
                নৈতিকতার শিক্ষাকেই সমৃদ্ধ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. সাহসদান : </b>{" "}
                </font>{" "}
                মানুষের মনে যখন ভয়ের সঞ্চার হয়, কিংবদন্তির ঘটনা তখন তার মনে
                সাহসের সৃষ্টি করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. ঘটনার বাস্তবতা : </b>{" "}
                </font>{" "}
                পৌরাণিক কাহিনির মতো কিংবদন্তিতে কল্পনার অতিরঞ্জন অনেক কম। ঘটনার
                একটি বাস্তব ভিত্তি থাকে। বাংলার কিংবদন্তি চরিত্র রঘু ডাকাতের
                কালীপূজার ভিত্তিতে আজও একটি কালী মন্দিরকে চিহ্নিত করা হয়।
                <br />
                <br />
                সুতরাং, অতীত কথা জানার জন্য কিংবদন্তি একটি গুরুত্বপূর্ণ উপাদান।
                ইতিহাস হল অতীতের সত্য ঘটনা, কিন্তু অতীতের এই প্রকৃত সত্য ঘটনা
                জানা অত্যন্ত কঠিন কাজ। কারণ উপযুক্ত তথ্যের অভাব। আর এই তথ্যের
                অভাব পূরণের জন্য অনেক সময় ঐতিহাসিককে কিংবদন্তির ওপর নির্ভর করতে
                হয়। এই দিক থেকে বিচার করলে ইতিহাস রচনায় কিংবদন্তির গুরুত্ব
                অপরিসীম।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. আধুনিক ইতিহাসের লিখন পদ্ধতি সম্পর্কে আলোচনা করো।</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b> উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                জ্ঞানের অন্যান্য শাখার মতো ইতিহাসেরও নিজস্ব লিখন পদ্ধতি রয়েছে।
                সাধারণভাবে বলা যায় সাক্ষ্য বা প্রামাণ্য তথ্যের বিচার-বিশ্লেষণ ও
                ব্যাখ্যার দ্বারাই ঐতিহাসিকগণ ইতিহাস রচনা করে থাকেন। আমাদের
                চারদিকের নানা ঐতিহাসিক উৎস ও তথ্যের সাহায্যে বিজ্ঞানসম্মতভাবে
                বেশ কিছু নিয়ম নীতি মেনে ঐতিহাসিকগণ ইতিহাস রচনা করে থাকেন। এই
                প্রসঙ্গে ঐতিহাসিক বিউরির বক্তব্য প্রণিধানযোগ্য। তাঁর
                পরিভাষায়—“ইতিহাস হল একটি বিজ্ঞান, এর কম কিছু নয়, বেশিও নয়।”
                <br />
                <br />
                <font color="#29900a">
                  <b> আধুনিক ইতিহাস লিখন পদ্ধতি : </b>{" "}
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. উৎসের অনুসন্ধান : </b>{" "}
                </font>{" "}
                ইতিহাস তথ্যনিষ্ঠ, তথ্যের উপর ভিত্তি করেই ঐতিহাসিকগণ ইতিহাস রচনা
                করে থাকেন। সুতরাং, ইতিহাস লিখন পদ্ধতির ক্ষেত্রে ঐতিহাসিকের
                প্রাথমিক কর্তব্য হল, ঐতিহাসিক ঘটনার উৎসের অনুসন্ধান করা। উৎস
                আবার বহুমুখী হয়ে থাকে। যথা —<br />
                <br />
                (i) প্রত্নতাত্ত্বিক (জীবাশ্ম, হাড়গোড়, যন্ত্রপাতি,
                অস্ত্রশস্ত্র, স্মৃতিসৌধ-স্থাপত্য প্রভৃতি)
                <br />
                (ii) মৌখিক (ধর্মীয় বিশ্বাস, পৌরাণিক কাহিনি, আত্মকথন, রূপকথা,
                কিংবদন্তী প্রভৃতি)
                <br />
                (iii) ছবি ভিত্তিক (চিত্রকলা, নকশা, মানচিত্র প্রভৃতি)
                <br />
                (iv) লিখিত বিবরণমূলক (প্রাচীন পাণ্ডুলিপি, চুক্তি বা সন্ধিপত্র,
                সরকারি দলিল-দস্তাবেজ, সংবাদপত্র প্রভৃতি।
                <br />
                এই সমস্ত উৎসগুলি থেকে ঐতিহাসিক তাঁর প্রয়োজনীয় উৎসের অনুসন্ধান
                করেন এবং সেগুলির ভিত্তিতে ইতিহাস রচনা করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. উৎস থেকে তথ্য চয়ন : </b>{" "}
                </font>{" "}
                উৎসের মধ্যেকার তথ্যাবলির গুরুত্ব অনুসারে ঐতিহাসিকগণ উৎসগুলিকে
                অতিপ্রয়োজনীয়, মোটামুটি প্রয়োজনীয় এবং অপ্রয়োজনীয় এই তিনটি
                ভাগে বিভক্ত করেন, এবং তারপর উৎসগুলির অনুসন্ধান করে সেই বিষয়ে
                স্বচ্ছ জ্ঞান লাভ করেন। আসলে ইতিহাস হল ঐতিহাসিক ও তথ্যের মধ্যে
                পারস্পরিক অবিচ্ছিন্ন ক্রিয়া-প্রতিক্রিয়া এবং বর্তমান ও অতীতের
                মধ্যে এক অন্তহীন সংলাপ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. তথ্যের বাছাইকরণ : </b>{" "}
                </font>{" "}
                ঐতিহাসিকগণ তথ্যগুলি গ্রহণ করার পর সমালোচনামূলক দৃষ্টিভঙ্গিতে
                সেগুলির যাচাই করে থাকেন। এক্ষেত্রে ঐতিহাসিককে মুখ্যত দুটি কাজ
                করতে হয়—প্রথমটি হল তথ্যের বাহ্যিক সমালোচনা, আর দ্বিতীয়টি হল
                তথ্যের অভ্যন্তরীণ সমালোচনা। বাহ্যিক সমালোচনার দ্বারা তথ্যের
                জালিয়াতি বা মৌলিকত্ব যাচাই করা হয়। তথ্য যাচাই এর দ্বিতীয় ধাপ
                হিসেবে অভ্যন্তরীণ সমালোচনার দ্বারা ঐতিহাসিক তার গৃহীত তথ্যের
                অন্তর্নিহিত ভাবাদর্শ ও প্রেরণা সম্বন্ধে নিশ্চিত হন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. তথ্যসমূহের বিশ্লেষণ : </b>{" "}
                </font>{" "}
                ইতিহাসবিদ প্রয়োজনীয় তথ্য যাচাই করার পর সেগুলিকে বিভিন্ন
                দৃষ্টিকোণ থেকে বিশ্লেষণ করে থাকেন। এক্ষেত্রে, ঐতিহাসিককে হতে হবে
                নির্মোহ ও নিরপেক্ষ। নিজস্ব মত, মতাদর্শ, সম্প্রদায়-প্রীতি
                প্রভৃতির ঊর্ধ্বে উঠে তথ্যের সঠিক বিশ্লেষণ দ্বারাই বস্তুনিষ্ঠ
                ইতিহাস রচনা সম্ভব।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. কার্যকরণ পদ্ধতি ও প্রশ্নোত্তরের ব্যবহার : </b>{" "}
                </font>{" "}
                আধুনিক তথ্যনিষ্ঠ তথা বিজ্ঞান সম্মত ইতিহাস রচনার প্রয়োজনে
                ঐতিহাসিককে অবশ্যই উক্ত দুই পদ্ধতির সাহায্য নিতে হবে। প্রতিটি
                কার্য সংগঠিত হওয়ার পিছনেই কোনো না কোনো কারণ থাকে। এক্ষেত্রে,
                ঐতিহাসিকের কাজ হবে শুধুমাত্র দৃশ্যমান বা সহজবোধ্য কারণ নয়,
                ঘটনার গভীরে লুকিয়ে থাকা প্রকৃত কারণটিকে খুঁজে বের করা।
                এক্ষেত্রে একজন ঐতিহাসিকে একজন বিজ্ঞানীর মতো যথার্থ বৈজ্ঞানিক
                পদ্ধতি অনুসরণ করতে হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. ধারাবাহিকতা ও কালানুক্রম : </b>{" "}
                </font>{" "}
                ইতিহাস হল মানব সভ্যতার ধারাবাহিক ক্রমবিবর্তনের বিজ্ঞানসম্মত
                বিশ্লেষণ। যথার্থ ইতিহাসকে ধারাবাহিক হতেই হবে। কালানুক্রম যথার্থ
                ইতিহাস রচনার আরেকটি গুরুত্বপূর্ণ দিক। যেকোনো ঘটনার বর্ণনার
                ক্ষেত্রে ঐতিহাসিক নিশ্চয় সেই ঘটনার সময়কাল উল্লেখ করবেন।
                বর্তমান বিশ্বে আন্তর্জাতিকভাবে সর্বত্র খ্রিস্টান কালানুক্রম
                ব্যবহৃত হয়। তাই ঐতিহাসিকও খ্রিস্টান কালানুক্রম অনুসারে
                ধারাবাহিকভাবে ঘটনা সাজিয়ে তার ইতিহাস রচনা করবেন। সময়ের উল্লেখ
                না থাকলে রচিত ইতিহাস মূল্যহীন হয়ে পড়বে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. তথ্য সংরক্ষণ ও ইতিহাস রচনা : </b>{" "}
                </font>{" "}
                দীর্ঘ গবেষণা থেকে যে তথ্যগুলি পাওয়া যায়, ঐতিহাসিক সেগুলি
                যথাযথভাবে সংরক্ষণ করবেন। এক্ষেত্রে তিনি তথ্যগুলিকে সংগ্রহ করে
                প্রথমেই তার ব্যক্তিগত সংগ্রহে বা নোটবুকে লিখে রাখেন। পরবর্তীতে
                ইতিহাস লেখার সময় প্রয়োজন অনুসারে তিনি তথ্যগুলিকে যথাযথভাবে
                ব্যবহার করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. ভৌগোলিক অবস্থানের উল্লেখ : </b>{" "}
                </font>{" "}
                ইতিহাসের সব ঘটনা কোনো ক্ষুদ্র বা বৃহৎ ভৌগোলিক স্থানে সংঘটিত হয়ে
                থাকে। তাই যথার্থ ইতিহাস রচনার প্রয়োজনে উৎস বা ঘটনার ভৌগোলিক
                অবস্থানের উল্লেখ করার বিষয়টিও একজন ঐতিহাসিকের পক্ষে ভীষণ জরুরি।
                স্থান-কাল-পাত্র বিহীন ইতিহাস যথার্থ ইতিহাস নয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. ঘটনা ও বক্তব্যের মধ্যে সম্পর্ক স্থাপন : </b>{" "}
                </font>{" "}
                দুটি ঘটনা বা বক্তব্যের মধ্যে সম্পর্ক স্থাপনেরমধ্য দিয়ে
                ঐতিহাসিকতার লেখাকে এগিয়ে নিয়েযান।এই সম্পর্ক স্থাপনের প্রচেষ্টা
                আসলে মূলত চারটি উপাদানের সম্মিলিত রূপ। যথা — করেসপন্ডেনস্
                থিয়োরি, কোহেরেন্স থিয়োরি, অথরিটি ও মেমরি। করেসপন্ডেনস্ থিয়োরি
                দিয়ে সত্যের সঙ্গে তথ্যের সম্পর্ক স্থাপন করা হয়। কোহেরেন্স
                থিয়োরি দ্বারা বিভিন্ন ঐতিহাসিক ঘটনাবলির মধ্যে সম্পর্ক স্থাপন
                করে ঐতিহাসিকগণ সত্যে পৌঁছানোর চেষ্টা করে থাকেন। অথরিটির ধারণা
                আসলে পূর্ববর্তী লেখাকে সত্য বলে গ্রহণ করা যেমন—থুকিডিসিস বা
                সিজার যা লিখেছেন সেগুলি বিনা প্রশ্নে গ্রহণ করা। আর সমকালের ঘটনা
                প্রত্যক্ষকারীরা তাদের অভিজ্ঞতা যেভাবে লিপিবদ্ধ করে থাকেন, তা হল
                স্মৃতিবদ্ধ ইতিহাস বা মেমরি। এই চার ধরনের ঐতিহাসিক সত্যের আলোকে
                ঐতিহাসিক তার লেখাকে এগিয়ে নিয়ে যান।
                <br />
                <br />
                <font color="#29900a">
                  <b> মূল্যায়ন : </b>{" "}
                </font>{" "}
                এভাবে সুনির্দিষ্ট বৈজ্ঞানিক পদ্ধতি অনুসরণের মাধ্যমে রচিত হয়
                আধুনিক তথা বস্তুনিষ্ঠ ইতিহাস, তবে শুধুমাত্র সুসংবদ্ধ লিখন পদ্ধতি
                অনুসরণ করলেই চলবে না, ঐতিহাসিককে হতে হবে নির্মোহ ও নিরপেক্ষ এবং
                ইতিহাসের ভাষা হতে হবে সহজ-সুন্দর-সাবলীল তথা সুখপাঠ্য। তবেই
                ইতিহাস মানব সমাজের অগ্রগতির যথার্থ হাতিয়ারে পরিণত হবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  6. অতীতকে স্মরণ করার ক্ষেত্রে মিথ (myths) এবং স্মৃতিকথার
                  (memories) ভূমিকা আলোচনা করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b> উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সভ্যতার ঊষালগ্নে প্রাকৃতিক শক্তির হাতে অসহায় মানুষ তার মনের
                উর্বর কল্পনায় সৃষ্টি করেছে অসংখ্য কল্পকাহিনি। সন-তারিখ ও তথ্যের
                যথার্থতা-বিহীন এইসব প্রায়-ঐতিহাসিক কাহিনিকে ‘জনশ্ৰুতি’ আখ্যা
                দেওয়া হয়েছে। জনশ্রুতির দুটি প্রকৃষ্ট উদাহরণ হল — মিথ এবং
                স্মৃতিকথা। <br />
                <br />
                <font color="#29900a">
                  <b> মিথ : </b>{" "}
                </font>{" "}
                গ্রিক শব্দ ‘Muthos’ থেকে ইংরেজি ‘Myth’ শব্দটির উদ্ভব, যার বাংলা
                প্রতিশব্দ ‘পৌরাণিক কাহিনি’ বা ‘উপকথা’। সাধারণভাবে, প্রাগৈতিহাসিক
                যুগের বিভিন্ন কাহিনি বা ঘটনার বিবরণ যে প্রায় ঐতিহাসিক উপাদানে
                তুলে ধরা হয়, তাকে পৌরাণিক কাহিনি বলে। এগুলি সাহিত্যের সর্বপ্রথম
                রূপ। মানব সভ্যতার উদ্ভবের পূর্বে ঐশ্বরিক জগতে সংঘটিত নানা
                কাল্পনিক ঘটনা থেকেই পৌরাণিক কাহিনির উৎপত্তি। এগুলি মধুর স্মৃতির
                পথ বেয়ে বংশ পরম্পরায় পরবর্তী যুগে পৌঁছোয়। <br />
                <br />
                বিশ্বের প্রায় সকল দেশেই পৌরাণিক কাহিনির অস্তিত্ব রয়েছে। হিন্দু
                ধর্মে ভগবান ব্রষ্মার মানস কন্যা রূপে দেবী দুর্গার জন্ম ও অসুর
                বধের কাহিনি বা চাঁদ সদাগর ও বেহুলা লক্ষ্মীন্ধরের কাহিনি জনপ্রিয়
                ভারতীয় পৌরাণিক কাহিনির উদাহরণ। একইভাবে, বাইবেলে বর্ণিত নোয়ার
                নৌকার কাহিনি, রোমের রোমুলাসের জীবন কাহিনি বিশ্বব্যাপ্ত পৌরাণিক
                কাহিনির আদর্শ উদাহরণ। ঐতিহাসিক জে. এফ. বিয়ারলেইন তাঁর Parallel
                Myths গ্রন্থে লিখেছেন, এগুলি হল আমাদের অবচেতন মনের কাহিনি বিশেষ,
                যা সম্ভবত আমাদের জিনের মধ্যেই লিপিবদ্ধ থাকে।'
                <br />
                <br />
                <font color="#29900a">
                  <b> অতীতের পুনর্গঠনে মিথের ভূমিকা : </b>{" "}
                </font>{" "}
                মিথ বা পৌরাণিক কাহিনি অতীত বিষয়ে মানুষের ধারণাকে নানাভাবে
                সমৃদ্ধ করে, যেমন — <br />
                <br />
                <font color="#f626d7">
                  <b>1. ঐতিহাসিক সত্যতা : </b>{" "}
                </font>{" "}
                ঐতিহাসিক জে. এফ. বিয়ারলেইন মনে করেন যে, মিথ বা পৌরাণিক
                কাহিনিগুলি হল ‘গল্পের ছলে সত্য ঘটনার প্রকাশ’। পুরাণের বহু চরিত্র
                ও তাদের কীর্তি-কলাপ কল্পিত হলেও এগুলি থেকে ফেলে আসা অতীতের
                আর্থ-সামাজিক ও রাজনৈতিক জীবনের কিছু পরোক্ষ উপাদান পাওয়া যেতে
                পারে। অতীতের বিভিন্ন রাজবংশের বংশতালিকা বা রাজবংশগুলির ক্রমিক
                তালিকা, রাজাদের কার্যকলাপ, প্রাচীন নদ-নদী পাহাড়-পর্বত, প্রাচীন
                শহর-নগর-তীর্থস্থান প্রভৃতি সম্পর্কে গুরুত্বপূর্ণ উপাদান পৌরাণিক
                কাহিনিগুলি থেকে আহরণ করা সম্ভব। উদাহরণ হিসেবে বলা যায়, প্রাচীন
                গ্রিসের পৌরাণিক কাহিনির সূত্র ধরেই বর্তমানকালে ট্রয় নগরী ও
                ট্রয়ের যুদ্ধ ক্ষেত্রের অবস্থান নির্ণয় করা সম্ভব হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. সময়কাল নির্ণয় : </b>{" "}
                </font>{" "}
                বিভিন্ন মিথ বা পৌরাণিক কাহিনিগুলি তুলনামুলক পদ্ধতিতে যাচাই করে
                ইতিহাসের সাল-তারিখ নির্ণয় করা সম্ভব হয়েছে। ফলে অতীতের
                ধারাবাহিক ছবি মিথের মাধ্যমে মানুষের কাছে স্পষ্ট হতে পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. অতীতের সঞ্চালন : </b>{" "}
                </font>{" "}
                পৌরাণিক কাহিনিগুলি ইতিহাসের ধারাবাহিকতা রক্ষা করে। বিভিন্ন
                পৌরাণিক কাহিনিতে উল্লেখিত বহু প্রাচীন রীতি-নীতি, পদ্ধতি প্রকরণ
                আজও বহু মানবগোষ্ঠী তাদের দৈনন্দিন জীবনধারায় লালনপালন করে চলেছে।
                যেমন — বর্তমান কালের হিন্দু সম্প্রদায় প্রাচীন বৈদিক সংস্কৃতিকেই
                নিজেদের অতীত সংস্কৃতি বলে মনে করে।
                <br />
                <br />
                <font color="#29900a">
                  <b>দ্বিতীয় অংশ </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#29900a">
                  <b> স্মৃতিকথা : </b>{" "}
                </font>{" "}
                যে আখ্যানধর্মী সাহিত্যে লেখক তাঁর অতীত জীবনে ঘটে যাওয়া কোনো
                ঘটনার স্মৃতিচারণা করে থাকেন, তাই-ই হল স্মৃতিকথা। স্মৃতিকথার উপর
                নির্ভর করেই মানুষ তাঁর ফেলে আসা অতীতের দিকে ফিরে তাকায়।
                সাধারণভাবে বলা যায়, ইতিহাসের যেখানে শেষ, সেখান থেকেই শুরু হয়
                স্মৃতিকথার পথ চলা। দক্ষিণারঞ্জন বসুর ‘ছেড়ে আসা গ্রাম’,
                মনিকুন্তলা সেনের ‘সেদিনের কথা’, সুফিয়া কামালের ‘একাত্তরের
                ডায়েরি’, মান্না দে-র ‘জীবনের জলসাঘরে’ প্রভৃতি উল্লেখযোগ্য
                স্মৃতিকথার উদাহরণ।
                <br />
                <br />
                <font color="#29900a">
                  <b> অতীতের পুনর্গঠনে স্মৃতিকথার ভূমিকা : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. প্রত্যক্ষদর্শীর বিবরণ : </b>{" "}
                </font>{" "}
                স্মৃতিকথা অথবা আত্মজীবনী একজন ব্যক্তি ও তাঁরসময়ের কথা তুলে ধরে।
                এই থেকে নানান তথ্য, সমকালীন ঘটনা ও দৃষ্টিভঙ্গির হদিশ পাওয়া
                যায়। তাই স্মৃতিকথা অথবা আত্মজীবনীকে নিরপেক্ষ দৃষ্টিতে বিচার করে
                ইতিহাসের উপাদান হিসেবে ব্যবহার করা যেতে পারে। উদাহরণস্বরূপ বলা
                যায়, দেশভাগের অপরিসীম দুঃখ-দুর্দশা, যন্ত্রণা, ছন্নছাড়া জীবন,
                বিভিন্ন ক্যাম্পে আশ্রয়গ্রহণ ও পুনর্বাসন প্রভৃতি সমকালীন অনেক
                বিদগ্ধ মানুষের আত্মজীবনী ও স্মৃতিকথায় স্থান পেয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. ঐতিহাসিক তথ্যসূত্র প্রদান : </b>{" "}
                </font>{" "}
                স্মৃতিকথা বিভিন্ন ঐতিহাসিক ঘটনার মূল্যবান তথ্যসূ ত্র হিসেবে কাজ
                করে। যেমন — ১৯৭১ খ্রিস্টাব্দে বাংলাদেশের মুক্তিযুদ্ধের সময়
                বর্বর পাকবাহিনী পূর্ববঙ্গের সাধারণ মানুষের উপর যে অত্যাচার ও
                হত্যালীলা চালায়, তার অত্যন্ত গুরুত্বপূর্ণ ঐতিহাসিক উপাদান হল
                সমকালীন স্মৃতিকথাগুলি। কেন-না, পাক সামরিক সরকার তখন পূর্ববঙ্গের
                সমস্ত সংবাদ বিশ্ব থেকে আড়াল করে রেখেছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. গুণীজনদের বিবরণ : </b>{" "}
                </font>{" "}
                অধিকাংশ ক্ষেত্রে স্মৃতিকথাগুলি কোনো না কোনো গুণী ব্যক্তির রচনা।
                ফলে, এগুলিতে অবান্তরবা অতিরঞ্জিত ঘটনাবলির অনুপ্রবেশের সম্ভাবনা
                অপেক্ষাকৃত কম। তাই বিস্মৃত অতীতকে ইতিহাসের আলোকে পুনরুদ্ভাসিত
                করতে স্মৃতিকথার সঞ্জীবনী ভূমিকা অস্বীকার করা যায় না।
                <br />
                <br />
                পরিশেষে বলা যায়, মানব সমাজের ফেলে আসা অতীতের পুনর্গঠনে মিথ বা
                স্মৃতিকথাগুলির স্বকীয় বৈশিষ্ট্য থাকলেও এগুলি থেকে ইতিহাস রচনা
                বহুক্ষেত্রেই ঝুঁকি সাপেক্ষ। এগুলিতে সত্য-মিথ্যা-সম্ভাবনা,
                ব্যক্তিগত অভিরুচি, অতিরঞ্জন প্রভৃতি মিলে-মিশে থাকায় ইতিহাস
                বিকৃতির সম্ভাবনা প্রবল। তাই এগুলি থেকে ইতিহাস লিখনের পূর্বে
                অন্যান্য যুক্তির কষ্টিপাথরে যাচাই করে নেওয়াই বাঞ্ছনীয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  7. পৌরাণিক কাহিনি এবং কিংবদন্তির মধ্যে পার্থক্য গুলি লেখো।
                  উদাহরণ দিয়ে কয়েকটি দেশের কিংবদন্তির পরিচয় দাও।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b> উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                <br />
                <table>
                  <colgroup>
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>বিষয়</b>
                          </font>
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>কিংবদন্তি</b>
                          </font>
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>পৌরাণিক কাহিনি</b>
                          </font>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">মূল চরিত্র</font>
                        </span>
                      </td>
                      <td>
                        <span>
                          মানুষ এর মূল চরিত্র। এতে কাহিনির মূল চরিত্র মানবতার
                          ওপর আরোপিত। মূল চরিত্রটি মানুষের বীরত্বমূলক কর্মকাণ্ড
                          বা বীরগাথার পরিচায়ক।
                        </span>
                      </td>
                      <td>
                        <span>
                          অলৌকিক দেবদেবী এর মূল চরিত্র। এতে কাহিনির মূল চরিত্র
                          ঈশ্বর বা ঐশ্বরিক শক্তির ওপর আরোপিত। মূল চরিত্রটি
                          ঈশ্বরের অলৌকিক লীলাকাহিনি বা কর্মকাণ্ডের রূপকার।
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">ভিত্তি</font>
                        </span>
                      </td>
                      <td>
                        <span>
                          কাহিনিগুলির ঐতিহাসিক, সামাজিক বা সাংস্কৃতিক ভিত্তি
                          থাকে। বিশেষ অঞ্চলের সংস্কার, লোকাচার, লোকবিশ্বাসের ওপর
                          নির্ভর করে কিংবদন্তি গড়ে ওঠে।
                        </span>
                      </td>
                      <td>
                        <span>
                          কাহিনিগুলি মূলত ধর্মভিত্তিক। ধর্মীয় বিশ্বাস, ধর্মীয়
                          সংস্কার, রীতিনীতি, পূজা – প্রার্থনা, ধর্মোৎসব – উপাচার
                          ইত্যাদির ভিত্তিতে পৌরাণিক কাহিনিগুলি গড়ে ওঠে।
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">কালপঞ্জি</font>
                        </span>
                      </td>
                      <td>
                        <span>
                          এতে বর্তমান সময়ের নিরিখে যথাযথভাবে ঘটনাভিত্তিক|
                          সময়কালের উল্লেখ বা কালপঞ্জি থাকে।
                        </span>
                      </td>
                      <td>
                        <span>
                          এতে বর্তমান সময়ের নিরিখে ঘটনাভিত্তিক ধারাবাহিক|
                          সময়কালের উল্লেখ বা কালপঞ্জি থাকে না।
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7">ঐতিহিসিকতা</font>
                        </span>
                      </td>
                      <td>
                        <span>
                          এটির রচনাকালে ক্ষীণভাবে হলেও ইতিহাসের সূত্র থাকে।
                          অনেকসময় এগুলির মধ্যে ইতিহাসের আদি ঘটনার প্রামাণ্য
                          তথ্যসূত্র মেলে।
                        </span>
                      </td>
                      <td>
                        <span>
                          এর মধ্যে ইতিহাসের প্রামাণ্য তথ্যসূত্র মেলে না। এটি
                          মূলত ধর্মীয় কল্পকথা ও এতে অতিলৌকিক কল্পনার আধিক্য
                          থাকে।
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <font color="#29900a">
                  <b>পৃথিবীর কয়েকটি দেশের কিংবদন্তি : </b>
                </font>
                পৃথিবীর বিভিন্ন দেশে অসংখ্য কিংবদন্তি চরিত্র ও ঘটনাবলি ছড়িয়ে
                আছে। রামচন্দ্র, শ্রীকৃষ্ণ, হারকিউলিস, প্রমিথিউস প্রমুখ কিংবদন্তি
                চরিত্রের সঙ্গে বহু কল্পকাহিনি মিশে থাকলেও বাস্তবে এইসব চরিত্ররা
                যে জীবিত ছিলেন এবং তাদের জীবনে সংঘটিত কিছু কিছু ঘটনার যে বাস্তব
                অস্তিত্ব ছিল তা ঐতিহাসিকগণ স্বীকার করেছেন। এই সমস্ত কিংবদন্তির
                মধ্যে কয়েকটি উল্লেখযোগ্য হল –<br />
                <br />
                <font color="#f626d7">
                  <b>(১) রামচন্দ্র : </b>
                </font>{" "}
                প্রাচীন মহাকাব্যের মধ্যে উল্লেখযোগ্য হল ভারতের রামায়ণ। আর রাম
                হলেন রামায়ণে উল্লিখিত একটি গুরুত্বপূর্ণ কিংবদন্তি চরিত্র। তাঁর
                সম্পর্কে প্রচলিত কিংবদন্তি ঘটনাগুলির মধ্যে অন্যতম হল, রাজসিংহাসন
                ছেড়ে পত্নী সীতা ও ভ্রাতা লক্ষ্মণকে নিয়ে চোদ্দো বছরের জন্য
                বনবাসে যাত্রা, লঙ্কায় রাবণের বিরুদ্ধে যুদ্ধ করে পত্নী সীতাকে
                উদ্ধার প্রভৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(২) শ্রীকৃষ্ণ প্রাচীন ভারতের মহাকাব্য : </b>
                </font>{" "}
                মহাভারতে উল্লিখিত শ্রীকৃষ্ণ চরিত্রটিও অন্যতম কিংবদন্তি
                চরিত্র।তার সম্পর্কে কিংবদন্তি উল্লেখযোগ্য ঘটনাগুলি হল, কংসকে
                হত্যা, কুরুক্ষেত্রের যুদ্ধে অর্জুনের রথের সারথি হিসেবে কৃষ্ণের
                ভূমিকা, ধর্মরাজ্য প্রতিষ্ঠায় যুধিষ্ঠিরকে সাহায্য প্রভৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(৩) গোপাল ভাঁড়ের কাহিনি : </b>
                </font>{" "}
                বাংলার কৃষ্ণচন্দ্র রাজার রাজসভার হাস্যরসিক গোপাল ভাঁড় সম্পর্কে
                বিভিন্ন কাহিনি প্রচলিত রয়েছে। বিভিন্ন কাহিনিতে গোপাল ভাঁড়ের
                অসামান্য বুদ্ধিমত্তা, রাজা ও রাজপরিবারের সদস্যদের বারংবার
                বুদ্ধিতে পরাজিত করা, হাস্যরস সৃষ্টি প্রভৃতি ফুটে উঠেছে। নদিয়া
                জেলার কৃষ্ণনগরের ঘূর্ণির বাসিন্দা হিসেবে পরিচিত গোপাল ভাঁড়ের
                বিভিন্ন কাহিনিতে কিছু কিছু যথার্থ ঐতিহাসিক তথ্য পাওয়া যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(৪) প্রমিথিউস : </b>
                </font>{" "}
                প্রমিথিউস হলেন প্রাচীন গ্রিসের অপর উল্লেখযোগ্য কিংবদন্তি চরিত্র।
                তার সম্পর্কে প্রচলিত উল্লেখযোগ্য কিংবদন্তি গুলি হল – প্রমিথিউস
                অন্যান্য জীবদের তুলনায় মানুষকে শ্রেষ্ঠতর করে তোলার জন্য মানুষকে
                সোজা হয়ে হাঁটতে শেখালেন, তিনি স্বর্গেগিয়ে সূর্যের কাছ থেকে
                আগুন এনে মানুষকে উপহার দিলেন এবং এর জন্য দেবতা জিউসের নিকট থেকে
                নানান কঠিন শাস্তি ভোগ করলেন প্রভৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(৫) হারকিউলিস : </b>
                </font>{" "}
                প্রাচীন গ্রিসের অন্যতম কিংবদন্তি চরিত্র হলেন হারকিউলিস। তিনি
                ছিলেন উত্তর আফ্রিকার একজন শক্তিশালী যোদ্ধা ও সুদক্ষ সেনাপতি।
                তাঁর সম্পর্কে যে-সমস্ত কিংবদন্তি প্রচলিত আছে তার মধ্যে
                উল্লেখযোগ্য হল, জিউসের ঘুমন্ত শিশুপুত্র হারকিউলিসকে হত্যা করার
                জন্য হেরা দুটি সাপ পাঠান। কিন্তু শিশু হারকিউলিস জেগে উঠে দুটি
                সাপকেই মেরে ফেলেন। যৌবনে হারকিউলিসের বহু অবিশ্বাস্য বীরত্বের
                কাহিনি প্রচলিত রয়েছে।
                <br />
                <br />
                তবে পৃথিবীর সবচেয়ে জনপ্রিয় কিংবদন্তি নায়ক হলেন রবিনহুড।
                ব্রিটেনের শেরউড জঙ্গলের এই ডাকাত তাঁর মহানুভবতার জন্য দেশকালের
                গণ্ডি অতিক্রম করে আজও অত্যন্ত জনপ্রিয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  8. মিথ বা উপকথা বা পৌরাণিক কাহিনি বা অতিকথা বলতে কী বোঝায়?
                  মিথ-এর বৈশিষ্ট্যগুলি কী কী? ইতিহাসে পৌরাণিক কাহিনির গুরুত্ব
                  লেখো।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>মিথ : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সৃষ্টির আদিমকালে অপরিণত বুদ্ধির মানুষ যে সমস্ত ধর্মীয় অলৌকিক
                কল্পকাহিনি রচনা ও প্রচার করে তাকে পৌরাণিক কাহিনি বা লােকপুরাণ
                (Myth) বলে। লােকপুরাণগুলি লােকসমাজের দ্বারা মৌখিকভাবে সুপ্রাচীন
                অতীত থেকে রচিত হয়ে আসছে। Myth’ শব্দটি এসেছে গ্রিক শব্দ ‘Muthos’
                থেকে। ব্রিটেনের ফোকলাের সােসাইটির প্রতিষ্ঠাতা প্রত্নতত্ত্ববিদ
                জর্জ লরেন্স গােম। তিনি বলেছেন, লােকপুরাণ হল ‘দ্য সায়েন্স অব এ
                প্রিসায়েনটিফিক এজ’। জে. এফ বিয়ারলেইন তাঁর ‘Parallel Myths’
                গ্রন্থে লিখেছেন – পৌরাণিক কাহিনি হল “আমাদের অবচেতন মনের
                কাহিনিবিশেষ যা সম্ভবত আমাদের জিন-এ লিপিবদ্ধ থাকে”।
                <br />
                <br />
                বলা যায় যে –“A myth is a traditioal story that explains the
                belief about the natural and human world. The main characters in
                myth are usually gods and supernatural heroes.”
                <br />
                <br />
                <font color="#29900a">
                  <b>বৈশিষ্ট্য : </b>{" "}
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. চরিত্রগত : </b>{" "}
                </font>{" "}
                লােকপুরাণে কেন্দ্রীয় চরিত্র হল অলৌকিক দেবদেবীগণ। এতে কাহিনির
                মূল চরিত্র ঈশ্বর বা ঐশ্বরিক শক্তির ওপর আরােপিত। মূল চরিত্রটি
                ঈশ্বরের অলৌকিক লীলাকাহিনি বা কর্মকাণ্ডের রূপকার।।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. কাহিনিগত : </b>{" "}
                </font>{" "}
                পৌরাণিক কাহিনিগুলি মূলত ধর্মভিত্তিক। ধর্মীয় বিশ্বাস, ধর্মীয়
                সংস্কার, রীতিনীতি, পূজা-প্রার্থনা, ধর্মোৎসব-উপাচার ইত্যাদির
                ভিত্তিতে পৌরাণিক কাহিনি গড়ে ওঠে। বিশ্বসৃষ্টির রহস্য,
                দেবতা-মানবের জন্ম, মহা- প্লাবন, দেবতা-দানব-মানবের দ্বন্দ্ব,
                জন্ম-মৃত্যু-আত্মা- পুনর্জন্ম-অবতার, স্বর্গ-নরক, পাপপুণ্য সবই হল
                এর বিষয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. প্রকৃতিগত : </b>{" "}
                </font>{" "}
                পৌরাণিক কাহিনিগুলি ধর্মীয় প্রকৃতির হয়। ধর্মীয় বিশ্বাস,
                ধর্মীয় সংস্কার, রীতিনীতি, পূজা-প্রার্থনা, ধর্মোৎসব-উপাচার
                ইত্যাদির ভিত্তিতে পৌরাণিক কাহিনি গড়ে ওঠে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. শ্রেণিলিঙ্গ গল্প : </b>{" "}
                </font>{" "}
                শ্রেণিবিভাগগত বিচারে পৌরাণিক কাহিনি বেশ কয়েকটি ভাগে বিভক্ত যথা
                — a. দৃষ্টিতত্ত্বমূলক, b. মানুষের উদ্ভবমূলক, c. দৃষ্টিমূলক, d.
                প্রলয়সূচক, e. আগুনের অধিকার বিষয়ক, f. সংস্কৃতির বিকাশ বিষয়ক,
                g. চন্দ্র-সূর্য গ্রহনক্ষত্র কেন্দ্রিক, h. সামাজিক বিধি বিধান
                বিষয়ক, i. ধর্মাচার সম্পর্কিত, j. সংস্কৃতি ধাতা (Culture Hero)
                বিষয়ক প্রভৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. স্ক্রিলীৰত্নাক্ত : </b>{" "}
                </font>{" "}
                বিশ্বের বিভিন্ন দেশের পৌরাণিক কাহিনিগুলির মধ্যে এক ধরনের
                বিশ্বজনীনতা রয়েছে। বিশ্বসৃষ্টিগত ধারণা, মহাপ্লাবনগত বর্ণনা,
                দেবদেবীর সৃষ্টিগত ধারণা প্রভৃতির ক্ষেত্রে বিশ্বের বিভিন্ন দেশের
                পৌরাণিক কাহিনিগুলির মধ্যে বর্ণনার মিল লক্ষ করা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. মৌখিক ঐতিজ্যান্সারে : </b>{" "}
                </font>{" "}
                পৌরাণিক কাহিনিগুলি সম্পূর্ণভাবে মৌখিক ঐতিহ্যানুসারী। বর্ণমালা
                সৃষ্টির অনেক আগে থেকেই মৌখিকভাবে পৌরাণিক কাহিনিগুলি প্রচলিত হয়ে
                আসছে। বিশ্বের জনপ্রিয় পৌরাণিক কাহিনিগুলি মানব সভ্যতার উদ্ভবের
                পূর্বের ও পরের ঐশ্বরিক জগৎ-এ সংঘটিত নানা কাল্পনিক ঘটনা নিয়ে
                গড়ে উঠেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. উৎসগত : </b>{" "}
                </font>{" "}
                বিশ্বের অধিকাংশ পৌরাণিক কাহিনিগুলির উৎসের সন্ধান মেলে
                মহাকাব্যগুলিতে। এমন বিশ্বের পাঁচটি প্রাচীনতম মহাকাব্য হল
                গিলগামেশ, রামায়ণ, মহাভারত, ইলিয়াড ও ওডিসি। যদিও মহাকাব্যগুলির
                বাইরেও পৌরাণিক কাহিনির সন্ধান মেলে।
                <br />
                <br />
                <font color="#29900a">
                  <b>মিথ-এর গুরুত্ব : </b>{" "}
                </font>{" "}
                প্রাচীন পৌরাণিক কাহিনিগুলির বিষয়বস্তু কতটা ঐতিহাসিকভাবে ঠিক বা
                ভুল তা যাচাই করা খুবই কঠিন। কাজ। তা সত্ত্বেও বর্তমান কালের মানব
                সংস্কৃতি ও ধর্মের ঐতিহাসে এসব পৌরাণিক কাহিনি বা মিথগুলির গুরুত্ব
                অনেকেই স্বীকার করে নিয়েছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. ঐতিহাসিক উপাদানের সূত্র : </b>{" "}
                </font>{" "}
                পৌরাণিক কাহিনিগুলিতে অতীত ইতিহাসের বহু সত্য ও যথার্থতা ও নানান
                উপাদান লুকিয়ে থাকে। ঐতিহাসিক জে এফ বিয়ারলেইন মনে করেন যে, মিথ
                বা পৌরাণিক কাহিনিগুলি হল গল্পের আকারে সত্য ঘটনার প্রকাশ। প্রাচীন
                গ্রিসে পৌরাণিক কাহিনির সূত্র ধরেই আধুনিক কালের ট্রয় নগরী ও
                যুদ্ধ ক্ষেত্রের অবস্থান নির্ণয় করা সম্ভব হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. সময়কাল নির্ণয় : </b>{" "}
                </font>{" "}
                পৌরাণিক কাহিনিগুলি তুলনামূলক পদ্ধতিতে যাচাই করে ইতিহাসের বহু
                সাল-তারিখ নির্ণয় করা সম্ভব হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. বংশতালিকা নির্ণয় : </b>{" "}
                </font>{" "}
                পৌরাণিক কাহিনিগুলি থেকে প্রাচীনকালের বিভিন্ন রাজবংশের বংশতালিকা
                জানা যায়। ড. রণবীর চক্রবর্তী মনে করেন যে, পুরাণে বর্ণিত বিভিন্ন
                রাজবংশ গুলির অস্তিত্ব বেশিরভাগই স্বীকৃত সত্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. ধারাবাহিকতা : </b>{" "}
                </font>{" "}
                পৌরাণিক কাহিনিগুলি ইতিহাসের ধারাবাহিকতা রক্ষা করে। প্রাচীন
                মানবসংস্কৃতি সম্পর্কিত বিভিন্ন পৌরাণিক গল্প বহু যুগ অতিক্রম করে
                বর্তমান কালেও প্রচলিত রয়েছে।
                <br />
                <br />
                সুতরাং, পৌরাণিক কাহিনি শুধুমাত্র গল্প কথা নয়, তার মধ্যে
                নির্ভরযোগ্য তথ্য অবশ্যই আছে। তবে তা অতি সতর্কতার সঙ্গে ব্যবহার
                করা উচিত।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

অতীতকেস্মরণ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(অতীতকেস্মরণ);
