const tintColor = "#2f95dc";

export default {
  main: "#0d89c5",
  secondary: "#d667cd",
  // secondary: '#ffffff',
  text: "#ffffff",
  tintColor,
  tabIconDefault: "#ccc",
  tabIconSelected: tintColor,
  tabBar: "#fefefe",
  errorBackground: "#ff7373",
  errorText: "#fff",
  warningBackground: "#EAEB5E",
  warningText: "#666804",
  noticeBackground: tintColor,
  noticeText: "#fff",
  white: "#ffffff",
  transparent: "transparent",
  black: "black",
  lightRed: "#ff726f",
  disabled: "#dddddd",
  correct: "#299832",
  red: "#FF0000",
  lightRed: "#FFCCCB",
  green: "#008000",
  acb: "#181d38",
};
