import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function প্রাকল্পিকওবৈকল্পিকন্যায়() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          প্রাকল্পিকও বৈকল্পিক ন্যায় | West Bengal Class 12 Guide | West Bengal
          HS Suggestion
        </title>
        <meta
          name="description"
          content="প্রাকল্পিকও বৈকল্পিক ন্যায় | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                প্রাকল্পিকও বৈকল্পিক ন্যায়
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বিসংবাদী বৈকল্পিক বচনের দুটি বিকল্প একই সঙ্গে —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনিশ্চিত হয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সত্য হতে পারে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিথ্যা হতে পারে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সত্য হতে পারে না
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>প্রাকল্পিক বচন কোন্‌ শ্রেণির বচন?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাপেক্ষ বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  যৌগিক বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বৈকল্পিক বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নিরপেক্ষ বচন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বৈকল্পিক নিরপেক্ষ ন্যায়ের অপ্রধান আশ্রয়বাক্যটি হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাপেক্ষ বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রাকল্পিক বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বৈকল্পিক বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  নিরপেক্ষ বচন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>প্রাকল্পিক বচনে ‘যদি’-এর পরবর্তী অংশকে বলা হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পূর্বগ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিকল্প
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যোজক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনুগ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অবরোহ যুক্তির একটি প্রকার হল নিরপেক্ষ যুক্তি, অন্যটি কী?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সাপেক্ষ যুক্তি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রাকল্পিক যুক্তি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বৈকল্পিক যুক্তি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নিরপেক্ষ যুক্তি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মিশ্র যুক্তি কী?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যার সব বচনগুলি নিরপেক্ষ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যার সব বচনগুলি সাপেক্ষ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  যার বচনগুলি সাপেক্ষ ও নিরপেক্ষ উভয়ই
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যার বচনগুলি সব ক-টি প্রশ্নবাচক
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বৈকল্পিক নিরপেক্ষ ন্যায় যে ধরনের যুক্তি তা হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মিশ্র
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অমিশ্র
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মৌলিক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জটিল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>M.P.-এই বৈধ মূর্তিটি হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নিরপেক্ষ ন্যায়ের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  গঠনমূলক প্রাকল্পিক ন্যায়ের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ধ্বংসমূলক প্রাকল্পিক ন্যায়ের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বৈকল্পিক ন্যায়ের
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>প্রাকল্পিক বচনের গুণ নির্ধারিত হয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অনুগের গুণের দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সংযোজনের দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পূর্বগের গুণের দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সবগুলির দ্বারা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অবিসংবাদী বৈকল্পিক বচনে দুটি বিকল্প একসঙ্গে —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সত্য হতে পারে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সত্য হতে পারে না
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনিশ্চিত হয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিথ্যা হতে পারে
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>ষষ্ঠ অধ্যায় - প্রাকল্পিক ও বৈকল্পিক ন্যায়</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. প্রাকল্পিক নিরপেক্ষ ন্যায়ের কোন বচনটি প্রাকল্পিক বচন হয়?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                প্রধান আশ্রয়বাক্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    2. প্রাকল্পিক নিরপেক্ষ ন্যায়ের অপ্রধান আশ্রয়বাক্য কোন বচন
                    হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                নিরপেক্ষ বচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. বৈকল্পিক নিরপেক্ষ ন্যায়ের সিদ্ধান্ত কোন বচন হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                নিরপেক্ষ বচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. মিশ্র ন্যায় কয় প্রকার ও কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                মিশ্র ন্যায় সাধারণত দু-প্রকার। যথা—প্রাকল্পিক ন্যায় ও বৈকল্পিক
                ন্যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    5. বৈকল্পিক ন্যায় বা বৈকল্পিক নিরপেক্ষ ন্যায় কাকে বলে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে মিশ্র ন্যায়ের প্রধান আশ্রয়বাক্যটি একটি বৈকল্পিক বচন এবং
                অপ্রধান আশ্রয়বাক্য ও সিদ্ধান্ত নিরপেক্ষ বচন হয়, তাকে বৈকল্পিক
                ন্যায় বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  6. বৈকল্পিক বচনে ‘অথবা’ শব্দটি ক-টি অর্থে ব্যবহৃত হয় এবং কী
                  কী?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                বৈকল্পিক বচনে ‘অথবা’ দুটি অর্থে ব্যবহৃত হয়—অবিসংবাদী অর্থে এবং
                বিসংবাদী অর্থে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. বিসংবাদী বৈকল্পিক বচনের একটি উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                হয় সে আসামে আছে অথবা কেরালাতে আছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. অবিসংবাদী বৈকল্পিক বচনের একটি উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                হয় লোকটি সাহিত্যিক অথবা নাট্যকার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. M.P বা Modus Ponens কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                M.P. বা Modus Ponens হল গঠনমূলক প্রাকল্পিক নিরপেক্ষ ন্যায়ের বৈধ
                মূর্তি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. M.T. বা Modus Tollens কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                M.T. বা Modus Tollens হল অস্বীকৃতিমূলক বা ধ্বংসমূলক প্রাকল্পিক
                নিরপেক্ষ ন্যায়ের বৈধ মূর্তি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11.p অথবা q, ~ p/ :: q—এই চুক্তি আকারটি বৈধ না অবৈধ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                বৈধ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. প্রাকল্পিক নিরপেক্ষ ন্যায়ের সিদ্ধান্ত কোন বচন হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                নিরপেক্ষ বচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    13. বৈকল্পিক নিরপেক্ষ ন্যায়ের প্রধান আশ্রয়বাক্য কোন বচন
                    হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                বৈকল্পিক বচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    14. বৈধতা/অবৈধতা নির্ণয় করো : লোকটি জীবিত অথবা মৃত। লোকটি
                    জীবিত। সুতরাং, লোকটি মৃত নয়।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                বৈধ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    15. বৈধতা/অবৈধতা বিচার করো : সে শিক্ষক অথবা কবি। সে শিক্ষক।
                    সুতরাং, এমন নয় যে সে কবি।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যুক্তিটি অবৈধ। যুক্তিটি বিকল্প পরিগ্রহণ বা বিকল্প স্বীকারজনিত
                দোষে দুষ্ট।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>16. হয় p অথবা q, p নয়/ .:. q-এই বৈধ মূর্তিটির নাম কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                D.S.
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. ‘যদি-তবে’ যুক্ত বচনকে কী বলা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                প্রাকল্পিক বচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    18. যদি p নয় তবেq নয়, pনয়/ q নয়—যুক্তি আকারটি বৈধ না
                    অবৈধ?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                বৈধ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    19. প্রাকল্পিক বচনের পূর্বর্গ সত্য, অনুগ মিথ্যা হলে সমগ্র
                    বচনটি কী হবে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                মিথ্যা হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. বৈধতা/অবৈধতা নির্ণয় করো : যদি লক বস্তুবাদী হন তবে তিনি
                    দার্শনিক। লক দার্শনিক। সুতরাং, তিনি বস্তুবাদী।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যুক্তিটি অবৈধ। এক্ষেত্রে অনুগ স্বীকারজনিত দোষ ঘটেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>21. বৈকল্পিক নিরপেক্ষ ন্যায়ের বৈধতার নিয়মটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                প্রধান হেতুবাক্যের যে-কোনো একটি বিকল্পকে অপ্রধান হেতুবাক্যে
                অস্বীকার করে সিদ্ধান্তে অপর বিকল্পকে স্বীকার করতে হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. গঠনমূলক প্রাকল্পিক ন্যায়ের একটি দৃষ্টান্ত দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যদি বৃষ্টি হয় তবে বন্যা হবে। বৃষ্টি হয়েছে। .. বন্যা হবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>22. একটি বৈধ বৈকল্পিক ন্যায়ের উদাহরণ দাও।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                হয় উত্তমকুমার নায়ক অথবা উত্তমকুমার গায়ক।
                <br />
                উত্তমকুমার নয় গায়ক।
                <br />
                উত্তমকুমার হয় নায়ক।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>23. বিকল্প স্বীকারজনিত দোষ কখন হয়?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                প্রধান হেতুবাক্যের যে-কোনো একটি বিকল্পকে অপ্রধান হেতুবাক্যে
                স্বীকার করে সিদ্ধান্তে অপর বিকল্পকে অস্বীকার করলে বিকল্প
                স্বীকারজনিত দোষ হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>24. বিকল্প স্বীকারজনিত দোষের একটি উদাহরণ দাও।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                হয় লোকটি কবি অথবা ডাক্তার।
                <br />
                লোকটি হয় ডাক্তার।
                <br />
                এমন নয় যে লোকটি কবি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  25. মিশ্র ন্যায় বা সাপেক্ষ ন্যায় বা যৌগিক যুক্তি কাকে বলে?
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে ন্যায়ে দুটি হেতুবাক্য ও সিদ্ধান্ত থাকে এবং হেতুবাক্য দুটির
                অন্তত একটি সাপেক্ষ বচন হয়, তাকে সাপেক্ষ ন্যায় বা মিশ্র ন্যায়
                বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  26. পূর্বর্গ অস্বীকারজনিত দোষ বা পূর্বৰ্গ নিষেধ দোষ কখন ঘটে?
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                প্রাকল্পিক যুক্তির ক্ষেত্রে অপ্রধান হেতুবাক্যে পূর্বৰ্গকে আগে
                অস্বীকার করে সিদ্ধান্তে অনুগকে অস্বীকার করলে পূর্বর্গ
                অস্বীকারজনিত দোষ ঘটে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

প্রাকল্পিকওবৈকল্পিকন্যায়.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(প্রাকল্পিকওবৈকল্পিকন্যায়);
