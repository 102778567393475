import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function বায়ুমণ্ডল() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          বায়ুমণ্ডল | West Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="বায়ুমণ্ডল | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                বায়ুমণ্ডল
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b>পঞ্চম অধ্যায়</b>
              </font>
              <br />{" "}
              <font color="#2b9a09" size="4">
                <b>বায়ুমণ্ডল</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রশ্নের মান - ৮</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                ১. ক্রান্তীয় ঘূর্ণবাতের উৎপত্তি বা জীবনচক্র আলোচনা করো।
                <br />
                ২. ঘূর্ণবাত ও প্রতীপ ঘূর্ণবাতের মধ্যে পার্থক্য লেখো। ঘূর্ণবাতের
                বৈশিষ্ট্য লেখো।
                <br />
                ৩. ঘূর্ণবাতের চক্ষু কাকে বলে? ক্রান্তীয় ও নাতিশীতোষ্ণ
                ঘূর্ণবাতের পার্থক্য নিরূপণ করো। ২ + ৫<br />
                ৪. ঘূর্ণবাতের শ্রেণিবিভাগ করো এবং তাদের বৈশিষ্ট্য লেখো।
                <br />
                ৫. বায়ু সঞ্চলন সম্পর্কে রসবিতরঙ্গ ও ওয়াকার সার্কুলেশন আলোচনা
                করো।
                <br />
                ৬. নাতিশীতোষ্ণ ঘূর্ণবাতের উৎপত্তির পর্যায় বা জীবনচক্র লেখো।
                <br />
                ৭. জেট বায়ু বলতে কী বোঝ? জেট বায়ুপ্রবাহের তিনটি বৈশিষ্ট্য
                উল্লেখকরো। এই বায়ুর উৎপত্তি সম্বন্ধে আলোচনা করো।
                <br />
                ৮. ক্রান্তীয় ঘূর্ণবাত কোথায় কী নামে পরিচিত? উষ্ণ ও শীতল
                সীমান্তের মধ্যে পার্থক্য উল্লেখ করো।
                <br />
                ৯. লা নিনা সম্পর্কে আলোচনা করো। এল নিনো ও লা নিনার পার্থক্য
                লেখো।
                <br />
                ১০. ভারতের জলবায়ুতে জেট বায়ুর প্রভাব আলোচনা করো। <br />
                অথবা,
                <br /> মৌসুমি বায়ুর সঙ্গে জেট বায়ুর সম্পর্ক আলোচন করো।
                <br />
                ১১. ত্রিকোশীয় মডেল বা ত্রিকক্ষীয় মডেল কাকে বলে? এর সম্পর্কে যা
                জানো লেখো।
                <br />
                ১২. মৌসুমি বায়ুর সঙ্গে এল-নিনোর সম্পর্ক লেখো। <br />
                অথবা,
                <br /> ভারতের জলবায়ুর উপর (মৌসুমি বায়ু) এল-নিনোর প্রভাব লেখো।
                <br />
                ১৩. উষ্ণতার তারতম্য অনুসারে স্বাভাবিক উদ্ভিদের শ্রেণিবিভাগ।
                <br />
                ১৪. মৌসুমি জলবায়ু এবং ভূমধ্যসাগরীয় জলবায়ুর মধ্যে পার্থক্য
                লেখো।
                <br />
                ১৫. হাইড্রোফাইট বা জলজ উদ্ভিদের শ্রেণিবিভাগ করো। হ্যালোফাইট
                উদ্ভিদের অভিযোজনগত বৈশিষ্ট্য লেখো।
                <br />
                ১৬. জাঙ্গল বা মরু উদ্ভিদের শ্রেণিবিভাগ করো। জাঙ্গল উদ্ভিদের
                অভিযোজনগত বৈশিষ্ট্য লেখো।
                <br />
                ১৭. জাঙ্গল উদ্ভিদ ও লবণাম্বু উদ্ভিদের তুলনা করো। মেসোফাইট বা
                সাধারণ উদ্ভিদের সাধারণ ও অভিযোজনগত বৈশিষ্ট্য আলোচনা করো।
                <br />
                ১৮. অম্লবৃষ্টির কারণ ও তার প্রভাব আলোচনা করো।
                <br />
                ১৯. গ্রিন হাউস এফেক্টের প্রভাব বা ফলাফল লেখো। <br />
                অথবা,
                <br /> গ্লোবাল ওয়ার্মিং-এর প্রভাব লেখো।
                <br />
                ২০. বিশ্ব উষ্ণায়ন কাকে বলে? এর কারণ লেখ।
                <br />
                ২১. ওজোন গহ্বর সৃষ্টির কারণ আলোচনা করো। ওজোন স্তর ক্ষয় বা
                হ্রাসের প্রভাব লেখো।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রশ্নের মান - ১</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>১. বায়ুমণ্ডলে শতকরা কত ভাগ নাইট্রোজেন থাকে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ৭৮.০৮৪ ভাগ।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>২. বায়ুমণ্ডলে শতকরা কত ভাগ অক্সিজেন থাকে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ২০.৯৪৬ ভাগ।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩. বায়ুমণ্ডলে শতকরা কতভাগ কার্বন ডাই অক্সাইড থাকে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ০.০৩ শতাংশ।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৪. বায়ুমণ্ডলে শতকরা কত ভাগ আর্গন থাকে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ০.৯৩ শতাংশ।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫. বায়ুমণ্ডলে প্রধান গ্যাসীয় উপাদানটির নাম কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> নাইট্রোজেন।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬. ট্রপোস্ফিয়ারের ঊর্ধ্বসীমার নাম কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ট্রপোপজ।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৭. বায়ুমণ্ডলের কোন স্তরে উচ্চতা বৃদ্ধির সঙ্গে সঙ্গে উষ্ণতা
                  হ্রাস পায়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ট্রপোস্ফিয়ার বা ক্ষুব্ধমণ্ডলে।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৮. এরোসল কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                এরোসল হল ক্ষুদ্রাকৃতি কঠিন কণা (সিলিকেট কণা, লবণ কণা প্রভৃতি)
                যাকে কেন্দ্র করে জলবিন্দুর সৃষ্টি হয়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৯. বায়ুমণ্ডলের কোন স্তরের মধ্যে মেঘ, ঝড়-ঝঞ্ঝা ইত্যাদি সৃষ্টি
                  হয়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ট্রপোস্ফিয়ারে।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১০. বায়ুমণ্ডলের কোন স্তরকে, কেন শান্তমণ্ডল বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                স্ট্র্যাটোস্ফিয়ার-কে। এই স্তরে আবহাওয়া শান্ত থাকার জন্য এই
                স্তরকে শান্তমণ্ডল বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ১১. রাসায়নিক গঠন অনুসারে বায়ুমণ্ডলকে কী কী ভাগে ভাগ করা যায়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                দুই ভাগে। হোমোস্ফিয়ার ও হেটরোস্ফিয়ার।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১২. ট্রপোস্ফিয়ারের ওপরে বায়ুর যে স্তর আছে তার নাম কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> স্ট্র্যাটোস্ফিয়ার।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৩. ওজোন গ্যাসের স্তর কোথায় দেখতে পাওয়া যায়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> স্ট্রাটোস্ফিয়ার–এ।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৪. বায়ুমণ্ডলের কোন স্তরে সুমেরু ও কুমেরু প্রভা দেখা যায়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> আয়নোস্ফিয়ার-এ।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ১৫. স্ট্র্যাটোস্ফিয়ারের ওপরে বায়ুর যে স্তর আছে তার নাম কী?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> মেসোস্ফিয়ার।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৭. মেসোস্ফিয়ারের ওপরে বায়ুর যে স্তর আছে তার নাম কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> থার্মোস্ফিয়ার।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ১৮. মেরু অঞ্চলে ট্রপোস্ফিয়ারের বিস্তৃতি কতদূর পর্যন্ত বিস্তৃত?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ৬-৮ কিমি।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ১৯. গ্যাসীয় উপাদান ছাড়া বায়ুমণ্ডলের অপর একটি উল্লেখযোগ্য
                  উপাদানের নাম কী।
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ধূলিকণা।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২০. বায়ুমণ্ডলের সর্বশেষ স্তরের নাম কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> এক্সোস্ফিয়ার।</font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ২১. বায়ুমণ্ডল ভূ-পৃষ্ঠ থেকে ওপরের দিকে কতদূর পর্যন্ত বিস্তৃত?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ওপরের দিকে প্রায় ১০,০০০ কিমি।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২২. কে কবে প্রথম কোথায় ওজোন ছিদ্র আবিষ্কার করেন?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ১৯৮৫ সালে জে. সি. ফারমেন ও তার সহকর্মীরা, আন্টার্কটিকায়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৩. পরিবহন কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                কোনও অনুগুলির মধ্য দিয়ে খুব ধীর গতিতে তাপের যে সঞ্চালন ঘটে তাকে
                পরিবহন বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৪. অ্যাডভেকশন কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                উষ্ণ বায়ুর অনুভূমিক প্রবাহকে অ্যাডভেকশন বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৫. তাপ পরিমাপ করার যন্ত্রের নাম কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ক্যালোরিমিটার।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৬. তাপমাত্রা পরিমাপ করার যন্ত্রের নাম কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> থার্মোমিটার।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৭. তাপীয় নিয়ন্ত্রক কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                সমুদ্রস্রোত উষ্ণতা নিয়ন্ত্রণের ক্ষেত্রে গুরুত্বপূর্ণ ভূমিকা পালন
                করে। তাই একে উষ্ণতা নিয়ন্ত্রক বলা হয়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৮. স্বাভাবিক উদ্ভিদ কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                প্রকৃতিতে স্বাভাবিকভাবে (মানুষের সাহায্য ছাড়া) যে উদ্ভিদ বেড়ে
                ওঠে তাকে স্বাভাবিক উদ্ভিদ বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৯. সমোষ্ণরেখা কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভূ-পৃষ্ঠের সম উষ্ণতাযুক্ত স্থানগুলিকে একটি কাল্পনিক রেখা দ্বারা
                যুক্ত করলে তাকে সমোষ্ণরেখা বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩০. El Nino শব্দের অর্থ কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> শিশু খ্রীষ্ট।</font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>৩১. বায়ুচাপের পরিমাপের যন্ত্র কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ব্যারোমিটার।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩২. কে কবে টরিসেলি ব্যারোমিটার আবিষ্কার করে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ১৯৪৩ খ্রীষ্টাব্দে বিজ্ঞানী টরিসেলি।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৩. কে কবে ফর্টিন্স ব্যারোমিটার আবিষ্কার করে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ১৮০০ খ্রীষ্টাব্দে নিকোলাস ফর্টিন।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৪. ভার্নিয়ার স্কেল কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                সূক্ষ্মতম দৈর্ঘ্য মাপার জন্য ১৬৩১ খ্রীষ্টাব্দে ফরাসী গণিতবিদ্‌
                পি ভার্নিয়ার যে স্কেল আবিষ্কার তার নাম ভার্নিয়ার স্কেল।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৫. কোন ব্যারোমিটারে পারদ ব্যবহার করা হয় না?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> অ্যানিরয়েড ব্যারোমিটারে।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৬. বায়ুপ্রবাহ কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভূপৃষ্ঠের ওপর বায়ু যখন অনুভূমিকভাবে চলাচল করে তখন তাকে
                বায়ুপ্রবাহ বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৭. শিশিরাঙ্ক কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে বিশেষ তাপমাত্রায় বায়ু সম্পৃক্ত অবস্থায় পৌঁছায় যেই তাপমাত্রাকে
                শিশিরাঙ্ক বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৮. বায়ুর আর্দ্রতা পরিমাপ করার যন্ত্রের নাম কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> হাইগ্রোমিটার।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৯. বায়ুর আপেক্ষিক আর্দ্রতা পরিমাপ করার যন্ত্রের নাম কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> সাইক্লোমিটার।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪০. মেঘ কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> জলকণা ও বরফকণার সমষ্টিকে মেঘ বলে।</font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>৪১. কোথায় পৃথিবীর সর্বাধিক বৃষ্টিপাত হয়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> মেঘালয়ের মৌসিনরামে।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪২. বৃষ্টিপাত পরিমাপ করার যন্ত্রের নাম কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> রেইন গেজ।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৩. বায়ুমণ্ডলে Co২-এর পরিমাণ কত শতাংশ ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ০.০৩%।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৪৪. গ্রিনহাউস গ্যাসের উৎপাদন ও নির্গমন হার হ্রাসের জন্য কী
                  চুক্তি স্বাক্ষরিত হয়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                কিয়ােটো প্রােটোকল (১৯৯৭, ১১ ডিসেম্বর)।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৫. বায়ুতে নাইট্রাস অক্সাইডের প্রধান উৎস কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> জমিতে নাইট্রোজেন সারের ব্যবহার।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৬. জলবায়ু পরিবর্তনের দুটি নিদর্শন উল্লেখ করাে।</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                (a) মরু করণ এবং (b) সমুদ্রজল পৃষ্ঠের উচ্চতা বৃদ্ধি।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৭. জোহানেসবার্গে পরিবেশ সম্মেলন কত খ্রিস্টাব্দে হয়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ২০০২ খ্রিস্টাব্দে।</font>

              <font color="#f626d7">
                <b>৪৮. বায়ুমণ্ডলের কোন স্তরে ওজোন পাওয়া যায় ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                বায়ুমণ্ডলের স্ট্র্যাটোস্ফিয়ার স্তরে ওজোন পাওয়া যায় ।{" "}
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৪৯. যে সীমান্ত বরাবর উঘ্ন বায়ুপুঞ্জ শীতল বায়ুপুত্থকে ঠেলে
                  এগিয়ে চলে তাকে কী বলে ?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> উষ্ণ সীমান্ত । </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫০. মিস্ট্রাল কী ? </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                মিস্টাল হলো শীতল স্থানীয় বায়ু যা ফ্রান্সে রোন নদীর উপত্যকা ধরে
                ভূমধ্যসাগরীয় নিম্নচাপের দিকে প্রবাহিত হয় ।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ৫১. ভূপৃষ্ঠের উচ্চতা হ্রাস পায় যে প্রক্রিয়ায় তার নাম কী ?{" "}
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> অবরোহণ প্রক্রিয়া। </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫২. প্রাকৃতিক সৌরপর্দা কাকে বলে ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ওজোন স্তরকে। </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৩. পৃথিবীর সর্বাধিক টর্নেডোপ্রবণ অঞ্চল কোনটি ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                যুক্তরাষ্ট্রের মিসিসিপি – মিসৌরি নদীর মোহনা।{" "}
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৪. বায়ুমণ্ডলে CO , বৃদ্ধির প্রধান উৎস কী ? </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> জীবাশ্ম জ্বালানির দহন। </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৫. টর্নেডো মার্কিন যুক্তরাষ্ট্রে কী নামে পরিচিত ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                টর্নেডো মার্কিন যুক্তরাষ্ট্রে ‘ টুইস্টার ‘ নামে পরিচিত ।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৬. CFC কী ? </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                এটি একটি গ্রিনহাউস গ্যাস পুরো নাম ক্লোরোফ্লুরোকার্বন। এই গ্যাস
                ওজোন স্তরের হ্রাস ঘটায় ।{" "}
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৭. সুনামি কী ? </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                সমুদ্রের তলদেশে ভূমিকম্পের ফলে যে বিশালাকৃতি বিধ্বংসী সামুদ্রিক
                ঢেউ উপকূলে আছড়ে পড়ে তাকে জাপানে সুনামি বলে ।{" "}
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৮. প্রাকৃতিক সৌরপর্দা কাকে বলা হয়? </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                পৃথিবীর জীবজগৎকে অতিবেগুনি রশ্মির ক্ষতিকর প্রভাব থেকে ছাতার মতো
                রক্ষা করে বলে ওজোন স্তরকে পৃথিবীর ‘ প্রাকৃতিক সৌরপর্দা ‘ বলে ।{" "}
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৫৯. বায়ু সঞ্চালন সম্পর্কিত ত্রিকোশ তত্ত্বের প্রবক্তা কে ?{" "}
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                বিজ্ঞানী পলম্যান বায়ু সঞ্চালন সম্পর্কিত ত্রিকোশ তত্ত্ব উপস্থাপন
                করেন।{" "}
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬০. ‘ ডোলড্রাম ‘ অঞ্চল কাকে বলে ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                প্রশাস্ত ও আটলান্টিক মহাসাগরের ITCZ অঞ্চল দিয়ে জাহাজ অতিধীরে
                অতিক্রম করত বলে নাবিকগণ এর নামকরণ করেন ‘ ডোলড্রাম ‘।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>৬১. ওজোন স্তরে কোন রশ্মি শোষিত হয় ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ওজোন স্তরে সূর্য থেকে আগত ক্ষতিকারক Ultra Violet Ray বা
                অতিবেগুনি রশ্মি শোষিত হয়।{" "}
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬২. এল নিনোর উৎপত্তি কোথায় ঘটে ? </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ক্রান্তীয় প্রশান্ত মহাসাগরের পূর্ব প্রান্তে পেরু , ইকুয়েডরের
                পশ্চিম উপকূলে এল নিনোর উৎপত্তি ঘটে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬৩. ‘ হ্যাডলি কোশের ‘ অপর নাম কী ? </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                হ্যাডলি কোশের অপর নাম ‘ প্রত্যক্ষ তাপীয় কোশ ‘।{" "}
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬৪. কোন প্রকার ঘূর্ণবাতে আবহাওয়া সর্বদা শাস্ত থাকে ? </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                প্রতীপ ঘূর্ণবাতে আবহাওয়া সর্বদা শান্ত থাকে।{" "}
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৬৫. ক্রান্তীয় অঞ্চলে সমুদ্রপৃষ্ঠে নিম্নচাপবিশিষ্ট দুর্বল
                  ঘূর্ণিঝড়কে কী বলা হয় ?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ক্রান্তীয় ডিপ্রেশন।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬৬. টর্নেডাের ব্যাস কত?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> প্রায় ১০০-৫০০ মিটারের মতাে।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬৭. হ্যারিকেনের ব্যাস কত?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> প্রায় ২০০-৬৫০ কিমি।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬৮. ক্রান্তীয় ঘূর্ণবাতে কী জাতীয় মেঘ দেখা যায় ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> কিউমুলােনিম্বাস।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬৯. নাতিশীতােয় ঘূর্ণবাতে কী জাতীয় মেঘের সৃষ্টি হয় ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> বিভিন্ন ধরনের স্ট্রাটাস মেঘের।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৭০. ক্রান্তীয় ঘূর্ণবাতের উৎপত্তিস্থল কোথায় ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> সমুদ্রের উপর।</font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

বায়ুমণ্ডল.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(বায়ুমণ্ডল);
