import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function সত্যাপেক্ষক() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          সত্যাপেক্ষক | West Bengal Class 12 Guide | West Bengal HS Suggestion |
          Higher Secondary Guide | WBCHSE | WB
        </title>
        <meta
          name="description"
          content="সত্যাপেক্ষক | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                সত্যাপেক্ষক
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  কোনো বচনাকার থেকে যেসব বচন পাওয়া যায়, তাদের ওই আকারের কীরূপ
                  দৃষ্টান্ত বলে?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নির্দেশক দৃষ্টান্ত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চরম দৃষ্টান্ত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শাস্তিমূলক দৃষ্টান্ত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  নিবেশন দৃষ্টান্ত
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  সংযৌগিক বচনের একটি সংযোগী মিথ্যা হলে বচনটির সত্যমূল্য কী হবে?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সত্য হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মিথ্যা হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনির্দিষ্ট হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আপতিক হবে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> P সত্য হলে ~P বচনাকারটি সত্যমূল্য কী হবে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সত্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মিথ্যা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অর্থহীন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আপতিক
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বৈকল্পিক বচনের অঙ্গবচনগুলিকে বলা হয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বিকল্প
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনুগ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পূর্বগ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সংযোগী
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  সত্যাপেক্ষ বচনের অন্তর্ভুক্ত সরল অঙ্গবাক্যগুলির পরিবর্তে যে
                  প্রতীক ব্যবহার করা হয়, তাকে বলা হয় —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যোজক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বচন-গ্রাহক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পদ-গ্রাহক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শব্দ-গ্রাহক
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>একটি বচনাকারে একটি গ্রাহক থাকলে সত্যসারণির সারি হবে —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  দুটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  একটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চারটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তিনটি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>P সত্য হলে ~~P বচনাকারটির সত্যমূল্য কী হবে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সত্য হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিথ্যা হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনির্দিষ্ট হবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আপতিক হবে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>যে প্রতীক যে-কোনো বচন গ্রহন করতে পারে তাকে কী বলে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শব্দ-গাহক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বচন-গ্রাহক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পদ-গ্রাহক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  যুক্তি-গ্রাহক
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> ‘~P’ এর নিষেধক বচনাকারটি কীরূপ বচনাকার?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>P
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  PvP
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ~P
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  PP
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  যে যৌগিক বচনের সত্যমূল্য সর্বদা মূল বচনের বিপরীত হয়, তাকে বলে
                  —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সংযৌগিক বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  নিষেধক বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রাকল্পিক বচন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বৈকল্পিক বচন
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>অষ্টম অধ্যায় - সত্যাপেক্ষক</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 1. একটি দ্বি-প্রাকল্পিক বচনের উদাহরণ দাও।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                বৃষ্টি হবে যদি এবং কেবল যদি মেঘ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. একটি স্বতঃসত্য বচনাকারের উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Pv ~ P<br />
                <br />
                <font color="#f626d7">
                  <b>
                    3. একটি স্বতঃসত্য বচনাকারকে অস্বীকার করলে কীরূপ বচনাকার
                    পাওয়া যাবে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                স্বতঃমিথ্যা বচনাকার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    4. একটি স্বতঃমিথ্যা বচনাকারকে অস্বীকার করলে কীরূপ বচনাকার
                    পাওয়া যাবে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                স্বতঃসত্য বচনাকার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    5. একটি আপতিক বচনাকারকে অস্বীকার করলে কীরূপ বচনাকার পাওয়া
                    যাবে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                আপতিক বচনাকার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. স্বতঃমিথ্যা বচনাকারের একটি দৃষ্টান্ত দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                p ~ p|
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. একটি বৈকল্পিক বচনের নিষেধ কী বচন হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                সংযৌগিক বচন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. একটি সংযৌগিক বচনের নিষেধ কোন বচন হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                বৈকল্পিক বচন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 1. নিষেধক বচন কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                কোনো বচনে যা ঘোষণা করা হয়, তাকে নিষেধ করলে বা অস্বীকার করলে যে
                বচনটি পাওয়া যায়, তাকে নিষেধক বচন বলে। যেমন — এমন নয় যে জবা
                লাল।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 2. সংযৌগিক বচন কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে যৌগিক বচনের দুই বা ততোধিক উপাদান বচন ‘এবং’ অথবা অনুরূপ
                অর্থযুক্ত শব্দের দ্বারা সংযুক্ত হয়, তাকে সংযৌগিক বচন বলে। যেমন
                — রিতা লেখিকা এবং গীতা ছাত্রী।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 3. প্রাকল্পিক বচন কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে যৌগিক বচনের অঙ্গবচন দুটি ‘যদি-তবে’ বা এই জাতীয় কোনো শর্তের
                দ্বারা যুক্ত হয়, তখন তাকে প্রাকল্পিক বচন বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 4. সত্যাপেক্ষক যোজক কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                সত্যাপেক্ষক বচনগুলি যেসব যোজক দ্বারা যুক্ত হয় তাদের সত্যাপেক্ষক
                যোজক বলে। যেমন — অথবা এবং প্রভৃতি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 5. আপতিক বচনাকার কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              যে বচনাকারের সত্য এবং মিথ্যা উভয়প্রকার নিবেশন দৃষ্টান্তই বর্তমান
              থাকে, তাকে আপতিক বচনাকার বলে।
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 6. স্বতঃমিথ্যা বচনাকার কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে বচনাকারের অন্তর্গত সব ক-টি নিবেশন দৃষ্টান্তই মিথ্যা তাকে
                স্বত-মিথ্যা বচনাকার বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 7. সত্যাপেক্ষ বচন কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যে যৌগিক বচনের সামগ্রিক সত্যমূল্য তার অঙ্গবচনগুলির সত্যমূল্যের
                অপেক্ষায় থাকে, সেই যৌগিক বচনকে সত্যাপেক্ষ বচন বলে। যেমন — হয়
                সে কবি অথবা দার্শনিক।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 8. স্থায়ী সংকেত বা ধ্রুবক কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                যুক্তি বা বচনের যেসব প্রতীককে পরিবর্তন করা যায় না, তাকে ধ্রুবক
                বলে। যেমন — v।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

সত্যাপেক্ষক.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(সত্যাপেক্ষক);
