import AsyncStorage from "@react-native-async-storage/async-storage";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware, connectRouter } from "connected-react-router";

import HomePageReducer from "./containers/HomePage/reducer";
import HomePageSaga from "./containers/HomePage/saga";

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
  homePage: HomePageReducer,
  //router: connectRouter(history),
  //   auth: UserReducer,
  //   internet: InternetReducer,
  //   spinner: SpinnerReducer,
  //   grammar: GrammarReducer,
  //   quizTest: ResultReducer,
});

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: false,
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }).concat(sagaMiddleware);
    //.concat(routerMiddleware(history));

    //   if (__DEV__ && !process.env.JEST_WORKER_ID) {
    //     const createDebugger = require('redux-flipper').default;
    //     middlewares.push(createDebugger());
    //   }

    return middlewares;
  },
});

sagaMiddleware.run(HomePageSaga);

const persistor = persistStore(store);

setupListeners(store.dispatch);

export { store, persistor, history };
