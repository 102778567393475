import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function খাদ্যউৎপাদনেরউন্নতিসাধন() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          খাদ্য উৎপাদনের উন্নতি সাধন | West Bengal Class 12 Guide | West Bengal
          HS Suggestion
        </title>
        <meta
          name="description"
          content="খাদ্য উৎপাদনের উন্নতি সাধন | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                খাদ্য উৎপাদনের উন্নতি সাধন
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>গমের একটি মাঝারি খর্ব ভ্যারাইটি হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  IR-8
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সোনালিকা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ট্রিটিকাম (Triticum)
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Saccharum
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নিম্নলিখিতগুলির কোন্‌টি মুরগির গুরুত্বপূর্ণ ব্রিড?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জার্সি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  রোড আইল্যান্ড রেড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  খাকি ক্যাম্পবেল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নাগেশ্বরী
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>উদ্ভিদ প্রজনন বিদ্যার মাধ্যমে উৎপন্ন করা হয় তা হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উচ্চ ফলনশীল ভ্যারাইটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রোগমুক্ত ভ্যারাইটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পূর্বে পরিণতি প্রাপ্তি ভ্যারাইটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সবকটিই
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নীচের কোন্‌টি মাইনর কার্প?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পুঁটিমাছ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বাটা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মৃগেল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রুই
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মধুতে উপস্থিত উৎসেচক হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইনভারটেজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্যাটালেজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডায়াস্টেজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সবগুলি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>SCP শব্দটি প্রথম ব্যবহার করেন -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উইলসন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মেডেল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হ্যাবারল্যান্ড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হোয়াইট
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>উদ্ভিদ কোশের সম্পূর্ণ উদ্ভিদ পুনরুৎপাদনের ক্ষমতাকে বলে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্লোনিং
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্লিউরিপোটেন্সি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কলা পোষণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  টোটিপোটেন্সি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  মৌমাছি দ্বারা উৎপাদিত মোম জাতীয় পদার্থ যা মৌচাক মেরামতিতে
                  ব্যবহৃত হয়, তা হল —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রয়্যাল জেলি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মৌনিহার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  প্রোপোলিজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্পোরোপোলেনিন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ট্রান্সজেনিক ‘গোল্ডেন রাইসে’ যা প্রচুর পরিমাণে থাকে তা হল -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিথিওনিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  B-ক্যারোটিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভিটামিন D
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লাইসিন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নিম্নলিখিত কোন্‌টি হল একটি ভূমধ্যসাগরীয় ব্রিড —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আসীল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অ্যানকোনা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অরপিংটন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রোড আইল্যান্ড রেড
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7">
                <b>1. বিশ্বে সবুজ বিপ্লবের জনক কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                বিশ্বে সবুজবিপ্লবের জনক NE Borlang
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. হাঁসের দেশীয় ব্রিডগুলির উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                রানার, নাগেশ্বরী, খাঁকি ক্যাম্পবেল ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. হাঁসের কয়েকটি প্রধান রোগের উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                কলেরা, প্লেগ, ক্র্যাম্প ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. SCP উৎপাদনকারী শৈবালের নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Spirulina
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. SCP উৎপাদনকারী ব্যাকটেরিয়ার নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                Rhodopseudomonas capsularis <br />
                <br />
                <font color="#f626d7">
                  <b>6. একটি GM (Genetically Modified) শস্যের নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                সোনালি চাল (Golden rice)
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. গোরুর দুটি ব্রিডের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                জার্সি(Jersey) এবং বাদামি সুইস (Brown Suiss)
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. BAP-এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                BAP-এর পুরো নাম Benzyle Amino Purine.
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. হার্ডেনিং কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                অণু বিস্তারণ পদ্ধতিতে কর্ষণ মাধ্যম থেকে চারাগাছগুলিকে ছোটো ছোটো
                টবে স্থানান্তরিত করে কয়েক সপ্তাহ ধরে গ্রিন হাউসে রেখে দিলে
                চারাগাছগুলি প্রাকৃতিক পরিবেশে অভিযোজিত হয় এবং প্রচুর পরিমাণে
                পাতা ও মূল উৎপন্ন হয়। এই সম্পূর্ণ পদ্ধতিকে হার্ডেনিং বলা হয়।
                হার্ডেনিং-এর পরেই চারাগাছগুলি প্রাকৃতিক পরিবেশে বা জমিতে
                স্থানান্তরের যোগ্য হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. রয়েল জেলি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                শ্রমিক মৌমাছিদের গলবিল নিঃসৃত প্রোটিন জাতীয় যে খাবার মৌমাছিদের
                খাদ্য হিসেবে ব্যবহৃত হয় তাকে রয়েল জেলি বা রাজকীয় জেলি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. কার্প কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                স্বাদু জলে বসবাসকারী যেসব মাছের মাথায় আঁশ থাকে না, চোয়ালে দাঁত
                থাকে না, অতিরিক্ত শ্বাসঅঙ্গ থাকে না, দেহগহ্বরে পটকা থাকে তাদের
                কার্প বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. ইকোটাইপ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পরিবেশের তারতম্যে একই প্রজাতিভুক্ত উদ্ভিদের মধ্যে প্রকরণের ফলে
                সৃষ্ট প্রকারগুলিকে ইকোটাইপ বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. প্রোটোপ্লাস্ট কর্ষণ কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                "যে কলা কর্ষণ পদ্ধতিতে উদ্ভিদ কোষ থেকে সংগৃহীত প্রোটোপ্লাস্টের
                বৃদ্ধি ও পরিপোষণ উন্নত করে এবং নতুন উদ্ভিদ উৎপাদন করে, সেই
                পদ্ধতিকে 'প্রোটোপ্লাস্ট কর্ষণ' বলা হয়। এই পদ্ধতিতে দুটি ভিন্ন
                প্রজাতির প্রোটোপ্লাস্টের ফিউশান ঘটতে পারে, যা হল 'হাইব্রিড
                প্রোটোপ্লাস্ট' গঠনে অনুমতি দেয়।"
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. জিনগান কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে যন্ত্র দ্বারা ক্যালাস কোশে জিন প্রবেশ করিয়ে ট্রান্সজেনিক
                উদ্ভিদ প্রজাতি সৃষ্টি করা হয়, তাকে জিনগান বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. প্রোপোলিস কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                এটি এক ধরনের রজন বিশেষ, যা মৌচাকের ফাটল মেরামতে ব্যবহৃত হয়।
                প্রোপোলিস মৌমাছির লালারস এবং মৌমোম-এর মিশ্রণে তৈরি হয়।
                প্রোপোলিসের ওষুধি গুণ আছে। এটি তেজস্ক্রিয়তার হাত থেকে ত্বককে
                রক্ষা করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. কোশ কৰ্ষণ (Cell Culture) বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                যে কলা কর্ষণ পদ্ধতিতে পরীক্ষাগারে জীবাণুমুক্ত পরিবেশে এবং
                উপযুক্ত তরল পুষ্টি মাধ্যমে একক বা কয়েকটি উদ্ভিদকোশের প্রতিপালন
                তথা বৃদ্ধি ঘটানো হয়, তাকে কোশকর্ষণ বা সাসপেনশন কালচার বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>9. ক্যালাস কর্ষণ ( Callus Culture) কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                "জীবাণুমুক্ত পরিবেশে এবং উপযুক্ত পুষ্টি মাধ্যমে পরীক্ষাগারে
                এক্সপ্ল্যান্ট থেকে অবিভেদিত এবং অসংগঠিত কোশসমূহ বা ক্যালাস তৈরি
                করে, এবং তাদের বৃদ্ধি এবং পোষণের মাধ্যমে পূর্ণাঙ্গ উদ্ভিদ উৎপন্ন
                করাকে 'ক্যালাস কর্ষণ' বলা হয়।"
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. জিনগত রূপান্তর বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                "বায়োফটিফিকেশনের একটি পদ্ধতি ব্যবহার করে যা বাইরে থেকে বিশেষ
                জিন প্রবেশ করিয়ে শস্যে প্রোটিন, ভিটামিন, ফ্যাট এবং
                ম্যাক্রোনিউট্রিএন্টের পরিমাণ বৃদ্ধি করে এবং শস্যের পুষ্টিগত মান
                উন্নত করে। এই পদ্ধতিকে 'জিনগত রুপান্তর' বলা হয়।"
                <br />
                <br />
                <font color="#f626d7">
                  <b>11. মৌ-মোম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                শ্রমিক মৌমাছির অঙ্কদেশের উদরে অবস্থিত মোম গ্রন্থির ক্ষরণ বাতাসের
                সংস্পর্শে এসে যে হরিদ্রাভ বা কালচে ধূসর বর্ণের নমনীয় ও
                অদ্রবণীয় পদার্থে পরিণত হয় তাকে মৌ-মোম বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. সোমাটিক ভ্ৰূণ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                দুটি ভিন্ন প্রজাতি বা ভ্যারাইটির প্রোটোপ্লাস্টের মিলন ঘটিয়ে
                নতুন জিনসমন্বিত যে ভ্রুণ তৈরি করা হয়, তাকে সোমাটিক ভ্রুণ বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>13. সাবকালচারিং বলতে কী বোঝ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                কলা পালনের সময় উৎপন্ন ক্যালাসের একটি অংশকে নতুন কর্ষণ মাধ্যমে
                জীবাণুমুক্তভাবে স্থানান্তরের পদ্ধতিকে বলা হয় পুনঃকর্ষণ বা
                সাবকালচারিং।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. টোটিপোটেন্সি কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                উপযুক্ত পরিবেশে ও পুষ্টি মাধ্যমে একটি বিভিন্ন দেশকোশ থেকে একটি
                পূর্ণাঙ্গ উদ্ভিদ সৃষ্টির অন্তর্নিহিত ক্ষমতাকে কোশটির
                টোটিপোটেন্সি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. ক্যালাস (Callus) কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                পুষ্টি মাধ্যমে এক্সপ্ল্যান্টটি অনিয়মিত বিভাজনের দ্বারা
                বৃদ্ধিপ্রাপ্ত হয়ে অবিভেদিত ও অসংগঠিত কোশগুচ্ছ গঠন করে, তাকে
                ক্যালাস বলে। এটি প্রকৃতপক্ষে প্যারেনকাইমা কলা।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>16. মধু কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                "শ্রমিক মৌমাছি দ্বারা সংগৃহীত উদ্ভিদের মধুবিশেষ, অথবা ফুলের রস
                দ্বারা প্রস্তুত এক ধরনের শর্করা ভিটামিন, খনিজ লবণের সহিত সমৃদ্ধ,
                সুমিষ্ট এবং সুগন্ধযুক্ত অর্ধতরল পদার্থকে 'মধু' বলা হয়।"
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>17. মৌমোম (bee-wax) কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                শ্রমিক মৌমাছির অঙ্কদেশের উদরে অবস্থিত মোম গ্রন্থির ক্ষরণ বাতাসের
                সংস্পর্শে এসে যে হরিদ্রাভ বা কালচে ধূসর বর্ণের নমনীয় ও
                অদ্রবণীয় পদার্থে পরিণত হয় তাকে মৌ-মোম বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>18. ইমাসকুলেশন কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                উভলিঙ্গ ফুল বিশিষ্ট উদ্ভিদের ক্ষেত্রে সংকরায়ণ পরীক্ষার সময়
                স্ত্রী জনিতৃ রূপে চিহ্নিত উদ্ভিদের সকল ফুলের পুংস্তবকগুলি পরিণত
                হওয়ার আগেই অথবা পরাগরেণু উৎপাদন হওয়ার আগে যে প্রক্রিয়ায় সেই
                উদ্ভিদের সকল ফুলের পুংস্তবক বা পরাগধানী ফুল থেকে অপসারণ করা হয়
                বা বিনষ্ট করে ফেলা হয়, সেই পদ্ধতিকেই পুং বন্ধ্যাত্বকরণ বা
                ইমাসকুলেশন বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>19. মাছের পাখনা পচন রোগের কারণ ও লক্ষণ লেখো।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                Saprolegnia parasitica নামক ছত্রাকের আক্রমণে মাছের পাখনা পচে
                যায়।
                <br />
                <font color="#f626d7">
                  <b>লক্ষণ : </b>
                </font>
                এই রোগে প্রাথমিক অবস্থায় পাখনায় সাদা রেখার দাগ দেখা যায়। পরে
                পাখনা পচে দেহ থেকে খসে পড়ে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>20. মাছের ফুলকা পচন রোগের কারণ ও লক্ষণ লেখো।</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                <font color="#1f7405">
                  <b>⦿ </b>
                </font>
                <font color="#f626d7">
                  <b> কারণ : </b>
                </font>{" "}
                Branchiomyces sanguinis নামক ছত্রাকের আক্রমণে মাছের ফুলকা পচে
                মাছ মারা যায়।
                <br />
                <br />
                <font color="#1f7405">
                  <b>⦿ </b>
                </font>
                <font color="#f626d7">
                  <b> লক্ষণ : </b>
                </font>{" "}
                এই রোগে ছত্রাকের অনুসূত্র ফুলকার ভিতরে রক্তজালকে ঢুকে যায়। ফলে
                প্রথমে ফ্যাকাশে হয়ে যায়। পরে ফুলকা সম্পূর্ণ সাদা হয়ে যায়।
                ফলে পচতে শুরু করে। অবশেষে মাছ মারা যায়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

খাদ্যউৎপাদনেরউন্নতিসাধন.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(খাদ্যউৎপাদনেরউন্নতিসাধন);
