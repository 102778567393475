import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function তৃতীয়স্তরেরক্রিয়াকলাপ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          তৃতীয় স্তরের ক্রিয়াকলাপ | West Bengal Class 12 Guide | West Bengal
          HS Suggestion | Higher Secondary Guide | WBCHSE | WB
        </title>
        <meta
          name="description"
          content="তৃতীয় স্তরের ক্রিয়াকলাপ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                তৃতীয় স্তরের ক্রিয়াকলাপ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b>অষ্টম অধ্যায়</b>
              </font>
              <br />{" "}
              <font color="#2b9a09" size="4">
                <b>তৃতীয় স্তরের অর্থনৈতিক ক্রিয়াকলাপ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ৭</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                1. তৃতীয় স্তরের অর্থনৈতিক কার্যাবলির বৈশিষ্ট্যগুলি উল্লেখ করাে।
                বিশ্ব বাণিজ্য সংস্থা (WTO) -র কার্যাবলি চিহ্নিত করাে।
                <br />
                2. ভারতের প্রধান প্রধান পর্যটন কেন্দ্রগুলির অবস্থান সম্পর্কে
                আলােচনা করাে। পর্যটন শিল্পের কয়েকটি গুরুত্ব লেখাে। <br />
                অথবা,
                <br /> কোনাে দেশের অর্থনীতির ওপর পর্যটন শিল্পের প্রভাব আলােচনা
                করাে।
                <br />
                3. পর্যটন বলতে কী বােঝ? পর্যটনের শ্রেণিবিভাগ গুলি আলােচনা করাে।
                পর্যটনশিল্প গড়ে ওঠার কারণ গুলি কী কী?
                <br />
                4. পরিবহণ ব্যবস্থা ও যােগাযােগ ব্যবস্থার মধ্যে পার্থক্য নিরূপণ
                করাে। আধুনিক যােগাযােগ ব্যবস্থার গুরুত্ব আলােচনা করাে।
                <br />
                5. যােগাযােগ ব্যবস্থা বলতে কী বােঝ? আধুনিক যােগাযােগ ব্যবস্থার
                বিভিন্ন মাধ্যমগুলি সম্পর্কে সংক্ষেপে আলােচনা করাে।
                <br />
                6. পরিবহণের গুরুত্ব আলােচনা করাে। বন্দর সংযােজক পরিকল্পনা কী?
                <br />
                7. অভ্যন্তরীণ ও আন্তর্জাতিক বাণিজ্য বলতে কী বােঝ? আন্তর্জাতিক
                বাণিজ্যের গুরুত্ব আলােচনা করাে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. তৃতীয় স্তরের অর্থনৈতিক কার্যাবলির বৈশিষ্ট্যগুলি উল্লেখ
                  করাে। বিশ্ব বাণিজ্য সংস্থা (WTO) -র কার্যাবলি চিহ্নিত করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>তৃতীয় স্তরের অর্থনৈতিক কার্যাবলির বৈশিষ্ট্যসমূহ : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যে অর্থনৈতিক ক্রিয়াকলাপের দ্বারা মানুষকে ব্যাবসাবাণিজ্য,
                আর্থিক, পরিবহণ ও যােগাযােগের বিভিন্ন পরিসেবা প্রদান করা হয়,
                তাকে তৃতীয় স্তরের অর্থনৈতিক ক্রিয়াকলাপ বলে। এই প্রকার
                অর্থনৈতিক ক্রিয়াকলাপের প্রধান বৈশিষ্ট্যগুলি হল 一<br />
                <br />
                1. প্রথম ও দ্বিতীয় স্তরের অর্থনৈতিক কাজের মধ্যে সংযােগ গড়ে
                তােলে তৃতীয় স্তরের অর্থনৈতিক কাজ।
                <br />
                2. উৎপাদনকারী এবং ভােগকারীর মধ্যে সংযােগ তৈরি করে থাকে তৃতীয়
                স্তরের অর্থনৈতিক কাজ।
                <br />
                3. চতুর্থ ও পঞ্চম স্তরের অর্থনৈতিক কাজ অনেকসময় তৃতীয় স্তরের
                অর্থনৈতিক কাজের ওপর নির্ভর করে গড়ে ওঠে।
                <br />
                4. উন্নত দেশ ও উন্নত সমাজে এই স্তরের অর্থনৈতিক কাজ বেশি দেখা
                যায়।
                <br />
                5. ব্যাবসাবাণিজ্যের মধ্যে এই স্তরের অর্থনৈতিক কাজের বেশি প্রসার
                লক্ষ করা যায়।
                <br />
                6. সংগঠিত অপেক্ষা অসংগঠিত ক্ষেত্রে বেশি নিযুক্ত লােকের সংখ্যা এই
                স্তরে দেখা যায়।
                <br />
                <br />
                <font color="#29900a">
                  <b>বিশ্ব বাণিজ্য সংস্থার কার্যাবলি : </b>{" "}
                </font>{" "}
                গ্যাট চুক্তির সিদ্ধান্তগুলির যথাযথ রূপায়ণের জন্য 1995 সালের 1
                জানুয়ারি স্থাপিত হয় বিশ্ব বাণিজ্য সংস্থা (WTO)। এর
                কার্যাবলিগুলি হল 一<br />
                <br />
                <font color="#f626d7">
                  <b>1. বাণিজ্যিক সম্প্রসারণ : </b>{" "}
                </font>{" "}
                বাণিজ্যিক বাধা দূর করে বহির্বাণিজ্যের সম্প্রসারণ ঘটানাের চেষ্টা
                করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. মেধাস্বত্ব সংরক্ষণের ব্যবস্থা : </b>{" "}
                </font>{" "}
                মেধাস্বত্ব রক্ষিত হচ্ছে কি না তা লক্ষ রাখা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. সংহতি স্থাপন : </b>{" "}
                </font>{" "}
                আন্তর্জাতিক আর্থিক প্রতিষ্ঠানগুলির মধ্যে সংহতি স্থাপন করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. বিরোধ মীমাংসা : </b>{" "}
                </font>{" "}
                সদস্য দেশগুলির মধ্যে বিভিন্ন কারণে বাণিজ্যিক বিরােধ দেখা দিলে
                সেই বিরােধের কারণ অনুসন্ধার করে তার মীমাংসা করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. চুক্তি রূপায়ণে নজরদারি : </b>{" "}
                </font>{" "}
                সদস্য দেশগুলির মধ্যে স্বাক্ষরিত বিভিন্ন প্রকার চুক্তির সার্থক
                রূপায়ণের জন্য নজরদারি করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. সমান সুবিধা দান : </b>{" "}
                </font>{" "}
                সমস্ত সদস্যদেশ যাতে কোনাে সুযােগসুবিধা থেকে বঞ্চিত না হয় এবং
                সকলে সমান সুযােগসুবিধা পায় তার দিকে নজর রাখা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. বহুমুখী বাণিজ্যিক সম্পর্ক স্থাপন : </b>{" "}
                </font>{" "}
                বিভিন্ন দেশের মধ্যে বহুমুখী বাণিজ্যিক সম্পর্ক স্থাপনের উদ্দেশ্যে
                আলােচনার মঞ্চ হিসেবে কাজ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. পরামর্শ দান : </b>{" "}
                </font>{" "}
                বাণিজ্য, অর্থনীতি প্রভৃতি বিষয়ে পরামর্শদাতার কাজ করা।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  2. ভারতের প্রধান প্রধান পর্যটন কেন্দ্রগুলির অবস্থান সম্পর্কে
                  আলােচনা করাে। পর্যটন শিল্পের কয়েকটি গুরুত্ব লেখাে। <br />
                  অথবা,
                  <br /> কোনাে দেশের অর্থনীতির ওপর পর্যটন শিল্পের প্রভাব আলােচনা
                  করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>ভারতের প্রধান প্রধান পর্যটন শিল্পকেন্দ্র : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                বহুবৈচিত্র্যময় ভারতবর্ষের বিভিন্ন অঞ্চলে বিভিন্ন কারণে
                পর্যটনশিল্প বিকাশলাভ করেছে। এই পর্যটনশিল্পকেন্দ্রগুলিকে অবস্থান
                অনুযায়ী তিনভাগে ভাগ করা যায়- [1] হিমালয় পার্বত্য অঞ্চলের
                পর্যটন কেন্দ্রসমূহ, [2] উত্তর ভারতের বৃহৎ সমভূমি অঞ্চলের পর্যটন
                কেন্দ্রসমূহ এবং [3] দাক্ষিণাত্যের মালভূমি এবং উপকূলবর্তী অঞ্চলের
                পর্যটন কেন্দ্রসমূহ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. হিমালয় পার্বত্য অঞ্চলের পর্যটন কেন্দ্রসমূহ : </b>{" "}
                </font>{" "}
                হিমালয় পার্বত্য অঞ্চলের পশ্চিমে জম্মু ও কাশ্মীর থেকে পূর্বে
                অরুণাচল প্রদেশ পর্যন্ত বহু পর্যটন কেন্দ্র গড়ে উঠেছে। জম্মু ও
                কাশ্মীরের শ্রীনগর, গুলমার্গ, পাহালগাম, সােনমার্গ এবং অমরনাথ
                ইত্যাদি উল্লেখযােগ্য পর্যটন কেন্দ্র। হিমাচল প্রদেশের সিমলা, কুলু
                মানালি; উত্তরাঞ্চলের হরিদ্বার, হৃষীকেশ, কেদারনাথ, বদরীনাথ,
                গঙ্গোত্রী, নৈনিতাল ইত্যাদি উল্লেখযােগ্য পর্যটন কেন্দ্র।
                পূর্বদিকে পশ্চিমবঙ্গের দার্জিলিং, মিরিক; সিকিমের গ্যাংটক;
                মেঘালয়ের শিলং; নাগাল্যান্ডের কোহিমা; মণিপুরের ইম্ফল; ত্রিপুরার
                আগরতলা; আসামের উপত্যকা অঞ্চল ইত্যাদি হল প্রধান প্রধান পর্যটন
                কেন্দ্র।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    2. উত্তর ভারতের বৃহৎ সমভূমি অঞ্চলের পর্যটন কেন্দ্রসমূহ :{" "}
                  </b>{" "}
                </font>{" "}
                পশ্চিমে রাজস্থান থেকে পূর্বে সুন্দরবন পর্যন্ত বিস্তৃত অঞ্চলের
                বিভিন্ন স্থানে পর্যটনশিল্পকেন্দ্রগুলি অবস্থিত। রাজস্থানের পর্যটন
                কেন্দ্রগুলি হল— জয়সলমির, যােধপুর, বিকানীর, জয়পুর। পাঞ্জাবের
                লুধিয়ানা, পাতিয়ালা, ভাটি হরিয়ানার আম্বালা, কুরুক্ষেত্র,
                পাণিপথ; দিল্লি; চণ্ডীগড়: উত্তরপ্রদেশের বৃন্দাবন, এলাহাবাদ,
                বারাণসী, লক্ষ্ণৌ, আগ্রা, আলিগড় ইত্যাদি অন্যতম পর্যটন কেন্দ্র।
                বিহারের পাটনা, নালন্দা, পশ্চিমবঙ্গের কলকাতা, সুন্দরবন,
                শান্তিনিকেতন ইত্যাদি উল্লেখযােগ্য পর্যটন কেন্দ্র।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    3. দাক্ষিণাত্যের মালভূমি এবং উপকূলবর্তী অঞ্চলের পর্যটন
                    কেন্দ্রসমূহ :{" "}
                  </b>{" "}
                </font>{" "}
                গুজরাতের ভাদোদরা, রাজকোট, জামনগর, সােমনাথ, দ্বারকা, গির অরণ্য;
                মহারাষ্ট্রের অজন্তা, ইলােরা, খালা, লােনাভলা, পুনে, নাগপুর,
                নাসিক, কোলাপুর উল্লেখযােগ্য পর্যটন কেন্দ্র। গােয়ার বিভিন্ন
                চার্চ, হেরিটেজ বাড়ি এবং সমুদ্র সৈকত; ওডিশার পুরী, ভুবনেশ্বর,
                কোনারক, কোরাপুট, উদয়গিরি; ঝাড়খণ্ডের রাঁচি, জামসেদপুর, দুমকা,
                ধানবাদ; ছত্তিশগড়ের বিলাসপুর, রায়পুর; মধ্যপ্রদেশের খাজুরাহ,
                সাঁচি, পাঁচমারি, অমরকণ্টক, চিত্রকুট ইত্যাদি মধ্যভারতের
                উল্লেখযােগ্য পর্যটন কেন্দ্র। অন্ধ্রপ্রদেশের হায়দ্রাবাদ,
                গােলকোণ্ড দুর্গ, শ্রীশৈলম; কর্ণাটকের মাইসাের, বিজাপুর,
                গুলবার্গা, শ্রীরঙ্গপত্তনম; তামিলনাড়ুর তিরুচিরাপল্লি, থাঞ্জাভুর,
                মাদুরাই, কাঞ্জিপুরম; কেরলের তিরুবনন্তপুরম, কোচি, পেরিয়ার,
                এর্ণাকুলাম ইত্যাদি হল দক্ষিণ ভারতের অন্যতম প্রধান পর্যটন
                কেন্দ্র। বঙ্গোপসাগরের আন্দামান ও নিকোবর দ্বীপপুঞ্জ এবং আরব
                সাগরের লাক্ষাদ্বীপ হল অন্যতম প্রধান পর্যটন কেন্দ্র।
                <br />
                <br />
                <font color="#29900a">
                  <b>পর্যটন শিল্পের গুরুত্ব : </b>{" "}
                </font>{" "}
                বর্তমান অর্থনৈতিক ব্যবস্থায় পর্যটন শিল্পের একাধিক গুরুত্ব
                রয়েছে। এর মধ্যে অন্যতম চারটি গুরুত্ব হল 一<br />
                <br />
                <font color="#f626d7">
                  <b>1. কর্মসংস্থান সৃষ্টি : </b>{" "}
                </font>{" "}
                পর্যটনশিল্পকে কেন্দ্র করে বহু মানুষের কাজের সুযােগ তৈরি হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. জাতীয় আয় বৃদ্ধি : </b>{" "}
                </font>{" "}
                কোনাে দেশ পর্যটন শিল্পে উন্নতি লাভ করলে সেই দেশের জাতীয় আয়
                বৃদ্ধি পায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. পরিবহণ ও যোগাযোগ ব্যবস্থার উন্নতি : </b>{" "}
                </font>{" "}
                পর্যটনশিল্পকে কেন্দ্র করে কোনাে একটা জায়গার পরিবহণ এবং
                যােগাযােগ ব্যবস্থার উন্নতি ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. পটনশিল্পকেন্দ্র অঞ্চলের উন্নতি : </b>{" "}
                </font>{" "}
                পর্যটনশিল্পকেন্দ্র গড়ে ওটার সাথে সাথে ঐ অঞ্চলের অর্থনৈতিক
                অবস্থার সামগ্রিক উন্নতি ঘটে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  3. পর্যটন বলতে কী বােঝ? পর্যটনশিল্প গড়ে ওঠার কারণ গুলি কী কী?
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>পর্যটন : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                অবকাশ যাপন , বিনোদন , ব্যাবসা বাণিজ্য , শিক্ষামূলক কর্মকাণ্ডের
                উদ্দেশ্যে মানুষ যখন অল্প সময়ের জন্য এক স্থান থেকে অন্যস্থানে
                ভ্রমণ করে এবং মানসিক আনন্দ লাভ করে , তাকেই পর্যটন বলে। এই
                পর্যটনের কারণে যখন কোনো স্থানে রাস্তাঘাটের উন্নয়ন , হোটেল ,
                রিসর্ট নির্মাণ বা প্রকৃতিকে উপভোগ করার জন্য নানা পরিকাঠামোর
                উন্নতি ঘটানো হয় , তখন তা পর্যটন শিল্প হিসেবে বিবেচনা করা যায়।
                <br />
                <br />
                পর্যটনের সাথে পর্যটকের যেমন উপকার হয় তেমনি সুষ্ঠু পর্যটন
                শিল্পের সাথে যুক্ত বহু মানুষের কর্মসংস্থান হয়। পশ্চিমবঙ্গ
                পর্যটন শিল্পে ভারতে প্রথম দশটি রাজ্যের মধ্যে অন্যতম।
                <br />
                <br />
                <font color="#29900a">
                  <b>পর্যটনশিল্প গড়ে ওঠার কারণসমূহ : </b>{" "}
                </font>{" "}
                বিভিন্ন প্রাকৃতিক এবং আর্থসামাজিক বিষয়গুলি কোনাে একটি অঞ্চলের
                পর্যটনশিল্প গড়ে ওঠার অন্যতম কারণ।
                <br />
                <br />
                <font color="#29900a">
                  <b>A. প্রাকৃতিক কারণ : </b>{" "}
                </font>{" "}
                যেসব প্রাকৃতিক কারণগুলি পর্যটনশিল্প গড়ে তুলতে সাহায্য করে
                সেগুলি হল 一<br />
                <br />
                <font color="#29900a">
                  <b>1. আবহাওয়া ও জলবায়ুগত বৈচিত্র্য : </b>{" "}
                </font>{" "}
                আবহাওয়া ও জলবায়ুগত বৈচিত্র্য পর্যটন কেন্দ্র গড়ে তুলতে সাহায্য
                করে। মনােরম জলবায়ুযুক্ত অঞ্চলে ভ্রমণের জন্য অধিক মানুষের সমাগম
                ঘটে। যেমনসুইটজারল্যান্ড তার মনােরম জলাবায়ুর জন্য পর্যটন শিল্পে
                উন্নত।
                <br />
                <br />
                <font color="#29900a">
                  <b>2. ভূমিরূপগত বৈচিত্র্য : </b>{" "}
                </font>{" "}
                ভূমিরূপগত বৈচিত্র্যও কোনাে জায়গায় পর্যটনশিল্প গড়ে তুলতে
                সাহায্য করে। ভারতের হিমালয় পর্বত অঞ্চলের বেশিরভাগ স্থানে
                ভূমিরূপের বিভিন্ন বৈচিত্র্যতার কারণে পর্যটনশিল্প গড়ে উঠেছে।
                <br />
                <br />
                <font color="#29900a">
                  <b>3. সমুদ্র, নদী ও হ্রদ তীরবর্তী অঞ্চল : </b>{" "}
                </font>{" "}
                সমুদ্র, নদী এবং হ্রদ তীরবর্তী অঞ্চলের প্রাকৃতিক সৌন্দর্য
                পর্যটনশিল্প গড়ে ওঠার অন্যতম কারণ। ভারতের গােয়াতে একারণেই
                পর্যটনশিল্প বিকাশলাভ করেছে।
                <br />
                <br />
                <font color="#29900a">
                  <b>B. আর্থসামাজিক কারণ : </b>{" "}
                </font>{" "}
                আর্থসামাজিক কারণগুলি হল 一<br />
                <font color="#29900a">
                  <b>1. ঐতিহাসিক কেন্দ্র : </b>{" "}
                </font>{" "}
                পৃথিবীর বহু স্থানের (যেমন- ভারতের আগ্রা) ঐতিহাসিক গুরুত্ব থাকায়
                সেখানে পর্যটনশিল্প বিকাশলাভ করেছে।
                <br />
                <br />
                <font color="#29900a">
                  <b>2. ধর্মীয় কারণ : </b>{" "}
                </font>{" "}
                পৃথিবীর বহু স্থান আছে যা ধর্মীয় কারণে অধিক গুরুত্বপূর্ণ (যেমন-
                ভারতের হরিদ্বার), সেইসব স্থানে ধর্মীয় কারণে পর্যটনশিল্প গড়ে
                উঠেছে।
                <br />
                <br />
                <font color="#29900a">
                  <b>3. শিক্ষা ও সাংস্কৃতিক কারণ : </b>{" "}
                </font>{" "}
                শিক্ষা ও সংস্কৃতিতে উন্নত পীঠস্থানগুলি (যেমন—পশ্চিমবঙ্গের
                শান্তিনিকেতন) পর্যটনশিল্প বিকাশ লাভ করেছে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  4. পরিবহণ ব্যবস্থা ও যােগাযােগ ব্যবস্থার মধ্যে পার্থক্য নিরূপণ
                  করাে। আধুনিক যােগাযােগ ব্যবস্থার গুরুত্ব আলােচনা করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>পরিবহণ ব্যবস্থা ও যােগাযােগ ব্যবস্থার মধ্যে পার্থক্য : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                <br />
                <table>
                  <colgroup>
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>উচ্চারণ</b>
                          </font>
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>উচ্চারণ</b>
                          </font>
                        </span>
                      </th>
                      <th scope="col">
                        <span>
                          <font color="#fff9f9">
                            <b>বঙ্গানুবাদ</b>
                          </font>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7"></font>
                        </span>
                      </td>
                      <td>
                        <span></span>
                      </td>
                      <td>
                        <span></span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7"></font>
                        </span>
                      </td>
                      <td>
                        <span></span>
                      </td>
                      <td>
                        <span></span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7"></font>
                        </span>
                      </td>
                      <td>
                        <span></span>
                      </td>
                      <td>
                        <span></span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <font color="#f626d7"></font>
                        </span>
                      </td>
                      <td>
                        <span></span>
                      </td>
                      <td>
                        <span></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <font color="#29900a">
                  <b>আধুনিক যোগাযোগ ব্যবস্থার গুরুত্ব : </b>
                </font>{" "}
                অতীতে যোগাযোগের মাধ্যম ছিল অতি ধীর। বর্তমানে বিজ্ঞান ও
                প্রযুক্তির অভাবনীয় উন্নতির ফলে যোগাযোগ ব্যবস্থায় এসেছে
                বৈপ্লবিক পরিবর্তন। তাই, আধুনিক যোগাযোগ ব্যবস্থায় সংযোজিত হয়েছে
                নানান পদ্ধতি। যন্ত্রনির্ভর ও পদ্ধতিনির্ভর আধুনিক যোগাযোগ
                ব্যবস্থার গুরুত্ব তাই বিশেষ উল্লেখযোগ্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(ক) ডাক ব্যবস্থার গুরুত্ব : </b>
                </font>{" "}
                <br />
                <br />
                (i) ডাক পরিসেবা সুষ্ঠুভাবে পরিচালনার জন্য ডাকঘরগুলিকে প্রধান
                ডাকঘর, উপ-ডাকঘর, অতিরিক্ত বিভাগীয় উপ-ডাকঘর ও অতিরিক্ত বিভাগীয়
                শাখা ডাকঘরে ভাগ করা হয়েছে।
                <br />
                (ii) ডাক পরিষেবা দ্রুত পরিচালনার জন্য ডাক বিভাগ PIN, QMS, স্পিড
                পোস্ট প্রভৃতি চালু করেছে।
                <br />
                (iii) ডাক পরিসেবার মাধ্যমগুলির মধ্যে পোস্টকার্ড, অন্তর্দেশীয়
                পত্র, এনভেলপ, টেলিগ্রাফ, বুকপোস্ট প্রভৃতি উল্লেখযোগ্য।
                <br />
                (iv) মহানগরগুলিতে বাণিজ্যিক ডাক পরিসেবা চালুহওয়ায় এর বাণিজ্যিক
                গুরুত্বও বহুগুণ বৃদ্ধি পেয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(খ) টেলিযোগাযোগ ও ইন্টারনেট ব্যবস্থার গুরুত্ব : </b>
                </font>{" "}
                <br />
                <br />
                (i) টেলিযোগাযোগ ব্যবস্থার আবিষ্কার ও উন্নতির ফলে পৃথিবীর বিভিন্ন
                দেশের যোগাযোগ ব্যবস্থায় বৈপ্লবিক পরিবর্তন এসেছে। দেশের যে-কোনো
                স্থানে সংবাদ পাঠাতে যেখানে সময় লাগত সপ্তাহ খানেকের ওপর, আজ তা
                মুহূর্তের মধ্যে সম্পন্ন হচ্ছে।
                <br />
                (ii) ইন্টারনেট ব্যবস্থা দূরকে টেনে এনেছে নিজের আঙিনায়। বিশাল
                পৃথিবীকে রূপান্তরিত করেছে বিশ্বপল্লীতে।
                <br />
                (iii) এই ব্যবস্থার মাধ্যমে পৃথিবীর যে-কোনো প্রান্তের সংবাদ
                মুহূর্তের মধ্যে অন্যপ্রান্তে পৌঁছে দেওয়া যায়।
                <br />
                (iv) এই ব্যবস্থায় কোনো সংবাদ বা তথ্য বিভিন্ন ওয়েবসাইটে সংরক্ষণ
                করা যায়। প্রয়োজন মতো ওই তথ্য পৃথিবীর যেকোনো স্থান থেকে সংগ্রহ
                করা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(গ) রেডিয়ো ও টেলিভিশনের গুরুত্ব : </b>
                </font>{" "}
                <br />
                <br />
                (i) রেডিয়ো ও টেলিভিশনের মাধ্যমে দেশবিদেশের শিক্ষা, শিল্প ও
                সংস্কৃতি, বিভিন্ন ঘটনাবলি, খেলাধুলা, বিভিন্ন রকমের সংবাদ,
                বিভিন্ন দ্রব্যের বাণিজ্যিক প্রচার, আবহাওয়া সংক্রান্ত সংবাদ
                প্রভৃতি যে-কোনো স্থানে পৌঁছে দেওয়া যায়।
                <br />
                (ii) জনসাধারণের মধ্যে সচেতনতা বৃদ্ধি, সংবাদ পরিবেশন, শিক্ষার
                প্রচার ও চিত্তবিনোদনে রেডিয়ো ও টেলিভিশন গুরুত্বপূর্ণ ভূমিকা
                পালন করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  5. যােগাযােগ ব্যবস্থা বলতে কী বােঝ? আধুনিক যােগাযােগ ব্যবস্থার
                  বিভিন্ন মাধ্যমগুলি সম্পর্কে সংক্ষেপে আলােচনা করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>পর্যটন : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সংবাদ বা তথ্য ও ভাবের আদানপ্রদানকে এককথায় যােগাযোেগ বলে।
                চিঠিপত্র, টেলিফোন, টেলিগ্রাম, ইনটারনেট প্রভৃতির সাহায্যে যে
                আদানপ্রদান হয়, সেগুলিকে বলে যােগাযােগের মাধ্যম। এই ধরনের একটি
                সম্পূর্ণ ও সংগঠিত আদানপ্রদান ব্যবস্থাকে বলে যােগাযােগ ব্যবস্থা।
                আগেকার দিনে যােগাযােগের মাধ্যম হিসেবে স্থলপথ, জলপথ ও আকাশপথে
                লিখিত বার্তা আদানপ্রদান করা হত। কিন্তু বর্তমানে ব্যস্ত জীবনের
                গতির সঙ্গে পাল্লা দিতে এবং উন্নত পরিসেবা প্রদানের লক্ষ্যে
                বিভিন্ন প্রকার আধুনিক যােগাযােগ ব্যবস্থার মাধ্যমে চালু হয়েছে।{" "}
                <br />
                <br />
                আধুনিক যােগাযােগ ব্যবস্থার বিভিন্ন মাধ্যম : আধুনিক যােগাযােগ
                ব্যবস্থার গুরুত্বপূর্ণ মাধ্যমগুলি হল- [1] ডাকব্যবস্থা, [2]
                সংবাদপত্র ও অন্যান্য মুদ্রিত মাধ্যম, [3] টেলিগ্রাফ ও টেলিগ্রাম,
                [4] টেলিফোন, [5] বেতারপদ্ধতি, [6] মােবাইল ও সেলুলার ফোন, [7]
                ইন্টারনেট ব্যবস্থা, [8] ভিডিও কনফারেন্সিং, [9] ফ্যাক্স, [1o]
                টেলিপ্রিন্টার বা টেলেক্স প্রভৃতি। নীচে এই মাধ্যমগুলি সম্পর্কে
                সংক্ষেপে আলােচনা করা হল一
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. ডাকব্যবস্থা : </b>{" "}
                </font>{" "}
                ডাকের মাধ্যমে সংবাদ আদানপ্রদান পৃথিবীর যােগাযােগ ব্যবস্থার একটি
                প্রাচীন মাধ্যম। খ্রিস্টপূর্ব 900 সালে এই ব্যবস্থার চিনে প্রথম
                সূচনা হয় ও ক্রমে সারা বিশ্বে বিস্তৃত হয়। ভারতে এই ব্যবস্থার
                সূচনাকাল 1766 খ্রিস্টাব্দে। অতীতে মানুষ ও বিভিন্ন পশুপাখির
                সাহায্যে ডাকের আদানপ্রদান করা হলেও, বর্তমানে আধুনিক পরিবহণ
                ব্যবস্থার দ্রুত মাধ্যমগুলির (রেলগাড়ি, বাস, প্রভৃতি) ব্যবহার করা
                হয়। আধুনিক ভারতের ডাক যােগাযােগ ব্যবস্থায় স্পিড পােস্ট,
                স্যাটেলাইট পােস্ট, এক্সপ্রেস পােস্ট, বিজনেস ও মিডিয়া পােস্ট
                প্রভৃতি নতুন নতুন পদ্ধতি চালু করা হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. সংবাদপত্র ও অন্যান্য মুদ্রিত মাধ্যম : </b>{" "}
                </font>{" "}
                বর্তমানে যােগাযােগের একটি গুরুত্বপূর্ণ মাধ্যম হল সংবাদপত্র ও
                বিভিন্ন প্রকার সাময়িকপত্র (যেমন—অর্ধসাপ্তাহিক, সাপ্তাহিক,
                মাসিক, ষান্মাসিক প্রভৃতি)। আধুনিক পরিভাষায় এই মুদ্রিত মাধ্যমকে
                বলে প্রেস ও প্রিন্ট মিডিয়া। স্থানীয়, আঞ্চলিক ও আন্তর্জাতিক
                স্তরে সর্বক্ষণ যেসব ঘটনা ঘটছে সেগুলির বিবরণ, বিশ্লেষণ,
                পরিসংখ্যান, ছবি ইত্যাদি সর্বস্তরের মানুষের কাছে পৌছে দেওয়ার
                ক্ষেত্রে এই মাধ্যমটি অসাধারণ ভূমিকা নেয়। বর্তমানে ভারতে
                রেজিস্ট্রিকৃত সাময়িকপত্র ও সংবাদপত্রের সংখ্যা প্রায় 60 হাজারের
                মতাে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. টেলিগ্রাফ ও টেলিগ্রাম : </b>{" "}
                </font>{" "}
                ইংরেজি Tele (টেলি) কথাটির বাংলা প্রতিশব্দ দূর (দূরে অবস্থিত)।
                বর্তমানে পৃথিবীতে যােগাযােগের ক্ষেত্রে বিভিন্ন প্রকার দূর সংযােগ
                ব্যবস্থা (টেলিফোন, টেলিগ্রাফ, টেলেক্স ইত্যাদি)-এর অভূতপূর্ব
                বিকাশ ঘটেছে। 1844 খ্রিস্টাব্দে স্যামুয়েল মাের্স প্রথম টেলিগ্রাফ
                পদ্ধতির আবিষ্কার করেন। তারের সাহায্যে যে বার্তা পাঠানাে হয় তাকে
                টেলিগ্রাফ বলে। এই ব্যবস্থায় পাঠানাে সাংকেতিক সংবাদকে মাের্স
                কোড-এর সাহায্যে নিমেষের মধ্যে পঠনযােগ্য ভাষায় পরিবর্তিত করা
                হয়। টেলিগ্রাফ যন্ত্রের মাধ্যমে পাঠানাে সংবাদকে টেলিগ্রাম বলে।
                বর্তমানে পৃথিবীর অধিকাংশ দেশে এর প্রচলন না থাকলেও বাহারিন,
                মেক্সিকো, সুইজারল্যান্ডের মতাে কয়েকটি দেশে এই পদ্ধতি সম্পূর্ণ
                বা আংশিকভাবে প্রচলিত রয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. টেলিফোন : </b>{" "}
                </font>{" "}
                টেলিফোন হল তারের সাহায্যে সংযুক্ত এমন একটি যােগাযােগ ব্যবস্থা
                যার দ্বারা দুজন মানুষ পৃথিবীর দুই প্রান্তে বা দূরদূরান্তে থাকলেও
                মুহূর্তের মধ্যে কথার মাধ্যমে পরস্পরের সঙ্গে যােগাযােগ। করতে
                পারে। আবার একটি প্রতিষ্ঠানের মধ্যেও অভ্যন্তরীণ যােগাযােগের
                ক্ষেত্রে টেলিফোন ব্যবহার করা যায়। একে ইন্টারকম (টেলিফোন পদ্ধতি
                বলে। এই টেলিফোন ব্যবস্থার আবিষ্কার করেন আলেকজান্ডার গ্রাহাম বেল
                1875 খ্রিস্টাব্দে। ভারতে প্রথম টেলিফোন পরিসেবা চালু হয় 1881-82
                সালে কলকাতায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. বেতারপদ্ধতি : </b>{" "}
                </font>{" "}
                এই ব্যবস্থায় রেডিয়াে স্টেশন বা বেতারকেন্দ্র থেকে প্রচারিত
                সংবাদসহ বিভিন্ন অনুষ্ঠান তড়িৎচুম্বকীয় তরঙ্গরূপে
                উর্ধ্বায়ুমণ্ডলের আয়নােস্ফিয়ারে প্রতিফলিত হয়ে রেডিয়াে বা
                বেতারযন্ত্রের মাধ্যমে শ্রোতাদের কানে পৌঁছায়। এটি তারবিহীন বলে
                যে-কোনাে স্থান, এমনকি মাঝ সমুদ্র, গভীর বন বা মরুভূমি ও দুর্গম
                পার্বত্যাঞ্চল থেকেও এর সাহায্যে যােগাযােগ করা যায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  6. পরিবহণ ব্যবস্থা বলতে কী বােঝায়? পরিবহণের গুরুত্ব আলােচনা
                  করাে। বন্দর সংযােজক পরিকল্পনা কী?
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>পরিবহণ ব্যবস্থা : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                আধুনিক সভ্যতার এক অপরিহার্য অঙ্গ হল পরিবহণ ব্যবস্থা।
                স্থানান্তরযােগ্য দ্রব্যসামগ্রী এবং যাত্রীকে এক স্থান থেকে অন্য
                স্থানে বহন করে নিয়ে যাওয়াকে পরিবহণ বলে। এর সাথে যুক্ত পথ,
                যানবাহন ও সংশ্লিষ্ট নিয়মকানুনকে একত্রে পরিবহণ ব্যবস্থা বলে।
                অধ্যাপক মার্শালের মতে, পরিবহণ কেবলমাত্র পণ্যসামগ্রী ও মানুষের
                স্থানান্তরেরই সাহায্য করে না, বরং মানুষের উন্নত সভ্যতার প্রতিটি
                পদেক্ষেপে গুরুত্বপূর্ণ ভূমিকা ও দায়িত্ব পালন করে।
                <br />
                <br />
                <font color="#29900a">
                  <b>পরিবহণের গুরুত্ব : </b>{" "}
                </font>{" "}
                আধুনিক অর্থনীতিতে পরিবহণের গুরুত্ব সর্বাধিক। গুরুত্বের প্রকৃতি
                অনুযায়ী পরিবহণের প্রভাবকে তিনভাগে ভাগ করা যায়- [1] অর্থনৈতিক
                গুরুত্ব, [2] রাজনৈতিক গুরুত্ব এবং [3] সামাজিক গুরুত্ব।
                <br />
                <br />
                <font color="#29900a">
                  <b>A. অর্থনৈতিক গুরুত্ব : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. কৃষির উন্নতি : </b>{" "}
                </font>{" "}
                কৃষিব্যবস্থার সম্প্রসারণ, কৃষির আধুনিকীকরণ, এবং কৃষিজাত পণ্যের
                বাজারজাতকরণের জন্য প্রয়ােজন হয় উন্নত পরিবহণ ব্যবস্থার
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. শিল্পের উন্নতি : </b>{" "}
                </font>{" "}
                শিল্পের অবস্থান নির্ণয়ে, কাঁচামাল আমদানি ও উৎপাদিত পণ্যকে
                বাজারে পাঠানাের জন্যও প্রয়ােজন উন্নত পরিবহণ ব্যবস্থার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. প্রাকৃতিক সম্পদ </b>{" "}
                </font>{" "}
                আহরণে সুবিধা : প্রাকৃতিক সম্পদগুলিকে তার উৎস অঞ্চল থেকে সংগ্রহ
                করতে পরিবহণ ব্যবস্থার প্রয়ােজন হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. কর্মসংস্থান সৃষ্টি : </b>{" "}
                </font>{" "}
                পরিবহণ ব্যবস্থার উন্নতি ঘটলে কৃষি, শিল্প ও বাণিজ্যের প্রসার ঘটে
                এবং সেইসমস্ত ক্ষেত্রে কর্মসংস্থান সৃষ্টি হয়। তা ছাড়া পরিবহণ
                মাধ্যমের সাথে যুক্ত হয়ে বহু মানুষের কর্মসংস্থান ঘটে।
                <br />
                <br />
                <font color="#29900a">
                  <b>B. রাজনৈতিক গুরুত্ব : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. প্রতিরক্ষা ক্ষেত্রে : </b>{" "}
                </font>{" "}
                দেশের সীমান্তবর্তী অঞ্চলে সৈন প্রেরণ, খাদ্য ও অস্ত্রশস্ত্র
                সরবরাহ প্রভৃতিতে পরিবহণ ব্যবস্থার গুরুত্ব সর্বাধিক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. ত্রাণ কাজে : </b>{" "}
                </font>{" "}
                প্রাকৃতিক বিপর্যয় এবং দুর্যোগপীড়িত অঞ্চলে দ্রুত ত্রাণ সামগ্রী
                পাঠাতে এবং উদ্ধারকাজ চালাতে উন্নত পরিবহণ ব্যবস্থার গুরুত্ব
                অপরিসীম।
                <br />
                <br />
                <font color="#29900a">
                  <b>C. সামাজিক গুরুত্ব : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. শিক্ষা ও সংস্কৃতির উন্নতি : </b>{" "}
                </font>{" "}
                কোনাে একটি দেশ বা অঞ্চলের শিক্ষা, জ্ঞান-বিজ্ঞান, কারিগরি ও
                প্রযুক্তিবিদ্যার উন্নতিতে পরিবহণ ব্যবস্থা গুরুত্বপূর্ণ ভূমিকা
                পালন করে। উন্নত পরিবহণ ব্যবস্থার মাধ্যমে এগুলির প্রসারও দ্রুত
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. নগরায়ণ : </b>{" "}
                </font>{" "}
                নগর গড়ে উঠতে এবং তার আয়তন বৃদ্ধিতে উন্নত পরিবহণ ব্যবস্থার
                দরকার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. আঞ্চলিক অনগ্রসরতা দূরীকরণ : </b>{" "}
                </font>{" "}
                অনগ্রসর অঞ্চলগুলিকে পরিবহণ ব্যবস্থার মাধ্যমে অগ্রসর অঞ্চলগুলির
                সাথে যুক্ত করে অনগ্রসরতা দূর করার চেষ্টা করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. জীবনযাত্রার মান উন্নয়নে : </b>{" "}
                </font>{" "}
                পরিবহণ ব্যবস্থার মাধ্যমে উন্নত অঞ্চলের মানুষের জীবনযাত্রা
                প্রণালী অনুন্নত অঞ্চলের মানুষের মধ্যে অভিযােজিত হয় এবং
                জীবনযাত্রা প্রণালীর পরিবর্তন ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>বন্দর সংযােজক পরিকল্পনা : </b>{" "}
                </font>{" "}
                ভারতের জাতীয় সড়কপথ সংস্থা (National Highway Authority of
                India) চার চ্যানেলবিশিষ্ট রাজপথ নির্মাণ ও ভারতের বিভিন্ন
                বন্দরগুলিকে রাজপথ দ্বারা সংযুক্ত করার পরিকল্পনা গ্রহণ করেছে।
                এটিই বন্দর সংযােজক পরিকল্পনা নামে পরিচিত। এই পরিকল্পনা অনুযায়ী
                হলদিয়া, পারাদীপ, বিশাখাপত্তনম, চেন্নাই, এন্নোর, তুতিকোরিন
                প্রভৃতি বন্দরগুলিকে রাজপথ দ্বারা যুক্ত করার কাজ চলেছে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  7. অভ্যন্তরীণ ও আন্তর্জাতিক বাণিজ্য বলতে কী বােঝ? আন্তর্জাতিক
                  বাণিজ্যের গুরুত্ব আলােচনা করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>আভ্যন্তরীণ বাণিজ্য ও আন্তর্জাতিক বাণিজ্য :</b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                <br />
                <br />
                <font color="#29900a">
                  <b>অভ্যন্তৱীণ বাণিজ্য : </b>{" "}
                </font>{" "}
                পণ্যদ্রব্য যখন দেশের সীমার মধ্যে বিভিন্ন অঞ্চলে ক্রয়বিক্রয় বা
                আদানপ্রদান করা হয়, তখন তাকে অভ্যন্তরীণ বাণিজ্য বা অন্তর্দেশীয়
                বাণিজ্য বলে। উদাহরণ— তামিলনাড়ুর কফি পশ্চিমবঙ্গে বিক্রি করা।
                <br />
                <br />
                <font color="#29900a">
                  <b>আন্তর্জাতিক বাণিজ্য : </b>{" "}
                </font>{" "}
                পণ্যসামগ্রী যখন এক দেশ থেকে অন্য দেশের মধ্যে আদানপ্রদান করা হয়,
                তখন তাকে আন্তর্জাতিক বা বৈদেশিক বা বহির্বাণিজ্য বলা হয়।
                উদাহরণ—দার্জিলিং-এর কমলালেবু বাংলাদেশে রপ্তানি করা অথবা ইরান,
                ইরাক, কুয়েত প্রভৃতি মধ্যপ্রাচ্যের খনিজ তেল ভারতে আমদানি করা।
                <br />
                <br />
                <font color="#29900a">
                  <b>আন্তর্জাতিক বাণিজ্যের গুরুত্ব : </b>{" "}
                </font>{" "}
                বিশ্বায়নের সঙ্গে সঙ্গে আন্তর্জাতিক বাণিজ্যের গুরুত্ব ক্রমাগত
                বৃদ্ধি পেয়েছে। নীচে এর গুরুত্বগুলি আলােচনা করা হল一
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. শিল্পোন্নয়ন : </b>{" "}
                </font>{" "}
                আন্তর্জাতিক বাণিজ্যের ফলে শিল্পোৎপাদন প্রচুর পরিমাণে বাড়ায়
                শিল্পে এসেছে জোয়ার। শিল্পোন্নত দেশগুলি তাদের উৎপাদিত দ্রব্য
                শিল্পে অনুন্নত দেশগুলিতে রপ্তানি করে। তা ছাড়া শিল্পে অনুন্নত
                দেশগুলি শিল্পের জন্য প্রয়ােজনীয় কাঁচামাল শিল্পোন্নত দেশগুলি
                থেকে আমদানি করে। যেমন—ভারত পরমাণু বিদ্যুৎকেন্দ্রের জন্য
                প্রয়ােজনীয় যন্ত্রপাতি এবং কাঁচামাল (যেমন-ইউরেনিয়াম) ফ্রান্স ও
                রাশিয়া থেকে আমদানি করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. সুসম্পর্ক স্থাপন : </b>{" "}
                </font>{" "}
                বাণিজ্যিক লেনদেন ঘটার ফলে দুটি দেশের মধ্যে সুসম্পর্ক স্থাপিত
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. দ্রব্য উৎপাদনে বিশেষীকরণ ও বাজার সৃষ্টি : </b>{" "}
                </font>{" "}
                আন্তর্জাতিক বাজারে বিশেষ বিশেষ দ্রব্যের চাহিদা থাকে। প্রাকৃতিক ও
                আর্থসামাজিক কারণে এক-একটি দেশ এক-একটি দ্রব্য উৎপাদনে দক্ষ হয়ে
                ওঠে এবং ওইসব দ্রব্যের গুণমান উৎকৃষ্ট হয়। ফলে ওই দ্রব্যের
                স্থানীয়, দেশীয় ও আন্তর্জাতিক বাজার সৃষ্টি হয়। যেমন—জাপান
                বৈদুতিন দ্রব্য উৎপাদনে সর্বশ্রেষ্ঠ, তাই দেশের জাতীয় পণ্যের
                বিশাল আন্তর্জাতিক বাজার তৈরি হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. সংস্কৃতির প্রসার : </b>{" "}
                </font>{" "}
                পণ্যদ্রব্য আমদানি-রপ্তানির সময় দেশ বিদেশের বহু লােকের সমাগম
                ঘটে। এর ফলে এক দেশের সংস্কৃতির অন্য দেশে প্রবেশ ঘটে এবং তা
                প্রসারিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. চাহিদা পূরণ : </b>{" "}
                </font>{" "}
                আন্তর্জাতিক বাণিজ্যে বিশ্বব্যাপী পণ্যদ্রব্যের আদানপ্রদান চলে।
                কোনাে দেশই সবধরনের পণ্য উৎপাদনে স্বয়ংসম্পূর্ণ হতে পারে না। তাই
                দ্রব্যের অভাবপূরণের জন্য বিভিন্ন দেশের মধ্যে বাণিজ্য চলে।
                যেমন—খনিজ তেলের অভাব থাকায় আমরা ইরান, ইরাক প্রভৃতি দেশ থেকে
                খনিজ তেল আমদানি করি। আবার ওইসব দেশ আমাদের দেশ থেকে খাদ্যশস্য
                আমদানি করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. কর্মসংস্থানের সুযোগ বৃদ্ধি : </b>{" "}
                </font>{" "}
                বাণিজ্যের প্রসার দেশবিদেশে কাজের সুযােগ বৃদ্ধি করে। বাণিজ্যের
                প্রসার পরােক্ষভাবে কৃষি ও শিল্পের উন্নতি ঘটায়। পণ্যসামগ্রীর
                আদানপ্রদানের জন্য পরিবহণ ব্যবস্থার উন্নতি ঘটে। বাণিজ্য-সংক্রান্ত
                বিভিন্ন ধরনের অফিসের কাজের ক্ষেত্র বিস্তৃত হয়। তাই কৃষি, শিল্প,
                পরিবহণ ও অন্যান্য সেবাক্ষেত্রে কর্মসংস্থানের সুযােগ সৃষ্টি হয়।
                বর্তমানে কম্পিউটার তথা ইন্টারনেটের যুগে সাইবার কাফেগুলিতে প্রচুর
                কর্মী কাজ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. স্বনির্ভরতা লাভ : </b>{" "}
                </font>{" "}
                আন্তর্জাতিক বাণিজ্যের প্রসার ঘটার ফলে কোনাে দেশ কৃষি, শিল্প,
                বিজ্ঞান ও প্রযুক্তিতে স্বনির্ভরতা অর্জন করে। যেমন—ভারতে যে সবুজ
                বিপ্লব ঘটেছিল তার প্রযুক্তি জাপান থেকে আমদানি করা হয়েছিল।
                বর্তমানে ভারত কৃষি উৎপাদনে স্বনির্ভর হয়েছে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

তৃতীয়স্তরেরক্রিয়াকলাপ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(তৃতীয়স্তরেরক্রিয়াকলাপ);
