import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function Networking() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          Networking | West Bengal Class 12 Guide | West Bengal HS Suggestion |
          Higher Secondary Guide | WBCHSE | WB
        </title>
        <meta
          name="description"
          content="Networking | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Networking
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>FTP-এর পূর্ণরূপ কী?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  File Transfer Protocal
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  File Transfer Port
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  File Topology Protocal
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  File Transport Port
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Image insert-এর জন্য কোন্ Tag ব্যবহার করা হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  &lt;IMG SRC&gt;
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  &lt;B&gt;
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  &lt;IMAGE SEARCH&gt;
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  &lt;Underline&gt;
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>দুটি নেটওয়ার্ককে যুক্ত করতে কোন্ যন্ত্র ব্যবহার করা হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Rouler
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Hub
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Switch
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Bridge
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Token Ring কোন্ Topology-তে ব্যবহার করা হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Bus Topology
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Ring Topology
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Star Topology
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Mesh Topology
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>LAN-এর সর্বোচ্চ ডেটা Transfer-এর হার কত?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>1 Mbps
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  10 Mbps
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  100 Mbps
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1000 Mbps
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Data Transmission mode কে কয়টি ভাগে ভাগ করা যায়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>1
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>2
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>3
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>4
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>TCP/IP-তে কতগুলি স্তর থাকে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>4
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>5
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>6
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>7
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নীচের কোন্‌টি Empty tag?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  &lt;B&gt;
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  &lt;U&gt;
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  &lt;H1&gt;
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  &lt;Br&gt;
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>HR Tag কী কাজে ব্যবহার করা হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ছবি বিয়োজন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উল্লম্ব Line
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অনুভূমিক Line
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ছবি সংযোজন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>IP Address কত বিটের হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  16 bit
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  32 bit
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  64 bit
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সবকয়টি
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b>Chapter ➺ 2</b>
              </font>{" "}
              <br />
              <font color="#2b9a09" size="4">
                <b>Networking</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. Communication channel কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                Network-এর ব্যবহৃত বিভিন্ন প্রকার Signal যেগুলি ডেটা পরিবহনের
                জন্য অতি প্রয়োজনীয় তাকে Communication channel বলে। <br />{" "}
                <br />
                Communication channel-কে সাধারণত দুটি ভাগে ভাগ করা যায়। <br />
                (i) Analog Signal <br />
                (ii) Digital Signal
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. Analog Signal বলতে কী বোঝ?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                Sine wave-এর মাধ্যমে প্রকাশিত কম bandwidth-এর যে Signal-এর
                মাধ্যমে ধারাবাহিকভাবে Data Transfer হয় তাকে Analog
                communication বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. Digital Signal বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                'O' এবং ‘1’-এর মাধ্যমে প্রকাশিত বেশি bandwidth-এর যে signal-এর
                মাধ্যমে বিচ্ছিন্নভাবে Data Transfer হয় তাকে Digital Signal বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. নেটওয়ার্কিং কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                একাধিক Computer এবং Network device নিজেদের মধ্যে বিভিন্ন মাধ্যম
                ব্যবহার করে যুক্ত হয়ে তথ্য আদানপ্রদান করে যে মাধ্যমের সাহায্যে
                তাকে নেটওয়ার্কিং বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. Data Transmission media বলতে কী বোঝ?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                Network এর মধ্যে অবস্থিত বিভিন্ন যন্ত্রাংশগুলি নিজেদের মধ্যে
                যোগাযোগ স্থাপনের জন্য যে পরিবহনের মাধ্যম স্থাপন করে তাকে Data
                Transmission Media বলে।
                <br />
                Data Transmission Media কে প্রধানত দুটি ভাগে ভাগ করা যায়।
                <br />
                (i) Guided media <br />
                (ii) Unguided media.
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. Node কাকে বলে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                Network-এর সাথে যুক্ত থাকা প্রতিটি Computer এবং Network device
                গুলিকে Node বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. Transmission mode কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                Network-এ অবস্থিত sender এবং receiver-এর মধ্যে Data
                Transmission-এর দিক নির্দেশ করার পদ্ধতিকে Transmission mode বলে।{" "}
                <br />
                Transmission mode-কে সাধারণত তিনটি ভাগে ভাগ করা যায়।
                <br />
                (i) Simplex Transmission
                <br />
                (ii) Half Duplex Transmission
                <br />
                (iii) Full Duplex Transmission
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. Full Duplex Transmission কাকে বলে?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যে Transmission mode-এ তথ্য উভয়দিকে একইসময়ে পরিবাহিত হয়
                অর্থাৎ sender, receiver-কে এবং receiver, sender-কে একই সময়ে
                তথ্য পরিবহন করতে সক্ষম তাকে Full Duplex বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>9. Network ব্যবহারের সুবিধাগুলি লেখো।</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                (i) সহজে এবং সুরক্ষিতভাবে ডেটা Transmission করা যায়।
                <br />
                (ii) নেটওয়ার্ক ব্যবহারের খরচ খুবই কম।
                <br />
                (iii) নেটওয়ার্কের সঙ্গে যুক্ত বিভিন্ন সম্পদগুলি প্রতিটি
                Computer ব্যবহার করতে পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    10. Network কতটা অঞ্চলে বিস্তৃত তার উপর ভিত্তি করে Network
                    কে কয়টি ভাগে ভাগ করা যায় ও কী কী?
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রধানত Network কে তিনটি ভাগে ভাগ করা যায়। <br />
                (i) LAN (Local Area Network) <br />
                (ii) MAN (Metropolitan Area Network) <br />
                (iii) WAN (Wide Area Network).
                <br />
                <br />
                <font color="#f626d7">
                  <b>11. Twisted Pair Cable এর বৈশিষ্ট্য লেখো।</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                (i) দুটি তামার তার প্যাঁচানো অবস্থায় থাকে, <br />
                (ii) ডেটা ট্রান্সফারের গতি 100 mbps পর্যন্ত হতে পারে, <br />
                (iii) Telephone এবং Internet Network স্থাপনের জন্য ব্যবহার করা
                হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>12. Co-axial Cable এর বৈশিষ্ট্য লেখো।</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                (i) একাধিক স্তরের মাধ্যমে এই Cable গঠিত হয়,
                <br />
                (ii) স্তরগুলি যথাক্রমে Outer jacket, Aluminium mesh, Insulator,
                Central core cable.
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. Simplex Transmission কাকে বলে?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যে Transmission mode-এ তথ্য একমুখে পরিবাহিত হয় অর্থাৎ sender
                সবসময় receiver-কে তথ্য পাঠায় তাকে Simplex Transmission বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. Half Duplex Transmission কাকে বলে?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যে Transmission mode-এ তথ্য উভয়দিকে পরিবাহিত হয় কিন্তু একই
                সময়ে হয় না অর্থাৎ sender, receiver-কে এবং receiver, sender-কে
                একই সময়ে তথ্য পরিবহন করতে পারে না তাকে Half duplex Transmission
                বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. MAN বলতে কী বোঝ?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                সর্বাধিক একটি শহরের মধ্যে সীমাবদ্ধ যে নেটওয়ার্কের মাধ্যমে
                একাধিক Computer এবং Network device যুক্ত করা যায় তাকে MAN বলে।
                সাধারণত Fiber Optical Cable এবং Co-axial cable এই Network স্থাপন
                করতে ব্যবহার করা হয়।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>16. WAN বলতে কী বোঝ?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                বিশ্বব্যাপী বিস্তৃত যে নেটওয়ার্কের মাধ্যমে সমস্ত Network device
                যুক্ত করা যায় তাকে WAN বলে। সাধারণত Optical Fiber Cable এই
                Network স্থাপন করতে ব্যবহার করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. Network Architechture কাকে বলে?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                Network এ অবস্থিত Client এবং Server এর অবস্থান এবং কাজের পদ্ধতির
                উপর নির্ভর করে Network এর যে সার্বিক গঠন তাকে Network
                Architechture বলে।
                <br />
                Network Architechture কে দুটি ভাগে ভাগ করা যায়।
                <br />
                (i) Client Server
                <br />
                (ii) Peer to peer.
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. LAN বলতে কী বোঝ?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                সর্বাধিক একটি বিল্ডিংয়ের মধ্যে সীমাবদ্ধ যে নেটওয়ার্কের মাধ্যমে
                একাধিক Computer এবং Network device কে যুক্ত করা যায় তাকে LAN
                বলে। সাধারণত Twisted Pair Cable এই Network স্থাপন করতে ব্যবহার
                করা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>19. Client Server Architechture কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যে Network Architechture এর একটি উচ্চ ক্ষমতাসম্পন্ন Computer এর
                সঙ্গে অন্যান্য Client Computer গুলি যুক্ত থাকে যেগুলি তুলনামূলক
                কম ক্ষমতাসম্পন্ন হয় এবং সমস্ত কাজের জন্য উচ্চ ক্ষমতাসম্পন্ন
                Computer অর্থাৎ Server Computer কে ব্যবহার করে তাকে Client
                Server Architechture বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20 Radio wave এর বৈশিষ্ট্য লেখো।</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                (i) বৃহৎ এলাকায় Data Transmission এর জন্য radiowave ব্যবহার করা
                হয়, <br />
                (ii) 3KHz থেকে 3THz এর মধ্যে কম্পাঙ্ক থাকে, <br />
                (iii) বিভিন্ন Radio Signal Transmission এর জন্য এটি ব্যবহার করা
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>21. Infrared wave এর বৈশিষ্ট্য লেখো। </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                (i) উচ্চ গতিসম্পন্ন তথ্য কম দূরত্বে Transmission এর জন্য
                Infrared wave ব্যবহার করা হয়, <br />
                (ii) সাধারণত একটি ঘরের মধ্যে এই wave সীমাবদ্ধ থাকে, <br />
                (iii) Remote এর সাহায্যে TV, AC প্রভৃতি ব্যবহার করতে এই তরঙ্গ
                ব্যবহার করা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>22. Serial Transmission বলতে কী বোঝ?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                একটিমাত্র Communication Channel এর মাধ্যমে তথ্য পরিবহন করার
                পদ্ধতি যার মাধ্যমে ধারাবাহিকভাবে তথ্য পাঠানো যায় তাকে Serial
                Transmission বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. Peer to peer Architechture কাকে বলে?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                যে Network Architechture এ প্রতিটি Computer একই ক্ষমতাসম্পন্ন
                হয় এবং নিজেদের মধ্যে তথ্য আদানপ্রদান করতে পারে তাকে Peer to
                Peer Architechtue বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. Guided media কাকে বলে?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                তুলনামূলক কম দূরত্বে তথ্য পরিবহনের জন্য Cable বা তারের মাধ্যমে
                তথ্য পাঠানোর পদ্ধতিকে Guided media বলে। <br />
                Guided media কে তিনটি ভাগে ভাগ করা যায়।
                <br />
                (i) Twisted Pair Cable <br />
                (ii) Co-axial Cable <br />
                (iii) Fiber Optical Cable.
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

Networking.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(Networking);
