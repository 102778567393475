import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function স্বাস্থ্যওরোগ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          স্বাস্থ্য ও রোগ | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="স্বাস্থ্য ও রোগ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                স্বাস্থ্য ও রোগ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সহজাত অনাক্রম্যতা গড়ে ওঠে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  T-cell দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  নিউট্রোফিল দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  B-cell দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যান্টিবডি দ্বারা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কার মাধ্যমে রসনির্ভর অনাক্রম্যতা সম্পন্ন হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  প্লাজমা কোশ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাইটোটক্সিক T-কোশ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইওসিনোফিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নিউট্রোফিল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সেরিব্রাল ম্যালেরিয়া হয় যার দ্বারা -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Plasmodium vivax
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Plasmodium malariae
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Plasmodium falciparum
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Plasmodium ovale
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>প্রাথমিক অনাক্রম্যতায় নীচের কোন্ Ig উৎপন্ন হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  IgM
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  IgE
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  IgA
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  IgG
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  নীচের কোন্ টিউমারটি দেহে উৎপত্তিস্থলেই সীমাবদ্ধ থাকে ও দেহের
                  অন্যত্র ছড়িয়ে পড়ে না?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্যানসার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ম্যালিগন্যান্ট টিউমার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বিনাইন টিউমার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মেটাস্টেসিস
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  নীচের কোন্ মনুষ্য পরজীবীগুলি মশার মাধ্যমে তাদের জীবনচক্র
                  সম্পন্ন করে?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Leishmania donovani ও Plasmodium ovale
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  Wuchereria bancrofti ও Plasmodium ovale
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Ascaris lumbricoldes ও Wuchereria bancrofti
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  Ascaris lumbricoides Luishmonia donovani
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>এর মধ্যে কোন্‌টি ভাইরাস ঘটিত রোগ? </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  টাইফয়েড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডিপথেরিয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পোলিও
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  টিউবারকিউলোসিস
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নিচের কোন্ গ্রন্থিটি সাধারণত AIDS-এর সঙ্গে সম্পর্কিত?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  থাইরয়েড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  থাইমাস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পিটুইটারি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাডরেনাল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নীচের কোন্ জোড়টি ভাইরাস সংক্রমিত রোগ?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আমাশয়—সাধারণ ঠান্ডা লাগা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সাধারণ ঠান্ডা লাগা— AIDS
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  টাইফয়েড—যক্ষ্মা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রিংওয়ার্ম — AIDS
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  পোকা কামড়ানোর ফলে কোনো অংশের প্রদাহের হাত থেকে যে রাসায়নিক
                  পদার্থের দ্বারা উপশন পাওয়া যায়, তা হল -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হিস্টামিন এবং কিনিন দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইন্টারফেরন এবং অপসোনিন দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হিস্টামিন এবং ডোপামিন দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইন্টারফেরন এবং হিস্টোন দ্বারা
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>নবম অধ্যায় - স্বাস্থ্য ও ব্যাধি বা রোগসমূহ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. কোন্ দিনটিতে বিশ্বস্বাস্থ্য দিবস পালিত হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                7 এপ্রিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    2. যে সমস্ত প্রাণী পরজীবী বহন করে এবং বিস্তার ঘটায় তাদের কী
                    বলে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বাহক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. মাঝারি বিয়ারে অ্যালকোহলের পরিমাণ কত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                5-7% <br />
                <br />
                <font color="#f626d7">
                  <b>4. স্বাস্থ্য কীসের বিপরীত অবস্থা?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রোগের।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. একটি বংশগত রোগের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                হিমোফিলিয়া।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. ফুসফুসের ক্যানসারের জন্য দায়ী অঙ্কোজিনটি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                C-myc <br />
                <br />
                <font color="#f626d7">
                  <b>7. বিনাইন টিউমার ক্যানসারের কোন পর্যায়ে দেখা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                প্রাথমিক অবস্থায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. মদ রাসায়নিক ভাষায় কী নামে পরিচিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ইথাইল অ্যালকোহল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. ডিপসোম্যানিয়া দেখা যায় কোন্ ধরনের অ্যালকোহলিজমে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                এপসিলন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. একটি সিডেটিভ ও একটি ট্রাংকুলাইজারের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সিডেটিভ—বারবিচুরেট। ট্রাংকুলাইজার—ভ্যালিয়াম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>11. আমাশয় রোগসৃষ্টিকারী আদ্যপ্রাণীটির নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                Entamoeba histolytical
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. সিফিলিস রোগসৃষ্টিকারী ব্যাকটেরিয়ার নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                Treponema pallidum <br />
                <br />
                <font color="#f626d7">
                  <b>13. ইয়োলো ফিভার কোন্ অঞ্চলের মানুষদের হয়ে থাকে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আফ্রিকার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    14. লোফার্স সিনড্রোম নামক এই বিশেষ উপসর্গটি কোন্ রোগে দেখা
                    যায়?{" "}
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অ্যাসকেরিয়েসিস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. ক্যানসার সৃষ্টিকারী ভাইরাসকে কী বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অঙ্কোভাইরাস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. আমাশয় নিবারণকারী একটি ওষুধের উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                Tinidazole
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. লোবার নিউমোনিয়াতে দেহের কোন্ অঙ্গ আক্রান্ত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ফুসফুসের লোব বা খণ্ডক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. AIDS রোগের ইনকিউবেশন পিরিয়ড কত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                2-3 বছর এমনকি 10 বছর পর্যন্ত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    19. একটি এন্ডোজেনাস ও একটি এক্সোজেনাস রোগের বাহকের নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                এন্ডোজেনাস—সিরাম প্রোটিন। এক্সোজেনাস—অ্যালারজেন (রেণু, পরাগ)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. ম্যালেরিয়ার রোগ জীবাণু বহনকারী প্রাণী কোন্‌টি?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                স্ত্রী অ্যানোফিলিস মশা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>21. মশা কি বাহক না ভেক্টর?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভেক্টর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. Plasmodium vivax কী ধরনের ম্যালেরিয়ার সৃষ্টি করে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বিনাইন টার্নিয়ান ম্যালেরিয়া (Benign Tertian Malaria)
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. এলিফ্যান্‌টিয়েসিস কোন্ রোগের অপর নাম?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ফাইলেরিয়েসিস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    24. সাধারণত কমন কোল্ড ডাইরাস ঘটিত হলেও, যে একধরনের
                    ব্যাকটেরিয়ার ফলে হয় তার নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                Dialister pneumosintes
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. প্রতিদিন কতবার খাদ্যগ্রহণ করা দরকার?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                দিনে 4-6 বার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. আর্টারিওস্ক্লেরোসিস কী ধরনের রোগ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ডিজেনারেটিভ বা ক্ষয়িষ্ণু।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    27. প্রাকৃতিক দুর্যোগপূর্ণ স্থানে (ঝড়, বন্যা, ভূমিকম্প)
                    মানুষ সহজেই কোন জাতীয় রোগে আক্রান্ত হয়ে পড়ে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                এপিডেমিক বা মড়ক রোগে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. শিশুদের রোজ কত ঘণ্টা ঘুমানো উচিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                12-13 ঘণ্টা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. প্যানডেমিক কী প্রকার রোগ? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>{" "}
                যেসব রোগ দেশব্যাপী বিস্তার লাভ করে সেই রোগকে বলে প্যানডেমিক।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. প্যাথোজেন কীভাবে রোগ সৃষ্টি করে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                প্যাথোজেন দু-ভাবে রোগ সৃষ্টি করে -<br />
                (i) কলা ধ্বংস করে—যেমন, রেবিস ভাইরাস মস্তিষ্কের কলা ধ্বংস করে।
                <br />
                (ii) অনেক প্যাথোজেন শক্তিশালী বিষ বা টক্সিন ক্ষরণ করে। এই টক্সিন
                রোগ সৃষ্টির একটি কারণ। এক্সোটক্সিন ডিপথেরিয়া, টিটেনাস ইত্যাদি
                রোগ সৃষ্টি করে এবং এন্ডোটক্সিন আমাশয়, কলেরা ইত্যাদি রোগ সৃষ্টি
                করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. মেটাস্ট্যাসিস কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                {" "}
                ম্যালিগন্যান্ট কোশগুলি তাদের উৎসস্থল থেকে বিচ্ছিন্ন হয়ে রক্ত ও
                নালিকার মাধ্যমে বাহিত হয়ে সারাদেহে ছড়িয়ে পড়ে যা বিভিন্ন হয়ে
                টিউমার সৃষ্টি করতে থাকে। ম্যালিগন্যান্ট কোশের এরকম ছড়িয়ে পড়া
                প্রবণতাকে মেটাস্ট্যাসিস বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. হ্যাপটেন কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                নিম্ন আণবিক ভর বিশিষ্ট যে সকল অপ্রোটিনধর্মী পদার্থ নিজে
                অ্যান্টিবডি সংশ্লেষকে উদ্দীপিত করতে পারে না অথচ কোনো বৃহৎ বাহক
                প্রোটিন অণুর সঙ্গে যুক্ত হয়ে অ্যান্টিবডি তৈরির মাধ্যমে
                অনাক্রম্য সাড়ার সৃষ্টি করতে পারে তাকে হ্যাপটেন বা অসম্পূর্ণ
                অ্যান্টিজেন বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>4. ইন্টারফেরণ কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                জীবদেহে ভাইরাস আক্রান্ত সজীবকোশে উৎপন্ন যে সাইটোকাইনিন ধর্মী
                প্রোটিন পার্শ্ববর্তী অন্যান্য কোশে ছড়িয়ে পড়ে সেই সকল কোশে ওই
                নির্দিষ্ট ভাইরাসের প্রতিলিপিকরণকে বাধা দান করে, তাকে ইন্টারফেরণ
                বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. কেমোথেরাপি (Chemotherapy) বলতে কী বোঝো?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                কয়েক প্রকার ক্যানসার প্রতিরোধক ওষুধ হিসেবে ব্যবহার করা হয়। এইসব
                ওষুধ DNA সংশ্লেষে বাধা সৃষ্টি করে কোশ বিভাজন বন্ধ হয় দেয়।
                কেমোথেরাপির সাহায্যে ক্যানসার কোশের মৃত্যু ঘটানো হয় কেমোথেরাপির
                কয়েকটি পার্শ্বপ্রতিক্রিয়া হল –চুল উঠে যাওয়া।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. এপিটোপ বা অ্যান্টিজেন ডিটারমিনেন্ট কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                অ্যান্টিজেনের যে অংশ অ্যান্টিবডি অথবা T-লিম্ফোসাইটের গাত্র
                দ্বারা চিহ্নিত বা আবদ্ধ হয় তাকে এপিটোপ বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. অটোইমিউনিটি কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সাধারণত জিনগত ত্রুটির ফলে দেহের অনাক্রম্যতা যখন অস্বাভাবিকভাবে
                নিজ দেহের সুস্থ কোশ ও অণুগুলিকে আক্রমণ করে ও তাদের ধ্বংস করে,
                তখন তাকে অটোইমিউনিটি বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>উদাহরণ : </b>
                </font>{" "}
                মায়েসথেনিয়া গ্রেডিন্স, রিউমাটয়েড আর্থরাইটিস।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>8. লিম্ফয়েড অঙ্গ কাকে বলে? এর প্রকারভেদ উদাহরণসহ লেখ।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                দেহে অনাক্রম্যতন্ত্রের অন্তর্গত যে সকল অঙ্গে অনাক্রম্যতার সঙ্গে
                সম্পর্কিত বিভিন্ন লিম্ফোসাইটের উৎপত্তি, পরিণতি, সংখ্যাবৃদ্ধি ও
                সঞ্চয় হয়, তাকে লিম্ফয়েড অঙ্গ বলে। লিম্ফয়েড অঙ্গ দুই প্রকার —
                <br />
                <br />
                <font color="#f626d7">
                  <b>(i) প্রাথমিক লিম্ফয়েড অঙ্গ : </b>
                </font>{" "}
                দেহ অনাক্রম্যতন্ত্রের অন্তর্গত যে অঙ্গে লিম্ফোসাইটের উৎপত্তি ও
                পরিণতি ঘটে, তাকে প্রাথমিক লিম্ফয়েড অঙ্গ বলে। যেমন — অস্থিমজ্জা,
                থাইমাস গ্রন্থি প্রভৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(ii) গৌণ লিম্ফয়েড অঙ্গ : </b>
                </font>{" "}
                দেহ অনাক্রম্যতন্ত্রের অন্তর্গত যে অঙ্গ B-লিম্ফোসাইট ও
                T-লিম্ফোসাইট সঞ্ঝিত থেকে অ্যান্টিজেনের উপস্থিতিতে দ্রুত তাদের
                সংখ্যা বৃদ্ধি পায়, সেই অঙ্গকে গৌণ লিম্ফয়েড বলে।
                <br />
                <br />
                যেমন — লসিকা গ্রন্থি, প্লিহা, Cutaneous Associated Lymphoid
                Tissue (CALT), Mucosa Associated Lymphoid Tissue (MALT)।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

স্বাস্থ্যওরোগ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(স্বাস্থ্যওরোগ);
