import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function ব্যতিক্রমধর্মীশিশুরশিক্ষা() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          ব্যতিক্রমধর্মী শিশুর শিক্ষা | West Bengal Class 12 Guide | West Bengal
          HS Suggestion
        </title>
        <meta
          name="description"
          content="ব্যতিক্রমধর্মী শিশুর শিক্ষা | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                ব্যতিক্রমধর্মী শিশুর শিক্ষা
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>যাদের শ্রুতিশক্তি 80 ডেসিবেলের বেশি তাদের বলা হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এর কোনোটিই নয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সম্পূর্ণ বধির
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আংশিক বধির
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অতিমাত্রায় বধির
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ব্রেইল পদ্ধতি চালু হয় –</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1829 খ্রিঃ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1810খ্রি:
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1820 খ্রি:
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1830 খ্রিঃ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মূক ও বধিরদের জন্য মৌখিক পদ্ধতির প্রবর্তন করেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  জুয়ান পাবলো বনে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কেটি অ্যালকর্ণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সোফিয়া অ্যালকর্ণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লুইস ব্রেইল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Mental Map কাদের শিক্ষার জন্য ব্যবহার করা হয়?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মূকদের জন্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  দৃষ্টিহীনদের জন্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মানসিকভাবে ক্ষতিগ্রস্থদের জন্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বধিরদের জন্য
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ব্রেইল পড়ার নিয়ম হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বাম থেকে ডানদিকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডান ও বাম উভয়দিকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডান থেকে বামদিকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মূক ও বধির শিশুদের পাঠক্রমের অন্তর্গত একটি বিষয় হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্রেইলপাঠ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সংগীত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হস্তশিল্প
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সবগুলি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ব্রেইল পদ্ধতি চালু হয় - </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1810খ্রিঃ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1829 খ্রিঃ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1820 খ্রঃ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1830 খ্রিঃ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ব্যতিক্রমী শিশুদের শিক্ষার প্রয়োজন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কারিগরী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাধারণ ধর্মী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বিশেষ ধর্মী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বৃত্তিমুখী
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অন্য শিশুদের শিক্ষার একটি উদ্দেশ্য হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হাঁটতে শেখানো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হীনমন্যতা দূর করা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিশেষ বিষয় শেখানো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>শ্রবণ প্রতিবন্ধী শিশুর শিক্ষাপদ্ধতি হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ওষ্ঠ পঠন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শব্দনির্ভর পদ্ধতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্রেইল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সক্রিয়তাভিত্তিক পদ্ধতি
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>নবম অধ্যায় - ব্যতিক্রমধর্মী শিশুদের শিক্ষা</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. মায়োপিয়া কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যে ব্যাধির কারণে ব্যক্তির দূরের দৃষ্টি ব্যাহত হয়, সেই রোগকে
                মায়োপিয়া বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. CWSN-এর পুরো নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                CWSN-এর পুরো নাম- Children with Special Needs.
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. ব্রেইলে ক-টি বিন্দুথাকে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ব্রেইলে 6 টি বিন্দু থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. Tooking Book কাদের জন্য ব্যবহার করা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                Tooking Book দৃষ্টিহীনদের শিক্ষার জন্য ব্যবহার করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. হাইপার মেট্রোপিয়া কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যে রোগে নিকটের দৃষ্টি ব্যাহত হয়, তাকে হাইপার মেট্রোপিয়া বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. বর্তমানে ব্যতিক্রমী শিশুরা কী নামে পরিচিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বর্তমানে ব্যতিক্রমী শিশুরা Differently abled children নামে
                পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. ব্রেইল পদ্ধতিতে কতগুলি বিন্দু দিয়ে লেখা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ব্রেইল পদ্ধতিতে 6টি ডট বা বিন্দু দিয়ে লেখা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. বর্তমানে ব্যতিক্রমী শিশুরা কী নামে পরিচিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বর্তমানে ব্যতিক্রমী শিশুরা 'Challenged Children বা Children with
                special Need' নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. স্টাইলাস কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ব্রেইল লেখার জন্য ব্যবহৃত বিশেষ ধরনের কলমকে স্টাইলাস বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. মূক ও বধির শিশুদের শিক্ষার দুটি উদ্দেশ্য লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মূক ও বধির শিশুদের শিক্ষার দুটি উদ্দেশ্য হল ভাষার বিকাশ ঘটানো
                এবং আত্মবিশ্বাস বৃদ্ধি করো।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. বধির কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যেসকল শিশু বা ব্যক্তি শ্রবণযন্ত্রের ত্রুটির জন্য কিছু শুনতে পায়
                না, তাদের বধির বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    12. WHO সমীক্ষা অনুযায়ী পৃথিবীর মোট জনসংখ্যার কত অংশ
                    প্রতিবন্ধী?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                WHO সমীক্ষা অনুযায়ী পৃথিবীর মোট জনসংখ্যার 10 শতাংশ প্রতিবন্ধী।{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. OPH এর পুরো নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                OPH-এর পুরো নাম হল-Orthopaedic Physically Handicapped.
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    14. লুইস ব্রেইল কত খ্রিস্টাব্দে ব্রেইল পদ্ধতির প্রচলন করেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                1829 খ্রিস্টাব্দে লুইস ব্রেইল ব্রেইল পদ্ধতির প্রচলন করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. While Cane Stick কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অন্ধ ব্যক্তিরা যে সাদা লাঠির সহায়তায় হাঁটাচলা করে তাকেই White
                Cane Stick
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    16. ভারতবর্ষে কে প্রথম দৃষ্টিহীনদের জন্য বিদ্যালয় স্থাপন
                    করেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                Miss Annie Sharp নামে একজন খ্রিস্টান মিশনারি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    17. শ্রবণে অক্ষমতার গভীরতার পরিমাপ মাপা হয় কীসের দ্বারা?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                শ্রবণে অক্ষমতার গভীরতার পরিমাণ মাপা হয় অডিয়োমিটার যন্ত্রের
                দ্বারা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    18. আমাদের দেশে কত সালে শিক্ষার অধিকার আইন (Right to
                    Education Act) চালু হয়েছে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                2010 খ্রিস্টাব্দের 1 এপ্রিল থেকে আমাদের দেশে শিক্ষার অধিকার আইন
                চালু হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    19. শারীরিক বা দৈহিক প্রতিবন্ধী শিশুদের দুটি শ্রেণিবিভাগ
                    করো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                শারীরিক বা দৈহিক প্রতিবন্ধী শিশুদের দুটি শ্রেণিবিভাগ হল —
                জ্ঞানেন্দ্রিয় ত্রুটিসম্পন্ন প্রতিবন্ধী এবং বাগযন্ত্রের
                ত্রুটিসম্পন্ন প্রতিবন্ধী।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. ব্রেইলের স্টাইলাস কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ব্রেইল ব্যবস্থায় পুরু কার্ডবোর্ড বা কাগজের ওপর যে শক্ত জিনিস
                দিয়ে উঁচু উঁচু ডট বা বিন্দু দেওয়া হয়, তাকে স্টাইলাস বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  21. আমাদের দেশে প্রতি বছর কোন দিনটি প্রতিবন্ধী দিবস হিসেবে
                  পালিত হয়?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতে প্রতি বছর 15 মার্চ প্রতিবন্ধী দিবস হিসেবে পালিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    22. রাষ্ট্রসংঘ কোন্ বছরকে ‘প্রতিবন্ধী বর্ষ' হিসেবে পালনের
                    নির্দেশ দেয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাষ্ট্রসংঘ 1981 খ্রিস্টাব্দকে প্রতিবন্ধী বর্ষ হিসেবে পালনের
                নির্দেশ দেয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. গ্লুকোমা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                গ্লুকোমা হল এমন একপ্রকার চোখের রোগ যার কারণে ব্যক্তি সম্পূর্ণ
                অন্ধ হয়ে পড়ে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. মায়োপিয়া কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যে রোগে দূরের দৃষ্টি ব্যাহত হয়, তাকে মায়োপিয়া বলে৷
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. শ্রেণিকক্ষের দুটি আচরণগত সমস্যা উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                শ্রেণিকক্ষের দুটি আচরণগত সমস্যা হল — চুরি করা, স্কুল পালানো।{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. ADHD?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ADHD-এর সম্পূর্ণ নাম - Attention Deficit Hyperactivity Disorder।
                এটি এক ধরনের মানসিক প্রতিকূলতার রোগ। এই রোগে আক্রান্ত ব্যক্তিরা
                কোনো কাজে সঠিকভাবে মনোনিবেশ করতে পারে না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. অন্ধ শিশুদের শিক্ষার দুটি সমস্যা লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অন্ধ শিশুদের শিক্ষার সমস্যা - (i) এই সকল শিশু হীনমন্যতায় ভোগে।
                নিজেদেরকে গুটিয়ে রাখে এবং সমাজের বোঝা বলে মনে করা হয়;
                <br />
                (ii) এই শিশুদের শিক্ষাদানের জন্য যে অর্থের প্রয়োজন সেই অর্থ
                সরকারের পক্ষ থেকে দেওয়া হয় না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. অন্ধ শিশুদের শিক্ষার দুটি উদ্দেশ্য লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                অন্ধ শিশুদের শিক্ষার উদ্দেশ্য হল – (i) হীনমন্যতা দূর করে
                আত্মবিশ্বাস জাগিয়ে তোলা। <br />
                (ii) চোখ ছাড়া অন্যান্য ইন্দ্রিয়ের মাধ্যমে শিক্ষার ব্যবস্থা
                করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. ব্রেইল পাঠের নিয়ম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ব্রেইল হল একটি স্পর্শমূলক পদ্ধতি। ব্রেইল বামদিক থেকে ডানদিকে
                পড়তে হয়। একজন দক্ষ ব্রেইল পাঠক প্রতি মিনিটে 60 টি শব্দ পড়তে
                পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. অন্ধ শিশুদের একটি শিক্ষা পদ্ধতির নাম লেখো। </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ব্রেইল হল অন্ধদের একটি শিক্ষা পদ্ধতি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>31. আন্তর্জাতিক স্তরে দৃষ্টিহীন কাদের বলা হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যে ব্যক্তির দৃষ্টিশক্তি 1/20 ভাগের কম বা যে ব্যক্তি দেড় মিনিট
                দূরত্ব থেকেও আঙুল গুণতে পারে না, তাকে আন্তর্জাতিক স্তরে
                দৃষ্টিহীন বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    32. দৈহিক প্রতিবন্ধী শিশুদের শিক্ষার যে-কোনো দুটি সমস্যা
                    লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                দৈহিক প্রতিবন্ধী শিশুদের শিক্ষায় সমস্যা - (i) শিক্ষার্থীরা
                হীনমন্যতায় ভোগে, (ii) অধিকাংশ ক্ষেত্রে অভিভাকদের উদাসীনতা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    33. লোয়েনফেল্ড দৃষ্টিহীনদের কয় ভাগে ভাগ করেছেন ও কী কী?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                লোয়েনফেল্ড দৃষ্টিহীনদের চার ভাগে ভাগ করেছেন। যথা —(i)
                জন্মগতভাবে সম্পূর্ণ দৃষ্টিহীন, (ii) পাঁচ বছর পর সম্পূর্ণ
                দৃষ্টিহীন, (iii) জন্মগতভাবে দৃষ্টিহীন, (iv) অর্জিত আংশিক
                দৃষ্টিহীন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    34. ভারতে অন্ধদের জন্য প্রথম বিদ্যালয় কোথায় প্রতিষ্ঠিত
                    হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মিশনারিরা সর্বপ্রথম 1883 খ্রিস্টাব্দে অমৃতসর শহরে দৃষ্টিহীনদের
                জন্য একটি শিক্ষা প্রতিষ্ঠান স্থাপন করেন, 1897 খ্রিস্টাব্দে একটি
                দেরাদুনের রায়পুরে স্থানান্তরিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. রিসোর্স কক্ষ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বধির শিক্ষার্থীদের শিক্ষার উদ্দেশ্যে বিদ্যালয়ে বিভিন্ন শিক্ষার
                উপকরণ দিয়ে তৈরি একটি প্রতিবন্ধী কক্ষ। যেখানে শিক্ষার্থীরা
                বিশেষজ্ঞ ব্যক্তিদের দ্বারা বিশেষ পদ্ধতিতে শিক্ষা গ্রহণ করে থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>36. ব্রেইল কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                দৃষ্টিহীনদের পড়ানোর জন্য লুইস ব্রেইল 1829 খ্রিস্টাব্দে এক বিশেষ
                প্রকার স্পর্শ পদ্ধতি আবিষ্কার করেন, তাই হল ব্রেইল পদ্ধতি৷
                বর্তমানে বাংলা ভাষায় উন্নত মানের ব্রেইল লেখা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. মিথ্যা কথা বলার দুটি কারণ লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                এর মিথ্যা কথা বলার কারণ হল -<br />
                (i) নিরাপত্তার অভাববোধ, হীনমন্যতারবোধ, (ii) শাস্তির ভয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. সহপাঠীকে উৎপীড়নে কারণ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                শিক্ষার্থীরা অনেক সময় অবহেলিত হওয়ার জন্য অন্যকেও তারা সহ্য
                করতে পারে না, বিরক্ত করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. ইনক্লুসিভ এডুকেশন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                জাতি, ধর্ম, বর্ণ, ভাষা, অক্ষমতা, প্রতিবন্ধকতা নির্বিশেষে সকলের
                জন্য Regular Mainstrem School-এর শিক্ষা ব্যবস্থাকে ইনক্লুসিভ
                এডুকেশন বা অন্তর্ভুক্তিমূলক শিক্ষা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    40. দৃষ্টিহীনদের শিক্ষার জন্য ভারত সরকারের যে কোনো একটি
                    পদক্ষেপ উল্লেখ করো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                দৃষ্টিহীনদের শিক্ষার জন্য ভারত সরকারের একটি উল্লেখযোগ্য পদক্ষেপ
                হল অন্ধদের শিক্ষাকেন্দ্রগুলিতে ব্রেইল সরবরাহের জন্য অন্ধদের
                সুবিধার্থে জাতীয় গ্রন্থাগার স্থাপন করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    41. ব্যতিক্রমী শিশু বা Exceptional Children কাদের বলা হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যে সকল শিশুরা শারীরিক, মানসিক, সামাজিক ও প্রাক্ষোভিক ত্রুটির
                কারণে সমাজে সাধারণ মানুষের থেকে পৃথক এবং তাদের ব্যক্তিসত্তার
                পরিপূর্ণ বিকাশ সাধনের জন্য বিশেষ ধরনের শিক্ষাব্যবস্থা প্রয়োজন
                হয় তাদেরকে বলা হয় ব্যতিক্রমী শিশু বা Exceptional Children.
                <br />
                <br />
                <font color="#f626d7">
                  <b>42. ব্যাহত, অক্ষমতা ও প্রতিবন্ধী বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ব্যাহত বলতে মূলত শারীরিক গঠন, ইন্দ্রিয়গত বা পেশিগত ত্রুটির
                কারণে কোনো ব্যক্তি যদি প্রাত্যহিক জীবনের নানান কাজের অসুবিধায়
                পড়ে, তখন তাকে অক্ষম হিসেবে গণ্য অক্ষমতার কারণে যদি কোনো ব্যক্তি
                পরিবেশের সঙ্গে স্বাভাবিক কাজকর্মের ক্ষেত্রে অনতিক্রম্য বাধার
                মুখে পড়ে, তখন তাকে প্রতিবন্ধী বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. মূক ও বধির শিশুদের শিক্ষাদানের পদ্ধতিগুলি আলোচনা করো।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যেসব শিশুরা কথা বলতে পারে না ও শুনতে পায় না তাদের মুক বলে।
                অন্যদিকে যে ব্যক্তি শ্রবণযন্ত্রের সহায়তায় বা সহায়তা ভিন্ন 70
                ডেসিবেল এর শব্দ শুনতে পায় না, তাদেরকে বধির বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. মৌখিক পদ্ধতি বা ওষ্ঠপঠন : </b>{" "}
                </font>{" "}
                এই পদ্ধতির প্রবর্তক হলেন জুয়ান প্যাবলো বনে। মুখের নড়াচড়া,
                ঠোঁট নড়ার কৌশল প্রভৃতি দেখে মুখ ও বধির বা বক্তার কথা বুঝতে
                পারে। পরে একইভাবে ঠোটের সঞ্চালন দেখে তারা ভাষা শিখে থাকে। এই
                পদ্ধতির আর এক নাম মৌখিক পদ্ধতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. কম্পন ও স্পর্শ পদ্ধতি : </b>{" "}
                </font>{" "}
                এই পদ্ধতিতে মূক ও বধির ছেলেমেয়েরা অভিজ্ঞ শিক্ষকের কথা বলার সময়
                তাদের মুখে হাত বুলিয়ে, গলা স্পর্শ করে শব্দ উচ্চারণ করতে শেখে।
                কম্পন ও স্পর্শ পদ্ধতিটি আবিষ্কার করেন কেটি অ্যালকন ও সোফিয়া
                অ্যালকন|
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. সঞ্চালন মূলক পদ্ধতি : </b>{" "}
                </font>{" "}
                এই পদ্ধতির প্রবর্তক পিরিয়ার। আঙুলের সঞ্চালনের দ্বারা এখানে মনের
                ভাব প্রকাশক হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. দর্শন নির্ভর পদ্ধতি : </b>{" "}
                </font>{" "}
                মূক ও বধির ছেলেমেয়েদের দর্শনভিত্তিক পদ্ধতিতেও মনের ভাব প্রকাশের
                প্রশিক্ষণ দেওয়া হয়। এতে কয়েকটি প্রতীক ব্যবহার করা হয়। শিক্ষক
                মুক ও বধির ছেলেমেয়েদের এইসব প্রতীকের ব্যবহার শিখিয়ে দেন। তারা
                আয়নার সামনে দাঁড়িয়ে শিক্ষকের মতো মুখভঙ্গি করে শব্দ উচ্চারণ
                করার চেষ্টা করে এবং ধীরে ধীরে তা আয়ত্ত করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. শ্ৰবণ সহায়ক যন্ত্রভিত্তিক পদ্ধতি : </b>{" "}
                </font>{" "}
                বর্তমানে অত্যন্ত সংবেদনশীল শ্রবণ সহায়কযন্ত্র তৈরি হয়েছে, যার
                সাহায্যে শিক্ষাদান করা হয়। এটি সহজ ও বহুলপ্রচারিত পদ্ধতি। এই
                পদ্ধতি বলা হয় শ্রবণ সহায়ক যন্ত্রভিত্তিক পদ্ধতি বা Hearing aid
                Method.
                <br />
                <br />
                এই পদ্ধতিগুলি অত্যন্ত জটিল। দক্ষ প্রশিক্ষক ছাড়া মূক ও বধির
                শিক্ষার্থীদের শেখানো অত্যন্ত কঠিন। তাছাড়া যথাযথ ভাবে আয়ত্ব
                করতে হলে শিক্ষার্থীদের দীর্ঘদিন অনুশীলন করা প্রয়োজন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. ব্রেইল পদ্ধতি সম্পর্কে সংক্ষেপে লেখো।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                অন্ধ শিশুদের জন্য স্পর্শভিত্তিক পাঠের একটি পদ্ধতি হল ‘ব্রেইল’,
                প্যারিসের অন্ধ বিদ্যালয়ের ছাত্র লুইস ব্রেইল 1829 খ্রিঃ আবিষ্কার
                করেন ব্রেইল পদ্ধতি। 1950 খ্রিস্টাব্দে UNESCO পৃথিবীর বিভিন্ন
                ভাষায় ব্রেইল প্রবর্তনের ব্যবস্থা গ্রহণ করেন।
                <br />
                <br />
                <font color="#1f7405">
                  <b>গঠন : </b>{" "}
                </font>{" "}
                ব্রেইলের গঠন হল আয়তাকার শক্ত কাগজ দিয়ে পুরু কাগজের ওপর ছটি
                উঁচু নিচু বিন্দুকে বিভিন্ন ভাবে বিন্যস্তকরে a. b,c, d প্রভৃতি
                বর্ণ লেখা হয়। এছাড়া স্টাইলাস দিয়ে চাপ দিয়ে নীচে রাখা শক্ত
                কাগজ চাপের ফলে গর্ত হয়ে যায়।
                <br />
                <br />
                <font color="#1f7405">
                  <b>ব্রেইল পাঠের পদ্ধতি : </b>{" "}
                </font>{" "}
                এই স্পর্শ পদ্ধতি স্টাইলাসের সাহায্যে লেখা হয়। সাধারণত বাম থেকে
                ডান দিকে ব্রেইল পাঠ করতে হয়। একজন দক্ষ ব্রেইল পাঠক প্রতিমিনিটে
                সর্বাধিক 60 টি শব্দ পড়তে পারে। শিক্ষার্থীরা ব্রেইল পড়ার পর
                ব্রেইল লেখা শেখানো হয়। বর্তমানে দ্রুত লেখার জন্য টাইপ মেশিন ও
                কম্পিউটারের সাহায্য নেওয়া হয়। ব্রেইল পদ্ধতির বাংলা সংস্করণ
                উদ্ভাবন করেন কলকাতা ব্লাইন্ড স্কুলের প্রতিষ্ঠাতা লালবিহারী শাহ।
                <br />
                <br />
                <font color="#1f7405">
                  <b>অসুবিধা : </b>{" "}
                </font>{" "}
                খুব ভারী এবং আয়তনে বড়ো হওয়ায় শিক্ষার্থীদের অসুবিধা হয়।
                এছাড়া ব্যয়বহুল ও দক্ষ শিক্ষকের অভাব আছে। <br />
                <br />
                বর্তমানে ব্রেইল আরো ছোটো আকারে ব্যবহারিত হচ্ছে যা ব্রেইল সেট
                নামে পরিচিত। এইভাবে এই পদ্ধতিতে আরো সহজ ও কম খরচের ব্যবস্থা
                গ্রহণের চেষ্টা চলছে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. প্রতিবন্ধী শিশুদের শিক্ষার কয়েকটি প্রয়োজনীয়তা লেখো।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতীয় সংবিধানে জাতি, ধর্ম, বর্ণ, লিঙ্গ নির্বিশেষে সকলকে
                শিক্ষার অধিকার দেওয়া হয়েছে। প্রতিবন্ধীরা যেহেতু সামাজিক,
                দৈহিক, মানসিক প্রভৃতি দিক থেকে অন্য শিশুদের থেকে পিছিয়ে তাই
                তাদের শিক্ষার প্রয়োজন আরো অনেক বেশি। নিম্নে এদের শিক্ষার
                প্রয়োজনীয়তার কারণগুলি আলোচনা করা হল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. আত্মবিশ্বাস জাগ্রত করা : </b>
                </font>{" "}
                প্রতিবন্ধী শিশুদের আত্মবিশ্বাস-এর অভাব থাকবে এটাই স্বাভাবিক।
                শিক্ষার মাধ্যমেই এদের আত্মবিশ্বাস জাগ্রত করা সম্ভব।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. অভিযোজনে সহায়তা করা : </b>
                </font>{" "}
                এই শিশুরা বিভিন্ন পরিবেশে অভিযোজন না করতে পারার জন্য এড়িয়ে
                চলার মনোভাব লক্ষ্য করা যায়। এই সমস্যা দূর করার জন্য উপযুক্ত
                শিক্ষার প্রয়োজন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. বৃত্তিমূলক প্রশিক্ষণ : </b>
                </font>{" "}
                প্রতিবন্ধীদের দায়িত্ব সবাই নিতে চায় না। একমাত্র শিক্ষা এবং
                বৃত্তিমূলক প্রশিক্ষণের মাধ্যমে শিক্ষার্থীরা জীবিকা অর্জন করতে
                পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. হীনমন্যতা দূরীকরণ : </b>
                </font>{" "}
                প্রতিবন্ধী শিক্ষার্থীরা সর্বদা হীনমন্যতায় সম্রাট ভোগে। একমাত্র
                শিক্ষার মাধ্যমেই এই বাধা দূর করা সম্ভব।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. প্রাক্ষোভিক চাহিদা পূরণে সহায়তা : </b>
                </font>{" "}
                শারীরিক ত্রুটির জন্য এই শিক্ষার্থীরা অস্বাভাবিক আচরণ করে। তাই এই
                অস্বাভাবিক প্রক্ষোভ নিয়ন্ত্রণের জন্য শিক্ষার প্রয়োজন।
                <br />
                <br />
                সুতরাং প্রতিবন্ধী শিশুরা যাতে সমাজের বোঝা না হয়ে দাঁড়ায় তার
                জন্য একান্ত শিক্ষার প্রয়োজন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>4. দৃষ্টিহীন শিশুদের শিক্ষার প্রধান উদ্দেশ্যগুলি কী কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সাধারণত দৃষ্টিহীন আমরা তাদেরকেই বলি যে কোনো কিছুই স্পষ্ট দেখতে
                পায় না, বা বড়ো হরফে লেখা পড়তে পারে, এরা চিরকালই সমাজের দয়ার
                পাত্র। কিন্তু উপযুক্ত নির্দেশনা ও সুযোগসুবিধা প্রাপ্তির মাধ্যমেও
                সমাজের দায়বদ্ধতা সহযোগিতা ও ভালোবাসায় এদেরকেও সমাজের মূল
                স্রোতে নিয়ে আসা সম্ভব। <br />
                <br />
                <font color="#1f7405">
                  <b>দৃষ্টিহীন শিশুদের শিক্ষার উদ্দেশ্য : </b>{" "}
                </font>{" "}
                <br />
                <br />
                1. দৃষ্টিহীন শিশুরা হীনমন্যতায় ভোগে। তাদের নিজেদের প্রতি
                আত্মবিশ্বাস বাড়ানোর জন্য শিক্ষাদান করা উচিত।
                <br />
                2. পরিবর্তনশীল সমাজের সঙ্গে সংগতি বিধানের জন্য এদের শিক্ষার
                একান্ত প্রয়োজন।
                <br />
                3. দৃষ্টিহীন শিক্ষার্থী বিকাশমূলক শিক্ষা বাধাপ্রাপ্ত হলেও অন্য
                ইন্দ্রিয় ব্যবহারমূলক প্রশিক্ষণ তাদের একান্ত প্রয়োজন হলে তাদের
                কর্মদক্ষতা অনেকাংশে বৃদ্ধি পায়।
                <br />
                4. দৃষ্টিহীন শিশুরা দৈনন্দিন কাজে অপারক। তাই শিক্ষার উদ্দেশ্য
                হবে তাদের মধ্যে দৈনন্দিন জীবনযাপনের কাজের দক্ষতা বৃদ্ধি করা।
                <br />
                5. দৃষ্টিহীন শিশুদের শিক্ষার উদ্দেশ্য হবে সমাজ সচেতনতা বৃদ্ধি
                করা। তারা যে সমাজে বসবাস করে সেই সমাজে তাদের গ্রহণযোগ্যতা রয়েছে
                এই বোধ জাগ্রত করা।
                <br />
                দৃষ্টিহীন শিক্ষার্থীদের বিভিন্ন চাহিদা, মানসিকতা, শিক্ষার
                উদ্দেশ্যের কথা মাথায় রেখে তাদের শিক্ষার পাঠ্যক্রম গঠন করতে হবে,
                যা তাদের সুঅভ্যাস গঠনে, আত্মবিশ্বাস গঠনে, দৈহিক ও মানসিক সামাজিক
                বিকাশে সহায়তা করবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  5. শ্রেণিকক্ষের আচরণগত সমস্যা হিসেবে মিথ্যা কথা বলা ও চুরিকরার
                  প্রতিকারের উপায়সমূহ লেখো।
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b>মিথ্যা কথা বলা ও চুরি করার প্রতিকারের উপায়সমূহ : </b>{" "}
                </font>{" "}
                শিক্ষার্থীর মিথ্যা কথা বলা কিংবা চুরি করার প্রবৃত্তির যদি
                শুরুতেই প্রতিকার করা না যায়, তবে তার পরবর্তী জীবনে তা
                সমাজবিরোধী আচরণে পর্যবসিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b>মিথ্যাবলার প্রতিকারের উপায় : </b>{" "}
                </font>{" "}
                মিথ্যার আশ্রয় গ্রহণ করলেসাময়িকভাবে শিশুর চাহিদার পূরণ হলেও
                অন্তর্দ্বন্দ্বের স্থায়ী সমাধান হয় না। উপরন্তু সাময়িক
                চাহিদাপূরণের জন্য তার মিথ্যা বলার মাত্রা ক্রমশ বৃদ্ধি পায়। এর
                ফলে মিথ্যা বলা তার ব্যক্তিত্বের একটা স্থায়ী বৈশিষ্ট্য হয়ে ওঠে।
                শিশুর বিশ্বাসযোগ্যতা হ্রাস পায়, অপসংগতির মাত্রা বৃদ্ধি পায় এবং
                তার ব্যক্তিগত চরিত্রের অবনতি ঘটে। সে ক্রমশ সমাজবিরোধী আচরণে
                লিপ্ত হয়। এই কারণে প্রথম থেকেই মিথ্যা কথা বলার প্রতিকারের উপায়
                গ্রহণ করা আবশ্যক। এর জন্য প্রয়োজন হল –<br />
                <br />
                <font color="#f626d7">
                  <b>1. মৌলিক চাহিদাপূরণ : </b>{" "}
                </font>{" "}
                যতদূর সম্ভব শিশুর মৌলিক চাহিদাপূরণ করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. গুরুজন ব্যক্তির মিথ্যা বলা থেকে বিরত থাকা : </b>{" "}
                </font>{" "}
                শিক্ষক ও বাবা-মা যেন শিশুর সম্মুখে মিথ্যা কথা বলা থেকে বিরত
                থাকেন সেদিকে বিশেষ নজর দিতে হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. মার্জনা করা : </b>{" "}
                </font>{" "}
                অপরাধ স্বীকার করলে মার্জনা করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. দলগত কাজ : </b>{" "}
                </font>{" "}
                শিক্ষার্থীর সামর্থ্য অনুযায়ী দলগত কাজের মধ্য দিয়ে শিশুর
                আত্মস্বীকৃতির চাহিদাপূরণ করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. পরামর্শদান : </b>{" "}
                </font>{" "}
                ব্যক্তিগতভাবে পরামর্শদানের ব্যবস্থা করা প্রয়োজন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. মনোচিকিৎসকের সাহায্যগ্রহণ : </b>{" "}
                </font>{" "}
                পরিবেশ পরিবর্তন এবং মনোচিকিৎসকের সাহায্যগ্রহণ করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b>চুরি করার প্রতিকারের উপায় : </b>{" "}
                </font>{" "}
                শিশুর চৌর্য আচরণ যত শীঘ্র সম্ভব প্রতিকার করা প্রয়োজন। অন্যথায়
                শিশুর এই ধরনের আচরণ অভ্যাসে রূপ নিতে পারে, যার ফলে পরবর্তী কালে
                সে দাগী চোর বা অন্যান্য অপরাধমূলক আচরণের শিকার হয়। শিশুর
                এইজাতীয় আচরণ প্রতিকারের জন্য করণীয় হল -<br />
                <br />
                <font color="#f626d7">
                  <b>1. চুরির কারণ অনুসন্ধান : </b>{" "}
                </font>{" "}
                কী কারণে শিশুরা এই ধরনের আচরণে লিপ্ত হয়েছে, তা জানা প্রয়োজন
                এবং সেই অনুযায়ী ব্যবস্থা গ্রহণ করা প্রয়োজন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. বাবা-মায়ের সহানুভূতি : </b>{" "}
                </font>{" "}
                শিক্ষক ও বাবা-মা শিশুর প্রতি সহানুভূতিশীল হবেন এবং নীতিবোধ গড়ে
                তুলতে সচেষ্ট হবেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. নিয়ন্ত্রিতভাবে চাহিদাপূরণ : </b>{" "}
                </font>{" "}
                বাবা-মা সাধ্যমতো শিশুর চাহিদাপূরণ করবেন, তবে শিশুর চাহিদা মাত্র
                সবকিছু সরবরাহ করবেন না। চাহিদা যে সবসময় পূরণ হয় না, সেই
                পরিস্থিতির সঙ্গে শিশুকে সচেতন করা প্রয়োজন, অন্যথায় শিশু
                চাহিদামতো কিছু না পেলেই চুরি করতে প্রবৃত্ত হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. সঠিক যৌন শিক্ষা : </b>{" "}
                </font>{" "}
                বিদ্যালয়ে যৌন শিক্ষার ব্যবস্থা, অবসর বিনোদনের সুযোগ প্রভৃতি
                এইজাতীয় সমস্যামূলক আচরণ দূর করতে সহায়ক হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. পরামর্শদান প্রয়োজন : </b>{" "}
                </font>{" "}
                শিক্ষার্থীকে ব্যক্তিগতভাবে পরামর্শদানের ব্যবস্থা করা প্রয়োজন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. মনোচিকিৎসকেরসাহায্য : </b>{" "}
                </font>{" "}
                সমস্যার গুরুত্ব অনুযায়ী মনোচিকিৎসকের সাহায্য গ্রহণ করা উচিত।
                প্রসঙ্গত উল্লেখ করা যায়, কোনোরকম প্রয়োজন ছাড়াও কোনো কোনো
                ব্যক্তি চুরি করে। যেমন – উচ্চবিত্ত ঘরের কোনো শিক্ষার্থী একাধিক
                ঘড়ি থাকা সত্ত্বেও কধুর বাড়ি গিয়ে ঘড়ি চুরি করে। এই ধরনের
                চুরির অভ্যাসকে 'ক্লিপ্টোম্যানিয়া' (Clyptomania) বলে। এর
                প্রতিকারের জন্য মনোচিকিৎসকের সাহায্য নেওয়া একান্ত অপরিহার্য।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

ব্যতিক্রমধর্মীশিশুরশিক্ষা.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(ব্যতিক্রমধর্মীশিশুরশিক্ষা);
