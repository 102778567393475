import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function ঊনবিংশওবিংশশতকেঔপনিবেশিকতাবাদওসাম্রাজ্যবাদেরপ্রসার() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          ঊনবিংশ ও বিংশ শতকে ঔপনিবেশিকতাবাদ ও সাম্রাজ্যবাদের প্রসার | West
          Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="ঊনবিংশ ও বিংশ শতকে ঔপনিবেশিকতাবাদ ও সাম্রাজ্যবাদের প্রসার | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                ঊনবিংশ ও বিংশ শতকে ঔপনিবেশিকতাবাদ ও সাম্রাজ্যবাদের প্রসার
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মার্কেন্টাইলবাদী অর্থনীতি ছিল — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জাতীয় স্বার্থের পরিপন্থী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উদার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সংরক্ষণবাদী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিশ্ব বাণিজ্যের সমর্থক
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মাদ্রাজে ফোর্ট সেন্ট জর্জ দুর্গ নির্মাণ করেছিল — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফরাসিরা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পোর্তুগিজরা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ইংরেজরা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ওলন্দাজরা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>রেড ইন্ডিয়ানরা বসবাস করত -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইংল্যান্ডে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আমেরিকায়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভারতে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্রাজিলে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>আমেরিকায় ব্রিটিশ উপনিবেশ স্থাপনের পথিকৃৎ ছিলেন -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কলম্বাস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জন কার্টিয়ার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আমেরিগো ভেসপুচি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হামফ্রে গিলবার্ট
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>‘যোগ্যতমের উদ্বর্তন তত্ত্ব’ প্রচার করেন — </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এডমন্ড স্পেনসার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জন ল্যামার্ক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  চার্লস ডারউইন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গ্রেগর মেন্ডেল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  পোর্তুগালে 'সাগ্রেস' নামক নৌ প্রশিক্ষণ কেন্দ্র গড়ে তোলেন —{" "}
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এমানুয়েল দ্য ফরচুনেটার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দিয়েগো ক্যাওর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দ্বিতীয় জন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  প্রিন্স হেনরি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  হিসপানিওলা দ্বীপে উপনিবেশ স্থাপন যে কারণে ইউরোপীয়
                  রাষ্ট্রগুলির বিশেষ আগ্রহের বিষয় ছিল —{" "}
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রৌপ্য খনি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  স্বর্ণ খনি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হীরক খনি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তাম্র খনি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>উত্তমাশা অন্তরীপ সর্বপ্রথম প্রদক্ষিণ করেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ম্যাগেলান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভাস্কো-দা-গামা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বার্থেলোমিউ দিয়াজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কলম্বাস
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>পোর্তুগিজরা ‘'ব্ল্যাক গোল্ড’ বলত —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লবঙ্গকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দারুচিনিকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কয়লাকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  গোলমরিচকে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>‘Wealth of Nations’-গ্রন্থটির লেখক হলেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডেভিড টমসন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ড মার্কেন্টাইল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ড অ্যাক্টন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অ্যাডাম স্মিথ
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="3">
                <b>
                  দ্বিতীয় অধ্যায় - ঊনবিংশ ও বিংশ শতকে ঔপনিবেশি কতাবাদ ও
                  সাম্রাজ্যবাদের প্রসার
                </b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রশ্নের মান - ৮</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                1. উপনিবেশবাদ ও সাম্রাজ্যবাদ বিষয়ে হবসন-লেনিনের তত্ত্ব আলোচনা
                করো।
                <br />
                2. ঔপনিবেশিক সমাজে জাতিসংক্রান্ত প্রশ্ন ও তার প্রভাব আলোচনা করো।{" "}
                <br />
                অথবা,
                <br /> জাতিবৈষম্য বলতে কী বোঝো? ঔপনিবেশিক রাষ্ট্রগুলিতে
                জাতিবৈষম্যের প্রভাবগুলি লেখো।
                <br />
                3. সাম্রাজ্যবাদ বলতে কী বোঝ? সাম্রাজ্যবাদ উদ্ভবের কারণগুলি
                সংক্ষেপে বর্ণনা করো। 3 + 5<br />
                4. নব সাম্রাজ্যবাদ বলতে কী বোঝো? “নব সাম্রাজ্যবাদ প্রথম
                বিশ্বযুদ্ধের জন্য দায়ী ছিল” – লেনিনের উত্তির তাৎপর্য ব্যাখ্যা
                করো।
                <br />
                5. ঔপনিবেশিক রাষ্ট্রগুলিতে জাতিগত ব্যবধানের প্রভাবগুলি লেখো।
                <br />
                6. মার্কেন্টাইল অর্থনীতি বলতে কী বোঝো? শিল্পপুজির বৈশিষ্ট্য
                আলোচনা করো। <br />
                অথবা,
                <br /> মার্কেন্টাইল মূলধন বলতে কী বোঝো? এই মতবাদের প্রধান
                বক্তব্যগুলি কী?{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ১</b>
              </font>
              <br />
              <br />
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b> 1. সোশ্যাল ডারউইনবাদের প্রবক্তা কে?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সোশ্যাল ডারউইনবাদের প্রবক্তা হারবার্ট স্পেনসার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. আমেরিকা কবে স্বাধীনতা লাভ করে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৭৮৩ খ্রিস্টাব্দে আমেরিকা স্বাধীনতা লাভ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. ইউনিয়ন অব সাউথ আফ্রিকা কবে গঠিত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৯১০ খ্রিস্টাব্দে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    4. বৈদেশিক বাণিজ্যের জন্য চিনের কোন দুটি বন্দর সীমাবদ্ধ ছিল?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                চিনের ক্যান্টন ও ম্যাকাও বন্দর দুটি বৈদেশিক বাণিজ্যের জন্য
                সীমাবদ্ধ ছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. গন্ডামাকের সন্ধি কবে, কাদের মধ্যে স্বাক্ষরিত হয়?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৮৭৯ খ্রিস্টাব্দে লর্ড লিটন ও আফগান আমির শের আলির মধ্যে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. কোন্ দেশ ‘মশলা দ্বীপপুঞ্জ’ নামে বিখ্যাত ছিল?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ইন্দোনেশিয়ার মালুকু দ্বীপ ‘মশলা দ্বীপপুঞ্জ’ নামে বিখ্যাত ছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. ‘ওয়েলথ্ অব নেশনস’ গ্রন্থটির রচয়িতা কে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ব্রিটিশ অর্থনীতিবিদ অ্যাডাম স্মিথ ১৭৭৬ খ্রিস্টাব্দে ‘ওয়েলথ অব
                নেশনস' গ্রন্থটি রচনা করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. কোন্‌ মার্কিন সেনাপতি সর্বপ্রথম জাপানে পদার্পণ করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                মার্কিন সেনাপতি কমোডোর পেরি ১৮৫৪ খ্রিস্টাব্দে সর্বপ্রথম জাপানে
                পদার্পণ করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. সোশ্যাল ডারউইনবাদের প্রবক্তা কে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                সোশ্যাল ডারউইনবাদের প্রবক্তা হলেন হার্বার্ট স্পেনসার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. ইনকা সভ্যতা কে ধ্বংস করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ফ্রান্সিসকো পিজারো ইনকা সভ্যতা ধ্বংস করেন (১৫৩২ খ্রিস্টাব্দ)।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. ভারতের কোন্ কোন্ স্থানে পোর্তুগিজ বাণিজ্যকুঠি ছিল?</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতের বোম্বাই, গোয়া, কোচিন, দমন, দিউ, সলসেট, বেসিন, চট্টগ্রাম,
                হুগলি প্রভৃতি স্থানে পোর্তুগিজ বাণিজ্যকুঠি গড়ে উঠেছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. ভাস্কো-দা-গামা কবে ভারতে আসেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                পোর্তুগিজ নাবিক ভাস্কো দা গামা ১৪৯৮ খ্রিস্টাব্দে ভারতের পশ্চিম
                উপকূলে কালিকট বন্দরে পদার্পণ করেছিলেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. কোন্ কোন্ দ্বীপ নিয়ে ইন্দোনেশিয়া গঠিত?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                জাভা, সুমাত্রা, বালি এবং বোর্ণিও দ্বীপ নিয়ে ইন্দোনেশিয়া গঠিত
                হয়। হল্যান্ডের ডাচ বা ওলন্দাজরা এখানে উপনিবেশ স্থাপন করেছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. হিলফারডিং-এর লেখা বইটির নাম কী? </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                হিলফারডিং-এর লেখা বিখ্যাত বইটির নাম Finance Capital। গ্রন্থটি
                ১৯১০ খ্রিস্টাব্দে প্রকাশিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. আমেরিকা মহাদেশ কে আবিষ্কার করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ইতালীয় নাবিক তথা স্পেনীয় অভিযাত্রী ক্রিস্টোফার কলম্বাস ১৪৯২
                খ্রিস্টাব্দে আমেরিকা মহাদেশ আবিষ্কার করেন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  16. ১৮১৬ খ্রিস্টাব্দের সগৌলির সন্ধিতে কোন্ কোন্ অঞ্চল ব্রিটিশ
                  সাম্রাজ্যভুক্ত হয়েছিল?
                </b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সিমলা, নৈনিতাল, মুসৌরি, কুমায়ুন, কুলু, মানালি, গাড়োয়াল
                প্রভৃতি অঞ্চল ব্রিটিশ সাম্রাজ্যভুক্ত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    17. কে কবে দক্ষিণ ব্রষ্মদেশ (পেগু) ব্রিটিশ সাম্রাজ্যভুক্ত
                    করেন?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                বড়োলাট লর্ড ডালহৌসি ১৮৫২-১৮৫৩ খ্রিস্টাব্দে দ্বিতীয় ইঙ্গ-ব্রয়
                যুদ্ধে জয়লাভের মধ্য দিয়ে দক্ষিণ ব্রষ্মদেশকে (পেগু) ব্রিটিশ
                সাম্রাজ্যভুক্ত করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    18. দক্ষিণ-পূর্ব এশিয়ার কোন্ কোন্ অঞ্চলে ব্রিটিশ ঔপনিবেশিক
                    সাম্রাজ্য গড়ে উঠেছিল?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                দক্ষিণ-পূর্ব এশিয়ার ভারত, সিংহল, সিঙ্গাপুর, ফিজি দ্বীপপুঞ্জ,
                ব্রহ্মদেশ প্রভৃতি অঞ্চলে ব্রিটিশ ঔপনিবেশিক সাম্রাজ্য গড়ে
                উঠেছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. ভাস্কো-দা-গামা কবে ভারতে আসেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৪৯৮ খ্রিস্টাব্দে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. Industry and Empire গ্রন্থের লেখক কে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                হবসবম।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. লেনিন রচিত দুটি গ্রন্থের নাম লেখো।</b>
              </font>
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                লেনিন রচিত দুটি গ্রন্থ হল – 'Imperialism the Highest Stage of
                Capitalism' এবং 'Imperialism and the Split in Socialism.
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. হিলফারডিং-এর বইটির নাম লেখো।</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                হিলফারডিং-এ বইটির নাম 'Finance Capital'
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    23. ভারতের কোন্ কোন্ জায়গায় পোর্তুগিজ বাণিজ্য কুঠি ছিল?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের মধ্যে মুম্বাই, কোচিন, বেসিন, সলসেট, দিউ, হুগলি ইত্যাদি
                অঞ্চলে পোর্তুগিজরা তাদের বাণিজ্যকুঠি গড়ে তুলেছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. কাদের মধ্যে প্রথম অহিফেন যুদ্ধ বাধে?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                চিন সরকারের সঙ্গে ইংরেজদের প্রথম অহিফেন যুদ্ধ বাধে (১৮৩৯
                খ্রিস্টাব্দ)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    25. কোন্ ইউরোপীয় দেশ চিনের সঙ্গে প্রথম বাণিজ্যিক সম্পর্ক
                    গড়ে তুলেছিল?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ষোড়শ শতকে (১৫১৬ খ্রিস্টাব্দে) মিং রাজবংশের অধীনে পোর্তুগিজরাই
                চিনের সঙ্গে প্রথম বাণিজ্যিক সম্পর্ক গড়ে তুলেছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    26. ১৬১২ খ্রিস্টাব্দে ভারতের কোথায় প্রথম ইংরেজ
                    বাণিজ্যকেন্দ্র স্থাপিত হয়?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারতের পশ্চিম উপকূলে অবস্থিত সুরাট বন্দরে ১৬১২ খ্রিস্টাব্দে
                প্রথম ইংরেজ বাণিজ্যকুঠি গড়ে ওঠে। <br />
                <br />
                <font color="#f626d7">
                  <b>
                    27. বৈদেশিক বাণিজ্যের জন্য চিনের কোন্ দুটি বন্দর নির্দিষ্ট
                    ছিল?
                  </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ক্যান্টন ও ম্যাকাও।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>28. লগ্নি পুঁজি কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                অধিক মুনাফা অর্জনের লক্ষ্যে শিল্প বা বাণিজ্য থেকে প্রাপ্ত মুনাফা
                যখন পুনরায় শিল্প বা বাণিজ্যে বিনিয়োগ করা হয়, তখন তাকে লগ্নি
                পুঁজি বলা হয়। এইভাবে পুঁজিপতিদের হাতে অচিরেই ‘পুঁজির পাহাড়’
                সঞ্চিত হয় এবং পাহাড়-প্রমাণ পুঁজি বিনিয়োগের নব নব ক্ষেত্র
                অনুসন্ধান সাম্রাজ্যবাদী প্রতিদ্বন্দ্বিতার জন্ম দেয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. ‘শ্বেতাঙ্গদের বোঝা' বলতে কী বোঝানো হয়? </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ইউরোপের কোনো কোনো সাম্রাজ্যবাদী চিন্তাবিদ মনে করতেন যে, এশিয়া ও
                আফ্রিকার অনুন্নত পশ্চাদপদ জাতিগুলিকে সভ্যতার আলোকে আলোকিত করার
                নৈতিকদায় ইউরোপীয় জাতিগুলির রয়েছে। এই তথাকথিত দায়বদ্ধতা
                ‘শ্বেতাঙ্গদের বোঝা’ বা ‘সাদা চামড়ার দায়বদ্ধতা’ নামে পরিচিত।
                ব্রিটিশ লেখক রুডইয়ার্ড কিপলিং, ফরাসি লেখক জুলি ফেরি প্রমুখ এই
                মতবাদের প্রবক্তা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. সাম্রাজ্যবাদ বলতে কী বোঝায়?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ল্যাটিন শব্দ 'ইম্পেরিয়াম' থেকে ইংরেজি ‘ইম্পেরিয়ালিজম' বা
                সাম্রাজ্যবাদ শব্দটি এসেছে, যার ব্যুৎপত্তিগত অর্থ ‘সামরিক
                কর্তৃত্ব’। প্রকৃত অর্থে, কোনো শক্তিশালী রাষ্ট্রের দ্বারা
                অপেক্ষাকৃত দুর্বল কোনো রাষ্ট্রের উপর ঔপনিবেশিক আধিপত্য স্থাপনকেই
                সাম্রাজ্যবাদ বলা যেতে পারে। ‘সাম্রাজ্যবাদ’ শব্দটি ইতিহাসে
                বহুচর্চিত হলেও বর্তমানে যথেষ্টই নিন্দিত ও ঘৃণিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>31. কত সালে আমেরিকা যুক্তরাষ্ট্র গঠিত হয়?</b>
                </font>
                <br /> <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ১৭৭৬ খ্রিস্টাব্দের ৪ জুলাই আমেরিকাস্থিত ‘ত্রয়োদশ ব্রিটিশ
                উপনিবেশ’ একত্রে ইংল্যান্ডের বিরুদ্ধে স্বাধীনতার যুদ্ধ ঘোষণা করে।
                এই ঘোষণার মধ্য দিয়েই গঠিত হয় আমেরিকা যুক্তরাষ্ট্র।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>32. সাম্রাজ্যবাদ বিষয়ে জে. এ. হবসনের মূল বক্তব্য কী ছিল?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সাম্রাজ্যবাদ ও ঔপনিবেশিকতাবাদের অর্থনৈতিক ব্যাখ্যাদাতাদের মধ্যে
                অগ্রগণ্য হলেন ব্রিটিশ অর্থনীতিবিদ জে. এ. হবসন। তাঁর বহুখ্যাত
                'Imperialism: A Study' (1902) গ্রন্থে তিনি লিখেছেন যে, পশ্চিমি
                দেশগুলিতে ধন-বণ্টনের বৈষম্যের ফলে পুঁজিপতিদের হাতে প্রচুর মূলধন
                জমে যায়। এই মাত্রাতিরিক্ত মূলধনের লগ্নির নব নব ক্ষেত্র
                অনুসন্ধানের জন্য তারা নিজের দেশের সরকারকে সাম্রাজ্য বিস্তারে
                বাধ্য করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>33. উপনিবেশবাদ’ বলতে কী বোঝো?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ল্যাটিন ‘কলোনিয়া’ শব্দটি থেকে এসেছে ইংরেজি ‘কলোনি’ বা
                উপনিবেশবাদ শব্দটির উদ্ভব, যার অর্থ ‘মানব সমাজের একটি
                স্থানান্তরিত অংশ'। সাধারণভাবে উপনিবেশ বলতে বোঝায় রাষ্ট্র থেকে
                বিচ্ছিন্ন কোনো ভূখণ্ড, যে ভূখণ্ড উক্ত রাষ্ট্রের কাছে
                নির্দিষ্টভাবে আনুগত্য প্রকাশ করে এবং ওই ভূখণ্ডের যাবতীয় সম্পদ
                আধিপত্যস্থাপনকারী দেশটির স্বার্থে ব্যবহৃত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    34. ‘জাতি ও জাতি বৈষম্য’ ধারণাটি কোন্ ক্ষেত্রের সঙ্গে যুক্ত
                    ছিল?
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                ‘জাতি ও জাতি বৈষম্যে’র ধারণাটির মূলে ছিল ইউরোপের শ্বেতাঙ্গ
                জাতিগুলির দ্বারা নিজেদেরকে ‘শ্রেষ্ঠতর’ এবং এশিয়া-আফ্রিকার
                অনুন্নত ও পশ্চাৎপদ জাতিগুলিকে ‘নিকৃষ্টতর’ মনে করার মানসিকতা। এর
                দ্বারা ওই সকল অঞ্চলে ইউরোপীয় সাম্রাজ্য স্থাপনের প্রচেষ্টাকে
                বৈধতা দেওয়া হয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. শিল্প বিপ্লব সর্বপ্রথম কোথায় শুরু হয়েছিল? </b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                অষ্টাদশ শতকের দ্বিতীয়ার্ধে ইংল্যান্ডে সর্বপ্রথম শিল্প বিপ্লব
                সংঘটিত হয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>36. কোন্ মার্কিন সর্বপ্রথম জাপানে পদার্পণ করেন?</b>
                </font>
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                কমোডর পেরি, ১৮৫৪ খ্রিস্টাব্দে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>37. ক্যাপ্টেন কুক কোন্ কোন্ দেশ আবিষ্কার করেন?</b>
              </font>
              <br />
              <br />
              <font color="#29900a">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                অষ্টাদশ শতকের শেষ পর্বে ইংরেজ নাবিক ও আবিষ্কারক ক্যাপ্টেন কুক
                নিউজিল্যান্ড (১৭৬৯ খ্রিস্টাব্দ), অস্ট্রেলিয়া (১৭৭০ খ্রিস্টাব্দ)
                ও হাওয়াই দ্বীপপুঞ্জ (১৭৭৮ খ্রিস্টাব্দ) আবিষ্কার করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. শুল্ক সংরক্ষণ নীতিটি কী?</b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                রাষ্ট্র নিয়ন্ত্রিত সঙ্কীর্ণঅর্থনৈতিক নীতির এক গুরুত্বপূর্ণ
                বৈশিষ্ট্য হল ‘শুল্ক সংরক্ষণ নীতি’। এই নীতির মূল কথা হল দেশীয়
                শিল্পপতিদের স্বার্থে নিজ দেশের পণ্য-শুল্ক যথাসম্ভব হ্রাস করে
                বিদেশি পণ্যে অত্যধিক হারে শুল্ক ধার্য করা। এই নীতিতে শুল্ক
                প্রাচীর গড়ে তুলে বিদেশি পণ্যের আমদানিতে বাধাদান করা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. আফ্রিকা কেন অন্ধকারাচ্ছন্ন মহাদেশ নামে পরিচিত ছিল? </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                সুপ্রাচীন সভ্যতা, সংস্কৃতি ও ঐতিহ্যের অধিকারী হলেও উনিশ শতকের
                মধ্যভাগ পর্যন্ত ইউরোপের তথাকথিত ‘সভ্য’ জাতিগুলির কাছে আফ্রিকা
                ছিল অজ্ঞাত ও অনাবিষ্কৃত। এর উপকূল রেখা ভিন্ন অভ্যন্তর ভাগের কোনো
                তথ্যই ইউরোপবাসীর জানা ছিল না। তাই আফ্রিকাকে ‘অন্ধকারাচ্ছন্ন
                মহাদেশ’ বলা হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    40. আফ্রিকায় সাম্রাজ্য বিস্তারের জন্য ইউরোপীয় দেশগুলির
                    মধ্যে কাড়াকাড়ি’ শুরু হয়েছিল কেন?
                  </b>
                </font>
                <br />
                <br />
                <font color="#29900a">
                  <b>উত্তর : </b>{" "}
                </font>
                প্রথমত, আফ্রিকার অফুরন্ত প্রাকৃতিক সম্পদের প্রতি লোভ। দ্বিতীয়ত,
                এই অঞ্চলের সামারিক গুরুত্ব। তৃতীয়ত, ‘মন্‌রো নীতি’র জন্য আমেরিকা
                মহাদেশে ইউরোপীয় দেশগুলির বিস্তৃতির পথ রুদ্ধ হওয়া প্রভৃতি কারণে
                ইউরোপীয় দেশগুলি আফ্রিকায় সাম্রাজ্য বিস্তারে উদ্যোগী হয়েছিল।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

ঊনবিংশওবিংশশতকেঔপনিবেশিকতাবাদওসাম্রাজ্যবাদেরপ্রসার.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
  memo
)(ঊনবিংশওবিংশশতকেঔপনিবেশিকতাবাদওসাম্রাজ্যবাদেরপ্রসার);
