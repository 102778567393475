import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function ক্ষয়চক্রগঠনওপ্রক্রিয়া() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          ক্ষয়চক্র : গঠন ও প্রক্রিয়া | West Bengal Class 12 Guide | West
          Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="ক্ষয়চক্র : গঠন ও প্রক্রিয়া | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                ক্ষয়চক্র : গঠন ও প্রক্রিয়া
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7" size="4">
                <b>দ্বিতীয় অধ্যায়</b>
              </font>{" "}
              <br />{" "}
              <font color="#2b9a09" size="4">
                <b>ক্ষয়চক্র : গঠন ও প্রক্রিয়া</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="3">
                <b>প্রশ্নের মান - ৮</b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                ১. ডেভিস বর্ণিত স্বাভাবিক ক্ষয়চক্রের বিবরণ দাও। <br />
                অথবা ,<br />
                ভূমিভাগের নির্দিষ্ট জীবন ইতিহাস সম্পর্কিত W. M. Davis- এর ধারণার
                তাৎপর্য?
                <br />
                ২. বােলসন বা প্লায়া কাকে বলে? পেডিমেন্ট ও বাজাদার মধ্যে
                পার্থক্য নির্ণয় করাে।
                <br />
                ৩. ক্ষয়চক্রের ব্যাঘাত বলতে কী বােঝায়? এর কারণগুলি উল্লেখ করাে|
                স্থিতিশীল পুনর্যৌবন লাভ কীভাবে ঘটে থাকে?
                <br />
                ৪. পুনর্যৌবন লাভের ফলে গঠিত ভূমিরূপের পরিচয় দাও।
                <br />
                ৫. পুনর্যৌবন লাভ বলতে কী বােঝ? ডেভিসের ক্ষয়চক্র ধারণার যৌবন
                অবস্থার বৈশিষ্ট্য উল্লেখ করাে। নদীমঞ্চ কীভাবে গড়ে ওঠে?
                <br />
                ৬. ক্ষয়চক্রের বিভিন্ন পর্যায়ে নদীর দ্বারা সৃষ্ট ভূমিরূপগুলির
                পরিচয় দাও।
                <br />
                ৭. নিক পয়েন্টে জলপ্রপাত গঠিত হয় কেন? স্বাভাবিক ক্ষয়চক্রের
                যৌবন পর্যায়ের ভূমিরূপগুলি আলােচনা করাে।
                <br />
                ৮. পেডিপ্লেন কীভাবে সৃষ্টি হয়? ডেভিসের ত্রয়ী বলতে কী বােঝ?
                স্বাভাবিক ক্ষয়চক্রের বার্ধক্য পর্যায়ে কী ধরনের ভূমিরূপের উদ্ভব
                ঘটে?
                <br />
                ১০. নিক বিন্দু কীভাবে গঠিত হয়? স্বাভাবিক ক্ষয়চক্রের
                বাস্তবায়নের সম্ভাবনা কতখানি? বাজাদার বৈশিষ্ট্য লেখাে।
                <br />
                ১২. স্বাভাবিক ক্ষয়চক্রের নিয়ন্ত্রকগুলি কী কী? সমপ্রায় ভূমি
                (পেনিপ্লেন) ও পাদ সমভূমি (পেডিপ্লেনের) মধ্যে পার্থক্য নির্ণয়
                করাে।?
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ১. ডেভিসের মতানুযায়ী ভূমিরূপ গঠনের তিনটি মুখ্য উপাদান কী কী?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                (i) ভূমিরূপের গঠন, (ii) প্রক্রিয়া, (i) সময় বা পর্যায়।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ২. ডেভিসের ক্ষয়চক্রের ধারণা কোন্ অঞ্চলের জন্য প্রযােজ্য নয়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                আফ্রিকা, অস্ট্রেলিয়া ও দক্ষিণ আমেরিকার মরুভূমিগুলির ক্ষেত্রে
                প্রযােজ্য নয়।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৩. ক্ষয়চক্রে পুঞ্জিত স্খলন ও আবহবিকার ঘটে কোন্ অবস্থায়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">যৌবন অবস্থায়।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ৪. পেঙ্ক ‘Die Morphologische Analyse' প্রকাশ করেন কত
                  খ্রিস্টাব্দে?{" "}
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">১৯২৪ খ্রিস্টাব্দে।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৫. প্রাইমারাম্ফ কী?*</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                পেঙ্ক বর্ণিত ক্ষয়চক্র মতবাদে ভূমিরূপ বিবর্তনের প্রথম পর্যায়ে
                সমুদ্রতল বরাবর গঠিত স্বল্প উচ্চ ও বন্ধুরতা বিশিষ্ট সমতল ভূভাগকে
                বলে প্রাইমারাম্ফ।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৬. বােলসন কোন দেশে দেখতে পাওয়া যায়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> আমেরিকা যুক্তরাষ্ট্রে।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ৭. ক্ষয়চক্রের বাধার ফলে ভূমিভাগের সার্বিক পরিবর্তনকারী
                  প্রক্রিয়া কী নামে পরিচিত?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">পুনর্যৌবন লাভ।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৮. System বা প্রণালীর ধারণা দেন কোন্ কোন্ বিজ্ঞানী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ১৯৭১ খ্রিস্টাব্দে আর জে চোরলে এবং বি কেনেডি।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৯. ‘পলি সাইক্লিক ল্যান্ডস্কেপ কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                একাধিক ক্ষয়চক্রের চিহ্ন পরিলক্ষিত হয় যে ভূমিরূপের উপর,তাকে বলে
                পলি সাইক্লিক ল্যান্ডস্কেপ।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>১০. নদীগ্রাস কখন ঘটে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                {" "}
                শক্তিশালী নদী দ্রুত জলবিভাজিকার মস্তক ক্ষয় করলে জলবিভাজিকার
                অন্য পাশের দুর্বল নদীকে অধিগ্রহণ করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>১১. স্বাভাবিক ক্ষয়চক্রের কোন্ পর্যায়ে নদীগ্রাস ঘটে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> যৌবন পর্যায়ে। </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ১২. ডেভিসের মতে স্বাভাবিক ক্ষয়চক্রের কোন্ পর্যায়ে ভূমির
                  উত্থান স্তব্ধ হয়ে যায় ?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> যৌবন পর্যায়ে।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৩. স্বাভাবিক ক্ষয়চক্রের বাধা কী ?*</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                একটি স্বাভাবিক ক্ষয়চক্রের পূর্ণতাপ্রাপ্তির পথে বিভিন্ন
                প্রাকৃতিক উপাদান (জলবায়ু, প্রাকৃতিক দুর্ঘটনা, উচ্চতার
                পরিবর্তন)গুলি বাধার সৃষ্টি করাকে বলা হয় ক্ষয়চক্রের বাধা।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৪. ডেভিসের ক্ষয়চক্র কোন্ প্রণালীর অন্তর্গত?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> আবদ্ধ প্রণালীর অন্তর্গত।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ১৫. প্রাথমিক পর্যায়ে উথিত ভূমিভাগে কী জাতীয় জলনির্গম প্রণালী
                  দেখতে পাওয়া যায়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> কেন্দ্র বহির্মুখী জলনিৰ্গম প্রণালী।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৬. ভূমিরূপ গঠনে দুটি বহির্জাত প্রক্রিয়ার উল্লেখ করাে।</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> জলধারা ও বায়ুপ্রবাহ।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৭. সমপ্ৰায় ভূমির সংজ্ঞা দাও।</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                ক্ষয়চক্রের বার্ধক্য পর্যায়ে সমগ্র ভূমিভাগটি ক্ষয়ের ফলে যখন
                ক্ষয়ের শেষ সীমা বা তার কাছাকাছি পৌঁছে যায় তখন সেই প্রায়
                সমভূমিকেই ডেলি সমপ্ৰায় ভূমি বলেছেন।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৮. শােল কী ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                নদীবক্ষে সৃষ্ট যে বালুচরের উপর দিয়ে জল প্রবাহিত হয় তাই হল
                শােল। একে মগ্নচরও বলে। এই ধরনের গঠন মহীসােপান অ্যালেও দেখা যায়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ১৯. ভূমিরূপের সম্ভাব্য স্থিতিশক্তি নির্ভর করে ভূমিরূপটির
                  নিজস্ব ভুর, তার উচ্চতা এবং প্রযুক্ত মাধ্যাকর্ষণ শক্তির উপর।
                  মাউন্ট মােনানক’ কোথায় অবস্থিত?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                যুক্তরাজ্যের নিউ হ্যাম্পশায়ার অণ্ডলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ২০. ডেভিসের ক্ষয়চক্রের কয়েকজন সমালােচকের নাম উল্লেখ করাে।
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">এল সি কিং, পাসার্জ, কটন।</font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>২১. দ্বিতীয় ক্ষয়চক্র কাকে বলে ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                স্বাভাবিক ক্ষয়চক্রের বার্ধক্য পর্যায়ে ভূ-আলােড়নের ফলে উখিত
                ভূমিভাগটির ক্ষয়কার্য সমাপ্ত হওয়ার আগেই অনেক সময় পরবর্তী
                ক্ষয়চক্রের সূচনা ঘটে। এই অবস্থাই দ্বিতীয় ক্ষয়চক্র বা
                ক্ষয়চক্রের পুনরুজ্জীবন নামে পরিচিত।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২২. নি বিন্দু কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                পুনর্যৌবন প্রাপ্ত ক্ষয়চক্রের নদীর পুরােনাে মৃদু ঢাল ও খাড়া
                নতুন ঢালের সংযােগ বিন্দুতে সৃষ্ট খাজকে বলে নি বিন্দু।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৩. ক্ষয়চক্র কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                একটি নির্দিষ্ট সময়কাল ধরে ভূ-অভ্যন্তরীণ শক্তির প্রভাবে কোনাে
                উথিত ভূমিরূপ আবহবিকার ও ক্ষয়ীভবনের প্রভাবে যৌবন, পরিণত ও
                বার্ধক্য অবস্থার মধ্য দিয়ে ক্ষয়ের সীমায় পৌছােয় তারপর আবার
                পুনরুত্থান, পুনঃক্ষয়সাধন ঘটে চক্রাকারে, একেই বলে ক্ষয়চক্র।
                ভূবিজ্ঞানী ডেভিসের মতে, উখিত কোনাে ভূভাগ সৃষ্টি ও বিনাশ চক্রের
                আকারে আবর্তিত হয়ে শেষ অবস্থায় পৌছােলে তাকে ক্ষয়চক্র বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৪. কোন ভৌগােলিক সর্বপ্রথম ক্ষয়চক্রের ধারণাটি দিয়েছেন?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> উইলিয়ম মরিস ডেভিস।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৫. স্বাভাবিক ক্ষয়চক্র কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                নদীদ্বারা সম্পন্ন ক্ষয়চক্রকে বলা হয় স্বাভাবিক ক্ষয়চক্র।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৬. আর্দ্র জলবায়ুতে কী জাতীয় ক্ষয়চক্র সম্পাদিত হয় ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                স্বাভাবিক ক্ষয়চক্র বা নদী ক্ষয়চক্র।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৭. ‘ডেভিসের ত্রয়ী' বলতে কী বােঝাে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                স্বাভাবিক ক্ষয়চক্রের মডেলে পর্যায়, প্রক্রিয়া ও গঠনকে একত্রে
                ‘ডেভিসের ত্রয়ী’ বলা হয়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ২৮. ক্ষয়চক্র সম্পর্কিত ‘ঢাল হাস মতবাদ’ বা ‘Slope Decline
                  Theory' কে দেন?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> এল সি কিং।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>২৯. স্বাভাবিক ক্ষয়চক্রের শেষ সীমা বলতে কী বােঝাে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                ডেভিসের মতানুসারে উখিত ভূমির ক্ষয়কার্যের মাধ্যমে নদী তার
                নিম্নক্ষয়ের শেষ সীমা যেখানে শেষ করে, তাকে বলে।স্বাভাবিক
                ক্ষয়চক্রের শেষ সীমা। নদীর মােহনা অঞ্চল বা সমুদ্রপৃষ্ঠ হল
                নদীক্ষয়ের শেষ সীমা।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৩০. নদীর সংখ্যা সবচেয়ে বেশি হয় স্বাভাবিক ক্ষয়চক্রের কোন্
                  পর্যায়ে ?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ক্ষয়চক্রের পরিণত পর্যায়ে।</font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>৩১. মোনাডনক কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                স্বাভাবিক ক্ষয়চক্রের বার্ধক্য পর্যায়ে সমপ্রায় ভূমির উপর সৃষ্ট
                কঠিন শিলাদ্বারা গঠিত ক্ষয়প্রতিরােধকারী বিচ্ছিন্ন ও স্বল্পোচ্চ
                টিলা বা পাহাড়কে বলা হয় মােনাডনক।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩২. মােনাডনক নামকরণ করা হয় কীভাবে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                মার্কিন যুক্তরাষ্ট্রের নিউ হ্যাম্পশায়ারের অন্তর্গত মাউন্ট
                মােনাড়নক-এর নামানুসারে এর নামকরণ করা হয়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৩. মরু সমপ্ৰায়ভূমি কাকে বলে?*</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                মরু ক্ষয়কার্যের বার্ধক্য পর্যায়ে ভূমির বন্ধুরতা হ্রাস পায়
                সম্পূর্ণরূপে ও ভূমিটি প্রায় সমতলাকার ধারণ করে। এরূপ ভূমিভাগকেই
                বলে মরু সমপ্রায় ভূমি।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৪. মরু সমপ্রায় ভূমি কী কী নামে পরিচিত? </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                লসন এরূপ ভূমিকে প্যানফ্যান এবং কিং পেডিপ্লেন নাম দেন।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৫. ইনসেলবার্জ প্রধানত কী জাতীয় শিলায় গড়ে ওঠে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> গ্রানাইট জাতীয় শিলায়।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৩৬. ভূবিজ্ঞানী এল সি কিং কোন অঞ্চলে দীর্ঘ গবেষণার পর মরু
                  ক্ষয়চক্রের বিষয়ে মতবাদ দেন ?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                দক্ষিণ আফ্রিকার মরু, মরুপ্রায় এবং সাভানা অঞ্চলের উপর গবেষণা
                করেন।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৭. র্যাভাইন কখন সৃষ্টি হয়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                শুষ্ক অঞ্চলের ক্ষয়চক্রের যৌবন অবস্থায়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৩৮. আপেক্ষিক ও চরম উচ্চতা হ্রাসের সময় কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                শুষ্কতার ক্ষয়চক্রের যৌবন পর্যায়কে বলা হয় আপেক্ষিক ও চরম
                উচ্চতা হ্রাসের সময়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৩৯. শুষ্কতার ক্ষয়চক্রের পার্বত্য ঢালের নিম্নভাগ কীসের মাধ্যমে
                  ব্যবচ্ছিন্ন হয়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> নালীক্ষয় ও পাতক্ষয়ের দ্বারা।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪০. প্লায়া কী?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                মরু অঞ্চলে আকস্মিক প্রবল বৃষ্টিপাতের ফলে জলধারা উপত্যকার
                মধ্যভাগে পৌছে যে লবণাক্ত ছােটো ছােটো হ্রদ সৃষ্টি করে, তাকে
                প্লায়া বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ৪১. শুষ্ক ক্ষয়চক্রের পরিণত পর্যায়ে ক্ষয়িত ও অবশিষ্ট
                  টিলাগুলিকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ইনসেলবার্জ।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪২. ইনসেলবার্জ কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                সম্ভবত প্রথম মার্কিন ভূবিজ্ঞানী প্যাসার্জ মরু ক্ষয়চক্রের
                বার্ধক্য পর্যায়ে সৃষ্ট ক্ষয়প্রতিরােধী পেডিপ্লেনের উপর ইতস্তত
                বিক্ষিপ্ত গােলাকার মস্তকদেশ ও খাড়া পার্শ্বঢালবিশিষ্ট ভূমিরূপকে
                বলেছেন ইনসেলবার্জ।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৩. বাজাদা’ কী ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                শুষ্কতার ক্ষয়চক্রের পরিণত অবস্থায় পেডিমেন্ট-এর নিম্নাংশে পলি,
                বালি, কাঁকর প্রভৃতি সঞ্চিত হয়ে স্বল্প ঢালযুক্ত তরঙ্গায়িত যে
                ভূমিরূপ গঠিত হয়, তাকে বাজাদা’ বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৪. মরু অঞ্চলে ক্ষয়ের শেষ সীমা কী ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                স্থায়ী ভৌম জলস্তর হল মরু অঞলে ক্ষয়ের শেষ সীমা।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৫. পর্যায়িত ঢাল কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                নদীর ঢালের যে অবস্থায় কোনােরূপ ঢালের ছেদ থাকে না, নদী ক্ষয়,
                সঞ্জয় কাজ করে না। ক্ষয়, পরিবহণ ও সঞ্চয়ের মধ্যে নদীর একপ্রকার
                সামঞ্জস্য তৈরি হয় এরূপ নদী ঢালকে বলে পর্যায়িত ঢাল।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৪৬. উপত্যকার মধ্যে উপত্যকা কোথায় দেখা যায় একটি উদাহরণ দাও।
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                আমেরিকা যুক্তরাষ্ট্রের কলােরাডাে নদীর গ্র্যান্ড ক্যানিয়ন অঞলে
                দেখা যায়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৭. উপত্যকার মধ্যে উপত্যকা কাকে বলে?*</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                পুনর্যৌবনপ্রাপ্ত নদী পুরাতন উপত্যকার মধ্যে নিম্নক্ষয় দ্বারা
                নতুন উপত্যকা সৃষ্টি করলে তাকে উপত্যকার মধ্যে উপত্যকা বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৪৮. পুনর্যৌবন লাভের ফলে সমান উচ্চতায় সৃষ্ট নদীর দু-পাশের
                  নদীমকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> যুগল বা প্রতিসাম্য নদীম।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪৯. পলিদ্বারা গঠিত নদীমকে কী বলা হয় ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> পলল মঞ্চ।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫০. মরু অঞ্চলের সময় ভূমি কী নামে পরিচিত?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> পেডিপ্লেন।</font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>৫১. পেডিপ্লেন কী ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                মরু ক্ষয়চক্রের অন্তিম পর্যায়ে জলবিভাজিকার উভয় দিকের পেডিমেন্ট
                যুক্ত হয়ে যে বিস্তৃত সমভূমি গঠিত হয়, তাকেই বলা হয় পেডিপ্লেন।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫২. পেডিমেন্ট’ কীভাবে গঠিত হয়?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ‘পেডি' কথার অর্থ পাদদেশ এবং ‘মন্ট’ কথার অর্থ পাহাড়। বায়ুর
                ক্ষয়কার্যের ফলে পর্বত পাদদেশের উপরের অংশে ক্ষয়চক্রের বার্ধক্য
                পর্যায়ে মৃদু ঢালযুক্ত বিস্তীর্ণ শিলাময় ভূমিভাগ বা ‘পেডিমেন্ট’
                গঠিত হয়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৩. ক্ষয়চক্রের অসম বিকাশ তত্ত্ব’-এর প্রবক্তা কে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ভূবিজ্ঞানী ক্রিকমে (১৯৩৩ খ্রি.)।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৫৪. Profile of equilibrium বা সাম্যাবস্থার প্রস্থচ্ছেদ-তত্ত্বর
                  ধারণা দেন কারা?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                ফরাসি ভূতত্ত্ববিদ অ্যাগাসিজ ও প্লেফেয়ার।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৫. ইউস্ট্যাটিক পুনর্যৌবন লাভ কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভূ-আলােড়নের প্রভাবে বা মহাদেশীয় হিমবাহ গলনের কারণে সমুদ্রতলের
                অবনমন ঘটলে সমুদ্রপৃষ্ঠের উচ্চতার বৃদ্ধি ঘটে। এর ফলে সময় ভূমির
                উপর পর্যায়িত নদী দ্রুত নিম্নক্ষয় দ্বারা যৌবন অব্ৰথা লাভ করে,
                যাকে বলে ইউস্ট্যাটিক পুনর্যৌবন লাভ।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৬. স্থিতিশীল পুনর্যৌবন লাভ’ কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                সমুদ্রপৃষ্ঠের অবনমন বা ভূভাগের উত্থান ব্যতীত অন্য কোনাে
                প্রাকৃতিক কারণে নদীর নিম্নক্ষয় বৃদ্ধি পেলে ভূমিভাগের পুনর্যৌবন
                লাভ ঘটে। একেই বলা হয় স্থিতিশীল পুনর্যৌবন লাভ।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৫৭. নদী উপত্যকার মধ্যে উপত্যকা গঠিত হলে তার দ্বারা কীসের
                  etapiot peti ait?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                নদীর পুনর্যৌবন লাভের প্রমাণ পাওয়া যায়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৮. স্প্যানফ্যান-এর ধারণা কে দেন?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                ভূবিজ্ঞানী লসন প্যানফ্যানের ধারণা দেন।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৫৯. কোন্ কারণে নিক বিন্দু নিশ্চিহ্ন হয়ে যায় ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                পুনর্যৌবন লাভের ফলে নদীর মস্তকমুখী ক্ষয়ের ফলে নিক বিন্দু
                ক্ষয়ের উৎসের দিকে এগিয়ে যায় ও একসময় নিশ্চিহ্ন হয়ে যায়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬০. হৈমিক ইউস্ট্যাটিক পুনর্যৌবন লাভ কী ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                বাষ্পীভূত সমুদ্রজল তুষাররূপে উঁচু পার্বত্য অঞলে সঞ্জিত হলে
                সমুদ্রপৃষ্ঠের পরিবর্তন দ্বারা ভূভাগে যৌবন অবস্থা ফিরে এলে একে
                হৈমিক পুনর্যৌবন লাভ বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬১. গতিশীল পুনর্যৌবন লাভ কাকে বলে?*</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                ভূ-আলােড়নের প্রভাবে ভূমিভাগের উত্থান ঘটলে ক্ষয়ের শেষ সীমার
                পরিবর্তন ঘটে ও নদীর নিম্নস্তরের মাত্রা বৃদ্ধি পায় ও নতুন এক
                ক্ষয়চক্রের সূচনা ঘটে ও ভূভাগটি যৌবন অবস্থা লাভ করে। একেই গতিশীল
                পুনর্যৌবন লাভ বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৬২. ম্যালটের মতানুসারে স্থিতিশীল পুনর্যৌবন ঘটার যে-কোনাে দুটি
                  কারণ লেখাে।
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                (a) বৃষ্টিপাতের বৃদ্ধির ফলে জলনিকাশের পরিমাণ বৃদ্ধি।(b) নদীর
                বােঝা হ্রাসের ফলে জলস্রোতের তীব্রতা বৃদ্ধি।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ৬৩. শুষ্ক মরু অঞ্চলে গড়ে ওঠা গভীর ও সংকীর্ণ গিরিখাত কী নামে
                  পরিচিত?
                </b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> ক্যানিয়ন।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৬৪. নদীর জলধারা ধাপ সৃষ্টি করে প্রবাহিত হলে তাকে কী বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5"> খরস্রোত।</font>
            </div>
            <br />
            <br />
            <div>
              <font color="#2b9a09" size="4">
                <b>প্রশ্নের মান - ৮</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ১. ডেভিস বর্ণিত স্বাভাবিক ক্ষয়চক্রের বিবরণ দাও। <br />
                  অথবা ,<br />
                  ভূমিভাগের নির্দিষ্ট জীবন ইতিহাস সম্পর্কিত W. M. Davis- এর
                  ধারণার তাৎপর্য?
                </b>
              </font>
              <br />
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                <font color="#2a9a08">
                  <b>ডেভিস বর্ণিত স্বাভাবিক ক্ষয়চক্র :</b>
                </font>
                <br />
                <br />
                আমেরিকার ভূবিজ্ঞানী উইলিয়াম মরিস ডেভিস ১৮৯৯ খ্রিস্টাব্দে
                ভূমিরূপের ধারাবাহিক বিবর্তনে ক্ষয়চক্রের ধারণাটি প্রথম প্রকাশ
                করেন। তাঁর মতে, কোনাে অঞ্চলের ভূমিরূপ হল সেই অঞ্চলের ভূতাত্ত্বিক
                গঠন, ভূমিরূপ গঠনকারী ব্রিয়াশীল প্রক্রিয়াসমূহ এবং সময় বা
                পর্যায়ের ফলশ্রুতি। প্রতিটি ভূমিরূপেরই একটি নির্দিষ্ট জীবন
                ইতিহাস আছে। এই ইতিহাস ভূমিরূপের ক্রমবিবর্তনের ইতিহাস যার মধ্যে
                দিয়ে ভূমিরূপের এক সুশৃঙ্খল পর্যায়ক্রমিক ও শ্রেণিবদ্ধ
                পরিবর্তনের মধ্য দিয়ে বিবর্তন ঘটে থাকে। উত্থিত কোনাে ভূমিভাগের
                ক্ষয়কার্যের ফলে প্রাথমিক অবস্থা থেকে নির্দিষ্ট ক্রম অনুসারে
                কতকগুলি অন্তর্বর্তী পর্যায়ের মধ্যে দিয়ে অন্তিম পর্যায়ে
                পৌঁছােনােকে ক্ষয়চক্র বলে। ভূপৃষ্ঠের প্রায় ৭০ শতাংশ ভূমির
                ভাস্কর্য নদীর স্বাভাবিক ক্ষয়কার্যের মাধ্যমে সাধিত হয় বলে একে
                স্বাভাবিক ক্ষয়চক্র বলে। ভূমিরূপের ধারাবাহিক পরিবর্তনে ভূমিভাগের
                যেসব স্বতন্ত্র বৈশিষ্ট্য গড়ে ওঠে, সেগুলিকে শ্রেণিবদ্ধ করার জন্য
                ডেভিস ক্ষয়চক্রটিকে যৌবন, পরিণত ও বার্ধক্য - এই তিনটি পর্যায়ে
                ভাগ করেন।
                <br />
                <br />
                <font color="#f626d7">
                  {" "}
                  <b> ⦿ </b>{" "}
                </font>{" "}
                <font color="#2a9a08">
                  <b>ক্ষয়চক্রের শর্তাবলি : </b>
                </font>
                <br />
                (১) ক্ষয়চক্রের সূচনা হবে নতুন ভূতাত্ত্বিক উপাদানের সমুদ্রবক্ষ
                থেকে উত্থানের ফলে। <br />
                (২) সমুদ্রমুখী ঢালবিশিষ্ট ভূতাত্ত্বিক গঠনের উপস্থিতি যা
                পর্যায়ক্রমে কঠিন ও কোমল শিলায় গঠিত হবে। <br />
                (৩) উত্থান পর্ব দ্রুত সম্পন্ন হবে এবং উত্থানকালে তেমন ক্ষয় হবে
                না। <br />
                (৪) ক্ষয়চক্র চলাকালীন ভূভাগ স্থিতিশীল অবস্থায় থাকবে। <br />
                (৫) অঞ্চলটি আর্দ্র জলবায়ুর অন্তর্গত হবে, যেখানে নদীগােষ্ঠীর
                উদ্ভব হবে এবং নদীক্ষয় প্রাধান্য পাবে। <br />
                (৬) ক্ষয়ের শেষ সীমা পর্যন্ত নদী নিম্নক্ষয় করতে থাকবে এবং উখিত
                ভূভাগ সমপ্রায় ভূমিতে পরিণত হবে।
                <br /> (৭) অলটি সমুদ্রপৃষ্ঠের ঊর্ধ্বে অবস্থান করবে।
                <br />
                (৮) নদীক্ষয়, আবহবিকার, পুঞ্জিত স্বালন প্রভৃতি বহির্জাত
                প্রক্রিয়াগুলি একযােগে ভূমিরূপের বিবর্তনে অংশগ্রহণ করবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>{" "}
                </font>{" "}
                <font color="#2a9a08">
                  <b>ক্ষয়চক্রের পর্যায় : </b>
                </font>
                স্বাভাবিক ক্ষয়চক্রের তিনটি পর্যায়- যৌবন, প্রৌঢ়ত্ব বা পরিণত ও
                বার্ধক্য। এই পর্যায়গুলিতে নদীর কার্যের ফলে সৃষ্ট ভূমিরূপগত
                বৈশিষ্ট্যগুলি নীচে আলােচনা করা হল-
                <br />
                <br />
                <font color="#f626d7">
                  <b>১. যৌবন অবস্থা : </b>
                </font>
                ভূমির উত্থান পর্ব শেষ হওয়ার পর থেকেই ভূমিরূপের যৌবন অবস্থা শুরু
                হয়। এই সময় থেকেই নদী ক্ষয় করতে শুরু করে। এই পর্যায়ে ভূমিভাগ
                যেসব বৈশিষ্ট্য লাভ করে সেগুলি হল -<br />
                (i) এই পর্যায়ে ভূমির প্রারম্ভিক ঢাল অনুযায়ী কতকগুলি অনুগামী
                নদী ও উপনদীর সৃষ্টি হয়। উপনদীগুলি মস্তক ক্ষয়ের মাধ্যমে
                প্রসারিত হয়ে বৃক্ষরূপী জলনির্গম প্রণালী গড়ে তােলে। <br />
                (ii) সমুদ্রপৃষ্ঠ থেকে ভূমির উচ্চতা সর্বাধিক হওয়ায় নদী
                নিম্নক্ষয়ের মাধ্যমে গভীর ও সংকীর্ণ 'v' আকৃতির উপত্যকা গঠন করে।
                <br /> (iii) এই পর্যায়ে প্লাবনভূমি সাধারণত সৃষ্টি হয় না। তবে
                প্রধান নদী উপত্যকায় উত্থানকালের চিহ্ন থাকতে পারে। <br />
                (iv) দুটি অনুগামী নদীর অন্তর্বর্তী অঞ্চল প্রশস্ত হয় এবং এই অংশে
                জলনির্গম প্রণালী উন্নত না হওয়ায় জলাভূমি ও হ্রদ অবস্থান করতে
                পারে।
                <br /> (v) এই অঞল পর্যায়ক্রমে কঠিন ও কোমল শিলায় গঠিত হওয়ায়
                প্রধান নদী বরাবর জলপ্রপাত সৃষ্টি হয়। পরিণত অবস্থায় পৌঁছােনাের
                আগেই এগুলি অন্তর্হিত হয় এবং নদীবক্ষ পর্যায়িত অবস্থায় পৌঁছায়।{" "}
                <br />
                (vi) এই পর্যায়ের শেষের দিকে দুই নদীর অন্তর্বর্তী অংশ সংকীর্ণ ও
                তীক্ষ্ম হয়। এর শীর্ষদেশে আদি শিলার অস্তিত্ব লক্ষ করা যায়।{" "}
                <br />
                (vii) নদীর নিম্নক্ষয়ের কারণে এই পর্যায়ে নদী উপত্যকার তলদেশ ও
                শৈলশিরার শীর্ষদেশের মধ্যে আপেক্ষিক উচ্চতা সর্বাধিক হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>২. পরিণত বা প্রৌঢ়ত্ব অবস্থা : </b>
                </font>
                ক্ষয়কার্যের ফলে উত্থিত ভূমিভাগের প্রারম্ভিক চিহ্নসমূহ যখন
                একেবারে বিলুপ্ত হয়, তখন থেকেই পরিণত অবস্থার শুরু। এই পর্যায়ের
                বিশিষ্ট ভূমিরূপগুলি হল -<br />
                (i) এই পর্যায়ের প্রথম ভাগে জলবিভাজিকার শীর্ষদেশ সুতীক্ষ্ণ হয়।
                জলবিভাজিকাগুলি শৈলশিরার ন্যায় অবস্থান করে। <br />
                (ii) মস্তক ক্ষয়ের মাধ্যমে নদী উপত্যকা বিস্তৃত হয় এবং জলবিন্যাস
                সুপ্রতিষ্ঠিত হয়। <br />
                (iii) এই পর্যায়ের প্রথমদিকে ভূমির আপেক্ষিক উচ্চতা সর্বাধিক হয়।
                <br /> (iv) পরিণত পর্যায়ে নদীর ঢাল পর্যায়িত অবস্থায় পৌঁছােয়
                বলে, পূর্বের জলাশয়, হ্রদ, জলপ্রপাত লুপ্ত হয়। <br />
                (v) নদীর নিম্নক্ষয় অপেক্ষা পার্শ্বক্ষয় বেশি হয় বলে নদী
                সঞ্চয়ের মাধ্যমে প্লাবনভূমি গড়ে তােলে। <br />
                (vi) প্রশস্ত প্লাবনভূমিতে নদীবাঁকের বিস্তার বেশি হয় এবং
                প্লাবনভূমিতে নদীগুলি সহজেই তাদের স্থান পরিবর্তন করতে পারে।{" "}
                <br />
                (vii) পরিণত পর্যায়ের শেষদিকে নদীর পার্শ্বক্ষয়ের মাত্রা
                নিম্নক্ষয় অপেক্ষা অনেক বেশি হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>৩. বার্ধক্য অবস্থা : </b>
                </font>
                উপত্যকার নিম্নক্ষয় বন্ধ হওয়ার সময়কাল থেকে বার্ধক্য অবস্থার
                শুরু। এই পর্যায়ে নদীর পার্শ্বক্ষয় ও উপত্যকার প্রশস্তকরণ চলতে
                থাকে। জলবিভাজিকাগুলি দ্রুত ক্ষয় হয়। ফলে উপত্যকার চরম উচ্চতা
                হ্রাস পায়। এই পর্যায়ে নিম্নক্ষয় বন্ধ হওয়ায় স্থানীয় উচ্চতা
                হ্রাস পায়। উপত্যকা প্রায় সমতল হয়। এই পর্যায়ের বৈশিষ্ট্যগুলি
                হল -<br />
                (i) এই পর্যায়ে নদীর সংখ্যা পরিণত পর্যায়ের থেকে কম হলেও যৌবন
                পর্যায়ের থেকে বেশি। <br />
                (ii) উপত্যকাগুলি অত্যন্ত প্রশস্ত এবং মৃদু ঢালযুক্ত। <br />
                (iii) বিস্তৃত প্লাবনভূমির মধ্য দিয়ে নদী সর্পিল গতিতে প্রবাহিত
                হয়। <br />
                (iv) এই পর্যায়ে নদীর অন্তর্বর্তী জলবিভাজিকাগুলি দ্রুত হ্রাস
                পায়। এই পর্যায়ের শেষভাগে এদের শনাক্ত করা কঠিন হয়। <br />
                (v) নদীবাকের দৈর্ঘ্য অপেক্ষা প্লাবনভূমির বিস্তার বেশি। <br />
                (vi) প্লাবনভূমিতে অশ্বক্ষুরাকৃতি হ্রদ, জলাভূমি প্রভৃতি লক্ষ করা
                যায়। <br />
                (vii) এই পর্যায়ে ভূমির নগ্নীভবনে পুঞ্জিত স্বলন ও রাসায়নিক
                বিক্রিয়া গুরুত্বপূর্ণ ভূমিকা পালন করে।
                <br /> (viii) এই পর্যায়ের শেষভাগে বিস্তীর্ণ ভূভাগ ক্ষয়ের শেষ
                সীমায় বা তার কাছাকাছি পৌঁছােয়। ফলে বৈচিত্র্যহীন সমপ্রায় ভূমি
                গঠন করে। এই সময় ভূমিতে মাঝেমধ্যে ক্ষয় প্রতিরােধী টিলা বা
                পাহাড় অবস্থান করে। এদের মােনাড়নক বলে। এগুলি সমপ্রায় ভূভাগে
                কিছুটা বৈচিত্র্যের চিহ্ন বহন করে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

ক্ষয়চক্রগঠনওপ্রক্রিয়া.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(ক্ষয়চক্রগঠনওপ্রক্রিয়া);
