import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function দ্বিতীয়বিশ্বযুদ্ধেরপরবর্তীপর্বেরআন্তর্জাতিকসম্পর্ক() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          দ্বিতীয় বিশ্বযুদ্ধের পরবর্তী পর্বের আন্তর্জাতিক সম্পর্ক | West Bengal
          Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="দ্বিতীয় বিশ্বযুদ্ধের পরবর্তী পর্বের আন্তর্জাতিক সম্পর্ক | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                দ্বিতীয় বিশ্বযুদ্ধের পরবর্তী পর্বের আন্তর্জাতিক সম্পর্ক
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b> দ্য এন্ড অফ হিস্ট্রি প্রবন্ধটির লেখক —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্তালিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রুজভেল্ট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নাসের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ফ্রান্সিস ফুকোয়ামা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বর্তমানে জোটনিরপেক্ষ আন্দোলনের সদস্য সংখ্যা -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  120
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  130
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  140
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  150
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> ‘ঠান্ডা লড়াই’ শব্দটি প্রথম প্রয়োগ করেন -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বার্নার্ড বারুচ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নাসের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ট্রুম্যান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মার্শাল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>দাঁতাত হল একটি —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সন্ধি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সংগঠন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  প্রক্রিয়া
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চুক্তি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>স্তালিন কার সঙ্গে অনাক্রমণ চুক্তি সম্পাদন করেন?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  হিটলার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মুসোলিনি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গরবাচেভ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্রুশ্চেভ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>জর্জ মার্শাল কে ছিলেন?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মার্কিন রাষ্ট্রপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্রিটিশ পররাষ্ট্রসচিব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মার্কিন পররাষ্ট্রসচিব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফ্রান্সের রাষ্ট্রপতি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>চিনে কমিউনিস্টরা ক্ষমতায় আসে —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1942 খ্রিস্টাব্দ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1946 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1949 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1947 খ্রিস্টাব্দে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> এশিয়ার একটি সামরিক জোটের নাম —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কমিনফর্ম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ন্যাটো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ওপেক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সিয়াটো
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  শান্তির জন্য সম্মিলিত হওয়ার প্রস্তাব’ সম্মিলিত জাতিপুঞ্জের যে
                  সংস্থা গ্রহণ করে -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সচিবালয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নিরাপত্তা পরিষদ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অছি পরিষদ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সাধারণ সভা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> ‘ওয়ারশ চুক্তি’ স্বাক্ষরিত হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1960 খ্রিস্টাব্দের ৪ মে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1954 খ্রিস্টাব্দের 6 মে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  1955 খ্রিস্টাব্দের 14 মে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  1955 খ্রিস্টাব্দের 16 জুন
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>
                  দ্বিতীয় অধ্যায় - দ্বিতীয় বিশ্বযুদ্ধোত্তর পর্বে আন্তর্জাতিক
                  সম্পর্ক
                </b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. কঙ্গো সমস্যা করে সৃষ্টি হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                1960 খ্রিস্টাব্দে কঙ্গো সমস্যা সৃষ্টি হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. পঞ্চশীল নীতির একটি নীতির উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                পঞ্চশীল নীতির একটি অন্যতম নীতি হল—অনাক্রমতা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    3. NATO কোন্ মহাসাগরের তীরবর্তী রাষ্ট্রগুলোকে নিয়ে গঠিত?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                NATO আটলান্টিক মহাসাগরের তীরবর্তী রাষ্ট্রগুলিকে নিয়ে গঠিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. পঞ্চশীল নীতি কে প্রথম ঘোষণা করেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                পঞ্চশীল নীতি প্রথম ঘোষণা করেন জওহরলাল নেহরু।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. ঠান্ডা যুদ্ধের সময়কালটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ঠান্ডা যুদ্ধের সময়কাল 1945 থেকে 1991 পর্যন্ত বিস্তৃত ছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. ঠান্ডা লড়াইকে ‘গরম যুদ্ধ’ কে বলেছেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ঠান্ডা লড়াইকে গরম যুদ্ধ বলে উল্লেখ করেছেন ফ্রিডম্যান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. কে ঠান্ডা লড়াইকে ‘গরম শান্তি’ বলেছেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                বার্নেট ঠান্ডা লড়াইকে ‘গরম শান্তি' বলেছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. CENTO কবে কার উদ্যোগে গঠিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                CENTO 1955 খ্রিস্টাব্দে মার্কিন উদ্যোগে গঠিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. NPT তে স্বাক্ষর করেনি এমন একটি রাষ্ট্রের নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ভারত বৈষম্যমূলক NPT চুক্তিতে স্বাক্ষর করেনি। <br />
                <br />
                <font color="#f626d7">
                  <b>10. ঠান্ডা লড়াইয়ের বিপরীত অবস্থার নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ঠান্ডা লড়াইয়ের বিপরীত অবস্থার নাম দাঁতাত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. দাঁতাত নীতির প্রবক্তা কে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                হেনরি কিসিংগার দাতাত নীতির প্রবক্তা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. দাঁতাত-এর অর্থ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                এককথায় ফরাসি শব্দ দাঁতাত এর অর্থ হল উত্তেজনার প্রশমন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. কবে চিন নিরাপত্তা পরিষদে স্থায়ী সদস্যপদ লাভ করে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                1971 খ্রিস্টাব্দে চিন নিরাপত্তা পরিষদে স্থায়ী সদস্যপদ লাভ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. বর্তমানে জোটনিরপেক্ষ আন্দোলনের সদস্যসংখ্যা কত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                বর্তমানে জোট নিরপেক্ষ আন্দোলনের সদস্য সংখ্যা হল 120 টি সদস্য
                রাষ্ট্র।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. ‘কমিনফর্ম’-এর প্রধান কার্যালয় কোথায় ছিল?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                কমিনফর্ম এর প্রধান কার্যালয় স্থাপিত হয়েছিল যুগোশ্লাভিয়ার
                বেলগ্রেড শহরে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. মূলত কোন্ দুই দেশের মধ্যে ঠান্ডা লড়াই চলেছিল?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                মূলত পূর্বতন সোভিয়েত ইউনিয়ন ও মার্কিন যুক্তরাষ্ট্রের মধ্যে
                ঠান্ডা লড়াই চলেছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. ঠান্ডা লড়াই’ শব্দটি প্রথম কে জনপ্রিয় করে তোলেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                উত্তর— ‘ঠান্ডা লড়াই’ শব্দটি প্রথম জনপ্রিয় করে তোলেন মার্কিন
                সাংবাদিক ওয়াল্টার লিপম্যান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. 'ঠান্ডা লড়াই’ শব্দটি প্রথম কে ব্যবহার করেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ‘ঠান্ডা লড়াই’ শব্দটি প্রথম ব্যবহার করেন বার্নাড বারুচ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. কবে সোভিয়েত ইউনিয়নের পতন ঘটে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                1991 খ্রিস্টাব্দে সোভিয়েত ইউনিয়নের পতন ঘটে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. ঠান্ডা যুদ্ধের সময় মার্কিন নেতৃত্বে গঠিত পশ্চিমি সামরিক
                    জোটটির নাম কী?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ঠান্ডা যুদ্ধের সময় মার্কিন নেতৃত্বে গঠিত পশ্চিমি সামরিক জোটটির
                নাম হল ন্যাটো (NATO)।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  21. ঠান্ডা যুদ্ধের সময় সোভিয়েত নেতৃত্বে গঠিত একটি সামরিক
                  জোটের নাম লেখো।
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                উত্তর→ ঠান্ডা যুদ্ধের সময় সোভিয়েত নেতৃত্বে গঠিত একটি সামরিক
                জোট হল ওয়ারশ(WARSAW)।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. NATO-র পুরো নামটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                NORTH ATLANTIC TREATY ORGANISATION বা উত্তর আটলান্টিক চুক্তি
                সংস্থা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. NAM-এর পুরো নামটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                NON-ALIGNED MOVEMENT বা নির্জোট আন্দোলন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>25. জোটনিরপেক্ষতার অর্থ কী? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জোটনিরপেক্ষতার অর্থ হল কোনো জোট বা শক্তিতে অংশ গ্রহণ না করে
                সম্পূর্ণ নিরপেক্ষ অবস্থানে থাকা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>26. দ্বিতীয় বিশ্বযুদ্ধের শুরু ও সমাপ্তির বছরটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                দ্বিতীয় বিশ্বযুদ্ধ শুরু হয় 1939 খ্রিস্টাব্দে এবং দ্বিতীয়
                বিশ্বযুদ্ধের সমাপ্তি ঘটে 1945 খ্রিস্টাব্দে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>27. জোটনিরপেক্ষতার উদ্ভবের একটি কারণ লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                অর্থনৈতিক অনগ্রসরতা ও জাতীয়তাবাদ জোটনিরপেক্ষতার উদ্ভবের অন্যতম
                একটি কারণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. জোটনিরপেক্ষতা শব্দটি প্রথম কে ব্যবহার করেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জওহরলাল নেহেরু প্রথম জোটনিরপেক্ষতা শব্দটি ব্যবহার করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. জোট নিরপেক্ষ আন্দোলনের প্রধান লক্ষ্য কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                জোট নিরপেক্ষ আন্দোলনের প্রধান লক্ষ্য হল বিশ্ব শান্তি বজায় রাখা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. মিত্র শক্তি কোন্ কোন্ দেশ নিয়ে গড়ে উঠেছিল?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                গ্রেট-ব্রিটেন, সোভিয়েত ইউনিয়ন, মার্কিন যুক্তরাষ্ট্র, ফ্রান্স
                প্রভৃতি দেশকে নিয়ে মিত্র শক্তি গড়ে উঠেছিল।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>31. অক্ষ শক্তি কোন্ কোন্ দেশ নিয়ে গড়ে উঠেছিল?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                জার্মানি, ইতালি, স্পেন, জাপান প্রভৃতি দেশগুলোকে নিয়ে অক্ষশক্তি
                গড়ে উঠেছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. কাদের ঘোষণার মধ্য দিয়ে ঠান্ডা লড়াইয়ের অবসান ঘটে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                গরবাচেভ ও জর্জ বুশের আনুষ্ঠানিক ঘোষণার মধ্য দিয়ে ঠান্ডা
                লড়াইয়ের অবসান ঘটে। <br />
                <br />
                <font color="#f626d7">
                  <b>
                    33. কোন্‌ বিষয়কে কেন্দ্র করে কত খ্রিস্টাব্দে কাদের মধ্যে
                    পঞ্চশীল নীতি স্বাক্ষরিত হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                1954 খ্রিস্টাব্দে তিব্বতকে কেন্দ্র করে ভারত ও চিনের মধ্যে
                পঞ্চশীল নীতি স্বাক্ষরিত হয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>34. বহুমেরুতা বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                বহুমেরুতা হল' আন্তর্জাতিক শক্তিসাম্যের এমন একটি পরিস্থিতিকে
                বোঝায় যেখানে দুই-এর বেশি ক্ষমতা কেন্দ্রে বজায় থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>35. বর্তমান বিশ্বরাজনীতি কোন্ মেরুকরণের দিকে চলেছে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                বর্তমানে বিশ্বরাজনীতি একমেরুকরণের দিকে চলেছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    36. ওয়ারশ (WARSAW) চুক্তি কবে, কাদের মধ্যে স্বাক্ষরিত
                    হয়েছিল?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ওয়ারশ (WARSAW) চুক্তি 1955 খ্রিস্টাব্দে ইউরোপের সমাজতান্ত্রিক
                রাষ্ট্রগুলোর মধ্যে স্বাক্ষরিত হয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. G-77 কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                এটি একটি গোষ্ঠী, মূলত জোটনিরপেক্ষ দেশগুলোকে নিয়ে এই গোষ্ঠীটি
                গড়ে ওঠে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. OAS-এর উদ্দেশ্য কী ছিল?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                সমগ্র লাতিন আমেরিকার উপর মার্কিন নিয়ন্ত্রণকে অধিকতর কঠোর করাই
                ছিল OAS-এর অন্যতম উদ্দেশ্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. NATO জোটের বিপরীত জোটের নাম লেখো। </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                NATO জোটের বিপরীত জোটে নাম হল WARSAW।
                <br />
                <br />
                <font color="#f626d7">
                  <b>40. ৰান্দুং সম্মেলনে গৃহীত নীতিগুলো কী নামে পরিচিত?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                বান্দুং সম্মেলনে গৃহীত নীতিগুলো বান্দুং-এর দশটি নীতি নামে
                পরিচিত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>41. পেরেস্ত্রৈকা ও গ্লাসনস্ত নীতি দুটির উদ্‌গাতা কে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                পেরেস্ত্রৈকা ও গ্লাসনস্ত নীতি দুটির উদ্গাতা হলেন প্রাক্তন
                সোভিয়েত রাষ্ট্রপ্রধান মিখাইল গরবাচেভ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>42. NPT-এর পুরো নামটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                NPT-এর পুরো নামটি হল— Nuclear Non-Proliferation Treaty|
                <br />
                <br />
                <font color="#f626d7">
                  <b>43. দাঁতাতের একটি ফলাফল লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                মহাশক্তিধর দুটি জোটের মধ্যে শত্রুতা প্রশমন ও পারমাণবিক অস্ত্রের
                প্রতিযোগিতা হ্রাস পাওয়া।
                <br />
                <br />
                <font color="#f626d7">
                  <b>44. SEATO-এর পুরো নামটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                SEATO-এর পুরো নাম হল-SOUTH EAST ASIA TREATY ORGANIZATION
                <br />
                <br />
                <font color="#f626d7">
                  <b>45. 38th Parallel কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                উত্তর কোরিয়া ও দক্ষিণ কোরিয়া এই দুই দেশের মধ্যবর্তী সীমানাকে
                38th Parallel বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>46. NAM-এর একটি নীতি উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                NAM বা নির্জোট আন্দোলনের একটি অন্যতম নীতি হল — সকল জাতির
                আত্মনিয়ন্ত্রণের অধিকারের স্বীকৃতি প্রতিষ্ঠা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>47. দাঁতাত ও ঠান্ডা লড়াইয়ের মধ্যে পার্থক্য কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                দাঁতাত হল উত্তেজনা প্রশমনের একটি প্রক্রিয়া এবং ঠান্ডা লড়াই হল
                উত্তেজনাকে উচ্চস্তরে রাখার একটি সচেতন প্রচেষ্টা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>48. SEATO কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                দক্ষিণ-পূর্ব এশিয়ায় সাম্যবাদ রোধ করার উদ্দেশ্যে মার্কিন
                উদ্যোগে 1954 খ্রিস্টাব্দে দক্ষিণ-পূর্ব এশিয়ার কয়েকটি দেশের
                সঙ্গে SEATO চুক্তি স্বাক্ষরিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    49. বর্তমানে জোটনিরপেক্ষ আন্দোলনের একটি প্রাসঙ্গিকতা লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                আন্তর্জাতিক ব্যবস্থায় বিশ্বশান্তি বজায় রাখা এবং গণতান্ত্রিক
                প্রক্রিয়াকে বাস্তবায়িত করার ক্ষেত্রে জোটনিরপেক্ষ আন্দোলনের
                প্রয়োজনীয়তা ও প্রাসঙ্গিকতা রয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>50. দাঁতাত কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                দুই বা ততোধিক শত্রুভাবাপন্ন দেশগুলোর মধ্যে উত্তেজনার হ্রাস ঘটানো
                এবং বোঝাপড়ার মাধ্যমে একটি সহজ সম্পর্ক স্থাপনের অবস্থা হল
                দাঁতাত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>51. অধ্যাপক ফ্রিডম্যানের মতে ঠান্ডা লড়াই কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                অধ্যাপক ফ্রিডম্যানের মতে ঠান্ডা লড়াই হল এমন এক ধরনের পরিস্থিতি
                যেখানে যুদ্ধ না হওয়া সত্ত্বেও যুদ্ধের জন্য সবরকম প্রস্তুতি জারি
                থাকতে দেখা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>52. পেরেস্ত্রৈকা বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                পেরেস্ত্রৈকা বলতে পুনর্গঠন, পরিবর্তন ও সংস্কার সাধনকে বোঝায়,
                পূর্বতন সোভিয়েত রাষ্ট্রপ্রধান মিখাইল গরবাচেভ অন্যতম এই নীতিটি
                গ্রহণ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>53. কে, কী উদ্দেশ্যে, কবে কোমেকন গঠন করে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                সোভিয়েত ইউনিয়ন 1949 খ্রিস্টাব্দে পূর্ব ইউরোপের সমাজতান্ত্রিক
                দেশগুলোর মধ্যে অর্থনৈতিক ও রাজনৈতিক সংহতি বৃদ্ধির উদ্দেশ্যে
                কোমেকন গঠন করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>54. গ্লাসনস্ত শব্দটির অর্থ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                গ্লাসনস্ত শব্দটির অর্থ হল মুক্তমনা হওয়ার জন্য ব্যাপক গণতন্ত্রের
                প্রসার। ঠান্ডা যুদ্ধ চলাকালীন পূর্বতন সোভিয়েত ইউনিয়ন
                রাষ্ট্রপ্রধান মিখাইল গরবাচেভ অন্যতম এই গ্লাসনস্ত নীতিটি গ্রহণ
                করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>55. দ্বিমেরুকরণ কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                1945 সালে দ্বিতীয় বিশ্বযুদ্ধের পরবর্তী সময়ে মার্কিন নেতৃত্বে
                পুঁজিবাদী জোট ও সোভিয়েত ইউনিয়নের নেতৃত্বে সমাজতান্ত্রিক জোট
                দুই মেরুতে বিভক্ত হয়ে পড়ে। একে দ্বিমেরুকরণ বলা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>56. ঠান্ডা যুদ্ধ কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                দ্বিতীয় বিশ্বযুদ্ধের পরবর্তী সময়ে মার্কিন নেতৃত্বে ধনতান্ত্রিক
                জোট এবং পূর্বতন সোভিয়েত ইউনিয়নের নেতৃত্বে সমাজতান্ত্রিক জোট
                উভয়ই প্রত্যক্ষভাবে যুদ্ধে লিপ্ত না হয়ে যে পারস্পরিক দ্বন্দ্ব ও
                উত্তেজনাময় পরিবেশের সৃষ্টি করেছিল, তা ঠান্ডা যুদ্ধ বা ঠান্ডা
                লড়াই নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    57. বান্দুং সম্মেলনে গৃহীত যে-কোনো দুটি নীতি উল্লেখ করো।{" "}
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                বান্দুং সম্মেলনে গৃহীত 10 টি নীতির মধ্যে অন্যতম দুটি-নীতি হল —
                <br />
                (i) সকল জাতির সার্বভৌমত্ব ও ভৌগোলিক অখণ্ডতার প্রতি
                শ্রদ্ধাজ্ঞাপন। (ii) পারস্পরিক স্বার্থ ও সহযোগিতার প্রসার ঘটানো।
                <br />
                <br />
                <font color="#f626d7">
                  <b>58. তৃতীয় বিশ্ব বলতে কী বোঝায়? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                দ্বিতীয় বিশ্বযুদ্ধের পরবর্তী সময়ে ঠান্ডা লড়াই চলাকালীন মূলত
                এশিয়া, আফ্রিকা ও লাতিন আমেরিকার দেশগুলো পুঁজিবাদী ও সাম্যবাদী
                দুটি পরস্পর বিরোধী মতাদর্শের কোনোটিটেই যোগ না দিয়ে নিরপেক্ষ
                রাষ্ট্র হিসাবে নিজেদের স্বতন্ত্র অস্তিত্ব বজায় রেখেছিল। এই
                দেশগুলো তৃতীয় বিশ্ব নামে পরিচিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>59. একমেরু বিশ্বব্যবস্থা বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                একমেরু বিশ্বব্যবস্থা বলতে আন্তর্জাতিক শক্তি সাম্যের এমন একটি
                অবস্থাকে বোঝায় যেখানে কেবলমাত্র একটি ক্ষমতা কেন্দ্রে বিরাজ করে।
                1991 খ্রিস্টাব্দে সোভিয়েত পতনের পরবর্তী সময়ে মার্কিন নেতৃত্বে
                এইরূপ বিশ্বব্যবস্থা প্রতিষ্ঠিত হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>60. ঠান্ডা লড়াইয়ের দুটি বৈশিষ্ট্য লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ঠান্ডা লড়াইয়ের অন্যতম দুটি বৈশিষ্ট্য হল — <br />
                (i) ঠান্ডা লড়াই হল দ্বিতীয় বিশ্বযুদ্ধের পরবর্তী সময়ে
                পুঁজিবাদী ও সাম্যবাদী শিবিরের মধ্যে আদর্শগত বা মতাদর্শগত লড়াই।
                <br />
                (ii) ঠান্ডা লড়াই না যুদ্ধ ও না শান্তির মধ্যবর্তী পর্যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>61. Proxy War বা ছায়াযুদ্ধ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                সরাসরি বা প্রত্যক্ষভাবে পরস্পরের বিরুদ্ধে যুদ্ধ ঘোষণা না করে
                আড়ালে থেকে যুদ্ধ পরিচালনা করাকে বলে Proxy War বা ছায়া যুদ্ধ।
                ঠান্ডা লড়াই চলাকালীন মার্কিন যুক্তরাষ্ট্র ও সোভিয়েত ইউনিয়নের
                মধ্যে এই Proxy War বা ছায়া যুদ্ধ সংঘটিত হয়েছিল।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

দ্বিতীয়বিশ্বযুদ্ধেরপরবর্তীপর্বেরআন্তর্জাতিকসম্পর্ক.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
  memo
)(দ্বিতীয়বিশ্বযুদ্ধেরপরবর্তীপর্বেরআন্তর্জাতিকসম্পর্ক);
