import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function শিখনেরকৌশল() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          শিখনের কৌশল | West Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="শিখনের কৌশল | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                শিখনের কৌশল
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অন্তর্দৃষ্টি মূলক শিখন মূলত সম্ভব নয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনুবর্তনের দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনুকরণের দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বুদ্ধির দ্বারা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনুশীলনের দ্বারা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>স্কিনার প্রবর্তিত সক্রিয় অনুবর্তনটি হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>U type
                  অনুবর্তন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>R type অনুবর্তন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>S type
                  অনুবর্তন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অপানুবর্তন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অন্তর্দৃষ্টি শিখন কৌশল নির্ভর করে বিষয়বস্তুর -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কখনো সামগ্রিকভাবে আবার কখনো আংশিকভাবে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সামগ্রিক উপস্থাপনের উপর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আংশিক উপস্থাপনের উপর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>প্রাচীন অনুবর্তন হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>U type
                  অনুবর্তন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>R type
                  অনুবর্তন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>S type অনুবর্তন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অপানুবর্তন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Gestalt কথাটির অর্থ হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পাঠক্রম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ক্ষেত্রমান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অবয়ব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিষয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  প্রাচীন অনুবর্তনে কোন্ উদ্দীপকটি তুলনামূলকভাবে বেশি শক্তিশালী
                  হয়?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পরিবেশগত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রক্ষোভগত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অনুবর্তিত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অনাবর্তিত
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  শিখন সংক্রান্ত গবেষণার ক্ষেত্রে পাজল বক্স ব্যবহার করেছেন কে?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্পিয়ারম্যান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  থর্নডাইক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্যাভলভ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্কিনার
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>আধুনিক শিক্ষা –</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রাথমিক শিক্ষা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শিক্ষাকেন্দ্রিক শিক্ষা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সমস্যা সমাধান ভিত্তিক শিক্ষা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  শিশুকেন্দ্রিক শিক্ষা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>প্যাভলভের অনুবর্তনের স্বাভাবিক প্রক্রিয়াটি হল—</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  লালাক্ষরণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  খাদ্য
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ঘণ্টাধ্বনি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সবকটি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>‘R-type' অনুবর্তনটির নামকরণ করেন -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্যাভলভ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  স্কিনার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভার্নন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  থর্নডাইক
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>দ্বিতীয় অধ্যায় - শিখন কৌশল</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. প্রচেষ্টা ও ভুলের কৌশল-এর প্রবক্তা কে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                থর্নডাইক
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. থর্নডাইক প্রদত্ত মুখ্য সূত্রগুলি কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                অনুশীলনের সূত্র, প্রস্তুতির সূত্র ও ফললাভের সূত্র।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. সমস্যা সমাধান শিখন তত্ত্বে কয়েকটি উপাদান ও কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                তিনটি উপাদান, (i) ব্যক্তি চাহিদা (ii) লক্ষ্য (iii) বাধা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    4. স্কিনার তার সক্রিয় অনুবর্তনের কৌশলটির কী নাম দিয়েছেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                স্কিনার তার সক্রিয় অনুবর্তনটির R-type অনুবর্তন নাম দিয়েছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    5. স্কিনার বাক্সে ইঁদুরের কাছে শক্তিদায়ক উদ্দীপক কোনটি?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                স্কিনার বাক্সে ইঁদুরের কাছে শক্তিদায়ক উদ্দীপক হল খাদ্য অর্জন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    4. কোন মনোবিদ তার পরীক্ষার সময় Time Curve ব্যবহার করেছিলেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                থর্নডাইক প্রথম তার পরীক্ষার সময় Time Curve ব্যবহার করেছিলেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    5. খাদ্যবস্তু নামক স্বাভাবিক উদ্দীপকের স্বাভাবিক
                    প্রক্রিয়াটি কী?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                খাদ্যবস্তু নামক স্বাভাবিক উদ্দীপকের স্বাভাবিক প্রক্রিয়াটি হল
                লালাক্ষরণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. অন্তদৃষ্টিমূলক শিখনের জনক কে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                অন্তদৃষ্টিমূলক শিখনের জনক হলেন কোহলার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    7. সক্রিয় অনুবর্তন কোন ধরনের স্নায়ুদ্বারা নিয়ন্ত্রিত হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                কেন্দ্রীয় স্নায়ুতন্ত্র দ্বারা সক্রিয় অনুবর্তন নিয়ন্ত্রিত
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. অপারেন্ট অনুবর্তনের মূল ভিত্তি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                অপারেন্ট অনুবর্তনের মূল ভিত্তি হল প্রাণীর সক্রিয়তা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. Gestal কথাটির অর্থ লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                গেস্টাল্ট শব্দটি একটি জার্মান শব্দ, যার অর্থ হল অবয়ব, সমগ্রতা,
                কাঠামো, আকার প্রভৃতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. গেস্টাল্টমতবাদের প্রবক্তা কারা?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                শিখন সম্পর্কে গেস্টান্ট মতবাদের প্রবক্তা হলেন—ওয়াদাইমার, কূট
                কাফকা এবং উলফ গ্যাং কোহলার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    11. থর্নডাইক কোন্ কোন্ প্রাণীর উপর তার প্রচেষ্টা ও ভুলের
                    তত্ত্বের প্রয়োগ করেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                থর্নডাইক তার প্রচেষ্টা ও ভুলের তত্ত্বের প্রয়োগ করেন কুকুর,
                বিড়াল, ইঁদুর, বাঁদর, মাছ, মুরগি প্রাণীদের উপর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    12. মনোবিদ ধর্নডাইক সংযোজনবাদ তত্ত্ব কোন্ বইয়ে প্রকাশ করেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                মনোবিদ ধর্নডাইক সংযোজনবাদ তত্ত্ব' Animal Intelligenci' গ্রন্থে
                প্রকাশ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    13. প্যাভেলভের অনুবর্তন পরীক্ষায় কাইমোগ্রাফ-এ কোন উপাদানের
                    পরিমাণ লিপিবদ্ধ করা হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                প্যাভেলভের অনুবর্তন পরীক্ষায় কাইমোগ্রাফ-এ লিপিবদ্ধ করা হয়
                লালাক্ষরণের মাত্রা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. ক্ল্যাসিকাল কন্ডিশনিং-এর অপর নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ক্ল্যাসিকাল কন্ডিশনিং-এর অপর নাম প্রাচীন অনুবর্তন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. S-R Bond-এর কথা কে বলেছেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                S-R Bond-এর কথা বলেছেন আইভান প্যাভলভ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. স্কিনারের মতে আচরণ কত প্রকার?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                স্কিনারের মতে আচরণ 2 প্রকার। (i) রেসপনডেন্ট (ii) অপারেন্ট।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    17. স্কিনারের অপারেন্ট অনুবর্তন তত্ত্বের একটি শিক্ষামূলক
                    প্রয়োগ উল্লেখ করো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                বিদ্যালয় এই নীতি প্রয়োগ করে শিক্ষার্থীর সঙ্গে পূর্ব প্রস্তুতির
                সঙ্গে উপযুক্ত নতুন আচরণ সৃষ্টিতে সহায়তা করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    18. বি. এফ. স্কিনার 'অপারেন্ট অনুবর্তন' কোন্ পত্রিকায়
                    প্রকাশ করেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                বি. এফ. স্কিনার ‘অপারেন্ট অনুবর্তন' Academy of Science পত্রিকায়
                প্রকাশ করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    19. বি. এফ. স্কিনার ‘অপারেন্ট অনুবর্তন' কোন প্রবন্ধে প্রকাশ
                    করেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                বি. এফ. স্কিনার অপারেন্ট অনুবর্তন “The concept of the reflex in
                the description of the behaviour" প্রবন্ধে প্রকাশ করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. সক্রিয়অনুবর্তন তত্ত্বের একটি ব্যর্থতা লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                সক্রিয় অনুবর্তন শিশুর ভাষা শিখন ব্যাখ্যা করতে পারে না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>21. Shapping কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                সক্রিয় অনুবর্তন তত্ত্বের প্রাণীর চেয়ে বিভিন্ন পর্যায়ের মধ্য
                দিয়ে অগ্রসর হয়ে জটিল প্রতিক্রিয়া সৃষ্টি করে তাকে শেপিং বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    22. থর্নডাইকের দেওয়া শিখনের যে কোনো একটি মুখ্য সূত্রের নাম
                    লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                থর্নডাইকের দেওয়া শিখনের একটি মুখ্য সূত্র হল ফললাভের সূত্র।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. মনোবিদ থর্নডাইকের প্রস্তুতির সুত্রটি লেখো।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                EL, Thorndik প্রস্তুতির সূত্রে বলেছেন, উদ্দীপক ও তার উপযোগী
                প্রতিক্রিয়ার মধ্যে সংযোগস্থাপনের জন্য ব্যক্তির মধ্যে দৈহিক
                প্রস্তুতি থাকা প্রয়োজন। দৈহিক প্রস্তুতি থাকলে যে কোনো ব্যক্তির
                পক্ষে কর্ম সম্পাদন করা তৃপ্তিদায়ক হবে। তার যদি প্রস্তুতি না
                থাকে তবে কাজটি তার কাছে বিরক্তিকর মনে হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. সামান্যীকরণ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                সামান্যীকরণ হল এমন একটি প্রণালী যা সাপেক্ষ প্রতিক্রিয়ার সঞ্চালন
                ঘটায় অন্য উদ্দীপককে এবং এই রূপান্তরিত উদ্দীপক মূল সাপেক্ষ
                উদ্দীপকের সমতুল্য হয়। একে সামান্যীকরণ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3.অপানুবর্তন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                ঘণ্টা ধ্বনির পর কুকুরকে যদি খাদ্য দেওয়া না হয় তাহলে কিছু দিন
                পরে, তার আর লালাক্ষরণ হয় না। এইভাবে অনুবর্তনের লোপ পাওয়াকে
                অপানুবর্তন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. শিখনের মানসিক প্রস্তুতির সূত্রটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                উদ্দীপক এবং প্রতিক্রিয়ার মধ্যে সার্থক সম্বন্ধ স্থাপন করতে হলে
                ব্যক্তির মানসিক প্রস্তুতির প্রয়োজন হয়। এই প্রস্তুতিকে এক
                মনোবিদ ধর্নডাইক শিখনের মানসিক প্রস্তুতির সূত্র বলেছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. পুনরুস্থাপন প্রক্রিয়া কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                অনুবর্তন প্রক্রিয়াকে সক্রিয় রাখার জন্য মাঝে মাঝে কৃত্রিম
                শক্তিদায়ী উদ্দীপককে প্রয়োগ করানো হয়। একে পুনরুত্থাপন বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. টাইম কার্ড কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                থর্নডাইক প্রচেষ্টা ও ভুলের শিখন কৌশলের পরীক্ষার ফলাফল লেখচিত্রের
                মাধ্যমে প্রকাশ করেন। থর্নডাইক পুনরাবৃত্তির সময়কে লেখচিত্রের
                সাহায্যে পরিবেশন করেন। পুনরাবৃত্তির সংখ্যা বাড়ার সঙ্গে সঙ্গে এই
                লেখ ক্রমশ নীচের দিকে নেমে আসে। একেই বলে টাইম কার্ড।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. ধর্নডাইকের মতবাদকে কেন সংযোজনবাদ বলা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                থর্নডাইকের মতে, উদ্দীপক ও প্রতিক্রিয়ার মধ্যে সংযোগ স্থাপনের
                মাধ্যমে শিখন সম্পন্ন হয়। সেজন্য থর্নডাইকের মতবাদকে সংযোজনবাদ
                বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. রেসপনডেন্ট বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                যে সমস্ত আচরণের উদ্দীপক পরিস্থিতির সম্পর্কে আমাদের ধারণা আছে বা
                যে সমস্ত আচরণকে আমরা নির্দিষ্ট বস্তুধর্মী উদ্দীপকের সংজ্ঞার
                সঙ্গে সংযুক্ত করতে পারি সেগুলিকে স্কিনার বলেছেন রেসপনডেন্ট।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. অনুবৰ্তন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                একটি স্বাভাবিক উদ্দীপকের সঙ্গে আর একটি কৃত্রিম উদ্দীপককে বারবার
                উপস্থিত করলে কৃত্রিম উদ্দীপকটি এক সময় স্বাভাবিক প্রতিক্রিয়া
                ঘটাতে সক্ষম হয়। একেই বলে অনুবর্তন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. ধর্নডাইকের দেওয়া ব্যবহারের সূত্রটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                থর্নডাইকের মত অনুসারে উদ্দীপক ও প্রতিক্রিয়ার সংযোগস্থাপনের পর
                যদি বারবার অনুশীলন করা হয়, তখন সংযোগের শক্তি বাড়বে এবং শিখন
                শক্তিশালী হবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. সক্রিয় আচরণ বলতে কী বোঝ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                স্কিনার সক্রিয় আচরণ বলতে বুঝিয়েছেন তা হল ইঁদুরটিরলিভারে চাপ
                দেওয়ার আচরণ খাদ্যবস্তু উদ্দীপকের দ্বারা সৃষ্টি হয় না। ঈপ্সিত
                বস্তুকে পাওয়ার জন্য প্রাণী সক্রিয় হয়ে উঠছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. গেস্টাল্ট মতবাদ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                'গেস্টান্ট' একটিজার্মান শব্দ, যার বাংলা প্রতিশব্দ হল 'অবয়ব'।
                গেস্টাল্ট মতবাদের মূলকথা হল — সমস্যামূলক পরিস্থিতিতে প্রাণী
                সামগ্রিকভাবে দেখে এবং সামগ্রিকভাবে প্রতিক্রিয়া করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. 'রেসপন্ডেট বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                নির্দিষ্ট উদ্দীপকের পরিপ্রেক্ষিতে যে আচরণ ঘটে, তাকে বলে
                প্রতিক্রিয়ামূলক আচরণ বা রেসপন্ডেন্ট।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. পাজল বাক্স কী? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                মনোবিদ থর্নডাইক তার প্রচেষ্টা ও ভুলের শিখন কৌশলের পরীক্ষায় যে
                যান্ত্রিক বাক্স ব্যবহার করেছিলেন তাই হল পাজল বক্স।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. অন্তদৃষ্টিমূলক শিখন বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                অন্তর্দৃষ্টিমূলক শিখন কৌশলে সমস্যামূলক পরিস্থিতিতে সমস্যাটি
                বিভিন্ন অংশের সঙ্গে পরিবেশের বিভিন্ন অংশের সম্পর্ক স্থাপন করে
                পরিস্থিতির সামগ্রিক রূপ লব্ধি করে সমস্যা সমাধানই হল
                অন্তদৃষ্টিমূলক শিখন কৌশল।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  16. অপারেন্ট অনুবর্তন ও প্রাচীন অনুবর্তনের দুটি পার্থক্য লেখো।
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                অপারেন্ট অনুবর্তন Type-II শিখন, প্রাচীন অনুবর্তন Type-I শিখন।
                অপারেন্ট অনুবর্তন কেন্দ্রীয় স্নায়ুতন্ত্র দ্বারা নিয়ন্ত্রিত,
                প্রাচীন অনুবর্তন স্বয়ংক্রিয় স্নায়ুতন্ত্র দ্বারা পরিচালিত হয়।{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. সক্রিয় আচরণ বলতে কী বোঝ?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                যে আচরণের ক্ষেত্রে কোনো সুনির্দিষ্ট উদ্দীপক থাকে না। যে কোনো
                উদ্দীপকের প্রভাবেই প্রাণী আচরণ করে। তাকে সক্রিয় আচরণ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. পুনরুস্থাপন প্রক্রিয়া কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                অনুবর্তন প্রক্রিয়াকে সক্রিয় রাখার জন্য মাঝে মাঝে কৃত্রিম
                শক্তিদায়ী উদ্দীপককে প্রয়োগ করানো হয়। একে পুনরুত্থাপন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. শিখনের মানসিক প্রস্তুতির সূত্রটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                উদ্দীপক এবং প্রতিক্রিয়ার মধ্যে সার্থক সম্বন্ধ স্থাপন করতে হলে
                ব্যক্তির মানসিক প্রস্তুতির প্রয়োজন হয়। এই প্রস্তুতিকে এক
                মনোবিদ ধর্নডাইক শিখনের মানসিক প্রস্তুতির সূত্র বলেছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. শিখনের মানসিক প্রস্তুতির সূত্রটি লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>{" "}
                </font>
                উদ্দীপক এবং প্রতিক্রিয়ার মধ্যে সার্থক সম্বন্ধ স্থাপন করতে হলে
                ব্যক্তির মানসিক প্রস্তুতির প্রয়োজন হয়। এই প্রস্তুতিকে এক
                মনোবিদ ধর্নডাইক শিখনের মানসিক প্রস্তুতির সূত্র বলেছেন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. শিক্ষাক্ষেত্রে প্রাচীন অনুবর্তন এর গুরুত্ব লেখো। অপানুবর্তন
                  কাকে বলে?
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                অনুবর্তন হল এক ধরনের শিখন প্রক্রিয়া। মানুষ স্বাভাবিকভাবেই
                উদ্দীপকের প্রভাবে সাড়া দেয় বা প্রতিক্রিয়া করে। Coleman-এর মতে
                'অনুবর্তন হল একটি শিখন প্রক্রিয়া যেখানে পরিবেশগত উদ্দীপক দ্বারা
                প্রাণীর আচরণ নিয়ন্ত্রিত হয়। Ivan Pavlov প্রাচীন অনুবর্তনবাদের
                প্রবক্তা। অনুবর্তনবাদের মূল কথা হল, স্বাভাবিক উদ্দীপকের দরুন
                স্বাভাবিক প্রতিক্রিয়া হয়। কিন্তু অনেক সময় কৃত্রিম উদ্দীপকের
                সাহায্যেও স্বাভাবিক প্রতিক্রিয়া ঘটানো সম্ভব। প্যাভলভ মনে করেন
                প্রাণীর সমস্ত শিখন অনুবর্তনের মাধ্যমেই হয়।
                <br />
                <br />
                <font color="#1f7405">
                  <b>শিক্ষাক্ষেত্রে প্রাচীন অনুবর্তন তত্ত্বের গুরুত্ব : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. সু-অভ্যাস গঠন : </b>{" "}
                </font>{" "}
                শিশুদের মধ্যে সু-অভ্যাস গঠনের ক্ষেত্রে প্রাচীন অনুবর্তন অত্যন্ত
                গুরুত্বপূর্ণ। যেমন - সকালে ঘুম থেকে ওঠা, পরিস্কার পরিচ্ছন্নতা,
                বয়স্কদের সম্মান প্রদর্শন, নির্দিষ্ট সময়ে পড়তে বসা ইত্যাদি। এই
                ধরনের সু-অভ্যাস শিশুকে শিক্ষা জীবনে, এমনকি ভবিষ্যতেও বিশেষভাবে
                সাহায্য করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. কু-অভ্যাস পরিত্যাগ : </b>{" "}
                </font>{" "}
                শিক্ষার্থীদের কু-অভ্যাস দূর করার জন্য বিয়োগাত্মক অনুবর্তনের
                সাহায্য নেওয়া হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. ভাষা শিক্ষা দান : </b>{" "}
                </font>{" "}
                ছোটো শিশুদের ভাষা শিক্ষা দেওয়ার ক্ষেত্রে প্রাচীন অনুবর্তনের
                ভূমিকা অনস্বীকার্য। যেমন একটি শিশুকে ‘গাছ’ দেখিয়ে গাছ শব্দটি
                উচ্চারণ করলে শিশুও অনুকরণ করে এই শব্দটি উচ্চারণ করবে। এভাবে শিশু
                বর্ণ ও বর্ণের আকৃতি, শব্দ রূপ ইত্যাদি ধীরে ধীরে শিখে থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. সামাজিক মূল্যবোধের বিকাশ : </b>{" "}
                </font>{" "}
                প্রাচীন অনুবর্তনের মাধ্যমে শিশু তথা শিক্ষার্থীরা সামাজিক
                মূল্যবোধ ও আচার-আচরণের শিক্ষা পায়। যেমন সততা, শ্রদ্ধাপূর্ণ
                মনোভাব, শিষ্টাচার, বন্ধুপ্রীতি ইত্যাদি।এ বিষয়গুলি সমাজেই নয়
                শিক্ষাক্ষেত্রেও ভীষণ গুরুত্বপূর্ণ। যা অনুবর্তনের দান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. বিষয়ের প্রতি ঘৃণা ও ভালোবাসা : </b>{" "}
                </font>{" "}
                অনুবর্তনের মাধ্যমে শিক্ষাক্ষেত্রে শিক্ষার্থীদের মধ্যে কোনো বিশেষ
                বিষয়ে ঘৃণা আবার কোনো বিষয়ে ভালোবাসার মনোভাব জাগ্রত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. সু-মনোভাব তৈরি : </b>{" "}
                </font>{" "}
                সমাজ, ব্যক্তি শিক্ষক বা শিক্ষা প্রতিষ্ঠানের প্রতি সুন্দর মনোভাব
                তৈরিতে সাহায্য করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. অনুবর্তিত প্রক্ষোভ দূরীকরণ : </b>{" "}
                </font>{" "}
                শিক্ষার্থীর উদ্বেগ বা ভয় বিয়োগাত্মক অনুবর্তনের মাধ্যমে দূর করা
                সম্ভব। মানসিক রোগীর উদ্বেগ দূর করার জন্য এই পদ্ধতি প্রয়োগ করা
                হয়। <br />
                <br />
                মনোবিদ প্যাভলভ অনুবর্তনের ক্ষেত্রে শুধুমাত্র পুনরাবৃত্তির ওপরই
                জোর দিয়েছেন। ফলে বুদ্ধি, দক্ষতা ইত্যাদি গুরুত্ব দেন নি। তবে এই
                ধরনের অল্পবিস্তর ত্রুটি থাকলেও শিখনের ক্ষেত্রে এই প্রাচীন
                অনুবর্তন তত্ত্ব একটি মাইল ফলকের মতো। শিশুদের শিখনের প্রাথমিক
                ক্ষেত্রে বিশেষভাবে এই তত্ত্বে প্রভাব লক্ষ্য করা যায়। শুধু তাই
                নয় এই শিখন তত্ত্ব শিশু তথা ব্যক্তির ভবিষ্যৎ জীবনকেও উজ্জ্বল
                করে। তাই সবদিক থেকে অনুবর্তন তত্ত্ব খুবই গুরুত্বপূর্ণশিক্ষা ও
                সমাজ উভয় ক্ষেত্রে।
                <br />
                <br />
                <font color="#1f7405">
                  <b>অপানুবর্তন : </b>{" "}
                </font>{" "}
                শক্তিদায়ক সত্ত্বা বা উদ্দীপকের অভাবে সময়ের ব্যবধানে অপর আচরণের
                শক্তি হ্রাস পেতে শুধু করে এই ঘটনাকে বলা হয় 'অপানুবর্তন'।
                কৃত্রিম উদ্দীপকের দীর্ঘ সময় ধরে স্বাভাবিক উদ্দীপক উপস্থাপন না
                করা হলে অনুবর্তিত প্রতিক্রিয়ার পরিমাণ ক্রমশ কমতে কমতে বন্ধ হয়ে
                যাবে, যা অপানুবর্তন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  2. শিখনের কৌশল হিসেবে স্কিনার বক্স কী? সংক্ষেপে স্কিনার বক্সের
                  পরীক্ষাটি বর্ণনা করো। <br />
                  অথবা,
                  <br /> স্কিনার বক্স কী? স্কিনারের সক্রিয় অনুবর্তন তত্ত্বের
                  ব্যাখ্যাটি সংক্ষেপে বর্ণনা করো। <br />
                  অথবা,
                  <br /> স্কিনার বক্স কাকে বলে? স্কিনারের সক্রিয় অনুবর্তনের
                  পরীক্ষাটি বর্ণনা করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                <font color="#2b9a09"> ⦿ </font>{" "}
                <font color="#f626d7">
                  <b> স্কিনার বক্স : </b>
                </font>
                প্রখ্যাত মনোবিদ তথা অপারেন্ট বা সক্রিয় অনুবর্তন তত্ত্বের
                প্রবক্তা স্কিনার তার পরীক্ষালব্ধ প্রতিক্রিয়া দেখার জন্য একটি
                বিশেষ বাক্স ব্যবহার করেন। একেই বলা হয় স্কিনার বক্স। এই বাক্সে
                একটি যন্ত্র নিয়ন্ত্রিত ট্রে থাকে। একটি বোতামের উপর চাপ পড়লেই
                ট্রে – তে খাবার চলে আসে। <br />
                <br />
                <font color="#2b9a09"> ⦿ </font>{" "}
                <font color="#f626d7">
                  <b> স্কিনার বক্সের / সক্রিয় অনুবর্তনের পরীক্ষা : </b>
                </font>{" "}
                যেকোনো প্রাণীর মানসিক অবস্থা অনুবর্তনের সময় উদ্দীপক ও
                প্রতিক্রিয়ার মধ্যে সংযোগ স্থাপনে শক্তি জোগায়। এই ধারণাটি হলো
                সক্রিয় অনুবর্তন। স্কিনারের মতে, প্রাণীর সাধারণ ধর্ম হলো
                উদ্দীপকের প্রতি প্রতিক্রিয়া করা। প্রাণীর কিছু কিছু
                প্রতিক্রিয়াকে বস্তুধর্মী নির্দিষ্ট উদ্দীপকের সঙ্গে সংযুক্ত করা
                যায় না। এই ধরনের প্রতিক্রিয়ার সঙ্গে উদ্দীপকের কীভাবে সংযোগ
                স্থাপিত হয়, স্কিনার সেই ব্যাখ্যাই দিয়েছেন তাঁর অপারেন্ট বা
                সক্রিয় অনুবর্তন তত্ত্বে। <br />
                <br />
                সক্রিয় অনুবর্তনের ব্যাখ্যায় স্কিনার বিভিন্ন পরীক্ষার বর্ণনা
                দিয়েছেন। এমন একটি পরীক্ষায় তিনি একটি ইঁদুরকে বাক্সে ঢুকিয়ে
                একটি ট্রে – তে খাদ্যবস্তু রাখেন। ইঁদুরটি ঐ খাবার খেয়ে নেয়।
                এরপর একদিন তিনি ইঁদুরটিকে বাক্সে ঢুকিয়ে বিশেষ এক যান্ত্রিক
                কৌশলে বোতাম টিপে ট্রে – র মধ্যে খাবার ফেলেন। এবারও ইঁদুরটি খাবার
                খেয়ে নিল। এবার তিনি ইঁদুরটিকে বাক্সে ঢুকিয়ে তার আচরণ
                পর্যবেক্ষণ করেন। ইঁদুরটি ট্রে – র মধ্যে খাবার না পেয়ে এদিক
                সেদিক খোঁজাখুজি করতে থাকে। <br />
                <br />
                এক পর্যায়ে ইঁদুরটি বোতাম টিপে দিল আর ট্রে – র মধ্যে খাবারও এসে
                পড়ল। এরপর ইঁদুরটি বোতাম টেপার প্রতিক্রিয়াটি করতে থাকে। এই
                প্রসঙ্গে স্কিনার বলেন, এই ধরনের প্রতিক্রিয়া খাদ্যবস্তুর উদ্দীপক
                দ্বারা নির্ধারিত হয়নি। এটি নির্ধারিত হয়েছে খাদ্যের প্রত্যাশার
                উত্তেজনায়। আর বাস্তবে এর কোনো অস্তিত্ব নেই। কোনো অজানা
                উদ্দীপকের ফলে প্রতিক্রিয়া করলে প্রাণী যদি একটি শক্তিদায়ক
                উদ্দীপক পায়, তাহলে সে পূর্বের প্রতিক্রিয়াই গ্রহণ করে। এটি
                সক্রিয় অনুবর্তনের মূল কথা।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  4. থর্নডাইকের শিখনের মূল সূত্রগুলি কী? শিক্ষাক্ষেত্রে যেকোনো
                  দু’টি মূল সূত্রের গুরুত্ব লেখো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                মনোবিদ থর্নডাইক তাঁর পাজল বক্সে বিড়ালকে রেখে এক যুগান্তকারী
                পরীক্ষা করেছিলেন। ঐ পরীক্ষার ভিত্তিতে শিখনপ্রক্রিয়া সম্পর্কে
                তিনি বেশ কয়েকটি সিদ্ধান্ত গ্রহণ করেন। দীর্ঘ গবেষণার পর থর্নডাইক
                শিখন সংক্রান্ত তিনটি প্রধান সূত্র ও পাঁচটি অপ্রধান সূত্র উদ্ভাবন
                করেন। <br />
                <br />
                <font color="#1f7405">
                  <b>থর্নডাইকের শিখনের মূল সূত্র : </b>{" "}
                </font>{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. অনুশীলনের সূত্র : </b>{" "}
                </font>{" "}
                অনুশীলনের সূত্র অনুযায়ী শিখতে গেলে অনুশীলন প্রয়োজন। এই
                পদ্ধতিতে শিখনপ্রক্রিয়া ক্রমবিকাশমান। <br />
                <br />
                <font color="#f626d7">
                  <b>2. প্রস্তুতির সূত্র : </b>{" "}
                </font>{" "}
                প্রচেষ্টা – ভুলের কৌশলে শিক্ষার্থীদের জৈবমানসিক প্রস্তুতি
                প্রয়োজন। শিক্ষার্থীর আগ্রহ বা প্রেষণা প্রচেষ্টা ও ভুলের কৌশলের
                একটি অত্যন্ত প্রয়োজনীয় উপাদান। <br />
                <br />
                <font color="#f626d7">
                  <b>3. ফললাভের সূত্র : </b>{" "}
                </font>{" "}
                প্রচেষ্টা ও ভুলের কৌশলে কোন প্রক্রিয়া গ্রহণ করা হবে বা কোন
                প্রক্রিয়া বর্জন করা হবে , তা প্রাপ্ত ফলের দ্বারা নির্ধারিত হয়।
                এক্ষেত্রে যে ফল সুখদায়ক সেই প্রতিক্রিয়া গৃহীত হয় এবং যে
                প্রতিক্রিয়া বেদনাদায়ক তা পরিত্যক্ত হয়। <br />
                <br />
                <font color="#1f7405">
                  <b>শিক্ষাক্ষেত্রে অনুশীলন সূত্রের গুরুত্ব : </b>{" "}
                </font>
                1. শ্রেণিকক্ষে শিক্ষক জটিল ও নতুন অংশগুলি বারবার উপস্থাপন করেন।
                এছাড়া আলোচনা, বিতর্কসভা, শিক্ষামূলক ভ্রমণ ইত্যাদির মাধ্যমে
                অনুশীলনের সুপারিশ করেন। <br />
                <br />
                2. পঠিত বিষয় কিছু সময়ের ব্যবধানে শিক্ষক আবার আলোচনা করবেন।{" "}
                <br />
                <br />
                3. শিক্ষক ছাত্র – ছাত্রীদের পাঠনপাঠনের সময় জানা থেকে অজানা
                বিষয়ে নিয়ে যাওয়ার নীতি গ্রহণ করবেন। এক্ষেত্রে তাকে অতীত
                অভিজ্ঞতার সঙ্গে যুক্ত করারও চেষ্টা করতে হবে। <br />
                <br />
                4. ভুল ও অপ্রয়োজনীয় অংশগুলি যাতে প্রথম সুযোগেই বাদ দেওয়
                শিক্ষক – শিক্ষার্থী উভয়কেই সেদিকে নজর দিতে হবে। <br />
                <br />
                <font color="#1f7405">
                  <b>শিক্ষাক্ষেত্রে ফললাভের সূত্রের গুরুত্ব : </b>{" "}
                </font>
                1. শিখন যদি শিক্ষার্থীকে আনন্দ ও তৃপ্তি দেয়, তাহলে শিক্ষার্থীর
                মধ্যে স্বাভাবিক প্রেরণা দেখা যাবে। এজন্য বিষয়বস্তু সহজ ও
                বোধগম্য হওয়া চাই। <br />
                <br />
                2. শিক্ষার্থী তার রোজকার জীবনের সঙ্গে শিক্ষণীয় বিষয়ের মিল
                খুঁজে পেলে শিখনে আগ্রহী হয়। <br />
                <br />
                3. শিক্ষার্থীর সামনে বিষয়বস্তুকে সহজ থেকে কঠিন নীতিতে উপস্থাপন
                করতে হবে। এভাবে শিক্ষার্থী সহজে বিষয়বস্তু আয়ও করতে পারবে।{" "}
                <br />
                <br />
                4. শিক্ষার্থীদের সামনে শিক্ষণীয় বিষয়কে প্রতিকর, সহজ ও
                আকর্ষণীয় করে উপস্থাপন করতে হবে। <br />
                <br />
                5. পঠনপাঠনের ক্ষেত্রে মাঝে মধ্যে শিক্ষক যদি শিক্ষার্থীদের
                প্রশংসা করেন বা পুরস্কার প্রদান করেন তাহলে তাদের মধ্যে উৎসাহ
                বাড়ে। এর কার্যকারিতা প্রমাণিত হয়েছে।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  5. স্কিনারের সক্রিয় অনুবর্তন তত্ত্বের পরীক্ষাটি বর্ণনা করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর : </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                মনোবিদ বি. এফ. স্কিনার (B. F. Skinner) অনুবর্তনের উপর
                পরীক্ষা-নিরীক্ষা করে এক বিকল্প মতবাদ প্রচার করেন। তিনি বলেন-
                অনুবর্তন হয় ঠিকই কিন্তু অনুবর্তনের ফলে উদ্দীপকের সঙ্গে
                প্রতিক্রিয়ার সংযোগ ঘটে না। আসলে একটি বিশেষ মানসিক অবস্থার সঙ্গে
                প্রতিক্রিয়ার সংযোগ ঘটে। তাঁর মতে, অনুবর্তনে প্রাণীর নিজস্ব
                মানসিক সক্রিয়তা গুরুত্বপূর্ণ ভূমিকা গ্রহণ করে। তিনি আচরণকে দুটি
                ভাগে বিভক্ত করেন —<br />
                <br />
                <font color="#f626d7">
                  <b>(i) রেসপনডেন্ট : </b>
                </font>{" "}
                যে আচরণগুলির উদ্দীপক পরিস্থিতি সম্পর্কে আমাদের পূর্বেই ধারণা
                আছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(ii) অপারেন্ট : </b>
                </font>{" "}
                যে আচরণগুলি স্বতঃস্ফূর্ত, অর্থাৎ কোনো নির্দিষ্ট উদ্দীপক দ্বারা
                পরিচালিত হয়।
                <br />
                <br />
                <font color="#2b9a09"> ⦿ </font> স্কিনারের মতে, শিখনের সময়
                নিজের ইপ্সিত লক্ষ্যে পৌঁছানোর জন্য প্রাণী ও পরিবেশের মধ্যে
                সক্রিয়তা গড়ে ওঠে এবং এই সক্রিয়তার মধ্যে থেকে একটি আচরণ
                স্বতঃস্ফূর্তভাবে বেরিয়ে আসে। <br />
                <br />
                <font color="#2b9a09"> ⦿ </font> স্কিনারের পরীক্ষা: স্কিনার
                দীর্ঘ দিন ধরেইঁদুর ও পায়রা নিয়ে বহু পরীক্ষা-নিরীক্ষা করেন। তবে
                তিনি পরীক্ষার ফল থেকে যে যুগান্তকারী শিখনতত্ত্ব দেন সেটি বর্ণনা
                করা হল স্কিনার প্রাণীদের আবর্ত প্রতিক্রিয়া অশীলনের জন্য বিশেষ
                ধরনের পরীক্ষা কৌশল এবং যান্ত্রিক উপকরণ ব্যবহার করেন।
                <br />
                <br />
                <font color="#2b9a09"> ⦿ </font> স্কিনার যে পাজল বক্সটি ব্যবহার
                করেন সেটিকে বর্তমানে স্কিনার বক্স বলা হয়। এই বক্সে প্রাণীর আচরণ
                রেকর্ড করার বৈদ্যুতিক ব্যবস্থাথাকে। এই বক্সে একটি বোতাম আছে যেটি
                সক্রিয় করতে পারলে বক্সের ভিতরে রাখা ট্রে-তে খাদ্যবস্তু চলে আসে।
                এই বক্সে একটি মাত্র প্রতিক্রিয়ার সুযোগ থাকে। <br />
                <br />
                <font color="#2b9a09"> ⦿ </font> তিনি একটি ক্ষুধার্ত ইঁদুরকে
                পরীক্ষামূলক বক্সে প্রবেশ করান। এই সময় বাইরে থেকে বক্সের মধ্যে
                রাখা ট্রেতে কিছু খাদ্যবস্তু দেওয়া হয়। ইঁদুরটি ট্রে থেকে খাদ্য
                বস্তুগ্রহণ করে।
                <br />
                <br />
                <font color="#2b9a09"> ⦿ </font> পরবর্তী পর্যায়ে অপর একদিন
                ইঁদুরটিকে ওই বক্সে ঢোকানোর পর যান্ত্রিক কৌশলে অর্থাৎ বোতাম টিপে
                খাদ্য গ্রহণ করে। এই পর্যায়ে ইঁদুরটি পরীক্ষামূলক পরিস্থিতির
                বিভিন্ন দিকের সঙ্গে পরিচিত হয়। <br />
                <br />
                <font color="#2b9a09"> ⦿ </font> তৃতীয় পর্যায়ে একদিন ইঁদুরটিকে
                ক্ষুধার্ত অবস্থায় বক্সের মধ্যে ঢুকিয়ে দিয়ে তার আচরণ
                পর্যবেক্ষণ করা হয়। ইঁদুরটি প্রথমেই খাদ্যের খোঁজে ট্রের দিকে
                দৌড়ে যায়। কারণ এর আগে সে ট্রের মধ্যে খাদ্য পেয়েছে। কিন্তু
                এবার ট্রেতে খাদ্য না পেয়ে সে খাদ্যের খোঁজে বক্সের মধ্যে
                ছোটোাছুটি করতে থাকে। ছোটোছুটি করার ফলে এক সময় ইঁদুরটি বোতামে
                চাপ দেয়। ফলে ট্রে-তে খাবার এসে যায় এবং ইঁদুরটি খাবার খায়।{" "}
                <br />
                <br />
                <font color="#2b9a09"> ⦿ </font> পরবর্তী পর্যায়ে ইঁদুরটিকে
                বক্সে ঢুকিয়ে দেওয়ার পর দেখা গেল সেটি বোতামে চাপ দিয়ে ট্রেতে
                খাবার নিতে আসতে পারছে।
                <br />
                <br />
                স্কিনার প্রাচীন প্যাভলভীয় অনুবর্তনের নাম দিয়েছেন S-type
                অনুবর্তন। এই ধরনের আচরণের সংখ্যা মানুষের দৈনন্দিন জীবনে খুবই কম।
                অপারেন্ট শ্রেণির আচরণকে স্কিনার বলেছেন R-type অনুবর্তন।
                এক্ষেত্রে প্রাণী সক্রিয় থাকে, তেমনি তার মানসিক অবস্থাও
                এক্ষেত্রে শক্তি জোগায়। এই আচরণের শেষে ফল প্রাপ্তির সুযোগ থাকে।
                স্কিনারের এই মতাবাদই বর্তমানে শিখনের অপারেন্ট অনুবর্তনের তত্ত্ব
                নামে পরিচিত।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

শিখনেরকৌশল.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(শিখনেরকৌশল);
