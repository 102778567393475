import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function জলনির্গমপ্রণালী() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          জলনির্গম প্রণালী | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="জলনির্গম প্রণালী | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                জলনির্গম প্রণালী
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#f626d7">
                ১. জাফরিরূপী জলনির্গম প্রণালী কোথায় দেখা যায় ?
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5">
                {" "}
                আমেরিকা যুক্তরাষ্ট্রের উত্তর-পূর্বাংশে অ্যাপলােশিয়ান উচ্চভূমির
                রিজ অ্যান্ড ভ্যালি’ নামক ভূপ্রাকৃতিক বিভাগে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                ২. ড্রামলিন অধ্যুষিত অঞ্চলে কী জাতীয় নদী প্রণালী দেখা যায় ?
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5"> জাফরিরূপী জলনিৰ্গম প্রণালী।</font>
              <br />
              <br />

              <font color="#f626d7">
                ৩. আয়তাকার জলনিৰ্গম প্রণালীর একটি বৈশিষ্ট্য লেখাে।
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5">
                {" "}
                মূল নদীতে একাধিক সমকোণী বাঁধ লক্ষ করা যায়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                ৪. নিমজ্জনশীল নদীর একটি বৈশিষ্ট্য লেখাে।
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5">
                {" "}
                নিমজ্জনশীল নদীর দৈর্ঘ্য সর্বদা ক্রমহ্রাসমান হয়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                ৫. কোনাে নদীর উচ্চ অববাহিকায় কীরূপ জল নির্গমন প্রণালী দেখা
                যায়?
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5"> বৃক্ষরূপী জলনির্গম প্রণালী।</font>
              <br />
              <br />

              <font color="#f626d7">
                ৬. কোন্ ধরনের শিলার উপর উপবৃক্ষরূপী জলনির্গম প্রণালী গড়ে ওঠে?
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5">
                {" "}
                স্বল্প নতিযুক্ত ও সূক্ষ্ম গ্ৰথনযুক্ত একনত গঠনযুক্ত শিলার উপর
                গড়ে ওঠে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                ৭. কোন্ ধরনের জলনির্গম প্রণালীতে জলধারাগুলি পরস্পর সুক্ষ্মকোণে
                মিলিত হয়?
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5"> পিনেট জলনির্গম প্রণালীতে।</font>
              <br />
              <br />

              <font color="#f626d7">
                ৮. নিম্ন বদ্বীপে নদীর অ্যানাস্টমজিং শাখা কোন্ জলনির্গম?
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5"> শাখানদী সমন্বিত নদী নকশা।</font>
              <br />
              <br />

              <font color="#f626d7">
                ৯. কোন্ জলনির্গম প্রণালীর ক্ষেত্রে আর্দ্র ঋতু ছাড়া নদীগুলিতে
                জলপ্রবাহ থাকে না?
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5"> সমান্তরাল জলনির্গম প্রণালীতে।</font>
              <br />
              <br />

              <font color="#f626d7">
                ১০. হিমবাহের গ্রাবরেখা সর্ণিত অঞ্চলে সমান্তরালভাবে গার্ল
                গ্রাবরেখা থাকলে কী জাতীয় জলনিৰ্গম গ্ৰণালী গড়ে ওঠে?
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5"> সমান্তরাল জলনিৰ্গম প্রণালী।</font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>১১. ক্ষয়ের কোন্ পর্যায়ে নদী নকশা সম্পূর্ণ হয় ?</b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5">ক্ষয়চক্রের পরিণত অবস্থায়।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১২. জলনির্গম প্রণালীর নিয়ন্ত্রকগুলি কী কী?</b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5">
                ভূনিম্নস্থ গঠন, ভূমির প্রারম্ভিক ঢাল, শিলার কাঠিন্য, ফাটল এবং
                চ্যুতির অবস্থান ও প্রকার ইত্যাদি।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৩. ভারতের একটি উল্লেখযােগ্য জলবিভাজিকার নাম লেখাে।</b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5"> পশ্চিমঘাট পর্বত।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ১৪. ১৯৩২ খ্রিস্টাব্দে ‘Journal of Geology' পত্রিকায়
                  নদীবিন্যাসের ব্যাখ্যা দেন কোন বিজ্ঞানী?
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5"> নদী ভৌগােলিক ই আর জারনিথ।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৫. কোন্ জলবায়ু অঞ্চলে কোনাে নদী নকশা গড়ে ওঠে না?</b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5"> চিরতুষারাবৃত অঞ্চলে।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৬. কোন্ প্রাকৃতিক পরিবেশে কোনাে নদী নকশা গড়ে ওঠে না?</b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5">
                কোনাে নদীর উপনদী, শাখানদী না থাকলে, নদীতে জলের পরিমাণ কম হলে নদী
                নকশা গড়ে ওঠে না।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৭. অনুগামী নদীর অপর নাম কী?</b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5"> প্রাথমিক নদী বা নতি নদী। </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ১৮. ভূমির প্রারম্ভিক ঢাল অনুসারে গড়ে ওঠা নদী কী নামে পরিচিত?
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5"> অনুগামী নদী।</font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৯. সমান্তরাল জলনিৰ্গম প্রণালী ভারতের কোথায় দেখা যায় ?</b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5">
                {" "}
                শিবালিক পর্বতের দক্ষিণ ঢাল, ছােটোনাগপুর মালভূমির ভৃগু ঢাল,
                নীলগিরি পর্বতের পূর্ব ঢাল, হাজারিবাগ মালভূমির প্রান্তদেশ প্রভৃতি
                স্থানে এরূপ জলনির্গম প্রণালী দেখা যায়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>
                  ২০. সমান্তরাল জলনির্গম প্রণালীর ক্ষেত্রে কীসের প্রাধান্য লঞ্চ
                  করা যায়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>
              <font color="#3c92e5"> ঢালের প্রাধান্য।</font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ২১. সমান্তরাল ও পরম্পর লম্বভাবে ছেদ করা দারাণের উপর গড়ে ওঠা
                  নদী ব্যবস্থাকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5"> জাফরি জলনিৰ্গম প্রণালী।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>২২. ভূগঠনের সঙ্গে সংগতিহীন নদীব্যবস্থা কী নামে পরিচিত?</b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                অসংগত নদী বা ডিসকর্ডান্ট নদী বা ইনকনসিকোয়েন্ট নদী।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>২৩. অসম কৌণিক জলনিৰ্গম প্রণালী কীভাবে গড়ে ওঠে?</b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                ভূনিম্নস্থ শিলাস্তর দারণ বা চ্যুতি দ্বারা অতিমাত্রায়
                নিয়ন্ত্রিত হলে অসম কৌণিক জলনির্গম প্রণালী গড়ে ওঠে।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ২৪. মূল নদীর সঙ্গে উপনদীগুলি সূক্ষ্মকোণে মিলিত হয়ে পাখির
                  পালকের মতাে যে জলনিৰ্গম প্রণালী গঠন করে, তাকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5"> চুনট বা পিনেট নদীনির্গম প্রণালী।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ২৫. মরু অঞ্চলে ও চুনাপাথর গঠিত অঞলে প্লায়া ও সিঙ্কহােল বরাবর
                  কোন্ নদী প্রণালী দেখতে পাওয়া যায়?
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5"> কেন্দ্রমুখী নদী প্রণালী।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>২৬. কেন্দ্রমুখী জলনির্গম প্রণালীর দুটি উদাহরণ দাও।</b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                আফ্রিকার চাদ হ্রদ, এশিয়ার লপনর হ্রদ।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ২৭. কেন্দ্রীয় অংশ থেকে ঢালকে অনুসরণ করে চতুর্দিকে প্রবাহিত
                  নদী বিন্যাসকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5"> কেন্দ্রবিমুখ নদী বিন্যাস।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>২৮. অঙ্গরীয় জলনির্গম প্রণালীকে কী বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">অসমকৌণিক জলনির্গম প্রণালী।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ২৯. আমেরিকা যুক্তরাষ্ট্রের উটারাজ্যের হেনরি পাহাড়ে কী জাতীয়
                  জলনির্গম প্রণালী দেখা যায় ?
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5"> অঙ্গুরীয়াকার জলনিৰ্গম প্রণালী।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ৩০. ঘর্ঘরার উপনদী রাপ্তির ঊর্ধ্বপ্রবাহে কী জাতীয় জলনির্গম
                  প্রণালী গড়ে উঠেছে?
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5"> হেরিংবােন জলনির্গম প্রণালী।</font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>৩১. গম্বুজ ভূ-গঠনে কোন ধরনের নদী নকশা গঠিত হয় ? </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                গম্বুজ ভু-গঠনে কেন্দ্রবিমুখ নদী নকশা গঠিত হয়।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ৩২. ঝিলাম নদীর উর্ধ্বপ্রবাহপথে কী জাতীয় জলনির্গম প্রণালী গড়ে
                  ওঠে?
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5"> হেরিংবােন জলনির্গম প্রণালী।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৩৩. বিশৃঙ্খল জলনিৰ্গম প্রণালীর একটি বৈশিষ্ট্য লেখাে।</b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                বিশৃঙ্খল জলনিৰ্গম প্রণালী ভূনিম্নহিত অনিয়মিত ভূগঠনের জন্য গড়ে
                ওঠে।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ৩৪. কারাকোরাম পর্বতের হৈমবাহিক উপত্যকায় কী জাতীয় জলনিৰ্গম
                  প্রণালী দেখা যায় ?
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5"> বিশৃঙ্খল নদী।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ৩৫. শিবালিক পর্বতের ঢালে যে জলনিৰ্গম প্রণালী গড়ে উঠেছে সেটি
                  কী ?
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5"> সমান্তরাল।</font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৩৬. 'জাফরিরুপী' নামকরণ কে করেছিলেন ? </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                বেইলি উইলিস 'জাফরিরুপী' নামকরণ করেছিলেন।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ৩৭. সিঙ্কহোল এবং প্লায়া হ্রদ অঞ্চলে কি ধরনের জলনির্গম প্রণালী
                  দেখা যায় ?{" "}
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                সিঙ্কহোল এবং প্লায়া হ্রদ অঞ্চলে কেন্দ্রমুখী জলনির্গম প্রণালী
                দেখা যায়।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ৩৮. শ্রীলঙ্কায় প্রধানত কেন্দ্রবিমুখ জলনির্গম প্রণালী দেখা
                  যায় কেন?{" "}
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                মধ্যভাগে উচ্চভূমি অবস্থানের কারণে শ্রীলঙ্কায় প্রধানত
                কেন্দ্রবিমুখ জলনির্গম প্রণালী দেখা যায়।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ৩৯. দাক্ষিণাত্য মালভূমির অধিকাংশ স্থানে কোন ধরনের জলনির্গম
                  প্রণালী দেখা যায় ?{" "}
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                দাক্ষিণাত্য মালভূমির অধিকাংশ স্থানে আয়তাকার জলনির্গম প্রণালী
                দেখা যায়
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৪০. নদী নকশা কোন পর্যায়ে পরিপূর্ণ রূপ পায় ? </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                নদী নকশা পরিণত পর্যায় নদী নকশা পরিপূর্ণ রূপ পায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  ৪১. কোন জলনির্গম প্রণালী একসঙ্গে অনুগামী নদী, পরবর্তী নদী,
                  বিপরা নদী ও পুনর্ভবা নদীর মিলিত প্রবাহে সৃষ্টি হয় ?
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                জাফরিরুপী জলনির্গম প্রণালী একসঙ্গে অনুগামী নদী, পরবর্তী নদী,
                বিপরা নদী ও পুনর্ভবা নদীর মিলিত প্রবাহে সৃষ্টি হয়।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ৪২. আগ্নেয়গিরির জ্বালামুখে কোন প্রকার জলনির্গম প্রণালী দেখা
                  যায় ?{" "}
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                আগ্নেয়গিরির জ্বালামুখে কেন্দ্রমুখী জলনিগম প্রণালী দেখা যায়।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৪৩. পূর্ববর্তী নদীর উদাহরণ দাও ? </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                ভিস্তা, ব্রহ্মপুত্র, সিন্ধু ও শতদ্রু প্রকৃতি নদী হয় পূর্ববর্তী
                নদী।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৪৪. অধ্যারোপিত নদীর উদাহরণ দাও?</b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                রাজস্থানের চম্বল নদী ও মধ্যপ্রদেশের শোন নদী হয় অধ্যারোপিত নদী।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ৪৫. নদীগ্লাসের মাধ্যমে কোন প্রকার জলনির্গম প্রণালী গড়ে ওঠে ?{" "}
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                নদীগ্লাসের মাধ্যমে বরশি বা আঁকশি আকৃতি জলনির্গম প্রণালী গড়ে
                ওঠে।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ৪৬. ড্রামলিন অধ্যুষিত অঞ্চলে কি জাতীয় জলনির্গম প্রণালী দেখা
                  যায় ?{" "}
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                ড্রামলিন অধ্যুষিত অঞ্চলে জাফরিরুপী জলনির্গম প্রণালী দেখা যায়।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৪৭. জলনির্গম প্রণালীর দুটি নিয়ন্ত্রকের নাম লেখো ? </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                ভূনিম্নস্থ গঠন ও ভূমির প্রারম্ভিক ঢাল হয় জলনির্গম প্রণালী
                গুরুত্বপূর্ণ নিয়ন্ত্রক।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৪৮. সমনত ভু-গঠনে কোন ধরনের নদী নকশা গঠিত হয়? </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                সমনত ভু-গঠনে জাফরিরুপী নদী নকশা গঠিত হয়।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>
                  ৪৯. ভাঁজযুক্ত বা ভঙ্গিল ভু-গঠনে কোন ধরনের নদী নকশা গড়ে ওঠে ?{" "}
                </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                ভাঁজযুক্ত বা ভঙ্গিল ভু-গঠনে জাফরিরুপী ও কেন্দ্রমুখী নদী নকশা
                গড়ে ওঠে।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৫০. চ্যুতি ভু-গঠনে কোন ধরনের নদী নকশা গঠিত হয় ? </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                চ্যুতি ভু-গঠনে আয়তাকার ও হেরিংবোন নদী নকশা গঠিত হয়।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৫১. একনত ভু-গঠনে কোন ধরনের নদী নকশা গড়ে ওঠে ? </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                একনত ভু-গঠনে জাফরিরুপী নদী নকশা গড়ে ওঠে।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৫২. অনুভূমিক ভু-গঠনে কোন ধরনের নদী নকশা গড়ে ওঠে ? </b>
              </font>
              <br />
              <font color="#2a9a08">উত্তর : </font>{" "}
              <font color="#3c92e5">
                {" "}
                অনুভূমিক ভু-গঠনে বৃক্ষরুপী ও সমান্তরাল নদী নকশা গড়ে ওঠে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>১. নদীর সংজ্ঞা দাও?</b>
              </font>
              <br />
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                উচ্চ পর্বত বা মালভূমি থেকে বৃষ্টি বা হিমবাহ গলা জল একটি
                স্বাভাবিক জলধারা রূপে নির্দিষ্ট থাতের মধ্যে দিয়ে ভূমিঢাল
                অনুসারে প্রবাহিত হয়ে সমুদ্র বা জলাশয়ে মিলিত হয় তাকে নদী বলে।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>২. প্রধান নদী কাকে বলে?</b>
              </font>
              <br /> <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভূমির প্রধান ঢাল অনুসারে বয়ে যায়, গতিপথে দুপাশ থেকে উপনদীর জল
                এসে মিলিত হয়, শাখানদী বের হয় এবং মোহনা পর্যন্ত সমস্ত জল কে বহন
                করে, তাকে প্রধান নদী বলে।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৩. উপনদী কাকে বলে ?</b>
              </font>
              <br /> <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ছোট ছোট নদী অপ্রধান ঢাল বরাবর বয়ে এসে প্রধান নদীতে মিলিত হয়ে
                জলের যোগান দেয়, তাকে উপনদী বলে।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৪. শাখানদী কাকে বলে ?</b>
              </font>
              <br /> <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                নিম্ন প্রবাহের প্রধান নদী থেকে শাখা-প্রশাথার আকারে বেরিয়ে
                সমুদ্রে মিলিত হয় তাকে শাখা নদী বলে।
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b>৫. নিত্যবহ নদী কাকে বলে?</b>
              </font>
              <br /> <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                হিমবাহ গলা জল বা সারাবছর বৃষ্টির জলে পুষ্ট এবং সারা বছর জল থাকে
                তাকে নিত্যবহ নদী বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>৬. অনিত্যবহ নদী কাকে বলে ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                যেসব নদী শুধুমাত্র বৃষ্টির জলে পুষ্ট এবং শুষ্ক ঋতুতে শুকিয়ে
                যায়, তাকে অনিত্যবহ নদী বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৭. অনুগামী নদী কাকে বলে ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভূমির প্রাথমিক ঢাল অনুযায়ী এবং শিলাস্তরের নতিকে অনুসরণ করে যে
                নদী প্রবাহিত হয়, তাকে অনুগামী নদী বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৪. পরবর্তী বা আয়াম নদী কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে সমস্ত নদী আয়াম বরাবর দুর্বল শিলাস্তর কে অনুসরণ করে প্রবাহিত
                হয়ে অনুগামী নদীতে মিলিত হয়, তাকে পরবর্তী নদী বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>৯. উপ-অনুগামী বা নতি নদী কাকে বলে ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                পরবর্তী নদী সৃষ্টির পর ভূমির প্রাথমিক ঢাল ও শিলাস্তরের নতি
                অনুসরণ করে যেসব ছোট নদী অনুগামী নদীর প্রবাহের অভিমুখে প্রবাহিত
                হয়ে পরবর্তীতে মিলিত হয়, তাদের উপ-অনুগামী বা নতি নদী বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১০. বিপরা নদী কাকে বলে ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                যেসব নদী ভূমির প্রারম্ভিক ঢালের বিপরীতে, অর্থাৎ শিলাস্তরের নদীর
                বিপরীত দিকে প্রবাহিত হয় তাদের বিপরা নদী বা বি-নতি নদী বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>১১. পুনর্ভব নদী কাকে বলে ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভাঁজ যুক্ত অঞ্চলে প্রথম স্কয়চক্রের পরে যেসব নদী অনুগামী নদীর
                দিকেই শিলাস্তরের নতি অনুসারে অপেক্ষাকৃত নিম্ন উচ্চতায় প্রবাহিত
                হয়, তাকে পুনর্ভব নদী বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১২. পূর্ববর্তী নদী কাকে বলে ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                কোন নদী সেই অঞ্চলের ভূ-উত্থানের সঙ্গে সামঞ্জস্য রেখে দ্রুত নিম্ন
                ক্ষয়ের মাধ্যমে তার পূর্বেকার প্রবাহ যদি বজায় রাখে, তাহলে সেই
                নদীকে পূর্ববর্তী নদী বলা হয়।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৩. অধ্যারোপিত নদী কাকে বলে?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                নবীনতর শিলাস্তরের উপর গঠিত কোন নদী ব্যবস্থা ক্ষয়ের মাধ্যমে
                পূর্বেকার প্রাচীন শিলাস্তরের ওপর প্রতিস্থাপিত হয়ে তার বর্তমান
                গতিপথ বজায় থাকলে তাকে অধ্যারোপিত নদী বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৪. অসংগত নদী কাকে বলে ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভূ-অভ্যন্তরীণ গঠনের সঙ্গে কোন সামঞ্জস্য না রেখে যেসব নদী
                প্রবাহিত হয় তাদের অসংগত নদী বলে।
              </font>
              <br />
              <br />

              <font color="#f626d7">
                <b>১৫. নদী অববাহিকা কাকে বলে ?</b>
              </font>
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                কোন সুবস্টিত অঞ্চলে প্রধান নদী ও তার উপনদী এবং শাখানদী মিলিতভাবে
                যে অঞ্চলের ওপর দিয়ে প্রবাহিত হয়, তাকে নদী অববাহিকা বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>১. জলনির্গম প্রণালী বা নদী নকশার সংজ্ঞা দাও ?</b>
              </font>
              <br />
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                একটি অববাহিকার মধ্যে প্রধান নদী ও তার উপনদী গুলি ভূতাত্বিক,
                ভূমিরূপগত ও অন্যান্য প্রাকৃতিক কারণে প্রভাবিত হয়ে নদীজালিকার যে
                জ্যামিতিক আকৃতি গঠন করে, তাকে নদী নকশা বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>২. বৃক্ষরুপী নদী নকশার সংজ্ঞা দাও?</b>
              </font>
              <br />
              <br />
              <font color="#2a9a08">
                <b>উত্তর : </b>
              </font>
              <font color="#3c92e5">
                {" "}
                প্রধানত ভূমির ঢালের দ্বারা প্রভাবিত হয়ে যে নদীজালিকায় মূলনদীর
                সঙ্গে তার উপনদী গুলি সূক্ষ্মকোণী মিলিত হয় এবং একটি গাছের আকার
                ধারণ করে তাকে বৃক্ষরুপী নদী নকশা বলে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

জলনির্গমপ্রণালী.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(জলনির্গমপ্রণালী);
