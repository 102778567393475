import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function কারণ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          কারণ | West Bengal Class 12 Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="কারণ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                কারণ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>‘পরিমাণের দিক থেকে কারণ হল কার্যের সমান'— বলেছেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোপি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বেইন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  কার্ভেস রিড
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>যে শর্ত উপস্থিত না থাকলে কার্যটি ঘটতে পারে না, তাকে বলে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আবশ্যিক শর্ত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পর্যাপ্ত শর্ত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নঞর্থক শর্ত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সব ক-টি ঠিক
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  যে তর্কবিজ্ঞানী কারণকে পর্যাপ্ত শর্ত অর্থে গ্রহণ করেছেন তার
                  নাম -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোপি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বেইন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কার্ভেস রিড
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সুইচ দেওয়া পাখা চলার কোন শর্ত?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আবশ্যিক পর্যাপ্ত শর্ত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আবশ্যিক শর্ত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পর্যাপ্ত শর্ত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নঞর্থক শর্ত
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কারণ হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ঘটনার সমষ্টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কার্যের সমষ্টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  শর্তের সমষ্টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  একটি শর্ত
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  কারণ হল নিয়ত শর্তান্তরহীন অব্যবহিত পূর্বগামী ঘটনা—একথা বলেছেন
                  —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মিল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোপি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বেইন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কার্ভেস রিড
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কারণকে অনুমান করা হয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উপমাযুক্তিতে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আবশ্যিক শর্তে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পর্যাপ্ত শর্তে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আবশ্যিক পর্যাপ্ত শর্তে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কার্যকে অনুমান করা হয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আবশ্যিক পর্যাপ্ত শর্তে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আবশ্যিক শর্তে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পর্যাপ্ত শর্তে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নঞর্থক শর্তে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>শক্তির অবিনশ্বরতা সূত্রটি হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পর্যাপ্ত শর্তরুপে কারণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কারণের গুণগত লক্ষণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  কারণের পরিমানগত লক্ষ্মণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আবশ্যিক শর্তরূপে কারণ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>একটি ঘটনার একাধিক শর্ত থাকতে পারে?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আবশ্যিক পর্যাপ্ত শর্তে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আবশ্যিক শর্তে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পর্যাপ্ত শর্তে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নঞর্থক শর্তে
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>দশম অধ্যায় - কারণ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. কারণের পরিমাণগত লক্ষণের মূল ভিত্তিগুলি কী কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                বস্তুর অবিনশ্বরতা নিয়ম এবং শক্তির অবিনশ্বরতা নিয়ম।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. এককারণবাদে কারণকে কোন অর্থে গ্রহণ করা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আবশ্যিক পর্যাপ্ত শর্ত অর্থে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. কারণ হল শর্তের সমষ্টি—কে বলেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    4. যা উপস্থিত বা অনুপস্থিত থেকে কার্যকে ঘটায় তাকে কী বলে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                শর্ত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    5. বহুকারণবাদকে বাস্তবক্ষেত্রে সতর্কবাদী বলে কে উল্লেখ
                    করেছেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মেলোস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    6. ‘যদি A ঘটে তবে B নামক ঘটনাটিও ঘটে’—এখানে A, B-এর কীরূপ
                    শর্ত?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পর্যাপ্ত শর্ত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    7. যে-কোনো পূর্ববর্তী ঘটনাকে কারণ বললে কী দোষ দেখা দেয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                কাকতালীয় দোষ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. একটি ঘটনার কয়টি আবশ্যিক শর্ত থাকতে পারে? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                একাধিক। <br />
                <br />
                <font color="#f626d7">
                  <b>9. একটি ঘটনার কয়টি পর্যাপ্ত শর্ত থাকতে পারে? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                একাধিক। <br />
                <br />
                <font color="#f626d7">
                  <b>10. মিল কারণকে কোন অর্থে গ্রহণ করেছেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মিল কারণকে পর্যাপ্ত শর্ত অর্থে কারণকে গ্রহণ করেছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    11. “বিষপান হলো মৃত্যুর কারণ ” – এক্ষেত্রে ‘ কারণ ’ কথাটি
                    কোন অর্থে ব্যবহৃত হয়েছে?{" "}
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পর্যাপ্ত শর্ত অর্থে। <br />
                <br />
                <font color="#f626d7">
                  <b>12. মিল কারণের কী সংজ্ঞা দেন? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                কারণ হলো কার্যের শর্তান্তরহীন অপরিবর্তনীয় পূর্ববর্তী ঘটনা।{" "}
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. পর্যাপ্ত কারণ হিসেবে কারণের একটি দৃষ্টান্ত দাও। </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভিতর হৃৎপিণ্ডে গুলিবিদ্ধ হওয়া হলো মৃত্যুর পর্যাপ্ত কারণ। <br />
                <br />
                <font color="#f626d7">
                  <b>14. কারণের পরিমাণগত লক্ষণ কী? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                জড়ের সংরক্ষণ নীতি ও শক্তির নিত্যতা নীতি। <br />
                <br />
                <font color="#f626d7">
                  <b>15. পর্যাপ্ত কারণ হিসেবে কারণের একটি দৃষ্টান্ত দাও। </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভিতর হৃৎপিণ্ডে গুলিবিদ্ধ হওয়া হলো মৃত্যুর পর্যাপ্ত কারণ।{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. স্থায়ী কারণ কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>স্থায়ী কারণ ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                এমন কতকগুলি প্রাকৃতিক কারণ আছে যেগুলিকে সম্পূর্ণভাবে অপসারণ বা
                বর্জন করা সম্ভব নয়। যুক্তিবিজ্ঞানী মিল এই প্রকার কারণগুলিকে
                স্থায়ী কারণ বলেছেন।
                <br />
                <br />
                <font color="#1f7405">
                  <b>উদাহরণ ➺ </b>{" "}
                </font>{" "}
                মাধ্যাকর্ষণ শক্তি, বায়ুমণ্ডলের চাপ, উত্তাপ প্রভৃতি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  2. বহুকারণবাদ কাকে বলে? বহুকারণবাদের একটি ত্রুটি বা অসুবিধা
                  লেখো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>বহুকারণবাদ ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যে মতবাদ অনুসারে একই কার্য বিভিন্ন সময়ে বিভিন্ন কারণের দ্বারা
                উৎপন্ন হতে পারে, তাকে বহুকারণবাদ বলা হয়।
                <br />
                <br />
                <font color="#1f7405">
                  <b>অসুবিধা ➺ </b>{" "}
                </font>{" "}
                কারণেরবিজ্ঞানসম্মত সংজ্ঞার সঙ্গে বহুকারণবাদের সংজ্ঞার কোনো মিল
                নেই।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. আবশ্যিক শর্ত কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>আবশ্যিক শর্ত ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যদি কোনো শর্ত উপস্থিত না থাকলে কোনো ঘটনা ঘটা সম্ভব না হয়, তবে
                সেই শর্তটিকে ওই ঘটনার আবশ্যিক শর্ত বলা হয়। যেমন — সূর্যের আলো
                গাছের বৃদ্ধির আবশ্যিক শর্ত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>4. নঞর্থক শর্ত কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>নঞর্থক শর্ত ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যে শর্তগুলি উপস্থিত থাকলে কার্যটি ঘটতে পারে না, সেগুলিকে সদর্থক
                শর্ত বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. পর্যাপ্ত শর্ত অর্থে কারণ বলতে কী বোঝো? উদাহরণ দাও।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>পর্যাপ্ত শর্ত অর্থে কারণ ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ‘কারণ’ কথাটি যদি এই অর্থে গ্রহণ করা হয় যে, কারণ ঘটলে কার্যটি
                ঘটবেই, সেই কারণকে বলা হবে পর্যাপ্ত শর্ত অর্থে কারণ।
                <br />
                <br />
                <font color="#1f7405">
                  <b> উদাহরণ ➺ </b>{" "}
                </font>{" "}
                আগুনে হাত দেওয়া হাত পুড়ে যাওয়ার কারণ। ‘কারণ’ কথাটি এক্ষেত্রে
                পর্যাপ্ত শর্ত হিসেবে ব্যবহৃত হয়েছে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. বৈজ্ঞানিক দৃষ্টিতে কারণ কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>বৈজ্ঞানিক দৃষ্টিতে কারণ ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                বৈজ্ঞানিক দৃষ্টিকোণ থেকে কারণ বলতে আমরা সকল সদর্থক ও নঞর্থক
                শর্তের সমষ্টিকে বুঝে থাকি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. আবশ্যিক পর্যাপ্ত শর্ত কাকে বলে?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>আবশ্যিক পর্যাপ্ত শর্ত ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যদি দুটি ঘটনার সম্বন্ধ এমন হয় যে, প্রথমটি না ঘটলে দ্বিতীয়টি
                ঘটবে না এবং প্রথমটি ঘটলে দ্বিতীয়টি ঘটে, তাহলে প্রথম ঘটনাটিকে
                বলা হয় দ্বিতীয় ঘটনার আবশ্যিক পর্যাপ্ত শর্ত। যেমন—ভিজে কাঠে
                অগ্নিসংযোগ করা হল ধূম সৃষ্টির আবশ্যিক পর্যাপ্ত শর্ত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>8. শক্তির অবিনশ্বরতার নিয়ম কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>শক্তির অবিনশ্বরতার নিয়ম ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                শক্তির অবিনশ্বরতার নিয়ম অনুসারে বলা হয়, জগতে যে পরিমাণ শক্তি
                আছে তা অবিনশ্বর। শক্তির রূপান্তর ঘটে মাত্র, কিন্তু তার পরিমাণের
                কোনো হ্রাসবৃদ্ধি ঘটে না।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

কারণ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(কারণ);
