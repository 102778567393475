import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function স্থানীয়স্বায়ত্বশাসনব্যবস্থা() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          স্থানীয় স্বায়ত্বশাসন ব্যবস্থা | West Bengal Class 12 Guide | West
          Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="স্থানীয় স্বায়ত্বশাসন ব্যবস্থা | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                স্থানীয় স্বায়ত্বশাসন ব্যবস্থা
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>গ্রাম পঞ্চায়েতের প্রথম সভা আহ্বান করেন -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পঞ্চায়েত প্রধান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মুখ্যমন্ত্রী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এসডিও
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বিডিও
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>বোরো কথার অর্থ হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ওয়ার্ড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এলাকা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পঞ্চায়েত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গ্রাম
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>খুদে জেলাশাসক বলা হয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মেয়র-কে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডি এম-কে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  এস ডি ও-কে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বি ডি ও-কে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>পশ্চিমবঙ্গের পঞ্চায়েত ব্যবস্থার স্তর সংখ্যা হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>2 টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>4 টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>3 টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>5 টি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>গ্রাম সংসদের ষান্মাসিক সভা অনুষ্ঠিত হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সেপ্টেম্বর মাসে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জুলাই মাসে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ডিসেম্বর মাসে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  নভেম্বর মাসে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>গ্রাম সংসদের বার্ষিক অধিবেশন হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নভেম্বর মাসে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মে মাসে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জানুয়ারি মাসে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জুন মাসে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>গ্রাম পঞ্চায়েতের সভা বসে মাসে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  তিন বার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অন্তত একবার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পাঁচ বার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অন্তত চারবার
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>কাউন্সিলার পদপ্রার্থীর বয়স কমপক্ষে হতে হবে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  30 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  25 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  18 বছৰ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  21 বছর
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>পশ্চিমবঙ্গে মোট পৌর কর্পোরেশন এর সংখ্যা হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>3 টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>5 টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>4 টি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>7 টি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>গ্রাম পঞ্চায়েত গঠিত হয় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  10-40 জন সদস্য নিয়ে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  7-35 জন সদস্য নিয়ে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  5-35 জন সদস্য নিয়ে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  5-30 জন সদস্য নিয়ে
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="4">
                <b>দশম অধ্যায় - স্থানীয় স্বায়ত্তশাসন ব্যবস্থা</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. বর্তমানে পশ্চিমবঙ্গে গ্রাম পঞ্চায়েতের সংখ্যা কত?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                বর্তমানে পশ্চিমবঙ্গে গ্রাম পঞ্চায়েতের সংখ্যা 3317টি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. পশ্চিমবঙ্গের একমাত্র মহকুমা পরিষদটির নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পশ্চিমবঙ্গের একমাত্র মহকুমা পরিষদটির নাম হল শিলিগুড়ি মহকুমা
                পরিষদ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    3. মিউনিসিপ্যাল কর্পোরেশনের মুখ্য কার্যনির্বাহক কী নামে
                    পরিচিত?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মিউনিসিপ্যাল কর্পোরেশনের মুখ্য কার্য নির্বাহক হলেন কমিশনার।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. কর্পোরেশনের স-পরিষদ মেয়রের সদস্যদের কে মনোনীত করেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মেয়র, স-পরিষদ মেয়রের সদস্যদের মনোনীত করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. মেয়রের সাধারণ কার্যকালের মেয়াদ কত বছর?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মেয়রের সাধারণ কার্যকালের মেয়াদ হল 5 বছর।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. পৌরসভা নির্বাচন পরিচালনার দায়িত্ব কার হাতে রয়েছে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                74তম সংবিধান সংশোধন অনুযায়ী পৌরসভার নির্বাচন পরিচালনার দায়িত্ব
                রাজ্য নির্বাচন কমিশনের হাতে রয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    7. পঞ্চায়েত নির্বাচন পরিচালনার দায়িত্ব কার হাতে রয়েছে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                73তম সংবিধান সংশোধন অনুযায়ী পঞ্চায়েত নির্বাচন পরিচালনার
                দায়িত্ব রাজ্য নির্বাচন কমিশনের হাতে রয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. ন্যায় পঞ্চায়েতে কখন কোরাম হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ন্যায় পঞ্চায়েতে 3 জন বিচারপতির উপস্থিতিতে কোরাম হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. রাজ্য নির্বাচন কমিশনার কার দ্বারা নিযুক্ত হন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাজ্য নির্বাচন কমিশনার রাজ্যপাল দ্বারা নিযুক্ত হন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    10. পঞ্চায়েত ব্যবস্থা ভারতের সংবিধানের কোন্ অংশে
                    অন্তর্ভুক্ত হয়েছে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পঞ্চায়েত ব্যবস্থা ভারতের সংবিধানের নবম অংশে অন্তর্ভুক্ত হয়েছে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  11. পৌরব্যবস্থা ভারতীয় সংবিধানের কোন্ অংশে অন্তর্ভুক্ত
                  হয়েছে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                পৌরব্যবস্থা ভারতীয় সংবিধানের নবম-ক অংশে অন্তর্ভুক্ত হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. জেলা প্রশাসনের কেন্দ্রবিন্দু কে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                জেলা প্রশাসনের কেন্দ্রবিন্দু হল জেলাশাসক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    13. কোন্ কমিটি ত্রিস্তর বিশিষ্ট পঞ্চায়েত ব্যবস্থার উপর
                    গুরুত্ব আরোপ করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বলবন্তরাই মেহেতা কমিটি ত্রিস্তরবিশিষ্ট পঞ্চায়েত সম্রাট
                ব্যবস্থার উপর গুরুত্ব আরোপ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. কলকাতা কর্পোরেশনের আয়ের প্রধান উৎস কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                কর্পোরেশন এলাকায় জমি ও বাড়ির ওপর কর বা সম্পত্তি করই হল কলকাতা
                কর্পোরেশনের আয়ের প্রধান উৎস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    15. প্রধানের অনুপস্থিতিতে গ্রাম পঞ্চায়েতের কার্যাবলিকে
                    সম্পাদন করেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                প্রধানের অনুপস্থিতিতে গ্রাম পঞ্চায়েতের কার্যাবলি সম্পাদন করেন
                উপপ্রধান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. কর্পোরেশনের তিনটি কর্তৃপক্ষ কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                কর্পোরেশনের তিনটি কর্তৃপক্ষ হল – (a) কর্পোরেশন, (b) স-পরিষদ
                মেয়র এবং (c) মেয়র।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. পৌর নিগমের মেয়র কীভাবে নির্বাচিত হন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পৌরনিগমের নবনির্বাচিত কাউন্সিলাররা নিজেদের মধ্য থেকে এক জনকে
                মেয়র নির্বাচন করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. পশ্চিমবঙ্গে কত প্রকার পৌরসভা আছে ও কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পশ্চিমবঙ্গে পৌরসভাগুলি মূলত 5 শ্রেণির। যথা—(a) ক-শ্রেণি, (b)
                খ-শ্রেণি, (c) গ-শ্রেণি, (d) ঘ-শ্রেণি, (e) ঙ-শ্রেণি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. জেলা পরিষদের দুটি স্থায়ী কমিটির নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                জেলা পরিষদের দুটি স্থায়ী কমিটি হল—(a) উন্নয়ন ও পরিকল্পনা
                সংক্রান্ত স্থায়ী কমিটি, (b) পরিবেশ-সংক্রান্ত স্থায়ী কমিটি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. গ্রাম সভা কাদের নিয়ে গঠিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                গ্রাম পঞ্চায়েত নির্বাচন কেন্দ্রের সব ভোটারকে নিয়ে গ্রাম সভা
                গঠিত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. স্থানীয় স্বায়ত্তশাসন ব্যবস্থা বলতে কী বোঝো?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                স্বায়ত্তশাসন বলতে স্বশাসন বা নিজেদের শাসনকে বােঝায়। স্থানীয়
                স্বায়ত্তশাসন ব্যবস্থার মূলকথা হল, স্থানীয় অধিবাসীদের দ্বারা
                জেলা, শহর, গ্রাম প্রভৃতির মতাে ক্ষুদ্র ক্ষুদ্র অঞ্চলের শাসন
                ব্যবস্থা পরিচালনা করা। এই শাসন ব্যবস্থায় স্থানীয় মানুষের
                দ্বারা আঞ্চলিক শাসন পরিচালিত হয়। স্থানীয় স্বায়ত্তশাসন
                ব্যবস্থায় নিম্নতর অন্যান্য পর্যায়েও নাগরিকদের স্থানীয়
                সমস্যাদি সমাধানের চেষ্টা করা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>2. স্থানীয় স্বায়ত্তশাসনের দুটি গুরুত্ব লেখো।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                স্থানীয় স্বায়ত্তশাসনের দুটি গুরুত্ব হল — <br />
                (a) স্থানীয় স্বায়ত্তশাসনের মাধ্যমে ক্ষমতার বিকেন্দ্রীকরণ
                নীতিকে বাস্তবায়িত করা হয়। <br />
                (b) সাধারণ মানুষরা প্রত্যক্ষভাবে শাসনকার্যে অংশগ্রহণের সুযোগ
                পায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>3. স্বায়ত্তশাসনের মূল লক্ষ্য কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                স্বায়ত্তশাসনের মূল লক্ষ্য হল গণতন্ত্রকে বিকশিত করে জনসাধারণের
                অংশগ্রহণ নিশ্চিত করে বিকেন্দ্রীকৃত ক্ষমতার মাধ্যমে জনগণের ব্যাপক
                কল্যাণ বা উন্নতিসাধন করা।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  4. স্থানীয় স্বায়ত্তশাসনের প্রয়ােজনীয়তার একটি কারণ উল্লেখ
                  করাে।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                স্থানীয় স্বায়ত্তশাসনের মাধ্যমে জনসাধারণের মধ্যে গণতান্ত্রিক
                অধিকারকে ছড়িয়ে দেওয়া হয়। এই স্থানীয় স্বায়ত্তশাসন ব্যবস্থার
                মাধ্যমে বিকেন্দ্রীকরণ কার্যকর হয় এবং প্রত্যেক ব্যক্তির
                ব্যক্তিত্বের পরিপূর্ণ বিকাশ সম্ভব হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>5. ন্যায় পঞ্চায়েত কী?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                গ্রাম স্তরে ছোটোখাটো দেওয়ানি ও ফৌজদারি মামলাগুলির নিষ্পত্তির
                জন্য তৃণমূল পর্যায়ে গঠিত একটি স্বায়ত্তশাসিত সংস্থা হল ন্যায়
                পঞ্চায়েত, গ্রাম পঞ্চায়েত দ্বারা নির্বাচিত পাঁচজন সদস্যকে নিয়ে
                ন্যায় পঞ্চায়েত গঠিত হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>6. পৌরসভার দুটি স্বেচ্ছাধীন কাজ লেখো।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                পৌরসভার দুটি অন্যতম স্বেচ্ছাধীন কাজ হল — <br />
                (a) দুর্ভিক্ষ বা প্রাকৃতিক বিপর্যয়ের সময় ত্রাণকার্যের
                ব্যবস্থাকরা, <br />
                (b) পাঠাগার নির্মাণ করা।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>7. পৌরসভা কাদের নিয়ে গঠিত হয়?</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                পৌরসভা গঠিত হয় – <br />
                (a) পৌর অঞ্চলের সকল সার্বিক প্রাপ্ত বয়স্কের ভোটাধিকারের
                ভিত্তিতে ওয়ার্ডগুলি থেকে নির্বাচিত সদস্য এবং <br />
                (b) রাজ্য সরকার কর্তৃক মনোনীত ব্যক্তিদের নিয়ে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

স্থানীয়স্বায়ত্বশাসনব্যবস্থা.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(স্থানীয়স্বায়ত্বশাসনব্যবস্থা);
