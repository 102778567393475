import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function ভারতেরশাসনবিভাগ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          ভারতের শাসন বিভাগ | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="ভারতের শাসন বিভাগ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                ভারতের শাসন বিভাগ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ভারতীয় সংবিধান অনুসারে রাষ্ট্রপতি জরুরি অবস্থা ঘোষণা করতে
                  পারেন —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>2
                  প্রকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>4
                  প্রকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>3 প্রকার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>6
                  প্রকার
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  রাজ্য মন্ত্রীসভার কোন্ সদস্য পদত্যাগ করলে সমগ্র মন্ত্রীসভার
                  পতন ঘটে?
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রধানমন্ত্রী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মুখ্যমন্ত্রী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাষ্ট্রপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাজ্যপাল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>রাজ্যপালের প্রধান পরামর্শদাতা হলেন -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মুখ্যসচিব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রধান বিচারপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মন্ত্রীপরিষদ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উপরাষ্ট্রপতি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>রাষ্ট্রপতি রাজ্যসভায় মনোনীত করেন -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  12 জন সদস্যকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  11 জন সদস্যকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  10 জন সদস্যকে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>7 জন
                  সদস্যকে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>মুখ্যমন্ত্রীর প্রধান প্রশাসনিক পরামর্শদাতা হলেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্রধানমন্ত্রী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মুখ্যসচিব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাষ্ট্রপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  রাজ্যপাল
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতে কেন্দ্রীয় মন্ত্রীসভার কার্যকালের মেয়াদ —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>4 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>5 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>6 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  10 বছর
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতের রাষ্ট্রপতির কার্যকালের মেয়াদ হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>৪ বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>5 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>6 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  10 বছর
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতের রাষ্ট্রপতি পদের পদপ্রার্থীর ন্যূনতম বয়স হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  40 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  21 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  25 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  35 বছর
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতে অদ্যাবধি জাতীয় জরুরি অবস্থা ঘোষিত হয়েছে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>1 বার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>2 বার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>3 বার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  একবারও নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>UPSC সদস্যদের কার্যকালের মেয়াদ হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>৪ বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>5 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>6 বছর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  10 বছর
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#2b9a09" size="3">
                <b>সপ্তম অধ্যায় - ভারতের শাসন বিভাগ</b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. ভারতের সর্বপ্রথম কোন্ রাজ্যে রাষ্ট্রপতি শাসন জারি হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                পাঞ্জাব রাজ্যে প্রথম রাষ্ট্রপতি শাসন জারি হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. ভারতের কেন্দ্রীয় সরকারের প্রধান মুখপাত্র কে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                প্রধানমন্ত্রী কেন্দ্রীয় সরকারের প্রধান মুখপাত্র।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    3. রাজ্যপালদের নিয়োগের সময় রাষ্ট্রপতি কার পরামর্শমতো কাজ
                    করেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                প্রধানমন্ত্রীর পরামর্শমতো রাষ্ট্রপতি রাজ্যপালদের নিয়োগ করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. ভারত রাষ্ট্রের সাংবিধানিক প্রধান কে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারত রাষ্ট্রের সাংবিধানিক প্রধান হলেন ভারতের রাষ্ট্রপতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. পশ্চিমবঙ্গের প্রথম মুখ্যমন্ত্রী কে ছিলেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ড. প্রফুল্ল চন্দ্র ঘোষ পশ্চিমবঙ্গের প্রথম মুখ্যমন্ত্রী ছিলেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. পশ্চিমবঙ্গের প্রথম রাজ্যপাল কে ছিলেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                চক্রবর্তী রাজাগোপালাচারী ছিলেন পশ্চিমবঙ্গের প্রথম রাজ্যপাল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. ভারতের প্রথম উপপ্রধানমন্ত্রী কে ছিলেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সর্দার বল্লভভাই প্যাটেল ভারতের প্রথম উপপ্রধানমন্ত্রী ছিলেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>8. পার্লামেন্টের দুটি অর্থ বিষয়ক কমিটির নাম করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পার্লামেন্টের দুটি অর্থ বিষয়ক কমিটির নাম হল—(a) সরকারি গাণিতিক
                কমিটি, (b) আনুমানিক ব্যয় পরীক্ষা কমিটি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    9. ভারতের সংবিধান সংশোধনের পদ্ধতি কোন্ ধারায় উল্লেখ আছে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সংবিধানের 368 নং ধারায় সংবিধান সংশোধনের পদ্ধতির উল্লেখ আছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    10. ভারতের কেন্দ্রীয় শাসনের রাজনৈতিক অংশ কাদের নিয়ে গঠিত?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাষ্ট্রপতি, উপ-রাষ্ট্রপতি এবং কেন্দ্রীয় মন্ত্রীসভাকে নিয়ে
                ভারতের কেন্দ্রীয় শাসনের রাজনৈতিক অংশ গঠিত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. ভারতের প্রথম নাগরিক কাকে বলা হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতের রাষ্ট্রপতিকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    12. রাষ্ট্রপতি নিজে পদত্যাগ করতে চাইলে কার কাছে পদত্যাগ পত্র
                    পাঠান?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাষ্ট্রপতি স্বেচ্ছায় পদত্যাগ করতে চাইলে উপরাষ্ট্রপতির নিকট তাঁর
                পদত্যাগ পত্র পাঠান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    13. কোন্ অপরাধে রাষ্ট্রপতির কার্যকাল শেষ হওয়ার পূর্বেই তাকে
                    অপসারণ করা যায়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সংবিধানের 56 নং ধারা অনুসারে রাষ্ট্রপতিকে সংবিধান ভঙ্গের অপরাধে
                পদচ্যুত করা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    14. ভারতের রাষ্ট্রপতি পদে নির্বাচিত হওয়ার দুটি যোগ্যতা
                    লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                উত্তর ভারতের রাষ্ট্রপতি নির্বাচিত হওয়ার দুটি যোগ্যতা হল –{" "}
                <br />
                (a) প্রার্থীকে অবশ্যই ভারতীয় নাগরিক হতে হবে। <br />
                (b) প্রার্থীকে অন্তত 35 বছর বয়সি হতে হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    15. রাজ্য বিশ্ববিদ্যালয়গুলোর আচার্য হিসেবে কে কাজ করেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাজ্যপাল রাজ্য বিশ্ববিদ্যালয়গুলির আচার্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. রাষ্ট্রপতি কখন অর্ডিন্যান্স জারি করতে পারেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাষ্ট্রপতি পার্লামেন্টের অধিবেশন স্থগিত থাকাকালীন প্রশাসনের
                জরুরি প্রয়োজনে অর্ডিন্যান্স বা অধ্যাদেশ জারি করতে পারেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    17. পার্লামেন্টের উভয়কক্ষের অধিবেশনের সমাপ্তি কে ঘোষণা
                    করেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাষ্ট্রপতি পার্লামেন্টের উভয় কক্ষের অধিবেশনের সমাপ্তি ঘোষণা
                করেন। তবে এক্ষেত্রে তিনি প্রধানমন্ত্রীর পরামর্শ নিয়ে থাকেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>18. রাষ্ট্রপতির ‘ভেটো’ বা ‘ভিটো’ ক্ষমতা বলতে কী বোঝো? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের রাষ্ট্রপতির পার্লামেন্টে প্রণীত বা রাজ্য আইনসভা প্রণীত
                কোনো বিল বাতিল করে দেওয়ার ক্ষমতাকে ‘ভিটো’ বা ভেটো ক্ষমতা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>19. রাষ্ট্রপতির ‘ভেটো’ ক্ষমতা কয় প্রকার ও কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাষ্ট্রপতির ‘ভেটো’ ক্ষমতা তিন প্রকার। যথা— (a) চরম ভেটো, (b)
                স্থগিতকারী ভেটো ও (c) পকেট ভেটো।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    20. কোনো ব্যক্তি রাষ্ট্রপতি পদে কতবার নির্বাচিত হতে পারেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                কোনো ব্যক্তি রাষ্ট্রপতি পদে কতবার নির্বাচিত হতে পারেন, এ বিষয়ে
                ভারতীয় সংবিধানে স্পষ্ট করে কিছু বলা হয়নি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  21. একই সঙ্গে রাষ্ট্রপতি ও উপরাষ্ট্রপতির পদ শূন্য হলে কে
                  রাষ্ট্রপতির দায়িত্ব পালন করে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                একই সঙ্গে রাষ্ট্রপতি ও উপরাষ্ট্রপতি পদ শূন্য হলে এক্ষেত্রে
                সুপ্রিমকোর্টের প্রধান বিচারপতি সাময়িকভাবে রাষ্ট্রপতির দায়িত্ব
                পালন করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    22. পশ্চিমবঙ্গে আজ পর্যন্ত কতবার শাসনতান্ত্রিক অচলাবস্থা
                    জারি হয়েছে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পশ্চিমবঙ্গে আজ পর্যন্ত চার বার শাসনতান্ত্রিক অচলাবস্থা জারি
                হয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. কিচেন ক্যাবিনেট বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                প্রধানমন্ত্রী ও তাঁর একান্ত অনুগত দু-তিনজন মন্ত্রীকে নিয়ে
                ক্যাবিনেটের অভ্যন্তরে যে ছোটো একটি অভ্যন্তরীণ ক্যাবিনেট গড়ে
                ওঠে, তাকে কিচেন ক্যাবিনেট বা ঘরোয়া ক্যাবিনেট বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. ভারতের রাষ্ট্রপতিকে শপথবাক্য পাঠ করান কে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সুপ্রিমকোর্টের প্রধান বিচারপতি ভারতের রাষ্ট্রপতিকে শপথবাক্য পাঠ
                করান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    25. রাষ্ট্রপতি পার্লামেন্টের কোনো কক্ষের সদস্য হতে পারেন কী?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সংবিধানের 59 নং ধারা অনুযায়ী রাষ্ট্রপতি পার্লামেন্টের কোনো
                কক্ষের সদস্য হতে পারেন না। তিনি আইনসভার অবিচ্ছেদ্য অংশ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    26. রাষ্ট্রপতির পূর্বসম্মতি ছাড়া উত্থাপন করা যায় না এমন
                    কয়েকটি বিলের উল্লেখ করো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                উত্তর যে সমস্ত বিল উত্থাপনের পূর্বে রাষ্ট্রপতির পূর্বসম্মতির
                প্রয়োজন হয় — অর্থবিল, নতুন রাজ্য গঠন বিল, রাজ্যের নাম ও সীমানা
                পরিবর্তন-সংক্রান্ত বিল ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    27. ভারতের বর্তমান রাষ্ট্রপতি ও উপ-রাষ্ট্রপতির নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের বর্তমান রাষ্ট্রপতি হলেন দ্রৌপদী মূর্ষু এবং ভারতের বর্তমান
                উপ-রাষ্ট্রপতি হলেন জগদীশ ধনকড়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>28. রাজ্যপালকে কে শপথ বাক্য পাঠ করান?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাজ্যপালকে সংশ্লিষ্ট রাজ্যের হাইকোর্টের প্রধান বিচারপতি শপথ
                বাক্য পাঠ করান।
                <br />
                <br />
                <font color="#f626d7">
                  <b>29. বর্তমানে পশ্চিমবঙ্গের রাজ্যপালের নাম কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পশ্চিমবঙ্গে বর্তমান রাজ্যপালের নাম হল ড. সি. ভি. আনন্দ বোস।
                <br />
                <br />
                <font color="#f626d7">
                  <b>30. রাষ্ট্রপতির বিচার-সংক্রান্ত দুটি ক্ষমতা উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাষ্ট্রপতির অন্যতম দুটি বিচার-সংক্রান্ত ক্ষমতা হল – <br />
                (a) সুপ্রিমকোর্ট ও হাইকোর্টের বিচারপতিদের নিয়োগ ও পদচ্যুতি,{" "}
                <br />
                (b) ফৌজদারি মামলায় দণ্ডপ্রাপ্ত ব্যক্তির দণ্ডাদেশ হ্রাস, স্থগিত
                অথবা ক্ষমা প্রদর্শন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>31. রাষ্ট্রপতির নির্বাচনে ভোটদান পদ্ধতিটির নাম কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                রাষ্ট্রপতির নির্বাচনে ভোটদান পদ্ধতিটির নাম হল একক হস্তান্তরযোগ্য
                সমানুপাতিক প্রতিনিধিত্বের নীতি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>32. সংবিধানের কোন্ ধারায় যৌথ অধিবেশন ডাকা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সংবিধানের 108 নং ধারায় পার্লামেন্টের যৌথ অধিবেশন ডাকা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    33. ভারতের রাষ্ট্রপতি পদে থাকাকালীন অবস্থায় তাঁর বিরুদ্ধে
                    কোন্ ধরনের মামলা দায়ের কার যায় না?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাষ্ট্রপতি পদে থাকাকালীন অবস্থায় তাঁর বিরুদ্ধে ফৌজদারি মামলা
                দায়ের করা যায় না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    34. ভারতের রাষ্ট্রপতি কোন্ কোন্ বিলে সম্মতি দিতে বাধ্য
                    থাকেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের রাষ্ট্রপতি অর্থবিল ও সংবিধান সংশোধন সংক্রান্ত বিলে সম্মতি
                দিতে বাধ্য থাকেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    35. ভারতীয় সংবিধান অনুযায়ী কোন্ পদাধিকারীরা স্বেচ্ছাধীন
                    ক্ষমতা ভোগ করে থাকেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের বিভিন্ন অঙ্গরাজ্যের রাজ্যপালরা এই স্বেচ্ছাধীন ক্ষমতা ভোগ
                করে থাকেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    36. ভারতের কেন্দ্রীয় সরকারের বাজেট কেন্দ্রীয় অর্থমন্ত্রী
                    কার পক্ষ হয়ে সংসদে উপস্থাপন করেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের রাষ্ট্রপতির পক্ষ হয়ে কেন্দ্রীয় অর্থমন্ত্রী বাজেট
                উপস্থাপন করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    37. রাজ্যপাল কোন্ কোন্ বিলকে রাষ্ট্রপতির সম্মতির জন্য পাঠাতে
                    বাধ্য?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাজ্যপাল তাঁর ইচ্ছানুসারে রাজ্য বিধানসভার যে-কোনো বিলকে
                রাষ্ট্রপতির সম্মতির জন্য পাঠাতে পারেন। তবে ব্যক্তিগত সম্পত্তি
                অধিগ্রহণ, হাইকোর্টের ক্ষমতা নিয়ন্ত্রণ প্রভৃতি বিল তিনি
                রাষ্ট্রপতির অনুমোদনের জন্য পাঠাতে বাধ্য থাকেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. ছাঁটাই প্রস্তাব কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                উত্তর আইন সভায় বিরোধী দলের সদস্যরা বাজেটে অর্থ বরাদ্দের দাবি
                সংক্রান্ত মূল প্রস্তাবের ছাঁটাই বিষয়ে যে দাবি উপস্থিত করে
                সেগুলোকে ছাঁটাই প্রস্তাব বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    39. কেন্দ্র ও রাজ্যের মধ্যে ক্ষমতা বণ্টনের কী কী তালিকা
                    রয়েছে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের সংবিধানের সপ্তম তফসিলে কেন্দ্র ও রাজ্যের মধ্যে ক্ষমতা
                বন্টনের জন্য তিনটি তালিকা আছে। যথা — <br />
                (a) কেন্দ্রীয় তালিকা, (b) রাজ্য তালিকা এবং (c) যুগ্ম তালিকা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>40. রাজ্যপাল পদে নিযুক্ত হওয়ার দুটি যোগ্যতা লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাজ্যপাল পদে নিযুক্ত হওয়ার দুটি যোগ্যতা হল – <br />
                (a) ব্যক্তিকে অবশ্যই ভারতীয় নাগরিক হতে হবে। <br />
                (b) ব্যক্তিকে ন্যূনতম ৩৫ বছর বয়সি হতে হবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>41. পকেট ভেটো কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                রাষ্ট্রপতি পার্লামেন্টে পাস হওয়া যেসব বিলে সম্মতি না দিয়ে অথবা
                পুনর্বিবেচনার জন্য পার্লামেন্টে ফেরত না পাঠিয়ে অনির্দিষ্টকালের
                জন্য বিলটিকে নিজের কাছে আটকে রাখেন তাকে পকেট ভেটো বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>42. স্থগিতকারী ভেটো বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পার্লামেন্টে পাস হওয়া কোনো বিলকে রাষ্ট্রপতি যখন সরাসরি সম্মতি
                বা অসম্মতি না জানিয়ে পুনর্বিবেচনার জন্য পার্লামেন্টের কাছে ফেরত
                পাঠিয়ে দেন, তখন তাকে স্থগিতকারী ভেটো ক্ষমতা বলা হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    43. সংবিধানে রাষ্ট্রপতির ক্ষমতাকে কয় ভাগে ভাগ করা যায়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সংবিধানে রাষ্ট্রপতির ক্ষমতাকে পাঁচ ভাগে ভাগ করা যায়। যথা —{" "}
                <br />
                (a) শাসন সংক্রান্ত ক্ষমতা, <br />
                (b) আইন-সংক্রান্ত ক্ষমতা, <br />
                (c) অর্থ-সংক্রান্ত ক্ষমতা, <br />
                (d) বিচার-সংক্রান্ত ক্ষমতা ও <br />
                (e) জরুরি অবস্থা-সংক্রান্ত ক্ষমতা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>44. রাজ্যপালের দুটি বিচার-সংক্রান্ত কাজ লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাজ্যপালের দুটি বিচার-সংক্রান্ত কাজ হল – <br />
                (a) রাজ্যপাল দণ্ডপ্রাপ্ত অপরাধীর দণ্ড হ্রাস করতে পারেন। <br />
                (b) রাজ্যের হাইকোর্টের বিচারপতি নিয়োগের সময় তিনি রাষ্ট্রপতির
                সঙ্গে পরামর্শকরতে পারেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>45. লোকসভায় প্রার্থী হওয়ার দুটি যোগ্যতা লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                লোকসভায় প্রার্থী হওয়ার দুটি যোগ্যতা হল – <br />
                (a) প্রার্থীকে অবশ্যই ভারতের নাগরিক হতে হবে। <br />
                (b) প্রার্থীকে অন্ততপক্ষে 25 বছর বয়সি হতে হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>46. ‘ভোট অন্ অ্যাকাউন্ট’ বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বাজেট পাস না হওয়া পর্যন্ত ব্যয়নির্বাহের জন্য নতুন আর্থিক বছর
                শুরুর আগে লোকসভা সরকারকে যে অর্থ ব্যয়ের অনুমোদন দেয় তাকে ‘ভোট
                অন্ অ্যাকাউন্ট' বলে। <br />
                <br />
                <font color="#f626d7">
                  <b>47. ইমপিচমেন্ট পদ্ধতিটি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সংবিধান ভঙ্গের অপরাধে ভারতীয় সংবিধানের 61 নং ধারা অনুসারে
                রাষ্ট্রপতি ও উপরাষ্ট্রপতির অপসারণ পদ্ধতিকেই ইমপিচমেন্ট পদ্ধতি
                বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>48. পার্লামেন্টের দুটি স্থায়ী কমিটির নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পার্লামেন্টের দুটি স্থায়ী কমিটি হল – <br />
                (a) ব্যয় হিসাব কমিটি বা এস্টিমেটস কমিটি,
                <br /> (b) সরকারি গাণিতিক কমিটি বা পাবলিক অ্যাকাউন্টস কমিটি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>49. পার্লামেন্টের দুটি অস্থায়ী কমিটির নাম লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                পার্লামেন্টের দুটি অস্থায়ী কমিটি হল — <br />
                (a) বিল সংক্রান্ত সিলেক্ট কমিটি এবং <br />
                (b) অনুসন্ধান কমিটি। <br />
                <br />
                <font color="#f626d7">
                  <b>50. ত্রিশঙ্কু লোকসভা বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                লোকসভা সাধারণ নির্বাচনে যখন কোনো দল বা জোট একক নিরঙ্কুশ
                সংখ্যাগরষ্ঠিতা না পেলে যে অবস্থার সৃষ্টি হয়, তাকে ত্রিশঙ্কু
                লোকসভা বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>51. হ্যাং পার্লামেন্ট বলতে কী বোঝায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সাধারণ নির্বাচনের পর লোকসভায় কোনো দল জোট একক সংখ্যাগরিষ্ঠতা
                অর্জন করতে না পারলে পার্লামেন্টে যে অবস্থার সৃষ্টি হয়,
                সাংবিধানিক ভাবে তাকে হ্যাং পার্লামেন্ট বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>52. ভারতের জাতীয় রাজধানী অঞ্চল ক-টি ও কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের জাতীয় রাজধানী অঞ্চল একটি। এই অঞ্চলটি হল নিউ দিল্লি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>53. প্রজাতান্ত্রিক সরকার কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যে রাষ্ট্রের রাষ্ট্রপ্রধান উত্তরাধিকার সূত্রে ওই পদ লাভ করেন না,
                জনগণের দ্বারা প্রত্যক্ষ বা নির্বাচিত প্রতিনিধিগণ কর্তৃক
                নির্দিষ্ট সময়ের জন্য নির্বাচিত হন, সেই সরকারকে প্রজাতান্ত্রিক
                সরকার বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>54. বাজেট বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                প্রত্যেক আর্থিক বছরে সরকারের আয়-ব্যয়ের আনুমানিক হিসেব
                রাষ্ট্রপতি অর্থমন্ত্রীর মাধ্যমে সংসদের উভয়কক্ষে বিবেচনার জন্য
                পেশ করেন। আয়-ব্যয়ের এই বার্ষিক বিবরণকে বাজেট বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    55. ভারতের রাষ্ট্রপতি কত ও কী কী ধরনের জরুরি অবস্থা জারি
                    করতে পারেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের রাষ্ট্রপতি তিন ধরনের জরুরি অবস্থা জারি করতে পারেন। এগুলি
                হল — <br />
                (a) জাতীয় জরুরি অবস্থা, <br />
                (b) রাজ্যসমূহের সাংবিধানিক অচলাবস্থাজনিত জরুরি অবস্থা, <br />
                (c) আর্থিক জরুরি অবস্থা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    56. রাষ্ট্রপতি কোন্ পরিস্থিতিতে জাতীয় জরুরি অবস্থা ঘোষণা
                    করতে পারেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                352 নং ধারা অনুসারে বহিরাক্রমণ, যুদ্ধ অথবা অভ্যন্তরীণ গোলযোগ বা
                তার সম্ভাবনার জন্য সমগ্র ভারত বা ভারতের কোনো অংশে নিরাপত্তা
                বিঘ্নিত হতে পারে, তা হলে রাষ্ট্রপতি জাতীয় জরুরি অবস্থা ঘোষণা
                করতে পারেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>57. রাজ্যপালের দুটি স্বেচ্ছাধীন ক্ষমতার উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাজ্যপালের দুটি স্বেচ্ছাধীন ক্ষমতার উদাহরণ হল – <br />
                (a) পার্শ্ববর্তী কেন্দ্রশাসিত অঞ্চলের প্রশাসনিক দায়িত্বভার
                অর্পিত হলে স্বাধীনভাবে তা পালন করা। <br />
                (b) রাষ্ট্রপতির কাছে রাজ্যের সাংবিধানিক অচলাবস্থা সম্পর্কিত
                রিপোর্ট পাঠানো।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    58. কার্যকাল শেষ হওয়ার আগে কোন্ কোন্ পরিস্থিতিতে
                    মন্ত্রীসভার পতন ঘটতে পারে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মন্ত্রীসভার কার্যকাল 5 বছর। তবে তার আগে কয়েকটি কারণে
                মন্ত্রিসভার পতন ঘটতে পারে — <br />
                (a) লোকসভায় অনাস্থা প্রস্তাব পাস হলে, <br />
                (b) লোকসভায় কোনো সরকারি বিল বা প্রস্তাব নাকচ হলে ও <br />
                (c) প্রধানমন্ত্রী পদত্যাগ করলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>59. ভারতের রাষ্ট্রপতিকে কীভাবে পদচ্যুত করা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের রাষ্ট্রপতিকে সংবিধানের 61 নং ধারায় ‘ইমপিচমেন্ট’ পদ্ধতির
                সাহায্যে পার্লামেন্টের দুই-তৃতীয়াংশ সদস্যের সম্মতির ভিত্তিতে
                পদচ্যুত করা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>60. ভারতেরউপরাষ্ট্রপতিকে কারা নির্বাচন করেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>{" "}
                ভারতের উপরাষ্ট্রপতি রাজ্যসভা ও লোকসভার সদস্যদের নিয়ে গঠিত একটি
                নির্বাচক সংস্থা কর্তৃক একক হস্তান্তরযোগ্য সমানুপাতিক
                প্রতিনিধিত্বের নীতির ভিত্তিতে গোপন ভোটে নির্বাচিত হন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>61. নিয়মতান্ত্রিক রাজতন্ত্র কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যে রাজতান্ত্রিক শাসনব্যবস্থায় রাজা বা রানি তত্ত্বগতভাবে শাসন
                বিভাগের চূড়ান্ত কর্তৃত্ব অধিকার করলেও বাস্তবে প্রকৃত ক্ষমতা
                জনগণের দ্বারা প্রত্যক্ষভাবে নির্বাচিত প্রতিনিধিদের হাতে ন্যস্ত
                থাকে তাকে নিয়মতান্ত্রিক রাজতন্ত্র বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>62. ভারতের রাষ্ট্রপতিকে কারা নির্বাচন করেন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের রাষ্ট্রপতি পার্লামেন্টের দুটি কক্ষ রাজ্যসভা ও লোকসভার
                নির্বাচিত সদস্য ও অঙ্গ রাজ্যগুলোর আইনসভার নিম্নকক্ষ বিধানসভার
                নির্বাচিত সদস্যদের নিয়ে গঠিত একটি নির্বাচক সংস্থা কর্তৃক একক
                হস্তান্তরযোগ্য সমানুপাতিক প্রতিনিধিত্বের নীতির ভিত্তিতে গোপন
                ভোটে নির্বাচিন হন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>63. ভারতের উপরাষ্ট্রপতিকে কীভাবে পদচ্যুত করা যায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের উপরাষ্ট্রপতিকে পদচ্যুত করতে হলে সংবিধান ভঙ্গের অপরাধে 14
                দিনের বিজ্ঞপ্তি দিতে হয়। এরপর রাজ্যসভার অধিকাংশ সদস্যরা যদি
                প্রস্তাবটি রাজ্যসভায় গ্রহণ করে এবং লোকসভা যদি প্রস্তাবে সম্মতি
                জানায় তাহলে উপরাষ্ট্রপতিকে পদচ্যুত করা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>64. কখন আর্থিক জরুরি অবস্থা ঘোষিত হতে পারে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাষ্ট্রপতি সংবিধানের 360 নং ধারা অনুযায়ী যদি এই মর্মে নিশ্চিন্ত
                হন যে, সমগ্র ভারতে বা ভারতের কোনো অংশে আর্থিক স্থায়িত্ব বা
                সুনাম ক্ষুণ্ন হয়েছে, তাহলে তিনি আর্থিক জরুরি অবস্থা ঘোষণা করতে
                পারেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>65. রাষ্ট্রপতির কূটনৈতিক ক্ষমতা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আন্তর্জাতিক ক্ষেত্রে ভারতের রাষ্ট্রপতি ভারতের হয়ে প্রতিনিধিত্ব
                করেন। তিনি বিভিন্ন রাষ্ট্রে কূটনৈতিক প্রতিনিধি নিয়োগ করেন ও
                পাঠিয়ে থাকেন আবার অন্য রাষ্ট্রের প্রতিনিধিদের গ্রহণ করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>66. ভারতের প্রধানমন্ত্রী কীভাবে নিযুক্ত হন?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সাধারণ নির্বাচনের মধ্য দিয়ে যে দল বা জোট সংখ্যাগরিষ্ঠতা অর্জনে
                সক্ষম হয়, সেই দল বা জোটের নেতা বা নেত্রীকে রাষ্ট্রপতি
                প্রধানমন্ত্রী পদে নিয়োগ করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>67. মন্ত্রীসভায় যৌথ দায়িত্বশীলতা বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সংসদীয় শাসনব্যবস্থায় সমগ্র মন্ত্রীসভাকে আইনসভার নিম্নকক্ষের
                কাছে দায়বদ্ধ থাকতে হয়। কোনো বিশেষ দপ্তরের মন্ত্রীর বিরুদ্ধে
                আইনসভার নিম্নকক্ষের সংখ্যাগরিষ্ঠ সদস্যরা অনাস্থা প্রস্তাব পাস
                করলে সংশ্লিষ্ট মন্ত্রী ব্যক্তিগতভাবে এবং সমগ্র মন্ত্রীসভার
                পদচ্যুতি ঘটে। একে মন্ত্রীসভার যৌথ দায়িত্বশীলতা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>68. ভারতে কেন্দ্রীয় মন্ত্রীরা কত শ্রেণির ও কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতের কেন্দ্রীয় মন্ত্রীসভায় তিন শ্রেণির মন্ত্রী রয়েছেন।
                সর্বোচ্চ পর্যায়ে ক্যাবিনেট মন্ত্রী, দ্বিতীয় পর্যায়ে
                রাষ্ট্রমন্ত্রী ও তৃতীয় পর্যায়ে থাকে প্রতিমন্ত্রী বা উপমন্ত্রী।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    69. রাজ্যে শাসনতান্ত্রিক অচলাবস্থাজারি হওয়ার দুটি ফলাফল
                    লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                কোনো রাজ্যে রাষ্ট্রপতি শাসন জারি হলে -<br />
                (a) রাজ্যের প্রশাসনিক ক্ষমতা রাষ্ট্রপতির হাতে স্থানান্তরিত হয়।{" "}
                <br />
                (b) রাষ্ট্রপতি ‘ভারতের সঞ্ঝিত তহবিল’ থেকে সংশ্লিষ্ট রাজ্যের জন্য
                ব্যয় মঞ্জুরির আদেশ দিতে পারেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>70. আর্থিক জরুরি অবস্থার যে-কোনো দুটি ফলাফল লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আর্থিক জরুরি অবস্থার দুটি ফলাফল হল – <br />
                (a) আর্থিক বিষয়ে রাজ্যগুলিকে কেন্দ্রের নির্দেশ মেনে চলতে হয়।{" "}
                <br />
                (b) রাজ্য সরকারি কর্মীদের বেতন ও ভাতা হ্রাস করার জন্য কেন্দ্র
                নির্দেশ দিতে পারে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>71. ‘প্রধানমন্ত্রীর কার্যালয়’ কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                প্রধানমন্ত্রীর কাজে সহযোগিতা করার জন্য তাঁর অধীনে একটি দপ্তর বা
                কার্যালয় আছে। একে প্রধানমন্ত্রীর কার্যালয় বা P.M.O বলে। তবে এই
                কার্যালয়ের কোনো সাংবিধানিক ভিত্তি নেই। <br />
                <br />
                <font color="#f626d7">
                  <b>72. রাজ্যপালের দুইটি আইন বিষয়ক ক্ষমতা লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাজ্যপালের দুটি আইন বিষয়ক ক্ষমতা হল –<br /> (a) রাজ্য আইনসভার
                অধিবেশন আহ্বান করা বা অধিবেশন স্থগিত রাখা। <br />
                (b) রাজ্য আইনসভায় পাস হওয়া বিলে সম্মতি দেওয়া বা না দেওয়া।
                <br />
                <br />
                <font color="#f626d7">
                  <b>73. জাতীয় জরুরি অবস্থার দুটি ফলাফল লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                জাতীয় জরুরি অবস্থার ফলে - <br />
                (a) পার্লামেন্ট রাজ্য তালিকাভুক্ত যে-কোনো বিষয়ে আইন প্রণয়ন
                করতে পারে, <br />
                (b) প্রশাসনিক কাজকর্ম পরিচালনার ব্যাপারে কেন্দ্র রাজ্যগুলিকে
                প্রয়োজনীয় নির্দেশ দিতে পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>74. কাস্টিং বা নির্ণায়ক ভোট কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                লোকসভা বা বিধানসভায় কোনো প্রস্তাবের পক্ষে বা বিপক্ষে সমান
                সংখ্যক ভোট পড়লে অধ্যক্ষ ভোটদানের মাধ্যমে প্রস্তাবটি নির্ণয়
                করেন। অধ্যক্ষ বা স্পিকারের এই ভোটকে কাস্টিং ভোট বা নির্ণায়ক ভোট
                বলে। <br />
                <br />
                <font color="#f626d7">
                  <b>75. অনাস্থা প্রস্তাব কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সংসদীয় শাসনব্যবস্থায় মন্ত্রীসভা আইন সভার নিম্নকক্ষ লোকসভার
                নিকট দায়বদ্ধ থাকে। লোকসভার সদস্যরা সমগ্র মন্ত্রীসভা বা কোনো
                মন্ত্রীর কাজকর্মে সন্তুষ্ট না হলে লোকসভায় অনাস্থা প্রস্তাব আনতে
                পারে এবং এই প্রস্তাব যদি লোকসভার সংখ্যা-গরিষ্ঠ সদস্য দ্বারা
                গৃহীত হয় তাহলে মন্ত্রীসভাকে পদত্যাগ করতে হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    76. কোন্ পরিস্থিতিতে কোনো অঙ্গরাজ্যে রাষ্ট্রপতি শাসন জারি
                    করা হয়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সংবিধানের 356 নং ধারা অনুসারে কোনো রাজ্যের রাজ্যপালের
                প্রতিবেদনের ভিত্তিতে অথবা অন্য কোনো সূত্র থেকে পাওয়া সংবাদের
                ভিত্তিতে রাষ্ট্রপতি যদি নিশ্চিত বা সন্তুষ্ট হন যে রাজ্যে সংবিধান
                অনুযায়ী শাসনকার্য পরিচালিত হচ্ছে না, এই মর্মে রাষ্ট্রপতি। উক্ত
                রাজ্যে রাষ্ট্রপতি শাসন জারি করতে পারেন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  1. ভারতের প্রধানমন্ত্রীর ক্ষমতা ও কার্যাবলি আলোচনা করো।
                  <br />
                  অথবা,
                  <br /> ভারতের শাসনব্যবস্থায় প্রধানমন্ত্রীর সাংবিধানিক ভূমিকা
                  ব্যাখ্যা করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ভারতীয় সংসদীয় শাসনব্যবস্থায় প্রকৃত শাসক হলেন প্রধানমন্ত্রী।
                ইংল্যান্ডের সংসদীয় শাসনব্যবস্থার অনুকরণে ভারতের সংসদীয়
                শাসনব্যবস্থা কার্যকর হয়েছে। সংবিধানের 75 নং ধারা অনুসারে
                প্রধানমন্ত্রী রাষ্ট্রপতি কর্তৃক নিযুক্ত হন। তবে তাঁর কার্যাবলি
                সম্পাদনের ক্ষেত্রে সাহায্য ও পরামর্শ দানের জন্য একটি
                মন্ত্রীপরিষদ অবশ্যই থাকবে এবং এই মন্ত্রীপরিষদের শীর্ষে থাকবেন
                প্রধানমন্ত্রী। সাধারণ নির্বাচনের পর লোকসভার সংখ্যাগরিষ্ঠ দলের
                নেতা বা নেত্রীকেই রাষ্ট্রপতি, প্রধানমন্ত্রী পদে নিযুক্ত করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b> প্রধানমন্ত্রীর ক্ষমতা ও কার্যাবলি ➺ </b>{" "}
                </font>{" "}
                ভারতের সংবিধানে প্রধানমন্ত্রীর ক্ষমতা ও কার্যাবলি সম্পর্কে
                স্পষ্টভাবে কিছু উল্লেখ করা নেই। তবে ব্রিটিশ প্রধানমন্ত্রীর
                ভূমিকা অনুসরণ করে রাষ্ট্রপতি, পার্লামেন্ট, ক্যাবিনেট, নিজের দল ও
                জনগণের সঙ্গে তাঁর সম্পর্কের নিরিখে প্রধানমন্ত্রীর ক্ষমতা ও
                কার্যাবলি নীচে আলোচনা করা হল - <br />
                <br />
                <font color="#f626d7">
                  <b>1. লোকসভার নেতা ➺ </b>{" "}
                </font>{" "}
                <br />
                i) লোকসভার সংখ্যাগরিষ্ঠ দল বা জোটের নেতা হিসেবে প্রধানমন্ত্রী
                তাঁর মন্ত্রীসভা গঠন করেন।
                <br />
                ii) লোকসভার কার্যক্রম তিনি স্থির করেন। প্রধানমন্ত্রী লোকসভার
                কার্য পরিচালনা-সংক্রান্ত উপদেষ্টা কমিটির সদস্য।
                <br />
                (iii) লোকসভার অধিবেশন কখন ডাকা হবে, কতদিন চলবে, কোন্ কোন্
                বিষয়ের ওপর আলোচনা চলবে সে সম্পর্কে প্রধানমন্ত্রী নিজে সিদ্ধান্ত
                নেন। অধিবেশন স্থগিত রাখা বা লোকসভা ভেঙে দেওয়ার ব্যাপারে
                রাষ্ট্রপতিকে তিনি পরামর্শ দিতে পারেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. রাষ্ট্রপতি ও সরকারের মধ্যে সেতুবন্ধনরূপে ভূমিকা ➺ </b>{" "}
                </font>{" "}
                সরকারের যাবতীয় কাজকর্ম, নীতি ও সিদ্ধান্ত সম্পর্কে রাষ্ট্রপতিকে
                অবগত করেন প্রধানমন্ত্রী। তিনিই রাষ্ট্রপতিকে মন্ত্রীদের অপসারণ,
                পদ বণ্টন ও পদের পুনঃবণ্টন সম্পর্কে যাবতীয় পরামর্শ দিয়ে থাকেন।
                তাছাড়া পার্লামেন্টের অধিবেশন আহ্বান, স্থগিত রাখা বা ভেঙে
                দেওয়ার বিষয়ে রাষ্ট্রপতি প্রধানমন্ত্রীর থেকেই সিদ্ধান্ত নিয়ে
                থাকেন। এইভাবে রাষ্ট্রপতির সঙ্গে সরকারে সংযোগসাধনের প্রধান মাধ্যম
                হিসেবে কাজ করেন প্রধানমন্ত্রী। <br />
                <br />
                <font color="#f626d7">
                  <b>3. ক্যাবিনেট বা মন্ত্রীসভার নেতা ➺ </b>{" "}
                </font>{" "}
                ভারতীয় সংসদীয় শাসন ব্যবস্থায় শাসনবিভাগের যে ক্ষুদ্র অংশটি
                প্রধানমন্ত্রীকে সহযোগিতা করার উদ্দেশ্যে কিছু গুরুত্বপূর্ণ ও
                আস্থাভাজন মন্ত্রীদের নিয়ে গঠিত হয়, তাকে ক্যাবিনেট বলে।
                প্রধানমন্ত্রী ক্যাবিনেটের সভায় সভাপতিত্ব করে। তিনি নক্ষত্রদের
                মধ্যে সবথেকে উজ্জ্বলতম নক্ষত্র। ক্যাবিনেটের কোনো সিদ্ধান্ত তাকে
                বাদ দিয়ে হতে পারেনা। প্রধানমন্ত্রীর বিরাগভাজন কোনো মন্ত্রীই
                ক্যাবিনেটে স্থায়ী থাকতে পারে না। মন্ত্রীসভার কাজকর্ম পরিচালনার
                জন্য যে সচিবালয় থাকে তা প্রধানমন্ত্রীর নিয়ন্ত্রণাধীনে কাজ করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. সংখ্যাগরিষ্ঠ দলের বা জোটের নেতা ➺ </b>{" "}
                </font>{" "}
                প্রধানমন্ত্রী লোকসভার সংখ্যাগরিষ্ঠ দলের বা জোটের নেতা হিসেবে কাজ
                করেন। লোকসভার ভেতরে ও বাইরে তাঁকে দলের বা জোটের মর্যাদা অক্ষুণ্ণ
                রেখে চলতে হয়। দল বা জোটের ঐক্য এবং সংহতি অটুট রাখা তাঁর
                দায়িত্ব। দল বা জোটের দেওয়া প্রতিশ্রুতি সরকারি কাজকর্মের
                মাধ্যমে রূপায়ণ করার ব্যাপারে তাঁকে অগ্রণী ভূমিকা পালন করতে হয়।
                দলের বা জোটের জনপ্রিয়তা রক্ষা ও বৃদ্ধি করার দায়িত্বও।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. বিদেশনীতির রূপকার ➺ </b>{" "}
                </font>{" "}
                পররাষ্ট্রনীতি রূপায়ণে প্রধানমন্ত্রীর ভূমিকা বেশ গুরুত্বপূর্ণ। এ
                বিষয়ে তিনি পররাষ্ট্র মন্ত্রকের সঙ্গে নিয়মিত যোগাযোগ রাখেন।
                পররাষ্ট্র বা বিদেশমন্ত্রীও প্রধানমন্ত্রীকে আন্তর্জাতিক
                ক্ষেত্রগুলি সম্পর্কে অবগত করেন। বিভিন্ন আন্তর্জাতিক সম্মেলন
                যেমন—সার্ক, আসিয়ান, কমনওয়েলথ ইত্যাদি সম্রাটে ক্ষেত্রে তিনি
                ভারতের হয়ে প্রতিনিধিত্ব করেন। বিভিন্ন রাষ্ট্রের সঙ্গে সুসম্পর্ক
                গড়ে তোলা ও আন্তর্জাতিক স্তরে ভারতের মান উঁচু রাখার জন্য সর্বদা
                সচেষ্ট থাকেন। এই কারণে তাঁর বিদেশ সফর বেশ গুরুত্বপূর্ণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. দেশ ও জাতির নেতা ➺ </b>{" "}
                </font>{" "}
                প্রধানমন্ত্রী সমগ্র জাতির নেতা। পূর্ণয় এবং আন্তর্জাতিক সমস্যা
                বিশ্লেষণে জনগণের দৃষ্টি আকর্ষণ করেন প্রধানমন্ত্রী। জনগণের
                ইচ্ছা-অনিচ্ছা অনুধাবন, জনমতের উপলদ্ধি এবং নিয়ন্ত্রণ
                প্রধানমন্ত্রীর একটি গুরুত্বপূর্ণ দায়িত্ব। প্রধানমন্ত্রীর
                জনপ্রিয়তার ওপর সরকার ও দলের জনপ্রিয়তা নির্ভর করে। রাজনৈতিক ও
                অর্থনৈতিক সমস্যা সমাধানে সরকারের দৃষ্টিভঙ্গি ব্যাখ্যা করে জনগণকে
                আশ্বস্ত করেন প্রধানমন্ত্রী।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b>উপসংহার ➺ </b>{" "}
                </font>{" "}
                ভারতের সংসদীয় রাজনৈতিক ব্যবস্থায় প্রধানমন্ত্রীর ভূমিকা অত্যন্ত
                গুরুত্বপূর্ণ। বর্তমানে জোট সরকারের চল শুরু হওয়ার ফলে
                প্রধানমন্ত্রীর ক্ষমতা ও কার্যাবলিতাঁর বিচক্ষণতা, সমন্বয়
                সাধনকারী নেতৃত্ব ও যোগ্য ব্যক্তিত্বের ওপর বহুলাংশে নির্ভরশীল
                হয়ে পড়েছে।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

ভারতেরশাসনবিভাগ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(ভারতেরশাসনবিভাগ);
