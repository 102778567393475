import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function সামুদ্রিকপ্রক্রিয়াসমূহওসংশ্লিষ্টভূমিরূপ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          সামুদ্রিক প্রক্রিয়াসমূহ ও সংশ্লিষ্ট ভূমিরূপ | West Bengal Class 12
          Guide | West Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="সামুদ্রিক প্রক্রিয়াসমূহ ও সংশ্লিষ্ট ভূমিরূপ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                সামুদ্রিক প্রক্রিয়াসমূহ ও সংশ্লিষ্ট ভূমিরূপ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভূগর্ভে ভৌমজল দ্বারা সম্পৃক্ত স্তরকে বলে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাকুইকুড
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অ্যাকুইফার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাকুইফিউজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাকুইটার্ড
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>যে স্তরটি জলধারণে অক্ষম -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কৈশিক স্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাকুইফার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ভাদোস স্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সর্বদা জল নির্গত হয় যে প্রস্রবণ থেকে -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গিজার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অবিরাম প্রস্রবণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সবিরাম প্রস্রবণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আর্টেজিওকূপ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> ফনটেন দ্য ভ্যকুস কোন্ ধরনের প্রস্রবণ -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সন্ধি প্রস্রবণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আগ্নেয় প্রস্রবণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  দ্রবণ প্রস্রবণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গিজার
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভূপৃষ্ঠে কাদামাটির স্তর থাকলে ভৌমজলের পরিমাণ -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বেশি হয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  খুব বেশি হয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মাঝারি হয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  কম হয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> চামচের মতো স্থানীয় বা পার্চড অ্যাকুইফার দেখা যায় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আবদ্ধ জলবাহী স্তরের মধ্যে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাকুইফিউজের মধ্যে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাকুইকুডের মধ্যে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মুক্ত জলবাহী স্তরের মধ্যে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  চুনাপাথর ও বৃষ্টির জল দ্বারা সৃষ্ট কার্বনিক অ্যাসিডের
                  রাসায়নিক বিক্রিয়া হল —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জল যোজন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  অঙ্গার যোজন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  জারণ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আর্দ্র বিশ্লেষণ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  দুটি অপ্রবেশ্য স্তরের মধ্যে প্রবেশ্য স্তর অবস্থান করলে সৃষ্টি
                  হয় —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাকুইফিউজ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মুক্ত অ্যাকুইফার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আবদ্ধ অ্যাকুইফার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অ্যাকুইটার্ড
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>'কার্স্ট’ (জার্মান শব্দ) শব্দটির অর্থ হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিস্তীর্ণ চুনাপাথরযুক্ত ভূমি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিস্তীর্ণ তৃণভূমি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিস্তীর্ণ বালুকাময় ভূমি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  উন্মুক্ত পাথুরে ভূমি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  সোয়ালো হোল যে সুড়ঙ্গের দ্বারা ভুগর্ভের সঙ্গে যুক্ত হয়, তা
                  হল —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কার্স্ট গুহা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অন্ধ উপত্যকা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শুষ্ক উপত্যকা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পোনর
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#ec3dde">
                <b>1. সামুদ্রিক প্রক্রিয়া বলতে কী বােঝ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সমুদ্র উপকূলে ভূমিরূপ পরিবর্তনের সবচেয়ে কার্যকর ও গুরুত্বপূর্ণ
                সামুদ্রিক প্রণালীকে বলা হয় সামুদ্রিক প্রক্রিয়া।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>2. উপকূল কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সমুদ্র ও স্থলভাগের সংযােগস্থলকে উপকূল বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>3. তটভূমি কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                উপকূলের সামনের দিকের ভূমিভাগকে তটভূমি বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>4. ফেচ বলতে কী বােঝায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                উন্মুক্ত সমুদ্রে বাতাস বাধাহীনভাবে যতখানি দূরত্ব অতিক্রম করে,
                সেই দূরত্বকে ফেচ বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>5. ভূমিরূপ গঠনে কী কী বহির্জাত প্রক্রিয়া অংশগ্রহণ করে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সমুদ্রপৃষ্ঠের উচ্চতার পরিবর্তন, বিশ্বব্যাপী জলবায়ুর পরিবর্তন,
                পাত ভূসংস্থানজনিত পরিবর্তন প্রভৃতি বিভিন্ন বহির্জাত প্রক্রিয়া
                ভূমিরূপ গঠনে অংশগ্রহণ করে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>6. কীরূপ অবস্থায় বিনাশকারী তরঙ্গের সৃষ্টি হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সাধারণত সুনামির আর্বিভাবে অথবা প্রবল ঝড়ের প্রকোপে উত্তাল
                তরঙ্গরূপে বিনাশকারী তরঙ্গের আর্বিভাব হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>7. গঠনকারী তরঙ্গ কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে তরঙ্গ তটভূমিতে বিভিন্ন পদার্থের সঞ্চয় ঘটিয়ে প্রশস্ত
                বেলাভূমি গঠন করে, তাকে গঠনকারী তরঙ্গ বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>8. সমুদ্রতরঙ্গের গতিপ্রকৃতির নিয়ন্ত্রকগুলি কী কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                বায়ুপ্রবাহের গতিবেগ ও ভূমিকম্পের তীব্রতা হল সমুদ্রতরঙ্গের
                গতিপ্রকৃতির নিয়ন্ত্রকসমূহ।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>9. সমুদ্রস্রোতের গতিপ্রকৃতির নিয়ন্ত্রকগুলি কী কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                নিয়ত বায়ুপ্রবাহ, উপকূলের রূপরেখা, সমুদ্রের জলের উয়তা, লবণতার
                পার্থক্য প্রভৃতি সমুদ্রস্রোতের গতিপ্রকৃতির নিয়ন্ত্রক।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>10. ক্ল্যাপােটিস কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                প্রত্যাবর্তনকারী তরঙ্গ ও সম্মুখ তরঙ্গ দুদিক থেকে মিলিত হয়ে যে
                শব্দসহ তরঙ্গ উৎপন্ন করে তাকে ক্ল্যাপােটিস বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>11. সমুদ্রতরঙ্গ সৃষ্টির প্রধান কারণ কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সমুদ্রের ওপর দিয়ে বায়ুপ্রবাহ ও সমুদ্রের তলদেশে ভূকম্পন
                সমুদ্রতরঙ্গ সৃষ্টির প্রধান কারণ।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>12. ঘর্ষণ ক্ষয় কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                তরঙ্গবাহিত বৃহৎ আকৃতির শিলাখণ্ড পরস্পরের সঙ্গে সংঘর্ষের ফলে
                ছােটো আকৃতির শিলাখণ্ডে পরিণত হওয়াকে ঘর্ষণ ক্ষয় বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>13. দ্রবণ ক্ষয় কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                চক, চুনাপাথর, ডলােমাইট প্রভৃতি শিলাদ্বারা গঠিত উপকূলভাগকে
                সমুদ্রজলে মিশ্রিত বিভিন্ন ধরনের রাসায়নিক পদার্থ যে প্রক্রিয়ার
                মাধ্যমে ক্ষয় করে তাকে দ্রবণ ক্ষয় বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>14. প্রবাল কীটের বংশবৃদ্ধির আদর্শ পরিবেশ কোনটি?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                উষ্ণমন্ডলের মহাদেশীয় শক্ত শিলায় গঠিত অন্তঃসাগরীয় মঞ্চ হল
                প্রবাল কীটের বংশবৃদ্ধির আদর্শ পরিবেশ।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>15. প্রতিবন্ধক প্রবাল প্রাচীর কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                স্থলভাগ থেকে প্রশস্ত অগভীর উপহ্রদ দ্বারা বিচ্ছিন্ন অনুদৈর্ঘ্য
                অথবা বৃত্তাকার প্রবাল প্রাচীরকে প্রতিবন্ধক প্রবাল প্রাচীর বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>16. প্রবাল প্রাচীর গড়ে ওঠার দুটি শর্ত লেখাে।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                প্রবাল প্রাচীর গঠনের দুটি শর্ত হল- (i) স্বচ্ছ পলিমুক্ত জল, (ii)
                সমুদ্রস্রোত ও সমুদ্রতরঙ্গের মিলনস্থল।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>17. প্রান্তদেশীয় প্রবাল প্রাচীর কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                মহীসােপানের ঢালু অংশ বরাবর সংকীর্ণ বলয়াকারে এবং দ্বীপসমূহের
                চারপাশে যে প্রবাল প্রাচীর গঠিত হয়, তাকে প্রান্তদেশীয় প্রবাল
                প্রাচীর বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>18. তরঙ্গশীর্ষ বলতে কী বােঝায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সমুদ্রতরঙ্গের উর্ধ্বভঙ্গের উচ্চতম অংশ তরঙ্গশীর্ষ নামে পরিচিত।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  19. কোন প্রকার বিনাশকারী সমুদ্রতরঙ্গ উপকূলকে অধিক ক্ষয় করে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                উত্তাল সমুদ্রতরঙ্গ (সােয়াশ) উপকূলকে অধিক ক্ষয় করে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>20. ফিয়র্ড উপকূল কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                হিমবাহ অধ্যুষিত উপকূল সমুদ্রজলে নিমজ্জিত হলে, তাকে ফিয়র্ড উপকূল
                বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>21. নিরপেক্ষ উপকূল কীভাবে গড়ে ওঠে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সামুদ্রিক সঞ্জয়কার্য, অগ্নুৎপাত ও প্রবাল কীটের সঞ্চয় প্রভৃতির
                মাধ্যমে অনিয়ন্ত্রিত বা এলােমেলােভাবে নিরপেক্ষ উপকূল গড়ে ওঠে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>22. ভারতের কোথায় নিরপেক্ষ উপকূলের অস্তিত্ব দেখা যায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভারতের গঙ্গা, গােদাবরী, কৃয়া ও কাবেরীর বদ্বীপ উপকূলে নিরপেক্ষ
                উপকূলের অস্তিত্ব দেখা যায়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>23. টম্বালাে কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                কোনাে সামুদ্রিক বাঁধ কোনাে দ্বীপকে উপকূলের সাথে যুক্ত করলে, তাকে
                টম্বোেলাে বলা হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>24. রিয়া উপকূল কীভাবে সৃষ্টি হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভূ-আন্দোলনের ফলে পার্বত্য অঞ্চল বা নদী উপত্যকা অবনমিত হলে অথবা
                সমুদ্রপৃষ্ঠের উত্থান ঘটায় নদী উপত্যকা নিমজ্জিত হলে রিয়া
                উপকূলের সৃষ্টি হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  25. কোন্ উপকূলে পর্বত ও নদী উপত্যকা উপকূলরেখার সাথে
                  আড়াআড়িভাবে অবস্থান করে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                রিয়া উপকূলে পর্বত ও নদী উপত্যকা উপকূলরেখার সাথে আড়াআড়িভাবে
                অবস্থান করে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>26. ডালমেশিয়ান উপকূল কোথায় দেখা যায় ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                যুগােস্লাভিয়ার আড্রিয়াটিক সাগরে ডালমেশিয়ান উপকূল দেখা যায়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>27. যৌগিক উপকূল বলতে কী বােঝায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে সমস্ত উপকূলে উত্থান ও নিমজ্জন দুই ই সংঘটিত হয়েছে, সেই সমস্ত
                উপকূল মিশ্র বা যৌগিক উপকূল নামে পরিচিত।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>28. রিয়া ও ফিয়র্ড উপকূলের একটি পার্থক্য লেখাে।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                রিয়া ও ফিয়র্ড উপকূলের একটি পার্থক্য হল—রিয়া উপকূলের আকৃতি
                ফানেলের মতাে এবং ফিয়র্ড উপকূল ‘U’ আকৃতির হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>29. ডালমেশিয়ান উপকূল কীভাবে সৃষ্টি হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                ভূ-আন্দোলনের ফলে উপকূলের সমান্তরালে অবস্থিত পর্বতশ্রেণি
                আংশিকভাবে নিমজ্জিত হলে ডালমেশিয়ান উপকূল সৃষ্টি হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>30. তরঙ্গের গতিবেগ বলতে কী বােঝায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                একটি পর্যায়কালের মধ্যে তরঙ্গটি যতটা দূরত্ব যেতে পারে, তাকে
                তরঙ্গের গতিবেগ বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>31. অনুপ্রস্থ তরঙ্গ কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                বৃত্তাকার কক্ষপথে জলকণাসমূহ ওঠানামা করে যে তরঙ্গ সৃষ্টি করে,
                তাকে অনুপ্রস্থ তরঙ্গ বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>32. প্লাঞ্জরেখা কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে রেখা বরাবর উর্মিভঙ্গ ঘটে, তাকে প্লাঞ্জরেখা বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>33. অগ্রভূমি বা হেডল্যান্ড কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সমুদ্র উপকূলের পশ্চাদপসরণকালে অপেক্ষাকৃত বেশি ক্ষয়প্রতিরােধকারী
                শিলা তটভূমি থেকে সমুদ্রের দিকে এগিয়ে অবস্থান করলে এই রূপ
                অভিক্ষিপ্ত ভূভাগকে অগ্রভূমি বা হেডল্যান্ড বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>34. সৈকতভূমি কী কী পদার্থ দ্বারা গঠিত?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সৈকতভূমির উর্ধ্বাংশ বােল্ডার, নুড়ি ও পাথরের টুকরাে প্রভৃতি
                স্থূল পদার্থে গঠিত, কিন্তু এর নিম্নাংশ অতি সূক্ষ্ম বালুকণা দিয়ে
                গঠিত।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>35. সমুদ্রভৃগু কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সমুদ্রের ক্ষয়ের ফলে উপকূল প্রান্তে যে খাড়া তল সৃষ্টি হয় তাকে
                সমুদ্রভৃগু বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>36. দুর্বল ভৃগু কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                যেসকল ভৃগু দুর্বল শিলায় (কাদাপাথরে) গঠিত সেইসব ভৃগুতে পুঞ্জিত
                স্থলন বেশি হয়, ফলে খুব দ্রুত ক্ষয়প্রাপ্ত হয়। এদের দুর্বল ভৃগু
                বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>37. কোন্ অবস্থায় তরঙ্গকর্তিত ম সৃষ্টি হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সমুদ্র তরঙ্গের আঘাতে উপকূলের ভৃগু পিছু হটার ফলে তরঙ্গকর্তিত ম
                সৃষ্টি হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>38. তরঙ্গের পর্যায়কাল কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                কোনাে একটি স্থানের নির্দিষ্ট বিন্দুতে পরপর দুটি তরঙ্গশীর্ষ আসার
                অন্তর্বর্তী সময়কালকে তরঙ্গের পর্যায়কাল বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>39. উপকূলরেখা কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                উপকূল-বরাবর স্থলভাগের সীমারেখাকে উপকূলরেখা বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>40. সৈকত বা বেলাভূমি বলতে কী বােঝায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সমুদ্রতরঙ্গ দ্বারা বাহিত পদার্থ তটভূমির ওপর সঞ্চিত হয়ে সৃষ্ঠ
                সমতলভূমিকে সৈকত বা বেলাভূমি বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>41. লেগুন কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                পুরােদেশীয় বাঁধের পেছনে জল আবদ্ধ হয়ে যে জলাভূমির সৃষ্টি করে
                তাকে লেগুন বলা হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>42. কয়াল কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                লেগুনকে কেরলের স্থানীয় ভাষায় কয়াল বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>43. ভারতের বৃহত্তম কয়াল বা ব্যাক ওয়াটারের নাম কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                ভারতের বৃহত্তম কয়াল বা ব্যাক ওয়াটারের নাম ভেমবানাদ কয়াল।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>44. তরঙ্গদৈঘ্য বলতে কী বােঝায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                পরপর দুটি তরঙ্গশীর্ষ বা দুটি দ্রোণিতলের মধ্যবর্তী অনুভূমিক
                দূরত্বকে তরঙ্গদৈর্ঘ্য বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>45. সমুদ্রতরঙ্গ সৃষ্টির দুটি প্রাকৃতিক কারণ লেখাে।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সমুদ্রতরঙ্গ সৃষ্টির দুটি প্রাকৃতিক কারণ হল— (i) সমুদ্রপৃষ্ঠের
                সাথে বায়ুপ্রবাহের ঘর্ষণ (ii) সমুদ্রের তলদেশে ভূকম্পন।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>46. সমুদ্রতরঙ্গ সৃষ্টির দুটি কৃত্রিম কারণ লেখাে।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সমুদ্রতরঙ্গ সৃষ্টির দুটি কৃত্রিম কারণ হল- (i) জলযান চলাচল এবং
                (ii) সমুদ্রের তলদেশে পারমাণবিক বােমার বিস্ফোরণ।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>47. বিনাশকারী তরঙ্গ কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                যে তরঙ্গের প্রভাবে উপকূলভাগ ক্ষয়প্রাপ্ত ও বিনষ্ট হয়, তাকে
                বিনাশকারী তরঙ্গ বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  48. সামুদ্রিক ক্ষয়কার্যের ফলে সৃষ্ট কোন্ ভূমিরূপ থেকে জিও
                  সৃষ্টি হয়?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                {" "}
                সামুদ্রিক ক্ষয়কার্যের ফলে সৃষ্ট ভূমিরূপ ব্লো-হােল থেকে জিও
                সৃষ্টি হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>49. স্ট্যাম্প কখন সৃষ্টি হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                সমুদ্রতরঙ্গের দ্বারা স্ট্যাক ক্ষয়প্রাপ্ত হয়ে স্ট্যাম্প সৃষ্টি
                হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  50. সমুদ্রতরঙ্গের ক্ষয়ের ফলে সৃষ্ট কোন্ দণ্ডকৃতি ভূমিরূপ কেবল
                  ভাটার সময় দেখা যায়?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                সমুদ্রতরঙ্গের ক্ষয়ের ফলে সৃষ্ট স্ট্যাম্প নামক দণ্ডকৃতি
                ভূমিরূপগুলি কেবল ভাটার সময় দেখা যায়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>51. সৈকতশিরা কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                সৈকতের বা বেলাভূমির ওপরে নুড়ি, বালি ইত্যাদি সঞ্চিত হয়ে সংকীর্ণ
                ও দীর্ঘায়িত উচ্চভূমি গঠন করলে, তাকে সৈকতশিরা বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>52. একটি উল্লেখযােগ্য প্রতিবন্ধক সৈকতের নাম লেখাে।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                একটি উল্লেখযােগ্য প্রতিবন্ধক সৈকতের নাম হল আমেরিকা
                যুক্তরাষ্ট্রের টেক্সাস উপকূলের পাদ্রা।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>53. পুরােদেশীয় বাঁধ কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                স্থলভাগ থেকে বিচ্ছিন্ন উপকূলের সমান্তরালে আংশিক নিমজ্জিত বাঁধ হল
                পুরােদেশীয় বাঁধ।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>54. অনুতটীয় বাঁধ কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                উপকূল থেকে দূরে সমুদ্রের অনুতটীয় অংশে গঠিত বালির বাঁধকে
                অনুতটীয় বাঁধ বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>55. তরঙ্গদ্রোণি বা তরঙ্গাঘাত কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                তরঙ্গের অধােভঙ্গের সর্বনিম্ন অংশকে বলে তরঙ্গদ্রোণি বা তরঙ্গাঘাত।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>56. সামুদ্রিক ক্ষয়কারী প্রক্রিয়াগুলির নাম লেখাে।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                সামুদ্রিক ক্ষয়কারী প্রক্রিয়াগুলি হল (i) জলপ্রবাহজনিত ক্ষয়
                (ii) অবঘর্ষ ক্ষয় (iii) ঘর্ষণ ক্ষয় (iv) দ্রবণ ক্ষয় (v) আবদ্ধ
                বায়ুর সংকোচন ও প্রসারণজনিত ক্ষয় (vi) ক্ল্যাপােটিস বা স্থির
                তরঙ্গজনিত ক্ষয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>57. সম্মুখ তরঙ্গ বা সােয়াশ কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                তরঙ্গ সমুদ্র থেকে এসে অগভীর উপকূল পেরিয়ে সৈকতের ওপর আছড়ে পড়লে
                তাকে সােয়াশ বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>58. পশ্চাদগামী তরঙ্গ বা ব্যাকওয়াশ বলতে কী বােঝ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                সম্মুখ তরঙ্গ সৈকতের ওপর আছড়ে পড়ার পর আবার ভূমির ঢাল বরাবর
                সমুদ্রে ফিরে যায়। একে বলে পশ্চাদগামী তরঙ্গ বা প্রত্যাবর্তনকারী
                তরঙ্গ বা ব্যাকওয়াশ।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>59. উর্মিচর কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                তটভূমির যে অংশে উর্মিভঙ্গ ঘটে সেখানে পলি, বালি জমে যে চর গঠিত
                হয় তাকে উর্মিচর বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>60. বার্ম কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                যখন ঝটিকা তরঙ্গের মাধ্যমে আসা নুড়ি, বালি, প্রভৃতি সম্মুখ তটভূমি
                এবং পশ্চাৎ তটভূমির সংযােগস্থলে সঞ্চিত হয়ে সমুদ্র উপকূলের
                সমান্তরালে প্রায় অনুভূমিক শিরার মতাে স্বল্পোচ্চ ভূমি গঠন করে,
                তখন তাকে বার্ম বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>61. রিয়া শব্দের অর্থ কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                রিয়া শব্দের অর্থ—নদী উপত্যকার নিমজ্জিত অংশ।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>62. প্রশস্ত বেলাভূমি কোন্ প্রকার তরঙ্গের দ্বারা গঠিত হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                প্রশস্ত বেলাভূমি সম্মুখ তরঙ্গ দ্বারা গঠিত হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>63. ব্যাকওয়াশ কেন বেশি শক্তিশালী তরঙ্গরূপে পরিচিত?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                ব্যাকওয়াশ ঢাল বেয়ে সমুদ্রে প্রত্যাবর্তনের সময় মাধ্যাকর্ষণ
                শক্তির অনুকূলে কাজ করায় বেশি শক্তিশালী হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>64. সােয়াশ বা সম্মুখ তরঙ্গ দুর্বল প্রকৃতির কেন?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                প্রত্যাবর্তনকারী তরঙ্গ দ্বারা বাধাপ্রাপ্ত হয় বলে এবং অভিকর্ষের
                প্রতিকূলে প্রবাহিত হওয়ায় এই তরঙ্গ দুর্বল প্রকৃতির হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>65. কোন্ ধরনের সামুদ্রিক জলস্রোত চোরাস্রোত নামে পরিচিত?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                উজানী তটীয় প্রবাহ বা স্রোত চোরাস্রোত নামে পরিচিত।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>66. যৌগিক ভৃগু কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                যে ধরনের ভৃগুতে কঠিন ও কোমল শিলাস্তর পরপর অবস্থান করে তাকে যৌগিক
                ভৃগু বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>67. উপকূলে ভৃগুর উৎপত্তির নিয়ন্ত্রকসমূহ কী কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                উপকূলে ভূগুর উৎপত্তির নিয়ন্ত্রকসমূহ হলশিলার কাঠিন্য, শিলার গঠন,
                ঢেউ-এর প্রকৃতি প্রভৃতি।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  68. সমুদ্রভৃগু সমুদ্রের কোন দুটি জলতল সীমার মধ্যে সৃষ্টি হয়?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                সমুদ্রভৃগু উচ্চ জলতল সীমা ও নিম্ন জলতল সীমার মধ্যে সৃষ্টি হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>69. উর্মিভঙ্গ কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                উর্মি’ কথাটির অর্থ তরঙ্গ বা ঢেউ। তরঙ্গ যখন ভেঙে পড়ে তখন তাকে
                বলে উর্মিভঙ্গ।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>70. বাঁধ (Bar) কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                যখন উপকূলের কাছাকাছি অগভীর সমুদ্রবক্ষে তরঙ্গবাহিত নুড়ি, কাঁকর,
                বালি প্রভৃতি সঞ্চিত সংকীর্ণ দ্বীপের মতাে চড়া পড়ে অর্থাৎ নতুন
                ভূভাগ গঠিত হয়, তখন তাকে বাঁধ বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>71. ম্পিট কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                বালির বাঁধের একপ্রান্ত স্থলভাগের সঙ্গে যুক্ত হয়ে অন্য প্রান্ত
                সমুদ্রের দিকে বিস্তৃত হলে এবং রেখা বরাবর সজ্জিত হলে তাকে ম্পিট
                বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>72. ব্যারিয়ার রিফ বা প্রতিবন্ধক প্রাচীর কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                মূল ভূখণ্ড থেকে বেশ দূরে (প্রায় কয়েকশাে মিটার থেকে 5 কিমি) যখন
                উপকূলের সমান্তরালে বৃহদাকার প্রবাল প্রাচীর গড়ে ওঠে, তাকে
                ব্যারিয়ার রিফ বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>73. অ্যাটল কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                যখন উপকূল থেকে বহুদূরে সমুদ্রের মাঝে বৃত্তাকারে প্রবাল প্রাচীর
                গড়ে ওঠে তাকে অ্যাটল বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>74. অঙ্গুরীয় আকৃতিবিশিষ্ট প্রবাল প্রাচীর কী নামে পরিচিত?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                অঙ্গুরীয় আকৃতিবিশিষ্ট প্রবাল প্রাচীর অ্যাটল নামে পরিচিত।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>75. অ্যাটলের একটি প্রধান বৈশিষ্ট্য লেখাে।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                অ্যাটলের একটি প্রধান বৈশিষ্ট্য হল—এটি সাধারণত অশ্বক্ষুরাকৃতি বা
                অঙ্গুরীয় আকৃতির বা উপবৃত্তাকার হয়ে থাকে, যার ওপরে উদ্ভিদ
                জন্মায়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  76. নিমজ্জিত উপকূলে কী ধরনের সামুদ্রিক সঞ্চয়জাত ভূমিরূপ
                  সৃষ্টি হয়?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                নিমজ্জিত উপকূলে পুরােদেশীয় বাঁধ, স্পিট, জলাভূমি, উপহ্রদ ইত্যাদি
                সৃষ্টি হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>77. পুরােদেশীয় বাঁধ কীরকম অবস্থায় স্পিট গঠন করে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                একই রেখা বরাবর পুরােদেশীয় বাঁধের এক প্রান্ত স্থলভাগের সঙ্গে
                যুক্ত ও অন্য প্রান্ত সমুদ্রে শেষ হলে স্পিট গঠিত হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>78. পুরােদেশীয় মঞ্ কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                তরঙ্গকর্তিত মঞ্চের যে অংশ নিম্ন জোয়ারের জলতলের নীচে থাকে তাকে
                পুরােদেশীয় মঞ্চ বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>79. ব্যাকওয়াশ কীভাবে প্রবাহিত হয় ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                ভূমির ঢালের অনুকূলে ব্যাকওয়াশ প্রবাহিত হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>80. অনুদৈর্ঘ্য তরঙ্গ কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                অনুপ্রস্থ তরঙ্গ উল্লম্বভাবে ওঠানামা করার পরিবর্তে তটভূমির
                সমান্তরালে উপকূলের দিকে প্রবাহিত হলে, তাকে 'অনুদৈর্ঘ্য তরঙ্গ
                বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  81. তলদেশ প্রবাহ বা উজানী তটীয় প্রবাহ বা চোরাস্রোত কাকে বলে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                দুটি অনুতটীয় তরঙ্গ উভয়দিক থেকে এসে একত্রে মিলিত হয়ে অধিক ঢাল
                বরাবর সমুদ্রগর্ভের দিকে প্রবল বেগে অগ্রসর হলে তাকে চোরাস্রোত
                বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  82. সমুদ্রের উচ্চ ও নিম্ন জলসীমার মধ্যে কোন্ সঞ্চয়জাত ভূমিরূপ
                  গড়ে ওঠে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                সমুদ্রের উচ্চ ও নিম্ন জলসীমার মধ্যে সম্মুখ তটভূমি গড়ে ওঠে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>83. সম্মুখ তটভূমির বিস্তার কতদূর?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                মরা কোটালে ভাটার সময় চিহ্নিত জলতলের সর্বনিম্ন সীমা এবং ভরা
                কোটালে জোয়ারের সময় চিহ্নিত সর্বোচ্চ সীমার মধ্যবর্তী অংশে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>84. পশ্চাৎ তটভূমি কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                সম্মুখ তটভূমির উর্ধ্বসীমা এবং ভৃগুতটের পাদদেশ পর্যন্ত বিস্তীর্ণ
                অঞ্চল পশ্চাৎ তটভূমি নামে পরিচিত।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>85. পুরােদেশীয় তটভূমি কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                সম্মুখ তটভূমির নিম্নসীমা এবং মহীসােপানের শেষ প্রান্ত পর্যন্ত
                বিস্তৃত অঞ্চলকে পুরােদেশীয় তটভূমি বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>86. কোরাল ব্লিচিং বলতে কী বােঝায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                কোরাল বা প্রবালগুলি জীবনধারণের জন্য মিথােজীবী জুজ্যানথেলি
                (zooxanthellae) শৈবালের ওপর। নির্ভরশীল। কোনাে কারণে প্রবালগুলির
                দেহ থেকে এই শৈবালের অপসারণ ঘটলে প্রবালগুলির মৃত্যু হয় এবং তারা
                সাদা বা ধূসর আকারের হয়, একে কোরাল ব্লিচিং বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  87. দুটি স্পিট সমুদ্রের দিকে পরস্পরের সঙ্গে মিলিত হলে যে
                  ত্রিকোণাকার ভূখণ্ড গঠিত হয়, তাকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                দুটি স্পিট সমুদ্রের দিকে পরস্পরের সঙ্গে মিলিত হলে যে ত্রিকোণাকার
                ভূখণ্ড গঠিত হয়, তাকে কাম্পেট পুরােভূমি বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>88.সমুদ্রভৃগুর পশ্চাদপসরণ বলতে কী বােঝায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                সমুদ্রতরঙ্গের আঘাতে ভৃগুর পাদদেশে খাঁজের সৃষ্টি হলে এবং ক্রমাগত
                ক্ষয়ের ফলে তা বেড়ে গিয়ে ভৃগুর সামনের অংশ ধসে পড়ে, একে
                সমুদ্রভৃগুর পশ্চাদপসরণ বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>89. ক্ল্যাপােটিস ক্ষয় বলতে কী বােঝ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                ক্ল্যাপােটিস তরঙ্গের ক্ষেত্রে জলতলের চাপের হ্রাসবৃদ্ধির ফলে
                সমুদ্রগর্ভে শিলা ফেটে আলগা হয়ে অপসারিত হলে উপকূল ক্ষয় হয়। এই
                ক্ল্যাপােরটিসের মাধ্যমে সংঘটিত ক্ষয়কে ক্ল্যাপােরটিস ক্ষয় বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>90. সমুদ্রগুহা কীভাবে সৃষ্টি হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                সমুদ্রভৃগুর দুর্বল অংশ ক্ষয়ের মাধ্যমে সমুদ্রগুহা সৃষ্টি করে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>91. সামুদ্রিক খিলান কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                সমুদ্রতরঙ্গের আঘাতে সমুদ্রগুহার আকার ক্রমাগত বৃদ্ধি পেয়ে অপর
                প্রান্ত পর্যন্ত প্রসারিত হলে মনে হয় যেন একটি সেতু দুটি ভিন্ন
                শিলাদেহকে যুক্ত করেছে। এইরূপ স্বাভাবিক সেতুকে সামদ্রিক খিলান
                বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>92. জিও কী?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                ক্ষয়কার্যের ফলে ব্লো-হােলের আয়তন বেড়ে গিয়ে এবং গুহার ছাদ ধসে
                গিয়ে যে দীর্ঘ ও সংকীর্ণ খাড়ির সৃষ্টি করে তাকে স্কটল্যান্ডে জিও
                বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>93. কোভ কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                সমুদ্র উপকূলের নরম শিলা তরঙ্গের আঘাতে ক্ষয়প্রাপ্ত হয়ে স্বল্প
                পরিসরের যে উত্তল বৃত্তচাপীয় খাঁড়ি সৃষ্টি হয়, তাকে কোভ বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>94. সমুদ্রস্রোত বলতে কী বােঝায়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                বায়ুপ্রবাহের মতাে সমুদ্রের জলরাশির এক জায়গা থেকে অন্য জায়গায়
                প্রবাহকে সমুদ্রস্রোত বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>95. জলপ্রবাহজনিত ক্ষয় কাকে বলে ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                প্রবল ঢেউয়ের আঘাতে উপকূলের শিলারাশি চূর্ণবিচূর্ণ হয়ে উপকূলভাগ
                ক্ষয়প্রাপ্ত হয়, একে জলপ্রবাহজনিত ক্ষয় বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>96. প্রবাল প্রাচীরকে 'সমুদ্রের বৃষ্টি অরণ্য' বলা হয় কেন?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                জীববৈচিত্র্যের প্রাচুর্যের জন্য প্রবাল প্রাচীরকে 'সমুদ্রের
                বৃষ্টি অরণ্য' বলা হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>97. তরঙ্গ উচ্চতা বলতে কী বােঝায় ?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                তরঙ্গ দ্রোণিতল থেকে তরঙ্গশীর্ষ পর্যন্ত উল্লম্ব উচ্চতাকে তরঙ্গ
                উচ্চতা বলে।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>98. সমুদ্রতরঙ্গ সৃষ্টির একটি কৃত্রিম কারণের উল্লেখ করাে।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                জলযান চলাচলের মাধ্যমে স্থানীয়ভাবে সমুদ্রতরঙ্গের উৎপত্তি হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>99. সােয়াশ কীভাবে প্রবাহিত হয়?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                ভূমির ঢালের বিপরীতে সােয়াশ প্রবাহিত হয়।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>100. স্বল্প বক্তৃতাযুক্ত দীর্ঘ সাগরতট কী নামে পরিচিত?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                স্বল্প বক্রতাযুক্ত দীর্ঘ সাগরতট বাইট নামে পরিচিত।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>
                  101. বেশি বক্রতাযুক্ত তিন দিক স্থলবেষ্টিত অগভীর সাগরকে কী বলে?
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                বেশি বক্রতাযুক্ত তিন দিক স্থলবেষ্টিত অগভীর সাগরকে বলে উপসাগর।
              </font>
              <br />
              <br />

              <font color="#ec3dde">
                <b>102. সমুদ্রের উন্মুক্ত অংশের দৈর্ঘ্য কী নামে পরিচিত?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর :</b>
              </font>
              <font color="#3c92e5">
                সমুদ্রের উন্মুক্ত অংশের দৈর্ঘ্য ফেচ নামে পরিচিত।
              </font>
              <br />
              <br />
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

সামুদ্রিকপ্রক্রিয়াসমূহওসংশ্লিষ্টভূমিরূপ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  withConnect,
  memo
)(সামুদ্রিকপ্রক্রিয়াসমূহওসংশ্লিষ্টভূমিরূপ);
