import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function PreviousyearQuestionpaper2017() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          Previous year Question paper 2017 | West Bengal Class 12 Guide | West
          Bengal HS Suggestion
        </title>
        <meta
          name="description"
          content="Previous year Question paper 2017 | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Previous year Question paper 2017
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>অবরোহণ প্রক্রিয়ার ফলে সৃষ্ট একটি ভূমিরূপ হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ক্ষয়জাত পর্বত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আগ্নেয় পর্বত
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  প্লাবনভূমি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বাজাদা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ভৌম জলস্তর এর নিচে অবস্থিত যে জলবাহী স্তর থেকে সব ঋতুতেই জল
                  পাওয়া যায়, তাকে বলে —{" "}
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভাদোস স্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কৈশিক স্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সাময়িক সম্পৃক্ত স্তর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  স্থায়ী সম্পৃক্ত স্তর
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  সামুদ্রিক সঞ্চয় কাজের ফলে সৃষ্ট বাঁধের একাংশ যদি সমুদ্রে
                  প্রক্ষিপ্ত হয় এবং অন্য প্রান্ত স্থলভাগের সঙ্গে যুক্ত থাকে,
                  তাকে বলে —{" "}
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  টম্বোলো
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  স্পিট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লেগুন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অগ্রভূমি
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  নদীর পুনর্যৌবন লাভ এর ফলে গঠিত হয় না এমন একটি ভূমিরূপ হল —{" "}
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উপত্যাকার মধ্যে উপত্যকা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মোনাডনক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নিক বিন্দু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নদী মঞ্চ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>শতদ্রু নদী যে প্রকার নদীর উদাহরণ তা হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পূর্ববর্তী নদী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পরবর্তী নদী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  অধ্যারোপিত নদী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিপরা নদী
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>স্পেডো সল মৃত্তিকার একটি উদাহরণ হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  পডজল
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পলিমাটি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চারনোজেম
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ল্যাটেরাইট
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  মৃত্তিকা পরিলেখের 'A' স্তর থেকে 'B' স্তরে পদার্থের অপসারণের
                  পদ্ধতিকে বলে —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হিউমিফিকেশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্যালিনাইজেশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইলুভিয়েশন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  এলুভিয়েশন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b> জেট বায়ু প্রবাহ দেখা যায় —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ঊর্ধ্ব ট্রপোস্ফিয়ারের
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ঊর্ধ্ব স্ট্র্যাটোস্ফিয়ারে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ঊর্ধ্ব মেসোস্ফিয়ারে
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্ট্রাটো পজে
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  দক্ষিণ আমেরিকার আমাজন নদীর অববাহিকা অঞ্চলটি যে প্রকার জলবায়ু
                  অঞ্চলের অন্তর্গত, তা হল —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  মৌসুমি জলবায়ু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভূমধ্যসাগরীয় জলবায়ু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  উষ্ণ মরু জলবায়ু
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  নিরক্ষীয় জলবায়ু
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতে মিথেন গ্যাসের একটি প্রধান উৎস হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ধান চাষের জমি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফলের বাগান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ফুলের বাগান
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চা বাগান
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#1f7405" size="5">
                <b>H.S QUESTION PAPER 2017</b>
              </font>
              <br />
              <font color="#3c92e5">
                <font color="#ed9c23" size="5">
                  <b>Geography</b>
                </font>
                <br />
                <font color="#ed9c23">(New Syllabus)</font>
                <br />
                <font color="#f11eda">
                  Total Time : 3 Hours 15 minutes
                  <br />
                  Total Marks : 70
                </font>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#1f7405" size="4">
                <b>PART - B (Marks : 35)</b>
              </font>
              <br />
              <br />

              <font color="#ed9c23">
                <b>
                  1. প্রতিটি প্রশ্নের বিকল্প উত্তরগুলির মধ্যে থেকে সঠিক উত্তরটি
                  বেছে নিয়ে ডানদিকে নীচে প্রদত্ত বাক্সে লেখাে :{" "}
                  <font color="#1f7405">
                    <b> 1 x 21 = 21</b>
                  </font>
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                <font color="#f11eda">
                  <b>i) অবরোহণ প্রক্রিয়ার ফলে সৃষ্ট একটি ভূমিরূপ হল —</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) ক্ষয়জাত পর্বত</b>
                </font>
                <br />
                (b) আগ্নেয় পর্বত
                <br />
                (c) প্লাবনভূমি
                <br />
                (d) বাজাদা
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    ii) ভৌম জলস্তর এর নিচে অবস্থিত যে জলবাহী স্তর থেকে সব ঋতুতেই
                    জল পাওয়া যায়, তাকে বলে —
                  </b>
                </font>
                <br />
                (a) ভাদোস স্তর
                <br />
                (b) কৈশিক স্তর
                <br />
                (c) সাময়িক সম্পৃক্ত স্তর
                <br />
                <font color="#1f7405">
                  <b>(d) স্থায়ী সম্পৃক্ত স্তর</b>
                </font>
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    iii) সামুদ্রিক সঞ্চয় কাজের ফলে সৃষ্ট বাঁধের একাংশ যদি
                    সমুদ্রে প্রক্ষিপ্ত হয় এবং অন্য প্রান্ত স্থলভাগের সঙ্গে
                    যুক্ত থাকে, তাকে বলে —
                  </b>
                </font>
                <br />
                (a) টম্বোলো
                <br />
                <font color="#1f7405">
                  <b>(b) স্পিট</b>
                </font>
                <br />
                (c) লেগুন
                <br />
                (d) অগ্রভূমি
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    iv) নদীর পুনর্যৌবন লাভ এর ফলে গঠিত হয় না এমন একটি ভূমিরূপ
                    হল —
                  </b>
                </font>
                <br />
                (a) উপত্যাকার মধ্যে উপত্যকা
                <br />
                <font color="#1f7405">
                  <b>(b) মোনাডনক</b>
                </font>
                <br />
                (c) নিক বিন্দু
                <br />
                (d) নদী মঞ্চ
                <br />
                <br />
                <font color="#f11eda">
                  <b>v) শতদ্রু নদী যে প্রকার নদীর উদাহরণ তা হল — </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) পূর্ববর্তী নদী</b>
                </font>
                <br />
                (b) পরবর্তী নদী
                <br />
                (c) অধ্যারোপিত নদী
                <br />
                (d) বিপরা নদী
                <br />
                <br />
                <font color="#f11eda">
                  <b>vi) স্পেডো সল মৃত্তিকার একটি উদাহরণ হল —</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) পডজল </b>
                </font>
                <br />
                (b) পলিমাটি
                <br />
                (c) চারনোজেম
                <br />
                (d) ল্যাটেরাইট
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    vii) মৃত্তিকা পরিলেখের 'A' স্তর থেকে 'B' স্তরে পদার্থের
                    অপসারণের পদ্ধতিকে বলে —
                  </b>
                </font>
                <br />
                (a) হিউমিফিকেশন
                <br />
                (b) স্যালিনাইজেশন
                <br />
                (c) ইলুভিয়েশন
                <br />
                <font color="#1f7405">
                  <b>(d) এলুভিয়েশন</b>
                </font>
                <br />
                <br />
                <font color="#f11eda">
                  <b>viii) জেট বায়ু প্রবাহ দেখা যায় —</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) ঊর্ধ্ব ট্রপোস্ফিয়ারের</b>
                </font>
                <br />
                (b) ঊর্ধ্ব স্ট্র্যাটোস্ফিয়ারে
                <br />
                (c) ঊর্ধ্ব মেসোস্ফিয়ারে
                <br />
                (d) স্ট্রাটো পজে
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    ix) দক্ষিণ আমেরিকার আমাজন নদীর অববাহিকা অঞ্চলটি যে প্রকার
                    জলবায়ু অঞ্চলের অন্তর্গত, তা হল —
                  </b>
                </font>
                <br />
                (a) মৌসুমি জলবায়ু
                <br />
                (b) ভূমধ্যসাগরীয় জলবায়ু
                <br />
                (c) উষ্ণ মরু জলবায়ু
                <br />
                <font color="#1f7405">
                  <b>(d) নিরক্ষীয় জলবায়ু</b>
                </font>
                <br />
                <br />
                <font color="#f11eda">
                  <b>x) ভারতে মিথেন গ্যাসের একটি প্রধান উৎস হল —</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) ধান চাষের জমি</b>
                </font>
                <br />
                (b) ফলের বাগান
                <br />
                (c) ফুলের বাগান
                <br />
                (d) চা বাগান
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f11eda">
                <b>
                  xi) সংরক্ষণযোগ্য উদ্ভিদ ও প্রাণী দের সংখ্যা যে পুস্তকের
                  মাধ্যমে প্রকাশ করা হয়, তাকে বলে —
                </b>
              </font>
              <br />
              <font color="#3c92e5">
                (a) গ্রিন ডেটা বুক
                <br />
                (b) গ্রিন ডেটা কার্ড
                <br />
                <font color="#1f7405">
                  <b>(c) রেড ডেটা বুক</b>
                </font>
                <br />
                (d) রেড ডেটা কার্ড
                <br />
                <br />
                <font color="#f11eda">
                  <b>xii) ভারতের একটি ধ্বস প্রবন রাজ্য হল —</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) হিমাচল প্রদেশ</b>
                </font>
                <br />
                (b) উত্তর প্রদেশ
                <br />
                (c) অন্ধ্রপ্রদেশ
                <br />
                (d) মধ্যপ্রদেশ
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    xiii) শুষ্ক কৃষি যে সকল অঞ্চলে প্রচলিত সেখানে সর্বাধিক
                    বার্ষিক বৃষ্টিপাতের পরিমাণ হল —{" "}
                  </b>
                </font>
                <br />
                (a) 25 সেমি
                <br />
                <font color="#1f7405">
                  <b>(b) 50 সেমি</b>
                </font>
                <br />
                (c) 75 সেমি
                <br />
                (d) 100 সেমি
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    xiv) যিনি প্রথম 'শস্য সমন্বয়' ধারণাটি অবতারণা করেন তার নাম
                    হল —
                  </b>
                </font>
                <br />
                (a) ওয়েবার
                <br />
                <font color="#1f7405">
                  <b>(b) উইভার</b>
                </font>
                <br />
                (c) ভন থুনেন
                <br />
                (d) জিমারম্যান
                <br />
                <br />
                <font color="#f11eda">
                  <b>xv) মালয়েশিয়ার একটা গুরুত্বপূর্ণ শিল্প হল —</b>
                </font>
                <br />
                (a) পাট শিল্প
                <br />
                (b) কাগজ শিল্প
                <br />
                <font color="#1f7405">
                  <b>(c) রবার শিল্প</b>
                </font>
                <br />
                (d) পেট্রোরসায়ন শিল্প
                <br />
                <br />
                <font color="#f11eda">
                  <b>xvi) শিল্পের অবস্থানগত তত্ত্ব সর্বপ্রথম প্রবর্তন করেন —</b>
                </font>
                <br />
                (a) জিমারম্যান
                <br />
                (b) ভন থুনেন
                <br />
                <font color="#1f7405">
                  <b>(c) ওয়েবার</b>
                </font>
                <br />
                (d) আগস্ট লস
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    xvii) পর্যটন যে প্রকার অর্থনৈতিক ক্ষেত্রে র অন্তর্গত তা হল —
                  </b>
                </font>
                <br />
                (a) প্রাথমিক ক্ষেত্র
                <br />
                (b) দ্বিতীয় ক্ষেত্র
                <br />
                <font color="#1f7405">
                  <b>(c) তৃতীয় ক্ষেত্র</b>
                </font>
                <br />
                (d) চতুর্থ ক্ষেত্র
                <br />
                <br />
                <font color="#f11eda">
                  <b>xviii) সোনালী পোশাকের কর্মীরা যে কাজে নিযুক্ত, তা হল —</b>
                </font>
                <br />
                (a) কৃষিকাজ
                <br />
                (b) শিল্পকর্ম
                <br />
                (c) পরিবহন
                <br />
                <font color="#1f7405">
                  <b>(d) পরামর্শদান</b>
                </font>
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    xix)) জনসংখ্যা বিবর্তন তত্ত্ব এর প্রথম পর্যায় বলতে বোঝায় —
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>(a) প্রাক শিল্প বিপ্লবের সময়কাল</b>
                </font>
                <br />
                (b) শিল্প বিপ্লবের সময়কাল
                <br />
                (c) শিল্প বিপ্লবের পরবর্তী সময় কাল
                <br />
                (d) বর্তমান সময়কাল
                <br />
                <br />
                <font color="#f11eda">
                  <b>xx) কার্যাবলী ভিত্তিতে বারানসি শহরটি হল —</b>
                </font>
                <br />
                (a) প্রশাসনিক শহর
                <br />
                <font color="#1f7405">
                  <b>(b) ধর্মীয় শহর</b>
                </font>
                <br />
                (c) প্রতিরক্ষামূলক শহর
                <br />
                (d) শিল্পনগরী
                <br />
                <br />
                <font color="#f11eda">
                  <b>xxi) ছত্রিশগড়ের একটি কয়লা উৎপাদনকারী কেন্দ্র হল —</b>
                </font>
                <br />
                (a) বায়লাডিলা
                <br />
                (b) বিলাসপুর
                <br />
                (c) দাল্লি রাজ হারা
                <br />
                <font color="#1f7405">
                  <b>(d) কোরবা</b>
                </font>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#ed9c23">
                <b>
                  2. নিম্মলিখিত প্রশ্নগুলির উত্তরদাও : ( বিকল্প প্রশ্নগুলি
                  লক্ষণীয় ) :{" "}
                  <font color="#1f7405">
                    <b>1 × 14 = 14</b>
                  </font>
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                <font color="#f11eda">
                  <b>(i) মানুষ জমি অনুপাতের সংজ্ঞা দাও?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                কোনো দেশ বা অঞ্চলে বসবাসকারী সাংস্কৃতিক গুণাবলীযুক্ত মোট
                জনসংখ্যা ও মোট কার্যকর জমির সংখ্যাসূচক অনুপাতকে মানুষ জমি অনুপাত
                বলে।
                <br />
                মানুষ জমি অনুপাত = (মোট জনসংখ্যা + তাদের কর্মক্ষমতা + সংস্কৃতি)
                ÷ (মোট কার্যকরী জমির আয়তন x জমির উৎপাদন ক্ষমতা) <br />
                <br />
                <font color="#f11eda">
                  <b>অথবা, পৌর পুঞ্জের সংজ্ঞা দাও?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                একই পথের দ্বার যুক্ত অথচ বিচ্ছিন্ন একগুচ্ছ শহর ক্রমশ্য
                সম্প্রসারিত হয়ে পরস্পর মিলিত হয়ে যে বহুদূর বিস্তৃত নিরবচ্ছিন্ন
                পৌর অঞ্চলে পরিণত হয়, তাকে পৌরপুঞ্জ বলে ।যেমন কলকাতা পৌরপুঞ্জ।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(ii) অনুসারী শিল্পের সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                যে সমস্ত শিল্প মূল বা প্রধান শিল্পের উৎপাদিত দ্রব্যকে কাঁচামাল
                হিসাবে ব্যবহার করে বাজারে চাহিদা আছে, এমন কোনো নতুন পন্য উৎপাদন
                করে, তাকে অনুসারী শিল্প বলে।যেমন : পেট্রোরসায়ন শিল্পের অনুসারী
                শিল্প হল কৃত্রিম তন্তু সার উৎপাদন প্রভৃতি।
                <br />
                <br />
                <font color="#f11eda">
                  <b>অথবা, কোন শহরকে দক্ষিণ ভারতের ম্যাঞ্চেস্টার বলা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                কোয়েম্বাটোর।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(iii) হড়পা বান বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                স্থানীয়ভাবে হঠাৎ করে অল্পসময়ের মধ্যে অতিরিক্ত মাত্রায়
                বৃষ্টিপাত হলে (মেঘভাঙ্গা বৃষ্টি) যে বিশাল মাত্রায় দ্রুতগতির
                জলপ্রবাহ ঘটে তাকে হড়পা বান বলে । ২০১৩ সালে আগস্ট মাসে
                উত্তরাখন্ডে এই ধরনের বন্যায় প্রচুর জীবনহানী ঘটে ও সম্পত্তি নষ্ট
                হয়।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(iv) জলবায়ু পরিবর্তনের দুটি নিদর্শন উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ১.বিশ্ব জুড়েই উষ্ণতার পরিমান বৃদ্ধি হচ্ছে। <br />
                ২.পার্বত্য অঞ্চল ও দুই মেরু অঞ্চলের হিমবাহ গুলি দ্রুত গলে
                যাচ্ছে।
                <br />
                ৩. সমুদ্রতলের উচ্চতা বৃদ্ধি পাচ্ছে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(v) হিউমিফিকেশন কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ভূমিতে থাকা নানা প্রকার অনুজীব বা সূক্ষ্মজীবাণুরা উদ্ভিদ বা
                প্রাণীর দেহাবশেষ, ঝরা পাতা ও অন্যান্য জৈব পদার্থকে বিয়োজিত করে
                হিউমাসে পরিণত করে । হিউমাস সৃষ্টির এই প্রক্রিয়াকে হিউমিফিকেশন
                বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    অথবা, মাটির রাসায়নিক বিক্রিয়ার ভিত্তিতে মৃত্তিকার
                    শ্রেণিবিভাগ করো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                রাসায়নিক বিক্রিয়া অনুসারে মৃত্তিকা তিনপ্রকার –<br />
                ১. অম্লমাটি : PH এর মান ৭ এর কম হয়।
                <br />
                ২. প্রশমিত মাটি : PH এর মান ৭ হয়।
                <br />
                ৩. ক্ষারকীয় মাটি : PH এর মান ৭ এর বেশি হয়।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(vi) সমপ্রায়ভূমির সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                ডব্লু. এম ডেভিস এর স্বাভাবিক ক্ষয়চক্রের বার্ধক্য পর্যায়ে নদীর
                কার্যের ফলে ঢেউখেলানো বৈচিত্রহীন, নীচু সমভূমির সৃষ্টি হয় তাকে
                সমপ্রায়ভূমি বলে ।<br />
                <br />
                <font color="#f11eda">
                  <b>অথবা, পেডিমেন্ট কিভাবে গঠিত হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                মরুভূমিতে অবস্থিত উচ্চভূমির পাদদেশে প্রথমে বায়ুর ক্ষয়কার্যের
                মাধ্যমে নীচু প্রায় সমতলভূমি সৃষ্টি হয়। পরবর্তীতে জলের সঞ্চয়
                কার্যের মাধ্যমে পার্বত্য অঞ্চলের পাদদেশে অবতল সমতলভূমি বা
                পেডিমেন্ট গঠিত হয়।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(vii) টর কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                গ্রানাইট শিলা গঠিত অঞ্চলে যান্ত্রিক আবহবিকারের পর সৃষ্ট রেগোলিথ
                অপসারিত হয়ে নিচের কঠিন শিলা উঁচু হয়ে খাড়া ভাবে দাঁড়িয়ে
                থাকলে ওই ভূমিরূপকে টর বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    (viii) ছত্রিশ গড়ে ইন্দ্রাবতী নদীর প্রবাহ পথে যে বিখ্যাত
                    জলপ্রপাতটি দেখা যায়, তার নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                চিত্রকূট জলপ্রপাত।
                <br />
                <br />
                <font color="#f11eda">
                  <b>অথবা, উন্নয়নের সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                কোনো অঞ্চলের অর্থনৈতিক . সামাজিক ও রাজনৈতিক অবস্থার সামগ্রিক
                উন্নতির সঙ্গে পরিবেশের সংরক্ষণ ও উন্নতি সাধনকে উন্নয়ন বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(ix) কুইনারী স্তরের অন্তর্ভূক্ত কার্যাবলীর নাম করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                বিশেষজ্ঞ , সিদ্ধান্ত গ্রহণকারী , পেশাদার উপদেষ্টা বা পরমর্শদাতা
                · নীতি প্রণেতা বা প্রকল্প রূপকার।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(x) আর্দ্র কৃষির সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                পৃথিবীর যে সমস্ত অঞ্চলে প্রচুর পরিমানে নিয়মিত বৃষ্টিপাত হয়
                সেখানে জসসেচ ছাড়াই বৃষ্টির জলে কৃষিকাজ করাকেই আর্দ্র কৃষি বলে।
                নিরক্ষীয় ও মৌসুমি জলবায়ু অঞ্চলে আদ্র কৃষি দেখা যায়।
                <br />
                <br />
                <font color="#f11eda">
                  <b>
                    অথবা, ভারতের কোন রাজ্য কফি উৎপাদনে প্রথম স্থান অধিকার করে?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                কর্ণাটক রাজ্য।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xi) জৈব বৈচিত্র বিনাশের দুটি কারণ উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>
                ১. জলবায়ু পরিবর্তন <br />
                ২. অতিরিক্ত শিকার বা চোরা শিকার <br />
                ৩. দূষণ
                <br />
                ৪. বহিরাগত প্রজাতির প্রাদুর্ভাব প্রভৃতি।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xii) লা নিনা বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                লা নিনা হল এল নিনোর সম্পূর্ণ বিপরীত অবস্থা। এলনিনোর পরবর্তী
                পর্যায়ে যখন ক্রান্তীয় প্রশান্ত মহাসাগরের পূর্বভাগে শুষ্ক ও
                শান্ত আবহাওয়া বিরাজ করে তখন তাকে লা নিনা বলে। এই সময় পেরু
                ইকুয়েডর উপকূলে অনাবৃষ্টি ও অস্ট্রেলিয় - ইন্দোনেশিয় অঞ্চলে
                প্রবল বৃষ্টি হয়।
                <br />
                <br />
                <font color="#f11eda">
                  <b>অথবা, অন্তর্ধৃত সীমানার সংজ্ঞা দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                নাতিশীতোষ্ণ ঘূর্ণবাত সৃষ্টির শেষ দিকে দ্রুতগামী শীতল সীমান্ত,
                উষ্ণ সীমান্তকে ধরে ফেলে এবং দুটি সীমান্ত মিলিত হয়ে একটি
                সীমান্তের সৃষ্টি করে এবং উষ্ণ আর্দ্র বায়ুপুঞ্জের বিচ্ছিন্ন অংশ
                শীতল বায়ুপুঞ্জের মধ্যে চক্রাকারে অবস্থান করে, এই অবস্থাকে
                অক্লুসন এবং সীমান্তকে অক্লুসন বা অন্তর্ধৃত সীমানা বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xiii) অঙ্গুরীয় জলনির্গম প্রণালী বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                গম্বুজাকৃতি পর্বতের দূর্বল শিলাস্তরকে অনুসরণ করে কেন্দ্ৰবিমুখ
                ছোটো ছোটো নদী আংটির মতো গোলাকৃতি পথে যে নদী নকশা গড়ে ওঠে তাকে
                অঙ্গুরীয় জলনির্গম প্রণালী বলে।
                <br />
                <br />
                <font color="#f11eda">
                  <b>(xiv) ফিয়োর্ড উপকূল বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর : </b>
                </font>{" "}
                উচ্চ অক্ষাংশে হিমবাহ উপত্যকার গভীরতা সমুদ্রতল অপেক্ষাবেশি হলে বা
                হিমবাহ উপত্যকা ভূ-আলোড়নের ফলে সমুদ্রে নিমজ্জিত হলে যে উপকূলের
                সৃষ্টি হয় তাকে ফিয়োর্ড উপকূল বলে। যেমন - নরওয়ে ও সুইডেন
                উপকূল।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#1f7405" size="5">
                <b> PART - A (Marks 35)</b>
              </font>
              <br />
              <br />

              <font color="#f11eda">
                <b>
                  1. নিম্মলিখিত প্রশ্নগুলির উত্তর দাও। (বিকল্প প্রশ্নগুলি
                  লক্ষণীয়) :{" "}
                  <font color="#1f7405">
                    <b>৭ × ৫ = ৩৫</b>
                  </font>
                </b>
              </font>
              <br />
              <br />
              <font color="#3c92e5">
                (a) কার্স্ট অঞ্চলে সৃষ্ট সিঙ্কহোল, পোলজি, এবং পাতন প্রস্তর
                কীভাবে গঠিত হয় তা চিত্রসহ আলোচনা করো। আরোহণ ও অবরোহণ প্রক্রিয়া
                বলতে কী বোঝায়? ৫ + ২ = ৭<br />
                <br />
                <font color="#1f7405" size="4">
                  <b>অথবা</b>
                </font>
                <br />
                বিভিন্ন প্রকার প্রবাল প্রাচীরের উৎপত্তি ও বৈশিষ্ট্য উপযুক্ত
                চিত্রসহ সংক্ষেপে লেখো। এন্টিসল ও মলিসল মৃত্তিকার একটি করে
                বৈশিষ্ট্য উল্লেখ করো। ৫ + ২ = ৭<br />
                <br />
                (b) উদাহরণসহ বিভিন্ন ধরনের মরু উদ্ভিদ সম্পর্কে আলোচনা করো।
                দুর্যোগ ও বিপর্যয়ের মধ্যে পার্থক্য নির্দেশ করো। ৪ + ৩ = ৭<br />
                <br />
                <font color="#1f7405" size="4">
                  <b>অথবা</b>
                </font>
                <br />
                ক্রান্তীয় ঘূর্ণবাত ও নাতিশীতোয় ঘূর্ণবাতের মধ্যে পার্থক্য
                নিরূপণ করো। সংক্ষেপে জীববৈচিত্রের গুরুত্ব লেখো। ৪ + ৩ = ৭<br />
                <br />
                (c) ব্যাপক কৃষি প্রধানত রপ্তানিভিত্তিক হওয়ার কারণ কী?
                শস্যাবর্তনের দুটি গুরুত্বপূর্ণ বৈশিষ্ট্য উল্লেখ করো। ভারতে ডাল
                চাষের সমস্যা গুলি কী কী? ৩ + ২ + ২ = ৭<br />
                <br />
                (d) ভারতের ছোটোনাগপুর মালভূমি অঞ্চলে লৌহ ইস্পাত শিল্পের
                কেন্দ্রীভবনের কারণসমূহ উল্লেখ করো। ভারতে গবাদি পশুর সংখ্যা
                সর্বাধিক হলেও এই দেশে দুগ্ধ প্রক্রিয়াকরণ শিল্প উন্নতি লাভ করেনি
                কেন? ৫ + ২ = ৭<br />
                <br />
                (e) কাম্য জনসংখ্যার সংজ্ঞা দাও।এবং এর দুটি বৈশিষ্ট্য উল্লেখ করো।
                কী ধরনের ভৌগোলিক পরিবেশে বিক্ষিপ্ত জনবসতি গড়ে ওঠে ? কলকাতার
                সহযোগী বন্দর হিসেবে হলদিয়া বন্দরের গুরুত্ব উল্লেখ করো। ৩ + ২ +
                ২ = ৭<br />
                <br />
                <font color="#1f7405" size="4">
                  <b>অথবা</b>
                </font>
                <br />
                ভারতের আদমশুমারী অনুযায়ী পৌরবসতির সংজ্ঞা দাও। পরিকল্পনা অঞ্চলের
                দুটি বৈশিষ্ট্য উল্লেখ করো। বৃহৎ পরিকল্পনা অঞ্চল বলতে কী বোঝায়
                উদাহরণ সহ লোখো। ৩ + ২ + ২ = ৭
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

PreviousyearQuestionpaper2017.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(PreviousyearQuestionpaper2017);
