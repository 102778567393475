import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";

export function সরকারেরবিভিন্নবিভাগ() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>
          সরকারের বিভিন্ন বিভাগ | West Bengal Class 12 Guide | West Bengal HS
          Suggestion
        </title>
        <meta
          name="description"
          content="সরকারের বিভিন্ন বিভাগ | West Bengal Class 12 Guide | West Bengal HS Suggestion"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                সরকারের বিভিন্ন বিভাগ
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>ভারতের অঙ্গরাজ্য আইনসভার উচ্চকক্ষের নাম —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সিনেট
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বিধান পরিষদ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  কমন্স সভা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিধান সভা
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>পূর্ণক্ষমতা স্বতন্ত্রীকরণ ব্যবস্থা —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সম্ভব
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সমস্যাজনক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  সম্ভব নয়
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  এর কোনোটিই নয়
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ‘বিচারপতিরা যদি দুর্নীতিপরায়ণ এবং বিকৃত মনোবৃত্তিসম্পন্ন হন,
                  তবে ন্যায়বিচার পদদলিত হতে বাধ্য’—উক্তিটি হল —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নেহরুর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  গোখেল-এর
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  গান্ধিজির
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ইন্দিরা গান্ধির
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  ব্যক্তিস্বাধীনতা ও মৌলিক অধিকার রক্ষার দায়িত্ব পালন করে -
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  বিচার বিভাগ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সেনাবাহিনী বিভাগ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  আইন বিভাগ
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  শাসন বিভাগ
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  “দ্বিতীয় পরিষদ হল স্বাধীনতার অপরিহার্য নিরাপত্তা”, একথা
                  বলেছেন —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ড ব্রাইস
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  লর্ড অ্যাক্টন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  সিসিলি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পোলক
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>
                  মার্কিন যুক্তরাষ্ট্রের সিনেটে প্রতিটি অঙ্গরাজ্য থেকে প্রতিনিধি
                  নির্বাচিত হন —
                </b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  চার জন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  দশ জন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পাঁচ জন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  দুই জন
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>“পার্লামেন্ট একটি ক্রীড়নকমাত্র” বলেছেন —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  মুসোলিনি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  স্তালিন
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  হিটলার
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  লর্ড ব্রাইস
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>নামসর্বস্ব শাসকের উদাহরণ হল -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ভারতের রাষ্ট্রপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভারতের প্রধানমন্ত্রী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্রিটেনের প্রধানমন্ত্রী
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বাংলাদেশের প্রধানমন্ত্রী
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>প্রকৃত শাসকের উদাহরণ হল —</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ব্রিটেনের রাজা বা রানি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  পাকিস্তানের রাষ্ট্রপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  ভারতের উপরাষ্ট্রপতি
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  ভারতের প্রধানমন্ত্রী
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>সরকারের স্থায়ী কর্মচারীদের বলা হয় -</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  বিচারক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  আমলা
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নামেমাত্র শাসক
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  নিম্নপদস্থ কর্মচারী
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <font color="#1f7405">
                <b>ষষ্ঠ অধ্যায় - সরকারের বিভিন্ন বিভাগ </b>
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. একটি প্রকৃত শাসকের উদাহরণ দাও।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                ব্রিটেনের প্রধানমন্ত্রী হলেন একজন প্রকৃত শাসকের উদাহরণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. ভারতের রাষ্ট্রপতিকে কোন্ বিভাগ অপসারণ করে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আইন বিভাগ ইমপিচমেন্ট পদ্ধতির মাধ্যমে ভারতের রাষ্ট্রপতিকে অপসারণ
                করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. সরকারের কোন্ বিভাগ অর্ডিন্যান্স জারি করে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সরকারের শাসন বিভাগ অর্ডিন্যান্স জারি করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. লাল ফিতার বাঁধন' কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আমলাতন্ত্রে প্রশাসন তথা সরকারি কাজে দীর্ঘসূত্রিতাকে বলা হয় 'লাল
                ফিতার বাঁধন'।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. 'আমলাতন্ত্র' শব্দটির শব্দগত অর্থ কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ‘আমলাতন্ত্র’ শব্দটির শব্দগত অর্থ হল – টেবিল শাসনব্যবস্থা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>6. আমলাদের কী কী নামে অভিহিত করা হয়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আমলাদের রাষ্ট্রকৃত্যক, রাষ্ট্রভৃত্যক প্রভৃতি নামে অভিহিত করা
                হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>7. আমলাদের একটি কাজ উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আমলাদের একটি অন্যতম কাজ হল দেশের শাসনব্যবস্থায় নিরবচ্ছিন্ন ও
                নিরপেক্ষভাবে কার্যবিধি সম্পাদন করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    8. ভারতে দ্বিকক্ষবিশিষ্ট আইনসভা আছে এমন কয়েকটি রাজ্যের নাম
                    করো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারতে দ্বিকক্ষবিশিষ্ট আইনসভা আছে এমন কয়েকটি রাজ্য হল —
                উত্তরপ্রদেশ, বিহার, কর্ণাটক, মহারাষ্ট্র ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>9. গঠনগতভাবে আইনসভা কয় প্রকার ও কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                গঠনগতভাবে আইনসভা দুই প্রকার, যথা — (a) এককক্ষ বিশিষ্ট আইনসভা,
                (b) দ্বিকক্ষবিশিষ্ট আইনসভা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>10. এককক্ষবিশিষ্ট আইনসভা কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যে সকল আইনসভার একটিমাত্র কক্ষ থাকে, তাকে এককক্ষবিশিষ্ট আইনসভা
                বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>11. দ্বিকক্ষবিশিষ্ট আইনসভা কাকে বলে?</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                যে সকল আইনসভার দুটি কক্ষ রয়েছে তাকে দ্বিকক্ষবিশিষ্ট আইনসভা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>12. আইনসভার দুটি কাজ লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আইনসভার অন্যতম দুটি কাজ হল –(a) আইন প্রণয়ন করা ও (b) শাসন
                বিভাগকে নিয়ন্ত্রণ করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>13. বিচারবিভাগের একটি কাজ উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                দেওয়ানি ও ফৌজদারি মামলার বিচারকার্যনিষ্পত্তি করা বিচার বিভাগের
                অন্যতম কাজ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>14. ক্ষমতা স্বতন্ত্রীকরণ নীতির মূলকথা কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সরকারের তিনটি বিভাগ আইন বিভাগ, শাসন বিভাগ ও বিচার বিভাগ সম্পূর্ণ
                পৃথক ও স্বাতন্ত্র্যভাবে কাজ করবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>15. নামসর্বস্ব বা নামেমাত্র শাসক বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যে শাসক তত্ত্বগতভাবে শাসন বিভাগের প্রধান হলেও বাস্তবে তিনি
                শাসনকার্য পরিচালনা করেন না তাকে নামসর্বস্ব শাসক বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>16. প্রকৃত শাসক বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যে শাসক আনুষ্ঠানিক বা সাংবিধানিক রাষ্ট্রপ্রধান না হয়েও বাস্তবে
                বা প্রকৃতপক্ষে শাসনকার্য ভোগ করে তাকে প্রকৃত শাসক বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>17. নামসর্বস্ব শাসকের দুটি শ্রেষ্ঠ উদাহরণ দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                নামসর্বস্ব শাসকের দুটি অন্যতম শ্রেষ্ঠ উদাহরণ হল ভারতের
                রাষ্ট্রপতি এবং গ্রেট ব্রিটেনের রাজা বা রানি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    18. সংসদীয় শাসনব্যবস্থায় কত ধরনের শাসক দেখা যায় ও কী কী?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সংসদীয় শাসনব্যবস্থায় দুই ধরনের শাসক দেখা যায়। যথা — (a)
                প্রকৃত শাসক এবং (b) নামসর্বস্ব শাসক।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    19. গঠন ও কার্যাবলির ভিত্তিতে শাসন বিভাগকে ক-টি অংশে ভাগ করা
                    যায়?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                গঠন ও কার্যাবলির ভিত্তিতে শাসন বিভাগকে দুটি অংশে ভাগ করা যায়।
                যথা — (a) রাজনৈতিক অংশ ও (b) অরাজনৈতিক অংশ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>20. আমলাতন্ত্রের দুটি প্রধান বৈশিষ্ট্য উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                গণতন্ত্রে আমলাতন্ত্রের অন্যতম দুটি প্রধান বৈশিষ্ট্য হল – (a)
                প্রশাসনিক কার্যে নিরবচ্ছিন্নতা রক্ষা করা ও (b) নিরপেক্ষভাবে
                প্রশাসনিক কার্যাদি সম্পাদন করা।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>21. আমলাতন্ত্রের একটি ত্রুটি উল্লেখ করো।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                উদারনৈতিক গণতন্ত্রে আমলাতন্ত্রের একটি মারাত্মক ত্রুটি হল
                দীর্ঘসূত্রিতা বা ‘লাল ফিতার বাঁধন।'
                <br />
                <br />
                <font color="#f626d7">
                  <b>22. সরকারের ক-টি বিভাগ ও কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সরকারের তিনটি বিভাগ, যথা — আইন বিভাগ, শাসন বিভাগ ও বিচার বিভাগ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>23. আইনসভার কিছু শাসন সংক্রান্ত কাজ লেখো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আইনসভার কিছু শাসন সংক্রান্ত কাজ হল—শাসন বিভাগকে নিয়ন্ত্রণ, শাসন
                বিভাগের কাজের তদারকি করা, সন্ধি বা চুক্তি অনুমোদন করা ইত্যাদি।
                <br />
                <br />
                <font color="#f626d7">
                  <b>24. আইনসভার বিচারসংক্রান্ত কাজগুলি কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আইনসভার বিচার সংক্রান্ত কাজগুলি হল—বিচারকদের সংখ্যা নির্ধারণ,
                বিচারকদের নিয়োগ করা ও পদচ্যুত করা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    25. ক্ষমতা স্বতন্ত্রীকরণ নীতির কয়েকজন প্রবক্তার নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                উত্তর ক্ষমতা স্বতন্ত্রীকরণ নীতির কয়েকজন প্রবক্তা হলেন সিসেরো,
                মন্তেস্কু, বোদাঁ, ব্ল্যাকস্টোন প্রমুখগণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    26. বর্তমানে ক্ষমতা স্বতন্ত্রীকরণ নীতির প্রয়োগ দেখা যায়
                    এমন কয়েকটি দেশের নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বর্তমানে মার্কিন যুক্তরাষ্ট্র, ফ্রান্স, ব্রাজিল, আর্জেন্টিনা
                ইত্যাদি দেশে ক্ষমতা স্বতন্ত্রীকরণ নীতির প্রয়োগ দেখা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    27. ক্ষমতা স্বতন্ত্রীকরণ নীতির কয়েকজন সমালোচকের নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ক্ষমতা স্বতন্ত্রীকরণ নীতির অন্যতম কয়েকজন সমালোচকের নাম
                হল—স্যাবাইন, গ্রিলক্রিস্ট, ল্যাস্কি প্রমুখ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    28. যুক্তরাষ্ট্রীয় ব্যবস্থায় দ্বিকক্ষবিশিষ্ট আইনসভা
                    অপরিহার্য কেন?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যুক্তরাষ্ট্রে আইনসভার দুটি কক্ষের মাধ্যমে জাতীয় স্বার্থ ও
                আঞ্চলিক স্বার্থের মধ্যে সামঞ্জস্য বিধানের জন্য দ্বিকক্ষবিশিষ্ট
                আইনসভা অপরিহার্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    29. বিচারবিভাগীয় সমীক্ষা করার ক্ষমতা রয়েছে এমন দুটি
                    রাষ্ট্রের নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                মার্কিন যুক্তরাষ্ট্র ও ভারতে বিচার বিভাগের বিচার বিভাগীয়
                সমীক্ষা করার ক্ষমতা রয়েছে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    30. বিচারপতিরা আইনসভা দ্বারা নিযুক্ত হয় এমন কয়েকটি দেশের
                    নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                সুইটজারল্যান্ড, আলবেনিয়া, বুলগেরিয়া, বলিভিয়া প্রভৃতি দেশের
                বিচারপতিরা আইনসভা দ্বারা নিযুক্ত হন।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>31. বিচারপতিরা জনগণ দ্বারা নিযুক্ত হয় তার উদাহরণ দাও।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                সুইটজারল্যান্ডের ক্যান্টনগুলিতে বিচারপতিরা জনগণ দ্বারা নিযুক্ত
                হন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    32. শাসন বিভাগের দ্বারা বিচারপতি নিযুক্ত হন তার উদাহরণ দাও।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ভারত ও মার্কিন যুক্তরাষ্ট্রে শাসন বিভাগ কর্তৃক বিচারপতিরা
                নিযুক্ত হন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    33. ক্ষমতা স্বতন্ত্রীকরণ নীতির বিপক্ষে একটি যুক্তি লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                এইরূপ নীতির বাস্তব প্রয়োগ যেমন দুরূহ, তেমনই এর বাস্তব প্রয়োগও
                কাম্য নয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    34. একক্ষবিশিষ্ট আইনসভা রয়েছে এমন কয়েকটি দেশের নাম লেখো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                এককক্ষবিশিষ্ট আইনসভা রয়েছে তুরস্ক, বুলগেরিয়া, রুমানিয়া,
                পানামা, চিন প্রভৃতি রাষ্ট্রে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    35. দ্বিকক্ষবিশিষ্ট আইনসভা রয়েছে এমন কয়েকটি দেশের নাম করো।
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                দ্বিকক্ষবিশিষ্ট আইনসভা রয়েছে মার্কিন যুক্তরাষ্ট্র, ব্রিটেন,
                ভারত প্রভৃতি রাষ্ট্রে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>36. শাসন বিভাগের দুটি কাজ উল্লেখ করো।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                শাসন বিভাগের অন্যতম দুটি কাজ হল –(a) সরকারি নীতি নির্ধারণ ও তা
                বাস্তবে রূপায়ণ করা। (b) দেশের শান্তিশৃঙ্খলা বজায় রাখা।
                <br />
                <br />
                <font color="#f626d7">
                  <b>37. ক্ষমতা স্বতন্ত্রীকরণ নীতির অর্থ বা শর্তগুলি কী কী?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ক্ষমতা স্বতন্ত্রীকরণ নীতির শর্তগুলি হল – (a) সরকারের এক বিভাগ
                অপর বিভাগের কাজ করবে না। (b) এক বিভাগ অন্য বিভাগকে নিয়ন্ত্রণ
                করবে না। (c) একজন ব্যক্তি একাধিক বিভাগের সঙ্গে যুক্ত থাকবে না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>38. ক্ষমতা স্বতন্ত্রীকরণ নীতির পক্ষে দুটি যুক্তি দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ক্ষমতা স্বতন্ত্রীকরণ নীতির পক্ষে দুটি যুক্তি হল—(a) সরকারের
                স্বৈরাচারী প্রবণতা রোধ করা সম্ভব হয়। (b) সরকারের প্রত্যেকটি
                বিভাগের কর্মকুশলতা বৃদ্ধি পায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>39. শাসন বিভাগ বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                রাষ্ট্রের প্রধান পরিচালক ও মন্ত্রীগণ থেকে শুরু করে প্রশাসনের
                সঙ্গে যুক্ত সকল উচ্চপদস্থ ও নিম্নপদস্থ কর্মচারীগণ শাসন বিভাগের
                অন্তর্গত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>40. দ্বিকক্ষবিশিষ্ট আইনসভার বিপক্ষে একটি যুক্তি দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                দ্বিকক্ষবিশিষ্ট আইনসভার উচ্চকক্ষের মতো অপ্রয়োজনীয় কক্ষের
                সদস্যদের বেতন, ভাতা ও কক্ষ পরিচালনার ক্ষেত্রে বিপুল পরিমাণ
                অর্থের অপচয় ঘটে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>41. দ্বিকক্ষবিশিষ্ট আইনসভার কয়েকজন সমর্থকের নাম লেখো।</b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                দ্বিকক্ষবিশিষ্ট আইনসভার কয়েকজন সমর্থক হলেন — জন স্টুয়ার্ট মিল,
                লর্ড ব্রাইস, গেটেল, দ্যুগুই প্রমুখগণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    42. দ্বিকক্ষবিশিষ্ট আইনসভার কয়েকজন সমালোচকের নাম লেখো।{" "}
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                ল্যাস্কি, বেন্থাম, ফ্রাংকলিন, আবেসিঁয়ে প্রমুখ রাষ্ট্রবিজ্ঞানীগণ
                দ্বিকক্ষবিশিষ্ট আইনসভার বিপক্ষে যুক্তি দিয়েছেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>43. এককক্ষবিশিষ্ট আইনসভার পক্ষে দুটি যুক্তি দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                এককক্ষবিশিষ্ট আইনসভার পক্ষে দুটি যুক্তি হল– (a) সরকারের ব্যয় ও
                অর্থ অপচয় অনেক কম হয়। (b) দ্রুত সরকারি সিদ্ধান্ত গ্রহণে খুবই
                উপযোগী।
                <br />
                <br />
                <font color="#f626d7">
                  <b>44. এককক্ষবিশিষ্ট আইনসভার বিপক্ষে একটি যুক্তি দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যুক্তরাষ্ট্রীয় ব্যবস্থার পক্ষে এককক্ষবিশিষ্ট আইনসভা কার্যকর
                নয়, এই রূপ আইনসভায় স্বৈরাচারী আইন প্রণয়নের প্রবল সম্ভাবনা
                থাকে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>45. দ্বিকক্ষবিশিষ্ট আইনসভার পক্ষে দুটি যুক্তি দাও।</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                দ্বিকক্ষবিশিষ্ট আইনসভার পক্ষে দুটি যুক্তি হল – (a) সুচিন্তিত ও
                সুপরিকল্পিতভাবে আইন প্রণয়ন সম্ভব। (b) সরকারের স্বৈরাচারী
                প্রবণতা রোধ করা সম্ভব।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  46. এককক্ষবিশিষ্ট আইনসভার বিরোধী কয়েকজন রাষ্ট্রবিজ্ঞানীর নাম
                  লেখো।
                </b>
              </font>
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                এককক্ষবিশিষ্ট আইনসভার বিরোধী রাষ্ট্রবিজ্ঞানীদের মধ্যে
                উল্লেখযোগ্য হলেন — হেনরি মেইন, লেকি, লর্ড ব্রাইস, লর্ড অ্যাক্টন
                প্রমুখগণ।
                <br />
                <br />
                <font color="#f626d7">
                  <b>47. ‘অর্পিত ক্ষমতাপ্রসূত আইন’ কাকে বলে? </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বর্তমানে আইনসভার কাজকর্ম ব্যাপকমাত্রায় বৃদ্ধি পেয়েছে। তাই
                আইনসভার কিছু কাজকর্ম শাসনবিভাগকে দেওয়া হয়। এই ক্ষমতাবলে শাসন
                বিভাগ কর্তৃক প্রণীত আইনকে অর্পিত ক্ষমতাপ্রসূত আইন বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>48. ‘আধা বিচারবিভাগীয়’ কাজ কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আধুনিক রাষ্ট্রব্যবস্থায় শাসনবিভাগের কিছু বিচার সংক্রান্ত কাজও
                রয়েছে। যেমন — বিচারপতিদের নিয়োগ, ক্ষমা প্রদর্শন,
                দন্ডাজ্ঞাপ্রাপ্ত ব্যক্তির শাস্তি হ্রাস ইত্যাদি। এগুলিকে শাসন
                বিভাগের আধা বিচারবিভাগীয় কাজ বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>49. রাষ্ট্রকৃত্যক বা আমলা বলতে কী বোঝায়?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আমলা বা রাষ্ট্রকৃত্যক হল শাসন বিভাগের অরাজনৈতিক অংশ বা উচ্চপদস্থ
                কর্মচারী যারা প্রতিযোগিতামূলক পরীক্ষার ভিত্তিতে স্থায়ীভাবে
                সরকারের কাজে নিযুক্ত হয় এবং সরকারের যাবতীয় নীতিগুলোকে
                বাস্তবায়িত করে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>50. বিচার বিভাগীয় সমীক্ষা বলতে কী বোঝো?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                আইনসভা দ্বারা প্রণীত আইন বা শাসন বিভাগের কোনো নীতি বা নির্দেশ
                সংবিধান বিরোধী হলে তাকে অবৈধ বা বাতিল ঘোষণা করায় যে ক্ষমতা
                বিচারবিভাগ ভোগ করে তাকে বিচারবিভাগীয় সমীক্ষা বা পর্যালোচনা বলে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    51. বিচার বিভাগের স্বাধীনতা ও নিরপেক্ষতা কোন্ কোন্ বিষয়ের
                    উপর নির্ভরশীল?
                  </b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                বিচারকের যোগ্যতা, নিয়োগ পদ্ধতি, কার্যকাল, বিচারকদের পদচ্যুতি,
                উপযুক্ত বেতন ও ভাতা ইত্যাদির উপর বিচার বিভাগের স্বাধীনতা ও
                নিরপেক্ষতা নির্ভরশীল।
                <br />
                <br />
                <font color="#f626d7">
                  <b>52. যুক্তরাষ্ট্রীয় সরকার কাকে বলে?</b>
                </font>
                <br />
                <font color="#1f7405">
                  <b>উত্তর ➺ </b>{" "}
                </font>
                যে শাসনব্যবস্থায় একটি লিখিত সংবিধানের মাধ্যমে একটি কেন্দ্রীয়
                সরকার ও একাধিক অঙ্গরাজ্য সরকারগুলির মধ্যে ক্ষমতা বণ্টিত হয় এবং
                একটি স্বাধীন ও নিরপেক্ষ শক্তিশালী বিচার বিভাগের অস্তিত্ব থাকে,
                তাকে যুক্তরাষ্ট্রীয় সরকার বলে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>1. এককক্ষবিশিষ্ট আইনসভার পক্ষে ও বিপক্ষে যুক্তি দাও।</b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b>উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                একটি আইনসভা যেটি কেবল একটি কক্ষ বা পরিষদ নিয়ে গঠিত হয় তাকে
                এককক্ষবিশিষ্ট আইনসভা বলা হয়। এই আইনসভার কাজকর্ম জনগণের
                নির্বাচিত প্রতিনিধিগণ একটি কক্ষের মাধ্যমে সম্পাদন করা হয়।
                আধুনিক সময়ে, এই প্রকার আইনসভা অধিক জনপ্রিয়তা অর্জন করেছে, এবং
                বিশ্বে প্রায় ১১২টি দেশে এই প্রণালী ব্যবহার করা হয়। চিন,
                বাংলাদেশ, বুলগেরিয়া, রোমানিয়া, তুরস্ক, পানামা ইত্যাদি দেশগুলির
                মধ্যে এই প্রণালী অন্তর্ভুক্ত। তবে, কোন দেশে একটি কক্ষ না দুটি
                কক্ষ রাখা উচিত সে সম্পর্কে রাষ্ট্রবিজ্ঞানীদের মধ্যে মতভেদ থাকে।
                <br />
                <br />
                <font color="#1f7405">
                  <b> 1. এককক্ষবিশিষ্ট আইনসভার স্বপক্ষে যুক্তি : ➺ </b>{" "}
                </font>{" "}
                বেন্থাম, আবেসিঁয়ে, ল্যাস্কি, ফ্রাংকলিন প্রমুখ রাষ্ট্রবিজ্ঞানীগণ
                দেশের আইনসভার একটিমাত্র কক্ষ থাকা উচিত বলে মনে করেন।
                এককক্ষবিশিষ্ট আইনসভার সমর্থনে যুক্তিগুলি নিম্নরূপ —<br />
                <br />
                <font color="#f626d7">
                  <b>(i) সুষ্ঠু, সুনিশ্চিত ও সহজ পদ্ধতিতে আইন প্রণয়ন ➺ </b>{" "}
                </font>{" "}
                এককক্ষবিশিষ্ট আইনসভায় আইন প্রণয়ন সহজ, নির্ভুল এবং সতর্কভাবে
                সম্পাদন করার জন্য বেশি সুযোগ থাকে। দুটি কক্ষ থাকলে অতিরিক্ত
                প্রক্রিয়া ও অশ্রেয় হতে পারে, যা সময়ের অপচয় তৈরি করতে পারে।
                এককক্ষবিশিষ্ট আইনসভা আইন প্রণয়নে বেশি নির্ভুলতা এবং কার্যকরীতা
                সহজতর করে তুলে আনতে সাহায্য করে। <br />
                <br />
                <font color="#f626d7">
                  <b>(ii) একক দায়িত্ব ➺ </b>{" "}
                </font>{" "}
                আইনসভার এককক্ষবিশিষ্ট অনুসন্ধানে, সদস্যগণ নিজেদের কার্য সম্পাদনে
                সর্বাধিক দায়িত্বশীল হতে পারেন। দ্বিতীয় কক্ষ থাকলে, কার্য
                সম্পাদন একটি কক্ষ থেকে অপর কক্ষের উপর দায়বদ্ধতা ব্যতীত করে
                আয়ত্ত দিতে পারে। এই কারণেই, এককক্ষবিশিষ্ট আইনসভায় আইন প্রণয়ন
                এবং কার্য সম্পাদনে দায়িত্ব ভাগ করা হয় না, এবং সদস্যদের কাজের
                সঠিক মূল্যায়ন সহজ হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(iii) ব্যয় অপচয় কম ➺ </b>{" "}
                </font>{" "}
                এককক্ষবিশিষ্ট আইনসভা পরিচালনার ক্ষেত্রে ব্যয় অপচয় কম হয়।
                দ্বিকক্ষবিশিষ্ট আইনসভার দুটি কক্ষের সদস্যদের বেতন, ভাতা ও
                অন্যান্য সুবিধা প্রদানে প্রচুর অর্থ দেশের কোশাগার থেকে ব্যয়
                হয়। তবে এককক্ষবিশিষ্ট আইনসভার ক্ষেত্রে অপর কক্ষ পরিচালনার
                অবাঞ্ছিত খরচ বাঁচিয়ে তা দেশের উন্নয়নে ব্যবহার করা যায়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(iv) আপৎকালীন অবস্থার উপযোগী ➺ </b>{" "}
                </font>{" "}
                দেশের জরুরি অবস্থাকালীন সময়পোযোগী দ্রুত সিদ্ধান্ত গ্রহণে
                এককক্ষবিশিষ্ট আইনসভাই শ্রেয়। আইনসভার দুটি কক্ষে দ্রুত সিদ্ধান্ত
                গ্রহণের প্রক্রিয়ার ক্ষেত্রে অযথা বিলম্ব ঘটে। জনস্বার্থ বা
                জাতীয় স্বার্থে এককক্ষবিশিষ্ট আইনসভাই দ্রুত উপযোগী সিদ্ধান্ত
                গ্রহণ করতে পারে।
                <br />
                <br />
                <font color="#1f7405">
                  <b> 2. এককক্ষবিশিষ্ট আইনসভার বিপক্ষে যুক্তি ➺ </b>{" "}
                </font>{" "}
                লর্ড ব্রাইস, জন স্টুয়ার্ট মিল, লেকি, গেটেল, হেনরি মেইন প্রমুখ
                রাষ্ট্রবিজ্ঞানীগণ এককক্ষবিশিষ্ট আইনসভার বিরুদ্ধে কঠোর সমালোচনা
                করেছেন। তাঁরা আইনসভার দুটি কক্ষ থাকা প্রয়োজন বলে মনে করেন।
                এককক্ষবিশিষ্ট আইনসভার বিপক্ষে যুক্তিগুলি নিম্নরূপ -<br />
                <br />
                <font color="#f626d7">
                  <b>(i) ব্যক্তি স্বাধীনতা বর্জিত স্বৈরাচারী আইন প্রণয়ন ➺ </b>{" "}
                </font>{" "}
                আইনসভার একটি কক্ষ থাকলে, আইন প্রণয়নের সম্পূর্ণ দায়িত্ব ঐ
                কক্ষের উপর বহন হয়। এতে আইনটি পুনরায় বিচার করার সুযোগ নেই এবং
                ক্ষমতাসীন দল জাতীয় স্বার্থ পরিবর্জন না করে দলীয় স্বার্থে আইন
                প্রণয়ন করে, তাদের স্বদেশী আচরণের রূপ প্রকাশ পাতে সহায়ক হতে
                পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(ii) যুক্তরাষ্ট্রীয় শাসনব্যবস্থার অনুপযোগী ➺ </b>{" "}
                </font>{" "}
                যুক্তরাষ্ট্রীয় ব্যবস্থায়, জাতীয় স্বার্থ ও আঞ্চলিক স্বার্থের
                সমন্বয় প্রয়োজন তাতে আঞ্চলিক বিরোধের সম্ভাবনা কমাতে সাহায্য
                করতে হয়। আইনসভার দুটি কক্ষ থাকলে, জাতীয় স্বার্থ এবং আঞ্চলিক
                স্বার্থের সঠিক সমন্বয় তৈরি করতে সম্ভব হতে পারে। এটি অন্যথায়
                একটি কক্ষ দ্বারা অপর কক্ষের স্থানান্তর সহ সম্পূর্ণ কার্যক্রম
                প্রয়োজন হতে পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(iii) জ্ঞানী ব্যক্তিত্ব প্রতিনিধিদের অনুপস্থিতি ➺ </b>{" "}
                </font>{" "}
                ভারতের মতো বৃহৎ যুক্তরাষ্ট্রীয় শাসন কাঠামোয় দ্বিকক্ষবিশিষ্ট
                আইনসভার উচ্চকক্ষে জ্ঞানী ব্যক্তিরা মনোনীত হবার সুযোগ পায়।
                অনেকসময় এককক্ষবিশিষ্ট আইনসভায় জনগণের নির্বাচিত প্রতিনিধিগণ
                অশিক্ষিত ও অসৎ হতে পারে যা দেশের পক্ষে অশুভ। এই কারণে
                এককক্ষবিশিষ্ট আইনসভায় বুদ্ধিজীবিদের অভাব পরিলক্ষিত হয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>(iv) সংখ্যালঘুর স্বার্থ উপেক্ষা ➺ </b>{" "}
                </font>{" "}
                আইনসভার একটি কক্ষে প্রত্যক্ষ নির্বাচিত প্রতিনিধিগণ নিযুক্ত
                হওয়ার প্রাধান্যে সংখ্যালঘু শ্রেণির প্রতিনিধি নির্বাচনের
                সম্ভাবনা কম হতে পারে। অপর দিকে, দ্বিকক্ষবিশিষ্ট আইনসভায়, এরূপ
                ঘটনা প্রত্যক্ষ করা হয় না। এখানে বিভিন্ন শ্রেণির প্রতিনিধিত্ব
                দেখা যায়।
                <br />
                <br />
                পরিশেষে একথা বলা যায় বহুত্ববাদী গণতান্ত্রিক সংস্কৃতিতে
                এককক্ষবিশিষ্ট শাসন ব্যবস্থা একেবারেই উপযোগী নয়। তাই গণতান্ত্রিক
                দেশগুলিতে এইরূপ ব্যবস্থা বেশি জনপ্রিয় হয়নি। তবে এককেন্দ্রিক
                শাসনব্যবস্থায় আইনসভার একটি কক্ষ বেশ উপযোগী হয়ে উঠতে পারে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  2. ক্ষমতা স্বতন্ত্রীকরণ নীতি বলতে কী বোঝো? “কঠোর ক্ষমতা
                  স্বতন্ত্রীকরণ নীতি সম্ভব নয়, কাম্যও নয়”— মন্তব্যটির যথার্থতা
                  বিচার করো।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                আধুনিক রাষ্ট্রব্যবস্থা পরিচালনার ক্ষেত্রে ক্ষমতা স্বতন্ত্রীকরণ
                নীতির গুরুত্ব অপরিসীম। ক্ষমতা বণ্টন ও ক্ষমতার পূর্ণ
                স্বাতন্ত্র্যতাই এই নীতির মূল ভিত্তি। সরকারের তিনটি বিভাগ — আইন
                বিভাগ, শাসন বিভাগ ও বিচার বিভাগ। ক্ষমতা স্বতন্ত্রীকরণ নীতির মূল
                প্রতিপাদ্য বিষয় হল - <br />
                <br />
                (i) সরকারের প্রত্যেকটি বিভাগ সম্পূর্ণ স্বতন্ত্র ও স্বাধীনভাবে
                কাজ করবে। <br />
                (ii) একটি বিভাগ অপর বিভাগকে সম্রাট নিয়ন্ত্রণ করবে না, ও অপর
                বিভাগের কাজে হস্তক্ষেপ করবে না।
                <br />
                (iii) একই ব্যক্তি একাধিক বিভাগের সঙ্গে কোনো প্রকার যুক্ত থাকবে
                না। ফরাসি দার্শনিক বোঁদা, রোমান দার্শনিক পলিবিয়াস ও সিসেরো
                প্রমুখের চিন্তাধারায় ক্ষমতা স্বতন্ত্রীকরণ নীতির প্রকাশ ঘটলেও,
                এই নীতির চরম প্রকাশ ঘটে ফরাসি দার্শনিক মন্তেস্কু এবং ইংরেজ
                দার্শনিক ব্ল্যাকস্টোন-এর হাতে। ফরাসি দার্শনিক মন্তেস্কু তাঁর
                “The Spirit of The Laws (1748 খ্রিস্টাব্দ) গ্রন্থে ক্ষমতা
                স্বতন্ত্রীকরণ নীতির ধারণা প্রকাশ করেন। মস্তেস্কু ছিলেন ফরাসি
                সম্রাট চতুর্দশ ও পঞ্চদশ লুই-এর সমসাময়িক। ফ্রান্সে তৎকালীন
                স্বৈরাচারী শাসনব্যবস্থায় সরকারের সমস্ত বিভাগের ক্ষমতা রাজার
                হাতেই কেন্দ্রীভূত ছিল। ক্ষমতার কেন্দ্রীভবনের জন্যই ব্যক্তি
                স্বাধীনতার অস্তিত্ব ছিল না। তৎকালীন ইংল্যান্ডের শাসনব্যবস্থাকে
                প্রত্যক্ষ করে মন্তেস্কু ক্ষমতা স্বতন্ত্রীকরণ নীতির আভাস পান।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b> পূর্ণ ক্ষমতা স্বতন্ত্রীকরণ সম্ভব নয় ➺ </b>{" "}
                </font>{" "}
                সরকারের বিভিন্ন বিভাগগুলোর কাজকর্মের সুষ্ঠু সমন্বয়ের মধ্য
                দিয়েই রাষ্ট্রব্যবস্থা সম্রাট কার্যকর হয়। তাই আধুনিক
                রাষ্ট্রব্যবস্থায় পূর্ণ ক্ষমতা স্বতন্ত্রীকরণ কতটা সম্ভবপর তা
                আলোচিত হল -<br />
                <br />
                <font color="#f626d7">
                  <b>1. সরকারের তিনটি বিভাগের পারস্পরিক সহযোগিতা ➺ </b>{" "}
                </font>{" "}
                আধুনিক রাষ্ট্রব্যবস্থায় সুষ্ঠ কর্মসম্পাদন ও আন্তর্জাতিক
                ব্যবস্থা মোকাবিলার স্বার্থে সরকারের প্রত্যেকটি বিভাগ পৃথকভাবে
                কার্য সম্পাদন করতে পারেনা। একজন পদাধিকারী ব্যক্তি একাধিক বিভাগের
                সঙ্গে যুক্ত থাকেন। যেমন ব্রিটেন ও ভারতীয় সংসদীয় শাসনব্যবস্থায়
                প্রধানমন্ত্রীসহ অন্যান্য মন্ত্রীগণ যেমন শাসনবিভাগের অন্তর্ভুক্ত,
                তেমনই আইন প্রণয়নে আইনসভার সদস্য হিসেবে কাজ করেন। আবার ভারতের
                রাষ্ট্রপতি ও ব্রিটেনের রাজা-রানি শাসন বিভাগের প্রধান হওয়া
                সত্ত্বেও আইন বিভাগের অবিচ্ছেদ্য অঙ্গ হিসেবে কার্য সম্পাদন করেন।
                <br />
                <br />
                <font color="#f626d7">
                  <b>
                    2. "মার্কিন যুক্তরাষ্ট্রে ক্ষমতা স্বাধীনতাবাদ নীতির
                    ব্যবহারের অসংগতি ➺{" "}
                  </b>{" "}
                </font>{" "}
                বলা হয়ে থাকে মার্কিন যুক্তরাষ্ট্রে ক্ষমতা স্বাধীনতাবাদ নীতির
                বাস্তব ব্যবহার ঘটেছে। তবে এই মতটি সম্পূর্ণ যুক্তিগ্রাহ্য নয়।
                কারণ মার্কিন শাসন বিভাগের নেতৃবৃন্দ মার্কিন রাষ্ট্রপতি
                বিচারপতিদের নিয়োগ করেন। আবার, মার্কিন আইনসভার উচ্চকক্ষ সিনেটের
                অনুমোদন ব্যতিত সরকারি কর্মচারী নিয়োগ সংক্রান্ত রাষ্ট্রপতির
                নির্দেশনা বাস্তবে হতে পারে না। কারণীয়, সংসদীয় এবং রাষ্ট্রপতি
                উভয় প্রকারের শাসনব্যবস্থায় দলব্যবস্থার প্রাধান্যের কারণে
                আইনবিভাগ ও শাসনবিভাগের মধ্যে ঘনিষ্ঠতা ক্রমশ বৃদ্ধি পেয়েছে। তাই,
                ক্ষমতা স্বাধীনতাবাদ নীতির পূর্ণ ব্যবহার বাস্তবে সম্ভব নয়।"
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. নীতিটির তত্ত্বগত আলোচনা ও বাস্তবতা এক নয় ➺ </b>{" "}
                </font>{" "}
                "কোন বিষয়ের তাত্ত্বিক উপস্থাপনা এবং তার বাস্তবিক প্রয়োগ এক
                নয়। ক্ষমতা স্বাধীনতাবাদ নীতির ক্ষেত্রেও এই মন্তব্য প্রয়োজনীয়।
                সরকারের তিনটি প্রধান বিভাগই সরকারের মূল চালনা শক্তিগুলিকে
                সম্পূর্ণ পৃথিবী পৃথক রেখে সরকারি কার্যক্রমগুলি সুস্থ ভাবে
                সম্পাদন করতে পারে না। যেমন — বর্তমানে আইন বিভাগের কাজকর্ম উন্নতি
                পেয়েছে। ফলটি শাসনবিভাগকে দেখা যায় আইনের স্থানাংক প্রস্তুতকারী
                সংস্থা হিসেবে। আবার আইনবিভাগের সামর্থ্যবধানে সময়ের দিকে
                শাসনবিভাগের প্রধান হিসেবে ভারতের রাষ্ট্রপতি আদেশ বা অর্ডিন্যান্স
                জারি করে থাকে। অর্থাৎ, আধুনিক রাষ্ট্রব্যবস্থায় এক বিভাগের
                ব্যক্তি অন্য বিভাগের কার্যক্রম পরিচালনা করে। অর্থাৎ, ক্ষমতা
                স্বাধীনতাবাদ নীতির পূর্ণ ব্যবহার অসম্ভাব্য।"
                <br />
                <br />
                <font color="#f626d7">
                  <b> ⦿ </b>
                </font>{" "}
                <font color="#1f7405">
                  <b> পূর্ণ ক্ষমতা স্বতন্ত্রীকরণ সমীচীনও নয় ➺ </b>{" "}
                </font>{" "}
                পূর্ণ ক্ষমতা স্বতন্ত্রীকরণ যেমন সম্ভব নয়, তেমনই এর বলপূর্বক
                বাস্তব প্রয়োগ উচিতও নয়।
                <br />
                <br />
                <font color="#f626d7">
                  <b>1. প্রশাসনিক অচলাবস্থা ➺ </b>{" "}
                </font>{" "}
                দেশের দক্ষ প্রশাসন পরিচালনায় সরকারের তিনটি বিভাগের সুষ্ঠু কার্য
                সম্পাদন অত্যন্ত আবশ্যিক। জন স্টুয়ার্ট মিল, ল্যাস্কি, ফাইনার
                প্রমুখ রাষ্ট্র দার্শনিকদের মতে ক্ষমতা স্বতন্ত্রীকরণ নীতির পূর্ণ
                প্রয়োগ আদৌও কাম্য ও উপযুক্ত নয়। মার্কিন যুক্তরাষ্ট্রের
                ক্ষেত্রেও তা সুনির্দিষ্টভাবে প্রতিপালন করা হয়নি। যদি তা করা হত,
                তবে প্রশাসনিক ক্ষেত্রে অচলাবস্থাই দেখা দিত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. জৈব মতবাদের ভূমিকা ➺ </b>{" "}
                </font>{" "}
                রাষ্ট্রব্যবস্থা জীবদেহের সমান। দেহের প্রত্যেকটি অঙ্গ প্রত্যঙ্গের
                সঠিক মেলবন্ধন ও কার্যসম্পাদন জীবদেহের পূর্ণ সঞ্চালন ঘটায়।
                ব্লুন্টসলির মতে সরকার জীবদেহের সমান। দেহ থেকে মস্তিষ্ককে পৃথক
                করলে জীবদেহের মৃত্যু ঘটে। তেমনই সরকারের বিভিন্ন বিভাগগুলোকে
                পরস্পর থেকে বিচ্ছিন্ন করলে সরকারের মৃত্যু ঘটবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. দায়িত্ব-কর্তব্য হীনতা ➺ </b>{" "}
                </font>{" "}
                সরকার পরিচালনার সমস্ত দায়-দায়িত্ব সরকারের তিনটি বিভাগের উপর
                বর্তায়। সরকারি কাজের সুষ্ঠু সম্পাদন বিভাগ তিনটির কর্ম সমন্বয়ের
                দ্বারাই রচিত। এক্ষেত্রে তিনটি বিভাগ যদি পূর্ণ স্বতন্ত্রতা ভোগ
                করে, তবে সরকারি কাজের দায়-দায়িত্ব প্রত্যেকটি বিভাগই এড়িয়ে
                যেতে পারে এবং কার্য সম্পাদনের ক্ষেত্রে একে অপরের উপর দায়ভার
                চাপাতে পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. দক্ষতাহীনতা ➺ </b>{" "}
                </font>{" "}
                আইন বিভাগের কাজ হল আইন প্রণয়ন, শাসন বিভাগের কাজ হল শাসন নীতি
                বাস্তবায়ন এবং বিচার বিভাগের কাজ হল বিচারকার্য সম্পাদনা। তবে
                বর্তমান জনকল্যাণকর রাষ্ট্রব্যবস্থায় এক বিভাগের সঙ্গে আর এক
                বিভাগের কার্যকর্মের সুষ্ঠু সহযোগিতা দ্বারা সু-আইন প্রণয়ন,
                সুশাসন প্রতিষ্ঠা এবং ন্যায়-বিচার কার্যকর হতে পারে।
                রাষ্ট্রবিজ্ঞানী জে. এস. মিলের মতে ক্ষমতা স্বাধীনতাবাদ নীতির
                পূর্ণ প্রয়োগ হলে সরকারের বিভিন্ন বিভাগের দক্ষতা নষ্ট হতে পারে।
                <br />
                <br />
                উপরোক্ত আলোচনা থেকে বিষয়টি স্পষ্ট ক্ষমতার বিকেন্দ্রীকরণই
                স্বৈরাচারের হাত থেকে রক্ষা করবে। বলা যায় ক্ষমতা স্বতন্ত্রীকরণ
                নীতিটি সম্পূর্ণ বাতিলযোগ্য নয়। আম্মুক্ষ্ম বার পূর্ণ প্রয়োগ
                সম্ভব নয়, সমীচীনও নয়। আইন ও শাসন বিভাগের মধ্যে পারস্পরিক
                সম্পর্ক মার্কিন শাসনব্যবস্থাতেও পরিলক্ষিত হয়। বলাবাহুল্য আধুনিক
                গণতান্ত্রিক ব্যবস্থায় নিরপেক্ষ প্রশাসন গড়ে তোলার লক্ষ্যে বিচার
                বিভাগকে আইন ও শাসন বিভাগের প্রভাব মুক্ত রাখা প্রয়োজন। শেষে বলা
                যায় পূর্ণ নয়, বরং আংশিক ক্ষমতা স্বতন্ত্রীকরণই শ্রেয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <font color="#f626d7">
                <b>
                  {" "}
                  3. ক্ষমতা স্বতন্ত্রীকরণ নীতির পক্ষে ও বিপক্ষে যুক্তি দাও।
                </b>
              </font>
              <br />
              <br />
              <font color="#1f7405">
                <b> উত্তর ➺ </b>{" "}
              </font>{" "}
              <font color="#3c92e5">
                আধুনিক রাষ্ট্র ব্যবস্থায় সরকারের তিনটি বিভাগ — আইন বিভাগ, বিচার
                বিভাগ ও শাসন বিভাগ। আইন বিভাগের কাজ আইন প্রণয়ন করা, বিচার
                বিভাগের কাজ বিচারকার্য সম্পাদন করা, শাসন বিভাগের কাজ প্রশাসন
                পরিচালনার নীতি সম্পূর্ণ বাস্তবায়ন করা। <br />
                <br />
                <font color="#f626d7">
                  <b>1. স্বাধীনতা রক্ষা : </b>
                </font>{" "}
                সরকারের তিনটি বিভাগ সম্পূর্ণ পৃথক থেকে কার্য সম্পাদন করে। একটি
                বিভাগ অপর বিভাগের উপর নিয়ন্ত্রণ বা কর্তৃত্ব কায়েম করে না। ফলে
                বিভাগীয় স্বাধীনতা রক্ষিত হয়। তা না হলে সরকারের স্বেচ্ছাচারীতাই
                প্রকাশ পেত।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. দায়িত্বশীলতা বৃদ্ধি : </b>
                </font>{" "}
                এখানে সরকারের প্রত্যেকটি বিভাগের দায়দায়িত্ব নির্দিষ্ট করে
                দেওয়া হয়। ফলে এক বিভাগ অপর বিভাগের উপর নিজের দায়িত্ব চাপিয়ে
                দিয়ে দায়ভারমুক্ত হতে পারে না এবং জনগণও বিভ্রান্ত হয় না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. স্বৈরাচারী প্রবণতা রোধ : </b>
                </font>{" "}
                ক্ষমতা স্বতন্ত্রীকরণের ফলে কোনো বিভাগের হাতে ক্ষমতার কেন্দ্রীকরণ
                ঘটে না। ক্ষমতা স্বতন্ত্রীকরণ নীতিটি ক্ষমতার বিকেন্দ্রীকরণকেই
                চিহ্নিত করে, ফলে কোনো বিভাগই প্রভূত ক্ষমতার অধিকারী হয়ে
                স্বৈরাচারী হয়ে উঠতে পারে না।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. ব্যক্তি স্বাধীনতা রক্ষা : </b>
                </font>{" "}
                সরকারের সমগ্র ক্ষমতা যদি কোনো একক ব্যক্তি বা গোষ্ঠীর হাতে থাকে
                তাহলে ওই ব্যক্তি বা গোষ্ঠী ক্ষমতার অপপ্রয়োগ ঘটিয়ে
                ব্যক্তিস্বাধীনতাকে খর্ব করতে পারে। তাই ব্যক্তি স্বাধীনতা
                রক্ষার্থে দেশের বিভিন্ন কাজকর্ম বিভিন্ন বিভাগের উপর ন্যস্ত
                থাকাটাই কাম্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b>5. কাজের কর্মকুশলতা ও গতিশীলতা বৃদ্ধি : </b>
                </font>{" "}
                কাজের বণ্টন যথাযথভাবে নির্দিষ্ট হলে, প্রত্যেকটি বিভাগ নিজ নিজ
                নির্দিষ্ট কাজে অত্যন্ত দক্ষ ও সুনিপুণ হয়ে ওঠে। তা ছাড়া
                বিভাগীয় কর্মীদের নির্দিষ্ট কাজের প্রতি গতিশীলতা বৃদ্ধি পায়।
                মন্তেস্কু এই কারণে একটি বিভাগ থেকে অপর বিভাগের প্রভাবমুক্ত থাকার
                কথা বলেছেন।
                <br />
                <br />
                <font color="#1f7405">
                  <b> ক্ষমতা স্বতন্ত্রীকরণ নীতিটির বিপক্ষে যুক্তি : </b>
                </font>{" "}
                জন স্টুয়ার্ট মিল, বুন্টসল্লি, ফাইনার, ল্যাস্কি প্রমুখ রাষ্ট্র
                বিজ্ঞানীগণের মতে ক্ষমতা স্বতন্ত্রীকরণ নীতির পূর্ণ প্রয়োগ সম্ভবও
                নয়, কাম্যও নয়। তাঁরা এই নীতিটির পূর্ণ প্রয়োগের সমালোচনাও
                করেছেন —<br />
                <br />
                <font color="#f626d7">
                  <b>1. বাস্তবায়ন অসম্ভব : </b>
                </font>{" "}
                গণতন্ত্রের একটি মূল প্রিন্সিপল হল সহযোগিতা। ক্ষমতা স্বতন্ত্রীকরণ
                নীতির পূর্ণ প্রয়োগ অসম্ভব। এক বিভাগ যদি অন্য বিভাগকে নিয়ন্ত্রণ
                করে, তা আধিপত্য এবং স্বৈরাচারিকতা বোধ করতে পারে, পারস্পরিক
                সহায়কতা নয়। দেখা যায় আইন বিভাগের অধিকতর কার্য শাসন বিভাগ
                প্রতিষ্ঠা করে এবং আবার আইনসভা অধিবেশন বন্ধ থাকার সময়ে শাসন
                বিভাগ অর্ডিন্যান্স জারি করে। এভাবে, সরকারের তিনটি বিভাগের
                পারস্পরিক সহায়তা সরকারের উন্নতি দিতে পারে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>2. বাস্তব প্রয়োগ কাম্য নয় : </b>
                </font>{" "}
                যদিও তর্কের খাতিরে ধরে নেওয়া হয় ক্ষমতা স্বতন্ত্রীকরণ নীতির
                বাস্তব প্রয়োগ সম্ভব, কিন্তু এর প্রয়োগ কাম্য নয়। কারণ এই নীতির
                পূর্ণ প্রয়োগ ঘটলে সরকারের তিনটি বিভাগের পারস্পরিক সহযোগিতার
                পরিবর্তে পারস্পরিক সংঘাত বা বিরোধ উপস্থিত হবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>3. জৈব তত্ত্বের যুক্তি : </b>
                </font>{" "}
                জৈব তত্ত্বে সরকারকে জীবদেহের সঙ্গে তুলনা করা হয়েছে। জীবদেহের
                সকল অঙ্গপ্রত্যঙ্গগুলি যেমন একে অপরের উপর নির্ভরশীল, তেমনই
                সরকারের তিনটি বিভাগ পরস্পরের উপর নির্ভরশীল। জীবদেহের মস্তিষ্ক,
                দেহ থেকে পৃথক করলে জীবদেহের মৃত্যু ঘটে। সরকারের বিভাগগুলোকে
                সম্পূর্ণভাবে পরস্পর থেকে পৃথক করলে সরকারেরও মৃত্যু ঘটবে।
                <br />
                <br />
                <font color="#f626d7">
                  <b>4. বিভাগগুলোর অদক্ষতা প্রকাশ : </b>
                </font>{" "}
                বিভাগগুলোর মধ্যে পূর্ণক্ষমতার বিভাজন ঘটলে বিভাগগুলোর অদক্ষতাই
                প্রকাশ পাবে। কারণ কোনো কাজের সুষ্ঠু বাস্তবায়নের জন্য সহযোগিতাই
                একমাত্র মাধ্যম। তবেই সুশাসন, সুআইন ও সুবিচার প্রতিষ্ঠা হবে।
                কিন্তু কাজের পূর্ণ বিভাজন ঘটলে প্রতিটি বিভাগ পরস্পরের সঙ্গে
                প্রাধান্য প্রতিষ্ঠার দ্বন্দ্বে লিপ্ত হবে ফলে সরকারের বিভিন্ন
                বিভাগের দক্ষতা নষ্ট হবে।
                <br />
                <br />
                পরিশেষে বলা যায়, “পূর্ণ ক্ষমতা স্বতন্ত্রীকরণ নীতি সম্ভবও নয়,
                সমীচীনও নয়”। প্রশাসন পরিচালনার সুবিধার্থেআইন বিভাগ ও শাসন
                বিভাগের মধ্যে সহযোগিতার সম্পর্ক থাকলেও গণতন্ত্র সংরক্ষণে বিচার
                বিভাগকে স্বতন্ত্র রাখা বিশেষ প্রয়োজন। তাই বলা যেতে পারে কোনো
                রাষ্ট্রে ক্ষমতা স্বতন্ত্রীকরণ নীতির পূর্ণ নির্ভুল প্রয়োগ সম্ভব
                না হলেও আংশিক প্রয়োগ কাম্য।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a>
    </div>
  );
}

সরকারেরবিভিন্নবিভাগ.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(সরকারেরবিভিন্নবিভাগ);
